import AppUserAvatar from '@src/components/common/image/AppUserAvatar';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IUserInfo } from '@src/model/user/User';
import { Col, Empty, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router';

// -- Prop types
// ----------

export interface IRepositoryUserListOwnProps {
  userList: IUserInfo[];
}

type IRepositoryUserListProps = IRepositoryUserListOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Repository users list */
class RepositoryUserList extends React.Component<IRepositoryUserListProps> {
  renderRepoItem = (item: IUserInfo) => {
    return <Col key={item.id} lg={6} md={12} xs={24}>
      <Link to={`/user/${item.id}`}>
        <div className="panel timun-repository__item">
          <Col>
            <AppUserAvatar size={'large'} imagePath={item.profileImageUrl}/>
          </Col>
          <Col>
            {item.firstName}&nbsp;{item.lastName}
          </Col>
        </div>
      </Link>
    </Col>;
  };

  render = () => {
    return this.props.userList.length > 0 ?
      <Row className="timun-repository__itemList">
        {this.props.userList.map((user) => {
          return this.renderRepoItem(user);
        })}
      </Row>
      :
      <Empty description={this.props.translate('COMMON.MESSAGE_NO_DATA')}/>;
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<IRepositoryUserListOwnProps>(RepositoryUserList as any);
