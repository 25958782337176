import AppContent from '@src/components/common/container/AppContent';
import LemonIcon from '@src/components/common/image/LemonIcon';
import ListPagination from '@src/components/common/list/ListPagination';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import NotificationForm from '@src/components/notification/form/NotificationForm';
import NotificationList from '@src/components/notification/list/NotificationList';
import { INotification, NotificationTypeEnum } from '@src/model/notification/Notification';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import notificationListBusinessStore, { INotificationListFilter } from '@src/service/business/notification/notificationListBusinessStore';
import notificationViewBusinessStore, { INotificationCreatePayload } from '@src/service/business/notification/notificationViewBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createActionThunk, IActionThunkMap } from '@src/service/util/action/thunk';
import { Button, Col, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

interface IAdminNotificationListContainerOwnProps { }

interface IAdminNotificationListContainerStateProps {
  notifications: ICollectionData<INotification>;
  notificationListFilter: INotificationListFilter;
}

interface IAdminNotificationListContainerDispatchProps {
  fetchNotificationList: (params: ICollectionFetchPayload<INotificationListFilter>) => any;
  deleteNotification: (id: string, callback: IActionThunkMap) => void;
  clearFilter: () => any;
  clearNotificationList: () => any;
  storeFilter: (listFilter: INotificationListFilter) => any;
  createNotification: (data: INotificationCreatePayload, callback: IActionThunkMap) => void;
  updateNotification: (data: INotification, callback: IActionThunkMap) => void;
}

type IAdminNotificationListContainerProps = IAdminNotificationListContainerOwnProps & IAdminNotificationListContainerStateProps & IAdminNotificationListContainerDispatchProps & IWithLocalizeOwnProps;

interface IAdminNotificationListContainerState {
  isModalVisible: boolean;
  notificationToEdit?: INotification;
  page: number;
  size: number;
  sort: string;
}

class AdminNotificationListContainer extends React.Component<IAdminNotificationListContainerProps, IAdminNotificationListContainerState> {
  state: IAdminNotificationListContainerState = {
    isModalVisible: false,
    page: 0,
    size: AppConfigService.getValue('api.paging.defaultPageSize'),
    sort: '',
    notificationToEdit: undefined,
  };

  componentDidMount() {
    const notificationListFilter = { type: NotificationTypeEnum.GENERIC };
    this.handleFilterChange(notificationListFilter);
  }

  componentDidUpdate = (prevProps: IAdminNotificationListContainerProps, prevState: IAdminNotificationListContainerState) => {
    if (this.state.page !== prevState.page || this.state.size !== prevState.size || this.state.sort !== prevState.sort || this.props.notificationListFilter !== prevProps.notificationListFilter) {
      this.fetchNotificationList();
    }
  };

  componentWillUnmount = () => {
    this.props.clearNotificationList();
  };

  render() {
    return (
      <AppContent>
        <Row className="timun-notificationListContainer__row" justify="end">
          <Col>
            <Button icon={<LemonIcon name="plus" />} type="primary" onClick={this.handleCreateButtonClick}>  {this.props.translate('NOTIFICATIONS.CREATE_NOTIFICATION')} </Button>
          </Col>
        </Row>

        {/**
         this is commented until we decide to use filtering in Admin Notification List
         */}
        {/*<NotificationListFilter onChangeFilter={this.handleFilterChange} listFilter={this.props.notificationListFilter || {}}/>*/}
        {this.props.notifications && <ListPagination page={this.props.notifications.page} onChange={this.handlePageChange} />}

        {this.props.notifications && <NotificationList notifications={this.props.notifications} showControls={true} onSortChange={this.handleSortChange} onPageChange={this.handlePageChange} onDelete={this.handleDelete} onEdit={this.handleEdit} />}

        {this.props.notifications && <ListPagination page={this.props.notifications.page} onChange={this.handlePageChange} />}

        {this.state.isModalVisible && <NotificationForm onCreateSubmit={this.handleCreate} onUpdateSubmit={this.handleUpdate} notification={this.state.notificationToEdit} onClose={() => this.hideModal()} />}
      </AppContent>
    );
  }

  handleCreateButtonClick = () => {
    this.showModal();
  };

  hideModal = () => {
    this.setState({
      isModalVisible: false,
      notificationToEdit: undefined,
    });
  };

  showModal = () => {
    this.setState({
      isModalVisible: true,
    });
  };

  handleEdit = (notification: INotification) => {
    this.setState({
      notificationToEdit: notification,
    });
    this.handleCreateButtonClick();
  };

  handleFilterChange = (listFilter: INotificationListFilter) => {
    this.props.storeFilter(listFilter);
    this.setState({ page: 0 });
  };

  handlePageChange = (page: number, pageSize?: number) => {
    this.setState({ page: page - 1, size: pageSize ?? this.state.size });
  };

  handleSortChange = (sort: string) => {
    if (this.state.sort !== sort) {
      this.setState({ page: 0, sort });
    }
  };

  private handleCreate = (data: INotificationCreatePayload) => {
    this.props.createNotification(data, {
      success: () => {
        this.fetchNotificationList();
        this.hideModal();
      },
    });
  };

  private handleUpdate = (data: INotification) => {
    this.props.updateNotification(data, {
      success: () => {
        this.fetchNotificationList();
        this.hideModal();
      },
    });
  };

  private handleDelete = (data: INotification) => {
    this.props.deleteNotification(data.id, {
      success: () => {
        this.fetchNotificationList();
      },
    });
  };

  private fetchNotificationList = () => {
    this.props.fetchNotificationList({
      filter: { ...this.props.notificationListFilter },
      page: this.state.page,
      size: this.state.size,
      sort: [this.state.sort],
    });
  };
}

const mapStateToProps = (state: any, ownProps: IAdminNotificationListContainerOwnProps): IAdminNotificationListContainerStateProps => ({
  notifications: notificationListBusinessStore.selectors.getNotificationList(state),
  notificationListFilter: notificationListBusinessStore.selectors.getNotificationListFilter(state),
});

const mapDispatchToProps = (dispatch: Dispatch): IAdminNotificationListContainerDispatchProps => ({
  fetchNotificationList: (params: ICollectionFetchPayload<INotificationListFilter>) => dispatch(notificationListBusinessStore.actions.fetchNotificationList({ ...params })),
  deleteNotification: (notificationId: string, thunkMap: IActionThunkMap) => dispatch(createActionThunk(notificationViewBusinessStore.actions.deleteNotification({ id: notificationId }), thunkMap)),
  clearFilter: () => dispatch(notificationListBusinessStore.actions.clearNotificationListFilter()),
  clearNotificationList: () => dispatch(notificationListBusinessStore.actions.clearNotificationList()),
  storeFilter: (listFilter: INotificationListFilter) => dispatch(notificationListBusinessStore.actions.storeNotificationListFilter(listFilter)),
  createNotification: (data: INotificationCreatePayload, thunkMap: IActionThunkMap) => dispatch(createActionThunk(notificationViewBusinessStore.actions.createNotification(data), thunkMap)),
  updateNotification: (data: INotification, thunkMap: IActionThunkMap) => dispatch(createActionThunk(notificationViewBusinessStore.actions.updateNotification(data), thunkMap)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<IAdminNotificationListContainerOwnProps>(AdminNotificationListContainer as any));
