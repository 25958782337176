import { Form } from '@ant-design/compatible';
import { LeftOutlined } from '@ant-design/icons';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import UserGroupTabsView from '@src/components/usergroup/view/UserGroupTabsView';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { IUserGroup } from '@src/model/usergroup/UserGroup';
import AppConfigService from '@src/service/common/AppConfigService';

import { Button, Col, Layout, Row, Typography } from 'antd';
import React from 'react';

// -- Const
// ----------
const USER_GROUP_LIST_ROUTE = AppConfigService.getValue('components.userGroups.userGroupRouterProp');
const ADMIN_USER_GROUP_LIST_ROUTE = AppConfigService.getValue('components.userGroups.adminUserGroupRouterProp');

const { Header, Content } = Layout;

// -- Prop types
// ----------

export interface IUserGroupViewOwnProps {
  userGroup?: IUserGroup;
  onUserGroupLeave?: () => void;
  onUserGroupJoin?: () => void;
}

type IUserGroupViewProps = IUserGroupViewOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

interface IUserGroupViewState { }

// -- Component
// ----------

class UserGroupView extends React.Component<IUserGroupViewProps, IUserGroupViewState> {
  state = {};

  render() {
    const isUserAdmin = this.props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN]);

    return (
      <div>
        <Layout>
          <Header className="timun-userGroup__header">
            <Row justify="space-between">
              <Col>
                <HeaderTitle link={isUserAdmin ? ADMIN_USER_GROUP_LIST_ROUTE : USER_GROUP_LIST_ROUTE} title={this.props.userGroup?.name ?? this.props.translate('USER_GROUP_VIEW.LABEL')} icon={<LeftOutlined />} />
              </Col>
              <Col>
                {this.props.userGroup?.currentUserMember ? (// TODO: join/leave same as organization?
                  <Button type="primary" onClick={this.props.onUserGroupLeave}>
                    {this.props.translate('USER_GROUP.LEAVE_BUTTON_LABEL')}
                  </Button>
                ) : (
                  <Button type="primary" onClick={this.props.onUserGroupJoin}>
                    {this.props.translate('USER_GROUP.JOIN_BUTTON_LABEL')}
                  </Button>
                )}
              </Col>
            </Row>
          </Header>
          <Layout>
            <Content className="timun-userGroup__content">
              <Form layout="inline" className="panel timun-userGroup__form">
                <Form.Item className="ant-form-text" label={this.props.translate('USER_GROUP_VIEW.DESCRIPTION_LABEL')}>
                  <Typography.Paragraph> {this.props.userGroup?.description}</Typography.Paragraph>
                </Form.Item>
                {this.props.userGroup?.latestCode?.value && ( // TODO: use view panel from organization join code?
                  <Form.Item className="ant-form-text" label={this.props.translate('USER_GROUP_VIEW.ACCESS_CODE_LABEL')}>
                    <Typography.Paragraph className="ant-form-text"> {this.props.userGroup?.latestCode.value}</Typography.Paragraph>
                  </Form.Item>
                )}
              </Form>

              {this.props.userGroup?.currentUserMember && (
                <React.Fragment>
                  <br />
                  <UserGroupTabsView userGroup={this.props.userGroup} />
                </React.Fragment>
              )}
            </Content>
          </Layout>
        </Layout>
      </div>
    );
  }
}

// -- HOCs and exports
// ----------

export default withRoles(withLocalize<IUserGroupViewOwnProps>(UserGroupView as any));
