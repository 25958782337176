import { IMathLibElement, useMathContext } from '@src/components/common/math/MathProvider';
import LemonError from '@src/service/common/LemonError';

/** Hook that exposes functions for rendering math on given elements or as a string formula. */
const useMath = (): [(elements: IMathLibElement[]) => Promise<any>, (content: string) => Promise<string>] => {
  const MathLib = useMathContext();

  const renderMathContent = (elements: IMathLibElement[]) => {
    if (MathLib == null) {
      throw new LemonError('Math lib not loaded!');
    }
    return MathLib.renderContent(elements);
  };
  const renderMathFormula = (content: string) => {
    if (MathLib == null) {
      throw new LemonError('Math lib not loaded!');
    }

    return MathLib.renderFormula(content);
  };

  return [renderMathContent, renderMathFormula];
};

export { useMath };
