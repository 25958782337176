import OrganizationViewContainer from '@src/components/organization/view/OrganizationViewContainer';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------
export interface IOrganizationViewPagePublicProps {
}

type IOrganizationViewPageProps = IOrganizationViewPagePublicProps & WithRouterProps;

// -- Component
// ----------
const OrganizationViewPage = (props: IOrganizationViewPageProps) => {
  return <OrganizationViewContainer organizationId={props.params.organizationId} openedTab={props.params.openedTab}/>;
};

export default withRouter<IOrganizationViewPagePublicProps>(OrganizationViewPage);
