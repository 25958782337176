import { PlayCircleOutlined } from '@ant-design/icons';
import LemonImage from '@src/components/common/image/LemonImage';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import RelatedObjects from '@src/components/course/RelatedObjects';
import ReminderCreateContainer from '@src/components/reminder/ReminderCreateContainer';
import WebinarHelperUtils from '@src/components/webinar/common/WebinarHelperUtils';
import { CommentObjectTypeEnum } from '@src/model/comment/CommentObjectType';
import { ReminderTypeEnum } from '@src/model/reminder/Reminder';
import { NoteObjectTypeEnum } from '@src/model/user/Note';
import { IUserDetails } from '@src/model/user/UserDetails';
import { IWebinar, WebinarStatusEnum } from '@src/model/webinar/Webinar';
import AppConfigService from '@src/service/common/AppConfigService';
import { Col, Row } from 'antd';
import moment from 'moment';
import React from 'react';

const DATE_WITH_YEAR: string = AppConfigService.getValue('dateTimeFormat.dateWithYear');

interface IWebinarViewWebinarContentOwnProps {
  webinar: IWebinar;
  onWebinarRedirect: (link: string) => void;
  currentUser: IUserDetails;
}

type IWebinarViewWebinarContentProps = IWebinarViewWebinarContentOwnProps & IWithLocalizeOwnProps & IWithPathResolverOwnProps;

interface IWebinarViewWebinarState {
  isEditingNotes: boolean;
}

class WebinarViewWebinarContent extends React.Component<IWebinarViewWebinarContentProps, IWebinarViewWebinarState> {
  state = {
    isEditingNotes: false,
  };

  render() {
    const hasPassed = this.props.webinar && WebinarHelperUtils.isWebinarInStatus(this.props.webinar, [WebinarStatusEnum.IN_CREATION, WebinarStatusEnum.CREATION_FINISHED, WebinarStatusEnum.PUBLISHED]);

    return (
      <React.Fragment>
        <div>
          <React.Fragment>
            <div className="timun-webinarView__videoThumbnail" onClick={this.handleWebinarRedirect}>
              <LemonImage imagePath={this.props.webinar.coverImageUrl || 'cover-placeholder.png'} className="timun-webinarView__coverImage"/>
              <PlayCircleOutlined className="timun-webinarView__playIcon"/>
            </div>
            &nbsp;
            {hasPassed && (<Row justify="center" className="text-center">
              <Col>
                <ReminderCreateContainer showCreateButton={true} refObject={{ id: this.props.webinar.id, name: this.props.webinar.title }} user={this.props.currentUser} reminderType={ReminderTypeEnum.WEBINAR}/>
              </Col>
            </Row>)}
            <Row justify="space-between">
              <Col className="timun-webinarView__descriptionCol" span={8}>
                {this.props.translate('WEBINAR_VIEW.HOST')}: {this.props.webinar.guest}
              </Col>
              <Col span={8} className="text-right">
                {this.props.translate('WEBINAR_VIEW.STATUS')}: {this.props.translate(`WEBINAR.STATUS_TITLE.${this.props.webinar.status.name}`)}
              </Col>
            </Row>
            <Row>
              <Col>
                {this.props.translate('WEBINAR_VIEW.DATE')}: {moment(this.props.webinar.estimatedStartDateTime).format(DATE_WITH_YEAR)}
              </Col>
            </Row>
            <Row>
              <Col>
                {this.props.translate('WEBINAR_VIEW.DESCRIPTION')}: {this.props.webinar.description}
              </Col>
            </Row>
          </React.Fragment>
        </div>
        &nbsp;&nbsp;
        {/*!streaming && */this.props.webinar && (
          <RelatedObjects
            noteObject={{ objectId: this.props.webinar.id, objectTypeId: NoteObjectTypeEnum.WEBINAR }}
            commentObject={{ objectId: this.props.webinar.id, objectTypeId: CommentObjectTypeEnum.WEBINAR }}
          />
        )}
      </React.Fragment>
    );
  }

  handleWebinarRedirect = () => {
    this.props.onWebinarRedirect(this.props.webinar.link);
  };

}

export default withPathResolver(withLocalize<IWebinarViewWebinarContentOwnProps>(WebinarViewWebinarContent as any));
