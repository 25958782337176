import { ICourse } from '@src/model/course/Course';
import { IFile } from '@src/model/file/File';
import { IUserDetails } from '@src/model/user/UserDetails';

export interface IReport {
  id: string;
  requestedDateTime: string;
  fileResource: IFile;
  status: IReportStatus;
  type: IReportType;
  course: ICourse;
  requestedBy: IUserDetails | null;
}

export interface IReportStatus {
  id: IReportStatusEnum;
  name: string;
  description: string;
}

export enum IReportStatusEnum {
  IN_CREATION = '1',
  DONE = '2',
  FAILED = '3',
}

export interface IReportType {
  id: IReportTypeEnum;
  name: string;
  description: string;
}

export enum IReportTypeEnum {
  GENERAL_COURSE = 'GENERAL_COURSE',
  COURSE = 'COURSE',
  COURSE_TRAINEE = 'COURSE_TRAINEE',
  EXTERNAL_EDUCATION_APPLICATION = 'EXTERNAL_EDUCATION_APPLICATION',
}
