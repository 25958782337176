import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

import ITenantConfiguration from '@src/model/tenant/TenantConfiguration';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import PublicTenantConfigurationBusinessStore from '@src/service/business/tenant/publicTenantConfigurationBusinessService';
import { actionThunk } from '@src/service/util/observable/operators';
import { startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts

export type ITenantConfigurationUpdatePayload = Pick<ITenantConfiguration, 'id' | 'configuration'>;

// -
// -------------------- Actions

const Actions = {
  TENANT_CONFIGURATION_UPDATE: 'TENANT_CONFIGURATION_UPDATE',
};

/** Update tenantConfiguration by ID. */
const updateTenantConfiguration = (data: ITenantConfigurationUpdatePayload): IPayloadAction<ITenantConfigurationUpdatePayload> => {
  return {
    type: Actions.TENANT_CONFIGURATION_UPDATE,
    payload: data,
  };
};

// -
// -------------------- Side-effects

const updateTenantConfigurationEffect = (action$: Observable<IPayloadAction<ITenantConfigurationUpdatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TENANT_CONFIGURATION_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('TenantConfiguration')
        .updateMethod('current', data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return PublicTenantConfigurationBusinessStore.actions.loadTenantConfiguration(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'TENANT_CONFIGURATION_CREATE.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating tenantConfiguration', error);
      return o;
    })
  );
};

// --
// -------------------- Business Store

export const TenantConfigurationBusinessStore = {
  actions: {
    updateTenantConfiguration,
  },

  effects: {
    updateTenantConfigurationEffect,
  },
};

// --
// export business store
export default TenantConfigurationBusinessStore;
