import GridItem from '@src/components/common/grid/GridItem';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/common/EducationApplicationHelperUtils';
import ExternalEducationApplicationFileListUpload from '@src/components/externalEducationApplication/common/ExternalEducationApplicationFileListUpload';
import { IFileListsByType } from '@src/components/externalEducationApplication/common/types';
import { ActivityPhaseEnum } from '@src/model/activity/ActivityPhase';

import { EmploymentStatusEnum, IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { IFile } from '@src/model/file/File';
import { FileTypeEnum } from '@src/model/file/FileSystemElement';
import { Button, Col, Row } from 'antd';

import React from 'react';

const LAYOUT_BUTTONS_1 = {
  sm: 24,
  xs: 24,
  md: 12,
};

// -- Prop types
// ----------
interface IExternalEducationApplicationTraineeDurationViewOwnProps {
  externalEducationApplication: IExternalEducationApplication;
  applicationFiles: IFileListsByType;
  onUpdate?: (data: IExternalEducationApplication) => void;
  // tslint:disable-next-line: bool-param-default
  onChangePhase?: (newPhase: ActivityPhaseEnum, save?: boolean) => void;
  onFileUpload?: (file: IFile[]) => void;
  onFileRemove?: (file: IFile[]) => void;
}

type IExternalEducationApplicationTraineeDurationViewProps = IExternalEducationApplicationTraineeDurationViewOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

interface IExternalEducationApplicationTraineeDurationViewState { }

// -- Component
// ----------

class ExternalEducationApplicationTraineeDurationView extends React.Component<IExternalEducationApplicationTraineeDurationViewProps, IExternalEducationApplicationTraineeDurationViewState> {
  state: IExternalEducationApplicationTraineeDurationViewState = {};

  componentDidUpdate = (prevProps: IExternalEducationApplicationTraineeDurationViewProps, prevState: IExternalEducationApplicationTraineeDurationViewState) => {
    //
  };

  isApplicationInPhases = (phases: ActivityPhaseEnum[]): boolean => {
    return EducationApplicationHelperUtils.isInPhases(phases, this.props.externalEducationApplication.activity);
  };

  handleChangePhase = (phase: ActivityPhaseEnum) => {
    if (this.props.onChangePhase) {
      this.props.onChangePhase(phase);
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.isApplicationInPhases([ActivityPhaseEnum.EDUCATION_IN_PROGRESS]) && (
          <React.Fragment>
            <Row>
              <Col>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.EDUCATION_IN_PROGRESS_TEXT')}</Col>
            </Row>

            {this.props.externalEducationApplication.employmentStatus?.id === EmploymentStatusEnum.UNEMPLOYED && (
              <Row>
                <Col>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.UNEMPLOYED_ATTENDANCE_CERTIFICATE_TEXT')}</Col>
              </Row>
            )}

            <GridItem label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.FILES.ATTENDANCE_CERTIFICATE_LABEL')} infoText={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.ATTENDANCE_CERTIFICATE_INFO')}>
              <ExternalEducationApplicationFileListUpload applicationFiles={this.props.applicationFiles} fileType="ATTENDANCE_CERTIFICATE" onFileUpload={this.props.onFileUpload} onFileRemove={this.props.onFileRemove} />
            </GridItem>

            <Row>
              <Col>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.UPLOAD_COMPETENCIES_CERTIFICATE_TEXT')}</Col>
            </Row>

            <GridItem label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.FILES.COMPETENCIES_CERTIFICATE_LABEL')} infoText={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.COMPETENCIES_CERTIFICATE_INFO')} requiredMark={true}>
              <ExternalEducationApplicationFileListUpload applicationFiles={this.props.applicationFiles} fileType="COMPETENCIES_CERTIFICATE" onFileUpload={this.props.onFileUpload} onFileRemove={this.props.onFileRemove} />
            </GridItem>

            <Row>
              <Col {...LAYOUT_BUTTONS_1}>
                <Button type="primary" block={false} onClick={() => this.handleChangePhase(ActivityPhaseEnum.EDUCATION_COMPLETED)}
                  disabled={!EducationApplicationHelperUtils.hasFilesInAllTypes(this.props.applicationFiles, [FileTypeEnum.COMPETENCIES_CERTIFICATE])}
                  data-test-id="timun-externalEducationApplicationView__educationCompletedButton">
                  {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.EDUCATION_COMPLETED_BUTTON_TEXT')}
                </Button>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

// -- HOCs and exports
// ----------

export default withRoles(withLocalize<IExternalEducationApplicationTraineeDurationViewOwnProps>(ExternalEducationApplicationTraineeDurationView as any));
