import React from 'react';
import { connect } from 'react-redux';

import TraineeStartedCourseListView from '@src/components/course/list/catalog/TraineeStartedCourseListView';
import { CourseCompletionStatusEnum, ICourse } from '@src/model/course/Course';
import { ICollectionData } from '@src/service/business/common/types';
import CourseListBusinessStore from '@src/service/business/courses/courseListBusinessStore';
import memoizeOne from 'memoize-one';

// -- Prop types
// ----------

export interface ITraineeStartedCourseListContainerOwnProps {}
export interface ITraineeStartedCourseListContainerStateProps {
  courseList: ICollectionData<ICourse>;
}
export interface ITraineeStartedCourseListContainerDispatchProps {}
type ITraineeStartedCourseListContainerProps = ITraineeStartedCourseListContainerOwnProps & ITraineeStartedCourseListContainerStateProps & ITraineeStartedCourseListContainerDispatchProps;

// -- Component
// ----------

/** Describe your component ... */
class TraineeStartedCourseListContainer extends React.Component<ITraineeStartedCourseListContainerProps> {
  // memoizez course list for status in progress
  getStartedCourseList = memoizeOne((courseList: ICourse[]) => courseList.filter((course: ICourse) => course.progress && course.progress.completionStatus.id === CourseCompletionStatusEnum.IN_PROGRESS));

  render = () => {
    return <TraineeStartedCourseListView courseList={this.getStartedCourseList(this.props.courseList?.content ?? [])} />;
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ITraineeStartedCourseListContainerOwnProps): ITraineeStartedCourseListContainerStateProps => ({
  courseList: CourseListBusinessStore.selectors.getCourseList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ITraineeStartedCourseListContainerDispatchProps => ({});

export default connect<ITraineeStartedCourseListContainerStateProps, ITraineeStartedCourseListContainerDispatchProps, ITraineeStartedCourseListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(TraineeStartedCourseListContainer as any);
