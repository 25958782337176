import withCollectionState, { IWithCollectionStateOwnProps, IWithCollectionStatePublicProps } from '@src/components/common/collectionParams/withCollectionState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import AdminSkillGroupListView from '@src/components/skillgroup/admin/AdminSkillGroupListView';
import SkillGroupModal from '@src/components/skillgroup/form/SkillGroupForm';
import SkillModal from '@src/components/skillgroup/modal/SkillModal';
import SkillModalUpdateContainer from '@src/components/skillgroup/SkillModalUpdateContainer';
import SkillLevelClassificationModalContainer from '@src/components/skilllevelclassification/SkillLevelClassificationModalContainer';
import { ISkill } from '@src/model/skillgroup/Skill';
import { ISkillGroup } from '@src/model/skillgroup/SkillGroup';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import SkillBusinessStore, { ISkillCreatePayload } from '@src/service/business/skillgroup/SkillBusinessStore';
import SkillGroupBusinessStore, { ISkillGroupCreatePayload, ISkillGroupListFilter } from '@src/service/business/skillgroup/SkillGroupBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

// -- Const
// ----------
const DEFAULT_PAGE_SIZE_VALUE = AppConfigService.getValue('api.paging.maxPageSize');
const VIEW_NAME = '@@ADMIN_SKILL_GROUP_LIST';

const collectionDefaults: IWithCollectionStatePublicProps<ISkillGroupListFilter> = {
  viewName: VIEW_NAME,
  initialValues: { size: DEFAULT_PAGE_SIZE_VALUE, filter: { withInactive: true } },
};

// -- Prop types
// ----------
export interface IAdminSkillGroupListContainerOwnProps {
}

export interface IAdminSkillGroupListContainerStateProps {
  skillGroupList: ICollectionData<ISkillGroup>;
}
export interface IAdminSkillGroupListContainerDispatchProps {
  fetchSkillGroupList: (payload: ICollectionFetchPayload<ISkillGroupListFilter>) => ITrackableAction;
  createSkillGroup: (data: ISkillGroupCreatePayload) => ITrackableAction;
  updateSkillGroup: (skillGroup: ISkillGroup) => ITrackableAction;
  createSkill: (data: ISkillCreatePayload) => ITrackableAction;
}

type IAdminSkillGroupListContainerProps = IAdminSkillGroupListContainerOwnProps & IAdminSkillGroupListContainerStateProps
  & IAdminSkillGroupListContainerDispatchProps & WithRouterProps & IWithLocalizeOwnProps & IWithCollectionStateOwnProps<ISkillGroupListFilter>;

interface IAdminSkillGroupListContainerState {
  isCreateSkillGroupModalVisible: boolean;
  isUpdateSkillGroupModalVisible: boolean;
  isSkillLevelClassificationModalVisible: boolean;
  isCreateSkillModalVisible: boolean;
  isUpdateSkillModalVisible: boolean;
  selectedSkillGroup?: ISkillGroup;
  selectedSkill?: ISkill;
}

// -- Component
// ----------
class AdminSkillGroupListContainer extends React.Component<IAdminSkillGroupListContainerProps, IAdminSkillGroupListContainerState> {
  state: IAdminSkillGroupListContainerState = {
    isCreateSkillGroupModalVisible: false,
    isUpdateSkillGroupModalVisible: false,
    isSkillLevelClassificationModalVisible: false,
    isCreateSkillModalVisible: false,
    isUpdateSkillModalVisible: false,
    selectedSkillGroup: undefined,
    selectedSkill: undefined,
  };

  componentDidMount() {
    this.props.setUpdateFunction(this.props.fetchSkillGroupList);
  }

  componentDidUpdate(prevProps: IAdminSkillGroupListContainerProps, prevState: IAdminSkillGroupListContainerState) {
    /*  */
  }

  componentWillUnmount() {
    /*  */
  }

  render() {
    return (
      <React.Fragment>
        {this.props.skillGroupList && <AdminSkillGroupListView
          skillGroupList={this.props.skillGroupList}
          onSkillGroupAdd={() => this.toggleCreateSkillGroupModal(true)}
          onSkillGroupEdit={this.handleSkillGroupEdit}
          onSkillEdit={this.handleSkillEdit}
          onSkillAdd={this.handleSkillAdd}
          onSkillLevelClassificationEdit={this.handleSkillLevelClassificationEdit}
          onPageChange={this.props.updateCollectionParams.onPageChange}
        />}

        {/* Modals */}
        {this.state.isCreateSkillGroupModalVisible && <SkillGroupModal title={this.props.translate('SKILL_GROUP_MODAL.SKILL_GROUP_CREATE_TITLE')} onClose={this.handleSkillGroupCreateModalClose} onCreate={this.handleCreateSkillGroup} />}
        {this.state.isUpdateSkillGroupModalVisible && this.state.selectedSkillGroup && <SkillGroupModal skillGroup={this.state.selectedSkillGroup} title={this.props.translate('SKILL_GROUP_MODAL.SKILL_GROUP_UPDATE_TITLE')} onClose={this.handleSkillGroupUpdateModalClose} onUpdate={this.handleSkillGroupUpdate} />}
        {this.state.isCreateSkillModalVisible && <SkillModal skillGroup={this.state.selectedSkillGroup} title={this.props.translate('SKILL_MODAL.SKILL_CREATE_TITLE')} onClose={this.handleSkillCreateModalClose} onCreateSkill={this.handleCreateSkill} />}
        {this.state.isUpdateSkillModalVisible && this.state.selectedSkill && <SkillModalUpdateContainer skill={this.state.selectedSkill} onUpdateSkill={this.handleUpdateSkill} onClose={this.handleSkillUpdateModalClose} />}
        {this.state.isSkillLevelClassificationModalVisible && this.state.selectedSkill && <SkillLevelClassificationModalContainer skillId={this.state.selectedSkill.id} onClose={() => this.handleSkillLevelClassificationModalClose()} />}
      </React.Fragment>
    );
  }


  handleSkillGroupUpdate = (skillGroup: ISkillGroup) => {
    this.props.updateSkillGroup(skillGroup).track().subscribe(
      // success
      () => {
        this.handleSkillGroupUpdateModalClose();
        this.props.onUpdateList();
      }
    );
  };

  handleSkillLevelClassificationEdit = (skill: ISkill) => {
    this.setState({ selectedSkill: skill });
    this.toggleSkillLevelClassificationModal(true);
  };

  handleSkillLevelClassificationModalClose = (selectedSkill?: ISkill) => {
    this.toggleSkillLevelClassificationModal(false);
    this.setState({ selectedSkill });
  };

  toggleSkillLevelClassificationModal = (isVisible: boolean) => {
    this.setState({ isSkillLevelClassificationModalVisible: isVisible });
  };

  handleSkillGroupEdit = (skillGroup: ISkillGroup) => {
    this.setState({ selectedSkillGroup: skillGroup });
    this.toggleUpdateSkillGroupModal(true);
  };

  handleSkillAdd = (skillGroup: ISkillGroup) => {
    this.setState({ selectedSkillGroup: skillGroup });
    this.toggleCreateSkillModal(true);
  };

  handleSkillEdit = (skill: ISkill) => {
    this.setState({ selectedSkill: skill });
    this.toggleUpdateSkillModal(true);
  };

  handleUpdateSkill = () => {
    this.handleSkillUpdateModalClose();
    this.props.onUpdateList();
  };

  toggleCreateSkillModal = (isVisible: boolean) => {
    this.setState({ isCreateSkillModalVisible: isVisible });
  };

  toggleCreateSkillGroupModal = (isVisible: boolean) => {
    this.setState({ isCreateSkillGroupModalVisible: isVisible });
  };

  toggleUpdateSkillGroupModal = (isVisible: boolean) => {
    this.setState({ isUpdateSkillGroupModalVisible: isVisible });
  };

  toggleUpdateSkillModal = (isVisible: boolean) => {
    this.setState({ isUpdateSkillModalVisible: isVisible });
  };

  handleSkillGroupCreateModalClose = () => {
    this.toggleCreateSkillGroupModal(false);
  };

  handleSkillGroupUpdateModalClose = () => {
    this.toggleUpdateSkillGroupModal(false);
  };

  handleSkillUpdateModalClose = () => {
    this.toggleUpdateSkillModal(false);
  };

  handleSkillCreateModalClose = () => {
    this.toggleCreateSkillModal(false);
  };

  handleCreateSkillGroup = (skillGroup: ISkillGroupCreatePayload) => {
    this.props.createSkillGroup(skillGroup).track().subscribe(
      // success
      () => {
        this.props.onUpdateList();
        this.toggleCreateSkillGroupModal(false);
      }
    );
  };

  handleCreateSkill = (skill: ISkillCreatePayload) => {
    this.props.createSkill(skill).track().subscribe(
      // success
      () => {
        this.props.onUpdateList();
        this.toggleCreateSkillModal(false);
      }
    );
  };

}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IAdminSkillGroupListContainerStateProps => ({
  skillGroupList: SkillGroupBusinessStore.selectors.getSkillGroupList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IAdminSkillGroupListContainerDispatchProps => ({
  fetchSkillGroupList: (params: ICollectionFetchPayload<ISkillGroupListFilter>) => createTrackableAction(dispatch(SkillGroupBusinessStore.actions.fetchSkillGroupList(params))),
  updateSkillGroup: (skillGroup: ISkillGroup) => createTrackableAction(dispatch(SkillGroupBusinessStore.actions.updateSkillGroup(skillGroup))),
  createSkillGroup: (data: ISkillGroupCreatePayload) => createTrackableAction(dispatch(SkillGroupBusinessStore.actions.createSkillGroup(data))),
  createSkill: (data: ISkillCreatePayload) => createTrackableAction(dispatch(SkillBusinessStore.actions.createSkill(data))),
});

export default connect(mapStateToProps, mapDispatchToProps)(withCollectionState(collectionDefaults)(withLocalize(withRouter<IAdminSkillGroupListContainerOwnProps>(AdminSkillGroupListContainer as any))));
