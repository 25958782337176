import { IUserDetails } from '@src/model/user/UserDetails';
import React from 'react';
import { Link } from 'react-router';

export interface IUserNameViewProps {
  user: IUserDetails | null;
  fallback?: string | React.ReactNode;
  link?: boolean;
}

/** Display user first and last name, else display fallback */
export const UserNameView = (props: IUserNameViewProps) => {
  const userName = props.user ? <React.Fragment>{props.user.firstName}&nbsp;{props.user.lastName}</React.Fragment> : undefined;
  return (
    <React.Fragment>
      {props.user ?
        props.link ? <Link to={`/user/${props.user.id}`}>{userName}</Link> : userName
        : props.fallback}
    </React.Fragment>
  );
};
