const en: any = {
  // ---------- Template labels
  'MODULE.LABEL_NAME': 'Template for adding labels',

  // ---------- Application
  'APPLICATION.APP_NAME': 'Timun',
  'APPLICATION.APP_SLOGAN': 'Control your destiny',
  'APPLICATION.APP_LOGO_TITLE': 'Timun - Control your destiny',
  'APPLICATION.APP_DESCRIPTION_MADE_WITH': 'Made with',
  'APPLICATION.APP_DESCRIPTION_MADE_BY': 'by Lamaro digital',

  // ---------- COMMON ACTIONS
  'COMMON.ACTION_SAVE': 'Save',
  'COMMON.ACTION_EDIT': 'Edit',
  'COMMON.ACTION_OK': 'OK',
  'COMMON.ACTION_YES': 'Yes',
  'COMMON.ACTION_NO': 'No',
  'COMMON.ACTION_CANCEL': 'Cancel',
  'COMMON.ACTION_ADD': 'Add',
  'COMMON.ACTION_ADD_FROM_PC': 'Add from pc',
  'COMMON.ACTION_ADD_NEW': 'Add new',
  'COMMON.ACTION_SEARCH': 'Search',
  'COMMON.ACTION_LOGOUT': 'Log out',
  'COMMON.ACTION_LOAD_MORE_ITEMS': 'Show More',
  'COMMON.ACTION_CLOSE': 'Close',
  'COMMON.ACTION_SHOW': 'Show',
  'COMMON.ACTION_SHOW_MORE_CONTENT': 'Show more',
  'COMMON.ACTION_SHOW_LESS_CONTENT': 'Show less',
  'COMMON.ACTION_DEACTIVATE': 'Deactivate',
  'COMMON.ACTION_ACTIVATE': 'Activate',
  'COMMON.ACTION_DELETE': 'Delete',
  'COMMON.ACTION_DOWNLOAD': 'Download',
  'COMMON.ACTION_CLEAR': 'Clear',
  'COMMON.ACTION_CONTINUE': 'Continue',
  'COMMON.ACTION_DONE': 'Done',
  'COMMON.ACTION_SORT_LABEL': 'Sort',
  'COMMON.ACTION_SORT_ASCEND': 'Alphabetically - Ascending',
  'COMMON.ACTION_SORT_DESCEND': 'Alphabetically - Descending',
  'COMMON.ACTION_SORT_NEWEST': 'Newest',
  'COMMON.ACTION_SORT_OLDEST': 'Oldest',
  'COMMON.ACTION_APPROVE': 'Approve',
  'COMMON.ACTION_REJECT': 'Reject',
  'COMMON.ACTION_ADD_VIDEO': 'Add Video',
  'COMMON.ACTION_BACK': 'Back',
  'COMMON.ACTION_FILTER': 'Filter',
  'COMMON.ACTION_RESET_FILTER': 'Reset all filters',
  'COMMON.ACTION_ENROLL': 'Enroll',
  'COMMON.ACTION_CREATE_NEW': 'Create new - (%{input})',
  'COMMON.EDITED_MESSAGE': 'Changes have been saved',

  // ----- confirmation
  'COMMON.CONFIRMATION_TITLE': 'Are you sure?',
  'COMMON.CONFIRMATION_DELETE_TITLE': 'Are you sure you want to delete?',
  'COMMON.CONFIRMATION_ITEM_DELETE_TITLE': 'Are you sure you want to delete "%{title}" ?',
  'COMMON.CONFIRMATION_ROUTE_NAVIGATION': 'Are you sure you want to leave this page? Changes will not be saved.',
  'COMMON.CONFIRMATION_CANCEL_ACTION_MESSAGE': 'Are you sure you want to quit editing? Changes will not be saved.',

  // ----- validation
  'COMMON.CONFIRM_DELETE_MESSAGE': 'Are you sure you want to delete?',
  'COMMON.FILL_REQUIRED_FIELD': 'Required field',
  'COMMON.PICK_REQUIRED_FIELD': 'Pick required value',
  'COMMON.ENTERED_VALUE_NOT_VALID': 'Wrong input',
  // --- (new) validation messages
  'COMMON.VALIDATION.REQUIRED': 'Required field',
  'COMMON.VALIDATION.INVALID': 'Invalid value',
  'COMMON.VALIDATION.INVALID_DECIMAL': 'Invalid decimal number',
  'COMMON.VALIDATION.INVALID_INTEGER': 'Invalid number',
  'COMMON.VALIDATION.INVALID_DATETIME': 'Invalid date and/or time',
  'COMMON.VALIDATION.INVALID_EMAIL': 'Invalid email address',
  'COMMON.MESSAGE_NO_DATA': 'No data',
  'COMMON.VALIDATION.INVALID_OIB': 'Invalid OIB',
  'COMMON.VALIDATION.INVALID_IBAN': 'Invalid IBAN',
  'COMMON.VALIDATION.MAX_FIELDS_ADDED': 'Max number of fields added',
  'COMMON.VALIDATION.MIN_FIELDS_REQUIRED': '  Please add the required field. Minimum number of required fields to enter are %{minFieldNumber}',
  'COMMON.VALIDATION.INVALID_EXTERNAL_LINK': 'Link should include https://',
  'COMMON.VALIDATION.MIN_VALUE_EXCEED_MAX_VALUE': "Minimum value can't exceed max value",
  'COMMON.VALIDATION.MAX_VALUE_BELOW_MIN_VALUE': "Max value can't go below min value",

  // ---------- General Messages
  'GENERAL_MESSAGE.GENERAL_FETCH_ERROR': 'Fetching data failed!',
  'GENERAL_MESSAGE.GENERAL_SEND_ERROR': 'Sending data failed!',
  'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR': 'Saving data failed!',
  'GENERAL_MESSAGE.GENERAL_DELETE_ERROR': 'Erasing data failed!',

  'GENERAL_MESSAGE.GENERAL_FETCH_SUCCESS': 'Fetched data successfully.',
  'GENERAL_MESSAGE.GENERAL_SEND_SUCCESS': 'Sent data successfully.',
  'GENERAL_MESSAGE.GENERAL_UPDATE_SUCCESS': 'Saved data successfully.',
  'GENERAL_MESSAGE.GENERAL_DELETE_SUCCESS': 'Erased data successfully.',

  // ----- Common labels
  'COMMON.LABEL.NAME': 'Name',
  'COMMON.LABEL.STATUS': 'Status',
  'COMMON.LABEL.ACTIVE': 'Active',
  'COMMON.LABEL.INACTIVE': 'Inactive',
  'COMMON.LABEL.EMAIL': 'E-mail',
  'COMMON.LABEL.PHONE': 'Phone',
  'COMMON.PLACEHOLDER.EMAIL': 'Enter e-mail address',
  'COMMON.PLACEHOLDER.PHONE': 'Enter phone number',
  'COMMON.LABEL.YES': 'Yes',
  'COMMON.LABEL.NO': 'No',
  'COMMON.LABEL.BOOLEAN_TRUE': 'Yes',
  'COMMON.LABEL.BOOLEAN_FALSE': 'No',
  'COMMON.LABEL.EDIT': 'Edit',
  'COMMON.LABEL.FILTER': 'Filter',
  'COMMON.LABEL.MAXIMUM': 'Maximum',
  'COMMON.LABEL.MINIMUM': 'Minimum',
  'COMMON.LABEL.PICK_ANSWER_PLACEHOLDER': 'Pick answer',

  // ---------- Course Error Messages
  'COURSE_CREATE.ERROR.COURSE_ALREADY_EXISTS': 'Course with the same name already exists, choose another name.',

  // ---------- Tag error messages
  'TAG_ADD.ERROR.TAG_ITEM_ALREADY_EXISTS': 'Tag has already been added.',

  // ---------- App navigation
  'APP_NAVIGATION.DASHBOARD': 'Home',
  'APP_NAVIGATION.MY_PROFILE': 'My profile',
  'APP_NAVIGATION.EDUCATION_GROUPS': 'Education groups',
  'APP_NAVIGATION.REPOSITORY': 'Knowledge base',
  'APP_NAVIGATION.COURSES': 'Course catalog',
  'APP_NAVIGATION.MY_COURSES': 'My courses',
  'APP_NAVIGATION.REPORTS': 'Reports',
  'APP_NAVIGATION:ADMINISTRATION': 'Administration',
  'APP_NAVIGATION.WEBINARS': 'Webinars',
  'APP_NAVIGATION.EXTERNAL_CONTENTS': 'External content',
  'APP_NAVIGATION.MY_USER_GROUPS': 'My groups',
  'APP_NAVIGATION.EDUCATION_APPLICATION': 'All education applications',
  'APP_NAVIGATION.EXTERNAL_EDUCATION_TEMPLATES': 'External education templates',

  // ---------- Activity history text
  'ACTIVITY_HISTORY.PHASE_TEXT.APPROVAL_REQUESTED': 'sent the program for verification.',
  'ACTIVITY_HISTORY.PHAE_TEXT.APPROVAL_IN_PROCESS': 'took over the education program for processing.',
  'ACTIVITY_HISTORY.PHAE_TEXT.APPROVED': 'has verified the education program.',
  'ACTIVITY_HISTORY.PHAE_TEXT.CHANGE_REQUESTED': 'asked for changes.',
  'ACTIVITY_HISTORY.PHAE_TEXT.DENIED': 'denied to verify the education program.',
  'ACTIVITY_HISTORY.PHAE_TEXT.CANCELLED ': 'canceled the education program.',
  'ACTIVITY_HISTORY.PHASE_TEXT.CHANGE_SUBMITTED': 'sent education program changes for verification.',

  // ---------- Login Form
  'LOGIN.USERNAME_LABEL': 'Username',
  'LOGIN.USERNAME_PLACEHOLDER': 'Enter username',
  'LOGIN.PASSWORD_LABEL': 'Password',
  'LOGIN.PASSWORD_PLACEHOLDER': 'Enter password',
  'LOGIN.LOGIN_BUTTON_LABEL': 'Log In',
  'LOGIN.FORGOT_PASSWORD_LABEL': 'Forgot password?',
  'LOGIN.REGISTRATION_LABEL': 'Need an account?',
  'LOGIN.REGISTRATION_LINK': 'Sign up',
  // errors
  'LOGIN.ERROR.GENERAL_ERROR': 'Login error',
  // API errors
  'LOGIN.ERROR.BAD_CREDENTIALS': 'Login unsuccessful. Please check your username and password and try again.',

  // ---------- Login Menu
  'LOGIN_MENU.LOGIN_LABEL': 'Log In',
  'LOGIN_MENU.LANGUAGE_LABEL': 'Language',

  // ---------- User Menu
  'USER_MENU.MY_PROFILE': 'My profile',
  'USER_MENU.CHANGE_PASSWORD_LABEL': 'Change password',
  'USER_MENU.LOGOUT_LABEL': 'Log out',
  'USER_MENU.NOTIFICATIONS': 'Notifications',

  // ---------- Error page
  'ERROR_PAGE.DEFAULT_ERROR_TITLE': 'Oops, something went wrong!',
  'ERROR_PAGE.DEFAULT_ERROR_DESCRIPTION': 'Please try again or contact us so we can try fixing the error together. Thanks!',

  // ---------- Welcome page
  'WELCOME.WELCOME_TITLE': 'Welcome to %{tenantName}!',

  // ---------- Course page
  'COURSE.STATUS_TITLE.IN_CREATION': 'In Progress',
  'COURSE.STATUS_TITLE.CREATION_FINISHED': 'Finished',
  'COURSE.STATUS_TITLE.PUBLISHED': 'Published',
  'COURSE.STATUS_TITLE.ARCHIVED': 'Archived',

  'COURSE_VIEW.LECTURE_LIST_MENU.COURSE_ABOUT_ITEM_LABEL': 'About course',
  'COURSE_VIEW.LECTURE_LIST_MENU.EXAM_TEMPLATE_ITEM_LABEL': 'Exam template',
  'COURSE_VIEW.CREATE.CREATE_COURSE_TITLE': 'New course',
  'COURSE_VIEW.VIEW.COURSE_START_BUTTON': 'Start',
  'COURSE_VIEW.VIEW.LECTURE_QUIZ_TITLE': 'Quiz',
  'COURSE_VIEW.VIEW.ADDITIONAL_COURSES_TITLE': 'Similar courses',
  'COURSE_VIEW.UPDATE.LECTURE_NAME_INPUT_TITLE': 'New lesson',
  'COURSE_VIEW.UPDATE.GROUP_NAME_INPUT_TITLE': 'New lesson group',
  'COURSE_VIEW.UPDATE.NAME_INPUT_PLACEHOLDER': 'Name',
  'COURSE_VIEW.UPDATE.DELETE_CONFIRM_TITLE': 'Delete?',
  'COURSE_VIEW.UPDATE.ABOUT_COURSE_TAB_TITLE': 'Course details',
  'COURSE_VIEW.UPDATE.LECTURE_GROUP_TITLE': 'Lesson groups',
  'COURSE_VIEW.UPDATE.LECTURE_GROUP_SELECT_PLACEHOLDER': 'Select a lesson group',
  'COURSE_VIEW.UPDATE.COURSE_TEXT_FORM_LABEL': 'About course',
  'COURSE_VIEW.UPDATE.COURSE_TITLE_FORM_LABEL': 'Course name',
  'COURSE_VIEW.UPDATE.COURSE_STATUS_FORM_LABEL': 'Course status',
  'COURSE_VIEW.UPDATE.COURSE_GROUP_SELECT_FORM_LABEL': 'Course group',
  'COURSE_VIEW.UPDATE.COURSE_GROUP_SELECT_PLACEHOLDER': 'Select a course group',
  'COURSE_VIEW.UPDATE.COURSE_LECTURE_GROUP_TITLE_FORM_LABEL': 'Course group name',
  'COURSE_VIEW.UPDATE.COURSE_LECTURE_GROUP_ORDERING_FORM_LABEL': 'Ordinal number',
  'COURSE_VIEW.UPDATE.LECTURE_TITLE_FORM_LABEL': 'Lesson title',
  'COURSE_VIEW.UPDATE.LECTURE_ORDERING_FORM_LABEL': 'Lesson number',
  'COURSE_VIEW.UPDATE.LECTURE_VIDEO_FORM_LABEL': 'Video content link',
  'COURSE_VIEW.UPDATE.LECTURE_CONTENT_FORM_LABEL': 'Lesson contents',
  'COURSE_VIEW.UPDATE.COURSE_CONTENT_EDIT_LABEL': 'Edit',
  'COURSE_VIEW.UPDATE.COURSE_CONTENT_PREVIEW_LABEL': 'Preview',
  'COURSE_VIEW.UPDATE.LECTURE_FILES_LABEL': 'Lesson materials',
  'COURSE_VIEW.UPDATE.KNOWLEDGE_BASE_BUTTON': 'Add from knowledge base',
  'COURSE_VIEW.UPDATE.VISIBILITY_LABEL': 'Course visibility',
  'COURSE_VIEW.UPDATE.VISIBILITY_REQUIRED_LABEL': 'Select visibility',
  'COURSE_VIEW.UPDATE.VISIBILITY_TITLE_PUBLIC': 'Public',
  'COURSE_VIEW.UPDATE.VISIBILITY_TITLE_PRIVATE': 'Private',
  'COURSE_VIEW.UPDATE.VISIBILITY_TITLE_LIMITED': 'Limited',
  'COURSE_VIEW.UPDATE.LECTURE_DELETED_MESSAGE': '%{lecture} has been deleted',
  'COURSE_VIEW.COURSE_DELETED_MESSAGE': 'Course has been deleted',
  'COURSE_VIEW.CREATE_NEW_NOTE': 'Create new note',
  'COURSE_VIEW.NOTES': 'Notes',
  'COURSE_VIEW.COMMENTS': 'Comments',
  'COURSE_VIEW.PUBLIC_LECTURE_TOOLTIP': 'You may view lecture content after you enroll in course.',
  'COURSE_VIEW_PUBLIC.TITLE': 'Course catalog',
  'COURSE_VIEW_PUBLIC.ALL_COURSEGROUPS_LABEL': 'All course groups',
  'COURSE_VIEW_PUBLIC.ALL_STATUSES_LABEL': 'All statuses',
  'COURSE_VIEW.ENROLL_MESSAGE': 'You have successfully enrolled in course %{courseName}.',
  'COURSE_VIEW.COURSE_COMPLETED_MESSAGE': 'Congratulations! You finished %{courseName}. You can download your certificate of completion on your profile.',
  // errors
  'COURSE_VIEW.MESSAGE.INVALID_QUIZ_CONTENT_ERROR': 'Invalid quiz content [%{error}]',
  'LECTURE_CREATE.ERROR.LECTURE_ALREADY_EXISTS': 'Lecture already exists',
  'LECTURE_CREATE.ERROR.LECTURE_GROUP_CREATE.ERROR': 'Lecture group already exists',

  // ---------- Comments view
  'COMMENTS_VIEW.DELETE_COMMENT': 'Delete comment',
  'COMMENTS_VIEW.EDIT_COMMENT': 'Edit comment',
  'COMMENTS_VIEW.CLOSE_EDIT_SECTION': 'Close editor',
  'COMMENTS_VIEW.ACTION_SORT_POPULAR': 'Popular',
  'COMMENTS_VIEW.ACTION_SORT_LABEL': 'Sort comments by',
  'COMMENTS_VIEW.APPROVAL_INFO_MESSAGE': 'Awaiting approval',
  'COMMENTS_VIEW.APPROVED_INFO_MESSAGE': 'Successfully approved comment "%{content}", %{firstName} %{lastName}',
  'COMMENTS_VIEW.COMMENT_ADDED_MESSAGE': 'Comment has been added',
  'COMMENTS_VIEW.COMMENT_DELETED_MESSAGE': 'Comment has been deleted',

  // ---------- User View
  'USER_VIEW.WORK_POSITION_LABEL': 'Job title',
  'USER_VIEW.WORK_SATRT_DATE_LABEL': 'Work start date',
  'USER_VIEW.DATE_OF_BIRTH_LABEL': 'Date of birth',
  'USER_VIEW.ADDRESS_LABEL': 'Address',
  'USER_VIEW.EDIT_USER': 'Edit user',
  'USER_VIEW.DELETE_USER': 'Delete user',
  'USER_VIEW.COURSES_TAB_LABEL': 'Courses',
  'USER_VIEW.ACTIVITIES_TAB_LABEL': 'Activities',
  'USER_VIEW.COORDINATOR_EDUCATION_APPLICATION_TAB_LABEL': 'Applications I process',
  'USER_VIEW.TRAINEE_EDUCATION_APPLICATION_TAB_LABEL': 'My applications',
  'USER_VIEW.DOCUMENTS_TAB_LABEL': 'Documents',
  'USER_VIEW.SKILLS_TAB_LABEL': 'Skills',
  'USER_VIEW.ORGANIZATIONS_TAB_LABEL': 'Organizations',
  'USER_VIEW.DOCUMENTS_TABLE_DOCUMENT_LABEL': 'Document name',
  'USER_VIEW.DOCUMENTS_TABLE_AUTHOR_LABEL': 'Author',
  'USER_VIEW.DOCUMENTS_TABLE_TIME_LABEL': 'Creation time',
  'USER_VIEW.USER_DELETED_LABEL': 'User deleted',
  'USER_VIEW.EXTERNAL_EDUCATION_TEMPLATES_TAB_LABEL': 'External education templates I process',

  // ---------- User Container View
  'USER_VIEW_CONTAINER.CREATE_USER': 'Create user',

  // --------- User notes
  'USER_NOTES.CREATE_NOTE': 'Create note',

  // ---------- Notes List View
  'NOTE_LIST.MY_NOTES_LABEL': 'My notes',
  'NOTE_LIST.NOTE_TYPE.COURSE': 'Course',
  'NOTE_LIST.NOTE_TYPE.WEBINAR': 'Webinar',
  'NOTE_LIST.NOTE_TYPE.EXAM_TEMPLATE': 'Exam template',
  'NOTE_LIST.NOTE_MODAL_LABEL': 'Edit note',
  'NOTE_CONTAINER.SUCCESS_CREATE_INFO_MESSAGE': 'The note has been successfully created, by clicking on "My notes" you can access the list of created notes',
  // tslint:disable-next-line: quotemark
  'NOTE_LIST.NO_DATA': "You don't have notes",
  'NOTE_LIST.EXPORT_BUTTON_LABEL': 'Export note list',
  'NOTE_LIST.CREATE_ERROR': 'There are currently no downloadable notes.',
  // ---------- Notes List Filter
  'NOTE_LIST_FILTER.SORT_BY_DATE_ADDED_LABEL': 'Sort by',

  // ---------- User List Filter
  'USER_LIST_FILTER.ALL_USERS': 'All users',
  'USER_LIST_FILTER.ONLY_ACTIVE_USERS': 'Active users',
  'USER_LIST_FILTER.INPUT_LENGTH': 'Minimum 3 characters required',
  'USER_LIST.TRAINEE_REPORTS': 'Trainee report',
  'USER_LIST_FILTER.NAME_LABEL': 'Name',
  'USER_LIST_FILTER.ASSIGNED_DATE_FROM_LABEL': 'Course assigned (from)',
  'USER_LIST_FILTER.ASSIGNED_DATE_TO_LABEL': 'Course assigned (to)',
  'USER_LIST_FILTER.USER_GROUPS_LABEL': 'User groups',

  // ---------- Course group list
  'COURSE_GROUP_LIST.VIEW_TITLE': 'Course groups',
  'COURSE_GROUP_LIST.CREATE_GROUP_TITLE': 'Add new course group',
  'COURSE_GROUP_LIST.EDIT_GROUP_TITLE': 'Course group',
  'COURSE_GROUP.DELETED_MESSAGE': 'Course group has been deleted',

  // ---------- Course list
  'COURSE_LIST.VIEW_TITLE': 'Courses',
  'COURSE_LIST.NO_DATA': 'No courses available',
  'COURSE_LIST.UNGROUPED_TITLE': 'Ungrouped courses',
  'COURSE_LIST.ACTION_CREATE': 'New course',
  'COURSE_LIST.ACTION_GROUPS_EDIT': 'Edit course groups',

  'COURSE_LIST_STARTED.VIEW_TITLE': 'Courses in progress',
  'COURSE_CONTINUE.VIEW_TITLE': 'Continue watching',
  'COURSE_LIST_STARTED.NO_DATA': 'No started courses',

  'COURSE_LIST.COURSE_STATUS_TO_DO': 'Not enrolled',
  'COURSE_LIST.COURSE_STATUS_IN_PROGRESS': 'In progress',
  'COURSE_LIST.COURSE_STATUS_COMPLETED': 'Completed',
  'COURSE_LIST.ADD_COURSES_LABEL': 'Assign a course',
  'COURSE_LIST.EDIT_COURSES_LABEL': 'Edit user courses',

  'COURSE_LIST_EDIT_FORM.ADD_TITLE_LABEL': 'Assign a course',
  'COURSE_LIST_EDIT_FORM.EDIT_TITLE_LABEL': 'Edit user courses',
  'COURSE_LIST_EDIT_FORM.UNASSIGNED_COURSES_LABEL': 'Unassigned',
  'COURSE_LIST_EDIT_FORM.ASSIGNED_COURSES_LABEL': 'Assigned',
  'COURSE_LIST_EDIT_FORM.DEADLINE_DATE_LABEL': 'Deadline',
  'COURSE_LIST_EDIT_FORM.DEADLINE_DATE_PLACEHOLDER': 'Enter the deadline for course completion',

  'COURSE_LIST_FILTER.TRAINEE_STATS_FROM_LABEL': 'Start date',
  'COURSE_LIST_FILTER.TRAINEE_STATS_TO_LABEL': 'End date',
  'COURSE_LIST_FILTER.USER_GROUPS_LABEL': 'User groups',

  // ---------- Course progress info
  'COURSE_PROGRESS_INFO.DEADLINE_DATE_TOOLTIP_LABEL': 'Deadline date',
  'COURSE_PROGRESS_INFO.EARNED_POINTS_TOOLTIP_LABEL': 'Earned points',

  // ---------- File upload
  'FILE_UPLOAD.MODAL_TITLE': 'File upload',
  'FILE_UPLOAD.MODAL_UPLOAD_BUTTON': 'Upload from computer',
  'FILE_UPLOAD.DROPZONE_TEXT': 'Click or drag files here to upload',
  'FILE_UPLOAD.ERROR_FILE_SIZE': 'Largest allowed file size is %{maxFileSize}!',
  'FILE_UPLOAD.ERROR_FILE_TYPE': 'Invalid file type!',
  'FILE_UPLOAD.IMAGE.ERROR_FILE_TYPE': 'Only .jpg and .png files are allowed!',
  'FILE_UPLOAD.VIDEO.ERROR_FILE_TYPE': 'Only video files are allowed!',

  'FILE_UPLOAD.FILE_ADDED_MESSAGE': 'File %{filename} added',
  'FILE_UPLOAD.FILE_REMOVED_MESSAGE': 'File %{filename} deleted',
  'FILE_UPLOAD.FILE_UPLOAD_ERROR_MESSAGE': 'Error uploading file %{filename}',
  'FILE_UPLOAD.TOOLTIP_MESSAGE': 'For best result upload an image 600-800 pixels wide that has less height than its width. For profile pictures use images of same length and width.',

  // ---------- Video upload
  'INTEGRATION_VIDEO.FORM.MODAL_TITLE': 'Video upload',
  'INTEGRATION_VIDEO.FORM.IMPORT_VIDEO_TITLE': 'Import video ID',
  'INTEGRATION_VIDEO.FORM.UPLOAD_VIDEO_TITLE': 'Upload video file',
  'INTEGRATION_VIDEO.FORM.ADD_VIDEO': 'Add video',
  'INTEGRATION_VIDEO.UPLOAD.DROPZONE_TEXT': 'Click or drag files here to upload',
  'INTEGRATION_VIDEO.UPLOAD.UPLOAD_BUTTON': 'Upload from computer',
  'INTEGRATION_VIDEO.IMPORT.IMPORT_BUTTON': 'Import video',

  'INTEGRATION_VIDEO.MESSAGE.ERROR_FILE_SIZE': 'Largest allowed file size is %{maxFileSize}!',
  'INTEGRATION_VIDEO.MESSAGE.ERROR_FILE_TYPE': 'Only video files are allowed!',
  'INTEGRATION_VIDEO.MESSAGE.UPLOAD_SUCCESSFUL': 'Video uploaded',
  'INTEGRATION_VIDEO.MESSAGE.UPLOAD_ERROR': 'Error uploading video',
  'INTEGRATION_VIDEO.MESSAGE.IMPORT_ERROR': 'Error importing video',
  'INTEGRATION_VIDEO.MESSAGE.UPLOAD_IN_PROGRESS': 'Video upload is still in progress. Are you sure you want to end it?',

  'INTEGRATION_VIDEO.ERROR.INTEGRATION_ERROR': 'Error preparing video upload', // smthng broke while creating placeholder
  'INTEGRATION_VIDEO.ERROR.UNSUPPORTED_INTEGRATION': 'Video upload not supported',
  'INTEGRATION_VIDEO.ERROR.NON_EXISTING_INTEGRATION_OBJECT': 'Requested video does not exist',

  // ---------- Users List
  'USER_LIST.USERS_TITLE': 'Users',
  'USER_LIST.TRAINEES_TITLE': 'Trainees',
  'USER_LIST.USER_LABEL': 'User',
  'USER_LIST.WORKPOSITION_LABEL': 'Work position',
  'USER_LIST.COMPLETED_COURSES_LABEL': 'Completed courses',
  'USER_LIST.COURSE_STATUS_LABEL': 'Status',
  'USER_LIST.COURSE_DEADLINE_LABEL': 'Deadline',

  // ---------- Users management list
  'USER_MANAGEMENT_LIST_VIEW.USER_ROLE_TRAINEE': 'Trainee',
  'USER_MANAGEMENT_LIST_VIEW.USER_ROLE_SUPERADMIN': 'Superadmin',
  'USER_MANAGEMENT_LIST_VIEW.USER_ROLE_ORGANIZATION_ADMIN': 'Organization admin',
  'USER_MANAGEMENT_LIST_VIEW.USER_ROLE_COURSE_CREATOR': 'Course creator',
  'USER_MANAGEMENT_LIST_VIEW.USER_ROLE_ORGANIZER': 'Organizer',
  'USER_MANAGEMENT_LIST_VIEW.USER_ROLE_ORGANIZATION_MEMBER': 'Organization member',
  'USER_MANAGEMENT_LIST_VIEW.USER_ROLE_COORDINATOR': 'Coordinator',
  'USER_MANAGEMENT_LIST_VIEW.USER_IDENTIFICATION_NUMBER_TITLE': 'OIB',
  'USER_MANAGEMENT_LIST_VIEW.USER_EMAIL_TITLE': 'Email',
  'USER_MANAGEMENT_LIST_VIEW.USER_FIRST_NAME_TITLE': 'First name',
  'USER_MANAGEMENT_LIST_VIEW.USER_LAST_NAME_TITLE': 'Last Name',
  'USER_MANAGEMENT_LIST_VIEW.USER_AVATAR_TITLE': 'Avatar',
  'USER_MANAGEMENT_LIST_VIEW.USER_ROLES_TITLE': 'Roles',
  'USER_MANAGEMENT_LIST_VIEW.SHOW_USER_PROFILE_ACTION_BUTTON_TITLE': 'Show profile',
  // ---------- Users management list filter
  'ADMIN_USER_MANAGEMENT_LIST_FILTER.OIB_RULE_MESSAGE': 'OIB contain 11 numbers',
  'ADMIN_USER_MANAGEMENT_LIST_FILTER.NAME_LABEL': 'By first or/and last name ',
  'ADMIN_USER_MANAGEMENT_LIST_FILTER.OIB_LABEL': 'By oib-u',
  'ADMIN_USER_MANAGEMENT_LIST_FILTER.ROLE_LABEL': 'By user role',

  // ---------- User Form
  'USER_FORM.FORM_TITLE_CREATE': 'Create user',
  'USER_FORM.FORM_TITLE_EDIT': 'Edit user',
  'USER_FORM.STATUS_LABEL': 'Status',
  'USER_FORM.ACTIVE_LABEL': 'Active',
  'USER_FORM.INACTIVE_LABEL': 'Inactive',
  'USER_FORM.FIRST_NAME_LABEL': 'First name',
  'USER_FORM.FIRST_NAME_PLACEHOLDER': 'Enter first name',
  'USER_FORM.LAST_NAME_LABEL': 'Last name',
  'USER_FORM.LAST_NAME_PLACEHOLDER': 'Enter last name',
  'USER_FORM.GENDER_LABEL': 'Gender',
  'USER_FORM.GENDER_LABEL_M': 'Male',
  'USER_FORM.GENDER_LABEL_F': 'Female',
  'USER_FORM.DATE_OF_BIRTH_LABEL': 'Date of birth',
  'USER_FORM.DATE_OF_BIRTH_PLACEHOLDER': 'Enter date of birth',
  'USER_FORM.ADDRESS_STREET_WITH_NUMBER_LABEL': 'Street name and home number',
  'USER_FORM.ADDRESS_STREET_WITH_NUMBER_PLACEHOLDER': 'Enter street name and home number',
  'USER_FORM.IDENTIFICATION_NUMBER_LABEL': 'Identification number',
  'USER_FORM.IDENTIFICATION_NUMBER_PLACEHOLDER': 'Enter identification number',
  'USER_FORM.ADDRESS_LABEL': 'Address',
  'USER_FORM.ADDRESS_ZIP_LABEL': 'Postcode',
  'USER_FORM.ADDRESS_ZIP_PLACEHOLDER': 'Enter postcode',
  'USER_FORM.ADDRESS_CITY_LABEL': 'City',
  'USER_FORM.ADDRESS_CITY_PLACEHOLDER': 'Enter city',
  'USER_FORM.ADDRESS_COUNTRY_LABEL': 'Country',
  'USER_FORM.ADDRESS_COUNTRY_PLACEHOLDER': 'Enter country',
  'USER_FORM.CONTACT_DATA_LABEL': 'Contact',
  'USER_FORM.ADD_CONTACT_DATA_LABEL': 'Add contact information',
  'USER_FORM.GDPR_LABEL': 'GDPR',
  'USER_FORM.GDPR_ACCEPTED': 'I confirm that I\'m over 16 years of age and accept the Terms of Use and Privacy Policy',
  'USER_FORM.WORK_POSITION_NAME_LABEL': 'Job title',
  'USER_FORM.WORK_POSITION_NAME_PLACEHOLDER': 'Choose job title',
  'USER_FORM.WORK_POSITION_START_DATE_LABEL': 'Work start date',
  'USER_FORM.WORK_POSITION_START_DATE_PLACEHOLDER': 'Enter work start date',
  'USER_FORM.EMAIL_LABEL': 'E-mail address',
  'USER_FORM.PHONE_LABEL': 'Phone',
  'USER_FORM.EMAIL_PLACEHOLDER': 'Enter e-mail address',
  'USER_FORM.PHONE_PLACEHOLDER': 'Enter phone number',
  'USER_FORM.EMAIL_VALIDATION_TEXT': 'Enter correct e-mail address',
  'USER_FORM.USERNAME_LABEL': 'Username',
  'USER_FORM.USERNAME_PLACEHOLDER': 'Enter username',
  'USER_FORM.USERNAME_MINIMUM_LENGTH_VALIDATION_MESSAGE': 'Username must contain at least 8 characters',
  'USER_FORM.PASSWORD_LABEL': 'Password',
  'USER_FORM.PASSWORD_PLACEHOLDER': 'Enter password',
  'USER_FORM.CONFIRM_PASSWORD_LABEL': 'Confirm password',
  'USER_FORM.CONFIRM_PASSWORD_PLACEHOLDER': 'Enter password confirmation',
  'USER_FORM.PASSWORD_MINIMUM_LENGTH_VALIDATION_MESSAGE': 'Password must contain at least 8 characters',
  'USER_FORM.PASSWORDS_DIFFERENT_ERROR': 'Passwords do not match',
  'USER_FORM.ROLES_LABEL': 'Role',
  'USER_FORM.ROLES_PLACEHOLDER': 'User role',
  'USER_FORM.ROLE_SUPERADMIN_LABEL': 'Main administrator',
  'USER_FORM.ROLE_ORGANIZATION_ADMIN_LABEL': 'Organisational administrator',
  'USER_FORM.ROLE_COURSE_CREATOR_LABEL': 'Course creator',
  'USER_FORM.ROLE_TRAINEE_LABEL': 'Trainee',
  'USER_FORM.ROLE_ORGANIZER_LABEL': 'Organizer',
  'USER_FORM.ROLE_ORGANIZATION_MEMBER_LABEL': 'Organization member',
  'USER_FORM.ROLE_COORDINATOR_LABEL': 'Coordinator',
  'USER_FORM.ACTIVITY_PLACEHOLDER': 'Account',
  'USER_FORM.ACTIVITY_ALL': 'All',
  'USER_FORM.ACTIVITY_ACTIVE_ONLY': 'Active',
  // error message
  'USER_FORM.ERROR_MESSAGE.USER_CREATE_OR_UPDATE': 'Error saving data',

  // ---------- User evaluation list
  'USER_SKILL_EVALUATION_LIST_CONTAINER.BUTTON_ADD_SKILL_LABEL': 'Assign skill',
  'SKILL_EVALUATION_LIST.ASSIGNED_SKILLS_LABEL': 'Assigned skills',
  'SKILL_EVALUATION_LIST.ASSIGNED_LEVEL_LABEL': 'Assigned skill level',
  'SKILL_EVALUATION_LIST.EVALUATOR_NAME_LABEL': 'Evaluator',
  'SKILL_EVALUATION_LIST.EVALUATION_TYPE_LABEL': 'Evaluation type',
  'SKILL_EVALUATION_LIST.DATE_LABEL': 'Date',
  'SKILL_EVALUATION.TYPE.PEER': 'Peer',
  'SKILL_EVALUATION.TYPE.HUMAN_RESOURCES': 'Human resources',
  'SKILL_EVALUATION.TYPE.MANAGER': 'Manager',
  'SKILL_EVALUATION.TYPE.SELF': 'Self',
  'USER_SKILL_EVALUATION.FORM.ASSIGN_SKILL_LABEL': 'Pick skill and skill level',
  'USER_SKILL_EVALUATION.MODAL.CREATE_SKILL_TITLE': 'Assign user skill level',
  'USER_SKILL_EVALUATION.MODAL.EDIT_SKILL_TITLE': 'Edit skill level',
  'USER_SKILL_EVALUATION.NOTIFICATION.USER_SKILL_EVALUATION_CREATED': 'Successful assigned skill',
  'USER_SKILL_EVALUATION.NOTIFICATION.USER_SKILL_EVALUATION_DELETED': 'Successful deleted skill',
  'USER_SKILL_EVALUATION.RADAR_CHART_TITLE': 'User skills comparison with work position',
  // --- error message
  'USER_SKILL_EVALUATION_CREATE.ERROR.USER_SKILL_EVALUATION_NOT_ALLOWED': 'You are not allowed to assing skill level',

  // ---------- Reset Password & Request
  'RESET_PASSWORD_REQUEST.INFO_TEXT_1': 'Request for new password sent to e-mail address',
  'RESET_PASSWORD_REQUEST.INFO_TEXT_2': 'Check your emails and follow the instructions',
  'RESET_PASSWORD_REQUEST.EMAIL_LABEL': 'E-mail',
  'RESET_PASSWORD_REQUEST.EMAIL_PLACEHOLDER': 'Enter your e-mail address',
  'RESET_PASSWORD_REQUEST.RESET_PASSWORD_BUTTON_LABEL': 'Submit a new password request',
  'RESET_PASSWORD_REQUEST.EMAIL_VALIDATION_TEXT': 'Please enter a valid email address',

  'RESET_PASSWORD.MISSING_TOKEN': 'Invalid request for new password',
  'RESET_PASSWORD.INVALID_TOKEN': 'New password request expired',
  'RESET_PASSWORD.SEND_NEW_REQUEST': 'Send a new request',
  'RESET_PASSWORD.RESET_DONE': 'New password successfully activated',

  // ---------- User registration & activation
  'REGISTRATION.CHECK_BOX_TERMS_OF_USE': 'Terms of use',
  'REGISTRATION.CHECK_BOX_GDPR_DESCRIPTION': 'By clicking on the "Create an account" button, you confirm that you are over 16 years of age, legally capable and authorized to dispose of the given data.',
  'REGISTRATION.INFO_TEXT_1': 'Account request sent to email address',
  'REGISTRATION.INFO_TEXT_2': 'Check your emails and follow the instructions',
  'REGISTRATION.EMAIL_LABEL': 'Email address to verify your account and sign in',
  'REGISTRATION.NEW_PASSWORD_LABEL': 'Password',
  'REGISTRATION.CONFIRM_PASSWORD_LABEL': 'Confirm password',
  'REGISTRATION.NEW_PASSWORD_PLACEHOLDER': 'Enter password',
  'REGISTRATION.CONFIRM_PASSWORD_PLACEHOLDER': 'Enter password',
  'REGISTRATION.USERNAME_ALREADY_IN_USE': 'Email address is already in use. Enter another email address.',
  'ACTIVATION.MISSING_TOKEN': 'Invalid request for account activation',
  'ACTIVATION.INVALID_TOKEN': 'Account activation request expired',
  'ACTIVATION.SEND_NEW_REQUEST': 'Create a new activation request',
  'ACTIVATION.ACTIVATION_DONE': 'Account successfully activated',
  'ACTIVATION.USER_LINK': 'Fill in your profile so you can request a voucher',
  'REGISTRATION.BUTTON_SUBMIT_LABEL': 'Create an account',

  // ---------- Change Password
  'CHANGE_PASSWORD.VIEW_TITLE': 'Change password',
  'CHANGE_PASSWORD.OLD_PASSWORD_LABEL': 'Old password',
  'CHANGE_PASSWORD.OLD_PASSWORD_PLACEHOLDER': 'Enter old password',
  'CHANGE_PASSWORD.NEW_PASSWORD_LABEL': 'New password',
  'CHANGE_PASSWORD.NEW_PASSWORD_PLACEHOLDER': 'Enter new password',
  'CHANGE_PASSWORD.CONFIRM_PASSWORD_LABEL': 'Confirm new password',
  'CHANGE_PASSWORD.CONFIRM_PASSWORD_PLACEHOLDER': 'Enter new password again',
  'CHANGE_PASSWORD.CHANGE_PASSWORD_BUTTON_LABEL': 'Change password',
  'CHANGE_PASSWORD.MINIMUM_LENGTH_VALIDATION_MESSAGE': 'Password must contain at least 8 characters',
  'CHANGE_PASSWORD.PASSWORD_NOT_NEW_ERROR': 'New password can not be the same as old password',
  'CHANGE_PASSWORD.PASSWORDS_DIFFERENT_ERROR': 'Passwords do not match',
  'CHANGE_PASSWORD.CHANGE_DONE': 'Password successfully changed',
  'CHANGE_PASSWORD.ERROR_MESSAGE.BAD_CREDENTIALS': 'Incorrect old password',
  'CHANGE_PASSWORD.ERROR_MESSAGE.CHANGE_PASSWORD': 'Error while changing password',

  // ---------- Reports
  'REPORTS_NAVIGATION.COURSES_MENU_LABEL': 'Courses reports',
  'REPORTS_NAVIGATION.USERS_MENU_LABEL': 'Trainee reports',
  'REPORTS_NAVIGATION.REPORTS_MENU_LABEL': 'Reports download',
  'REPORTS_NAVIGATION.EDUCATION_APPLICATION_MENU_LABEL': 'Applications',
  'REPORTS_COURSES.NO_ATTENDEES_MESSAGE': 'This course has no trainees',

  'COURSES_REPORT.TODO_LABEL': 'To do',
  'COURSES_REPORT.INPROGRESS_LABEL': 'In progress',
  'COURSES_REPORT.COMLETED_LABEL': 'Completed',

  'REPORTS_LIST.TABLE_EMPTY': 'No available reports, request a new one',
  'REPORTS_LIST.TABLE_UNFINISHED_REPORT': 'Creating report...',
  'REPORTS_LIST.TABLE_FAILED_REPORT': 'Creating report failed',
  'REPORTS_LIST.TABLE_FILENAME_LABEL': 'File name',
  'REPORTS_LIST.TABLE_TYPE_LABEL': 'Report type',
  'REPORTS_LIST.TABLE_REQUESTED_LABEL': 'Requested by',
  'REPORTS_LIST.TABLE_DATETIME_LABEL': 'Request date and time',
  'REPORTS_LIST.DOWNLOAD': 'Download',
  'REPORTS_LIST.DELETE': 'Delete',
  'REPORTS_LIST.TYPE_COURSE': 'Individual course report',
  'REPORTS_LIST.TYPE_GENERAL_COURSE': 'All courses report',
  'REPORTS_LIST.TYPE_COURSE_TRAINEE': 'All trainee reports',
  'REPORTS_LIST.CREATE_REPORT_BUTTON_LABEL': 'Request report',
  'REPORTS_LIST.COURSES_MODAL_PICKER': 'Choose a course',
  'REPORTS_LIST.TYPE_EXTERNAL_EDUCATION_APPLICATION': 'Education application report',
  'REPORTS_LIST.EDUCATION_APPLICATION_MODAL_PICKER': 'Choose a external application',
  // info message
  'REPORTS.CREATE_INFO_MESSAGE': 'You have requested a report. Its creation may take some time. To verify that the report was created, click the refresh report list button.',
  'REPORTS.DELETED_MESSAGE': 'Report has been deleted',
  // error message
  'REPORTS_LIST.ERROR_MESSAGE.REPORT_NOT_CREATED': 'Error deleting, report not created',

  // ---------- Administration
  'ADMINISTRATION_NAVIGATION.USERS_MENU_LABEL': 'Users',
  'ADMINISTRATION_NAVIGATION.USERS_MANAGEMENT_MENU_LABEL': 'Users management',
  'ADMINISTRATION_NAVIGATION.WORKPOSITION_MENU_LABEL': 'Manage job positions',
  'ADMINISTRATION_NAVIGATION.APPROVAL_COMMENT_MENU_LABEL': 'Approval of comments',
  'ADMINISTRATION_NAVIGATION.RESULTS_MENU_LABEL': 'Exam results',
  'ADMINISTRATION_NAVIGATION.NOTIFICATIONS_MENU_LABEL': 'Notifications',
  'ADMINISTRATION_NAVIGATION.USER_ACTIVITIES_MENU_LABEL': 'Platform activity',
  'ADMINISTRATION_NAVIGATION.EMAIL_TEMPLATES_MENU_LABEL': 'E-mail templates',
  'ADMINISTRATION_NAVIGATION.TENANT_MENU_LABEL': 'Tenant',
  'ADMINISTRATION_NAVIGATION.TENANT_ORGANIZATION_MENU_LABEL': 'System settings',
  'ADMINISTRATION_NAVIGATION.ORGANIZATIONS_MENU_LABEL': 'Organizations',
  'ADMINISTRATION_NAVIGATION.USER_GROUPS_MENU_LABEL': 'User groups',
  'ADMINISTRATION_NAVIGATION.SKILL_GROUPS_MENU_LABEL': 'Skills',
  'ADMINISTRATION_NAVIGATION.USER_GROUPS': 'User groups',
  'ADMINISTRATION_NAVIGATION.SURVEY_TEMPLATE_MENU_LABEL': 'Survey templates',
  'ADMINISTRATION_NAVIGATION.CODEBOOKS_MENU_LABEL': 'Codebooks',
  'ADMINISTRATION.WORKPOSITION_UPDATE_COURSES_BUTTON_LABEL': 'Add courses',
  'ADMINISTRATION.WORKPOSITION_UPDATE_NAME_LABEL': 'Name',
  'ADMINISTRATION.WORKPOSITION_UPDATE_MODAL_TITLE': 'Edit workplace',
  'ADMINISTRATION.WORKPOSITION_UPDATE_SKILLS_PANEL_LABEL': 'Skills',
  'ADMINISTRATION.WORKPOSITION_UPDATE_ADD_SKILL_BUTTON_LABEL': 'Add skill',
  'ADMINISTRATION.CONTAINER_CREATE_WORKPOSITION': 'New job position',
  'ADMINISTRATION.UPDATE_WORKPOSITION': 'Job position',
  'ADMINISTRATION.WORKPOSITION_CRREATED_MESSAGE': 'Workposition has been created',
  'ADMINISTRATION_NAVIGATION.SKILLS_GAP_MENU_LABEL': 'Recommendations',

  // ---------- User Activities
  'USER_ACTIVITY.TYPE.LEFT_COMMENT': 'Comments',
  'USER_ACTIVITY.TYPE.COMPLETED_COURSE': 'Course finished',
  'USER_ACTIVITY.TYPE.ADDED_VOTE': 'Votes',
  'USER_ACTIVITY.TYPE.CHANGED_VOTE': 'Changed votes',
  'USER_ACTIVITY.TYPE.REMOVED_VOTE': 'Removed votes',
  'USER_ACTIVITY.TYPE.CREATED_REMINDER': 'Reminders',
  'USER_ACTIVITY.LIST_LABEL': 'Platform activites',
  'USER_ACTIVITY.LIST_EMPTY': 'No recent activities',

  // ---------- User Activities List
  'USER_ACTIVITY_LIST.EMPTY_DATA_LABEL': 'No activity',
  'USER_ACTIVITY_LIST.TIME_COLUMN_TITLE': 'Date',
  'USER_ACTIVITY_LIST.TIME_TARGET_USER_COLUMN_TITLE': 'Recipient',
  'USER_ACTIVITY_LIST.ACTIVITY_COLUMN_TITLE': 'Activity',
  'USER_ACTIVITY_LIST.TIMELINE_ACTION.LEFT_COMMENT': 'left a comment on',
  'USER_ACTIVITY_LIST.TIMELINE_ACTION.COMPLETED_COURSE': 'finished course',
  'USER_ACTIVITY_LIST.TIMELINE_ACTION.CREATED_REMINDER': 'has created a reminder.',
  'USER_ACTIVITY_LIST.TIMELINE_ACTION.ADDED_VOTE': 'has voted on',
  'USER_ACTIVITY_LIST.TIMELINE_ACTION.CHANGED_VOTE': 'has changed vote on',
  'USER_ACTIVITY_LIST.TIMELINE_ACTION.REMOVED_VOTE': 'has removed vote on',
  'USER_ACTIVITY_LIST.USER': 'User',

  // ---------- Activity filter
  'USER_ACTIVITY_LIST_FILTER.USER_LABEL': 'User',
  'USER_ACTIVITY.TYPE.ALL_TYPES': 'All activities',
  'USER_ACTIVITY_LIST_FILTER.START_DATE_LABEL': 'Date from',
  'USER_ACTIVITY_LIST_FILTER.END_DATE_LABEL': 'Date to',
  'USER_ACTIVITY_LIST_FILTER.ACTIVITY_TYPE_LABEL': 'Type',
  'USER_ACTIVITY_LIST_FILTER.USER_NAME_PLACEHOLDER': 'Enter first or last name',

  // ---------- Diagrams
  'DIAGRAMS.ACTION_EDIT': 'Edit diagram',
  'DIAGRAMS.ACTION_CREATE': 'New diagram',

  // ---------- Education group list
  'EDUCATION_GROUP_LIST.VIEW_TITLE': 'Education group list',
  'EDUCATION_GROUP_LIST.CREATE_GROUP_TITLE': 'Add new education group',
  'EDUCATION_GROUP_LIST.GROUP_TITLE': 'Education group',

  // ---------- Education group
  'EDUCATION_GROUP.CURRICULUM': 'Curriculum',
  'EDUCATION_GROUP.CHECK': 'Check',
  'EDUCATION_GROUP.ADD_EXAM_TEMPLATE': 'Exam template',
  'EDUCATION_GROUP.ADD_COURSE': 'Add course',
  'EDUCATION_GROUP.ITEM.TYPE': 'Item type',
  'EDUCATION_GROUP.ITEM.TYPE.EXAM_TEMPLATE': 'Exam template',
  'EDUCATION_GROUP.ITEM.TYPE.COURSE': 'Course',
  'EDUCATION_GROUP.ITEM.TYPE.EXAM_INSTANCE': 'Exam instance',
  'EDUCATION_GROUP.ITEM.TITLE': 'Title',

  // ---------- Exam template
  'EXAM_TEMPLATE.EXAM_TEMPLATE_HEADER_LABEL': 'Exam template',
  'EXAM_TEMPLATE.CREATE_TITLE': 'Quiz: %{examTemplateTitle}',
  'EXAM_TEMPLATE.TITLE': 'Exam template title',
  'EXAM_TEMPLATE.VIEW_QUESTION_LIST': 'View question list',
  'EXAM_TEMPLATE.VIEW_ASSIGNED_EXAMS': 'View assigned exams',
  'EXAM_TEMPLATE.GENERATE_EXAMS': 'Generate exam',
  'EXAM_TEMPLATE.GENERATE_EXAMS_INFO_MESSAGE': 'You need to add users to generate the exam',
  'EXAM_TEMPLATE.EDIT_BUTTON_LABEL': 'Edit exam template',
  'EXAM_TEMPLATE.MODAL_TITLE': 'Exam template %{examTemplateTitle}',
  'EXAM_TEMPLATE.MODAL_INSTRUCTION_TITLE': 'Enter instruction',
  'EXAM_TEMPLATE.MODAL_SETTINGS_NUMBER_OF_QUESTIONS_TITLE': 'Number of questions to solve',
  'EXAM_TEMPLATE.MODAL_SETTINGS_ANSWERS_VISIBLE_TITLE': 'Show answers',
  'EXAM_TEMPLATE.MODAL_SETTINGS_SKIP_EVALUATION_TITLE': 'Skip evaluation phase',
  'EXAM_TEMPLATE.MODAL.OUTCOME': 'Outcome',
  'EXAM_TEMPLATE.MODAL.MAXIMUM_NUMBER_OF_QUESTIONS': 'Maximum number of questions',
  'EXAM_TEMPLATE.MODAL.NUMBER_OF_QUESTIONS_IN_EXAM': 'Number of questions in exam',
  'EXAM_TEMPLATE.MODAL.CREATE_QUESTION_LIST': 'Create question list',
  'EXAM_TEMPLATE.MODAL.ALLOWED_NUMBER_MESSAGE': 'Allowed number of questions is from %{min} to b%{max}',
  'EXAM_TEMPLATE.OUTCOME': 'Outcome',
  'EXAM_TEMPLATE.EXAM_STATUS_FINISHED_INFO_LABEL': 'Course is published',

  'EXAM_TEMPLATE.STATISTICS.CORRECTNESS_PERCENTAGE_CORRECT': 'Correct',
  'EXAM_TEMPLATE.STATISTICS.CORRECTNESS_PERCENTAGE_INCORRECT': 'Incorrect',
  'EXAM_TEMPLATE.STATISTICS.STATISTICS_TITLE': 'Quiz statistics',
  'EXAM_TEMPLATE.STATISTICS.SUBMITED_COUNT_TITLE': 'Submited quizzes count',
  'EXAM_TEMPLATE.STATISTICS.AVERAGE_COMPLETION_TIME_TITLE': 'Average completion time',
  'EXAM_TEMPLATE.STATISTICS.QUESTION_CORRECTNESS_PERCENTAGE_TITLE': 'Question correctness percentage',
  'EXAM_TEMPLATE.STATISTICS.QUESTION_LIST_CORRECTNESS_PERCENTAGE_TITLE': 'Correctness percentage by questions',

  'EXAM_TEMPLATE.STATISTICS.QUIZ_CORRECTNESS_PERCENTAGE_TITLE': 'Quiz correctness percentage',
  'EXAM_TEMPLATE.STATISTICS.QUESTION_ORDERING_TITLE': 'Ordering no.',
  'EXAM_TEMPLATE.STATISTICS.QUESTION_TEXT_TITLE': 'Question text',
  'EXAM_TEMPLATE.STATISTICS.QUESTION_CORRECTNESS_TITLE': 'Correctness',

  // ---------- Exam instance
  'EXAM_INSTANCE.LIST.TRAINEE_LABEL': 'Student',
  'EXAM_INSTANCE.LIST.ASSIGNED_EXAM_LABEL': 'Assigned exams',
  'EXAM_INSTANCE.LIST.STATUS_LABEL': 'Status',
  'EXAM_INSTANCE.LIST_ITEM.STATUS.READY': 'Assigned',
  'EXAM_INSTANCE.LIST_ITEM.STATUS.ANSWERS_SUBMITTED': 'Submitted',
  'EXAM_INSTANCE.LIST_ITEM.STATUS.EVALUATED': 'Evaluated',
  'EXAM_INSTANCE.LIST_ITEM.STATUS.NEVER_STARTED': 'Exam not solved and availability expired',
  'EXAM_INSTANCE.LIST_ITEM.STATUS.EXTERNAL_EDUCATION_APPLICATION_CREATION': 'In creation',
  'EXAM_INSTANCE.LIST_ITEM.STATUS.FINISHED': 'Creation finished',
  'EXAM_INSTANCE.LIST_ITEM.STATUS.ARCHIVED': 'Archived',
  'EXAM_INSTANCE.LIST_ITEM.STATUS.NOT_STARTED': 'Exam created, but not available',
  'EXAM_INSTANCE.LIST_ITEM.STATUS.ANSWERING_STARTED': 'Answering started',
  'EXAM_INSTANCE.LIST_ITEM.STATUS.IN_EVALUATION': 'In evaluation',
  'EXAM_INSTANCE.LIST_ITEM.STATUS.CANCELLED': 'Canceled',
  'EXAM_INSTANCE.LIST.EARNED_POINTS_LABEL': 'Bodovi',
  'EXAM_INSTANCE.LIST.TIME_LABEL': 'Time',
  'EXAM_INSTANCE.LIST.ACTION_LABEL': 'Action',
  'EXAM_INSTANCE.LIST_ITEM.BUTTON.GRADE_EXAM_LABEL': 'Grade the exam',
  'EXAM_INSTANCE.LIST_ITEM.BUTTON.VIEW_EVALUATION_LABEL': 'Look at the evaluation',
  'EXAM_INSTANCE_FROM.ASSIGNEE': 'EXAMINEE: ',
  'EXAM_INSTANCE.BUTTON_SUBMIT_LABEL': 'Submit exam',
  'EXAM_INSTANCE.CONFIRM_DIALOG_MESSAGE': 'Start solving the exam',
  'EXAM_INSTANCE.EVALUATION_MESSAGE_INFO': 'Exam is being graded',

  // ----------- Notification
  'NOTIFICATION.TITLE': 'Notification title',
  'NOTIFICATION.TEXT': 'Notification text',
  'NOTIFICATION.NEW_NOTIFICATION': 'New notification',
  'NOTIFICATION_FORM.MESSAGE_LABEL': 'Text',
  'NOTIFICATION_FORM.TITLE_LABEL': 'Title',
  'NOTIFICATION.CREATED_MESSAGE': 'Notification has been created',
  'NOTIFICATION.DELETED_MESSAGE': 'Notification has been deleted',

  // ---------- Reminders
  'REMINDER.CREATE_BUTTON_LABEL': 'Create reminder',
  'REMINDER_FORM.LABEL': 'Reminder',
  'REMINDER_FORM.DATE': 'Date',
  'REMINDER_FORM.TIME': 'Time',
  'REMINDER_FORM.DESCRIPTION': 'Reminder text',
  'REMINDER_FORM.DATE_VALIDATION_MESSAGE': 'Please select a date',
  'REMINDER_FORM.TIME_VALIDATION_MESSAGE': 'Please select time',
  'REMINDER_FORM.DESCRIPTION_VALIDATION_MESSAGE': 'Please enter description',
  'REMINDER_CALENDAR.TITLE_LABEL': 'Calendar',
  'REMINDER_CALENDAR.NEXT_MONTH': 'Next month (%{month})',
  'REMINDER_CALENDAR.PREVIOUS_MONTH': 'Previous month (%{month})',
  'REMINDER_CALENDAR.UPCOMING_EVENTS': 'Reminders in %{month}. month',
  'REMINDER_CALENDAR.EMPTY_MESSAGE': 'No reminders',
  'REMINDER_CALENDAR.SELECTED_DAY': 'Selected day',
  'REMINDER_TYPE.USER_DEFINED': 'Reminder',
  'REMINDER_TYPE.WEBINAR': 'Webinar',
  'REMINDER_TYPE.CONSULTATION': 'Consultations',
  'REMINDER.SET_REMINDER_TOOLTIP': 'Set reminder',
  'REMINDER.DELETED_MESSAGE': 'Reminder has been deleted',
  'REMINDER.CREATED_MESSAGE': 'Reminder has been created',

  // ---------- User registration modal
  'USER_REGISTRATION.MODAL.TITLE': 'User information',
  'USER_REGISTRATION.MODAL.WELCOME': 'Welcome %{firstName} %{lastName}! Please check and update your information.',
  'USER_REGISTRATION.FORM.GDPR_BUTTON_LABEL': 'Read "Terms of Use and Privacy Policy"',
  // error message
  'USER_REGISTRATION.FORM.ERROR_MESSAGE.GDPR_REQUIRED': 'Please, read and accept "Terms of Use and Privacy Policy" ',

  // ---------- Gdpr
  'GDPR_MODAL.TITLE': 'Terms of Use and Privacy Policy',

  // ---------- Webinar
  'WEBINAR_LIST_VIEW.TABS.ANOUNCED': 'Anounced',
  'WEBINAR_LIST_VIEW.TABS.FINISHED': 'Finished',

  'WEBINAR_LIST.VIEW_TITLE': 'Webinars',
  'WEBINAR_LIST_VIEW.NO_DATA': 'No webinars available',
  'WEBINAR_LIST_VIEW.CREATE_WEBINAR': 'Create',
  'WEBINAR_LIST_VIEW.EDIT_WEBINAR': 'Edit',
  'WEBINAR_LIST_VIEW.ALL_WEBINARS': 'All webinars',
  'WEBINAR_LIST_VIEW.SORT_START_DATE_ASCEND': 'Start time ascend',
  'WEBINAR_LIST_VIEW.SORT_START_DATE_DESCEND': 'Start time descend',

  // ---------- Webinar view
  'WEBINAR_VIEW.DATE': 'Date',
  'WEBINAR_VIEW.STATUS': 'Status',
  'WEBINAR_VIEW.HOST': 'Host',
  'WEBINAR_VIEW.DESCRIPTION': 'About',
  'WEBINAR_VIEW.SET_REMINDER': 'Set reminder',
  'WEBINAR_VIEW.CREATE.CREATE_WEBINAR_TITLE': 'New webinar',
  'WEBINAR_VIEW.CREATE.WEBINAR_TITLE': 'Title',
  'WEBINAR_VIEW.CREATE.WEBINAR_LINK': 'Link',
  'WEBINAR_VIEW.CREATE.WEBINAR_DATE': 'Date',
  'WEBINAR_VIEW.CREATE.WEBINAR_TIME': 'Time',
  'WEBINAR_VIEW.CREATE.WEBINAR_GUEST': 'Guest',
  'WEBINAR_VIEW.CREATE.WEBINAR_DESCRIPTION': 'Description/subject',
  'WEBINAR_VIEW.CREATE.WEBINAR_PHOTO': 'Webinar photo',
  'WEBINAR_VIEW.CREATE.WEBINAR_DATE_PLACEHOLDER': 'Enter start date',
  'WEBINAR_VIEW.CREATE.WEBINAR_TIME_PLACEHOLDER': 'Enter start time',
  'WEBINAR_VIEW.CREATE.WEBINAR_STATUS': 'Status',
  'WEBINAR_VIEW.COMMENTS': 'Comments',
  'WEBINAR_VIEW.NOTES': 'Notes',
  'WEBINAR.STATUS_TITLE.IN_CREATION': 'In creation',
  'WEBINAR.STATUS_TITLE.CREATION_FINISHED': 'Creation finished',
  'WEBINAR.STATUS_TITLE.IN_PROGRESS': 'In progress',
  'WEBINAR.STATUS_TITLE.PUBLISHED': 'Published',
  'WEBINAR.STATUS_TITLE.FINISHED': 'Finished',
  'WEBINAR.STATUS_TITLE.CANCELLED': 'Cancelled',
  'WEBINAR.STATUS_TITLE.ARCHIVED': 'Archived',

  // ---------- Notifications
  'NOTIFICATIONS.NOTIFICATIONS': 'Notifications',
  'NOTIFICATIONS.SHOW_ALL': 'Show all',
  'NOTIFICATIONS.CREATE_NOTIFICATION': 'New notification',
  'NOTIFICATION_LIST.DATE_TIME': 'Date and time',
  'NOTIFICATION_LIST.NOTIFICATION_TITLE': 'Title',
  'NOTIFICATION_LIST.NOTIFICATION_MESSAGE': 'Message',
  'NOTIFICATION_LIST.NO_DATA': 'No data',
  'NOTIFICATION_LIST.SEARCH_NOTIFICATIONS': 'Search',
  'NOTIFICATION_LIST_FILTER.SORT_BY_TYPE': 'Notifications by type',
  'NOTIFICATION_LIST_FILTER.SELECTOR_OPTION.ALL_NOTIFICATIONS': 'All notifications',
  'NOTIFICATION_FORM.PERSONAL_TYPE': 'Personal',
  'NOTIFICATION_FORM.GENERIC_TYPE': 'General',
  'NOTIFICATION_LIST.NOTIFICATION_TYPE': 'Type',

  // ------------ Quiz
  'QUIZ_CREATOR.MODAL_TITLE': 'Question editor',
  'QUIZ_CREATOR.QUESTION_SUCCESS_INFO_MESSAGE': 'Question saved successfully',
  'QUIZ_CREATOR.QUESTION_ERROR_INFO_MESSAGE': 'Question not saved, enter possible answers to the created question',

  // ---------- Repository
  'REPOSITORY.TITLE': 'Knowledge base',
  'REPOSITORY.DOCUMENTS_LABEL': 'Documents',
  'REPOSITORY.DOCUMENTS_EMPTY_LABEL': 'No documents',
  'REPOSITORY.COURSES_LABEL': 'Courses',
  'REPOSITORY.WEBINARS_LABEL': 'Webinars',
  'REPOSITORY.USERS_LABEL': 'Users',
  'REPOSITORY.USERS_EMPTY_LABEL': 'No users',
  'REPOSITORY.TAG_FILTER_LABEL': 'Tags',
  'REPOSITORY.SELECTED_TAGS_LABEL': 'Selected tags',
  'REPOSITORY.TAG_FILTER_SEARCH_LABEL': 'Search tags',
  'REPOSITORY.TAG_HEADER_LABEL': 'Tags',
  'REPOSITORY.FILE_ADD_LABEL': 'New file',
  'REPOSITORY.FILE_REMOVED_MESSAGE': 'File has been deleted',

  // ---------- Tags
  'TAG_CONTAINER.ADD_TAG': 'Add tag',
  // error messages
  'TAG_ERROR_MESSAGE.TITLE_ALREADY_IN_USE': 'Title is already in use. Please choose another.',

  // ---------- Tenant Configuration
  'TENANT_CONFIGURATION_VIEW.DISPLAY_NAME_LABEL': 'Display name',
  'TENANT_CONFIGURATION_VIEW.LOGO_IMAGE_LABEL': 'Logo',
  'TENANT_CONFIGURATION_VIEW.ENABLE_WEBINARS_LABEL': 'Use webinars',
  'TENANT_CONFIGURATION_VIEW.ENABLE_LOCALES_LABEL': 'Enable multilingualism',
  'TENANT_CONFIGURATION_VIEW.ENABLE_COURSES_LABEL': 'Use courses',
  'TENANT_CONFIGURATION_VIEW.ENABLE_DASHBOARD_LABEL': 'Use dashboard',
  'TENANT_CONFIGURATION_VIEW.ENABLE_EXTERNAL_CONTENTS_LABEL': 'Use external contents',
  'TENANT_CONFIGURATION_VIEW.ENABLE_REPOSITORY_LABEL': 'Use repository',
  'TENANT_CONFIGURATION_VIEW.ENABLE_USER_GROUPS_LABEL': 'Use user groups',
  'TENANT_CONFIGURATION_VIEW.ENABLE_USER_GROUP_COURSES_LABEL': 'Use organizational user groups',
  'TENANT_CONFIGURATION_VIEW.STYLE_THEME_LABEL': 'Theme',
  'TENANT_CONFIGURATION_VIEW.BACKGROUND_IMAGE_LABEL': 'Background image',
  'TENANT_CONFIGURATION_VIEW.ENABLE_EXTERNAL_EDUCATION_APPLICATIONS_LABEL': 'Use education applications',
  'TENANT_CONFIGURATION_VIEW.ENABLE_EXTERNAL_EDUCATIONS_LABEL': 'Use external educations',
  'TENANT_CONFIGURATION_VIEW.DISPLAY_TERMS_LABEL': 'Terms of agreement',
  'TENANT_CONFIGURATION_VIEW.FOOTER_LABEL': 'Footer',
  'TENANT_CONFIGURATION_VIEW.JAVASCRIPT_LABEL': 'Javascript',
  'TENANT_CONFIGURATION_VIEW.JAVASCRIPT_TOOLTIP': 'Write pure JavaScript code without <script> tag.',
  'TENANT_CONFIGURATION_VIEW.ENABLE_INTEGRATION_VIDEO': 'Video integration (Vimeo)',
  'TENANT_CONFIGURATION_VIEW.EXAM_QUESTION_LIST': 'Question types in exam creator',
  'TENANT_CONFIGURATION_VIEW.SURVEY_QUESTION_LIST': 'Question types in survey creator',
  // --- admin tabs
  'TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_WORKPOSITIONS': 'Workposition administration',
  'TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_SURVEYS': 'Survey administration',
  'TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_SKILLS': 'Skill administration',
  'TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_SKILL_LEVELS': 'Skill levels administration',
  'TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_EMAILS': 'Email templates administration',
  'TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_ACTIVITIES': 'Activities administration',
  'TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_NOTIFICATIONS': 'Notifications administration',
  'TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_COMMENTS': 'Comments administration',
  'TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_CODEBOOKS': 'Codebooks administration',

  // ---------- External content
  'EXTERNAL_CONTENT.LIST.TITLE': 'External content',
  'EXTERNAL_CONTENT.LIST.FILTER_PLACEHOLDER': 'Search external content',
  'EXTERNAL_CONTENT.LIST.EMPTY_MESSAGE': 'No external content',
  'EXTERNAL_CONTENT.LIST.ADD': 'New external content',
  'EXTERNAL_CONTENT.CREATE.TITLE': 'External contents list',
  'EXTERNAL_CONTENT.FORM.TITLE_LABEL': 'Title',
  'EXTERNAL_CONTENT.FORM.URL_LABEL': 'Link',
  'EXTERNAL_CONTENT.FORM.DESCRIPTION_LABEL': 'Description',
  'EXTERNAL_CONTENT.FORM.COVER_LABEL': 'Image',
  'EXTERNAL_CONTENT.FORM.TITLE_WHITESPACE_MESSAGE': 'Insert valid title',
  'EXTERNAL_CONTENT.FORM.TITLE_REQUIRED_MESSAGE': 'Inser title',
  'EXTERNAL_CONTENT.FORM.URL_REGEX_MESSAGE': 'Insert valid link',
  'EXTERNAL_CONTENT.FORM.URL_REQUIRED_MESSAGE': 'Insert link',
  'EXTERNAL_CONTENT.FORM.DESCRIPTION_WHITESPACE_MESSAGE': 'Insert valid description',
  'EXTERNAL_CONTENT.DELETED_MESSAGE': 'External content has been deleted',

  // ---------- Email template
  'EMAIL_TEMPLATE_LIST.EMAIL_TEMPLATE_TITLE': 'Title',
  'EMAIL_TEMPLATE_LIST.EMAIL_TEMPLATE_CONTENT': 'Content',
  'EMAIL_TEMPLATE_LIST.EMAIL_TEMPLATE_TYPE': 'Type',
  'EMAIL_TEMPLATE_FORM.CODE_GENERATOR_TYPE': 'Code generator',
  'EMAIL_TEMPLATE_LIST.NO_DATA': 'No data',
  'EMAIL_TEMPLATE_FORM.TITLE_LABEL': 'E-mail template title:',
  'EMAIL_TEMPLATE_FORM.CONTENT_LABEL': 'E-mail template content:',
  'EMAIL_TEMPLATE_FORM.TYPE_LABEL': 'Select e-mail template type:',
  'EMAIL_TEMPLATE_FORM.INACTIVE_USER_TYPE': 'Inactive user',
  'EMAIL_TEMPLATE_FILTER.ALL_TYPES_LABEL': 'All types',
  'EMAIL_TEMPLATE.ADD_EMAIL_TEMPLATE_LABEL': 'Add e-mail template',
  'EMAIL_TEMPLATE.CREATE_EMAIL_TEMPLATE_TITLE': 'Create e-mail template',
  'EMAIL_TEMPLATE.EDIT_EMAIL_TEMPLATE_TITLE': 'Edit e-mail template',
  'EMAIL_TEMPLATE.DELETED_MESSAGE': 'Template has been deleted',
  'EMAIL_TEMPLATE_LIST.EMAIL_TEMPLATE_CONTENT_LINK_LABEL': 'Show content',
  // error messages
  'EMAIL_TEMPLATE_ERROR_MESSAGE.TITLE_ALREADY_IN_USE': 'Title is already in use. Please choose another.',

  // ---------- Member list
  'MEMBER_LIST.ROLE_LABEL': 'Role',
  'MEMBER_LIST.USER_LABEL': 'User',
  'MEMBER_LIST.ROLE_PICKER_PLACEHOLDER_LABEL': 'Select role',
  'MEMBER_LIST.USER_PICKER_PLACEHOLDER_LABEL': 'Select user',

  // ---------- Type picker
  'TYPE_PICKER.LIST_TYPE_LABEL': 'View',
  'TYPE_PICKER.LIST_TYPE_CARD_LABEL': 'Cards',
  'TYPE_PICKER.LIST_TYPE_TABLE_LABEL': 'List',

  // ---------- User group
  'USER_GROUP.LEAVE_CONFIRM_TITLE': 'Are you sure you want to leave the group?',
  'USER_GROUP.COMMON_ACTION_LEAVE': 'Leave group',
  'USER_GROUP.COMMON_ACTION_JOIN': 'Join group',
  'USER_GROUP.NEW_USER_GROUP_TITLE': 'New user group',
  'USER_GROUP_VIEW.DESCRIPTION_LABEL': 'Description:',
  'USER_GROUP_VIEW.ACCESS_CODE_LABEL': 'Access group code:',
  'USER_GROUP_VIEW.LABEL': 'User Groups',
  'USER_GROUP_VIEW.NAVIGATION_MENU_MEMEBERS_LABEL': 'Members',
  'USER_GROUP_VIEW.NAVIGATION_MENU_MATERIALS_LABEL': 'Materials',
  'USER_GROUP_VIEW.NAVIGATION_MENU_COURSES_LABEL': 'Courses',
  'USER_GROUP_MEMBER_LIST.ROLE_LABEL': 'Role:',
  'USER_GROUP_MEMBER_LIST.ROLE_TYPE.OWNER': 'Owner',
  'USER_GROUP_MEMBER_LIST.ROLE_TYPE.ADMIN': 'Admin',
  'USER_GROUP_MEMBER_LIST.ROLE_TYPE.MEMBER': 'Member',
  'USER_GROUP_LIST_VIEW.MY_GROUPS_TITLE': 'My groups',
  'USER_GROUP_LIST_VIEW.TABS.MY_GROUPS_LABEL': 'My groups',
  'USER_GROUP_LIST_VIEW.TABS.AVAILABLE_GROUP_LIST_LABEL': 'Available groups',
  'USER_GROUP.LIST_VIEW.HIERARCHY_TREE_TITLE': 'Organizational graph',
  'USER_GROUP_LIST_VIEW.FILE_ADD_LABEL': 'New file',
  'USER_GROUP.JOIN_BUTTON_LABEL': 'Join group',
  'USER_GROUP.LEAVE_BUTTON_LABEL': 'Leave group',
  // error messages
  'USER_GROUP_ERROR_MESSAGE.NON_EXISTING_USER_GROUP': 'The code does not match any group in the system',

  // ---------- Access code form
  'ACCESS_CODE_FORM.JOIN_BUTTON_LABEL': 'Join',
  'ACCESS_CODE_FORM.LEAVE_BUTTON_LABEL': 'Leave',
  'ACCESS_CODE_FORM.GENERATE_LABEL': 'Generate new access code',
  'ACCESS_CODE_FORM.GENERATE_BUTTON_LABEL': 'Generirate code',
  'ACCESS_CODE_FORM.ACCESS_CODE_LABEL': 'Access code',
  'ACCESS_CODE_FORM.CODE_EXPIRATION_LABEL': 'Code expiration date',
  'ACCESS_CODE_FORM.TITLE': 'Enter access code',
  'ACCESS_CODE_FORM.URL_LABEL': 'Link',

  // ---------- User group form
  'USER_GROUP_FORM.NAME_LABEL': 'Group name',
  'USER_GROUP_FORM.DESCRIPTION_LABEL': 'Description',
  'USER_GROUP_FORM.VISIBILITY_LABEL': 'Group visibility',
  'USER_GROUP_FORM.VISIBILITY_TITLE_PUBLIC': 'Public',
  'USER_GROUP_FORM.VISIBILITY_TITLE_LIMITED': 'Limited',
  'USER_GROUP_FORM.VISIBILITY_TITLE_CLOSED': 'Private',
  'USER_GROUP_FORM.TYPE_LABEL': 'User group type',
  'USER_GROUP_FORM.TYPE_BASIC': 'Basic',
  'USER_GROUP_FORM.TYPE_ORGANIZATION': 'Organization',

  'USER_GROUP_ACCESS_FORM.ACCESS_CODE_LABEL': 'Access code',
  'USER_GROUP_ACCESS_FORM.TITLE': 'Enter group access code',
  'USER_GROUP_EDIT_FORM.EDIT_LABEL': 'Edit user group',

  // ---------- Skill groups
  'SKILL_GROUP_LIST.SKILL_GROUP_DESCRIPTION_LABEL': 'Skill group:',
  'SKILL_GROUP_LIST.SKILL_DESCRIPTION_LABEL': 'Skill:',
  'SKILL_GROUP_LIST.SKILL_ADD_BUTTON_LABEL': 'Add skill',
  'SKILL_GROUP_LIST.SKILL_GROUP_ADD_BUTTON_LABEL': 'Add new skill group',

  // ---------- Skill group modals
  'SKILL_MODAL.SKILL_CREATE_TITLE': 'New skill',
  'SKILL_MODAL.SKILL_UPDATE_TITLE': 'Edit skill',
  'SKILL_FORM.SKILL_LEVEL_CLASSIFICATION_LABEL': 'Skill levels',
  'SKILL_GROUP_MODAL.SKILL_GROUP_CREATE_TITLE': 'New skill group',
  'SKILL_GROUP_MODAL.SKILL_GROUP_UPDATE_TITLE': 'Edit skill group',
  // ---------- error message
  'SKILL_GROUP_LIST.ERROR.SKILL_GROUP_ALREADY_EXISTS': 'There is already a skill group with the same name, choose another name.',
  'SKILL_LIST.ERROR.SKILL_ALREADY_EXISTS': 'There is already a skill with the same name, choose another name.',

  // ---------- Skill list
  'SKILL_LIST.VIEW.SKILL_LEVEL_CLASSIFICATION_BUTTON_LABEL': 'Edit skill level classifications',

  // ---------- Data table
  'DATA_TABLE.SELECTED_ROWS_COUNT_LABEL': 'Selected',
  'DATA_TABLE.SELECTED_ROWS_MORE_ACTIONS_LABEL': 'More options',

  // ---------- Company Form
  'COMPANY.FORM.LABEL': 'Company info',
  'COMPANY.FORM.SELECTOR_NAME_LABEL': 'Find company by name',
  'COMPANY.FORM.SELECTOR_ID_LABEL': 'Find company by ID',
  'COMPANY.FORM.NAME_LABEL': 'Company name',
  'COMPANY.FORM.ID_LABEL': 'Company ID',
  'COMPANY.FORM.CITY_LABEL': 'Company city',
  'COMPANY.FORM.ZIP_LABEL': 'Company ZIP code',
  'COMPANY.FORM.STREET_LABEL': 'Company address',
  'COMPANY.FORM.CONTACT_LABEL': 'Company e-mail',
  'COMPANY.FORM.NAME_VALIDATION_MESSAGE': 'Insert company name',
  'COMPANY.FORM.ID_VALIDATION_MESSAGE': 'Insert company ID',
  'COMPANY.FORM.CITY_VALIDATION_MESSAGE': 'Insert company city',
  'COMPANY.FORM.ZIP_VALIDATION_MESSAGE': 'Insert company ZIP code',
  'COMPANY.FORM.ADDRESS_VALIDATION_MESSAGE': 'Insert company address',
  'COMPANY.FORM.CONTACT_VALIDATION_MESSAGE': 'Insert company e-mail',
  'COMPANY.FORM.VALID_EMAIL_MESSAGE': 'Insert valid e-mail',

  // ---------- Survey template
  'SURVEY_TEMPLATE.TITLE.USER_EDUCATION_COMPLETED': 'Survey for training participants (immediately after completion)',
  'SURVEY_TEMPLATE.TITLE.USER_EDUCATION_COMPLETED_CHECK_IN': 'Survey for training participants (6 months after completion)',
  'SURVEY_TEMPLATE.TITLE.EDUCATION_PROVIDER': 'Survey for education provider',
  'SURVEY_TEMPLATE.TITLE.EMPLOYER': 'Survey for the employer',

  // ---------- Survey instance
  'SURVEY_INSTANCE.SURVEY_TYPE_TITLE.USER_EDUCATION_COMPLETED': 'Survey for training participants (immediately after completion)',
  'SURVEY_INSTANCE.SURVEY_TYPE_TITLE.USER_EDUCATION_COMPLETED_CHECK_IN': 'Survey for training participants (6 months after completion)',
  'SURVEY_INSTANCE.SURVEY_TYPE_TITLE.EDUCATION_PROVIDER': 'Survey for education provider',
  'SURVEY_INSTANCE.SURVEY_TYPE_TITLE.EMPLOYER': 'Survey for the employer',
  'SURVEY_INSTANCE.BUTTON_SUBMIT_LABEL': 'Submit survey',
  // error message
  'SURVEY_INSTANCE_ERROR_MESSAGE.REQUIRED_QUESTION_MISSING_ANSWER': 'The survey was not submitted, please answer the required questions',
  'SURVEY_INSTANCE_ERROR_MESSAGE.SURVEY_MULTIPLE_SUBMISSIONS_NOT_ALLOWED': 'The survey has already been submitted, it is not allowed to answer more than once',
  'SURVEY_INSTANCE_ERROR_MESSAGE.PARTIAL_ANSWER_NOT_ALLOWED': 'The survey has not been submitted, please answer the question already started',
  'SURVEY_INSTANCE.ERROR_MESSAGE.TOKEN_INVALID': 'The token has expired or is invalid',

  // ---------- Survey instance feedback
  'SURVEY_INSTANCE_FEEDBACK.SURVEY_TYPE_TITLE.USER_EDUCATION_COMPLETED': 'Submitted - Survey for training participants (immediately after completion)',
  'SURVEY_INSTANCE_FEEDBACK.SURVEY_TYPE_TITLE.USER_EDUCATION_COMPLETED_CHECK_IN': 'Submitted - Survey for training participants (6 months after completion)',
  'SURVEY_INSTANCE_FEEDBACK.SURVEY_TYPE_TITLE.EDUCATION_PROVIDER': 'Submitted - Survey for education provider',
  'SURVEY_INSTANCE_FEEDBACK.SURVEY_TYPE_TITLE.EMPLOYER': 'Submitted - Survey for the employer',

  // ---------- External education application
  'EXTERNAL_EDUCATION_APPLICATION.EMPLOYED_LABEL': 'Employed person',
  'EXTERNAL_EDUCATION_APPLICATION.UNEMPLOYED_LABEL': 'Unemployed person',

  'EXTERNAL_EDUCATION_APPLICATION.FILES.CURRICULUM_VITAE_LABEL': 'Biography',
  'EXTERNAL_EDUCATION_APPLICATION.FILES.PROFESSIONAL_PLAN_LABEL': 'Professional plan',
  'EXTERNAL_EDUCATION_APPLICATION.FILES.OFFER_LABEL': 'Offer',
  'EXTERNAL_EDUCATION_APPLICATION.FILES.CURRICULUM_LABEL': 'Curriculum',
  'EXTERNAL_EDUCATION_APPLICATION.FILES.FINANCING_AGREEMENT_LABEL': 'Financing agreement',
  'EXTERNAL_EDUCATION_APPLICATION.FILES.MUTUALLY_SIGNED_FINANCING_AGREEMENT_LABEL': 'Mutually signed agreement',
  'EXTERNAL_EDUCATION_APPLICATION.FILES.SIGNED_FINANCING_AGREEMENT_LABEL': 'Signed financing agreement',
  'EXTERNAL_EDUCATION_APPLICATION.FILES.PROOF_OF_PAYMENT_LABEL': 'Proof of payment',
  'EXTERNAL_EDUCATION_APPLICATION.FILES.ATTENDANCE_CERTIFICATE_LABEL': 'Attendance certificate',
  'EXTERNAL_EDUCATION_APPLICATION.FILES.COMPETENCIES_CERTIFICATE_LABEL': 'Compentencies certificate',

  'EXTERNAL_EDUCATION_APPLICATION.OWNERSHIP.MY_APPLICATIONS_LABEL': 'My Applications',
  'EXTERNAL_EDUCATION_APPLICATION.OWNERSHIP.OTHER_COORDINATORS_APPLICATIONS_LABEL': 'Others applications',
  'EXTERNAL_EDUCATION_APPLICATION.OWNERSHIP.WITHOUT_COORDINATOR_LABEL': 'Not taken applications',

  'EXTERNAL_EDUCATION_APPLICATION.FORM.STEP_ONE_TITLE': 'About me',
  'EXTERNAL_EDUCATION_APPLICATION.FORM.STEP_TWO_TITLE': 'About education',
  'EXTERNAL_EDUCATION_APPLICATION.FORM.STEP_THREE_TITLE': 'General terms',
  'EXTERNAL_EDUCATION_APPLICATION.FORM.STEP_FOUR_TITLE': 'Request overview',

  'EXTERNAL_EDUCATION_APPLICATION.BUTTON_PREV_LABEL': 'Previous step',
  'EXTERNAL_EDUCATION_APPLICATION.BUTTON_SAVE_LABEL': 'Save for later',
  'EXTERNAL_EDUCATION_APPLICATION.BUTTON_NEXT_LABEL': 'Keep filling out the form',
  'EXTERNAL_EDUCATION_APPLICATION.BUTTON_SUBMIT_LABEL': 'Submit request',

  'EXTERNAL_EDUCATION_APPLICATION.SKILL_PANEL_TITLE': 'Skill you want to aquire',
  'EXTERNAL_EDUCATION_APPLICATION.ABOUT_ME_PANEL_TITLE': 'My info',
  'EXTERNAL_EDUCATION_APPLICATION.EMPLOYER_PANEL_TITLE': 'My employer info',
  'EXTERNAL_EDUCATION_APPLICATION.EDUCATION_PROVIDER_PANEL_TITLE': 'Education provider info',
  'EXTERNAL_EDUCATION_APPLICATION.ABOUT_EDUCATION_PANEL_TITLE': 'Education info',
  'EXTERNAL_EDUCATION_APPLICATION.TERMS_PANEL_TITLE': 'General terms of voucher usage',
  'EXTERNAL_EDUCATION_APPLICATION.EDUCATION_APPLICATION_AGREEMENT_PANEL_TITLE': 'Agreement info',
  'EXTERNAL_EDUCATION_APPLICATION.AGREEMENTS_AND_CERTIFICATES_PANEL_TITLE': 'Application files agreements and certificates',
  'EXTERNAL_EDUCATION_APPLICATION.SKILL_LABEL': 'Select skill',
  'EXTERNAL_EDUCATION_APPLICATION.EMPLOYMENT_STATUS_LABEL': 'Select your status',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR_HELP_WANTED_LABEL': 'Do you require HZZ coordinator support and advice?',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR_HELP_WANTED_TOOLTIP_TEXT': 'Coordinator will see the text when you submit the application',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR_HELP_CONTACT_TYPE_LABEL': 'Which contact method do you prefer?',
  'EXTERNAL_EDUCATION_APPLICATION.EDUCATION_RELATED_LABEL': 'Is your application in relation with your education?',
  'EXTERNAL_EDUCATION_APPLICATION.JOB_RELATED_LABEL': 'Is your application in relation with your current job?',
  'EXTERNAL_EDUCATION_APPLICATION.APPLICATION_VALIDATION_TEXT': 'Description needs a minimum 750 characters of text',
  'EXTERNAL_EDUCATION_APPLICATION.APPLICATION_DESCRIPTION_PLACEHOLDER': 'Describe how your application is related to the selected skill',
  'EXTERNAL_EDUCATION_APPLICATION.ACCEPT_TERMS_CHECKBOX_LABEL': 'I agree to the General terms of voucher usage for the skill aquiring program',

  // ---------- External education application expenses
  'EXTERNAL_EDUCATION_APPLICATION.EXPENSE.EDUCATION_OFFER_PRICE': 'Education expenses',
  'EXTERNAL_EDUCATION_APPLICATION.EXPENSE.TRAVEL_EXPENSE': 'Travel expenses',
  'EXTERNAL_EDUCATION_APPLICATION.EXPENSE.INSURANCE_EXPENSE': 'Insurance expenses',
  'EXTERNAL_EDUCATION_APPLICATION.TERMS_LABEL': 'General terms of use of vouchers for skills acquisition programs',
  'EXTERNAL_EDUCATION_APPLICATION.FORM.SUBMIT_SUCCESSFUL_TITLE': 'Your request has been successfully submitted',
  'EXTERNAL_EDUCATION_APPLICATION.FORM.SUBMIT_SUCCESSFUL_MESSAGE': 'HZZ coordinator will review your request within 8 days',

  // ----------- Education application report list filter
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.SKILL_LABEL': 'Skills acquisition program',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_LABEL': 'Education program',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_PROVIDER_LABEL': 'Education service provider',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.ASSIGNEE_LABEL': 'Participant',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.EVALUATOR_LABEL': 'Hzz coordinator',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.EMPLOYMENT_STATUS_LABEL': 'Employment status of the participant when submitting the application',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.PENDING_CONSULTATION_LABEL': 'Coordinator support and advice wanted',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_RELATED_LABEL': 'Is the request related to education?',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.JOB_RELATED_LABEL': 'Is the application related to job?',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_APPLICATION_STATUS_LABEL': 'Application status',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_DURATION_LABEL': 'Duration of education program',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_APPLICATION_SUBMISSION_DATE_LABEL': 'Submission date of application',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_EXPENSES_LABEL': 'The expenses of education programs',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.TRAVEL_EXPENSES_LABEL': 'Travel expenses',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.INSURANCE_EXPENSES_LABEL': 'Insurance expenses',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.ASSIGNEE_PLACEHOLDER': 'Enter the last name of the participant',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.EVALUATOR_PLACEHOLDER': 'Enter the last name of the coordinator',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.PICK_STATUS_PLACEHOLDER': 'Pick status',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_DURATION_SUFFIX': 'hours',

  // ----- Education application report list filter info panel
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.DESCRIPTION': 'Selected filters (%{numberOfSelectedParameters}):',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_EMPLOYMENTSTATUS': 'Employment status',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_ASSIGNEE': 'Participant',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_PHASE': 'Education application status',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_EDUCATIONPROVIDER': 'Education provider',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_SKILL': 'Skill acquisition',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_EDUCATION': 'Education programe',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_EVALUATOR': 'HZZ coordinator',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_PENDINGCONSULTATION': 'Coordinator help wanted',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_EDUCATIONRELATED': 'Application related to education',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_JOBRELATED': 'Application related to job',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_EDUCATIONMINDURATION': 'Minimal education duration',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_EDUCATIONMAXDURATION': 'Maximum education duration',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_EDUCATIONMINEXPENSES': 'Minimal education expenses',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_EDUCATIONMAXEXPENSES': 'Maximum education expenses',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_TRAVELMINEXPENSES': 'Minimal travel expenses',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_TRAVELMAXEXPENSES': 'Maximum travel expenses',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_INSURANCEMINEXPENSE': 'Minimal insurance expense',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_INSURANCEMAXEXPENSE': 'Maximum insurance expense',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_SUBMITTEDFROM': 'Application submitted from',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_SUBMITTEDTO': 'Application submitted to',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_CITY': 'City',

  // ----- Education application coordinator list filter
  'EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.APPLICATION_OWNERSHIP_LABEL': 'Application ownership',
  'EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.ASSIGNEE_LABEL': 'Participant',
  'EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.ASSIGNEE_CITY_LABEL': 'Participant city',
  'EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.EDUCATION_APPLICATION_STATUS_LABEL': 'Application status',
  'EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.PARTICIPANT_PLACEHOLDER': 'Enter participant last name',
  'EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.PARTICIPANT_CITY_PLACEHOLDER': 'Enter city',
  'EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.PICK_STATUS_PLACEHOLDER': 'Pick status',
  'EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.PENDING_CONSULTATION_LABEL': 'HZZ coordinator support and advice',
  'EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.PENDING_CONSULTATION_PLACEHOLDER': 'Pick answer',
  // ----- Education application coordinator list info filter
  'EDUCATION_APPLICATION_COORDINATOR_LIST_INFO_FILTER.SEARCH_URGENT_APPLICATIONS': 'Search applications',
  'EDUCATION_APPLICATION_COORDINATOR_LIST_INFO_FILTER.DESCRIPTION_SUFIX': 'with requested support and advice',
  'EDUCATION_APPLICATION_COORDINATOR_LIST_INFO_FILTER.DESCRIPTION': 'The request to which the user has requested support and advice needs to be responded to urgently.',

  // ---------- Education application list
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.SKILL_HEADER_TITLE': 'Skill',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EMPLOYER_NAME_HEADER_TITLE': 'Education provider',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.ASSIGNEE_RESIDENCE_HEADER_TITLE': 'Assignee residance',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.ASSIGNEE_HEADER_TITLE': 'Assignee',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.APPLICATION_STATUS_HEADER_TITLE': 'Status',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.APPLICATION_SUBMISSION_DATE_HEADER_TITLE': 'Submission date',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EVALUATOR_HEADER_TITLE': 'Evaluator',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.NEW_APPLICATION_BUTTON_LABEL': 'Submit new application',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.ASSIGNEE_EMPLOYMENT_STATUS_BEFORE_EDUCATION_HEADER_TITLE': 'Employment status when applying on education',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.HZZ_COORDINATOR_HELP_WANTED_HEADER_TITLE': 'Coordinator support and advice wanted',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EDUCATION_RELEATED_HEADER_TITLE': 'Is the application related to education',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.JOB_RELATED_HEADER_TITLE': 'Is the application related to job',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EDUCATION_DURATION_HEADER_TITLE': 'Education duration',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.ASSIGNEE_GRADE_HEADER_TITLE': 'Assignee grade',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.PROVIDER_GRADE_HEADER_TITLE': 'Provider grade',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EMPLOYER_GRADE_HEADER_TITLE': 'Ocjena poslodavca',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EDUCATION_EXPENSE_HEADER_TITLE': 'Education expense',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.TRAVEL_EXPENSE_HEADER_TITLE': 'Travel expense',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.INSURANCE_EXPENSE_HEADER_TITLE': 'Insurnace expense',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EXPENSE_VALUE_LABEL': '%{value} (without VAT)',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.TRAVEL_AND_INSURANCE_EXPENSE_HEADER_TITLE': 'Travel and insurance expense',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EMPLOYMENT_STATUS.EMPLOYED': 'Employed',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EMPLOYMENT_STATUS.UNEMPLOYED': 'Unemployed',

  // ---------- Request
  // ---------- User view section
  'REQUEST.USER_VIEW_SECTION.TITLE': 'About user',
  'REQUEST.USER_VIEW_SECTION.LINK_LABEL': 'Go to profile',
  'REQUEST.USER_VIEW_SECTION.EMPLOYED_LABEL': 'employed',
  'REQUEST.USER_VIEW_SECTION.UNEMPLOYED_LABEL': 'unemployed',
  'REQUEST.USER_VIEW_SECTION.EMAIL_LABEL': 'user e-mail',
  'REQUEST.USER_VIEW_SECTION.CONSULTATION_LABEL': 'HZZ coordinator proffesional support needed',
  'REQUEST.USER_VIEW_SECTION.CONSULTATION_CONTACT_LABEL': 'contact: %{contact}',
  'REQUEST.USER_VIEW_SECTION.IDENTIFICATION_NUMBER_LABEL': 'user OIB',
  'REQUEST.USER_VIEW_SECTION.FOOTER_TITLE': 'Files',
  'REQUEST.USER_VIEW_SECTION.CURRICULUM_VITAE_LABEL': 'cv',
  'REQUEST.USER_VIEW_SECTION.PROFESSIONAL_PLAN_LABEL': 'professional plan',
  'REQUEST.USER_VIEW_SECTION.IDENTIFICATION_NUMBER_MISSING_LABEL': 'No OIB',
  'REQUEST.USER_VIEW_SECTION.EMAIL_MISSING_LABEL': 'no e-mail',
  'REQUEST.USER_VIEW_SECTION.EMPLOYER_NAME_LABEL': 'employer',
  'REQUEST.USER_VIEW_SECTION.EMPLOYER_OIB_LABEL': 'employer OIB',
  'REQUEST.USER_VIEW_SECTION.EMPLOYER_EMAIL_LABEL': 'employer e-mail',
  'REQUEST.USER_VIEW_SECTION.EMPLOYER_ADDRESS_LABEL': 'employer address',
  'REQUEST.USER_VIEW_SECTION.EMPLOYER_NO_NAME_LABEL': 'Employer name not entered',
  'REQUEST.USER_VIEW_SECTION.EMPLOYER_NO_OIB_LABEL': 'Epmloyer iban not entered',

  // ----------- Education application details
  'EXTERNAL_EDUCATION_APPLICATION.DETAILS.EDUCATION_START_DATE_TITLE': 'Education start date',
  'EXTERNAL_EDUCATION_APPLICATION.DETAILS.TITLE': 'Application details',
  'EXTERNAL_EDUCATION_APPLICATION.DETAILS.JOB_RELATED_TITLE': 'Is the request related to your job?',
  'EXTERNAL_EDUCATION_APPLICATION.DETAILS.EDUCATION_RELATED_TITLE': 'Is the request related to your education?',
  'EXTERNAL_EDUCATION_APPLICATION.DETAILS.SKILL_ACQUISITION_TITLE': 'Skills acquisition program',
  'EXTERNAL_EDUCATION_APPLICATION.DETAILS.APPLICATION_DESCRIPTION_TITLE': 'Explain how the completion of the proposed program will affect your career development or employment opportunities',
  'EXTERNAL_EDUCATION_APPLICATION.DETAILS.CONSULTATION_TITLE': 'Requested counseling and support?',

  'EDUCATION_APPLICATION_SELECTOR.BOOLEAN_STATUS_TRUE': 'Yes',
  'EDUCATION_APPLICATION_SELECTOR.BOOLEAN_STATUS_FALSE': 'No',

  // ----- external education
  'EXTERNAL_EDUCATION_APPLICATION.APPLICATION_DENIED_TEXT': 'Application denied',
  'EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.SKILL_INFO': 'Enter the first three letters of the name of the education program or select the appropriate program from the drop-down menu.',
  'EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.CURRICULUM_VITAE_INFO': 'The CV should contain information on completed education, additional training, courses, seminars, previous work experience and acquired skills, interests and hobbies, etc. Supported formats are .pdf, .jpg, .jpeg, .png and .doc.',
  'EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.OFFER_INFO':
    'Request a quote from your education provider. The file should contain information on the name of the education program, the education service provider, the price of attending the selected education program and information on the payment of the costs of attending the program. Supported formats are .pdf, .jpg, .jpeg, .png and .doc.',
  'EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.CURRICULUM_INFO':
    'Request the curriculum from the education provider. The file should contain information on the name of the program, area/educational sector to which it belongs, enrollment conditions, duration, method of execution (online, in the classroom, combined in the classroom and with the employer, etc.), content of the education program (subjects, topics, modules, etc.). ), performers (qualifications of lecturers/teachers/mentors), expected learning outcomes that will be acquired upon completion of the education program, the title of the certificate of completed education program, and the approximate schedule of classes by days/weeks/months. Supported formats are .pdf, .jpg, .jpeg, .png and .doc.',
  'EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.TERMS_INFO': 'Below are the General Terms and Conditions of Voucher Use. By clicking on "I agree to the General Terms of Use of vouchers for the education program" you confirm that you are familiar with and that you agree to the stated conditions.',
  'EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.SIGNED_FINANCING_AGREEMENT_INFO': 'Supported formats are .pdf, .jpg, .jpeg, .png and .doc.',
  'EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.START_DATE_INFO': 'Enter the start date of the education program provided by the education service provider',
  'EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.CONTRACT_GENERATION_INFO':
    'Based on the information from the attached offer and curriculum files and the entered start date of the program, an Agreement on mutual rights and obligations was generated during the education program through vouchers. The agreement needs to be taken over and signed.',
  'EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.PROOF_OF_PAYMENT_INFO': 'A proof of payment must be attached so that the voucher user/student can attend the education program. Supported formats are .pdf, .jpg, .jpeg, .png and .doc.',
  'EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.ATTENDANCE_CERTIFICATE_INFO':
    'The certificate of attendance certified by the program service provider is submitted by the unemployed person no later than every 5th of the month, and other job seekers after the completion of the education program. Supported formats are .pdf, .jpg, .jpeg, .png and .doc.',
  'EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.COMPETENCIES_CERTIFICATE_INFO':
    'Ask your education provider for confirmation of completion of your education program. The certificate must contain information on the name of the program, duration and acquired learning outcomes/competencies. Supported formats are .pdf, .jpg, .jpeg, .png and .doc.',

  'EXTERNAL_EDUCATION_APPLICATION.ERROR.SKILL_DATA': 'Choose the skill you want to acquire',
  'EXTERNAL_EDUCATION_APPLICATION.ERROR.CONTACT_INFORMATION': 'Enter contact information',
  'EXTERNAL_EDUCATION_APPLICATION.ERROR.EMPLOYER_INFORMATION': 'Enter information about the employer',
  'EXTERNAL_EDUCATION_APPLICATION.ERROR.ACCEPTED_TERMS': 'You must accept the general terms of use',
  'EXTERNAL_EDUCATION_APPLICATION.ERROR.APPLICATION_DESCRIPTION_LENGTH': 'The justification must contain a minimum of 750 characters',
  'EXTERNAL_EDUCATION_APPLICATION.ERROR.APPLICATION_DESCRIPTION_REQUIRED': 'Enter the explanation of the application',
  'EXTERNAL_EDUCATION_APPLICATION.ERROR.FILE_CURRICULUM_VITAE': 'Add a resume',
  'EXTERNAL_EDUCATION_APPLICATION.ERROR.FILE_OFFER': 'Add an offer of education programs',
  'EXTERNAL_EDUCATION_APPLICATION.ERROR.FILE_CURRICULUM': 'Add an education program curriculum',

  'EXTERNAL_EDUCATION_APPLICATION.VIEW.EVALUATOR_TITLE': 'Coordinator: %{name}',
  'EXTERNAL_EDUCATION_APPLICATION.VIEW.STEP_ONE_TITLE': 'Submitting a application',
  'EXTERNAL_EDUCATION_APPLICATION.VIEW.STEP_TWO_TITLE': 'Approval of the application',
  'EXTERNAL_EDUCATION_APPLICATION.VIEW.STEP_THREE_TITLE': 'Program duration',
  'EXTERNAL_EDUCATION_APPLICATION.VIEW.STEP_FOUR_TITLE': 'Closing the application',

  'EXTERNAL_EDUCATION_APPLICATION.EXPENSE.PANEL_TITLE': 'Expense',
  'EXTERNAL_EDUCATION_APPLICATION.EXPENSE.TYPE_LABEL': 'Type of expense',
  'EXTERNAL_EDUCATION_APPLICATION.EXPENSE.VALUE_LABEL': 'Amount cost (without VAT)',
  'EXTERNAL_EDUCATION_APPLICATION.EXPENSE.DATE_LABEL': 'Payment date',
  'EXTERNAL_EDUCATION_APPLICATION.EXPENSE.PANEL_LABEL': 'Enter expense',
  'EXTERNAL_EDUCATION_APPLICATION.EXPENSE.TRAVEL_EXPENSE_LABEL': 'Travel expense',
  'EXTERNAL_EDUCATION_APPLICATION.EXPENSE.INSURANCE_EXPENSE_LABEL': 'Insurance expense',
  'EXTERNAL_EDUCATION_APPLICATION.EXPENSE.CREATE_SUCCESS': 'Expense saved',

  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR_PAYMENT_INFO.BANK_NAME_LABEL': 'Education provider bank name',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR_PAYMENT_INFO.IBAN_LABEL': 'Education provider IBAN (HRcc AAAA AAAB BBBB BBBB B)',

  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE_PAYMENT_INFO.BANK_NAME_LABEL': 'The name of your bank',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE_PAYMENT_INFO.IBAN_LABEL': 'Your IBAN (HRcc AAAA AAAB BBBB BBBB B)',

  'EXTERNAL_EDUCATION_APPLICATION.SURVEY.TRAINEE_SURVEY_LABEL': 'Trainee grade',
  'EXTERNAL_EDUCATION_APPLICATION.SURVEY.EMPLOYER_SURVEY_LABEL': 'Employer grade',
  'EXTERNAL_EDUCATION_APPLICATION.SURVEY.EDUCATION_PROVIDER_SURVEY_LABEL': 'Education provider grade',

  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.USER_SUBMITTED_TEXT': 'The user has submitted an application for an education program',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.IN_PROCCESS_TEXT':
    'Review the user\'s application. If the application meets the criteria, select "Approve", if it does not meet, select "Do not approve" the application. In case the necessary information is missing, you can application information from the applicant, by selecting "Return to user" ',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.APPLICATION_APPROVED_TEXT': 'You have approved this application',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.APPLICATION_DENIED_TEXT': 'Application denied',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.TAKE_APPLICATION_BUTTON_TEXT': 'Download application',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.APPROVE_APPLICATION_BUTTON_TEXT': 'Approve',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.RETURN_APPLICATION_BUTTON_TEXT': 'Return to user',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.RETURN_APPLICATION_MODAL_TITLE': 'Are you sure you want to return the application to the user?',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.RETURN_APPLICATION_PLACEHOLDER': 'Enter the reason for returning the application.',
  // tslint:disable-next-line: quotemark
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.REJECT_APPLICATION_BUTTON_TEXT': "D'Don't approve",
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.REJECT_APPLICATION_MODAL_TITLE': 'Are you sure you want to decline the application?',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.REJECT_APPLICATION_PLACEHOLDER': 'Enter the reason for the rejection of the request.',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.REJECT_APPLICATION_CONFIRM_BUTTON': 'Reject',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.APPLICATION_CANCELED_TEXT': 'The user has canceled this application',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.REQUEST_SIGNED_CONTRACT_BUTTON_TEXT': 'Request agreement',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.AWAITING_SIGNED_CONTRACT_TEXT': 'You have requested a signed agreement from the user. The user is obliged to upload the signed agreement within 8 days',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.EDUCATION_NOT_ENTERED_TEXT': 'Enter information about the education program',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.EDUCATION_ENTERED_TEXT': 'You have entered information about the education program',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CONTRACT_GENERATION_TEXT': 'Check the registration and ask the user for a signed agreement. Once you request an agreement, it is no longer possible to edit information about the education program and education provider',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.SIGNED_CONTRACT_UPLOADED_TEXT': 'The user uploaded the signed agreement.',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.UPLOAD_PROOF_OF_PAYMENT_TEXT': 'Initiate the process of paying the costs of the education program to the account of the education service provider. Then attach a proof of payment.',
  // tslint:disable-next-line: quotemark
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.ENABLE_EDUCATION_TEXT': "O'Once you've done that, you can make it possible to attend an education program.",
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.EDUCATION_COMPLETED_TEXT': 'Student has completed the education program',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CANCEL_APPLICATION_APPROVAL_BUTTON_TEXT': 'Revoke approval',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CANCEL_APPLICATION_APPROVAL_MODAL_TITLE': 'Are you sure you want to revoke the approval?',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CANCEL_APPLICATION_APPROVAL_PLACEHOLDER': 'Enter the reason for revoking the approval ',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.OPEN_SURVEY_BUTTON_LABEL': 'Look at the rating',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.ENABLE_EDUCATION_BUTTON_TEXT': 'Send proof of payment',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.COORDINATOR_HELP_WANTED_TEXT': 'The user requested the advice and support of a HZZ coordinator for proffesional guidance when making a decision on choosing a skill that you would like to develop or further improve',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.COORDINATOR_HELP_FINISHED_BUTTON_TEXT': 'Counseling done',

  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.SUBMITED_AND_WAITING_COORDINATOR_TEXT': 'HZZ coordinator will review your application',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.APPROVED_AND_CONTRACT_IN_GENERATION_TEXT': 'The HZZ coordinator reviewed your application and asked you for a signed agreement. Enter the information required below to generate the agreement.',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.ENTER_START_DATE_TEXT': 'Select the start date of the educational program.',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.ENTER_PAYMENT_INFO_TEXT': 'Enter information about your bank. Information is needed to make travel expenses.',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.CONTRACT_GENERATED_TEXT': 'You have successfully generated an agreement. Download, read and sign the written agreement yourself. ',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.AWAITING_PAYMENT_TEXT': 'HZZ coordinator will send a confirmation of payment',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.EDUCATION_IN_PROGRESS_TEXT': 'HZZ coordinator approved your request and provided proof of payment. You can contact your education provider and start an education program.',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.UNEMPLOYED_ATTENDANCE_CERTIFICATE_TEXT': 'As an unemployed person, you have agreed to submit a certificate of attendance by agreement no later than the 5th of each month.',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.UPLOAD_COMPETENCIES_CERTIFICATE_TEXT': 'Upon completion of the education program, enclose a certificate of acquired competencies. Ask your education provider for confirmation.',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.APPLICATION_CANCELED_TEXT': 'You have canceled this request',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.EDUCATION_IN_PROGRESS_FINISH_TEXT': 'When you have completed the educational program and attached a certificate of acquired competencies, click on the button below to confirm the completion of the educational program.',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.EDUCATION_COMPLETED_TEXT': 'Access the education program evaluation survey. According to the signed agreement, you are obliged to fill in the survey within N days after the end of the education program. ',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.TAKE_SURVEY_BUTTON_LABEL': 'Access the survey ',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.CANCEL_APPLICATION_LABEL': 'You still don\t want to attend an education program?',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.CANCEL_APPLICATION_BUTTON_TEXT': 'Reject the application',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.SUBMIT_SIGNED_CONTRACT_TEXT': 'Once you have attached the signed agreement, you can request proof of payment from the HZZ coordinator.',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.SUBMIT_SIGNED_CONTRACT_BUTTON_TEXT': 'Request proof of payment',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.GENERATE_CONTRACT_TEXT': 'When you enter the data, you can generate an agreement',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.GENERATE_CONTRACT_BUTTON_TEXT': 'Generate agreement',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.EDUCATION_COMPLETED_BUTTON_TEXT': 'Education completed',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR_ADD_LABEL': 'Add coordinator',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR_CHANGE_LABEL': 'Change coordinator',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR_SEARCH_PLACEHOLDER': 'Find coordinator',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR_VALIDATION_MESSAGE': 'Please find another coordinator',

  // agreement form
  'EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.CODE_OFFER_LABEL': 'Number of offer',
  'EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.EDUCATION_EXPENSE_LABEL': 'Cost of education program (without VAT)',
  'EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.DISTRICT_OFFICE_ADDRESS_LABEL': 'PS/PU Headquarter',
  'EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.CODE_OFFER_PLACEHOLDER': 'Enter number of offer',
  'EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.EDUCATION_EXPENSE_PLACEHOLDER': 'Enter the cost amount',
  'EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.DISTRICT_OFFICE_ADDRESS_PLACEHOLDER': 'Enter headquarter PS/PU',
  //  agreement form validation message
  'EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.VALIDATION.CODE_OFFER_MESSAGE': 'Please enter the offer number',
  'EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.VALIDATION.EDUCATION_EXPENSE_MESSAGE': 'Please enter the expense amount',
  'EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.VALIDATION.DISTRICT_OFFICE_ADDRESS_MESSAGE': 'Please enter the headquarter PS/PU',

  // ---------- External education application timeline
  'EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.TITLE': 'Education application activities and notes',
  'EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.EXTERNAL_EDUCATION_APPLICATION_SUBMITTED': '%{name} has submitted an application.',
  'EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.EXTERNAL_EDUCATION_APPLICATION_APPROVED': '%{name} has approved an application.',
  'EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.EXTERNAL_EDUCATION_APPLICATION_DENIED': '%{name}nhas denied the application to the user.',
  'EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.COORDINATOR_ADDED': '%{name} was added as a coordinator.',
  'EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.COORDINATOR_REMOVED': '%{name} has been removed as a coordinator.',
  'EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.SIGNED_FINANCING_AGREEMENT_REQUESTED': '%{name} requested a signed agreement.',
  'EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.FILE_ATTACHED': '%{name} has attached a file %{filename}.',
  'EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.EDUCATION_COMPLETED': '%{name} has completed the education program.',
  'EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.SURVEY_SUBMITTED': '%{name} completed the survey. ',
  'EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.NOTE.TITLE': 'The HZZ coordinator wrote a note.',
  'EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.UNKNOWN_FILE_TITLE': '[Unknown file]',
  'EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.EXTERNAL_EDUCATION_APPLICATION_RETURNED_TO_USER': 'Return to the user',

  // ---------- Education outcome picker
  'EDUCATION_OUTCOME.PICKER.OUTCOME_PLACEHOLDER': 'Search for existing outcome',
  'EDUCATION_OUTCOME.PICKER.OUTCOME_EMPTY_MESSAGE': 'No outcomes found',

  // ----------- External education template tabs
  'EXTERNAL_EDUCATION_TEMPLATE.TABS.EDUCATION_INSTANCES_LABEL': 'Education instances',
  'EXTERNAL_EDUCATION_TEMPLATE.TABS.EDUCATION_PROVIDER_LABEL': 'Education provider',
  'EXTERNAL_EDUCATION_TEMPLATE.TABS.RATING_LABEL': 'Rating',
  'EXTERNAL_EDUCATION_TEMPLATE.TABS.ACTIVITY_HISTORY_LABEL': 'Activity',

  // ----------- External education template
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.VERIFICATION_BUTTON_LABEL': 'Send to verification',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.COST_LABEL': '%{minPrice} kn - %{maxPrice} kn',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.COST_SPAN_DESCRIPTION': 'cost span',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.CONTRACTOR_QUALIFICATION_DESCRIPTION': 'contractor qualification',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_OUTCOME_DESCRIPTION': 'education outcome',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.CERTIFICATE_NAME_DESCRIPTION': 'name of certificate upon completion',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_PROVIDER_NAME_LABEL': 'education provider',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_PROVIDER_ADDRESS_LABEL': 'provider address',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_PROVIDER_IDENTIFICATION_NUMBER_LABEL': 'provider OIB',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_PROVIDER_EMAIL_LABEL': 'provider email',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.DETAILS_TITLE': 'Program details',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_SECTOR_TITLE': 'Education sector',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.SKILLS_TITLE': 'Skills acquired upon completion of the educational program',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.DESCRIPTION_TITLE': 'Program description',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_DESCRIPTION': 'education',
  'EXTERNAL_EDUCATION_TEMPLATE_RATE.VIEW_SECTION.HEADER_TITLE': 'Ratings visible to advisors and providers',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.ENROLLMENT_REQUIREMENTS_TITLE': 'Education programe enrollment requirements',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.ENROLLMENT_REQUIREMENTS_DESCRIPTION': 'enrollment requirement',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_DURATION': '%{duration} hours',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_DURATION_LABEL': 'education duration',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_OUTCOME_LABEL': 'education outcome',

  'EDUCATION_PROVIDER.VIEW_SECTION.HEADER_TITLE': 'Education provider details',
  'EDUCATION_PROVIDER.VIEW_SECTION.SHOW_PROFILE_BUTTON_LABEL': 'See profile',

  // ---------- External education template list
  'EXTERNAL_EDUCATION_TEMPLATE_LIST_VIEW.ADD_EXTERNAL_EDUCATION_BUTTON LABEL': 'New external education',
  'EXTERNAL_EDUCATION_TEMPLATE_LIST_VIEW.PAGE_TITLE': 'External educations',
  'EXTERNAL_EDUCATION_TEMPLATE_LIST_VIEW.NO_DATA': 'No external education',
  'EXTERNAL_EDUCATION_TEMPLATE_LIST_VIEW.SKILLS_LABEL': 'Skills',

  // ---------- External education template status
  'EXTERNAL_EDUCATION_TEMPLATE.STATUS.IN_CREATION': 'In creation',
  'EXTERNAL_EDUCATION_TEMPLATE.STATUS.CREATION_FINISHED': 'Pending approval',
  'EXTERNAL_EDUCATION_TEMPLATE.STATUS.PUBLISHED': 'Published',
  'EXTERNAL_EDUCATION_TEMPLATE.STATUS.ARCHIVED': 'Archived',

  // ---------- External education template table
  'EXTERNAL_EDUCATION_TEMPLATE.TABLE.EDUCATION_PROVIDER_HEADER_LABEL': 'Education provider name',
  'EXTERNAL_EDUCATION_TEMPLATE.TABLE.EXTERNAL_EDUCATION_TEMPLATE_TITLE_LHEADER_ABEL': 'Title',
  'EXTERNAL_EDUCATION_TEMPLATE.TABLE.CREATION_STATUS_HEADER_LABEL': 'Creation status',
  'EXTERNAL_EDUCATION_TEMPLATE.TABLE.ACTIVITY_STATUS_HEADER_LABEL': 'Activity status',
  'EXTERNAL_EDUCATION_TEMPLATE.TABLE.COORDINATOR_HEADER_LABEL': 'Coodinator',
  'EXTERNAL_EDUCATION_TEMPLATE.TABLE.NOT_ASSIGNED_LABEL': 'Not assigned',
  'EXTERNAL_EDUCATION_TEMPLATE.TABLE.NOT_SUBMITTED_LABEL': 'Not sumbitted',
  'EXTERNAL_EDUCATION_TEMPLATE.TABLE.SUMBITTED_AT_HEADER_LABEL': 'Submited at',

  // ---------- External education template activity status
  'EXTERNAL_EDUCATION_TEMPLATE.ACTIVITY_PHASE.APPROVAL_REQUESTED': 'Approval requested',
  'EXTERNAL_EDUCATION_TEMPLATE.ACTIVITY_PHASE.APPROVAL_IN_PROCESS': 'Approval in process',
  'EXTERNAL_EDUCATION_TEMPLATE.ACTIVITY_PHASE.CHANGE_REQUESTED': 'Change requested',
  'EXTERNAL_EDUCATION_TEMPLATE.ACTIVITY_PHASE.CHANGE_SUBMITTED': 'Change submitted',
  'EXTERNAL_EDUCATION_TEMPLATE.ACTIVITY_PHASE.APPROVED': 'Approved',
  'EXTERNAL_EDUCATION_TEMPLATE.ACTIVITY_PHASE.DENIED': 'Denied',
  'EXTERNAL_EDUCATION_TEMPLATE.ACTIVITY_PHASE.CANCELLED': 'Cancelled',

  // ---------- External education template picker
  'EXTERNAL_EDUCATION_TEMPLATE.PICKER.SEARCH_PLACEHOLDER': 'Search external education template',

  // ---------- External education template form
  'EXTERNAL_EDUCATION_TEMPLATE.FORM.TITLE_LABEL': 'Title',
  'EXTERNAL_EDUCATION_TEMPLATE.FORM.SKILLS_LABEL': 'The skills that the trainee acquires through the program',
  'EXTERNAL_EDUCATION_TEMPLATE.FORM.DESCRIPTION_LABEL': 'Description',
  'EXTERNAL_EDUCATION_TEMPLATE.FORM.EDUCATION_DURATION_SUFFIX': 'hours',
  'EXTERNAL_EDUCATION_TEMPLATE.FORM.EDUCATION_CATEGORY_LABEL': 'Indicate if the program belongs to one of the listed education categories',
  'EXTERNAL_EDUCATION_TEMPLATE.FORM.EDUCATION_SECTOR_LABEL': 'Education sector',
  'EXTERNAL_EDUCATION_TEMPLATE.FORM.DURATION_LABEL': 'Duration',
  'EXTERNAL_EDUCATION_TEMPLATE.FORM.MIN_PRICE_LABEL': 'Min price',
  'EXTERNAL_EDUCATION_TEMPLATE.FORM.MAX_PRICE_LABEL': 'Max price',
  'EXTERNAL_EDUCATION_TEMPLATE.FORM.EDUCATION_OUTCOMES_LABEL': 'Education outcomes',
  'EXTERNAL_EDUCATION_TEMPLATE.FORM.ENROLLMENT_REQUIREMENTS_LABEL': 'Enrollment requirements',
  'EXTERNAL_EDUCATION_TEMPLATE.FORM.CERTIFICATE_NAME_LABEL': 'Name of the certificate of completed education program',
  'EXTERNAL_EDUCATION_TEMPLATE.FORM.CONTRACTOR_QUALIFICATION_LABEL': 'Contractor qualification',
  'EXTERNAL_EDUCATION_TEMPLATE.MODAL.TITLE': 'New education template',
  'EXTERNAL_EDUCATION_TEMPLATE.FORM.EDUCATION_DURATION_PLACEHOLDER': 'Enter education duration',
  // info message
  'EXTERNAL_EDUCATION_TEMPLATE.CREATE_INFO_MESSAGE': 'You have successfully created external education template',
  'EXTERNAL_EDUCATION_TEMPLATE.UPDATE_INFO_MESSAGE': 'You have successfully updated external education template',
  'EXTERNAL_EDUCATION_TEMPLATE.DELETE_INFO_MESSAGE': 'You have successfully deleted external education template',

  // ---------- External education template coordinator
  'EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.APPROVAL_REQUESTED_TEXT': 'By accepting the education program, you will begin the verification process.',
  'EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.APPROVAL_IN_PROCCESS_TEXT': 'Review the program. If the program meets the criteria, select "Verify", if it does not match, select "Do not verify". In case the necessary information is missing, request information by selecting "Return to education provider".',
  'EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.VERIFY_EDUCATION_TEMPLATE_BUTTON_TEXT': 'Verify',
  'EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.TAKE_EDUCATION_TEMPLATE_BUTTON_TEXT': 'Accept verification',
  'EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.RETURN_EDUCATION_TEMPLATE_BUTTON_TEXT': 'Return to education provider',
  'EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.RETURN_EDUCATION_TEMPLATE_MODAL_TITLE': 'Return the education program to the provider',
  'EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.RETURN_EDUCATION_TEMPLATE_PLACEHOLDER': 'Reason for returning the education program.',
  'EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.REJECT_VERIFICATION_EDUCATION_TEMPLATE_BUTTON_TEXT': 'Reject',
  'EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.REJECT_VERIFICATION_EDUCATION_TEMPLATE_MODAL_TITLE': 'Reject education program',
  'EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.REJECT_VERIFICATION_EDUCATION_TEMPLATE_PLACEHOLDER': 'Reason for not verifying the education program.',

  '.SEARCH_PLACEHOLDER': 'Search for education programe',
  'EXTERNAL_EDUCATION.VIEW_SECTION.HEADER_TITLE': 'About education',
  'EXTERNAL_EDUCATION.VIEW_SECTION.EDUCATION_PROVIDER_NAME_LABEL': 'education provider',
  'EXTERNAL_EDUCATION.VIEW_SECTION.EDUCATION_PROVIDER_ADDRESS_LABEL': 'provider address',
  'EXTERNAL_EDUCATION.VIEW_SECTION.EDUCATION_PROVIDER_IDENTIFICATION_NUMBER_LABEL': 'provider OIB',
  'EXTERNAL_EDUCATION.VIEW_SECTION.EDUCATION_PROVIDER_EMAIL_LABEL': 'provider email',
  'EXTERNAL_EDUCATION.VIEW_SECTION.EDUCATION_TITLE_LABEL': 'education',
  'EXTERNAL_EDUCATION.VIEW_SECTION.EDUCATION_DURATION': '%{duration} hours',
  'EXTERNAL_EDUCATION.VIEW_SECTION.EDUCATION_DURATION_LABEL': 'education duration',
  'EXTERNAL_EDUCATION.VIEW_SECTION.EDUCATION_OUTCOME_LABEL': 'education outcome',

  // ---------- External education instance list
  'EXTERNAL_EDUCATION_INSTANCE_LIST_VIEW.ADD_EXTERNAL_EDUCATION_INSTANCE_BUTTON_LABEL': 'New education instance',
  'EXTERNAL_EDUCATION_INSTANCE_LIST.START_DATE_TIME_LABEL': 'Start date time',
  'EXTERNAL_EDUCATION_INSTANCE_LIST.ADDRESS_LABEL': 'Location',
  'EXTERNAL_EDUCATION_INSTANCE_LIST.EXECUTION_METHOD_LABEL': 'Execution method',
  'EXTERNAL_EDUCATION_INSTANCE_LIST.PRICE_LABEL': 'Price',
  'EXTERNAL_EDUCATION_INSTANCE_LIST.CONTRACTORS_LABEL': 'Tutors',

  // ---------- External education instance form
  'EXTERNAL_EDUCATION_INSTANCE.FORM.START_DATE_TIME_LABEL': 'Start date',
  'EXTERNAL_EDUCATION_INSTANCE.FORM.EXECUTION_METHOD_LABEL': 'Execution method',
  'EXTERNAL_EDUCATION_INSTANCE.FORM.ADDRESS_LABEL': 'Location',
  'EXTERNAL_EDUCATION_INSTANCE.FORM.PRICE_LABEL': 'Price',
  'EXTERNAL_EDUCATION_INSTANCE.FORM.CONTRACTORS_LABEL': 'Contractors',
  'EXTERNAL_EDUCATION_INSTANCE.FORM.CONTRACTORS_PLACEHOLDER': 'Enter contractors first and last names',
  'EXTERNAL_EDUCATION_INSTANCE.FORM.DESCRIPTION_LABEL': 'Additional execution information (eg schedule)',

  // ---------- Execution method options
  'EXECUTION_METHOD.SELECT_OPTION.ONLINE_LABEL': 'Online',
  'EXECUTION_METHOD.SELECT_OPTION.EDUCATION_PROVIDER_LABEL': 'U obrazovnoj ustanovi',
  'EXECUTION_METHOD.SELECT_OPTION.EMPLOYER_LABEL': 'Kod poslodavca',
  'EXECUTION_METHOD.SELECT_OPTION.COMBINED_LABEL': 'Kombinirano',

  // ----------- External education application statistics
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.GRADE_TITLE': 'Grade',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.SURVEYS_BY_SURVEY_TYPE': 'Number of completed surveys by type',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.SUBMITTED_SURVEYS': 'Total completed surveys',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.EDUCATION_PROVIDER_SURVEYS': 'education provider',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.EMPLOYER_SURVEYS': 'employer',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.USER_EDUCATION_COMPLETED_SURVEYS': 'users immediately upon completion',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.SURVEY_QUESTION_LABEL': '%{orderNumber}. %{questionText} (%{numberOfAnswers} answers)',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.EDUCATION_PROVIDER_SURVEY_AVERAGE_QUESTION_TITLE': 'Average answers per question "Survey for education program providers"',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.EMPLOYER_SURVEY_AVERAGE_QUESTION_TITLE': 'Average answers per question "Survey for employers"',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.USER_EDUCATION_COMPLETED_SURVEY_AVERAGE_QUESTION_TITLE': 'Average answers per question "Survey for user after 6 months"',

  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.MODAL.STATISTICS_TITLE': 'Statistics',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.STATISTICS_COUNT_TITLE': 'Number of applications',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.STATISTICS_TOTAL_COUNT_TITLE': 'Total number of applications',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.STATISTICS_COUNT_PER_APPLICATION_STATUS_TITLE': 'According to the status of the application',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.STATISTICS_COUNT_PER_EMPLYOMENT_STATUS_TITLE': 'According to the employment status of the user',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.STATISTICS_COUNT_SUBMITTED_APPLICATIONS': 'submitted applications',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.STATISTICS_COUNT_APPLICATIONS_IN_PROGRESS': 'applications in progress',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.STATISTICS_COUNT_APPLICATIONS_IN_PROCESS': 'applications in process',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.STATISTICS_COUNT_APPLICATIONS_COMPLETED': 'completed applications',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.STATISTICS_COUNT_APPLICATIONS_EMPLOYED': 'employed',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.STATISTICS_COUNT_APPLICATIONS_UNEMPLYOED': 'unemployed',

  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.STATISTICS_AVERAGE_EXPENSES_TITLE': 'Average cost in HRK',

  // ----------- Admin codebooks
  'CODEBOOK.CODEBOOK_TITLE': 'Codebooks',
  'CODEBOOK.TITLE.EDUCATION_QUALIFICATION': 'Education qualification codebook',
  'CODEBOOK.TITLE.GRADE': 'Grades codebook',
  'CODEBOOK.TITLE.SKILL_LEVEL': 'Skill level codebook',
  'CODEBOOK.TITLE.ENROLLMENT_REQUIREMENT': 'Enrollment requirement',
  'CODEBOOK.TITLE.EDUCATION_SECTOR': 'Education sector',
  'CODEBOOK.TITLE.EDUCATION_CATEGORY': 'Education category',

  // ------ Grade
  'CODEBOOK_GRADE.CREATE_CODED_GRADE_BUTTON_LABEL': 'Create new grade',
  'CODEBOOK_GRADE.CODED_GRADE_DESCRIPTION_LABEL': 'Description:',
  'CODEBOOK_GRADE_FORM.TITLE': 'Codebook grade creation',
  'CODEBOOK_GRADE_FORM.NAME_LABEL': 'Name',
  'CODEBOOK_GRADE_FORM.DESCRIPTION_LABEL': 'Description',
  'CODEBOOK_GRADE_FORM_FORM.ORDERING_LABEL': 'Ordering',
  // info message
  'CODEBOOK_GRADE.CREATE_INFO_MESSAGE': 'You have successfully created a grade codebook',
  'CODEBOOK_GRADE.DELETE_INFO_MESSAGE': 'You have successfully deleted a grade codebook',
  // error message
  'CODEBOOK_GRADE.ERROR.INVALID_ORDERING': 'Coded grade with ordering number already exist',

  // ------ Education qualification
  'EDUCATION_QUALIFICATION.CREATE_EDUCATION_QUALIFICATION_BUTTON_LABEL': 'Create new education qualificaiton',
  'EDUCATION_QUALIFICATION_FORM.TITLE': 'Education qualification creation',
  'EDUCATION_QUALIFICATION_FORM.NAME_LABEL': 'Name',
  'EDUCATION_QUALIFICATION_FORM.DESCRIPTION_LABEL': 'Description',
  // info message
  'EDUCATION_QUALIFICATION.CREATE_INFO_MESSAGE': 'You have successfully created a education qualification codebook',
  'EDUCATION_QUALIFICATION.DELETE_INFO_MESSAGE': 'You have successfully deleted a education qualification codebook',

  // ------ LOCATION
  'LOCATION_CODEBOOK.CREATE_LOCATION_BUTTON_LABEL': 'Create new location',
  'LOCATION_CODEBOOK.LOCATION_DESCRIPTION_LABEL': 'Location type:',
  'LOCATION_CODEBOOK_FORM.TITLE': 'Location creation',
  'LOCATION_CODEBOOK_FORM.TITLE_LABEL': 'Name',
  'LOCATION_CODEBOOK_FORM.ADRESS_LABEL': 'Address',
  'LOCATION_CODEBOOK_FORM.LOCATION_TYPE_LABEL': 'Location type',
  'LOCATION_CODEBOOK_FORM.MANAGER_LABEL': 'Manager',
  'LOCATION_CODEBOOK_FORM.CONTACT_DATA_LABEL': 'Contact',
  'LOCATION_TYPE.DISTRICT_OFFICE_LABEL': 'District office',
  // info message
  'LOCATION_CODEBOOK.CREATE_INFO_MESSAGE': 'You have successfully created a location codebook',
  'LOCATION_CODEBOOK.DELETE_INFO_MESSAGE': 'You have successfully deleted a location codebook',

  // ------ Enrollment requirement
  'ENROLLMENT_REQUIREMENT.CREATE_BUTTON_LABEL': 'Create new enrollment requirement',
  'ENROLLMENT_REQUIREMENT_FORM.TITLE': 'Enrollment requirement creation',
  'ENROLLMENT_REQUIREMENT_FORM.NAME_LABEL': 'Name',
  'ENROLLMENT_REQUIREMENT_FORM.DESCRIPTION_LABEL': 'Description',
  'ENROLLMENT_REQUIREMENT.DESCRIPTION_LABEL': 'Description:',
  // info message
  'ENROLLMENT_REQUIREMENT.CREATE_INFO_MESSAGE': 'You have successfully created a enrollment requirement codebook',
  'ENROLLMENT_REQUIREMENT.DELETE_INFO_MESSAGE': 'You have successfully deleted a enrollment requirement codebook',

  // ------ Education sector
  'EDUCATION_SECTOR.CREATE_BUTTON_LABEL': 'Create new education sector',
  'EDUCATION_SECTOR_FORM.TITLE': 'Education sector creation',
  'EDUCATION_SECTOR_FORM.NAME_LABEL': 'Name',
  'EDUCATION_SECTOR_FORM.DESCRIPTION_LABEL': 'Description',
  'EDUCATION_SECTOR.DESCRIPTION_LABEL': 'Description:',
  // info message
  'EDUCATION_SECTOR.CREATE_INFO_MESSAGE': 'You have successfully created a education sector codebook',
  'EDUCATION_SECTOR.DELETE_INFO_MESSAGE': 'You have successfully deleted a education sector codebook',

  // ------ Education category
  'EDUCATION_CATEGORY.CREATE_BUTTON_LABEL': 'Create new education category',
  'EDUCATION_CATEGORY_FORM.TITLE': 'Education category creation',
  'EDUCATION_CATEGORY_FORM.NAME_LABEL': 'Name',
  'EDUCATION_CATEGORY_FORM.DESCRIPTION_LABEL': 'Description',
  'EDUCATION_CATEGORY.DESCRIPTION_LABEL': 'Description:',
  // info message
  'EDUCATION_CATEGORY.CREATE_INFO_MESSAGE': 'You have successfully created a education category codebook',
  'EDUCATION_CATEGORY.DELETE_INFO_MESSAGE': 'You have successfully deleted a education category codebook',

  // ----------- Contact data property input
  'CONTACT_DATA_PROPERTY_INPUT.ADD_CONTACT_LABEL': 'Add contact',
  'CONTACT_DATA_PROPERTY_INPUT.TYPE.EMAIL_LABEL': 'E-mail',
  'CONTACT_DATA_PROPERTY_INPUT.TYPE.PHONE_LABEL': 'Phone',
  // error message
  'CONTACT_DATA_PROPERTY_INPUT.ERROR_MESSAGE.MINIMUM_FIELD_REQUIRED': 'Please enter at least one contact',

  // ----------- Address property input
  'ADDRESS_PROPERTY_INPUT.STREET_WITH_HOME_NUMBER_LABEL': 'Street with home number',
  'ADDRESS_PROPERTY_INPUT.CITY_LABEL': 'City',
  'ADDRESS_PROPERTY_INPUT.ZIP_LABEL': 'ZIP code',
  'ADDRESS_PROPERTY_INPUT.COUNTRY_LABEL': 'Country',

  // ---------- Address short info
  'ADDRESS_SHORT_INFO_INPUT.CITY_PLACEHOLDER': 'City',
  'ADDRESS_SHORT_INFO_INPUT.STREET_WITH_HOME_NUMBER_PLACEHOLDER': 'Street with home number',

  // ----------- Skill level classification
  'SKILL_LEVEL_CLASSIFICATION_LIST.LEVEL_LABEL': 'Level No',
  'SKILL_LEVEL_CLASSIFICATION_LIST.LEVEL_DESCRIPTION_LABEL': 'Level description:',
  'SKILL_LEVEL_CLASSIFICATION_LIST.LEVEL_NAME_LABEL': 'Level name',

  // ----------- Skill level classification form
  'SKILL_LEVEL_CLASSIFICATION.FORM.LEVEL_DESCRIPTION_LABEL': 'Description level %{levelNumber}',
  'SKILL_LEVEL_CLASSIFICATION.FORM.LEVEL_DESCRIPTION_PLACEHOLDER': 'Enter level description %{levelNumber}',
  'SKILL_LEVEL_CLASSIFICATION.FORM.EDIT_SKILL_LEVEL_BUTTON_LABE': 'Edit levels',

  // ----------- Skill level classification data picker
  'SKILL_LEVEL_CLASSIFICATION_PICKER.LEVEL_DESCRIPTION_LABEL': 'Level description:',

  // ----------- Skill level classification modal
  'SKILL_LEVEL_CLASSIFICATION.MODAL.TITLE': 'Edit skill level classifications',

  // ----------- Organization
  'ORGANIZATION_VIEW.USERS_TAB_LABEL': 'Members',
  'ORGANIZATION_VIEW.USERS_EMPTY': 'This organization has no members',
  'ORGANIZATION_VIEW.DESCRIPTION_LABEL': 'Description',
  'ORGANIZATION.VIEW_IDENTIFICATION_NUMBER_LABEL': 'Oib',
  'ORGANIZATION_VIEW.ADDRESS_LABEL': 'Headquarters',
  'ORGANIZATION_VIEW.URL_LABEL': 'Link to web page',
  'ORGANIZATION_VIEW.CONTACT_LABEL': 'Contact',
  'ORGANIZATION_VIEW.EXTERNAL_EDUCATION_TEMPLATES_TAB_LABEL': 'Education programs',
  'ORGANIZATION_LIST.ADD_BUTTON_LABEL': 'Add new organization',
  'ORGANIZATION_LIST.NAME_FILTER_LABEL': 'Name',
  'ORGANIZATION_FORM.TITLE_CREATE': 'Create new organization profile',
  'ORGANIZATION_FORM.TITLE_EDIT': 'Edit',
  'ORGANIZATION_FORM.DESCRIPTION_LABEL': 'Description',
  'ORGANIZATION_FORM.URL_LABEL': 'Web page',
  'ORGANIZATION_FORM.LOGO_LABEL': 'Logo',
  'ORGANIZATION_FORM.COMPANY_REQUIRED_LABEL': 'Please enter organization details',
  'ORGANIZATION_VIEW.JOINED_ORGANIZATION_INFO_MESSAGE': 'You have successfully joined the organization!',

  'ORGANIZATION_MEMBER_LIST.ROLE_TYPE.OWNER': 'Owner',
  'ORGANIZATION_MEMBER_LIST.ROLE_TYPE.ADMIN': 'Admin',
  'ORGANIZATION_MEMBER_LIST.ROLE_TYPE.MEMBER': 'Member',

  // error messages
  'ORGANIZATION_ERROR_MESSAGE.NON_EXISTING_ORGANIZATION_PROFILE': 'The code does not match any organization in the system',

  // ----------- Payment Info
  'PAYMENT_INFO_FORM.BANK_NAME_LABEL': 'Bank',
  'PAYMENT_INFO_FORM.IBAN_LABEL': 'IBAN',

  // ----------- SkillBased Recommendations
  'SKILL_BASED_RECOMMENDATION.FILTER.TYPE_LABEL': 'Recommendation for',
  'SKILL_BASED_RECOMMENDATION.FILTER.TYPE_USER_LABEL': 'user',
  'SKILL_BASED_RECOMMENDATION.FILTER.TYPE_WORKPOSITION_LABEL': 'work position',
  'SKILL_BASED_RECOMMENDATION.FILTER.USER_LABEL': 'User',
  'SKILL_BASED_RECOMMENDATION.FILTER.WORKPOSITION_LABEL': 'Work position',
  'SKILL_BASED_RECOMMENDATION.FILTER.VALUE_RELATION_LABEL': 'Value relation',
  'SKILL_BASED_RECOMMENDATION.FILTER.MAX_ABSOLUTE_DIFF_LABEL': 'Maximum difference',
  'SKILL_BASED_RECOMMENDATION.FILTER.RELATION_TYPE_LOWER': 'Lower',
  'SKILL_BASED_RECOMMENDATION.FILTER.RELATION_TYPE_HIGHER': 'Higher',
  'SKILL_BASED_RECOMMENDATION.FILTER.RELATION_TYPE_BETWEEN': 'Between',
  'SKILL_BASED_RECOMMENDATION.FILTER.VALUE_REALTION_TOOLTIP': 'Less - all with lower skill level within maximum difference. More - all with higher skill level within maximum difference. Between - all with lower/higher skill level within maximum difference',
};

export default en;
