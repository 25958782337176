import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import UserGroupAccessFormWrapper from '@src/components/usergroup/form/UserGroupAccessForm';
import UserGroupView from '@src/components/usergroup/view/UserGroupView';
import { IUserGroup } from '@src/model/usergroup/UserGroup';
import UserGroupBusinessStore from '@src/service/business/usergroup/UserGroupBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

// -- Const
// ----------
const USER_GROUP_ROUTE = AppConfigService.getValue('components.userGroups.userGroupRouterProp');

// -- Prop types
// ----------

export interface IUserGroupContainerOwnProps {
  userGroupId: string;
}

export interface IUserGroupContainerStateProps {
  userGroup: IUserGroup;
}

export interface IUserGroupContainerDispatchProps {
  fetchUserGroup: (userGroupId: string) => void;
  clearUserGroup: () => void;
  leaveUserGroup: (userGroupId: string) => ITrackableAction;
  joinUserGroup: (userGroupAccessCode: string) => ITrackableAction;
}

type IUserGroupContainerProps = IUserGroupContainerOwnProps & IUserGroupContainerStateProps & IUserGroupContainerDispatchProps & WithRouterProps & IWithLocalizeOwnProps;

interface IUserGroupContainerState {
  isAccessModalVisible: boolean;
}

// -- Component
// ----------

class UserGroupContainer extends React.Component<IUserGroupContainerProps, IUserGroupContainerState> {
  state = {
    isAccessModalVisible: false,
  };

  componentDidMount() {
    this.fetchUserGroup();
  }

  componentDidUpdate(prevProps: IUserGroupContainerProps, prevState: IUserGroupContainerState) {
    if (this.props.userGroupId !== prevProps.userGroupId) {
      this.fetchUserGroup();
    }
  }

  componentWillUnmount() {
    this.props.clearUserGroup();
  }

  render() {
    return (
      <React.Fragment>
        <UserGroupView userGroup={this.props.userGroup} onUserGroupLeave={this.handleUserGroupLeave} onUserGroupJoin={this.handleUserGroupJoin} />

        {this.state.isAccessModalVisible && <UserGroupAccessFormWrapper title={this.props.translate('ACCESS_CODE_FORM.TITLE')} onSubmit={this.handleSubmit} onCancel={this.handleCancel} />}
      </React.Fragment>
    );
  }

  fetchUserGroup = () => {
    this.props.fetchUserGroup(this.props.userGroupId);
  };

  handleUserGroupJoin = () => {
    this.toggleAccessModal(true);
  };

  toggleAccessModal = (isVisible: boolean) => {
    this.setState({ isAccessModalVisible: isVisible });
  };

  handleCancel = () => {
    this.toggleAccessModal(false);
  };

  handleSubmit = (accessCode: string) => {
    this.props
      .joinUserGroup(accessCode)
      .track()
      .subscribe(
        // Success
        () => {
          this.fetchUserGroup();
          this.toggleAccessModal(false);
        },
      );
  };

  handleUserGroupLeave = () => {
    confirmationDialog({
      title: this.props.translate('USER_GROUP.LEAVE_CONFIRM_TITLE'),
      okText: this.props.translate('USER_GROUP.COMMON_ACTION_LEAVE'),
      onConfirm: () =>
        this.props
          .leaveUserGroup(this.props.userGroupId)
          .track()
          .subscribe(() => {
            this.props.router.push(USER_GROUP_ROUTE);
          }),
    });
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IUserGroupContainerStateProps => ({
  userGroup: UserGroupBusinessStore.selectors.getUserGroup(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IUserGroupContainerDispatchProps => ({
  fetchUserGroup: (userGroupId: string) => dispatch(UserGroupBusinessStore.actions.fetchUserGroup(userGroupId)),
  clearUserGroup: () => dispatch(UserGroupBusinessStore.actions.clearUserGroup()),
  leaveUserGroup: (userGroupId: string) => createTrackableAction(dispatch(UserGroupBusinessStore.actions.leaveUserGroup(userGroupId))),
  joinUserGroup: (userGroupAccessCode: string) => createTrackableAction(dispatch(UserGroupBusinessStore.actions.joinUserGroup(userGroupAccessCode))),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withRouter<IUserGroupContainerOwnProps>(UserGroupContainer as any)));
