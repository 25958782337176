import { IActivity } from '@src/model/activity/Activity';
import { IActivityParticipant, ParticipantRoleEnum } from '@src/model/activity/ActivityParticipant';
import { ActivityPhaseEnum } from '@src/model/activity/ActivityPhase';

export class ActivityUtils {
  /** Return participant by requested role. */
  static getParticipantByRole(requestedRole: ParticipantRoleEnum, activity: IActivity): IActivityParticipant | undefined {
    return activity.participants.find((paritcipant: IActivityParticipant) => paritcipant.participantRole === requestedRole);
  }

  /** Returns participant name by requested role. */
  static getParticipantNameByRole(requestedRole: ParticipantRoleEnum, activity?: IActivity): string | undefined {
    const participant = activity?.participants.find((paritcipant: IActivityParticipant) => paritcipant.participantRole === requestedRole);
    if (participant) {
      return `${participant?.firstName} ${participant?.lastName}`;
    } else {
      return undefined;
    }
  }

  /** Check if activity is in one of listed phases. */
  static isInPhases(requestedPhases: ActivityPhaseEnum[], activity: IActivity): boolean {
    return requestedPhases.some((phase) => activity.currentPhase.id === phase);
  }

  /** Check if userId is in one of participants userId. */
  static isParticipant(userId: string, activity: IActivity): boolean {
    return activity.participants.some((participant) => participant.userId === userId);
  }
}
