import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';
import SurveyTemplateContainer from '@src/components/survey/SurveyTemplateContainer';

type IAdminSurveyTemplatePageProps = WithRouterProps;

const AdminSurveyTemplatePage = (props: IAdminSurveyTemplatePageProps) => <SurveyTemplateContainer surveyTemplateId={props.params.surveyTemplateId} />;

export default withRouter(AdminSurveyTemplatePage as any);


