import { ITag } from '@src/model/tag/Tag';
import { ITagItemType } from '@src/model/tag/TagItemType';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, IIdDataPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError, createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { actionThunk, trackAction } from '@src/service/util/observable/operators';
import { reportCaughtMessage, startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators/userFeedback';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';


// -
// -------------------- Types&Consts

export interface ITagListFilter {
  title?: string;
}

export interface ITagEntityPayload {
  objectIds: string[];
  tags: ITag[];
  type: ITagItemType;
}


export type ITagCreatePayload = Pick<ITag, Exclude<keyof ITag, 'id' | 'itemCount'>>;

// List filter ID

const TAG_LIST_FILTER = '@@TAG_LIST_FILTER';

// -
// -------------------- Selectors

const getTagList = (store: any): ICollectionData<ITag> => store.tagList;
const getTagListFilter = (store: any): ITagListFilter => ListFilterBusinessStore.selectors.getListFilter(store, TAG_LIST_FILTER);

// -
// -------------------- Actions

const Actions = {
  TAG_LIST_FETCH: 'TAG_LIST_FETCH',
  TAG_LIST_LOAD: 'TAG_LIST_LOAD',
  TAG_LIST_CLEAR: 'TAG_LIST_CLEAR',
  TAG_CREATE: 'TAG_CREATE',
  TAG_UPDATE: 'TAG_UPDATE',
  TAG_ADD_TO_ENTITY: 'TAG_ADD_TO_ENTITY',
  TAG_REMOVE_FROM_ENTITY: 'TAG_REMOVE_FROM_ENTITY',
};

const fetchTagList = (listFilter: ITagListFilter, page: number, size: number, sort: string[]): IPayloadAction<ICollectionFetchPayload<ITagListFilter>> => {
  return {
    type: Actions.TAG_LIST_FETCH,
    payload: {
      filter: listFilter,
      page,
      size,
      sort,
    },
  };
};

const loadTagList = (data: ICollectionData<ITag>): IPayloadAction<ICollectionData<ITag>> => {
  return {
    type: Actions.TAG_LIST_LOAD,
    payload: data,
  };
};

const clearTagList = (): ILemonAction => {
  return {
    type: Actions.TAG_LIST_CLEAR,
  };
};

const createTag = (data: ITagCreatePayload): IPayloadAction<ITagCreatePayload> => {
  return {
    type: Actions.TAG_CREATE,
    payload: data,
  };
};

const updateTag = (tagId: string, data: ITag): IPayloadAction<IIdDataPayload<ITag>> => {
  return {
    type: Actions.TAG_UPDATE,
    payload: {
      id: tagId,
      data,
    },
  };
};

const addTagToEntity = (objectIds: string[], tags: ITag[], type: ITagItemType): IPayloadAction<ITagEntityPayload> => {
  return {
    type: Actions.TAG_ADD_TO_ENTITY,
    payload: {
      objectIds,
      tags,
      type,
    },
  };
};

const removeTagFromEntity = (objectIds: string[], tags: ITag[], type: ITagItemType): IPayloadAction<ITagEntityPayload> => {
  return {
    type: Actions.TAG_REMOVE_FROM_ENTITY,
    payload: {
      objectIds,
      tags,
      type,
    },
  };
};

// -
// -------------------- Effects

const fetchTagListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<ITagListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TAG_LIST_FETCH;
    }),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Tag').fetchEntityList(payload).pipe(
        trackAction(action)
      );
    }),

    map((data) => {
      return loadTagList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching tag list', error);
      return o;
    })
  );
};

const createTagEffect = (action$: Observable<IPayloadAction<string>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TAG_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return (EntityApiServiceRegistry.getService('Tag')).createEntity(data).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_SEND_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating tag', error);
      return o;
    })
  );
};

const updateTagEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ITag>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TAG_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return (EntityApiServiceRegistry.getService('Tag')).updateEntity(id, data).pipe(
        actionThunk(action)
      );
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating tag', error);
      return o;
    })
  );
};

const addTagToEntityEffect = (action$: Observable<IPayloadAction<ITagEntityPayload>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TAG_ADD_TO_ENTITY;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      return (EntityApiServiceRegistry.getService('Tag').createMethod('items', action.payload)).pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'TAG_ADD.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error adding tags', error);
      return o;
    })
  );
};

const removeTagFromEntityEffect = (action$: Observable<IPayloadAction<ITagEntityPayload>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TAG_REMOVE_FROM_ENTITY;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      return (EntityApiServiceRegistry.getService('Tag').deleteMethod('items', action.payload).pipe(actionThunk(action)));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error adding tags', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const tagList = (state: ICollectionData<ITag> | null = null, action: IPayloadAction<ICollectionData<ITag>>) => {
  if (action.type === Actions.TAG_LIST_LOAD) {
    return {
      ...action.payload,
    };
  }
  else if (action.type === Actions.TAG_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

const TagBusinessStore = {
  actions: {
    fetchTagList,
    clearTagList,
    createTag,
    updateTag,
    addTagToEntity,
    removeTagFromEntity,
  },

  selectors: {
    getTagList,
    getTagListFilter,
  },

  effects: {
    fetchTagListEffect,
    createTagEffect,
    updateTagEffect,
    addTagToEntityEffect,
    removeTagFromEntityEffect,
  },

  reducers: {
    tagList,
  },
};

export default TagBusinessStore;
