import React from 'react';
import { connect } from 'react-redux';

import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';

export interface IWhenUserAuthenticatedPublicProps {
  fallback: string | React.ReactNode;
  children: React.ReactNode;
}
export interface IWhenUserAuthenticatedStateProps {
  isUserLoggedIn: boolean;
}

type IWhenUserAuthenticatedProps = IWhenUserAuthenticatedPublicProps & IWhenUserAuthenticatedStateProps;

/**
 * Component provides control logic for showing content only if user has been authenticated (aka. is-logged-in).
 */
const WhenUserAuthenticated: React.FC<IWhenUserAuthenticatedProps> = (props) => {
  return <React.Fragment>{props.isUserLoggedIn ? props.children : props.fallback}</React.Fragment>;
};

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IWhenUserAuthenticatedStateProps => ({
  isUserLoggedIn: LoginBusinessStore.selectors.isUserLoggedIn(state),
});

export default connect<IWhenUserAuthenticatedStateProps, any, IWhenUserAuthenticatedPublicProps>(mapStateToProps)(WhenUserAuthenticated);
