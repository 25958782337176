import { Form } from '@ant-design/compatible';
import { Button, Input, Row } from 'antd';
import Col, { ColProps } from 'antd/lib/col';
import React from 'react';

import { HtmlViewer } from '@src/components/common/htmlviewer/HtmlViewer';
import LemonAvatar from '@src/components/common/image/LemonAvatar';
import LemonImage from '@src/components/common/image/LemonImage';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import ITenantOrganizationConfiguration from '@src/model/tenant/TenantOrganizationConfiguration';
import { LangUtils } from '@src/service/util/LangUtils';

const GRID_LAYOUT_LABEL_LAYOUT: ColProps = { xs: 24, sm: 10, md: 8 };
const GRID_LAYOUT_WRAPPER_LAYOUT: ColProps = { xs: 24, sm: 12, md: 12 };

type Editors = keyof Pick<ITenantOrganizationConfiguration['configuration'], 'logoFileId' | 'backgroundImageFileId'>;

// -- Prop types
// ----------

export interface ITenantOrganizationConfigurationViewOwnProps {
  configuration: ITenantOrganizationConfiguration;

  onEdit?: () => void;
}
type ITenantOrganizationConfigurationViewProps = ITenantOrganizationConfigurationViewOwnProps & IWithLocalizeOwnProps & IWithPathResolverOwnProps;

// -- Component
// ----------

/** Display tenant configuration items. */
const TenantOrganizationConfigurationView: React.FC<ITenantOrganizationConfigurationViewProps> = (props) => {
  return (
    <Form>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.DISPLAY_NAME_LABEL')}>
        {props.configuration.configuration?.displayName}
      </Form.Item>

      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.FOOTER_LABEL')}>
        {props.configuration.configuration?.footerHtml ?
          <HtmlViewer dangerousValue={props.configuration.configuration?.footerHtml} />
          : props.translate('COMMON.ACTION_NO')}
      </Form.Item>

      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.JAVASCRIPT_LABEL')}>
        {props.configuration.configuration?.jsScripts ?
          <Input.TextArea autoSize={{ minRows: 6, maxRows: 6 }} readOnly={true} value={props.configuration.configuration?.jsScripts} />
          : props.translate('COMMON.ACTION_NO')}
      </Form.Item>

      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.LOGO_IMAGE_LABEL')}>
        <LemonAvatar imagePath={getImageFormValue('logoFileId', props)} size="large" />
      </Form.Item>

      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.BACKGROUND_IMAGE_LABEL')}>
        {getImageFormValue('backgroundImageFileId', props) != null && (
          <div className="timun-courseView__coverContainer">
            <LemonImage imagePath={getImageFormValue('backgroundImageFileId', props)} className="timun-courseView__coverImage" />
          </div>
        )}
      </Form.Item>

      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.DISPLAY_TERMS_LABEL')}>
        {props.configuration.configuration?.displayTerms ?
          <HtmlViewer dangerousValue={props.configuration.configuration?.displayTerms} />
          : props.translate('COMMON.ACTION_NO')}
      </Form.Item>

      <Form.Item>
        <Row justify="space-around">
          <Col className="text-center">
            <Button onClick={(e) => props.onEdit?.()}>{props.translate('COMMON.ACTION_EDIT')}</Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

/** Returns image file path or fallback image path */
function getImageFormValue(editorName: Editors, props: ITenantOrganizationConfigurationViewProps): string | undefined {
  const tenantConfiguration = props.configuration;

  const value = tenantConfiguration.configuration[editorName];
  if (!LangUtils.isStringEmpty(value)) {
    return props.resolveApiFilePath(value);
  }
  return;
}

export default withLocalize<ITenantOrganizationConfigurationViewOwnProps>(withPathResolver(TenantOrganizationConfigurationView as any));
