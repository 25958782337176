import { ICollectionData, IIdDataPayload, IIdPayload } from '@src/service/business/common/types';
import { ICollectionFetchPayload } from '@src/service/business/common/types';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

import { ISkillLevelClassificationShortInfo } from '@src/model/skillgroup/SkillLevelClassification';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ILemonAction, IPayloadAction, IStoreListData } from '@src/service/business/common/types';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { trackAction } from '@src/service/util/observable/operators';
import { startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts

export interface ISkillLevelClassificationShortInfoListFilter { }

// List filter ID
const SKILL_LEVEL_CLASSIFICATION_LIST_FILTER = '@@SKILL_LEVEL_CLASSIFICATION_LIST_FILTER';

// -
// -------------------- Selectors

/** Returns data list of skill level classifications from store. */
const getSkillLevelClassificationShortInfoListData = (store: any, skillClassificationListId: string): ICollectionData<ISkillLevelClassificationShortInfo> => store.skillLevelClassificationShortInfoListData[skillClassificationListId];

/** Returns SkillLevelClassification list filter. */
const getSkillLevelClassificationShortInfoListFilter = (store: any): ISkillLevelClassificationShortInfoListFilter => ListFilterBusinessStore.selectors.getListFilter(store, SKILL_LEVEL_CLASSIFICATION_LIST_FILTER);

// -
// -------------------- Actions

const Actions = {
  SKILL_LEVEL_CLASSIFICATION_LIST_FETCH: 'SKILL_LEVEL_CLASSIFICATION_LIST_FETCH',
  SKILL_LEVEL_CLASSIFICATION_LIST_LOAD: 'SKILL_LEVEL_CLASSIFICATION_LIST_LOAD',
  SKILL_LEVEL_CLASSIFICATION_LIST_CLEAR: 'SKILL_LEVEL_CLASSIFICATION_LIST_CLEAR',
  SKILL_LEVEL_CLASSIFICATION_LIST_UPDATE: 'SKILL_LEVEL_CLASSIFICATION_LIST_UPDATE',
  SKILL_LEVEL_CLASSIFICATION_LIST_DATA_CLEAR: 'SKILL_LEVEL_CLASSIFICATION_LIST_DATA_CLEAR',
};

/** Fetch skill level classification list by id. */
const fetchSkillLevelClassificationShortInfoList = (id: string, params: ICollectionFetchPayload<ISkillLevelClassificationShortInfoListFilter>): IPayloadAction<IIdDataPayload<ISkillLevelClassificationShortInfoListFilter>> => {
  return {
    type: Actions.SKILL_LEVEL_CLASSIFICATION_LIST_FETCH,
    payload: {
      data: params,
      id,
    },
  };
};

/** Updagte skill level classification list. */
const updateSkillLevelClassificationShortInfoList = (id: string, data: ISkillLevelClassificationShortInfo[]): IPayloadAction<IIdDataPayload<ISkillLevelClassificationShortInfo[]>> => {
  return {
    type: Actions.SKILL_LEVEL_CLASSIFICATION_LIST_UPDATE,
    payload: {
      data,
      id,
    },
  };
};

/** Load skill level classification list to the data store by skillLevelId. */
const loadSkillLevelClassificationShortInfoList = (skillLevelClassificationListId: string, data: ICollectionData<ISkillLevelClassificationShortInfo>): IPayloadAction<IIdDataPayload<ICollectionData<ISkillLevelClassificationShortInfo>>> => {
  return {
    type: Actions.SKILL_LEVEL_CLASSIFICATION_LIST_LOAD,
    payload: {
      id: skillLevelClassificationListId,
      data,
    },
  };
};

/** Clear skill level classification list from store. Eg. when leaving list view. */
const clearSkillLevelClassificationShortInfoList = (skillClassificationListId: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.SKILL_LEVEL_CLASSIFICATION_LIST_CLEAR,
    payload: {
      id: skillClassificationListId,
    },
  };
};

/** Clear skill level classification list data from store. Eg. when leaving list view. */
const clearSkillLevelClassificationShortInfoListData = (): ILemonAction => {
  return {
    type: Actions.SKILL_LEVEL_CLASSIFICATION_LIST_DATA_CLEAR,
  };
};

/** Store skill level classification list filter to store. */
const storeSkillLevelClassificationShortInfoListFilter = (listFilter: ISkillLevelClassificationShortInfoListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(SKILL_LEVEL_CLASSIFICATION_LIST_FILTER, listFilter);
};

// -
// -------------------- Side-effects

const fetchSkillLevelClassificationShortInfoListEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ISkillLevelClassificationShortInfoListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.SKILL_LEVEL_CLASSIFICATION_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('Skill')
        .fetchSubentityList(id, 'skillLevelClassification', data)
        .pipe(
          trackAction(action),

          map((response) => {
            return { id, response };
          })
        );
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadSkillLevelClassificationShortInfoList(data.id, data.response);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching skill level classification list', error);
      return o;
    })
  );
};

const updateSkillLevelClassificationShortInfoListEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ISkillLevelClassificationShortInfo[]>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.SKILL_LEVEL_CLASSIFICATION_LIST_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('Skill')
        .updateSubentityList(id, 'skillLevelClassification', data)
        .pipe(
          trackAction(action),

          map((response) => {
            return { id, response };
          })
        );
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error skill level classification list update', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const skillLevelClassificationShortInfoListData = (state: IStoreListData<ISkillLevelClassificationShortInfo> = {}, action: IPayloadAction<IIdDataPayload<IStoreListData<ISkillLevelClassificationShortInfo>>>) => {
  if (action.type === Actions.SKILL_LEVEL_CLASSIFICATION_LIST_LOAD) {
    const { id, data } = action.payload;
    return {
      ...state,
      [id]: data,
    };
  } else if (action.type === Actions.SKILL_LEVEL_CLASSIFICATION_LIST_CLEAR) {
    const { id } = action.payload;
    const { [id]: removedSkillLevelList, ...newState } = state;
    return newState;
  } else if (action.type === Actions.SKILL_LEVEL_CLASSIFICATION_LIST_DATA_CLEAR) {
    return {};
  }
  return state;
};

// --
// -------------------- Business Store

const SkillLevelClassificationListBusinessStore = {
  actions: {
    fetchSkillLevelClassificationShortInfoList,
    loadSkillLevelClassificationShortInfoList,
    clearSkillLevelClassificationShortInfoList,
    clearSkillLevelClassificationShortInfoListData,
    storeSkillLevelClassificationShortInfoListFilter,
    updateSkillLevelClassificationShortInfoList,
  },

  selectors: {
    getSkillLevelClassificationShortInfoListData,
    getSkillLevelClassificationShortInfoListFilter,
  },

  effects: {
    fetchSkillLevelClassificationShortInfoListEffect,
    updateSkillLevelClassificationShortInfoListEffect,
  },

  reducers: {
    skillLevelClassificationShortInfoListData,
  },
};

// --
// export business store
export default SkillLevelClassificationListBusinessStore;
