import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { LocationTypeEnum } from '@src/model/location/Location';
import { Form, Select } from 'antd';

// -- Const
// ----------
const { Option } = Select;

// -- Prop types
// ----------
export interface ILocationTypeSelectorOwnProps {
  value?: any;
  onChange?: () => void;
}

type ILocationTypeSelectorProps = ILocationTypeSelectorOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Diplay location type form selector */
const LocationTypeSelector: React.FC<ILocationTypeSelectorProps> = (props: ILocationTypeSelectorProps) => {

  const options = Object.keys(LocationTypeEnum)
    .map((item: string) => (
      <Option
        value={LocationTypeEnum[item as keyof typeof LocationTypeEnum]}
        key={LocationTypeEnum[item as keyof typeof LocationTypeEnum]}
      >
        {props.translate(`LOCATION_TYPE.${item}_LABEL`)}
      </Option>
    ));
  return (
    <Form.Item label={props.translate('LOCATION_CODEBOOK_FORM.LOCATION_TYPE_LABEL')}
      name={['type', 'id']}
      rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
      <Select placeholder={props.translate('COMMON.PICK_REQUIRED_FIELD')}>
        {options}
      </Select>
    </Form.Item>
  );
};

export default withLocalize<ILocationTypeSelectorOwnProps>(LocationTypeSelector as any);
