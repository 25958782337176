import React, { useCallback } from 'react';

import GridItem from '@src/components/common/grid/GridItem';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import TimunShortInfoTable from '@src/components/common/table/TimunShortInfoTable';
import { ITimunListPaginationPosition } from '@src/components/course/common/types';
import ExternalEducationTemplateStatus from '@src/components/externaleducation/ExternalEducationTemplateStatus';
import { ExternalEducationTemplateStatusEnum, IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { ICollectionData } from '@src/service/business/common/types';
import { IExternalEducationTemplateListFilter } from '@src/service/business/externaleducations/ExternalEducationTemplateListBusinessStore';
import { Button, Row, Tag } from 'antd';
import Column from 'antd/lib/table/Column';

// -- Prop types
// ----------

export interface IExternalEducationTemplateListViewOwnProps {
  externalEducationTemplateList: ICollectionData<IExternalEducationTemplate>;
  externalEducationTemplateListFilter?: IExternalEducationTemplateListFilter;
  isEducationProviderActive: boolean;
  showFilter?: boolean;
  paginationPosition?: ITimunListPaginationPosition;
  canEdit?: boolean;
  canAdd?: boolean;
  onAdd?: () => void;
  onPageChange: (page: number, size?: number) => void;
  onSortChange: (sort: string[]) => void;
  onFilterChange: (data: IExternalEducationTemplateListFilter) => void;
  onExternalEducationTemplateAdd?: () => void;
  onExternalEducationTemplateEdit?: (data: IExternalEducationTemplate) => void;
}
type IExternalEducationTemplateListViewProps = IExternalEducationTemplateListViewOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Display external education template short info table */
const ExternalEducationTemplateListView: React.FC<IExternalEducationTemplateListViewProps> = (props: IExternalEducationTemplateListViewProps) => {
  const additionalColumns = useCallback(() => {
    return <Column key="status" width="20%" align="left" render={(record: IExternalEducationTemplate) => <ExternalEducationTemplateStatus status={record.status} />} />;
  }, []);
  const renderExternalEducationTemplateRowDescription = useCallback((record: IExternalEducationTemplate) => {
    return <GridItem layout="horizontal" key={record.id} label={props.translate('EXTERNAL_EDUCATION_TEMPLATE_LIST_VIEW.SKILLS_LABEL')}>
      {record.skills.map((skill) => <Tag key={skill.id}>{skill.title}</Tag>)}
    </GridItem >;
  }, []);

  const isEditButtonVisible = useCallback((record: IExternalEducationTemplate): boolean => record.status.id === ExternalEducationTemplateStatusEnum.IN_CREATION, []);
  return (
    <React.Fragment>
      {props.showFilter && <Row>
        {/* TODO: ADD: filter component */}
      </Row>}

      {props.canAdd && props.isEducationProviderActive &&
        <React.Fragment>
          <Row className="timun-wrapper__gutterBox" justify="end">
            <Button icon={<LemonIcon name="plus" />} type="primary" onClick={props.onAdd}>
              {props.translate('EXTERNAL_EDUCATION_TEMPLATE_LIST_VIEW.ADD_EXTERNAL_EDUCATION_BUTTON_LABEL')}
            </Button>
          </Row>
        </React.Fragment>
      }

      <TimunShortInfoTable<IExternalEducationTemplate>
        titlePropPath="title"
        itemBaseRoute="externaleducationtemplate"
        bordered={false}
        items={props.externalEducationTemplateList.content}
        paginationData={props.externalEducationTemplateList.page}
        renderBackAdditionalColumns={additionalColumns}
        rowDescription={renderExternalEducationTemplateRowDescription}
        actionButtons={{
          edit: { showButton: isEditButtonVisible, onClick: props.onExternalEducationTemplateEdit },
        }}
        showPagination={true}
        paginationConfig={{ onChange: props.onPageChange }}
      />
    </React.Fragment>
  );
};

export default withLocalize<IExternalEducationTemplateListViewOwnProps>(ExternalEducationTemplateListView as any);
