import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';

import { FormComponentProps } from '@ant-design/compatible/lib/form';
import DeleteElementButton from '@src/components/common/button/DeleteElementButton';
import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';
import { CrmEditorConfig } from '@src/components/common/editor/CrmEditorConfigs';
import { Editor } from '@src/components/common/editor/Editor';
import FileUtils, { ITimunFile } from '@src/components/common/file/FileUtils';
import LemonIcon from '@src/components/common/image/LemonIcon';
import IntegrationVideoForm from '@src/components/common/integrationvideo/IntegrationVideoForm';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import MathContent from '@src/components/common/math/MathContent';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import FileUpload, { IUploadStatus } from '@src/components/common/upload/FileUpload';
import CourseHelperUtils from '@src/components/course/common/CourseHelperUtils';
import CourseContentControl from '@src/components/course/CourseContentControl';
import RepositoryFileListPicker from '@src/components/repository/file/RepositoryFileListPicker';
import IfTenantPropEnabled from '@src/components/tenant/IfTenantPropEnabled';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import { ICourse } from '@src/model/course/Course';
import { ILectureWithContent } from '@src/model/course/Lecture';
import { ICourseLectureListElement } from '@src/model/course/LectureGroup';
import { IFile } from '@src/model/file/File';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, Col, Input, InputNumber, Row, Select, Spin, Typography } from 'antd';
// tslint:disable-next-line:no-submodule-imports
import { UploadFile } from 'antd/lib/upload/interface';
import React, { SyntheticEvent } from 'react';

const FormItem = Form.Item;
const Option = Select.Option;

const MIN_LECTURE_ORDERING_VALUE = AppConfigService.getValue('components.courses.minOrderingValue');

// -- Prop types
// ----------

export interface ICourseLectureContentUpdateFormOwnProps {
  lecture: ILectureWithContent;
  lectureList: ICourseLectureListElement[];
  fileList: IFile[];
  course: ICourse;
  canEditStructure: boolean;
  onLectureUpdate: (data: ILectureWithContent) => void;
  onLectureDelete: (data: ILectureWithContent) => void;
  onEditingChange: (editing: boolean) => void;
  onEditorFileAdd: (fileBlob: any, fileName: string, successFn: (url: string) => void, failureFn: (err: string) => void) => void;
  onFileAdd: (data: IFile[]) => void;
  onFileDelete: (data: IFile[]) => void;
}

type ICourseLectureContentUpdateFormProps = ICourseLectureContentUpdateFormOwnProps & IWithLocalizeOwnProps & FormComponentProps & IWithPathResolverOwnProps & IWithTenantPropEnabledOwnProps;

// -- Component
// ----------

/** Update form for lecture content ... */
class LectureContentUpdateForm extends React.Component<ICourseLectureContentUpdateFormProps> {
  componentDidUpdate = (prevProps: ICourseLectureContentUpdateFormOwnProps) => {
    if (this.props !== prevProps && this.props.lecture.id !== prevProps.lecture.id) {
      this.closeForm();
    }
  };

  // tslint:disable-next-line: no-big-function
  render = () => {
    const { getFieldDecorator } = this.props.form;

    // if user has changed some values and there are no errors, save button can be enabled
    const canSave = CourseHelperUtils.isReadyToSubmit(this.props.form);

    // selected lecture group, it's length and data if lecture group has been changed are needed to correctly limit value of ordering
    const currentLectureGroup = this.props.lectureList.find((lectureGroup) => {
      const selected = this.props.form.getFieldValue('lectureGroups') ? this.props.form.getFieldValue('lectureGroups') : this.props.lecture.lectureGroup.id;
      return lectureGroup.id === selected;
    });
    // safety, should be impossible to get undefined for currentLectureGroup or list length
    const currentLectureGroupListLength = currentLectureGroup ? currentLectureGroup.lectureList.length : 0;
    const maxLectureOrderingValue = currentLectureGroup && currentLectureGroup.id === this.props.lecture.lectureGroup.id ? currentLectureGroupListLength : currentLectureGroupListLength + 1;

    const fileList: ITimunFile[] = FileUtils.mapToTimunFileList(this.props.fileList);

    const ButtonControls = (
      <FormItem>
        <Row justify="end" gutter={12}>
          <Col>
            <Button data-test-id="timun-courseView__update-lecture_submitButton" icon={<LemonIcon name="check" />} disabled={!canSave} type="primary" htmlType="submit">
              {this.props.translate('COMMON.ACTION_SAVE')}
            </Button>
          </Col>
          <Col>
            <Button onClick={this.handleCancel} icon={<LemonIcon name="times" />} data-test-id="timun-courseView__update-lecture_cancelButton">
              {this.props.translate(`COMMON.ACTION_${canSave ? 'CANCEL' : 'CLOSE'}`)}
            </Button>
          </Col>
          {this.props.canEditStructure && (
            <Col>
              <DeleteElementButton<ILectureWithContent> buttonText={this.props.translate('COMMON.ACTION_DELETE')} titlePropPath={'title'} item={this.props.lecture} onDelete={this.props.onLectureDelete} />
            </Col>
          )}
        </Row>
      </FormItem>
    );

    return (
      <React.Fragment>
        <Form layout="vertical" hideRequiredMark={true} onSubmit={this.handleSubmit} className="timun-courseView__lecture-content">
          {/* ----- buttons ----- */}
          {ButtonControls}

          {/* ----- title ----- */}
          <FormItem label={this.props.translate('COURSE_VIEW.UPDATE.LECTURE_TITLE_FORM_LABEL')}>
            {getFieldDecorator('lectureTitle', {
              initialValue: this.props.lecture.title,
              rules: [
                { whitespace: true, message: this.props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
                { required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') },
              ],
            })(<Input data-test-id="timun-courseView__update-lecture_lectureTitle"/>)}
          </FormItem>

          {/* ----- groups ----- */}
          <FormItem label={this.props.translate('COURSE_VIEW.UPDATE.LECTURE_GROUP_TITLE')}>
            {getFieldDecorator('lectureGroups', {
              initialValue: this.props.lecture.lectureGroup.id,
              rules: [{ required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') }],
            })(
              <Select placeholder={this.props.translate('COURSE_VIEW.UPDATE.LECTURE_GROUP_SELECT_PLACEHOLDER')} disabled={!this.props.canEditStructure}>
                {this.props.lectureList.map((lectureGroup: ICourseLectureListElement) => (
                  <Option key={lectureGroup.id} value={lectureGroup.id} data-test-id={`lemon-fileRepository__tlectureGroupSelect_${lectureGroup.id}`}>
                    {lectureGroup.title}
                  </Option>
                ))}
              </Select>,
            )}
          </FormItem>

          {/* ----- ordering ----- */}
          <FormItem label={this.props.translate('COURSE_VIEW.UPDATE.LECTURE_ORDERING_FORM_LABEL')}>
            {getFieldDecorator('lectureOrdering', {
              initialValue: this.props.lecture.ordering,
            })(<InputNumber data-test-id="timun-courseView__update-lecture_lectureOrdering" min={MIN_LECTURE_ORDERING_VALUE} max={maxLectureOrderingValue} disabled={!this.props.canEditStructure}/>)}
          </FormItem>

          {/* ----- video URL ----- */}
          <IfTenantPropEnabled property="integration_video" fallback="">
            <FormItem label={this.props.translate('COURSE_VIEW.UPDATE.LECTURE_VIDEO_FORM_LABEL')}>
              <div>
                {getFieldDecorator('integrationVideo', {
                  initialValue: this.props.lecture.integrationVideo ?? undefined, // TODO: BE returns integrationVideo as null when empty, it should be undefined
                })(<IntegrationVideoForm />)}
              </div>
            </FormItem>
          </IfTenantPropEnabled>

          {/* ----- content ----- */}
          <FormItem>
            <Typography.Title level={4}>{this.props.translate('COURSE_VIEW.UPDATE.LECTURE_CONTENT_FORM_LABEL')}</Typography.Title>

            {getFieldDecorator('content', {
              initialValue: this.props.lecture.content,
            })(
              // change to Editor
              <CourseContentControl
                dataTestId="timun-courseView__update-lecture-content"
                renderEdit={(content, handleEditorChange) => {
                  return {
                    body: (
                      <Editor
                        value={content.body}
                        onChange={(contentBody: string) => {
                          handleEditorChange(contentBody);
                        }}
                        data-test-id="timun-courseView__update-aboutCourse-content"
                        init={{
                          ...CrmEditorConfig.defaultConfig(),
                          height: 500,
                          menubar: true,
                          // image upload config (NOTE: we should probably create new componente (eg. TinyEditor) that will configure this for all editors
                          relative_urls: false, // tinymce tries to convert all URLs to relative ones and breaks links to uploaded API file (removes leading /) - this disables that behaviour
                          images_upload_handler: this.handleEditorFileAdd, // image upload handler
                        }}
                      />
                    ),
                  };
                }}
                renderPreview={(content) => {
                  return {
                    body: (
                      <React.Fragment>
                        {/*TODO: replace with component that will render lecture body*/}
                        {content.body && (
                          <Typography.Paragraph>
                            <MathContent key={content.body}>
                              <div dangerouslySetInnerHTML={{ __html: content.body }}/>
                            </MathContent>
                          </Typography.Paragraph>
                        )}
                      </React.Fragment>
                    ),
                  };
                }}
              />
            )}
          </FormItem>

          {/* ----- files ----- */}
          {this.props.canEditStructure && (
            <FormItem>
              <Typography.Title level={4}>{this.props.translate('COURSE_VIEW.UPDATE.LECTURE_FILES_LABEL')}</Typography.Title>
              {/* Antd Upload component has issues with upload and display of changes when there is a controlled list.
            Bypassed if one upload component handles upload only, and additional one displays the list and handles remove of files*/}
              <FileUpload onUploadFile={this.handleFileAdd}>
                {(status: IUploadStatus) => (
                  <React.Fragment>
                    {status.isUploading && <Spin/>}
                    <Button icon={<LemonIcon name="paperClip"/>}>{this.props.translate('COMMON.ACTION_ADD_FROM_PC')}</Button>
                  </React.Fragment>
                )}
              </FileUpload>
              {this.props.isTenantPropEnabled('repository') && <RepositoryFileListPicker onFileSelect={this.handleFileAdd}/>}
              <FileUpload uploadProps={{ showUploadList: true, fileList, onRemove: this.handleFileDelete }}/>
              <br/>
            </FormItem>
          )}

          {/* ----- buttons ----- */}
          {ButtonControls}
        </Form>
      </React.Fragment>
    );
  };

  handleEditorFileAdd = (blobInfo: any, success: (msg: string) => void, failure: (msg: string) => void) => {
    this.props.onEditorFileAdd(blobInfo.blob(), blobInfo.filename(), success, failure);
  };

  handleFileAdd = (data: IFile) => {
    this.props.onFileAdd([data]);
  };

  handleFileDelete = (data: UploadFile) => {
    const deleted: IFile | undefined = this.props.fileList.find((file: IFile) => file.id === data.uid);
    if (deleted) {
      this.props.onFileDelete([deleted]);
    }
  };

  handleCancel = () => {
    if (this.props.form.isFieldsTouched()) {
      confirmationDialog({
        onConfirm: () => {
          this.closeForm();
        },
        title: this.props.translate('COMMON.CONFIRMATION_CANCEL_ACTION_MESSAGE'),
      });
    } else {
      this.closeForm();
    }
  };

  closeForm = () => {
    this.props.form.resetFields();
    this.props.onEditingChange(false);
  };

  handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        const data: ILectureWithContent = {
          ...this.props.lecture,
          title: values.lectureTitle,
          ordering: values.lectureOrdering,
          lectureGroup: { id: values.lectureGroups },
          content: values.content,
          integrationVideo: values.integrationVideo,
        };
        this.props.onLectureUpdate(data);
        this.closeForm();
      }
    });
  };
}

const CourseLectureContentUpdateForm = Form.create()(LectureContentUpdateForm);

export default withPathResolver(withTenantPropEnabled<ICourseLectureContentUpdateFormOwnProps>(withLocalize(CourseLectureContentUpdateForm as any)));
