import React from 'react';
import { connect } from 'react-redux';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';

import LocalePicker from '@src/components/app/locale/LocalePicker';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import UserSettingsBusinessStore, { IUserSettings } from '@src/service/business/usersettings/userSettingsBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { CookieManager } from '@src/service/util/CookieManager';
// tslint:disable-next-line:no-submodule-imports
import { MenuInfo } from 'rc-menu/lib/interface';

const localeCookieName = AppConfigService.getValue('cookies.locale.name');

const localeCookie = CookieManager.getCookie(localeCookieName);

// -- Prop types
// ----------
export interface ILocalePickerContainerOwnProps {
  standalone?: boolean;
}

export interface ILocalePickerContainerStateProps {
  userSettings: IUserSettings;
}

export interface ILocalePickerContainerDispatchProps {
  updateLocale: (locale: string) => void;
}
type ILocalePickerContainerProps = ILocalePickerContainerOwnProps & ILocalePickerContainerStateProps & IWithTenantPropEnabledOwnProps & ILocalePickerContainerDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

class LocalePickerContainer extends React.Component<ILocalePickerContainerProps> {
  render = () => {
    return <>
      {this.props.isTenantPropEnabled('languages') && <LocalePicker onLocaleChange={this.handleLocaleChange} standalone={this.props.standalone} locale={this.props.userSettings ? this.props.userSettings.locale : localeCookie} />}
    </>;
  };
  handleLocaleChange = (e: MenuInfo) => {
    this.props.updateLocale(e.key);
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ILocalePickerContainerOwnProps): ILocalePickerContainerStateProps => ({
  userSettings: UserSettingsBusinessStore.selectors.getUserSettings(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ILocalePickerContainerDispatchProps => ({
  updateLocale: (locale: string) => dispatch(UserSettingsBusinessStore.actions.updateUserSettings({ locale })),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<ILocalePickerContainerOwnProps>(withTenantPropEnabled(LocalePickerContainer) as any));
