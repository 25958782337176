import GridItem from '@src/components/common/grid/GridItem';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ViewSectionItem from '@src/components/common/section/ViewSectionItem';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import ApplicationStatisticsUtils from '@src/components/externalEducationApplication/statistics//utils/ApplicationStatisticsUtils';
import { IPieData, TimunPieChart } from '@src/components/externalEducationApplication/statistics/charts/TimunPieChart';
import StatisticCardItem from '@src/components/externalEducationApplication/statistics/StatisticCardItem';
import { SurveyStatisticsCategories } from '@src/components/externalEducationApplication/statistics/utils/consts';
import { ISurveyQuestionStats, ISurveyReportPerType } from '@src/model/externalEducationApplication/ExternalEducationApplicationStatistics';
import { SurveyTypeEnum } from '@src/model/survey/SurveyTemplate';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import { Divider, Typography } from 'antd';
import React from 'react';

interface IEducationApplicationStatisticSurveyReportOwnProps {
  surveyReportPerTypes: ISurveyReportPerType[];
}

type IEducationApplicationStatisticSurveyReportProps = IEducationApplicationStatisticSurveyReportOwnProps & IWithLocalizeOwnProps;

const EducationApplicationStatisticSurveyReport = (props: IEducationApplicationStatisticSurveyReportProps) => {
  const userEducationCompletedSurvey = ApplicationStatisticsUtils.findSurveyReportByType(SurveyTypeEnum.USER_EDUCATION_COMPLETED, props.surveyReportPerTypes);
  const employerSurvey = ApplicationStatisticsUtils.findSurveyReportByType(SurveyTypeEnum.EMPLOYER, props.surveyReportPerTypes);
  const providerEducationSurvey = ApplicationStatisticsUtils.findSurveyReportByType(SurveyTypeEnum.EDUCATION_PROVIDER, props.surveyReportPerTypes);

  const numberOfSubmittedSurveys = ApplicationStatisticsUtils.sumCompletedSurveyReportPerRequestedType(SurveyStatisticsCategories.submitted, props.surveyReportPerTypes);
  const numberOfEducationProviederSurveys = ApplicationStatisticsUtils.sumCompletedSurveyReportPerRequestedType(SurveyStatisticsCategories.educationProvider, props.surveyReportPerTypes);
  const numberOfEmployerSurveys = ApplicationStatisticsUtils.sumCompletedSurveyReportPerRequestedType(SurveyStatisticsCategories.employer, props.surveyReportPerTypes);
  const numberOfUserEducationCompletedSurveys = ApplicationStatisticsUtils.sumCompletedSurveyReportPerRequestedType(SurveyStatisticsCategories.userCompleted, props.surveyReportPerTypes);
  const numberOfUserEducationCompletedCheckInSurveys = ApplicationStatisticsUtils.sumCompletedSurveyReportPerRequestedType(SurveyStatisticsCategories.userCheckIn, props.surveyReportPerTypes);
  const surveysPerStatusData: IPieData[] = [
    {
      label: props.translate('EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.EDUCATION_PROVIDER_SURVEYS'),
      value: numberOfEducationProviederSurveys,
    },
    {
      label: props.translate('EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.EMPLOYER_SURVEYS'),
      value: numberOfEmployerSurveys,
    },
    {
      label: props.translate('EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.USER_EDUCATION_COMPLETED_SURVEYS'),
      value: numberOfUserEducationCompletedSurveys,
    },
    {
      label: props.translate('EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.USER_EDUCATION_COMPLETED_CHECK_IN_SURVEYS'),
      value: numberOfUserEducationCompletedCheckInSurveys,
    },
  ];

  return (
    <ViewSectionItem
      header={<HeaderTitle title={props.translate('EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.GRADE_TITLE')} icon={<LemonIcon name="star" size="large" />} />}
      collapsible={false}
      active={true}
      bordered={true}
    >
      <StatisticCardItem statisticalNumber={numberOfSubmittedSurveys} description={props.translate('EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.SUBMITTED_SURVEYS')} />

      <Divider />

      <Typography.Title level={3}> {props.translate('EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.SURVEYS_BY_SURVEY_TYPE')} </Typography.Title>
      <TimunPieChart data={surveysPerStatusData} />

      <Divider />

      {userEducationCompletedSurvey && userEducationCompletedSurvey.numberOfSubmissions > 0 && <React.Fragment>
        <Typography.Paragraph> {props.translate('EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.EDUCATION_COMPLETED_SURVEY_AVERAGE_QUESTION_TITLE')} </Typography.Paragraph>
        {userEducationCompletedSurvey?.surveyQuestionStats.map((surveyQuestionStat: ISurveyQuestionStats, index) => {
          return (
            <GridItem key={surveyQuestionStat.question.id} label={LocalizeService.translate('EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.SURVEY_QUESTION_LABEL', { orderNumber: index + 1, questionText: surveyQuestionStat.question.intro.text, numberOfAnswers: surveyQuestionStat.numberOfAnswers })}>
              {surveyQuestionStat.averageAnswer}
            </GridItem>
          );
        })}
        <Divider />
      </React.Fragment>
      }

      {employerSurvey && employerSurvey.numberOfSubmissions > 0 && <React.Fragment>
        <Typography.Paragraph> {props.translate('EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.EMPLOYER_SURVEY_AVERAGE_QUESTION_TITLE')} </Typography.Paragraph>
        {employerSurvey?.surveyQuestionStats.map((surveyQuestionStat: ISurveyQuestionStats, index) => {
          return (
            <GridItem key={surveyQuestionStat.question.id} label={LocalizeService.translate('EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.SURVEY_QUESTION_LABEL', { orderNumber: index + 1, questionText: surveyQuestionStat.question.intro.text, numberOfAnswers: surveyQuestionStat.numberOfAnswers })}>
              {surveyQuestionStat.averageAnswer}
            </GridItem>
          );
        })}
        <Divider />
      </React.Fragment>}

      {providerEducationSurvey && providerEducationSurvey.numberOfSubmissions > 0 && <React.Fragment>
        <Typography.Paragraph> {props.translate('EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.EDUCATION_PROVIDER_SURVEYS_AVERAGE_QUESTION_TITLE')} </Typography.Paragraph>
        {providerEducationSurvey?.surveyQuestionStats.map((surveyQuestionStat: ISurveyQuestionStats, index) => {
          return (
            <GridItem key={surveyQuestionStat.question.id} label={props.translate('EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.SURVEY_QUESTION_LABEL', { oderNumber: index + 1, questionText: surveyQuestionStat.question.intro.text, numberOfAnswers: surveyQuestionStat.numberOfAnswers })}>
              {surveyQuestionStat.averageAnswer}
            </GridItem>
          );
        })}
        <Divider />
      </React.Fragment>}
    </ViewSectionItem>
  );
};

export default withLocalize<IEducationApplicationStatisticSurveyReportOwnProps>(EducationApplicationStatisticSurveyReport as any);
