import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ExternalEducationTemplateForm from '@src/components/externaleducation/form/ExternalEducationTemplateForm';
import { ExternalEducationTemplateStatusEnum, IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { IExternalEducationTemplateCreatePayload } from '@src/service/business/externaleducations/ExternalEducationTemplateBusinessStore';
import { Form, Modal } from 'antd';

// -- Prop types
// ----------
export interface IExternalEducationTemplateFormModalOwnProps {
  educationProviderId: string;
  externalEducationTemplate?: IExternalEducationTemplate;
  onCreate?: (externalEducationTemplate: IExternalEducationTemplateCreatePayload) => void;
  onUpdate?: (externalEducationTemplate: IExternalEducationTemplate) => void;
  onCancel?: () => void;
}

type IExternalEducationTemplateFormModalProps = IExternalEducationTemplateFormModalOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Display external education tamplete form in modal */
const ExternalEducationTemplateFormModal: React.FC<IExternalEducationTemplateFormModalProps> = (props: IExternalEducationTemplateFormModalProps) => {
  const [form] = Form.useForm<IExternalEducationTemplate>();

  const handleSubmit = (value: IExternalEducationTemplate) => {
    if (props.externalEducationTemplate?.id) {
      //  update
      props.onUpdate?.(value);
    } else if (props.onCreate) {
      // create
      const createData = { ...value, educationProvider: { id: props.educationProviderId }, status: { id: ExternalEducationTemplateStatusEnum.IN_CREATION } };
      props.onCreate(createData);
    }
  };

  return (
    <Modal
      className="lemon-modal__modal--lg"
      title={props.translate('EXTERNAL_EDUCATION_TEMPLATE.MODAL.TITLE')}
      visible={true}
      maskClosable={false}
      onCancel={props.onCancel}
      onOk={form.submit}
      okText={props.translate('COMMON.ACTION_SAVE')}
      cancelText={props.translate('COMMON.ACTION_CANCEL')}>
      <ExternalEducationTemplateForm formRef={form} value={props.externalEducationTemplate} onSubmit={handleSubmit} />
    </Modal >
  );
};

export default withLocalize<IExternalEducationTemplateFormModalOwnProps>(ExternalEducationTemplateFormModal as any);
