import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

import { IUserSkillEvaluation } from '@src/model/user/UserSkillEvaluation';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { startGlobalProgress, stopGlobalProgress, trackAction } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts
export interface IUserSkillEvaluationListFilter {
  userId?: string;
}
// List filter ID
const USER_SKILL_EVALUATION_LIST_FILTER = '@@USER_SKILL_EVALUATION_LIST_FILTER';

// -
// -------------------- Selectors

/** Returns list of UserSkillEvaluationList from store. */
const getUserSkillEvaluationList = (store: any): ICollectionData<IUserSkillEvaluation> => store.userSkillEvaluationList;

/** Returns UserSkillEvaluationList list filter. */
const getUserSkillEvaluationListFilter = (store: any): IUserSkillEvaluationListFilter => ListFilterBusinessStore.selectors.getListFilter(store, USER_SKILL_EVALUATION_LIST_FILTER);

// -
// -------------------- Actions

const Actions = {
  USER_SKILL_EVALUATION_LIST_FETCH: 'USER_SKILL_EVALUATION_LIST_FETCH',
  USER_SKILL_EVALUATION_LIST_LOAD: 'USER_SKILL_EVALUATION_LIST_LOAD',
  USER_SKILL_EVALUATION_LIST_CLEAR: 'USER_SKILL_EVALUATION_LIST_CLEAR',
};

/** Fetch UserSkillEvaluationList list by filter. */
const fetchUserSkillEvaluationList = (userId: string, params: ICollectionFetchPayload<IUserSkillEvaluationListFilter>): IPayloadAction<ICollectionFetchPayload<IUserSkillEvaluationListFilter>> => {
  return {
    type: Actions.USER_SKILL_EVALUATION_LIST_FETCH,
    payload: {
      ...params,
      filter: {
        ...params.filter,
        userId,
      },
    },
  };
};

/** Load UserSkillEvaluationList list to store. */
const loadUserSkillEvaluationList = (data: ICollectionData<IUserSkillEvaluation>): IPayloadAction<ICollectionData<IUserSkillEvaluation>> => {
  return {
    type: Actions.USER_SKILL_EVALUATION_LIST_LOAD,
    payload: data,
  };
};

/** Clear UserSkillEvaluationList list from store. Eg. when leaving list view. */
const clearUserSkillEvaluationList = (): ILemonAction => {
  return {
    type: Actions.USER_SKILL_EVALUATION_LIST_CLEAR,
  };
};

/** Store UserSkillEvaluationList list filter to store. */
const storeUserSkillEvaluationListFilter = (listFilter: IUserSkillEvaluationListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(USER_SKILL_EVALUATION_LIST_FILTER, listFilter);
};

// -
// -------------------- Side-effects

const fetchUserSkillEvaluationListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<IUserSkillEvaluationListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_SKILL_EVALUATION_LIST_FETCH;
    }),

    startGlobalProgress(),
    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('UserSkillEvaluation')
        .fetchEntityList(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadUserSkillEvaluationList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching UserSkillEvaluationList list', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const userSkillEvaluationList = (state: ICollectionData<IUserSkillEvaluation> | null = null, action: IPayloadAction<ICollectionData<IUserSkillEvaluation>>) => {
  if (action.type === Actions.USER_SKILL_EVALUATION_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.USER_SKILL_EVALUATION_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const UserSkillEvaluationListBusinessStore = {
  actions: {
    fetchUserSkillEvaluationList,
    loadUserSkillEvaluationList,
    clearUserSkillEvaluationList,
    storeUserSkillEvaluationListFilter,
  },

  selectors: {
    getUserSkillEvaluationList,
    getUserSkillEvaluationListFilter,
  },

  effects: {
    fetchUserSkillEvaluationListEffect,
  },

  reducers: {
    userSkillEvaluationList,
  },
};

// --
// export business store
export default UserSkillEvaluationListBusinessStore;
