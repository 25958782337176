import React from 'react';
import { connect } from 'react-redux';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import UserView from '@src/components/user/view/UserView';
import { IFile } from '@src/model/file/File';
import { IUserInfo } from '@src/model/user/User';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import CollectionBusinessStore from '@src/service/business/common/collectionBusinessStore';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import UserBusinessStore from '@src/service/business/user/UserBusinessStore';
import { createActionThunk, IActionThunkMap } from '@src/service/util/action/thunk';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { ILemonEvent } from '@src/service/util/event/lemonEvent';
import { withRouter, WithRouterProps } from 'react-router';

// -- Const
// ----------

// --
// ----- Prop types
interface IUserViewContainerOwnProps {
  userId: string;
  openedTab: string;
}

interface IUserViewContainerStateProps {
  currentUser: IUserInfo;
  user: IUserInfo;
  workPositionList: IWorkPosition[];
}

interface IUserViewContainerDispatchProps {
  fetchUser: (id: string) => void;
  updateUser: (data: IUserInfo, sourceEvent: ILemonEvent<IUserInfo>) => ITrackableAction;
  clearUser: () => void;
  createUserAvatar: (id: string, data: IFile, thunkMap: IActionThunkMap) => void;
  deleteUser: (id: string) => ITrackableAction;
}

type IUserViewContainerProps = IUserViewContainerOwnProps & IUserViewContainerStateProps & IUserViewContainerDispatchProps & WithRouterProps & IWithLocalizeOwnProps;
// --
// ----- State types
interface IUserViewContainerState { }

// --
// ----- Component
class UserViewContainer extends React.Component<IUserViewContainerProps, IUserViewContainerState> {
  state: IUserViewContainerState = {};

  componentDidMount = () => {
    // initial data load
    this.fetchUser();
  };

  componentWillUnmount = () => {
    this.props.clearUser();
  };

  render = () => {
    return (
      <React.Fragment>
        {this.props.user && (
          <UserView
            user={this.props.user}
            currentUser={this.props.currentUser}
            openedTab={this.props.openedTab}
            workPositionList={this.props.workPositionList}
            onAvatarSubmit={this.onAvatarSubmit}
            onUserSubmit={this.handleFormSubmit}
            onUserUpdate={this.fetchUser}
            onUserDelete={this.handleUserDelete}
          />
        )}
      </React.Fragment>
    );
  };

  private fetchUser = () => {
    const id = this.props.userId ? this.props.userId : this.props.currentUser.id;
    this.props.fetchUser(id);
  };

  private onAvatarSubmit = (data: IFile) => {
    this.props.createUserAvatar(this.props.user.id, data, {
      success: () => {
        this.fetchUser();
      },
    });
  };

  private handleFormSubmit = (event: ILemonEvent<IUserInfo>) => {
    this.props.updateUser(event.data, event).track();
  };

  private handleUserDelete = (data: IUserInfo) => {
    this.props
      .deleteUser(data.id)
      .track()
      .subscribe(() => this.props.router.push('/users'));
  };
}

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IUserViewContainerOwnProps): IUserViewContainerStateProps => ({
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
  user: UserBusinessStore.selectors.getUser(state),
  workPositionList: CollectionBusinessStore.selectors.getCollectionContent(state, 'WorkPosition'),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any, ownProps: IUserViewContainerOwnProps): IUserViewContainerDispatchProps => ({
  fetchUser: (id: string) => dispatch(UserBusinessStore.actions.fetchUser({ id })),
  updateUser: (data: IUserInfo, sourceEvent: ILemonEvent<IUserInfo>) => dispatch(createTrackableAction(UserBusinessStore.actions.updateUser(data), sourceEvent)),
  clearUser: () => dispatch(UserBusinessStore.actions.clearUser()),
  createUserAvatar: (id: string, data: IFile, thunkMap: IActionThunkMap) => dispatch(createActionThunk(UserBusinessStore.actions.createUserAvatar(id, data), thunkMap)),
  deleteUser: (id: string) => createTrackableAction(dispatch(UserBusinessStore.actions.deleteUser({ id }))),
});

export default connect<IUserViewContainerStateProps, IUserViewContainerDispatchProps, IUserViewContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(withRouter<IUserViewContainerOwnProps>(UserViewContainer as any)));
