import AppUserAvatar from '@src/components/common/image/AppUserAvatar';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { UserNameView } from '@src/components/common/view/UserNameView';
import { IComment } from '@src/model/comment/Comment';
import { CommentObjectTypeEnum } from '@src/model/comment/CommentObjectType';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, Col, Row, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router';

// -- Const
// ----------
const DATE_FORMAT: string = AppConfigService.getValue('dateTimeFormat.dateWithTime');

// -- PropTypes
// ----------
export interface IAdminCommentListItemOwnProps {
  comment: IComment;
  onApprove: (comment: IComment) => void;
}

type IAdminCommentListItemProps = IAdminCommentListItemOwnProps & IWithLocalizeOwnProps;

// --
// ----- State types
interface IAdminCommentListItemState {
}

// -- Component
// ----------
class AdminCommentListItem extends React.Component<IAdminCommentListItemProps, IAdminCommentListItemState> {
  state = {};
  renderLocationParagraph = (comment: IComment) => {
    let path;
    switch (comment.objectType.id) {
      case (CommentObjectTypeEnum.COURSE):
        path = `/course/${comment.object.id}`;
        break;
      case (CommentObjectTypeEnum.LECTURE):
        path = `/course/lecture/${comment.object.id}`;
        break;
      case (CommentObjectTypeEnum.WEBINAR):
        path = `/webinar/${comment.object.id}`;
        break;
    }
    return <Typography.Paragraph><Link to={path}> {comment.object.name}</Link></Typography.Paragraph>;
  };

  render() {
    return (
      <Row align="middle" gutter={24} className="panel" key={this.props.comment.id}>
        <Col xs={6} lg={6} className="timun-adminCommentListItem__itemCol">
          <div className="timun-adminCommentListItem__nameCol">
            <AppUserAvatar imagePath={this.props.comment.creator ? this.props.comment.creator.profileImageUrl : undefined}/>
            <Typography.Paragraph ellipsis={{ rows: 1, expandable: false }}> <UserNameView user={this.props.comment.creator} fallback={this.props.translate('USER_VIEW.USER_DELETED_LABEL')}/></Typography.Paragraph>
          </div>
        </Col>
        <Col xs={10} lg={10}>
          <Typography.Paragraph ellipsis={{ rows: 2, expandable: true }}> {this.props.comment.content} </Typography.Paragraph>
          <Typography.Paragraph type="secondary">{moment(this.props.comment.addedDateTime).format(DATE_FORMAT)}</Typography.Paragraph>
        </Col>
        <Col xs={8} lg={4}>
          {this.renderLocationParagraph(this.props.comment)}
        </Col>
        <Col xs={24} lg={4}>
          <Row justify="space-around" className="full-width">
            <Col>
              <Button onClick={() => this.props.onApprove(this.props.comment)}> {this.props.translate('COMMON.ACTION_APPROVE')} </Button>
            </Col>
            {/* TODO: add onClick function for reject comment status */}
            <Col>
              <Button> {this.props.translate('COMMON.ACTION_REJECT')} </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

// -- HOCs and exports
// ----------

export default withLocalize<IAdminCommentListItemOwnProps>(AdminCommentListItem as any);
