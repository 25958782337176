import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';
import { ISurveyTemplate, ISurveyType } from '@src/model/survey/SurveyTemplate';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { IIdDataPayload, IIdPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { trackAction } from '@src/service/util/observable/operators';
import { startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts
export interface ISurveyTemplateCreatePayload {
  title: string;
  type: Pick<ISurveyType, 'id'>;
}

// -
// -------------------- Selectors

/** Returns SurveyTemplate from store. */
const getSurveyTemplate = (store: any): ISurveyTemplate => store.surveyTemplate;

// -
// -------------------- Actions

const Actions = {
  SURVEY_TEMPLATE_FETCH: 'SURVEY_TEMPLATE_FETCH',
  SURVEY_TEMPLATE_LOAD: 'SURVEY_TEMPLATE_LOAD',
  SURVEY_TEMPLATE_CLEAR: 'SURVEY_TEMPLATE_CLEAR',
  SURVEY_TEMPLATE_CREATE: 'SURVEY_TEMPLATE_CREATE',
  SURVEY_TEMPLATE_UPDATE: 'SURVEY_TEMPLATE_UPDATE',
};

/** Fetch SurveyTemplate by ID. */
const fetchSurveyTemplate = (id: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.SURVEY_TEMPLATE_FETCH,
    payload: {
      id,
    },
  };
};

/** Load SurveyTemplate to store. */
const loadSurveyTemplate = (data: ISurveyTemplate): IPayloadAction<ISurveyTemplate> => {
  return {
    type: Actions.SURVEY_TEMPLATE_LOAD,
    payload: data,
  };
};

/** Clear SurveyTemplate from store. Eg. when leaving view. */
const clearSurveyTemplate = (): ILemonAction => {
  return {
    type: Actions.SURVEY_TEMPLATE_CLEAR,
  };
};

/** Create new SurveyTemplate. */
const createSurveyTemplate = (data: ISurveyTemplateCreatePayload): IPayloadAction<ISurveyTemplateCreatePayload> => {
  return {
    type: Actions.SURVEY_TEMPLATE_CREATE,
    payload: data,
  };
};

/** Update SurveyTemplate by ID. */
const updateSurveyTemplate = (data: ISurveyTemplate): IPayloadAction<IIdDataPayload<ISurveyTemplateCreatePayload>> => {
  return {
    type: Actions.SURVEY_TEMPLATE_UPDATE,
    payload: {
      id: data.id,
      data,
    },
  };
};

// -
// -------------------- Side-effects

const fetchSurveyTemplateEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.SURVEY_TEMPLATE_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('SurveyTemplate')
        .fetchEntity(id)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadSurveyTemplate(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching SurveyTemplate', error);
      return o;
    })
  );
};

const createSurveyTemplateEffect = (action$: Observable<IPayloadAction<ISurveyTemplateCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.SURVEY_TEMPLATE_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;
      return EntityApiServiceRegistry.getService('SurveyTemplate')
        .createEntity(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating SurveyTemplate', error);
      return o;
    })
  );
};

const updateSurveyTemplateEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ISurveyTemplate>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.SURVEY_TEMPLATE_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('SurveyTemplate')
        .updateEntity(payload.id, payload.data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error updating survey template', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const surveyTemplate = (state: ISurveyTemplate | null = null, action: IPayloadAction<ISurveyTemplate>) => {
  if (action.type === Actions.SURVEY_TEMPLATE_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.SURVEY_TEMPLATE_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const surveyTemplateBusinessStore = {
  actions: {
    fetchSurveyTemplate,
    loadSurveyTemplate,
    clearSurveyTemplate,
    createSurveyTemplate,
    updateSurveyTemplate,
  },

  selectors: {
    getSurveyTemplate,
  },

  effects: {
    fetchSurveyTemplateEffect,
    createSurveyTemplateEffect,
    updateSurveyTemplateEffect,
  },

  reducers: {
    surveyTemplate,
  },
};

// --
// export business store
export default surveyTemplateBusinessStore;
