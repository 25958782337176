import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

import { ICourse } from '@src/model/course/Course';
import { ILectureWithContent } from '@src/model/course/Lecture';
import { ICourseLectureListElement } from '@src/model/course/LectureGroup';
import { IFile } from '@src/model/file/File';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, IIdDataPayload, IIdPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { ICollectionFetchPayload } from '@src/service/business/common/types';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import AppConfigService from '@src/service/common/AppConfigService';
import { actionThunk } from '@src/service/util/observable/operators';
import { startGlobalProgress, stopGlobalProgress, trackAction } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts

// NOTE: course file API sorts (and filters?) by different props than the ones returned in file objects - this is not good and should be changed
const COURSE_FILE_LIST_NAME_SORT = 'fileResource.metadata.title,asc';
// use default collection payload until it is provided by components
const COURSE_FILE_LIST_COLLECTION_DATA: ICollectionFetchPayload<ICourseLectureFileListFilter> = {
  filter: {},
  page: 0,
  size: AppConfigService.getValue('api.collectionDefaultLimit'),
  sort: [COURSE_FILE_LIST_NAME_SORT],
};

export interface ICourseLectureListFilter {
  // TODO: add list filter propeties
}

export interface ICourseLectureFileListFilter {
  // TODO: add list filter propeties
}

// List filter ID
const COURSE_LECTURES_LIST_FILTER = '@@COURSE_LECTURES_LIST_FILTER';

// -
// -------------------- Selectors

/** Returns course from store. */
const getCourseContent = (store: any): ICourse => store.courseData;

/** Returns lecture from store. */
const getLectureContent = (store: any): ILectureWithContent => store.courseLecture;

/** Returns list of course lectures from store. */
const getCourseLectureList = (store: any): ICourseLectureListElement[] => store.courseLectureList;

/** Returns course lecture list filter. */
const getCourseLectureListFilter = (store: any): ICourseLectureListFilter => ListFilterBusinessStore.selectors.getListFilter(store, COURSE_LECTURES_LIST_FILTER);

/** Returns course lecture file list. */
const getLectureFileList = (store: any): ICollectionData<IFile> => store.lectureFileList;

// -
// -------------------- Actions

const Actions = {
  COURSE_CONTENT_FETCH: 'COURSE_CONTENT_FETCH',
  COURSE_CONTENT_LOAD: 'COURSE_CONTENT_LOAD',
  COURSE_CONTENT_CLEAR: 'COURSE_CONTENT_CLEAR',
  COURSE_TRAINEE_PROGRESS_UPDATE: 'COURSE_TRAINEE_PROGRESS_UPDATE',
  COURSE_LECTURES_LIST_FETCH: 'COURSE_LECTURES_LIST_FETCH',
  COURSE_LECTURES_LIST_LOAD: 'COURSE_LECTURES_LIST_LOAD',
  COURSE_LECTURES_LIST_CLEAR: 'COURSE_LECTURES_LIST_CLEAR',
  LECTURE_CONTENT_FETCH: 'LECTURE_CONTENT_FETCH',
  LECTURE_CONTENT_LOAD: 'LECTURE_CONTENT_LOAD',
  LECTURE_CONTENT_CLEAR: 'LECTURE_CONTENT_CLEAR',
  LECTURE_FILE_LIST_FETCH: 'LECTURE_FILE_LIST_FETCH',
  LECTURE_FILE_LIST_LOAD: 'LECTURE_FILE_LIST_LOAD',
  LECTURE_FILE_LIST_CLEAR: 'LECTURE_FILE_LIST_CLEAR',
};

/** Fetch course by ID. */
const fetchCourseContent = (params: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.COURSE_CONTENT_FETCH,
    payload: params,
  };
};

/** Load course to store. */
const loadCourseContent = (data: ICourse): IPayloadAction<ICourse> => {
  return {
    type: Actions.COURSE_CONTENT_LOAD,
    payload: data,
  };
};

/** Clear course from store. Eg. when leaving view. */
const clearCourseContentData = (): ILemonAction => {
  return {
    type: Actions.COURSE_CONTENT_CLEAR,
  };
};

/** Update the progress of trainee in a course */
const updateCourseProgress = (id: string, lecture: IIdPayload): IPayloadAction<IIdDataPayload<IIdPayload>> => {
  return {
    type: Actions.COURSE_TRAINEE_PROGRESS_UPDATE,
    payload: {
      id,
      data: lecture,
    },
  };
};

/** Fetch course lecture list by filter. */
const fetchCourseLectureList = (id: string, listFilter: ICourseLectureListFilter): IPayloadAction<IIdDataPayload<ICourseLectureListFilter>> => {
  return {
    type: Actions.COURSE_LECTURES_LIST_FETCH,
    payload: {
      id,
      data: listFilter,
    },
  };
};

/** Load course lecture list to store. */
const loadCourseLectureList = (data: ICollectionData<ICourse>): IPayloadAction<ICollectionData<ICourse>> => {
  return {
    type: Actions.COURSE_LECTURES_LIST_LOAD,
    payload: data,
  };
};

/** Clear course lecture list from store. Eg. when leaving list view. */
const clearCourseLectureList = (): ILemonAction => {
  return {
    type: Actions.COURSE_LECTURES_LIST_CLEAR,
  };
};

/** Fetch lecture by ID. */
const fetchLectureContent = (params: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.LECTURE_CONTENT_FETCH,
    payload: params,
  };
};

/** Load lecture to store. */
const loadLectureContent = (data: ILectureWithContent): IPayloadAction<ILectureWithContent> => {
  return {
    type: Actions.LECTURE_CONTENT_LOAD,
    payload: data,
  };
};

/** Clear lecture from store. Eg. when leaving view. */
const clearLectureContentData = (): ILemonAction => {
  return {
    type: Actions.LECTURE_CONTENT_CLEAR,
  };
};

const fetchLectureFileList = (lectureId: string): IPayloadAction<IIdDataPayload<ICollectionFetchPayload<ICourseLectureFileListFilter>>> => {
  return {
    type: Actions.LECTURE_FILE_LIST_FETCH,
    payload: {
      id: lectureId,
      data: COURSE_FILE_LIST_COLLECTION_DATA,
    },
  };
};

const loadLectureFileList = (data: ICollectionData<IFile>): IPayloadAction<ICollectionData<IFile>> => {
  return {
    type: Actions.LECTURE_FILE_LIST_LOAD,
    payload: data,
  };
};

const clearLectureFileList = (): ILemonAction => {
  return {
    type: Actions.LECTURE_FILE_LIST_CLEAR,
  };
};

/** Store course lecture list filter to store. */
const storeCourseLectureListFilter = (listFilter: ICourseLectureListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(COURSE_LECTURES_LIST_FILTER, listFilter);
};

// -
// -------------------- Side-effects

const fetchCourseEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COURSE_CONTENT_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('Course')
        .fetchEntity(id)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadCourseContent(data);
    }),

    // reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'COURSE_VIEW.ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),
    // tslint:disable-next-line: no-duplicate-string
    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching course', error);
      return o;
    })
  );
};

const updateCourseProgressEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IIdPayload>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COURSE_TRAINEE_PROGRESS_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('Course')
        .updateEntityMethod(id, 'progress', data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadCourseContent(data);
    }),

    // reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'COURSE_VIEW.ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),
    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching course', error);
      return o;
    })
  );
};

const fetchCourseLectureListEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ICourseLectureListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COURSE_LECTURES_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('Course')
        .fetchSubobject(id, 'lecturelist', data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadCourseLectureList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching course lecture list', error);
      return o;
    })
  );
};

const fetchCourseLectureContentEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.LECTURE_CONTENT_FETCH;
    }),

    startGlobalProgress(),

    // tslint:disable-next-line: no-identical-functions
    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('Lecture')
        .fetchEntity(id)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadLectureContent(data);
    }),

    // reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'COURSE_VIEW.ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),
    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching course lecture', error);
      return o;
    })
  );
};

const fetchLectureFileListEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ICollectionFetchPayload<ICourseLectureFileListFilter>>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.LECTURE_FILE_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('Lecture')
        .fetchSubentityList(id, 'File', data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadLectureFileList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching lecture content list', error);

      return o;
    })
  );
};

// -
// -------------------- Reducers

const courseData = (state: ICourse | null = null, action: IPayloadAction<ICourse>) => {
  if (action.type === Actions.COURSE_CONTENT_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.COURSE_CONTENT_CLEAR) {
    return null;
  }

  return state;
};

const courseLecture = (state: ILectureWithContent | null = null, action: IPayloadAction<ILectureWithContent>) => {
  if (action.type === Actions.LECTURE_CONTENT_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.LECTURE_CONTENT_CLEAR) {
    return null;
  }

  return state;
};

const courseLectureList = (state: ICourseLectureListElement[] = [], action: IPayloadAction<ICourseLectureListElement[]>) => {
  if (action.type === Actions.COURSE_LECTURES_LIST_LOAD) {
    return action.payload;
  } else if (action.type === Actions.COURSE_LECTURES_LIST_CLEAR) {
    return [];
  }

  return state;
};

const lectureFileList = (state: ICollectionData<IFile> | null = null, action: IPayloadAction<ICollectionData<IFile>>) => {
  if (action.type === Actions.LECTURE_FILE_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.LECTURE_FILE_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const courseViewBusinessStore = {
  actions: {
    fetchCourseContent,
    loadCourseContent,
    clearCourseContentData,
    updateCourseProgress,
    fetchCourseLectureList,
    loadCourseLectureList,
    clearCourseLectureList,
    storeCourseLectureListFilter,
    fetchLectureContent,
    loadLectureContent,
    clearLectureContentData,
    fetchLectureFileList,
    loadLectureFileList,
    clearLectureFileList,
  },

  selectors: {
    getCourseContent,
    getCourseLectureList,
    getCourseLectureListFilter,
    getLectureContent,
    getLectureFileList,
  },

  effects: {
    fetchCourseEffect,
    updateCourseProgressEffect,
    fetchCourseLectureListEffect,
    fetchCourseLectureContentEffect,
    fetchLectureFileListEffect,
  },

  reducers: {
    courseData,
    courseLectureList,
    courseLecture,
    lectureFileList,
  },
};

// --
// export business store
export default courseViewBusinessStore;
