import React from 'react';
import { connect } from 'react-redux';
import { IQuiz } from '@lamarodigital/quizzler-lib-frontend/model/quiz/Quiz';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { withRouter, WithRouterProps } from 'react-router';
import { ISurveyTemplate } from '@src/model/survey/SurveyTemplate';
import SurveyTemplateBusinessStore from '@src/service/business/survey/SurveyTemplateBusinessStore';
import SurveyTemplateView from '@src/components/survey/view/SurveyTemplateView';

// -- Prop types
// ----------

export interface ISurveyTemplateContainerOwnProps {
  surveyTemplateId: string;
}

export interface ISurveyTemplateContainerStateProps {
  surveyTemplate?: ISurveyTemplate;
}

export interface ISurveyTemplateContainerDispatchProps {
  fetchSurveyTemplate: (surveyTemplateId: string) => ITrackableAction;
  updateSurveyTemplate: (surveyTemplate: ISurveyTemplate) => ITrackableAction;
  clearSurveyTemplate: () => void;
}

type ISurveyTemplateContainerProps = ISurveyTemplateContainerOwnProps & ISurveyTemplateContainerStateProps & ISurveyTemplateContainerDispatchProps & IWithLocalizeOwnProps & WithRouterProps;

interface ISurveyTemplateContainerState {
  page: number;
  size: number;
  sort: string;
}

// -- Component
// ----------

/** Container component for displaying survey template collection */
class SurveyTemplateContainer extends React.Component<ISurveyTemplateContainerProps, ISurveyTemplateContainerState> {
  state: ISurveyTemplateContainerState = {
    page: 0,
    size: AppConfigService.getValue('api.paging.defaultPageSize'),
    sort: '',
  };

  componentDidMount() {
    this.fetchSurveyTemplate(this.props.surveyTemplateId);
  }

  componentDidUpdate(prevProps: ISurveyTemplateContainerProps, prevState: ISurveyTemplateContainerState) {
    if (this.props.surveyTemplateId !== prevProps.surveyTemplateId) {
      this.fetchSurveyTemplate(this.props.surveyTemplateId);
    }
  }

  componentWillUnmount() {
    this.props.clearSurveyTemplate();
  }

  render = () => {
    return (
      <React.Fragment>
        {this.props.surveyTemplate && <SurveyTemplateView surveyTemplate={this.props.surveyTemplate} onSurveyCollectionSubmit={this.handleSurveyCollectionSubmit} />}
      </React.Fragment>
    );
  }

  handleSurveyCollectionSubmit = (quizData: IQuiz) => {
    if (this.props.surveyTemplate) {
      const newSurveyTemplateCollection: ISurveyTemplate = { ...this.props.surveyTemplate };
      newSurveyTemplateCollection.collection = quizData;
      
      this.props.updateSurveyTemplate(newSurveyTemplateCollection).track().subscribe(
        //success
        () => {
          this.props.fetchSurveyTemplate(this.props.surveyTemplateId);
        }
      );
    }
  }

  fetchSurveyTemplate = (surveyTemplateId: string) => {
    this.props.fetchSurveyTemplate(surveyTemplateId);
  }

}
// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ISurveyTemplateContainerOwnProps): ISurveyTemplateContainerStateProps => ({
  surveyTemplate: SurveyTemplateBusinessStore.selectors.getSurveyTemplate(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ISurveyTemplateContainerDispatchProps => ({
  fetchSurveyTemplate: (surveyTemplateId: string) => createTrackableAction(dispatch(SurveyTemplateBusinessStore.actions.fetchSurveyTemplate(surveyTemplateId))),
  updateSurveyTemplate: (surveyTemplate: ISurveyTemplate) => createTrackableAction(dispatch(SurveyTemplateBusinessStore.actions.updateSurveyTemplate(surveyTemplate))),
  clearSurveyTemplate: () => dispatch(SurveyTemplateBusinessStore.actions.clearSurveyTemplate()),
});

// -- HOCs and exports
// ----------
export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withRouter<ISurveyTemplateContainerOwnProps>(SurveyTemplateContainer as any)));
