import NumberFormatService from '@src/service/util/numberformat/NumberFormatService';
import { Input } from 'antd';
import React from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

const MAX_CURRENCY_INPUT_LENGTH = 22; // NOTE: formatter goes bananas past this point, :shrug:

export interface ICurrencyInputOwnProps {
  placeholder?: string;
  /** Use this props to limit input length, keep in mind two decimal places, default is 11 (millions) */
  maxLength?: number;
  className?: string;
  value?: number;
  onChange?: (value?: number) => void;
  allowClear?: boolean;
  disabled?: boolean;
}

type ICurrencyInputProps = ICurrencyInputOwnProps;

interface ICurrencyInputState {
  value?: number;
}

class CurrencyInput extends React.Component<ICurrencyInputProps, ICurrencyInputState> {
  state: ICurrencyInputState = {
    value: undefined,
  };

  componentDidMount() {
    if (this.props.value) {
      this.setState({
        value: this.props.value * 100,
      });
    }
  }

  componentDidUpdate(prevProps: ICurrencyInputProps, prevState: ICurrencyInputState) {
    if (this.props.value !== prevProps.value) {
      this.setState({
        value: this.props.value ? this.props.value * 100 : undefined,
      });
    }
  }

  render = () => {
    return <NumberFormat
      placeholder={this.props.placeholder}
      className={this.props.className}
      value={this.state.value ?? ''}
      format={this.currencyFormatter}
      onValueChange={this.handleValueChange}
      maxLength={MAX_CURRENCY_INPUT_LENGTH}
      customInput={Input} // look like AntD Input component
      allowClear={this.props.allowClear}
      disabled={this.props.disabled}
    />;
  };

  handleValueChange = (data: NumberFormatValues) => {
    this.setState({
      value: data.floatValue,
    });

    if (this.props.onChange) {
      this.props.onChange(data.floatValue != null ? data.floatValue / 100 : undefined);
    }
  };

  currencyFormatter = (valueToFormat: string) => {
    return NumberFormatService.formatCurrency(Number(valueToFormat) / 100);
  };
}

export default CurrencyInput;
