import ListPagination from '@src/components/common/list/ListPagination';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import { ITimunListPaginationPosition } from '@src/components/course/common/types';
import WebinarGrid from '@src/components/webinar/list/WebinarGrid';
import WebinarListViewFilter from '@src/components/webinar/list/WebinarListViewFilter';
import { IWebinar } from '@src/model/webinar/Webinar';
import { ICollectionData } from '@src/service/business/common/types';
import { IWebinarsFilter } from '@src/service/business/webinars/webinarListBusinessStore';
import { Col, Empty, Row } from 'antd';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

// ----- Constants

export enum WebinarListNavigationMenuKeys {
  Anounced = '1',
  Recorded = '2',
}

export interface IWebinarListViewOwnProps {
  webinarList: ICollectionData<IWebinar>;
  webinarListFilter: IWebinarsFilter;
  sortValue: string;
  showStatusFilter?: boolean;
  paginationPosition?: ITimunListPaginationPosition;
  onFilterChange?: (filter: IWebinarsFilter) => void;
  onSortChange?: (sort: string) => void;
  onCreateWebinar?: () => void;
  onPageChange?: (page: number, pageSize?: number) => void;
  onSetReminder?: (webinar: IWebinar) => void;
}

type IWebinarListViewProps = IWebinarListViewOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps & WithRouterProps;

interface IWebinarListViewState {
  selectedKey: string;
}

class WebinarListView extends React.Component<IWebinarListViewProps, IWebinarListViewState> {
  state: IWebinarListViewState = {
    selectedKey: WebinarListNavigationMenuKeys.Anounced,
  };

  componentDidMount() {
    //
  }

  componentDidUpdate(prevProps: IWebinarListViewProps, prevState: IWebinarListViewState) {
    //
  }

  componentWillUnmount() {
    //
  }

  render() {
    const showStatusFilter = this.props.showStatusFilter ?? true;
    const paginationPosition = this.props.paginationPosition ?? 'both';

    console.log(this.props.showStatusFilter, showStatusFilter);

    return (

      this.props.webinarList ?
        (
          <React.Fragment>
            {/*-------- webinar list filter -----------*/}
            <Row justify="space-between" align="middle">
              {/* ----- filter ----- */}
              <Col xs={24} sm={24} md={showStatusFilter ? 24 : 12}>
                <WebinarListViewFilter webinarListFilter={this.props.webinarListFilter} showStatusFilter={showStatusFilter} onFilterChange={this.props.onFilterChange} sortValue={this.props.sortValue} onSortChange={this.props.onSortChange} />
              </Col>

              {/* ----- pagination (top) ----- */}
              {(paginationPosition === 'both' || paginationPosition === 'top') && (
                <Col xs={24} sm={24} md={showStatusFilter ? 24 : 12}>
                  {this.props.webinarList && <ListPagination page={this.props.webinarList.page} onChange={this.props.onPageChange} />}
                </Col>
              )}
            </Row>

            <WebinarGrid webinars={this.props.webinarList ? this.props.webinarList.content : []} onSetReminder={this.props.onSetReminder} />

            {/* ----- pagination (bottom) ----- */}
            {(paginationPosition === 'both' || paginationPosition === 'bottom') && this.props.webinarList &&
              <ListPagination page={this.props.webinarList.page} onChange={this.props.onPageChange} />
            }
          </React.Fragment>
        ) :
        <Empty description={this.props.translate('WEBINAR_LIST_VIEW.NO_DATA')} />

    );
  }

}

export default withLocalize<IWebinarListViewOwnProps>(withRoles(withRouter(WebinarListView as any)));
