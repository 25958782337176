import { Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

import LocalePickerContainer from '@src/components/app/locale/LocalePickerContainer';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import LoginMenu from '@src/components/login/loginmenu/LoginMenu';
import UserMenu from '@src/components/login/usermenu/UserMenu';
import { IUserInfo } from '@src/model/user/User';
import { logoutCurrentUser } from '@src/service/business/login/loginBusinessService';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';

// -- Prop types
// ----------
export interface IMenuControlsContainerOwnProps {}

export interface IMenuControlsContainerStateProps {
  isUserLoggedIn: boolean;
  currentUser: IUserInfo;
}

type IMenuControlsContainerProps = IMenuControlsContainerOwnProps & IMenuControlsContainerStateProps & IWithLocalizeOwnProps;

// -- Component
// ----------

class MenuControlsContainer extends React.Component<IMenuControlsContainerProps> {
  render = () => {
    return (
      <Row justify="space-between">
        {!this.props.isUserLoggedIn && (
          <div>
            <LocalePickerContainer standalone={true} />
          </div>
        )}
        <div className="lemon-userMenu__loggedUserContainer">
          {this.props.isUserLoggedIn && (
            <React.Fragment>
              <UserMenu user={this.props.currentUser} onDoLogout={this.onLogoutClick} />
            </React.Fragment>
          )}
          {!this.props.isUserLoggedIn && <LoginMenu />}
        </div>
      </Row>
    );
  };

  onLogoutClick = () => {
    logoutCurrentUser();
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IMenuControlsContainerOwnProps): IMenuControlsContainerStateProps => ({
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
  isUserLoggedIn: LoginBusinessStore.selectors.isUserLoggedIn(state),
});

export default connect(mapStateToProps)(withLocalize<IMenuControlsContainerOwnProps>(MenuControlsContainer as any));
