import { Button, Divider, Form } from 'antd';
import React from 'react';

import EducationOutcomePicker from '@src/components/common/datapicker/EducationOutcomePicker';
import FormListData from '@src/components/common/form/FormDataItemList';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IExternalEducationOutcome } from '@src/model/externalEducationTemplate/ExternalEducationOutcome';


// --
// ----- Prop types

interface IMOCK {
  externalEducationOutcomes: IExternalEducationOutcome[];
  datePicker?: any;
  stringovi?: string[];
}

const MOCK: IMOCK = {
  externalEducationOutcomes: [
    { id: '47', title: 'ajmo jedan novi ishod' },
    { id: '18', title: 'ishod234' },
  ],
  stringovi: ['bla', 'bla2'],
};

interface IHomeViewOwnProps {
  label: string;
}
interface IHomeViewStateProps { }

type IHomeViewProps = IHomeViewOwnProps & IHomeViewStateProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Testing... */
const HomeView: React.FC<IHomeViewProps> = (props: IHomeViewProps) => {
  const [form] = Form.useForm<IMOCK>();
  const handleSubmit = (data: any) => {
    console.log('DATA', data);
  };


  return (
    <React.Fragment>
      <p>{props.label} </p>
      <Form<IMOCK>
        form={form}
        layout="vertical"
        initialValues={MOCK}
        name="education"
        onFinish={handleSubmit}>
        {/* Education outcome picker */}
        <FormListData formRef={form} initialCount={3} min={1} max={4} name={'externalEducationOutcomes'}>
          <EducationOutcomePicker />
        </FormListData>

        {/* Outcomes demo */}
        <FormListData formRef={form} initialCount={3} min={2} max={4} name={'externalEducationOutcomesDEMO'}>
          <EducationOutcomePicker />
        </FormListData>
      </Form>
      <Divider />
      <Button onClick={() => form.submit()}> Predaj </Button>
      <Divider />
      {/* <ExternalEducationTemplateForm onSubmit={handleSubmit} /> */}
    </React.Fragment>
  );

};

export default withLocalize<IHomeViewOwnProps>(HomeView as any);
