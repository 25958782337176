import DeleteElementButton from '@src/components/common/button/DeleteElementButton';
import EditableWrapper from '@src/components/common/container/EditableWrapper';
import AppUserAvatar from '@src/components/common/image/AppUserAvatar';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import ImageUploadForm from '@src/components/common/upload/ImageUploadForm';
import TagContainer from '@src/components/tag/TagContainer';
import UserForm from '@src/components/user/form/UserForm';
import { IFile } from '@src/model/file/File';
import { TagItemTypeEnum } from '@src/model/tag/TagItemType';
import { ContactDataTypeEnum, IContactData } from '@src/model/user/ContactData';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { IWorkData } from '@src/model/user/WorkData';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import { ILemonEvent } from '@src/service/util/event/lemonEvent';
import { Button, Card, Col, Row, Typography } from 'antd';
import React from 'react';

// TODO: commented for HZZ demo purpose only
// const dateFormat = AppConfigService.getValue('dateTimeFormat.dateWithYear');

interface IUserDetailsPanelOwnProps {
  user: IUserInfo;
  workPositionList?: IWorkPosition[];
  isOwnProfile: boolean;
  onAvatarSubmit: (data: IFile) => void;
  onUserSubmit: (data: ILemonEvent<IUserInfo>) => void;
  onUserDelete: (data: IUserInfo) => void;
  onUserUpdate?: () => void;
}

type IUserDetailsPanelProps = IUserDetailsPanelOwnProps & IWithRolesOwnProps & IWithLocalizeOwnProps;

interface IUserDetailsPanelState {
  isEditingUser: boolean;
  isEditingAvatar: boolean;
}

class UserDetailsPanel extends React.Component<IUserDetailsPanelProps, IUserDetailsPanelState> {
  state = {
    isEditingUser: false,
    isEditingAvatar: false,
  };

  render = () => {
    // TODO: isProfileIncomplete kill with fire
    const isProfileIncomplete = this.props.user.firstName.length === 0;
    return (
      <Card className="lemon-card--fullWidthBody">
        <Row>
          <Col xs={24} md={12} className="timun-userView__userCol">
            <Row>
              <Col xs={24} lg={8} className="text-center">
                <EditableWrapper onEdit={this.toggleAvatarEdit} showMask={false} showRemove={false}>
                  <AppUserAvatar imagePath={this.props.user.profileImageUrl} size="large" />
                </EditableWrapper>

                {this.state.isEditingAvatar && <ImageUploadForm avatar={true} defaultImageLink={this.props.user.profileImageUrl} onSubmit={this.props.onAvatarSubmit} onClose={this.toggleAvatarEdit} />}
              </Col>
              <Col xs={24} lg={16}>
                <Row className="timun-userView__userName" justify="center">
                  <Typography.Title level={1}>{`${this.props.user.firstName} ${this.props.user.lastName}`}</Typography.Title>
                </Row>
                {this.props.user.contactData
                  // .sort((a: IContactData, b: IContactData) => a.type.id > b.type.id ? 1 : -1)
                  .map((item: IContactData) => (
                    <Row key={item.id}>
                      <LemonIcon className="lemon-userView__contactIcon" name={item.type.id === ContactDataTypeEnum.EMAIL ? 'mail' : 'phone'} />
                      &nbsp;
                      {item.value}
                    </Row>
                  ))}
                <br />
                <Row>
                  <Col>
                    <TagContainer entityId={this.props.user.id} entityType={TagItemTypeEnum.USER} tags={this.props.user.tags} onEntityTagUpdate={this.handleEntityTagUpdate} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={24} md={12} className="timun-userView__detailsCol">
            <Row className="timun-userView__detailsRow">
              <Col span={8}>{this.props.translate('USER_VIEW.WORK_POSITION_LABEL')}:</Col>
              <Col span={16}>
                <b>{this.getWorkPosition()?.name ?? '--'}</b>
              </Col>
            </Row>
            {/* TODO: commented for HZZ demo purpose only */}
            {/* <Row className="timun-userView__detailsRow">
              <Col span={8}>{this.props.translate('USER_VIEW.WORK_SATRT_DATE_LABEL')}:</Col>
              <Col span={16}>{this.getWorkData() && moment(this.getWorkData().startDate).format(dateFormat)}</Col>
            </Row> */}
            {/*<Row>Završena škola:</Row>*/}
            {/* <Row className="timun-userView__detailsRow">
              <Col span={8}>{this.props.translate('USER_VIEW.DATE_OF_BIRTH_LABEL')}:</Col>
              <Col span={16}>{moment(this.props.user.dateOfBirth).format(dateFormat)}</Col>
            </Row> */}
            <Row className="timun-userView__detailsRow">
              <Col span={8}>{this.props.translate('USER_VIEW.ADDRESS_LABEL')}:</Col>
              <Col span={16}>{this.props.user.address && `${this.props.user.address.streetWithHomeNumber}, ${this.props.user.address.city}`}</Col>
            </Row>

            {/* spacer row */}
            <Row>
              <Col>&nbsp;</Col>
            </Row>

            <Row justify="center">
              <Col>
                {this.props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]) && <DeleteElementButton<IUserInfo> buttonText={this.props.translate('COMMON.ACTION_DELETE')} item={this.props.user} titlePropPath={'lastName'} onDelete={this.props.onUserDelete} />}
                &nbsp;&nbsp;
                {(this.props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]) || this.props.isOwnProfile) && (
                  <Button icon={<LemonIcon name="edit" />} onClick={this.toggleUserEdit}>
                    {this.props.translate('COMMON.ACTION_EDIT')}
                  </Button>
                )}
                {(this.state.isEditingUser || isProfileIncomplete) && <UserForm user={this.props.user} workPositionList={this.props.workPositionList || []} onToggleModal={this.toggleUserEdit} allowUserStatusChange={this.props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN])} allowRoleChange={this.props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN])} onUserSubmit={this.props.onUserSubmit} />}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
    );
  };

  handleEntityTagUpdate = () => {
    if (this.props.onUserUpdate) {
      this.props.onUserUpdate();
    }
  };

  private getWorkData = (): IWorkData | undefined => {
    return this.props.user.workData;
  };

  private getWorkPosition = (): IWorkPosition | undefined => {
    const workData = this.getWorkData();
    return workData?.workPosition;
  };

  private toggleUserEdit = () => {
    this.setState({ isEditingUser: !this.state.isEditingUser });
  };

  private toggleAvatarEdit = () => {
    this.setState({ isEditingAvatar: !this.state.isEditingAvatar });
  };
}

export default withLocalize<IUserDetailsPanelOwnProps>(withRoles(UserDetailsPanel as any));
