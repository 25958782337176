import React from 'react';

import IfUserRoles from '@src/components/common/role/IfUserRoles';
import MyCourseListView from '@src/components/course/list/catalog/MyCourseListView';
import { UserRoleEnum } from '@src/model/user/UserRole';

const MyCourseListPage = () => (
  <IfUserRoles roles={[UserRoleEnum.TRAINEE]} fallback="">
    <MyCourseListView />
  </IfUserRoles>
);

export default MyCourseListPage;
