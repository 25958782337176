import { LeftOutlined } from '@ant-design/icons';

import AppContent from '@src/components/common/container/AppContent';
import { IListAdditionalCol } from '@src/components/common/list/ListAdditionalCol';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ProgressBar from '@src/components/common/progressbar/ProgressBar';
import UserListContainer from '@src/components/user/list/UserListContainer';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { IUserListFilter } from '@src/service/business/user/userListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { Col, Row } from 'antd';
import { ProgressProps } from 'antd/lib/progress';
import moment from 'moment';
import React from 'react';
import { Link, withRouter, WithRouterProps } from 'react-router';

const DATE_FORMAT: string = AppConfigService.getValue('dateTimeFormat.dateWithYear');

// --
// ----- Prop types
interface IUserReportsListContainerOwnProps {
  courseId: string;
}

type IUserReportsListContainerProps = IUserReportsListContainerOwnProps & IWithLocalizeOwnProps & WithRouterProps;

// --
// ----- Component
class UserReportsListContainer extends React.Component<IUserReportsListContainerProps> {
  predefinedListFilter: Readonly<IUserListFilter> = { role: UserRoleEnum.TRAINEE, withCourseProgress: true, course: this.props.params.courseId };

  render = () => {
    return (
      <AppContent
        title={
          <Link to="" onClick={this.handleBackClick}>
            <Row>
              <Col>
                <LeftOutlined/>
              </Col>
              <Col>{this.getListTitle()}</Col>
            </Row>
          </Link>
        }
      >
        <UserListContainer additionalCols={this.additionalCols} additionalSearchParams={false} withCreateUserButton={false} predefinedListFilter={this.predefinedListFilter}/>
      </AppContent>
    );
  };

  handleBackClick = () => {
    this.props.router.goBack();
  };

  getListTitle(): string {
    return this.props.translate('USER_LIST.TRAINEES_TITLE');
  }

  getCourseId(): string {
    return this.props.location.pathname.slice(this.props.location.pathname.lastIndexOf('/') + 1);
  }

  private additionalCols = (): IListAdditionalCol[] => {
    return [
      {
        key: 'statusCol',
        responsiveWidth: { lg: 6 },
        headerTitle: this.props.translate('USER_LIST.COURSE_STATUS_LABEL'),
        content: (user: IUserInfo) => {
          const progressProp: ProgressProps = {};
          let statusCell;

          if (user?.additionalData?.progress) {
            statusCell = (
              <span>
                <ProgressBar progressProps={{ type: 'circle', width: 30, ...progressProp }} progress={user.additionalData.progress}/>
                &nbsp;
                {this.props.translate(`COURSE_LIST.COURSE_STATUS_${user.additionalData.progress.completionStatus.name}`)}
              </span>
            );
          }
          return statusCell;
        },
      },
      {
        key: 'dueDateCol',
        responsiveWidth: { lg: 6 },
        headerTitle: this.props.translate('USER_LIST.COURSE_DEADLINE_LABEL'),
        content: (user: IUserInfo) => {
          return user.additionalData?.progress?.deadlineDate != null ? moment(user.additionalData.progress.deadlineDate).format(DATE_FORMAT) : undefined;
        },
      },
    ];
  };
}

export default withRouter<IUserReportsListContainerOwnProps>(withLocalize(UserReportsListContainer) as any);
