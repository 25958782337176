import { sessionExpiredApiResponseErrorHandler, userAuthenticationApiResponseErrorHandler } from '@src/service/api/error/apiServiceErrorHandlers';
import LoginApiService from '@src/service/api/login/LoginApiService';
import { registerAPiServiceErrorHandler } from '@src/service/api/registry/entity/EntityApiService';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import CollectionHelperService from '@src/service/business/common/CollectionHelperService';
import { hasUserToken, initializeCurrentUser, isUserLoggedIn } from '@src/service/business/login/loginBusinessService';
import { initializeTenantCode, initializeTenantConfig } from '@src/service/business/tenant/tenantInitializer';
import AppConfigService from '@src/service/common/AppConfigService';
import { LemonApplicationIconResolver } from '@src/service/common/icon/LemonApplicationIconResolver';
import AuthenticationManager from '@src/service/util/auth/AuthenticationManager';

import { notification } from 'antd';
import moment from 'moment-timezone';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError, finalize, map, mergeMap, share, tap } from 'rxjs/operators';
import { FontAwesomeIconSet } from './icon/FontAwesomeIconSet';

/** App initialization promise - used when caller needs to wait for app initialization event. */
const PUBLIC_APP_INITIALIZED_OBSERVABLE = new BehaviorSubject(false);
const AUTH_APP_INITIALIZED_OBSERVABLE = new BehaviorSubject(false);

/** Synchronous method that returns true if app is already initialized and false otherwise. */
export function isPublicAppInitialized(): boolean {
  return PUBLIC_APP_INITIALIZED_OBSERVABLE.value !== false;
}

export function isAuthAppInitialized(): boolean {
  return AUTH_APP_INITIALIZED_OBSERVABLE.value !== false;
}

/** Asynchronous method that returns promise that is resolved when app is initialized. */
export function publicAppInitialization(): Observable<boolean> {
  return PUBLIC_APP_INITIALIZED_OBSERVABLE.asObservable();
}

export function authAppInitialization(): Observable<boolean> {
  return AUTH_APP_INITIALIZED_OBSERVABLE.asObservable();
}

/** Public app observable. */
const PUBLIC_APP_OBSRV = of(true).pipe(
  // ----- load initial data
  tap(() => {
    console.info('Initializing public app ...');
  }),

  // ----- initialize icon set
  tap(() => {
    LemonApplicationIconResolver.initialize(FontAwesomeIconSet);
    console.info('Initialized icon set');
  }),

  // initialize tenant code *before* any API calls
  initializeTenantCode(),

  mergeMap(() => {
    console.info('Initializing authentication manager');
    return AuthenticationManager.init();
  }),

  // ----- initialize entity services
  tap(() => {
    // register API service error handlers
    registerAPiServiceErrorHandler(sessionExpiredApiResponseErrorHandler);
    registerAPiServiceErrorHandler(userAuthenticationApiResponseErrorHandler);

    // register public API services
    EntityApiServiceRegistry.registerService('PublicTenantConfiguration', 'TenantConfiguration');
    EntityApiServiceRegistry.registerService('PublicOrganizationConfiguration', 'OrganizationConfiguration');
    EntityApiServiceRegistry.registerService('LOGIN', 'AUTH', LoginApiService.create);
    EntityApiServiceRegistry.registerService('Role');
    EntityApiServiceRegistry.registerService('PublicCourse', 'Course');
    EntityApiServiceRegistry.registerService('PublicCourseGroup', 'CourseGroup');
    EntityApiServiceRegistry.registerService('PublicSurveyInstance', 'SurveyInstance');

    // register user auth service because this service contains API call for esolving current user
    EntityApiServiceRegistry.registerAuthService('User');
  }),

  // fetch tenant config
  initializeTenantConfig(),

  // fetch collections
  mergeMap(() => {
    return CollectionHelperService.loadCollections(['Role', 'PublicCourseGroup']);
  }),

  // setup default datetime locale
  tap(() => {
    moment.tz.setDefault(AppConfigService.getValue('app.defaultTimeZone'));
  }),

  // initialize current user
  mergeMap(() => {
    if (hasUserToken()) {
      return initializeCurrentUser();
    } else {
      return of(true);
    }
  }),

  // initialize GUI component default config
  // TODO: could we move this to service initializers? (although components should not be in *service* initializers)
  tap(() => {
    // ----- Ant notifications
    const NOTIFICATION_DEFAULT_CONFIG = AppConfigService.getValue('components.notification.defaultConfig') || {};
    notification.config(NOTIFICATION_DEFAULT_CONFIG);
  }),

  // app initialized
  map(() => {
    console.info('Public app initialized succesfully');

    // return true after successful init
    return true;
  }),

  // handle errors
  catchError((error: any, caught: Observable<any>) => {
    console.error('Public app initialization error', error);

    return throwError(error);
  }),

  finalize(() => {
    // app init promise callback
    PUBLIC_APP_INITIALIZED_OBSERVABLE.next(true);
  }),

  // concat auth observable if user is already logged in
  mergeMap(() => {
    if (isUserLoggedIn()) {
      return AUTH_APP_OBSRV;
    } else {
      return of(true);
    }
  }),

  // share subscriptions so this chain gets executed ONLY once!
  share()
);

/** Auth app observable. */
const AUTH_APP_OBSRV = of(true).pipe(
  // ----- load initial data
  tap(() => {
    console.info('Initializing auth app ...');
  }),

  // initialize current user
  mergeMap(() => {
    // if we have valid user token then user should have been resolved in public part of init, if not, then go to login
    // NOTE: this should not happen because public part must execute before auth part
    if (!isUserLoggedIn()) {
      return AuthenticationManager.login().pipe(
        // login returns undefined so we have map it to true
        map(() => true)
      );
    } else {
      return of(true);
    }
  }),

  // ----- initialize entity services
  tap(() => {
    // all services that require authentication should be registered as "auth" services
    EntityApiServiceRegistry.registerAuthService('TenantConfiguration');
    EntityApiServiceRegistry.registerAuthService('OrganizationConfiguration');
    EntityApiServiceRegistry.registerAuthService('WorkPosition');
    EntityApiServiceRegistry.registerAuthService('CourseGroup');
    EntityApiServiceRegistry.registerAuthService('Report');
    EntityApiServiceRegistry.registerAuthService('Collection');
    EntityApiServiceRegistry.registerAuthService('Question');
    EntityApiServiceRegistry.registerAuthService('Folder');
    EntityApiServiceRegistry.registerAuthService('Course');
    EntityApiServiceRegistry.registerAuthService('LectureGroup');
    EntityApiServiceRegistry.registerAuthService('Lecture');
    EntityApiServiceRegistry.registerAuthService('Comment');
    EntityApiServiceRegistry.registerAuthService('EducationGroup');
    EntityApiServiceRegistry.registerAuthService('ExamTemplate');
    EntityApiServiceRegistry.registerAuthService('CodedGrade');
    EntityApiServiceRegistry.registerAuthService('ExamInstance');
    EntityApiServiceRegistry.registerAuthService('Note');
    EntityApiServiceRegistry.registerAuthService('Notification');
    EntityApiServiceRegistry.registerAuthService('OrganizationProfile');
    EntityApiServiceRegistry.registerAuthService('Reminder');
    EntityApiServiceRegistry.registerAuthService('Webinar');
    EntityApiServiceRegistry.registerAuthService('UserActivity');
    EntityApiServiceRegistry.registerAuthService('Tag');
    EntityApiServiceRegistry.registerAuthService('Activity');
    EntityApiServiceRegistry.registerAuthService('ExternalContent');
    EntityApiServiceRegistry.registerAuthService('EmailTemplate');
    EntityApiServiceRegistry.registerAuthService('UserGroup');
    EntityApiServiceRegistry.registerAuthService('SkillGroup');
    EntityApiServiceRegistry.registerAuthService('Skill');
    EntityApiServiceRegistry.registerAuthService('Company');
    EntityApiServiceRegistry.registerAuthService('ExternalEducationApplication');
    EntityApiServiceRegistry.registerAuthService('ExternalEducationTemplate');
    EntityApiServiceRegistry.registerAuthService('ExternalEducationInstance');
    EntityApiServiceRegistry.registerAuthService('ExternalEducationExpense');
    EntityApiServiceRegistry.registerAuthService('SurveyTemplate');
    EntityApiServiceRegistry.registerAuthService('EducationOutcome');
    EntityApiServiceRegistry.registerAuthService('SurveyInstance');
    EntityApiServiceRegistry.registerAuthService('ExternalEducationPaymentInfo');
    EntityApiServiceRegistry.registerAuthService('EducationQualification');
    EntityApiServiceRegistry.registerAuthService('Location');
    EntityApiServiceRegistry.registerAuthService('IntegrationVideo');
    EntityApiServiceRegistry.registerAuthService('UserSkillEvaluation');
    EntityApiServiceRegistry.registerAuthService('SkillLevel');
    EntityApiServiceRegistry.registerAuthService('EnrollmentRequirement');
    EntityApiServiceRegistry.registerAuthService('EducationSector');
    EntityApiServiceRegistry.registerAuthService('EducationCategory');
    EntityApiServiceRegistry.registerAuthService('Outcome');
    EntityApiServiceRegistry.registerAuthService('SkillBasedRecommendation');
  }),

  // fetch collections
  mergeMap(() => {
    return CollectionHelperService.loadCollections(['WorkPosition', 'CourseGroup', 'EducationCategory']);
  }),

  mergeMap(() => {
    console.info('Starting initializers ...');
    // TODO: return serviceInitializer();
    return of(true);
  }),

  // app initialized
  // tslint:disable-next-line: no-identical-functions
  map(() => {
    console.info('Auth app initialized succesfully');
    // return true after successful init
    return true;
  }),

  // handle errors
  // tslint:disable-next-line: no-identical-functions
  catchError((error: any, caught: Observable<any>) => {
    console.error('App initialization error', error);

    return throwError(error);
  }),

  finalize(() => {
    // app init promise callback
    AUTH_APP_INITIALIZED_OBSERVABLE.next(true);
  }),

  // share subscriptions so this chain gets executed ONLY once!
  share()
);

/**
 * Public application init sequence.
 */
export function initPublicApp() {
  // wait for app init
  if (!isPublicAppInitialized()) {
    return PUBLIC_APP_OBSRV.pipe(
      catchError((err: any, o: Observable<any>) => {
        console.error('Error initializing public part of application', err);
        throw err;
      })
    );
  }
  // already initialized, proceed
  else {
    return of(true);
  }
}

/**
 * Auth application init sequence.
 */
export function initAuthApp() {
  // wait for app init
  if (!isAuthAppInitialized()) {
    return AUTH_APP_OBSRV.pipe(
      catchError((err: any, o: Observable<any>) => {
        console.error('Error initializing auth part of application', err);
        throw err;
      })
    );
  }
  // already initialized, proceed
  else {
    return of(true);
  }
}
