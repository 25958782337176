import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { Divider, Layout, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router';

// -- Const
// ----------
const { Header } = Layout;

// -- Prop types
// ----------
export interface ISurveyInstanceNotifViewOwnProps {
}

type ISurveyInstanceNotifViewProps = ISurveyInstanceNotifViewOwnProps & IWithLocalizeOwnProps;

interface ISurveyInstanceNotifViewState {
}

// -- Component
// ----------
class SurveyInstanceNotifView extends React.Component<ISurveyInstanceNotifViewProps, ISurveyInstanceNotifViewState> {
  state: ISurveyInstanceNotifViewState = {
  };

  render() {
    return (
      <React.Fragment>
        <Layout className="panel  timun-examInstanceView__examContentTitle"> <Header>
          <Typography.Title> Uspješno predano ! </Typography.Title>
        </Header>
          <Divider />
          <Layout>
            <Typography.Title>   Zahvaljujemo na ispunjenom upitniku </Typography.Title>
            <Link to="/"> Povratak na početnu stranicu </Link>
          </Layout>
        </Layout>
      </React.Fragment>
    );
  }
}

// -- HOCs and exports
// ----------

export default withLocalize<ISurveyInstanceNotifViewOwnProps>(SurveyInstanceNotifView as any);
