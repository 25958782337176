export interface IUserGroupVisibility {
  description: string;
  id: UserGroupVisibilityEnum;
  name: string;
}

export enum UserGroupVisibilityEnum {
  PUBLIC = '1',
  LIMITED = '2',
  CLOSED = '3',
}
