import CoordinatorPickerModalView from '@src/components/externalEducationApplication/participant/CoordinatorPickerModalView';
import { IActivityParticipant } from '@src/model/activity/ActivityParticipant';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import UserListBusinessStore, { IUserListFilter } from '@src/service/business/user/userListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { LangUtils } from '@src/service/util/LangUtils';
import { debounce } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

const debounceTimeout = AppConfigService.getValue('components.common.debounceTimeout');
const minSearchLength = AppConfigService.getValue('components.common.minSearchStringLength');

interface ICoordinatorPickerModalContainerOwnProps {
  currentCoordinator?: IActivityParticipant;
  onChangeCoordinator: (id: string) => void;
}

interface ICoordinatorPickerModalContainerStateProps {
  // coordinatorList: ICollectionData<IUserInfo>;
}

interface ICoordinatorPickerModalContainerDispatchProps {
  fetchCoordinatorList: (params: ICollectionFetchPayload<IUserListFilter>) => ITrackableAction;
}
type ICoordinatorPickerModalContainerProps = ICoordinatorPickerModalContainerOwnProps & ICoordinatorPickerModalContainerStateProps & ICoordinatorPickerModalContainerDispatchProps;

interface ICoordinatorPickerModalContainerState {
  page: number;
  size: number;
  sort: string[];
  coordinatorList: IUserInfo[];
}

class CoordinatorPickerModalContainer extends React.Component<ICoordinatorPickerModalContainerProps, ICoordinatorPickerModalContainerState> {
  state: ICoordinatorPickerModalContainerState = {
    page: 0,
    size: AppConfigService.getValue('api.paging.defaultPageSize'),
    sort: [],
    coordinatorList: [],
  }

  render = () => {
    return <React.Fragment>
      <CoordinatorPickerModalView onSearch={this.handleSearch} onSubmit={this.props.onChangeCoordinator} currentCoordinator={this.props.currentCoordinator} coordinatorList={this.state.coordinatorList} />
    </React.Fragment>
  }

  handleSearch = debounce((value: string) => {
    const filter: IUserListFilter = { name: value };
    if (value.length >= minSearchLength) {
      this.updateList(filter);
    } else if (LangUtils.isEmpty(value) || value.length < minSearchLength) {
      this.storeList()
    }
  }, debounceTimeout)

  private updateList = ($filter: IUserListFilter) => {
    const filter = { role: UserRoleEnum.COORDINATOR, ...$filter }
    this.props.fetchCoordinatorList({ page: this.state.page, size: this.state.size, sort: this.state.sort, filter }).track().subscribe(
      (data: ICollectionData<IUserInfo>) => this.storeList(data.content)
    );
  }

  private storeList = (coordinatorList?: IUserInfo[]) => {
    this.setState({ coordinatorList: coordinatorList || [] });
  };
}

// -- HOCs and exports
// ----------


// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ICoordinatorPickerModalContainerOwnProps): ICoordinatorPickerModalContainerStateProps => ({
  // coordinatorList: UserListBusinessStore.selectors.getUserList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ICoordinatorPickerModalContainerDispatchProps => ({
  fetchCoordinatorList: (params: ICollectionFetchPayload<IUserListFilter>) => createTrackableAction(dispatch(UserListBusinessStore.actions.fetchUserPickerList(params)))
});

export default connect(mapStateToProps, mapDispatchToProps)(CoordinatorPickerModalContainer);