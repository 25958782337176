import { Upload } from 'antd';
import { UploadProps } from 'antd/lib/upload/interface';
import { RcFile } from 'antd/lib/upload/interface';
import React from 'react';
import { connect } from 'react-redux';
import { Observable, Observer, of, Subscription } from 'rxjs';
import { catchError, finalize, mergeMap, tap } from 'rxjs/operators';
import * as tus from 'tus-js-client';

import { IIntegrationVideo, IntegrationVideoTypeEnum } from '@src/model/integrationvideo/IntegrationVideo';
import IntegrationVideoBusinessStore, { IIntegrationVideoTemplateCreatePayload } from '@src/service/business/integrationvideo/IntegrationVideoBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

export type IUploadedVideoFile = RcFile;

export interface IUploadProgress {
  uploaded: number;
  total: number;
}

export interface IUploadStatus {
  isUploading: boolean;
  uploadProgress?: IUploadProgress;
}

// -- Prop types
// ----------

export interface IIntegrationVideoUploadOwnProps {
  /** Render children function. */
  children?: (status: IUploadStatus) => React.ReactNode;
  /** Render as d'n'd area? */
  dragger?: boolean;
  /** Additional upload props. See: https://ant.design/components/upload/#API */
  uploadProps?: UploadProps<IIntegrationVideo>;

  /** Callback called upon sucesfull video upload */
  onUpload?: (video: IIntegrationVideo) => void;
  /** Callback called repeatedly during entire video creation and upload process. */
  onStatusChange?: (status: IUploadStatus) => void;
  /** Callback called upon video upload error */
  onError?: (err: unknown) => void;
}
export interface IIntegrationVideoUploadStateProps { }
export interface IIntegrationVideoUploadDispatchProps {
  createVideoTemplate: (params: IIntegrationVideoTemplateCreatePayload) => ITrackableAction;
  confirmVideoUpload: (id: string) => ITrackableAction;
}
type IIntegrationVideoUploadProps = IIntegrationVideoUploadOwnProps & IIntegrationVideoUploadStateProps & IIntegrationVideoUploadDispatchProps;

interface IIntegrationVideoUploadState {
  isUploading: boolean;
  uploadProgress?: IUploadProgress;
}

// -- Component
// ----------

/**
 * Integration video upload component.
 * It's based on antd's Upload component but implements custom upload request and uses antd's component only for d'n'd and file picking.
 *
 * Implements entire integration video create/upload process
 *  - call timun BE to create video placeholder
 *  - BE returns placeholder with one-time upload URI
 *  - upload file to upload URI
 *  - on success call BE to set placeholder status
 *
 * On unmount, component will abort any ongoing upload process. So, if you want to abort, just unmount.
 *
 * NOTE: Initially based on https://github.com/Javier-Machin/tus-vimeo-upload/blob/master/src/App.js
 */
class IntegrationVideoUpload extends React.Component<IIntegrationVideoUploadProps, IIntegrationVideoUploadState> {
  state: IIntegrationVideoUploadState = {
    isUploading: false,
  };

  private uploader?: tus.Upload;
  private uploadPipelineSubscription?: Subscription;

  componentDidUpdate(prevProps: IIntegrationVideoUploadProps, prevState: IIntegrationVideoUploadState) {
    if (prevState.isUploading !== this.state.isUploading || prevState.uploadProgress !== this.state.uploadProgress) {
      this.notifyUploadProgressChange();
    }
  }

  componentWillUnmount() {
    this.cleanup();
  }

  render = () => {
    // NOTE: antd's Upload component provides file picker and drag&drop area
    // TODO: maybe we could replace it with custom/other d&d area if that's all it gives us?!
    const UploadType = !this.props.dragger ? Upload : Upload.Dragger;

    const props: UploadProps = {
      showUploadList: false,

      customRequest: (componentsData) => {
        // TODO: should we check if this really is File or smtng else?
        const file = componentsData.file as File;

        // ----- integration video upload process
        this.uploadPipelineSubscription = of(true)
          .pipe(
            tap(() => {
              this.toggleUploadingStatus(true);
            }),

            // --- create video - call our API
            mergeMap(() => {
              return this.createVideo(file);
            }),

            // --- upload video - call integration API
            mergeMap((integrationVideo) => {
              return this.uploadVideo(integrationVideo, file);
            }),

            // --- confirm video upload - call our API
            mergeMap((integrationVideo) => {
              return this.confirmVideoUpload(integrationVideo);
            }),

            tap((integrationVideo) => {
              this.props.onUpload?.(integrationVideo);
            }),

            catchError((err) => {
              this.props.onError?.(err);

              throw err;
            }),

            finalize(() => {
              this.toggleUploadingStatus(false);
              this.afterUpload();
            })
          )
          .subscribe();
      },
      ...(this.props.uploadProps ?? {}),
    };

    // disable from upload props must override any other disableds
    const effectiveDisabled = (this.props.uploadProps?.disabled != null && this.props.uploadProps?.disabled) ?? this.state.isUploading;

    return (
      <UploadType {...props} disabled={effectiveDisabled}>
        {this.props.children != null ? this.props.children({ isUploading: this.state.isUploading, uploadProgress: this.state.uploadProgress }) : null}
      </UploadType>
    );
  };

  toggleUploadingStatus = (enabled: boolean) => {
    this.setState({ isUploading: enabled });
  };

  updateUploadProgress = (uploaded: number, total: number) => {
    this.setState({ uploadProgress: { uploaded, total } });
  };

  /** Send status change event. Since it is a composite event it's triggered by component's didUpdate when one of composites changes. */
  notifyUploadProgressChange = () => {
    this.props.onStatusChange?.({ isUploading: this.state.isUploading, uploadProgress: this.state.uploadProgress });
  };

  /** Create video placeholder. Currently only Vimeo is supported */
  createVideo = (file: File): Observable<IIntegrationVideo> => {
    // TODO: make integration type configurable. Maybe as a component attribute?
    return this.props.createVideoTemplate({ size: file.size, integrationType: { id: IntegrationVideoTypeEnum.VIMEO }, filename: file.name }).track();
  };

  /** Upload video directly to integration */
  uploadVideo = (videoPlaceholder: IIntegrationVideo, file: File): Observable<IIntegrationVideo> => {
    return Observable.create((observer: Observer<IIntegrationVideo>) => {
      // Create a new tus upload
      this.uploader = new tus.Upload(file, {
        // using one time upload link provided to our BE by integration service
        uploadUrl: videoPlaceholder.properties.uploadLink,
        // delays handle network glitches but can also help mitigate provider's rate limits (eg. https://developer.vimeo.com/guidelines/rate-limiting)
        retryDelays: [0, 3000, 5000, 10000, 20000],

        onError(error) {
          // TODO: should we & how to call componentsData.onError()

          observer.error(error);
        },
        onProgress: (bytesUploaded, bytesTotal) => {
          // console.log(`Video upload progress: ${bytesUploaded}, ${bytesTotal}, ${(bytesUploaded / bytesTotal) * 100}%`);
          // TODO: should we and how to call componentsData.onProgress() - TUS doesn't give us ProgressEvent which is required by antd's onProgress callback

          this.updateUploadProgress(bytesUploaded, bytesTotal);
        },
        onSuccess: () => {
          // TODO: should we & how to call componentsData.onSuccess()

          observer.next(videoPlaceholder);
          observer.complete();
        },
      });

      this.uploader.start();
    });
  };

  /** Cleanup component's resources. */
  cleanup = () => {
    if (this.uploadPipelineSubscription != null) {
      this.uploadPipelineSubscription.unsubscribe();
      this.uploadPipelineSubscription = undefined;
    }
    // abort uploader if there is one
    if (this.uploader != null) {
      // NOTE: abort DELETES uploaded file!!! Use only when interrupting current upload.
      this.uploader.abort(true);
      this.uploader = undefined;
    }
  };

  /** Cleanup after successful upload. It's important to clear uploader so it doesn't get aborted in cleanup because abort deletes uploaded file. */
  afterUpload = () => {
    this.uploader = undefined;
  };

  /** Confirm that video has been uploaded */
  confirmVideoUpload = (videoIntegration: IIntegrationVideo) => {
    return this.props.confirmVideoUpload(videoIntegration.id).track();
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IIntegrationVideoUploadOwnProps): IIntegrationVideoUploadStateProps => ({});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IIntegrationVideoUploadDispatchProps => ({
  createVideoTemplate: (params: IIntegrationVideoTemplateCreatePayload) => dispatch(createTrackableAction(IntegrationVideoBusinessStore.actions.createVideoTemplate(params))),
  confirmVideoUpload: (id: string) => dispatch(createTrackableAction(IntegrationVideoBusinessStore.actions.confirmVideoUpload(id))),
});

export default connect<IIntegrationVideoUploadStateProps, IIntegrationVideoUploadDispatchProps, IIntegrationVideoUploadOwnProps>(mapStateToProps, mapDispatchToProps)(IntegrationVideoUpload as any);
