import StatusBadgeWrapper from '@src/components/common/card/StatusBadgeWrapper';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import IIdTitleRef from '@src/model/common/IdTitleRef';
import { ICourse } from '@src/model/course/Course';
import { IWebinar } from '@src/model/webinar/Webinar';
import { Card, Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router';

// -- Prop types
// ----------

export interface IEducationCardOwnProps {
  education: ICourse | IWebinar;
  type: 'COURSE' | 'WEBINAR';
  showStatusBadge?: boolean;
  cardCover: React.ReactNode;
  cardDescription: React.ReactNode;
  currentLecture?: IIdTitleRef;
  getPath: (id: string, lecture?: string) => string;
}

type IEducationCardProps = IEducationCardOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

// -- Component
// ----------

/** Component display education information */
class EducationCard extends React.Component<IEducationCardProps> {
  render() {

    return (
      <Link to={this.props.getPath(this.props.education.id, this.props.currentLecture?.id)} data-test-id={`timun-educationCard__link_${this.props.education.id}:lecture_${this.props.currentLecture?.id}`}>
        <StatusBadgeWrapper status={this.props.education.status} showBadge={this.props.showStatusBadge} type={this.props.type}>
          <Card hoverable={true} cover={this.props.cardCover} className="timun-educationCard">
            <Card.Meta
              title={
                <div className="text-center">
                  <Tooltip placement="topLeft" overlay={this.props.education.title}>{this.props.education.title}</Tooltip>
                </div>
              }
              description={this.props.cardDescription}
            />
          </Card>
        </StatusBadgeWrapper>
      </Link>
    );
  }
}

export default withRoles(withLocalize<IEducationCardOwnProps>(EducationCard as any));
