import React from 'react';

import SubviewLayout from '@src/components/app/layout/SubviewLayout';
import ReportsNavigation from '@src/components/app/navigation/ReportsNavigation';
import AppContent from '@src/components/common/container/AppContent';

const ReportsLayout: React.FC = (props: any) => {
  return (
    <AppContent>
      <SubviewLayout header={<ReportsNavigation />}>{props.children}</SubviewLayout>
    </AppContent>
  );
};

export default ReportsLayout;
