import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { UserNameView } from '@src/components/common/view/UserNameView';
import { IActivityHistory } from '@src/model/activity/ActivityHistory';
import { IActivityPhase } from '@src/model/activity/ActivityPhase';
import AppConfigService from '@src/service/common/AppConfigService';
import { Col, Row, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

// -- Const
// ----------
const dateFormat = AppConfigService.getValue('dateTimeFormat.dateWithTime');

// -- Prop types
// ----------
export interface IActivityHistoryListOwnProps {
  activityHistoryList: IActivityHistory[];
}

type IActivityHistoryListProps = IActivityHistoryListOwnProps & IWithLocalizeOwnProps & WithRouterProps;

/** Display activity history text with date list */
const ActivityHistoryList: React.FC<IActivityHistoryListProps> = (props: IActivityHistoryListProps) => {
  const getActivityPhaseText = ((phase: IActivityPhase) => props.translate(`ACTIVITY_HISTORY.PHASE_TEXT.${phase.name}`));

  return <React.Fragment>
    {props.activityHistoryList.map((activity) => {
      return <div className="panel" key={activity.id}>
        <Row justify="start">
          <Col>
            <UserNameView user={activity.startedBy} /> &nbsp;
            {getActivityPhaseText(activity.phase)}
          </Col>
        </Row>
        <Row>
          <Col>
            <Typography.Paragraph type="secondary">{moment(activity.actualStartDateTime).format(dateFormat)}</Typography.Paragraph>
          </Col>
        </Row>
      </div>;
    })}
  </React.Fragment>;
};

export default withLocalize(withRouter<IActivityHistoryListOwnProps>(ActivityHistoryList as any));
