import AppConfigService from '@src/service/common/AppConfigService';
import {SortOrder} from 'antd/es/table/interface';

/** Returns sorter for each field with sorter configured in AppConfig script */
export function hasSorter(columnKey: string): boolean {
  return AppConfigService.getValue(`components.repository.sort.${columnKey}`) ? true : false;
}

export function sortOrder(sort: string): SortOrder {
  return sort.includes(',asc') ? 'ascend' : 'descend';
}