import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/common/EducationApplicationHelperUtils';
import { IFileListsByType } from '@src/components/externalEducationApplication/common/types';
import SurveyInstanceFeedbackContainer from '@src/components/survey/SurveyInstanceFeedbackContainer';
import { ActivityPhaseEnum } from '@src/model/activity/ActivityPhase';

import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { IFile } from '@src/model/file/File';
import { ISurveyInstance } from '@src/model/survey/SurveyInstance';
import { SurveyTypeEnum } from '@src/model/survey/SurveyTemplate';
import { Button, Col, Row, Typography } from 'antd';

import React from 'react';

const LAYOUT_BUTTONS = {
  xs: 24,
  sm: 12,
};

// -- Prop types
// ----------
interface IExternalEducationApplicationCoordinatorCompletedViewOwnProps {
  externalEducationApplication: IExternalEducationApplication;
  applicationFiles: IFileListsByType;
  onUpdate?: (data: IExternalEducationApplication) => void;
  // tslint:disable-next-line: bool-param-default
  onChangePhase?: (newPhase: ActivityPhaseEnum, save?: boolean) => void;
  onFileUpload?: (file: IFile[]) => void;
  onFileRemove?: (file: IFile[]) => void;
}

type IExternalEducationApplicationCoordinatorCompletedViewProps = IExternalEducationApplicationCoordinatorCompletedViewOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

interface IExternalEducationApplicationCoordinatorCompletedViewState {
  isModalVisible: boolean;
  selectedSurvey?: ISurveyInstance;
}

// -- Component
// ----------

class ExternalEducationApplicationCoordinatorCompletedView extends React.Component<IExternalEducationApplicationCoordinatorCompletedViewProps, IExternalEducationApplicationCoordinatorCompletedViewState> {
  state: IExternalEducationApplicationCoordinatorCompletedViewState = {
    isModalVisible: false,
  };

  componentDidUpdate = (prevProps: IExternalEducationApplicationCoordinatorCompletedViewProps, prevState: IExternalEducationApplicationCoordinatorCompletedViewState) => {
    //
  };

  isApplicationInPhases = (phases: ActivityPhaseEnum[]): boolean => {
    return EducationApplicationHelperUtils.isInPhases(phases, this.props.externalEducationApplication.activity);
  };

  handleChangePhase = (phase: ActivityPhaseEnum) => {
    if (this.props.onChangePhase) {
      this.props.onChangePhase(phase);
    }
  };

  render() {
    const traineeSurvey = this.props.externalEducationApplication.surveyInstances?.find((survey) => survey.type.id === SurveyTypeEnum.USER_EDUCATION_COMPLETED);
    const employerSurvey = this.props.externalEducationApplication.surveyInstances?.find((survey) => survey.type.id === SurveyTypeEnum.EMPLOYER);
    const educationProviderSurvey = this.props.externalEducationApplication.surveyInstances?.find((survey) => survey.type.id === SurveyTypeEnum.EDUCATION_PROVIDER);
    return (
      <React.Fragment>
        {this.isApplicationInPhases([ActivityPhaseEnum.EDUCATION_COMPLETED]) && (
          <React.Fragment>
            <Row>
              <Col>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.EDUCATION_COMPLETED_TEXT')}</Col>
            </Row>

            <Row gutter={[12, 12]} justify="center">
              {traineeSurvey && (
                <Col {...LAYOUT_BUTTONS} className="text-center">
                  <Typography.Title level={3}>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.SURVEY.TRAINEE_SURVEY_LABEL')}</Typography.Title>

                  {/* tslint:disable-next-line: no-duplicate-string */}
                  <Button onClick={() => this.showSurvey(traineeSurvey)} disabled={!traineeSurvey.answersSubmitted} data-test-id="timun-externalEducationApplicationView__openTraineeSurveyButton">
                    {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.OPEN_SURVEY_BUTTON_LABEL')}
                  </Button>
                </Col>
              )}

              {employerSurvey && (
                <Col {...LAYOUT_BUTTONS} className="text-center">
                  <Typography.Title level={3}>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.SURVEY.EMPLOYER_SURVEY_LABEL')}</Typography.Title>

                  <Button onClick={() => this.showSurvey(employerSurvey)} disabled={!employerSurvey.answersSubmitted} data-test-id="timun-externalEducationApplicationView__openEmployerSurveyButton">
                    {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.OPEN_SURVEY_BUTTON_LABEL')}
                  </Button>
                </Col>
              )}

              {educationProviderSurvey && (
                <Col {...LAYOUT_BUTTONS} className="text-center">
                  <Typography.Title level={3}>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.SURVEY.EDUCATION_PROVIDER_SURVEY_LABEL')}</Typography.Title>

                  <Button onClick={() => this.showSurvey(educationProviderSurvey)} disabled={!educationProviderSurvey.answersSubmitted} data-test-id="timun-externalEducationApplicationView__openProviderSurveyButton">
                    {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.OPEN_SURVEY_BUTTON_LABEL')}
                  </Button>
                </Col>
              )}
            </Row>
          </React.Fragment>
        )}

        {this.isApplicationInPhases([ActivityPhaseEnum.CONTRACT_TERMINATED]) && (
          <React.Fragment>
            <Row>
              <Col>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.CONTRACT_TERMINATED_TEXT')}</Col>
            </Row>
          </React.Fragment>
        )}

        {this.state.isModalVisible && this.state.selectedSurvey && <SurveyInstanceFeedbackContainer onModalClose={this.handleModalClose} surveyInstance={this.state.selectedSurvey} />}
      </React.Fragment>
    );
  }

  handleModalClose = () => {
    this.setState({ isModalVisible: !this.state.isModalVisible });
  };

  private showSurvey = (survey: ISurveyInstance): void => {
    return this.setState({ isModalVisible: true, selectedSurvey: survey });
  };
}

// -- HOCs and exports
// ----------

export default withRoles(withLocalize<IExternalEducationApplicationCoordinatorCompletedViewOwnProps>(ExternalEducationApplicationCoordinatorCompletedView as any));
