import { QuizDisplay } from '@lamarodigital/quizzler-lib-frontend';
import { IQuizzlerUploadProps } from '@lamarodigital/quizzler-lib-frontend/components';
import { IQuestion } from '@lamarodigital/quizzler-lib-frontend/model/quiz/question/Question';
import { IQuiz } from '@lamarodigital/quizzler-lib-frontend/model/quiz/Quiz';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { FileUploadHelper } from '@src/components/common/upload/FileUploadHelper';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import ExamInstanceHelperUtils from '@src/components/exam/common/ExamInstanceHelperUtils';
import { QuizHelperUtils } from '@src/components/exam/quiz/QuizHelperUtils';
import { ParticipantRoleEnum } from '@src/model/activity/ActivityParticipant';
import { IExamInstance } from '@src/model/education/ExamInstance';
import { Button, Divider, Layout, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

// -- Const
// ----------
const { Header, Content, Footer } = Layout;

// -- Prop types
// ----------
export interface IExamInstanceFormOwnProps {
  examInstance?: IExamInstance;
  showQuestionIntroActionButtons: boolean;
  showEvaluationFeedback: boolean;
  onSubmit?: (examInstance: IExamInstance) => void;
  getLink: () => string;
}

export interface IExamInstanceFormStateProps {

}

export interface IExamInstanceFormDispatchProps {
}

type IExamInstanceFormProps = IExamInstanceFormOwnProps & IExamInstanceFormStateProps & IExamInstanceFormDispatchProps & IWithLocalizeOwnProps;

interface IExamInstanceFormState {
  examInstance?: IExamInstance;
  isQuizFinished: boolean;
}

// -- Component
// ----------
class ExamInstanceForm extends React.Component<IExamInstanceFormProps, IExamInstanceFormState> {
  state: IExamInstanceFormState = {
    examInstance: undefined,
    isQuizFinished: false,
  };

  componentDidMount() {
    // mount
    if (this.props.examInstance) {
      this.setState({ examInstance: this.props.examInstance });
    }
  }

  render() {
    const uploadProps: IQuizzlerUploadProps = {
      ...FileUploadHelper.getUploadDefaultProps(),
      onChange: FileUploadHelper.handleAntdUploadChange,
      getQuizzlerFile: QuizHelperUtils.getQuizzlerFile,

    };
    const examInstanceFirstActivity = this.props.examInstance?.activities?.slice(0, 1).shift();
    return (
      <React.Fragment>
        <Layout className="panel">
          <Header>
            <HeaderTitle link={this.props.getLink()} title={this.props.examInstance?.title ?? ''} icon={<LemonIcon name="left" />} />
            {this.props.examInstance && <Typography.Title level={2}>{this.props.translate('EXAM_INSTANCE_FROM.ASSIGNEE')} {examInstanceFirstActivity && ExamInstanceHelperUtils.getActivityParticipantNameByRole(ParticipantRoleEnum.ASSIGNEE, examInstanceFirstActivity)} </Typography.Title>}
          </Header>
          <Divider />
          <Layout>
            <Content className="timun-examInstanceForm__content">
              <QuizDisplay
                appUploadProps={uploadProps}
                resolveFileUrl={QuizHelperUtils.resolveFileUrl}
                showHelpIntroActionButton={this.props.showQuestionIntroActionButtons}
                showRedoIntroActionButton={this.props.showQuestionIntroActionButtons}
                showEvaluationFeedback={this.props.showEvaluationFeedback}
                onChange={this.handleQuizValuesChange}
                quiz={this.props.examInstance?.collection} />
            </Content>
          </Layout>
          {this.props.onSubmit && <React.Fragment>
            <Divider />
            <Footer>
              <Button disabled={!this.state.isQuizFinished} onClick={this.handleSubmit}>{this.props.translate('EXAM_INSTANCE.BUTTON_SUBMIT_LABEL')}</Button>
            </Footer>
          </React.Fragment>}
        </Layout>
      </React.Fragment>
    );
  }

  handleSubmit = () => {
    if (this.props.onSubmit && this.state.examInstance) {
      this.props.onSubmit(this.state.examInstance);
    }
  };

  handleQuizValuesChange = (quiz: IQuiz) => {
    const updatedExamInstance = { ...this.props.examInstance, collection: quiz } as IExamInstance;
    this.setState({ examInstance: updatedExamInstance }, () => this.handleIfQuizFinished());
  };

  handleIfQuizFinished = () => {
    if (this.state.examInstance) {
      const isFinised: boolean = this.state.examInstance.collection.questions.every((question: IQuestion) => question.answerSubmission && question.answerSubmission.answers.length >= 1);
      this.setState({ isQuizFinished: isFinised });
    }
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IExamInstanceFormOwnProps): IExamInstanceFormStateProps => ({});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IExamInstanceFormDispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<IExamInstanceFormOwnProps>(ExamInstanceForm as any));
