import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { ICollectionData, ICollectionFetchPayload, IEntityDataPayload, INamedPayload, IPayloadAction } from '@src/service/business/common/types';
import AppConfigService from '@src/service/common/AppConfigService';
import { trackAction } from '@src/service/util/observable/operators';

// -
// -------------------- Types

export interface ICollectionDataPayload<T, M> extends INamedPayload {
  name: string;
  collection: ICollectionData<T, M>;
}

// -
// -------------------- Selectors

const getCollection = (store: any, name: string): ICollectionData<any> => store.collections[name];

const getCollectionContent = (store: any, name: string): any[] => store.collections[name] && (store.collections[name] as ICollectionData<any>).content;

// -
// -------------------- Actions

const Actions = {
  COLLECTION_FETCH: 'COLLECTION_FETCH',
  COLLECTION_LOAD: 'COLLECTION_LOAD',
};

const fetchCollection = (entityName: string): IPayloadAction<IEntityDataPayload<ICollectionFetchPayload<{}>>> => {
  return {
    type: Actions.COLLECTION_FETCH,
    payload: {
      entity: entityName,
      data: {
        filter: {},
        sort: [],
        page: 0,
        size: AppConfigService.getValue('api.collectionDefaultLimit'),
      },
    },
  };
};

const loadCollection = (name: string, collection: ICollectionData<any>): IPayloadAction<ICollectionDataPayload<any, any>> => {
  return {
    type: Actions.COLLECTION_LOAD,
    payload: {
      name,
      collection,
    },
  };
};

// -
// -------------------- Side-effects

const fetchCollectionEffect = (action$: Observable<IPayloadAction<IEntityDataPayload<ICollectionFetchPayload<{}>>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COLLECTION_FETCH;
    }),

    mergeMap((action) => {
      const entity = action.payload.entity;
      const params = action.payload.data;

      return EntityApiServiceRegistry.getService(entity)
        .fetchEntityList(params)
        .pipe(
          trackAction(action),
          map((collection) => {
            return loadCollection(entity, collection as ICollectionData<any>);
          })
        );
    }),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching collection', error);

      return o;
    })
  );
};

// -
// -------------------- Reducers

const collections = (state: { [name: string]: ICollectionData<any>; } = {}, action: IPayloadAction<ICollectionDataPayload<any, any>>) => {
  if (action.type === Actions.COLLECTION_LOAD) {
    return {
      ...state,
      [action.payload.name]: action.payload.collection,
    };
  }

  return state;
};

// --
// ----- Export store object

const CollectionBusinessStore = {
  actions: {
    fetchCollection,
    loadCollection,
  },

  selectors: {
    getCollection,
    getCollectionContent,
  },

  effects: {
    fetchCollectionEffect,
  },

  reducers: {
    collections,
  },
};
export default CollectionBusinessStore;
