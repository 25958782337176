import React from 'react';

import EducationCategoryDataPicker from '@src/components/common/datapicker/EducationCategoryDataPicker';
import EducationOutcomePicker from '@src/components/common/datapicker/EducationOutcomePicker';
import EducationSectorDataPicker from '@src/components/common/datapicker/EducationSectorDataPicker';
import EnrollmentRequirementDataPicker from '@src/components/common/datapicker/EnrollmentRequirementDataPicker';
import SkillDataPicker from '@src/components/common/datapicker/SkillDataPicker';
import FormDataItemList from '@src/components/common/form/FormDataItemList';
import FormItemLabelIcon from '@src/components/common/form/FormItemLabelIcon';
import CurrencyInput from '@src/components/common/input/CurrencyInput';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import AppConfigService from '@src/service/common/AppConfigService';
import { LangUtils } from '@src/service/util/LangUtils';
import { Col, Form, FormInstance, Input, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

// -- Const
// ----------
const TEXT_AREA_MAX_LENGTH = AppConfigService.getValue('components.common.bigCharacterLengthInput');
const INPUT_MAX_LENGTH = AppConfigService.getValue('components.common.mediumCharacterLengthInput');
const MIN_INPUT_NUMBER = 1;

// -- Prop types
// ----------

export interface IExternalEducationTemplateFormOwnProps {
  value?: IExternalEducationTemplate;
  onSubmit?: (data: IExternalEducationTemplate) => void;
  formRef?: FormInstance<IExternalEducationTemplate>;
  disabled?: boolean;
}
type IExternalEducationTemplateFormProps = IExternalEducationTemplateFormOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Display external education template form */
const ExternalEducationTemplateForm: React.FC<IExternalEducationTemplateFormProps> = (props: IExternalEducationTemplateFormProps) => {
  const [form] = Form.useForm<IExternalEducationTemplate>(props.formRef);

  const validateNumberMinMaxRange = (rule: any, value: any, callback: any) => {
    const minPrice: number = parseInt(form.getFieldValue('minPrice'), 10);
    const maxPrice: number = parseInt(form.getFieldValue('maxPrice'), 10);
    if (LangUtils.isNumber(maxPrice) && LangUtils.isNumber(minPrice) && minPrice > maxPrice) {
      callback(props.translate('COMMON.VALIDATION.MIN_VALUE_EXCEED_MAX_VALUE'));
    } else {
      callback();
    }
  };

  return (
    <Form<IExternalEducationTemplate>
      initialValues={props.value}
      layout="vertical"
      form={form}
      name="externalEducationTemplate"
      onFinish={props.onSubmit}>

      <Form.Item noStyle={true} hidden={true} name={'status'}>
        <Input />
      </Form.Item>

      <Form.Item noStyle={true} hidden={true} name={'educationProvider'}>
        <Input />
      </Form.Item>

      <Form.Item noStyle={true} hidden={true} name={['id']}>
        <Input />
      </Form.Item>

      <Form.Item
        label={<FormItemLabelIcon label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.TITLE_LABEL')}
          tooltipDescription={props.translate('COMMON.LABEL.MAX_LENGTH_CHARACTER_INFO_LABEL', { maxCharNum: INPUT_MAX_LENGTH })} />}
        name={'title'}
        rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
        { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
        { max: INPUT_MAX_LENGTH, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') }]}
      >
        <Input disabled={props.disabled} />
      </Form.Item>

      <Form.Item label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.SKILLS_LABEL')} required={true}>
        <FormDataItemList disabled={props.disabled} formRef={form} initialCount={1} min={1} name={'skills'} >
          <SkillDataPicker disabled={props.disabled} />
        </FormDataItemList>
      </Form.Item>

      <Form.Item
        label={<FormItemLabelIcon label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.DESCRIPTION_LABEL')}
          tooltipDescription={props.translate('COMMON.LABEL.MAX_LENGTH_CHARACTER_INFO_LABEL', { maxCharNum: TEXT_AREA_MAX_LENGTH })} />}
        name="description"
        rules={[{ whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
        { max: TEXT_AREA_MAX_LENGTH, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') }]}>
        <TextArea disabled={props.disabled} rows={2} />
      </Form.Item>

      <Form.Item label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.EDUCATION_CATEGORY_LABEL')} name="educationCategories">
        <EducationCategoryDataPicker disabled={props.disabled} />
      </Form.Item>

      <Form.Item label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.EDUCATION_SECTOR_LABEL')} name="educationSector" required={true}>
        <EducationSectorDataPicker disabled={props.disabled} />
      </Form.Item>

      <Row gutter={26}>
        <Col xs={24} md={6} >
          <Form.Item label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.DURATION_LABEL')}
            name="duration"
            rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
            <Input disabled={props.disabled} min={MIN_INPUT_NUMBER} type="number" placeholder={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.EDUCATION_DURATION_PLACEHOLDER')} addonAfter={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.EDUCATION_DURATION_SUFFIX')} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={26}>
        <Col xs={24} md={6} >
          <Form.Item label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.MIN_PRICE_LABEL')}
            name="minPrice"
            dependencies={['maxPrice']}
            rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
            { validator: validateNumberMinMaxRange, message: props.translate('COMMON.VALIDATION.MIN_VALUE_EXCEED_MAX_VALUE') },
            ]}>
            <CurrencyInput disabled={props.disabled} placeholder={props.translate('COMMON.LABEL.MINIMUM')} />
          </Form.Item>
        </Col>
        <Col xs={24} md={6} >
          <Form.Item label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.MAX_PRICE_LABEL')}
            name="maxPrice"
            dependencies={['minPrice']}
            rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
            { validator: validateNumberMinMaxRange, message: props.translate('COMMON.VALIDATION.MAX_VALUE_BELOW_MIN_VALUE') }]}>
            <CurrencyInput disabled={props.disabled} placeholder={props.translate('COMMON.LABEL.MAXIMUM')} />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.EDUCATION_OUTCOMES_LABEL')} required={true}>
        <FormDataItemList disabled={props.disabled} formRef={form} initialCount={1} min={1} name={'educationOutcomes'}>
          <EducationOutcomePicker disabled={props.disabled} />
        </FormDataItemList>
      </Form.Item>

      <Form.Item label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.ENROLLMENT_REQUIREMENTS_LABEL')} >
        <FormDataItemList disabled={props.disabled} formRef={form} initialCount={1} name="enrollmentRequirements">
          <EnrollmentRequirementDataPicker disabled={props.disabled} />
        </FormDataItemList>
      </Form.Item>

      <Form.Item
        label={<FormItemLabelIcon label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.CERTIFICATE_NAME_LABEL')}
          tooltipDescription={props.translate('COMMON.LABEL.MAX_LENGTH_CHARACTER_INFO_LABEL', { maxCharNum: INPUT_MAX_LENGTH })} />}
        name={'certificateName'}
        rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
        { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
        { max: INPUT_MAX_LENGTH, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') }]}
      >
        <Input disabled={props.disabled} />
      </Form.Item>

      <Form.Item
        label={<FormItemLabelIcon label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.FORM.CONTRACTOR_QUALIFICATION_LABEL')}
          tooltipDescription={props.translate('COMMON.LABEL.MAX_LENGTH_CHARACTER_INFO_LABEL', { maxCharNum: INPUT_MAX_LENGTH })} />}
        name={'contractorQualification'}
        rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
        { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
        { max: INPUT_MAX_LENGTH, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') }]}
      >
        <Input disabled={props.disabled} />
      </Form.Item>
    </Form >
  );
};

export default withLocalize<IExternalEducationTemplateFormOwnProps>(ExternalEducationTemplateForm as any);
