import { Globals } from '@src/service/util/Globals';


const SCRIPT_CACHE = {
  loaded: [] as string[],
  pending: {} as Record<string, Promise<string>>,
};

/** Internal conter for generating unique callback names. */
let CALLBACK_COUNTER = 0;

/** Inject script tag. */
const scriptTag = (scriptUri: any) => {
  return new Promise((resolve, reject) => {
    const body = Globals.window.document.getElementsByTagName('body')[0];
    const tag = Globals.window.document.createElement('script');
    const callbackName = `scripLoaderCB${CALLBACK_COUNTER++}${Date.now()}`;

    tag.type = 'text/javascript';
    tag.async = false; // Load in order

    const handleLoad = () => {
      resolve(scriptUri);
    };
    const handleReject = () => {
      reject(scriptUri);
    };

    if (scriptUri.match(/callback=CALLBACK_NAME/)) {
      scriptUri = scriptUri.replace(/(callback=)[^&]+/, `$1${callbackName}`);
      Globals.window[callbackName] = handleLoad;
    } else {
      tag.addEventListener('load', handleLoad);
    }
    tag.addEventListener('error', handleReject);

    // script URI
    tag.src = scriptUri;

    // append tag (and start download)
    body.appendChild(tag);

    return tag;
  });
};

/** Check if script as already been loaded and load it if it's not. */
const loadScript = (scriptUri: string): Promise<string> => {
  let scriptPromise;
  if (SCRIPT_CACHE.loaded.indexOf(scriptUri) !== -1) {
    scriptPromise = Promise.resolve(scriptUri);
  } else if (SCRIPT_CACHE.pending[scriptUri] != null) {
    return SCRIPT_CACHE.pending[scriptUri];
  } else {
    scriptPromise = new Promise<string>((resolve, reject) => {
      scriptTag(scriptUri)
        .then(() => {
          delete SCRIPT_CACHE.pending[scriptUri];
          SCRIPT_CACHE.loaded.push(scriptUri);

          resolve(scriptUri);
        })
        .catch((err) => {
          delete SCRIPT_CACHE.pending[scriptUri];

          reject(err);
        });
    });

    SCRIPT_CACHE.pending[scriptUri] = scriptPromise;
  }

  return scriptPromise;
};

/** Returns promis which resolves once all scripts have been loaded. */
const scriptLoader = (scripts: string[]): Promise<string[]> => {
  return Promise.all(
    (scripts || []).map((script) => {
      return loadScript(script);
    })
  );
};

export default scriptLoader;
