import { forkJoin, Observable } from 'rxjs';

import CollectionBusinessStore from '@src/service/business/common/collectionBusinessStore';
import { ICollectionData } from '@src/service/business/common/types';
import StoreService from '@src/service/business/StoreService';
import { createTrackableAction } from '@src/service/util/action/trackAction';

/** Helper methods for easier loading collections during app init. */
export default class CollectionHelperService {

  /** Fetch a collection by it's entity name */
  static fetchCollection = <T>(entity: string): Observable<ICollectionData<T>> => {
    return createTrackableAction<ICollectionData<T>>(StoreService.dispatchAction(CollectionBusinessStore.actions.fetchCollection(entity))).track();
  };

  /** Load multiple collections in parallel. Used on app startup. */
  static loadCollections(modelEntities: string[]): Observable<any> {
    return forkJoin(
      modelEntities.map((entityName) => {
        return CollectionHelperService.fetchCollection(entityName);
      })
    );
  }
}
