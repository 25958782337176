import AppUserAvatar from '@src/components/common/image/AppUserAvatar';
import { IListAdditionalCol } from '@src/components/common/list/ListAdditionalCol';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IUserInfo } from '@src/model/user/User';
import { Col, ColProps, Empty, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router';

interface IUserListOwnProps {
  users: IUserInfo[];
  /**
   * Width of course column. Number is used as a grid column width.
   * All additional columns are summed together with course column width should be equal to 24 (Ant grid col number).
   */
  userColWidth?: ColProps;

  /** Render function which returns list of {@link IUserListAdditionalCol} describing additional groups. */
  renderAdditionalCols?: () => IListAdditionalCol[];

  emptyLabel?: string;
}

class UserList extends React.Component<IUserListOwnProps & IWithLocalizeOwnProps> {
  render = () => {
    const additionalCols = this.props.renderAdditionalCols ? this.props.renderAdditionalCols() : [];
    return (
      <div className="timun-userList__container">
        {this.props.users.length === 0 ? (
          <Empty description={<p>{this.props.emptyLabel ? this.props.emptyLabel : this.props.translate('REPORTS_COURSES.NO_ATTENDEES_MESSAGE')}</p>}/>
        ) : (
          <div className="timun-gridTable__table">
            <Row className="timun-gridTable__headerRow">
              <Col {...this.props.userColWidth} className="timun-gridTable__headerCell">
                <div className="timun-userList__nameHeaderCell">{this.props.translate('USER_LIST.USER_LABEL')}</div>
              </Col>
              {additionalCols.map((col) => {
                return (
                  <Col key={col.key} {...col.responsiveWidth}>
                    {col.headerTitle}
                  </Col>
                );
              })}
            </Row>
            {this.props.users.map((user: IUserInfo) => {
              return (
                <Row key={user.id} data-test-id={`timun-userList__userItem_${user.id}`} className="timun-gridTable__itemRow">
                  <Col {...this.props.userColWidth} className="timun-timun-gridTable__itemCell">
                    <div className="timun-userList__nameItemCell">
                      <AppUserAvatar imagePath={user.profileImageUrl}/>

                      <Link to={`/user/${user.id}`} data-test-id={`timun-userList__userItem_${user.id}`}>
                        {`${user.firstName} ${user.lastName}`}
                      </Link>
                    </div>
                  </Col>
                  {additionalCols.map((col) => {
                    return (
                      <Col key={col.key} {...col.responsiveWidth} className="timun-gridTable__itemCell">
                        {col.content(user)}
                      </Col>
                    );
                  })}
                </Row>
              );
            })}
          </div>
        )}
      </div>
    );
  };
}

export default withLocalize<IUserListOwnProps>(UserList as any);
