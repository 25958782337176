import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IActivity } from '@src/model/activity/Activity';
import { ActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { Tag } from 'antd';


// -- Prop types
// ----------

export interface IExamInstanceStatusOwnProps {
  activity: IActivity;
}
type IExamInstanceStatusProps = IExamInstanceStatusOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Describe your component ... */
const ExamInstanceStatus: React.FC<IExamInstanceStatusProps> = (props: IExamInstanceStatusProps) => {
  let tagColor;
  if (props.activity.currentPhase.id === ActivityPhaseEnum.EVALUATED) {
    tagColor = 'green';
  } else if (props.activity.currentPhase.id === ActivityPhaseEnum.ANSWERS_SUBMITTED) {
    tagColor = 'yellow';
  } else {
    tagColor = 'grey';
  }
  return (
    <Tag color={tagColor}> {props.translate(`EXAM_INSTANCE.LIST_ITEM.STATUS.${props.activity.currentPhase.name}`)} </Tag>
  );
};

export default withLocalize<IExamInstanceStatusOwnProps>(ExamInstanceStatus as any);
