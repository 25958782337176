import React from 'react';

import { Col, Row } from 'antd';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import CourseGroupGridItem from '@src/components/course/group/CourseGroupGridItem';
import { ICourseGroup } from '@src/model/course/CourseGroup';

// -- Prop types
// ----------

export interface ICourseGroupGridOwnProps {
  courseGroups: ICourseGroup[];
  onDeleteCourseGroup: (courseGroup: ICourseGroup) => void;
  onEditCourseGroupTitle: (id: string, title: string) => void;
  onEditCourseGroupCover: (id: string) => void;
}
type ICourseGroupGridProps = ICourseGroupGridOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Show course groups list in grid. */
class CourseGroupGrid extends React.Component<ICourseGroupGridProps> {
  render = () => {
    return (
      <Row gutter={16}>
        {this.props.courseGroups.map((courseGroup) => (
          <Col key={courseGroup.id} xs={24} sm={12} md={8} lg={6}>
            <CourseGroupGridItem courseGroup={courseGroup} linkTo="/courses" showEditButtons={true} onDeleteCourseGroup={this.props.onDeleteCourseGroup} onEditCourseGroupTitle={this.props.onEditCourseGroupTitle} onEditCourseGroupCover={this.props.onEditCourseGroupCover} />
            <br />
          </Col>
        ))}
      </Row>
    );
  };
}

export default withLocalize<ICourseGroupGridOwnProps>(CourseGroupGrid as any);
