import React from 'react';

import GridItem from '@src/components/common/grid/GridItem';
import LemonIcon from '@src/components/common/image/LemonIcon';
import StatusBadge from '@src/components/common/status/StatusBadge';
import TimunShortInfoTable from '@src/components/common/table/TimunShortInfoTable';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import { IShortInfo } from '@src/model/common/IShortInfo';
import { ICollectionData } from '@src/service/business/common/types';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import { Button, Col, Row, Typography } from 'antd';
import Column from 'antd/lib/table/Column';

// -- Const
// ----------
const path = '/administration/codebooks';

// -- Prop types
// ----------

interface ICodeBookItem extends IShortInfo {
  ordering?: number;
  active?: boolean;
}
interface ICodeBookListViewStateProps {
}

interface ICodeBookListViewOwnProps<T> {
  title: string;
  descriptionPrefix?: string;
  buttonLabel?: string;
  codebookItemList: ICollectionData<T>;
  showOrderingColumn?: boolean;
  showBackColumn?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;

  onEditCodebookItemClick?: (codebookItem: T) => void;
  onCreateCodebookItemClick: () => void;
  onCodebookItemDelete?: (codebookItem: T) => void;
  onPageChange: (page: number, size?: number) => void;
}

type ICodeBookListViewProps<T> = ICodeBookListViewOwnProps<T>;

// -- Component
// ----------

/** Display codebook list of items */
class CodeBookListView<T extends ICodeBookItem> extends React.Component<ICodeBookListViewProps<T>, ICodeBookListViewStateProps> {
  render() {

    const codeBookActionColumns = {
      edit: { showButton: true, onClick: this.props.onEditCodebookItemClick },
    };

    const additionalColumns = () => <Column key="ordering" width="5%" align="center" render={(record: T) => record.ordering} />;
    const statusColumns = () => <Column key="ordering" width="5%" align="center" render={(record: T) => <StatusBadge status={record.active} />} />;

    return (
      <React.Fragment>
        <Row justify="space-between">
          <Col>
            <HeaderTitle title={LocalizeService.translate('CODEBOOK.CODEBOOK_TITLE')} link={path} icon={<LemonIcon name="left" />} />
          </Col>
          <Col>
            <Button icon={<LemonIcon name="plus" />} type="primary" onClick={this.props.onCreateCodebookItemClick}>{this.props.buttonLabel} </Button>
          </Col>
        </Row>
        <Typography.Title>{this.props.title}</Typography.Title>
        <TimunShortInfoTable<T>
          items={this.props.codebookItemList.content}
          paginationData={this.props.codebookItemList.page}
          renderFrontAdditionalColumns={this.props.showOrderingColumn ? additionalColumns : undefined}
          renderBackAdditionalColumns={this.props.showBackColumn ? statusColumns : undefined}
          rowDescription={this.renderGradeRowDescription}
          paginationConfig={{ onChange: this.props.onPageChange }}
          actionButtons={codeBookActionColumns}
        />
      </React.Fragment>
    );
  }

  renderGradeRowDescription = (record: T) => {
    if (record.description) {
      return (
        <GridItem key={record.id} contentType="secondary" layout="horizontal" label={this.props.descriptionPrefix && LocalizeService.translate(this.props.descriptionPrefix)}>
          {record.description}
        </GridItem>
      );
    }
    return;
  };

}

// -- HOCs and exports
// ----------

export default CodeBookListView;
