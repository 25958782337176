import { Form } from '@ant-design/compatible';

import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { DatePicker, Modal, Transfer } from 'antd';
// tslint:disable-next-line:no-submodule-imports
import { TransferItem } from 'antd/lib/transfer';
import React, { SyntheticEvent } from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import IIdRef from '@src/model/common/IdRef';
import { ICourse } from '@src/model/course/Course';
import { IUserInfo } from '@src/model/user/User';
import { IUpdateUsersCoursesPayload } from '@src/service/business/courses/UsersCoursesUpdateBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { Moment } from 'moment';
import moment from 'moment';

const FormItem = Form.Item;

const DATE_FORMAT: string = AppConfigService.getValue('dateTimeFormat.dateWithYear');
const ISO_DATE_FORMAT: string = AppConfigService.getValue('dateTimeFormat.backendQueryParamDateFormat');

// -- Prop types
// ----------

export interface ICourseListEditFormOwnProps {
  user: IUserInfo;
  allCourseList: ICourse[];
  userCourseList: ICourse[];
  onCancel: () => void;
  onSubmit: (data: IUpdateUsersCoursesPayload) => void;
}
type ICourseListEditFormProps = ICourseListEditFormOwnProps & FormComponentProps & IWithLocalizeOwnProps;

interface ICourseListEditFormState {
  transferDataSource: TransferItem[];
  transferTargetKeys: string[];
  userCoursesKeys: string;
  isDeadlineDateRequired: boolean;
}

// -- Component
// ----------

/** Show list of courses grouped by course groups. Additional (custom) columns can be added per each course using render function. */
class NormalCourseListEditForm extends React.Component<ICourseListEditFormProps, ICourseListEditFormState> {
  state: ICourseListEditFormState = {
    transferDataSource: [],
    transferTargetKeys: [],
    userCoursesKeys: '',
    isDeadlineDateRequired: false,
  };

  componentDidMount() {
    this.prepareTransferData();
  }

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        maskClosable={false}
        className="lemon-modal__modal--md timun-courseListEditForm__container"
        visible={true}
        title={this.props.translate(`COURSE_LIST_EDIT_FORM.${this.props.userCourseList.length ? 'EDIT' : 'ADD'}_TITLE_LABEL`)}
        okText={this.props.translate(this.props.userCourseList.length ? 'COMMON.ACTION_SAVE' : 'COMMON.ACTION_ADD')}
        onOk={this.handleSubmit}
        onCancel={this.props.onCancel}
        okButtonProps={{ disabled: !this.hasCourseListChanged() }}
      >
        <Form>
          <FormItem label={this.props.translate('COURSE_LIST.VIEW_TITLE')}>
            {getFieldDecorator('courseKeys')(
              <Transfer
                titles={[this.props.translate('COURSE_LIST_EDIT_FORM.UNASSIGNED_COURSES_LABEL'), this.props.translate('COURSE_LIST_EDIT_FORM.ASSIGNED_COURSES_LABEL')]}
                dataSource={this.state.transferDataSource}
                targetKeys={this.state.transferTargetKeys}
                render={(item) => item.title || null}
                onChange={this.handleChange}
                data-test-id="timun-courseListEditForm__courseKeys"
              />
            )}
          </FormItem>
          <FormItem label={this.props.translate('COURSE_LIST_EDIT_FORM.DEADLINE_DATE_LABEL')}>
            {getFieldDecorator('deadlineDate', {
              rules: [{ required: this.state.isDeadlineDateRequired, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') }],
            })(<DatePicker allowClear={false} format={DATE_FORMAT} disabledDate={this.disabledDate} disabled={!this.state.isDeadlineDateRequired} placeholder={this.props.translate('COURSE_LIST_EDIT_FORM.DEADLINE_DATE_PLACEHOLDER')}
              data-test-id="timun-courseListEditForm__deadlineDate" />)}
          </FormItem>
        </Form>
      </Modal>
    );
  }

  // past Days are disabled
  private disabledDate = (current: Moment): boolean => {
    return current != null ? current < moment().startOf('day') : false;
  };

  private prepareTransferData = () => {
    const transferDataSource: TransferItem[] = this.props.allCourseList.map((course: ICourse) => ({ key: course.id, title: course.title }));
    const transferTargetKeys: string[] = this.props.userCourseList.map((course: ICourse) => course.id);
    // tslint:disable-next-line: no-misleading-array-reverse
    this.setState({ transferDataSource, transferTargetKeys, userCoursesKeys: transferTargetKeys.sort().toString() });
  };

  private hasCourseListChanged = (): boolean => {
    return this.state.userCoursesKeys !== this.state.transferTargetKeys.sort().toString();
  };

  private hasNewCourses = (selectedKeys: string[]): boolean => {
    return selectedKeys.filter((item: string) => !this.state.userCoursesKeys.split(',').includes(item)).length > 0;
  };

  private handleChange = (nextTargetKeys: string[], direction: string, moveKeys: string[]) => {
    this.setState({ transferTargetKeys: nextTargetKeys });
    this.setState({ isDeadlineDateRequired: this.hasNewCourses(nextTargetKeys) });
  };

  private handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err: any, values: any) => {
      if (!err) {
        const userCourses: string[] = this.state.userCoursesKeys ? this.state.userCoursesKeys.split(',') : [];
        const coursesToAdd: Array<IIdRef<string>> = values.courseKeys.filter((item: string) => !userCourses.includes(item)).map((item: string) => ({ id: item }));
        const coursesToRemove: Array<IIdRef<string>> = userCourses.filter((item: string) => !values.courseKeys.includes(item)).map((item: string) => ({ id: item }));
        this.props.onSubmit({
          dataToAdd: {
            users: [{ id: this.props.user.id }],
            courses: coursesToAdd,
            deadlineDate: values.deadlineDate ? values.deadlineDate.format(ISO_DATE_FORMAT) : null,
          },
          dataToRemove: {
            users: [{ id: this.props.user.id }],
            courses: coursesToRemove,
          },
        });
      }
    });
  };
}

const CourseListEditForm = Form.create()(NormalCourseListEditForm);

export default withLocalize<ICourseListEditFormOwnProps>(CourseListEditForm as any);
