import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { EducationApplicationActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { Select } from 'antd';
import React from 'react';

// -- Const
// ----------
const { Option } = Select;

// -- Prop types
// ----------
export interface IEducationApplicationStatusSelectorOwnProps {
  value?: EducationApplicationActivityPhaseEnum;
  onChange?: (newValue?: EducationApplicationActivityPhaseEnum) => void;
}

type IEducationApplicationStatusSelectorProps = IEducationApplicationStatusSelectorOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

const EducationApplicationStatusSelector: React.FC<IEducationApplicationStatusSelectorProps> = (props) => {

  const options = Object.keys(EducationApplicationActivityPhaseEnum)
    .map((item: string) => (
      <Option
        value={EducationApplicationActivityPhaseEnum[item as keyof typeof EducationApplicationActivityPhaseEnum]}
        key={EducationApplicationActivityPhaseEnum[item as keyof typeof EducationApplicationActivityPhaseEnum]}
        data-test-id={`timun-externalEducationApplicationFilter__statusSelect_option_${item}`}
      >
        {props.translate(`EXTERNAL_EDUCATION_APPLICATION.PHASE.${item}_LABEL`)}
      </Option>
    ));

  return (
    <Select onChange={props.onChange} placeholder={props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.PICK_STATUS_PLACEHOLDER')} allowClear={true} defaultValue={props.value}
      data-test-id="timun-externalEducationApplicationFilter__statusSelect"
    >
      {options}
    </Select>
  );
};

export default withLocalize<IEducationApplicationStatusSelectorOwnProps>(EducationApplicationStatusSelector as any);
