import { IOrganizationTreeProps } from '@src/components/common/orgTree/OrganizationTree';
import React from 'react';

export interface IOrganizationTreeNodeProps extends IOrganizationTreeProps {
  content: React.ReactNode;
}


const OrganizationTreeNode: React.FC<IOrganizationTreeNodeProps> = (props: IOrganizationTreeNodeProps) => {
  return (
    <li className="timun-orgTree__node timun-orgTree__nodeLines">
      <div className="timun-orgTree__nodeContent">{props.content}</div>
      {React.Children.count(props.children) > 0 && (
        <ul className="timun-orgTree__childrenContainer">{props.children}</ul>
      )}
    </li>
  );
};

export default OrganizationTreeNode;
