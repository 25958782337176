import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

import { IQuestion } from '@lamarodigital/quizzler-lib-frontend/model/quiz/question/Question';
import IIdRef from '@src/model/common/IdRef';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { actionThunk, startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts

// -
// -------------------- Selectors

/** Returns question from store. */
const getQuestion = (store: any): IQuestion => store.question;

// -
// -------------------- Actions

const Actions = {
  QUESTION_FETCH: 'QUESTION_FETCH',
  QUESTION_LOAD: 'QUESTION_LOAD',
  QUESTION_CREATE: 'QUESTION_CREATE',
  QUESTION_UPDATE: 'QUESTION_UPDATE',
  QUESTION_CLEAR: 'QUESTION_CLEAR',
};

/** Fetch question list by filter. Forces default QUESTION_PAYLOAD_BASE. */
const fetchQuestion = (data: IIdRef<string>): IPayloadAction<IIdRef<string>> => {
  return {
    type: Actions.QUESTION_FETCH,
    payload: data,
  };
};

/** Load question list to store. */
const loadQuestion = (data: IQuestion): IPayloadAction<IQuestion> => {
  return {
    type: Actions.QUESTION_LOAD,
    payload: data,
  };
};

/** Create new question */
const createQuestion = (data: IQuestion): IPayloadAction<IQuestion> => {
  return {
    type: Actions.QUESTION_CREATE,
    payload: data,
  };
};

/** Update question by ID */
const updateQuestion = (data: IQuestion): IPayloadAction<IQuestion> => {
  return {
    type: Actions.QUESTION_UPDATE,
    payload: data,
  };
};

const clearQuestionData = (): ILemonAction => {
  return {
    type: Actions.QUESTION_CLEAR,
  };
};

// -
// -------------------- Side-effects

const fetchQuestionEffect = (action$: Observable<IPayloadAction<IIdRef<string>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.QUESTION_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Question')
        .fetchEntity(payload.id)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadQuestion(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching question list', error);
      return o;
    })
  );
};

const createQuestionEffect = (action$: Observable<IPayloadAction<IQuestion>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.QUESTION_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Question')
        .createEntity(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadQuestion(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating question', error);
      return o;
    })
  );
};

const updateQuestionEffect = (action$: Observable<IPayloadAction<IQuestion>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.QUESTION_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('Question')
        .updateEntity(data.id, data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadQuestion(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating question', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const question = (state: IQuestion | null = null, action: IPayloadAction<IQuestion>) => {
  if (action.type === Actions.QUESTION_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.QUESTION_CLEAR) {
    return null;
  }
  return state;
};

// --
// -------------------- Business Store

export const QuestionBusinessStore = {
  actions: {
    fetchQuestion,
    loadQuestion,
    createQuestion,
    updateQuestion,
    clearQuestionData,
  },
  selectors: {
    getQuestion,
  },
  effects: {
    fetchQuestionEffect,
    createQuestionEffect,
    updateQuestionEffect,
  },
  reducers: {
    question,
  },
};

// --
// export business store
export default QuestionBusinessStore;
