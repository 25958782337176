import CourseExamContainer from '@src/components/course/exam/CourseExamContainer';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------
type ICourseExamPageProps = WithRouterProps;

const CourseExamPage = (props: ICourseExamPageProps) => <CourseExamContainer courseId={props.params.courseId} examInstanceId={props.params.examId} />;

export default withRouter<ICourseExamPageProps>(CourseExamPage);
