import { Icon as LegacyIcon } from '@ant-design/compatible';
import withCollectionState, { IWithCollectionStateOwnProps, IWithCollectionStatePublicProps } from '@src/components/common/collectionParams/withCollectionState';
import AppContent from '@src/components/common/container/AppContent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ImageUploadForm from '@src/components/common/upload/ImageUploadForm';
import UserGroupListView from '@src/components/usergroup/list/UserGroupListView';
import UserGroupCreateContainer from '@src/components/usergroup/update/UserGroupCreateContainer';
import UserGroupUpdateContainer from '@src/components/usergroup/update/UserGroupUpdateContainer';
import { IFile } from '@src/model/file/File';
import { IUserGroup } from '@src/model/usergroup/UserGroup';
import { IUserGroupHierarchy } from '@src/model/usergroup/UserGroupHierarchy';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import UserGroupBusinessStore from '@src/service/business/usergroup/UserGroupBusinessStore';
import UserGroupListBusinessStore, { IUserGroupListFilter } from '@src/service/business/usergroup/UserGroupListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { Button, Col, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

// -- Const
// ----------
const ADMIN_USER_GROUP_ROUTE = AppConfigService.getValue('components.userGroups.adminUserGroupRouterProp');

const collectionDefaults: IWithCollectionStatePublicProps<IUserGroupListFilter> = {
  viewName: undefined,
  initialValues: undefined,
};

// -- Prop types
// ----------
export interface IAdminUserGroupListOwnProps {
}

export interface IAdminUserGroupListStateProps {
  userGroupList: ICollectionData<IUserGroup>;
  userGroupHierarchy: IUserGroupHierarchy;
}

export interface IAdminUserGroupListDispatchProps {
  fetchUserGroupList: (params: ICollectionFetchPayload<IUserGroupListFilter>) => ITrackableAction;
  fetchUserGroupHierarchy: () => void;
  uploadUserGroupCoverImage: (id: string, data: IFile) => ITrackableAction;
  deleteUserGroup: (userGroupId: string) => ITrackableAction;
}

type IAdminUserGroupListProps = IAdminUserGroupListOwnProps & IAdminUserGroupListStateProps & IAdminUserGroupListDispatchProps & IWithLocalizeOwnProps & IWithCollectionStateOwnProps<IUserGroupListFilter>;

interface IAdminUserGroupListState {
  isEditModalVisible: boolean;
  isCoverModalVisible: boolean;
  isCreateModalVisible: boolean;
  selectedUserGroup?: IUserGroup;

}

// -- Component
// ----------

class AdminUserGroupList extends React.Component<IAdminUserGroupListProps, IAdminUserGroupListState> {
  state: IAdminUserGroupListState = {
    isEditModalVisible: false,
    isCoverModalVisible: false,
    isCreateModalVisible: false,
    selectedUserGroup: undefined,
  };


  componentDidMount() {
    this.updateList();
    this.props.fetchUserGroupHierarchy();
  }

  componentDidUpdate(prevProps: IAdminUserGroupListProps, prevState: IAdminUserGroupListState) {
    if (prevProps.collectionParams !== this.props.collectionParams) {
      this.updateList();
    }
  }

  componentWillUnmount() {
    //
  }

  render() {
    return (
      <AppContent title={<Row justify="end">
        <Col>
          <Button icon={<LegacyIcon type={'plus'} />} type="primary" onClick={() => this.toggleCreateModal(true)}>{this.props.translate('USER_GROUP.NEW_USER_GROUP_TITLE')}</Button>
        </Col>
      </Row>}>

        {/* ----- User group list ----- */}
        {this.props.userGroupList &&
          <UserGroupListView
            basePath={ADMIN_USER_GROUP_ROUTE}
            showButtons={true}
            userGroupList={this.props.userGroupList}
            userGroupListFilter={this.props.collectionParams.filter}
            userGroupHierarchy={this.props.userGroupHierarchy}
            onUserGroupHierachyUpdate={this.handleDataUpdate}
            onPageChange={this.props.updateCollectionParams.onPageChange}
            onUserGroupEdit={this.handleUserGroupEdit}
            onUserGroupCoverEdit={this.handleEditUserGroupCover}
            onUserGroupDelete={this.handleUserGroupDelete}
          />
        }

        {/* ----- User group update modals ----- */}
        {this.state.isCreateModalVisible && <UserGroupCreateContainer onSubmit={this.handleUserGroupCreateSubmit} onCancel={this.handleCreateModalCancel} />}

        {this.state.isEditModalVisible && this.state.selectedUserGroup && <UserGroupUpdateContainer userGroup={this.state.selectedUserGroup} onSubmit={this.handleUserGroupUpdateSubmit} onCancel={this.handleUpdateModalCancel} />}

        {this.state.isCoverModalVisible && <ImageUploadForm onSubmit={this.handleUserGroupCoverSubmit} onClose={this.handleCoverModalClose} />}
      </AppContent>
    );
  }

  handleCreateModalCancel = () => {
    this.toggleCreateModal(false);
  };

  handleUpdateModalCancel = () => {
    this.toggleEditModal(false);
  };

  handleCoverModalClose = () => {
    this.toggleCoverModal(false);
  };

  handleUserGroupCreateSubmit = () => {
    this.handleCreateModalCancel();
    this.handleDataUpdate();
  };

  handleUserGroupUpdateSubmit = () => {
    this.handleUpdateModalCancel();
    this.handleDataUpdate();
  };

  toggleCreateModal = (isVisible: boolean) => {
    this.setState({ isCreateModalVisible: isVisible });
  };

  toggleEditModal = (isVisible: boolean) => {
    this.setState({ isEditModalVisible: isVisible });
  };

  toggleCoverModal = (isVisible: boolean) => {
    this.setState({ isCoverModalVisible: isVisible });
  };

  handleUserGroupEdit = (userGroup: IUserGroup) => {
    this.setState({ selectedUserGroup: userGroup });
    this.toggleEditModal(true);
  };

  handleUserGroupDelete = (userGroupId: string) => {
    this.props.deleteUserGroup(userGroupId).track().subscribe(
      () => this.handleDataUpdate()
    );
  };

  handleEditUserGroupCover = (userGroup: IUserGroup) => {
    this.setState({ selectedUserGroup: userGroup }, () => {
      this.toggleCoverModal(true);
    });
  };

  handleUserGroupCoverSubmit = (data: IFile) => {
    if (this.state.selectedUserGroup) {
      this.props
        .uploadUserGroupCoverImage(this.state.selectedUserGroup.id, data)
        .track()
        .subscribe(() => this.handleDataUpdate());
    }
  };

  handleDataUpdate = () => {
    this.updateList();
    this.props.fetchUserGroupHierarchy();
  };

  updateList = () => {
    this.props.onUpdateList(this.props.fetchUserGroupList);
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IAdminUserGroupListStateProps => ({
  userGroupList: UserGroupListBusinessStore.selectors.getUserGroupList(state),
  userGroupHierarchy: UserGroupListBusinessStore.selectors.getUserGroupHierarchy(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IAdminUserGroupListDispatchProps => ({
  fetchUserGroupList: (params: ICollectionFetchPayload<IUserGroupListFilter>) => createTrackableAction(dispatch(UserGroupListBusinessStore.actions.fetchUserGroupList(params))),
  fetchUserGroupHierarchy: () => dispatch(UserGroupListBusinessStore.actions.fetchUserGroupHierarchy()),
  uploadUserGroupCoverImage: (id: string, data: IFile) => createTrackableAction(dispatch(UserGroupBusinessStore.actions.uploadUserGroupCoverImage(id, data))),
  deleteUserGroup: (userGroupId: string) => createTrackableAction(dispatch(UserGroupBusinessStore.actions.deleteUserGroup(userGroupId))),
});

export default connect(mapStateToProps, mapDispatchToProps)(withCollectionState(collectionDefaults)(withLocalize<IAdminUserGroupListOwnProps>(AdminUserGroupList as any)));
