import { Form } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';

import DeleteButton from '@src/components/common/button/DeleteButton';
import EditableWrapper from '@src/components/common/container/EditableWrapper';
import LemonIcon from '@src/components/common/image/LemonIcon';
import LemonImage from '@src/components/common/image/LemonImage';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import ImageUploadForm from '@src/components/common/upload/ImageUploadForm';
import { IExternalContent } from '@src/model/externalcontent/ExternalContent';
import { IFile } from '@src/model/file/File';
import { IExternalContentCreatePayload } from '@src/service/business/externalcontent/ExternalContentViewBusinessStore';
import { Button, Col, Input, Row } from 'antd';
import React, { SyntheticEvent } from 'react';

const TextArea = Input.TextArea;

const httpsPrefix = 'https://';

// -- Prop types
// ----------

interface IExternalContentDataFormOwnProps {
  externalContent?: IExternalContent;
  coverImageId?: string;
  onSubmit?: (data: IExternalContentCreatePayload) => void;
  onCancel?: (editing: boolean) => void;
  onDelete?: () => void;
  onCoverSubmit: (data: IFile) => void;
}

type IExternalContentDataFormProps = IExternalContentDataFormOwnProps & IWithLocalizeOwnProps & FormComponentProps & IWithRolesOwnProps & IWithPathResolverOwnProps;

interface IExternalContentDataFormState {
  loading: boolean;
  isEditingCover: boolean;
}

// -- Component
// ----------

class ExternalContentDataForm extends React.Component<IExternalContentDataFormProps, IExternalContentDataFormState> {
  state = {
    loading: false,
    isEditingCover: false,
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form layout="vertical" onSubmit={this.handleSubmit}>
        <Form.Item label={this.props.translate('EXTERNAL_CONTENT.FORM.TITLE_LABEL')}>
          {getFieldDecorator('title', {
            initialValue: this.props.externalContent?.title,
            rules: [
              { whitespace: true, message: this.props.translate('EXTERNAL_CONTENT.FORM.TITLE_WHITESPACE_MESSAGE') },
              { required: true, message: this.props.translate('EXTERNAL_CONTENT.FORM.TITLE_REQUIRED_MESSAGE') },
            ],
          })(<Input/>)}
        </Form.Item>
        <Form.Item label={this.props.translate('EXTERNAL_CONTENT.FORM.URL_LABEL')}>
          {getFieldDecorator('url', {
            initialValue: this.parseUrl(this.props.externalContent?.url),
            rules: [
              { required: true, message: this.props.translate('EXTERNAL_CONTENT.FORM.URL_REQUIRED_MESSAGE') },
            ],
          })(<Input addonBefore={httpsPrefix}/>)}
        </Form.Item>
        <Form.Item label={this.props.translate('EXTERNAL_CONTENT.FORM.DESCRIPTION_LABEL')}>
          {getFieldDecorator('description', {
            initialValue: this.props.externalContent?.description,
            rules: [{ whitespace: true, message: this.props.translate('EXTERNAL_CONTENT.FORM.DESCRIPTION_WHITESPACE_MESSAGE') }],
          })(<TextArea/>)}
        </Form.Item>
        <Form.Item label={this.props.translate('EXTERNAL_CONTENT.FORM.COVER_LABEL')}>
          {getFieldDecorator('coverImageUrl', {
            initialValue: this.props.externalContent?.coverImageUrl,
          })(
            <React.Fragment>
              {this.props.externalContent?.coverImageUrl || this.props.coverImageId ? (
                <EditableWrapper className="timun-externalContentForm__imageWrapper" onEdit={this.toggleCoverEdit} showMask={false} showRemove={false}>
                  <LemonImage imagePath={this.props.externalContent?.coverImageUrl || (this.props.coverImageId && this.props.resolveApiFilePath(this.props.coverImageId)) || 'cover-placeholder.png'} className="timun-externalContentForm__coverImage"/>
                </EditableWrapper>
              ) : (
                <Button icon={<LemonIcon name="paperClip"/>} onClick={this.toggleCoverEdit} type="primary">
                  {this.props.translate('FILE_UPLOAD.MODAL_UPLOAD_BUTTON')}
                </Button>
              )}
              {this.state.isEditingCover && <ImageUploadForm avatar={true} defaultImageLink={this.props.externalContent?.coverImageUrl} onSubmit={this.props.onCoverSubmit} onClose={this.toggleCoverEdit}/>}
            </React.Fragment>,
          )}
        </Form.Item>
        <Form.Item>
          <Row justify="end" gutter={12}>
            {this.props.onDelete && (
              <React.Fragment>
                <Col>
                  <DeleteButton buttonText={this.props.translate('COMMON.ACTION_DELETE')} onConfirm={this.props.onDelete}/>
                </Col>
                &nbsp;&nbsp;
              </React.Fragment>
            )}
            <Col>
              <Button icon={<LemonIcon name="times"/>} onClick={this.handleCancel}>
                {this.props.translate('COMMON.ACTION_CANCEL')}
              </Button>
            </Col>
            <Col>
              <Button icon={<LemonIcon name="check"/>} type="primary" htmlType="submit">
                {this.props.translate('COMMON.ACTION_SAVE')}
              </Button>
              &nbsp;
            </Col>
          </Row>
        </Form.Item>
      </Form>
    );
  }

  handleCancel = () => {
    this.props.form.resetFields();
    if (this.props.onCancel) {
      this.props.onCancel(false);
    }
  };

  toggleCoverEdit = () => {
    this.setState({
      isEditingCover: !this.state.isEditingCover,
    });
  };

  formatUrl = (url: string) => { // add https to input url
    return httpsPrefix + url;
  };

  parseUrl = (url?: string) => { // remove https from url
    return url?.replace(httpsPrefix, '');
  };

  private handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        const url = this.formatUrl(values.url);
        const data: IExternalContentCreatePayload = {
          title: values.title,
          url,
          description: values.description,
          coverImageUrl: values.coverImageUrl,
        };

        if (this.props.onSubmit) {
          this.props.onSubmit(data);
        }
      }
    });
  };
}

// -- HOCs and exports
// ----------

const ExternalContentForm = Form.create()(ExternalContentDataForm);

export default withLocalize<IExternalContentDataFormOwnProps>(withRoles(withPathResolver(ExternalContentForm as any)));
