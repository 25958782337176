import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';
import EditableWrapper from '@src/components/common/container/EditableWrapper';
import LemonIcon from '@src/components/common/image/LemonIcon';
import IntegrationVideoImport from '@src/components/common/integrationvideo/IntegrationVideoImport';
import IntegrationVideoPlayer from '@src/components/common/integrationvideo/IntegrationVideoPlayer';
import IntegrationVideoUploadForm from '@src/components/common/integrationvideo/IntegrationVideoUploadForm';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IIntegrationVideo } from '@src/model/integrationvideo/IntegrationVideo';
import { LangUtils } from '@src/service/util/LangUtils';
import { Button, Collapse, Modal } from 'antd';
import React, { useState } from 'react';

// -- Consts
// ----------

enum VideoIntegrationMethod {
  VIDEO_ID,
  VIDEO_UPLOAD,
}

// -- Prop types
// ----------

export interface IIntegrationVideoFormOwnProps {
  value?: IIntegrationVideo;
  disabled?: boolean;

  onChange?: (file?: IIntegrationVideo) => void;
}

type IIntegrationVideoFormProps = IIntegrationVideoFormOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/**
 * Integration video form component.
 *
 * Integrates two methods of creating integration video:
 * - import video from video ID
 * - upload video file
 */
const IntegrationVideoForm: React.FC<IIntegrationVideoFormProps> = (props) => {
  const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false);
  const [isUploadInProgress, setIsUploadInProgress] = useState<boolean>(false);
  const [newVideo, setNewVideo] = useState<IIntegrationVideo | undefined>();
  const [openedPanel, setActivePanel] = useState<VideoIntegrationMethod>(VideoIntegrationMethod.VIDEO_UPLOAD);

  // -------------------- start callbacks ----- //

  const handleVideoChange = (value?: IIntegrationVideo) => {
    setNewVideo(value);
  };

  const handleUploadProgress = (inProgress: boolean) => {
    setIsUploadInProgress(inProgress);
  };

  // ----- CRUD callbacks

  /** Open dialog to add new video */
  const handleVideoAdd = () => {
    setIsDialogOpened(true);
  };

  /** Remove existing video */
  const handleVideoRemove = () => {
    props.onChange?.();
  };

  /** Open existing video for edit/change */
  const handleVideoEdit = () => {
    setNewVideo(props.value);

    setIsDialogOpened(true);
  };

  // ----- dialog callbacks

  /** Submit&close dialog and notify parent components of value change */
  const handleDialogSubmit = () => {
    if (newVideo != null) {
      props.onChange?.(newVideo);
    }

    handleDialogClose();
  };

  const handleDialogCancel = () => {
    handleDialogClose();
  };

  /**
   * Close dialog but ask for confirmation if video upload is in progress.
   * Video files can be large and thus their uploads can last longer. We'd like to prevent users form accidentally brekaing long upload by closing dialog.
   */
  const handleDialogClose = () => {
    // confirm closing dialog if upload is in progress
    if (isUploadInProgress) {
      confirmationDialog({
        title: props.translate('INTEGRATION_VIDEO.MESSAGE.UPLOAD_IN_PROGRESS'),
        onConfirm: () => {
          closeDialog();
        },
      });
    } else {
      closeDialog();
    }
  };

  /** Close dialog and do cleanup. No questions asked. */
  const closeDialog = () => {
    // clear state when modal is closing, so it doesn't remember uploaded file upon next modal open
    setNewVideo(undefined);
    setIsUploadInProgress(false);

    setIsDialogOpened(false);
  };

  // -----

  const handlePanelChange = (activePanels: string | string[]) => {
    const activePanelId = (LangUtils.isArray(activePanels) ? activePanels : [activePanels]).shift() as VideoIntegrationMethod | undefined; // force string to enum conversion
    const panelId = activePanelId ?? VideoIntegrationMethod.VIDEO_UPLOAD;
    setActivePanel(panelId);
  };

  // ---------- end callbacks ----- //

  const videoPreview = props.value != null ? <IntegrationVideoPlayer videoId={props.value.integrationId} integrationType={props.value.integrationType.id}/> : null;

  return (
    <React.Fragment>
      {/* ---------- existing video preview ---------- */}
      {videoPreview != null && (
        <EditableWrapper showMask={false} position={{ h: 'right', v: 'top' }} className="full-width" disabled={props.disabled} onEdit={handleVideoEdit} onRemove={() => handleVideoRemove()}>
          {videoPreview ?? <LemonIcon name="video" size="xxlarge"/>}
          &nbsp;{/* NOTE: editable wrapper has height 0 thus video is not visible; also we have to set editable wrapper to full width */}
        </EditableWrapper>
      )}

      {/* ---------- no video ---------- */}
      {videoPreview == null && (
        <Button onClick={handleVideoAdd}>
          {props.translate('INTEGRATION_VIDEO.FORM.ADD_VIDEO')} <LemonIcon name="plus"/>
        </Button>
      )}

      {/* ---------- video upload dialog ---------- */}
      {isDialogOpened && (
        // NOTE: it is important to "destroyOnClose" dialog to cancel ongoing uploads in IntegrationVideoUpload components because dialog doesn't unmount them by default
        <Modal
          maskClosable={false}
          title={props.translate('INTEGRATION_VIDEO.FORM.MODAL_TITLE')}
          className="lemon-modal__modal lemon-modal__modal--md"
          visible={true}
          destroyOnClose={true}
          onOk={handleDialogSubmit}
          onCancel={handleDialogCancel}
          okText={props.translate('COMMON.ACTION_SAVE')}
          cancelText={props.translate('COMMON.ACTION_CANCEL')}
          okButtonProps={{ disabled: newVideo === props.value || newVideo == null || isUploadInProgress }}
        >
          <Collapse ghost={true} accordion={true} activeKey={openedPanel} onChange={handlePanelChange}>
            <Collapse.Panel key={VideoIntegrationMethod.VIDEO_ID} header={props.translate('INTEGRATION_VIDEO.FORM.IMPORT_VIDEO_TITLE')}>
              <IntegrationVideoImport value={newVideo} disabled={props.disabled} onChange={handleVideoChange}/>
            </Collapse.Panel>
            <Collapse.Panel key={VideoIntegrationMethod.VIDEO_UPLOAD} header={props.translate('INTEGRATION_VIDEO.FORM.UPLOAD_VIDEO_TITLE')}>
              <IntegrationVideoUploadForm value={newVideo} disabled={props.disabled} onChange={handleVideoChange} onProgress={handleUploadProgress}/>
            </Collapse.Panel>
          </Collapse>
        </Modal>
      )}
    </React.Fragment>
  );
};

export default withLocalize<IIntegrationVideoFormOwnProps>(IntegrationVideoForm as any);
