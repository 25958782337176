import React from 'react';
import { connect } from 'react-redux';

import LocationCodebookForm from '@src/components/codebook/form/LocationCodebookForm';
import LocationCodebookListView from '@src/components/codebook/view/LocationCodebookListView';
import withCollectionState, { IWithCollectionStateOwnProps, IWithCollectionStatePublicProps } from '@src/components/common/collectionParams/withCollectionState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ILocation } from '@src/model/location/Location';
import { ICollectionData, ICollectionFetchPayload, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import LocationBusinessStore, { ILocationCreatePayload, ILocationListFilter } from '@src/service/business/location/LocationBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

// -- Const
// ----------
const DEFAULT_PAGE_SIZE_VALUE = AppConfigService.getValue('api.collectionDefaultLimit');
const VIEW_NAME = '@@LOCATION_CODEBOOK_LIST';
const collectionDefaults: IWithCollectionStatePublicProps<ILocationListFilter> = {
  viewName: VIEW_NAME,
  initialValues: { size: DEFAULT_PAGE_SIZE_VALUE },
};

// -- Prop types
// ----------
export interface ILocationCodebookContainerOwnProps {
}

export interface ILocationCodebookContainerStateProps {
  locationCodebookList: ICollectionData<ILocation>;
}

export interface ILocationCodebookContainerDispatchProps {
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
  fetchLocationCodebookList: (params: ICollectionFetchPayload<ILocationListFilter>) => ITrackableAction;
  createLocationCodebook: (data: ILocationCreatePayload) => ITrackableAction;
  updateLocationCodebook: (data: ILocation) => ITrackableAction;
}

type ILocationCodebookContainerProps = ILocationCodebookContainerOwnProps & ILocationCodebookContainerStateProps
  & ILocationCodebookContainerDispatchProps & IWithLocalizeOwnProps & IWithCollectionStateOwnProps<ILocationListFilter>;

interface ILocationCodebookContainerState {
  isLocationCodebookModalVisible: boolean;
  locationCodeBookToEdit?: ILocation;
}


// -- Component
// ----------

/** Location codebook container */
class LocationCodebookContainer extends React.Component<ILocationCodebookContainerProps, ILocationCodebookContainerState> {
  state: ILocationCodebookContainerState = {
    isLocationCodebookModalVisible: false,
    locationCodeBookToEdit: undefined,
  };

  componentDidMount() {
    // initial parameters set, parameter change will trigger componentDidUpdate and automaticaly fetch the list
    // when no override is needed, this.updateList() should be called here
    this.fetchLocationCodebookList();
  }

  componentDidUpdate(prevProps: ILocationCodebookContainerProps, prevState: ILocationCodebookContainerState) {
    if (prevProps.collectionParams !== this.props.collectionParams) {
      this.fetchLocationCodebookList();
    }
  }

  render = () => {
    return (
      <React.Fragment>
        {this.props.locationCodebookList &&
          <LocationCodebookListView
            title={this.props.translate('CODEBOOK.TITLE.LOCATION')}
            buttonLabel={this.props.translate('LOCATION_CODEBOOK.CREATE_LOCATION_BUTTON_LABEL')}
            locationCodebookList={this.props.locationCodebookList}
            onEditLocationCodebookClick={this.handleLocationCodebookUpdateModalOpen}
            onCreateLocationCodebookClick={this.handleLocationCodebookModalOpen}
            onPageChange={this.props.updateCollectionParams.onPageChange}
          />
        }

        {this.state.isLocationCodebookModalVisible && <LocationCodebookForm
          locationCodebook={this.state.locationCodeBookToEdit}
          onCancel={this.handleLocationCodebookModalCancel}
          onLocationCodebookCreateSubmit={this.handleLocationCodebookCreateSubmit}
          onLocationCodebookUpdateSubmit={this.handleLocationCodebookUpdateSubmit} />}
      </React.Fragment>
    );
  };

  toogleLocationCodebookModal = (isVisible: boolean) => {
    this.setState({ isLocationCodebookModalVisible: isVisible });
  };

  handleLocationCodebookModalCancel = () => {
    this.setState({ locationCodeBookToEdit: undefined });
    this.toogleLocationCodebookModal(false);
  };

  handleLocationCodebookModalOpen = () => {
    this.toogleLocationCodebookModal(true);
  };

  handleLocationCodebookUpdateModalOpen = (locationCodeBookToEdit: ILocation) => {
    this.setState({ locationCodeBookToEdit });
    this.handleLocationCodebookModalOpen();
  };

  private handleLocationCodebookCreateSubmit = (data: ILocationCreatePayload) => {
    this.props.createLocationCodebook(data).track().subscribe(
      // success
      () => {
        this.props.reportMessage({ message: this.props.translate('LOCATION_CODEBOOK.CREATE_INFO_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
        this.fetchLocationCodebookList();
        this.handleLocationCodebookModalCancel();
      }
    );
  };

  private handleLocationCodebookUpdateSubmit = (data: ILocation) => {
    this.props.updateLocationCodebook(data).track().subscribe(
      // success
      () => {
        this.fetchLocationCodebookList();
        this.handleLocationCodebookModalCancel();
      }
    );
  };

  private fetchLocationCodebookList = (): void => {
    this.props.onUpdateList(this.props.fetchLocationCodebookList);
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ILocationCodebookContainerOwnProps): ILocationCodebookContainerStateProps => ({
  locationCodebookList: LocationBusinessStore.selectors.getLocationList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ILocationCodebookContainerDispatchProps => ({
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
  fetchLocationCodebookList: (params: ICollectionFetchPayload<ILocationListFilter>) => createTrackableAction(dispatch(LocationBusinessStore.actions.fetchLocationList(params))),
  createLocationCodebook: (data: ILocationCreatePayload) => createTrackableAction(dispatch(LocationBusinessStore.actions.createLocation(data))),
  updateLocationCodebook: (data: ILocation) => createTrackableAction(dispatch(LocationBusinessStore.actions.updateLocation(data))),
});

export default connect<ILocationCodebookContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withCollectionState(collectionDefaults)(withLocalize(LocationCodebookContainer as any)));
