import React from 'react';
import { connect } from 'react-redux';

import CodedGradeForm from '@src/components/codebook/form/CodedGradeForm';
import CodeBookListView from '@src/components/codebook/view/CodeBookListView';
import withCollectionState, { IWithCollectionStateOwnProps, IWithCollectionStatePublicProps } from '@src/components/common/collectionParams/withCollectionState';
import AppContent from '@src/components/common/container/AppContent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ICodedGrade } from '@src/model/education/CodedGrade';
import codedGradeBusinessStore, { ICodedGradeCreatePayload, ICodedGradeListFilter } from '@src/service/business/codedgrade/codedGradeBusinessStore';
import { ICollectionData, ICollectionFetchPayload, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

// -- Const
// ----------
const DEFAULT_SORT_VALUE = ['ordering,asc'];
const DEFAULT_PAGE_SIZE_VALUE = AppConfigService.getValue('api.collectionDefaultLimit');
const VIEW_NAME = '@@CODED_GRADE_LIST';
const collectionDefaults: IWithCollectionStatePublicProps<ICodedGradeListFilter> = {
  viewName: VIEW_NAME,
  initialValues: { size: DEFAULT_PAGE_SIZE_VALUE, sort: DEFAULT_SORT_VALUE },
};

// -- Prop types
// ----------
export interface ICodedGradeContainerOwnProps { }
export interface ICodedGradeContainerStateProps {
  codedGradeList: ICollectionData<ICodedGrade>;
}
export interface ICodedGradeContainerDispatchProps {
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
  fetchCodedGradeList: (params: ICollectionFetchPayload<ICodedGradeListFilter>) => ITrackableAction;
  createCodedGrade: (data: ICodedGradeCreatePayload) => ITrackableAction;
  updateCodedGrade: (data: ICodedGrade) => ITrackableAction;
}
type ICodedGradeContainerProps = ICodedGradeContainerOwnProps & ICodedGradeContainerStateProps & ICodedGradeContainerDispatchProps & IWithLocalizeOwnProps & IWithCollectionStateOwnProps<ICodedGradeListFilter>;

interface ICodedGradeContainerState {
  codedGradeModalVisible: boolean;
  codedGradeToEdit?: ICodedGrade;
}

// -- Component
// ----------

/** Coded grade container */
class CodedGradeContainer extends React.Component<ICodedGradeContainerProps, ICodedGradeContainerState> {
  state: ICodedGradeContainerState = {
    codedGradeModalVisible: false,
    codedGradeToEdit: undefined,
  };

  componentDidMount() {
    this.fetchCodedGradeList();
  }

  componentDidUpdate(prevProps: ICodedGradeContainerProps, prevState: ICodedGradeContainerState) {
    if (prevProps.collectionParams !== this.props.collectionParams) {
      this.fetchCodedGradeList();
    }
  }

  render = () => {
    return (
      <AppContent>
        {this.props.codedGradeList && <CodeBookListView<ICodedGrade>
          showOrderingColumn={true}
          showBackColumn={true}
          title={this.props.translate('CODEBOOK.TITLE.GRADE')}
          descriptionPrefix={'CODEBOOK_GRADE.CODED_GRADE_DESCRIPTION_LABEL'}
          buttonLabel={this.props.translate('CODEBOOK_GRADE.CREATE_CODED_GRADE_BUTTON_LABEL')}
          codebookItemList={this.props.codedGradeList}
          canEdit={true}
          onEditCodebookItemClick={this.handleUpdateCodedGradeOpen}
          onCreateCodebookItemClick={this.handleCreateCodedGradeOpen}
          onPageChange={this.props.updateCollectionParams.onPageChange}
        />}

        {this.state.codedGradeModalVisible && <CodedGradeForm codedGrade={this.state.codedGradeToEdit} onCancel={this.handleCodedGradeModalCancel} onCodedGradeUpdateSubmit={this.handleCodedGradeUpdateSubmit} onCodedGradeCreateSubmit={this.handleCodedGradeCreateSubmit} />}
      </AppContent>
    );
  };

  toggleCodedGrademodal = (isVisible: boolean) => {
    this.setState({ codedGradeModalVisible: isVisible });
  };

  handleCodedGradeModalCancel = () => {
    this.setState({ codedGradeToEdit: undefined });
    this.toggleCodedGrademodal(false);
  };

  handleCreateCodedGradeOpen = () => {
    this.toggleCodedGrademodal(true);
  };

  handleUpdateCodedGradeOpen = (codedGrade: ICodedGrade) => {
    this.setState({ codedGradeToEdit: codedGrade });
    this.toggleCodedGrademodal(true);
  };

  private handleCodedGradeCreateSubmit = (data: ICodedGradeCreatePayload) => {
    this.props.createCodedGrade(data).track().subscribe(
      // success
      () => {
        this.props.reportMessage({ message: this.props.translate('CODEBOOK_GRADE.CREATE_INFO_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
        this.fetchCodedGradeList();
        this.handleCodedGradeModalCancel();
      }
    );
  };

  private handleCodedGradeUpdateSubmit = (data: ICodedGrade) => {
    this.props.updateCodedGrade(data).track().subscribe(
      // success
      () => {
        this.fetchCodedGradeList();
        this.handleCodedGradeModalCancel();
      }
    );
  };

  private fetchCodedGradeList = () => {
    this.props.onUpdateList(this.props.fetchCodedGradeList);
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ICodedGradeContainerOwnProps): ICodedGradeContainerStateProps => ({
  codedGradeList: codedGradeBusinessStore.selectors.getCodedGradeList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ICodedGradeContainerDispatchProps => ({
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
  fetchCodedGradeList: (params: ICollectionFetchPayload<ICodedGradeListFilter>) => dispatch(createTrackableAction(codedGradeBusinessStore.actions.fetchCodedGradeList(params))),
  createCodedGrade: (data: ICodedGradeCreatePayload) => dispatch(createTrackableAction(codedGradeBusinessStore.actions.createCodedGrade(data))),
  updateCodedGrade: (data: ICodedGrade) => dispatch(createTrackableAction(codedGradeBusinessStore.actions.updateCodedGrade(data))),
});

export default connect<ICodedGradeContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withCollectionState(collectionDefaults)(withLocalize(CodedGradeContainer as any)));
