import { ITag } from '@src/model/tag/Tag';
import { Tag } from 'antd';
import React from 'react';

// -- Prop types
// ----------

interface ITagListOwnProps {
  tags: ITag[];
  closable: boolean;
  onClose?: (event: React.MouseEvent, id: string) => void;
}
type ITagListProps = ITagListOwnProps;

// -- Component
// ----------

class TagList extends React.Component<ITagListProps> {
  render = () => {
    return this.props.tags.map((tag) => {
      return <Tag key={tag.id}
        className="timun-tagContainer__tag"
        closable={this.props.closable}
        onClose={(event: React.MouseEvent) => this.props.onClose && this.props.onClose(event, tag.id)}
      >
        {tag.title}
      </Tag>;
    });
  };
}

// -- HOCs and exports
// ----------

export default TagList;
