import en from '@src/service/locale/message/en';
import hr from '@src/service/locale/message/hr';

export const MESSAGES = {
  hr: {
    ...hr,
  },
  en,
} as any;

export const LABELS = {
  hr: 'Hrvatski',
  en: 'English',
} as any;
