export interface IActivityPhase {
  id: ActivityPhaseEnum;
  name: string;
  description: string;
}

export enum ExamActivityPhaseEnum {
  EXAM_TEMPLATE_CREATION = '1',
  FINISHED = '2',
  ARCHIVED = '3',
  NOT_STARTED = '4',
  READY = '5',
  ANSWERING_STARTED = '6',
  ANSWERS_SUBMITTED = '7',
  IN_EVALUATION = '8',
  EVALUATED = '9',
  NEVER_STARTED = '10',
}

export enum EducationApplicationActivityPhaseEnum {
  EXTERNAL_EDUCATION_APPLICATION_CREATION = '12',
  SUBMITTED = '13',
  AWAITING_APPROVAL = '14',
  IN_PROCESS = '18',
  CONTRACT_GENERATION = '19',
  AWAITING_SIGNED_CONTRACT = '20',
  AWAITING_PAYMENT = '21',
  EDUCATION_IN_PROGRESS = '22',
  EDUCATION_COMPLETED = '24',
  RETURNED_TO_USER = '16',
  USER_CANCELLED = '15',
  DENIED = '17',
  CONTRACT_TERMINATED = '23',
}

export enum ExternalEducationTemplateActivityPhaseEnum {
  APPROVAL_REQUESTED = '25',
  APPROVAL_IN_PROCESS = '26',
  CHANGE_REQUESTED = '27',
  CHANGE_SUBMITTED = '28',
  APPROVED = '29',
  DENIED = '30',
  CANCELLED = '31',
}

export const ActivityPhaseEnum = { ...ExamActivityPhaseEnum, ...EducationApplicationActivityPhaseEnum, ...ExternalEducationTemplateActivityPhaseEnum };

export type ActivityPhaseEnum = ExamActivityPhaseEnum | EducationApplicationActivityPhaseEnum | ExternalEducationTemplateActivityPhaseEnum;
