import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

import WebinarUpdateContainer from '@src/components/webinar/update/WebinarUpdateContainer';
import WebinarViewContainer from '@src/components/webinar/view/WebinarViewContainer';

// -- Prop types
// ----------
export interface IWebinarViewPagePublicProps { }
interface IWebinarViewPageStateProps {
  currentUser: IUserInfo;
}
type IWebinarViewPageProps = IWebinarViewPagePublicProps & IWebinarViewPageStateProps & WithRouterProps & IWithRolesOwnProps;

// -- Component
// ----------
const WebinarViewPage = (props: IWebinarViewPageProps) => {
  const DisplayComponent = props.allowedRoles([UserRoleEnum.COURSE_CREATOR, UserRoleEnum.ORGANIZATION_ADMIN]) ? WebinarUpdateContainer : WebinarViewContainer;

  return (
    <React.Fragment>
      <DisplayComponent webinarId={props.params.webinarId} />
    </React.Fragment>
  );
};

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IWebinarViewPagePublicProps): IWebinarViewPageStateProps => ({
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
});

export default withRouter(connect<IWebinarViewPageStateProps, any, IWebinarViewPagePublicProps>(mapStateToProps)(withRoles(WebinarViewPage)));
