import { Card, Col } from 'antd';
import React from 'react';

interface IStatisticCardItemOwnProps {
  statisticalNumber: number | string;
  description: string;
}

type IStatisticCardItemProps = IStatisticCardItemOwnProps;

const StatisticCardItem = (props: IStatisticCardItemProps) => {
  return (
    <Col xs={24} md={16} lg={20}>
      <Card title={props.statisticalNumber.toString()} bordered={true}>
        {props.description}
      </Card>
    </Col>
  );
};

export default StatisticCardItem;
