import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

import ITenantConfiguration from '@src/model/tenant/TenantConfiguration';
import PublicTenantConfigurationBusinessStore from '@src/service/business/tenant/publicTenantConfigurationBusinessService';
import { resolvePropValues, TenantPropsType } from '@src/service/business/tenant/tenantPropEnabled';
import AppConfigService from '@src/service/common/AppConfigService';

export interface ITenantPropEnabledRouteGuardPublicProps {
  property: TenantPropsType | TenantPropsType[];
  fallbackRoute?: string;
}
export interface ITenantPropEnabledRouteGuardStateProps {
  tenantConfiguration: ITenantConfiguration;
}
type ITenantPropEnabledRouteGuardProps = ITenantPropEnabledRouteGuardPublicProps & ITenantPropEnabledRouteGuardStateProps & WithRouterProps;

export interface ITenantPropEnabledRouteGuardState {
  allowed: boolean;
}

/**
 * Tenant prop enabled based route guard.
 *
 * Check if tenant props are enabled and renders children if any of them match.
 * If not, user is redirect to logged user's default page (from config).
 *
 * @see {resolvePropValue} for implementation details.
 */
class TenantPropEnabledRouteGuard extends React.Component<ITenantPropEnabledRouteGuardProps, ITenantPropEnabledRouteGuardState> {
  state: ITenantPropEnabledRouteGuardState = {
    allowed: false,
  };

  componentDidMount() {
    this.check();
  }

  componentDidUpdate(previousProps: ITenantPropEnabledRouteGuardProps) {
    if (this.props !== previousProps) {
      this.check();
    }
  }

  render() {
    return <React.Fragment>{this.state.allowed && this.props.children}</React.Fragment>;
  }

  private check() {
    const isAllowed = this.isAllowed();
    // always set state in case this component doesn't get unmounted on redirect
    this.setState({ allowed: isAllowed });

    if (!isAllowed) {
      this.redirectAway();
    }
  }

  private isAllowed(): boolean {
    if (this.props.tenantConfiguration == null) {
      console.warn('Cannot check tenant prop enabled, tenant configuration is empty.');
      return false;
    }

    return resolvePropValues(this.props.property, this.props.tenantConfiguration);
  }

  private redirectAway() {
    const redirectRoute = this.getRedirectRoute();
    if (this.props.location.pathname !== redirectRoute) {
      this.props.router.replace(this.getRedirectRoute());
    } else {
      console.warn(`Trying to redirect to the same route "${redirectRoute}" would cause infinite loop`);
    }
  }

  private getRedirectRoute(): string {
    // default route for logged users - if user is not logged in, authetication route guard should take care of him
    return this.props.fallbackRoute ?? AppConfigService.getValue('routing.publicDefaultRoute');
  }
}

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): ITenantPropEnabledRouteGuardStateProps => ({
  // tutorList: TutorListBusinessStore.selectors.getTutorList(state),
  tenantConfiguration: PublicTenantConfigurationBusinessStore.selectors.getTenantConfiguration(state),
});

// store exported component so we can use it in withXXX HOC
const ExportComponent = connect<ITenantPropEnabledRouteGuardStateProps, any, ITenantPropEnabledRouteGuardPublicProps>(mapStateToProps)(withRouter(TenantPropEnabledRouteGuard));
export default ExportComponent;

/** Higher order component for wrapping existing component with route guard. */
const withTenantPropEnabledRouteGuard = <P extends object>(Component: React.ComponentType<P>, property: TenantPropsType | TenantPropsType[], fallbackRoute?: string) =>
  // tslint:disable-next-line:max-classes-per-file - don't want to declare this in a new file since it is only a util
  class WrappedComponent extends React.Component<P> {
    render() {
      // TODO: find how to type router Route props
      const wrappedProps = (this.props as any).route.props;
      return (
        <ExportComponent {...wrappedProps} property={property} fallbackRoute={fallbackRoute}>
          <Component {...this.props} />
        </ExportComponent>
      );
    }
  };

export { withTenantPropEnabledRouteGuard };
