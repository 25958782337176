import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import React from 'react';


// -- Prop types
// ----------
export interface IEducationApplicationInfoPanelFilterOwnProps {
  onConsultationApplicationFilterClick: () => void;
}
type IEducationApplicationInfoPanelFilterProps = IEducationApplicationInfoPanelFilterOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

const EducationApplicationInfoPanelFilter: React.FC<IEducationApplicationInfoPanelFilterProps> = (props: IEducationApplicationInfoPanelFilterProps) => {
  const educationApplicationFilterLink = <a className="timun-educationApplicationFilter__label--uppercase" onClick={props.onConsultationApplicationFilterClick}> {props.translate('EDUCATION_APPLICATION_COORDINATOR_LIST_INFO_FILTER.SEARCH_URGENT_APPLICATIONS')} </a>;
  return <div className="panel timun-educationApplicationFilter__info">
    <LemonIcon size="xlarge" name="exclamation" />
    {props.translate('EDUCATION_APPLICATION_COORDINATOR_LIST_INFO_FILTER.DESCRIPTION')}
    {educationApplicationFilterLink}
    {props.translate('EDUCATION_APPLICATION_COORDINATOR_LIST_INFO_FILTER.DESCRIPTION_SUFIX')}
  </div>;
};

export default withLocalize<IEducationApplicationInfoPanelFilterOwnProps>(EducationApplicationInfoPanelFilter as any);
