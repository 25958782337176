import { validateIban } from '@src/components/common/form/validation';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { Form, Input } from 'antd';
import React from 'react';

export interface IPaymentInfoInputOwnProps {
  required?: boolean;
}

type IPaymentInfoInputProps = IPaymentInfoInputOwnProps & IWithLocalizeOwnProps;

class PaymentInfoInput extends React.Component<IPaymentInfoInputProps> {
  render = () => {
    return <React.Fragment>
      <Form.Item
        noStyle={true}
        hidden={true}
        name={['paymentInfo', 'id']}
      >
        <Input/>
      </Form.Item>
      <Form.Item
        label={this.props.translate('PAYMENT_INFO_FORM.BANK_NAME_LABEL')}
        name={['paymentInfo', 'bankName']}
        rules={[{ required: this.props.required, message: this.props.translate('COMMON.VALIDATION.REQUIRED') }]}
      >
        <Input/>
      </Form.Item>
      <Form.Item
        label={this.props.translate('PAYMENT_INFO_FORM.IBAN_LABEL')}
        name={['paymentInfo', 'iban']}
        rules={[{ validator: validateIban }, { required: this.props.required, message: this.props.translate('COMMON.VALIDATION.REQUIRED') }]}
      >
        <Input/>
      </Form.Item>
    </React.Fragment>;
  };
}

export default withLocalize<IPaymentInfoInputOwnProps>(PaymentInfoInput as any);
