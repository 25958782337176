import { IReference } from '@src/model/common/Reference';

export interface IOrganizationMemberRole extends IReference<OrganizationMemberRoleEnum> {
}

export enum OrganizationMemberRoleEnum {
  OWNER = '1',
  ADMIN = '2',
  MEMBER = '3',
}
