import { ITag } from '@src/model/tag/Tag';
import { IAdditionalData } from '@src/model/user/AdditionalData';
import { IAddress } from '@src/model/user/Address';
import { IContactData } from '@src/model/user/ContactData';
import { IUserDetails } from '@src/model/user/UserDetails';
import { IUserRole } from '@src/model/user/UserRole';
import { IUserStatus } from '@src/model/user/UserStatus';
import { IUserType } from '@src/model/user/UserType';
import { IWorkData } from '@src/model/user/WorkData';

export interface IUserInfo extends IUserDetails {
  userStatus: IUserStatus;
  gender?: GenderEnum;
  dateOfBirth?: string;
  roles: IUserRole[];
  additionalData: IAdditionalData;
  address?: IAddress;
  contactData: IContactData[];
  workData?: IWorkData;
  userType: IUserType;
  gdprAccepted: boolean;
  tags?: ITag[];
  identificationNumber?: string;
}

export enum GenderEnum {
  M = 'M',
  F = 'F',
}
