export interface INotification {
  id: string;
  message: string;
  title: string;
  type: INotificationType;
  createdAt: string;
}

export interface INotificationType {
  id: NotificationTypeEnum;
  name: string;
}

export enum NotificationTypeEnum {
  PERSONAL = '1',
  GENERIC = '2',
}