import DataPicker, { IDataPickerItem } from '@src/components/common/datapicker/DataPicker';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { ICollectionData } from '@src/service/business/common/types';
import ExternalEducationTemplateBusinessStore from '@src/service/business/externaleducations/ExternalEducationTemplateBusinessStore';
import ExternalEducationTemplateListBusinessStore, { IExternalEducationTemplateListFilter } from '@src/service/business/externaleducations/ExternalEducationTemplateListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { LangUtils } from '@src/service/util/LangUtils';
import { debounce } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

const debounceTimeout = AppConfigService.getValue('components.common.debounceTimeout');
const minSearchLength = AppConfigService.getValue('components.common.minSearchStringLength');

// -- Prop types
// ----------

interface IExternalEducationTemplatePickerOwnProps {
  value?: IExternalEducationTemplate;
  onChange?: (data?: IExternalEducationTemplate) => void;
}


interface IExternalEducationTemplatePickerStateProps {
  externalEducationTemplate: IExternalEducationTemplate;
}

interface IExternalEducationTemplatePickerDispatchProps {
  fetchExternalEducationTemplate: (filter: any) => ITrackableAction;
}
type IExternalEducationTemplatePickerProps = IExternalEducationTemplatePickerOwnProps & IExternalEducationTemplatePickerStateProps & IWithLocalizeOwnProps & IExternalEducationTemplatePickerDispatchProps;

interface IEducationPickerState {
  educations?: IExternalEducationTemplate[];
}

// -- Component
// ---------- keeping this temporary

class ExternalEducationTemplatePicker extends React.Component<IExternalEducationTemplatePickerProps, IEducationPickerState> {
  state: IEducationPickerState = {
    educations: [],
  };

  handleSearch = debounce((value: string) => {
    const filter: IExternalEducationTemplateListFilter = { title: value };
    if (value.length >= minSearchLength) {
      this.updateList(filter);
    } else if (LangUtils.isEmpty(value) || value.length < minSearchLength) {
      this.storeList();
    }
  }, debounceTimeout);

  render = () => {
    const pickerValue = this.props.value && this.mapToItem(this.props.value);
    const pickerItems = this.state.educations?.map(this.mapToItem) || [];

    return <DataPicker
      value={pickerValue}
      items={pickerItems}
      disabled={false}
      onChange={this.handleDataPickerChange}
      onSearch={this.handleSearch}
      placeholderSuffix={this.props.translate('EXTERNAL_EDUCATION_TEMPLATE.PICKER.SEARCH_PLACEHOLDER')}
      dataTestIdPrefix="timun-educationPicker"
    />;
  };

  handleDataPickerChange = (value?: IDataPickerItem) => {
    if (this.props.onChange) {
      this.props.onChange(value?.data);
    }
  };

  private mapToItem = (education: IExternalEducationTemplate): IDataPickerItem => {
    return {
      value: education.id,
      name: education.title,
      data: education,
      description: `${education.title}, ${education.duration}h`,
    };
  };

  private updateList = (filter: IExternalEducationTemplateListFilter = {}) => {
    this.props.fetchExternalEducationTemplate(filter).track().subscribe(
      (data: ICollectionData<IExternalEducationTemplate>) => this.storeList(data.content)
    );
  };

  private storeList = (educations?: IExternalEducationTemplate[]) => {
    this.setState({ educations });
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IExternalEducationTemplatePickerOwnProps): IExternalEducationTemplatePickerStateProps => ({
  externalEducationTemplate: ExternalEducationTemplateBusinessStore.selectors.getExternalEducationTemplate(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: Dispatch): IExternalEducationTemplatePickerDispatchProps => ({
  fetchExternalEducationTemplate: (filter: IExternalEducationTemplateListFilter) => createTrackableAction(dispatch(ExternalEducationTemplateListBusinessStore.actions.fetchExternalEducationTemplatePickerList(filter))),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<IExternalEducationTemplatePickerOwnProps>(ExternalEducationTemplatePicker as any));
