import AppUserAvatar from '@src/components/common/image/AppUserAvatar';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IActionMenuItem } from '@src/components/common/table/ActionMenu';
import DataTable from '@src/components/common/table/DataTable';
import { ITimunDataTableCol } from '@src/components/common/table/TimunDataTableCol';
import { ContactDataTypeEnum, IContactData } from '@src/model/user/ContactData';
import { IUserInfo } from '@src/model/user/User';
import { IUserRole } from '@src/model/user/UserRole';
import { ICollectionData } from '@src/service/business/common/types';
import { Tag } from 'antd';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------
export interface IUserManagementListOwnProps {
  userList: ICollectionData<IUserInfo>;
  scrollable?: boolean;
  additionalCols?: () => Array<ITimunDataTableCol<IUserInfo>>;
  onPageChange?: (page: number, pageSize?: number) => void;
  showOib?: boolean;
}

type IUserManagementListProps = IUserManagementListOwnProps & IWithLocalizeOwnProps & WithRouterProps;

interface IUserUserManagementListState {
}

// -- Component
// ----------
/** Display admin user management list with generic dataTable  */
class UserManagementList extends React.Component<IUserManagementListProps, IUserUserManagementListState> {
  state: IUserUserManagementListState = {};
  renderColumns = () => {
    const columns: Array<ITimunDataTableCol<IUserInfo>> = [
      {
        key: 'avatar',
        contentType: 'string',
        headerTitle: this.props.translate('USER_MANAGEMENT_LIST_VIEW.USER_AVATAR_TITLE'),
        content: (record: IUserInfo) => <AppUserAvatar imagePath={record.profileImageUrl} />,
      },
      {
        key: 'userFirstName',
        contentType: 'string',
        headerTitle: this.props.translate('USER_MANAGEMENT_LIST_VIEW.USER_FIRST_NAME_TITLE'),
        content: (record: IUserInfo) => record.firstName,
      },
      {
        key: 'userLastName',
        contentType: 'string',
        headerTitle: this.props.translate('USER_MANAGEMENT_LIST_VIEW.USER_LAST_NAME_TITLE'),
        content: (record: IUserInfo) => record.lastName,
      },
      {
        key: 'userEmail',
        contentType: 'string',
        headerTitle: this.props.translate('USER_MANAGEMENT_LIST_VIEW.USER_EMAIL_TITLE'),
        content: (record: IUserInfo) => this.getEmailValue(record.contactData),
      },
      {
        key: 'userRole',
        contentType: 'string',
        headerTitle: this.props.translate('USER_MANAGEMENT_LIST_VIEW.USER_ROLES_TITLE'),
        content: (record: IUserInfo) => record.roles.map((role: IUserRole) => <p key={role.id}><Tag>{this.props.translate(`USER_MANAGEMENT_LIST_VIEW.USER_ROLE_${role.name}`)}</Tag></p>),
      },
    ];
    if (this.props.showOib) {
      columns.push({
        key: 'userOib',
        contentType: 'string',
        headerTitle: this.props.translate('USER_MANAGEMENT_LIST_VIEW.USER_IDENTIFICATION_NUMBER_TITLE'),
        content: (record: IUserInfo) => record.identificationNumber,
      });
    }
    if (this.props.additionalCols) {
      columns.push(...this.props.additionalCols());
    }
    return columns;
  };

  renderActionMenuItems = () => {
    const actions: Array<IActionMenuItem<IUserInfo>> = [
      {
        title: this.props.translate('USER_MANAGEMENT_LIST_VIEW.SHOW_USER_PROFILE_ACTION_BUTTON_TITLE'),
        onClick: (record) => this.props.router.push(`/user/${record.id}`),
      },
    ];

    return actions;
  };

  render() {

    return (
      <React.Fragment>
        <DataTable<IUserInfo>
          items={this.props.userList.content}
          paginationData={this.props.userList.page}
          paginationConfig={{ onChange: this.props.onPageChange }}
          columns={this.renderColumns()}
          scrollable={true}
          showActionMenu={true}
          actionMenuItems={this.renderActionMenuItems()}
        />
      </React.Fragment>
    );
  }

  getEmailValue = (contactData: IContactData[]): string => {
    if (contactData) {
      const email = contactData?.find((contact) => contact.type.id === ContactDataTypeEnum.EMAIL);
      if (email) {
        return email.value;
      }
    }
    return '';
  };

}

// -- HOCs and exports
// ----------
export default withLocalize(withRouter<IUserManagementListOwnProps>(UserManagementList as any));
