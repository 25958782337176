import { Tag } from 'antd';
import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import EducationApplicationFilterHelper from '@src/components/externalEducationApplication/filter/utils/EducationApplicationFilterHelper';
import { IExternalEducationApplicationListFilter } from '@src/service/business/externalEducationApplication/ExternalEducationApplicationBusinessStore';

// -- Prop types
// ----------
export interface IEducationApplicationReportListFilterInfoPanelOwnProps {
  filter: IExternalEducationApplicationListFilter;
}

type IEducationApplicationReportListFilterInfoPanelProps = IEducationApplicationReportListFilterInfoPanelOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------
class EducationApplicationReportListFilterInfoPanel extends React.Component<IEducationApplicationReportListFilterInfoPanelProps> {
  render = () => {
    return (
      EducationApplicationFilterHelper.getSelectedParamterKeys(this.props.filter).length > 0 && (
        <div className="timun-wrapper__gutterBox--vertical panel">
          <label>{this.props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.DESCRIPTION', EducationApplicationFilterHelper.getFilterInfo(this.props.filter))}</label>
          &nbsp;
          {EducationApplicationFilterHelper.getSelectedParamterKeys(this.props.filter).map((param) => (
            <Tag key={param}>
              <label>{this.props.translate(`EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_${param.toUpperCase()}`)}:</label>
              &nbsp;
              {EducationApplicationFilterHelper.getSelectedParameterValue(this.props.filter, param)}
            </Tag>
          ))}
        </div>
      )
    );
  };
}

export default withLocalize<IEducationApplicationReportListFilterInfoPanelOwnProps>(EducationApplicationReportListFilterInfoPanel as any);
