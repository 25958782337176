import IBAN from 'iban';

const OIB_REGEX = RegExp('^[0-9]{11}$');

export default class ValidationUtils {
  /** Returns true if IBAN is valid  */
  static isIbanValid = (iban: string): boolean => {
    return IBAN.isValid(iban);
  };

  /** Returns true if OIB is valid */
  static isOibValid = (oib: string): boolean => {
    if (!OIB_REGEX.test(oib)) {
      return false;
    }
    let a = 10;
    for (const digit of oib.substring(0, 10)) {
      // tslint:disable-next-line:radix
      a += parseInt(digit);
      a %= 10;
      if (a === 0) {
        a = 10;
      }
      a *= 2;
      a %= 11;
    }

    let controlVariable = 11 - a;
    if (controlVariable === 10) {
      controlVariable = 0;
    }

    // tslint:disable-next-line:radix
    return controlVariable === parseInt(oib.substr(10, 1));
  };
}
