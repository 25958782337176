import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

import CourseUpdateContainer from '@src/components/course/update/CourseUpdateContainer';
import CourseViewContainer from '@src/components/course/view/CourseViewContainer';

// -- Prop types
// ----------
export interface ICourseViewPagePublicProps { }
interface ICourseViewPageStateProps {
  currentUser: IUserInfo;
}
type ICourseViewPageProps = ICourseViewPagePublicProps & ICourseViewPageStateProps & WithRouterProps & IWithRolesOwnProps;

// -- Component
// ----------
const CourseViewPage = (props: ICourseViewPageProps) => {
  const DisplayComponent = props.allowedRoles([UserRoleEnum.COURSE_CREATOR, UserRoleEnum.ORGANIZATION_ADMIN]) ? CourseUpdateContainer : CourseViewContainer;
  return (
    <React.Fragment>
      <DisplayComponent courseId={props.params.courseId} lectureId={props.params.lectureId} />
    </React.Fragment>
  );
};

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ICourseViewPagePublicProps): ICourseViewPageStateProps => ({
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
});

export default withRouter(connect<ICourseViewPageStateProps, any, ICourseViewPagePublicProps>(mapStateToProps)(withRoles(CourseViewPage)));
