import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { Typography } from 'antd';
import { classNames } from '@src/components/common/util/classNames';

type TitleLevel = 1 | 2 | 3 | 4 | undefined;

// -- Prop types
// ----------

export interface IAppContentOwnProps {
  /** Container title. */
  title?: string | React.ReactNode;
  /** Title level. Number translates to header level eg. 1 -> H1. */
  level?: TitleLevel;
  /** Marks component as top level container which adds additional CSS class in order to provide additional styling hook. */
  mainContent?: boolean;
}
type IAppContentProps = IAppContentOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Container with title for content groups ... */
const AppContent: React.FC<IAppContentProps> = (props) => {
  return (
    <div className={classNames({ 'lemon-appContent__container': true, 'lemon-appContent__container--mainContent': props.mainContent ?? false })}>
      {props.title && (
        <Typography.Title level={getTitleLevel(props)} className="lemon-appContent__header">
          {props.title}
        </Typography.Title>
      )}

      <div className="lemon-appContent__content">{props.children}</div>
    </div>
  );
};

const getTitleLevel = (props: IAppContentProps): TitleLevel => {
  return props.level ?? 1;
};

export default withLocalize<IAppContentOwnProps>(AppContent as any);
