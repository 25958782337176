import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import UserRegistrationForm from '@src/components/user/registration/UserRegistrationForm';
import { IUserInfo } from '@src/model/user/User';
import { Form, Modal } from 'antd';

// -- Prop types
// ----------
export interface IUserRegistrationFormModalOwnProps {
  user: IUserInfo;
  onSubmit?: (data: IUserInfo) => void;
  onCancel?: () => void;
}

type IUserRegistrationFormModalProps = IUserRegistrationFormModalOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Display external education tamplete form in modal */
const UserRegistrationFormModal: React.FC<IUserRegistrationFormModalProps> = (props: IUserRegistrationFormModalProps) => {
  const [form] = Form.useForm<IUserInfo>();

  return (
    <Modal
      className="lemon-modal__modal--md"
      title={props.translate('USER_REGISTRATION.MODAL.TITLE')}
      visible={true}
      maskClosable={false}
      onCancel={props.onCancel}
      onOk={form.submit}
      okText={props.translate('COMMON.ACTION_SAVE')}
      cancelText={props.translate('COMMON.ACTION_CANCEL')}>
      <UserRegistrationForm formRef={form} value={props.user} onSubmit={props.onSubmit} />
    </Modal >
  );
};

export default withLocalize<IUserRegistrationFormModalOwnProps>(UserRegistrationFormModal as any);
