import React, { useCallback } from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { Tag } from 'antd';


// -- Prop types
// ----------

export interface IStatusBadgeOwnProps {
  status?: boolean;
}
type IStatusBadgeProps = IStatusBadgeOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Display status tag  */
const StatusBadge: React.FC<IStatusBadgeProps> = (props: IStatusBadgeProps) => {
  const renderStatusBadge = useCallback(() => {
    let color;
    let message;
    if (props.status) {
      color = 'green';
      message = props.translate('COMMON.LABEL.ACTIVE');
    } else {
      color = 'gray';
      message = props.translate('COMMON.LABEL.INACTIVE');
    }
    return <Tag color={color}> {message} </Tag>;
  }, [props.status]);

  return (
    renderStatusBadge()
  );
};

export default withLocalize<IStatusBadgeOwnProps>(StatusBadge as any);
