import { Form } from '@ant-design/compatible';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { ExpeneseTypeEnum, IExternalEducationExpense } from '@src/model/externalEducationExpense/ExternalEducationExpense';
import AppConfigService from '@src/service/common/AppConfigService';

import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Button, Col, DatePicker, Input, InputNumber, Row, Select } from 'antd';
import React, { SyntheticEvent } from 'react';

const dateFormat = AppConfigService.getValue('dateTimeFormat.dateWithYear');
const backendDate = AppConfigService.getValue('dateTimeFormat.backendDate');

const { Option } = Select;

interface IExternalEducationExpensesFormOwnProps {
  externalEducationApplication: IExternalEducationApplication;
  onSubmit?: (data: IExternalEducationExpense) => void;
}
type IExternalEducationExpensesFormProps = IExternalEducationExpensesFormOwnProps & IWithLocalizeOwnProps & FormComponentProps;

interface IExternalEducationExpensesFormState { }

class ExternalEducationExpensesForm extends React.Component<IExternalEducationExpensesFormProps, IExternalEducationExpensesFormState> {
  state: IExternalEducationExpensesFormState = {};

  componentDidUpdate = (prevProps: IExternalEducationExpensesFormProps, prevState: IExternalEducationExpensesFormState) => {
    //
  };

  render = () => {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form layout="vertical" colon={false}>
        <Form.Item className="hidden">{getFieldDecorator('id', {})(<Input type="hidden" />)}</Form.Item>
        <Form.Item className="hidden">
          {getFieldDecorator('externalEducationApplication.id', {
            initialValue: this.props.externalEducationApplication?.id,
          })(<Input type="hidden" />)}
        </Form.Item>

        <Row gutter={[16, 16]} align="bottom">
          <Col xs={24} sm={12} md={8}>
            <Form.Item label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.EXPENSE.VALUE_LABEL')}>
              {getFieldDecorator('value', {
                rules: [
                  // tslint:disable-next-line: no-duplicate-string
                  { required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') },
                ],
              })(<InputNumber className="full-width" data-test-id="timun-externalEducationApplicationExpensesForm__value" />)}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.EXPENSE.TYPE_LABEL')}>
              {getFieldDecorator('expenseType.id', {
                rules: [{ required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') }],
              })(
                <Select className="timun-dataPicker__select" data-test-id="timun-externalEducationApplicationExpensesForm__expenseType">
                  {/* TODO: hide for HZZ demo purpose only */}
                  {/* <Option value={ExpeneseTypeEnum.INSURANCE_EXPENSE} key={ExpeneseTypeEnum.INSURANCE_EXPENSE} data-test-id={`timun-externalEducationApplicationExpensesForm__expenseType_${ExpeneseTypeEnum.INSURANCE_EXPENSE}`}>
                    {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.EXPENSE.INSURANCE_EXPENSE_LABEL')}
                  </Option> */}
                  <Option value={ExpeneseTypeEnum.TRAVEL_EXPENSE} key={ExpeneseTypeEnum.TRAVEL_EXPENSE} data-test-id={`timun-externalEducationApplicationExpensesForm__expenseType_${ExpeneseTypeEnum.TRAVEL_EXPENSE}`}>
                    {this.props.translate(`EXTERNAL_EDUCATION_APPLICATION.EXPENSE.TRAVEL_EXPENSE_LABEL`)}
                  </Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={8}>
            <Form.Item label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.EXPENSE.DATE_LABEL')}>
              {getFieldDecorator('paymentDate', {
                rules: [{ required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') }],
              })(<DatePicker format={dateFormat} className="full-width" data-test-id="timun-externalEducationApplicationExpensesForm__paymentDate" />)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button type="primary" onClick={this.handleSubmit} disabled={!this.props.form.isFieldsTouched()} data-test-id="timun-externalEducationApplicationExpensesForm__submitButton">
              {this.props.translate('COMMON.ACTION_SAVE')}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        const data = {
          ...values,
          paymentDate: values.paymentDate.format(backendDate),
        };

        if (this.props.onSubmit) {
          this.props.onSubmit(data);
        }

        this.props.form.resetFields();
      }
    });
  };
}

const ExternalEducationExpensesFormWrapper = Form.create<IExternalEducationExpensesFormProps>()(ExternalEducationExpensesForm);

export default withLocalize<IExternalEducationExpensesFormOwnProps>(ExternalEducationExpensesFormWrapper as any);
