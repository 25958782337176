import { Form } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { SearchOutlined } from '@ant-design/icons';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IUserInfo } from '@src/model/user/User';
import { IUserActivityListFilter } from '@src/service/business/user/userActivityListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, Col, DatePicker, Row, Select } from 'antd';
import { debounce } from 'lodash';
import { Moment } from 'moment';
import React from 'react';

const FormItem = Form.Item;
const Option = Select.Option;

const dateFormat = AppConfigService.getValue('dateTimeFormat.dateWithYear');
const queryDateFormat = AppConfigService.getValue('dateTimeFormat.backendQueryParamDateFormat');
const debounceTimeout = AppConfigService.getValue('components.common.debounceTimeout');
const minSearchStringLength = AppConfigService.getValue('components.common.minSearchStringLength');

export interface IUserActivityListFilterOwnProps {
  activityTypesList: any;
  filter: IUserActivityListFilter;
  userList: IUserInfo[];

  onChange: (listFilter: IUserActivityListFilter) => any;
  updateUserList: (value: any) => any;
}

interface ITutoringSessionViewFormState {
  selectedUser?: string;
}

type IUserActivityListFilterProps = IUserActivityListFilterOwnProps & IWithLocalizeOwnProps & FormComponentProps;

class ActivityListFilter extends React.Component<IUserActivityListFilterProps, ITutoringSessionViewFormState> {
  state: ITutoringSessionViewFormState = {};

  // filtering on BE uses name prop and gets both first name and last name when {name: value} is provided
  handleNameSearch = debounce((value: string) => {
    if (value.trim().length >= minSearchStringLength) {
      this.props.updateUserList({ name: value });
    }
  }, debounceTimeout);

  render() {
    const { getFieldDecorator } = this.props.form;

    const nameSearchOptions = this.props.userList.length ? this.props.userList.map((user: IUserInfo) => <Option key={user.id} value={user.id}>{`${user.firstName} ${user.lastName}`}</Option>) : [];

    return (
      <React.Fragment>
        <Form onSubmit={this.handleSubmit} className="timun-userActivityList__filterForm" labelCol={{ span: 5 }} wrapperCol={{ span: 12 }}>
          <Row justify="start">
            <Col xs={24} lg={11}>
              <FormItem label={this.props.translate('USER_ACTIVITY_LIST_FILTER.USER_LABEL')}>
                {getFieldDecorator('name', {
                  trigger: 'onSelect',
                })(
                  <Select
                    allowClear={true}
                    showSearch={true}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={this.handleNameSearch}
                    onSelect={this.handleNameSelect}
                    onChange={this.onNameInputChange}
                    notFoundContent={null}
                    suffixIcon={<SearchOutlined/>}
                    disabled={!this.props.form.isFieldTouched('name') && !!this.state.selectedUser}
                    placeholder={
                      <React.Fragment>
                        <SearchOutlined/>
                        &nbsp;{this.props.translate('USER_ACTIVITY_LIST_FILTER.USER_NAME_PLACEHOLDER')}
                      </React.Fragment>
                    }
                  >
                    {...nameSearchOptions}
                  </Select>,
                )}
              </FormItem>
            </Col>
            <Col xs={24} lg={11}>
              <FormItem label={this.props.translate('USER_ACTIVITY_LIST_FILTER.ACTIVITY_TYPE_LABEL')}>
                {getFieldDecorator('activityType', {
                  initialValue: this.props.filter.activityType || 0,
                })(
                  <Select data-test-id="timun-userActivityList__activityType">
                    <Option allowClear={true} value={0} data-test-id="timun-userActivityList__activityType_placeholder">
                      {this.props.translate('USER_ACTIVITY.TYPE.ALL_TYPES')}
                    </Option>
                    {this.props.activityTypesList.map((role: any) => (
                      <Option key={role.id} value={role.id} data-test-id={`timun-userActivityList__activityType_${role.id}`}>
                        {this.props.translate(`USER_ACTIVITY.TYPE.${role.name}`)}
                      </Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
            </Col>
          </Row>

          <Row justify="start">
            <Col xs={24} lg={11}>
              <FormItem className="" label={this.props.translate('USER_ACTIVITY_LIST_FILTER.START_DATE_LABEL')}>
                {getFieldDecorator('activityStartDate', {})(<DatePicker data-test-id="timun-userActivityList__requestedStartDate" allowClear={true} disabledDate={this.disabledStartDate} format={dateFormat}/>)}
              </FormItem>
            </Col>
            <Col xs={24} lg={11}>
              <FormItem className="" label={this.props.translate('USER_ACTIVITY_LIST_FILTER.END_DATE_LABEL')}>
                {getFieldDecorator('activityEndDate', {})(<DatePicker data-test-id="timun-userActivityList__requestedEndDate" allowClear={true} disabledDate={this.disabledEndDate} format={dateFormat}/>)}
              </FormItem>
            </Col>

            <Col xs={24} span={2} className="timun-userActivityList__filterForm-submitButton">
              <FormItem className="text-center" wrapperCol={{ span: 24 }}>
                <Button data-test-id="timun-userActivityList__filterForm-submitButton" type="primary" htmlType="submit">
                  {this.props.translate('COMMON.ACTION_SEARCH')}
                </Button>
              </FormItem>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    );
  }

  handleNameSelect = (value: any) => {
    this.setState({
      selectedUser: value,
    });
  };

  onNameInputChange = (value: any) => {
    if (!value) {
      this.setState({
        selectedUser: undefined,
      });
      this.props.form.resetFields(['name']);
    }
  };

  disabledStartDate = (startValue?: Moment) => {
    const endValue = this.props.form.getFieldValue('activityEndDate');
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  disabledEndDate = (endValue?: Moment) => {
    const startValue = this.props.form.getFieldValue('activityStartDate');
    if (!endValue || !startValue) {
      return false;
    }

    return endValue.valueOf() <= startValue.valueOf();
  };

  private handleSubmit = (e: any) => {
    e.preventDefault();
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        const listFilter: IUserActivityListFilter = {} as IUserActivityListFilter;
        if (values.activityStartDate) {
          listFilter.startDate = values.activityStartDate.format(queryDateFormat).toString();
        }
        if (values.activityEndDate) {
          listFilter.endDate = values.activityEndDate.format(queryDateFormat).toString();
        }
        if (values.activityType) {
          listFilter.type = values.activityType;
        }
        if (this.state.selectedUser) {
          listFilter.creator = this.state.selectedUser;
        }

        this.props.onChange(listFilter);
      }
    });
  };
}

const WrappedUserActivityListFilter = Form.create<IUserActivityListFilterProps>()(ActivityListFilter as any);

export default withLocalize<IUserActivityListFilterOwnProps>(WrappedUserActivityListFilter as any);
