import { Form } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';

import DeleteElementButton from '@src/components/common/button/DeleteElementButton';
import LemonIcon from '@src/components/common/image/LemonIcon';
import LemonImage from '@src/components/common/image/LemonImage';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import AllowedUserRoles from '@src/components/common/role/AllowedUserRoles';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import ImageUploadForm from '@src/components/common/upload/ImageUploadForm';
import WebinarHelperUtils from '@src/components/webinar/common/WebinarHelperUtils';
import { IFile } from '@src/model/file/File';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { IWebinar, WebinarStatusEnum } from '@src/model/webinar/Webinar';
import { IWebinarCreatePayload } from '@src/service/business/webinars/webinarViewBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, Col, DatePicker, Input, message, Radio, Row, TimePicker } from 'antd';
import moment from 'moment';
import React, { SyntheticEvent } from 'react';

const TextArea = Input.TextArea;

const DATE_FORMAT: string = AppConfigService.getValue('dateTimeFormat.dateWithYear');
const TIME_FORMAT: string = AppConfigService.getValue('dateTimeFormat.time');
const BACKEND_DATE_TIME: string = AppConfigService.getValue('dateTimeFormat.backendDate');
const BACKEND_DATE_QUERY: string = AppConfigService.getValue('dateTimeFormat.backendQueryParamDateFormat');
const WEBINAR_TITLE_MAX_LENGTH: number = 128;
const WEBINAR_GUEST_MAX_LENGTH: number = AppConfigService.getValue('components.common.smallCharacterLengthInput');

interface IWebinarDataFormOwnProps {
  webinar?: IWebinar;
  onSubmit?: (data: IWebinarCreatePayload) => void;
  onCancel?: (editing: boolean) => void;
  onDelete?: (data: IWebinar) => void;
  coverImageId?: string;
  onCoverSubmit: (data: IFile) => void;
}

type IWebinarDataFormProps = IWebinarDataFormOwnProps & IWithLocalizeOwnProps & FormComponentProps & IWithRolesOwnProps & IWithPathResolverOwnProps;

interface IWebinarDataFormState {
  loading: boolean;
  isEditingCover: boolean;
}

class WebinarDataFields extends React.Component<IWebinarDataFormProps, IWebinarDataFormState> {
  state = {
    loading: false,
    isEditingCover: false,
  };

  // tslint:disable-next-line:cognitive-complexity
  render() {
    const ButtonControls = (
      <Form.Item>
        <Row justify="end" gutter={12}>
          <Col>
            <Button icon={<LemonIcon name="check" />} disabled={!WebinarHelperUtils.isReadyToSubmit(this.props.form)} type="primary" htmlType="submit">
              {this.props.translate('COMMON.ACTION_SAVE')}
            </Button>
          </Col>
          <Col>
            <Button icon={<LemonIcon name="times" />} onClick={this.handleCancel}>
              {this.props.translate('COMMON.ACTION_CANCEL')}
            </Button>
          </Col>
          {this.props.onDelete && (
            <Col>
              <DeleteElementButton<IWebinar> buttonText={this.props.translate('COMMON.ACTION_DELETE')} item={this.props.webinar} onDelete={this.props.onDelete} />
            </Col>
          )}
        </Row>
      </Form.Item>
    );

    const { getFieldDecorator } = this.props.form;

    const inCreation = this.props.webinar && WebinarHelperUtils.isWebinarInStatus(this.props.webinar, [WebinarStatusEnum.IN_CREATION]);
    const creationFinished = this.props.webinar && WebinarHelperUtils.isWebinarInStatus(this.props.webinar, [WebinarStatusEnum.CREATION_FINISHED]);
    const wasPublished = this.props.webinar && WebinarHelperUtils.isWebinarInStatus(this.props.webinar, [WebinarStatusEnum.PUBLISHED, WebinarStatusEnum.FINISHED]);

    return (
      <Form className="timun-webinarCreate__form" layout="vertical" onSubmit={this.handleSubmit}>
        <Form.Item label={this.props.translate('WEBINAR_VIEW.CREATE.WEBINAR_TITLE')}>
          {getFieldDecorator('title', {
            initialValue: this.props.webinar && this.props.webinar.title,
            rules: [
              { whitespace: true, message: this.props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
              { required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') },
              { max: WEBINAR_TITLE_MAX_LENGTH, message: this.props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label={this.props.translate('WEBINAR_VIEW.CREATE.WEBINAR_LINK')}>
          {getFieldDecorator('link', {
            initialValue: this.props.webinar && this.props.webinar.link,
            rules: [
              { whitespace: true, message: this.props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
              { required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') },
            ],
          })(<Input />)}
        </Form.Item>


        <Form.Item label={this.props.translate('WEBINAR_VIEW.CREATE.WEBINAR_DATE')}>
          {getFieldDecorator('startDate', {
            rules: [{ required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') }],
            initialValue: this.props.webinar && moment(this.props.webinar.estimatedStartDateTime, BACKEND_DATE_QUERY),
          })(<DatePicker className="timun-webinarCreate__form" placeholder={this.props.translate('WEBINAR_VIEW.CREATE.WEBINAR_DATE_PLACEHOLDER')} allowClear={false} format={DATE_FORMAT} />)}
        </Form.Item>

        <Form.Item label={this.props.translate('WEBINAR_VIEW.CREATE.WEBINAR_TIME')}>
          {getFieldDecorator('startTime', {
            rules: [{ required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') }],
            initialValue: this.props.webinar && moment(this.props.webinar.estimatedStartDateTime),
          })(<TimePicker className="timun-webinarCreate__form" placeholder={this.props.translate('WEBINAR_VIEW.CREATE.WEBINAR_TIME_PLACEHOLDER')} allowClear={false} format={TIME_FORMAT} />)}
        </Form.Item>


        {this.props.webinar && (
          <Form.Item label={this.props.translate('WEBINAR_VIEW.CREATE.WEBINAR_STATUS')}>
            {getFieldDecorator('webinarStatus', {
              initialValue: this.props.webinar.status.id,
              rules: [{ required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') }],
            })(
              <Radio.Group buttonStyle="solid">
                <AllowedUserRoles roles={[UserRoleEnum.COURSE_CREATOR, UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN]} fallback="">
                  <Radio.Button value={WebinarStatusEnum.IN_CREATION} disabled={(creationFinished || wasPublished) && this.props.isInRoles([UserRoleEnum.COURSE_CREATOR, UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.TRAINEE])}>
                    {this.props.translate('WEBINAR.STATUS_TITLE.IN_CREATION')}
                  </Radio.Button>
                  <Radio.Button value={WebinarStatusEnum.CREATION_FINISHED} disabled={wasPublished && this.props.isInRoles([UserRoleEnum.COURSE_CREATOR, UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.TRAINEE])}>
                    {this.props.translate('WEBINAR.STATUS_TITLE.CREATION_FINISHED')}
                  </Radio.Button>
                </AllowedUserRoles>
                <AllowedUserRoles roles={[UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN]} fallback="">
                  <Radio.Button value={WebinarStatusEnum.PUBLISHED} disabled={inCreation}>
                    {this.props.translate('WEBINAR.STATUS_TITLE.PUBLISHED')}
                  </Radio.Button>
                </AllowedUserRoles>
                <AllowedUserRoles roles={[UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN]} fallback="">
                  <Radio.Button value={WebinarStatusEnum.FINISHED} disabled={inCreation}>
                    {this.props.translate('WEBINAR.STATUS_TITLE.FINISHED')}
                  </Radio.Button>
                </AllowedUserRoles>
                <AllowedUserRoles roles={[UserRoleEnum.COURSE_CREATOR, UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN]} fallback="">
                  <Radio.Button value={WebinarStatusEnum.CANCELLED} disabled={inCreation || creationFinished}>
                    {this.props.translate('WEBINAR.STATUS_TITLE.CANCELLED')}
                  </Radio.Button>
                  <Radio.Button value={WebinarStatusEnum.ARCHIVED}>{this.props.translate('WEBINAR.STATUS_TITLE.ARCHIVED')}</Radio.Button>
                </AllowedUserRoles>
              </Radio.Group>,
            )}
          </Form.Item>
        )}
        <Form.Item label={this.props.translate('WEBINAR_VIEW.CREATE.WEBINAR_GUEST')}>
          {getFieldDecorator('guest', {
            initialValue: this.props.webinar && this.props.webinar.guest,
            rules: [
              { whitespace: true, message: this.props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
              { max: WEBINAR_GUEST_MAX_LENGTH, message: this.props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') },
            ],
          })(<Input />)}
        </Form.Item>
        <Form.Item label={this.props.translate('WEBINAR_VIEW.CREATE.WEBINAR_DESCRIPTION')}>
          {getFieldDecorator('description', {
            initialValue: this.props.webinar && this.props.webinar.description,
            rules: [
              { whitespace: true, message: this.props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
              { required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') },
            ],
          })(<TextArea />)}
        </Form.Item>
        <Form.Item label={this.props.translate('WEBINAR_VIEW.CREATE.WEBINAR_PHOTO')}>
          {getFieldDecorator('coverImageUrl', {
            initialValue: this.props.webinar && this.props.webinar.coverImageUrl,
            rules: [{ whitespace: true, message: this.props.translate('COMMON.ENTERED_VALUE_NOT_VALID') }, { message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') }],
          })(
            <React.Fragment>
              {this.props.webinar?.coverImageUrl || this.props.coverImageId ? (
                <div className={'timun-webinarView__coverImage-editable'}>
                  <LemonImage imagePath={this.props.webinar?.coverImageUrl || (this.props.coverImageId && this.props.resolveApiFilePath(this.props.coverImageId)) || 'cover-placeholder.png'} className="timun-webinarView__coverImage" />
                  <div className="timun-webinarView__coverImage-editButton">
                    <a onClick={this.toggleCoverEdit}>
                      <LemonIcon name="edit" />
                    </a>
                  </div>
                </div>
              ) : (
                <Button icon={<LemonIcon name="paperClip" />} onClick={this.toggleCoverEdit} type="primary">
                  {this.props.translate('FILE_UPLOAD.MODAL_UPLOAD_BUTTON')}
                </Button>
              )}
              {this.state.isEditingCover && <ImageUploadForm avatar={true} defaultImageLink={this.props.webinar?.coverImageUrl} onSubmit={this.props.onCoverSubmit} onClose={this.toggleCoverEdit} />}
            </React.Fragment>,
          )}
        </Form.Item>
        {ButtonControls}
      </Form>
    );
  }

  handleCancel = () => {
    this.props.form.resetFields();
    if (this.props.onCancel) {
      this.props.onCancel(false);
    }
  };

  toggleCoverEdit = () => {
    this.setState({
      isEditingCover: !this.state.isEditingCover,
    });
  };

  checkFile = (file: any) => {
    const isImage = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isImage) {
      message.error(this.props.translate('FILE_UPLOAD.IMAGE.ERROR_FILE_TYPE'));
    }
    return isImage;
  };


  private handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        const newValues = { year: values.startDate.year(), month: values.startDate.month(), date: values.startDate.date() };
        const newStartDateTime = moment(values.startTime)
          .set(newValues)
          .format(BACKEND_DATE_TIME);
        const data: IWebinarCreatePayload = {
          coverImageUrl: values.coverImageUrl,
          description: values.description,
          estimatedStartDateTime: newStartDateTime,
          guest: values.guest,
          link: values.link,
          title: values.title,
          status: { id: values.webinarStatus },
        };

        if (this.props.onSubmit) {
          this.props.onSubmit(data);
        }
      }
    });
  };
}

const WebinarDataForm = Form.create()(WebinarDataFields);

export default withLocalize<IWebinarDataFormOwnProps>(withRoles(withPathResolver(WebinarDataForm as any)));
