import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import { ITimunDataTableCol } from '@src/components/common/table/TimunDataTableCol';
import EducationApplicationInfoPanelFilter from '@src/components/externalEducationApplication/filter/EducationApplicationInfoPanelFilter';
import EducationApplicationListFilter from '@src/components/externalEducationApplication/filter/EducationApplicationListFilter';
import EducationApplicationListView from '@src/components/externalEducationApplication/list/EducationApplicationListView';
import { ParticipantRoleEnum } from '@src/model/activity/ActivityParticipant';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import activityBusinessStore, { IActivityParticipantCreatePayload } from '@src/service/business/examtemplates/activityBusinessStore';
import ExternalEducationApplicationBusinessStore, { IExternalEducationApplicationCreatePayload, IExternalEducationApplicationListFilter } from '@src/service/business/externalEducationApplication/ExternalEducationApplicationBusinessStore';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

// -- Const
// ----------
const defaultSortValue = ['createdDateTime,desc'];

// -- Prop types
// ----------
interface IEducationApplicationListContainerOwnProps {
  user?: IUserInfo;
  scrollable?: boolean;
  additionalCols?: () => Array<ITimunDataTableCol<IExternalEducationApplication>>;
  hideMyProfileColumn?: boolean;
  showFilter?: boolean;
}

interface IEducationApplicationListContainerStateProps {
  applicationList: ICollectionData<IExternalEducationApplication>;
  applicationListFilter: IExternalEducationApplicationListFilter;
  currentUser: IUserInfo;
}

interface IEducationApplicationListContainerDispatchProps {
  fetchApplicationList: (params: ICollectionFetchPayload<IExternalEducationApplicationListFilter>) => ITrackableAction;
  createApplication: (applicationData: IExternalEducationApplicationCreatePayload) => ITrackableAction;
  clearFilter: () => void;
  storeFilter: (listFilter: IExternalEducationApplicationListFilter) => void;
  addActivityParticipant: (id: string, data: IActivityParticipantCreatePayload) => ITrackableAction;
}

type IEducationApplicationListContainerProps = IEducationApplicationListContainerOwnProps & IEducationApplicationListContainerStateProps & IEducationApplicationListContainerDispatchProps & WithRouterProps & IWithRolesOwnProps & IWithLocalizeOwnProps;

interface IEducationApplicationListContainerState {
  page: number;
  size: number;
  sort: string[];
}
// -- Component
// ----------
class EducationApplicationListContainer extends React.Component<IEducationApplicationListContainerProps, IEducationApplicationListContainerState> {
  state: IEducationApplicationListContainerState = {
    page: 0,
    size: AppConfigService.getValue('api.paging.midPageSize'),
    sort: defaultSortValue,
  };

  componentDidMount = () => {
    this.props.storeFilter({ participant: this.props.user });
  };

  componentDidUpdate = (prevProps: IEducationApplicationListContainerProps, prevState: IEducationApplicationListContainerState) => {
    if (this.state.page !== prevState.page || this.state.size !== prevState.size || this.state.sort !== prevState.sort || this.props.applicationListFilter !== prevProps.applicationListFilter) {
      this.updateList();
    }
  };

  componentWillUnmount = () => {
    this.props.clearFilter();
  };

  render = () => {
    const isCurrentUserTrainee = this.props.isInRoles([UserRoleEnum.TRAINEE]);
    const isCurrentUserCoordinator = this.props.isInRoles([UserRoleEnum.COORDINATOR]);
    const isCurrentUserAdmin = this.props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]);
    const isOwnProfile = this.props.currentUser.id === this.props.user?.id;

    const showCreateApplicationButton = isCurrentUserTrainee || (isCurrentUserCoordinator && !!this.props.user?.id && !isOwnProfile);

    return (
      <React.Fragment>
        {/* Filter */}
        {(isCurrentUserCoordinator || isCurrentUserAdmin) && this.props.showFilter && this.props.applicationListFilter &&
          <EducationApplicationListFilter
            listFilter={this.props.applicationListFilter}
            onSortChange={this.handleSortChange}
            sortValue={this.state.sort}
            onFilterSubmit={this.handleFilterSubmit}
          />
        }

        {/* Info filter */}
        {isCurrentUserCoordinator && this.props.showFilter &&
          <EducationApplicationInfoPanelFilter onConsultationApplicationFilterClick={this.handleConsultationApplicationFilterClick} />}

        {/* List with list pagination */}
        {this.props.applicationList && (
          <EducationApplicationListView
            currentUser={this.props.currentUser}
            isOwnProfile={isOwnProfile}
            isCurrentUserCoordinator={isCurrentUserCoordinator}
            isCurrentUserTrainee={isCurrentUserTrainee}
            isCurrentUserAdmin={isCurrentUserAdmin}
            additionalCols={this.props.additionalCols}
            additionalSearchParams={false}
            scrollable={this.props.scrollable}
            showCoordinatorColumn={isCurrentUserCoordinator}
            hideMyProfileColumn={this.props.hideMyProfileColumn}
            showCreateApplicationButton={showCreateApplicationButton}
            applicationList={this.props.applicationList}
            onApplicationTraineeCreation={this.handleApplicationTraineeCreate}
            onApplicationCoordinatorCreation={this.handleApplicationCoordinatorCreate}
            onPageChange={this.handlePageChange}
          />
        )}
      </React.Fragment>
    );
  };

  handleConsultationApplicationFilterClick = () => {
    this.setState({ page: 0 });
    this.props.storeFilter({ ...this.props.applicationListFilter, pendingConsultation: true });
  };

  handlePageChange = (page: number, pageSize?: number) => {
    this.setState({ page: page - 1, size: pageSize ?? this.state.size });
  };

  handleFilterSubmit = (listFilter: IExternalEducationApplicationListFilter) => {
    this.setState({ page: 0 });
    this.props.storeFilter(listFilter);
  };

  handleSortChange = (sort: string[]) => {
    this.setState({ sort, page: 0 });
  };

  handleResetFiltersClick = () => {
    this.props.storeFilter({});
  };

  handleApplicationTraineeCreate = () => {
    const applicationFormPageRoute = (id: string) => `externaleducationapplication/${id}`;
    // external education application form needs an existing application to work, so we create it here and redirect to a form-filling view after creation
    this.props
      .createApplication({})
      .track()
      .subscribe(
        // success
        (response: IExternalEducationApplication) => this.props.router.push(applicationFormPageRoute(response.id))
      );
  };

  handleApplicationCoordinatorCreate = () => {
    const applicationFormPageRoute = (id: string) => `externaleducationapplication/${id}`;
    // external education application form needs an existing application to work, so we create it here and redirect to a form-filling view after creation
    this.props
      .createApplication({})
      .track()
      .subscribe(
        // success
        (response: IExternalEducationApplication) => {
          if (this.props.user?.id) {
            const participant: IActivityParticipantCreatePayload = {
              userId: this.props.user.id,
              participantRole: ParticipantRoleEnum.ASSIGNEE,
            };
            this.props
              .addActivityParticipant(response.activity.id, participant)
              .track()
              .subscribe((res) => {
                this.props.router.push(applicationFormPageRoute(response.id));
              });
          } else {
            this.props.router.push(applicationFormPageRoute(response.id));
          }
        }
      );
  };

  private updateList() {
    this.props.fetchApplicationList({
      filter: { ...this.props.applicationListFilter },
      page: this.state.page,
      size: this.state.size,
      sort: this.state.sort,
    });
  }
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IEducationApplicationListContainerStateProps => ({
  applicationList: ExternalEducationApplicationBusinessStore.selectors.getExternalEducationApplications(state),
  applicationListFilter: ExternalEducationApplicationBusinessStore.selectors.getExternalEducationApplicationListFilter(state),
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IEducationApplicationListContainerDispatchProps => ({
  fetchApplicationList: (params: ICollectionFetchPayload<IExternalEducationApplicationListFilter>) => createTrackableAction(dispatch(ExternalEducationApplicationBusinessStore.actions.fetchExternalEducationApplicationList({ ...params }))),
  createApplication: (applicationData: IExternalEducationApplicationCreatePayload) => createTrackableAction(dispatch(ExternalEducationApplicationBusinessStore.actions.createExternalEducationApplication(applicationData))),
  clearFilter: () => dispatch(ExternalEducationApplicationBusinessStore.actions.clearExternalEducationApplicationListFilter()),
  storeFilter: (listFilter: IExternalEducationApplicationListFilter) => dispatch(ExternalEducationApplicationBusinessStore.actions.storeExternalEducationApplicationListFilter(listFilter)),
  addActivityParticipant: (id: string, data: IActivityParticipantCreatePayload) => createTrackableAction(dispatch(activityBusinessStore.actions.updateActivityParticipant(id, data))),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withRoles(withLocalize<IEducationApplicationListContainerOwnProps>(EducationApplicationListContainer as any))));
