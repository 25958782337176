import GridItem from '@src/components/common/grid/GridItem';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import TimunShortInfoTable from '@src/components/common/table/TimunShortInfoTable';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import { ILocation } from '@src/model/location/Location';
import { ICollectionData } from '@src/service/business/common/types';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import { Button, Col, Row, Typography } from 'antd';
import React from 'react';

// -- Const
// ----------
const path = '/administration/codebooks';

// -- Prop types
// ----------
interface ILocationCodebookListViewStateProps {
}

interface ILocationCodebookListViewOwnProps {
  title: string;
  buttonLabel?: string;
  locationCodebookList: ICollectionData<ILocation>;
  onPageChange: (page: number, size?: number) => void;
  onEditLocationCodebookClick?: (locationCodebook: ILocation) => void;
  onCreateLocationCodebookClick: () => void;
  onLocationCodebookDelete?: (locationCodebook: ILocation) => void;
}

type ILocationCodebookListViewProps = ILocationCodebookListViewOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Display codebook list of items */
class LocationCodebookListView extends React.Component<ILocationCodebookListViewProps, ILocationCodebookListViewStateProps> {
  render() {
    const locationCodebookTableActionColumns = {
      edit: { showButton: true, onClick: this.props.onEditLocationCodebookClick },
    };

    return (
      <React.Fragment>
        <Row justify="space-between">
          <Col>
            <HeaderTitle title={LocalizeService.translate('CODEBOOK.CODEBOOK_TITLE')} link={path} icon={<LemonIcon name="left" />} />
          </Col>
          <Col>
            <Button icon={<LemonIcon name="plus" />} type="primary" onClick={this.props.onCreateLocationCodebookClick}>{this.props.buttonLabel} </Button>
          </Col>
        </Row>
        <Typography.Title>{this.props.title}</Typography.Title>
        <TimunShortInfoTable<ILocation>
          titlePropPath="title"
          paginationConfig={{ onChange: this.props.onPageChange }}
          items={this.props.locationCodebookList.content}
          paginationData={this.props.locationCodebookList.page}
          rowDescription={this.renderGradeRowDescription}
          actionButtons={locationCodebookTableActionColumns} />
      </React.Fragment>
    );
  }

  renderGradeRowDescription = (record: ILocation) => {
    if (record.type) {
      return <GridItem key={record.id} contentType="secondary" layout="horizontal" label={this.props.translate('LOCATION_CODEBOOK.LOCATION_DESCRIPTION_LABEL')}> {this.props.translate(`LOCATION_TYPE.${record.type.name}_LABEL`)} </GridItem>;
    }
    return;
  };

  handleEditLocationCodebookClick = (locationCodebook: ILocation) => {
    if (this.props.onEditLocationCodebookClick) {
      this.props.onEditLocationCodebookClick(locationCodebook);
    }
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<ILocationCodebookListViewOwnProps>(LocationCodebookListView as any);
