import React from 'react';
import { connect } from 'react-redux';

import withCollectionState, { IWithCollectionStateOwnProps, IWithCollectionStatePublicProps } from '@src/components/common/collectionParams/withCollectionState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import SkillLevelClassificationList from '@src/components/skilllevelclassification/view/SkillLevelClassificationList';
import { ISkillLevelClassificationShortInfo } from '@src/model/skillgroup/SkillLevelClassification';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import SkillLevelClassificationListBusinessStore, { ISkillLevelClassificationShortInfoListFilter } from '@src/service/business/skilllevelclassification/SkillLevelClassificationBusinessStore';

const VIEW_NAME = '@@SKILL_LEVEL_CLASIFICATION_LIST';
const collectionDefaults: IWithCollectionStatePublicProps<ISkillLevelClassificationShortInfoListFilter> = {
  viewName: VIEW_NAME,
  initialValues: {},
};

// -- Prop types
// ----------

export interface ISkillLevelClassificationListContainerOwnProps {
  skillId: string;
}

export interface ISkillLevelClassificationContainerStateProps {
  skillLevelClassificationList: ICollectionData<ISkillLevelClassificationShortInfo>;
}
export interface ISkillLevelClassificationContainerDispatchProps {
  fetchSkillLevelClassificationList: (skillId: string, params: ICollectionFetchPayload<ISkillLevelClassificationShortInfoListFilter>) => void;
  clearSkillLevelClassificationList: (skillId: string) => void;
}
type ISkillLevelClassificationListContainerProps = ISkillLevelClassificationListContainerOwnProps & ISkillLevelClassificationContainerStateProps
  & ISkillLevelClassificationContainerDispatchProps & IWithLocalizeOwnProps & IWithCollectionStateOwnProps<ISkillLevelClassificationShortInfoListFilter>;

interface ISkillLevelClassificationListContainerState {
}

// -- Component
// ----------

/** Describe your component ... */
class SkillLevelClassificationListContainer extends React.Component<ISkillLevelClassificationListContainerProps, ISkillLevelClassificationListContainerState> {
  state: ISkillLevelClassificationListContainerState = {};

  componentDidMount() {
    this.updateSkillLevelClassificationList();
  }

  componentDidUpdate(prevProps: ISkillLevelClassificationListContainerProps, prevState: ISkillLevelClassificationListContainerState) {
    if (prevProps.skillId !== this.props.skillId) {
      this.updateSkillLevelClassificationList();
    }
  }

  componentWillUnmount() {
    this.props.clearSkillLevelClassificationList(this.props.skillId);
  }

  render = () => {
    return (
      <React.Fragment>
        {this.props.skillLevelClassificationList && <SkillLevelClassificationList skillId={this.props.skillId} skillLevelClassificationList={this.props.skillLevelClassificationList.content} />}
      </React.Fragment>
    );
  };

  fetchSkillLevelClassificationList = (payload: ICollectionFetchPayload<ISkillLevelClassificationShortInfoListFilter>) => {
    this.props.fetchSkillLevelClassificationList(this.props.skillId, payload);
  };

  private updateSkillLevelClassificationList = () => {
    this.props.onUpdateList(this.fetchSkillLevelClassificationList);
  };
}


// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ISkillLevelClassificationListContainerOwnProps): ISkillLevelClassificationContainerStateProps => ({
  skillLevelClassificationList: SkillLevelClassificationListBusinessStore.selectors.getSkillLevelClassificationShortInfoListData(state, ownProps.skillId),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ISkillLevelClassificationContainerDispatchProps => ({
  fetchSkillLevelClassificationList: (skillId: string, params: ICollectionFetchPayload<ISkillLevelClassificationShortInfoListFilter>) => dispatch(SkillLevelClassificationListBusinessStore.actions.fetchSkillLevelClassificationShortInfoList(skillId, params)),
  clearSkillLevelClassificationList: (skillId: string) => dispatch(SkillLevelClassificationListBusinessStore.actions.clearSkillLevelClassificationShortInfoList(skillId)),
});


export default connect(mapStateToProps, mapDispatchToProps)(withCollectionState(collectionDefaults)(withLocalize<ISkillLevelClassificationListContainerOwnProps>(SkillLevelClassificationListContainer as any)));
