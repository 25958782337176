import React, { PropsWithChildren, useEffect, useState } from 'react';

import styleThemePathResolver from '@src/components/common/theme/styleThemePathResolver';

// -- Prop types
// ----------

export interface IAppStyleThemeProviderOwnProps {
  name: string;
  fallback: string;
}
type IAppStyleThemeProviderProps = PropsWithChildren<IAppStyleThemeProviderOwnProps>;

// -- Component
// ----------

/** Dynamically loads app style theme according to given name or fallback. */
const AppStyleThemeProvider: React.FC<IAppStyleThemeProviderProps> = (props) => {
  // --- state
  // themeLoaded
  const [themeLoaded, setThemeLoaded] = useState<boolean>(false);

  // --- effects
  // load theme on name change
  useEffect(() => {
    setThemeLoaded(false);
    console.log(`Loading theme "${props.name}" or "${props.fallback}"`);

    const resolverPromise = styleThemePathResolver(props.name, props.fallback);
    resolverPromise
      .then(() => {
        console.log(`Theme loaded "${props.name}"`);
        setThemeLoaded(true);
      })
      .catch(() => {
        console.error(`Error loading theme "${props.name}" or it's fallback "${props.fallback}"`);
        setThemeLoaded(false);
      });

    return () => {};
  }, [props.name, props.fallback]);

  return <React.Fragment>{themeLoaded && props.children}</React.Fragment>;
};

export default AppStyleThemeProvider;
