import { Action } from 'redux';
import { Observable } from 'rxjs';

/** Prototypes of business store functions. */
export type ActionFn = (...args: any[]) => any;
export type SelectorFn = (...args: any[]) => any;
export type EffectFn = (action$: Observable<IPayloadAction<any>>, state$: any) => Observable<any>;
export type ReducerFn = (state: any, action: IPayloadAction<any>) => any;

/** Structure of every business store. */
export interface IBusinessStore {
  actions: { [key: string]: ActionFn; };
  selectors: { [key: string]: SelectorFn; };
  effects: { [key: string]: EffectFn; };
  reducers: { [key: string]: ReducerFn; };
}

/** Type interface for base redux action (no payload). */
export interface ILemonAction extends Action<string> { }

/** Type interface for redux action with payload. */
export interface IPayloadAction<P> extends ILemonAction {
  payload: P;
}

/** Type interface for collection data/meta structure. See also ICollectionResponse<T, M>. */
export interface ICollectionDataCount {
  pageable: {
    sort: {
      sorted: boolean;
      unsorted: boolean;
    };
    offset: number;
    pageSize: number;
    pageNumber: number;
    paged: boolean;
    unpaged: boolean;
  };
  last: boolean;
  totalPages: number;
  totalElements: number;
  size: number;
  number: number;
  numberOfElements: number;
  first: boolean;
  sort: {
    sorted: boolean;
    unsorted: boolean;
  };
}

/** Type interface for collection data/meta structure. See also ICollectionResponse<T, M>. */
export interface ICollectionData<T, M = ICollectionDataCount> {
  content: T[];
  page: M;
}

// --
// ----------- Utility action payloads

/** Describes payload with id property. */
export interface IIdPayload {
  id: string;
}

/** Describes payload with token property. */
export interface ITokenPayload {
  token: string;
}

/** Describes payload with name property. */
export interface INamedPayload {
  name: string;
}

/** Describes payload with title property. */
export interface ITitlePayload {
  title: string;
}

/** Describes action payload with ID and additional data. */
export interface IIdDataPayload<P> {
  id: string;
  data: P;
}

/** Describes action payload when entity name is needed, and has additional payload data. */
export interface IEntityDataPayload<P> {
  entity: string;
  data: P;
}

/** Describes action payload for fetching collections (list). */
export interface ICollectionFetchPayload<F> {
  /** Search data. # TODO: define search data format */
  filter?: F;
  /** Page number to access (0 indexed, defaults to 0). */
  page?: number;
  /** Page size requested (defaults to 20). */
  size?: number;
  /** A collection of sort directives in the format (property_name(,+[asc|desc])? */
  sort?: string[];
}

// --
// ---------- User Feedback

export enum UserFeedbackMessageSeverity {
  SUCCESS,
  INFO,
  WARNING,
  ERROR,
}

export enum UserFeedbackMessageType {
  /** Feedback message displayed to user as a popup message. */
  // TODO: rename USER -> MESSAGE
  USER,
  /** Feedback message displayed to user using system notification mechanism. */
  NOTIFICATION,
}

/** Describes action payload for reporting user feedback message. */
export interface IUserFeedbackMessagePayload<P = any> {
  id?: string;
  severity: UserFeedbackMessageSeverity;
  type: UserFeedbackMessageType;
  message: string;
  data?: P;
  timeout?: number;
  autoRemove?: boolean;
}

/** Describes data structure for for storing user feedback message. */
export interface IUserFeedbackMessage<P = any> {
  id: string;
  severity: UserFeedbackMessageSeverity;
  type: UserFeedbackMessageType;
  message: string;
  data?: P;
}

/** Describes action data structure for changing stored status. */
export interface IUserFeedbackProgressStatusPayload {
  id: string;
  status?: number;
  update?: number;
}

/** Describes data structure for storing progress status. */
export interface IUserFeedbackProgressStatus {
  id: string;
  status: number;
}


/* store entry for multiple list */
export interface IStoreListData<T> {
  [key: string]: ICollectionData<T>;
}
