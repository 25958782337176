import CreatorCourseListContainer from '@src/components/course/list/catalog/CreatorCourseListContainer';
import React from 'react';
import { connect } from 'react-redux';

// -- Prop types
// ----------

export interface ICreatorDashboardContainerOwnProps { }
export interface ICreatorDashboardContainerStateProps { }
export interface ICreatorDashboardContainerDispatchProps { }
type ICreatorDashboardContainerProps = ICreatorDashboardContainerOwnProps & ICreatorDashboardContainerStateProps & ICreatorDashboardContainerDispatchProps;

interface ICreatorDashboardContainerState { }

// -- Component
// ----------

/** Describe your component ... */
class CreatorDashboardContainer extends React.Component<ICreatorDashboardContainerProps, ICreatorDashboardContainerState> {
  constructor(props: ICreatorDashboardContainerProps) {
    super(props);

    this.state = {};
  }

  render = () => {
    return <CreatorCourseListContainer />;
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ICreatorDashboardContainerOwnProps): ICreatorDashboardContainerStateProps => ({
  // exmaple of state prop
  // currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ICreatorDashboardContainerDispatchProps => ({
  // exmaples of dispatch prop
  // fetchSessionList: (params: ICollectionFetchPayload<ISessionListFilter>) => dispatch(SessionBusinessStore.actions.fetchSessionList(params)),
  // confirmSession: (id: string, message: string | undefined, thunkMap: IActionThunkMap) => dispatch(createActionThunk(SessionBusinessStore.actions.confirmSession({ id }, message), thunkMap)),
});

export default connect<ICreatorDashboardContainerOwnProps>(mapStateToProps, mapDispatchToProps)(CreatorDashboardContainer as any);
