import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { hasFormErrors } from '@src/components/common/form/validation';
import IIdTitleRef from '@src/model/common/IdTitleRef';
import { CourseStatusEnum, ICourse, ICourseProgress } from '@src/model/course/Course';
import { ICourseQuiz } from '@src/model/course/CourseQuiz';
import AppConfigService from '@src/service/common/AppConfigService';
import LocalizeService from '@src/service/util/localize/LocalizeService';

const COURSE_ABOUT_ROUTE = AppConfigService.getValue('components.courses.courseAboutRouterProp');
const COURSE_QUIZ_ROUTE = AppConfigService.getValue('components.courses.courseQuizRouterProp');
const COURSE_NOTES_ROUTE = AppConfigService.getValue('components.courses.courseNotesRouterProp');

export default class CourseHelperUtils {
  /** Check if course is in one of given statuses */
  static isCourseInStatus = (course: ICourse, requestedStatuses: CourseStatusEnum[]): boolean => {
    return requestedStatuses.find((status) => course && course.status.id === status) != null;
  };

  /** Check if opened item is about course content */
  static isAboutCourseContent = (lectureId: string): boolean => {
    return lectureId === COURSE_ABOUT_ROUTE;
  };

  /** Check if opened item is course final quiz */
  static isCourseQuizContent = (lectureId: string): boolean => {
    return lectureId === COURSE_QUIZ_ROUTE;
  };

  /** Check if opened item is course note content */
  static isNotesCourseContent = (lectureId: string): boolean => {
    return lectureId === COURSE_NOTES_ROUTE;
  };

  /** Check if opened item is a lecture */
  static isLecture = (lectureId: string): boolean => {
    return !(CourseHelperUtils.isAboutCourseContent(lectureId) || CourseHelperUtils.isNotesCourseContent(lectureId) || CourseHelperUtils.isCourseQuizContent(lectureId));
  };

  /** Check if course has exam instance defined */
  static hasExamInstance = (quiz: ICourseQuiz): boolean => {
    return quiz?.examInstance != null;
  };

  /** Check if course has exam template defined */
  static hasExamTemplate = (quiz: ICourseQuiz): boolean => {
    return quiz?.examTemplate != null;
  };

  // quiz is null if it was never defined, but once defined and deleted after, quiz object exists with body value ''
  static hasQuiz = (quiz: ICourseQuiz): boolean => {
    return quiz != null && quiz.body != null && quiz.body !== '';
  };

  // go through all form fields and get errors, return if there are any
  static hasFormErrors = (form: WrappedFormUtils): boolean => {
    const fieldsError: any = form.getFieldsError();
    return hasFormErrors(fieldsError);
  };

  // if user has changed some values and there are no errors, save button can be enabled
  static isReadyToSubmit = (form: WrappedFormUtils): boolean => {
    return form.isFieldsTouched() && !CourseHelperUtils.hasFormErrors(form);
  };

  static isCourseInProgress = (progress?: ICourseProgress) => {
    return progress != null && progress.completedLecturesNumber !== 0 && progress.completedLecturesNumber !== progress.lecturesNumber;
  };

  static getCurrentLecture = (progress?: ICourseProgress): IIdTitleRef => {
    let currentLecture: string;
    let currentLectureTitle: string;

    const isCourseInProgress = CourseHelperUtils.isCourseInProgress(progress);

    if (progress != null && isCourseInProgress) {
      currentLecture = progress.currentLecture.id;
      currentLectureTitle = progress.currentLecture.title;
    } else {
      currentLecture = COURSE_ABOUT_ROUTE;
      currentLectureTitle = LocalizeService.translate('COURSE_VIEW.LECTURE_LIST_MENU.COURSE_ABOUT_ITEM_LABEL');
    }

    return { id: currentLecture, title: currentLectureTitle };
  };

}
