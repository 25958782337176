import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';
import AppContent from '@src/components/common/container/AppContent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ExamInstanceView from '@src/components/exam/examInstance/ExamInstanceView';
import { ActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { ICourse } from '@src/model/course/Course';
import { IExamInstance } from '@src/model/education/ExamInstance';
import { IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import courseViewBusinessStore from '@src/service/business/courses/courseViewBusinessStore';
import activityBusinessStore, { IActivityPhaseCreatePayload } from '@src/service/business/examtemplates/activityBusinessStore';
import examInstanceBusinessStore from '@src/service/business/examtemplates/examInstanceBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import React from 'react';
import { connect } from 'react-redux';

// -- Prop types
// ----------

export interface ICourseExamContainerOwnProps {
  examInstanceId: string;
  courseId: string;
}

export interface ICourseExamContainerStateProps {
  examInstance: IExamInstance;
  course: ICourse;
}

export interface ICourseExamContainerDispatchProps {
  fetchExamInstance: (examInstanceId: string) => ITrackableAction;
  clearExamInstance: () => void;
  submitExamInstance: (examInstance: IExamInstance) => ITrackableAction;
  updateActivityNextPhase: (id: string, data: IActivityPhaseCreatePayload) => ITrackableAction;

  reportMessage: (data: IUserFeedbackMessagePayload) => void;
  fetchCourse: (courseId: string) => void;
}

type ICourseExamContainerProps = ICourseExamContainerOwnProps & ICourseExamContainerStateProps & ICourseExamContainerDispatchProps & IWithLocalizeOwnProps;

interface ICourseExamContainerState {
}

// -- Component
// ----------

/** Describe your component ... */
class CourseExamContainer extends React.Component<ICourseExamContainerProps, ICourseExamContainerState> {
  state: ICourseExamContainerState = {};

  componentDidMount() {
    // mount
    this.fetchExamInstance(this.props.examInstanceId);
    this.props.fetchCourse(this.props.courseId);
  }

  componentDidUpdate(prevProps: ICourseExamContainerProps, prevState: ICourseExamContainerState) {
    if (this.props.examInstanceId !== prevProps.examInstanceId || this.props.courseId !== prevProps.courseId) {
      this.fetchExamInstance(this.props.examInstanceId);
      this.props.fetchCourse(this.props.courseId);
    }
  }

  render() {
    return (
      <AppContent>
        {/* --- Display course exam instance view --- */}
        {this.props.examInstance && <ExamInstanceView examInstance={this.props.examInstance} onSubmit={this.handleExamInstanceSubmit} onExamStart={this.resolveConfirmationDialog} courseId={this.props.courseId}/>}
      </AppContent>
    );
  }

  resolveConfirmationDialog = () => {
    confirmationDialog({
      onConfirm: () => this.handleNextPhase(this.props.examInstance.activities[0].id, ActivityPhaseEnum.ANSWERING_STARTED),
    });
  };

  handleExamInstanceSubmit = (examInstance: IExamInstance) => {
    this.props.submitExamInstance(examInstance).track().subscribe(
      // success
      () => {
        this.props.reportMessage({ message: this.props.translate('COURSE_VIEW.COURSE_COMPLETED_MESSAGE', { courseName: this.props.course.title }), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
        this.fetchExamInstance(this.props.examInstanceId);
      },
    );
  };

  fetchExamInstance = (examInstanceId: string) => {
    this.props.fetchExamInstance(examInstanceId);
  };

  handleNextPhase = (activityId: string, nextPhaseId: string) => {
    this.props.updateActivityNextPhase(activityId, { phaseId: nextPhaseId }).track().subscribe(
      // success
      () => this.fetchExamInstance(this.props.examInstanceId),
    );
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ICourseExamContainerOwnProps): ICourseExamContainerStateProps => ({
  examInstance: examInstanceBusinessStore.selectors.getExamInstance(state),
  course: courseViewBusinessStore.selectors.getCourseContent(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ICourseExamContainerDispatchProps => ({
  fetchExamInstance: (examInstanceId: string) => createTrackableAction(dispatch(examInstanceBusinessStore.actions.fetchExamInstance(examInstanceId))),
  clearExamInstance: () => dispatch(examInstanceBusinessStore.actions.clearExamInstance()),
  submitExamInstance: (examInstance: IExamInstance) => createTrackableAction(dispatch(examInstanceBusinessStore.actions.submitExamInstance(examInstance))),
  updateActivityNextPhase: (id: string, data: IActivityPhaseCreatePayload) => createTrackableAction(dispatch(activityBusinessStore.actions.updateActivityPhase(id, data))),

  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
  fetchCourse: (courseId: string) => dispatch(courseViewBusinessStore.actions.fetchCourseContent({ id: courseId })),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<ICourseExamContainerOwnProps>(CourseExamContainer as any));
