import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

import { IQuestionOutcome } from '@lamarodigital/quizzler-lib-frontend/model/quiz/outcome/QuestionOutcome';
import { IExamTemplate } from '@src/model/education/ExamTemplate';
import { IExamTemplateStatistics } from '@src/model/education/ExamTemplateStatistics';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { ICollectionData, IIdDataPayload, IIdPayload, ILemonAction, IPayloadAction, ITitlePayload } from '@src/service/business/common/types';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { trackAction } from '@src/service/util/observable/operators';
import { startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts
export interface IExamTemplateCreatePayload extends ITitlePayload {}

// -
// -------------------- Selectors

/** Returns ExamTemplate from store. */
const getExamTemplate = (store: any): IExamTemplate => store.examTemplate;

/** Returns ExamTemplate statistics from store. */
const getExamTemplateStatistics = (store: any): IExamTemplateStatistics => store.examTemplateStatistics;

/** Returns outComeList from store. */
const getOutcomeList = (store: any): ICollectionData<IQuestionOutcome> => store.outcomeList;

// -
// -------------------- Actions

const Actions = {
  EXAM_TEMPLATE_FETCH: 'EXAM_TEMPLATE_FETCH',
  EXAM_TEMPLATE_LOAD: 'EXAM_TEMPLATE_LOAD',
  EXAM_TEMPLATE_CLEAR: 'EXAM_TEMPLATE_CLEAR',
  EXAM_TEMPLATE_CREATE: 'EXAM_TEMPLATE_CREATE',
  EXAM_TEMPLATE_UPDATE: 'EXAM_TEMPLATE_UPDATE',
  EXAM_TEMPLATE_STATISTICS_FETCH: 'EXAM_TEMPLATE_STATISTICS_FETCH',
  EXAM_TEMPLATE_STATISTICS_LOAD: 'EXAM_TEMPLATE_STATISTICS_LOAD',
  EXAM_TEMPLATE_STATISTICS_CLEAR: 'EXAM_TEMPLATE_STATISTICS_CLEAR',
};

/** Fetch ExamTemplate by ID. */
const fetchExamTemplate = (id: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.EXAM_TEMPLATE_FETCH,
    payload: {
      id,
    },
  };
};

/** Load ExamTemplate to store. */
const loadExamTemplate = (data: IExamTemplate): IPayloadAction<IExamTemplate> => {
  return {
    type: Actions.EXAM_TEMPLATE_LOAD,
    payload: data,
  };
};

/** Clear ExamTemplate from store. Eg. when leaving view. */
const clearExamTemplateData = (): ILemonAction => {
  return {
    type: Actions.EXAM_TEMPLATE_CLEAR,
  };
};

/** Create new ExamTemplate. */
const createExamTemplate = (data: IExamTemplateCreatePayload): IPayloadAction<IExamTemplateCreatePayload> => {
  return {
    type: Actions.EXAM_TEMPLATE_CREATE,
    payload: data,
  };
};

/** Update ExamTemplate by ID. */
const updateExamTemplate = (data: IExamTemplate): IPayloadAction<IIdDataPayload<IExamTemplate>> => {
  return {
    type: Actions.EXAM_TEMPLATE_UPDATE,
    payload: {
      id: data.id,
      data,
    },
  };
};

/** Fetch ExamTemplate statistics by ID. */
const fetchExamTemplateStatistics = (id: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.EXAM_TEMPLATE_STATISTICS_FETCH,
    payload: {
      id,
    },
  };
};

/** Load ExamTemplate statistics to store. */
const loadExamTemplateStatistics = (data: IExamTemplateStatistics): IPayloadAction<IExamTemplateStatistics> => {
  return {
    type: Actions.EXAM_TEMPLATE_STATISTICS_LOAD,
    payload: data,
  };
};

/** Clear ExamTemplate statistics from store. Eg. when leaving view. */
const clearExamTemplateStatisticsData = (): ILemonAction => {
  return {
    type: Actions.EXAM_TEMPLATE_STATISTICS_CLEAR,
  };
};

// -
// -------------------- Side-effects

const fetchExamTemplateEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXAM_TEMPLATE_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('ExamTemplate')
        .fetchEntity(id)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadExamTemplate(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching ExamTemplate', error);
      return o;
    })
  );
};

const createExamTemplateEffect = (action$: Observable<IPayloadAction<IExamTemplateCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXAM_TEMPLATE_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;
      return EntityApiServiceRegistry.getService('ExamTemplate')
        .createEntity(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating ExamTemplate', error);
      return o;
    })
  );
};

const updateExamTemplateEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IExamTemplate>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXAM_TEMPLATE_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('ExamTemplate')
        .updateEntity(payload.id, payload.data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error updating exam template', error);
      return o;
    })
  );
};

const fetchExamTemplateStatisticsEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXAM_TEMPLATE_STATISTICS_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const id = action.payload.id;

      return EntityApiServiceRegistry.getService('ExamTemplate')
        .fetchSubobject(id, 'statistics')
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadExamTemplateStatistics(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error fetching exam template statistics list', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const examTemplate = (state: IExamTemplate | null = null, action: IPayloadAction<IExamTemplate>) => {
  if (action.type === Actions.EXAM_TEMPLATE_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.EXAM_TEMPLATE_CLEAR) {
    return null;
  }

  return state;
};

const examTemplateStatistics = (state: IExamTemplateStatistics | null = null, action: IPayloadAction<IExamTemplateStatistics>) => {
  if (action.type === Actions.EXAM_TEMPLATE_STATISTICS_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.EXAM_TEMPLATE_STATISTICS_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const examTemplateBusinessStore = {
  actions: {
    fetchExamTemplate,
    loadExamTemplate,
    clearExamTemplateData,
    createExamTemplate,
    updateExamTemplate,
    fetchExamTemplateStatistics,
    loadExamTemplateStatistics,
    clearExamTemplateStatisticsData,
  },

  selectors: {
    getExamTemplate,
    getExamTemplateStatistics,
    getOutcomeList,
  },

  effects: {
    fetchExamTemplateEffect,
    createExamTemplateEffect,
    updateExamTemplateEffect,
    fetchExamTemplateStatisticsEffect,
  },

  reducers: {
    examTemplate,
    examTemplateStatistics,
  },
};

// --
// export business store
export default examTemplateBusinessStore;
