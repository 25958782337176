import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import UserGroupAccessFormWrapper from '@src/components/usergroup/form/UserGroupAccessForm';
import UserGroupListView from '@src/components/usergroup/list/UserGroupListView';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { IUserGroup } from '@src/model/usergroup/UserGroup';
import { IUserGroupHierarchy } from '@src/model/usergroup/UserGroupHierarchy';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import UserGroupBusinessStore from '@src/service/business/usergroup/UserGroupBusinessStore';
import UserGroupListBusinessStore, { IUserGroupListFilter } from '@src/service/business/usergroup/UserGroupListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { Button, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

// -- Const
// ----------
const USER_GROUP_ROUTE = AppConfigService.getValue('components.userGroups.userGroupRouterProp');

// -- Prop types
// ----------
export interface IUserGroupListContainerOwnProps {
  memberId?: string;
  showAvailableGroups?: boolean;
}

export interface IUserGroupListContainerStateProps {
  userGroupList: ICollectionData<IUserGroup>;
  userGroupListFilter: IUserGroupListFilter;
  userGroupHierarchy: IUserGroupHierarchy;
}

export interface IUserGroupListContainerDispatchProps {
  fetchUserGroupList: (params: ICollectionFetchPayload<IUserGroupListFilter>) => ITrackableAction;
  fetchUserGroupHierarchy: () => void;
  clearUserGroupList: () => void;
  storeFilter: (filter: IUserGroupListFilter) => void;
  clearFilter: () => void;
  joinUserGroup: (userGroupAccessCode: string) => ITrackableAction;
}

type IUserGroupListContainerProps = IUserGroupListContainerOwnProps & IUserGroupListContainerStateProps & IWithRolesOwnProps & IUserGroupListContainerDispatchProps & IWithLocalizeOwnProps;

interface IUserGroupListContainerState {
  page: number;
  size: number;
  sort: string[];
  isAccessModalVisible: boolean;
  showUserGroupHierarchy: boolean;
}
// -- Component
// ----------

class UserGroupListContainer extends React.Component<IUserGroupListContainerProps, IUserGroupListContainerState> {
  state: IUserGroupListContainerState = {
    page: 0,
    size: AppConfigService.getValue('api.paging.defaultPageSize'),
    sort: [],
    isAccessModalVisible: false,
    showUserGroupHierarchy: this.props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]),
  };

  componentDidMount() {
    this.props.storeFilter({ memberId: this.props.memberId, withoutCurrentUserGroups: this.props.showAvailableGroups });
    if (this.state.showUserGroupHierarchy) {
      this.props.fetchUserGroupHierarchy();
    }
  }

  componentDidUpdate(prevProps: IUserGroupListContainerProps, prevState: IUserGroupListContainerState) {
    if (this.state.page !== prevState.page || this.state.size !== prevState.size || this.state.sort !== prevState.sort || prevProps.userGroupListFilter !== this.props.userGroupListFilter) {
      this.updateList();
    }
  }

  componentWillUnmount() {
    this.props.clearUserGroupList();
  }

  render() {
    return (
      <React.Fragment>
        <Row justify="end">
          <Button type="primary" onClick={this.handleUserGroupAccess}>
            {this.props.translate('USER_GROUP.JOIN_BUTTON_LABEL')}
          </Button>
        </Row>
        {/* ----- User group list MEMBER ----- */}
        {this.props.userGroupList &&
          <UserGroupListView
            userGroupList={this.props.userGroupList}
            userGroupListFilter={this.props.userGroupListFilter}
            showButtons={false}
            basePath={USER_GROUP_ROUTE}
            onPageChange={this.handlePageChange}
            userGroupHierarchy={this.state.showUserGroupHierarchy ? this.props.userGroupHierarchy : undefined}
            onUserGroupHierachyUpdate={this.state.showUserGroupHierarchy ? this.handleDataUpdate : undefined}
          />
        }

        {this.state.isAccessModalVisible &&
          <UserGroupAccessFormWrapper title={this.props.translate('ACCESS_CODE_FORM.TITLE')} onSubmit={this.handleSubmit} onCancel={this.handleCancel} />
        }
      </React.Fragment>
    );
  }

  handleUserGroupAccess = () => {
    this.toggleAccessModal(true);
  };

  toggleAccessModal = (isVisible: boolean) => {
    this.setState({ isAccessModalVisible: isVisible });
  };

  handleCancel = () => {
    this.toggleAccessModal(false);
  };

  handleSubmit = (accessCode: string) => {
    this.props
      .joinUserGroup(accessCode)
      .track()
      .subscribe(
        // Success
        () => {
          this.handleDataUpdate();
          this.toggleAccessModal(false);
        }
      );
  };

  handleDataUpdate = () => {
    this.updateList();
    if (this.state.showUserGroupHierarchy) {
      this.props.fetchUserGroupHierarchy();
    }
  };

  handlePageChange = (page: number, pageSize?: number) => {
    this.setState({ page: page - 1, size: pageSize ?? this.state.size });
  };

  updateList = () => {
    this.props.fetchUserGroupList({
      filter: this.props.userGroupListFilter,
      page: this.state.page,
      size: this.state.size,
      sort: this.state.sort,
    });
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IUserGroupListContainerStateProps => ({
  userGroupList: UserGroupListBusinessStore.selectors.getUserGroupList(state),
  userGroupListFilter: UserGroupListBusinessStore.selectors.getUserGroupListFilter(state),
  userGroupHierarchy: UserGroupListBusinessStore.selectors.getUserGroupHierarchy(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IUserGroupListContainerDispatchProps => ({
  fetchUserGroupList: (params: ICollectionFetchPayload<IUserGroupListFilter>) => createTrackableAction(dispatch(UserGroupListBusinessStore.actions.fetchUserGroupList(params))),
  fetchUserGroupHierarchy: () => dispatch(UserGroupListBusinessStore.actions.fetchUserGroupHierarchy()),
  clearUserGroupList: () => dispatch(UserGroupListBusinessStore.actions.clearUserGroupList()),
  storeFilter: (filter: IUserGroupListFilter) => dispatch(UserGroupListBusinessStore.actions.storeGroupListFilter(filter)),
  clearFilter: () => dispatch(UserGroupListBusinessStore.actions.clearGroupListFilter()),
  joinUserGroup: (userGroupAccessCode: string) => createTrackableAction(dispatch(UserGroupBusinessStore.actions.joinUserGroup(userGroupAccessCode))),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRoles(withLocalize<IUserGroupListContainerOwnProps>(UserGroupListContainer as any)));
