import { ISkillBasedRecommendation } from '@src/model/skillbasedrecommendation/SkillBasedRecommendation';
import { IUserInfo } from '@src/model/user/User';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { ICollectionData, ICollectionFetchPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { transformSkillBasedRecommendationsFilter } from '@src/service/business/skillbasedrecommendations/util';
import { startGlobalProgress, stopGlobalProgress, trackAction } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

// -
// -------------------- Types&Consts

export interface ISkillBasedRecommendationsListFilter {
  /** This "id" is actually User or Workposition object (it is being used for skill recommendations) and being transformed into their respective "id" value before being queried */
  id: IUserInfo | IWorkPosition;
  type: string;
  valueRelation: number;
  maxAbsoluteDiff: number;
}

// -
// -------------------- Selectors

/** Returns list of reports from store. */
const getSkillBasedRecommendationList = (store: any): ICollectionData<ISkillBasedRecommendation> => store.skillBasedRecommendationList;

// -
// -------------------- Actions

const Actions = {
  SKILL_BASED_RECOMMENDATIONS_FETCH: 'SKILL_BASED_RECOMMENDATIONS_FETCH',
  SKILL_BASED_RECOMMENDATIONS_LOAD: 'SKILL_BASED_RECOMMENDATIONS_LOAD',
  SKILL_BASED_RECOMMENDATIONS_CLEAR: 'SKILL_BASED_RECOMMENDATIONS_CLEAR',
};

const fetchSkillBasedRecommendation = (params: ICollectionFetchPayload<ISkillBasedRecommendationsListFilter>): IPayloadAction<ICollectionFetchPayload<ISkillBasedRecommendationsListFilter>> => {
  return {
    type: Actions.SKILL_BASED_RECOMMENDATIONS_FETCH,
    payload: params,
  };
};

/** Load SkillBasedRecommendations list to the store */
const loadSkillBasedRecommendation = (data: ICollectionData<ISkillBasedRecommendation>): IPayloadAction<ICollectionData<ISkillBasedRecommendation>> => {
  return {
    type: Actions.SKILL_BASED_RECOMMENDATIONS_LOAD,
    payload: data,
  };
};

/** Clear SkillBasedRecommendations list from store. Eg. when leaving view. */
const clearSkillBasedRecommendation = (): ILemonAction => {
  return {
    type: Actions.SKILL_BASED_RECOMMENDATIONS_CLEAR,
  };
};

// -
// -------------------- Side-effects

const fetchSkillBasedRecommendationsListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<ISkillBasedRecommendationsListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.SKILL_BASED_RECOMMENDATIONS_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = {
        ...action.payload,
        filter: action.payload.filter && transformSkillBasedRecommendationsFilter(action.payload.filter),
      };

      return EntityApiServiceRegistry.getService('SkillBasedRecommendation')
        .fetchEntityList(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadSkillBasedRecommendation(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error fetching SkillBasedRecommendation list', error);
      return o;
    }),
  );
};

// -
// -------------------- Reducers

const skillBasedRecommendationList = (state: ICollectionData<ISkillBasedRecommendation> | null = null, action: IPayloadAction<ICollectionData<ISkillBasedRecommendation>>) => {
  if (action.type === Actions.SKILL_BASED_RECOMMENDATIONS_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.SKILL_BASED_RECOMMENDATIONS_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const SkillBasedRecommendationsBusinessStore = {
  actions: {
    fetchSkillBasedRecommendation,
    clearSkillBasedRecommendation,
  },
  selectors: {
    getSkillBasedRecommendationList,
  },
  effects: {
    fetchSkillBasedRecommendationsListEffect,
  },
  reducers: {
    skillBasedRecommendationList,
  },
};

// --
// export business store
export default SkillBasedRecommendationsBusinessStore;
