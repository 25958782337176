import { Col, Row } from 'antd';
import React from 'react';

import AppLogoContainer from '@src/components/app/layout/AppLogoContainer';
import MenuControlsContainer from '@src/components/app/layout/MenuControlsContainer';
import AppNavigation from '@src/components/app/navigation/AppNavigation';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';

// -- Prop types
// ----------
export interface IAppHeaderPublicProps {}
interface IAppHeaderStateProps {}
type IAppHeaderProps = IAppHeaderPublicProps & IWithLocalizeOwnProps & IAppHeaderStateProps;

// --
// ----- Component
const AppHeader: React.FC<IAppHeaderProps> = (props) => {
  return (
    <Row className="lemon-appLayout__headerContent" align="middle" justify="space-between">
      <Col className="lemon-application__logoContainer">
        <AppLogoContainer />
      </Col>

      <Col xs={10} sm={15} md={16} lg={17} className="lemon-application__headerAppInfo">
        <AppNavigation />
      </Col>

      <Col className="lemon-application__userMenuContainer">
        <MenuControlsContainer />
      </Col>
    </Row>
  );
};

export default withLocalize<IAppHeaderPublicProps>(AppHeader as any);
