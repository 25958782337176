import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import EmailTemplateBusinessStore, { IEmailTemplateCreatePayload } from '@src/service/business/emailtemplates/EmailTemplateBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import EmailTemplateFormWrapper from '@src/components/emailtemplate/form/EmailTemplateForm';

// -- Prop types
// ----------
interface IEmailTemplateCreateContainerOwnProps {
  onCancel?: () => void;
  onCreate?: () => void;
}

interface IEmailTemplateCreateContainerStateProps {
}

interface IEmailTemplateCreateContainerDispatchProps {
  createEmailTemplate: (data: IEmailTemplateCreatePayload) => ITrackableAction;
}

// -- State types
// ----------
type IEmailTemplateCreateContainerContentProps = IEmailTemplateCreateContainerOwnProps & IEmailTemplateCreateContainerStateProps & IEmailTemplateCreateContainerDispatchProps & IWithLocalizeOwnProps;

interface IEmailTemplateCreateContainerState {
}

// -- Component
// ----------

/** Component for creating email template */
class EmailTemplateCreateContainer extends React.Component<IEmailTemplateCreateContainerContentProps, IEmailTemplateCreateContainerState> {
  state = {
  };

  render() {
    return (
      <React.Fragment>
        <EmailTemplateFormWrapper title={this.props.translate('EMAIL_TEMPLATE.CREATE_EMAIL_TEMPLATE_TITLE')} onCancel={this.props.onCancel} onCreate={this.handleCreateEmailTemplate} />
      </React.Fragment>
    );
  }

  private handleCreateEmailTemplate = (emailTemplate: IEmailTemplateCreatePayload) => {
    this.props.createEmailTemplate(emailTemplate).track().subscribe(
      // success
      () => {
        this.props.onCreate && this.props.onCreate();
      }
    );
  }
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IEmailTemplateCreateContainerOwnProps): IEmailTemplateCreateContainerStateProps => ({
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: Dispatch): IEmailTemplateCreateContainerDispatchProps => ({
  createEmailTemplate: (data: IEmailTemplateCreatePayload) => createTrackableAction(dispatch(EmailTemplateBusinessStore.actions.createEmailTemplate(data))),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<IEmailTemplateCreateContainerOwnProps>(EmailTemplateCreateContainer as any));
