import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { Select } from 'antd';
import React from 'react';

// -- Const
// ----------
const { Option } = Select;

// -- Prop types
// ----------
export interface IYesNoSelectorOwnProps {
  value?: boolean;
  onChange?: (newValue?: boolean) => void;
  fieldName: string;
}

type IYesNoSelectorProps = IYesNoSelectorOwnProps & IWithLocalizeOwnProps;
export enum BooleanStatusEnum {
  TRUE = 'true',
  FALSE = 'false',
}
// -- Component
// ----------

const YesNoSelector: React.FC<IYesNoSelectorProps> = (props) => {
  const handleChange = (value: string) => {
    let transformedValue;
    if (value === BooleanStatusEnum.TRUE) {
      transformedValue = true;
    } else if (value === BooleanStatusEnum.FALSE) {
      transformedValue = false;
    }
    props.onChange && props.onChange(transformedValue);
  };

  const options = Object.keys(BooleanStatusEnum)
    .map((item: string) => (
      <Option value={BooleanStatusEnum[item as keyof typeof BooleanStatusEnum]} key={BooleanStatusEnum[item as keyof typeof BooleanStatusEnum]}
        data-test-id={`timun-yesNoSelect__${props.fieldName}_option_${item}`}
      >
        {props.translate(`COMMON.LABEL.BOOLEAN_${item}`)}
      </Option>
    ));

  return (
    <Select onChange={handleChange} placeholder={props.translate('COMMON.LABEL.PICK_ANSWER_PLACEHOLDER')} value={props.value?.toString()} allowClear={true}
      data-test-id={`timun-yesNoSelect__${props.fieldName}`}
    >
      {options}
    </Select>
  );
};

export default withLocalize<IYesNoSelectorOwnProps>(YesNoSelector as any);
