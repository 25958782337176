/**
 * Helper methods for all UI related issues
 *
 */

export default class UserInterfaceUtils {
  // scroll to top of page
  static scrollToTop = () => {
    window.scrollTo(0, 0);
  };
}
