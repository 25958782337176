import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import MemberList from '@src/components/common/member/MemberList';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import { IOrganization } from '@src/model/organization/Organization';
import { IOrganizationMember } from '@src/model/organization/OrganizationMember';
import { IOrganizationMemberRole, OrganizationMemberRoleEnum } from '@src/model/organization/OrganizationMemberRole';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { ICollectionData } from '@src/service/business/common/types';
import React from 'react';

// -- Prop types
// ----------

export interface IOrganizationMemberListOwnProps {
  organizationMemberList: ICollectionData<IOrganizationMember>;
  organization: IOrganization;
  onPageChange?: (page?: number | undefined, size?: number | undefined) => void;
  onAddMember?: (user: IOrganizationMember) => void;
  onUpdateMember?: (user: IOrganizationMember) => void;
  onDeleteMember?: (user: IOrganizationMember) => void;
}
type IOrganizationMemberListProps = IOrganizationMemberListOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

// -- Component
// ----------

class OrganizationMemberList extends React.Component<IOrganizationMemberListProps> {

  roleTypes = Object.entries(OrganizationMemberRoleEnum).map(([key, value]) => {
    return { value, label: this.props.translate(`ORGANIZATION_MEMBER_LIST.ROLE_TYPE.${key}`) };
  });

  render = () => {
    const canAddMembers = this.props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.COORDINATOR]);
    return (
      <MemberList<IOrganizationMemberRole>
        defaultListType="table"
        memberList={this.props.organizationMemberList}
        roleTypes={this.roleTypes}
        onPageChange={this.props.onPageChange}
        canEditMember={this.canEditMember}
        canAddMembers={canAddMembers}
        onAddMember={this.props.onAddMember}
        onUpdateMember={this.props.onUpdateMember}
        onDeleteMember={this.props.onDeleteMember}
      />
    );
  };

  canEditMember = (member: IOrganizationMember): boolean => {
    if (this.props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.COORDINATOR])) {
      return true;
    }
    if (this.props.organization.currentUserMember?.role.id === OrganizationMemberRoleEnum.OWNER) {
      return member.role.id !== OrganizationMemberRoleEnum.OWNER;
    }
    if (this.props.organization.currentUserMember?.role.id === OrganizationMemberRoleEnum.ADMIN) {
      return member.role.id !== OrganizationMemberRoleEnum.OWNER && member.role.id !== OrganizationMemberRoleEnum.ADMIN;
    }
    return false;
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<IOrganizationMemberListOwnProps>(withRoles(OrganizationMemberList as any));
