import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import { LangUtils } from '@src/service/util/LangUtils';
import { classNames } from '@src/components/common/util/classNames';
import { Icon as LegacyIcon } from '@ant-design/compatible';

// path starts with protocol prefix
const FULL_URI_MATCHER = new RegExp('^(?:[A-Za-z]+:)?//');
// path starts with relative path dleiemiters (. or /)
const RELATIVE_PATH_MATCHER = new RegExp('^[./]');

// -- Prop types
// ----------

// -- Public component props
export interface ILemonImageOwnProps {
  imagePath?: string;
  title?: string;
  className?: string;
  fallbackIconName?: string;
  fallbackImagePath?: string;

  // events
  onError?: () => void;
}
// -- Complete component props
type ILemonImageProps = ILemonImageOwnProps & IWithLocalizeOwnProps & IWithPathResolverOwnProps;

// -- Component state
interface ILemonImageState {
  hasImageLoadError: boolean;
}

// -- Component
// ----------

/**
 * Component for displaying images using HTML "img" element. Also, component provides "onError" event handler in case image cannot be loaded.
 * Images can be referenced using full URL with protocol, as static images bundled with app or as API files.
 *
 * Image path resolving:
 * * path starts with protocol prefix (eg. https://<path> or //<path>) - literal path is used
 * * path starts with absolute or relative path delimiters (eg. / or ./ or ../) - path is resolved as API file URL
 * * path without relative path delimiters (eg. only filename) - path is resolved against image root
 *
 * See "withPathResolver" HOC for more info.
 */
class LemonImage extends React.Component<ILemonImageProps, ILemonImageState> {
  state: ILemonImageState = {
    hasImageLoadError: false,
  };

  componentDidUpdate(prevProps: ILemonImageProps) {
    if (this.props.imagePath !== prevProps.imagePath) {
      this.resetLoadingState();
    }
  }

  render = () => {
    return (
      <React.Fragment>
        {/* render original image */}
        {this.shouldRenderImage() && <img className={this.getClassName()} src={this.resolveImagePath(this.props.imagePath!)} title={this.props.title} onError={this.imageLoadErrorHandler} />}

        {/* render fallback */}
        {this.shouldRenderFallbackImage() && <img className={this.getClassName()} src={this.resolveImagePath(this.props.fallbackImagePath!)} title={this.props.title} />}
        {this.shouldRenderFallbackIcon() && (
          <div className={this.getClassName()}>
            <LegacyIcon type={this.props.fallbackIconName} />
          </div>
        )}
      </React.Fragment>
    );
  };

  imageLoadErrorHandler = () => {
    this.setState({ hasImageLoadError: true });

    if (this.props.onError) {
      this.props.onError();
    }
  };

  shouldRenderImage() {
    return !this.state.hasImageLoadError && !LangUtils.isEmpty(this.props.imagePath);
  }

  shouldRenderFallbackImage() {
    return !LangUtils.isEmpty(this.props.fallbackImagePath) && !this.shouldRenderImage();
  }

  shouldRenderFallbackIcon() {
    return !LangUtils.isEmpty(this.props.fallbackIconName) && !this.shouldRenderImage() && !this.shouldRenderFallbackImage();
  }
  resetLoadingState = () => {
    this.setState({ hasImageLoadError: false });
  };

  resolveImagePath(imagePath: string) {
    // URL contains protocol delimiter and maybe schema, no need to resolve
    if (FULL_URI_MATCHER.test(imagePath)) {
      return imagePath;
    }
    // url contains path delimiters (/) or relative
    else if (RELATIVE_PATH_MATCHER.test(imagePath)) {
      return this.props.resolveApiPath(imagePath);
    } else {
      return this.props.resolveImagePath(imagePath);
    }
  }

  getClassName() {
    return classNames({
      [this.props.className || '']: true,
    });
  }
}

export default withLocalize<ILemonImageOwnProps>(withPathResolver(LemonImage as any));
