import AppContent from '@src/components/common/container/AppContent';
import ListPagination from '@src/components/common/list/ListPagination';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import NotificationList from '@src/components/notification/list/NotificationList';
import NotificationListFilter from '@src/components/notification/list/NotificationListFilter';
import { INotification } from '@src/model/notification/Notification';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import notificationListBusinessStore, {
  INotificationListFilter,
} from '@src/service/business/notification/notificationListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

interface IReadOnlyNotificationListContainerOwnProps { }

interface IReadOnlyNotificationListContainerStateProps {
  notifications: ICollectionData<INotification>;
  notificationListFilter: INotificationListFilter;
}

interface IReadOnlyNotificationListContainerDispatchProps {
  fetchNotificationList: (params: ICollectionFetchPayload<INotificationListFilter>) => any;
  clearFilter: () => any;
  clearNotificationList: () => any;
  storeFilter: (listFilter: INotificationListFilter) => any;
}

type IReadOnlyNotificationListContainerProps = IReadOnlyNotificationListContainerOwnProps & IReadOnlyNotificationListContainerStateProps & IReadOnlyNotificationListContainerDispatchProps & IWithLocalizeOwnProps;

interface IReadOnlyNotificationListContainerState {
  page: number;
  size: number;
  sort: string;
}

class ReadOnlyNotificationListContainer extends React.Component<IReadOnlyNotificationListContainerProps, IReadOnlyNotificationListContainerState> {
  state: IReadOnlyNotificationListContainerState = {
    page: 0,
    size: AppConfigService.getValue('api.paging.defaultPageSize'),
    sort: '',
  };

  componentDidMount() {
    this.handleFilterChange({});
  }

  componentDidUpdate = (prevProps: IReadOnlyNotificationListContainerProps, prevState: IReadOnlyNotificationListContainerState) => {
    if (this.state.page !== prevState.page || this.state.size !== prevState.size || this.state.sort !== prevState.sort || this.props.notificationListFilter !== prevProps.notificationListFilter) {
      this.fetchNotificationList();
    }
  };

  componentWillUnmount = () => {
    this.props.clearNotificationList();
  };

  render() {
    return (
      <AppContent level={2} title={this.props.translate('NOTIFICATIONS.NOTIFICATIONS')}>
        &nbsp;
        <NotificationListFilter onChangeFilter={this.handleFilterChange} listFilter={this.props.notificationListFilter || {}} />
        &nbsp;
        {this.props.notifications &&
          <React.Fragment>
            <ListPagination
              page={this.props.notifications.page}
              onChange={this.handlePageChange}
            />
            <NotificationList
              notifications={this.props.notifications}
              showControls={false}
              onSortChange={this.handleSortChange}
              onPageChange={this.handlePageChange}
            />
            <ListPagination
              page={this.props.notifications.page}
              onChange={this.handlePageChange}
            />
          </React.Fragment>
        }
      </AppContent>
    );
  }

  handleFilterChange = (listFilter: INotificationListFilter) => {
    this.props.storeFilter(listFilter);
    this.setState({ page: 0 });
  };

  handlePageChange = (page: number, pageSize?: number) => {
    this.setState({ page: page - 1, size: pageSize ?? this.state.size });
  };

  handleSortChange = (sort: string) => {
    if (this.state.sort !== sort) {
      this.setState({ page: 0, sort });
    }
  };

  private fetchNotificationList = () => {
    this.props.fetchNotificationList({
      filter: { ...this.props.notificationListFilter },
      page: this.state.page,
      size: this.state.size,
      sort: [this.state.sort],
    });
  };
}

const mapStateToProps = (state: any, ownProps: IReadOnlyNotificationListContainerOwnProps): IReadOnlyNotificationListContainerStateProps => ({
  notifications: notificationListBusinessStore.selectors.getNotificationList(state),
  notificationListFilter: notificationListBusinessStore.selectors.getNotificationListFilter(state),
});

const mapDispatchToProps = (dispatch: Dispatch): IReadOnlyNotificationListContainerDispatchProps => ({
  fetchNotificationList: (params: ICollectionFetchPayload<INotificationListFilter>) => dispatch(notificationListBusinessStore.actions.fetchNotificationList({ ...params })),
  clearFilter: () => dispatch(notificationListBusinessStore.actions.clearNotificationListFilter()),
  clearNotificationList: () => dispatch(notificationListBusinessStore.actions.clearNotificationList()),
  storeFilter: (listFilter: INotificationListFilter) => dispatch(notificationListBusinessStore.actions.storeNotificationListFilter(listFilter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<IReadOnlyNotificationListContainerOwnProps>(ReadOnlyNotificationListContainer as any));
