import React from 'react';
import ReactPlayer from 'react-player/lazy';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';

export interface ILemonVideoOptions {
  showControls?: boolean;
}

export type LemonVideoSource = string | string[];

// -- Prop types
// ----------

export interface ILemonVideoOwnProps {
  src: LemonVideoSource;
  options?: ILemonVideoOptions;
  className?: string;

  onReady?: () => void;
  onError?: (err: any) => void;
}
type ILemonVideoProps = ILemonVideoOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Render video element */
const LemonVideo: React.FC<ILemonVideoProps> = (props) => {
  const showControls = props.options?.showControls ?? false;
  // w/h on 100% will render responsive video
  const width = '100%';
  const height = '100%';

  return (
    <React.Fragment>
      {/* add key prop to video tag because it does not rerender on changed src, no clue why */}
      <ReactPlayer
        key={props.src.toString()}
        className={props.className}
        url={props.src}
        controls={showControls}
        width={width}
        height={height}
        config={{
          file: {
            attributes: {
              // hint browser to prevent preloading entire video, but fetch only some metadata eg. length
              // webkit browser fetch metadata after the entire video has been preloaded thus preloading video event in this case
              preload: 'metadata',
            },
          },
        }}

        onReady={props.onReady}
        onError={props.onError}
      />
    </React.Fragment>
  );
};

export default withLocalize<ILemonVideoOwnProps>(LemonVideo as any);
