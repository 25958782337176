import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, mergeMap } from 'rxjs/operators';

import IIdRef from '@src/model/common/IdRef';
import { IIntegrationVideoType, IntegrationVideoTypeEnum } from '@src/model/integrationvideo/IntegrationVideo';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { IIdPayload, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { startGlobalProgress, stopGlobalProgress, trackAction } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts

export interface IIntegrationVideoCreatePayload {
  integrationId: string;
  integrationType: IIdRef<IntegrationVideoTypeEnum>;
}

export interface IIntegrationVideoTemplateCreatePayload {
  size: number;
  integrationType: Pick<IIntegrationVideoType, 'id'>;
  filename?: string;
}

// -
// -------------------- Selectors

// TODO: add if needed

// -
// -------------------- Actions

const Actions = {
  VIDEO_CREATE: 'VIDEO_CREATE',
  VIDEO_TEMPLATE_CREATE: 'VIDEO_TEMPLATE_CREATE',
  VIDEO_CONFIRM_UPLOAD: 'VIDEO_CONFIRM_UPLOAD',
};

/** Create video. */
const createVideo = (integrationId: string, integrationType: IntegrationVideoTypeEnum): IPayloadAction<IIntegrationVideoCreatePayload> => {
  return {
    type: Actions.VIDEO_CREATE,
    payload: { integrationId, integrationType: { id: integrationType } },
  };
};

/** Create video template. */
const createVideoTemplate = (params: IIntegrationVideoTemplateCreatePayload): IPayloadAction<IIntegrationVideoTemplateCreatePayload> => {
  return {
    type: Actions.VIDEO_TEMPLATE_CREATE,
    payload: params,
  };
};

/** Confirm video upload. Required only when creating video template and uploading video file. */
const confirmVideoUpload = (id: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.VIDEO_CONFIRM_UPLOAD,
    payload: { id },
  };
};

// -
// -------------------- Side-effects

const createVideoEffect = (action$: Observable<IPayloadAction<IIntegrationVideoTemplateCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.VIDEO_CREATE;
    }),

    // creating integrtion video is standalone process, unlike uploading video, thus needs standard progress indicator
    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('IntegrationVideo')
        .createEntity(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'INTEGRATION_VIDEO.ERROR', 'INTEGRATION_VIDEO.MESSAGE.IMPORT_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating integration video', error);
      return o;
    })
  );
};

const createVideoTemplateEffect = (action$: Observable<IPayloadAction<IIntegrationVideoTemplateCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.VIDEO_TEMPLATE_CREATE;
    }),

    // uploading integration video is part of a larger UI process which has it's own progress indicator thus we don't need it here
    // for more info see: IntegrationVideoUpload component

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('IntegrationVideo')
        .createMethod('placeholder', payload)
        .pipe(trackAction(action));
    }),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'INTEGRATION_VIDEO.ERROR', 'INTEGRATION_VIDEO.MESSAGE.UPLOAD_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating integration video template', error);
      return o;
    })
  );
};

const confirmVideoUploadEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.VIDEO_CONFIRM_UPLOAD;
    }),

    // uploading integration video is part of a larger UI process which has it's own progress indicator thus we don't need it here
    // for more info see: IntegrationVideoUpload component

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('IntegrationVideo')
        .updateEntityMethod(payload.id, 'confirmupload', {})
        .pipe(trackAction(action));
    }),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'INTEGRATION_VIDEO.ERROR', 'INTEGRATION_VIDEO.MESSAGE.UPLOAD_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating integration video', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

// TODO: add if needed

// --
// -------------------- Business Store

export const IntegrationVideoBusinessStore = {
  actions: {
    createVideo,
    createVideoTemplate,
    confirmVideoUpload,
  },

  selectors: {},

  effects: {
    createVideoEffect,
    createVideoTemplateEffect,
    confirmVideoUploadEffect,
  },

  reducers: {},
};

// --
// export business store
export default IntegrationVideoBusinessStore;
