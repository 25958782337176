import { Form } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import UserDataPicker from '@src/components/common/datapicker/UserDataPicker';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesStateProps } from '@src/components/common/role/withRoles';
import YesNoSelector from '@src/components/common/selector/YesNoSelector';
import EducationApplicationStatusSelector from '@src/components/externalEducationApplication/filter/common/EducationApplicationStatusSelector';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { IExternalEducationApplicationListFilter } from '@src/service/business/externalEducationApplication/ExternalEducationApplicationBusinessStore';
import { Button, Col, Input, Row, Select } from 'antd';
import React, { SyntheticEvent } from 'react';

const FILTER_LAYOUT = {
  xs: 24,
  sm: 12,
  lg: 8,
  xl: 4,
};

// -- Const
// ----------
const sortItems = [
  {
    id: 'createdDateTime,desc',
    translationKey: 'COMMON.ACTION_SORT_NEWEST',
  },
  {
    id: 'createdDateTime,asc',
    translationKey: 'COMMON.ACTION_SORT_OLDEST',
  },
];

const { Option } = Select;

// -- Prop types
// ----------
export enum EducationApplicationOwnershipStatusEnum {
  MY_APPLICATIONS = '1',
  OTHER_COORDINATORS_APPLICATIONS = '2',
  WITHOUT_COORDINATOR = '3',
}

interface IEducationApplicationListFilterOwnProps {
  listFilter?: IExternalEducationApplicationListFilter;
  sortValue: string[];
  onFilterSubmit?: (filter: IExternalEducationApplicationListFilter) => void;
  onSortChange: (sort: string[]) => void;
}

type IEducationApplicationListFilterProps = IEducationApplicationListFilterOwnProps & IWithLocalizeOwnProps & FormComponentProps & IWithRolesStateProps;

interface IEducationApplicationListFilterState { }

// -- Component
// ----------
class EducationApplicationListFilter extends React.Component<IEducationApplicationListFilterProps, IEducationApplicationListFilterState> {
  state: IEducationApplicationListFilterState = {};

  componentDidUpdate = (prevProps: IEducationApplicationListFilterProps, prevState: IEducationApplicationListFilterState) => {
    if (this.props.listFilter && prevProps.listFilter !== this.props.listFilter) {
      this.props.form.setFieldsValue(this.props.listFilter);
    }
  };

  renderEducationApplicationOwnershipStatusOptions = () => {
    return Object.keys(EducationApplicationOwnershipStatusEnum).map((item: string) => (
      <Option
        value={EducationApplicationOwnershipStatusEnum[item as keyof typeof EducationApplicationOwnershipStatusEnum]}
        key={EducationApplicationOwnershipStatusEnum[item as keyof typeof EducationApplicationOwnershipStatusEnum]}
        data-test-id={`timun-externalEducationApplicationFilter__ownershipSelect_option_${item}`}
      >
        {this.props.translate(`EXTERNAL_EDUCATION_APPLICATION.OWNERSHIP.${item}_LABEL`)}
      </Option>
    ));
  };

  render = () => {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form layout="vertical">
        <Row justify="space-around" gutter={8}>
          <Col {...FILTER_LAYOUT}>
            <Form.Item label={this.props.translate('EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.PENDING_CONSULTATION_LABEL')}>
              {getFieldDecorator('pendingConsultation', {
                initialValue: this.props.listFilter?.pendingConsultation,
              })(<YesNoSelector fieldName="pendingConsultation" />)}
            </Form.Item>
          </Col>

          <Col {...FILTER_LAYOUT}>
            <Form.Item label={this.props.translate('EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.APPLICATION_OWNERSHIP_LABEL')}>
              {getFieldDecorator(
                'externalApplicationOwnershipStatus',
                {}
              )(
                <Select allowClear={true} placeholder={this.props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.PICK_STATUS_PLACEHOLDER')}
                  data-test-id="timun-externalEducationApplicationFilter__ownershipSelect"
                >
                  {this.renderEducationApplicationOwnershipStatusOptions()}
                </Select>
              )}
            </Form.Item>
          </Col>

          <Col {...FILTER_LAYOUT}>
            <Form.Item label={this.props.translate('EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.ASSIGNEE_LABEL')}>
              {getFieldDecorator('assignee', {
                initialValue: this.props.listFilter?.assignee,
              })(<UserDataPicker placeholder={this.props.translate('EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.ASSIGNEE_PLACEHOLDER')} role={UserRoleEnum.TRAINEE} />)}
            </Form.Item>
          </Col>

          <Col {...FILTER_LAYOUT}>
            <Form.Item label={this.props.translate('EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.ASSIGNEE_CITY_LABEL')}>
              {getFieldDecorator('city', {
                initialValue: this.props.listFilter?.city,
              })(<Input placeholder={this.props.translate('EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.ASSIGNEE_CITY_PLACEHOLDER')} data-test-id="timun-externalEducationApplicationFilter__city" />)}
            </Form.Item>
          </Col>

          <Col {...FILTER_LAYOUT}>
            <Form.Item label={this.props.translate('EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.EDUCATION_APPLICATION_STATUS_LABEL')}>
              {getFieldDecorator('educationApplicationStatus', {
                initialValue: this.props.listFilter?.phase,
              })(<EducationApplicationStatusSelector />)}
            </Form.Item>
          </Col>

          <Col {...FILTER_LAYOUT}>
            <Form.Item label={this.props.translate('COMMON.ACTION_SORT_LABEL')}>
              <Select onChange={this.props.onSortChange} value={this.props.sortValue ?? sortItems[0].id} data-test-id="timun-externalEducationApplicationFilter__sortSelect">
                {sortItems.map((item) => {
                  return (
                    <Select.Option key={item.id} value={item.id} data-test-id={`timun-externalEducationApplicationFilter__sortSelect_option_${item.id}`}>
                      {this.props.translate(item.translationKey)}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          {/* Action */}
          <Col>
            <Button onClick={this.handleSubmit} data-test-id="timun-externalEducationApplicationFilter__submitButton"> {this.props.translate('COMMON.ACTION_FILTER')} </Button>
          </Col>
          <Col>
            <Button onClick={this.handleResetFilterSubmit} data-test-id="timun-externalEducationApplicationFilter__resetButton">{this.props.translate('COMMON.ACTION_RESET_FILTER')} </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  handleResetFilterSubmit = () => {
    if (this.props.onFilterSubmit) {
      this.props.onFilterSubmit({});
      this.props.form.resetFields();
    }
  };

  handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        const listFilter: IExternalEducationApplicationListFilter = {};

        listFilter.pendingConsultation = values.pendingConsultation;

        listFilter.evaluator = values.externalApplicationOwnershipStatus === EducationApplicationOwnershipStatusEnum.MY_APPLICATIONS ? { id: this.props.currentUser.id } : undefined;

        listFilter.withoutCoordinator = values.externalApplicationOwnershipStatus !== undefined && values.externalApplicationOwnershipStatus === EducationApplicationOwnershipStatusEnum.WITHOUT_COORDINATOR ? true : undefined;

        listFilter.excludedEvaluator = values.externalApplicationOwnershipStatus === EducationApplicationOwnershipStatusEnum.OTHER_COORDINATORS_APPLICATIONS ? { id: this.props.currentUser.id } : undefined;

        if (values.assignee) {
          listFilter.assignee = values.assignee;
        }
        if (values.city) {
          listFilter.city = values.city;
        }
        if (values.educationApplicationStatus) {
          listFilter.phase = values.educationApplicationStatus;
        }
        if (this.props.onFilterSubmit) {
          this.props.onFilterSubmit(listFilter);
        }
      }
    });
  };
}

const EducationApplicationListFilterForm = Form.create<IEducationApplicationListFilterProps>()(EducationApplicationListFilter);

export default withRoles(withLocalize<IEducationApplicationListFilterOwnProps>(EducationApplicationListFilterForm as any));
