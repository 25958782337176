import React, { useEffect, useState } from 'react';

import SkillDataPicker from '@src/components/common/datapicker/SkillDataPicker';
import SkillLevelDataPicker from '@src/components/common/datapicker/SkillLevelDataPicker';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ISkill } from '@src/model/skillgroup/Skill';
import { ISkillLevel, ISkillLevelClassification } from '@src/model/skillgroup/SkillLevelClassification';
import { Col, Row } from 'antd';
import GridItem from '@src/components/common/grid/GridItem';

// -- Const
// ----------

// -- Prop types
// ----------
export interface ISkillLevelClassificationPickerOwnProps {
  value?: Partial<ISkillLevelClassification>;
  onChange?: (values?: Partial<ISkillLevelClassification>) => void;
  disabled?: boolean;
}

type ISkillLevelClassificationPickerProps = ISkillLevelClassificationPickerOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/**
 * Form to select a skill and it's skill level data
 * It will accept and return data even if it is partially filled, custom form item validation should be written to check if all data exists
 */
const SkillLevelClassificationPicker: React.FC<ISkillLevelClassificationPickerProps> = (props: ISkillLevelClassificationPickerProps) => {

  const [skillLevelClassification, setSkillLevelClassification] = useState<Partial<ISkillLevelClassification | undefined>>(props.value);

  useEffect(() => {
    setSkillLevelClassification(props.value);
  }, [props.value]);

  const handleSkillChange = (skill?: ISkill) => {
    const newValue = {
      skill,
      skillLevel: undefined,
      description: undefined,
    };
    handleChange(newValue);
  };

  const handleSkillLevelChange = (skillLevel?: { skillLevel: ISkillLevel, description: string; }) => {
    const newValue = {
      skill: skillLevelClassification?.skill,
      skillLevel: skillLevel?.skillLevel,
      description: skillLevel?.description,
    };
    handleChange(newValue);
  };

  const handleChange = (newValue?: Partial<ISkillLevelClassification>) => {
    setSkillLevelClassification(newValue);
    props.onChange?.(newValue);
  };

  const getSkillLevelValue = () => {
    return skillLevelClassification?.skillLevel && skillLevelClassification?.description ? {
      skillLevel: skillLevelClassification.skillLevel,
      description: skillLevelClassification.description,
    } : undefined;
  };

  return (
    <React.Fragment>
      <Row gutter={16} justify="space-between">
        <Col span={12}>
          <SkillDataPicker value={skillLevelClassification?.skill} onChange={handleSkillChange} disabled={props.disabled} />
        </Col>
        <Col span={12}>
          <SkillLevelDataPicker skillId={skillLevelClassification?.skill?.id} value={getSkillLevelValue()} onChange={handleSkillLevelChange} disabled={props.disabled} />
        </Col>
      </Row>
      &nbsp;
      {getSkillLevelValue()?.description && <GridItem label={props.translate('SKILL_LEVEL_CLASSIFICATION_PICKER.LEVEL_DESCRIPTION_LABEL')}> {getSkillLevelValue()?.description}  </GridItem>}
    </React.Fragment>
  );
};

export default withLocalize<ISkillLevelClassificationPickerOwnProps>(SkillLevelClassificationPicker as any);
