import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Comment, Tag, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';

import CommentForm from '@src/components/comment/CommentForm';
import AppUserAvatar from '@src/components/common/image/AppUserAvatar';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import { UserNameView } from '@src/components/common/view/UserNameView';
import { IComment, ICommentCreatePayload } from '@src/model/comment/Comment';
import { VoteTypeEnum } from '@src/model/comment/VoteObjectType';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import AppConfigService from '@src/service/common/AppConfigService';

const DATE_FORMAT: string = AppConfigService.getValue('dateTimeFormat.dateWithTime');

// -- PropTypes
// ----------

interface ICommentListState {
  isEditFormVisible: boolean;
  isCreateFormVisible: boolean;
  page: number;
  size: number;
  commentToEdit?: IComment;
}

export interface ICommentListOwnProps {
  comments: IComment[];
  currentUser: IUserInfo;
  objectId?: string;
  objectTypeId?: string;
  onCommentCreateSubmit?: (comment: ICommentCreatePayload) => void;
  onCommentUpdate?: (newComment: IComment) => void;
  onCommentDelete?: (id: string) => void;
  onVoteAdd?: (commentId: string, voteType: VoteTypeEnum) => void;
  onDeleteVote?: (commentId: string, voteType: VoteTypeEnum) => void;
}

type ICommentListProps = ICommentListOwnProps & IWithRolesOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** component for displaying, editing & deleting comments and comment votes */
class CommentList extends React.Component<ICommentListProps, ICommentListState> {
  state: ICommentListState = {
    isEditFormVisible: false,
    isCreateFormVisible: true,
    page: 0,
    size: AppConfigService.getValue('api.paging.defaultPageSize'),
    commentToEdit: undefined,
  };

  downVoteComment = (commentId: string, voteType: VoteTypeEnum): void => {
    if (this.props.onVoteAdd) {
      this.props.onVoteAdd(commentId, voteType);
    }
  };

  upVoteComment = (commentId: string, voteType: VoteTypeEnum): void => {
    if (this.props.onVoteAdd) {
      this.props.onVoteAdd(commentId, voteType);
    }
  };

  openEditComment = (commentToEdit: IComment): void => {
    this.setState({ commentToEdit, isEditFormVisible: true, isCreateFormVisible: false });
  };

  closeEditComment = () => {
    this.setState({ isEditFormVisible: false, isCreateFormVisible: true });
  }

  updateComment = (commentToEdit: IComment): void => {
    this.props.onCommentUpdate?.(commentToEdit);
    this.setState({ commentToEdit: undefined, isEditFormVisible: false, isCreateFormVisible: true });
  };

  deleteComment = (id: string): void => {
    if (this.props.onCommentDelete) {
      this.props.onCommentDelete(id);
    }
  };

  deleteVote = (commentId: string, voteType: VoteTypeEnum): void => {
    if (this.props.onDeleteVote) {
      this.props.onDeleteVote(commentId, voteType);
    }
  };

  onVoteClick = (comment: IComment, clickedVoteTypeEnum: VoteTypeEnum) => {
    const existingVoteType = comment.vote.currentUserVote.voteType;

    if (existingVoteType == null) {
      if (clickedVoteTypeEnum === VoteTypeEnum.UPVOTE) {
        this.upVoteComment(comment.id, clickedVoteTypeEnum);
      } else {
        this.downVoteComment(comment.id, clickedVoteTypeEnum);
      }
    } else {
      if (existingVoteType.id !== clickedVoteTypeEnum) {
        if (clickedVoteTypeEnum === VoteTypeEnum.UPVOTE) {
          this.upVoteComment(comment.id, clickedVoteTypeEnum);
        } else {
          this.downVoteComment(comment.id, clickedVoteTypeEnum);
        }
      } else {
        this.deleteVote(comment.id, clickedVoteTypeEnum);
      }
    }
  };

  render() {
    const { currentUser } = this.props;

    const isUserAdmin = this.props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN]);

    return (
      <React.Fragment>
        {this.props.comments &&
          this.props.comments.map((comment, index) => (
            <React.Fragment key={index}>
              {/* Comment */}
              <Comment
                actions={[
                  (comment.approved === false) && (<span key="comment-info">
                    <Tag color="#bababa" > {this.props.translate('COMMENTS_VIEW.APPROVAL_INFO_MESSAGE')} </Tag>
                  </span>),
                  <span key="comment-basic-like">
                    <Tooltip title="Like">
                      <LegacyIcon type="like" theme={comment.vote.currentUserVote.voteType && comment.vote.currentUserVote.voteType.name === 'UP' ? 'filled' : 'outlined'} onClick={() => this.onVoteClick(comment, VoteTypeEnum.UPVOTE)} />
                    </Tooltip>
                    <span className="timun-commentView__voteCount">{comment.vote.upVoteCount}</span>
                  </span>,
                  <span key="comment-basic-dislike">
                    <Tooltip title="Dislike">
                      <LegacyIcon type="dislike" theme={comment.vote.currentUserVote.voteType && comment.vote.currentUserVote.voteType.name === 'DOWN' ? 'filled' : 'outlined'} onClick={() => this.onVoteClick(comment, VoteTypeEnum.DOWNVOTE)} />
                    </Tooltip>
                    <span className="timun-commentView__voteCount">{comment.vote.downVoteCount}</span>
                  </span>,
                  <React.Fragment key="comment-handling-section">
                    {isUserAdmin || comment.creator && (comment.creator.id === currentUser.id) ? (
                      <React.Fragment>
                        {this.state.isEditFormVisible && this.state.commentToEdit?.id === comment.id ? <span key="comment-basic-edit" onClick={this.closeEditComment}>
                          {this.props.translate('COMMENTS_VIEW.CLOSE_EDIT_SECTION')}
                        </span>
                          :
                          <span key="comment-basic-edit" onClick={() => this.openEditComment(comment)}>
                            {this.props.translate('COMMENTS_VIEW.EDIT_COMMENT')}
                          </span>
                        }
                        <span key="comment-basic-reply-to" onClick={() => this.deleteComment(comment.id)}>
                          {this.props.translate('COMMENTS_VIEW.DELETE_COMMENT')}
                        </span>
                      </React.Fragment>
                    ) : (
                      ''
                    )}
                  </React.Fragment>,
                ]}
                author={<UserNameView user={comment.creator} fallback={this.props.translate('USER_VIEW.USER_DELETED_LABEL')} />}
                avatar={<AppUserAvatar size="small" imagePath={comment.creator ? comment.creator.profileImageUrl : undefined} />}
                content={<p>{comment.content}</p>}
                datetime={
                  <Tooltip title={moment(comment.addedDateTime).format(DATE_FORMAT)}>
                    <span>{moment(comment.addedDateTime).fromNow()}</span>
                  </Tooltip>
                }
              />
              {this.state.isEditFormVisible && this.state.commentToEdit?.id === comment.id && <CommentForm comment={this.state.commentToEdit} onCommentUpdateSubmit={this.updateComment} />}
            </React.Fragment>
          ))}
        {this.state.isCreateFormVisible && <CommentForm object={this.props.objectId} objectType={this.props.objectTypeId} onCommentCreateSubmit={this.props.onCommentCreateSubmit} />
        }
      </React.Fragment>
    );
  }
}

export default withLocalize<ICommentListOwnProps>(withRoles(CommentList as any));
