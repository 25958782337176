import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ViewSectionItem from '@src/components/common/section/ViewSectionItem';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import { IPieData, TimunPieChart } from '@src/components/externalEducationApplication/statistics/charts/TimunPieChart';
import StatisticCardItem from '@src/components/externalEducationApplication/statistics/StatisticCardItem';
import { IExamTemplateStatistics, IQuestionCorrectnessPercentage } from '@src/model/education/ExamTemplateStatistics';
import AppConfigService from '@src/service/common/AppConfigService';
import { Col, Divider, Progress, Row, Typography } from 'antd';
import Table, { ColumnProps } from 'antd/lib/table';
import moment from 'moment';
import React from 'react';
import { WithRouterProps } from 'react-router';

// -- Prop types
// ----------

const timeFormat = AppConfigService.getValue('dateTimeFormat.timeWithSeconds');

export interface IExamTemplateStatisticsPanelOwnProps {
  examTemplateStatistics: IExamTemplateStatistics;
}

export interface IExamTemplateStatisticsPanelStateProps {
}

export interface IExamTemplateStatisticsPanelDispatchProps {
}

type IExamTemplateStatisticsPanelProps = IExamTemplateStatisticsPanelOwnProps & IExamTemplateStatisticsPanelStateProps & IExamTemplateStatisticsPanelDispatchProps & IWithLocalizeOwnProps & WithRouterProps;

interface IExamTemplateStatisticsPanelState {
}

// -- Component
// ----------

/** Component that display exam template details */
class ExamTemplateStatisticsPanel extends React.Component<IExamTemplateStatisticsPanelProps, IExamTemplateStatisticsPanelState> {
  state: IExamTemplateStatisticsPanelState = {};

  columns: Array<ColumnProps<IQuestionCorrectnessPercentage>> = [
    {
      title: this.props.translate('EXAM_TEMPLATE.STATISTICS.QUESTION_ORDERING_TITLE'),
      key: 'ordering',
      width: '10%',
      render: (record: IQuestionCorrectnessPercentage) => record.question.ordering,
    },
    {
      title: this.props.translate('EXAM_TEMPLATE.STATISTICS.QUESTION_TEXT_TITLE'),
      key: 'text',
      render: (record: IQuestionCorrectnessPercentage) => record.question.intro?.text,
    },
    {
      title: this.props.translate('EXAM_TEMPLATE.STATISTICS.QUESTION_CORRECTNESS_TITLE'),
      key: 'time',
      width: '35%',
      render: (record: IQuestionCorrectnessPercentage) => {
        const value = this.roundPercentage(record.correctnessPercentage);

        return <Progress percent={value}/>;
      },
    },
  ];

  roundPercentage = (percentage: number): number => {
    return +(percentage.toFixed(1));
  };

  render() {
    const examTemplateStatistics: IPieData[] = [
      {
        label: this.props.translate('EXAM_TEMPLATE.STATISTICS.CORRECTNESS_PERCENTAGE_INCORRECT'),
        value: this.roundPercentage(100 - this.props.examTemplateStatistics.correctnessPercentage),
      },
      {
        label: this.props.translate('EXAM_TEMPLATE.STATISTICS.CORRECTNESS_PERCENTAGE_CORRECT'),
        value: this.roundPercentage(this.props.examTemplateStatistics.correctnessPercentage),
      },
    ];

    const averageCompletionTimeInMs = moment().startOf('day').milliseconds(this.props.examTemplateStatistics.averageCompletionTimeInMs).format(timeFormat); // can't find a normal way to display time format from miliseconds

    return (
      <Row>
        <Col span={24}>
          <ViewSectionItem
            header={<HeaderTitle title={this.props.translate('EXAM_TEMPLATE.STATISTICS.STATISTICS_TITLE')} icon={<LemonIcon name="clipboard" size="large"/>}/>}
            collapsible={true}
            active={false}
            bordered={true}
          >
            <Row gutter={[16, 16]} align="middle" justify="space-between">
              <Col xs={24} lg={16}>
                <Typography.Title level={3}> {this.props.translate('EXAM_TEMPLATE.STATISTICS.QUIZ_CORRECTNESS_PERCENTAGE_TITLE')} </Typography.Title>
                <TimunPieChart data={examTemplateStatistics} percentage={true}/>
              </Col>

              <Col className="text-center" xs={24} lg={8}>
                <StatisticCardItem statisticalNumber={this.props.examTemplateStatistics.submittedExamCount} description={this.props.translate('EXAM_TEMPLATE.STATISTICS.SUBMITED_COUNT_TITLE')}/>
                <br/>
                <StatisticCardItem statisticalNumber={averageCompletionTimeInMs} description={this.props.translate('EXAM_TEMPLATE.STATISTICS.AVERAGE_COMPLETION_TIME_TITLE')}/>
              </Col>

              <Divider/>

              <Col span={24}>
                <Typography.Title level={3}> {this.props.translate('EXAM_TEMPLATE.STATISTICS.QUESTION_LIST_CORRECTNESS_PERCENTAGE_TITLE')} </Typography.Title>
                <Table
                  columns={this.columns}
                  dataSource={this.props.examTemplateStatistics.questionsStatistics}
                  rowKey={(record) => record.question.id}
                  pagination={false}
                />
              </Col>
            </Row>

          </ViewSectionItem>
        </Col>
      </Row>
    );
  }
}

// -- HOCs and exports
// ----------

export default withLocalize<IExamTemplateStatisticsPanelOwnProps>(ExamTemplateStatisticsPanel as any);
