import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

import AppContent from '@src/components/common/container/AppContent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import UserDetailsPanel from '@src/components/user/view/UserDetailsPanel';
import UserViewTabs from '@src/components/user/view/UserViewTabs';
import { IFile } from '@src/model/file/File';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import { ILemonEvent } from '@src/service/util/event/lemonEvent';
import RoleUtils from '@src/service/util/role/RoleUtils';

// -- Const
// ----------

// --
// ----- Prop types
interface IUserViewOwnProps {
  openedTab: string;
  currentUser: IUserInfo;
  user: IUserInfo;
  workPositionList: IWorkPosition[];
  onUserUpdate: () => void;
  onAvatarSubmit: (data: IFile) => void;
  onUserSubmit: (event: ILemonEvent<IUserInfo>) => void;
  onUserDelete: (data: IUserInfo) => void;
}

type IUserViewProps = IUserViewOwnProps & WithRouterProps & IWithLocalizeOwnProps & IWithTenantPropEnabledOwnProps & IWithRolesOwnProps;
// --
// ----- State types
interface IUserViewState {}

// --
// ----- Component
class UserView extends React.Component<IUserViewProps, IUserViewState> {
  state: IUserViewState = {};

  render = () => {
    const userId = this.props.user.id || this.props.currentUser.id;
    const isOwnProfile = this.props.currentUser.id === userId;
    const isTraineeProfile = this.props.user && RoleUtils.allowedRoles([UserRoleEnum.TRAINEE], this.props.user.roles);
    const isAdmin = RoleUtils.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN], this.props.currentUser.roles);
    const isCoordinator = RoleUtils.allowedRoles([UserRoleEnum.COORDINATOR], this.props.currentUser.roles);

    return (
      <React.Fragment>
        {this.props.user && (
          <AppContent>
            <UserDetailsPanel user={this.props.user} workPositionList={this.props.workPositionList} onAvatarSubmit={this.props.onAvatarSubmit} onUserSubmit={this.props.onUserSubmit} onUserUpdate={this.props.onUserUpdate} onUserDelete={this.props.onUserDelete} isOwnProfile={isOwnProfile} />

            <UserViewTabs
              userId={userId}
              user={this.props.user}
              showActivitiesTab={isOwnProfile || isAdmin}
              showDocumentsTab={this.props.isTenantPropEnabled('repository') && (isOwnProfile || isAdmin)}
              showEducationApplicationTab={isOwnProfile || isAdmin || isCoordinator}
              showCoursesTab={isTraineeProfile}
              showSkillTab={this.props.isTenantPropEnabled('admin_skillLevelClassification')}
              showOrganizationTab={this.props.allowedRoles([UserRoleEnum.ORGANIZATION_MEMBER])}
              showExternalEducationTemplatesTab={isCoordinator}
              openedTab={this.props.openedTab}
              workPositionList={this.props.workPositionList}
            />
          </AppContent>
        )}
      </React.Fragment>
    );
  };
}

export default withLocalize(withRouter<IUserViewOwnProps>(withTenantPropEnabled(withRoles(UserView) as any)));
