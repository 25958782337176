import { IQuizzlerFile } from '@lamarodigital/quizzler-lib-frontend/model/file/File';
import { IFile } from '@src/model/file/File';
import UrlBuilderFactory from '@src/service/util/UrlBuilderFactory';

/** Helper utils for handling file uploads. */
export class QuizHelperUtils {

  static getQuizzlerFile = (file: IFile): IQuizzlerFile => {
    return {
      id: file.id,
      name: file.name,
      mimeType: file.mimeType,
      size: file.size,
    };
  };
  static resolveFileUrl = (id: string) => {
    return UrlBuilderFactory.buildApiFileUrl(id);
  };
}
