import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

import AppContent from '@src/components/common/container/AppContent';
import ListPagination from '@src/components/common/list/ListPagination';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import AdminReportHeader from '@src/components/report/AdminReportHeader';
import ReportList from '@src/components/report/list/ReportList';
import { IReport, IReportTypeEnum } from '@src/model/report/Report';
import { ICollectionData, ICollectionFetchPayload, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import { IExternalEducationApplicationListFilter } from '@src/service/business/externalEducationApplication/ExternalEducationApplicationBusinessStore';
import { transformExternalEducationApplicationListFilter } from '@src/service/business/externalEducationApplication/util';
import ReportsBusinessStore, { IReportListFilter } from '@src/service/business/reports/reportsBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createActionThunk, IActionThunkMap } from '@src/service/util/action/thunk';

// -- Prop types
// ----------

export interface IAdminReportContainerOwnProps { }

export interface IAdminReportContainerStateProps {
  reportList: ICollectionData<IReport>;
  reportListFilter: IReportListFilter;
}

export interface IAdminReportContainerDispatchProps {
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
  fetchReportList: (params: ICollectionFetchPayload<IReportListFilter>) => any;
  createReport: (reportType: IReportTypeEnum, course: string | undefined, filters: IExternalEducationApplicationListFilter | undefined, thunkMap: IActionThunkMap) => void;
  deleteReport: (data: string, thunkMap: IActionThunkMap) => void;
}

type IAdminReportContainerProps = IAdminReportContainerDispatchProps & IAdminReportContainerStateProps & IAdminReportContainerOwnProps & WithRouterProps & IWithLocalizeOwnProps;

// -- State types
// ----------

export interface IAdminReportContainerState {
  page: number;
  size: number;
  sort: string;
}

// -- Component
// ----------

class AdminReportContainer extends React.Component<IAdminReportContainerProps, IAdminReportContainerState> {
  state = {
    page: 0,
    size: AppConfigService.getValue('api.paging.defaultPageSize'),
    sort: AppConfigService.getValue('api.sorting.byDate'),
  };

  componentDidMount() {
    // initial list fetch
    this.updateList();
  }

  componentDidUpdate = (prevProps: IAdminReportContainerProps, prevState: IAdminReportContainerState) => {
    if (this.state !== prevState) {
      this.updateList();
    }
  };

  render() {
    return (
      <AppContent>
        {/* Header */}
        <AdminReportHeader onCreateReport={this.handleCreateReport} onChangeSort={this.handleChangeSort} />

        {/* List */}
        {this.props.reportList && <ReportList reportList={this.props.reportList.content} pagination={this.props.reportList.page} onDelete={this.handleDeleteReport} onReload={this.updateList} />}

        {/* Pagination */}
        {this.props.reportList && <ListPagination page={this.props.reportList.page} onChange={this.handlePageChange} />}
      </AppContent>
    );
  }

  handleChangeSort = (sort: string) => {
    this.setState({ page: 0, sort });
  };

  handlePageChange = (page: number, pageSize?: number) => {
    this.setState({ page: page - 1, size: pageSize ? pageSize : this.state.size });
  };

  handleDeleteReport = (data: IReport) => {
    this.props.deleteReport(data.id, { success: () => this.updateList() });
  };

  handleCreateReport = (type: IReportTypeEnum, course?: string, filters?: IExternalEducationApplicationListFilter) => {
    const transformedFilter = filters && transformExternalEducationApplicationListFilter(filters);
    this.props.createReport(type, course, transformedFilter, {
      success: () => {
        this.props.reportMessage({ message: this.props.translate('REPORTS.CREATE_INFO_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.INFO });
        this.updateList();
      },
    });
  };

  private updateList = () => {
    this.props.fetchReportList({
      filter: { ...this.props.reportListFilter },
      page: this.state.page,
      size: this.state.size,
      sort: [this.state.sort],
    });
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IAdminReportContainerOwnProps): IAdminReportContainerStateProps => ({
  reportList: ReportsBusinessStore.selectors.getReportList(state),
  reportListFilter: ReportsBusinessStore.selectors.getReportListFilter(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IAdminReportContainerDispatchProps => ({
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
  fetchReportList: (params: ICollectionFetchPayload<IReportListFilter>) => dispatch(ReportsBusinessStore.actions.fetchReportList(params)),
  createReport: (reportType: IReportTypeEnum, course: string | undefined, filters: IExternalEducationApplicationListFilter | undefined, thunkMap: IActionThunkMap) => dispatch(createActionThunk(ReportsBusinessStore.actions.createReport(reportType, { id: course }, filters), thunkMap)),
  deleteReport: (data: string, thunkMap: IActionThunkMap) => dispatch(createActionThunk(ReportsBusinessStore.actions.deleteReport({ id: data }), thunkMap)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<IAdminReportContainerOwnProps>(withRouter(AdminReportContainer as any)));
