import { ISkillBasedRecommendationsListFilter } from '@src/service/business/skillbasedrecommendations/SkillBasedRecommendationsBusinessStore';
import ListFilterHelper, { ListFilterQuery } from '@src/service/util/filter/ListFilterHelper';

/** Transform User list filter to API filter */
export function transformSkillBasedRecommendationsFilter(filter: ISkillBasedRecommendationsListFilter): ListFilterQuery {
  return ListFilterHelper.simpleFilterToQueryMapper(filter, (filterQuery, name, filterValue) => {
    // process "id"

    if (name === 'id') {
      const value = filterValue[name]; // take filter value for current property

      if (value != null /* empty values should not be added to query */) {
        filterQuery[name] = value.id;
      }
      return true; // signal mapper that this prop has been manually processed
    }

    return false; // signal mapper that this prop has NOT been processed and mapper should apply default mapping
  });
}
