import FileUtils from '@src/components/common/file/FileUtils';
import { IListAdditionalCol } from '@src/components/common/list/ListAdditionalCol';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import RepositoryFileView from '@src/components/repository/file/RepositoryFileView';
import TagContainer from '@src/components/tag/TagContainer';
import { IPath } from '@src/model/common/Path';
import { IFile } from '@src/model/file/File';
import { TagItemTypeEnum } from '@src/model/tag/TagItemType';
import { ICollectionData } from '@src/service/business/common/types';
import FileListBusinessStore, { IFileListFilter } from '@src/service/business/files/FileListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createActionThunk, IActionThunkMap } from '@src/service/util/action/thunk';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import React from 'react';
import { connect } from 'react-redux';

const repoPageSize = AppConfigService.getValue('api.paging.midPageSize');

const rootFolder: IPath = FileUtils.getRootFolder();

// -- Prop types
// ----------

export interface IRepositoryFileContainerOwnProps {
  folderId?: string;
  currentTags: string[];
}

export interface IRepositoryFileContainerStateProps {
  fileList: ICollectionData<IFile>;
  fileListFilter: IFileListFilter;
}

export interface IRepositoryFileContainerDispatchProps {
  // fetchFileList: (folderId: string, listFilter: IFileListFilter, page: number, size: number, sort: string[]) => void;
  // fetchFolderInfo: (folderId: string, callback: IActionThunkMap) => void;
  addFile: (folderId: string, data: IFile[], callback: IActionThunkMap) => void;
  storeFileListFilter: (filter: IFileListFilter) => void;
  clearFileListFilter: () => void;
  fetchRepositoryFileList: (filter: IFileListFilter, page: number, size: number, sort: string[]) => void;
  clearFileList: () => void;
  deleteFile: (folderId: string, data: IFile[]) => ITrackableAction;
}

type IRepositoryFileContainerProps = IRepositoryFileContainerOwnProps & IRepositoryFileContainerStateProps & IRepositoryFileContainerDispatchProps & IWithLocalizeOwnProps;

interface IRepositoryFileContainerState {
  // currentFolder: Pick<IPath, 'id' | 'name'>;
  // routes: IPath;
  sort: string;
  page: number;
  size: number;
}

// -- Component
// ----------

/** Repository container component */
class RepositoryFileContainer extends React.Component<IRepositoryFileContainerProps, IRepositoryFileContainerState> {
  state: IRepositoryFileContainerState = {
    // currentFolder: rootFolder,
    // routes: rootFolder,
    sort: '',
    page: 0,
    size: repoPageSize,
  };

  componentDidMount = () => {
    this.props.storeFileListFilter({ ...this.props.fileListFilter, tags: this.props.currentTags });
  };

  componentDidUpdate = (prevProps: IRepositoryFileContainerProps, prevState: IRepositoryFileContainerState) => {
    if (this.props.currentTags !== prevProps.currentTags) {
      this.props.storeFileListFilter({ ...this.props.fileListFilter, tags: this.props.currentTags });
    }
    if ((this.props.fileListFilter !== prevProps.fileListFilter) || this.state.page !== prevState.page || this.state.size !== prevState.size) {
      this.updateRepositoryList();
    }
  };

  componentWillUnmount = () => {
    this.props.clearFileListFilter();
    this.props.clearFileList();
  };

  render = () => {
    return <RepositoryFileView
      fileList={this.props.fileList}
      fileListFilter={this.props.fileListFilter}
      renderAdditionalCols={this.additionalCols}
      onFilterChange={this.handleFilterChange}
      onPageChange={this.handlePageChange}
      onUpload={this.handleUploadFile}
      onDelete={this.handleDeleteFile}
    />;
  };

  handleFilterChange = (filter: IFileListFilter) => {
    this.props.storeFileListFilter(filter);
    this.setState({ page: 0 });
  };

  handlePageChange = (page: number, pageSize?: number) => {
    this.setState({ page: page - 1, size: pageSize ?? this.state.size });
  };

  handleUploadFile = (data: IFile) => {
    const folderId = rootFolder.id;
    this.props.addFile(folderId, [data], {
      success: () => {
        this.updateRepositoryList();
      },
    });
  };

  handleDeleteFile = (data: IFile) => {
    const folderId = rootFolder.id;
    this.props.deleteFile(folderId, [data]).track().subscribe(
      () => this.updateRepositoryList(),
    );
  };

  private additionalCols = (): IListAdditionalCol[] => {
    return [
      {
        key: 'tags',
        responsiveWidth: { lg: 10 },
        headerTitle: this.props.translate('REPOSITORY.TAG_HEADER_LABEL'),
        content: (file: IFile) => <TagContainer tags={file.tags} entityId={file.id} entityType={TagItemTypeEnum.FILE} onEntityTagUpdate={this.updateRepositoryList}/>,
      },
    ];
  };

  // private fetchFolder = (id: string) => {
  //   this.props.fetchFolderInfo(id, {
  //     success: (response) => {
  //       this.setState({
  //         routes: { ...response.fullPath },
  //         currentFolder: response,
  //       });
  //       this.updateRepositoryList(response.id);
  //     },
  //   });
  // };

  private updateRepositoryList = (filter: IFileListFilter = this.props.fileListFilter, page: number = this.state.page, size: number = this.state.size, sort: string = this.state.sort) => {
    this.props.fetchRepositoryFileList(filter, page, size, [sort]);
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IRepositoryFileContainerStateProps => ({
  fileList: FileListBusinessStore.selectors.getFileList(state),
  fileListFilter: FileListBusinessStore.selectors.getFileListFilter(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IRepositoryFileContainerDispatchProps => ({
  // fetchFileList: (folderId: string, listFilter: IFileListFilter, page: number, size: number, sort: string[]) => dispatch(FileListBusinessStore.actions.fetchFileList(folderId, listFilter, page, size, sort)),
  // fetchFolderInfo: (folderId: string, thunkMap: IActionThunkMap) => dispatch(createActionThunk(FileListBusinessStore.actions.fetchFolderInfo({ id: folderId }), thunkMap)),
  addFile: (folderId: string, data: IFile[], thunkMap: IActionThunkMap) => dispatch(createActionThunk(FileListBusinessStore.actions.addFile(folderId, data), thunkMap)),
  storeFileListFilter: (filter: IFileListFilter) => dispatch(FileListBusinessStore.actions.storeFileListFilter(filter)),
  clearFileListFilter: () => dispatch(FileListBusinessStore.actions.clearFileListFilter()),
  fetchRepositoryFileList: (filter: IFileListFilter, page: number, size: number, sort: string[]) => dispatch(FileListBusinessStore.actions.fetchRepositoryFileList(filter, page, size, sort)),
  clearFileList: () => dispatch(FileListBusinessStore.actions.clearFileList()),
  deleteFile: (folderId: string, file: IFile[]) => dispatch(createTrackableAction(FileListBusinessStore.actions.removeFile(folderId, file))),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<IRepositoryFileContainerOwnProps>(RepositoryFileContainer as any));
