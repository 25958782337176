import AppConfigService from '@src/service/common/AppConfigService';

/** Editor config is assembled from several parts and has several different configs, so this is one place where config should be read from. */
export class CrmEditorConfig {
  /** Default editor config */
  static defaultConfig(): Record<string, any> {
    return {
      ...AppConfigService.getValue('tinyEditor'),
      // LD formula editor config
      ldformulaeditor_api_key: AppConfigService.getValue('imatheq.apiKey'),
      ldformulaeditor_lang: AppConfigService.getValue('imatheq.lang'),
    };
  }
}
