import PublicCourseViewContainer from '@src/components/course/view/PublicCourseViewContainer';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------
export interface ICourseViewPagePublicProps { }
type ICourseViewPageProps = ICourseViewPagePublicProps & WithRouterProps;

// -- Component
// ----------
const PublicCourseViewPage = (props: ICourseViewPageProps) => {
  return <PublicCourseViewContainer courseId={props.params.courseId} />
};

export default withRouter<ICourseViewPagePublicProps>(PublicCourseViewPage);
