import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ISkillLevelClassificationShortInfo } from '@src/model/skillgroup/SkillLevelClassification';
import { Table } from 'antd';


// -- Prop types
// ----------

export interface ISkillLevelClassificationListOwnProps {
  skillLevelClassificationList: ISkillLevelClassificationShortInfo[];
  skillId: string;
}
type ISkillLevelClassificationListProps = ISkillLevelClassificationListOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Describe your component ... */
const SkillLevelClassificationList: React.FC<ISkillLevelClassificationListProps> = (props: ISkillLevelClassificationListProps) => {
  const skillLevelClassificationColumns = [
    {
      key: 'level',
      title: props.translate('SKILL_LEVEL_CLASSIFICATION_LIST.LEVEL_LABEL'),
      render: (record: ISkillLevelClassificationShortInfo) => record.skillLevel.level,
    },
    {
      key: 'levelName',
      title: props.translate('SKILL_LEVEL_CLASSIFICATION_LIST.LEVEL_NAME_LABEL'),
      render: (record: ISkillLevelClassificationShortInfo) => record.skillLevel.name,
    },
    {
      key: 'description',
      title: props.translate('SKILL_LEVEL_CLASSIFICATION_LIST.LEVEL_DESCRIPTION_LABEL'),
      render: (record: ISkillLevelClassificationShortInfo) => record.description,
    },
  ];


  return (
    <Table
      rowKey={(record) => record.skillLevel.id}
      columns={skillLevelClassificationColumns}
      dataSource={props.skillLevelClassificationList}
      pagination={false}
    />
  );
};

export default withLocalize<ISkillLevelClassificationListOwnProps>(SkillLevelClassificationList as any);
