import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

import { IQuestionOutcome } from '@lamarodigital/quizzler-lib-frontend/model/quiz/outcome/QuestionOutcome';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, IIdPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError, createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { startGlobalProgress, stopGlobalProgress, trackAction } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts
export interface IQuestionOutcomeCreatePayload {
  title: string;
}

export interface IQuestionOutcomeListFilter {
  title?: string;
}

// List filter ID
const QUESTION_OUTCOME_LIST_FILTER = '@@QUESTION_OUTCOME_LIST_FILTER';

// -
// -------------------- Selectors

/** Returns question outcome from store. */
const getQuestionOutcome = (store: any): IQuestionOutcome => store.questionOutcome;

/** Returns list of question outcomes from store. */
const getQuestionOutcomeList = (store: any): ICollectionData<IQuestionOutcome> => store.questionOutcomeList;

/** Returns question outcome list filter. */
const getQuestionOutcomeListFilter = (store: any): IQuestionOutcomeListFilter => ListFilterBusinessStore.selectors.getListFilter(store, QUESTION_OUTCOME_LIST_FILTER);

// -
// -------------------- Actions

const Actions = {
  QUESTION_OUTCOME_FETCH: 'QUESTION_OUTCOME_FETCH',
  QUESTION_OUTCOME_LOAD: 'QUESTION_OUTCOME_LOAD',
  QUESTION_OUTCOME_CLEAR: 'QUESTION_OUTCOME_CLEAR',
  QUESTION_OUTCOME_CREATE: 'QUESTION_OUTCOME_CREATE',
  QUESTION_OUTCOME_UPDATE: 'QUESTION_OUTCOME_UPDATE',
  QUESTION_OUTCOME_LIST_FETCH: 'QUESTION_OUTCOME_LIST_FETCH',
  QUESTION_OUTCOME_LIST_LOAD: 'QUESTION_OUTCOME_LIST_LOAD',
  QUESTION_OUTCOME_LIST_CLEAR: 'QUESTION_OUTCOME_LIST_CLEAR',
};

/** Fetch question outcome by ID. */
const fetchQuestionOutcome = (params: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.QUESTION_OUTCOME_FETCH,
    payload: params,
  };
};

/** Load question outcome to store. */
const loadQuestionOutcome = (data: IQuestionOutcome): IPayloadAction<IQuestionOutcome> => {
  return {
    type: Actions.QUESTION_OUTCOME_LOAD,
    payload: data,
  };
};

/** Clear question outcome from store. Eg. when leaving view. */
const clearQuestionOutcomeData = (): ILemonAction => {
  return {
    type: Actions.QUESTION_OUTCOME_CLEAR,
  };
};

/** Create new question outcome. */
const createQuestionOutcome = (data: IQuestionOutcomeCreatePayload): IPayloadAction<IQuestionOutcomeCreatePayload> => {
  return {
    type: Actions.QUESTION_OUTCOME_CREATE,
    payload: data,
  };
};

/** Update question outcome by ID. */
const updateQuestionOutcome = (data: IQuestionOutcome): IPayloadAction<IQuestionOutcome> => {
  return {
    type: Actions.QUESTION_OUTCOME_UPDATE,
    payload: data,
  };
};

/** Fetch question outcome list by filter. */
const fetchQuestionOutcomeList = (params: ICollectionFetchPayload<IQuestionOutcomeListFilter>): IPayloadAction<ICollectionFetchPayload<IQuestionOutcomeListFilter>> => {
  return {
    type: Actions.QUESTION_OUTCOME_LIST_FETCH,
    payload: params,
  };
};

/** Load question outcome list to store. */
const loadQuestionOutcomeList = (data: ICollectionData<IQuestionOutcome>): IPayloadAction<ICollectionData<IQuestionOutcome>> => {
  return {
    type: Actions.QUESTION_OUTCOME_LIST_LOAD,
    payload: data,
  };
};

/** Clear question outcome list from store. Eg. when leaving list view. */
const clearQuestionOutcomeList = (): ILemonAction => {
  return {
    type: Actions.QUESTION_OUTCOME_LIST_CLEAR,
  };
};

/** Store question outcome list filter to store. */
const storeQuestionOutcomeListFilter = (listFilter: IQuestionOutcomeListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(QUESTION_OUTCOME_LIST_FILTER, listFilter);
};

// -
// -------------------- Side-effects

const fetchQuestionOutcomeEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.QUESTION_OUTCOME_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('Outcome')
        .fetchEntity(id)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadQuestionOutcome(data);
    }),

    // reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'Outcome_VIEW.ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),
    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching QuestionOutcome', error);
      return o;
    })
  );
};

const createQuestionOutcomeEffect = (action$: Observable<IPayloadAction<IQuestionOutcomeCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.QUESTION_OUTCOME_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Outcome')
        .createEntity(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadQuestionOutcome(data);
    }),

    // reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'Outcome_CREATE.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),
    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating QuestionOutcome', error);
      return o;
    })
  );
};

const updateQuestionOutcomeEffect = (action$: Observable<IPayloadAction<IQuestionOutcome>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.QUESTION_OUTCOME_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('Outcome')
        .updateEntity(data.id, data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadQuestionOutcome(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'OUTCOME_CREATE.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating QuestionOutcome', error);
      return o;
    })
  );
};

const fetchQuestionOutcomeListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<IQuestionOutcomeListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.QUESTION_OUTCOME_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Outcome')
        .fetchEntityList(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadQuestionOutcomeList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching QuestionOutcome list', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const questionOutcome = (state: IQuestionOutcome | null = null, action: IPayloadAction<IQuestionOutcome>) => {
  if (action.type === Actions.QUESTION_OUTCOME_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.QUESTION_OUTCOME_CLEAR) {
    return null;
  }

  return state;
};

const questionOutcomeList = (state: ICollectionData<IQuestionOutcome> | null = null, action: IPayloadAction<ICollectionData<IQuestionOutcome>>) => {
  if (action.type === Actions.QUESTION_OUTCOME_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.QUESTION_OUTCOME_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const questionOutcomeListBusinessStore = {
  actions: {
    fetchQuestionOutcome,
    loadQuestionOutcome,
    clearQuestionOutcomeData,
    createQuestionOutcome,
    updateQuestionOutcome,
    fetchQuestionOutcomeList,
    loadQuestionOutcomeList,
    clearQuestionOutcomeList,
    storeQuestionOutcomeListFilter,
  },

  selectors: {
    getQuestionOutcome,
    getQuestionOutcomeList,
    getQuestionOutcomeListFilter,
  },

  effects: {
    fetchQuestionOutcomeEffect,
    createQuestionOutcomeEffect,
    updateQuestionOutcomeEffect,
    fetchQuestionOutcomeListEffect,
  },

  reducers: {
    questionOutcome,
    questionOutcomeList,
  },
};

// --
// export business store
export default questionOutcomeListBusinessStore;
