import { Form } from '@ant-design/compatible';

import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { SearchOutlined } from '@ant-design/icons';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { CourseStatusEnum } from '@src/model/course/Course';
import { ICourseGroup } from '@src/model/course/CourseGroup';
import { ICourseListFilter } from '@src/service/business/courses/courseListBusinessStore';
import { IPublicCourseListFilter } from '@src/service/business/courses/publicCourseListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { Col, Input, Row, Select } from 'antd';
import { debounce } from 'lodash';
import React from 'react';

const debounceTimeout = AppConfigService.getValue('components.common.debounceTimeout');

const sortItems = [
  {
    id: 'title,asc',
    translationKey: 'COMMON.ACTION_SORT_ASCEND',
  },
  {
    id: 'title,desc',
    translationKey: 'COMMON.ACTION_SORT_DESCEND',
  },
  {
    id: 'createdAt,desc',
    translationKey: 'COMMON.ACTION_SORT_NEWEST',
  },
  {
    id: 'createdAt,asc',
    translationKey: 'COMMON.ACTION_SORT_OLDEST',
  },
];

// -- Prop types
// ----------

interface ICourseListViewFilterOwnProps {
  courseGroups?: ICourseGroup[];
  courseListFilter: ICourseListFilter;
  sortValue?: string[];
  showStatusFilter?: boolean;
  onSortChange?: (sort: string[]) => void;
  onFilterChange?: (data: IPublicCourseListFilter) => void;
}

type ICourseListViewFilterProps = ICourseListViewFilterOwnProps & IWithLocalizeOwnProps & FormComponentProps;

// -- Component
// ----------

/**  */
class CourseListViewFilter extends React.Component<ICourseListViewFilterProps> {

  handleStatusChange = debounce((data: CourseStatusEnum) => {
    this.props.onFilterChange?.({ ...this.props.courseListFilter, status: data });
  }, debounceTimeout);
  handleTitleSearch = (value: string) => {
    if (value.trim()) {
      this.props.onFilterChange?.({ ...this.props.courseListFilter, title: value.trim() });
    } else {
      const { title, ...filter } = this.props.courseListFilter;
      this.props.onFilterChange?.(filter);
    }
  };


  render = () => {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form layout="vertical">
        <Row gutter={16} className="timun-wrapper__gutterBox--vertical">
          <Col xs={24} sm={12} md={this.props.showStatusFilter ? 6 : 8}>
            <Form.Item label={this.props.translate('COMMON.LABEL.NAME')}>
              {getFieldDecorator('title', {
                initialValue: this.props.courseListFilter?.title,
              })(
                <Input.Search prefix={<SearchOutlined/>} allowClear={true} placeholder={this.props.translate('COMMON.ACTION_SEARCH')} onSearch={this.handleTitleSearch}/>,
              )}
            </Form.Item>
          </Col>
          <Col xs={24} sm={12} md={this.props.showStatusFilter ? 6 : 8}>
            <Form.Item label={this.props.translate('COURSE_VIEW.UPDATE.COURSE_GROUP_SELECT_FORM_LABEL')}>
              {getFieldDecorator('courseGroup', {
                initialValue: this.props.courseListFilter?.courseGroups,
              })(
                <Select mode="multiple" placeholder={this.props.translate('COURSE_VIEW_PUBLIC.ALL_COURSEGROUPS_LABEL')} allowClear={true} onChange={this.handleGroupChange}
                        data-test-id="timun-courseListFilter__courseGroupSelect">
                  {(this.props.courseGroups || []).map((group) => {
                    return (
                      <Select.Option key={group.id} value={group.id} data-test-id={`timun-courseListFilter__courseGroupSelect_option_${group.id}`}>
                        {group.title}
                      </Select.Option>
                    );
                  })}
                </Select>,
              )}
            </Form.Item>
          </Col>
          {this.props.showStatusFilter &&
          <Col xs={24} sm={12} md={6}>
            <Form.Item label={this.props.translate('COMMON.LABEL.STATUS')}>
              {getFieldDecorator('status', {
                initialValue: this.props.courseListFilter?.status,
              })(
                <Select className="full-width" placeholder={this.props.translate('COURSE_VIEW_PUBLIC.ALL_STATUSES_LABEL')} allowClear={true} onChange={this.handleStatusChange}>
                  {Object.keys(CourseStatusEnum).map((item: string) => (
                    <Select.Option value={CourseStatusEnum[item as keyof typeof CourseStatusEnum]} key={CourseStatusEnum[item as keyof typeof CourseStatusEnum]}>
                      {this.props.translate(`COURSE.STATUS_TITLE.${item}`)}
                    </Select.Option>
                  ))}
                </Select>,
              )}
            </Form.Item>
          </Col>
          }
          <Col xs={24} sm={12} md={this.props.showStatusFilter ? 6 : 8}>
            <Form.Item label={this.props.translate('COMMON.ACTION_SORT_LABEL')}>
              <Select onChange={this.props.onSortChange} value={this.props.sortValue || [sortItems[0].id]} data-test-id="timun-courseListFilter__sortSelect">
                {sortItems.map((item) => {
                  return (
                    <Select.Option key={item.id} value={item.id} data-test-id={`timun-courseListFilter__sortSelect_option_${item.id}`}>
                      {this.props.translate(item.translationKey)}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };

  handleGroupChange = (data: string[]) => {
    this.props.onFilterChange?.({ courseGroups: data });
  };
}

const CourseListViewFilterForm = Form.create()(CourseListViewFilter);

// -- HOCs and exports
// ----------

export default withLocalize<ICourseListViewFilterOwnProps>(CourseListViewFilterForm as any);
