import AppContent from '@src/components/common/container/AppContent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import DashboardNotificationList from '@src/components/notification/list/DashboardNotificationList';
import { INotification } from '@src/model/notification/Notification';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import notificationListBusinessStore, {
  INotificationListFilter
} from '@src/service/business/notification/notificationListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { Col, Row, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------

export interface IDashboardNotificationListContainerOwnProps { }
export interface IDashboardNotificationListContainerStateProps {
  notifications: ICollectionData<INotification>;
  notificationListFilter: INotificationListFilter;
}
export interface IDashboardNotificationListContainerDispatchProps {
  fetchNotificationList: (params: ICollectionFetchPayload<INotificationListFilter>) => any;
  clearNotificationList: () => any;
}
type IDashboardNotificationListContainerProps = IDashboardNotificationListContainerOwnProps & IDashboardNotificationListContainerStateProps & IDashboardNotificationListContainerDispatchProps & IWithLocalizeOwnProps & WithRouterProps;

interface IDashboardNotificationListContainerState {
  page: number;
  size: number;
  sort: string;
}

// -- Component
// ----------

/** Dashboard for Admin */
class DashboardNotificationListContainer extends React.Component<IDashboardNotificationListContainerProps, IDashboardNotificationListContainerState> {
  state: IDashboardNotificationListContainerState = {
    page: 0,
    size: AppConfigService.getValue('api.paging.smallPageSize'),
    sort: '',
  };

  componentDidMount = () => {
    this.fetchNotifications();
  };

  componentWillUnmount = () => {
    this.props.clearNotificationList();
  };

  render = () => {
    return (
      <AppContent title={
        <Row justify="space-between">
          <Col>
            {this.props.translate('NOTIFICATIONS.NOTIFICATIONS')}
          </Col>
          <Col>
            <Typography.Title level={3}>
              <Link to="/notifications" onClick={this.handleDisplayAllNotifications}>{this.props.translate('NOTIFICATIONS.SHOW_ALL')}</Link>
            </Typography.Title>
          </Col>
        </Row>
      }>
        <DashboardNotificationList notifications={this.props.notifications ? this.props.notifications.content : []} />
      </AppContent>
    );
  };

  fetchNotifications = () => {
    this.props.fetchNotificationList({
      filter: { ...this.props.notificationListFilter },
      page: this.state.page,
      size: this.state.size,
      sort: [this.state.sort],
    });
  };

  handleDisplayAllNotifications = () => {
    this.props.router.push('/notifications');
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IDashboardNotificationListContainerStateProps => ({
  notifications: notificationListBusinessStore.selectors.getNotificationList(state),
  notificationListFilter: notificationListBusinessStore.selectors.getNotificationListFilter(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IDashboardNotificationListContainerDispatchProps => ({
  fetchNotificationList: (params: ICollectionFetchPayload<INotificationListFilter>) => dispatch(notificationListBusinessStore.actions.fetchNotificationList({ ...params })),
  clearNotificationList: () => dispatch(notificationListBusinessStore.actions.clearNotificationList()),
});

export default connect<IDashboardNotificationListContainerStateProps, IDashboardNotificationListContainerDispatchProps, IDashboardNotificationListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(withRouter(DashboardNotificationListContainer as any)));
