import ExamInstanceContainer from '@src/components/exam/examInstance/ExamInstanceContainer';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------
type IExamInstancePageProps = WithRouterProps;

const ExamInstancePage = (props: IExamInstancePageProps) => <ExamInstanceContainer examInstanceId={props.params.examId} />;

export default withRouter<IExamInstancePageProps>(ExamInstancePage);
