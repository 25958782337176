import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ReminderCalendar from '@src/components/reminder/calendar/ReminderCalendar';
import ReminderCreateContainer from '@src/components/reminder/ReminderCreateContainer';
import ReminderEditForm from '@src/components/reminder/ReminderEditForm';
import ReminderList from '@src/components/reminder/ReminderList';
import { IReminder, ReminderTypeEnum } from '@src/model/reminder/Reminder';
import { IUserInfo } from '@src/model/user/User';
import { ICollectionData } from '@src/service/business/common/types';
import { IReminderListFilter } from '@src/service/business/reminder/reminderListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { ITrackableAction } from '@src/service/util/action/trackAction';
import { Col, Row, Tabs } from 'antd';
import moment, { Moment } from 'moment';
import React from 'react';

const backendDate = AppConfigService.getValue('dateTimeFormat.backendDate');
const dayOfWeekName = AppConfigService.getValue('dateTimeFormat.dayOfWeekName');
const date = AppConfigService.getValue('dateTimeFormat.date');

enum ReminderCalendarTabKeys {
  UPCOMING = '1',
  SELECTED = '2',
}

// -- Prop types
// ----------

export interface IReminderCalendarViewOwnProps {
  reminderList: ICollectionData<IReminder>;
  selectedReminderList?: ICollectionData<IReminder>;
  currentUser: IUserInfo;
  reminderFilter?: IReminderListFilter;

  onLoadMore: () => void;
  onUpdateReminder: (reminder: IReminder) => ITrackableAction;
  onDeleteReminder: (reminder: IReminder) => void;
  onCreateReminder: () => void;
  onUpdateFilter: (filter: IReminderListFilter) => void;
  onSelectedChange: (filter: IReminderListFilter) => void;
}

type IReminderCalendarViewProps = IReminderCalendarViewOwnProps & IWithLocalizeOwnProps;

interface IReminderCalendarViewState {
  month: Moment;
  selectedDate?: Moment;
  isEditing: boolean;
  reminder?: IReminder;

}

// -- Component
// ----------

/** Reminder calendar component view ... */
class ReminderCalendarView extends React.Component<IReminderCalendarViewProps, IReminderCalendarViewState> {
  state: IReminderCalendarViewState = {
    month: this.props.reminderFilter?.startDateTime ? moment(this.props.reminderFilter.startDateTime) : moment(),
    selectedDate: undefined,
    isEditing: false,
    reminder: undefined,
  };

  componentDidMount = () => {
    this.handleCalendarDateSelect(moment()); // fetch the list for today
  };

  componentDidUpdate = (prevProps: IReminderCalendarViewProps, prevState: IReminderCalendarViewState) => {
    if (this.state.month.month() !== prevState.month.month()) {
      this.updateReminderListFilter();
    }
  };

  render = () => {
    console.log(this.state.month);
    const upcomingMonth = moment(this.state.month).month() + 1;
    const selectedDateTabLabel = `${this.state.selectedDate?.format(dayOfWeekName)}, ${this.state.selectedDate?.format(date)}`;

    return <React.Fragment>
      <Row>
        <Col span={24} className="timun-reminderCalendar__calendar">
          <ReminderCalendar reminderList={this.props.reminderList?.content} selectedDate={this.state.selectedDate} selectedMonth={this.state.month} onCalendarDateSelect={this.handleCalendarDateSelect} onCalendarDateChange={this.handleCalendarDateChange}/>
        </Col>
        <Col>
          <ReminderCreateContainer showCreateButton={true} user={this.props.currentUser} reminderType={ReminderTypeEnum.USER_DEFINED} onReminderCreate={this.props.onCreateReminder}/>
        </Col>
        <Col span={24} className="timun-reminderCalendar__reminderList">
          <Tabs defaultActiveKey={ReminderCalendarTabKeys.UPCOMING}>
            <Tabs.TabPane key={ReminderCalendarTabKeys.UPCOMING} tab={this.props.translate('REMINDER_CALENDAR.UPCOMING_EVENTS', { month: upcomingMonth })}>
              {this.props.reminderList && <ReminderList reminderList={this.props.reminderList} onReminderDelete={this.props.onDeleteReminder} onReminderUpdate={this.handleReminderUpdate} onLoadMore={this.props.onLoadMore}/>}
            </Tabs.TabPane>
            <Tabs.TabPane key={ReminderCalendarTabKeys.SELECTED} tab={selectedDateTabLabel}>
              {this.props.selectedReminderList && <ReminderList reminderList={this.props.selectedReminderList} onReminderDelete={this.props.onDeleteReminder} onReminderUpdate={this.handleReminderUpdate}/>}
            </Tabs.TabPane>
          </Tabs>
        </Col>
      </Row>
      {this.state.isEditing && this.state.reminder && <ReminderEditForm reminder={this.state.reminder} onCancel={this.closeModal} onSubmit={this.handleSubmit}/>}
    </React.Fragment>;
  };

  handleSubmit = (reminder: IReminder) => {
    this.props.onUpdateReminder(reminder).track().subscribe(
      () => this.closeModal(),
    );
  };

  handleReminderUpdate = (reminder: IReminder) => {
    this.setState({
      reminder,
    });
    this.openModal();
  };

  handleCalendarDateChange = (newDate?: Moment) => {
    if (newDate != null) {
      const month = newDate.month();
      const year = newDate.year();
      const day = newDate.date();

      if (this.state.month.date() !== day || this.state.month.month() !== month || this.state.month.year() !== year) {
        this.setState({
          month: moment(newDate),
        });
      }
    }
  };

  handleCalendarDateSelect = (newDate?: Moment) => {
    const { selectedDate } = this.state;

    if (newDate) {
      if (newDate.date() !== selectedDate?.date() || newDate.month() !== selectedDate?.month()) {
        this.setState({
          selectedDate: moment(newDate),
        });

        const filter: IReminderListFilter = {
          startDateTime: newDate.startOf('day').format(backendDate),
          endDateTime: newDate.endOf('day').format(backendDate),
        };

        this.props.onSelectedChange(filter);
      }
    }
  };

  updateReminderListFilter = () => {
    const filter: IReminderListFilter = {
      startDateTime: this.state.month.startOf('month').format(backendDate),
      endDateTime: this.state.month.endOf('month').format(backendDate),
    };

    this.props.onUpdateFilter(filter);
  };

  closeModal = () => {
    this.setState({
      isEditing: false,
    });
  };

  openModal = () => {
    this.setState({
      isEditing: true,
    });
  };

}

// -- HOCs and exports
// ----------

export default withLocalize<IReminderCalendarViewOwnProps>(ReminderCalendarView as any);
