import React from 'react';
import { connect } from 'react-redux';

import PublicWelcomeView from '@src/components/home/PublicWelcomeView';
import ITenantOrganizationConfiguration from '@src/model/tenant/TenantOrganizationConfiguration';
import { PublicTenantOrganizationConfigurationBusinessStore } from '@src/service/business/tenant/publicTenantOrganizationConfigurationService';

// -- Prop types
// ----------

export interface IPublicWelcomeContainerOwnProps {}

export interface IPublicWelcomeContainerStateProps {
  tenantOrganizationConfiguration: ITenantOrganizationConfiguration;
}

type PublicWelcomeContainerProps = IPublicWelcomeContainerOwnProps & IPublicWelcomeContainerStateProps;

// -- Component
// ----------

const PublicWelcomeContainer: React.FC<PublicWelcomeContainerProps> = (props) => {
  return <PublicWelcomeView tenantOrganizationConfiguration={props.tenantOrganizationConfiguration} />;
};

// -- HOCs and exports
// ----------

// we're doing redux connect inside because we need to connect wrapper role and not the HOC
const mapStateToProps = (state: any): IPublicWelcomeContainerStateProps => ({
  tenantOrganizationConfiguration: PublicTenantOrganizationConfigurationBusinessStore.selectors.getTenantOrganizationConfiguration(state),
});

export default connect<IPublicWelcomeContainerStateProps, undefined, IPublicWelcomeContainerOwnProps>(mapStateToProps)(PublicWelcomeContainer as any);
