import CoursesTreeModalContainer from '@src/components/administration/common/CoursesTreeModalContainer';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import EducationApplicationReportModal from '@src/components/externalEducationApplication/filter/EducationApplicationReportListFilter';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import IIdRef from '@src/model/common/IdRef';
import { IReportTypeEnum } from '@src/model/report/Report';
import { IExternalEducationApplicationListFilter } from '@src/service/business/externalEducationApplication/ExternalEducationApplicationBusinessStore';
import { Button, Col, Dropdown, Menu, Row, Select, Typography } from 'antd';
import React from 'react';

const Item = Menu.Item;

const sortItems = [
  {
    id: 'requestedDateTime,desc',
    translationKey: 'COMMON.ACTION_SORT_NEWEST',
  },
  {
    id: 'requestedDateTime,asc',
    translationKey: 'COMMON.ACTION_SORT_OLDEST',
  },
];

// -- Prop types
// ----------

export interface IAdminReportHeaderOwnProps {
  onCreateReport: (type: IReportTypeEnum, course?: string, filters?: IExternalEducationApplicationListFilter) => void;
  onChangeSort: (sort: string) => void;
}

// -- State types
// ----------

export interface IAdminReportHeaderState {
  isCourseTreeModalVisible: boolean;
  isEducationApplicationReportModalVisible: boolean;
}

type IAdminReportHeaderProps = IAdminReportHeaderOwnProps & IWithLocalizeOwnProps & IWithTenantPropEnabledOwnProps;

// -- Component
// ----------

class AdminReportHeader extends React.Component<IAdminReportHeaderProps, IAdminReportHeaderState> {
  state = {
    isCourseTreeModalVisible: false,
    isEducationApplicationReportModalVisible: false,
  };

  menu = (): React.ReactElement => {
    return (
      <Menu>
        {this.props.isTenantPropEnabled('courses') && (
          <Item>
            <a onClick={() => this.props.onCreateReport(IReportTypeEnum.GENERAL_COURSE)}>{this.props.translate('REPORTS_LIST.TYPE_GENERAL_COURSE')}</a>
          </Item>
        )}

        {this.props.isTenantPropEnabled('courses') && (
          <Item>
            <a onClick={() => this.toggleCourseTreeModal()}>{this.props.translate('REPORTS_LIST.TYPE_COURSE')}</a>
          </Item>
        )}

        {this.props.isTenantPropEnabled('courses') && (
          <Item>
            <a onClick={() => this.props.onCreateReport(IReportTypeEnum.COURSE_TRAINEE)}>{this.props.translate('REPORTS_LIST.TYPE_COURSE_TRAINEE')}</a>
          </Item>
        )}

        {this.props.isTenantPropEnabled('externaleducationapplications') && <Item>
          <a onClick={() => this.toggleEducationApplicationReportModal()}>{this.props.translate('REPORTS_LIST.TYPE_EXTERNAL_EDUCATION_APPLICATION')}</a>
        </Item>}
      </Menu>
    );
  };

  render() {
    return (
      <div className="timun-adminReportsHeader">
        <Row justify="space-between" gutter={[16, 16]}>
          <Col>
            <Typography.Text>{this.props.translate('COMMON.ACTION_SORT_LABEL')}:&nbsp;</Typography.Text>
            <Select defaultValue={sortItems[0].id} onChange={this.props.onChangeSort}>
              {sortItems.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {this.props.translate(item.translationKey)}
                </Select.Option>
              ))}
            </Select>
          </Col>

          <Col>
            <Dropdown overlay={this.menu()} trigger={['click']}>
              <Button type="primary">{this.props.translate('REPORTS_LIST.CREATE_REPORT_BUTTON_LABEL')}</Button>
            </Dropdown>
          </Col>
        </Row>
        {this.state.isEducationApplicationReportModalVisible && (
          <EducationApplicationReportModal title={this.props.translate('REPORTS_LIST.EDUCATION_APPLICATION_MODAL_PICKER')} okText={this.props.translate('REPORTS_LIST.CREATE_REPORT_BUTTON_LABEL')} onCancel={this.toggleEducationApplicationReportModal} onSubmit={this.handleCreateApplicationReportSubmit}/>
        )}
        {this.state.isCourseTreeModalVisible && <CoursesTreeModalContainer modalTitle={this.props.translate('REPORTS_LIST.COURSES_MODAL_PICKER')} isPicker={true} onSubmit={this.handleCreateCourseReportSubmit} onCloseModal={this.toggleCourseTreeModal}/>}
      </div>
    );
  }

  handleCreateCourseReportSubmit = (data: Array<IIdRef<string>>) => {
    this.toggleCourseTreeModal();
    this.props.onCreateReport(IReportTypeEnum.COURSE, data[0].id);
  };

  handleCreateApplicationReportSubmit = (filters: IExternalEducationApplicationListFilter) => {
    this.toggleEducationApplicationReportModal();
    this.props.onCreateReport(IReportTypeEnum.EXTERNAL_EDUCATION_APPLICATION, undefined, filters);
  };

  toggleEducationApplicationReportModal = () => {
    this.setState({ isEducationApplicationReportModalVisible: !this.state.isEducationApplicationReportModalVisible });
  };

  toggleCourseTreeModal = () => {
    this.setState({
      isCourseTreeModalVisible: !this.state.isCourseTreeModalVisible,
    });
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<IAdminReportHeaderOwnProps>(withTenantPropEnabled(AdminReportHeader as any));
