import { IPath } from '@src/model/common/Path';
import { IFile } from '@src/model/file/File';
import { IFileSystemElement } from '@src/model/file/FileSystemElement';
import IFolder from '@src/model/file/Folder';
import UrlBuilderFactory from '@src/service/util/UrlBuilderFactory';
import { UploadFile } from 'antd/lib/upload/interface';

export type ITimunFile = UploadFile<IFile>;

export default class FileUtils {
  static isFolder = (arg: IFileSystemElement): arg is IFolder => {
    return arg.mimeType === 'application/vnd.lemon.folder';
  };

  static isFile = (arg: IFileSystemElement): arg is IFile => {
    return arg.mimeType !== 'application/vnd.lemon.folder';
  };

  static isFileImage(file: IFile) {
    return file.mimeType.startsWith('image/');
  }

  static getFileLink = (file: IFile) => {
    return UrlBuilderFactory.buildApiFileUrl(file.id);
  };

  static getRootFolder = (): IPath => {
    return { id: 'root', name: 'root', child: null };
  };

  static isFilePreviewable = (fileType?: string): boolean => {
    if (fileType?.startsWith('image') || fileType?.startsWith('video') || fileType?.startsWith('audio') || fileType?.includes('pdf')) {
      return true;
    } else {
      return false;
    }
  };

  static mapToTimunFile = (file: IFile): ITimunFile => {
    return {
      uid: file.id,
      name: file.name,
      type: file.mimeType,
      size: file.size,
      url: UrlBuilderFactory.buildApiFileUrl(file.id),
      status: 'done', // required for correct actions rendering (eg. download button)
    };
  };

  static mapToTimunFileList = (files: IFile[]): ITimunFile[] => {
    return files.map((file: IFile) => {
      return FileUtils.mapToTimunFile(file);
    });
  };
}
