
import LemonAvatar from '@src/components/common/image/LemonAvatar';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import { UserNameView } from '@src/components/common/view/UserNameView';
import { IUserGroup } from '@src/model/usergroup/UserGroup';
import { IUserGroupHierarchy } from '@src/model/usergroup/UserGroupHierarchy';

import { IUserGroupMember } from '@src/model/usergroup/UserGroupMember';
import { UserGroupMemberRoleEnum } from '@src/model/usergroup/UserGroupMemberRole';
import { Button, Col, Divider, Row, Tooltip, Typography } from 'antd';
import React from 'react';
import { Link } from 'react-router';

// -- Const
// ----------


// -- Prop types
// ----------

export interface IUserGroupHierarchyItemOwnProps {
  basePath: string;
  userGroupHierarchy: IUserGroupHierarchy;
  canEdit?: boolean;
  canAdd?: boolean;
  onUserGroupEdit?: (userGroup: IUserGroup) => void;
  onUserGroupHierarchyAdd?: (parentUserGroup: IUserGroup) => void;
}

type IUserGroupHierarchyItemProps = IUserGroupHierarchyItemOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

interface IUserGroupHierarchyItemState { }

// -- Component
// ----------

class UserGroupHierarchyItem extends React.Component<IUserGroupHierarchyItemProps, IUserGroupHierarchyItemState> {
  state = {};

  render() {
    const groupAdmins = this.getGroupAdmins(this.props.userGroupHierarchy);

    return (
      <Row className="timun_userGroupHierarchy__groupCard" >
        <Col span={24} key="title">
          <Row justify="center">
            <Col>
              <Tooltip overlay={this.props.userGroupHierarchy.userGroup.name}>
                <Typography.Title level={4}>

                  <Link to={this.getPath(this.props.userGroupHierarchy.userGroup.id)}> {this.props.userGroupHierarchy.userGroup.name}</Link>
                </Typography.Title>
              </Tooltip>
            </Col>
          </Row>
        </Col>
        {groupAdmins.map((admin) => {
          return (
            <Col span={24} key={admin.id}>
              <Row justify="start" align="middle" gutter={8} >
                <Col>
                  <LemonAvatar size="xsmall" imagePath={admin.user.profileImageUrl} fallbackIconName="user" />
                </Col>
                <Col>
                  <Typography.Text type="secondary"><UserNameView user={admin.user} /></Typography.Text>
                </Col>
              </Row>
            </Col>
          );
        })
        }
        {(this.props.canEdit || this.props.canAdd) &&
          <React.Fragment>
            <Divider className="timun_userGroupHierarchy__actionsDivider" />
            <Col span={24} key="actions">
              <Row justify="space-around" align="middle">
                {this.props.canEdit &&
                  <Col>
                    <Button icon={<LemonIcon key="edit" name="edit" />} onClick={() => this.props.onUserGroupEdit?.(this.props.userGroupHierarchy.userGroup)} />
                  </Col>
                }
                {(this.props.canEdit && this.props.canAdd) &&
                  <Divider type="vertical" />
                }
                {this.props.canAdd &&
                  <Col>
                    <Button icon={<LemonIcon key="add" name="plusCircle" />} onClick={() => this.props.onUserGroupHierarchyAdd?.(this.props.userGroupHierarchy.userGroup)} />
                  </Col>
                }
              </Row>
            </Col>
          </React.Fragment>
        }
      </Row>
    );
  }

  getPath = (id: string) => {
    return id ? `${this.props.basePath}/${id}` : this.props.basePath;
  };

  getGroupAdmins = (group: IUserGroupHierarchy): IUserGroupMember[] => {
    return group.members.filter((member) => member.role.id === UserGroupMemberRoleEnum.ADMIN);
  };

}

// -- HOCs and exports
// ----------

export default withRoles(withLocalize<IUserGroupHierarchyItemOwnProps>(UserGroupHierarchyItem as any));
