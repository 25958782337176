import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import UserGroupFilesContainer from '@src/components/usergroup/file/UserGroupFilesContainer';
import UserGroupMemberContainer from '@src/components/usergroup/members/UserGroupMemberContainer';
import CourseWorkpositionTreeContainer from '@src/components/usergroup/update/CourseWorkpositionTreeContainer';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { IUserGroup } from '@src/model/usergroup/UserGroup';
import { Tabs } from 'antd';
import React from 'react';

// -- Const
// ----------
const { TabPane } = Tabs;

export enum UserGroupContentNavigationMenuKeys {
  MEMEBERS = 'members',
  MATERIALS = 'materials',
  COURSES = 'courses',
}

// -- Prop types
// ----------

export interface IUserGroupTabsViewOwnProps {
  userGroup: IUserGroup;
}

type UserGroupTabsViewProps = IUserGroupTabsViewOwnProps & IWithLocalizeOwnProps & IWithTenantPropEnabledOwnProps & IWithRolesOwnProps;

interface IUserGroupTabsViewState { }

// -- Component
// ----------

class UserGroupTabsView extends React.Component<UserGroupTabsViewProps, IUserGroupTabsViewState> {
  state: IUserGroupTabsViewState = {};

  render() {
    const hasCoursesTab = this.props.isTenantPropEnabled('userGroupCourses') && this.props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]);
    return (
      <div>
        <Tabs defaultActiveKey={UserGroupContentNavigationMenuKeys.MEMEBERS} >
          <TabPane tab={this.props.translate('USER_GROUP_VIEW.NAVIGATION_MENU_MEMEBERS_LABEL')} key={UserGroupContentNavigationMenuKeys.MEMEBERS}>
            <div className="timun-userGroupTabsView__tab">
              <UserGroupMemberContainer userGroupId={this.props.userGroup.id} />
            </div>
          </TabPane>
          <TabPane tab={this.props.translate('USER_GROUP_VIEW.NAVIGATION_MENU_MATERIALS_LABEL')} key={UserGroupContentNavigationMenuKeys.MATERIALS}>
            <div className="timun-userGroupTabsView__tab">
              <UserGroupFilesContainer userGroupId={this.props.userGroup.id} />
            </div>
          </TabPane>
          {hasCoursesTab &&
            <TabPane tab={this.props.translate('USER_GROUP_VIEW.NAVIGATION_MENU_COURSES_LABEL')} key={UserGroupContentNavigationMenuKeys.COURSES}>
              <div className="timun-userGroupTabsView__tab">
                <CourseWorkpositionTreeContainer userGroupId={this.props.userGroup.id} />
              </div>
            </TabPane>
          }
        </Tabs>
      </div>

    );
  }
}

// -- HOCs and exports
// ----------

export default withTenantPropEnabled(withRoles(withLocalize<IUserGroupTabsViewOwnProps>(UserGroupTabsView as any)));
