import React from 'react';
import { connect } from 'react-redux';

import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import RoleUtils from '@src/service/util/role/RoleUtils';

export interface IIfUserRolesPublicProps {
  roles: UserRoleEnum[];
  fallback: string | React.ReactNode;
  children: React.ReactNode;
}
export interface IIfUserRolesStateProps {
  currentUser: IUserInfo;
}

type IIfUserRolesProps = IIfUserRolesPublicProps & IIfUserRolesStateProps;

export interface IIfUserRolesState {
  allowed: boolean;
}

/**
 * Component provides control logic on current user's roles.
 *
 * Compares list of current user's roles with given roles whitelist and renders children if any of them match.
 * If non of them match, fallback element is rendered.
 *
 * This component uses RoleUtils.isInRoles() for role checking.
 */
class IfUserRoles extends React.Component<IIfUserRolesProps, IIfUserRolesState> {
  state: IIfUserRolesState = {
    allowed: false,
  };

  componentDidMount() {
    this.check();
  }

  componentDidUpdate(previousProps: IIfUserRolesProps) {
    if (this.props !== previousProps) {
      this.check();
    }
  }

  render() {
    return <React.Fragment>{this.state.allowed ? this.props.children : this.props.fallback}</React.Fragment>;
  }

  private check() {
    this.setState({
      allowed: this.isAllowed(),
    });
  }

  private isAllowed(): boolean {
    if (this.props.currentUser == null) {
      console.warn('Cannot check user roles if current user is empty. This protection does not work on public pages.');
      return false;
    }

    // find if any of users roles appears in allowed roles list
    return RoleUtils.isInRoles(this.props.roles, this.props.currentUser.roles);
  }
}

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IIfUserRolesStateProps => ({
  // tutorList: TutorListBusinessStore.selectors.getTutorList(state),
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
});

export default connect<IIfUserRolesStateProps, any, IIfUserRolesPublicProps>(mapStateToProps)(IfUserRoles);
