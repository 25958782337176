import LemonIcon from '@src/components/common/image/LemonIcon';
import { classNames } from '@src/components/common/util/classNames';
import { Col, Row, Tooltip } from 'antd';
import React, { ReactNode } from 'react';

const ITEM_CSS_CLASS_BASE = 'timun-gridItem__container';
const ITEM_CSS_CLASS_SECONDARY = 'timun-gridItem__container--secondary';
const ITEM_CSS_CLASS_HORIZONTAL = 'timun-gridItem__container--horizontal';
const LABEL_CSS_CLASS_BASE = 'timun-gridItem__label';
const LABEL_CSS_CLASS_REQUIRED = 'timun-gridItem__label--required';
const LABEL_CSS_CLASS_HORIZONTAL = 'timun-gridItem__label--horizontal';
// -- Prop types
// ----------

export interface IGridItemOwnProps {
  label: ReactNode;
  infoText?: ReactNode;
  requiredMark?: boolean;
  className?: string;
  labelClassName?: string;
  ariaLabel?: string;
  layout?: 'horizontal' | 'vertical';
  contentType?: 'secondary';
}

// -- Component
// ----------

/** Component for displaying data with a label */
const GridItem: React.FC<IGridItemOwnProps> = (props) => {
  const getItemClassName = () => {
    return classNames({
      [ITEM_CSS_CLASS_BASE]: true,
      [ITEM_CSS_CLASS_SECONDARY]: props.contentType === 'secondary',
      [ITEM_CSS_CLASS_HORIZONTAL]: props.layout === 'horizontal',
      [props.className || '']: true,
    });
  };

  const getLabelClassName = () => {
    return classNames({
      [LABEL_CSS_CLASS_BASE]: true,
      [LABEL_CSS_CLASS_REQUIRED]: !!props.requiredMark,
      [LABEL_CSS_CLASS_HORIZONTAL]: props.layout === 'horizontal',
      [props.labelClassName || '']: true,
    });
  };

  return (
    <div className={getItemClassName()}>
      <Row align={(props.layout === 'horizontal' ? 'top' : 'middle')} gutter={8}>
        <Col className={getLabelClassName()}>
          <label aria-label={props.ariaLabel}>{props.label}</label>

          {props.infoText && (
            <React.Fragment>
              &nbsp;
              <Tooltip overlayClassName="timun-gridItem__infoTooltip" title={props.infoText}>
                <LemonIcon name="infoCircle" className="timun-gridItem__infoTextIcon" />
              </Tooltip>
            </React.Fragment>
          )}
        </Col>
        {props.layout === 'horizontal' && <Col span={20} className="timun-gridItem__content--horizontal" > {props.children} </Col>}
      </Row>
      {(props.layout === 'vertical' || props.layout === undefined) && <div className="timun-gridItem__content--vertical" >
        {props.children}
        {/* keep content height if empty */}
        {(props.children == null || props.children === '') && <React.Fragment>&nbsp;</React.Fragment>}
      </div>}
    </div >
  );
};

// -- HOCs and exports
// ----------

export default GridItem;
