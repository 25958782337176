import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import React from 'react';
import { Tabs } from 'antd';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import { IUserDetails } from '@src/model/user/UserDetails';
import UserGroupListContainer from '@src/components/usergroup/list/UserGroupListContainer';
import AppContent from '@src/components/common/container/AppContent';

// -- Const
// ----------
const { TabPane } = Tabs;
export enum UserGroupMembershipContentNavigationMenuKeys {
  MY_GROUPS = 'myGroups',
  AVAILABLE_GROUPS = 'availableGroups',
}

// -- Prop types
// ----------
export interface IUserGroupMembershipOwnProps {
}

export interface IUserGroupMembershipStateProps {
  currentUser: IUserDetails;
}

type IUserGroupMembershipProps = IUserGroupMembershipOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps & IUserGroupMembershipStateProps;

interface IUserGroupMembershipOwnState {
}
// -- Component
// ----------

class UserGroupMembership extends React.Component<IUserGroupMembershipProps, IUserGroupMembershipOwnState> {
  state: IUserGroupMembershipOwnState = {
  };

  render() {
    return (
      <AppContent title={this.props.translate('USER_GROUP_LIST_VIEW.MY_GROUPS_TITLE')}>
        <Tabs defaultActiveKey={UserGroupMembershipContentNavigationMenuKeys.MY_GROUPS} destroyInactiveTabPane={true}>
          {/* ----- Display a list of groups within which the user is a member----- */}
          <TabPane tab={this.props.translate('USER_GROUP_LIST_VIEW.TABS.MY_GROUPS_LABEL')} key={UserGroupMembershipContentNavigationMenuKeys.MY_GROUPS}>
            <UserGroupListContainer memberId={this.props.currentUser.id} />
          </TabPane>

          {/* ----- Display a list of groups that user can see and join ----- */}
          <TabPane tab={this.props.translate('USER_GROUP_LIST_VIEW.TABS.AVAILABLE_GROUP_LIST_LABEL')} key={UserGroupMembershipContentNavigationMenuKeys.AVAILABLE_GROUPS}>
            <UserGroupListContainer showAvailableGroups={true} />
          </TabPane>
        </Tabs>
      </AppContent>
    )
  };

}

// -- HOCs and exports
// ----------

export default withRoles(withLocalize<IUserGroupMembershipOwnProps>(UserGroupMembership as any));