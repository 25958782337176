import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import UserSkillEvaluationForm from '@src/components/user/form/UserSkillEvaluationForm';
import { IUserSkillEvaluation } from '@src/model/user/UserSkillEvaluation';
import { IUserSkillEvaluationCreatePayload } from '@src/service/business/user/userSkillEvaluationBusinessStore';
import { Form, Modal } from 'antd';


// -- Prop types
// ----------

export interface IUserSkillEvaluationModalOwnProps {
  title: string;
  userSkillEvaluation?: IUserSkillEvaluation;
  onCancel?: () => void;
  onUserSkillEvaluationCreate?: (userSkillEvaluation: IUserSkillEvaluationCreatePayload) => void;
  onUserSkillEvaluationUpdate?: (userSkillEvaluation: IUserSkillEvaluation) => void;
}
type IUserSkillEvaluationModalProps = IUserSkillEvaluationModalOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Describe your component ... */
const UserSkillEvaluationModal: React.FC<IUserSkillEvaluationModalProps> = (props: IUserSkillEvaluationModalProps) => {
  const [form] = Form.useForm();

  const handleSubmit = (value: IUserSkillEvaluation) => {
    if (props.userSkillEvaluation?.id) {
      //  update
      props.onUserSkillEvaluationUpdate?.(value);
    } else if (props.onUserSkillEvaluationCreate) {
      // create
      props.onUserSkillEvaluationCreate({ ...value });
    }
  };

  return (
    <Modal
      title={props.title}
      visible={true}
      onCancel={props.onCancel}
      onOk={form.submit}
      okText={props.translate('COMMON.ACTION_SAVE')}
      cancelText={props.translate('COMMON.ACTION_CANCEL')} >
      <UserSkillEvaluationForm
        formRef={form}
        onSubmit={handleSubmit}
        userSkillEvaluation={props.userSkillEvaluation} />
    </Modal>
  );
};

export default withLocalize<IUserSkillEvaluationModalOwnProps>(UserSkillEvaluationModal as any);
