import CollectionParamsBusinessStore from '@src/service/business/common/collectionParamsBusinessStore';
import { ICollectionFetchPayload } from '@src/service/business/common/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* Custom hook to store and get collection params from redux store by view name */
const useCollectionStore = <F>(viewName?: string, initialParams?: ICollectionFetchPayload<F>): [ICollectionFetchPayload<F> | undefined, ((params: ICollectionFetchPayload<F>) => void) | undefined] => {
  const dispatch = useDispatch();

  const collectionParams: ICollectionFetchPayload<F> | undefined = useSelector((store: any) => CollectionParamsBusinessStore.selectors.getCollectionParams(store, viewName));

  useEffect(() => { // only set initial params if none exists in store already, so they are not overwritten
    if (collectionParams == null && initialParams && setCollectionParams) {
      setCollectionParams(initialParams);
    }
  }, []);

  const setCollectionParams = viewName ? (params: ICollectionFetchPayload<F>) => (dispatch(CollectionParamsBusinessStore.actions.storeCollectionParams(viewName, params))) : undefined;

  return [collectionParams, setCollectionParams];

};

export default useCollectionStore;
