import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

import { IWebinar } from '@src/model/webinar/Webinar';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { actionThunk } from '@src/service/util/observable/operators';
import { reportCaughtMessage, startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts

export interface IWebinarsFilter {
  statuses?: string[];
  tags?: string[];
  [key: string]: any;
}

// List filter ID
const WEBINARS_LIST_FILTER = '@@WEBINARS_LIST_FILTER';

// -
// -------------------- Selectors

const getWebinars = (store: any): ICollectionData<IWebinar> => store.webinars;

/** Returns webinars filter. */
const getWebinarsFilter = (store: any): IWebinarsFilter => ListFilterBusinessStore.selectors.getListFilter(store, WEBINARS_LIST_FILTER);

// -
// -------------------- Actions

const Actions = {
  WEBINARS_FETCH: 'WEBINARS_FETCH',
  FINISHED_WEBINARS_FETCH: 'FINISHED_WEBINARS_FETCH',
  PUBLISHED_WEBINARS_FETCH: 'PUBLISHED_WEBINARS_FETCH',
  IN_PROGRESS_WEBINARS_FETCH: 'IN_PROGRESS_WEBINARS_FETCH',
  WEBINARS_LOAD: 'WEBINARS_LOAD',
  WEBINARS_CLEAR: 'WEBINARS_CLEAR',
  FINISHED_WEBINARS_LOAD: 'FINISHED_WEBINARS_LOAD',
  IN_PROGRESS_WEBINARS_LOAD: 'IN_PROGRESS_WEBINARS_LOAD',
  PUBLISHED_WEBINARS_LOAD: 'PUBLISHED_WEBINARS_LOAD',
};

const clearWebinarsFilter = (): ILemonAction => {
  return ListFilterBusinessStore.actions.clearListFilter(WEBINARS_LIST_FILTER);
};

const storeWebinarsFilter = (listFilter: IWebinarsFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(WEBINARS_LIST_FILTER, listFilter);
};

const fetchWebinars = (webinarFilter: ICollectionFetchPayload<IWebinarsFilter>): IPayloadAction<ICollectionFetchPayload<IWebinarsFilter>> => {
  return {
    type: Actions.WEBINARS_FETCH,
    payload: webinarFilter,
  };
};

/** Load webinars to store. */
const loadWebinars = (data: ICollectionData<IWebinar>): IPayloadAction<ICollectionData<IWebinar>> => {
  return {
    type: Actions.WEBINARS_LOAD,
    payload: data,
  };
};

const clearWebinars = (): ILemonAction => {
  return {
    type: Actions.WEBINARS_CLEAR,
  };
};

// -
// -------------------- Side-effects

const fetchWebinarsEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<IWebinarsFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.WEBINARS_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Webinar')
        .fetchEntityList(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadWebinars(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error fetching webinars list', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const webinars = (state: ICollectionData<IWebinar> | null = null, action: IPayloadAction<ICollectionData<IWebinar>>) => {
  if (action.type === Actions.WEBINARS_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.WEBINARS_CLEAR) {
    return null;
  }

  return state;
};

const WebinarListBusinessStore = {
  actions: {
    fetchWebinars,
    clearWebinars,
    clearWebinarsFilter,
    storeWebinarsFilter,
  },
  selectors: {
    getWebinarsFilter,
    getWebinars,
  },
  effects: {
    fetchWebinarsEffect,
  },
  reducers: {
    webinars,
  },
};

export default WebinarListBusinessStore;
