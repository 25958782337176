import { LangUtils } from '@src/service/util/LangUtils';

/**
 * Mapper iteration callback which allows caller to override mapping for certain properties.
 * Caller should return true if it has processed filter property or false if not and mapper should do it.
 */
export type ListFilterBuilderCallbackFn<T, P> = (filterQuery: ListFilterQuery, name: P, filterValue: T) => boolean;

/** Union type of possible filter property values. */
export type ListFilterPropValue = string | number | boolean;

/** Generated filter query value */
export type ListFilterQuery = Record<string, ListFilterPropValue>;

/** Filter value type forces it to be mapped type */
export interface IListFilterValue {
  [key: string]: any;
}

/** Collection of static helper methods to assist in converitng list filter objects to query filter expression. */
export default class ListFilterHelper {
  /**
   * Simple list filter to API mapper that returns key-value map that can be directly mapped to API filter query
   *
   * Filter value must be mapped type with string keys since we must send them via HTTP request.
   * Values can be anything and if they are not primitive they will be serialized to string using `LangUtils.stringify()`.
   * Empty values (see `LangUtils.isEmpty()`) are skipped.
   *
   * Filter prop names are simply copied as is but it is possible to override behaviour for each prop by defining callback handler function.
   * Callback fn recieves filter query obj, currently processed prop name and the original filter value and can then manually transform property and add it to filter query map or even decide to ignore it.
   */
  // tslint:disable-next-line: cognitive-complexity - remove when this method is refactored
  static simpleFilterToQueryMapper<T extends IListFilterValue>(filterValue: T, callback?: ListFilterBuilderCallbackFn<T, keyof T>): ListFilterQuery {
    // ----- build filter
    const filterQuery: ListFilterQuery = {};

    Object.keys(filterValue).forEach((propName) => {
      const propValue = filterValue[propName];

      // first try with callback
      const valueProcessed = callback && callback(filterQuery, propName, filterValue);

      // let callback handler to do the job
      if (!valueProcessed) {
        let queryValue: ListFilterPropValue;
        // ignore empty values
        if (LangUtils.isEmpty(propValue)) {
          return;
        }
        // oh, primitives, nice
        else if (LangUtils.isPrimitive(propValue)) {
          // check strings for whitespace, very common in form submitting
          if (LangUtils.isString(propValue)) {
            queryValue = propValue.trim();
            // ignore empty strings
            if (queryValue === '') {
              return;
            }
          }
          // number or boolean
          else {
            queryValue = propValue;
          }
        }
        // we can't handle non-primitives - stringify them
        else {
          queryValue = LangUtils.stringify(propValue);
        }

        // defaults to "EQUAL" operator
        filterQuery[propName] = queryValue;
      }
    });

    return filterQuery;
  }
}
