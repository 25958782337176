import React from 'react';

import { QuizCreator } from '@lamarodigital/quizzler-lib-frontend';
import { IQuizzlerUploadProps } from '@lamarodigital/quizzler-lib-frontend/components';
import { QuestionTypes } from '@lamarodigital/quizzler-lib-frontend/components/creator/QuizCreatorContainer';
import { IQuestionOutcome } from '@lamarodigital/quizzler-lib-frontend/model/quiz/outcome/QuestionOutcome';
import { IQuestion } from '@lamarodigital/quizzler-lib-frontend/model/quiz/question/Question';
import { IQuiz } from '@lamarodigital/quizzler-lib-frontend/model/quiz/Quiz';
import { QuizTypeEnum } from '@lamarodigital/quizzler-lib-frontend/model/quiz/QuizType';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { FileUploadHelper } from '@src/components/common/upload/FileUploadHelper';
import { QuizHelperUtils } from '@src/components/exam/quiz/QuizHelperUtils';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import ITenantConfiguration from '@src/model/tenant/TenantConfiguration';
import { ICollectionData, ICollectionFetchPayload, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import QuestionBusinessStore from '@src/service/business/quiz/questionBusinessStore';
import questionOutcomeListBusinessStore, { IQuestionOutcomeCreatePayload, IQuestionOutcomeListFilter } from '@src/service/business/quiz/questionOutcomeListBusinessStore';
import QuizCollectionBusinessStore from '@src/service/business/quiz/quizCollectionBusinessStore';
import PublicTenantConfigurationBusinessStore from '@src/service/business/tenant/publicTenantConfigurationBusinessService';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { connect } from 'react-redux';
import { Observable } from 'rxjs';

// -- Const
// ----------
const DEFAULT_PAGE_SIZE = AppConfigService.getValue('api.paging.defaultPageSize');

// -- Prop types
// ----------
export interface IQuizCreatorContainerOwnProps {
  onQuizCollectionSubmit?: (quizCollection: IQuiz) => void;
  quizId?: string;
  quizType: QuizTypeEnum.QUIZ | QuizTypeEnum.SURVEY;
}

export interface IQuizCreatorContainerStateProps {
  question: IQuestion;
  quizCollection: IQuiz;
  tenantConfig: ITenantConfiguration;
  outcomeList: ICollectionData<IQuestionOutcome>;
  outcomeListFilter: IQuestionOutcomeListFilter;
}

export interface IQuizCreatorContainerDispatchProps {
  createQuizCollection: (data: IQuiz) => ITrackableAction;
  updateQuizCollection: (data: IQuiz, dataId: string) => ITrackableAction;
  fetchQuizCollection: (quizId: string) => ITrackableAction;
  clearQuizCollection: () => void;

  fetchOutcomeList: (params: ICollectionFetchPayload<IQuestionOutcomeListFilter>) => void;
  createOutcome: (outcomeData: IQuestionOutcomeCreatePayload) => ITrackableAction;
  storeOutcomeListFilter: (filter: IQuestionOutcomeListFilter) => void;

  createQuestion: (questionData: IQuestion) => ITrackableAction;
  updateQuestion: (data: IQuestion) => ITrackableAction;
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
}

type IQuizCreatorContainerProps = IQuizCreatorContainerOwnProps & IQuizCreatorContainerStateProps & IWithTenantPropEnabledOwnProps & IQuizCreatorContainerDispatchProps & IWithLocalizeOwnProps;

interface IQuizCreatorContainerState {}

// -- Component
// ----------
class QuizCreatorContainer extends React.Component<IQuizCreatorContainerProps, IQuizCreatorContainerState> {
  state = {};

  componentDidMount() {
    if (this.props.quizId) {
      this.props.fetchQuizCollection(this.props.quizId);
    }
    this.updateOutcomeList();
  }

  componentDidUpdate(prevProps: IQuizCreatorContainerProps, prevState: IQuizCreatorContainerState) {
    if (prevProps.outcomeListFilter !== this.props.outcomeListFilter) {
      this.updateOutcomeList();
    }
  }

  componentWillUnmount() {
    this.props.clearQuizCollection();
  }

  renderQuestionTypeList = (quizType: QuizTypeEnum) => {
    let questionTypeList;
    if (quizType === QuizTypeEnum.QUIZ) {
      questionTypeList = this.props.tenantConfig.configuration.examQuestionList;
    } else {
      questionTypeList = this.props.tenantConfig.configuration.surveyQuestionList;
    }
    return questionTypeList as QuestionTypes;
  };

  render() {
    const uploadProps: IQuizzlerUploadProps = {
      ...FileUploadHelper.getUploadDefaultProps(),
      onChange: FileUploadHelper.handleAntdUploadChange,
      getQuizzlerFile: QuizHelperUtils.getQuizzlerFile,
    };
    return (
      <React.Fragment>
        <QuizCreator
          questionOutcomeList={this.props.outcomeList?.content ?? []}
          quiz={this.props.quizCollection}
          // TODO: tenantConfig enableQuestionOutcomes
          onQuestionSubmit={this.handleQuestionSubmit}
          onQuestionOrderChange={this.handleQuestionOrderChange}
          questionTypes={this.renderQuestionTypeList(this.props.quizType)}
          onQuestionRemove={this.handleQuestionRemove}
          onQuestionListChange={this.handleQuestionListChange}
          quizType={this.props.quizType}
          resolveFileUrl={QuizHelperUtils.resolveFileUrl}
          appUploadProps={uploadProps}
          showOutcomeSection={true}
          onOutcomeSearch={this.handleOutcomeSearch}
          onCreateOutcome={this.handleOutcomeCreate}
        />
      </React.Fragment>
    );
  }

  handleQuizCreate = (quizData: IQuiz) => {
    const newQuizzType = { ...quizData };
    if (this.props.quizType === QuizTypeEnum.QUIZ) {
      newQuizzType.type.id = QuizTypeEnum.POOL;
    } else {
      newQuizzType.type.id = QuizTypeEnum.SURVEY_POOL;
    }
    this.props
      .createQuizCollection(quizData)
      .track()
      .subscribe(
        // success
        () => {
          this.props.onQuizCollectionSubmit?.(this.props.quizCollection);
        }
      );
  };

  handleQuizUpdate = (quizData: IQuiz) => {
    if (quizData.id) {
      this.props
        .updateQuizCollection(quizData, quizData.id)
        .track()
        .subscribe(
          // success
          () => {
            this.props.onQuizCollectionSubmit?.(this.props.quizCollection);
          }
        );
    }
  };

  handleQuestionRemove = (quizData: IQuiz, index: number, question: IQuestion) => {
    if (quizData.id && question.id) {
      this.props.updateQuizCollection(quizData, quizData.id);
    }
  };

  handleQuestionSubmit = (questionData: IQuestion, questionIndex: number, quizData: IQuiz) => {
    if (questionData.id) {
      this.handleQuestionUpdate(questionData, quizData);
    } else {
      this.handleQuestionCreate(questionData, questionIndex, quizData);
    }
  };

  handleQuestionCreate = (questionData: IQuestion, questionIndex: number, updatedQuiz: IQuiz) => {
    this.props
      .createQuestion(questionData)
      .track()
      .subscribe(
        // success
        (question: IQuestion) => {
          this.props.reportMessage({ message: this.props.translate('QUIZ_CREATOR.QUESTION_SUCCESS_INFO_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.INFO });
          const newQuizObject = {
            ...updatedQuiz,
            questions: updatedQuiz.questions.map((value, index) => {
              if (index === questionIndex) {
                return { ...question, ordering: questionIndex + 1 };
              }
              return value;
            }),
          };
          if (newQuizObject.type.id === QuizTypeEnum.QUIZ || newQuizObject.type.id === QuizTypeEnum.SURVEY) {
            this.handleQuizCreate(newQuizObject);
          } else {
            this.handleQuizUpdate(newQuizObject);
          }
        }
      );
  };

  handleQuestionUpdate = (questionData: IQuestion, quizData: IQuiz) => {
    this.props
      .updateQuestion(questionData)
      .track()
      .subscribe(
        // success
        () => {
          // need to update whole quiz because question property required is part of quiz collection
          if (quizData.id) {
            this.props.updateQuizCollection(quizData, quizData.id);
          }
          this.props.reportMessage({ message: this.props.translate('QUIZ_CREATOR.QUESTION_SUCCESS_INFO_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.INFO });
        }
      );
  };

  handleQuestionOrderChange = (question: IQuestion, oldIndex: number, quiz: IQuiz) => {
    this.props
      .updateQuestion(question)
      .track()
      .subscribe(
        // success
        () => {
          this.handleQuestionListChange(quiz);
        }
      );
  };

  handleQuestionListChange = (updateQuiz: IQuiz) => {
    if (updateQuiz.id) {
      this.props.updateQuizCollection(updateQuiz, updateQuiz.id);
    }
  };

  handleOutcomeSearch = (value: string) => {
    this.props.storeOutcomeListFilter({ title: value });
  };

  /** We are fetching all objects in list so we have predefined collection params. */
  updateOutcomeList = () => {
    this.props.fetchOutcomeList({ filter: this.props.outcomeListFilter, page: 0, size: DEFAULT_PAGE_SIZE, sort: [''] });
  };

  handleOutcomeCreate = (title: string): Observable<IQuestionOutcome> => {
    return this.props.createOutcome({ title }).track();
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IQuizCreatorContainerOwnProps): IQuizCreatorContainerStateProps => ({
  question: QuestionBusinessStore.selectors.getQuestion(state),
  quizCollection: QuizCollectionBusinessStore.selectors.getQuizCollection(state),
  tenantConfig: PublicTenantConfigurationBusinessStore.selectors.getTenantConfiguration(state),
  outcomeList: questionOutcomeListBusinessStore.selectors.getQuestionOutcomeList(state),
  outcomeListFilter: questionOutcomeListBusinessStore.selectors.getQuestionOutcomeListFilter(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IQuizCreatorContainerDispatchProps => ({
  createQuestion: (data: IQuestion) => createTrackableAction(dispatch(QuestionBusinessStore.actions.createQuestion(data))),
  updateQuestion: (data: IQuestion) => createTrackableAction(dispatch(QuestionBusinessStore.actions.updateQuestion(data))),

  fetchOutcomeList: (params: ICollectionFetchPayload<IQuestionOutcomeListFilter>) => dispatch(questionOutcomeListBusinessStore.actions.fetchQuestionOutcomeList(params)),
  createOutcome: (outcomeData: IQuestionOutcomeCreatePayload) => createTrackableAction(dispatch(questionOutcomeListBusinessStore.actions.createQuestionOutcome(outcomeData))),
  storeOutcomeListFilter: (filter: IQuestionOutcomeListFilter) => dispatch(questionOutcomeListBusinessStore.actions.storeQuestionOutcomeListFilter(filter)),

  fetchQuizCollection: (quizId: string) => createTrackableAction(dispatch(QuizCollectionBusinessStore.actions.fetchQuizCollection({ id: quizId }))),
  createQuizCollection: (data: IQuiz) => createTrackableAction(dispatch(QuizCollectionBusinessStore.actions.createQuizCollection(data))),
  updateQuizCollection: (data: IQuiz, dataId: string) => createTrackableAction(dispatch(QuizCollectionBusinessStore.actions.updateQuizCollection(data, dataId))),
  clearQuizCollection: () => dispatch(QuizCollectionBusinessStore.actions.clearQuizCollectionData()),

  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<IQuizCreatorContainerOwnProps>(withTenantPropEnabled(QuizCreatorContainer as any)));
