import { CourseStatusEnum, ICourse } from '@src/model/course/Course';
import { IUserGroup } from '@src/model/usergroup/UserGroup';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { transformCourseListFilter } from '@src/service/business/courses/util';
import AppConfigService from '@src/service/common/AppConfigService';
import { RequiredBy } from '@src/service/util/lang/type';
import { actionThunk, startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';
import { ActionsObservable, StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

// -
// -------------------- Types&Consts

export interface ICourseListFilter {
  status?: CourseStatusEnum;
  assignee?: string;
  withTraineesStats?: boolean;
  tags?: string[];
  createdBy?: string;
  courseGroups?: string[];
  traineeStatsFrom?: string;
  traineeStatsTo?: string;
  title?: string;
  userGroups?: RequiredBy<Partial<IUserGroup>, 'id'>;
}

// List filter ID
const COURSE_LIST_FILTER = '@@COURSE_LIST_FILTER';

/**
 * Force collection size course list.
 *
 * Courses are always displayed in groups thus paging will not work.
 * Additionally, there will probably not be that many courses to require paging.
 */
const COURSE_LIST_DEFAULT_PAGE_SIZE = AppConfigService.getValue('api.collectionDefaultLimit');

/** trainee default course list filter base */
const COURSE_LIST_PAYLOAD_BASE: Pick<ICollectionFetchPayload<ICourseListFilter>, Exclude<keyof ICollectionFetchPayload<ICourseListFilter>, 'filter'>> = {
  sort: [],
  // force first page since courses are fetched with collection size - see explanation on COURSE_LIST_DEFAULT_PAGE_SIZE
  page: 0,
  // force default size
  size: COURSE_LIST_DEFAULT_PAGE_SIZE,
};

// -
// -------------------- Selectors

/** Returns list of courses from store. */
const getCourseList = (store: any): ICollectionData<ICourse> => store.courseList;

/** Returns last viewed course from store. */
const getLastCourse = (store: any): ICourse => store.latestCourse;

/** Returns course list filter. */
const getCourseListFilter = (store: any): ICourseListFilter => ListFilterBusinessStore.selectors.getListFilter(store, COURSE_LIST_FILTER);

/**
 * Returns list of all courses from store.
 * same as fetchAdminCourseLists, but separated for cases when both admin and trainee courses needed at the same time
 */
const getAllCourseList = (store: any): ICollectionData<ICourse> => store.allCourseList;

// -
// -------------------- Actions

const Actions = {
  COURSE_LIST_FETCH: 'COURSE_LIST_FETCH',
  COURSE_LIST_LOAD: 'COURSE_LIST_LOAD',
  COURSE_LIST_CLEAR: 'COURSE_LIST_CLEAR',
  COURSE_LAST_FETCH: 'COURSE_LAST_FETCH',
  COURSE_LAST_CLEAR: 'COURSE_LAST_CLEAR',
  COURSE_LAST_LOAD: 'COURSE_LAST_LOAD',
  COURSE_LIST_ALL_FETCH: 'COURSE_LIST_ALL_FETCH',
  COURSE_LIST_ALL_LOAD: 'COURSE_LIST_ALL_LOAD',
  COURSE_LIST_ALL_CLEAR: 'COURSE_LIST_ALL_CLEAR',
};

/** Fetch admin course list. Forces default COURSE_LIST_PAYLOAD_BASE except sort */
const fetchAdminCourseList = (listFilter: ICourseListFilter, page?: number, size?: number, sort?: string[]): IPayloadAction<ICollectionFetchPayload<ICourseListFilter>> => {
  return fetchCourseList({
    filter: listFilter,
    sort: sort ?? COURSE_LIST_PAYLOAD_BASE.sort,
    page: page ?? COURSE_LIST_PAYLOAD_BASE.page,
    size: size ?? COURSE_LIST_PAYLOAD_BASE.size,
  });
};

/** Fetch trainee course list. */
const fetchTraineeCourseList = (userId: string, listFilter: ICourseListFilter, page: number, size: number, sort: string[]): IPayloadAction<ICollectionFetchPayload<ICourseListFilter>> => {
  return fetchCourseList({
    filter: {
      ...listFilter,
      assignee: userId,
    },
    page,
    size,
    sort,
  });
};

/** Fetch trainee latest viewed course */
const fetchLastCourse = (): ILemonAction => {
  return {
    type: Actions.COURSE_LAST_FETCH,
  };
};

/** Clear trainee last viewed course */
const clearLastCourse = (): ILemonAction => {
  return {
    type: Actions.COURSE_LAST_CLEAR,
  };
};

/** Load trainee last viewed course */
const loadLastCourse = (course: ICourse): IPayloadAction<ICourse> => {
  return {
    type: Actions.COURSE_LAST_LOAD,
    payload: course,
  };
};

/** Fetch course list by filter. */
const fetchCourseList = (params: ICollectionFetchPayload<ICourseListFilter>): IPayloadAction<ICollectionFetchPayload<ICourseListFilter>> => {
  return {
    type: Actions.COURSE_LIST_FETCH,
    payload: params,
  };
};

/** Load course list to store. */
const loadCourseList = (data: ICollectionData<ICourse>): IPayloadAction<ICollectionData<ICourse>> => {
  return {
    type: Actions.COURSE_LIST_LOAD,
    payload: data,
  };
};

/** Clear course list from store. Eg. when leaving list view. */
const clearCourseList = (): ILemonAction => {
  return {
    type: Actions.COURSE_LIST_CLEAR,
  };
};

/** Store course list filter to store. */
const storeCourseListFilter = (listFilter: ICourseListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(COURSE_LIST_FILTER, listFilter);
};

const clearCourseListFilter = (): ILemonAction => {
  return ListFilterBusinessStore.actions.clearListFilter(COURSE_LIST_FILTER);
};

/** Fetch list of all courses by filter. */
const fetchAllCourseList = (allListFilter: ICourseListFilter): IPayloadAction<ICollectionFetchPayload<ICourseListFilter>> => {
  return {
    type: Actions.COURSE_LIST_ALL_FETCH,
    payload: {
      filter: {
        ...allListFilter,
      },
      ...COURSE_LIST_PAYLOAD_BASE,
    },
  };
};

/** Load list of all courses to store. */
const loadAllCourseList = (data: ICollectionData<ICourse>): IPayloadAction<ICollectionData<ICourse>> => {
  return {
    type: Actions.COURSE_LIST_ALL_LOAD,
    payload: data,
  };
};

/** Clear list of all courses from store. Eg. when leaving list view. */
const clearAllCourseList = (): ILemonAction => {
  return {
    type: Actions.COURSE_LIST_ALL_CLEAR,
  };
};

// -
// -------------------- Side-effects
const fetchLastCourseEffect = (action$: ActionsObservable<ILemonAction>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COURSE_LAST_FETCH;
    }),

    // fetch user latest course
    mergeMap((action) => {
      return EntityApiServiceRegistry.getService('Course')
        .fetchMethod('latest')
        .pipe(actionThunk(action));
    }),

    // load user latest course
    map((course) => {
      return loadLastCourse(course);
    }),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching last viewed course', error);

      return o;
    }),
  );
};

const fetchCourseListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<ICourseListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COURSE_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = {
        ...action.payload,
        filter: action.payload.filter && transformCourseListFilter(action.payload.filter),
      };

      return EntityApiServiceRegistry.getService('Course')
        .fetchEntityList(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadCourseList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching course list', error);
      return o;
    }),
  );
};

const fetchAllCourseListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<ICourseListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COURSE_LIST_ALL_FETCH;
    }),

    startGlobalProgress(),

    // tslint:disable-next-line: no-identical-functions
    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Course')
        .fetchEntityList(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadAllCourseList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching list of all courses', error);
      return o;
    }),
  );
};

// -
// -------------------- Reducers

const courseList = (state: ICollectionData<ICourse> | null = null, action: IPayloadAction<ICollectionData<ICourse>>) => {
  if (action.type === Actions.COURSE_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.COURSE_LIST_CLEAR) {
    return null;
  }

  return state;
};

const allCourseList = (state: ICollectionData<ICourse> | null = null, action: IPayloadAction<ICollectionData<ICourse>>) => {
  if (action.type === Actions.COURSE_LIST_ALL_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.COURSE_LIST_ALL_CLEAR) {
    return null;
  }

  return state;
};

/** Dummy login check. Untila proper user is resolved. */
const latestCourse = (state: any = null, action: IPayloadAction<ICourse>) => {
  if (action.type === Actions.COURSE_LAST_LOAD) {
    return action.payload;
  } else if (action.type === Actions.COURSE_LAST_CLEAR) {
    return null;
  }

  return state;
};
// --
// -------------------- Business Store

export const CourseListBusinessStore = {
  actions: {
    fetchLastCourse,
    clearLastCourse,
    fetchCourseList,
    fetchAdminCourseList,
    fetchTraineeCourseList,
    loadCourseList,
    clearCourseList,
    storeCourseListFilter,
    clearCourseListFilter,
    fetchAllCourseList,
    loadAllCourseList,
    clearAllCourseList,
  },

  selectors: {
    getLastCourse,
    getCourseList,
    getCourseListFilter,
    getAllCourseList,
  },

  effects: {
    fetchLastCourseEffect,
    fetchCourseListEffect,
    fetchAllCourseListEffect,
  },

  reducers: {
    courseList,
    allCourseList,
    latestCourse,
  },
};

// --
// export business store
export default CourseListBusinessStore;
