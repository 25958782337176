import LemonError from '@src/service/common/LemonError';

/** Thrown when there is something wrong with API reponse (eg. response is empty). */
export default class HttpErrorException extends LemonError {
  readonly code: number;

  constructor(code: number, message: string) {
    super(`HTTP error reponse [${code}]: ${message}`);

    this.code = code;
  }
}
