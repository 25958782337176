import { Col, Row } from 'antd';
import React from 'react';

import ListPagination from '@src/components/common/list/ListPagination';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import { ITimunListPaginationPosition } from '@src/components/course/common/types';
import CourseListViewFilter from '@src/components/course/filter/CourseListViewFilter';
import CourseCardList from '@src/components/course/list/CourseCardList';
import { ICourse } from '@src/model/course/Course';
import { ICourseGroup } from '@src/model/course/CourseGroup';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { ICollectionData } from '@src/service/business/common/types';
import { ICourseListFilter } from '@src/service/business/courses/courseListBusinessStore';

// -- Prop types
// ----------

interface ICourseListViewOwnProps {
  courseList: ICollectionData<ICourse>;
  courseListFilter?: ICourseListFilter;
  courseGroups?: ICourseGroup[];
  sortValue?: string[];

  getPath: (id: string, lecture?: string) => string;
  paginationPosition?: ITimunListPaginationPosition;

  /** Show list filter. Default is `true`. */
  showFilter?: boolean;
  /** Show list pagiation. Default is `true`. */
  showPagination?: boolean;
  /** Show course progress view. Default is `false`. */
  showProgressView?: boolean;
  /** Show course progress bar. Default is `true`. */
  showStatusBadge?: boolean;

  onSortChange?: (sort: string[]) => void;
  onFilterChange?: (filter: ICourseListFilter) => void;
  onPageChange?: (page: number, size?: number) => void;
}
type ICourseListViewProps = ICourseListViewOwnProps & IWithRolesOwnProps;

// -- Component
// ----------

const CourseListView: React.FC<ICourseListViewProps> = (props) => {
  const showFilter = props.showFilter ?? true;
  const paginationPosition = props.paginationPosition ?? 'both';
  const showProgressView = props.showProgressView ?? false;
  const showStatusBadge = props.showStatusBadge ?? true;

  const showStatusFilter = showFilter && props.isInRoles([UserRoleEnum.COURSE_CREATOR, UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN]);

  return (
    <React.Fragment>
      <Row justify="space-between" align="top">
        {/* ----- filter ----- */}
        {showFilter && (
          <Col span={24}>
            <CourseListViewFilter courseGroups={props.courseGroups} showStatusFilter={showStatusFilter} courseListFilter={props.courseListFilter || {}} sortValue={props.sortValue} onSortChange={props.onSortChange} onFilterChange={props.onFilterChange} />
          </Col>
        )}

        {/* ----- pagination (bottom) ----- */}
        {(paginationPosition === 'both' || paginationPosition === 'top') && (
          <Col span={24}>
            {props.courseList && <ListPagination page={props.courseList.page} onChange={props.onPageChange} />}
          </Col>
        )}
      </Row>

      {/* ----- Course List ----- */}
      <CourseCardList showProgressView={showProgressView} showStatusBadge={showStatusBadge} courses={props.courseList ? props.courseList.content : []} getPath={props.getPath} />

      {/* ----- pagination (bottom) ----- */}
      {(paginationPosition === 'both' || paginationPosition === 'bottom') && (
        <Row justify="end">
          <Col>{props.courseList && <ListPagination page={props.courseList.page} onChange={props.onPageChange} />}</Col>
        </Row>
      )}
    </React.Fragment>
  );
};

// -- HOCs and exports
// ----------

export default withRoles<ICourseListViewOwnProps>(CourseListView as any);
