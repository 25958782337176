import React from 'react';
import { connect } from 'react-redux';

import withLocalize from '@src/components/common/localize/withLocalize';
import UserRegistrationFormModal from '@src/components/user/registration/UserRegistrationFormModal';
import { IUserInfo } from '@src/model/user/User';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import { UserBusinessStore } from '@src/service/business/user/UserBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { StaticPageRoutingHelper } from '@src/service/util/StaticPageRoutingHelper';
import { withRouter, WithRouterProps } from 'react-router';

// -- Const
// ----------

// --
// ----- Prop types
interface IUserRegistrationContainerOwnProps { }

interface IUserRegistrationContainerStateProps {
  currentUser: IUserInfo;
}

interface IUserRegistrationContainerDispatchProps {
  updateUser: (user: IUserInfo) => ITrackableAction;
}

type IUserRegistrationContainerProps = IUserRegistrationContainerOwnProps & IUserRegistrationContainerStateProps & IUserRegistrationContainerDispatchProps & WithRouterProps;

// --
// ----- Component
class UserRegistrationContainer extends React.Component<IUserRegistrationContainerProps> {
  render = () => {
    if (this.props.currentUser) {
      return <UserRegistrationFormModal user={this.props.currentUser} onSubmit={this.handleFormSubmit} onCancel={this.handleFormCancel} />;
    } else {
      return null;
    }
  };

  // private onAvatarSubmit = (data: IFile) => {
  //   this.props.createUserAvatar(this.props.currentUser.id, data, {
  //     // success: () => {
  //     //   this.fetchUser();
  //     // },
  //   });
  // };

  private handleFormSubmit = (user: IUserInfo) => {
    this.props
      .updateUser(user)
      .track()
      .subscribe(() => {
        StaticPageRoutingHelper.routeToAuthDefaultPage();
      });
  };

  private handleFormCancel = () => {
    this.props.router.push(AppConfigService.getValue('routing.publicDefaultRoute'));
  };
}

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IUserRegistrationContainerOwnProps): IUserRegistrationContainerStateProps => ({
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any, ownProps: IUserRegistrationContainerOwnProps): IUserRegistrationContainerDispatchProps => ({
  updateUser: (user: IUserInfo) => dispatch(createTrackableAction(UserBusinessStore.actions.updateUser(user))),
});

export default connect<IUserRegistrationContainerStateProps, IUserRegistrationContainerDispatchProps, IUserRegistrationContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withRouter(withLocalize(UserRegistrationContainer)));
