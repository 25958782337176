import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ViewSectionContentItem from '@src/components/common/section/ViewSectionContentItem';
import ViewSectionItem from '@src/components/common/section/ViewSectionItem';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import EducationApplicationUserFileListView from '@src/components/externalEducationApplication/common/EducationApplicationUserFileListView';
import { IFileListsByType } from '@src/components/externalEducationApplication/common/types';
import { EmploymentStatusEnum, IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { ExternalEducationApplicationPaymentInfoTypeEnum } from '@src/model/externalEducationApplication/ExternalEducationApplicationPaymentInfo';
import { FileTypeEnum } from '@src/model/file/FileSystemElement';
import { IAddress } from '@src/model/user/Address';
import { ContactDataTypeEnum, IContactData } from '@src/model/user/ContactData';
import { IUserInfo } from '@src/model/user/User';
import { Col, Row } from 'antd';
import React from 'react';

interface IEducationApplicationUserViewSectionOwnProps {
  user: IUserInfo;
  externalEducationApplication?: IExternalEducationApplication;
  files?: IFileListsByType;
}
type IEducationApplicationUserViewSectionProps = IEducationApplicationUserViewSectionOwnProps & IWithLocalizeOwnProps;

interface IEducationApplicationUserViewSectionState {
}

class EducationApplicationUserViewSection extends React.Component<IEducationApplicationUserViewSectionProps, IEducationApplicationUserViewSectionState> {
  state: IEducationApplicationUserViewSectionState = {
  };

  getUserPaymentInfo = () => {
    return this.props.externalEducationApplication?.paymentInfos?.find((paymentInfo) => paymentInfo.paymentInfoType.id === ExternalEducationApplicationPaymentInfoTypeEnum.USER);
  };

  render = () => {
    const userPaymentInfo = this.getUserPaymentInfo();

    return <React.Fragment>
      <ViewSectionItem
        header={<HeaderTitle className="timun-userViewSection__header" icon={<LemonIcon name="user" size="large" />} title={this.props.translate('REQUEST.USER_VIEW_SECTION.TITLE')} />}
        collapsible={true}
        bordered={true}
        actions={<a href={`/user/${this.props.user?.id}`} target="_blank" data-test-id="timun-externalEducationApplicationUserPanel__openProfileLink">{this.props.translate('REQUEST.USER_VIEW_SECTION.LINK_LABEL')}</a>}
        footer={this.renderFiles()}
      >
        <Row>
          <Col xs={24} md={12} >
            <ViewSectionContentItem
              icon={<LemonIcon name="user" size="xlarge" />}
              title={`${this.props.user?.firstName} ${this.props.user?.lastName}`}
              description={this.props.externalEducationApplication?.employmentStatus?.name ? this.props.translate(`REQUEST.USER_VIEW_SECTION.${this.props.externalEducationApplication.employmentStatus?.name}_LABEL`) : undefined}
            />
            <ViewSectionContentItem icon={<LemonIcon name="mail" size="xlarge" />} title={this.getContactEmail(this.props.user?.contactData)} description={this.props.translate('REQUEST.USER_VIEW_SECTION.EMAIL_LABEL')} />
            <ViewSectionContentItem icon={<LemonIcon name="star" size="xlarge" />} title={this.props.user?.identificationNumber ?? this.props.translate('REQUEST.USER_VIEW_SECTION.IDENTIFICATION_NUMBER_MISSING_LABEL')} description={this.props.translate('REQUEST.USER_VIEW_SECTION.IDENTIFICATION_NUMBER_LABEL')} />
            <ViewSectionContentItem icon={<LemonIcon name="address" size="xlarge" />} title={this.getUserFullAddress(this.props.user?.address)} description={this.props.translate('REQUEST.USER_VIEW_SECTION.ADDRESS_LABEL')} />

            {this.props.externalEducationApplication && this.props.externalEducationApplication.consultation &&
              <ViewSectionContentItem icon={<LemonIcon name="comment" size="xlarge" />} title={this.props.translate('REQUEST.USER_VIEW_SECTION.CONSULTATION_LABEL')}
                description={this.props.translate('REQUEST.USER_VIEW_SECTION.CONSULTATION_CONTACT_LABEL', { contact: this.getConsultationContact(this.props.externalEducationApplication.consultation.userContact) })} />
            }

            {this.props.externalEducationApplication && this.props.externalEducationApplication.employmentStatus?.id === EmploymentStatusEnum.UNEMPLOYED && userPaymentInfo &&
              <React.Fragment>
                <ViewSectionContentItem
                  icon={<LemonIcon name="building" size="xlarge" />}
                  title={userPaymentInfo.bankName}
                  description={this.props.translate('REQUEST.USER_VIEW_SECTION.BANK_NAME_LABEL')}
                /><ViewSectionContentItem
                  icon={<LemonIcon name="moneyCheck" size="xlarge" />}
                  title={userPaymentInfo.iban}
                  description={this.props.translate('REQUEST.USER_VIEW_SECTION.IBAN_LABEL')}
                />
              </React.Fragment>
            }
          </Col>

          {this.props.externalEducationApplication && this.props.externalEducationApplication.employmentStatus?.id === EmploymentStatusEnum.EMPLOYED &&
            <Col xs={24} md={12}>
              <ViewSectionContentItem icon={<LemonIcon name="suitcase" size="xlarge" />} title={this.props.externalEducationApplication?.employerInformation?.name ?? this.props.translate('REQUEST.USER_VIEW_SECTION.EMPLOYER_NO_NAME_LABEL')} description={this.props.translate('REQUEST.USER_VIEW_SECTION.EMPLOYER_NAME_LABEL')} />
              <ViewSectionContentItem icon={<LemonIcon name="star" size="xlarge" />} title={this.props.externalEducationApplication?.employerInformation?.identificationNumber ?? this.props.translate('REQUEST.USER_VIEW_SECTION.EMPLOYER_NO_IBAN_LABEL')} description={this.props.translate('REQUEST.USER_VIEW_SECTION.EMPLOYER_OIB_LABEL')} />
              <ViewSectionContentItem icon={<LemonIcon name="mail" size="xlarge" />} title={this.getContactEmail(this.props.externalEducationApplication?.employerInformation?.contactData)} description={this.props.translate('REQUEST.USER_VIEW_SECTION.EMPLOYER_EMAIL_LABEL')} />
              <ViewSectionContentItem icon={<LemonIcon name="address" size="xlarge" />} title={this.getUserFullAddress(this.props.externalEducationApplication.employerInformation?.address)} description={this.props.translate('REQUEST.USER_VIEW_SECTION.EMPLOYER_ADDRESS_LABEL')} />

            </Col>
          }
        </Row>
      </ViewSectionItem>
    </React.Fragment>;
  };

  renderFiles = () => {
    return <React.Fragment>
      <span>{this.props.translate('REQUEST.USER_VIEW_SECTION.FOOTER_TITLE')}</span>
      <EducationApplicationUserFileListView files={this.props.files} displayTypes={[FileTypeEnum.CURRICULUM_VITAE]} />
    </React.Fragment>;
  };

  // TODO: NOTE: candidates for a UserUtils?
  getUserFullAddress = (address?: IAddress) => {
    return address ? `${address.streetWithHomeNumber}, ${address.zip} ${address.city}` : this.props.translate('REQUEST.USER_VIEW_SECTION.ADDRESS_MISSING_LABEL');
  };

  getContactEmail = (contactData?: IContactData[]) => {
    const email = contactData && contactData.find((data) => data.type.id === ContactDataTypeEnum.EMAIL);

    if (email) {
      return email.value;
    } else {
      return this.props.translate('REQUEST.USER_VIEW_SECTION.EMAIL_MISSING_LABEL');
    }
  };

  getConsultationContact = (contactData?: IContactData) => {
    return contactData?.value;
  };

}

export default withLocalize<IEducationApplicationUserViewSectionOwnProps>(EducationApplicationUserViewSection as any);
