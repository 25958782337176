// tslint:disable-next-line:no-submodule-imports
import { Form } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import CompanyDataPicker from '@src/components/common/datapicker/CompanyDataPicker';
import ExternalEducationTemplatePicker from '@src/components/common/datapicker/ExternalEducationTemplatePicker';
import SkillDataPicker from '@src/components/common/datapicker/SkillDataPicker';
import UserDataPicker from '@src/components/common/datapicker/UserDataPicker';
import CurrencyInput from '@src/components/common/input/CurrencyInput';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withNumberFormat, { IWithNumberFormatOwnProps } from '@src/components/common/numberformat/withNumberFormat';
import YesNoSelector from '@src/components/common/selector/YesNoSelector';
import EducationApplicationStatusSelector from '@src/components/externalEducationApplication/filter/common/EducationApplicationStatusSelector';
import { ILayoutType } from '@src/model/common/LayoutType';
import { EmploymentStatusEnum } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { ISkill } from '@src/model/skillgroup/Skill';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { IExternalEducationApplicationListFilter } from '@src/service/business/externalEducationApplication/ExternalEducationApplicationBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { Col, DatePicker, Input, Modal, Row, Select } from 'antd';
import moment, { Moment } from 'moment';
import React from 'react';

// -- Const
// ----------
const Option = Select.Option;
const dateFormat = AppConfigService.getValue('dateTimeFormat.dateWithYear');
const backendDateFormat = AppConfigService.getValue('dateTimeFormat.backendDate');
const day = 'day';

const LAYOUT_2COL: ILayoutType = {
  rows: {
    gutter: [16, 16],
  },
  cols: {
    xs: 24,
    sm: 12,
  },
};

// -- Prop types
// ----------
export interface IEducationApplicationReportListFilterOwnProps {
  onCancel: () => void;
  skillList?: ISkill[];
  filter?: IExternalEducationApplicationListFilter;
  onSubmit: (listFilter: IExternalEducationApplicationListFilter) => void;
  okText?: string;
  title?: string;
}

interface IEducationApplicationReportListFilterState { }

type IEducationApplicationReportListFilterProps = IEducationApplicationReportListFilterOwnProps & IWithLocalizeOwnProps & FormComponentProps & IWithNumberFormatOwnProps;

// -- Component
// ----------
class EducationApplicationReportListFilter extends React.Component<IEducationApplicationReportListFilterProps, IEducationApplicationReportListFilterState> {
  state: IEducationApplicationReportListFilterState = {};
  renderFilterInputNumber = (placeholder: string, addonAfter: string, fieldName?: string) => {
    return <Input placeholder={placeholder} type="number" addonAfter={addonAfter} data-test-id={`timun-externalEducationApplicationFilter__${fieldName}`} />;
  };

  renderFilterCurrencyInput = (placeholder: string, fieldName?: string) => {
    return <CurrencyInput placeholder={placeholder} data-test-id={`timun-externalEducationApplicationFilter__${fieldName}`} />;
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        className="lemon-modal__modal--md"
        title={this.props.title ?? this.props.translate('COMMON.LABEL.FILTER')}
        visible={true}
        onCancel={this.props.onCancel}
        okText={this.props.okText ?? this.props.translate('COMMON.ACTION_FILTER')}
        onOk={this.handleSubmit}
        cancelText={this.props.translate('COMMON.ACTION_CANCEL')}
        maskClosable={false}
      >
        <Form layout="vertical">
          <Form.Item label={this.props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.SKILL_LABEL')}>
            {getFieldDecorator('skill', {
              initialValue: this.props.filter?.skill,
            })(<SkillDataPicker />)}
          </Form.Item>

          <Form.Item label={this.props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_LABEL')}>
            {getFieldDecorator('education', {
              initialValue: this.props.filter?.education,
            })(<ExternalEducationTemplatePicker />)}
          </Form.Item>

          <Form.Item label={this.props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_PROVIDER_LABEL')}>
            {getFieldDecorator('educationProvider', {
              initialValue: this.props.filter?.educationProvider,
            })(<CompanyDataPicker />)}
          </Form.Item>

          <Form.Item label={this.props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.ASSIGNEE_LABEL')}>
            {getFieldDecorator('assignee', {
              initialValue: this.props.filter?.assignee,
            })(<UserDataPicker placeholder={this.props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.ASSIGNEE_PLACEHOLDER')} role={UserRoleEnum.TRAINEE} />)}
          </Form.Item>

          <Form.Item label={this.props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.EVALUATOR_LABEL')}>
            {getFieldDecorator('evaluator', {
              initialValue: this.props.filter?.evaluator,
            })(<UserDataPicker placeholder={this.props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.EVALUATOR_PLACEHOLDER')} role={UserRoleEnum.COORDINATOR} />)}
          </Form.Item>

          <Form.Item label={this.props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.EMPLOYMENT_STATUS_LABEL')}>
            {getFieldDecorator('employmentStatus', {
              initialValue: this.props.filter?.employmentStatus,
            })(
              <Select placeholder={this.props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.PICK_STATUS_PLACEHOLDER')} allowClear={true} data-test-id="timun-externalEducationApplicationFilter__employmentSelect">
                {Object.keys(EmploymentStatusEnum).map((item: string) => {
                  const value = EmploymentStatusEnum[item as keyof typeof EmploymentStatusEnum];
                  return (
                    <Option value={value} key={value} data-test-id={`timun-externalEducationApplicationFilter__employmentSelect_option_${item}`}>
                      {this.props.translate(`EXTERNAL_EDUCATION_APPLICATION.${item}_LABEL`)}
                    </Option>
                  );
                })}
              </Select>
            )}
          </Form.Item>

          <Form.Item label={this.props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_RELATED_LABEL')}>
            {getFieldDecorator('educationRelated', {
              initialValue: this.props.filter?.educationRelated,
            })(<YesNoSelector fieldName="educationRelated" />)}
          </Form.Item>

          <Form.Item label={this.props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.JOB_RELATED_LABEL')}>
            {getFieldDecorator('jobRelated', {
              initialValue: this.props.filter?.jobRelated,
            })(<YesNoSelector fieldName="jobRelated" />)}
          </Form.Item>

          <Form.Item label={this.props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.PENDING_CONSULTATION_LABEL')}>
            {getFieldDecorator('pendingConsultation', {
              initialValue: this.props.filter?.pendingConsultation,
            })(<YesNoSelector fieldName="pendingConsultation" />)}
          </Form.Item>

          <Form.Item label={this.props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_APPLICATION_STATUS_LABEL')}>
            {getFieldDecorator('educationApplicationStatus', {
              initialValue: this.props.filter?.phase,
            })(<EducationApplicationStatusSelector />)}
          </Form.Item>

          <Row align="bottom" justify="start" {...LAYOUT_2COL.rows}>
            <Col {...LAYOUT_2COL.cols}>
              <Form.Item label={this.props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_DURATION_LABEL')}>
                {getFieldDecorator('educationMinDuration', {
                  initialValue: this.props.filter?.educationMinDuration,
                })(this.renderFilterInputNumber(this.props.translate('COMMON.LABEL.MINIMUM'), this.props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_DURATION_SUFFIX'), 'educationMinDuration'))}
              </Form.Item>
            </Col>
            <Col {...LAYOUT_2COL.cols}>
              <Form.Item>
                {getFieldDecorator('educationMaxDuration', {
                  initialValue: this.props.filter?.educationMaxDuration,
                })(this.renderFilterInputNumber(this.props.translate('COMMON.LABEL.MAXIMUM'), this.props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_DURATION_SUFFIX'), 'educationMaxDuration'))}
              </Form.Item>
            </Col>
          </Row>

          <Row align="bottom" justify="start" {...LAYOUT_2COL.rows}>
            <Col {...LAYOUT_2COL.cols}>
              <Form.Item label={this.props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_APPLICATION_SUBMISSION_DATE_LABEL')}>
                {getFieldDecorator('submittedFrom', {
                  initialValue: this.props.filter?.submittedFrom && moment(this.props.filter.submittedFrom),
                })(<DatePicker allowClear={true} disabledDate={this.disabledStartDate} format={dateFormat} data-test-id="timun-externalEducationApplicationFilter__submittedFromDate" />)}
              </Form.Item>
            </Col>
            <Col {...LAYOUT_2COL.cols}>
              <Form.Item>
                {getFieldDecorator('submittedTo', {
                  initialValue: this.props.filter?.submittedTo && moment(this.props.filter.submittedTo),
                })(<DatePicker allowClear={true} disabledDate={this.disabledEndDate} format={dateFormat} data-test-id="timun-externalEducationApplicationFilter__submittedToDate" />)}
              </Form.Item>
            </Col>
          </Row>

          <Row align="bottom" justify="start" {...LAYOUT_2COL.rows}>
            <Col {...LAYOUT_2COL.cols}>
              <Form.Item label={this.props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_EXPENSES_LABEL')}>
                {getFieldDecorator('educationMinExpenses', {
                  initialValue: this.props.filter?.educationMinExpenses,
                })(this.renderFilterCurrencyInput(this.props.translate('COMMON.LABEL.MINIMUM'), 'educationMinExpenses'))}
              </Form.Item>
            </Col>
            <Col {...LAYOUT_2COL.cols}>
              <Form.Item>
                {getFieldDecorator('educationMaxExpenses', {
                  initialValue: this.props.filter?.educationMaxExpenses,
                })(this.renderFilterCurrencyInput(this.props.translate('COMMON.LABEL.MAXIMUM'), 'educationMaxExpenses'))}
              </Form.Item>
            </Col>
          </Row>

          <Row align="bottom" justify="start" {...LAYOUT_2COL.rows}>
            <Col {...LAYOUT_2COL.cols}>
              <Form.Item label={this.props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.TRAVEL_EXPENSES_LABEL')}>
                {getFieldDecorator('travelMinExpenses', {
                  initialValue: this.props.filter?.travelMinExpenses,
                })(this.renderFilterCurrencyInput(this.props.translate('COMMON.LABEL.MINIMUM'), 'travelMinExpenses'))}
              </Form.Item>
            </Col>
            <Col {...LAYOUT_2COL.cols}>
              <Form.Item>
                {getFieldDecorator('travelMaxExpenses', {
                  initialValue: this.props.filter?.travelMaxExpenses,
                })(this.renderFilterCurrencyInput(this.props.translate('COMMON.LABEL.MAXIMUM'), 'travelMaxExpenses'))}
              </Form.Item>
            </Col>
          </Row>
          {/* TODO: hide for HZZ demo purpose only */}
          {/* <Row align="bottom" justify="start">
            <Col {...LAYOUT_2COL.cols}>
              <Form.Item label={this.props.translate('EDUCATION_APPLICATION_REPORT_LIST_FILTER.INSURANCE_EXPENSES_LABEL')}>
                {getFieldDecorator('insuranceMinExpense', {
                  initialValue: this.props.filter?.insuranceMinExpense,
                })(this.renderFilterInputNumber(this.props.translate('COMMON.LABEL.MINIMUM'), currencySymbol))}
              </Form.Item>
            </Col>
            <Col {...LAYOUT_2COL.cols}>
              <Form.Item>
                {getFieldDecorator('insuranceMaxExpense', {
                  initialValue: this.props.filter?.insuranceMaxExpense,
                })(this.renderFilterInputNumber(this.props.translate('COMMON.LABEL.MAXIMUM'), currencySymbol))}
              </Form.Item>
            </Col>
          </Row> */}
        </Form>
      </Modal>
    );
  }

  disabledStartDate = (startValue?: Moment) => {
    const endValue = this.props.form.getFieldValue('educationEndDate');
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  disabledEndDate = (endValue?: Moment) => {
    const startValue = this.props.form.getFieldValue('educationStartDate');
    if (!endValue || !startValue) {
      return false;
    }

    return endValue.valueOf() <= startValue.valueOf();
  };

  private handleSubmit = (e: any) => {
    e.preventDefault();
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        const listFilter: IExternalEducationApplicationListFilter = {} as IExternalEducationApplicationListFilter;
        if (values.skill) {
          listFilter.skill = values.skill;
        }
        if (values.education) {
          listFilter.education = values.education;
        }
        if (values.educationProvider) {
          listFilter.educationProvider = values.educationProvider;
        }
        if (values.assignee) {
          listFilter.assignee = values.assignee;
        }
        if (values.evaluator) {
          listFilter.evaluator = values.evaluator;
        }

        if (values.employmentStatus) {
          listFilter.employmentStatus = values.employmentStatus;
        }

        listFilter.educationRelated = values.educationRelated;

        listFilter.jobRelated = values.jobRelated;

        listFilter.pendingConsultation = values.pendingConsultation;

        if (values.educationApplicationStatus) {
          listFilter.phase = values.educationApplicationStatus;
        }
        if (values.educationMinDuration) {
          listFilter.educationMinDuration = values.educationMinDuration;
        }
        if (values.educationMaxDuration) {
          listFilter.educationMaxDuration = values.educationMaxDuration;
        }
        if (values.educationMinExpenses) {
          listFilter.educationMinExpenses = values.educationMinExpenses;
        }
        if (values.educationMaxExpenses) {
          listFilter.educationMaxExpenses = values.educationMaxExpenses;
        }
        if (values.travelMinExpenses) {
          listFilter.travelMinExpenses = values.travelMinExpenses;
        }
        if (values.travelMaxExpenses) {
          listFilter.travelMaxExpenses = values.travelMaxExpenses;
        }
        if (values.insuranceMinExpense) {
          listFilter.insuranceMinExpense = values.insuranceMinExpense;
        }
        if (values.insuranceMaxExpense) {
          listFilter.insuranceMaxExpense = values.insuranceMaxExpense;
        }
        if (values.submittedFrom) {
          listFilter.submittedFrom = values.submittedFrom.startOf(day).format(backendDateFormat);
        }
        if (values.submittedTo) {
          listFilter.submittedTo = values.submittedTo.endOf(day).format(backendDateFormat);
        }
        this.props.onSubmit(listFilter);
      }
    });
  };
}

const EducationApplicationReportListFilterModal = Form.create<IEducationApplicationReportListFilterProps>()(EducationApplicationReportListFilter as any);

export default withNumberFormat(withLocalize<IEducationApplicationReportListFilterOwnProps>(EducationApplicationReportListFilterModal as any));
