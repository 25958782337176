import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';

import ITenantConfiguration from '@src/model/tenant/TenantConfiguration';
import PublicTenantConfigurationBusinessStore from '@src/service/business/tenant/publicTenantConfigurationBusinessService';
import { resolvePropValues, TenantPropsType } from '@src/service/business/tenant/tenantPropEnabled';

export interface IIfTenantPropEnabledPublicProps {
  /** Property name to resolve, */
  property: TenantPropsType | TenantPropsType[];
  /** Attribute that negates property resolving result. Ie. if properties are enabled, this resolves as FALSE. If properties are disabled, this resolves as TRUE. */
  not?: boolean;

  /** Node to display if property resolves to false. */
  fallback: React.ReactNode;
}
export interface IIfTenantPropEnabledStateProps {
  tenantConfiguration: ITenantConfiguration;
}

type IIfTenantPropEnabledProps = PropsWithChildren<IIfTenantPropEnabledPublicProps> & IIfTenantPropEnabledStateProps;

/**
 * Component provides control logic for showing it's children only if tenant's "property" is "enable".
 * If tenant property is empty (null/undefined), this component acts like it's disabled ie. does not show it's content.
 *
 * @see {resolvePropValue} for implementation details.
 */
const IfTenantPropEnabled: React.FC<IIfTenantPropEnabledProps> = (props) => {
  const notExpression = props.not ?? false;
  const propEnabled = resolvePropValues(props.property, props.tenantConfiguration);

  const showChildren = notExpression ? !propEnabled : propEnabled;

  return <React.Fragment>{showChildren ? props.children : props.fallback}</React.Fragment>;
};

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IIfTenantPropEnabledStateProps => ({
  tenantConfiguration: PublicTenantConfigurationBusinessStore.selectors.getTenantConfiguration(state),
});

export default connect<IIfTenantPropEnabledStateProps, any, IIfTenantPropEnabledPublicProps>(mapStateToProps)(IfTenantPropEnabled);
