import { Layout } from 'antd';
import React from 'react';

import AppFooter from '@src/components/app/layout/AppFooter';
import AppHeader from '@src/components/app/layout/AppHeader';
import LemonErrorBoundary from '@src/components/common/error/LemonErrorBoundary';

const AppLayout: React.FC<React.PropsWithChildren<{}>> = (props) => {
  return (
    <Layout className={`lemon-appLayout__layout`}>
      <Layout.Header className="lemon-appLayout__header">
        <LemonErrorBoundary>
          <AppHeader />
        </LemonErrorBoundary>
      </Layout.Header>

      <Layout.Content className="lemon-appLayout__content">
        <LemonErrorBoundary showDefaultError={true}>{props.children}</LemonErrorBoundary>
      </Layout.Content>

      <Layout.Footer className="lemon-appLayout__footer">
        <LemonErrorBoundary>
          <AppFooter />
        </LemonErrorBoundary>
      </Layout.Footer>
    </Layout>
  );
};

export default AppLayout;
