const hr: any = {
  // ---------- Template labels
  'MODULE.LABEL_NAME': 'Template for adding labels',

  // ---------- Application
  'APPLICATION.APP_NAME': 'Timun',
  'APPLICATION.APP_SLOGAN': 'Upravljajte svojom sudbinom',
  'APPLICATION.APP_LOGO_TITLE': 'Timun - Upravljajte svojom sudbinom',
  'APPLICATION.APP_DESCRIPTION_MADE_WITH': 'Made with',
  'APPLICATION.APP_DESCRIPTION_MADE_BY': 'by Lamaro digital',

  // ---------- COMMON ACTIONS
  'COMMON.ACTION_SAVE': 'Spremi',
  'COMMON.ACTION_EDIT': 'Uredi',
  'COMMON.ACTION_OK': 'OK',
  'COMMON.ACTION_YES': 'Da',
  'COMMON.ACTION_NO': 'Ne',
  'COMMON.ACTION_CANCEL': 'Odustani',
  'COMMON.ACTION_PREVIEW': 'Pregledajte',
  'COMMON.ACTION_ADD': 'Dodajte',
  'COMMON.ACTION_ADD_FROM_PC': 'Dodaj s računala',
  'COMMON.ACTION_ADD_NEW': 'Dodaj novi',
  'COMMON.ACTION_SEARCH': 'Traži',
  'COMMON.ACTION_LOGOUT': 'Odjavite se',
  'COMMON.ACTION_LOAD_MORE_ITEMS': 'Prikaži više',
  'COMMON.ACTION_CLOSE': 'Zatvori',
  'COMMON.ACTION_SHOW': 'Prikaži',
  'COMMON.ACTION_SHOW_MORE_CONTENT': 'prikaži više',
  'COMMON.ACTION_SHOW_LESS_CONTENT': 'sakrij',
  'COMMON.ACTION_DEACTIVATE': 'Deaktiviraj',
  'COMMON.ACTION_ACTIVATE': 'Aktiviraj',
  'COMMON.ACTION_DELETE': 'Obriši',
  'COMMON.ACTION_DOWNLOAD': 'Preuzmi',
  'COMMON.ACTION_CLEAR': 'Očisti',
  'COMMON.ACTION_CONTINUE': 'Nastavi',
  'COMMON.ACTION_DONE': 'Gotovo',
  'COMMON.ACTION_SORT_LABEL': 'Poredak',
  'COMMON.ACTION_SORT_ASCEND': 'Abecedno silazno',
  'COMMON.ACTION_SORT_DESCEND': 'Abecedno uzlazno',
  'COMMON.ACTION_SORT_NEWEST': 'Od najnovijeg',
  'COMMON.ACTION_SORT_OLDEST': 'Od najstarijeg',
  'COMMON.ACTION_APPROVE': 'Odobri',
  'COMMON.ACTION_REJECT': 'Odbij',
  'COMMON.ACTION_ADD_VIDEO': 'Dodaj video',
  'COMMON.ACTION_BACK': 'Povratak',
  'COMMON.ACTION_FILTER': 'Filtriraj',
  'COMMON.ACTION_RESET_FILTER': 'Poništi sve filtere',
  'COMMON.ACTION_ENROLL': 'Upiši se',
  'COMMON.ACTION_CREATE_NEW': 'Stvori novi - (%{input})',
  'COMMON.EDITED_MESSAGE': 'Promjene su spremljene',

  // ----- confirmation
  'COMMON.CONFIRMATION_TITLE': 'Jeste li sigurni?',
  'COMMON.CONFIRMATION_DELETE_TITLE': 'Jeste li sigurni da želite obrisati?',
  'COMMON.CONFIRMATION_ITEM_DELETE_TITLE': 'Jeste li sigurni da želite obrisati "%{title}"?',
  'COMMON.CONFIRMATION_ROUTE_NAVIGATION': 'Jeste li sigurni da želite napustiti stranicu? Promjene neće biti spremljene.',
  'COMMON.CONFIRMATION_CANCEL_ACTION_MESSAGE': 'Jeste li sigurni da želite odustati? Promjene neće biti spremljene.',

  // ----- validation
  'COMMON.CONFIRM_DELETE_MESSAGE': 'Jeste li sigurni da želite obrisati?',
  'COMMON.FILL_REQUIRED_FIELD': 'Popunite obavezan podatak',
  'COMMON.PICK_REQUIRED_FIELD': 'Odaberite obaveznu vrijednost',
  'COMMON.ENTERED_VALUE_NOT_VALID': 'Pogrešan unos',
  // --- (new) validation messages
  'COMMON.VALIDATION.REQUIRED': 'Polje je obavezno',
  'COMMON.VALIDATION.INVALID': 'Neispravan unos',
  'COMMON.VALIDATION.INVALID_DECIMAL': 'Decimalni broj nije ispravan',
  'COMMON.VALIDATION.INVALID_INTEGER': 'Broj nije ispravan',
  'COMMON.VALIDATION.INVALID_DATETIME': 'Datum i/ili vrijeme nije ispravan',
  'COMMON.VALIDATION.INVALID_EMAIL': 'Neispravna email adresa',
  'COMMON.MESSAGE_NO_DATA': 'Nema podataka',
  'COMMON.VALIDATION.INVALID_OIB': 'OIB nije ispravan',
  'COMMON.VALIDATION.INVALID_IBAN': 'IBAN nije ispravan',
  'COMMON.VALIDATION.MAX_FIELDS_ADDED': 'Dodali ste maksimalan broj polja za unos',
  'COMMON.VALIDATION.MIN_FIELDS_REQUIRED': 'Molimo da dodate traženo polje. Minimalan broj potrebnih polja za unos %{minFieldNumber}',
  'COMMON.VALIDATION.INVALID_EXTERNAL_LINK': 'Poveznica bi trebala sadržavati https://',
  'COMMON.VALIDATION.MIN_VALUE_EXCEED_MAX_VALUE': 'Minimalna vrijednost ne može prijeći veću',
  'COMMON.VALIDATION.MAX_VALUE_BELOW_MIN_VALUE': 'Maksimalna vrijednost ne može ići ispod manje',

  // ----- Common labels
  'COMMON.LABEL.NAME': 'Naziv',
  'COMMON.LABEL.STATUS': 'Status',
  'COMMON.LABEL.ACTIVE': 'Aktivno',
  'COMMON.LABEL.INACTIVE': 'Neaktivno',
  'COMMON.LABEL.EMAIL': 'E-mail',
  'COMMON.LABEL.PHONE': 'Telefon',
  'COMMON.PLACEHOLDER.EMAIL': 'Unesite e-mail adresu',
  'COMMON.PLACEHOLDER.PHONE': 'Unesite broj telefona ili mobitela',
  'COMMON.LABEL.YES': 'Da',
  'COMMON.LABEL.NO': 'Ne',
  'COMMON.LABEL.BOOLEAN_TRUE': 'Da',
  'COMMON.LABEL.BOOLEAN_FALSE': 'Ne',
  'COMMON.LABEL.FILES': 'Datoteke',
  'COMMON.LABEL.EDIT': 'Uredi',
  'COMMON.LABEL.FILTER': 'Filter',
  'COMMON.LABEL.MAXIMUM': 'Maksimum',
  'COMMON.LABEL.MINIMUM': 'Minimum',
  'COMMON.LABEL.MAX_LENGTH_REACHED': 'Prekoračili ste dopušten broj znakova',
  'COMMON.LABEL.MAX_LENGTH_CHARACTER_INFO_LABEL': 'Dopušten broj znakova je %{maxCharNum}',
  'COMMON.LABEL.PICK_ANSWER_PLACEHOLDER': 'Odaberite odgovor',

  // ---------- General Messages
  'GENERAL_MESSAGE.GENERAL_FETCH_ERROR': 'Greška pri dohvatu podataka!',
  'GENERAL_MESSAGE.GENERAL_SEND_ERROR': 'Greška pri slanju podataka!',
  'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR': 'Greška pri spremanju podataka!',
  'GENERAL_MESSAGE.GENERAL_DELETE_ERROR': 'Greška pri brisanju podataka!',

  'GENERAL_MESSAGE.GENERAL_FETCH_SUCCESS': 'Podatci uspješno dohvaćeni.',
  'GENERAL_MESSAGE.GENERAL_SEND_SUCCESS': 'Podatci uspješno poslani.',
  'GENERAL_MESSAGE.GENERAL_UPDATE_SUCCESS': 'Podatci uspješno spremljeni.',
  'GENERAL_MESSAGE.GENERAL_DELETE_SUCCESS': 'Podatci uspješno obrisani.',

  // ---------- Course error Messages
  'COURSE_CREATE.ERROR.COURSE_ALREADY_EXISTS': 'Već postoji tečaj s istim imenom, odaberite drugo ime.',

  // ---------- Tag error messages
  'TAG_ADD.ERROR.TAG_ITEM_ALREADY_EXISTS': 'Oznaka je već dodana',

  // ---------- App navigation
  'APP_NAVIGATION.DASHBOARD': 'Početna',
  'APP_NAVIGATION.MY_PROFILE': 'Moj profil',
  'APP_NAVIGATION.EDUCATION_GROUPS': 'Program obrazovanja',
  'APP_NAVIGATION.REPOSITORY': 'Baza znanja',
  'APP_NAVIGATION.COURSES': 'Katalog tečajeva',
  'APP_NAVIGATION.MY_COURSES': 'Moji tečajevi',
  'APP_NAVIGATION.REPORTS': 'Izvještaji',
  'APP_NAVIGATION:ADMINISTRATION': 'Administracija',
  'APP_NAVIGATION.WEBINARS': 'Webinari',
  'APP_NAVIGATION.EXTERNAL_CONTENTS': 'Vanjske poveznice',
  'APP_NAVIGATION.MY_USER_GROUPS': 'Moje grupe',
  'APP_NAVIGATION.EDUCATION_APPLICATION': 'Svi zahtjevi',
  'APP_NAVIGATION.EXTERNAL_EDUCATION_TEMPLATES': 'Svi programi obrazovanja',

  // ---------- Activity history text
  'ACTIVITY_HISTORY.PHASE_TEXT.APPROVAL_REQUESTED': 'je poslao program na verifikaciju.',
  'ACTIVITY_HISTORY.PHASE_TEXT.APPROVAL_IN_PROCESS': 'je preuzeo program obrazovanja na obradu.',
  'ACTIVITY_HISTORY.PHASE_TEXT.APPROVED': 'je verificirao program obrazovanja.',
  'ACTIVITY_HISTORY.PHASE_TEXT.CHANGE_REQUESTED': 'je zatražio promjene.',
  'ACTIVITY_HISTORY.PHASE_TEXT.DENIED': 'je odbio verificirati program obrazovanja.',
  'ACTIVITY_HISTORY.PHASE_TEXT.CANCELLED ': 'je otkazao program obrazovanja.',
  'ACTIVITY_HISTORY.PHASE_TEXT.CHANGE_SUBMITTED': 'je predao promjene programa na verifikaciju.',

  // ---------- Login Form
  'LOGIN.USERNAME_LABEL': 'Korisničko ime',
  'LOGIN.USERNAME_PLACEHOLDER': 'Unesite korisničko ime',
  'LOGIN.PASSWORD_LABEL': 'Lozinka',
  'LOGIN.PASSWORD_PLACEHOLDER': 'Unesite lozinku',
  'LOGIN.LOGIN_BUTTON_LABEL': 'Prijava',
  'LOGIN.FORGOT_PASSWORD_LABEL': 'Zaboravili ste lozinku?',
  'LOGIN.REGISTRATION_LABEL': 'Nemate korisnički račun?',
  'LOGIN.REGISTRATION_LINK': 'Registracija',
  // errors
  'LOGIN.ERROR.GENERAL_ERROR': 'Greška pri prijavi u sustav',
  // API errors
  'LOGIN.ERROR.BAD_CREDENTIALS': 'Neuspješna prijava. Molimo provjerite svoje pristupne podatke i pokušajte ponovo.',

  // ---------- Login Menu
  'LOGIN_MENU.LOGIN_LABEL': 'Prijava',
  'LOGIN_MENU.LANGUAGE_LABEL': 'Jezik',

  // ---------- User Menu
  'USER_MENU.MY_PROFILE': 'Moj profil',
  'USER_MENU.CHANGE_PASSWORD_LABEL': 'Promjena lozinke',
  'USER_MENU.LOGOUT_LABEL': 'Odjava',
  'USER_MENU.NOTIFICATIONS': 'Obavijesti',

  // ---------- Error page
  'ERROR_PAGE.DEFAULT_ERROR_TITLE': 'Ups, nešto se nije dobro prikazalo',
  'ERROR_PAGE.DEFAULT_ERROR_DESCRIPTION': 'Molimo probajte ponovo ili nam javite da pokušamo zajedno riješiti problem. Hvala!',

  // ---------- Welcome page
  'WELCOME.WELCOME_TITLE': 'Dobrodošli na %{tenantName}!',

  // ---------- Course page
  'COURSE.STATUS_TITLE.IN_CREATION': 'U Izradi',
  'COURSE.STATUS_TITLE.CREATION_FINISHED': 'Završen',
  'COURSE.STATUS_TITLE.PUBLISHED': 'Objavljen',
  'COURSE.STATUS_TITLE.ARCHIVED': 'Arhiviran',

  'COURSE_VIEW.LECTURE_LIST_MENU.COURSE_ABOUT_ITEM_LABEL': 'O tečaju',
  'COURSE_VIEW.LECTURE_LIST_MENU.EXAM_TEMPLATE_ITEM_LABEL': 'Predložak ispita',
  'COURSE_VIEW.CREATE.CREATE_COURSE_TITLE': 'Novi tečaj',
  'COURSE_VIEW.VIEW.COURSE_START_BUTTON': 'Započni',
  'COURSE_VIEW.VIEW.LECTURE_QUIZ_TITLE': 'Provjera znanja',
  'COURSE_VIEW.VIEW.ADDITIONAL_COURSES_TITLE': 'Slični tečajevi',
  'COURSE_VIEW.UPDATE.LECTURE_NAME_INPUT_TITLE': 'Nova lekcija',
  'COURSE_VIEW.UPDATE.GROUP_NAME_INPUT_TITLE': 'Nova grupa lekcija',
  'COURSE_VIEW.UPDATE.NAME_INPUT_PLACEHOLDER': 'Unesi ime',
  'COURSE_VIEW.UPDATE.DELETE_CONFIRM_TITLE': 'Izbrisati?',
  'COURSE_VIEW.UPDATE.ABOUT_COURSE_TAB_TITLE': 'Detalji tečaja',
  'COURSE_VIEW.UPDATE.LECTURE_GROUP_TITLE': 'Grupe lekcija',
  'COURSE_VIEW.UPDATE.LECTURE_GROUP_SELECT_PLACEHOLDER': 'Odaberi grupu lekcija',
  'COURSE_VIEW.UPDATE.COURSE_TEXT_FORM_LABEL': 'O tečaju',
  'COURSE_VIEW.UPDATE.COURSE_TITLE_FORM_LABEL': 'Naslov tečaja',
  'COURSE_VIEW.UPDATE.COURSE_STATUS_FORM_LABEL': 'Status Tečaja',
  'COURSE_VIEW.UPDATE.COURSE_GROUP_SELECT_FORM_LABEL': 'Grupe tečajeva',
  'COURSE_VIEW.UPDATE.COURSE_GROUP_SELECT_PLACEHOLDER': 'Odaberi grupu tečajeva',
  'COURSE_VIEW.UPDATE.COURSE_LECTURE_GROUP_TITLE_FORM_LABEL': 'Naslov grupe',
  'COURSE_VIEW.UPDATE.COURSE_LECTURE_GROUP_ORDERING_FORM_LABEL': 'Redni broj',
  'COURSE_VIEW.UPDATE.LECTURE_TITLE_FORM_LABEL': 'Naslov lekcije',
  'COURSE_VIEW.UPDATE.LECTURE_ORDERING_FORM_LABEL': 'Redni broj lekcije u grupi',
  'COURSE_VIEW.UPDATE.LECTURE_VIDEO_FORM_LABEL': 'Video sadržaj',
  'COURSE_VIEW.UPDATE.LECTURE_CONTENT_FORM_LABEL': 'Sadržaj lekcije',
  'COURSE_VIEW.UPDATE.COURSE_CONTENT_EDIT_LABEL': 'Uređivanje',
  'COURSE_VIEW.UPDATE.COURSE_CONTENT_PREVIEW_LABEL': 'Pregled',
  'COURSE_VIEW.UPDATE.LECTURE_FILES_LABEL': 'Dodatni materijali',
  'COURSE_VIEW.UPDATE.KNOWLEDGE_BASE_BUTTON': 'Dodaj iz baze znanja',
  'COURSE_VIEW.UPDATE.VISIBILITY_LABEL': 'Vidljivost tečaja',
  'COURSE_VIEW.UPDATE.VISIBILITY_REQUIRED_LABEL': 'Odaberite vidljivost',
  'COURSE_VIEW.UPDATE.VISIBILITY_TITLE_PUBLIC': 'Javan',
  'COURSE_VIEW.UPDATE.VISIBILITY_TITLE_PRIVATE': 'Privatan',
  'COURSE_VIEW.UPDATE.VISIBILITY_TITLE_LIMITED': 'Ograničen',
  'COURSE_VIEW.UPDATE.LECTURE_DELETED_MESSAGE': 'Obrisana je lekcija: %{lecture}',
  'COURSE_VIEW.COURSE_DELETED_MESSAGE': 'Tečaj je obrisan',
  'COURSE_VIEW.CREATE_NEW_NOTE': 'Stvori novu bilješku',
  'COURSE_VIEW.NOTES': 'Bilješke',
  'COURSE_VIEW.COMMENTS': 'Komentari',
  'COURSE_VIEW.PUBLIC_LECTURE_TOOLTIP': 'Sadržaj lekcije možete gledati tek nakon što upišete tečaj.',
  'COURSE_VIEW_PUBLIC.TITLE': 'Katalog tečajeva',
  'COURSE_VIEW_PUBLIC.ALL_COURSEGROUPS_LABEL': 'Sve grupe tečajeva',
  'COURSE_VIEW_PUBLIC.ALL_STATUSES_LABEL': 'Svi statusi',
  'COURSE_VIEW.NO_DATA_LABEL': 'Popis tečajeva je prazan',
  'COURSE_VIEW.ENROLL_MESSAGE': 'Uspješno ste se upisali na tečaj %{courseName}.',
  'COURSE_VIEW.COURSE_COMPLETED_MESSAGE': 'Čestitamo! Završili ste tečaj %{courseName}. Potvrdu o završetku možete preuzeti na svom profilu.',
  // errors
  'COURSE_VIEW.MESSAGE.INVALID_QUIZ_CONTENT_ERROR': 'Neispravan sadržaj kviza [%{error}]',
  'LECTURE_CREATE.ERROR.LECTURE_ALREADY_EXISTS': 'Lekcija već postoji',
  'LECTURE_GROUP_CREATE.ERROR.LECTURE_GROUP_ALREADY_EXISTS': 'Grupa lekcija već postoji',

  // ---------- Course progress info
  'COURSE_PROGRESS_INFO.DEADLINE_DATE_TOOLTIP_LABEL': 'Rok',
  'COURSE_PROGRESS_INFO.EARNED_POINTS_TOOLTIP_LABEL': 'Ostvareni bodovi',

  // ---------- Comments view
  'COMMENTS_VIEW.DELETE_COMMENT': 'Obrišite komentar',
  'COMMENTS_VIEW.EDIT_COMMENT': 'Uredite komentar',
  'COMMENTS_VIEW.CLOSE_EDIT_SECTION': 'Zatvorite uređivanje komentara',
  'COMMENTS_VIEW.ACTION_SORT_POPULAR': 'Najpopularniji',
  'COMMENTS_VIEW.ACTION_SORT_LABEL': 'Poredaj komentare po',
  'COMMENTS_VIEW.APPROVAL_INFO_MESSAGE': 'Čeka odobrenje',
  'COMMENTS_VIEW.APPROVED_INFO_MESSAGE': 'Uspješno odobren komentar "%{content}", %{firstName} %{lastName}',
  'COMMENTS_VIEW.COMMENT_ADDED_MESSAGE': 'Komentar je dodan',
  'COMMENTS_VIEW.COMMENT_DELETED_MESSAGE': 'Komentar je obrisan',

  // ---------- User View
  'USER_VIEW.WORK_POSITION_LABEL': 'Radno mjesto',
  'USER_VIEW.WORK_SATRT_DATE_LABEL': 'Početak rada',
  'USER_VIEW.DATE_OF_BIRTH_LABEL': 'Datum rođenja',
  'USER_VIEW.ADDRESS_LABEL': 'Adresa',
  'USER_VIEW.EDIT_USER': 'Uredi polaznika',
  'USER_VIEW.DELETE_USER': 'Obriši polaznika',
  'USER_VIEW.COURSES_TAB_LABEL': 'Tečajevi',
  'USER_VIEW.ACTIVITIES_TAB_LABEL': 'Aktivnosti',
  'USER_VIEW.COORDINATOR_EDUCATION_APPLICATION_TAB_LABEL': 'Zahtjevi koje obrađujem',
  'USER_VIEW.TRAINEE_EDUCATION_APPLICATION_TAB_LABEL': 'Moji zahtjevi',
  'USER_VIEW.DOCUMENTS_TAB_LABEL': 'Dokumenti',
  'USER_VIEW.SKILLS_TAB_LABEL': 'Vještine',
  'USER_VIEW.ORGANIZATIONS_TAB_LABEL': 'Organizacije',
  'USER_VIEW.DOCUMENTS_TABLE_DOCUMENT_LABEL': 'Naziv dokumenta',
  'USER_VIEW.DOCUMENTS_TABLE_AUTHOR_LABEL': 'Autor',
  'USER_VIEW.DOCUMENTS_TABLE_TIME_LABEL': 'Vrijeme kreiranja',
  'USER_VIEW.USER_DELETED_LABEL': 'Korisnik obrisan',
  'USER_VIEW.EXTERNAL_EDUCATION_TEMPLATES_TAB_LABEL': 'Programi obrazovanja koje obrađujem',

  // ---------- User Container View
  'USER_VIEW_CONTAINER.CREATE_USER': 'Kreiraj korisnika',

  // --------- User Notes
  'USER_NOTES.CREATE_NOTE': 'Stvorite bilješku',

  // ---------- Notes List View
  'NOTE_LIST.MY_NOTES_LABEL': 'Moje bilješke',
  'NOTE_LIST.NOTE_TYPE.COURSE': 'Tečaj',
  'NOTE_LIST.NOTE_TYPE.WEBINAR': 'Webinar',
  'NOTE_LIST.NOTE_TYPE.EXAM_TEMPLATE': 'Predložak ispita',
  'NOTE_LIST.NOTE_MODAL_LABEL': 'Uredi bilješku',
  'NOTE_CONTAINER.SUCCESS_CREATE_INFO_MESSAGE': 'Bilješka je uspješno kreirana.',
  'NOTE_CONTAINER.NOTE_DELETED_MESSAGE': 'Bilješka je uklonjena',
  'NOTE_LIST.NO_DATA': 'Nemate bilješki',
  'NOTE_LIST.EXPORT_BUTTON_LABEL': 'Izvezi bilješke',
  'NOTE_LIST.CREATE_ERROR': 'Trenutno nema bilješki za preuzimanje.',

  // ---------- Notes List Filter
  'NOTE_LIST_FILTER.SORT_BY_DATE_ADDED_LABEL': 'Poredaj po vremenu dodavanja',

  // ---------- User List Filter
  'USER_LIST_FILTER.ALL_USERS': 'Svi korisnici',
  'USER_LIST_FILTER.ONLY_ACTIVE_USERS': 'Aktivni korisnici',
  'USER_LIST_FILTER.INPUT_LENGTH': 'Minimalno 3 znaka',
  'USER_LIST.TRAINEE_REPORTS': 'Izvještaj polaznika',
  'USER_LIST_FILTER.NAME_LABEL': 'Ime',
  'USER_LIST_FILTER.ASSIGNED_DATE_FROM_LABEL': 'Dodjela tečaja (od)',
  'USER_LIST_FILTER.ASSIGNED_DATE_TO_LABEL': 'Dodjela tečaja (do)',
  'USER_LIST_FILTER.USER_GROUPS_LABEL': 'Korisničke grupe',

  // ---------- Course group list
  'COURSE_GROUP_LIST.VIEW_TITLE': 'Grupe tečajeva',
  'COURSE_GROUP_LIST.CREATE_GROUP_TITLE': 'Nova grupa tečajeva',
  'COURSE_GROUP_LIST.EDIT_GROUP_TITLE': 'Grupa tečajeva',
  'COURSE_GROUP.DELETED_MESSAGE': 'Korisnička grupa je obrisana',

  // ---------- Course list
  'COURSE_LIST.VIEW_TITLE': 'Tečajevi',
  'COURSE_LIST.NO_DATA': 'Nema dodijeljenih tečajeva',
  'COURSE_LIST.UNGROUPED_TITLE': 'Nedodijeljeni',
  'COURSE_LIST.ACTION_CREATE': 'Novi tečaj',
  'COURSE_LIST.ACTION_GROUPS_EDIT': 'Uredi grupe',

  'COURSE_LIST_STARTED.VIEW_TITLE': 'Tečajevi u tijeku',
  'COURSE_CONTINUE.VIEW_TITLE': 'Nastavi gledati tečaj',
  'COURSE_LIST_STARTED.NO_DATA': 'Nema započetih tečajeva',

  'COURSE_LIST.COURSE_STATUS_TO_DO': 'Nezapočeto',
  'COURSE_LIST.COURSE_STATUS_IN_PROGRESS': 'U tijeku',
  'COURSE_LIST.COURSE_STATUS_COMPLETED': 'Položeno',
  'COURSE_LIST.ADD_COURSES_LABEL': 'Dodaj tečaj(eve) polazniku',
  'COURSE_LIST.EDIT_COURSES_LABEL': 'Uredi tečaj(eve) polaznika',

  'COURSE_LIST_EDIT_FORM.ADD_TITLE_LABEL': 'Dodavanje tečaj(eva) korisniku',
  'COURSE_LIST_EDIT_FORM.EDIT_TITLE_LABEL': 'Uređivanje tečaj(eva) korisnika',
  'COURSE_LIST_EDIT_FORM.UNASSIGNED_COURSES_LABEL': 'Nedodijeljeni',
  'COURSE_LIST_EDIT_FORM.ASSIGNED_COURSES_LABEL': 'Dodijeljeni',
  'COURSE_LIST_EDIT_FORM.DEADLINE_DATE_LABEL': 'Rok',
  'COURSE_LIST_EDIT_FORM.DEADLINE_DATE_PLACEHOLDER': 'Unesite rok do kojeg polaznik treba završiti tečaj',

  'COURSE_LIST_FILTER.TRAINEE_STATS_FROM_LABEL': 'Početni datum',
  'COURSE_LIST_FILTER.TRAINEE_STATS_TO_LABEL': 'Završni datum',
  'COURSE_LIST_FILTER.USER_GROUPS_LABEL': 'Korisničke grupe',

  // ---------- File upload
  'FILE_UPLOAD.MODAL_TITLE': 'Učitavanje datoteke',
  'FILE_UPLOAD.MODAL_UPLOAD_BUTTON': 'Dohvati s računala',
  'FILE_UPLOAD.DROPZONE_TEXT': 'Kliknite ili dovucite datoteke ovdje za dodavanje',
  'FILE_UPLOAD.ERROR_FILE_SIZE': 'Najveća dopuštena veličina datoteke je %{maxFileSize}!',
  'FILE_UPLOAD.ERROR_FILE_TYPE': 'Datoteka nije ispravnog tipa!',
  'FILE_UPLOAD.IMAGE.ERROR_FILE_TYPE': 'Moguće je odabrati samo .jpg ili .png datoteke!',
  'FILE_UPLOAD.VIDEO.ERROR_FILE_TYPE': 'Moguće je odabrati samo video datoteke!',

  'FILE_UPLOAD.FILE_ADDED_MESSAGE': 'Datoteka %{filename} dodana',
  'FILE_UPLOAD.FILE_REMOVED_MESSAGE': 'Datoteka %{filename} izbrisana',
  'FILE_UPLOAD.FILE_UPLOAD_ERROR_MESSAGE': 'Greška prilikom dodavanja datoteke %{filename}',
  'FILE_UPLOAD.TOOLTIP_MESSAGE': 'Za najbolje rezultate koristite slike širine 600-800 piksela koje su manje visine nego širine. Za profilnu sliku sliku jednakih dimenzija po širini i visini.',

  // ---------- Video upload
  'INTEGRATION_VIDEO.FORM.MODAL_TITLE': 'Učitavanje videa',
  'INTEGRATION_VIDEO.FORM.IMPORT_VIDEO_TITLE': 'Uvezi video ID',
  'INTEGRATION_VIDEO.FORM.UPLOAD_VIDEO_TITLE': 'Učitaj video iz datoteke',
  'INTEGRATION_VIDEO.FORM.ADD_VIDEO': 'Dodaj video',
  'INTEGRATION_VIDEO.UPLOAD.DROPZONE_TEXT': 'Kliknite ili dovucite ovdje datoteku za dodavanje',
  'INTEGRATION_VIDEO.UPLOAD.UPLOAD_BUTTON': 'Dohvati s računala',
  'INTEGRATION_VIDEO.IMPORT.IMPORT_BUTTON': 'Uvezi video',

  'INTEGRATION_VIDEO.MESSAGE.ERROR_FILE_SIZE': 'Najveća dopuštena veličina datoteke je %{maxFileSize}!',
  'INTEGRATION_VIDEO.MESSAGE.ERROR_FILE_TYPE': 'Moguće je odabrati samo video datoteke!',
  'INTEGRATION_VIDEO.MESSAGE.UPLOAD_SUCCESSFUL': 'Video uspješno učitan',
  'INTEGRATION_VIDEO.MESSAGE.UPLOAD_ERROR': 'Greška prilikom učitavanja videa',
  'INTEGRATION_VIDEO.MESSAGE.IMPORT_ERROR': 'Greška prilikom učitavanja videa',
  'INTEGRATION_VIDEO.MESSAGE.UPLOAD_IN_PROGRESS': 'Učitavanje videa je još uvijek u tijeku. Jeste li sigurni da želite prekinuti?',

  'INTEGRATION_VIDEO.ERROR.INTEGRATION_ERROR': 'Greška kod pripreme videa', // smthng broke while creating placeholder
  'INTEGRATION_VIDEO.ERROR.UNSUPPORTED_INTEGRATION': 'Učitavanje videa nije podržano',
  'INTEGRATION_VIDEO.ERROR.NON_EXISTING_INTEGRATION_OBJECT': 'Zatraženi video nije pronađen',

  // ---------- Users List
  'USER_LIST.USERS_TITLE': 'Korisnici',
  'USER_LIST.TRAINEES_TITLE': 'Polaznici',
  'USER_LIST.USER_LABEL': 'Korisnik',
  'USER_LIST.WORKPOSITION_LABEL': 'Radno mjesto',
  'USER_LIST.COMPLETED_COURSES_LABEL': 'Završeni tečajevi',
  'USER_LIST.COURSE_STATUS_LABEL': 'Status',
  'USER_LIST.COURSE_DEADLINE_LABEL': 'Rok',

  // ---------- Users management List

  'USER_MANAGEMENT_LIST_VIEW.USER_ROLE_TRAINEE': 'Korisnik',
  'USER_MANAGEMENT_LIST_VIEW.USER_ROLE_SUPERADMIN': 'Glavni admin',
  'USER_MANAGEMENT_LIST_VIEW.USER_ROLE_ORGANIZATION_ADMIN': 'Organizacijski admin',
  'USER_MANAGEMENT_LIST_VIEW.USER_ROLE_COURSE_CREATOR': 'Kreator tečajeva',
  'USER_MANAGEMENT_LIST_VIEW.USER_ROLE_ORGANIZER': 'Organizator',
  'USER_MANAGEMENT_LIST_VIEW.USER_ROLE_ORGANIZATION_MEMBER': 'Član organizacije',
  'USER_MANAGEMENT_LIST_VIEW.USER_ROLE_COORDINATOR': 'Savjetnik',
  'USER_MANAGEMENT_LIST_VIEW.USER_IDENTIFICATION_NUMBER_TITLE': 'OIB',
  'USER_MANAGEMENT_LIST_VIEW.USER_EMAIL_TITLE': 'Email',
  'USER_MANAGEMENT_LIST_VIEW.USER_FIRST_NAME_TITLE': 'Ime',
  'USER_MANAGEMENT_LIST_VIEW.USER_LAST_NAME_TITLE': 'Prezime',
  'USER_MANAGEMENT_LIST_VIEW.USER_AVATAR_TITLE': 'Slika',
  'USER_MANAGEMENT_LIST_VIEW.USER_ROLES_TITLE': 'Uloge',
  'USER_MANAGEMENT_LIST_VIEW.SHOW_USER_PROFILE_ACTION_BUTTON_TITLE': 'Pogledaj profil',
  // ---------- Users management list filter
  'ADMIN_USER_MANAGEMENT_LIST_FILTER.OIB_RULE_MESSAGE': 'OIB se sastoji od 11 znamenki',
  'ADMIN_USER_MANAGEMENT_LIST_FILTER.NAME_LABEL': 'Prema imenu i/ili prezimenu',
  'ADMIN_USER_MANAGEMENT_LIST_FILTER.OIB_LABEL': 'Prema OIB-u',
  'ADMIN_USER_MANAGEMENT_LIST_FILTER.ROLE_LABEL': 'Prema ulozi',

  // ---------- User Form
  'USER_FORM.FORM_TITLE_CREATE': 'Kreiranje korisnika',
  'USER_FORM.FORM_TITLE_EDIT': 'Uređivanje korisnika',
  'USER_FORM.STATUS_LABEL': 'Status',
  'USER_FORM.ACTIVE_LABEL': 'Aktivan',
  'USER_FORM.INACTIVE_LABEL': 'Neaktivan',
  'USER_FORM.FIRST_NAME_LABEL': 'Ime',
  'USER_FORM.FIRST_NAME_PLACEHOLDER': 'Unesite ime',
  'USER_FORM.LAST_NAME_LABEL': 'Prezime',
  'USER_FORM.LAST_NAME_PLACEHOLDER': 'Unesite prezime',
  'USER_FORM.GENDER_LABEL': 'Spol',
  'USER_FORM.GENDER_LABEL_M': 'Muško',
  'USER_FORM.GENDER_LABEL_F': 'Žensko',
  'USER_FORM.DATE_OF_BIRTH_LABEL': 'Datum rođenja',
  'USER_FORM.DATE_OF_BIRTH_PLACEHOLDER': 'Unesite datum rođenja',
  'USER_FORM.ADDRESS_STREET_WITH_NUMBER_LABEL': 'Ulica i kućni broj',
  'USER_FORM.ADDRESS_STREET_WITH_NUMBER_PLACEHOLDER': 'Unesite naziv ulice i kućni broj',
  'USER_FORM.IDENTIFICATION_NUMBER_LABEL': 'OIB',
  'USER_FORM.IDENTIFICATION_NUMBER_PLACEHOLDER': 'Unesite OIB',
  'USER_FORM.ADDRESS_LABEL': 'Adresa',
  'USER_FORM.ADDRESS_ZIP_LABEL': 'Poštanski broj',
  'USER_FORM.ADDRESS_ZIP_PLACEHOLDER': 'Unesite poštanski broj',
  'USER_FORM.ADDRESS_CITY_LABEL': 'Mjesto',
  'USER_FORM.ADDRESS_CITY_PLACEHOLDER': 'Unesite naziv mjesta',
  'USER_FORM.ADDRESS_COUNTRY_LABEL': 'Država',
  'USER_FORM.ADDRESS_COUNTRY_PLACEHOLDER': 'Unesite naziv države',
  'USER_FORM.CONTACT_DATA_LABEL': 'Kontakt podaci',
  'USER_FORM.ADD_CONTACT_DATA_LABEL': 'Dodajte kontakt podatak',
  'USER_FORM.GDPR_LABEL': 'GDPR',
  'USER_FORM.GDPR_ACCEPTED': 'Potvrđujem da sam stariji/a od 16 godina i prihvaćam Uvjete korištenja i Pravila privatnosti',
  'USER_FORM.WORK_POSITION_NAME_LABEL': 'Radno mjesto',
  'USER_FORM.WORK_POSITION_NAME_PLACEHOLDER': 'Odaberite naziv radnog mjesta',
  'USER_FORM.WORK_POSITION_START_DATE_LABEL': 'Početak rada',
  'USER_FORM.WORK_POSITION_START_DATE_PLACEHOLDER': 'Unesite početak rada',
  'USER_FORM.EMAIL_LABEL': 'E-mail',
  'USER_FORM.PHONE_LABEL': 'Telefon',
  'USER_FORM.EMAIL_PLACEHOLDER': 'Unesite e-mail adresu',
  'USER_FORM.PHONE_PLACEHOLDER': 'Unesite broj telefona ili mobitela',
  'USER_FORM.EMAIL_VALIDATION_TEXT': 'Unesite ispravnu e-mail adresu',
  'USER_FORM.USERNAME_LABEL': 'Korisničko ime',
  'USER_FORM.USERNAME_PLACEHOLDER': 'Unesite korisničko ime',
  'USER_FORM.USERNAME_MINIMUM_LENGTH_VALIDATION_MESSAGE': 'Korisničko ime mora sadržavati minimalno 8 znakova',
  'USER_FORM.PASSWORD_LABEL': 'Lozinka',
  'USER_FORM.PASSWORD_PLACEHOLDER': 'Unesite lozinku',
  'USER_FORM.CONFIRM_PASSWORD_LABEL': 'Potvrda lozinke',
  'USER_FORM.CONFIRM_PASSWORD_PLACEHOLDER': 'Unesite potvrdu lozinke',
  'USER_FORM.PASSWORD_MINIMUM_LENGTH_VALIDATION_MESSAGE': 'Lozinka mora sadržavati minimalno 8 znakova',
  'USER_FORM.PASSWORDS_DIFFERENT_ERROR': 'Lozinke nisu identične',
  'USER_FORM.ROLES_LABEL': 'Vrsta profila',
  'USER_FORM.ROLES_PLACEHOLDER': 'Uloga korisnika',
  'USER_FORM.ROLE_SUPERADMIN_LABEL': 'Glavni administrator',
  'USER_FORM.ROLE_ORGANIZATION_ADMIN_LABEL': 'Organizacijski administrator',
  'USER_FORM.ROLE_COURSE_CREATOR_LABEL': 'Kreator tečajeva',
  'USER_FORM.ROLE_TRAINEE_LABEL': 'Korisnik',
  'USER_FORM.ROLE_ORGANIZER_LABEL': 'Organizator',
  'USER_FORM.ROLE_ORGANIZATION_MEMBER_LABEL': 'Član organizacije',
  'USER_FORM.ROLE_COORDINATOR_LABEL': 'Savjetnik',
  'USER_FORM.ACTIVITY_PLACEHOLDER': 'Korisnički račun',
  'USER_FORM.ACTIVITY_ALL': 'Svi',
  'USER_FORM.ACTIVITY_ACTIVE_ONLY': 'Aktivni',
  // error message
  'USER_FORM.ERROR_MESSAGE.USER_CREATE_OR_UPDATE': 'Greška pri spremanju podataka',

  // ---------- User evaluation list
  'USER_SKILL_EVALUATION_LIST_CONTAINER.BUTTON_ADD_SKILL_LABEL': 'Pridruži vještinu',
  'SKILL_EVALUATION_LIST.ASSIGNED_SKILLS_LABEL': 'Pridružene vještine',
  'SKILL_EVALUATION_LIST.ASSIGNED_LEVEL_LABEL': 'Pridružena razina',
  'SKILL_EVALUATION_LIST.EVALUATOR_NAME_LABEL': 'Procjenivač',
  'SKILL_EVALUATION_LIST.EVALUATION_TYPE_LABEL': 'Vrsta procjene',
  'SKILL_EVALUATION_LIST.DATE_LABEL': 'Datum',
  'SKILL_EVALUATION.TYPE.PEER': 'Procjena kolega',
  'SKILL_EVALUATION.TYPE.HUMAN_RESOURCES': 'Ljudski resursi',
  'SKILL_EVALUATION.TYPE.MANAGER': 'Menadžer',
  'SKILL_EVALUATION.TYPE.SELF': 'Samoprocjena',
  'USER_SKILL_EVALUATION.FORM.ASSIGN_SKILL_LABEL': 'Odaberi vještinu i razinu',
  'USER_SKILL_EVALUATION.MODAL.CREATE_SKILL_TITLE': 'Pridruživanje vještina korisniku',
  'USER_SKILL_EVALUATION.MODAL.EDIT_SKILL_TITLE': 'Uredi vještinu korisniku',
  'USER_SKILL_EVALUATION.NOTIFICATION.USER_SKILL_EVALUATION_CREATED': 'Uspješno pridužena vještina',
  'USER_SKILL_EVALUATION.NOTIFICATION.USER_SKILL_EVALUATION_DELETED': 'Uspješno obrisana vještina',
  'USER_SKILL_EVALUATION.RADAR_CHART_TITLE': 'Usporedba vještina zaposlenika sa radnim mjestom.',
  // --- error message
  'USER_SKILL_EVALUATION_CREATE.ERROR.USER_SKILL_EVALUATION_NOT_ALLOWED': 'Nije vam dozvoljeno priduživat vještine ovome korisniku',

  // ---------- Reset Password & Request
  'RESET_PASSWORD_REQUEST.INFO_TEXT_1': 'Zahtjev za novu lozinku poslan na e-mail adresu',
  'RESET_PASSWORD_REQUEST.INFO_TEXT_2': 'Provjerite Vaše e-mail poruke i slijedite upute',
  'RESET_PASSWORD_REQUEST.EMAIL_LABEL': 'E-mail',
  'RESET_PASSWORD_REQUEST.EMAIL_PLACEHOLDER': 'Unesite Vašu e-mail adresu',
  'RESET_PASSWORD_REQUEST.RESET_PASSWORD_BUTTON_LABEL': 'Pošalji zahtjev za novu lozinku',
  'RESET_PASSWORD_REQUEST.EMAIL_VALIDATION_TEXT': 'Unesite ispravnu e-mail adresu',

  'RESET_PASSWORD.MISSING_TOKEN': 'Neispravan poziv za novu lozinku',
  'RESET_PASSWORD.INVALID_TOKEN': 'Zahtjev za novu lozinku istekao',
  'RESET_PASSWORD.SEND_NEW_REQUEST': 'Pošaljite novi zahtjev',
  'RESET_PASSWORD.RESET_DONE': 'Nova lozinka uspješno aktivirana',

  // ---------- User registration & activation
  'REGISTRATION.CHECK_BOX_TERMS_OF_USE': 'Uvjeti korištenja',
  'REGISTRATION.CHECK_BOX_GDPR_DESCRIPTION': 'Klikom na gumb "Kreirajte račun" potvrđujete da ste stariji od 16 godina, poslovno sposobni i ovlašteni raspolagati danim podacima.',
  'REGISTRATION.INFO_TEXT_1': 'Zahtjev za korisnički račun poslan na e-mail adresu',
  'REGISTRATION.INFO_TEXT_2': 'Provjerite Vaše e-mail poruke i slijedite upute',
  'REGISTRATION.EMAIL_LABEL': 'E-mail adresa kojom ćete potvrditi korisnički račun i prijavljivati se',
  'REGISTRATION.NEW_PASSWORD_LABEL': 'Lozinka',
  'REGISTRATION.CONFIRM_PASSWORD_LABEL': 'Potvrda lozinke',
  'REGISTRATION.NEW_PASSWORD_PLACEHOLDER': 'Unesite lozinku',
  'REGISTRATION.CONFIRM_PASSWORD_PLACEHOLDER': 'Unesite ponovno lozinku',
  'REGISTRATION.USERNAME_ALREADY_IN_USE': 'E-mail adresa se već koristi. Unesite neku drugu e-mail adresu.',
  'ACTIVATION.MISSING_TOKEN': 'Neispravan poziv za aktiviranje korisničkog računa',
  'ACTIVATION.INVALID_TOKEN': 'Zahtjev za aktiviranje korisničkog računa istekao',
  'ACTIVATION.SEND_NEW_REQUEST': 'Kreirajte novi aktivacijski zahtjev',
  'ACTIVATION.ACTIVATION_DONE': 'Korisnički račun uspješno aktiviran',
  'ACTIVATION.USER_LINK': 'Dopunite svoj profil kako bi mogli zatražiti vaučer',
  'REGISTRATION.BUTTON_SUBMIT_LABEL': 'Kreirajte račun',

  // ---------- Change Password
  'CHANGE_PASSWORD.VIEW_TITLE': 'Promjena lozinke',
  'CHANGE_PASSWORD.OLD_PASSWORD_LABEL': 'Stara lozinka',
  'CHANGE_PASSWORD.OLD_PASSWORD_PLACEHOLDER': 'Unesite staru lozinku',
  'CHANGE_PASSWORD.NEW_PASSWORD_LABEL': 'Nova lozinka',
  'CHANGE_PASSWORD.NEW_PASSWORD_PLACEHOLDER': 'Unesite novu lozinku',
  'CHANGE_PASSWORD.CONFIRM_PASSWORD_LABEL': 'Potvrda nove lozinke',
  'CHANGE_PASSWORD.CONFIRM_PASSWORD_PLACEHOLDER': 'Unesite ponovno novu lozinku',
  'CHANGE_PASSWORD.CHANGE_PASSWORD_BUTTON_LABEL': 'Promijeni lozinku',
  'CHANGE_PASSWORD.MINIMUM_LENGTH_VALIDATION_MESSAGE': 'Lozinka mora sadržavati minimalno 8 znakova',
  'CHANGE_PASSWORD.PASSWORD_NOT_NEW_ERROR': 'Nova lozinka se mora razlikovati od stare',
  'CHANGE_PASSWORD.PASSWORDS_DIFFERENT_ERROR': 'Nove lozinke nisu identične',
  'CHANGE_PASSWORD.CHANGE_DONE': 'Lozinka uspješno promijenjena',
  'CHANGE_PASSWORD.ERROR_MESSAGE.BAD_CREDENTIALS': 'Pogrešna stara lozinka',
  'CHANGE_PASSWORD.ERROR_MESSAGE.CHANGE_PASSWORD': 'Greška prilikom promjene lozinke',

  // ---------- Reports
  'REPORTS_NAVIGATION.COURSES_MENU_LABEL': 'Izvještaji tečajeva',
  'REPORTS_NAVIGATION.USERS_MENU_LABEL': 'Izvještaji polaznika',
  'REPORTS_NAVIGATION.REPORTS_MENU_LABEL': 'Preuzimanje izvještaja',
  'REPORTS_NAVIGATION.EDUCATION_APPLICATION_MENU_LABEL': 'Zahtjevi',
  'REPORTS_COURSES.NO_ATTENDEES_MESSAGE': 'Ovom tečaju nisu pridijeljeni polaznici',

  'COURSES_REPORT.TODO_LABEL': 'Nezapočeto',
  'COURSES_REPORT.INPROGRESS_LABEL': 'U tijeku',
  'COURSES_REPORT.COMLETED_LABEL': 'Položeno',

  'REPORTS_LIST.TABLE_EMPTY': 'Nema raspoloživih izvještaja, zatražite novi',
  'REPORTS_LIST.TABLE_UNFINISHED_REPORT': 'Izvještaj u izradi...',
  'REPORTS_LIST.TABLE_FAILED_REPORT': 'Izrada izvještaja nije uspjela',
  'REPORTS_LIST.TABLE_FILENAME_LABEL': 'Naziv datoteke',
  'REPORTS_LIST.TABLE_TYPE_LABEL': 'Tip izvještaja',
  'REPORTS_LIST.TABLE_REQUESTED_LABEL': 'Korisnik koji je zatražio',
  'REPORTS_LIST.TABLE_DATETIME_LABEL': 'Datum i vrijeme zahtjeva',
  'REPORTS_LIST.DOWNLOAD': 'Preuzmi',
  'REPORTS_LIST.DELETE': 'Ukloni',
  'REPORTS_LIST.TYPE_COURSE': 'Izvještaj pojedinog tečaja',
  'REPORTS_LIST.TYPE_GENERAL_COURSE': 'Izvještaj svih tečajeva',
  'REPORTS_LIST.TYPE_COURSE_TRAINEE': 'Izvještaj svih polaznika',
  'REPORTS_LIST.TYPE_EXTERNAL_EDUCATION_APPLICATION': 'Izvještaj zahtjeva',
  'REPORTS_LIST.CREATE_REPORT_BUTTON_LABEL': 'Zatraži izvještaj',
  'REPORTS_LIST.COURSES_MODAL_PICKER': 'Odaberite tečaj',
  'REPORTS_LIST.EDUCATION_APPLICATION_MODAL_PICKER': 'Odaberite zahtjev',
  // info message
  'REPORTS.CREATE_INFO_MESSAGE': 'Zatražili ste izvještaj. Njegovo kreiranje može potrajati neko vrijeme. Za provjeru je li izvještaj kreiraj, pritisnite gumb za osvježavanje liste izvještaja.',
  'REPORTS.DELETED_MESSAGE': 'Izvještaj je obrisan',
  // error message
  'REPORTS_LIST.ERROR_MESSAGE.REPORT_NOT_CREATED': 'Greška pri brisanju, izvještaj nije kreiran',

  // ---------- Administration
  'ADMINISTRATION_NAVIGATION.USERS_MENU_LABEL': 'Korisnici',
  'ADMINISTRATION_NAVIGATION.USERS_MANAGEMENT_MENU_LABEL': 'Upravljanje korisnicima',
  'ADMINISTRATION_NAVIGATION.WORKPOSITION_MENU_LABEL': 'Upravljanje radnim mjestima',
  'ADMINISTRATION_NAVIGATION.APPROVAL_COMMENT_MENU_LABEL': 'Odobravanje komentara',
  'ADMINISTRATION_NAVIGATION.RESULTS_MENU_LABEL': 'Rezultati ispita',
  'ADMINISTRATION_NAVIGATION.NOTIFICATIONS_MENU_LABEL': 'Obavijesti',
  'ADMINISTRATION_NAVIGATION.USER_ACTIVITIES_MENU_LABEL': 'Aktivnosti na platformi',
  'ADMINISTRATION_NAVIGATION.EMAIL_TEMPLATES_MENU_LABEL': 'E-mail predlošci',
  'ADMINISTRATION_NAVIGATION.USER_GROUPS_MENU_LABEL': 'Korisničke grupe',
  'ADMINISTRATION_NAVIGATION.SKILL_GROUPS_MENU_LABEL': 'Vještine',
  'ADMINISTRATION_NAVIGATION.USER_GROUPS': 'Korisničke grupe',
  'ADMINISTRATION_NAVIGATION.SURVEY_TEMPLATE_MENU_LABEL': 'Predlošci upitnika',
  'ADMINISTRATION_NAVIGATION.CODEBOOKS_MENU_LABEL': 'Šifrarnici',
  'ADMINISTRATION_NAVIGATION.TENANT_MENU_LABEL': 'Tenant',
  'ADMINISTRATION_NAVIGATION.TENANT_ORGANIZATION_MENU_LABEL': 'Postavke sustava',
  'ADMINISTRATION_NAVIGATION.ORGANIZATIONS_MENU_LABEL': 'Organizacije',
  'ADMINISTRATION.WORKPOSITION_UPDATE_COURSES_BUTTON_LABEL': 'Dodaj tečajeve',
  'ADMINISTRATION.WORKPOSITION_UPDATE_NAME_LABEL': 'Naziv',
  'ADMINISTRATION.WORKPOSITION_UPDATE_MODAL_TITLE': 'Uredi radno mjesto',
  'ADMINISTRATION.WORKPOSITION_UPDATE_SKILLS_PANEL_LABEL': 'Vještine',
  'ADMINISTRATION.WORKPOSITION_UPDATE_ADD_SKILL_BUTTON_LABEL': 'Pridruži vještinu',
  'ADMINISTRATION.CONTAINER_CREATE_WORKPOSITION': 'Novo radno mjesto',
  'ADMINISTRATION.UPDATE_WORKPOSITION': 'Radno mjesto',
  'ADMINISTRATION.WORKPOSITION_CRREATED_MESSAGE': 'Radno mjesto je uspješno kreirano',
  'ADMINISTRATION_NAVIGATION.SKILLS_GAP_MENU_LABEL': 'Skills gap',

  // ---------- User Activities
  'USER_ACTIVITY.TYPE.LEFT_COMMENT': 'Komentari',
  'USER_ACTIVITY.TYPE.COMPLETED_COURSE': 'Tečaj završen',
  'USER_ACTIVITY.TYPE.ADDED_VOTE': 'Glasovi',
  'USER_ACTIVITY.TYPE.CHANGED_VOTE': 'Promijenjeni glasovi',
  'USER_ACTIVITY.TYPE.REMOVED_VOTE': 'Uklonjeni glasovi',
  'USER_ACTIVITY.TYPE.CREATED_REMINDER': 'Podsjetnici',
  'USER_ACTIVITY.LIST_LABEL': 'Aktivnosti na platformi',
  'USER_ACTIVITY.LIST_EMPTY': 'Nema nedavnih aktivnosti',

  // ---------- User Activities List
  'USER_ACTIVITY_LIST.EMPTY_DATA_LABEL': 'Nema aktivnosti',
  'USER_ACTIVITY_LIST.TIME_COLUMN_TITLE': 'Datum',
  'USER_ACTIVITY_LIST.TIME_TARGET_USER_COLUMN_TITLE': 'Primatelj',
  'USER_ACTIVITY_LIST.ACTIVITY_COLUMN_TITLE': 'Aktivnost',
  'USER_ACTIVITY_LIST.TIMELINE_ACTION.LEFT_COMMENT': 'je ostavio/la komentar na',
  'USER_ACTIVITY_LIST.TIMELINE_ACTION.COMPLETED_COURSE': 'je završio/la tečaj',
  'USER_ACTIVITY_LIST.TIMELINE_ACTION.CREATED_REMINDER': 'je napravio podsjetnik.',
  'USER_ACTIVITY_LIST.TIMELINE_ACTION.ADDED_VOTE': 'je dodao/la glas na',
  'USER_ACTIVITY_LIST.TIMELINE_ACTION.CHANGED_VOTE': 'je promijenio/la glas na',
  'USER_ACTIVITY_LIST.TIMELINE_ACTION.REMOVED_VOTE': 'je uklonio/la glas na',
  'USER_ACTIVITY_LIST.USER': 'Korisnik',

  // ---------- Activity filter
  'USER_ACTIVITY_LIST_FILTER.USER_LABEL': 'Korisnik',
  'USER_ACTIVITY.TYPE.ALL_TYPES': 'Sve aktivnosti',
  'USER_ACTIVITY_LIST_FILTER.START_DATE_LABEL': 'Datum od',
  'USER_ACTIVITY_LIST_FILTER.END_DATE_LABEL': 'Datum do',
  'USER_ACTIVITY_LIST_FILTER.ACTIVITY_TYPE_LABEL': 'Vrsta',
  'USER_ACTIVITY_LIST_FILTER.USER_NAME_PLACEHOLDER': 'Upišite dio imena/prezimena',

  // ---------- Diagrams
  'DIAGRAMS.ACTION_EDIT': 'Uredi dijagram',
  'DIAGRAMS.ACTION_CREATE': 'Novi dijagram',

  // ---------- Education group list
  'EDUCATION_GROUP_LIST.VIEW_TITLE': 'Program obrazovanja',
  'EDUCATION_GROUP_LIST.CREATE_GROUP_TITLE': 'Dodaj novi predmet',
  'EDUCATION_GROUP_LIST.GROUP_TITLE': 'Predmet',

  // ---------- Education group
  'EDUCATION_GROUP.CURRICULUM': 'Kurikulum',
  'EDUCATION_GROUP.CHECK': 'Pregledaj',
  'EDUCATION_GROUP.ADD_EXAM_TEMPLATE': 'Predložak ispita',
  'EDUCATION_GROUP.ADD_COURSE': 'Dodaj tečaj',
  'EDUCATION_GROUP.ITEM.TYPE': 'Tip',
  'EDUCATION_GROUP.ITEM.TYPE.EXAM_TEMPLATE': 'Predložak ispita',
  'EDUCATION_GROUP.ITEM.TYPE.COURSE': 'Tečaj',
  'EDUCATION_GROUP.ITEM.TYPE.EXAM_INSTANCE': 'Ispit',
  'EDUCATION_GROUP.ITEM.TITLE': 'Naslov',

  // ---------- Exam template
  'EXAM_TEMPLATE.EXAM_TEMPLATE_HEADER_LABEL': 'Predložak ispita',
  'EXAM_TEMPLATE.CREATE_TITLE': 'Kviz: %{examTemplateTitle}',
  'EXAM_TEMPLATE.TITLE': 'Naslov predloška ispita',
  'EXAM_TEMPLATE.VIEW_QUESTION_LIST': 'Pregledaj listu pitanja',
  'EXAM_TEMPLATE.VIEW_ASSIGNED_EXAMS': 'Pregled dodijeljenih ispita',
  'EXAM_TEMPLATE.GENERATE_EXAMS': 'Generiraj ispite',
  'EXAM_TEMPLATE.GENERATE_EXAMS_INFO_MESSAGE': 'Za generiranje ispita potrebno je dodati korisnike',
  'EXAM_TEMPLATE.OUTCOME': 'Ishod',
  'EXAM_TEMPLATE.EDIT_BUTTON_LABEL': 'Uredite predložak ispita',
  'EXAM_TEMPLATE.MODAL_TITLE': 'Predložak ispita %{examTemplateTitle}',
  'EXAM_TEMPLATE.MODAL_INSTRUCTION_TITLE': 'Unesite postupak',
  'EXAM_TEMPLATE.MODAL_SETTINGS_NUMBER_OF_QUESTIONS_TITLE': 'Broj pitanja za rješavanje',
  'EXAM_TEMPLATE.MODAL_SETTINGS_ANSWERS_VISIBLE_TITLE': 'Vidljivost odgovora',
  'EXAM_TEMPLATE.MODAL_SETTINGS_SKIP_EVALUATION_TITLE': 'Preskoči ocjenjivanje',
  'EXAM_TEMPLATE.MODAL_SETTINGS_SHOW_OUTCOMES_TITLE': 'Prikaži ishode',
  'EXAM_TEMPLATE.MODAL.OUTCOME': 'Ishod',
  'EXAM_TEMPLATE.MODAL.MAXIMUM_NUMBER_OF_QUESTIONS': 'Maksimalan broj pitanja',
  'EXAM_TEMPLATE.MODAL.NUMBER_OF_QUESTIONS_IN_EXAM': 'Broj pitanja u ispitu',
  'EXAM_TEMPLATE.MODAL.CREATE_QUESTION_LIST': 'Kreiraj listu pitanja',
  'EXAM_TEMPLATE.MODAL.ALLOWED_NUMBER_MESSAGE': 'Dozvoljeni broj pitanja je od %{min} do %{max}',
  'EXAM_TEMPLATE.EXAM_STATUS_FINISHED_INFO_LABEL': 'Tečaj je objavljen',

  'EXAM_TEMPLATE.STATISTICS.CORRECTNESS_PERCENTAGE_CORRECT': 'Točno',
  'EXAM_TEMPLATE.STATISTICS.CORRECTNESS_PERCENTAGE_INCORRECT': 'Netočno',
  'EXAM_TEMPLATE.STATISTICS.STATISTICS_TITLE': 'Statistike kviza',
  'EXAM_TEMPLATE.STATISTICS.SUBMITED_COUNT_TITLE': 'Broj rješenih ispita',
  'EXAM_TEMPLATE.STATISTICS.AVERAGE_COMPLETION_TIME_TITLE': 'Prosječno vrijeme rješavanja',
  'EXAM_TEMPLATE.STATISTICS.QUESTION_CORRECTNESS_PERCENTAGE_TITLE': 'Postotak točnih odgovora',
  'EXAM_TEMPLATE.STATISTICS.QUESTION_LIST_CORRECTNESS_PERCENTAGE_TITLE': 'Točnost odgovora po pitanjima',

  'EXAM_TEMPLATE.STATISTICS.QUIZ_CORRECTNESS_PERCENTAGE_TITLE': 'Točnost rješavanja kviza',
  'EXAM_TEMPLATE.STATISTICS.QUESTION_ORDERING_TITLE': 'Redni broj',
  'EXAM_TEMPLATE.STATISTICS.QUESTION_TEXT_TITLE': 'Tekst pitanja',
  'EXAM_TEMPLATE.STATISTICS.QUESTION_CORRECTNESS_TITLE': 'Točnost',

  // ---------- Exam instance
  'EXAM_INSTANCE.LIST.TRAINEE_LABEL': 'Student/ica',
  'EXAM_INSTANCE.LIST.ASSIGNED_EXAM_LABEL': 'Dodjeljeni ispiti',
  'EXAM_INSTANCE.LIST.STATUS_LABEL': 'Status',
  'EXAM_INSTANCE.LIST_ITEM.STATUS.READY': 'Dodijeljen',
  'EXAM_INSTANCE.LIST_ITEM.STATUS.ANSWERS_SUBMITTED': 'Predan',
  'EXAM_INSTANCE.LIST_ITEM.STATUS.NEVER_STARTED': 'Nije riješen, a dostupnost je prošla',
  'EXAM_INSTANCE.LIST_ITEM.STATUS.EXTERNAL_EDUCATION_APPLICATION_CREATION': 'Izrada u tijeku',
  'EXAM_INSTANCE.LIST_ITEM.STATUS.FINISHED': 'Završeno kreiranje',
  'EXAM_INSTANCE.LIST_ITEM.STATUS.ARCHIVED': 'Arhivirano',
  'EXAM_INSTANCE.LIST_ITEM.STATUS.NOT_STARTED': 'Izrađen, nije još dostupan za rješavanje',
  'EXAM_INSTANCE.LIST_ITEM.STATUS.ANSWERING_STARTED': 'Rješavanje započeto',
  'EXAM_INSTANCE.LIST_ITEM.STATUS.IN_EVALUATION': 'Ocjenjivanje u tijeku',
  'EXAM_INSTANCE.LIST_ITEM.STATUS.EVALUATED': 'Ocijenjen',
  'EXAM_INSTANCE.LIST_ITEM.STATUS.CANCELLED': 'Otkazan',
  'EXAM_INSTANCE.LIST.EARNED_POINTS_LABEL': 'Bodovi',
  'EXAM_INSTANCE.LIST.TIME_LABEL': 'Vrijeme',
  'EXAM_INSTANCE.LIST.ACTION_LABEL': 'Akcija',
  'EXAM_INSTANCE.LIST_ITEM.BUTTON.GRADE_EXAM_LABEL': 'Ocijeni ispit',
  'EXAM_INSTANCE.LIST_ITEM.BUTTON.VIEW_EVALUATION_LABEL': 'Pregledaj evaluaciju',
  'EXAM_INSTANCE_FROM.ASSIGNEE': 'Ispitanik:',
  'EXAM_INSTANCE.BUTTON_SUBMIT_LABEL': 'Predaj ispit',
  'EXAM_INSTANCE.CONFIRM_DIALOG_MESSAGE': 'Započni rješavati ispit',
  'EXAM_INSTANCE.EVALUATION_MESSAGE_INFO': 'Ispit je u procesu ocijenjivanja',

  // ----------- Notification
  'NOTIFICATION.TITLE': 'Naziv obavijesti',
  'NOTIFICATION.TEXT': 'Tekst obavijesti',
  'NOTIFICATION.NEW_NOTIFICATION': 'Nova obavijest',
  'NOTIFICATION_FORM.MESSAGE_LABEL': 'Tekst',
  'NOTIFICATION_FORM.TITLE_LABEL': 'Naziv',
  'NOTIFICATION.CREATED_MESSAGE': 'Notifikacija je kreirana',
  'NOTIFICATION.DELETED_MESSAGE': 'Notifikacija je obrisana',

  // ---------- Reminders
  'REMINDER.CREATE_BUTTON_LABEL': 'Dodaj podsjetnik',
  'REMINDER_FORM.LABEL': 'Podsjetnik',
  'REMINDER_FORM.DATE': 'Datum',
  'REMINDER_FORM.TIME': 'Vrijeme',
  'REMINDER_FORM.DESCRIPTION': 'Tekst podsjetnika',
  'REMINDER_FORM.DATE_VALIDATION_MESSAGE': 'Molimo odaberite datum',
  'REMINDER_FORM.TIME_VALIDATION_MESSAGE': 'Molimo odaberite vrijeme',
  'REMINDER_FORM.DESCRIPTION_VALIDATION_MESSAGE': 'Molimo unesite opis',

  'REMINDER_CALENDAR.TITLE_LABEL': 'Vaš kalendar',
  'REMINDER_CALENDAR.NEXT_MONTH': 'Sljedeći mjesec (%{month})',
  'REMINDER_CALENDAR.PREVIOUS_MONTH': 'Prethodni mjesec (%{month})',
  'REMINDER_CALENDAR.UPCOMING_EVENTS': 'Podsjetnici za %{month}. mjesec',
  'REMINDER_CALENDAR.EMPTY_MESSAGE': 'Nema podsjetnika',
  'REMINDER_CALENDAR.SELECTED_DAY': 'Odabrani dan',
  'REMINDER_TYPE.USER_DEFINED': 'Podsjetnik',
  'REMINDER_TYPE.WEBINAR': 'Webinar',
  'REMINDER_TYPE.CONSULTATION': 'Konzultacije',
  'REMINDER.SET_REMINDER_TOOLTIP': 'Postavite podsjetnik',
  'REMINDER.DELETED_MESSAGE': 'Podsjetnik je obrisan',
  'REMINDER.CREATED_MESSAGE': 'Podsjetnik je kreiran',

  // ---------- User registration modal
  'USER_REGISTRATION.MODAL.TITLE': 'Korisnički podatci',
  'USER_REGISTRATION.MODAL.WELCOME': 'Dobrodošli %{firstName} %{lastName}! Molimo Vas provjerite i nadopunite Vaše podatke.',
  'USER_REGISTRATION.FORM.GDPR_BUTTON_LABEL': 'Pročitajte "Uvjete korištenja i pravila privatnosti"',
  // error message
  'USER_REGISGTRATION.FORM.ERROR_MESSAGE.GDPR_REQUIRED': 'Molimo Vas da pročitate i prihvatite "Uvjete korištenja i pravila privatnosti"',

  // ---------- Gdpr
  'GDPR_MODAL.TITLE': 'Uvjeti korištenja i pravila privatnosti',

  // ---------- Webinar list
  'WEBINAR_LIST_VIEW.TABS.ANOUNCED': 'U najavi',
  'WEBINAR_LIST_VIEW.TABS.FINISHED': 'Održani/Završeni',

  'WEBINAR_LIST.VIEW_TITLE': 'Webinari',
  'WEBINAR_LIST_VIEW.NO_DATA': 'Popis webinara je prazan',
  'WEBINAR_LIST_VIEW.CREATE_WEBINAR': 'Izradi webinar',
  'WEBINAR_LIST_VIEW.EDIT_WEBINAR': 'Uredi webinar',
  'WEBINAR_LIST_VIEW.ALL_WEBINARS': 'Svi webinari',
  'WEBINAR_LIST_VIEW.SORT_START_DATE_ASCEND': 'Vrijeme početka uzlazno',
  'WEBINAR_LIST_VIEW.SORT_START_DATE_DESCEND': 'Vrijeme početka silazno',

  // ---------- Webinar view
  'WEBINAR_VIEW.DATE': 'Datum',
  'WEBINAR_VIEW.STATUS': 'Status',
  'WEBINAR_VIEW.HOST': 'Gost',
  'WEBINAR_VIEW.DESCRIPTION': 'O webinaru',
  'WEBINAR_VIEW.SET_REMINDER': 'Postavite podsjetnik',
  'WEBINAR_VIEW.CREATE.CREATE_WEBINAR_TITLE': 'Novi webinar',
  'WEBINAR_VIEW.CREATE.WEBINAR_TITLE': 'Naslov',
  'WEBINAR_VIEW.CREATE.WEBINAR_LINK': 'Poveznica na webinar',
  'WEBINAR_VIEW.CREATE.WEBINAR_DATE': 'Datum',
  'WEBINAR_VIEW.CREATE.WEBINAR_TIME': 'Vrijeme',
  'WEBINAR_VIEW.CREATE.WEBINAR_GUEST': 'Gost',
  'WEBINAR_VIEW.CREATE.WEBINAR_DESCRIPTION': 'Opis/tema',
  'WEBINAR_VIEW.CREATE.WEBINAR_PHOTO': 'Fotografija',
  'WEBINAR_VIEW.CREATE.WEBINAR_DATE_PLACEHOLDER': 'Unesite datum početka',
  'WEBINAR_VIEW.CREATE.WEBINAR_TIME_PLACEHOLDER': 'Unesite vrijeme početka',
  'WEBINAR_VIEW.CREATE.WEBINAR_STATUS': 'Status',
  'WEBINAR_VIEW.COMMENTS': 'Komentari',
  'WEBINAR_VIEW.NOTES': 'Bilješke',
  'WEBINAR.STATUS_TITLE.IN_CREATION': 'U izradi',
  'WEBINAR.STATUS_TITLE.CREATION_FINISHED': 'Izrada završena',
  'WEBINAR.STATUS_TITLE.IN_PROGRESS': 'U tijeku',
  'WEBINAR.STATUS_TITLE.FINISHED': 'Završen',
  'WEBINAR.STATUS_TITLE.CANCELLED': 'Otkazan',
  'WEBINAR.STATUS_TITLE.PUBLISHED': 'Objavljen',
  'WEBINAR.STATUS_TITLE.ARCHIVED': 'Arhiviran',

  // ---------- Notifications
  'NOTIFICATIONS.NOTIFICATIONS': 'Obavijesti',
  'NOTIFICATIONS.SHOW_ALL': 'Prikaži sve',
  'NOTIFICATIONS.CREATE_NOTIFICATION': 'Nova obavijest',
  'NOTIFICATION_LIST.DATE_TIME': 'Datum i vrijeme',
  'NOTIFICATION_LIST.NOTIFICATION_TITLE': 'Naslov',
  'NOTIFICATION_LIST.NOTIFICATION_MESSAGE': 'Tekst',
  'NOTIFICATION_LIST.NO_DATA': 'Nema podataka',
  'NOTIFICATION_LIST.SEARCH_NOTIFICATIONS': 'Pretraži',
  'NOTIFICATION_LIST_FILTER.SORT_BY_TYPE': 'Obavijesti po tipu',
  'NOTIFICATION_LIST_FILTER.SELECTOR_OPTION.ALL_NOTIFICATIONS': 'Sve obavijesti',
  'NOTIFICATION_FORM.PERSONAL_TYPE': 'Personalizirane',
  'NOTIFICATION_FORM.GENERIC_TYPE': 'Opće',
  'NOTIFICATION_LIST.NOTIFICATION_TYPE': 'Tip',

  // ------------ Quiz
  'QUIZ_CREATOR.MODAL_TITLE': 'Uređivač pitanja',
  'QUIZ_CREATOR.QUESTION_SUCCESS_INFO_MESSAGE': 'Pitanje je uspješno spremljeno',
  'QUIZ_CREATOR.QUESTION_ERROR_INFO_MESSAGE': 'Pitanje nije spremljeno, unesite moguće odgovore na kreirano pitanje',

  // ---------- Repository
  'REPOSITORY.TITLE': 'Baza znanja',
  'REPOSITORY.DOCUMENTS_LABEL': 'Dokumenti',
  'REPOSITORY.DOCUMENTS_EMPTY_LABEL': 'Nema dokumenata',
  'REPOSITORY.COURSES_LABEL': 'Tečajevi',
  'REPOSITORY.WEBINARS_LABEL': 'Webinari',
  'REPOSITORY.USERS_LABEL': 'Korisnici',
  'REPOSITORY.USERS_EMPTY_LABEL': 'Nema korisnika',
  'REPOSITORY.TAG_FILTER_LABEL': 'Oznake',
  'REPOSITORY.SELECTED_TAGS_LABEL': 'Odabrane oznake',
  'REPOSITORY.TAG_FILTER_SEARCH_LABEL': 'Pretražite oznake',
  'REPOSITORY.TAG_HEADER_LABEL': 'Oznake',
  'REPOSITORY.FILE_ADD_LABEL': 'Nova datoteka',
  'REPOSITORY.FILE_REMOVED_MESSAGE': 'Datoteka je obrisana',

  // ---------- Tags
  'TAG_CONTAINER.ADD_TAG': 'Dodaj oznaku',
  // error messages
  'TAG_ERROR_MESSAGE.TITLE_ALREADY_IN_USE': 'Naziv se već koristi. Molimo odaberite drugi.',

  // ---------- Tenant Configuration
  'TENANT_CONFIGURATION_VIEW.DISPLAY_NAME_LABEL': 'Naziv',
  'TENANT_CONFIGURATION_VIEW.LOGO_IMAGE_LABEL': 'Logo',
  'TENANT_CONFIGURATION_VIEW.ENABLE_WEBINARS_LABEL': 'Korištenje webinara',
  'TENANT_CONFIGURATION_VIEW.ENABLE_LOCALES_LABEL': 'Omogući višejezičnost',
  'TENANT_CONFIGURATION_VIEW.ENABLE_COURSES_LABEL': 'Korištenje tečajeva',
  'TENANT_CONFIGURATION_VIEW.ENABLE_DASHBOARD_LABEL': 'Korištenje dashboarda',
  'TENANT_CONFIGURATION_VIEW.ENABLE_EXTERNAL_CONTENTS_LABEL': 'Korištenje vanjskih poveznica',
  'TENANT_CONFIGURATION_VIEW.ENABLE_REPOSITORY_LABEL': 'Korištenje baze znanja',
  'TENANT_CONFIGURATION_VIEW.ENABLE_USER_GROUPS_LABEL': 'Korištenje grupe korisnika',
  'TENANT_CONFIGURATION_VIEW.ENABLE_USER_GROUP_COURSES_LABEL': 'Dodavanje tečajeva grupama korisnika',
  'TENANT_CONFIGURATION_VIEW.STYLE_THEME_LABEL': 'Tema',
  'TENANT_CONFIGURATION_VIEW.BACKGROUND_IMAGE_LABEL': 'Pozadinska slika',
  'TENANT_CONFIGURATION_VIEW.ENABLE_EXTERNAL_EDUCATION_APPLICATIONS_LABEL': 'Korištenje zahtjeva',
  'TENANT_CONFIGURATION_VIEW.ENABLE_EXTERNAL_EDUCATIONS_LABEL': 'Korištenje vanjskih edukacija',
  'TENANT_CONFIGURATION_VIEW.DISPLAY_TERMS_LABEL': 'Uvjeti sporazuma',
  'TENANT_CONFIGURATION_VIEW.FOOTER_LABEL': 'Podnožje',
  'TENANT_CONFIGURATION_VIEW.JAVASCRIPT_LABEL': 'Javascript',
  'TENANT_CONFIGURATION_VIEW.JAVASCRIPT_TOOLTIP': 'Upišite čisti JavaScript kod bez <script> taga',
  'TENANT_CONFIGURATION_VIEW.ENABLE_INTEGRATION_VIDEO': 'Video integracija (Vimeo)',
  'TENANT_CONFIGURATION_VIEW.EXAM_QUESTION_LIST': 'Vrste pitanja u kreatoru ispita',
  'TENANT_CONFIGURATION_VIEW.SURVEY_QUESTION_LIST': 'Vrste pitanja u kreatoru upitnika',
  // --- admin tabs
  'TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_WORKPOSITIONS': 'Administracija radnih mjesta',
  'TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_SURVEYS': 'Administracija upitnika',
  'TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_SKILLS': 'Administracija vještina',
  'TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_SKILL_LEVELS': 'Administracija razina vještina',
  'TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_EMAILS': 'Administracija email predložaka',
  'TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_ACTIVITIES': 'Administracija aktivnosti',
  'TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_NOTIFICATIONS': 'Administracija obavijesti',
  'TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_COMMENTS': 'Administracija komentara',
  'TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_CODEBOOKS': 'Administracija šifrarnika',

  // ---------- External content
  'EXTERNAL_CONTENT.LIST.TITLE': 'Vanjske poveznice',
  'EXTERNAL_CONTENT.LIST.FILTER_PLACEHOLDER': 'Pretraži poveznice',
  'EXTERNAL_CONTENT.LIST.EMPTY_MESSAGE': 'Nema vanjskih poveznica',
  'EXTERNAL_CONTENT.LIST.ADD': 'Nova poveznica',
  'EXTERNAL_CONTENT.CREATE.TITLE': 'Popis vanjskih sadržaja',
  'EXTERNAL_CONTENT.FORM.TITLE_LABEL': 'Naslov',
  'EXTERNAL_CONTENT.FORM.URL_LABEL': 'Poveznica',
  'EXTERNAL_CONTENT.FORM.DESCRIPTION_LABEL': 'Opis',
  'EXTERNAL_CONTENT.FORM.COVER_LABEL': 'Slika',
  'EXTERNAL_CONTENT.FORM.TITLE_WHITESPACE_MESSAGE': 'Unesite ispravan naslov',
  'EXTERNAL_CONTENT.FORM.TITLE_REQUIRED_MESSAGE': 'Unesite naslov',
  'EXTERNAL_CONTENT.FORM.URL_REGEX_MESSAGE': 'Unesite ispravnu poveznicu',
  'EXTERNAL_CONTENT.FORM.URL_REQUIRED_MESSAGE': 'Unesite poveznicu',
  'EXTERNAL_CONTENT.FORM.DESCRIPTION_WHITESPACE_MESSAGE': 'Unesite ispravan opis',
  'EXTERNAL_CONTENT.DELETED_MESSAGE': 'Vanjska poveznica je obrisana',

  // ---------- Email template
  'EMAIL_TEMPLATE_LIST.EMAIL_TEMPLATE_TITLE': 'Naslov',
  'EMAIL_TEMPLATE_LIST.EMAIL_TEMPLATE_CONTENT': 'Tekst',
  'EMAIL_TEMPLATE_LIST.EMAIL_TEMPLATE_TYPE': ' Vrsta',
  'EMAIL_TEMPLATE_FORM.CODE_GENERATION_TYPE': 'Generiranje kodova',
  'EMAIL_TEMPLATE_LIST.NO_DATA': 'Nema podataka',
  'EMAIL_TEMPLATE_FORM.INACTIVE_USER_TYPE': 'Neaktivnost korisnika',
  'EMAIL_TEMPLATE_FORM.TITLE_LABEL': 'Naslov e-mail predloška:',
  'EMAIL_TEMPLATE_FORM.CONTENT_LABEL': 'Tekst e-mail predloška:',
  'EMAIL_TEMPLATE_FORM.TYPE_LABEL': 'Odaberite tip e-mail predloška:',
  'EMAIL_TEMPLATE_FILTER.ALL_TYPES_LABEL': 'Svi tipovi',
  'EMAIL_TEMPLATE.ADD_EMAIL_TEMPLATE_LABEL': 'Dodaj e-mail predložak',
  'EMAIL_TEMPLATE.CREATE_EMAIL_TEMPLATE_TITLE': 'Kreiraj e-mail predložak',
  'EMAIL_TEMPLATE.EDIT_EMAIL_TEMPLATE_TITLE': 'Uredi e-mail predložak',
  'EMAIL_TEMPLATE.DELETED_MESSAGE': 'Predložak je obrisan',
  'EMAIL_TEMPLATE_LIST.EMAIL_TEMPLATE_CONTENT_LINK_LABEL': 'Prikaži sadržaj',
  // error messages
  'EMAIL_TEMPLATE_ERROR_MESSAGE.TITLE_ALREADY_IN_USE': 'Naslov se već koristi. Molimo odaberite drugi.',

  // ---------- Member list
  'MEMBER_LIST.ROLE_LABEL': 'Uloga',
  'MEMBER_LIST.USER_LABEL': 'Korisnik',
  'MEMBER_LIST.ROLE_PICKER_PLACEHOLDER_LABEL': 'Odaberite ulogu',
  'MEMBER_LIST.USER_PICKER_PLACEHOLDER_LABEL': 'Odaberite korisnika',

  // ---------- Type picker
  'TYPE_PICKER.LIST_TYPE_LABEL': 'Prikaz',
  'TYPE_PICKER.LIST_TYPE_CARD_LABEL': 'Kartice',
  'TYPE_PICKER.LIST_TYPE_TABLE_LABEL': 'Lista',

  // ---------- User group
  'USER_GROUP.LEAVE_CONFIRM_TITLE': 'Sigurno želite napustiti grupu?',
  'USER_GROUP.COMMON_ACTION_LEAVE': 'Napusti grupu',
  'USER_GROUP.COMMON_ACTION_JOIN': 'Pristupi grupi',
  'USER_GROUP.NEW_USER_GROUP_TITLE': 'Nova korisnička grupa',
  'USER_GROUP_VIEW.LABEL': 'Korisničke grupe',
  'USER_GROUP_VIEW.DESCRIPTION_LABEL': 'Opis grupe:',
  'USER_GROUP_VIEW.ACCESS_CODE_LABEL': 'Kod za pozivanje u grupu:',
  'USER_GROUP_VIEW.NAVIGATION_MENU_MEMEBERS_LABEL': 'Članovi',
  'USER_GROUP_VIEW.NAVIGATION_MENU_MATERIALS_LABEL': 'Materijali',
  'USER_GROUP_VIEW.NAVIGATION_MENU_COURSES_LABEL': 'Tečajevi',
  'USER_GROUP_MEMBER_LIST.ROLE_LABEL': 'Uloga:',
  'USER_GROUP_MEMBER_LIST.ROLE_TYPE.OWNER': 'Vlasnik',
  'USER_GROUP_MEMBER_LIST.ROLE_TYPE.ADMIN': 'Administrator',
  'USER_GROUP_MEMBER_LIST.ROLE_TYPE.MEMBER': 'Član',
  'USER_GROUP_LIST_VIEW.MY_GROUPS_TITLE': 'Moje grupe',
  'USER_GROUP_LIST_VIEW.TABS.MY_GROUPS_LABEL': 'Moje grupe',
  'USER_GROUP_LIST_VIEW.TABS.AVAILABLE_GROUP_LIST_LABEL': 'Dostupne grupe',
  'USER_GROUP_LIST_VIEW.FILE_ADD_LABEL': 'Nova datoteka',
  'USER_GROUP.LIST_VIEW.HIERARCHY_TREE_TITLE': 'Organizacijski graf',
  'USER_GROUP.JOIN_BUTTON_LABEL': 'Pridruži se grupi',
  'USER_GROUP.LEAVE_BUTTON_LABEL': 'Napusti grupi',
  // error messages
  'USER_GROUP_ERROR_MESSAGE.NON_EXISTING_USER_GROUP': 'Kod ne odgovara ni jednoj grupi u sustavu',

  // ---------- User group form
  'USER_GROUP_FORM.NAME_LABEL': 'Ime grupe',
  'USER_GROUP_FORM.DESCRIPTION_LABEL': 'Opis',
  'USER_GROUP_FORM.VISIBILITY_LABEL': 'Vidljivost grupe',
  'USER_GROUP_FORM.VISIBILITY_TITLE_PUBLIC': 'Javno',
  'USER_GROUP_FORM.VISIBILITY_TITLE_LIMITED': 'Ograničeno',
  'USER_GROUP_FORM.VISIBILITY_TITLE_CLOSED': 'Privatno',
  'USER_GROUP_FORM.TYPE_LABEL': 'Vrsta korisničke grupe',
  'USER_GROUP_FORM.TYPE_BASIC': 'Osnovna',
  'USER_GROUP_FORM.TYPE_ORGANIZATION': 'Organizacijska',

  'USER_GROUP_ACCESS_FORM.ACCESS_CODE_LABEL': 'Pristupni kod',
  'USER_GROUP_ACCESS_FORM.TITLE': 'Unesite pristupni kod za grupu',
  'USER_GROUP_EDIT_FORM.EDIT_LABEL': 'Uredi korisničku grupu',

  // ---------- Access code form
  'ACCESS_CODE_FORM.JOIN_BUTTON_LABEL': 'Pridruži se',
  'ACCESS_CODE_FORM.LEAVE_BUTTON_LABEL': 'Napusti',
  'ACCESS_CODE_FORM.GENERATE_LABEL': 'Generiranje novog pristupnog koda',
  'ACCESS_CODE_FORM.GENERATE_BUTTON_LABEL': 'Generiraj kod',
  'ACCESS_CODE_FORM.ACCESS_CODE_LABEL': 'Pristupni kod',
  'ACCESS_CODE_FORM.CODE_EXPIRATION_LABEL': 'Datum isteka koda',
  'ACCESS_CODE_FORM.TITLE': 'Unesite pristupni kod',
  'ACCESS_CODE_FORM.URL_LABEL': 'Poveznica',

  // ---------- Skill groups
  'SKILL_GROUP_LIST.SKILL_GROUP_DESCRIPTION_LABEL': 'Područje vještina:',
  'SKILL_GROUP_LIST.SKILL_DESCRIPTION_LABEL': 'Vještina:',
  'SKILL_GROUP_LIST.SKILL_ADD_BUTTON_LABEL': 'Dodaj program stjecanja vještine',
  'SKILL_GROUP_LIST.SKILL_GROUP_ADD_BUTTON_LABEL': 'Dodaj područje vještina',
  // ---------- error message
  'SKILL_GROUP_LIST.ERROR.SKILL_GROUP_ALREADY_EXISTS': 'Već postoji područje vještina s istim imenom, odaberite drugo ime.',
  'SKILL_LIST.ERROR.SKILL_ALREADY_EXISTS': 'Već postoji vještina s istim imenom, odaberite drugo ime.',

  // ---------- Skill list
  'SKILL_LIST.VIEW.SKILL_LEVEL_CLASSIFICATION_BUTTON_LABEL': 'Uredi razine programa vještina',

  // ---------- Skill group modals
  'SKILL_MODAL.SKILL_CREATE_TITLE': 'Novi program stjecanja vještine',
  'SKILL_MODAL.SKILL_UPDATE_TITLE': 'Uredi program stjecanja vještine',
  'SKILL_FORM.SKILL_LEVEL_CLASSIFICATION_LABEL': 'Razine vještine',
  'SKILL_GROUP_MODAL.SKILL_GROUP_CREATE_TITLE': 'Novo područje vještina',
  'SKILL_GROUP_MODAL.SKILL_GROUP_UPDATE_TITLE': 'Uredi područje vještina',

  // ---------- Request
  // ---------- User view section
  'REQUEST.USER_VIEW_SECTION.TITLE': 'O korisniku',
  'REQUEST.USER_VIEW_SECTION.LINK_LABEL': 'Pogledajte profil',
  'REQUEST.USER_VIEW_SECTION.EMPLOYED_LABEL': 'zaposlena osoba',
  'REQUEST.USER_VIEW_SECTION.UNEMPLOYED_LABEL': 'nezaposlena osoba',
  'REQUEST.USER_VIEW_SECTION.EMAIL_LABEL': 'e-mail korisnika',
  'REQUEST.USER_VIEW_SECTION.IDENTIFICATION_NUMBER_LABEL': 'OIB korisnika',
  'REQUEST.USER_VIEW_SECTION.CONSULTATION_LABEL': 'potrebna stručna podrška HZZ savjetnika',
  'REQUEST.USER_VIEW_SECTION.CONSULTATION_CONTACT_LABEL': 'kontakt: %{contact}',
  'REQUEST.USER_VIEW_SECTION.FOOTER_TITLE': 'Datoteke',
  'REQUEST.USER_VIEW_SECTION.CURRICULUM_VITAE_LABEL': 'životopis',
  'REQUEST.USER_VIEW_SECTION.PROFESSIONAL_PLAN_LABEL': 'profesionalni plan',
  'REQUEST.USER_VIEW_SECTION.IDENTIFICATION_NUMBER_MISSING_LABEL': 'Nema unesen OIB',
  'REQUEST.USER_VIEW_SECTION.EMAIL_MISSING_LABEL': 'Nema unesen e-mail',
  'REQUEST.USER_VIEW_SECTION.ADDRESS_LABEL': 'mjesto stanovanja korisnika',
  'REQUEST.USER_VIEW_SECTION.ADDRESS_MISSING_LABEL': 'Korisnik nema unesenu adresu',
  'REQUEST.USER_VIEW_SECTION.BANK_NAME_LABEL': 'Naziv banke',
  'REQUEST.USER_VIEW_SECTION.IBAN_LABEL': 'IBAN korisnika',
  'REQUEST.USER_VIEW_SECTION.EMPLOYER_NAME_LABEL': 'poslodavac',
  'REQUEST.USER_VIEW_SECTION.EMPLOYER_OIB_LABEL': 'OIB poslodavca',
  'REQUEST.USER_VIEW_SECTION.EMPLOYER_EMAIL_LABEL': 'e-mail poslodavca',
  'REQUEST.USER_VIEW_SECTION.EMPLOYER_ADDRESS_LABEL': 'adresa poslodavca',
  'REQUEST.USER_VIEW_SECTION.EMPLOYER_NO_NAME_LABEL': 'Nije unesen naziv poslodavca',
  'REQUEST.USER_VIEW_SECTION.EMPLOYER_NO_OIB_LABEL': 'Nije unesen OIB poslodavca',

  // ---------- Survey template
  'SURVEY_TEMPLATE.TITLE.USER_EDUCATION_COMPLETED': 'Upitnik za polaznika programa obrazovanja (odmah nakon završetka)',
  'SURVEY_TEMPLATE.TITLE.USER_EDUCATION_COMPLETED_CHECK_IN': 'Upitnik za polaznika programa obrazovanja (6 mjeseci nakon završetka)',
  'SURVEY_TEMPLATE.TITLE.EDUCATION_PROVIDER': 'Upitnik za pružatelja programa obrazovanja',
  'SURVEY_TEMPLATE.TITLE.EMPLOYER': 'Upitnik za poslodavca',

  // ---------- Survey instance
  'SURVEY_INSTANCE.SURVEY_TYPE_TITLE.USER_EDUCATION_COMPLETED': 'Upitnik za polaznika programa obrazovanja (odmah nakon završetka)',
  'SURVEY_INSTANCE.SURVEY_TYPE_TITLE.USER_EDUCATION_COMPLETED_CHECK_IN': 'Upitnik za polaznika programa obrazovanja (6 mjeseci nakon završetka)',
  'SURVEY_INSTANCE.SURVEY_TYPE_TITLE.EDUCATION_PROVIDER': 'Upitnik za pružatelja programa obrazovanja',
  'SURVEY_INSTANCE.SURVEY_TYPE_TITLE.EMPLOYER': 'Upitnik za poslodavca',
  'SURVEY_INSTANCE.BUTTON_SUBMIT_LABEL': 'Predaj upitnik',
  // error message
  'SURVEY_INSTANCE.ERROR_MESSAGE.REQUIRED_QUESTION_MISSING_ANSWER': 'Upitnik nije predan, molimo Vas da odgovorite na obavezna pitanja',
  'SURVEY_INSTANCE.ERROR_MESSAGE.SURVEY_MULTIPLE_SUBMISSIONS_NOT_ALLOWED': 'Upitnik je već predan, nije dopušteno više puta odgovarati',
  'SURVEY_INSTANCE.ERROR_MESSAGE.PARTIAL_ANSWER_NOT_ALLOWED': 'Upitnik nije predan, molimo Vas da odgovorite na već započeto pitanje',
  'SURVEY_INSTANCE.ERROR_MESSAGE.TOKEN_INVALID': 'Token je istekao ili je neispravan',

  // ---------- Survey instance feedback
  'SURVEY_INSTANCE_FEEDBACK.SURVEY_TYPE_TITLE.USER_EDUCATION_COMPLETED': 'Predano - Upitnik za polaznika edukacije (odmah nakon završetka)',
  'SURVEY_INSTANCE_FEEDBACK.SURVEY_TYPE_TITLE.USER_EDUCATION_COMPLETED_CHECK_IN': 'Predano - Upitnik za polaznika edukacije (6 mjeseci nakon završetka)',
  'SURVEY_INSTANCE_FEEDBACK.SURVEY_TYPE_TITLE.EDUCATION_PROVIDER': 'Predano - Upitnik za pružatelja edukacije',
  'SURVEY_INSTANCE_FEEDBACK.SURVEY_TYPE_TITLE.EMPLOYER': 'Predano - Upitnik za poslodavca',

  // ----------- External education application details
  'EXTERNAL_EDUCATION_APPLICATION.DETAILS.EDUCATION_START_DATE_TITLE': 'Početak programa obrazovanja',
  'EXTERNAL_EDUCATION_APPLICATION.DETAILS.TITLE': 'Detalji zahtjeva',
  'EXTERNAL_EDUCATION_APPLICATION.DETAILS.JOB_RELATED_TITLE': 'Je li zahtjev vezan uz Vaše radno mjesto?',
  'EXTERNAL_EDUCATION_APPLICATION.DETAILS.EDUCATION_RELATED_TITLE': 'Je li zahtjev vezan uz Vaše obrazovanje?',
  'EXTERNAL_EDUCATION_APPLICATION.DETAILS.SKILL_ACQUISITION_TITLE': 'Program stjecanja vještina',
  'EXTERNAL_EDUCATION_APPLICATION.DETAILS.APPLICATION_DESCRIPTION_TITLE': 'Obrazložite na koji će način završetak predloženog programa utjecati na Vaš razvoj karijere ili mogućnosti zapošljavanja',
  'EXTERNAL_EDUCATION_APPLICATION.DETAILS.CONSULTATION_TITLE': 'Zatraženi savjet i podrška?',

  // ---------- Data table
  'DATA_TABLE.SELECTED_ROWS_COUNT_LABEL': 'Označenih zapisa',
  'DATA_TABLE.SELECTED_ROWS_MORE_ACTIONS_LABEL': 'Više opcija',

  // ---------- Company Form
  'COMPANY.FORM.LABEL': 'Informacije o pružatelju usluga',
  'COMPANY.FORM.SELECTOR_NAME_LABEL': 'Pronađi po nazivu',
  'COMPANY.FORM.SELECTOR_ID_LABEL': 'Pronađi po OIB-u',
  'COMPANY.FORM.NAME_LABEL': 'Naziv tvrtke',
  'COMPANY.FORM.ID_LABEL': 'OIB tvrtke',
  'COMPANY.FORM.CITY_LABEL': 'Mjesto tvrtke',
  'COMPANY.FORM.ZIP_LABEL': 'Poštanski broj tvrtke',
  'COMPANY.FORM.STREET_LABEL': 'Ulica i kućni broj tvrtke',
  'COMPANY.FORM.CONTACT_LABEL': 'E-mail tvrtke',
  'COMPANY.FORM.NAME_VALIDATION_MESSAGE': 'Unesite naziv',
  'COMPANY.FORM.ID_VALIDATION_MESSAGE': 'Unesite OIB',
  'COMPANY.FORM.CITY_VALIDATION_MESSAGE': 'Unesite mjesto',
  'COMPANY.FORM.ZIP_VALIDATION_MESSAGE': 'Unesite poštanski broj',
  'COMPANY.FORM.ADDRESS_VALIDATION_MESSAGE': 'Unesite adresu',
  'COMPANY.FORM.CONTACT_VALIDATION_MESSAGE': 'Unesite e-mail',
  'COMPANY.FORM.VALID_EMAIL_MESSAGE': 'Unesite ispravan e-mail',

  // ---------- External education application list
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.SKILL_HEADER_TITLE': 'Program stjecanja vještine',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EMPLOYER_NAME_HEADER_TITLE': 'Pružatelj usluga',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.ASSIGNEE_RESIDENCE_HEADER_TITLE': 'Mjesto korisnika',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.ASSIGNEE_HEADER_TITLE': 'Korisnik',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.APPLICATION_STATUS_HEADER_TITLE': 'Status',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.APPLICATION_SUBMISSION_DATE_HEADER_TITLE': 'Vrijeme podnošenja',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EVALUATOR_HEADER_TITLE': 'Savjetnik',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.NEW_APPLICATION_BUTTON_LABEL': 'Podnesite novi zahtjev',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.ASSIGNEE_EMPLOYMENT_STATUS_BEFORE_EDUCATION_HEADER_TITLE': 'Status zaposlenosti korisnika prilikom podnošenja zahtjeva',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.HZZ_COORDINATOR_HELP_WANTED_HEADER_TITLE': 'Zatražena podrška i savjet HZZ savjetnika',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EDUCATION_RELEATED_HEADER_TITLE': 'Je li zahtjev vezan uz obrazovanje',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.JOB_RELATED_HEADER_TITLE': 'Je li zahtjev vezan uz radno mjesto',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EDUCATION_DURATION_HEADER_TITLE': 'Trajanje programa',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.ASSIGNEE_GRADE_HEADER_TITLE': 'Ocjena korisnika',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.PROVIDER_GRADE_HEADER_TITLE': 'Ocjena pružatelja usluga',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EMPLOYER_GRADE_HEADER_TITLE': 'Ocjena poslodavca',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EDUCATION_EXPENSE_HEADER_TITLE': 'Trošak programa obrazovanja',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.TRAVEL_EXPENSE_HEADER_TITLE': 'Putni troškovi',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.INSURANCE_EXPENSE_HEADER_TITLE': 'Troškovi osiguranja',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EXPENSE_VALUE_LABEL': '%{value} (bez PDV-a)',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.TRAVEL_AND_INSURANCE_EXPENSE_HEADER_TITLE': 'Putni troškovi i troškovi osiguranja',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EMPLOYMENT_STATUS.EMPLOYED': 'Zaposlen',
  'EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EMPLOYMENT_STATUS.UNEMPLOYED': 'Nezaposlen',

  // ---------- External education application
  'EXTERNAL_EDUCATION_APPLICATION.EMPLOYED_LABEL': 'Zaposlena osoba',
  'EXTERNAL_EDUCATION_APPLICATION.UNEMPLOYED_LABEL': 'Nezaposlena osoba',

  'EXTERNAL_EDUCATION_APPLICATION.FILES.CURRICULUM_VITAE_LABEL': 'Životopis',
  'EXTERNAL_EDUCATION_APPLICATION.FILES.PROFESSIONAL_PLAN_LABEL': 'Profesionalni plan',
  'EXTERNAL_EDUCATION_APPLICATION.FILES.OFFER_LABEL': 'Ponuda',
  'EXTERNAL_EDUCATION_APPLICATION.FILES.CURRICULUM_LABEL': 'Kurikulum',
  'EXTERNAL_EDUCATION_APPLICATION.FILES.FINANCING_AGREEMENT_LABEL': 'Sporazum',
  'EXTERNAL_EDUCATION_APPLICATION.FILES.SIGNED_FINANCING_AGREEMENT_LABEL': 'Potpisani sporazum',
  'EXTERNAL_EDUCATION_APPLICATION.FILES.MUTUALLY_SIGNED_FINANCING_AGREEMENT_LABEL': 'Obostrano potpisani sporazum',
  'EXTERNAL_EDUCATION_APPLICATION.FILES.PROOF_OF_PAYMENT_LABEL': 'Potvrda o plaćanju',
  'EXTERNAL_EDUCATION_APPLICATION.FILES.ATTENDANCE_CERTIFICATE_LABEL': 'Potvrda o prisutnosti',
  'EXTERNAL_EDUCATION_APPLICATION.FILES.COMPETENCIES_CERTIFICATE_LABEL': 'Potvrda o stečenim kompetencijama',
  'EXTERNAL_EDUCATION_APPLICATION.FILES.APPLICATION_EXPORT_LABEL': 'Zahtjev za financiranje obrazovanja',

  'EXTERNAL_EDUCATION_APPLICATION.OWNERSHIP.MY_APPLICATIONS_LABEL': 'Moji zahtjevi',
  'EXTERNAL_EDUCATION_APPLICATION.OWNERSHIP.OTHER_COORDINATORS_APPLICATIONS_LABEL': 'Tuđi zahtjevi',
  'EXTERNAL_EDUCATION_APPLICATION.OWNERSHIP.WITHOUT_COORDINATOR_LABEL': 'Nisu preuzeti',

  'EXTERNAL_EDUCATION_APPLICATION.PHASE.EXTERNAL_EDUCATION_APPLICATION_CREATION_LABEL': 'U izradi',
  'EXTERNAL_EDUCATION_APPLICATION.PHASE.SUBMITTED_LABEL': 'Podnesen',
  'EXTERNAL_EDUCATION_APPLICATION.PHASE.AWAITING_APPROVAL_LABEL': 'Pregled zahtjeva',
  'EXTERNAL_EDUCATION_APPLICATION.PHASE.IN_PROCESS_LABEL': 'Odobren',
  'EXTERNAL_EDUCATION_APPLICATION.PHASE.CONTRACT_GENERATION_LABEL': 'U obradi',
  'EXTERNAL_EDUCATION_APPLICATION.PHASE.AWAITING_SIGNED_CONTRACT_LABEL': 'Potpis sporazuma',
  'EXTERNAL_EDUCATION_APPLICATION.PHASE.AWAITING_PAYMENT_LABEL': 'Uplata u tijeku',
  'EXTERNAL_EDUCATION_APPLICATION.PHASE.EDUCATION_IN_PROGRESS_LABEL': 'Obrazovanje u tijeku',
  'EXTERNAL_EDUCATION_APPLICATION.PHASE.EDUCATION_COMPLETED_LABEL': 'Zaključen',
  'EXTERNAL_EDUCATION_APPLICATION.PHASE.RETURNED_TO_USER_LABEL': 'Vraćen korisniku',
  'EXTERNAL_EDUCATION_APPLICATION.PHASE.USER_CANCELLED_LABEL': 'Korisnik odustao',
  'EXTERNAL_EDUCATION_APPLICATION.PHASE.DENIED_LABEL': 'Neodobren',
  'EXTERNAL_EDUCATION_APPLICATION.PHASE.CONTRACT_TERMINATED_LABEL': 'Poništen',

  'EXTERNAL_EDUCATION_APPLICATION.FORM.STEP_ONE_TITLE': 'Informacije o meni',
  'EXTERNAL_EDUCATION_APPLICATION.FORM.STEP_TWO_TITLE': 'Program obrazovanja',
  'EXTERNAL_EDUCATION_APPLICATION.FORM.STEP_THREE_TITLE': 'Opći uvjeti',
  'EXTERNAL_EDUCATION_APPLICATION.FORM.STEP_FOUR_TITLE': 'Pregled zahtjeva',

  'EXTERNAL_EDUCATION_APPLICATION.BUTTON_PREV_LABEL': 'Prethodni korak',
  'EXTERNAL_EDUCATION_APPLICATION.BUTTON_SAVE_LABEL': 'Spremite za kasnije',
  'EXTERNAL_EDUCATION_APPLICATION.BUTTON_NEXT_LABEL': 'Nastavite ispunjavati zahtjev',
  'EXTERNAL_EDUCATION_APPLICATION.BUTTON_SUBMIT_LABEL': 'Podnesite zahtjev',

  'EXTERNAL_EDUCATION_APPLICATION.SKILL_PANEL_TITLE': 'Vještina koju želite steći',
  'EXTERNAL_EDUCATION_APPLICATION.ABOUT_ME_PANEL_TITLE': 'Informacije o meni',
  'EXTERNAL_EDUCATION_APPLICATION.EMPLOYER_PANEL_TITLE': 'Informacije o mojem poslodavcu',
  'EXTERNAL_EDUCATION_APPLICATION.EDUCATION_PROVIDER_PANEL_TITLE': 'Informacije o pružatelju usluge',
  'EXTERNAL_EDUCATION_APPLICATION.ABOUT_EDUCATION_PANEL_TITLE': 'Program obrazovanja',
  'EXTERNAL_EDUCATION_APPLICATION.TERMS_PANEL_TITLE': 'Opći uvjeti korištenja vaučera',
  'EXTERNAL_EDUCATION_APPLICATION.EDUCATION_APPLICATION_AGREEMENT_PANEL_TITLE': 'Informacije za sporazum',
  'EXTERNAL_EDUCATION_APPLICATION.AGREEMENTS_AND_CERTIFICATES_PANEL_TITLE': 'Datoteke zahtjeva sporazumi i potvrde',
  'EXTERNAL_EDUCATION_APPLICATION.SKILL_LABEL': 'Odaberite program stjecanja vještine',
  'EXTERNAL_EDUCATION_APPLICATION.EMPLOYMENT_STATUS_LABEL': 'Odaberite vaš status',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR_HELP_WANTED_LABEL': 'Je li Vam potreban savjet i podrška savjetnika za profesionalno usmjeravanje HZZ-a prilikom donošenja odluke o odabiru vještine koju biste htjeli razviti ili dodatno usavršiti?',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR_HELP_WANTED_TOOLTIP_TEXT': 'Savjetnik će odgovor vidjeti tek kad podnesete zahtjev',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR_HELP_CONTACT_TYPE_LABEL': 'Na koji način želite da Vas HZZ savjetnik kontaktira radi dogovora termina razgovora?',
  'EXTERNAL_EDUCATION_APPLICATION.EDUCATION_RELATED_LABEL': 'Je li zahtjev vezan uz Vaše obrazovanje?',
  'EXTERNAL_EDUCATION_APPLICATION.JOB_RELATED_LABEL': 'Je li zahtjev vezan uz trenutno radno mjesto?',
  'EXTERNAL_EDUCATION_APPLICATION.APPLICATION_DESCRIPTION_LABEL': 'Obrazložite na koji će način završetak predloženog programa utjecati na Vaš razvoj karijere ili mogućnost zapošljavanja',
  'EXTERNAL_EDUCATION_APPLICATION.APPLICATION_DESCRIPTION_PLACEHOLDER': 'Obrazložite na koji način je zahtjev vezan uz odabrani program stjecanja vještine',
  'EXTERNAL_EDUCATION_APPLICATION.ACCEPT_TERMS_CHECKBOX_LABEL': 'Pristajem na Opće uvjete korištenja vaučera za programe stjecanja vještina',

  // ---------- External education application expenses
  'EXTERNAL_EDUCATION_APPLICATION.EXPENSE.EDUCATION_OFFER_PRICE': 'Troškovi programa obrazovanja',
  'EXTERNAL_EDUCATION_APPLICATION.EXPENSE.TRAVEL_EXPENSE': 'Putni troškovi',
  'EXTERNAL_EDUCATION_APPLICATION.EXPENSE.INSURANCE_EXPENSE': 'Troškovi osiguranja',
  'EXTERNAL_EDUCATION_APPLICATION.TERMS_LABEL': 'Opći uvjeti korištenja vaučera za programe stjecanja vještina',
  'EXTERNAL_EDUCATION_APPLICATION.FORM.SUBMIT_SUCCESSFUL_TITLE': 'Vaš zahtjev je uspješno podnesen',
  'EXTERNAL_EDUCATION_APPLICATION.FORM.SUBMIT_SUCCESSFUL_MESSAGE': 'HZZ savjetnik će u roku od 8 dana pregledati Vaš zahtjev',

  // ----- Education application report list filter
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.SKILL_LABEL': 'Program stjecanja vještina',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_LABEL': 'Program obrazovanja',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_PROVIDER_LABEL': 'Pružatelj usluge obrazovanja',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.ASSIGNEE_LABEL': 'Korisnik/ca',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.EVALUATOR_LABEL': 'Hzz savjetnik',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.EMPLOYMENT_STATUS_LABEL': 'Status zaposlenosti korisnika/ce prilikom podnošenja zahtjeva',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.PENDING_CONSULTATION_LABEL': 'Zatražena podrška i savjet HZZ savjetnika',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_RELATED_LABEL': 'Je li zahtjev vezan uz obrazovanje?',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.JOB_RELATED_LABEL': 'Je li zahtjev vezan uz radno mjesto?',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_APPLICATION_STATUS_LABEL': 'Status zahtjeva',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_DURATION_LABEL': 'Trajanje programa obrazovanja',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_APPLICATION_SUBMISSION_DATE_LABEL': 'Vrijeme podnošenja zahtjeva',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_EXPENSES_LABEL': 'Trošak programa obrazovanja',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.TRAVEL_EXPENSES_LABEL': 'Putni troškovi',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.INSURANCE_EXPENSES_LABEL': 'Troškovi osiguranja',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.ASSIGNEE_PLACEHOLDER': 'Upišite prezime korisnika/ce',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.EVALUATOR_PLACEHOLDER': 'Upišite prezime savjetnika/ce',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.PICK_STATUS_PLACEHOLDER': 'Odaberite status',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER.EDUCATION_DURATION_SUFFIX': 'sati',

  // ----- Education application report list filter info panel
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.DESCRIPTION': 'Odabrani filteri (%{numberOfSelectedParameters}):',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_EMPLOYMENTSTATUS': 'Status zaposlenosti',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_ASSIGNEE': 'Korisnik/ca',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_PHASE': 'Status zahtjeva',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_EDUCATIONPROVIDER': 'Pružatelj usluge obrazovanja',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_SKILL': 'Program stjecanja vještina',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_EDUCATION': 'Program obrazovanja',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_EVALUATOR': 'Hzz savjetnik',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_PENDINGCONSULTATION': 'Zatražena pomoć savjetnika',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_EDUCATIONRELATED': 'Zahtjev vezan uz obrazovanje',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_JOBRELATED': 'Zahtjev vezan uz posao',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_EDUCATIONMINDURATION': 'Trajanje programa obrazovanja',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_EDUCATIONMAXDURATION': 'Trajanje programa obrazovanja',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_EDUCATIONMINEXPENSES': 'Minimalni trošak programa obrazovanja',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_EDUCATIONMAXEXPENSES': 'Maksimalni trošak programa obrazovanja',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_TRAVELMINEXPENSES': 'Minimalni putni troškovi',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_TRAVELMAXEXPENSES': 'Maksimalni putni troškovi',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_INSURANCEMINEXPENSE': 'Minimalni trošak osiguranja',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_INSURANCEMAXEXPENSE': 'Maksimalni trošak osiguranja',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_SUBMITTEDFROM': 'Vrijeme podnošenja zahtjeva od',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_SUBMITTEDTO': 'Vrijeme podnošenja zahtjeva do',
  'EDUCATION_APPLICATION_REPORT_LIST_FILTER_INFO_PANEL.PARAMETER_CITY': 'Mjesto korisnika',

  // ----- Education application coordinator list filter
  'EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.APPLICATION_OWNERSHIP_LABEL': 'Preuzetost zahtjeva',
  'EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.ASSIGNEE_LABEL': 'Korisnik/ca',
  'EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.ASSIGNEE_CITY_LABEL': 'Mjesto korisnika',
  'EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.EDUCATION_APPLICATION_STATUS_LABEL': 'Status zahtjeva',
  'EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.ASSIGNEE_PLACEHOLDER': 'Prezime korisnika/ce',
  'EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.ASSIGNEE_CITY_PLACEHOLDER': 'Upišite grad',
  'EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.PICK_STATUS_PLACEHOLDER': 'Odaberite status',
  'EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.PENDING_CONSULTATION_LABEL': 'HZZ savjet i podrška',
  'EDUCATION_APPLICATION_COORDINATOR_LIST_FILTER.PENDING_CONSULTATION_PLACEHOLDER': 'Odaberite odgovor',
  // ----- Education application coordinator list info filter
  'EDUCATION_APPLICATION_COORDINATOR_LIST_INFO_FILTER.SEARCH_URGENT_APPLICATIONS': 'Pretražite zahtjeve',
  'EDUCATION_APPLICATION_COORDINATOR_LIST_INFO_FILTER.DESCRIPTION_SUFIX': 'sa zatraženom podrškom i savjetom',
  'EDUCATION_APPLICATION_COORDINATOR_LIST_INFO_FILTER.DESCRIPTION': 'Na zahtjev na koje je korisnik zatražio podršku i savjet potrebno je žurno reagirati.',

  // ---------- External education template list
  'EXTERNAL_EDUCATION_TEMPLATE_LIST_VIEW.ADD_EXTERNAL_EDUCATION_BUTTON_LABEL': 'Novi program obrazovanja',
  'EXTERNAL_EDUCATION_TEMPLATE_LIST_VIEW.PAGE_TITLE': 'Programi obrazovanja',
  'EXTERNAL_EDUCATION_TEMPLATE_LIST_VIEW.NO_DATA': 'Nema programa obrazovanja',
  'EXTERNAL_EDUCATION_TEMPLATE_LIST_VIEW.SKILLS_LABEL': 'Vještine',

  // ---------- External education template table
  'EXTERNAL_EDUCATION_TEMPLATE.TABLE.EDUCATION_PROVIDER_HEADER_LABEL': 'Pružatelj',
  'EXTERNAL_EDUCATION_TEMPLATE.TABLE.EXTERNAL_EDUCATION_TEMPLATE_TITLE_HEADER_LABEL': 'Naziv programa',
  'EXTERNAL_EDUCATION_TEMPLATE.TABLE.CREATION_STATUS_HEADER_LABEL': 'Status kreiranja',
  'EXTERNAL_EDUCATION_TEMPLATE.TABLE.ACTIVITY_STATUS_HEADER_LABEL': 'Status izvođenja',
  'EXTERNAL_EDUCATION_TEMPLATE.TABLE.COORDINATOR_HEADER_LABEL': 'Savjetnik',
  'EXTERNAL_EDUCATION_TEMPLATE.TABLE.COORDINATOR_NOT_ASSIGNED_LABEL': 'Nije preuzeto',
  'EXTERNAL_EDUCATION_TEMPLATE.TABLE.NOT_SUBMITTED_LABEL': 'Program nije podnesen',
  'EXTERNAL_EDUCATION_TEMPLATE.TABLE.SUMBITTED_AT_HEADER_LABEL': 'Vrijeme podnošenja',

  // ---------- External education template activity status
  'EXTERNAL_EDUCATION_TEMPLATE.ACTIVITY_PHASE.APPROVAL_REQUESTED': 'Zatraženo odobravanje',
  'EXTERNAL_EDUCATION_TEMPLATE.ACTIVITY_PHASE.APPROVAL_IN_PROCESS': 'Odobravanje u tijeku',
  'EXTERNAL_EDUCATION_TEMPLATE.ACTIVITY_PHASE.CHANGE_REQUESTED': 'Zatražene promjene',
  'EXTERNAL_EDUCATION_TEMPLATE.ACTIVITY_PHASE.CHANGE_SUBMITTED': 'Promjene predane',
  'EXTERNAL_EDUCATION_TEMPLATE.ACTIVITY_PHASE.APPROVED': 'Odobreno',
  'EXTERNAL_EDUCATION_TEMPLATE.ACTIVITY_PHASE.DENIED': 'Nije odobreno',
  'EXTERNAL_EDUCATION_TEMPLATE.ACTIVITY_PHASE.CANCELLED': 'Otkazano',

  // ---------- External education template status
  'EXTERNAL_EDUCATION_TEMPLATE.STATUS.IN_CREATION': 'U izradi',
  'EXTERNAL_EDUCATION_TEMPLATE.STATUS.CREATION_FINISHED': 'Čeka odobrenje',
  'EXTERNAL_EDUCATION_TEMPLATE.STATUS.PUBLISHED': 'Objavljen',
  'EXTERNAL_EDUCATION_TEMPLATE.STATUS.ARCHIVED': 'Arhiviran',

  // ---------- External education template form
  'EXTERNAL_EDUCATION_TEMPLATE.FORM.TITLE_LABEL': 'Naziv programa',
  'EXTERNAL_EDUCATION_TEMPLATE.FORM.SKILLS_LABEL': 'Vještina/e koju polaznik usvaja programom',
  'EXTERNAL_EDUCATION_TEMPLATE.FORM.DESCRIPTION_LABEL': 'Opis programa',
  'EXTERNAL_EDUCATION_TEMPLATE.FORM.EDUCATION_DURATION_SUFFIX': 'sati',
  'EXTERNAL_EDUCATION_TEMPLATE.FORM.EDUCATION_CATEGORY_LABEL': 'Označite pripada li program nekoj od navedenih kategorija programa',
  'EXTERNAL_EDUCATION_TEMPLATE.FORM.EDUCATION_SECTOR_LABEL': 'Obrazovni sektor',
  'EXTERNAL_EDUCATION_TEMPLATE.FORM.DURATION_LABEL': 'Trajanje programa',
  'EXTERNAL_EDUCATION_TEMPLATE.FORM.MIN_PRICE_LABEL': 'Raspon cijene min',
  'EXTERNAL_EDUCATION_TEMPLATE.FORM.MAX_PRICE_LABEL': 'Raspon cijene max',
  'EXTERNAL_EDUCATION_TEMPLATE.FORM.EDUCATION_OUTCOMES_LABEL': 'Ishodi',
  'EXTERNAL_EDUCATION_TEMPLATE.FORM.ENROLLMENT_REQUIREMENTS_LABEL': 'Uvjeti upisa',
  'EXTERNAL_EDUCATION_TEMPLATE.FORM.CERTIFICATE_NAME_LABEL': 'Naziv potvrde o završenom programu obrazovanja',
  'EXTERNAL_EDUCATION_TEMPLATE.FORM.CONTRACTOR_QUALIFICATION_LABEL': 'Kvalifikacije izvoditelja',
  'EXTERNAL_EDUCATION_TEMPLATE.MODAL.TITLE': 'Novi program obrazovanja',
  'EXTERNAL_EDUCATION_TEMPLATE.FORM.EDUCATION_DURATION_PLACEHOLDER': 'Upišite trajanje',
  // info message
  'EXTERNAL_EDUCATION_TEMPLATE.CREATE_INFO_MESSAGE': 'Uspješno ste kreirali program obrazovanja',
  'EXTERNAL_EDUCATION_TEMPLATE.UPDATE_INFO_MESSAGE': 'Uspješno ste uredili program obrazovanja',
  'EXTERNAL_EDUCATION_TEMPLATE.DELETE_INFO_MESSAGE': 'Uspješno ste obrisali program obrazovanja',

  // ---------- External education instance list
  'EXTERNAL_EDUCATION_INSTANCE_LIST_VIEW.ADD_EXTERNAL_EDUCATION_INSTANCE_BUTTON_LABEL': 'Novi termin',
  'EXTERNAL_EDUCATION_INSTANCE_LIST.START_DATE_TIME_LABEL': 'Datum početka',
  'EXTERNAL_EDUCATION_INSTANCE_LIST.ADDRESS_LABEL': 'Lokacija izvođenja',
  'EXTERNAL_EDUCATION_INSTANCE_LIST.EXECUTION_METHOD_LABEL': 'Način izvođenja',
  'EXTERNAL_EDUCATION_INSTANCE_LIST.PRICE_LABEL': 'Jedinična cijena',
  'EXTERNAL_EDUCATION_INSTANCE_LIST.CONTRACTORS_LABEL': 'Izvoditelj/i',

  // ---------- External education instance form
  'EXTERNAL_EDUCATION_INSTANCE.FORM.START_DATE_TIME_LABEL': 'Datum početka',
  'EXTERNAL_EDUCATION_INSTANCE.FORM.EXECUTION_METHOD_LABEL': 'Način izvođenja',
  'EXTERNAL_EDUCATION_INSTANCE.FORM.ADDRESS_LABEL': 'Lokacije',
  'EXTERNAL_EDUCATION_INSTANCE.FORM.PRICE_LABEL': 'Jedinična cijena',
  'EXTERNAL_EDUCATION_INSTANCE.FORM.CONTRACTORS_LABEL': 'Izvoditelji',
  'EXTERNAL_EDUCATION_INSTANCE.FORM.CONTRACTORS_PLACEHOLDER': 'Upišite ime i prezime izvoditelja',
  'EXTERNAL_EDUCATION_INSTANCE.FORM.DESCRIPTION_LABEL': 'Dodatne informacije o izvođenju (npr. raspored, održavanje prijepodne ili poslijepodne)',

  // ---------- Execution method options
  'EXECUTION_METHOD.SELECT_OPTION.ONLINE_LABEL': 'Online',
  'EXECUTION_METHOD.SELECT_OPTION.EDUCATION_PROVIDER_LABEL': 'U obrazovnoj ustanovi',
  'EXECUTION_METHOD.SELECT_OPTION.EMPLOYER_LABEL': 'Kod poslodavca',
  'EXECUTION_METHOD.SELECT_OPTION.COMBINED_LABEL': 'Kombinirano',

  // ---------- External education template coordinator
  'EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.APPROVAL_REQUESTED_TEXT': 'Preuzimanjem programa obrazovanja započet ćete proces verifikacije.',
  'EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.APPROVAL_IN_PROCCESS_TEXT': 'Pregledajte program. Ukoliko program odgovara kriterijima odaberite "Verificirajte", ukoliko ne odgovara odaberite "Nemojte verificirati". U slučaju da nedostaju potrebne informacije, zatražite informacije odabirom "Vratite pružatelju".',
  'EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.VERIFICATION_HEADER_TITLE': 'Verifikacija programa obrazovanja',
  'EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.TAKE_EDUCATION_TEMPLATE_BUTTON_TEXT': 'Preuzmite program obrazovanja',
  'EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.VERIFY_EDUCATION_TEMPLATE_BUTTON_TEXT': 'Verificirajte',
  'EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.RETURN_EDUCATION_TEMPLATE_BUTTON_TEXT': 'Vratite pružatelju',
  'EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.RETURN_EDUCATION_TEMPLATE_MODAL_TITLE': 'Vraćanje programa obrazovanja?',
  'EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.RETURN_EDUCATION_TEMPLATE_PLACEHOLDER': 'Razlog vraćanja',
  'EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.REJECT_VERIFICATION_EDUCATION_TEMPLATE_BUTTON_TEXT': 'Odbij',
  'EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.REJECT_VERIFICATION_EDUCATION_TEMPLATE_MODAL_TITLE': 'Odbijanje programa obrazovanja?',
  'EXTERNAL_EDUCATION_TEMPLATE.COORDINATOR.REJECT_VERIFICATION_EDUCATION_TEMPLATE_PLACEHOLDER': 'Razlog odbijanja verifikacije',

  // ---------- External education
  'EXTERNAL_EDUCATION_APPLICATION.APPLICATION_DENIED_TEXT': 'Zahtjev nije odobren',
  'EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.SKILL_INFO': 'Upišite prva tri slova naziva programa obrazovanja ili odaberite odgovarajući program iz padajućeg izbornika.',
  'EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.CURRICULUM_VITAE_INFO':
    'Životopis treba sadržavati podatke o završenom obrazovanju, dodatnom usavršavanju, tečajevima, seminarima, dosadašnjem radnom iskustvu i stečenim vještinama, interesima i hobijima itd. Podržani formati su .pdf, .jpg, .jpeg, .png i .doc. Podržani formati su .pdf, .jpg, .jpeg, .png i .doc.',
  'EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.OFFER_INFO':
    'Ponudu zatražite od pružatelja usluga obrazovanja. Datoteka treba sadržavati informacije o nazivu programa obrazovanja, pružatelju usluga obrazovanja, cijeni pohađanja odabranog programa obrazovanja i podacima za uplatu troškova pohađanja programa. Podržani formati su .pdf, .jpg, .jpeg, .png i .doc. Podržani formati su .pdf, .jpg, .jpeg, .png i .doc.',
  'EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.CURRICULUM_INFO':
    'Kurikulum (nastavni plan i program) zatražite od pružatelja usluga obrazovanja. Datoteka treba sadržavati informacije o nazivu programa, području/obrazovnom sektoru kojemu pripada, uvjetima upisa, trajanju, načinu izvođenja (online, u učionici, kombinirano u učionici i kod poslodavca itd.), sadržaju programa obrazovanja (predmeti, teme, moduli i sl.), izvoditeljima (kvalifikacije predavača/nastavnika/mentora), očekivane ishode učenja koji će se steći završetkom programa obrazovanja, naziv potvrde o završenom programu obrazovanja, te okviran raspored nastave po danima/tjednima/mjesecima. Podržani formati su .pdf, .jpg, .jpeg, .png i .doc.',
  'EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.TERMS_INFO': 'U nastavku se nalaze Opći uvjeti korištenja vaučera. Klikom na "Pristajem na Opće uvjete korištenja vaučera za program obrazovanja" potvrđujete da ste upoznati i da pristajete na navedene uvjete.',
  'EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.SIGNED_FINANCING_AGREEMENT_INFO': ' Podržani formati su .pdf, .jpg, .jpeg, .png i .doc.',
  'EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.START_DATE_INFO': 'Unesite datum početka programa obrazovanja dobiven od pružatelja usluga obrazovanja.',
  'EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.CONTRACT_GENERATION_INFO': 'Na osnovi informacija iz priloženih datoteka ponude i kurikuluma te unesenog datuma početka programa generiran je Sporazum o međusobnim pravima i obvezama za vrijeme programa obrazovanja putem vaučera. Sporazum je potrebno preuzeti i potpisati.',
  'EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.PROOF_OF_PAYMENT_INFO': 'Potrebno je priložiti potvrdu o plaćanju kako bi korisnik vaučera/polaznik obrazovanja mogao pohađati program obrazovanja. Podržani formati su .pdf, .jpg, .jpeg, .png i .doc.',
  'EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.ATTENDANCE_CERTIFICATE_INFO':
    'Potvrdu o prisutnosti ovjerenu od strane pružatelja usluga programa nezaposlena osoba prilaže do najkasnije svakog 5. u mjesecu, a ostali tražitelji zaposlenja nakon završetka programa obrazovanja. Podržani formati su .pdf, .jpg, .jpeg, .png i .doc.',
  'EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.COMPETENCIES_CERTIFICATE_INFO': 'Potvrdu o završenom programu obrazovanja zatražite od pružatelja usluga obrazovanja. Potvrda mora sadržavati podatak o nazivu programa, trajanju i stečenim ishodima učenja/kompetencijama. Podržani formati su .pdf, .jpg, .jpeg, .png i .doc.',

  'EXTERNAL_EDUCATION_APPLICATION.ERROR.SKILL_DATA': 'Odaberite vještinu koju želite steći',
  'EXTERNAL_EDUCATION_APPLICATION.ERROR.CONTACT_INFORMATION': 'Unesite podatke za kontakt',
  'EXTERNAL_EDUCATION_APPLICATION.ERROR.EMPLOYER_INFORMATION': 'Unesite podatke o poslodavcu',
  'EXTERNAL_EDUCATION_APPLICATION.ERROR.ACCEPTED_TERMS': 'Morate prihvatiti opće uvjete korištenja',
  'EXTERNAL_EDUCATION_APPLICATION.ERROR.APPLICATION_DESCRIPTION_LENGTH': 'Obrazloženje mora sadržavati minimalno 750 znakova',
  'EXTERNAL_EDUCATION_APPLICATION.ERROR.APPLICATION_DESCRIPTION_REQUIRED': 'Upišite obrazloženje prijave',
  'EXTERNAL_EDUCATION_APPLICATION.ERROR.FILE_CURRICULUM_VITAE': 'Dodajte životopis',
  'EXTERNAL_EDUCATION_APPLICATION.ERROR.FILE_OFFER': 'Dodajte ponudu programa obrazovanja',
  'EXTERNAL_EDUCATION_APPLICATION.ERROR.FILE_CURRICULUM': 'Dodajte kurikulum programa obrazovanja',

  'EXTERNAL_EDUCATION_APPLICATION.VIEW.EVALUATOR_TITLE': 'Savjetnik: %{name}',
  'EXTERNAL_EDUCATION_APPLICATION.VIEW.STEP_ONE_TITLE': 'Podnošenje zahtjeva',
  'EXTERNAL_EDUCATION_APPLICATION.VIEW.STEP_TWO_TITLE': 'Odobravanje zahtjeva',
  'EXTERNAL_EDUCATION_APPLICATION.VIEW.STEP_THREE_TITLE': 'Trajanje programa',
  'EXTERNAL_EDUCATION_APPLICATION.VIEW.STEP_FOUR_TITLE': 'Zatvaranje zahtjeva',

  'EXTERNAL_EDUCATION_APPLICATION.EXPENSE.PANEL_TITLE': 'Troškovi',
  'EXTERNAL_EDUCATION_APPLICATION.EXPENSE.TYPE_LABEL': 'Vrsta troška',
  'EXTERNAL_EDUCATION_APPLICATION.EXPENSE.VALUE_LABEL': 'Iznos troška (bez PDV-a)',
  'EXTERNAL_EDUCATION_APPLICATION.EXPENSE.DATE_LABEL': 'Datum plaćanja',
  'EXTERNAL_EDUCATION_APPLICATION.EXPENSE.PANEL_LABEL': 'Upišite trošak',
  'EXTERNAL_EDUCATION_APPLICATION.EXPENSE.TRAVEL_EXPENSE_LABEL': 'Putni trošak',
  'EXTERNAL_EDUCATION_APPLICATION.EXPENSE.INSURANCE_EXPENSE_LABEL': 'Trošak osiguranja',
  'EXTERNAL_EDUCATION_APPLICATION.EXPENSE.CREATE_SUCCESS': 'Trošak spremljen',

  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR_PAYMENT_INFO.BANK_NAME_LABEL': 'Naziv banke pružatelja',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR_PAYMENT_INFO.IBAN_LABEL': 'IBAN pružatelja (HRcc AAAA AAAB BBBB BBBB B)',

  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE_PAYMENT_INFO.BANK_NAME_LABEL': 'Naziv vaše banke',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE_PAYMENT_INFO.IBAN_LABEL': 'Vaš IBAN (HRcc AAAA AAAB BBBB BBBB B)',

  'EXTERNAL_EDUCATION_APPLICATION.SURVEY.TRAINEE_SURVEY_LABEL': 'Ocjena korisnika',
  'EXTERNAL_EDUCATION_APPLICATION.SURVEY.EMPLOYER_SURVEY_LABEL': 'Ocjena poslodavca',
  'EXTERNAL_EDUCATION_APPLICATION.SURVEY.EDUCATION_PROVIDER_SURVEY_LABEL': 'Ocjena pružatelja usluga',

  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.USER_SUBMITTED_TEXT': 'Korisnik je predao zahtjev za program obrazovanja',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.IN_PROCCESS_TEXT':
    'Pregledajte zahtjev korisnika. Ukoliko zahtjev odgovara kriterijima odaberite "Odobrite", ukoliko ne odgovara odaberite "Nemojte odobriti" zahtjev. U slučaju da nedostaju potrebne informacije, možete od podnositelja zahtjeva zatražiti informacije, odabirom "Vratite korisniku"',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.APPLICATION_APPROVED_TEXT': 'Odobrili ste ovaj zahtjev',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.APPLICATION_DENIED_TEXT': 'Zahtjev nije odobren',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.TAKE_APPLICATION_BUTTON_TEXT': 'Preuzmite zahtjev',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.APPROVE_APPLICATION_BUTTON_TEXT': 'Odobrite',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.RETURN_APPLICATION_BUTTON_TEXT': 'Vratite korisniku',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.RETURN_APPLICATION_MODAL_TITLE': 'Jeste li sigurni da želite vratiti korisniku zahtjev?',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.RETURN_APPLICATION_PLACEHOLDER': 'Unesite razlog vraćanja zahtjeva.',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.REJECT_APPLICATION_BUTTON_TEXT': 'Nemojte odobriti',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.REJECT_APPLICATION_MODAL_TITLE': 'Jeste li sigurni da želite odbiti zahtjev?',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.REJECT_APPLICATION_PLACEHOLDER': 'Unesite razlog odbijanja zahtjeva.',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.REJECT_APPLICATION_CONFIRM_BUTTON': 'Odbijte',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.APPLICATION_CANCELED_TEXT': 'Korisnik je otkazao ovaj zahtjev',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.REQUEST_SIGNED_CONTRACT_BUTTON_TEXT': 'Zatražite sporazum',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.AWAITING_SIGNED_CONTRACT_TEXT': 'Zatražili ste od korisnika potpisani sporazum. Korisnik je dužan u roku od 8 dana učitati potpisani sporazum',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.EDUCATION_NOT_ENTERED_TEXT': 'Upišite informacije o programu obrazovanja',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.EDUCATION_ENTERED_TEXT': 'Upisali ste informacije o programu obrazovanja',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CONTRACT_GENERATION_TEXT': 'Provjerite upisano i zatražite od korisnika potpisan sporazum. Nakon što zatražite sporazum više nije moguće urediti informacije o programu obrazovanja i pružatelju usluga obrazovanja',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.SIGNED_CONTRACT_UPLOADED_TEXT': 'Korisnik je učitao potpisani sporazum.',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.UPLOAD_PROOF_OF_PAYMENT_TEXT': 'Pokrenite postupak za uplatu troškova programa obrazovanja na račun pružatelja usluge obrazovanja. Potom priložite potvrdu o plaćanju.',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.ENABLE_EDUCATION_TEXT': 'Kada ste dodali potvrdu o plaćanu, pošaljite ju korisniku da bi mogao započeti obrazovanje.',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.EDUCATION_COMPLETED_TEXT': 'Polaznik je završio program obrazovanja',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CANCEL_APPLICATION_APPROVAL_BUTTON_TEXT': 'Poništite odobrenje',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CANCEL_APPLICATION_APPROVAL_MODAL_TITLE': 'Jeste li sigurni da želite poništiti odobrenje?',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CANCEL_APPLICATION_APPROVAL_PLACEHOLDER': 'Unesite razlog poništavanja odobrenja',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.OPEN_SURVEY_BUTTON_LABEL': 'Pogledajte ocjenu',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.ENABLE_EDUCATION_BUTTON_TEXT': 'Pošaljite potvrdu o plaćanju',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.COORDINATOR_HELP_WANTED_TEXT': 'Korisnik je zatražio savjet i podršku savjetnika za profesionalno usmjeravanje HZZ-a prilikom donošenja odluke o odabiru vještine koju biste htjeli razviti ili dodatno usavršiti',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.COORDINATOR_HELP_FINISHED_BUTTON_TEXT': 'Savjetovanje obavljeno',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.USER_EDUCATION_IN_PROGERSS_LABEL': 'Korisnik vaučera pohađa program obrazovanja. Po završetku unijet će potvrdu o završenosti.',

  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.SUBMITED_AND_WAITING_COORDINATOR_TEXT': 'HZZ savjetnik će pregledati Vaš zahtjev',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.APPROVED_AND_CONTRACT_IN_GENERATION_TEXT': 'HZZ savjetnik je pregledao Vaš zahtjev i zatražio od Vas potpisani sporazum. U nastavku upišite informacije koje su potrebne kako bi se sporazum mogao generirati.',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.ENTER_START_DATE_TEXT': 'Odaberite datum početka obrazovnog programa.',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.ENTER_PAYMENT_INFO_TEXT': 'Upišite informacije o Vašoj banci. Informacije su potrebne kako bi se izvršile uplate putnih troškova.',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.CONTRACT_GENERATED_TEXT': 'Uspješno ste generirali sporazum. Preuzmite, pročitajte i vlastoručno potpišite ispisani sporazum.',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.AWAITING_PAYMENT_TEXT': 'HZZ savjetnik će poslati potvrdu o plaćanju',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.EDUCATION_IN_PROGRESS_TEXT': 'HZZ savjetnik je odobrio Vaš zahtjev i priložio potvrdu o plaćanju. Kontaktirajte pružatelja usluge obrazovanja i započnite s programom obrazovanja.',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.UNEMPLOYED_ATTENDANCE_CERTIFICATE_TEXT': 'Kao nezaposlena osoba ste se sporazumom obvezali najkasnije svakog 5. u mjesecu dostaviti potvrdu o prisutnosti.',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.UPLOAD_COMPETENCIES_CERTIFICATE_TEXT': 'Nakon završetka programa obrazovanja, priložite potvrdu o stečenim kompetencijama. Potvrdu zatražite od pružatelja usluge obrazovanja.',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.APPLICATION_CANCELED_TEXT': 'Otkazali ste ovaj zahtjev',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.EDUCATION_IN_PROGRESS_FINISH_TEXT': 'Kada završite obrazovni program i priložite potvrdu o stečenim kompetencijama, klikom na gumb u nastavku potvrdite završetak programa obrazovanja.',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.EDUCATION_COMPLETED_TEXT': 'Pristupite upitniku za ocjenu programa obrazovanja. Prema potpisanom sporazumu, dužni ste u roku N dana nakon završetka programa obrazovanja ispuniti upitnik.',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.TAKE_SURVEY_BUTTON_LABEL': 'Pristupite upitniku',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.CANCEL_APPLICATION_LABEL': 'Ipak ne želite pohađati program obrazovanja?',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.CANCEL_APPLICATION_BUTTON_TEXT': 'Odbacite zahtjev',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.SUBMIT_SIGNED_CONTRACT_TEXT': 'Kada priložite potpisani sporazum, zatražite potvrdu o plaćanju od HZZ savjetnika.',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.SUBMIT_SIGNED_CONTRACT_BUTTON_TEXT': 'Zatražite potvrdu o plaćanju',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.GENERATE_CONTRACT_TEXT': 'Kada upišete podatke, generirajte sporazum',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.GENERATE_CONTRACT_BUTTON_TEXT': 'Generirajte sporazum',
  'EXTERNAL_EDUCATION_APPLICATION.TRAINEE.EDUCATION_COMPLETED_BUTTON_TEXT': 'Obrazovanje završeno',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR_ADD_LABEL': 'Dodajte savjetnika',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR_CHANGE_LABEL': 'Promijenite savjetnika',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR_SEARCH_PLACEHOLDER': 'Pronađite savjetnika',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR_VALIDATION_MESSAGE': 'Molimo odaberite novog savjetnika',
  'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR_LABEL': 'Kliknite u izbornik i upišite ime novog savjetnika. Klikom na OK savjetnik će se automatski ažurirati.',

  // agreement form
  'EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.CODE_OFFER_LABEL': 'Broj ponude',
  'EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.EDUCATION_EXPENSE_LABEL': 'Trošak programa obrazovanja (bez PDV-a)',
  'EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.DISTRICT_OFFICE_ADDRESS_LABEL': 'Sjedište PS/PU',
  'EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.CODE_OFFER_PLACEHOLDER': 'Upišite broj ponude',
  'EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.EDUCATION_EXPENSE_PLACEHOLDER': 'Upišite iznos troška',
  'EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.DISTRICT_OFFICE_ADDRESS_PLACEHOLDER': 'Upišite sjedište PS/PU',
  //  agreement form validation message
  'EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.VALIDATION.CODE_OFFER_MESSAGE': 'Molimo unesite broj ponude',
  'EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.VALIDATION.EDUCATION_EXPENSE_MESSAGE': 'Molimo unesite iznos troška',
  'EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.VALIDATION.DISTRICT_OFFICE_ADDRESS_MESSAGE': 'Molimo unesite sjedište PS/PU',

  // ---------- External education application timeline
  'EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.TITLE': 'Aktivnosti i bilješke zahtjeva',
  'EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.EXTERNAL_EDUCATION_APPLICATION_SUBMITTED': '%{name} je podnio/la zahtjev.',
  'EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.EXTERNAL_EDUCATION_APPLICATION_APPROVED': '%{name} je odobrio/la zahtjev.',
  'EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.EXTERNAL_EDUCATION_APPLICATION_DENIED': '%{name} je odbio/la zahtjev korisniku.',
  'EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.COORDINATOR_ADDED': '%{name} je dodan/a kao savjetnik.',
  'EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.COORDINATOR_REMOVED': '%{name} je uklonjen/a kao savjetnik.',
  'EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.SIGNED_FINANCING_AGREEMENT_REQUESTED': '%{name} je zatražio/la potpisan sporazum.',
  'EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.FILE_ATTACHED': '%{name} je priložio/la datoteku %{filename}.',
  'EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.EDUCATION_COMPLETED': '%{name} je završio/la program obrazovanja.',
  'EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.SURVEY_SUBMITTED': '%{name} je ispunio/la upitnik.',
  'EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.NOTE.TITLE': 'HZZ savjetnik je napisao bilješku.',
  'EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.UNKNOWN_FILE_TITLE': '[Nepoznata datoteka]',
  'EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.EXTERNAL_EDUCATION_APPLICATION_RETURNED_TO_USER': 'Vraćeno korisniku',

  // ---------- Education outcome picker
  'EDUCATION_OUTCOME.PICKER.OUTCOME_PLACEHOLDER': 'Pretražite postojeće ishode',
  'EDUCATION_OUTCOME.PICKER.OUTCOME_EMPTY_MESSAGE': 'Nema pronađenih ishoda',

  // ---------- External education template picker
  'EXTERNAL_EDUCATION_TEMPLATE.PICKER.SEARCH_PLACEHOLDER': 'Pronađite program obrazovanja',

  // ----------- External education template tabs
  'EXTERNAL_EDUCATION_TEMPLATE.TABS.EDUCATION_INSTANCES_LABEL': 'Termini',
  'EXTERNAL_EDUCATION_TEMPLATE.TABS.EDUCATION_PROVIDER_LABEL': 'Pružatelj programa edukacije',
  'EXTERNAL_EDUCATION_TEMPLATE.TABS.RATING_LABEL': 'Ocjene',
  'EXTERNAL_EDUCATION_TEMPLATE.TABS.ACTIVITY_HISTORY_LABEL': 'Aktivnosti',

  // ----------- External education template
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.VERIFICATION_BUTTON_LABEL': 'Pošaljite na verifikaciju',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_PROVIDER_NAME_LABEL': 'pružatelj usluga',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_PROVIDER_ADDRESS_LABEL': 'adresa pružatelja',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_PROVIDER_IDENTIFICATION_NUMBER_LABEL': 'OIB pružatelja',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_PROVIDER_EMAIL_LABEL': 'email pružatelja',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_DESCRIPTION': 'program obrazovanja',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_DURATION': '%{duration} sati',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_DURATION_LABEL': 'trajanje programa',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_OUTCOME_LABEL': 'Ishod programa',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_OUTCOME_DESCRIPTION': 'ishod programa obrazovanja',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.COST_LABEL': '%{minPrice} kn - %{maxPrice} kn',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.COST_SPAN_DESCRIPTION': 'raspon cijene',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.CONTRACTOR_QUALIFICATION_DESCRIPTION': 'kvalifikacije izvoditelja',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.CERTIFICATE_NAME_DESCRIPTION': 'naziv potvrde po završetku',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.DESCRIPTION_TITLE': 'Opis programa',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.ENROLLMENT_REQUIREMENTS_TITLE': 'Uvjeti upisa programa',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.ENROLLMENT_REQUIREMENTS_DESCRIPTION': 'uvjet pohađanja programa',
  'EXTERNAL_EDUCATION_TEMPLATE_RATE.VIEW_SECTION.HEADER_TITLE': 'Ocjene vidljive savjetnicima i pružateljima',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.SKILLS_TITLE': ' Vještine koje se stječu završetkom obrazovnog programa',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_SECTOR_TITLE': 'Obrazovni sektor',
  'EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.DETAILS_TITLE': 'Detalji programa',

  'EDUCATION_PROVIDER.VIEW_SECTION.HEADER_TITLE': 'O pružatelju programa obrazovanja',
  'EDUCATION_PROVIDER.VIEW_SECTION.SHOW_PROFILE_BUTTON_LABEL': 'Pogledaj profil',

  // ----------- External education application statistics
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.VIEW_STATISTICS_BUTTON_LABEL': 'Prikaži statistiku',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.GRADE_TITLE': 'Ocjene',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.SURVEYS_BY_SURVEY_TYPE': 'Ispunjenih upitnika prema vrsti upitnika ',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.SUBMITTED_SURVEYS': 'Ukupno ispunjenih upitnika',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.EDUCATION_PROVIDER_SURVEYS': 'pružatelji programa obrazovanja',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.EMPLOYER_SURVEYS': 'poslodavci',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.USER_EDUCATION_COMPLETED_SURVEYS': 'korisnici odmah po završetku',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.USER_EDUCATION_COMPLETED_CHECK_IN_SURVEYS': 'korisnici nakon 6 mjeseci',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.SURVEY_QUESTION_LABEL': '%{orderNumber}. %{questionText} (%{numberOfAnswers} odgovora)',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.EDUCATION_PROVIDER_SURVEYS_AVERAGE_QUESTION_TITLE': 'Prosječni odgovori po pitanju "Upitnik za pružatelja programa obrazovanja"',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.EMPLOYER_SURVEY_AVERAGE_QUESTION_TITLE': 'Prosječni odgovori po pitanju "Upitnik za poslodavce"',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.SURVEY_REPORT.EDUCATION_COMPLETED_SURVEY_AVERAGE_QUESTION_TITLE': 'Prosječni odgovori po pitanju "Upitnik za korisnike nakon 6 mjeseci"',

  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.MODAL.STATISTICS_TITLE': 'Statistika',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.STATISTICS_COUNT_TITLE': 'Broj zahtjeva',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.STATISTICS_TOTAL_COUNT_TITLE': 'Ukupno zahtjeva',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.STATISTICS_COUNT_PER_APPLICATION_STATUS_TITLE': 'Prema statusu zahtjeva',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.STATISTICS_COUNT_PER_EMPLYOMENT_STATUS_TITLE': 'Prema statusu zaposlenosti korisnika',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.STATISTICS_COUNT_SUBMITTED_APPLICATIONS': 'podneseni zahtjevi',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.STATISTICS_COUNT_APPLICATIONS_IN_PROGRESS': 'zahtjevi u tijeku',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.STATISTICS_COUNT_APPLICATIONS_IN_PROCESS': 'zahtjevi u obradi',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.STATISTICS_COUNT_APPLICATIONS_COMPLETED': 'završeni zahtjevi',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.STATISTICS_COUNT_APPLICATIONS_EMPLOYED': 'zaposleni',
  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.STATISTICS_COUNT_APPLICATIONS_UNEMPLYOED': 'nezaposleni',

  'EXTERNAL_EDUCATION_APPLICATION_STATISTICS.STATISTICS_AVERAGE_EXPENSES_TITLE': 'Prosječni troškovi u kunama',

  // ----------- Admin codebooks
  'CODEBOOK.CODEBOOK_TITLE': 'Šifrarnici',
  'CODEBOOK.TITLE.EDUCATION_QUALIFICATION': 'Šifrarnici razine obrazovanja',
  'CODEBOOK.TITLE.GRADE': 'Šifrarnici ocjena',
  'CODEBOOK.TITLE.LOCATION': 'Šifrarnici lokacija',
  'CODEBOOK.TITLE.SKILL_LEVEL': 'Šifrarnik razina vještine',
  'CODEBOOK.TITLE.ENROLLMENT_REQUIREMENT': 'Uvjeti upisa',
  'CODEBOOK.TITLE.EDUCATION_SECTOR': 'Obrazovni sektori',
  'CODEBOOK.TITLE.EDUCATION_CATEGORY': 'Kategorija programa',

  // ------ Grade
  'CODEBOOK_GRADE.CREATE_CODED_GRADE_BUTTON_LABEL': 'Kreiraj novu ocjenu',
  'CODEBOOK_GRADE.CODED_GRADE_DESCRIPTION_LABEL': 'Opis:',
  'CODEBOOK_GRADE_FORM.TITLE': 'Kreiranje nove ocjene',
  'CODEBOOK_GRADE_FORM.NAME_LABEL': 'Naziv ocjene',
  'CODEBOOK_GRADE_FORM.DESCRIPTION_LABEL': 'Opis ocjene',
  'CODEBOOK_GRADE_FORM_FORM.ORDERING_LABEL': 'Redoslijed',
  // info message
  'CODEBOOK_GRADE.CREATE_INFO_MESSAGE': 'Uspješno ste kreirali šifrarnik ocjene',
  'CODEBOOK_GRADE.DELETE_INFO_MESSAGE': 'Uspješno ste obrisali šifrarnik ocjene',
  // error message
  'CODEBOOK_GRADE.ERROR.INVALID_ORDERING': 'Šifrarnik ocjene postoji s trenutnim rednim brojem',

  // ------ Education qualification
  'EDUCATION_QUALIFICATION.CREATE_EDUCATION_QUALIFICATION_BUTTON_LABEL': 'Kreiraj novu razinu znanja',
  'EDUCATION_QUALIFICATION_FORM.TITLE': 'Kreiranje nove razine obrazovanja',
  'EDUCATION_QUALIFICATION_FORM.NAME_LABEL': 'Naziv razine znanja',
  'EDUCATION_QUALIFICATION_FORM.DESCRIPTION_LABEL': 'Opis razine znanja',
  // info message
  'EDUCATION_QUALIFICATION.CREATE_INFO_MESSAGE': 'Uspješno ste kreirali šifrarnik razine obrazovanja',
  'EDUCATION_QUALIFICATION.DELETE_INFO_MESSAGE': 'Uspješno ste obrisali šifrarnik razine obrazovanja',

  // ------ Enrollment requirement
  'ENROLLMENT_REQUIREMENT.CREATE_BUTTON_LABEL': 'Kreiraj novi uvjet upisa',
  'ENROLLMENT_REQUIREMENT_FORM.TITLE': 'Kreiranje uvjeta upisa',
  'ENROLLMENT_REQUIREMENT_FORM.NAME_LABEL': 'Naziv',
  'ENROLLMENT_REQUIREMENT_FORM.DESCRIPTION_LABEL': 'Opis',
  'ENROLLMENT_REQUIREMENT.DESCRIPTION_LABEL': 'Opis:',
  // info message
  'ENROLLMENT_REQUIREMENT.CREATE_INFO_MESSAGE': 'Uspješno ste kreirali uvjet upisa',
  'ENROLLMENT_REQUIREMENT.DELETE_INFO_MESSAGE': 'Uspješno ste obrisali uvjet upisa',

  // ------ Education sector
  'EDUCATION_SECTOR.CREATE_BUTTON_LABEL': 'Kreiraj novi obrazovni sektor',
  'EDUCATION_SECTOR_FORM.TITLE': 'Kreiranje obrazovnog sektora',
  'EDUCATION_SECTOR_FORM.NAME_LABEL': 'Naziv',
  'EDUCATION_SECTOR_FORM.DESCRIPTION_LABEL': 'Opis',
  'EDUCATION_SECTOR.DESCRIPTION_LABEL': 'Opis:',
  // info message
  'EDUCATION_SECTOR.CREATE_INFO_MESSAGE': 'Uspješno ste kreirali obrazovni sektor',
  'EDUCATION_SECTOR.DELETE_INFO_MESSAGE': 'Uspješno ste obrisali obrazovni sektor',

  // ------ Education category
  'EDUCATION_CATEGORY.CREATE_BUTTON_LABEL': 'Kreiraj novu kategoriju programa',
  'EDUCATION_CATEGORY_FORM.TITLE': 'Kreiranje kategorije programa',
  'EDUCATION_CATEGORY_FORM.NAME_LABEL': 'Naziv',
  'EDUCATION_CATEGORY_FORM.DESCRIPTION_LABEL': 'Opis',
  'EDUCATION_CATEGORY.DESCRIPTION_LABEL': 'Opis:',
  // info message
  'EDUCATION_CATEGORY.CREATE_INFO_MESSAGE': 'Uspješno ste kreirali kategoriju programa',
  'EDUCATION_CATEGORY.DELETE_INFO_MESSAGE': 'Uspješno ste obrisali kategoriju programa',

  // ------ LOCATION
  'LOCATION_CODEBOOK.CREATE_LOCATION_BUTTON_LABEL': 'Kreiraj novu lokaciju',
  'LOCATION_CODEBOOK.LOCATION_DESCRIPTION_LABEL': 'Tip lokacije:',
  'LOCATION_FORM.DESCRIPTION_LABEL': 'Opis razine znanja',
  'LOCATION_CODEBOOK_FORM.TITLE': 'Kreiranje nove lokacije',
  'LOCATION_CODEBOOK_FORM.TITLE_LABEL': 'Naziv',
  'LOCATION_CODEBOOK_FORM.ADRESS_LABEL': 'Adresa',
  'LOCATION_CODEBOOK_FORM.LOCATION_TYPE_LABEL': 'Tip lokacije',
  'LOCATION_CODEBOOK_FORM.MANAGER_LABEL': 'Predstojnik',
  'LOCATION_CODEBOOK_FORM.CONTACT_DATA_LABEL': 'Kontakt',
  'LOCATION_TYPE.DISTRICT_OFFICE_LABEL': 'Područni ured/služba',
  // info message
  'LOCATION_CODEBOOK.CREATE_INFO_MESSAGE': 'Uspješno ste kreirali šifrarnik lokacije',
  'LOCATION_CODEBOOK.DELETE_INFO_MESSAGE': 'Uspješno ste obrisali šifrarnik lokacije',

  // ----------- Contact data property input
  'CONTACT_DATA_PROPERTY_INPUT.ADD_CONTACT_LABEL': 'Dodajte kontakt',
  'CONTACT_DATA_PROPERTY_INPUT.TYPE.EMAIL_LABEL': 'E-mail',
  'CONTACT_DATA_PROPERTY_INPUT.TYPE.PHONE_LABEL': 'Telefon',
  // error message
  'CONTACT_DATA_PROPERTY_INPUT.ERROR_MESSAGE.MINIMUM_FIELD_REQUIRED': 'Molimo Vas da uneste barem jedan kontakt',

  // ----------- Address property input
  'ADDRESS_PROPERTY_INPUT.STREET_WITH_HOME_NUMBER_LABEL': 'Ulica i kućni broj',
  'ADDRESS_PROPERTY_INPUT.CITY_LABEL': 'Mjesto',
  'ADDRESS_PROPERTY_INPUT.ZIP_LABEL': 'Poštanski broj',
  'ADDRESS_PROPERTY_INPUT.COUNTRY_LABEL': 'Država',

  // ---------- Address short info
  'ADDRESS_SHORT_INFO_INPUT.CITY_PLACEHOLDER': 'Mjesto',
  'ADDRESS_SHORT_INFO_INPUT.STREET_WITH_HOME_NUMBER_PLACEHOLDER': 'Ulica i broj',

  // ----------- Skill level classification
  'SKILL_LEVEL_CLASSIFICATION_LIST.LEVEL_LABEL': 'Br. razine',
  'SKILL_LEVEL_CLASSIFICATION_LIST.LEVEL_DESCRIPTION_LABEL': 'Opis razine',
  'SKILL_LEVEL_CLASSIFICATION_LIST.LEVEL_NAME_LABEL': 'Ime razine',

  // ----------- Skill level classification form
  'SKILL_LEVEL_CLASSIFICATION.FORM.LEVEL_DESCRIPTION_LABEL': 'Opis Razine %{levelNumber}',
  'SKILL_LEVEL_CLASSIFICATION.FORM.LEVEL_DESCRIPTION_PLACEHOLDER': 'Upišite opis razine %{levelNumber}',
  'SKILL_LEVEL_CLASSIFICATION.FORM.EDIT_SKILL_LEVEL_BUTTON_LABEL': 'Uredi razine',

  // ----------- Skill level classification data picker
  'SKILL_LEVEL_CLASSIFICATION_PICKER.LEVEL_DESCRIPTION_LABEL': 'Opis razine:',

  // ----------- Skill level classification modal
  'SKILL_LEVEL_CLASSIFICATION.MODAL.TITLE': 'Uredi razine programa vještine',

  // ---------- Question Types
  'QUESTION_TYPE.YES_NO': 'Da/ne',
  'QUESTION_TYPE.SINGLE_ANSWER': 'Jedan točan odgovor',
  'QUESTION_TYPE.MULTIPLE_ANSWERS': 'Više točnih odgovora',
  'QUESTION_TYPE.ANSWER_PAIRS': 'Uparivanje odgovora',
  'QUESTION_TYPE.PAIR_DRAG': 'Povlačenje parova',
  'QUESTION_TYPE.WORD_INSERT': 'Upisivanje riječi',
  'QUESTION_TYPE.ELEMENT_INSERT': 'Upisivanje u elemente',
  'QUESTION_TYPE.WORD_SELECT': 'Odabiranje riječi',
  'QUESTION_TYPE.WORD_DRAG': 'Povlačenje riječi',
  'QUESTION_TYPE.ELEMENT_GROUP_SORT': 'Razvrstavanje elemenata',
  'QUESTION_TYPE.ELEMENT_ORDER': 'Uređivanje poretka elemenata',
  'QUESTION_TYPE.NUMERIC_RANGE': 'Numerički',
  'QUESTION_TYPE.PICTURE_DRAG': 'Povlačenje na sliku',
  'QUESTION_TYPE.REPL': 'REPL zadatak',
  'QUESTION_TYPE.ESSAY': 'Esejsko pitanje',
  'QUESTION_TYPE.LINEAR_SCALE': 'Linearna ljestvica',
  'QUESTION_TYPE.GRID': 'Grid',
  'QUESTION_TYPE.FILE': 'Pitanje s datotekom',

  // ----------- Organization
  'ORGANIZATION_VIEW.EXTERNAL_EDUCATION_TEMPLATES_TAB_LABEL': 'Programi obrazovanja',
  'ORGANIZATION_VIEW.USERS_TAB_LABEL': 'Članovi',
  'ORGANIZATION_VIEW.USERS_EMPTY': 'Ova organizacija nema članova',
  'ORGANIZATION_VIEW.DESCRIPTION_LABEL': 'Opis',
  'ORGANIZATION_VIEW.IDENTIFICATION_NUMBER_LABEL': 'Oib',
  'ORGANIZATION_VIEW.ADDRESS_LABEL': 'Sjedište',
  'ORGANIZATION_VIEW.URL_LABEL': 'Poveznica na web stranicu',
  'ORGANIZATION_VIEW.CONTACT_LABEL': 'Kontakt',
  'ORGANIZATION_LIST.ADD_BUTTON_LABEL': 'Nova organizacija',
  'ORGANIZATION_LIST.NAME_FILTER_LABEL': 'Ime',
  'ORGANIZATION_FORM.TITLE_EDIT': 'Uredi',
  'ORGANIZATION_FORM.TITLE_CREATE': 'Stvori novi organizacijski profil',
  'ORGANIZATION_FORM.DESCRIPTION_LABEL': 'Opis',
  'ORGANIZATION_FORM.URL_LABEL': 'Web stranica',
  'ORGANIZATION_FORM.LOGO_LABEL': 'Logo',
  'ORGANIZATION_FORM.COMPANY_REQUIRED_LABEL': 'Molimo unesite podatke o organizaciji',
  'ORGANIZATION_VIEW.JOINED_ORGANIZATION_INFO_MESSAGE': 'Uspješno ste se pridružili organizaciji!',

  'ORGANIZATION_MEMBER_LIST.ROLE_TYPE.OWNER': 'Vlasnik',
  'ORGANIZATION_MEMBER_LIST.ROLE_TYPE.ADMIN': 'Administrator',
  'ORGANIZATION_MEMBER_LIST.ROLE_TYPE.MEMBER': 'Član',

  // error messages
  'ORGANIZATION_ERROR_MESSAGE.NON_EXISTING_ORGANIZATION_PROFILE': 'Kod ne odgovara ni jednoj organizaciji u sustavu',

  // ----------- Payment Info
  'PAYMENT_INFO_FORM.BANK_NAME_LABEL': 'Banka',
  'PAYMENT_INFO_FORM.IBAN_LABEL': 'IBAN',

  // ----------- SkillBased Recommendations
  'SKILL_BASED_RECOMMENDATION.FILTER.TYPE_LABEL': 'Preporuči za',
  'SKILL_BASED_RECOMMENDATION.FILTER.TYPE_USER_LABEL': 'korisnika',
  'SKILL_BASED_RECOMMENDATION.FILTER.TYPE_WORKPOSITION_LABEL': 'radno mjesto',
  'SKILL_BASED_RECOMMENDATION.FILTER.USER_LABEL': 'Korisnik',
  'SKILL_BASED_RECOMMENDATION.FILTER.WORKPOSITION_LABEL': 'Radno mjesto',
  'SKILL_BASED_RECOMMENDATION.FILTER.VALUE_RELATION_LABEL': 'Smjer razlike',
  'SKILL_BASED_RECOMMENDATION.FILTER.MAX_ABSOLUTE_DIFF_LABEL': 'Maksimalna razlika',
  'SKILL_BASED_RECOMMENDATION.FILTER.RELATION_TYPE_LOWER': 'Manje',
  'SKILL_BASED_RECOMMENDATION.FILTER.RELATION_TYPE_HIGHER': 'Više',
  'SKILL_BASED_RECOMMENDATION.FILTER.RELATION_TYPE_BETWEEN': 'Između',
  'SKILL_BASED_RECOMMENDATION.FILTER.VALUE_REALTION_TOOLTIP': 'Manje - oni koji imaju manju razinu vještine u zadanoj maksimalnoj razlici. Više - oni koji imaju veću razinu vještine u zadanoj maksimalnoj razlici. Između - oni koji imaju manju/višu razinu vještine u zadanoj maksimalnoj razlici',
};
export default hr;
