import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import RoleUtils from '@src/service/util/role/RoleUtils';

export interface IUserRoleRouteGuardPublicProps {
  roles: UserRoleEnum[];
}
export interface IUserRoleRouteGuardStateProps {
  currentUser: IUserInfo;
}
type IUserRoleRouteGuardProps = IUserRoleRouteGuardPublicProps & IUserRoleRouteGuardStateProps & WithRouterProps;

export interface IUserRoleRouteGuardState {
  allowed: boolean;
}

/**
 * User role based route guard.
 *
 * Compares list of current user's roles with given roles whitelist and renders children if any of them match.
 * If non of them match, user is redirect to logged user's default page (from config).
 */
class UserRoleRouteGuard extends React.Component<IUserRoleRouteGuardProps, IUserRoleRouteGuardState> {
  state: IUserRoleRouteGuardState = {
    allowed: false,
  };

  componentDidMount() {
    this.check();
  }

  componentDidUpdate(previousProps: IUserRoleRouteGuardProps) {
    if (this.props !== previousProps) {
      this.check();
    }
  }

  render() {
    return <React.Fragment>{this.state.allowed && this.props.children}</React.Fragment>;
  }

  private check() {
    const isAllowed = this.isAllowed();
    // always set state in case this component doesn't get unmounted on redirect
    this.setState({ allowed: isAllowed });

    if (!isAllowed) {
      this.redirectAway();
    }
  }

  private isAllowed(): boolean {
    if (this.props.currentUser == null) {
      console.warn('Cannot check user roles if current user is empty. This protection does not work on public pages.');
      return false;
    }

    // find if any of users roles appears in allowed roles list
    return RoleUtils.allowedRoles(this.props.roles, this.props.currentUser.roles);
  }

  private redirectAway() {
    this.props.router.replace(this.getRedirectRoute());
  }

  private getRedirectRoute(): string {
    // default route for logged users - if user is not logged in, authetication route guard should take care of him
    return AppConfigService.getValue('routing.publicDefaultRoute');
  }
}

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IUserRoleRouteGuardStateProps => ({
  // tutorList: TutorListBusinessStore.selectors.getTutorList(state),
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
});

// store exported component so we can use it in withXXX HOC
const ExportComponent = connect<IUserRoleRouteGuardStateProps, any, IUserRoleRouteGuardPublicProps>(mapStateToProps)(withRouter(UserRoleRouteGuard));
export default ExportComponent;

// ----- withUserRoleRouteGuard

/** Higher order component for wrapping existing component with route guard. */
const withUserRoleRouteGuard = <P extends object>(Component: React.ComponentType<P>, roles: UserRoleEnum[]) =>
  // tslint:disable-next-line:max-classes-per-file - don't want to declare this in a new file since it is only a util
  class WrappedComponent extends React.Component<P> {
    render() {
      // TODO: find how to type router Route props
      const wrappedProps = (this.props as any).route.props;
      return (
        <ExportComponent {...wrappedProps} roles={roles}>
          <Component {...this.props} />
        </ExportComponent>
      );
    }
  };

export { withUserRoleRouteGuard };
