import { IIdDataPayload } from '@src/service/business/common/types';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

import { ISkillGroup } from '@src/model/skillgroup/SkillGroup';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError, createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { startGlobalProgress, stopGlobalProgress, trackAction } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts
export interface ISkillGroupListFilter {
  withInactive?: boolean;
}

export interface ISkillGroupCreatePayload {
  title: string;
  active: boolean;
}

// List filter ID
const SKILL_GROUP_LIST_FILTER = '@@SKILL_GROUP_LIST_FILTER';

// -
// -------------------- Selectors

/** Returns SkillGroup list from store. */
const getSkillGroupList = (store: any): ICollectionData<ISkillGroup> => store.skillGroupList;

/** Returns SkillGroup list filter. */
const getSkillGroupListFilter = (store: any): ISkillGroupListFilter => ListFilterBusinessStore.selectors.getListFilter(store, SKILL_GROUP_LIST_FILTER);

// -
// -------------------- Actions
const Actions = {
  SKILL_GROUP_LIST_FETCH: 'SKILL_GROUP_LIST_FETCH',
  SKILL_GROUP_LIST_LOAD: 'SKILL_GROUP_LIST_LOAD',
  SKILL_GROUP_LIST_CLEAR: 'SKILL_GROUP_LIST_CLEAR',
  SKILL_GROUP_CREATE: 'SKILL_GROUP_CREATE',
  SKILL_GROUP_UPDATE: 'SKILL_GROUP_UPDATE',
};

/** Fetch SkillGroup list by filter */
const fetchSkillGroupList = (params: ICollectionFetchPayload<ISkillGroupListFilter>): IPayloadAction<ICollectionFetchPayload<ISkillGroupListFilter>> => {
  return {
    type: Actions.SKILL_GROUP_LIST_FETCH,
    payload: params,
  };
};

/** Load SkillGroup list to the store */
const loadSkillGroupList = (data: ICollectionData<ISkillGroup>): IPayloadAction<ICollectionData<ISkillGroup>> => {
  return {
    type: Actions.SKILL_GROUP_LIST_LOAD,
    payload: data,
  };
};

/** Clear SkillGroup list from store. Eg. when leaving view. */
const clearSkillGroupList = (): ILemonAction => {
  return {
    type: Actions.SKILL_GROUP_LIST_CLEAR,
  };
};

/** Create SkillGroup */
const createSkillGroup = (data: ISkillGroupCreatePayload): IPayloadAction<ISkillGroupCreatePayload> => {
  return {
    type: Actions.SKILL_GROUP_CREATE,
    payload: data,
  };
};

/** Update SkillGroup by id */
const updateSkillGroup = (data: ISkillGroup): IPayloadAction<IIdDataPayload<ISkillGroup>> => {
  return {
    type: Actions.SKILL_GROUP_UPDATE,
    payload: {
      id: data.id,
      data,
    },
  };
};

const storeSkillGroupListFilter = (listFilter: ISkillGroupListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(SKILL_GROUP_LIST_FILTER, listFilter);
};

const clearSkillGroupListFilter = (): ILemonAction => {
  return ListFilterBusinessStore.actions.clearListFilter(SKILL_GROUP_LIST_FILTER);
};

// -
// -------------------- Side-effects
const fetchSkillGroupListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<ISkillGroupListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.SKILL_GROUP_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('SkillGroup')
        .fetchEntityList(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadSkillGroupList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error fetching SkillGroup list', error);
      return o;
    })
  );
};

const createSkillGroupEffect = (action$: Observable<IPayloadAction<ISkillGroupCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.SKILL_GROUP_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('SkillGroup')
        .createEntity(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'SKILL_GROUP_LIST.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error creating SkillGroup', error);
      return o;
    })
  );
};

const updateSkillGroupEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ISkillGroup>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.SKILL_GROUP_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('SkillGroup')
        .updateEntity(id, data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'SKILL_GROUP_LIST.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error updating SkillGroup', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers
const skillGroupList = (state: ICollectionData<ISkillGroup> | null = null, action: IPayloadAction<ICollectionData<ISkillGroup>>) => {
  if (action.type === Actions.SKILL_GROUP_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.SKILL_GROUP_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store
export const SkillGroupBusinessStore = {
  actions: {
    fetchSkillGroupList,
    loadSkillGroupList,
    clearSkillGroupList,
    createSkillGroup,
    updateSkillGroup,
    storeSkillGroupListFilter,
    clearSkillGroupListFilter,
  },

  selectors: {
    getSkillGroupList,
    getSkillGroupListFilter,
  },

  effects: {
    fetchSkillGroupListEffect,
    createSkillGroupEffect,
    updateSkillGroupEffect,
  },

  reducers: {
    skillGroupList,
  },
};

// --
// export business store
export default SkillGroupBusinessStore;
