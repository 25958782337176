import { Form } from '@ant-design/compatible';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';

import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { SearchOutlined } from '@ant-design/icons';
import { WebinarStatusEnum } from '@src/model/webinar/Webinar';
import { IWebinarsFilter } from '@src/service/business/webinars/webinarListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { Col, Input, Row, Select } from 'antd';
import { debounce } from 'lodash';
import React from 'react';

const Option = Select.Option;

const debounceTimeout = AppConfigService.getValue('components.common.debounceTimeout');

const sortItems = [
  {
    id: 'title,asc',
    translationKey: 'COMMON.ACTION_SORT_ASCEND',
  },
  {
    id: 'title,desc',
    translationKey: 'COMMON.ACTION_SORT_DESCEND',
  },
  {
    id: 'estimatedStartDateTime,asc',
    translationKey: 'WEBINAR_LIST_VIEW.SORT_START_DATE_ASCEND',
  },
  {
    id: 'estimatedStartDateTime,desc',
    translationKey: 'WEBINAR_LIST_VIEW.SORT_START_DATE_DESCEND',
  },
];

// -- Prop types
// ----------

interface IWebinarListViewFilterOwnProps {
  webinarListFilter: IWebinarsFilter;
  sortValue: string;
  showStatusFilter?: boolean;
  onFilterChange?: (data: IWebinarsFilter) => void;
  onSortChange?: (sort: string) => void;
}
type IWebinarListViewFilterProps = IWebinarListViewFilterOwnProps & IWithLocalizeOwnProps & FormComponentProps;

// -- Component
// ----------

/**  */
class WebinarListViewFilter extends React.Component<IWebinarListViewFilterProps> {

  handleStatusChange = debounce((data: string) => {
    this.props.onFilterChange?.({ ...this.props.webinarListFilter, statuses: [data] });
  }, debounceTimeout);

  handleTitleSearch = (value: string) => {
    if (value.trim()) {
      this.props.onFilterChange?.({ ...this.props.webinarListFilter, title: value.trim() });
    } else {
      const { title, ...filter } = this.props.webinarListFilter;
      this.props.onFilterChange?.(filter);
    }
  };

  render = () => {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form layout="vertical">
        <Row gutter={16} className="timun-wrapper__gutterBox--vertical" align="middle">
          <Col xs={24} sm={12} md={this.props.showStatusFilter ? 6 : 12}>
            <Form.Item label={this.props.translate('COMMON.LABEL.NAME')}>
              {getFieldDecorator('title', {
                initialValue: this.props.webinarListFilter?.title,
              })(
                <Input.Search prefix={<SearchOutlined />} allowClear={true} placeholder={this.props.translate('COMMON.ACTION_SEARCH')} onSearch={this.handleTitleSearch} />
              )}
            </Form.Item>
          </Col>
          {this.props.showStatusFilter &&
            <Col xs={24} sm={12}>
              <Form.Item label={this.props.translate('COMMON.LABEL.STATUS')}>
                {getFieldDecorator('statuses', {
                  initialValue: this.props.webinarListFilter?.statuses,
                })(
                  <Select className="full-width" mode="multiple" placeholder={this.props.translate('WEBINAR_LIST_VIEW.ALL_WEBINARS')} allowClear={true} onChange={this.handleStatusChange}>
                    {Object.keys(WebinarStatusEnum).map((item: string) => (
                      <Option value={WebinarStatusEnum[item as keyof typeof WebinarStatusEnum]} key={WebinarStatusEnum[item as keyof typeof WebinarStatusEnum]}>
                        {this.props.translate(`WEBINAR.STATUS_TITLE.${item}`)}
                      </Option>
                    ))}
                  </Select>
                )}
              </Form.Item>
            </Col>
          }
          <Col xs={24} sm={12} md={this.props.showStatusFilter ? 6 : 12}>
            <Form.Item label={this.props.translate('COMMON.ACTION_SORT_LABEL')}>
              <Select onChange={this.props.onSortChange} value={this.props.sortValue} data-test-id="timun-webinarListFilter__sortSelect">
                {sortItems.map((item) => {
                  return (
                    <Select.Option key={item.id} value={item.id} data-test-id={`timun-webinarListFilter__sortSelect_option_${item.id}`}>
                      {this.props.translate(item.translationKey)}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  };
}

const WebinarListViewFilterForm = Form.create()(WebinarListViewFilter);

// -- HOCs and exports
// ----------

export default withLocalize<IWebinarListViewFilterOwnProps>(WebinarListViewFilterForm as any);
