import EnrollButtonView from '@src/components/common/enroll/EnrollButtonView';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { CourseVisibilityEnum, ICourse } from '@src/model/course/Course';
import { IUserInfo } from '@src/model/user/User';
import { ICollectionData, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import CourseListBusinessStore, { ICourseListFilter } from '@src/service/business/courses/courseListBusinessStore';
import UsersCoursesUpdateBusinessStore, { IEnrollUsersCoursesPayload } from '@src/service/business/courses/UsersCoursesUpdateBusinessStore';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import React from 'react';
import { connect } from 'react-redux';

// -- Prop types
// ----------

interface ICourseEnrollButtonContainerOwnProps {
  course: ICourse;
  onEnroll: () => void;
}

interface ICourseEnrollButtonContainerStateProps {
  currentUser: IUserInfo;
  traineeCourseList: ICollectionData<ICourse>;
}

interface ICourseEnrollButtonContainerDispatchProps {
  fetchTraineeCourseList: (id: string, filter: ICourseListFilter, page: number, size: number, sort: string[]) => void;

  enrollUser: (data: IEnrollUsersCoursesPayload) => ITrackableAction;
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
}
type ICourseEnrollButtonContainerProps = ICourseEnrollButtonContainerOwnProps & ICourseEnrollButtonContainerStateProps & ICourseEnrollButtonContainerDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** */
class CourseEnrollButtonContainer extends React.Component<ICourseEnrollButtonContainerProps> {
  componentDidMount = () => {
    if (this.props.currentUser) {
      this.fetchTraineeCourseList();
    }
  };
  render = () => {
    return !this.isEnrolled() ?
      <EnrollButtonView onEnroll={this.handleEnroll} /> : null; {/* TODO: add a message/element to show enroll status? */ }
  };

  handleEnroll = () => {
    this.props.enrollUser({
      courses: [
        { id: this.props.course.id },
      ],
    }).track().subscribe(
      () => {
        this.props.reportMessage({ message: this.props.translate('COURSE_VIEW.ENROLL_MESSAGE', { courseName: this.props.course.title }), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
        this.fetchTraineeCourseList();
        this.props.onEnroll();
      }
    );
  };

  allowUserEnroll = () => {
    if (this.props.course) {
      const isCoursePublic = this.props.course.visibility.id === CourseVisibilityEnum.PUBLIC;
      return isCoursePublic;
    } else {
      return false;
    }
  };

  isEnrolled = () => {
    if (this.props.traineeCourseList && this.props.course) {
      const isEnrolled = this.props.traineeCourseList.content.some((course) => {
        return course.id === this.props.course.id;
      });

      return isEnrolled;
    } else { // if there is no traineeCourseList or fetched course, disable enroll button
      return true;
    }
  };

  private fetchTraineeCourseList = () => {
    this.props.fetchTraineeCourseList(this.props.currentUser.id, {}, 0, AppConfigService.getValue('api.collectionDefaultLimit'), []);
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ICourseEnrollButtonContainerOwnProps): ICourseEnrollButtonContainerStateProps => ({
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
  traineeCourseList: CourseListBusinessStore.selectors.getCourseList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ICourseEnrollButtonContainerDispatchProps => ({
  fetchTraineeCourseList: (id: string, filter: ICourseListFilter, page: number, size: number, sort: string[]) => dispatch(CourseListBusinessStore.actions.fetchTraineeCourseList(id, filter, page, size, sort)),
  enrollUser: (data: IEnrollUsersCoursesPayload) => createTrackableAction(dispatch(UsersCoursesUpdateBusinessStore.actions.enrollUsersCourses(data))),
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<ICourseEnrollButtonContainerOwnProps>(CourseEnrollButtonContainer as any));
