import { IUserRole, UserRoleEnum } from '@src/model/user/UserRole';
import { getCurrentUser } from '@src/service/business/login/loginBusinessService';

/** Static role utility methods. */
export default class RoleUtils {

  /**
   * Returns true if current user has at least one of requested roles in it's list.
   * If user is superadmin method returns true without further checks.
   */
  static allowedRoles = (requestedRoles: UserRoleEnum[], userRoles?: IUserRole[]): boolean => {
    const targetUserRoles = RoleUtils.getTargetRoles(userRoles);
    // all hail superadmin
    if (RoleUtils.isSuperAdmin(targetUserRoles)) {
      return true;
    }

    const match = RoleUtils.matchingRoles(requestedRoles, targetUserRoles);
    return match.length > 0;
  };

  /** Returns list of requested user role enums which are FOUND in user roles list. */
  static matchingRoles(requestedRoles: UserRoleEnum[], userRoles?: IUserRole[]): UserRoleEnum[] {
    if (!userRoles) {
      return [];
    }
    return requestedRoles.filter((role) => userRoles.find((userRole) => userRole.id === role));
  }

  /** Check if user has at least one requested role in it's role list. This is intended for strict role checking. No superadmin checks are performed. */
  static isInRoles(requestedRoles: UserRoleEnum[], userRoles?: IUserRole[]): boolean {
    return RoleUtils.matchingRoles(requestedRoles, RoleUtils.getTargetRoles(userRoles)).length > 0;
  }

  /** Check if user has superadmin role in it's list. */
  static isSuperAdmin(userRoles?: IUserRole[]): boolean {
    return RoleUtils.isInRoles([UserRoleEnum.SUPERADMIN], RoleUtils.getTargetRoles(userRoles));
  }

  /* check role list, if none is provided get current user's role list */
  static getTargetRoles = (userRoles?: IUserRole[]): IUserRole[] | undefined => {
    return userRoles ?? getCurrentUser()?.roles;
  };
}
