import AppContent from '@src/components/common/container/AppContent';
import GridItem from '@src/components/common/grid/GridItem';
import LemonIcon from '@src/components/common/image/LemonIcon';
import LemonImage from '@src/components/common/image/LemonImage';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import StatusBadge from '@src/components/common/status/StatusBadge';
import TimunShortInfoTable from '@src/components/common/table/TimunShortInfoTable';
import OrganizationListFilter from '@src/components/organization/list/OrganizationListFilter';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import { IOrganization } from '@src/model/organization/Organization';
import { ICollectionData } from '@src/service/business/common/types';
import { IOrganizationListFilter } from '@src/service/business/organization/OrganizationListBusinessStore';
import { Button, Divider, Row } from 'antd';
import Column from 'antd/lib/table/Column';
import React from 'react';

// -- Prop types
// ----------
export interface IOrganizationListViewOwnProps {
  organizationList: ICollectionData<IOrganization>;
  filter?: IOrganizationListFilter;
  canAdd?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
  onOrganizationAdd?: () => void;
  onOrganizationEdit?: (organization: IOrganization) => void;
  onOrganizationDelete?: (organization: IOrganization) => void;
  onFilterChange?: (filter: IOrganizationListFilter) => void;
  onPageChange?: (page: number, size?: number) => void;
}

type IOrganizationListViewProps = IOrganizationListViewOwnProps & IWithLocalizeOwnProps & IWithTenantPropEnabledOwnProps & IWithPathResolverOwnProps;

interface IOrganizationListViewState {
}

// -- Component
// ----------
class OrganizationListView extends React.Component<IOrganizationListViewProps, IOrganizationListViewState> {
  state: IOrganizationListViewState = {};

  render() {

    return (
      <AppContent>
        <Row>
          <OrganizationListFilter filter={this.props.filter} onSubmit={this.props.onFilterChange} />
        </Row>

        {this.props.canAdd &&
          <React.Fragment>
            <Row className="timun-wrapper__gutterBox" justify="end">
              <Button icon={<LemonIcon name="plus" />} type="primary" onClick={this.props.onOrganizationAdd}>
                {this.props.translate('ORGANIZATION_LIST.ADD_BUTTON_LABEL')}
              </Button>
            </Row>
          </React.Fragment>
        }

        <Divider />

        <TimunShortInfoTable<IOrganization>
          className="timun-organizationList__table"
          titlePropPath="company.name"
          itemBaseRoute="organizations"
          bordered={false}
          items={this.props.organizationList.content}
          paginationData={this.props.organizationList.page}
          showPagination={true}
          renderFrontAdditionalColumns={this.renderLogo}
          renderBackAdditionalColumns={this.renderStatusColumn}
          rowDescription={this.renderOrganizationRowDescription}
          paginationConfig={{ onChange: this.props.onPageChange }}
          actionButtons={{
            edit: { showButton: true, onClick: this.props.onOrganizationEdit },
            delete: { showButton: true, onClick: this.props.onOrganizationDelete },
          }}
        />
      </AppContent>
    );
  }

  renderLogo = () => (
    <Column
      className="timun-organizationList__logoImageColumn"
      key="logo"
      align="center"
      render={(record: IOrganization) => <LemonImage className="timun-organizationList__logoImage" imagePath={record.logo ? this.props.resolveApiFilePath(record.logo.id) : undefined} />}
    />
  );

  renderOrganizationRowDescription = (record: IOrganization) => (
    <GridItem label=" " key={record.id} contentType="secondary">
      {`${record.company.address.streetWithHomeNumber}, ${record.company.address.city}`}
    </GridItem>
  );

  renderStatusColumn = () => <Column key="ordering" width="5%" align="center" render={(record: IOrganization) => <StatusBadge status={record.active} />} />;
}

// -- HOCs and exports
// ----------
export default withLocalize<IOrganizationListViewOwnProps>(withPathResolver(withTenantPropEnabled(OrganizationListView as any)));
