import AdminCommentList from '@src/components/comment/AdminCommentList';
import ListPagination, { ListPaginationTypeEnum } from '@src/components/common/list/ListPagination';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import { IComment } from '@src/model/comment/Comment';
import { IUserInfo } from '@src/model/user/User';
import CommentsBusinessStore, { ICommentsFilter } from '@src/service/business/comment/commentsBusinessStore';
import { ICollectionData, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createActionThunk, IActionThunkMap } from '@src/service/util/action/thunk';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Dispatch } from 'redux';

// -- Const
// ----------
const DEFAULT_SORT_VALUE = 'addedDateTime,desc';

// -- PropTypes
// ----------

export interface IAdminCommentListContainerOwnProps {
}

export interface IAdminCommentListContainerStateProps {
  comments: ICollectionData<IComment>;
  commentsFilter: ICommentsFilter;
  currentUser: IUserInfo;
}
export interface IAdminCommentListContainerDispatchProps {
  fetchComments: (commentFilter: ICommentsFilter, size: number, page: number, sort: string[], callback: IActionThunkMap) => void;
  approveComment: (id: string) => ITrackableAction;
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
}

type IAdminCommentListContainerProps = IAdminCommentListContainerDispatchProps & IAdminCommentListContainerStateProps & IAdminCommentListContainerOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

// --
// ----- State types
interface IAdminCommentListContainerState {
  page: number;
  size: number;
  sort: string;
}

// -- Component
// ----------

/** container for displaying, editing & deleting comments and comment votes */
class AdminCommentListContainer extends React.Component<IAdminCommentListContainerProps, IAdminCommentListContainerState> {
  state: IAdminCommentListContainerState = {
    page: 0,
    size: AppConfigService.getValue('api.paging.smallPageSize'),
    sort: DEFAULT_SORT_VALUE,
  };

  componentDidMount = () => {
    // initial list update
    this.fetchCommentList();
  };

  componentDidUpdate = (prevProps: IAdminCommentListContainerProps, prevState: IAdminCommentListContainerState) => {
    if (this.state !== prevState) {
      this.fetchCommentList();
    }
  };

  render() {

    return (
      <React.Fragment>
        {/* List */}
        {this.props.comments && <AdminCommentList commentList={this.props.comments.content} onApprove={this.handleApprove} />}
        {/* Pagination */}
        {this.props.comments && <ListPagination page={this.props.comments.page} paginationType={ListPaginationTypeEnum.LOAD_MORE} onLoadMore={this.handleLoadMore} />}
      </React.Fragment>
    );
  }

  handleApprove = (comment: IComment) => {
    this.props.approveComment(comment.id).track().subscribe(
      //  success
      () => {
        this.fetchCommentList();
        this.props.reportMessage({ message: this.props.translate('COMMENTS_VIEW.APPROVED_INFO_MESSAGE', { content: comment.content, firstName: comment.creator?.firstName, lastName: comment.creator?.lastName }), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.INFO });
      }
    );
  };

  handleLoadMore = () => {
    this.setState((prevState) => {
      return {
        size: prevState.size + 5,
      };
    });
  };

  fetchCommentList = () => {
    const filter = {
      ...this.props.commentsFilter,
      approved: false,
    };
    this.props.fetchComments(filter, this.state.size, this.state.page, [this.state.sort], {});
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IAdminCommentListContainerOwnProps): IAdminCommentListContainerStateProps => ({
  comments: CommentsBusinessStore.selectors.getComments(state),
  commentsFilter: CommentsBusinessStore.selectors.getCommentsFilter(state),
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: Dispatch): IAdminCommentListContainerDispatchProps => ({
  fetchComments: (commentFilter: ICommentsFilter, size: number, page: number, sort: string[], thunkMap: IActionThunkMap) => dispatch(createActionThunk(CommentsBusinessStore.actions.fetchComments(commentFilter, size, page, sort), thunkMap)),
  approveComment: (commentId: string) => createTrackableAction(dispatch(CommentsBusinessStore.actions.approveComment(commentId))),
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<IAdminCommentListContainerOwnProps>(withRoles(withRouter(AdminCommentListContainer as any))));
