import React from 'react';
import { connect } from 'react-redux';

import ITenantConfiguration from '@src/model/tenant/TenantConfiguration';
import PublicTenantConfigurationBusinessStore from '@src/service/business/tenant/publicTenantConfigurationBusinessService';
import { resolvePropValues, TenantPropsType } from '@src/service/business/tenant/tenantPropEnabled';

export interface IWithTenantPropEnabledOwnProps {
  /**
   * Check if tenant prop(s) are enabled.
   *
   * @see {resolvePropValues} for implementation details.
   */
  isTenantPropEnabled: (propName: TenantPropsType | TenantPropsType[]) => boolean;
}

interface IWithTenantPropEnabledStateProps {
  tenantConfiguration: ITenantConfiguration;
}

type IWithTenantPropEnabledProps = IWithTenantPropEnabledOwnProps & IWithTenantPropEnabledStateProps;

/**
 * Higher order component injects utility functions for checking if tenant props are enabled.
 *
 * @see {resolvePropValue} for implementation details.
 */
const withTenantPropEnabled = <P extends object>(Component: React.ComponentType<P & IWithTenantPropEnabledOwnProps>) => {
  // create a wrapper component class
  class WithTenantPropEnabledWrapper extends React.Component<P & IWithTenantPropEnabledProps> {
    render() {
      return <Component {...this.props} isTenantPropEnabled={this.isTenantPropEnabled} />;
    }

    /** Check if user has that role in it's list. If current user is empty, returns false. */
    isTenantPropEnabled = (propName: TenantPropsType | TenantPropsType[]): boolean => {
      return resolvePropValues(propName, this.props.tenantConfiguration);
    };

  }

  // we're doing redux connect inside because we need to connect wrapper role and not the HOC
  const mapStateToProps = (state: any, ownProps: P): IWithTenantPropEnabledStateProps => ({
    tenantConfiguration: PublicTenantConfigurationBusinessStore.selectors.getTenantConfiguration(state),
  });

  // force type hinting exported component to "P" which is the original wrapped component's props type
  return connect<IWithTenantPropEnabledStateProps, undefined, P>(mapStateToProps)(WithTenantPropEnabledWrapper as any);
};

export default withTenantPropEnabled;
