import React from 'react';

import MathProvider from '@src/components/common/math/MathProvider';
import AppConfigService from '@src/service/common/AppConfigService';

const MATH_SRC = AppConfigService.getValue('math.src');
const MATH_OPTIONS = AppConfigService.getValue('math.options');

/** Main application shell. Can be used for initializing various resources (eg. context). */
export default class App extends React.Component {
  render() {
    return (
      <MathProvider src={MATH_SRC} options={MATH_OPTIONS}>
        <div className="lemon-app__container">{this.props.children}</div>
      </MathProvider>
    );
  }
}
