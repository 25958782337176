import FilePreviewModal from '@src/components/common/file/FilePreviewModal';
import FileUtils, { ITimunFile } from '@src/components/common/file/FileUtils';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ViewSectionContentItem from '@src/components/common/section/ViewSectionContentItem';
import { IFileListsByType } from '@src/components/externalEducationApplication/common/types';
import { FileTypeEnum } from '@src/model/file/FileSystemElement';
import { Button, Col, Row } from 'antd';
import React from 'react';

interface IEducationApplicationUserFileListViewOwnProps {
  displayTypes: FileTypeEnum[];
  files?: IFileListsByType;
}
type IEducationApplicationUserFileListViewProps = IEducationApplicationUserFileListViewOwnProps & IWithLocalizeOwnProps;

interface IEducationApplicationUserFileListViewState {
  fileToDisplay?: ITimunFile;
}

class EducationApplicationUserFileListView extends React.Component<IEducationApplicationUserFileListViewProps, IEducationApplicationUserFileListViewState> {
  state: IEducationApplicationUserFileListViewState = {
    fileToDisplay: undefined,
  };

  render = () => {
    return <React.Fragment>
      <Row justify="space-between" gutter={16}>
        {this.props.displayTypes.map((type) => {
          const fileList = this.props.files && this.props.files[type];
          if (fileList && fileList.length) {
            return fileList.map((file) => {
              return (
                <Col xs={24} md={24} lg={12} key={file.uid}>
                  <ViewSectionContentItem
                    className="timun-userViewSection__file"
                    icon={<LemonIcon name="paperClip" size="xlarge" />}
                    title={file.name}
                    description={file.type ? this.props.translate(`EXTERNAL_EDUCATION_APPLICATION.FILES.${file.response?.type.name}_LABEL`) : undefined}
                    actions={this.renderFileActions(file)}
                  />
                </Col>
              );
            });
          }
          return null;

        })}
      </Row>
      {this.state.fileToDisplay && <FilePreviewModal closeModal={this.handleClosePreview} record={this.state.fileToDisplay} />}
    </React.Fragment>;
  };

  renderFileActions = (file: ITimunFile) => {

    return <Row className="margin-auto">
      <Button href={file.url}><LemonIcon name="download" size="small" /></Button>
      &nbsp;&nbsp;
      {FileUtils.isFilePreviewable(file.type) && <Button onClick={() => this.toggleImagePreviewVisible(file)}><LemonIcon name="eye" size="small" /></Button>}
    </Row>;
  };

  toggleImagePreviewVisible = (_file: ITimunFile) => {
    this.setState({ fileToDisplay: _file });
  };

  handleClosePreview = () => {
    this.setState({
      fileToDisplay: undefined,
    });
  };
}

export default withLocalize<IEducationApplicationUserFileListViewOwnProps>(EducationApplicationUserFileListView as any);

