import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

import { IComment } from '@src/model/comment/Comment';
import { ICurrentUserVote } from '@src/model/comment/VoteDetails';
import { VoteTypeEnum } from '@src/model/comment/VoteObjectType';
import IIdRef from '@src/model/common/IdRef';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, IIdDataPayload, IIdPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { actionThunk, trackAction } from '@src/service/util/observable/operators';
import { reportCaughtMessage, startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts
export interface ICommentsFilter {
  objectId: string;
  objectType: string;
  approved: boolean;
}

export interface ICommentCreatePayload {
  content: string;
  objectType: IIdRef<string>;
  object: IIdRef<string>;
}

// List filter ID
const COMMENTS_LIST_FILTER = '@@COMMENTS_LIST_FILTER';

// -
// -------------------- Selectors

const getComments = (store: any): ICollectionData<IComment> => store.comments;

/** Returns comments filter. */
const getCommentsFilter = (store: any): ICommentsFilter => ListFilterBusinessStore.selectors.getListFilter(store, COMMENTS_LIST_FILTER);

// -
// -------------------- Actions

const Actions = {
  COMMENTS_FETCH: 'COMMENTS_FETCH',
  COMMENTS_LOAD: 'COMMENTS_LOAD',
  COMMENTS_CLEAR: 'COMMENTS_CLEAR',
  COMMENT_CREATE: 'COMMENT_CREATE',
  COMMENT_DELETE: 'COMMENT_DELETE',
  COMMENT_UPDATE: 'COMMENT_UPDATE',
  VOTE_ON_COMMENT: 'VOTE_ON_COMMENT',
  VOTE_DELETE: 'VOTE_DELETE',
  COMMENT_APPROVE: 'COMMENT_APPROVE',
};

const clearCommentsFilter = (): ILemonAction => {
  return ListFilterBusinessStore.actions.clearListFilter(COMMENTS_LIST_FILTER);
};

const storeCommentsFilter = (listFilter: ICommentsFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(COMMENTS_LIST_FILTER, listFilter);
};

const fetchComments = (commentFilter: ICommentsFilter, size: number, page: number, sort: string[]): IPayloadAction<ICollectionFetchPayload<ICommentsFilter>> => {
  return {
    type: Actions.COMMENTS_FETCH,
    payload: {
      filter: commentFilter,
      size,
      page,
      sort,
    },
  };
};

/** Create comment */
const createComment = (data: ICommentCreatePayload): IPayloadAction<ICommentCreatePayload> => {
  return {
    type: Actions.COMMENT_CREATE,
    payload: data,
  };
};

/** Delete comment by ID */
const deleteComment = (data: IIdRef<string>): IPayloadAction<IIdRef<string>> => {
  return {
    type: Actions.COMMENT_DELETE,
    payload: data,
  };
};

/** Update comment by ID */
const updateComment = (data: IComment): IPayloadAction<IIdDataPayload<IComment>> => {
  return {
    type: Actions.COMMENT_UPDATE,
    payload: {
      id: data.id,
      data,
    },
  };
};

/** Vote on comment by comment id */
const voteOnComment = (commentId: string, voteTypeId: IIdRef<VoteTypeEnum>): IPayloadAction<IIdDataPayload<ICurrentUserVote>> => {
  return {
    type: Actions.VOTE_ON_COMMENT,
    payload: {
      id: commentId,
      data: { voteType: voteTypeId },
    },
  };
};

/** Delete vote on comment by vote id and comment id */
const deleteVote = (commentId: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.VOTE_DELETE,
    payload: {
      id: commentId,
    },
  };
};

/** Load comments to store. */
const loadComments = (data: ICollectionData<IComment>): IPayloadAction<ICollectionData<IComment>> => {
  return {
    type: Actions.COMMENTS_LOAD,
    payload: data,
  };
};

const clearComments = (): ILemonAction => {
  return {
    type: Actions.COMMENTS_CLEAR,
  };
};

/** Approve Comment by id. */
const approveComment = (commentId: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.COMMENT_APPROVE,
    payload: {
      id: commentId,
    },
  };
};

// -
// -------------------- Side-effects

const fetchCommentsEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<ICommentsFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COMMENTS_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Comment')
        .fetchEntityList(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadComments(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error fetching comments list', error);
      return o;
    })
  );
};

const createCommentEffect = (action$: Observable<IPayloadAction<ICommentCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COMMENT_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Comment')
        .createEntity(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating comment', error);
      return o;
    })
  );
};

const deleteCommentEffect = (action$: Observable<IPayloadAction<IIdRef<string>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COMMENT_DELETE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Comment')
        .deleteEntity(payload.id)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_DELETE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error deleting comment', error);
      return o;
    })
  );
};

const updateCommentEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IComment>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COMMENT_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Comment')
        .updateEntity(payload.id, payload.data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error updating comment', error);
      return o;
    })
  );
};

const voteOnCommentEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IIdRef<ICurrentUserVote>>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.VOTE_ON_COMMENT;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Comment')
        .createSubobject(payload.id, 'vote', payload.data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.VOTE_ON_COMMENT_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error voting on comment', error);
      return o;
    })
  );
};

const deleteVoteEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.VOTE_DELETE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Comment')
        .deleteSubobject(payload.id, 'vote', {})
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.DELETE_VOTE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error deleting vote', error);
      return o;
    })
  );
};

const approveCommentEffect = (action$: Observable<IPayloadAction<IIdRef<string>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COMMENT_APPROVE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Comment')
        .updateEntityMethod(payload.id, 'approve', {})
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error approving comment', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const comments = (state: ICollectionData<IComment> | null = null, action: IPayloadAction<ICollectionData<IComment>>) => {
  if (action.type === Actions.COMMENTS_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.COMMENTS_CLEAR) {
    return null;
  }

  return state;
};

export const CommentsBusinessStore = {
  actions: {
    fetchComments,
    clearComments,
    clearCommentsFilter,
    storeCommentsFilter,
    createComment,
    deleteComment,
    updateComment,
    voteOnComment,
    deleteVote,
    approveComment,
  },
  selectors: {
    getCommentsFilter,
    getComments,
  },
  effects: {
    fetchCommentsEffect,
    createCommentEffect,
    deleteCommentEffect,
    updateCommentEffect,
    voteOnCommentEffect,
    deleteVoteEffect,
    approveCommentEffect,
  },
  reducers: {
    comments,
  },
};

export default CommentsBusinessStore;
