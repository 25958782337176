import { PlusOutlined } from '@ant-design/icons';
import AppContent from '@src/components/common/container/AppContent';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IActionMenuItem } from '@src/components/common/table/ActionMenu';
import DataTable from '@src/components/common/table/DataTable';
import { ITimunDataTableCol } from '@src/components/common/table/TimunDataTableCol';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/common/EducationApplicationHelperUtils';
import { ParticipantRoleEnum } from '@src/model/activity/ActivityParticipant';
import { EducationApplicationActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { ISkill } from '@src/model/skillgroup/Skill';
import { IUserInfo } from '@src/model/user/User';
import { ICollectionData } from '@src/service/business/common/types';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router';

// -- Const
// ----------
const dateFormat = AppConfigService.getValue('dateTimeFormat.dateWithTime');

// -- Prop types
// ----------
export interface IEducationApplicationListViewOwnProps {
  currentUser?: IUserInfo;
  isOwnProfile?: boolean;
  isCurrentUserCoordinator?: boolean;
  isCurrentUserTrainee?: boolean;
  isCurrentUserAdmin?: boolean;
  applicationList: ICollectionData<IExternalEducationApplication>;
  additionalSearchParams?: boolean;
  showCreateApplicationButton?: boolean;
  showCoordinatorColumn?: boolean;
  hideMyProfileColumn?: boolean;
  scrollable?: boolean;
  userActions?: Array<IActionMenuItem<IExternalEducationApplication>>;
  userListActions?: Array<IActionMenuItem<IExternalEducationApplication[]>>;
  additionalCols?: () => Array<ITimunDataTableCol<IExternalEducationApplication>>;
  onApplicationTraineeCreation?: () => void;
  onApplicationCoordinatorCreation?: () => void;
  onPageChange?: (page: number, pageSize?: number) => void;
}

type IEducationApplicationListViewProps = IEducationApplicationListViewOwnProps & IWithLocalizeOwnProps;

interface IEducationApplicationListViewState {
}

// -- Component
// ----------
class EducationApplicationListView extends React.Component<IEducationApplicationListViewProps, IEducationApplicationListViewState> {
  state: IEducationApplicationListViewState = {};

  renderColumns = () => {
    const columns: Array<ITimunDataTableCol<IExternalEducationApplication>> = [
      {
        key: 'icon',
        contentType: 'string',
        // colWidth: '20%',
        headerTitle: '',
        // show exclamation icon next to application that need HZZ support and doesn't have assigned coordinator or need HZZ support and currentUser is assigned to that application
        // hide exclamation icon if education application have status; DENIED, EDUCATION_COMPLETED, CONTRACT_TERMINATED, USER_CANCELLED
        // tslint:disable-next-line: max-line-length
        content: (record: IExternalEducationApplication) =>
          (
            this.props.isCurrentUserCoordinator &&
            record.consultation &&
            record.consultation.startDateTime == null &&
            !EducationApplicationHelperUtils.hasCoordinator(record.activity) &&
            !EducationApplicationHelperUtils.isInPhases([EducationApplicationActivityPhaseEnum.DENIED, EducationApplicationActivityPhaseEnum.EDUCATION_COMPLETED, EducationApplicationActivityPhaseEnum.CONTRACT_TERMINATED, EducationApplicationActivityPhaseEnum.USER_CANCELLED], record.activity)) ||
            (this.props.isCurrentUserCoordinator &&
              record.consultation &&
              !record.consultation.endDateTime &&
              !EducationApplicationHelperUtils.isInPhases([EducationApplicationActivityPhaseEnum.DENIED, EducationApplicationActivityPhaseEnum.EDUCATION_COMPLETED, EducationApplicationActivityPhaseEnum.CONTRACT_TERMINATED, EducationApplicationActivityPhaseEnum.USER_CANCELLED], record.activity) &&
              EducationApplicationHelperUtils.getActivityParticipantByRole(ParticipantRoleEnum.EVALUATOR, record.activity)?.userId === this.props.currentUser?.id) ||
            (this.props.isCurrentUserAdmin &&
              record.consultation &&
              !record.consultation.endDateTime &&
              !EducationApplicationHelperUtils.isInPhases([EducationApplicationActivityPhaseEnum.DENIED, EducationApplicationActivityPhaseEnum.EDUCATION_COMPLETED, EducationApplicationActivityPhaseEnum.CONTRACT_TERMINATED, EducationApplicationActivityPhaseEnum.USER_CANCELLED], record.activity))
            ? (
              <LemonIcon name="exclamation" />
            ) : (
              undefined
            ),
      },
      {
        key: 'skills',
        contentType: 'string',
        // colWidth: '20%',
        headerTitle: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.SKILL_HEADER_TITLE'),
        content: (record: IExternalEducationApplication) =>
          record.skills?.length ? (
            <Link to={`/externaleducationapplication/${record.id}`} data-test-id={`timun-externalEducationApplicationListView__openApplicationLink_${record.id}`}>
              {record.skills.map((skill: ISkill) => (
                <Row key={skill.id}>{skill.title}</Row>
              ))}
            </Link>
          ) : (
            undefined
          ),
      },
      {
        key: 'education.educationProvider',
        contentType: 'string',
        // colWidth: '20%',
        headerTitle: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EMPLOYER_NAME_HEADER_TITLE'),
        content: (record: IExternalEducationApplication) => record.education?.educationProvider.company?.name,
      },
      {
        key: 'activityStatus',
        contentType: 'string',
        // colWidth: '20%',
        headerTitle: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.APPLICATION_STATUS_HEADER_TITLE'),
        content: (record: IExternalEducationApplication) => <Link to={`/externaleducationapplication/${record.id}`} data-test-id={`timun-externalEducationApplicationListView__openApplicationLink_${record.id}`}>{this.props.translate(`EXTERNAL_EDUCATION_APPLICATION.PHASE.${record.activity?.currentPhase?.name}_LABEL`)}</Link>,
      },
      {
        key: 'createdAt',
        contentType: 'string',
        // colWidth: '20%',
        headerTitle: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.APPLICATION_SUBMISSION_DATE_HEADER_TITLE'),
        content: (record: IExternalEducationApplication) => moment(record.createdAt).format(dateFormat),
      },
    ];
    if (this.props.additionalCols) {
      columns.push(...this.props.additionalCols());
    }
    // show coordinator name colum on MyProfile if another coordinator watching other coordinator profile or if current user is trainee/assignee
    if (!this.props.isOwnProfile && this.props.isCurrentUserCoordinator && !this.props.hideMyProfileColumn) {
      columns.push({
        key: 'evaluatorName',
        contentType: 'string',
        // colWidth: '25%',
        headerTitle: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EVALUATOR_HEADER_TITLE'),
        content: (record: IExternalEducationApplication) => EducationApplicationHelperUtils.getActivityParticipantNameByRole(ParticipantRoleEnum.EVALUATOR, record.activity),
      });
    }
    // show assignee/trainee name colum on coordinator MyProfile
    if (!this.props.isCurrentUserTrainee && this.props.isOwnProfile && !this.props.hideMyProfileColumn) {
      columns.push({
        key: 'assigneeName',
        contentType: 'string',
        // colWidth: '25%',
        headerTitle: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.ASSIGNEE_HEADER_TITLE'),
        content: (record: IExternalEducationApplication) => EducationApplicationHelperUtils.getActivityParticipantNameByRole(ParticipantRoleEnum.ASSIGNEE, record.activity),
      });
    }

    return columns;
  };

  render() {

    return (
      <AppContent title={this.props.showCreateApplicationButton ?
        <Row justify="end">
          <Button type="primary" onClick={this.props.isCurrentUserCoordinator ? this.handleApplicationCoordinatorCreation : this.handleApplicationTraineeCreation}
            data-test-id="timun-externalEducationApplicationListView__createApplicationButton"
          >
            <PlusOutlined />
            {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.NEW_APPLICATION_BUTTON_LABEL')}
          </Button>
        </Row>
        : null
      }
      >
        <DataTable<IExternalEducationApplication>
          items={this.props.applicationList.content}
          paginationData={this.props.applicationList.page}
          columns={this.renderColumns()}
          scrollable={this.props.scrollable}
          paginationConfig={{ onChange: this.props.onPageChange }}
          actionMenuItems={this.props.userActions}
          groupActions={this.props.userListActions}
        />
      </AppContent>
    );
  }

  handleApplicationTraineeCreation = () => {
    if (this.props.onApplicationTraineeCreation) {
      this.props.onApplicationTraineeCreation();
    }
  };

  handleApplicationCoordinatorCreation = () => {
    if (this.props.onApplicationCoordinatorCreation) {
      this.props.onApplicationCoordinatorCreation();
    }
  };
}

// -- HOCs and exports
// ----------
export default withLocalize<IEducationApplicationListViewOwnProps>(EducationApplicationListView as any);
