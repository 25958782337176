import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import ViewSectionItem from '@src/components/common/section/ViewSectionItem';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import ExternalEducationTemplateViewSection from '@src/components/externaleducation/ExternalEducationTemplateDetailsPanel';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/common/EducationApplicationHelperUtils';
import EducationApplicationUserFileListView from '@src/components/externalEducationApplication/common/EducationApplicationUserFileListView';
import { IFileListsByType } from '@src/components/externalEducationApplication/common/types';
import EducationApplicationDetailsViewSection from '@src/components/externalEducationApplication/view/section/EducationApplicationDetailsViewSection';
import EducationApplicationExpensesViewSection from '@src/components/externalEducationApplication/view/section/EducationApplicationExpensesViewSection';
import EducationApplicationUserViewSection from '@src/components/externalEducationApplication/view/section/EducationApplicationUserViewSection';
import ExternalEducationApplicationActivitiesViewSection from '@src/components/externalEducationApplication/view/section/ExternalEducationApplicationActivitiesViewSection';
import { ITimelineActivity } from '@src/model/activity/TimelineActivity';

import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { FileTypeEnum } from '@src/model/file/FileSystemElement';

import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import React from 'react';


// -- Prop types
// ----------
interface IExternalEducationApplicationInfoViewOwnProps {
  externalEducationApplication: IExternalEducationApplication;
  applicationFiles: IFileListsByType;
  timeline?: ITimelineActivity[];
  traineeUser?: IUserInfo;
  onCreateNote?: () => void;
  panelsActive?: boolean;
}

type IExternalEducationApplicationInfoViewProps = IExternalEducationApplicationInfoViewOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

interface IExternalEducationApplicationInfoViewState {
}

// -- Component
// ----------

class ExternalEducationApplicationInfoView extends React.Component<IExternalEducationApplicationInfoViewProps, IExternalEducationApplicationInfoViewState> {
  state: IExternalEducationApplicationInfoViewState = {
  };

  componentDidMount = () => {
    //
  };

  componentDidUpdate = (prevProps: IExternalEducationApplicationInfoViewProps, prevState: IExternalEducationApplicationInfoViewState) => {
    //
  };


  render() {
    const isCurrentUserCoordinator = this.props.isInRoles([UserRoleEnum.COORDINATOR]);

    return (
      this.props.externalEducationApplication ?
        <React.Fragment>

          {/* ---------- Common application data ---------- */}


          {/* ---------- Activities list ---------- */}
          {this.props.timeline && <ExternalEducationApplicationActivitiesViewSection canCreateNotes={isCurrentUserCoordinator} onCreateNote={this.props.onCreateNote} id={this.props.externalEducationApplication.id} timeline={this.props.timeline} />}


          {/* ---------- Education info ---------- */}
          {this.props.externalEducationApplication.education ?
            <ExternalEducationTemplateViewSection externalEducationTemplate={this.props.externalEducationApplication.education} />
            :
            <ViewSectionItem
              active={this.props.panelsActive}
              bordered={true}
              header={<HeaderTitle title={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.ABOUT_EDUCATION_PANEL_TITLE')} icon={<LemonIcon name="externalEducation" />} />}
              collapsible={true}
            >
              <EducationApplicationUserFileListView files={this.props.applicationFiles} displayTypes={[FileTypeEnum.CURRICULUM, FileTypeEnum.OFFER]} />
            </ViewSectionItem>
          }

          {/* ---------- Expenses list ---------- */}
          {this.props.externalEducationApplication?.expenses && this.props.externalEducationApplication?.expenses.length > 0 &&
            <EducationApplicationExpensesViewSection expenses={this.props.externalEducationApplication.expenses} />
          }

          {/* ---------- File list ---------- */}
          {EducationApplicationHelperUtils.hasFilesInTypes(this.props.applicationFiles, [FileTypeEnum.SIGNED_FINANCING_AGREEMENT, FileTypeEnum.PROOF_OF_PAYMENT, FileTypeEnum.COMPETENCIES_CERTIFICATE, FileTypeEnum.ATTENDANCE_CERTIFICATE]) &&
            <ViewSectionItem
              active={this.props.panelsActive}
              bordered={true}
              header={<HeaderTitle title={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.AGREEMENTS_AND_CERTIFICATES_PANEL_TITLE')} icon={<LemonIcon name="file" />} />}
              collapsible={true}
            >
              <EducationApplicationUserFileListView files={this.props.applicationFiles} displayTypes={[FileTypeEnum.SIGNED_FINANCING_AGREEMENT, FileTypeEnum.PROOF_OF_PAYMENT, FileTypeEnum.COMPETENCIES_CERTIFICATE, FileTypeEnum.ATTENDANCE_CERTIFICATE, FileTypeEnum.APPLICATION_EXPORT]} />
            </ViewSectionItem>
          }

          {/* ---------- Trainee info ---------- */}
          {this.props.traineeUser && this.props.applicationFiles ?
            <EducationApplicationUserViewSection user={this.props.traineeUser} externalEducationApplication={this.props.externalEducationApplication} files={this.props.applicationFiles} />
            : null
          }

          {/* ---------- Application info ---------- */}
          <EducationApplicationDetailsViewSection active={this.props.panelsActive} educationApplicationDetails={this.props.externalEducationApplication} />
        </React.Fragment>
        : null
    );
  }

}

// -- HOCs and exports
// ----------

export default withRoles(withLocalize<IExternalEducationApplicationInfoViewOwnProps>(ExternalEducationApplicationInfoView as any));
