import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

import LoginBusinessStore, { ILoginPayload } from '@src/service/business/login/loginBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';

export interface IAuthenticationRouteGuardPublicProps {}
export interface IAuthenticationRouteGuardStateProps {
  isUserLoggedIn: boolean;
}
export interface IAuthenticationRouteGuardDispatchProps {
  doLogin: (options?: ILoginPayload) => void;
}
type IAuthenticationRouteGuardProps = IAuthenticationRouteGuardPublicProps & IAuthenticationRouteGuardStateProps & IAuthenticationRouteGuardDispatchProps & WithRouterProps;

interface IAuthenticationRouteGuardState {
  allowed: boolean;
}

/**
 * Authentication based route guard. Guard route from unautheticated users.
 *
 * If current user is authenticated, renders children. If not, user is redirect to public default page (from config).
 */
class AuthenticationRouteGuard extends React.Component<IAuthenticationRouteGuardProps, IAuthenticationRouteGuardState> {
  state: IAuthenticationRouteGuardState = {
    allowed: false,
  };

  componentDidMount() {
    this.check();
  }

  componentDidUpdate(previousProps: IAuthenticationRouteGuardProps) {
    if (this.props !== previousProps) {
      this.check();
    }
  }

  render() {
    return <React.Fragment>{this.state.allowed && this.props.children}</React.Fragment>;
  }

  private check() {
    const isAllowed = this.isAllowed();
    // always set state in case this component doesn't get unmounted on redirect
    this.setState({ allowed: isAllowed });

    if (!isAllowed) {
      console.log('Route not allowed. User not authenticated or page is not public. Redirecting ...');
      this.props.doLogin({ redirectUri: `${this.props.location.pathname}${this.props.location.search}` });
    }
  }

  private isAllowed(): boolean {
    // public routes should be allowed to anyone
    const publicRoutes: string[] = AppConfigService.getValue('routing.publicRoutes');
    const isPublicRoute = (publicRoutes || []).indexOf(this.props.location.pathname) !== -1;

    return this.props.isUserLoggedIn || isPublicRoute;
  }
}

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IAuthenticationRouteGuardStateProps => ({
  // tutorList: TutorListBusinessStore.selectors.getTutorList(state),
  isUserLoggedIn: LoginBusinessStore.selectors.isUserLoggedIn(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IAuthenticationRouteGuardDispatchProps => ({
  doLogin: (options?: ILoginPayload) => dispatch(LoginBusinessStore.actions.doLogin(options)),
});

// store exported component so we can use it in withXXX HOC
const AuthenticationRouteGuardWrapper = connect<IAuthenticationRouteGuardStateProps, any, IAuthenticationRouteGuardPublicProps>(mapStateToProps, mapDispatchToProps)(withRouter(AuthenticationRouteGuard));
export default AuthenticationRouteGuardWrapper;

// ----- withAuthenticationRouteGuard

/** Higher order component for wrapping existing component with route guard. */
const withAuthenticationRouteGuard = <P extends object>(Component: React.ComponentType<P>) =>
  // tslint:disable-next-line:max-classes-per-file - don't want to declare this in a new file since it is only a util
  class WrappedComponent extends React.Component<P> {
    render() {
      // TODO: find how to type router Route props
      const wrappedProps = (this.props as any).route.props || {};
      return (
        <AuthenticationRouteGuardWrapper {...wrappedProps}>
          <Component {...this.props} />
        </AuthenticationRouteGuardWrapper>
      );
    }
  };

export { withAuthenticationRouteGuard };
