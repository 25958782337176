import { CrmEditorConfig } from '@src/components/common/editor/CrmEditorConfigs';
import { Editor } from '@src/components/common/editor/Editor';
import { validateExternalUrl } from '@src/components/common/form/validation';
import FormImageComponent from '@src/components/common/image/FormImageComponent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import CompanyPickerFormView from '@src/components/company/view/CompanyPickerFormView';
import PaymentInfoInput from '@src/components/payment/PaymentInfoInput';
import { IOrganization } from '@src/model/organization/Organization';
import { Form, FormInstance, Input, Modal } from 'antd';
import React from 'react';

// -- Prop types
// ----------

export interface IOrganizationFormOwnProps {
  title: string;
  organization?: IOrganization;
  onSubmit?: (values: IOrganization) => void;
  onCancel: () => void;
}

type IOrganizationFormProps = IOrganizationFormOwnProps & IWithLocalizeOwnProps & IWithPathResolverOwnProps;

// -- Component
// ----------

class OrganizationForm extends React.Component<IOrganizationFormProps> {
  formRef = React.createRef<FormInstance<IOrganization>>();

  onFinish = (values: IOrganization) => {
    this.props.onSubmit?.(values);
  };

  onFinishFailed = (errorInfo: any) => {
    console.log(errorInfo);
  };

  handleClear = () => {
    this.formRef.current?.resetFields();
  };

  render = () => {
    return <Modal
      className="lemon-modal__modal--md"
      visible={true}
      maskClosable={false}
      title={this.props.title}
      okText={this.props.translate(`COMMON.ACTION_SAVE`)}
      cancelText={this.props.translate('COMMON.ACTION_CANCEL')}
      onCancel={this.props.onCancel}
      onOk={() => this.formRef.current?.submit()}
    >
      <Form
        name="organizationForm"
        ref={this.formRef}
        onFinish={this.onFinish}
        onFinishFailed={this.onFinishFailed}
        initialValues={this.props.organization}
        layout="vertical"
      >
        <Form.Item
          noStyle={true}
          hidden={true}
          name={['id']}
          fieldKey={['id']}
        >
          <Input type="hidden"/>
        </Form.Item>

        <Form.Item
          name={['company']}
          fieldKey={['company']}
          rules={[{ required: true, message: this.props.translate('ORGANIZATION_FORM.COMPANY_REQUIRED_LABEL') }]}
          valuePropName={'company'}
        >
          <CompanyPickerFormView onClear={this.handleClear}/>
        </Form.Item>

        <Form.Item
          name={['description']}
          fieldKey={['description']}
          label={this.props.translate('ORGANIZATION_FORM.DESCRIPTION_LABEL')}
        >
          <Editor
            init={{
              ...CrmEditorConfig.defaultConfig(),
              height: 500,
              menubar: true,
            }}
          />
        </Form.Item>

        <Form.Item
          name={['webUrl']}
          fieldKey={['webUrl']}
          label={this.props.translate('ORGANIZATION_FORM.URL_LABEL')}
          rules={[{ validator: validateExternalUrl }]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          name={['paymentInfo']}
          fieldKey={['paymentInfo']}
        >
          <PaymentInfoInput required={true}/>
        </Form.Item>

        <Form.Item
          noStyle={true}
          name={['logo']}
          fieldKey={['logo']}
          label={this.props.translate('ORGANIZATION_FORM.LOGO_LABEL')}
        >
          <FormImageComponent/>
        </Form.Item>
      </Form>
    </Modal>;
  };
};

export default withLocalize<IOrganizationFormOwnProps>(withPathResolver(OrganizationForm) as any);
