import LemonError from '@src/service/common/LemonError';

/** API service error codes enumeration. */
export enum ApiResponseErrorCode {
  // common
  SESSION_EXPIRED = 'SESSION_EXPIRED',
  AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR',
  // session
  ALREADY_ACCEPTED_TUTORING_SESSION_IN_THE_SAME_PERIOD = 'ALREADY_ACCEPTED_TUTORING_SESSION_IN_THE_SAME_PERIOD',
  // tutor profile calendar
  TIME_PERIODS_IN_THE_SAME_DAY_COLLIDING = 'TIME_PERIODS_IN_THE_SAME_DAY_COLLIDING',
  // tutor profile details does not exist
  TUTOR_DETAILS_NOT_FOUND = 'TUTOR_DETAILS_NOT_FOUND',
  // invalid old pasword on password change
  BAD_CREDENTIALS = 'BAD_CREDENTIALS',
}

/** Thrown when API call returns error reponse. */
export default class ApiResponseErrorException extends LemonError {
  readonly code: string;

  constructor(code: string, message?: string) {
    super(`API responded with error '${code}' ${message != null ? ': ' + message : ''}`);

    this.code = code;
  }
}
