import { QuizDisplay } from '@lamarodigital/quizzler-lib-frontend';
import { IQuiz } from '@lamarodigital/quizzler-lib-frontend/model/quiz/Quiz';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { QuizHelperUtils } from '@src/components/exam/quiz/QuizHelperUtils';
import { ISurveyInstance } from '@src/model/survey/SurveyInstance';
import { Button, Modal, Typography } from 'antd';
import React from 'react';

// -- Prop types
// ----------
export interface ISurveyInstanceFeedbackModalOwnProps {
  surveyInstance?: ISurveyInstance;
  surveyInstanceSubmissionList: IQuiz[];
  showEvaluationFeedback: boolean;
  onCancel: () => void;
}

type ISurveyInstanceFeedbackModalProps = ISurveyInstanceFeedbackModalOwnProps & IWithLocalizeOwnProps;

interface ISurveyInstanceFeedbackModalState {
}

// -- Component
// ----------
class SurveyInstanceFeedbackModal extends React.Component<ISurveyInstanceFeedbackModalProps, ISurveyInstanceFeedbackModalState> {
  state: ISurveyInstanceFeedbackModalState = {
  };

  render() {
    const surveyFeedbackTitle = <React.Fragment>
      <Typography.Paragraph> {this.props.translate(`SURVEY_INSTANCE_FEEDBACK.SURVEY_TYPE_TITLE.${this.props.surveyInstance?.type.name}`)} </Typography.Paragraph>
      <Typography.Paragraph> {this.props.surveyInstance?.generatedIntro} </Typography.Paragraph>
    </React.Fragment>;

    return (
      <Modal
        maskClosable={true}
        title={surveyFeedbackTitle}
        visible={true}
        className="lemon-modal__modal--lg"
        onCancel={this.props.onCancel}
        okText={this.props.translate('COMMON.ACTION_SAVE')}
        cancelText={this.props.translate('COMMON.ACTION_CANCEL')}
        footer={
          <Button key="back" onClick={this.props.onCancel}>
            {this.props.translate('COMMON.ACTION_CLOSE')}
          </Button>
        }
      >
        <div className="timun-surveyInstanceView__content">
          <QuizDisplay resolveFileUrl={QuizHelperUtils.resolveFileUrl} showHelpIntroActionButton={false} showRedoIntroActionButton={false} showEvaluationFeedback={this.props.showEvaluationFeedback} quiz={this.props.surveyInstanceSubmissionList[0]} />
        </div>
      </Modal>
    );
  }
}

// -- HOCs and exports
// ----------

export default withLocalize<ISurveyInstanceFeedbackModalOwnProps>(SurveyInstanceFeedbackModal as any);
