import React from 'react';
import { connect } from 'react-redux';

import DataPicker, { IDataPickerItem } from '@src/components/common/datapicker/DataPicker';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import UserListBusinessStore, { IUserListFilter } from '@src/service/business/user/userListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { LangUtils } from '@src/service/util/LangUtils';

const minSearchLength = AppConfigService.getValue('components.common.minSearchStringLength');

// -- Prop types
// ----------

export interface IUserDataPickerOwnProps {
  value?: IUserInfo;
  disabled?: boolean;
  onChange?: (newValue?: IUserInfo) => void;
  role?: UserRoleEnum;
  placeholder?: string;
}

export interface IUserDataPickerStateProps { }
export interface IUserDataPickerDispatchProps {
  fetchUserList: (params: ICollectionFetchPayload<IUserListFilter>) => ITrackableAction;
}

type IUserDataPickerProps = IUserDataPickerOwnProps & IUserDataPickerStateProps & IUserDataPickerDispatchProps;

interface IUserDataPickerState {
  userList?: IUserInfo[] | null;
}

// -- Component
// ----------

/** User data picker by or without userRole */
class UserDataPicker extends React.Component<IUserDataPickerProps, IUserDataPickerState> {
  state: IUserDataPickerState = {};

  render = () => {
    const pickerValue = this.props.value && this.mapToItem(this.props.value);
    const pickerItems = this.state.userList != null ? this.state.userList.map(this.mapToItem) : pickerValue ? [pickerValue] : [];

    return <DataPicker placeholderSuffix={this.props.placeholder} value={pickerValue} items={pickerItems} disabled={this.props.disabled} onChange={this.handleDataPickerChange} onSearch={this.handleDataPickerSearch} dataTestIdPrefix={`timun-userPicker__role_${this.props.role}`} />;
  };

  fetch = (payload: ICollectionFetchPayload<IUserListFilter>) => {
    this.props.fetchUserList({
      ...payload,
    });
  };

  private handleDataPickerChange = (value?: IDataPickerItem) => {
    if (this.props.onChange) {
      this.props.onChange(value?.data);
    }
  };

  private handleDataPickerSearch = (value: string) => {
    if (value.length >= minSearchLength) {
      this.searchList(value);
    } else if (LangUtils.isEmpty(value) || value.length < minSearchLength) {
      this.storeList();
    }
  };

  /** Map entity ref list to data picker item list. */
  private mapToItem(user: IUserInfo): IDataPickerItem {
    return {
      value: user.id,
      name: `${user.firstName} ${user.lastName}`,
      data: user,
    };
  }

  private searchList(search: string) {
    this.fetchList({
      filter: {
        name: search,
        role: this.props.role,
      },
      page: 0,
      size: AppConfigService.getValue('components.dataPicker.defaultPageSize'),
      sort: [],
    });
  }

  private fetchList(params: ICollectionFetchPayload<IUserListFilter>) {
    this.props.fetchUserList(params).track().subscribe(this.storeList);
  }

  private storeList = (userList?: ICollectionData<IUserInfo>) => {
    this.setState({ userList: userList?.content });
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IUserDataPickerOwnProps): IUserDataPickerStateProps => ({});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IUserDataPickerDispatchProps => ({
  fetchUserList: (params: ICollectionFetchPayload<IUserListFilter>) => createTrackableAction(dispatch(UserListBusinessStore.actions.fetchUserList(params))),
});

export default connect<IUserDataPickerStateProps, IUserDataPickerDispatchProps, IUserDataPickerOwnProps>(mapStateToProps, mapDispatchToProps)(UserDataPicker as any);
