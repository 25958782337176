import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ViewSectionItem from '@src/components/common/section/ViewSectionItem';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import { IColumnChartData, TimunColumnChart } from '@src/components/externalEducationApplication/statistics/charts/TimunColumnChart';
import { IPieData, TimunPieChart } from '@src/components/externalEducationApplication/statistics/charts/TimunPieChart';
import StatisticCardItem from '@src/components/externalEducationApplication/statistics/StatisticCardItem';
import ApplicationStatisticsUtils from '@src/components/externalEducationApplication/statistics/utils/ApplicationStatisticsUtils';
import { ExternalApplicationStatisticsCategories } from '@src/components/externalEducationApplication/statistics/utils/consts';
import { ActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { EmploymentStatusEnum } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { ICountPerStatus } from '@src/model/externalEducationApplication/ExternalEducationApplicationStatistics';
import { Divider, Typography } from 'antd';
import React from 'react';

interface IEducationApplicationStatisticsCountOwnProps {
  numberOfApplications: number;
  countPerApplicationStatus: Array<ICountPerStatus<ActivityPhaseEnum>>;
  countPerEmploymentStatus: Array<ICountPerStatus<EmploymentStatusEnum>>;
}

type IEducationApplicationStatisticsCountProps = IEducationApplicationStatisticsCountOwnProps & IWithLocalizeOwnProps;

const EducationApplicationStatisticsCount = (props: IEducationApplicationStatisticsCountProps) => {

  const numberOfEmployedApplications = ApplicationStatisticsUtils.sumEducationApplicationCountPerRequestedEmplyomentStatusGroup(ExternalApplicationStatisticsCategories.employed, props.countPerEmploymentStatus);
  const numberOfUnemployedApplications = ApplicationStatisticsUtils.sumEducationApplicationCountPerRequestedEmplyomentStatusGroup(ExternalApplicationStatisticsCategories.unemplyoed, props.countPerEmploymentStatus);
  const numberOfApplicationsPerStatus: IColumnChartData[] = props.countPerApplicationStatus.reduce((accumulator: IColumnChartData[], currentValue) => {
    return [...accumulator, { value: currentValue.count, label: props.translate(`EXTERNAL_EDUCATION_APPLICATION.PHASE.${currentValue.type.name}_LABEL`) }];
  }, []);

  const applicationsPerEmploymentStatusData: IPieData[] = [
    {
      label: props.translate('EXTERNAL_EDUCATION_APPLICATION_STATISTICS.STATISTICS_COUNT_APPLICATIONS_EMPLOYED'),
      value: numberOfEmployedApplications,
    },
    {
      label: props.translate('EXTERNAL_EDUCATION_APPLICATION_STATISTICS.STATISTICS_COUNT_APPLICATIONS_UNEMPLYOED'),
      value: numberOfUnemployedApplications,
    },
  ];

  return (
    <ViewSectionItem
      header={<HeaderTitle title={props.translate('EXTERNAL_EDUCATION_APPLICATION_STATISTICS.STATISTICS_COUNT_TITLE')} icon={<LemonIcon name="clipboard" size="large" />} />}
      collapsible={false}
      active={true}
      bordered={true}
    >
      <StatisticCardItem statisticalNumber={props.numberOfApplications} description={props.translate('EXTERNAL_EDUCATION_APPLICATION_STATISTICS.STATISTICS_TOTAL_COUNT_TITLE')} />

      <Divider />

      <Typography.Title level={3}> {props.translate('EXTERNAL_EDUCATION_APPLICATION_STATISTICS.STATISTICS_COUNT_PER_APPLICATION_STATUS_TITLE')} </Typography.Title>
      <TimunColumnChart data={numberOfApplicationsPerStatus} />

      <Divider />

      <Typography.Title level={3}> {props.translate('EXTERNAL_EDUCATION_APPLICATION_STATISTICS.STATISTICS_COUNT_PER_EMPLYOMENT_STATUS_TITLE')} </Typography.Title>
      <TimunPieChart data={applicationsPerEmploymentStatusData} />
    </ViewSectionItem>
  );
};

export default withLocalize<IEducationApplicationStatisticsCountOwnProps>(EducationApplicationStatisticsCount as any);
