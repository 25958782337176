import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/common/EducationApplicationHelperUtils';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { ContactDataTypeEnum } from '@src/model/user/ContactData';
import { Button, Col, Row } from 'antd';
import React from 'react';

// -- Const
// ----------

const LAYOUT_BUTTONS_1 = {
  sm: 24,
  xs: 24,
  md: 12,
};

// -- Prop types
// ----------
export interface IEducationApplicationCoordinatorHelpPanelOwnProps {
  externalEducationApplication: IExternalEducationApplication;
  onCoordinatorAdd?: () => void;
  onFinishConsultation?: () => void;
}

type IEducationApplicationCoordinatorHelpPanelProps = IEducationApplicationCoordinatorHelpPanelOwnProps & IWithLocalizeOwnProps;

interface IEducationApplicationCoordinatorHelpPanelState { }

// -- Component
// ----------

/** Component wrapped with viewSectionItem displaying application details */
class EducationApplicationCoordinatorHelpPanel extends React.Component<IEducationApplicationCoordinatorHelpPanelProps, IEducationApplicationCoordinatorHelpPanelState> {
  state: IEducationApplicationCoordinatorHelpPanelState = {};

  render() {
    const hasCoordinator = EducationApplicationHelperUtils.hasCoordinator(this.props.externalEducationApplication.activity);

    return (
      <div className="panel timun-externalEducationApplication__coordinatorHelpPanel">
        <Row>
          <Col>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.COORDINATOR_HELP_WANTED_TEXT')}</Col>
        </Row>
        <Row gutter={16}>
          <Col>
            {this.props.translate(this.props.externalEducationApplication.consultation?.userContact?.type.id === ContactDataTypeEnum.EMAIL ? 'COMMON.LABEL.EMAIL' : 'COMMON.LABEL.PHONE')}
            &nbsp;:&nbsp;
          </Col>
          <Col>{this.props.externalEducationApplication.consultation?.userContact?.value}</Col>
        </Row>
        <Row gutter={16}>
          {!hasCoordinator && (
            <Col {...LAYOUT_BUTTONS_1}>
              <Button type="primary" block={false} onClick={() => this.props.onCoordinatorAdd && this.props.onCoordinatorAdd()}
                data-test-id="timun-externalEducationApplicationCoordinatorHelpPanel__takeRequestButton"
              >
                {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.TAKE_APPLICATION_BUTTON_TEXT')}
              </Button>
            </Col>
          )}
          {hasCoordinator && !this.props.externalEducationApplication.consultation?.endDateTime && (
            <Col {...LAYOUT_BUTTONS_1}>
              <Button block={false} onClick={() => this.props.onFinishConsultation && this.props.onFinishConsultation()}
                data-test-id="timun-externalEducationApplicationCoordinatorHelpPanel__finishConsultationButton"
              >
                {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.COORDINATOR_HELP_FINISHED_BUTTON_TEXT')}
              </Button>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}
// -- HOCs and exports
// ----------

export default withLocalize<IEducationApplicationCoordinatorHelpPanelOwnProps>(EducationApplicationCoordinatorHelpPanel as any);
