import AdminOrganizationListContainer from '@src/components/administration/organization/AdminOrganizationListContainer';
import AdministrationWorkPositionListContainer from '@src/components/administration/workposition/AdministrationWorkPositionListContainer';
import AppContainer from '@src/components/app/AppContainer';
import AdministrationLayout from '@src/components/app/layout/AdministrationLayout';
import AppLayout from '@src/components/app/layout/AppLayout';
import ReportsLayout from '@src/components/app/layout/ReportsLayout';
import withLayout from '@src/components/app/layout/withLayout';
import { EmptyWrapper } from '@src/components/common/container/EmptyWrapper';
import { withAuthenticationRouteGuard } from '@src/components/common/route/guard/AuthenticationRouteGuard';
import { withInitializerRouteGuard } from '@src/components/common/route/guard/InitializerRouteGuard';
import UserRegistrationRouteGuard from '@src/components/common/route/guard/UserRegistrationRouteGuard';
import { withUserRoleRouteGuard } from '@src/components/common/route/guard/UserRoleRouteGuard';
import { withUserTypeRouteGuard } from '@src/components/common/route/guard/UserTypeRouteGuard';
import CourseGroupListContainer from '@src/components/course/group/CourseGroupListContainer';
import CoursesReportContainer from '@src/components/course/report/CoursesReportContainer';
import AdminCodebooksPage from '@src/components/page/AdminCodebooksPage';
import AdminCommentPage from '@src/components/page/AdminCommentPage';
import AdminEducationApplicationReportPage from '@src/components/page/AdminEducationApplicationReportPage';
import AdminEducationCategoryCodebookPage from '@src/components/page/AdminEducationCategoryCodebookPage';
import AdminEducationQualificationCodebookPage from '@src/components/page/AdminEducationQualificationCodebookPage';
import AdminEducationSectorCodebookPage from '@src/components/page/AdminEducationSectorCodebookPage';
import AdminEmailTemplateListPage from '@src/components/page/AdminEmailTemplateListPage';
import AdminEnrollmentRequirementCodebookPage from '@src/components/page/AdminEnrollmentRequirementCodebookPage';
import AdminGradeCodebookPage from '@src/components/page/AdminGradeCodebookPage';
import AdminLocationCodebookPage from '@src/components/page/AdminLocationCodebookPage';
import AdminNotificationPage from '@src/components/page/AdminNotificationPage';
import AdminSkillGroupListPage from '@src/components/page/AdminSkillGroupListPage';
import AdminSkillLevelCodebookPage from '@src/components/page/AdminSkillLevelCodebookPage';
import AdminSkillBasedRecommendationsPage from '@src/components/page/AdminSkillsGapPage';
import AdminSurveyTemplateListPage from '@src/components/page/AdminSurveyTemplateListPage';
import AdminSurveyTemplatePage from '@src/components/page/AdminSurveyTemplatePage';
import AdminTenantOrganizationPage from '@src/components/page/AdminTenantOrganizationPage';
import AdminTenantPage from '@src/components/page/AdminTenantPage';
import AdminUserGroupListPage from '@src/components/page/AdminUserGroupListPage';
import AdminUsersManagementPage from '@src/components/page/AdminUsersManagementPage';
import ChangePasswordPage from '@src/components/page/ChangePasswordPage';
import ExamResultPage from '@src/components/page/CodedGradePage';
import CoordinatorEducationApplicationPage from '@src/components/page/CoordinatorEducationApplicationPage';
import CourseCreatePage from '@src/components/page/CourseCreatePage';
import CourseExamPage from '@src/components/page/CourseExamPage';
import CourseListPage from '@src/components/page/CourseListPage';
import CourseViewPage from '@src/components/page/CourseViewPage';
import DashboardPage from '@src/components/page/DashboardPage';
import ExamInstancePage from '@src/components/page/ExamInstancePage';
import ExamTemplateViewPage from '@src/components/page/ExamTemplateViewPage';
import ExternalContentCreatePage from '@src/components/page/ExternalContentCreatePage';
import ExternalContentListPage from '@src/components/page/ExternalContentListPage';
import ExternalContentViewPage from '@src/components/page/ExternalContentViewPage';
import ExternalEducationApplicationViewPage from '@src/components/page/ExternalEducationApplicationViewPage';
import ExternalEducationTemplateListPage from '@src/components/page/ExternalEducationTemplateListPage';
import ExternalEducationTemplateViewPage from '@src/components/page/ExternalEducationTemplateViewPage';
import { HomePage } from '@src/components/page/HomePage';
import MyCourseListPage from '@src/components/page/MyCourseListPage';
import NotificationPage from '@src/components/page/NotificationPage';
import OrganizationListPage from '@src/components/page/OrganizationListPage';
import OrganizationViewPage from '@src/components/page/OrganizationViewPage';
import PublicCourseListPage from '@src/components/page/PublicCourseListPage';
import PublicCourseViewPage from '@src/components/page/PublicCourseViewPage';
import PublicSurveyInstancePage from '@src/components/page/PublicSurveyInstancePage';
import PublicWelcomePage from '@src/components/page/PublicWelcomePage';
import RepositoryPage from '@src/components/page/RepositoryPage';
import SurveyInstanceNotifPage from '@src/components/page/SurveyInstanceNotifPage';
import UserActivityListPage from '@src/components/page/UserActivityListPage';
import UserGroupMembershipPage from '@src/components/page/UserGroupMembershipPage';
import UserGroupPage from '@src/components/page/UserGroupPage';
import UserPage from '@src/components/page/UserPage';
import UserRegistrationPage from '@src/components/page/UserRegistrationPage';
import WebinarCreatePage from '@src/components/page/WebinarCreatePage';
import WebinarListPage from '@src/components/page/WebinarListPage';
import WebinarViewPage from '@src/components/page/WebinarViewPage';
import AdminReportContainer from '@src/components/report/AdminReportContainer';
import { withTenantPropEnabledRouteGuard } from '@src/components/tenant/TenantPropEnabledRouteGuard';
import AdminUsersReportContainer from '@src/components/user/report/AdminUsersReportContainer';
import UserReportsListContainer from '@src/components/user/report/UserReportsListContainer';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { UserTypeEnum } from '@src/model/user/UserType';
import AppConfigService from '@src/service/common/AppConfigService';
import { initAuthApp, initPublicApp } from '@src/service/common/appInit';
import { createHistory } from 'history';
import React from 'react';
import { IndexRedirect, Redirect, Route, Router, useRouterHistory } from 'react-router';

const PUBLIC_DEFAULT_ROUTE = AppConfigService.getValue('routing.publicDefaultRoute');
const AUTH_DEFAULT_ROUTE = AppConfigService.getValue('routing.authDefaultRoute');

// configure browser history obj - DO NOT use epxported "react-router/history" obj because we're creating new instance here
const browserHistory = useRouterHistory(createHistory)({
  basename: AppConfigService.getValue('app.basePath'),
});

// ---- public route guards
// suspend displaying app shell until public initializer is finished
const AppContainerInitWrapper = withInitializerRouteGuard(AppContainer, initPublicApp);

// ---- protected route guards
// suspend displaying protected pages until auth initiailizer is finished (EmptyWrapper is a component tha simply displays it's children)
const AuthContainerInitiWrapper = withInitializerRouteGuard(EmptyWrapper, initAuthApp);
// protect non-public pages from unauthorized users
const AuthenticationRouteGuardWrapper = withAuthenticationRouteGuard(AuthContainerInitiWrapper);
// users must be fully registered to access protected pages - this forces them to first finish their profile
const UserRegistrationRouteGuardWrapper = withInitializerRouteGuard(UserRegistrationRouteGuard, initAuthApp);

const appRouter = (
  <Router key={Math.random()} history={browserHistory}>
    <Route path="/" component={AppContainerInitWrapper}>
      <IndexRedirect to={PUBLIC_DEFAULT_ROUTE}/>

      {/* ---------- public routes ---------- */}
      <Route path="home" component={withLayout(HomePage, AppLayout)} props={{ label: 'public route' }}/>

      {/* ----- required user registration - only for authenticated users that haven't finished their profile */}
      <Route component={AuthenticationRouteGuardWrapper}>
        <Route component={UserRegistrationRouteGuardWrapper}>
          <Route path="user/registration" component={withLayout(UserRegistrationPage, AppLayout)}/>
        </Route>
      </Route>

      <Route path="public">
        <Route path="surveyinstance" component={withLayout(PublicSurveyInstancePage, AppLayout)}/>
        <Route path="surveyinstance/notification" component={withLayout(SurveyInstanceNotifPage, AppLayout)}/>

        <IndexRedirect to="courses"/>

        {/* Courses can be unavailable if disabled in tenant's config, then we fallback to /public/welcome route.
            If we didn't, we would be redirected to /public which would redirect back to /public/courses and ... we'd have endless loop.
        */}
        <Route path="courses" component={withTenantPropEnabledRouteGuard(withLayout(PublicCourseListPage, AppLayout), 'courses', '/public/welcome')}/>
        <Route path="course/:courseId" component={withTenantPropEnabledRouteGuard(withLayout(PublicCourseViewPage, AppLayout), 'courses', '/public/welcome')}/>

        <Route path="welcome" component={withLayout(PublicWelcomePage, AppLayout)}/>
      </Route>

      {/* ----- */}
      {/* ---------- restricted routes ---------- */}
      <Route component={AuthenticationRouteGuardWrapper}>
        <Route component={UserRegistrationRouteGuardWrapper}>
          {/* Login route is simply protected route that should trigger authentication if user is not logged in, otherwise it will redirect to default route */}
          <Route path="/login">
            <IndexRedirect to={AUTH_DEFAULT_ROUTE}/>
          </Route>

          <Route path="dashboard" component={withTenantPropEnabledRouteGuard(withLayout(DashboardPage, AppLayout), 'dashboard')}/>
          <Route path="changepassword" component={withUserTypeRouteGuard(withLayout(ChangePasswordPage, AppLayout), UserTypeEnum.LOCAL)}/>

          <Route path="myprofile">
            <IndexRedirect to="educationapplications"/>
            <Route path=":openedTab" component={withLayout(UserPage, AppLayout)}/>
          </Route>

          {/* creators have entire admin course list on dashboard, so there is no need for them to see "/courses" app nav link because it contains the same content. */}
          <Route path="courses" component={withTenantPropEnabledRouteGuard(withLayout(CourseListPage, AppLayout), 'courses')}/>
          <Route path="course">
            <Route path="groups" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(CourseGroupListContainer, AppLayout), [UserRoleEnum.COURSE_CREATOR]), 'courses')}/>
            <Route path="create" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(CourseCreatePage, AppLayout), [UserRoleEnum.COURSE_CREATOR]), 'courses')}/>
            <Route path="lecture/:lectureId" component={withTenantPropEnabledRouteGuard(withLayout(CourseViewPage, AppLayout), 'courses')}/>
            <Redirect from=":courseId" to=":courseId/about"/>;
            <Route path=":courseId/examinstance/:examId" component={withLayout(CourseExamPage, AppLayout)}/>
            <Route path=":courseId/:lectureId" component={withTenantPropEnabledRouteGuard(withLayout(CourseViewPage, AppLayout), 'courses')}/>
          </Route>

          <Route path="mycourses" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(MyCourseListPage, AppLayout), [UserRoleEnum.TRAINEE]), 'courses')}/>

          <Route path="externalcontents" component={withTenantPropEnabledRouteGuard(withLayout(ExternalContentListPage, AppLayout), 'externalcontents')}/>
          <Route path="externalcontent">
            <Route path="create" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(ExternalContentCreatePage, AppLayout), [UserRoleEnum.COURSE_CREATOR, UserRoleEnum.ORGANIZATION_ADMIN]), 'externalcontents')}/>
            <Route path=":externalId" component={withTenantPropEnabledRouteGuard(withLayout(ExternalContentViewPage, AppLayout), 'externalcontents')}/>
          </Route>

          <Route path="examinstance">
            <Route path=":examId" component={withLayout(ExamInstancePage, AppLayout)}/>
          </Route>

          <Route path="externaleducationtemplate/:externalEducationTemplateId">
            <IndexRedirect to="externaleducationinstances"/>
            <Route path=":openedTab" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(ExternalEducationTemplateViewPage, AppLayout), [UserRoleEnum.ORGANIZATION_MEMBER]), 'externaleducations')}/>
          </Route>
          <Route path="externaleducationtemplates" component={withUserRoleRouteGuard(withLayout(ExternalEducationTemplateListPage, AppLayout), [UserRoleEnum.COORDINATOR])}/>

          <Route path="externaleducationtemplate">
            <Route path=":externalEducationTemplateId" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(ExternalEducationTemplateViewPage, AppLayout), [UserRoleEnum.COORDINATOR, UserRoleEnum.COURSE_CREATOR, UserRoleEnum.ORGANIZER, UserRoleEnum.ORGANIZATION_ADMIN]), 'externaleducations')}/>
          </Route>

          <Route path="examtemplate">
            <Route path=":examTemplateId" component={withLayout(ExamTemplateViewPage, AppLayout)}/>
          </Route>

          <Route path="organizations" component={withLayout(OrganizationListPage, AppLayout)}/>

          <Route path="organizations/:organizationId">
            <IndexRedirect to="members"/>
            <Route path=":openedTab" component={withLayout(OrganizationViewPage, AppLayout)}/>
          </Route>

          <Route path="repository">
            <IndexRedirect to="courses"/>
            <Route path=":entity" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(RepositoryPage, AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN]), 'repository')}/>
            <Route path=":entity/:entityId" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(RepositoryPage, AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN]), 'repository')}/>
          </Route>

          <Route path="user/:userId">
            <IndexRedirect to="activities"/>
            <Route path=":openedTab" component={withLayout(UserPage, AppLayout)}/>
          </Route>

          <Route path="reports">
            {/* TODO: hzz demo */}
            <IndexRedirect to="reports"/>
            <Route path="courses" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(CoursesReportContainer, ReportsLayout), AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN]), 'courses')}/>
            <Route path="courses/:courseId" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(UserReportsListContainer, AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN]), 'courses')}/>
            <Route path="users" component={withUserRoleRouteGuard(withLayout(withLayout(AdminUsersReportContainer, ReportsLayout), AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN])}/>
            <Route path="reports" component={withUserRoleRouteGuard(withLayout(withLayout(AdminReportContainer, ReportsLayout), AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN])}/>
            <Route path="educationapplication" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(AdminEducationApplicationReportPage, ReportsLayout), AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN]), 'externaleducationapplications')}/>
            <Route path="useractivities" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(UserActivityListPage, ReportsLayout), AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN]), 'admin_activities')}/>
          </Route>
          <Route path="usergroups" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(UserGroupMembershipPage, AppLayout), [UserRoleEnum.TRAINEE, UserRoleEnum.COURSE_CREATOR]), 'usergroups')}/>
          <Route path="usergroups/:userGroupId" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(UserGroupPage, AppLayout), [UserRoleEnum.TRAINEE, UserRoleEnum.COURSE_CREATOR]), 'usergroups')}/>

          <Route path="educationapplications" component={withUserRoleRouteGuard(withLayout(CoordinatorEducationApplicationPage, AppLayout), [UserRoleEnum.COORDINATOR])}/>

          <Route path="administration">
            <IndexRedirect to="users"/>
            {/* <Route path="users" component={withUserRoleRouteGuard(withLayout(withLayout(AdminUserListContainer, AdministrationLayout), AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN])} /> */}
            <Route path="users" component={withUserRoleRouteGuard(withLayout(withLayout(AdminUsersManagementPage, AdministrationLayout), AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN])}/>
            <Route path="organizations" component={withUserRoleRouteGuard(withLayout(withLayout(AdminOrganizationListContainer, AdministrationLayout), AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN])}/>
            <Route path="workposition" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(AdministrationWorkPositionListContainer, AdministrationLayout), AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN]), 'admin_workpositions')}/>
            <Route path="grades" component={withUserRoleRouteGuard(withLayout(withLayout(ExamResultPage, AdministrationLayout), AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN])}/>
            <Route path="notifications" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(AdminNotificationPage, AdministrationLayout), AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN]), 'admin_notifications')}/>
            <Route path="usergroups" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(AdminUserGroupListPage, AdministrationLayout), AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN]), 'usergroups')}/>
            <Route path="usergroups/:userGroupId" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(UserGroupPage, AdministrationLayout), AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN]), 'usergroups')}/>
            <Route path="comments" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(AdminCommentPage, AdministrationLayout), AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN]), 'admin_comments')}/>
            <Route path="emailtemplates" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(AdminEmailTemplateListPage, AdministrationLayout), AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN]), 'admin_emails')}/>
            <Route path="skillgroups" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(AdminSkillGroupListPage, AdministrationLayout), AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN]), 'admin_skills')}/>
            <Route path="tenant" component={withUserRoleRouteGuard(withLayout(withLayout(AdminTenantPage, AdministrationLayout), AppLayout), [UserRoleEnum.SUPERADMIN])}/>
            <Route path="tenantorganization" component={withUserRoleRouteGuard(withLayout(withLayout(AdminTenantOrganizationPage, AdministrationLayout), AppLayout), [UserRoleEnum.SUPERADMIN, UserRoleEnum.ORGANIZATION_ADMIN])}/>
            <Route path="surveytemplates" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(AdminSurveyTemplateListPage, AdministrationLayout), AppLayout), [UserRoleEnum.SUPERADMIN]), 'admin_surveys')}/>
            <Route path="surveytemplate/:surveyTemplateId" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(AdminSurveyTemplatePage, AdministrationLayout), AppLayout), [UserRoleEnum.SUPERADMIN]), 'admin_surveys')}/>
            <Route path="codebooks" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(AdminCodebooksPage, AdministrationLayout), AppLayout), [UserRoleEnum.SUPERADMIN]), 'admin_codebooks')}/>
            <Route path="codebooks">
              <Route path="grade" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(AdminGradeCodebookPage, AdministrationLayout), AppLayout), [UserRoleEnum.SUPERADMIN]), 'admin_codebooks')}/>
              <Route path="educationqualification" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(AdminEducationQualificationCodebookPage, AdministrationLayout), AppLayout), [UserRoleEnum.SUPERADMIN]), 'admin_codebooks')}/>
              <Route path="location" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(AdminLocationCodebookPage, AdministrationLayout), AppLayout), [UserRoleEnum.SUPERADMIN]), 'admin_codebooks')}/>
              <Route path="skilllevel" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(AdminSkillLevelCodebookPage, AdministrationLayout), AppLayout), [UserRoleEnum.SUPERADMIN]), 'admin_codebooks')}/>
              <Route path="enrollmentrequirement" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(AdminEnrollmentRequirementCodebookPage, AdministrationLayout), AppLayout), [UserRoleEnum.SUPERADMIN]), 'admin_codebooks')}/>
              <Route path="educationsector" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(AdminEducationSectorCodebookPage, AdministrationLayout), AppLayout), [UserRoleEnum.SUPERADMIN]), 'admin_codebooks')}/>
              <Route path="educationcategory" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(AdminEducationCategoryCodebookPage, AdministrationLayout), AppLayout), [UserRoleEnum.SUPERADMIN]), 'admin_codebooks')}/>
            </Route>
          </Route>

          <Route path="usergroups" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(UserGroupMembershipPage, AppLayout), [UserRoleEnum.TRAINEE, UserRoleEnum.COURSE_CREATOR]), 'usergroups')}/>
          <Route path="usergroups/:userGroupId" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(UserGroupPage, AppLayout), [UserRoleEnum.TRAINEE, UserRoleEnum.COURSE_CREATOR]), 'usergroups')}/>

          <Route path="educationapplications" component={withUserRoleRouteGuard(withLayout(CoordinatorEducationApplicationPage, AppLayout), [UserRoleEnum.COORDINATOR])}/>

          <Route path="administration">
            <IndexRedirect to="users"/>
            {/* <Route path="users" component={withUserRoleRouteGuard(withLayout(withLayout(AdminUserListContainer, AdministrationLayout), AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN])} /> */}
            <Route path="users" component={withUserRoleRouteGuard(withLayout(withLayout(AdminUsersManagementPage, AdministrationLayout), AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN])}/>
            <Route path="organizations" component={withUserRoleRouteGuard(withLayout(withLayout(AdminOrganizationListContainer, AdministrationLayout), AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN])}/>
            <Route path="workposition" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(AdministrationWorkPositionListContainer, AdministrationLayout), AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN]), 'admin_workpositions')}/>
            <Route path="grades" component={withUserRoleRouteGuard(withLayout(withLayout(ExamResultPage, AdministrationLayout), AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN])}/>
            <Route path="notifications" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(AdminNotificationPage, AdministrationLayout), AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN]), 'admin_notifications')}/>
            <Route path="usergroups" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(AdminUserGroupListPage, AdministrationLayout), AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN]), 'usergroups')}/>
            <Route path="usergroups/:userGroupId" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(UserGroupPage, AdministrationLayout), AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN]), 'usergroups')}/>
            <Route path="comments" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(AdminCommentPage, AdministrationLayout), AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN]), 'admin_comments')}/>
            <Route path="emailtemplates" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(AdminEmailTemplateListPage, AdministrationLayout), AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN]), 'admin_emails')}/>
            <Route path="skillgroups" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(AdminSkillGroupListPage, AdministrationLayout), AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN]), 'admin_skills')}/>
            <Route path="tenant" component={withUserRoleRouteGuard(withLayout(withLayout(AdminTenantPage, AdministrationLayout), AppLayout), [UserRoleEnum.SUPERADMIN])}/>
            <Route path="tenantorganization" component={withUserRoleRouteGuard(withLayout(withLayout(AdminTenantOrganizationPage, AdministrationLayout), AppLayout), [UserRoleEnum.SUPERADMIN, UserRoleEnum.ORGANIZATION_ADMIN])}/>
            <Route path="surveytemplates" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(AdminSurveyTemplateListPage, AdministrationLayout), AppLayout), [UserRoleEnum.SUPERADMIN]), 'admin_surveys')}/>
            <Route path="surveytemplate/:surveyTemplateId" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(AdminSurveyTemplatePage, AdministrationLayout), AppLayout), [UserRoleEnum.SUPERADMIN]), 'admin_surveys')}/>
            <Route path="codebooks" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(AdminCodebooksPage, AdministrationLayout), AppLayout), [UserRoleEnum.SUPERADMIN]), 'admin_codebooks')}/>
            <Route path="codebooks">
              <Route path="grade" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(AdminGradeCodebookPage, AdministrationLayout), AppLayout), [UserRoleEnum.SUPERADMIN]), 'admin_codebooks')}/>
              <Route path="educationqualification" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(AdminEducationQualificationCodebookPage, AdministrationLayout), AppLayout), [UserRoleEnum.SUPERADMIN]), 'admin_codebooks')}/>
              <Route path="location" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(AdminLocationCodebookPage, AdministrationLayout), AppLayout), [UserRoleEnum.SUPERADMIN]), 'admin_codebooks')}/>
              <Route path="skilllevel" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(AdminSkillLevelCodebookPage, AdministrationLayout), AppLayout), [UserRoleEnum.SUPERADMIN]), 'admin_codebooks')}/>
            </Route>
            <Route path="recommendations" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(withLayout(AdminSkillBasedRecommendationsPage, AdministrationLayout), AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN]), 'admin_skills')}/>
          </Route>

          <Route path="webinars" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(WebinarListPage, AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.COURSE_CREATOR, UserRoleEnum.TRAINEE]), 'webinars')}/>
          <Route path="webinar">
            <Route path="create" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(WebinarCreatePage, AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.COURSE_CREATOR]), 'webinars')}/>
            <Route path=":webinarId" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(WebinarViewPage, AppLayout), [UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.COURSE_CREATOR, UserRoleEnum.TRAINEE]), 'webinars')}/>
          </Route>

          <Route path="externaleducationapplication">
            <Route path=":id" component={withTenantPropEnabledRouteGuard(withUserRoleRouteGuard(withLayout(ExternalEducationApplicationViewPage, AppLayout), [UserRoleEnum.SUPERADMIN, UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.COORDINATOR, UserRoleEnum.TRAINEE]), 'externaleducationapplications')}/>
          </Route>

          <Route path="notifications" component={withLayout(NotificationPage, AppLayout)}/>

          {/* admin dashboard - for testing route guards - can be remove later */}
          <Route path="dashboard" component={withUserRoleRouteGuard(withLayout(HomePage, AppLayout), [UserRoleEnum.SUPERADMIN])} props={{ label: 'admin route' }}/>

          {/* all non-existing Routes redirected to default Route */}
          <Redirect from="*" to={PUBLIC_DEFAULT_ROUTE}/>
        </Route>
      </Route>
    </Route>
  </Router>
);

export default appRouter;
