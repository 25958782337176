import React, { useCallback, useEffect, useState } from 'react';
import ReactPlayer from 'react-player/lazy';

import LemonImage from '@src/components/common/image/LemonImage';
import { IntegrationVideoHelper } from '@src/components/common/integrationvideo/IntegrationVideoHelper';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { classNames } from '@src/components/common/util/classNames';
import { IntegrationVideoTypeEnum } from '@src/model/integrationvideo/IntegrationVideo';

export interface IIntegrationVideoPlayerOptions {
  showControls?: boolean;
}

// -- Prop types
// ----------

export interface IIntegrationVideoPlayerOwnProps {
  videoId: string;
  integrationType: IntegrationVideoTypeEnum;

  options?: IIntegrationVideoPlayerOptions;
  className?: string;

  onReady?: () => void;
  onError?: (err: any) => void;
}
type IIntegrationVideoPlayerProps = IIntegrationVideoPlayerOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/**
 * Render integration video player
 *
 * Currently only Vimeo videos are implemented.
 *
 * TODO: while new video is in process of transcoding player receives HTTP 404 error and currently only displays cover image. More informative process would require querying API for status.
 */
const IntegrationVideoPlayer: React.FC<IIntegrationVideoPlayerProps> = (props) => {
  const [isError, setIsError] = useState<boolean>(false);

  // reset error state when video changes
  useEffect(() => {
    setIsError(false);
  }, [props.videoId, props.integrationType]);

  const handleVideoError = useCallback((err) => {
    setIsError(true);

    props.onError?.(err);
  }, []);
  const handleVideoReady = useCallback(() => {
    setIsError(false);

    props.onReady?.();
  }, []);

  const showControls = props.options?.showControls ?? true;
  // w/h on 100% will render responsive video
  const videoWidth = '100%';
  const videoHeight = '100%';
  const videoUrl = IntegrationVideoHelper.getIntegrationVideoUrl(props.videoId, props.integrationType);
  const playerConfig = {
    ...IntegrationVideoHelper.getIntegrationVideoConfig(props.integrationType),
  };
  const playerCss = classNames({ 'timun-integrationVideo__content': true, [props.className ?? '']: props.className != null });

  return (
    <React.Fragment>
      {!isError && videoUrl && (
        <div className="timun-integrationVideo__container">
          {/* add key prop to video tag because it does not rerender on changed src, no clue why */}
          <ReactPlayer key={videoUrl} className={playerCss} url={videoUrl} controls={showControls} width={videoWidth} height={videoHeight} config={playerConfig} onReady={handleVideoReady} onError={handleVideoError} />
        </div>
      )}

      {/* --- loading error - show placeholder img --- */}
      {isError && <LemonImage imagePath={'video-placeholder.png'} className="timun-integrationVideo__coverImage" />}
    </React.Fragment>
  );
};

export default withLocalize<IIntegrationVideoPlayerOwnProps>(IntegrationVideoPlayer as any);
