import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

import { IActivity } from '@src/model/activity/Activity';
import { IActivityHistory } from '@src/model/activity/ActivityHistory';
import { IActivityParticipant, ParticipantRoleEnum } from '@src/model/activity/ActivityParticipant';
import IIdRef from '@src/model/common/IdRef';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { ICollectionData, IIdDataPayload, IIdPayload, IPayloadAction, IStoreListData } from '@src/service/business/common/types';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { actionThunk, trackAction } from '@src/service/util/observable/operators';
import { startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts

export interface IActivityListFilter {
  [key: string]: string;
}
export interface IActivityPhaseCreatePayload {
  phaseId: string;
}

export interface IActivityParticipantCreatePayload {
  userId: string;
  participantRole: ParticipantRoleEnum;
}

// -
// -------------------- Selectors

const getActivityHistoryList = (store: any, activityId: string): IActivityHistory[] => store.activityHistoryList[activityId];

// -
// -------------------- Actions

const Actions = {
  ACTIVITY_PHASE_UPDATE: 'ACTIVITY_PHASE_UPDATE',
  ACTIVITY_PARTICIPANT_UPDATE: 'ACTIVITY_PARTICIPANT_UPDATE',
  ACTIVITY_HISTORY_LIST_FETCH: 'ACTIVITY_HISTORY_LIST_FETCH',
  ACTIVITY_HISTORY_LIST_LOAD: 'ACTIVITY_HISTORY_LIST_LOAD',
  ACTIVITY_HISTORY_LIST_CLEAR: 'ACTIVITY_HISTORY_LIST_CLEAR',
  ACTIVITY_PARTICIPANT_REMOVE: 'ACTIVITY_PARTICIPANT_REMOVE',
};

/** Fetch activity history list. */
const fetchActivityHistoryList = (id: string): IPayloadAction<IIdRef<string>> => {
  return {
    type: Actions.ACTIVITY_HISTORY_LIST_FETCH,
    payload: {
      id,
    },
  };
};

/** update activity phase */
const updateActivityPhase = (activityId: string, data: IActivityPhaseCreatePayload): IPayloadAction<IIdDataPayload<IActivityPhaseCreatePayload>> => {
  return {
    type: Actions.ACTIVITY_PHASE_UPDATE,
    payload: {
      id: activityId,
      data,
    },
  };
};

/** update activity participant */
const updateActivityParticipant = (activityId: string, data: IActivityParticipantCreatePayload): IPayloadAction<IIdDataPayload<IActivityParticipantCreatePayload>> => {
  return {
    type: Actions.ACTIVITY_PARTICIPANT_UPDATE,
    payload: {
      id: activityId,
      data,
    },
  };
};

const removeActivityParticipant = (activityId: string, data: IActivityParticipant): IPayloadAction<IIdDataPayload<IActivityParticipant>> => {
  return {
    type: Actions.ACTIVITY_PARTICIPANT_REMOVE,
    payload: {
      id: activityId,
      data,
    },
  };
};

/** Load activity history to store. */
const loadActivityHistoryList = (id: string, data: ICollectionData<IActivity>): IPayloadAction<IIdDataPayload<ICollectionData<IActivity>>> => {
  return {
    type: Actions.ACTIVITY_HISTORY_LIST_LOAD,
    payload: {
      id,
      data,
    },
  };
};

/** Clear activity history list from store. Eg. when leaving view. */
const clearActivityHistoryList = (activityId: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.ACTIVITY_HISTORY_LIST_CLEAR,
    payload: activityId,
  };
};

// -
// -------------------- Side-effects

const updateActivityPhaseEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IActivityPhaseCreatePayload>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.ACTIVITY_PHASE_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('Activity')
        .updateSubobject(id, 'nextPhase', data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error updating activity phase', error);
      return o;
    })
  );
};

const addActivityParticipantEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IActivityParticipantCreatePayload>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.ACTIVITY_PARTICIPANT_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('Activity')
        .updateSubobject(id, 'addParticipant', data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error updating activity participant', error);
      return o;
    })
  );
};

const fetchActivityHistoryListEffect = (action$: Observable<IPayloadAction<IIdRef<string>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.ACTIVITY_HISTORY_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('Activity')
        .fetchSubobject(id, 'history')
        .pipe(
          actionThunk(action),

          map((response) => {
            return { id, response };
          })
        );
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadActivityHistoryList(data.id, data.response);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching activity history list', error);
      return o;
    })
  );
};

const removeActivityParticipantEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IActivityParticipant>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.ACTIVITY_PARTICIPANT_REMOVE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('Activity')
        .updateSubobject(id, 'removeParticipant', data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error removing activity participant', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const activityHistoryList = (state: IStoreListData<IActivityHistory> = {}, action: IPayloadAction<IIdDataPayload<IActivityHistory>>) => {
  if (action.type === Actions.ACTIVITY_HISTORY_LIST_LOAD) {
    const { id, data } = action.payload;
    return {
      ...state,
      [id]: data,
    };
  } else if (action.type === Actions.ACTIVITY_HISTORY_LIST_CLEAR) {
    const { id } = action.payload;
    const { [id]: removedHistory, ...newState } = state;
    return newState;
  }

  return state;
};

// --
// -------------------- Business Store

export const activityBusinessStore = {
  actions: {
    updateActivityPhase,
    updateActivityParticipant,
    fetchActivityHistoryList,
    loadActivityHistoryList,
    clearActivityHistoryList,
    removeActivityParticipant,
  },

  selectors: {
    getActivityHistoryList,
  },

  effects: {
    updateActivityPhaseEffect,
    addActivityParticipantEffect,
    fetchActivityHistoryListEffect,
    removeActivityParticipantEffect,
  },

  reducers: {
    activityHistoryList,
  },
};

// --
// export business store
export default activityBusinessStore;
