import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import TimunRadarChart, { IChartDataItem } from '@src/components/user/statistics/TimunRadarChart';
import { ISkillBasedRecommendation } from '@src/model/skillbasedrecommendation/SkillBasedRecommendation';
import { ISkillLevelClassification } from '@src/model/skillgroup/SkillLevelClassification';
import { Card, Col, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router';

export interface ISkillBasedRecommendationListItemOwnProps {
  recommendation: ISkillBasedRecommendation;
  selectedEntitySkills?: IChartDataItem[];
}

type ISkillBasedRecommendationListItemProps = ISkillBasedRecommendationListItemOwnProps & IWithLocalizeOwnProps;

const SkillBasedRecommendationListItem: React.FC<ISkillBasedRecommendationListItemProps> = (props) => {
  const getChartData = (): IChartDataItem[][] => {
    return [mapRecommendationSkillsToChartData(props.recommendation.skillLevelClassifications), props.selectedEntitySkills ?? []];
  };

  const mapRecommendationSkillsToChartData = (skills: ISkillLevelClassification[]) => {
    return skills.map((skill) => {
      return {
        title: skill.skill.title,
        level: skill.skillLevel.level,
        series: props.recommendation.title,
      };
    });
  };

  const getTitle = () => {
    return props.recommendation.subtitle ? <Link to={`/user/${props.recommendation.id}/skills`}>
      <LemonIcon name="user" size="xlarge"/>&nbsp;{props.recommendation.title}
    </Link> : <React.Fragment>
      <LemonIcon name="user" size="xlarge"/>&nbsp;{props.recommendation.title}
    </React.Fragment>;
  };

  return <Card className="timun-recommendations_listItem">
    <Card.Meta
      title={<Row align="middle" justify="center">
        <Col span={24} className="text-center">
          {getTitle()}
        </Col>
        {props.recommendation.subtitle && <React.Fragment>
          &nbsp;
          <Col span={24} className="text-center">
            <LemonIcon name="workposition" size="xlarge"/>&nbsp;{props.recommendation.subtitle}
          </Col>
        </React.Fragment>}
      </Row>}
      description={
        <TimunRadarChart chartData={getChartData()}/>
      }
    />
  </Card>;
};

export default withLocalize<ISkillBasedRecommendationListItemOwnProps>(SkillBasedRecommendationListItem as any);
