import React from 'react';

import SubviewLayout from '@src/components/app/layout/SubviewLayout';
import AdministrationNavigation from '@src/components/app/navigation/AdministrationNavigation';
import AppContent from '@src/components/common/container/AppContent';

const AdministrationLayout: React.FC = (props: any) => {
  return (
    <AppContent>
      <SubviewLayout header={<AdministrationNavigation />}>{props.children}</SubviewLayout>
    </AppContent>
  );
};

export default AdministrationLayout;
