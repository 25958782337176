import EducationCard from '@src/components/common/card/EducationCard';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import WebinarCoverImage from '@src/components/webinar/common/WebinarCoverImage';
import { IWebinar } from '@src/model/webinar/Webinar';
import { Col, Empty, Row } from 'antd';
import React from 'react';

interface IWebinarGridOwnProps {
  webinars?: IWebinar[];
  inProgress?: boolean;
  title?: string;
  onSetReminder?: (webinar: IWebinar) => void;
}

type IWebinarGridProps = IWebinarGridOwnProps & IWithLocalizeOwnProps;

class WebinarGrid extends React.Component<IWebinarGridProps, any> {
  render() {
    return (
      this.props.webinars?.length ? (
        <Row className="timun-courseCardList__container" gutter={[16, 16]}>
          {this.props.webinars.map((webinar: IWebinar) => {
            return (
              <Col key={webinar.id} xs={24} sm={12} md={8} lg={6} className="timun-gridTable__itemCell">
                <EducationCard
                  type="WEBINAR"
                  showStatusBadge={true}
                  key={webinar.id}
                  education={webinar}
                  getPath={this.getPath}
                  cardCover={<WebinarCoverImage webinar={webinar} onSetReminder={this.props.onSetReminder}/>}
                  cardDescription={webinar.guest}
                />
              </Col>
            );
          })}
        </Row>
      ) : (
        <Empty description={this.props.translate('COMMON.MESSAGE_NO_DATA')}/>
      )

    );
  }

  getPath = (id: string) => {
    return `/webinar/${id}`;
  };
}

export default withLocalize<IWebinarGridOwnProps>(WebinarGrid as any);
