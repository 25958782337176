import { ICodeBookEntry } from '@src/model/common/CodeBookEntry';
import { ITag } from '@src/model/tag/Tag';
import { IUserDetails } from '@src/model/user/UserDetails';

export interface IFileSystemElement {
  id: string;
  name: string;
  addedDateTime: string;
  creator: IUserDetails | null;
  mimeType: string;
  tags?: ITag[];
  type: ICodeBookEntry<FileTypeEnum>;
}

export enum FileTypeEnum {
  CURRICULUM_VITAE = '1',
  // PROFESSIONAL_PLAN = '2',
  OFFER = '3',
  CURRICULUM = '4',
  FINANCING_AGREEMENT = '5',
  SIGNED_FINANCING_AGREEMENT = '6',
  PROOF_OF_PAYMENT = '7',
  ATTENDANCE_CERTIFICATE = '8',
  COMPETENCIES_CERTIFICATE = '9',
  MUTUALLY_SIGNED_FINANCING_AGREEMENT = '10',
  APPLICATION_EXPORT = '11',
}
