import { IntegrationVideoTypeEnum } from '@src/model/integrationvideo/IntegrationVideo';
import AppConfigService from '@src/service/common/AppConfigService';

const VIDEO_VIMEO_BASE_URL: string = AppConfigService.getValue('external.integrationVideo.vimeo.baseUrl') ?? '';
const VIDEO_VIMEO_SETTINGS: {} = AppConfigService.getValue('external.integrationVideo.vimeo.config') ?? {};

/**
 * Integration video static helpers.
 *
 * TODO: this helper uses static app config any maybe should throw error if something is not found
 */
export class IntegrationVideoHelper {
  /**
   * Builds and returns integration video URL. If integration is not supported it returns only video ID.
   */
  static getIntegrationVideoUrl(videoId: string, integrationType: IntegrationVideoTypeEnum) {
    if (integrationType === IntegrationVideoTypeEnum.VIMEO) {
      return VIDEO_VIMEO_BASE_URL + videoId;
    } else {
      console.warn(`Unsupported video integration type ${integrationType}`);
      return videoId;
    }
  }

  /**
   * Returns integration video player config. If integration is not supported it returns empty object
   */
  static getIntegrationVideoConfig(integrationType: IntegrationVideoTypeEnum) {
    if (integrationType === IntegrationVideoTypeEnum.VIMEO) {
      return VIDEO_VIMEO_SETTINGS;
    } else {
      console.warn(`Unsupported video integration type ${integrationType}`);
      return {};
    }
  }
}
