import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

import { ICodeBookEntry } from '@src/model/common/CodeBookEntry';
import IIdRef from '@src/model/common/IdRef';
import { ExternalEducationApplicationPaymentInfoTypeEnum, IExternalEducationApplicationPaymentInfo } from '@src/model/externalEducationApplication/ExternalEducationApplicationPaymentInfo';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { IIdDataPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError, createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { actionThunk } from '@src/service/util/observable/operators';
import { startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts

export interface IExternalEducationApplicationPaymentInfoCreatePayload {
  bankName: string;
  externalEducationApplication?: IIdRef<string>;
  iban: string;
  paymentInfoType: ICodeBookEntry<ExternalEducationApplicationPaymentInfoTypeEnum>;
}

// -
// -------------------- Selectors

/** Returns external education payment info from store. */
const getExternalEducationPaymentInfo = (store: any): IExternalEducationApplicationPaymentInfo => store.externalEducationPaymentInfoData;

// -
// -------------------- Actions

const Actions = {
  EXTERNAL_EDUCATION_PAYMENT_INFO_FETCH: 'EXTERNAL_EDUCATION_PAYMENT_INFO_FETCH',
  EXTERNAL_EDUCATION_PAYMENT_INFO_LOAD: 'EXTERNAL_EDUCATION_PAYMENT_INFO_LOAD',
  EXTERNAL_EDUCATION_PAYMENT_INFO_CLEAR: 'EXTERNAL_EDUCATION_PAYMENT_INFO_CLEAR',
  EXTERNAL_EDUCATION_PAYMENT_INFO_CREATE: 'EXTERNAL_EDUCATION_PAYMENT_INFO_CREATE',
  EXTERNAL_EDUCATION_PAYMENT_INFO_UPDATE: 'EXTERNAL_EDUCATION_PAYMENT_INFO_UPDATE',
  EXTERNAL_EDUCATION_PAYMENT_INFO_DELETE: 'EXTERNAL_EDUCATION_PAYMENT_INFO_DELETE',
};

/** Fetch external education payment info by ID. */
const fetchExternalEducationPaymentInfo = (id: string): IPayloadAction<string> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_PAYMENT_INFO_FETCH,
    payload: id,
  };
};

/** Load external education payment info to store. */
const loadExternalEducationPaymentInfo = (data: IExternalEducationApplicationPaymentInfo): IPayloadAction<IExternalEducationApplicationPaymentInfo> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_PAYMENT_INFO_LOAD,
    payload: data,
  };
};

/** Clear external education payment info from store. Eg. when leaving view. */
const clearExternalEducationPaymentInfoData = (): ILemonAction => {
  return {
    type: Actions.EXTERNAL_EDUCATION_PAYMENT_INFO_CLEAR,
  };
};

/** Create new external education payment info. */
const createExternalEducationPaymentInfo = (data: IExternalEducationApplicationPaymentInfoCreatePayload): IPayloadAction<IExternalEducationApplicationPaymentInfoCreatePayload> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_PAYMENT_INFO_CREATE,
    payload: data,
  };
};

/** Update external education payment info by id */
const updateExternalEducationPaymentInfo = (id: string, data: IExternalEducationApplicationPaymentInfoCreatePayload): IPayloadAction<IIdDataPayload<IExternalEducationApplicationPaymentInfoCreatePayload>> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_PAYMENT_INFO_UPDATE,
    payload: {
      id,
      data,
    },
  };
};

/** Delete external education payment info by id */
const deleteExternalEducationPaymentInfo = (id: string): IPayloadAction<string> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_PAYMENT_INFO_DELETE,
    payload: id,
  };
};

// -
// -------------------- Side-effects

const fetchExternalEducationPaymentInfoEffect = (action$: Observable<IPayloadAction<string>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_EDUCATION_PAYMENT_INFO_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const id = action.payload;

      return EntityApiServiceRegistry.getService('ExternalEducationPaymentInfo')
        .fetchEntity(id)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadExternalEducationPaymentInfo(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching external education payment info', error);
      return o;
    })
  );
};

const createExternalEducationPaymentInfoEffect = (action$: Observable<IPayloadAction<IExternalEducationApplicationPaymentInfoCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_EDUCATION_PAYMENT_INFO_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('ExternalEducationPaymentInfo')
        .createEntity(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'TAG_ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating external education payment info', error);
      return o;
    })
  );
};

const updateExternalEducationPaymentInfoEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IExternalEducationApplicationPaymentInfoCreatePayload>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_EDUCATION_PAYMENT_INFO_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('ExternalEducationPaymentInfo')
        .updateEntity(id, data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'TAG_ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating external education payment info', error);
      return o;
    })
  );
};

const deleteExternalEducationPaymentInfoEffect = (action$: Observable<IPayloadAction<string>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_EDUCATION_PAYMENT_INFO_DELETE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const id = action.payload;

      return EntityApiServiceRegistry.getService('ExternalEducationPaymentInfo')
        .deleteEntity(id)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_DELETE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error deleting external education payment info', error);
      return o;
    })
  );
};


// -
// -------------------- Reducers

const externalEducationPaymentInfoData = (state: IExternalEducationApplicationPaymentInfo | null = null, action: IPayloadAction<IExternalEducationApplicationPaymentInfo>) => {
  if (action.type === Actions.EXTERNAL_EDUCATION_PAYMENT_INFO_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.EXTERNAL_EDUCATION_PAYMENT_INFO_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

const ExternalEducationPaymentInfoBusinessStore = {
  actions: {
    fetchExternalEducationPaymentInfo,
    clearExternalEducationPaymentInfoData,
    createExternalEducationPaymentInfo,
    updateExternalEducationPaymentInfo,
    deleteExternalEducationPaymentInfo,
  },

  selectors: {
    getExternalEducationPaymentInfo,
  },

  effects: {
    fetchExternalEducationPaymentInfoEffect,
    createExternalEducationPaymentInfoEffect,
    updateExternalEducationPaymentInfoEffect,
    deleteExternalEducationPaymentInfoEffect,
  },

  reducers: {
    externalEducationPaymentInfoData,
  },
};

// --
// export business store
export default ExternalEducationPaymentInfoBusinessStore;
