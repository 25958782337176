import PublicSurveyInstanceContainer from '@src/components/survey/public/PublicSurveyInstanceContainer';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

type IPublicSurveyInstancePageProps = WithRouterProps;

const PublicSurveyInstancePage = (props: IPublicSurveyInstancePageProps) => {
  const token = props.location.query.token;
  return <PublicSurveyInstanceContainer surveyInstanceToken={token} />;
};

export default withRouter<IPublicSurveyInstancePageProps>(PublicSurveyInstancePage as any);
