import AdministrationWorkPositionHeaderView from '@src/components/administration/workposition/AdministrationWorkPositionHeaderView';
import WorkPositionCoursesTreeModalContainer from '@src/components/administration/workposition/WorkPositionCoursesTreeModalContainer';
import WorkPositionUpdateModal from '@src/components/administration/workposition/WorkPositionUpdateModal';
import AppContent from '@src/components/common/container/AppContent';
import ListPagination from '@src/components/common/list/ListPagination';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import IIdRef from '@src/model/common/IdRef';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import { ICollectionData } from '@src/service/business/common/types';
import { Button, Card, Col, Row, Typography } from 'antd';
import React from 'react';

// --
// ----- Prop types

interface IAdministrationWorkPositionListViewOwnProps {
  workPositionList: ICollectionData<IWorkPosition>;
  onUpdateWorkPosition: (data: IWorkPosition) => void;
  onUpdateWorkPositionCourses: (id: string, data: Array<IIdRef<string>>) => void;
  onCreateWorkPosition: (name: string) => void;
  onSortChange: (sort: string[]) => void;
  onPageChange: (page: number, pageSize?: number) => void;
}

// --
// ----- State types

interface IAdministrationWorkPositionListViewState {
  workPositionTreeModalVisible: boolean;
  editModalVisible: boolean;
  selectedWorkPosition?: IWorkPosition;
}

// -- Component
// ----------

class AdministrationWorkPositionListView extends React.Component<IWithLocalizeOwnProps & IAdministrationWorkPositionListViewOwnProps, IAdministrationWorkPositionListViewState> {
  state: IAdministrationWorkPositionListViewState = {
    workPositionTreeModalVisible: false,
    editModalVisible: false,
    selectedWorkPosition: undefined,
  };

  renderList = () => {
    return this.props.workPositionList?.content.map((workPosition: IWorkPosition) => {
      const workPositionId = workPosition.id;
      const workPositionName = workPosition.name;
      return (
        <Card key={workPositionId}>
          <Row align="middle">
            <Col xs={12} md={16} lg={18}>
              <Typography.Title level={4}>{workPositionName}</Typography.Title>
            </Col>

            <Col xs={12} md={8} lg={6} className="text-right">
              <Button type="default" onClick={() => this.toggleEditWorkPositionCourses(workPosition)}>
                {this.props.translate('ADMINISTRATION.WORKPOSITION_UPDATE_COURSES_BUTTON_LABEL')}
              </Button>
              &nbsp;
              <Button type="default" onClick={() => this.toggleEditWorkPosition(workPosition)}>
                {this.props.translate('COMMON.ACTION_EDIT')}
              </Button>
            </Col>
          </Row>
        </Card>
      );
    });
  };

  render() {
    const pagination = this.props.workPositionList && <ListPagination page={this.props.workPositionList.page} onChange={this.props.onPageChange}/>;
    return (
      <AppContent>
        <AdministrationWorkPositionHeaderView onCreateWorkPosition={this.props.onCreateWorkPosition} changeSort={this.props.onSortChange}/>
        {/* Pagination */}
        {pagination}
        {/* List */}
        <div className="timun-workPositions__container">
          {this.renderList()}
        </div>
        {/* Pagination */}
        {pagination}

        {this.state.workPositionTreeModalVisible && this.state.selectedWorkPosition &&
        <WorkPositionCoursesTreeModalContainer
          workPosition={this.state.selectedWorkPosition}
          onSubmit={this.handleUpdateWorkPositionCourses}
          onCloseModal={() => this.toggleEditWorkPositionCourses()} // button sends event object as input to function
        />
        }
        {this.state.editModalVisible && this.state.selectedWorkPosition &&
        <WorkPositionUpdateModal
          workPosition={this.state.selectedWorkPosition}
          onSubmit={this.handleUpdateWorkPosition}
          onCancelEdit={() => this.toggleEditWorkPosition()} // button sends event object as input to function
        />
        }
      </AppContent>
    );
  }

  toggleEditWorkPosition = (workPosition?: IWorkPosition) => {
    this.setState({ selectedWorkPosition: workPosition, editModalVisible: !!workPosition });
  };

  toggleEditWorkPositionCourses = (workPosition?: IWorkPosition) => {
    this.setState({ selectedWorkPosition: workPosition, workPositionTreeModalVisible: !!workPosition });
  };

  handleUpdateWorkPositionCourses = (data: Array<IIdRef<string>>) => {
    if (this.state.selectedWorkPosition) {
      this.props.onUpdateWorkPositionCourses(this.state.selectedWorkPosition.id, data);
    }
    this.toggleEditWorkPositionCourses();
  };

  handleUpdateWorkPosition = (workPosition: IWorkPosition) => {
    this.props.onUpdateWorkPosition(workPosition);
    this.toggleEditWorkPosition();
  };

}

// -- HOCs and exports
// ----------

export default withLocalize<IAdministrationWorkPositionListViewOwnProps>(AdministrationWorkPositionListView as any);
