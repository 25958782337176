import React from 'react';

import ContactDataPropertyInput from '@src/components/codebook/form/common/ContactDataPropertyInput';
import LocationTypeSelector from '@src/components/codebook/form/common/LocationTypeSelector';
import AddressPropertyInput from '@src/components/common/form/AddressPropertyInput';
import FormItemLabelIcon from '@src/components/common/form/FormItemLabelIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ILocation } from '@src/model/location/Location';
import { ILocationCreatePayload } from '@src/service/business/location/LocationBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { Form, Input, Modal, Radio } from 'antd';

// -- Const
// ----------
const INPUT_MAX_LENGTH = AppConfigService.getValue('components.common.mediumCharacterLengthInput');

// -- Prop types
// ----------
export interface ILocationCodebookFormOwnProps {
  locationCodebook?: ILocation;
  onLocationCodebookCreateSubmit?: (locationCodebook: ILocationCreatePayload) => void;
  onLocationCodebookUpdateSubmit?: (locationCodebook: ILocation) => void;
  onCancel?: () => void;
}

type ILocationCodebookFormProps = ILocationCodebookFormOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Display form for create and update location codebook */
const LocationCodebookForm: React.FC<ILocationCodebookFormProps> = (props: ILocationCodebookFormProps) => {
  const [form] = Form.useForm<ILocation>();

  const handleFinish = (value: ILocation) => {
    if (props.locationCodebook?.id) {
      //  update
      props.onLocationCodebookUpdateSubmit?.(value);
    } else if (props.onLocationCodebookCreateSubmit) {
      // create
      props.onLocationCodebookCreateSubmit(value);
    }
  };

  return (
    <Modal
      title={props.translate('LOCATION_CODEBOOK_FORM.TITLE')}
      visible={true}
      onCancel={props.onCancel}
      onOk={form.submit}
      okText={props.translate('COMMON.ACTION_SAVE')}
      cancelText={props.translate('COMMON.ACTION_CANCEL')}>
      <Form<ILocation>
        layout="vertical"
        form={form}
        initialValues={props.locationCodebook}
        name="location"
        onFinish={handleFinish}>
        <Form.Item
          label={<FormItemLabelIcon label={props.translate('LOCATION_CODEBOOK_FORM.TITLE_LABEL')}
            tooltipDescription={props.translate('COMMON.LABEL.MAX_LENGTH_CHARACTER_INFO_LABEL', { maxCharNum: INPUT_MAX_LENGTH })} />}
          name={'title'}
          rules={[
            { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
            { required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
            { max: INPUT_MAX_LENGTH, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') }]}>
          <Input />
        </Form.Item>

        <Form.Item label={props.translate('LOCATION_CODEBOOK_FORM.ADRESS_LABEL')} shouldUpdate={true}>
          {() => <AddressPropertyInput />}
        </Form.Item>

        <LocationTypeSelector />

        <Form.Item label={props.translate('LOCATION_CODEBOOK_FORM.CONTACT_DATA_LABEL')} shouldUpdate={true}>
          {() => <ContactDataPropertyInput formRef={form} />}
        </Form.Item>

        <Form.Item label={props.translate('LOCATION_CODEBOOK_FORM.MANAGER_LABEL')} name={'manager'}>
          <Input />
        </Form.Item>

        <Form.Item
          label={props.translate('COMMON.LABEL.STATUS')}
          name={'active'}
          rules={[{ required: true, message: props.translate('COMMON.PICK_REQUIRED_FIELD') }]}>
          <Radio.Group buttonStyle="solid">
            <Radio.Button value={true}>{props.translate('COMMON.LABEL.ACTIVE')} </Radio.Button>
            <Radio.Button value={false}>{props.translate('COMMON.LABEL.INACTIVE')} </Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          noStyle={true}
          hidden={true}
          name={'id'}>
          <Input />
        </Form.Item>
      </Form>
    </Modal >
  );
};

export default withLocalize<ILocationCodebookFormOwnProps>(LocationCodebookForm as any);
