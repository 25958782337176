import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

import ITenantConfiguration from '@src/model/tenant/TenantConfiguration';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { IPayloadAction } from '@src/service/business/common/types';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { actionThunk } from '@src/service/util/observable/operators';
import { startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Selectors

/** Returns tenantConfiguration from store. */
const getTenantConfiguration = (store: any): ITenantConfiguration => store.tenantConfigurationView;

// -
// -------------------- Actions

const Actions = {
  PUBLIC_TENANT_CONFIGURATION_FETCH: 'PUBLIC_TENANT_CONFIGURATION_FETCH',
  PUBLIC_TENANT_CONFIGURATION_LOAD: 'PUBLIC_TENANT_CONFIGURATION_LOAD',
};

/** Fetch tenantConfiguration by tenant code. */
const fetchTenantConfiguration = (tenantCode: string): IPayloadAction<string> => {
  return {
    type: Actions.PUBLIC_TENANT_CONFIGURATION_FETCH,
    payload: tenantCode,
  };
};

/** Load tenantConfiguration to store. */
const loadTenantConfiguration = (data: ITenantConfiguration): IPayloadAction<ITenantConfiguration> => {
  return {
    type: Actions.PUBLIC_TENANT_CONFIGURATION_LOAD,
    payload: data,
  };
};

// -
// -------------------- Side-effects

const fetchTenantConfigurationEffect = (action$: Observable<IPayloadAction<string>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.PUBLIC_TENANT_CONFIGURATION_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const tenantCode = action.payload;

      return EntityApiServiceRegistry.getService('PublicTenantConfiguration')
        .fetchEntity(tenantCode)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadTenantConfiguration(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching tenant configuration', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const tenantConfigurationView = (state: ITenantConfiguration | null = null, action: IPayloadAction<ITenantConfiguration>) => {
  if (action.type === Actions.PUBLIC_TENANT_CONFIGURATION_LOAD) {
    return {
      ...action.payload,
    };
  }

  return state;
};

// --
// -------------------- Business Store

export const PublicTenantConfigurationBusinessStore = {
  actions: {
    fetchTenantConfiguration,
    loadTenantConfiguration,
  },

  selectors: {
    getTenantConfiguration,
  },

  effects: {
    fetchTenantConfigurationEffect,
  },

  reducers: {
    tenantConfigurationView,
  },
};

// --
// export business store
export default PublicTenantConfigurationBusinessStore;
