import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/common/EducationApplicationHelperUtils';
import { IFileListsByType } from '@src/components/externalEducationApplication/common/types';
import { IExternalEducationApplicationAgreementCreateData, IExternalEducationApplicationAgreementUpdateData } from '@src/components/externalEducationApplication/form/ExternalEducationApplicationAgreementForm';
import ExternalEducationApplicationView from '@src/components/externalEducationApplication/view/ExternalEducationApplicationView';
import { IActivityParticipant, ParticipantRoleEnum } from '@src/model/activity/ActivityParticipant';
import { ActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { ITimelineActivity } from '@src/model/activity/TimelineActivity';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { IExternalEducationApplicationPaymentInfo } from '@src/model/externalEducationApplication/ExternalEducationApplicationPaymentInfo';
import { IExternalEducationExpense } from '@src/model/externalEducationExpense/ExternalEducationExpense';
import { IFile } from '@src/model/file/File';
import ITenantOrganizationConfiguration from '@src/model/tenant/TenantOrganizationConfiguration';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { ICollectionData, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import activityBusinessStore, { IActivityParticipantCreatePayload, IActivityPhaseCreatePayload } from '@src/service/business/examtemplates/activityBusinessStore';
import ExternalEducationApplicationBusinessStore from '@src/service/business/externalEducationApplication/ExternalEducationApplicationBusinessStore';
import ExternalEducationPaymentInfoBusinessStore, { IExternalEducationApplicationPaymentInfoCreatePayload } from '@src/service/business/externalEducationApplication/ExternalEducationPaymentInfoBusinessStore';
import ExternalEducationExpenseBusinessStore, { IExternalEducationExpenseCreatePayload } from '@src/service/business/externalEducationExpense/ExternalEducationExpenseBusinessStore';
import { IFileListFilter } from '@src/service/business/files/FileListBusinessStore';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import PublicTenantOrganizationConfigurationBusinessStore from '@src/service/business/tenant/publicTenantOrganizationConfigurationService';
import TimelineBusinessStore from '@src/service/business/timeline/TimelineBusinessStore';
import UserBusinessStore from '@src/service/business/user/UserBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import RoleUtils from '@src/service/util/role/RoleUtils';
import { Modal } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';
import { Dispatch } from 'redux';

// -- Prop types
// ----------

interface IExternalEducationApplicationContainerOwnProps {
  id: string;
}

interface IExternalEducationApplicationContainerStateProps {
  traineeUser: IUserInfo;
  currentUser: IUserInfo;
  externalEducationApplication: IExternalEducationApplication;
  externalEducationApplicationFileList: ICollectionData<IFile>;
  externalEducationTimeline: ICollectionData<ITimelineActivity>;
  tenantOrganizationConfiguration: ITenantOrganizationConfiguration;
}


interface IExternalEducationApplicationContainerDispatchProps {
  fetchTraineeUser: (id: string) => ITrackableAction;
  clearTraineeUser: () => void;
  fetchExternalEducationApplication: (id: string) => ITrackableAction;
  updateExternalEducationApplication: (data: IExternalEducationApplication) => ITrackableAction;
  clearExternalEducationApplication: () => void;
  fetchExternalEducationApplicationFileList: (externalEducationApplicationId: string, listFilter: IFileListFilter, page: number, size: number, sort: string[]) => ITrackableAction;
  addExternalEducationApplicationFile: (id: string, data: IFile[]) => ITrackableAction;
  removeExternalEducationApplicationFile: (id: string, data: IFile[]) => ITrackableAction;
  clearExternalEducationApplicationFileList: (externalEducationApplicationId: string) => void;
  updateActivityNextPhase: (id: string, data: IActivityPhaseCreatePayload) => ITrackableAction;
  addActivityParticipant: (id: string, data: IActivityParticipantCreatePayload) => ITrackableAction;
  updateExternalEducationExpense: (expenseId: string, data: IExternalEducationExpense) => ITrackableAction;
  createExternalEducationExpense: (data: IExternalEducationExpenseCreatePayload) => ITrackableAction;
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
  removeActivityParticipant: (activityId: string, participant: IActivityParticipant) => ITrackableAction;
  fetchExternalEducationTimeline: (id: string, sort: string[]) => void;
  clearExternalEducationTimeline: () => void;
  createExternalEducationApplicationPaymentInfo: (data: IExternalEducationApplicationPaymentInfoCreatePayload) => ITrackableAction;
  updateExternalEducationApplicationPaymentInfo: (data: IExternalEducationApplicationPaymentInfo) => ITrackableAction;
  finishConsultation: (applicationId: string) => ITrackableAction;
}


interface IExternalEducationApplicationContainerState {
  applicationFiles: IFileListsByType;
  traineeUser?: IUserInfo;
}

type IExternalEducationApplicationContainerProps = IExternalEducationApplicationContainerOwnProps & IExternalEducationApplicationContainerStateProps & IExternalEducationApplicationContainerDispatchProps & WithRouterProps;

// -- Component
// ----------

class ExternalEducationApplicationContainer extends React.Component<IExternalEducationApplicationContainerProps, IExternalEducationApplicationContainerState> {
  state: IExternalEducationApplicationContainerState = {
    applicationFiles: {},
  };

  componentDidMount = () => {
    this.fetchExternalEducationApplicationData();
  };

  componentDidUpdate = (prevProps: IExternalEducationApplicationContainerProps, prevState: IExternalEducationApplicationContainerState) => {
    if (this.props.id !== prevProps.id) {
      this.fetchExternalEducationApplicationData();
    }
    if (this.props.externalEducationApplicationFileList !== prevProps.externalEducationApplicationFileList) {
      this.setFileLists(this.props.externalEducationApplicationFileList.content);
    }

    if (this.props.externalEducationApplication !== prevProps.externalEducationApplication) {
      this.setTraineeUser();
    }
  };

  componentWillUnmount = () => {
    this.props.clearExternalEducationApplication();
    this.props.clearExternalEducationApplicationFileList(this.props.id);
    this.props.clearExternalEducationTimeline();
    this.props.clearTraineeUser();
  };

  render() {
    return (
      this.props.externalEducationApplication ?
        <ExternalEducationApplicationView
          onUpdateExternalEducationApplication={this.handleExternalEducationApplicationUpdate}
          applicationFiles={this.state.applicationFiles}
          currentCoordinator={this.getCurrentCoordinator()}
          traineeUser={this.state.traineeUser}
          currentUser={this.props.currentUser}
          onSubmit={this.handleExternalEducationApplicationSubmit}
          onUpdateAndChangePhase={this.handleUpdateDataWithNextPhase}
          onChangePhase={this.handleNextPhase}
          externalEducationApplication={this.props.externalEducationApplication}
          onExternalEducationApplicationAgreementSubmit={this.handleExternalEducationApplicationAgreementSubmit}
          onExternalEducationApplicationAgreementUpdate={this.handleExternalEducationApplicationAgreementUpdate}
          onFileUpload={this.handleFileUpload}
          onFileRemove={this.handleFileRemove}
          onCoordinatorAdd={this.handleAddCoordinator}
          onCreateExpense={this.handleCreateExpense}
          onChangeCoordinator={this.handleChangeCoordinator}
          timeline={this.props.externalEducationTimeline}
          onCreateNote={this.handleCreateNote}
          onUpdatePaymentInfo={this.handleExternalEducationApplicationPaymentInfoUpdate}
          onFinishConsultation={this.handleFinishConsultation}
          termsOfAgreement={this.props.tenantOrganizationConfiguration.configuration?.displayTerms}
        />
        : null
    );
  }

  handleChangeCoordinator = (nextCoordinatorId: string) => {
    const currentCoordinator = this.getCurrentCoordinator();
    const nextCoordinator: IActivityParticipantCreatePayload = {
      userId: nextCoordinatorId,
      participantRole: ParticipantRoleEnum.EVALUATOR,
    };
    const activityId = this.props.externalEducationApplication.activity.id;

    if (currentCoordinator) {
      this.props.removeActivityParticipant(activityId, currentCoordinator).track().subscribe(
        () => this.props.addActivityParticipant(activityId, nextCoordinator).track().subscribe(
          () => this.fetchExternalEducationApplicationData()
        )
      );
    } else {
      this.props.addActivityParticipant(activityId, nextCoordinator).track().subscribe(
        () => this.fetchExternalEducationApplicationData()
      );
    }
  };

  getCurrentCoordinator = () => {
    return this.props.externalEducationApplication && EducationApplicationHelperUtils.getActivityParticipantByRole(ParticipantRoleEnum.EVALUATOR, this.props.externalEducationApplication.activity);
  };

  handleCreateNote = () => {
    this.fetchExternalEducationTimeline();
  };

  handleFileUpload = (data: IFile[]) => {
    this.props.addExternalEducationApplicationFile(this.props.id, data).track().subscribe((res) => {
      this.fetchExternalEducationApplicationFileList();
    });
  };

  handleFileRemove = (data: IFile[]) => {
    this.props.removeExternalEducationApplicationFile(this.props.id, data).track().subscribe((res) => {
      this.fetchExternalEducationApplicationFileList();
    });
  };

  handleExternalEducationApplicationSubmit = (data: IExternalEducationApplication) => {
    confirmationDialog({
      onConfirm: () =>
        this.props.updateExternalEducationApplication(data).track().subscribe((res) => {
          const isCurrentUserCoordinator = RoleUtils.isInRoles([UserRoleEnum.COORDINATOR], this.props.currentUser.roles);
          let nextPhaseId = ActivityPhaseEnum.SUBMITTED;
          if (EducationApplicationHelperUtils.isInPhases([ActivityPhaseEnum.RETURNED_TO_USER], this.props.externalEducationApplication.activity)) {
            nextPhaseId = ActivityPhaseEnum.AWAITING_APPROVAL;
          }
          if (isCurrentUserCoordinator) {
            nextPhaseId = ActivityPhaseEnum.IN_PROCESS;
          }

          this.props.updateActivityNextPhase(this.props.externalEducationApplication.activity.id, { phaseId: nextPhaseId }).track().subscribe(
            // success
            () => {
              Modal.success({
                title: LocalizeService.translate('EXTERNAL_EDUCATION_APPLICATION.FORM.SUBMIT_SUCCESSFUL_TITLE'),
                // content: LocalizeService.translate('EXTERNAL_EDUCATION_APPLICATION.FORM.SUBMIT_SUCCESSFUL_MESSAGE'),
              });
              this.fetchExternalEducationApplicationData();
            }
          );
        }),
    });
  };

  handleNextPhase = (nextPhaseId: string) => {
    this.props.updateActivityNextPhase(this.props.externalEducationApplication.activity.id, { phaseId: nextPhaseId }).track().subscribe(
      // success
      () => this.fetchExternalEducationApplicationData()
    );
  };

  handleUpdateDataWithNextPhase = (nextPhaseId: string, data: IExternalEducationApplication) => {
    this.props.updateExternalEducationApplication(data).track().subscribe((res) => {
      this.handleNextPhase(nextPhaseId);
    });
  };

  handleCreateExpense = (data: IExternalEducationExpenseCreatePayload) => {
    this.props.createExternalEducationExpense(data).track().subscribe(
      // success
      () => {
        this.fetchExternalEducationApplicationData();
        this.props.reportMessage({ message: LocalizeService.translate('EXTERNAL_EDUCATION_APPLICATION.EXPENSE.CREATE_SUCCESS'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.INFO });
      }
    );
  };

  handleExternalEducationApplicationUpdate = (data: IExternalEducationApplication) => {
    this.props.updateExternalEducationApplication(data).track().subscribe(
      // success
      () => {
        this.fetchExternalEducationApplicationData();
        this.props.reportMessage({ message: LocalizeService.translate('GENERAL_MESSAGE.GENERAL_UPDATE_SUCCESS'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.INFO });
      }
    );
  };

  handleExternalEducationApplicationPaymentInfoUpdate = (data: IExternalEducationApplicationPaymentInfo) => {
    if (data.id) {
      this.props.updateExternalEducationApplicationPaymentInfo(data).track().subscribe((res) => {
        this.fetchExternalEducationApplicationData();
        this.props.reportMessage({ message: LocalizeService.translate('GENERAL_MESSAGE.GENERAL_UPDATE_SUCCESS'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.INFO });
      });
    } else {
      this.props.createExternalEducationApplicationPaymentInfo(data).track().subscribe((res) => {
        this.fetchExternalEducationApplicationData();
        this.props.reportMessage({ message: LocalizeService.translate('GENERAL_MESSAGE.GENERAL_UPDATE_SUCCESS'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.INFO });
      });
    }
  };

  handleAddCoordinator = (userId: string) => {
    const participant: IActivityParticipantCreatePayload = {
      userId,
      participantRole: ParticipantRoleEnum.EVALUATOR,
    };

    this.props.addActivityParticipant(this.props.externalEducationApplication.activity.id, participant).track().subscribe((res) => {
      this.fetchExternalEducationApplicationData();
    });
  };

  handleExternalEducationApplicationAgreementSubmit = (data: IExternalEducationApplicationAgreementCreateData) => {
    this.props.createExternalEducationExpense(data.externalEducationApplicationExpenseCreateData).track().subscribe(
      // success
      () => {
        this.props.updateExternalEducationApplication(data.externalEducationApplicationData);
        this.props.reportMessage({ message: LocalizeService.translate('GENERAL_MESSAGE.GENERAL_UPDATE_SUCCESS'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.INFO });
      }
    );
  };

  handleExternalEducationApplicationAgreementUpdate = (data: IExternalEducationApplicationAgreementUpdateData) => {
    this.props.updateExternalEducationExpense(data.externalEducationApplicationExpenseUpdateData.id, data.externalEducationApplicationExpenseUpdateData).track().subscribe(
      // success
      () => {
        this.props.updateExternalEducationApplication(data.externalEducationApplicationData);
        this.props.reportMessage({ message: LocalizeService.translate('GENERAL_MESSAGE.GENERAL_UPDATE_SUCCESS'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.INFO });
      }
    );
  };

  handleFinishConsultation = () => {
    this.props.finishConsultation(this.props.id).track().subscribe(
      // success
      () => {
        this.fetchExternalEducationApplicationData();
      }
    );
  };

  private setFileLists = (files?: IFile[]) => {
    const fileLists: IFileListsByType = EducationApplicationHelperUtils.getFileLists(files);

    this.setState({
      applicationFiles: fileLists,
    });
  };

  private setTraineeUser = () => {
    const traineeParticipant = EducationApplicationHelperUtils.getActivityParticipantByRole(ParticipantRoleEnum.ASSIGNEE, this.props.externalEducationApplication.activity);
    if (traineeParticipant) {
      if (traineeParticipant.userId !== this.props.currentUser.id) {
        this.props.fetchTraineeUser(traineeParticipant.userId).track().subscribe((res) => {
          this.setState({ traineeUser: res });
        });
      } else {
        this.setState({ traineeUser: this.props.currentUser });
      }
    }
  };

  private fetchExternalEducationTimeline = () => {
    const sort = ['createdAt,desc'];
    this.props.fetchExternalEducationTimeline(this.props.id, sort);
  };

  private fetchExternalEducationApplicationFileList = () => {
    this.props.fetchExternalEducationApplicationFileList(this.props.id, {}, 0, AppConfigService.getValue('api.collectionDefaultLimit'), []);
  };

  private fetchExternalEducationApplicationData = () => {
    this.props.fetchExternalEducationApplication(this.props.id);
    this.fetchExternalEducationApplicationFileList();
    this.fetchExternalEducationTimeline();
  };
}

// -- HOCs and exports
// ----------


// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IExternalEducationApplicationContainerOwnProps): IExternalEducationApplicationContainerStateProps => {
  return ({
    traineeUser: UserBusinessStore.selectors.getUser(state),
    currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
    externalEducationApplication: ExternalEducationApplicationBusinessStore.selectors.getExternalEducationApplication(state),
    externalEducationApplicationFileList: ExternalEducationApplicationBusinessStore.selectors.getExternalEducationApplicationFileList(state, ownProps.id),
    externalEducationTimeline: TimelineBusinessStore.selectors.getTimelineList(state),
    tenantOrganizationConfiguration: PublicTenantOrganizationConfigurationBusinessStore.selectors.getTenantOrganizationConfiguration(state),
  });
};

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: Dispatch): IExternalEducationApplicationContainerDispatchProps => ({
  fetchTraineeUser: (id: string) => createTrackableAction(dispatch(UserBusinessStore.actions.fetchUser({ id }))),
  clearTraineeUser: () => dispatch(UserBusinessStore.actions.clearUser()),
  fetchExternalEducationApplication: (id: string) => createTrackableAction(dispatch(ExternalEducationApplicationBusinessStore.actions.fetchExternalEducationApplication({ id }))),
  updateExternalEducationApplication: (data: IExternalEducationApplication) => createTrackableAction(dispatch(ExternalEducationApplicationBusinessStore.actions.updateExternalEducationApplication(data))),
  clearExternalEducationApplication: () => dispatch(ExternalEducationApplicationBusinessStore.actions.clearExternalEducationApplication()),
  fetchExternalEducationApplicationFileList: (externalEducationApplicationId: string, listFilter: IFileListFilter, page: number, size: number, sort: string[]) => createTrackableAction(dispatch(ExternalEducationApplicationBusinessStore.actions.fetchExternalEducationApplicationFileList(externalEducationApplicationId, listFilter, page, size, sort))),
  addExternalEducationApplicationFile: (id: string, data: IFile[]) => createTrackableAction(dispatch(ExternalEducationApplicationBusinessStore.actions.addExternalEducationApplicationFile(id, data))),
  removeExternalEducationApplicationFile: (id: string, data: IFile[]) => createTrackableAction(dispatch(ExternalEducationApplicationBusinessStore.actions.removeExternalEducationApplicationFile(id, data))),
  clearExternalEducationApplicationFileList: (externalEducationApplicationId: string) => dispatch(ExternalEducationApplicationBusinessStore.actions.clearExternalEducationApplicationFileList({ id: externalEducationApplicationId })),
  updateActivityNextPhase: (id: string, data: IActivityPhaseCreatePayload) => createTrackableAction(dispatch(activityBusinessStore.actions.updateActivityPhase(id, data))),
  addActivityParticipant: (id: string, data: IActivityParticipantCreatePayload) => createTrackableAction(dispatch(activityBusinessStore.actions.updateActivityParticipant(id, data))),
  removeActivityParticipant: (activityId: string, participant: IActivityParticipant) => createTrackableAction(dispatch(activityBusinessStore.actions.removeActivityParticipant(activityId, participant))),
  createExternalEducationExpense: (data: IExternalEducationExpenseCreatePayload) => createTrackableAction(dispatch(ExternalEducationExpenseBusinessStore.actions.createExternalEducationExpense(data))),
  updateExternalEducationExpense: (expenseId: string, data: IExternalEducationExpense) => createTrackableAction(dispatch(ExternalEducationExpenseBusinessStore.actions.updateExternalEducationExpense(expenseId, data))),
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
  fetchExternalEducationTimeline: (id: string, sort: string[]) => dispatch(TimelineBusinessStore.actions.fetchTimelineList(id, undefined, undefined, sort)),
  clearExternalEducationTimeline: () => dispatch(TimelineBusinessStore.actions.clearTimelineList()),
  createExternalEducationApplicationPaymentInfo: (data: IExternalEducationApplicationPaymentInfoCreatePayload) => createTrackableAction(dispatch(ExternalEducationPaymentInfoBusinessStore.actions.createExternalEducationPaymentInfo(data))),
  updateExternalEducationApplicationPaymentInfo: (data: IExternalEducationApplicationPaymentInfo) => createTrackableAction(dispatch(ExternalEducationPaymentInfoBusinessStore.actions.updateExternalEducationPaymentInfo(data.id, data))),
  finishConsultation: (externalEducationApplicationId: string) => createTrackableAction(dispatch(ExternalEducationApplicationBusinessStore.actions.finishConsultation({ id: externalEducationApplicationId }))),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ExternalEducationApplicationContainer));
