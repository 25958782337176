import { Form } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import SkillTreePicker from '@src/components/common/datapicker/SkillTreePicker';
import { getFormErrorList, hasFormErrors } from '@src/components/common/form/validation';
import GridItem from '@src/components/common/grid/GridItem';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import DialogPanel from '@src/components/common/panel/DialogPanel';
import CompanyPickerFormView from '@src/components/company/view/CompanyPickerFormView';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/common/EducationApplicationHelperUtils';
import ExternalEducationApplicationFileListUpload from '@src/components/externalEducationApplication/common/ExternalEducationApplicationFileListUpload';
import { IFileListsByType } from '@src/components/externalEducationApplication/common/types';
import ExternalEducationApplicationInfoView from '@src/components/externalEducationApplication/view/ExternalEducationApplicationInfoView';
import { EmploymentStatusEnum, IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { IFile } from '@src/model/file/File';
import { FileTypeEnum } from '@src/model/file/FileSystemElement';
import { ContactDataTypeEnum } from '@src/model/user/ContactData';
import { IUserInfo } from '@src/model/user/User';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, Checkbox, Col, Input, Radio, Row, Select, Steps } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import React from 'react';

const Step = Steps.Step;
const TextArea = Input.TextArea;
const Option = Select.Option;

const MIN_DESCRIPTION_LENGTH: number = AppConfigService.getValue('components.externalEducationApplication.minDescriptionLength');

// -- Prop types
// ----------
interface IExternalEducationApplicationFormOwnProps {
  externalEducationApplication: IExternalEducationApplication;
  applicationFiles: IFileListsByType;
  traineeUser?: IUserInfo;
  onUpdate?: (data: IExternalEducationApplication) => void;
  onSubmit?: (data: IExternalEducationApplication) => void;
  onCancel?: () => void;
  onFileUpload?: (file: IFile[]) => void;
  onFileRemove?: (file: IFile[]) => void;
  onCoordinatorAdd?: () => void;
  termsOfAgreement?: string;
}
type IExternalEducationApplicationFormProps = IExternalEducationApplicationFormOwnProps & IWithLocalizeOwnProps & FormComponentProps;

interface IExternalEducationApplicationFormState {
  currentStep: number;
  hasCoordinatorHelp: boolean;
}

// -- Component
// ----------

class ExternalEducationApplicationForm extends React.Component<IExternalEducationApplicationFormProps, IExternalEducationApplicationFormState> {
  state: IExternalEducationApplicationFormState = {
    currentStep: 0,
    hasCoordinatorHelp: false,
  };

  componentDidMount = () => {
    this.setCoordinatorHelpFlag();
  };

  componentDidUpdate = (prevProps: IExternalEducationApplicationFormProps, prevState: IExternalEducationApplicationFormState) => {
    if (JSON.stringify(this.props.externalEducationApplication) !== JSON.stringify(prevProps.externalEducationApplication)) {
      // after form change, externalEducationApplication is always a new object
      this.props.form.resetFields();
      this.setCoordinatorHelpFlag();
    }

    // validate data on last step to show errors and disable submit
    if (this.state.currentStep !== prevState.currentStep && this.state.currentStep === 3) {
      this.validateData();
    }
  };

  setCoordinatorHelpFlag = () => {
    this.setState({ hasCoordinatorHelp: this.props.externalEducationApplication.consultation != null });
  };

  render() {
    const { getFieldDecorator, getFieldValue } = this.props.form;

    return (
      <div className="panel">
        <Steps current={this.state.currentStep} onChange={this.setStep} responsive={true}>
          <Step title={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.FORM.STEP_ONE_TITLE')} data-test-id="timun-externalEducationApplicationForm__stepsView_1" />
          <Step title={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.FORM.STEP_TWO_TITLE')} data-test-id="timun-externalEducationApplicationForm__stepsView_2" />
          <Step title={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.FORM.STEP_THREE_TITLE')} data-test-id="timun-externalEducationApplicationForm__stepsView_3" />
          <Step title={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.FORM.STEP_FOUR_TITLE')} data-test-id="timun-externalEducationApplicationForm__stepsView_3" />
        </Steps>
        <br />

        {this.state.currentStep === 3 && this.hasFormErrors() && (
          <DialogPanel className="timun-externalEducationApplication__errorsPanel">
            {this.getFormErrors().map((error, index) => {
              return (
                <Row key={index}>
                  <Col>
                    <LemonIcon name="error" />
                    &nbsp;
                    {error}
                  </Col>
                </Row>
              );
            })}
          </DialogPanel>
        )}

        <Form layout="vertical" hideRequiredMark={true}>
          {/* ---------- Step 1 ---------- */}

          <DialogPanel title={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.SKILL_PANEL_TITLE')} className={this.state.currentStep === 0 ? '' : 'hidden'}>
            <GridItem label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.SKILL_LABEL')} infoText={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.SKILL_INFO')}>
              <Form.Item required={true}>
                {getFieldDecorator(`skills[0]`, {
                  initialValue: this.props.externalEducationApplication?.skills && this.props.externalEducationApplication?.skills[0],
                  rules: [
                    {
                      required: true,
                      message: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.ERROR.SKILL_DATA'),
                      validator: async (rule, value) => {
                        if (value.id == null) {
                          throw new Error(this.props.translate('EXTERNAL_EDUCATION_APPLICATION.ERROR.SKILL_DATA'));
                        }
                      },
                    },
                  ],
                })(<SkillTreePicker />)}
              </Form.Item>
            </GridItem>
          </DialogPanel>

          <DialogPanel title={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.ABOUT_ME_PANEL_TITLE')} className={this.state.currentStep === 0 ? '' : 'hidden'}>
            <Form.Item required={true} label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.EMPLOYMENT_STATUS_LABEL')}>
              {getFieldDecorator('employmentStatus.id', {
                initialValue: this.props.externalEducationApplication?.employmentStatus?.id || EmploymentStatusEnum.UNEMPLOYED,
              })(
                <Select data-test-id="timun-externalEducationApplicationForm__employmentStatus">
                  {Object.keys(EmploymentStatusEnum).map((item: string) => {
                    const value = EmploymentStatusEnum[item as keyof typeof EmploymentStatusEnum];
                    return (
                      <Option value={value} key={value} data-test-id={`timun-externalEducationApplicationForm_employmentStatus_${item}`}>
                        {this.props.translate(`EXTERNAL_EDUCATION_APPLICATION.${item}_LABEL`)}
                      </Option>
                    );
                  })}
                </Select>
              )}
            </Form.Item>

            <GridItem label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.FILES.CURRICULUM_VITAE_LABEL')} infoText={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.CURRICULUM_VITAE_INFO')}>
              <ExternalEducationApplicationFileListUpload applicationFiles={this.props.applicationFiles} fileType="CURRICULUM_VITAE" onFileUpload={this.props.onFileUpload} onFileRemove={this.props.onFileRemove} />
            </GridItem>

            <Form.Item className="timun-externalEducationApplication__formItemLabel" label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR_HELP_WANTED_LABEL')}>
              <Radio.Group value={this.state.hasCoordinatorHelp} buttonStyle="solid" onChange={this.handleCoordinatorHelpChange} disabled={!!this.props.externalEducationApplication.consultation?.startDateTime} data-test-id="timun-externalEducationApplicationForm__hasCoordinatorHelp">
                {/* tslint:disable-next-line: no-duplicate-string*/}
                <Radio.Button value={true} data-test-id="timun-externalEducationApplicationForm__hasCoordinatorHelp_true">{this.props.translate('COMMON.ACTION_YES')}</Radio.Button>
                {/* tslint:disable-next-line: no-duplicate-string*/}
                <Radio.Button value={false} data-test-id="timun-externalEducationApplicationForm__hasCoordinatorHelp_false">{this.props.translate('COMMON.ACTION_NO')}</Radio.Button>
              </Radio.Group>
            </Form.Item>

            {this.state.hasCoordinatorHelp && (
              <React.Fragment>
                <Form.Item required={true} label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR_HELP_CONTACT_TYPE_LABEL')}>
                  {/* tslint:disable-next-line: no-duplicate-string*/}
                  {getFieldDecorator('consultation.userContact.type.id', {
                    rules: [
                      /* tslint:disable-next-line: no-duplicate-string*/
                      { required: true, message: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.ERROR.CONTACT_INFORMATION') },
                    ],
                    initialValue: this.props.externalEducationApplication?.consultation?.userContact?.type.id || ContactDataTypeEnum.PHONE,
                  })(
                    <Radio.Group buttonStyle="solid" data-test-id="timun-externalEducationApplicationForm__consultationUserContactType">
                      <Radio.Button value={ContactDataTypeEnum.EMAIL} data-test-id={`timun-externalEducationApplicationForm__consultationUserContactType_${ContactDataTypeEnum.EMAIL}`}>{this.props.translate('COMMON.LABEL.EMAIL')}</Radio.Button>
                      <Radio.Button value={ContactDataTypeEnum.PHONE} data-test-id={`timun-externalEducationApplicationForm__consultationUserContactType_${ContactDataTypeEnum.PHONE}`}>{this.props.translate('COMMON.LABEL.PHONE')}</Radio.Button>
                    </Radio.Group>
                  )}
                </Form.Item>

                <Form.Item required={true} label={this.props.translate(getFieldValue('userContact.type.id') === ContactDataTypeEnum.EMAIL ? 'COMMON.PLACEHOLDER.EMAIL' : 'COMMON.PLACEHOLDER.PHONE')}>
                  {getFieldDecorator('consultation.userContact.value', {
                    rules: [{ required: true, message: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.ERROR.CONTACT_INFORMATION') }],
                    initialValue: this.props.externalEducationApplication?.consultation?.userContact?.value || '',
                  })(<Input data-test-id="timun-externalEducationApplicationForm__consultationUserContactValue" placeholder={this.props.translate(getFieldValue('userContact.type.id') === ContactDataTypeEnum.EMAIL ? 'COMMON.PLACEHOLDER.EMAIL' : 'COMMON.PLACEHOLDER.PHONE')} />)}
                </Form.Item>
              </React.Fragment>
            )}
          </DialogPanel>
          {getFieldValue('employmentStatus.id') === EmploymentStatusEnum.EMPLOYED && (
            <DialogPanel title={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.EMPLOYER_PANEL_TITLE')} className={this.state.currentStep === 0 ? '' : 'hidden'}>
              <Form.Item required={true}>
                {getFieldDecorator('employerInformation', {
                  initialValue: this.props.externalEducationApplication?.employerInformation,
                  valuePropName: 'company',
                  rules: [{ required: true, message: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.ERROR.EMPLOYER_INFORMATION') }],
                })(<CompanyPickerFormView />)}
              </Form.Item>
            </DialogPanel>
          )}

          {/* ---------- Step 2 ---------- */}

          <DialogPanel title={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.ABOUT_EDUCATION_PANEL_TITLE')} className={this.state.currentStep === 1 ? '' : 'hidden'}>
            <GridItem label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.FILES.OFFER_LABEL')} infoText={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.OFFER_INFO')}>
              <ExternalEducationApplicationFileListUpload applicationFiles={this.props.applicationFiles} fileType="OFFER" onFileUpload={this.props.onFileUpload} onFileRemove={this.props.onFileRemove} />
            </GridItem>

            <GridItem label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.FILES.CURRICULUM_LABEL')} infoText={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.CURRICULUM_INFO')}>
              <ExternalEducationApplicationFileListUpload applicationFiles={this.props.applicationFiles} fileType="CURRICULUM" onFileUpload={this.props.onFileUpload} onFileRemove={this.props.onFileRemove} />
            </GridItem>

            <Form.Item required={true} label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.EDUCATION_RELATED_LABEL')}>
              {getFieldDecorator('educationRelated', {
                initialValue: this.props.externalEducationApplication?.educationRelated,
              })(
                <Radio.Group buttonStyle="solid" data-test-id="timun-externalEducationApplicationForm__educationRelated">
                  <Radio.Button value={true} data-test-id="timun-externalEducationApplicationForm__educationRelated_true">{this.props.translate('COMMON.ACTION_YES')}</Radio.Button>
                  <Radio.Button value={false} data-test-id="timun-externalEducationApplicationForm__educationRelated_false">{this.props.translate('COMMON.ACTION_NO')}</Radio.Button>
                </Radio.Group>
              )}
            </Form.Item>

            <Form.Item required={true} label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.JOB_RELATED_LABEL')}>
              {getFieldDecorator('jobRelated', {
                initialValue: this.props.externalEducationApplication?.jobRelated,
              })(
                <Radio.Group buttonStyle="solid" data-test-id="timun-externalEducationApplicationForm__jobRelated">
                  <Radio.Button value={true} data-test-id="timun-externalEducationApplicationForm__jobRelated_true">{this.props.translate('COMMON.ACTION_YES')}</Radio.Button>
                  <Radio.Button value={false} data-test-id="timun-externalEducationApplicationForm__jobRelated_false">{this.props.translate('COMMON.ACTION_NO')}</Radio.Button>
                </Radio.Group>
              )}
            </Form.Item>

            <Form.Item required={true} label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.APPLICATION_DESCRIPTION_LABEL')}>
              {getFieldDecorator('applicationDescription', {
                rules: [
                  { required: true, message: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.ERROR.APPLICATION_DESCRIPTION_REQUIRED') },
                  { min: MIN_DESCRIPTION_LENGTH, message: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.ERROR.APPLICATION_DESCRIPTION_LENGTH') },
                ],
                initialValue: this.props.externalEducationApplication?.applicationDescription,
              })(<TextArea data-test-id="timun-externalEducationApplicationForm__applicationDescription" autoSize={{ minRows: 2, maxRows: 6 }} placeholder={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.ERROR.APPLICATION_DESCRIPTION_LENGTH')} />)}
            </Form.Item>
          </DialogPanel>

          {/* ---------- Step 3 ---------- */}

          <DialogPanel title={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TERMS_PANEL_TITLE')} className={this.state.currentStep === 2 ? '' : 'hidden'}>
            <GridItem label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TERMS_LABEL')} infoText={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.TERMS_INFO')}>
              {/* tekst uvjeta */}
              {/* TODO: for HZZ purpose only */}
              {this.props.termsOfAgreement && <div dangerouslySetInnerHTML={{ __html: this.props.termsOfAgreement }} />}
            </GridItem>
            <Form.Item required={true}>
              {getFieldDecorator('userAcceptedTermsOfUse', {
                valuePropName: 'checked',
                rules: [
                  {
                    required: true,
                    message: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.ERROR.ACCEPTED_TERMS'),
                    validator: async (rule, value) => {
                      if (value !== true) {
                        throw new Error(this.props.translate('EXTERNAL_EDUCATION_APPLICATION.ERROR.ACCEPTED_TERMS'));
                      }
                    },
                  },
                ],
                initialValue: this.props.externalEducationApplication?.userAcceptedTermsOfUse,
              })(<Checkbox data-test-id="timun-externalEducationApplicationForm__userAcceptedTermsOfUse">{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.ACCEPT_TERMS_CHECKBOX_LABEL')}</Checkbox>)}
            </Form.Item>
          </DialogPanel>
        </Form>

        {/* ---------- Step 4 ---------- */}

        {this.state.currentStep === 3 && <ExternalEducationApplicationInfoView panelsActive={true} externalEducationApplication={this.getApplicationObject(this.props.form.getFieldsValue())} applicationFiles={this.props.applicationFiles} traineeUser={this.props.traineeUser} />}

        <Row justify="space-between">
          <Col>
            {this.state.currentStep > 0 && (
              <Button key="prev" onClick={() => this.changeStep(-1)} data-test-id="timun-externalEducationApplicationForm__prevButton">
                {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.BUTTON_PREV_LABEL')}
              </Button>
            )}
          </Col>
          <Col>
            {this.state.currentStep < 3 && (
              <Button key="save" onClick={this.handleSave} data-test-id="timun-externalEducationApplicationForm__saveButton">
                {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.BUTTON_SAVE_LABEL')}
              </Button>
            )}
            {this.state.currentStep < 3 && (
              <Button type="primary" key="next" onClick={() => this.changeStep(1)} data-test-id="timun-externalEducationApplicationForm__nextButton">
                {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.BUTTON_NEXT_LABEL')}
              </Button>
            )}
            {this.state.currentStep === 3 && (
              <Button key="submit" type="primary" disabled={this.hasFormErrors()} onClick={this.handleSubmit} data-test-id="timun-externalEducationApplicationForm__submitButton">
                {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.BUTTON_SUBMIT_LABEL')}
              </Button>
            )}
          </Col>
        </Row>
      </div>
    );
  }

  handleCoordinatorHelpChange = (event: RadioChangeEvent) => {
    const newValue = event.target.value;
    this.setState({ hasCoordinatorHelp: newValue });
  };

  hasFormErrors = (): boolean => {
    const fieldsError: any = this.props.form.getFieldsError();
    return hasFormErrors(fieldsError) || !EducationApplicationHelperUtils.hasFilesInAllTypes(this.props.applicationFiles, [FileTypeEnum.CURRICULUM_VITAE, FileTypeEnum.OFFER, FileTypeEnum.CURRICULUM]);
  };

  getFormErrors = (): string[] => {
    const fieldsError: any = this.props.form.getFieldsError();
    const errorList = getFormErrorList(fieldsError);

    if (!EducationApplicationHelperUtils.hasFilesInTypes(this.props.applicationFiles, [FileTypeEnum.CURRICULUM_VITAE])) {
      errorList.push(this.props.translate('EXTERNAL_EDUCATION_APPLICATION.ERROR.FILE_CURRICULUM_VITAE'));
    }
    if (!EducationApplicationHelperUtils.hasFilesInTypes(this.props.applicationFiles, [FileTypeEnum.OFFER])) {
      errorList.push(this.props.translate('EXTERNAL_EDUCATION_APPLICATION.ERROR.FILE_OFFER'));
    }
    if (!EducationApplicationHelperUtils.hasFilesInTypes(this.props.applicationFiles, [FileTypeEnum.CURRICULUM])) {
      errorList.push(this.props.translate('EXTERNAL_EDUCATION_APPLICATION.ERROR.FILE_CURRICULUM'));
    }

    return errorList;
  };

  private setStep = (nextStep: number) => {
    this.setState({ currentStep: nextStep });
  };

  private changeStep = (value: number) => {
    const nextStep = this.state.currentStep + value;
    this.setStep(nextStep);
  };

  private validateData = () => {
    this.props.form.validateFields((err: any, values: Partial<IExternalEducationApplication>) => {
      // a console.log(values, err);
    });
  };

  private handleSubmit = () => {
    this.props.form.validateFields((err: any, values: Partial<IExternalEducationApplication>) => {
      const application = this.getApplicationObject(values);
      if (!err && this.props.onSubmit && application.skills != null) {
        this.props.onSubmit(application);
      }
    });
  };

  private handleSave = () => {
    const values: Partial<IExternalEducationApplication> = this.props.form.getFieldsValue();
    const application = this.getApplicationObject(values);
    if (this.props.onUpdate) {
      this.props.onUpdate(application);
    }
  };

  private getApplicationObject = (values: Partial<IExternalEducationApplication>): IExternalEducationApplication => {
    const skills = values.skills?.filter((skill) => skill != null);
    const consultation = this.state.hasCoordinatorHelp ? values.consultation : undefined;
    return {
      ...this.props.externalEducationApplication,
      ...values,
      consultation,
      skills,
    };
  };
}

const ExternalEducationApplicationFormView = Form.create<IExternalEducationApplicationFormProps>()(ExternalEducationApplicationForm);

// -- HOCs and exports
// ----------

export default withLocalize<IExternalEducationApplicationFormOwnProps>(ExternalEducationApplicationFormView as any);
