import React from 'react';
import { connect } from 'react-redux';

import TimunStyleProvider from '@src/components/app/theme/TimunStyleProvider';
import TimunStyleThemeProvider from '@src/components/app/theme/TimunStyleThemeProvider';
import AppInfo from '@src/components/common/userfeedback/AppInfo';
import ProgressStatus from '@src/components/common/userfeedback/ProgressStatus';
import UserMessages from '@src/components/common/userfeedback/UserMessages';
import ITenantOrganizationConfiguration from '@src/model/tenant/TenantOrganizationConfiguration';
import { IUserFeedbackMessage, IUserFeedbackProgressStatus } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import PublicTenantOrganizationConfigurationBusinessStore from '@src/service/business/tenant/publicTenantOrganizationConfigurationService';

import '@src/asset/fonts/fonts.css';
import { BackTop } from 'antd';

export interface IAppContainerPublicProps { }
export interface IAppContainerStateProps {
  progressStatus: IUserFeedbackProgressStatus;
  userMessages: IUserFeedbackMessage[];
  tenantOrganizationConfiguration: ITenantOrganizationConfiguration;
}
export interface IAppContainerDispatchProps { }
type IAppContainerProps = IAppContainerPublicProps & IAppContainerDispatchProps & IAppContainerStateProps;

/** App container, for adding things common to all layouts. */
class AppContainer extends React.Component<IAppContainerProps> {
  render() {
    return (
      <TimunStyleThemeProvider>
        <TimunStyleProvider />

        {this.props.children}

        {/* ----- user messages ----- */}
        {this.props.userMessages && this.props.userMessages.length > 0 && <UserMessages messages={this.props.userMessages} />}

        {/* ----- progress status ----- */}
        {this.props.progressStatus && this.props.progressStatus.status > 0 && <ProgressStatus />}

        <BackTop />

        {/* ----- app info ----- */}
        <AppInfo tenantOrganizationConfiguration={this.props.tenantOrganizationConfiguration} />
      </TimunStyleThemeProvider>
    );
  }
}

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IAppContainerStateProps => ({
  // tutorList: TutorListBusinessStore.selectors.getTutorList(state),
  progressStatus: UserFeedbackBusinessStore.selectors.getGlobalProgressStatus(state),
  userMessages: UserFeedbackBusinessStore.selectors.getMessages(state),
  tenantOrganizationConfiguration: PublicTenantOrganizationConfigurationBusinessStore.selectors.getTenantOrganizationConfiguration(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IAppContainerDispatchProps => ({});

export default connect<IAppContainerStateProps, IAppContainerDispatchProps, IAppContainerPublicProps>(mapStateToProps, mapDispatchToProps)(AppContainer);
