import { ICodeBookEntry } from '@src/model/common/CodeBookEntry';
import { IAddress } from '@src/model/user/Address';
import { IContactData } from '@src/model/user/ContactData';
import { IUserDetails } from '@src/model/user/UserDetails';

export interface ILocation {
  address: IAddress;
  contactData: IContactData[];
  createdBy: IUserDetails;
  id: string;
  manager: string;
  title: string;
  type: ICodeBookEntry<LocationTypeEnum>;
  active: boolean;
}

export enum LocationTypeEnum {
  DISTRICT_OFFICE = '1',
}
