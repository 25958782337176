import { Form } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { IQuestionOutcomeStats } from '@lamarodigital/quizzler-lib-frontend/model/quiz/outcome/QuestionOutcomeStats';
import { IQuizOutcomeStats } from '@lamarodigital/quizzler-lib-frontend/model/quiz/outcome/QuizOutcomeStats';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { Col, Input, InputNumber, Modal, Row, Typography } from 'antd';
import React, { SyntheticEvent } from 'react';

// -- Const
// ----------
const FormItem = Form.Item;

// -- Prop types
// ----------
export interface IExamInstanceModalFormOwnProps {
  title: string; // prop for modal title
  quizCollectionOutcomeStats?: IQuizOutcomeStats;
  onSubmit: (quizCollectionOutcomeStats: IQuizOutcomeStats) => void;
  onCancel: (isVisible: boolean) => void;
}

type IExamInstanceModalFormProps = IExamInstanceModalFormOwnProps & IWithLocalizeOwnProps & FormComponentProps;

interface IExamInstanceModalFormState { }

// -- Component
// ----------

/** Modal for generating new exam instance */
class ExamInstanceModalForm extends React.Component<IExamInstanceModalFormProps, IExamInstanceModalFormState> {

  handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        const quizCollectionOutcomeStats: IQuizOutcomeStats = {
          totalNumberOfQuestions: values.totalNumberOfQuestions,
          questionsPerOutcome: values.questionsPerOutcome,
        };
        this.props.onSubmit(quizCollectionOutcomeStats);
      }
    });
  };


  renderFormTable = () => {
    const { getFieldDecorator } = this.props.form;
    // If question has outcomes, show a table that takes the number of questions by outcomes from the collection needed to generate the exam instance
    if (this.props.quizCollectionOutcomeStats && this.props.quizCollectionOutcomeStats.questionsPerOutcome.length > 0) {
      return <React.Fragment>
        {/* Header Row */}
        <Row>
          <Col className="text-left" span={8}>
            {this.props.translate('EXAM_TEMPLATE.MODAL.OUTCOME')}
          </Col>
          <Col className="text-left" span={8}>
            {this.props.translate('EXAM_TEMPLATE.MODAL.MAXIMUM_NUMBER_OF_QUESTIONS')}
          </Col>
          <Col className="text-left" span={8}>
            {this.props.translate('EXAM_TEMPLATE.MODAL.NUMBER_OF_QUESTIONS_IN_EXAM')}
          </Col>
        </Row>
        {/* Outcome Row */}
        {this.props.quizCollectionOutcomeStats?.questionsPerOutcome.map((questionOutcome: IQuestionOutcomeStats, index: number) => {
          return <Row key={questionOutcome.outcome.id}>
            <Col className="text-left" span={8}>
              {questionOutcome.outcome.title}
            </Col>
            <Col className="text-left" span={8}>
              <Typography.Paragraph strong={true}> {questionOutcome.numberOfQuestions} </Typography.Paragraph>
            </Col>
            <Col className="text-left" span={8}>
              <FormItem>
                {getFieldDecorator(`questionsPerOutcome[${index}].numberOfQuestions`, {
                  initialValue: 0,
                  rules: [
                    { min: 0, max: questionOutcome.numberOfQuestions, type: 'number', message: this.props.translate('EXAM_TEMPLATE.MODAL.ALLOWED_NUMBER_MESSAGE', { min: 0, max: questionOutcome.numberOfQuestions }) },
                    { required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') },
                  ],
                })(<InputNumber id={questionOutcome.outcome.id} min={0} max={questionOutcome.numberOfQuestions} />)}
              </FormItem>
              <FormItem className="hidden">
                {getFieldDecorator(`questionsPerOutcome[${index}].outcome.id`, {
                  initialValue: questionOutcome.outcome.id,
                })(<Input type="hidden" />)}
              </FormItem>
              <FormItem className="hidden">
                {getFieldDecorator(`questionsPerOutcome[${index}].outcome.title`, {
                  initialValue: questionOutcome.outcome.title,
                })(<Input type="hidden" />)}
              </FormItem>
            </Col>
          </Row>;
        })}
      </React.Fragment>;
    }
    // otherwise show a table in which you can enter number of questions from the collection which is needed to generate the exam instance
    else {
      return <React.Fragment>
        <Row>
          <Col className="text-left" span={12}>
            {this.props.translate('EXAM_TEMPLATE.MODAL.MAXIMUM_NUMBER_OF_QUESTIONS')}
          </Col>
          <Col className="text-left" span={12}>
            {this.props.translate('EXAM_TEMPLATE.MODAL.NUMBER_OF_QUESTIONS_IN_EXAM')}
          </Col>
        </Row>
        <Row>
          <Col className="text-left" span={12}>
            <Typography.Paragraph strong={true}> {this.props.quizCollectionOutcomeStats?.totalNumberOfQuestions} </Typography.Paragraph>
          </Col>
          <Col className="text-left" span={12}>
            <FormItem>
              {getFieldDecorator(`totalNumberOfQuestions`, {
                initialValue: 0,
                rules: [
                  { min: 0, max: this.props.quizCollectionOutcomeStats?.totalNumberOfQuestions, type: 'number', message: this.props.translate('EXAM_TEMPLATE.MODAL.ALLOWED_NUMBER_MESSAGE', { min: 0, max: this.props.quizCollectionOutcomeStats?.totalNumberOfQuestions }) },
                  { required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') },
                ],
              })(<InputNumber min={0} max={this.props.quizCollectionOutcomeStats?.totalNumberOfQuestions} />)}
            </FormItem>
          </Col>
        </Row>
      </React.Fragment>;
    }
  };

  render() {
    return (
      <Modal
        maskClosable={false}
        title={this.props.title}
        visible={true}
        className="lemon-modal__modal--sm"
        onOk={this.handleSubmit}
        onCancel={() => this.props.onCancel(false)}
        okText={this.props.translate('EXAM_TEMPLATE.MODAL.CREATE_QUESTION_LIST')}
        cancelText={this.props.translate('COMMON.ACTION_CANCEL')}
      >
        <Form>
          {this.renderFormTable()}
        </Form>
      </Modal >
    );
  }
}

const ExamInstanceModal = Form.create()(ExamInstanceModalForm);

export default withLocalize<IExamInstanceModalFormOwnProps>(ExamInstanceModal as any);
