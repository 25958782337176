import { Empty, Table } from 'antd';
import moment from 'moment';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';
import { ColumnProps } from 'antd/es/table';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import TimelineUiHelper from '@src/components/common/useractivities/UserActivityUiHelper';
import { IUserActivity } from '@src/model/activity/UserActivity';
import { ICollectionData } from '@src/service/business/common/types';
import AppConfigService from '@src/service/common/AppConfigService';

const dateFormat = AppConfigService.getValue('dateTimeFormat.dateWithTime');
const TABLE_SCROLL_SIZE = AppConfigService.getValue('layout.table.scrollSize');

export interface IUserActivityListOwnProps {
  userActivities: ICollectionData<IUserActivity>;
}
type IUserActivityListProps = IUserActivityListOwnProps & IWithLocalizeOwnProps & WithRouterProps;

interface IUserActivityListState {}

class UserActivityList extends React.Component<IUserActivityListProps, IUserActivityListState> {
  columns: ColumnProps<IUserActivity>[] = [
    {
      title: this.props.translate('USER_ACTIVITY_LIST.ACTIVITY_COLUMN_TITLE'),
      key: 'activity',
      render: (record: IUserActivity) => {
        return TimelineUiHelper.getActivityTitle(record);
      },
    },
    {
      title: this.props.translate('USER_ACTIVITY_LIST.TIME_TARGET_USER_COLUMN_TITLE'),
      key: 'targetUser',
      width: '15%',
      render: (record: IUserActivity) =>
        record.receiver && (
          <span>
            {record.receiver.firstName} {record.receiver.lastName}
          </span>
        ),
    },
    {
      title: this.props.translate('USER_ACTIVITY_LIST.TIME_COLUMN_TITLE'),
      key: 'time',
      width: '15%',
      render: (record: IUserActivity) => {
        return moment(record.createdAt).format(dateFormat);
      },
    },
  ];

  render() {
    return (
      <React.Fragment>
        <Table
          columns={this.columns}
          dataSource={this.props.userActivities.content}
          rowKey={(record) => record.id}
          bordered={true}
          locale={{
            emptyText: <Empty description={this.props.translate('USER_ACTIVITY_LIST.EMPTY_DATA_LABEL')} />,
          }}
          scroll={{ x: TABLE_SCROLL_SIZE }}
          pagination={false}
        />
      </React.Fragment>
    );
  }
}

export default withLocalize(withRouter<IUserActivityListOwnProps>(UserActivityList as any));
