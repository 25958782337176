import { Form } from '@ant-design/compatible';
// tslint:disable-next-line:no-submodule-imports
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import DeleteElementButton from '@src/components/common/button/DeleteElementButton';
import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import CourseHelperUtils from '@src/components/course/common/CourseHelperUtils';
import CourseDataForm from '@src/components/course/create/CourseDataForm';
import AddElementLink from '@src/components/course/update/AddElementLink';
import { CourseStatusEnum, ICourse } from '@src/model/course/Course';
import { ICourseGroup } from '@src/model/course/CourseGroup';
import { ICourseLectureGroup, ICourseLectureListElement } from '@src/model/course/LectureGroup';
import { ICourseCreatePayload, ICourseUpdate } from '@src/service/business/courses/courseUpdateBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';

import { Button, Col, Input, InputNumber, Row, Tabs } from 'antd';
import React from 'react';

const FormItem = Form.Item;
const TabPane = Tabs.TabPane;

const NAME_LENGTH_MAX = AppConfigService.getValue('userValidation.nameLengthMax');
const MIN_GROUP_ORDERING_VALUE = AppConfigService.getValue('components.courses.minOrderingValue');

// -- Prop types
// ----------

export interface ICourseUpdateFormOwnProps {
  course: ICourse;
  courseGroups: ICourseGroup[];
  lectureList: ICourseLectureListElement[];
  canDeleteCourse: boolean;
  canEditStructure: boolean;
  onCourseUpdate: (data: ICourseUpdate) => void;
  onCourseDelete: (data: ICourse) => void;
  onLectureGroupAdd: (title: string) => void;
  onLectureGroupUpdate: (data: ICourseLectureGroup) => void;
  onLectureGroupDelete: (data: ICourseLectureGroup) => void;
  onEditingChange: (editing: boolean) => void;
}

type ICourseUpdateFormProps = ICourseUpdateFormOwnProps & IWithLocalizeOwnProps & FormComponentProps;

interface ICourseUpdateFormState {
}

// -- Component
// ----------

/** Update form for course data and lecture groups */
class CourseDataUpdateForm extends React.Component<ICourseUpdateFormProps, ICourseUpdateFormState> {
  state = {};

  componentDidUpdate = (prevProps: ICourseUpdateFormOwnProps) => {
    if (this.props !== prevProps && this.props.course.id !== prevProps.course.id) {
      this.handleCancel();
    }
  };

  render = () => {
    const isInCreation = CourseHelperUtils.isCourseInStatus(this.props.course, [CourseStatusEnum.IN_CREATION]);

    // value used to disable ordering values that are higher than current count
    const maxGroupOrderingValue = this.props.lectureList.length;

    const { getFieldDecorator } = this.props.form;

    return (
      <React.Fragment>
        <Tabs className="timun-courseView__lecture-content">
          <TabPane tab={<span data-test-id="timun-courseUpdateForm_tabs_about">{this.props.translate('COURSE_VIEW.UPDATE.ABOUT_COURSE_TAB_TITLE')}</span>} key="1">
            <CourseDataForm course={this.props.course} courseGroups={this.props.courseGroups} canDeleteCourse={this.props.canDeleteCourse} onSubmit={this.handleCourseDataSubmit} onCancel={this.handleCancel} onDelete={this.props.onCourseDelete} isInCreation={isInCreation}/>
          </TabPane>

          <TabPane tab={<span data-test-id="timun-courseUpdateForm_tabs_lectureGroups">{this.props.translate('COURSE_VIEW.UPDATE.LECTURE_GROUP_TITLE')}</span>} key="2">
            <Row justify="end">
              <Button onClick={this.handleCancel} icon={<LemonIcon name="times"/>} data-test-id="timun-courseUpdateForm-aboutCourse_cancelButton">
                {this.props.translate('COMMON.ACTION_CLOSE')}
              </Button>
            </Row>

            {this.props.lectureList
              .sort((a: ICourseLectureGroup, b: ICourseLectureGroup) => a.ordering - b.ordering)
              .map((lectureGroup: ICourseLectureGroup) => {
                // if some of the fields are changed, and the values are not the same as before, save button gets displayed
                const canSave =
                  this.props.form.isFieldsTouched([`lecturegroupOrdering_${lectureGroup.id}`, `lecturegroupTitle_${lectureGroup.id}`]) &&
                  (this.props.form.getFieldValue(`lecturegroupOrdering_${lectureGroup.id}`) !== lectureGroup.ordering || this.props.form.getFieldValue(`lecturegroupTitle_${lectureGroup.id}`) !== lectureGroup.title);

                return (
                  <Form hideRequiredMark={true} layout="inline" key={lectureGroup.id}>
                    <FormItem label={this.props.translate('COURSE_VIEW.UPDATE.COURSE_LECTURE_GROUP_ORDERING_FORM_LABEL')}>
                      {getFieldDecorator(`lecturegroupOrdering_${lectureGroup.id}`, {
                        initialValue: lectureGroup.ordering,
                        rules: [
                          { min: MIN_GROUP_ORDERING_VALUE, max: maxGroupOrderingValue, type: 'number', message: this.props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
                          { required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') },
                        ],
                      })(<InputNumber data-test-id={`timun-courseUpdateForm-lecturegroupOrdering_${lectureGroup.id}`} min={MIN_GROUP_ORDERING_VALUE} max={maxGroupOrderingValue} disabled={!this.props.canEditStructure}/>)}
                    </FormItem>

                    <FormItem label={this.props.translate('COURSE_VIEW.UPDATE.COURSE_LECTURE_GROUP_TITLE_FORM_LABEL')}>
                      {getFieldDecorator(`lecturegroupTitle_${lectureGroup.id}`, {
                        initialValue: lectureGroup.title,
                        rules: [
                          { whitespace: true, message: this.props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
                          { required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') },
                        ],
                      })(<Input maxLength={NAME_LENGTH_MAX} data-test-id={`timun-courseUpdateForm-lecturegroupTitle_${lectureGroup.id}`}/>)}
                    </FormItem>

                    {canSave ? (
                      <FormItem>
                        <Button data-test-id={`timun-courseUpdateForm-lecturegroup_${lectureGroup.id}_submitButton`} icon={<LemonIcon name="check"/>} type="primary" onClick={() => this.handleLectureGroupSubmit(lectureGroup.id)}>
                          {this.props.translate('COMMON.ACTION_SAVE')}
                        </Button>
                        &nbsp;
                        <Button data-test-id={`timun-courseUpdateForm-lecturegroup_${lectureGroup.id}_cancelButton`} icon={<LemonIcon name="times"/>} onClick={() => this.props.form.resetFields([`lecturegroupOrdering_${lectureGroup.id}`, `lecturegroupTitle_${lectureGroup.id}`])}>
                          {this.props.translate('COMMON.ACTION_CANCEL')}
                        </Button>
                      </FormItem>
                    ) : null}
                    {this.props.canEditStructure && !canSave && (
                      <FormItem>
                        <DeleteElementButton<ICourseLectureGroup> item={lectureGroup} onDelete={this.props.onLectureGroupDelete}/>
                      </FormItem>
                    )}
                  </Form>
                );
              })}
            {this.props.canEditStructure && (
              <React.Fragment>
                <br/>
                <Row justify="center">
                  <Col>
                    <AddElementLink onSubmit={this.props.onLectureGroupAdd} title={this.props.translate('COURSE_VIEW.UPDATE.GROUP_NAME_INPUT_TITLE')}/>
                  </Col>
                </Row>
              </React.Fragment>
            )}
          </TabPane>
        </Tabs>
      </React.Fragment>
    );
  };

  handleCancel = () => {
    if (this.props.form.isFieldsTouched()) {
      confirmationDialog({
        onConfirm: () => {
          this.props.onEditingChange(false);
        },
        title: this.props.translate('COMMON.CONFIRMATION_CANCEL_ACTION_MESSAGE'),
      });
    } else {
      this.props.onEditingChange(false);
    }
  };

  handleLectureGroupSubmit = (id: string) => {
    this.props.form.validateFields([`lecturegroupOrdering_${id}`, `lecturegroupTitle_${id}`], (err: any, values: any) => {
      console.log(values);
      if (!err) {
        const data: ICourseLectureGroup = {
          id,
          ordering: values[`lecturegroupOrdering_${id}`],
          title: values[`lecturegroupTitle_${id}`],
        };
        this.props.onLectureGroupUpdate(data);
        this.props.form.resetFields([`lecturegroupOrdering_${id}`, `lecturegroupTitle_${id}`]);
      }
    });
  };

  handleCourseDataSubmit = (data: ICourseCreatePayload) => {
    const { content, ...courseData } = data;

    const course: ICourseUpdate = {
      ...this.props.course,
      ...courseData,
      content: {
        ...this.props.course.content,
        ...content,
      },
    };

    this.props.onCourseUpdate(course);
    this.props.onEditingChange(false);
  };
}

const CourseUpdateForm = Form.create()(CourseDataUpdateForm);

export default withLocalize<ICourseUpdateFormOwnProps>(CourseUpdateForm as any);
