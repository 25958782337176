import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { IUserGroup } from '@src/model/usergroup/UserGroup';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { transformUserListFilter } from '@src/service/business/user/util';
import { RequiredBy } from '@src/service/util/lang/type';
import { actionThunk, startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

// -
// -------------------- Types&Consts

// TODO: TS complains if we export interface after declaration, so we still use inline export
export interface IUserListFilter {
  id?: string;
  name?: string;
  role?: UserRoleEnum;
  activeOnly?: boolean;
  withCoursesStats?: boolean;
  withCourseProgress?: boolean;
  course?: string;
  tags?: string[];
  identificationNumber?: number;
  assignedDateFrom?: string;
  assignedDateTo?: string;
  userGroups?: RequiredBy<Partial<IUserGroup>, 'id'>;
}

const USER_LIST_FILTER_NAME = '@@USER_LIST_FILTER_NAME';

// -
// -------------------- Selectors

const getUserList = (store: any): ICollectionData<IUserInfo> => store.userList;

const getUserListFilter = (store: any): IUserListFilter => ListFilterBusinessStore.selectors.getListFilter(store, USER_LIST_FILTER_NAME);
// -
// -------------------- Actions

const Actions = {
  USER_SEARCH_LIST_FETCH: 'USER_SEARCH_LIST_FETCH',
  USER_SEARCH_PICKER_LIST_FETCH: 'USER_SEARCH_PICKER_LIST_FETCH',
  USER_SEARCH_LIST_LOAD: 'USER_SEARCH_LIST_LOAD',
  USER_SEARCH_LIST_CLEAR: 'USER_SEARCH_LIST_CLEAR',
};

const fetchUserList = (params: ICollectionFetchPayload<IUserListFilter>): IPayloadAction<ICollectionFetchPayload<IUserListFilter>> => {
  return {
    type: Actions.USER_SEARCH_LIST_FETCH,
    payload: params,
  };
};

const fetchUserPickerList = (params: ICollectionFetchPayload<IUserListFilter>): IPayloadAction<ICollectionFetchPayload<IUserListFilter>> => {
  return {
    type: Actions.USER_SEARCH_PICKER_LIST_FETCH,
    payload: params,
  };
};

const loadUserList = (data: ICollectionData<IUserInfo>): IPayloadAction<ICollectionData<IUserInfo>> => {
  return {
    type: Actions.USER_SEARCH_LIST_LOAD,
    payload: data,
  };
};

const clearUserList = (): ILemonAction => {
  return {
    type: Actions.USER_SEARCH_LIST_CLEAR,
  };
};

const clearUserListFilter = (): ILemonAction => {
  return ListFilterBusinessStore.actions.clearListFilter(USER_LIST_FILTER_NAME);
};

const storeUserListFilter = (listFilter: IUserListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(USER_LIST_FILTER_NAME, listFilter);
};

// -
// -------------------- Side-effects

const fetchUserListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<IUserListFilter>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_SEARCH_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = {
        ...action.payload,
        filter: action.payload.filter && transformUserListFilter(action.payload.filter),
      };

      return EntityApiServiceRegistry.getService('User')
        .fetchEntityList(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadUserList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching user list', error);

      return o;
    }),
  );
};

const fetchUserPickerListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<IUserListFilter>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_SEARCH_PICKER_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('User')
        .fetchEntityList(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching user list', error);

      return o;
    }),
  );
};

// -
// -------------------- Reducers

const userList = (state: ICollectionData<IUserInfo> | null = null, action: IPayloadAction<ICollectionData<IUserInfo>>) => {
  if (action.type === Actions.USER_SEARCH_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.USER_SEARCH_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const UserListBusinessStore = {
  actions: {
    fetchUserList,
    loadUserList,
    clearUserList,
    storeUserListFilter,
    clearUserListFilter,
    fetchUserPickerList,
  },

  selectors: {
    getUserList,
    getUserListFilter,
  },

  effects: {
    fetchUserListEffect,
    fetchUserPickerListEffect,
  },

  reducers: {
    userList,
  },
};

// --
// ----- Exports

export default UserListBusinessStore;
