
import FilePreviewModal from '@src/components/common/file/FilePreviewModal';
import { ITimunFile } from '@src/components/common/file/FileUtils';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import FileUpload, { IUploadStatus } from '@src/components/common/upload/FileUpload';
import { IFileListsByType } from '@src/components/externalEducationApplication/common/types';

import { IFile } from '@src/model/file/File';
import { FileTypeEnum } from '@src/model/file/FileSystemElement';
import { Button, Col, Row, Spin } from 'antd';
import { UploadFile } from 'antd/lib/upload/interface';
import React from 'react';


interface IExternalEducationApplicationFileListUploadOwnProps {
  applicationFiles: IFileListsByType;
  fileType: keyof typeof FileTypeEnum;
  onFileUpload?: (file: IFile[]) => void;
  onFileRemove?: (file: IFile[]) => void;
}

interface IExternalEducationApplicationFileListUploadState {
  fileToDisplay?: UploadFile;
}

type IExternalEducationApplicationFileListUploadProps = IExternalEducationApplicationFileListUploadOwnProps & IWithLocalizeOwnProps;

class ExternalEducationApplicationFileListUpload extends React.Component<IExternalEducationApplicationFileListUploadProps, IExternalEducationApplicationFileListUploadState> {
  state: IExternalEducationApplicationFileListUploadState = {
  };

  render() {
    const fileType = FileTypeEnum[this.props.fileType];
    return (
      <React.Fragment >
        <Row>
          <Col xs={24} md={24} lg={12}>
            {/* Antd Upload component has issues with upload and display of changes when there is a controlled list.
            Bypassed if one upload component handles upload only, and additional one displays the list and handles remove of files*/}
            <FileUpload
              onUploadFile={this.handleFileAdd}
              dragger={true}
              dataTestIdPrefix={`timun-externalEducationApplication__fileType_${this.props.fileType}`}
            >
              {(status: IUploadStatus) => (
                <div className="timun-fileUpload__dragField">
                  <p className="ant-upload-drag-icon">{status.isUploading ? <Spin size="large" /> : <LemonIcon name="fileUploadDropzone" />}</p>
                  <Button type="link">{this.props.translate('FILE_UPLOAD.DROPZONE_TEXT')}</Button>
                </div>
              )}
            </FileUpload>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={24} lg={12}>
            <FileUpload
              onUploadFile={this.handleFileAdd}
              uploadProps={{ onPreview: this.toggleImagePreviewVisible, showUploadList: true, fileList: this.props.applicationFiles[fileType], onRemove: this.handleFileDelete }}
              dataTestIdPrefix={`timun-externalEducationApplication__fileType_${this.props.fileType}`}
            />
          </Col>
        </Row>

        {this.state.fileToDisplay && <FilePreviewModal closeModal={this.handleClosePreview} record={this.state.fileToDisplay} />}
      </React.Fragment>
    );
  }

  toggleImagePreviewVisible = (_file: ITimunFile) => {
    this.setState({ fileToDisplay: _file });
  };

  handleClosePreview = () => {
    this.setState({
      fileToDisplay: undefined,
    });
  };

  handleFileAdd = (data: IFile) => {
    const fileType = FileTypeEnum[this.props.fileType];
    const newFile: IFile = {
      ...data,
      type: {
        id: fileType,
        name: this.props.fileType,
      },
    };
    if (this.props.onFileUpload) {
      this.props.onFileUpload([newFile]);
    }

  };

  handleFileDelete = (data: ITimunFile) => {
    const fileType = FileTypeEnum[this.props.fileType];
    const deleted: IFile | undefined = this.props.applicationFiles[fileType]?.find((file: UploadFile) => file.response?.id === data.uid)?.response;
    if (this.props.onFileRemove && deleted) {
      this.props.onFileRemove([deleted]);
    }
  };

}

export default withLocalize<IExternalEducationApplicationFileListUploadOwnProps>(ExternalEducationApplicationFileListUpload as any);
