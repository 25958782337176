import React from 'react';
import { connect } from 'react-redux';

import DataPicker, { IDataPickerItem } from '@src/components/common/datapicker/DataPicker';
import { IEducationSector } from '@src/model/educationsector/EducationSector';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import EducationSectorBusinessStore, { IEducationSectorListFilter } from '@src/service/business/educationsector/educationSectorBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { LangUtils } from '@src/service/util/LangUtils';

const minSearchLength = AppConfigService.getValue('components.common.minSearchStringLength');

// -- Prop types
// ----------

export interface IEducationSectorDataPickerOwnProps {
  value?: IEducationSector;
  disabled?: boolean;
  onChange?: (newValue?: IEducationSector) => void;
}

export interface IEducationSectorDataPickerStateProps {
}

export interface IEducationSectorDataPickerDispatchProps {
  clearSkill: () => void;
  fetchEducationSector: (params: ICollectionFetchPayload<IEducationSectorListFilter>) => ITrackableAction;
}
type IEducationSectorDataPickerProps = IEducationSectorDataPickerOwnProps & IEducationSectorDataPickerStateProps & IEducationSectorDataPickerDispatchProps;

interface IEducationSectorDataPickerState {
  educationSector?: IEducationSector[] | null;
}

// -- Component
// ----------

/** Describe your component ... */
class EducationSectorDataPicker extends React.Component<IEducationSectorDataPickerProps, IEducationSectorDataPickerState> {
  state: IEducationSectorDataPickerState = {};

  render = () => {
    const pickerValue = this.props.value && this.mapToItem(this.props.value);
    const pickerItems = this.state.educationSector != null ? this.state.educationSector.map(this.mapToItem) : [];

    return <DataPicker value={pickerValue} items={pickerItems} disabled={this.props.disabled} onChange={this.handleDataPickerChange} onSearch={this.handleDataPickerSearch} dataTestIdPrefix="timun-skillPicker" />;
  };

  private handleDataPickerChange = (value?: IDataPickerItem) => {
    if (this.props.onChange) {
      this.props.onChange(value?.data);
    }
  };

  private handleDataPickerSearch = (value: string) => {
    if (value.length >= minSearchLength) {
      this.searchList(value);
    } else if (LangUtils.isEmpty(value) || value.length < minSearchLength) {
      this.storeList();
    }
  };

  /** Map entity ref list to data picker item list. */
  private mapToItem(skill: IEducationSector): IDataPickerItem {
    return {
      value: skill.id,
      name: skill.name,
      data: skill,
    };
  }

  private searchList(search: string) {
    // search by name only active education sectors
    this.fetchList({
      filter: { name: search, active: true },
      page: 0,
      size: AppConfigService.getValue('components.dataPicker.defaultPageSize'),
      sort: [],
    });
  }

  private fetchList(params: ICollectionFetchPayload<IEducationSectorListFilter>) {
    this.props.fetchEducationSector(params).track().subscribe(this.storeList);
  }

  private storeList = (educationSector?: ICollectionData<IEducationSector>) => {
    this.setState({ educationSector: educationSector?.content });
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IEducationSectorDataPickerOwnProps): IEducationSectorDataPickerStateProps => ({
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IEducationSectorDataPickerDispatchProps => ({
  clearSkill: () => dispatch(EducationSectorBusinessStore.actions.clearEducationSectorData),
  fetchEducationSector: (params: ICollectionFetchPayload<IEducationSectorListFilter>) => dispatch(createTrackableAction(EducationSectorBusinessStore.actions.fetchEducationSectorList(params))),
});

export default connect<IEducationSectorDataPickerStateProps, IEducationSectorDataPickerDispatchProps, IEducationSectorDataPickerOwnProps>(mapStateToProps, mapDispatchToProps)(EducationSectorDataPicker as any);
