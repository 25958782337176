import { ITag } from '@src/model/tag/Tag';

export interface IWebinar {
  id: string;
  title: string;
  actualStartDateTime?: string;
  description?: string;
  coverImageUrl?: string;
  guest?: string;
  actualEndDateTime?: string;
  estimatedEndDateTime?: string;
  estimatedStartDateTime: string;
  link: string;
  status: IWebinarStatus;
  tags?: ITag[];
}

export interface IWebinarStatus {
  id: WebinarStatusEnum;
  name: string;
  description: string;
}

export enum WebinarStatusEnum {
  IN_CREATION = '1',
  CREATION_FINISHED = '2',
  PUBLISHED = '3',
  IN_PROGRESS = '4',
  FINISHED = '5',
  CANCELLED = '6',
  ARCHIVED = '7',
}
