import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import UserGroupForm from '@src/components/usergroup/form/UserGroupForm';
import { IUserGroup, UserGroupTypeEnum } from '@src/model/usergroup/UserGroup';
import UserGroupBusinessStore, { IUserGroupCreatePayload } from '@src/service/business/usergroup/UserGroupBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import React from 'react';
import { connect } from 'react-redux';

// -- Prop types
// ----------

export interface IUserGroupCreateContainerOwnProps {
  groupType?: UserGroupTypeEnum;
  parentGroup?: IUserGroup;
  onSubmit?: () => void;
  onCancel?: () => void;
}

export interface IUserGroupCreateContainerStateProps {
}
export interface IUserGroupCreateContainerDispatchProps {
  createUserGroup: (userGroup: IUserGroupCreatePayload) => ITrackableAction;
}

type IUserGroupCreateContainerProps = IUserGroupCreateContainerOwnProps & IUserGroupCreateContainerStateProps & IUserGroupCreateContainerDispatchProps & IWithLocalizeOwnProps;

interface IUserGroupCreateContainerState {
}

// -- Component
// ----------
class UserGroupCreateContainer extends React.Component<IUserGroupCreateContainerProps, IUserGroupCreateContainerState> {
  state = {
  };

  render() {
    return (
      <UserGroupForm
        title={this.props.translate('USER_GROUP.NEW_USER_GROUP_TITLE')}
        parentGroup={this.props.parentGroup}
        groupType={this.props.groupType}
        onCancel={this.props.onCancel}
        onCreate={this.handleCreateUserGroup}
      />
    );
  }

  private handleCreateUserGroup = (userGroup: IUserGroupCreatePayload) => {
    this.props.createUserGroup(userGroup).track().subscribe(
      // success
      () => {
        this.props.onSubmit?.();
      }
    );
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IUserGroupCreateContainerStateProps => ({
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IUserGroupCreateContainerDispatchProps => ({
  createUserGroup: (userGroup: IUserGroupCreatePayload) => createTrackableAction(dispatch(UserGroupBusinessStore.actions.createUserGroup(userGroup))),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<IUserGroupCreateContainerOwnProps>(UserGroupCreateContainer as any));
