import ApiResponseErrorException, { ApiResponseErrorCode } from '@src/service/api/ApiResponseErrorException';
import { logoutCurrentUser } from '@src/service/business/login/loginBusinessService';

/** Handle SESSION_EXPIRED API response error and redirect user to login page. */
export function sessionExpiredApiResponseErrorHandler(error: any): boolean {
  if (error instanceof ApiResponseErrorException && error.code === ApiResponseErrorCode.SESSION_EXPIRED) {
    console.info(`API session expired detected. Logout user and redirect to login page.`);

    clearCurrentUser();

    return false;
  }

  return true;
}

/** Handle AUTHENTICATION_ERROR API response error and redirect user to login page. */
export function userAuthenticationApiResponseErrorHandler(error: any): boolean {
  if (error instanceof ApiResponseErrorException && error.code === ApiResponseErrorCode.AUTHENTICATION_ERROR) {
    console.info(`Error authenticating current user. Logout user and redirect to login page.`);

    clearCurrentUser();

    return false;
  }

  return true;
}

function clearCurrentUser() {
  logoutCurrentUser();
}
