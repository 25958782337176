import { IAddress } from '@src/model//user/Address';
import { IActivity } from '@src/model/activity/Activity';
import { ICodeBookEntry } from '@src/model/common/CodeBookEntry';
import { ICompany } from '@src/model/company/Company';
import { IExternalEducationApplicationConsultation } from '@src/model/externalEducationApplication/ExternalEducationApplicationConsultation';
import { IExternalEducationApplicationPaymentInfo } from '@src/model/externalEducationApplication/ExternalEducationApplicationPaymentInfo';
import { IExternalEducationExpense } from '@src/model/externalEducationExpense/ExternalEducationExpense';
import { IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { ISkill } from '@src/model/skillgroup/Skill';
import { ISurveyInstance } from '@src/model/survey/SurveyInstance';

export interface IExternalEducationApplication {
  activity: IActivity;
  id: string;
  createdAt: string;
  applicationDescription?: string;
  userAcceptedTermsOfUse?: boolean;
  employmentStatus?: ICodeBookEntry<EmploymentStatusEnum>;
  employerInformation?: ICompany;
  startDateTime?: string;
  jobRelated?: boolean;
  educationRelated?: boolean;
  skills?: ISkill[];
  consultation?: IExternalEducationApplicationConsultation;
  surveyInstances?: ISurveyInstance[];
  education?: IExternalEducationTemplate;
  expenses?: IExternalEducationExpense[];
  districtOfficeAddress?: Pick<IAddress, 'city'>; // IAddress;
  paymentInfos?: IExternalEducationApplicationPaymentInfo[];
  offerCode: string;
}

export enum EmploymentStatusEnum {
  EMPLOYED = '1',
  UNEMPLOYED = '2',
}
