import React from 'react';

import UrlBuilderFactory from '@src/service/util/UrlBuilderFactory';

// --
// ----- Prop types

export interface IWithPathResolverOwnProps {
  /** Create URL to static image from given file path. File path is calculated relatively against image root path configured during app bundling. */
  resolveImagePath: (filePath: string) => string;

  /** Create URL to APP from given resource path. This is used for building app page links. */
  resolveAppPath: (resourcePath: string) => string;

  /** Create URL to full API path. */
  resolveApiPath: (resourcePath: string) => string;

  /** Create URL to API resource from given resource path (eg. entity name and ID). */
  resolveApiResourcePath: (resourcePath: string) => string;

  /** Create URL to API file resource from given file ID. */
  resolveApiFilePath: (fileId: string) => string;
}

// --
// ----- Component

/** Higher order component for injecting function for resolving in-app and app-to-api URLs (eg. to images or to API calls). */
const withPathResolver = <P extends object>(Component: React.ComponentType<P>) =>
  class WithPath extends React.Component<Exclude<P, IWithPathResolverOwnProps>> {
    render() {
      const TypedComponent: React.ComponentType<P & IWithPathResolverOwnProps> = Component as any;
      return <TypedComponent {...this.props} resolveImagePath={UrlBuilderFactory.buildImageUrl} resolveAppPath={UrlBuilderFactory.buildAppUrl} resolveApiPath={UrlBuilderFactory.buildApiUrl} resolveApiResourcePath={UrlBuilderFactory.buildApiResourceUrl} resolveApiFilePath={UrlBuilderFactory.buildApiFileUrl} />;
    }
  };

// ----- exports

export default withPathResolver;
