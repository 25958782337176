import React from 'react';

import LemonDefaultError from '@src/components/common/error/LemonDefaultError';

export interface ILemonErrorBoundaryPublicProps {
  /** Show default error component. */
  showDefaultError?: boolean;

  /** Rendering function for showing custom error. */
  renderError?: () => string | React.ReactNode;
}
type ILemonErrorBoundaryProps = ILemonErrorBoundaryPublicProps;

interface ILemonErrorBoundaryState {
  hasError: boolean;
}

/** Standard error boundary component. */
class LemonErrorBoundary extends React.Component<ILemonErrorBoundaryProps, ILemonErrorBoundaryState> {
  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }
  constructor(props: ILemonErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: any, info: any) {
    // You can also log the error to an error reporting service
    console.error('Boundary error caught', error, info);

    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      // render defaultError
      if (this.props.showDefaultError) {
        return <LemonDefaultError />;
      } else if (this.props.renderError != null) {
        return this.props.renderError();
      } else {
        // warning sign
        return <div>&#9888;</div>;
      }
    }

    return this.props.children;
  }
}

export default LemonErrorBoundary;
