import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import TimunShortInfoTable from '@src/components/common/table/TimunShortInfoTable';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import { ISkillLevel } from '@src/model/skillgroup/SkillLevelClassification';
import { ICollectionData } from '@src/service/business/common/types';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import { Col, Row, Typography } from 'antd';
import React from 'react';

// -- Const
// ----------
const path = '/administration/codebooks';

// -- Prop types
// ----------
interface ISkillLevelCodebookListViewStateProps {
}

interface ISkillLevelCodebookListViewOwnProps {
  skillLevelCodebookList: ICollectionData<ISkillLevel>;
  onPageChange: (page: number, size?: number) => void;
  onEditClick?: (skillLevelCodebook: ISkillLevel) => void;
}

type ISkillLevelCodebookListViewProps = ISkillLevelCodebookListViewOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Display codebook list of items */
class SkillLevelCodebookListView extends React.Component<ISkillLevelCodebookListViewProps, ISkillLevelCodebookListViewStateProps> {
  render() {
    const SkillLevelCodebookTableActionColumns = {
      edit: { showButton: true, onClick: this.props.onEditClick },
    };

    return (
      <React.Fragment>
        <Row justify="space-between">
          <Col>
            <HeaderTitle title={LocalizeService.translate('CODEBOOK.CODEBOOK_TITLE')} link={path} icon={<LemonIcon name="left" />} />
          </Col>
        </Row>
        <Typography.Title>{this.props.translate('CODEBOOK.TITLE.SKILL_LEVEL')}</Typography.Title>
        <TimunShortInfoTable<ISkillLevel>
          paginationConfig={{ onChange: this.props.onPageChange }}
          items={this.props.skillLevelCodebookList.content}
          paginationData={this.props.skillLevelCodebookList.page}
          // rowDescription={this.renderGradeRowDescription}
          actionButtons={SkillLevelCodebookTableActionColumns} />
      </React.Fragment>
    );
  }
}

// -- HOCs and exports
// ----------

export default withLocalize<ISkillLevelCodebookListViewOwnProps>(SkillLevelCodebookListView as any);
