import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import UserGroupFormWrapper from '@src/components/usergroup/form/UserGroupForm';
import { IReference } from '@src/model/common/Reference';
import { IUserGroup } from '@src/model/usergroup/UserGroup';
import UserGroupBusinessStore from '@src/service/business/usergroup/UserGroupBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

// -- Prop types
// ----------
interface IUserGroupUpdateContainerOwnProps {
  userGroup?: IUserGroup;
  parentGroup?: IReference<string>;
  onCancel?: () => void;
  onSubmit?: () => void;
}

interface IUserGroupUpdateContainerStateProps {
}

interface IUserGroupUpdateContainerDispatchProps {
  updateUserGroup: (userGroup: IUserGroup) => ITrackableAction;
}

// -- State types
// ----------
type IUserGroupUpdateContainerContentProps = IUserGroupUpdateContainerOwnProps & IUserGroupUpdateContainerStateProps & IUserGroupUpdateContainerDispatchProps & IWithLocalizeOwnProps;

interface IUserGroupUpdateContainerState {
}

// -- Component
// ----------

/** Component for user group update */
class UserGroupUpdateContainer extends React.Component<IUserGroupUpdateContainerContentProps, IUserGroupUpdateContainerState> {
  state = {
  };

  render() {
    return (
      <UserGroupFormWrapper
        userGroup={this.props.userGroup}
        parentGroup={this.props.parentGroup}
        title={this.props.translate('USER_GROUP_EDIT_FORM.EDIT_LABEL')}
        onUpdate={this.handleUserGroupUpdate}
        onCancel={this.props.onCancel}
      />
    );
  }

  private handleUserGroupUpdate = (userGroup: IUserGroup) => {
    this.props.updateUserGroup(userGroup).track().subscribe(
      // success
      () => {
        this.props.onSubmit?.();
      }
    );
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IUserGroupUpdateContainerOwnProps): IUserGroupUpdateContainerStateProps => ({
});
// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: Dispatch): IUserGroupUpdateContainerDispatchProps => ({
  updateUserGroup: (userGroup: IUserGroup) => createTrackableAction(dispatch(UserGroupBusinessStore.actions.updateUserGroup(userGroup))),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<IUserGroupUpdateContainerOwnProps>(UserGroupUpdateContainer as any));
