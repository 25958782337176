import { IFileListsByType } from '@src/components/externalEducationApplication/common/types';
import { IActivity } from '@src/model/activity/Activity';
import { IActivityParticipant, ParticipantRoleEnum } from '@src/model/activity/ActivityParticipant';
import { ActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { ExpeneseTypeEnum, IExternalEducationExpense } from '@src/model/externalEducationExpense/ExternalEducationExpense';
import { IFile } from '@src/model/file/File';
import { FileTypeEnum } from '@src/model/file/FileSystemElement';
import UrlBuilderFactory from '@src/service/util/UrlBuilderFactory';

export default class EducationApplicationHelperUtils {

  /** Return participant by requested role. */
  static getActivityParticipantByRole(requestedRole: ParticipantRoleEnum, activity: IActivity): IActivityParticipant | undefined {
    return activity.participants.find((paritcipant: IActivityParticipant) => paritcipant.participantRole === requestedRole);
  }

  /** Returns participant name by requested role. */
  static getActivityParticipantNameByRole(requestedRole: ParticipantRoleEnum, activity: IActivity): string | undefined {
    const participant = activity.participants.find((paritcipant: IActivityParticipant) => paritcipant.participantRole === requestedRole);
    if (participant) {
      return `${participant?.firstName} ${participant?.lastName}`;
    } else {
      return undefined;
    }
  }

  /** Check if application has a coordinator assigned. */
  static hasCoordinator = (activity: IActivity) => {
    return EducationApplicationHelperUtils.getActivityParticipantByRole(ParticipantRoleEnum.EVALUATOR, activity) != null;
  };

  /** Return education application expense value by requested expense type id. */
  static getEducationApplicationExpenseValueByExpenseType(requestedExpenseTypeId: ExpeneseTypeEnum, educationApplicationExpense: IExternalEducationExpense[]): number | undefined {
    const requestedExpense = educationApplicationExpense.find((expense: IExternalEducationExpense) => expense.expenseType.id === requestedExpenseTypeId);
    return requestedExpense?.value;
  }

  /** Check if activity is in one of listed phases. */
  static isInPhases(requestedPhases: ActivityPhaseEnum[], activity: IActivity): boolean {
    return requestedPhases.some((phase) => activity.currentPhase.id === phase);
  }

  /** Check if thereare files for all listed types. */
  static hasFilesInAllTypes = (files: IFileListsByType, types: FileTypeEnum[]) => {
    return types.every((type) => files && files[type]?.length > 0);
  };

  /** Check if thereare files for any listed types. */
  static hasFilesInTypes = (files: IFileListsByType, types: FileTypeEnum[]) => {
    return types.some((type) => files && files[type]?.length > 0);
  };

  /** get files sorted by type from standard file list */
  static getFileLists = (files?: IFile[]): IFileListsByType => {
    const fileLists: IFileListsByType = {};
    files?.forEach((item: IFile) => {
      const typeId = item?.type.id;
      const fileObject = {
        uid: item.id,
        name: item.name,
        type: item.mimeType,
        size: item.size,
        response: item,
        url: UrlBuilderFactory.buildApiFileUrl(item.id),
      };
      if (!fileLists[typeId]) {
        fileLists[typeId] = [fileObject];
      } else {
        fileLists[typeId].push(fileObject);
      }

    });
    return fileLists;
  };
}
