import { Form } from '@ant-design/compatible';
import { Button, Row, Tag } from 'antd';
import Col, { ColProps } from 'antd/lib/col';
import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import ITenantConfiguration from '@src/model/tenant/TenantConfiguration';

const GRID_LAYOUT_LABEL_LAYOUT: ColProps = { xs: 24, sm: 10, md: 8 };
const GRID_LAYOUT_WRAPPER_LAYOUT: ColProps = { xs: 24, sm: 12, md: 12 };

// -- Prop types
// ----------

export interface ITenantConfigurationViewOwnProps {
  configuration: ITenantConfiguration;

  onEdit?: () => void;
}
type ITenantConfigurationViewProps = ITenantConfigurationViewOwnProps & IWithLocalizeOwnProps & IWithPathResolverOwnProps;

// -- Component
// ----------

/** Display tenant configuration items. */
const TenantConfigurationView: React.FC<ITenantConfigurationViewProps> = (props) => {
  const enabledLabel = props.translate('COMMON.ACTION_YES');
  const disabledLabel = props.translate('COMMON.ACTION_NO');
  return (
    <Form>
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.STYLE_THEME_LABEL')}>
        {props.configuration.configuration?.styleTheme}
      </Form.Item>

      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_DASHBOARD_LABEL')}>
        {props.configuration.configuration?.enableDashboard === true ? enabledLabel : disabledLabel}
      </Form.Item>

      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_LOCALES_LABEL')}>
        {props.configuration.configuration?.enableLanguages === true ? enabledLabel : disabledLabel}
      </Form.Item>

      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_COURSES_LABEL')}>
        {props.configuration.configuration?.enableCourses === true ? enabledLabel : disabledLabel}
      </Form.Item>

      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_WEBINARS_LABEL')}>
        {props.configuration.configuration?.enableWebinars === true ? enabledLabel : disabledLabel}
      </Form.Item>

      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_EXTERNAL_CONTENTS_LABEL')}>
        {props.configuration.configuration?.enableExternalContents === true ? enabledLabel : disabledLabel}
      </Form.Item>

      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_REPOSITORY_LABEL')}>
        {props.configuration.configuration?.enableRepository === true ? enabledLabel : disabledLabel}
      </Form.Item>

      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_USER_GROUP_COURSES_LABEL')}>
        {props.configuration.configuration?.enableUserGroupCourses === true ? enabledLabel : disabledLabel}
      </Form.Item>

      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_USER_GROUPS_LABEL')}>
        {props.configuration.configuration?.enableUserGroups === true ? enabledLabel : disabledLabel}
      </Form.Item>

      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_EXTERNAL_EDUCATION_APPLICATIONS_LABEL')}>
        {props.configuration.configuration?.enableExternalEducationApplications === true ? enabledLabel : disabledLabel}
      </Form.Item>

      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_EXTERNAL_EDUCATIONS_LABEL')}>
        {props.configuration.configuration?.enableExternalEducations === true ? enabledLabel : disabledLabel}
      </Form.Item>

      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_INTEGRATION_VIDEO')}>
        {props.configuration.configuration?.enableIntegrationVideo === true ? enabledLabel : disabledLabel}
      </Form.Item>

      {/* Administration links */}
      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_WORKPOSITIONS')}>
        {props.configuration.configuration?.enableWorkpositions === true ? enabledLabel : disabledLabel}
      </Form.Item>

      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_ACTIVITIES')}>
        {props.configuration.configuration?.enableActivities === true ? enabledLabel : disabledLabel}
      </Form.Item>

      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_NOTIFICATIONS')}>
        {props.configuration.configuration?.enableNotifications === true ? enabledLabel : disabledLabel}
      </Form.Item>

      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_EMAILS')}>
        {props.configuration.configuration?.enableEmails === true ? enabledLabel : disabledLabel}
      </Form.Item>

      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_SKILLS')}>
        {props.configuration.configuration?.enableSkills === true ? enabledLabel : disabledLabel}
      </Form.Item>

      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_SKILL_LEVELS')}>
        {props.configuration.configuration?.enableSkillLevelClassification === true ? enabledLabel : disabledLabel}
      </Form.Item>

      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_SURVEYS')}>
        {props.configuration.configuration?.enableSurveys === true ? enabledLabel : disabledLabel}
      </Form.Item>

      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_COMMENTS')}>
        {props.configuration.configuration?.enableComments === true ? enabledLabel : disabledLabel}
      </Form.Item>

      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_CODEBOOKS')}>
        {props.configuration.configuration?.enableCodebooks === true ? enabledLabel : disabledLabel}
      </Form.Item>

      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.EXAM_QUESTION_LIST')}>
        <Row >  {props.configuration.configuration?.examQuestionList?.map(questionType => <Col key={questionType}> <Tag>  {props.translate(`QUESTION_TYPE.${questionType}`)} </Tag>  </Col>)} </Row>
      </Form.Item>

      <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={props.translate('TENANT_CONFIGURATION_VIEW.SURVEY_QUESTION_LIST')}>
        <Row>  {props.configuration.configuration?.surveyQuestionList?.map(questionType => <Col key={questionType}> <Tag>  {props.translate(`QUESTION_TYPE.${questionType}`)} </Tag> </Col>)} </Row>
      </Form.Item>

      <Form.Item>
        <Row justify="space-around">
          <Col className="text-center">
            <Button onClick={(e) => props.onEdit?.()}>{props.translate('COMMON.ACTION_EDIT')}</Button>
          </Col>
        </Row>
      </Form.Item>
    </Form>
  );
};

export default withLocalize<ITenantConfigurationViewOwnProps>(withPathResolver(TenantConfigurationView as any));
