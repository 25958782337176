import { Spin } from 'antd';
import React from 'react';

// --
// ---------- Prop types

export interface IProgressStatusPublicProps {}
type IProgressStatusProps = IProgressStatusPublicProps;

// --
// ---------- Component

export default class ProgressStatus extends React.Component<IProgressStatusProps> {
  render() {
    return (
      <div className="lemon-userFeedback__progressStatusContainer">
        <div className="lemon-userFeedback__progressStatusMask" data-test-id="lemon-userFeedback__loadingSpinner" />
        <Spin size="large" />
      </div>
    );
  }
}
