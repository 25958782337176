import DeleteElementButton from '@src/components/common/button/DeleteElementButton';
import AppContent from '@src/components/common/container/AppContent';
import LemonIcon from '@src/components/common/image/LemonIcon';
import LemonInfoTooltip from '@src/components/common/info/LemonInfoTooltip';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import { UserNameView } from '@src/components/common/view/UserNameView';
import UserSkillEvaluationModal from '@src/components/user/modal/UserSkillEvaluationModal';
import UserSkillEvaluationHelperUtils from '@src/components/user/utils/UserSkillEvaluationHelperUtils';
import UserSkillLevelChart from '@src/components/user/view/UserSkillLevelChart';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { IUserSkillEvaluation } from '@src/model/user/UserSkillEvaluation';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import { ICollectionData } from '@src/service/business/common/types';
import { IUserSkillEvaluationCreatePayload } from '@src/service/business/user/userSkillEvaluationBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { ITrackableAction } from '@src/service/util/action/trackAction';
import RoleUtils from '@src/service/util/role/RoleUtils';
import { Button, Col, Row, Table } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';

// -- Const
// ----------
const dateFormat = AppConfigService.getValue('dateTimeFormat.dateWithTime');

// -- Prop types
// ----------

export interface IUserSkillEvaluationListViewOwnProps {
  userId: string;
  currentUser: IUserInfo;
  skillEvaluationList?: ICollectionData<IUserSkillEvaluation>;
  onUpdate?: (userEvaulation: IUserSkillEvaluation) => ITrackableAction;
  onCreate?: (userEvaluation: IUserSkillEvaluationCreatePayload) => ITrackableAction;
  onDelete?: (userEvaluation: IUserSkillEvaluation) => void;
  onChange?: () => void;
  workPosition?: IWorkPosition;
  userSkillEvaluationList?: IUserSkillEvaluation[];
}

type IUserSkillEvaluationListViewProps = IUserSkillEvaluationListViewOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

// -- Component
// ----------

/** Display user skill evaluation list with create and edit modals */
const UserSkillEvaluationListView: React.FC<IUserSkillEvaluationListViewProps> = (props: IUserSkillEvaluationListViewProps) => {
  // user skill evaluation state
  const [isCreateModalVisible, setIsCreateModalVisible] = useState<boolean>(false);
  const [isUpdateModalVisible, setisUpdateModalVisible] = useState<boolean>(false);
  const [selectedUserSkillEvaluation, setSelectedUserSkillEvaluation] = useState<IUserSkillEvaluation | undefined>();

  const handleUserSkillEvaluationEdit = (userSkillEvaluation: IUserSkillEvaluation) => {
    setSelectedUserSkillEvaluation(userSkillEvaluation);
    handleUserSkillEvaluationUpdateModalOpen();
  };

  // user skill evaluation update methods
  const toggleUserSkillEvaluationUpdate = (isVisible: boolean) => setisUpdateModalVisible(isVisible);
  const handleUserSkillEvaluationUpdateModalOpen = () => toggleUserSkillEvaluationUpdate(true);
  const handleUserSkillEvaluationUpdateModalClose = (userSkillEvaluation?: IUserSkillEvaluation) => {
    setSelectedUserSkillEvaluation(userSkillEvaluation);
    toggleUserSkillEvaluationUpdate(false);
  };
  const handleUpdate = (userEvaluation: IUserSkillEvaluation) => {
    props.onUpdate?.(userEvaluation).track().subscribe(
      // success
      () => {
        props.onChange?.();
        handleUserSkillEvaluationUpdateModalClose();
      },
    );
  };

  // user skill evaluation create methods
  const toggleUserSkillEvaluationCreate = (isVisible: boolean) => setIsCreateModalVisible(isVisible);
  const handleUserSkillEvaluationCreateModalOpen = () => toggleUserSkillEvaluationCreate(true);
  const handleUserSkillEvaluationCreateModalClose = () => toggleUserSkillEvaluationCreate(false);
  const handleCreate = (userEvaluation: IUserSkillEvaluationCreatePayload) => {
    props.onCreate?.(userEvaluation).track().subscribe(
      // success
      () => {
        props.onChange?.();
        handleUserSkillEvaluationCreateModalClose();
      },
    );
  };

  const isCurrentUserOrgAdmin = RoleUtils.isInRoles([UserRoleEnum.ORGANIZATION_ADMIN], props.currentUser.roles);
  const canDelete = (evaluation: IUserSkillEvaluation): boolean => (UserSkillEvaluationHelperUtils.isUserSkillEvaluationOwner(evaluation, props.currentUser)) || isCurrentUserOrgAdmin;
  const canEdit = (evaluation: IUserSkillEvaluation): boolean => UserSkillEvaluationHelperUtils.isUserSkillEvaluationOwner(evaluation, props.currentUser);

  const skillEvaluationColumns = [
    {
      key: 'assignedSkills',
      title: props.translate('SKILL_EVALUATION_LIST.ASSIGNED_SKILLS_LABEL'),
      render: (record: IUserSkillEvaluation) => record.skillLevelClassification.skill.title,
    },
    {
      key: 'assignedSkillLevel',
      title: props.translate('SKILL_EVALUATION_LIST.ASSIGNED_LEVEL_LABEL'),
      render: (record: IUserSkillEvaluation) => <Row><Col span={20}>{record.skillLevelClassification.skillLevel.name}</Col> <Col span={4}><LemonInfoTooltip message={record.skillLevelClassification.description}/></Col> </Row>,
    },
    {
      key: 'evaluatorName',
      title: props.translate('SKILL_EVALUATION_LIST.EVALUATOR_NAME_LABEL'),
      render: (record: IUserSkillEvaluation) => <UserNameView user={record.evaluatedBy} fallback={props.translate('USER_VIEW.USER_DELETED_LABEL')}/>,
    },
    {
      key: 'evaluationType',
      title: props.translate('SKILL_EVALUATION_LIST.EVALUATION_TYPE_LABEL'),
      render: (record: IUserSkillEvaluation) => props.translate(`SKILL_EVALUATION.TYPE.${record.userSkillEvaluationType.name}`),
    },
    {
      key: 'date',
      title: props.translate('SKILL_EVALUATION_LIST.DATE_LABEL'),
      render: (record: IUserSkillEvaluation) => record.createdDateTime && moment(record.createdDateTime).format(dateFormat),
    },
    {
      key: 'action',
      render: (record: IUserSkillEvaluation) => {
        return (
          <Row gutter={4}>
            <Col xs={24} lg={12}>
              {canEdit(record) && <Button icon={<LemonIcon onClick={() => handleUserSkillEvaluationEdit(record)} name="edit"/>}/>}
            </Col>
            <Col xs={24} lg={12}>
              {canDelete(record) && <DeleteElementButton<IUserSkillEvaluation> item={record} onDelete={props.onDelete}/>}
            </Col>
          </Row>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <UserSkillLevelChart workPosition={props.workPosition} userSkillEvaluationList={props.skillEvaluationList?.content}/>
      <AppContent title={
        <Row justify="end">
          <Button
            onClick={handleUserSkillEvaluationCreateModalOpen}
            type="primary"
            icon={<LemonIcon name="plus"/>}> {props.translate('USER_SKILL_EVALUATION_LIST_CONTAINER.BUTTON_ADD_SKILL_LABEL')}
          </Button>
        </Row>}>
        <Table
          rowKey={(record) => record.id}
          columns={skillEvaluationColumns}
          dataSource={props.skillEvaluationList?.content}
          pagination={false}
          scroll={{ x: 'auto' }}
        />
      </AppContent>
      {/* Modal */}
      {isCreateModalVisible && <UserSkillEvaluationModal title={props.translate('USER_SKILL_EVALUATION.MODAL.CREATE_SKILL_TITLE')} onCancel={handleUserSkillEvaluationCreateModalClose} onUserSkillEvaluationCreate={handleCreate}/>}
      {isUpdateModalVisible && selectedUserSkillEvaluation && <UserSkillEvaluationModal title={props.translate('USER_SKILL_EVALUATION.MODAL.EDIT_SKILL_TITLE')} userSkillEvaluation={selectedUserSkillEvaluation} onCancel={() => handleUserSkillEvaluationUpdateModalClose()} onUserSkillEvaluationUpdate={handleUpdate}/>}
    </React.Fragment>
  );
};

export default withLocalize<IUserSkillEvaluationListViewOwnProps>(withRoles(UserSkillEvaluationListView as any));
