import { ITimelineActivity } from '@src/model/activity/TimelineActivity';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, IIdDataPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { actionThunk, startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

export interface ITimelineActivityListFilter { }

const TIMELINE_ACTIVITY_LIST_FILTER = "@@TIMELINE_ACTIVITY_LIST_FILTER";

// -
// -------------------- Selectors

const getTimelineList = (store: any): ICollectionData<ITimelineActivity> => store.timelineList;
const getTimelineListFilter = (store: any): ITimelineActivityListFilter => ListFilterBusinessStore.selectors.getListFilter(store, TIMELINE_ACTIVITY_LIST_FILTER);

// -
// -------------------- Actions

const Actions = {
  TIMELINE_LIST_FETCH: 'TIMELINE_LIST_FETCH',
  TIMELINE_LIST_LOAD: 'TIMELINE_LIST_LOAD',
  TIMELINE_LIST_CLEAR: 'TIMELINE_LIST_CLEAR',
}

// NOTE: remove Partial from payload type if timeline has to turn to proper list
const fetchTimelineList = (id: string, page?: number, size?: number, sort?: string[], filter?: ITimelineActivityListFilter): IPayloadAction<IIdDataPayload<Partial<ICollectionFetchPayload<ITimelineActivityListFilter>>>> => {
  return {
    type: Actions.TIMELINE_LIST_FETCH,
    payload: {
      id,
      data: {
        filter,
        page,
        size,
        sort
      }
    }
  };
};

const loadTimelineList = (data: ICollectionData<ITimelineActivity>): IPayloadAction<ICollectionData<ITimelineActivity>> => {
  return {
    type: Actions.TIMELINE_LIST_LOAD,
    payload: data,
  };
};

const clearTimelineList = (): ILemonAction => {
  return {
    type: Actions.TIMELINE_LIST_CLEAR,
  }
}

const clearTimelineActivityListFilter = (): ILemonAction => {
  return ListFilterBusinessStore.actions.clearListFilter(TIMELINE_ACTIVITY_LIST_FILTER);
};

const storeTimelineActivityListFilter = (listFilter: ITimelineActivityListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(TIMELINE_ACTIVITY_LIST_FILTER, listFilter);
};

// -
// -------------------- Side-effects

const fetchTimelineListEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ICollectionFetchPayload<ITimelineActivityListFilter>>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TIMELINE_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const id = action.payload.id
      const params = action.payload.data

      return EntityApiServiceRegistry.getService('ExternalEducationApplication')
        .fetchSubentityList(id, 'timeline', params)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadTimelineList(data)
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching externalEducationApplication timeline list', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const timelineList = (state: ICollectionData<ITimelineActivity> | null = null, action: IPayloadAction<ICollectionData<ITimelineActivity>>) => {
  if (action.type === Actions.TIMELINE_LIST_LOAD) {
    return { ...action.payload }
  } else if (action.type === Actions.TIMELINE_LIST_CLEAR) {
    return null;
  }

  return state;
}

// --
// -------------------- Business Store

const TimelineBusinessStore = {
  actions: {
    fetchTimelineList,
    clearTimelineList,
    storeTimelineActivityListFilter,
    clearTimelineActivityListFilter
  },

  selectors: {
    getTimelineList,
    getTimelineListFilter
  },

  effects: {
    fetchTimelineListEffect
  },

  reducers: {
    timelineList
  },
};

// --
// export business store

export default TimelineBusinessStore