import { ITag } from '@src/model/tag/Tag';
import { Tag } from 'antd';
import React from 'react';

export interface IRepositoryTagOwnProps {
  tag: ITag;
  checked: boolean;
  onChecked: (checked: boolean) => void;
}
type IRepositoryTagProps = IRepositoryTagOwnProps;

class RepositoryTag extends React.Component<IRepositoryTagProps> {
  render = () => {
    return <Tag.CheckableTag
      className="timun-tagList__tag"
      onChange={this.props.onChecked}
      checked={this.props.checked}>
      {`${this.props.tag.title} (${this.props.tag.itemCount})`}
    </Tag.CheckableTag>;
  }
}

export default RepositoryTag;