import React from 'react';

/** Higher order component for wrapping component in layout. */
const withLayout = <P extends object>(Component: React.ComponentType<P>, LayoutComponent: React.ComponentType) =>
  class WithLayout extends React.Component<P> {
    render() {
      // TODO: find how to type router Route props
      const layoutProps = (this.props as any).route.props;
      return (
        <LayoutComponent {...layoutProps}>
          <Component {...this.props} />
        </LayoutComponent>
      );
    }
  };

export default withLayout;
