import ITenantConfiguration from '@src/model/tenant/TenantConfiguration';
import StoreService from '@src/service/business/StoreService';
import PublicTenantConfigurationBusinessStore from '@src/service/business/tenant/publicTenantConfigurationBusinessService';
import LemonError from '@src/service/common/LemonError';
import { LangUtils } from '@src/service/util/LangUtils';

/**
 * Supported types of tenant props.
 *
 * Prop "nope" is always negative and is used for testing.
 */
export type TenantPropsType =
  | 'admin_surveys'
  | 'admin_skills'
  | 'admin_skillLevelClassification'
  | 'admin_emails'
  | 'admin_activities'
  | 'admin_notifications'
  | 'admin_comments'
  | 'admin_workpositions'
  | 'languages'
  | 'courses'
  | 'webinars'
  | 'externalcontents'
  | 'repository'
  | 'usergroups'
  | 'userGroupCourses'
  | 'externaleducationapplications'
  | 'externaleducations'
  | 'dashboard'
  | 'admin_codebooks'
  | 'nope'
  | 'integration_video';

/**
 * Static method for checking if tenant prop is enabled. To be used in non-react code (eg. business stores).
 *
 * @see {resolvePropValue} for implementation details
 */
export const isTenantPropsEnabled = (propName: TenantPropsType | TenantPropsType[], state: any) => {
  return resolvePropValues(propName, PublicTenantConfigurationBusinessStore.selectors.getTenantConfiguration(state ?? StoreService.getStore().getState()));
};

/**
 * Resolve tenant property or array of properties.
 *
 * @see {resolvePropValue} for implementation details
 */
export function resolvePropValues(props: TenantPropsType | TenantPropsType[], tenantConfiguration?: ITenantConfiguration): boolean {
  const propArr = ensureArray(props);

  return (
    propArr
      // resolve value for each prop
      .map((prop) => resolvePropValue(prop, tenantConfiguration))
      // ensure all values are true
      .every((value) => value)
  );
}

/**
 * Resolve tenant property state against apropriate config source.
 *
 * Component is a facade for various tenant configuration sources. Currently, only tenant configuration is supported
 * and supported properties are:
 *  - languages - allow locale change
 *  - webinars - are webinars enabled in app
 *  - courses - are courses enabled in app
 *  - externalcontents - are external contents enabled in app
 *  - repository - are repository enabled in app
 *  - usergroups - are user groups enabled in app
 *  - externaleducationapplications - are external education applications enabled in app
 *  - dashboard - is dashboard enabled in app
 *  - nope - always negative, used for debuging
 * - integration_video - enable integration video (currently Vimeo) support, also disables lecture videos uploaded via /files API
 *
 *  administration links NOTE: (perhaps link it to specific features instead of just hiding it in menu)
 *
 *  - admin_surveys -
 *  - admin_skills -
 * -  admin_skillLevelClassification - are skill levels enabled in app
 *  - admin_emails -
 *  - admin_activities -
 *  - admin_notifications -
 *  - admin_comments -
 *  - admin_workpositions -
 *  - admin_users -
 *  - admin_codebooks -
 */
export function resolvePropValue(propertyName: TenantPropsType, tenantConfiguration?: ITenantConfiguration): boolean {
  // --- administration links
  if ('admin_surveys' === propertyName) {
    return tenantConfiguration?.configuration?.enableSurveys ?? false;
  }
  if ('admin_skills' === propertyName) {
    return tenantConfiguration?.configuration?.enableSkills ?? false;
  }
  if ('admin_skillLevelClassification' === propertyName) {
    return tenantConfiguration?.configuration?.enableSkillLevelClassification ?? false;
  }
  if ('admin_emails' === propertyName) {
    return tenantConfiguration?.configuration?.enableEmails ?? false;
  }
  if ('admin_activities' === propertyName) {
    return tenantConfiguration?.configuration?.enableActivities ?? false;
  }
  if ('admin_notifications' === propertyName) {
    return tenantConfiguration?.configuration?.enableNotifications ?? false;
  }
  if ('admin_comments' === propertyName) {
    return tenantConfiguration?.configuration?.enableComments ?? false;
  }
  if ('admin_workpositions' === propertyName) {
    return tenantConfiguration?.configuration?.enableWorkpositions ?? false;
  }
  if ('admin_codebooks' === propertyName) {
    return tenantConfiguration?.configuration?.enableCodebooks ?? false;
  }

  // --- webinars
  if ('webinars' === propertyName) {
    return tenantConfiguration?.configuration?.enableWebinars ?? false;
  }
  // --- courses
  if ('courses' === propertyName) {
    return tenantConfiguration?.configuration?.enableCourses ?? false;
  }
  // --- languages
  if ('languages' === propertyName) {
    return tenantConfiguration?.configuration?.enableLanguages ?? false;
  }
  // --- external contents
  if ('externalcontents' === propertyName) {
    return tenantConfiguration?.configuration?.enableExternalContents ?? false;
  }
  // --- repository
  if ('repository' === propertyName) {
    return tenantConfiguration?.configuration?.enableRepository ?? false;
  }
  // --- user groups
  if ('usergroups' === propertyName) {
    return tenantConfiguration?.configuration?.enableUserGroups ?? false;
  }
  // --- organization user groups
  if ('userGroupCourses' === propertyName) {
    return tenantConfiguration?.configuration?.enableUserGroupCourses ?? false;
  }
  // --- external education applications
  if ('externaleducationapplications' === propertyName) {
    return tenantConfiguration?.configuration?.enableExternalEducationApplications ?? false;
  }
  // --- external education
  if ('externaleducations' === propertyName) {
    return tenantConfiguration?.configuration?.enableExternalEducations ?? false;
  }
  // --- integration video
  if ('integration_video' === propertyName) {
    return tenantConfiguration?.configuration?.enableIntegrationVideo ?? false;
  }
  // --- dashboard
  if ('dashboard' === propertyName) {
    return tenantConfiguration?.configuration?.enableDashboard ?? false;
  }
  // --- nope - always false value (for testing)
  else if ('nope' === propertyName) {
    return false;
  }
  // fallback
  else {
    throw new LemonError(`Unhandled tenant property ${propertyName}`);
  }
}

// ----- private

function ensureArray<T>(prop: T | T[]): T[] {
  return LangUtils.isArray(prop) ? prop : [prop];
}
