import React, { useCallback, useEffect } from 'react';
import { connect } from 'react-redux';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ExternalEducationTemplateView from '@src/components/externaleducation/ExternalEducationTemplateView';
import { ParticipantRoleEnum } from '@src/model/activity/ActivityParticipant';
import { ExternalEducationTemplateActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { IUserInfo } from '@src/model/user/User';
import activityBusinessStore, { IActivityParticipantCreatePayload, IActivityPhaseCreatePayload } from '@src/service/business/examtemplates/activityBusinessStore';
import ExternalEducationTemplateBusinessStore from '@src/service/business/externaleducations/ExternalEducationTemplateBusinessStore';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

// -- Prop types
// ----------

export interface IExternalEducationTemplateContainerOwnProps {
  externalEducationTemplateId: string;
  openedTab: string;
}
export interface IExternalEducationTemplateContainerStateProps {
  externalEducationTemplate: IExternalEducationTemplate;
  currentUser: IUserInfo;
}
export interface IExternalEducationTemplateContainerDispatchProps {
  fetchExternalEducationTemplate: (id: string) => ITrackableAction;
  updateExternalEducationTemplate: (data: IExternalEducationTemplate) => ITrackableAction;

  updateActivityNextPhase: (id: string, data: IActivityPhaseCreatePayload) => ITrackableAction;
  addActivityParticipant: (id: string, data: IActivityParticipantCreatePayload) => ITrackableAction;
}
type IExternalEducationTemplateContainerProps = IExternalEducationTemplateContainerOwnProps & IExternalEducationTemplateContainerStateProps & IExternalEducationTemplateContainerDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Display external education template information view page */
const ExternalEducationTemplateContainer = (props: IExternalEducationTemplateContainerProps) => {

  useEffect(() => {
    fetchExternalEducationTemplate();
  }, [props.externalEducationTemplateId]);

  const handleActivityPhaseChange = useCallback((nextPhaseId: ExternalEducationTemplateActivityPhaseEnum) => {
    if (props.externalEducationTemplate.activity) {
      props.updateActivityNextPhase(props.externalEducationTemplate.activity.id, { phaseId: nextPhaseId })
        .track()
        .subscribe(
          // success
          () => fetchExternalEducationTemplate()
        );
    }
  }, [props.externalEducationTemplate]);

  const handleAddCoordinator = useCallback(() => {
    const participant: IActivityParticipantCreatePayload = {
      userId: props.currentUser.id,
      participantRole: ParticipantRoleEnum.EVALUATOR,
    };
    if (props.externalEducationTemplate.activity) {
      props.addActivityParticipant(props.externalEducationTemplate.activity.id, participant)
        .track()
        .subscribe((res) => {
          fetchExternalEducationTemplate();
        });
    }
  }, [props.externalEducationTemplate, props.currentUser.id]);

  const handleVerificationSubmit = useCallback((updatedExternalEducationTemplate: IExternalEducationTemplate) => {
    props.updateExternalEducationTemplate(updatedExternalEducationTemplate).track().subscribe(
      () => fetchExternalEducationTemplate()
    );
  }, [props.updateExternalEducationTemplate]);

  const fetchExternalEducationTemplate = () => {
    props.fetchExternalEducationTemplate(props.externalEducationTemplateId);
  };

  return (
    <React.Fragment>
      {props.externalEducationTemplate && <ExternalEducationTemplateView
        currentUser={props.currentUser}
        openedTab={props.openedTab}
        externalEducationTemplate={props.externalEducationTemplate}
        onActivityPhaseChange={handleActivityPhaseChange}
        onCoordinatorAdd={handleAddCoordinator}
        onVerificationSubmit={handleVerificationSubmit}
      />}
    </React.Fragment>
  );
};


// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IExternalEducationTemplateContainerOwnProps): IExternalEducationTemplateContainerStateProps => ({
  externalEducationTemplate: ExternalEducationTemplateBusinessStore.selectors.getExternalEducationTemplate(state),
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IExternalEducationTemplateContainerDispatchProps => ({
  fetchExternalEducationTemplate: (id: string) => createTrackableAction(dispatch(ExternalEducationTemplateBusinessStore.actions.fetchExternalEducationTemplate(id))),
  updateExternalEducationTemplate: (data: IExternalEducationTemplate) => createTrackableAction(dispatch(ExternalEducationTemplateBusinessStore.actions.updateExternalEducationTemplate(data))),
  updateActivityNextPhase: (id: string, data: IActivityPhaseCreatePayload) => createTrackableAction(dispatch(activityBusinessStore.actions.updateActivityPhase(id, data))),
  addActivityParticipant: (id: string, data: IActivityParticipantCreatePayload) => createTrackableAction(dispatch(activityBusinessStore.actions.updateActivityParticipant(id, data))),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<IExternalEducationTemplateContainerOwnProps>(ExternalEducationTemplateContainer as any));
