import { CSSProperties } from 'react';

const DIVIDER = '\n';

const regex = new RegExp(/[A-Z]/g);
const kebabCase = (str: string) => str.replace(regex, (v) => `-${v.toLowerCase()}`);

/**
 * Serialize styles map to regular CSS.
 *
 * Map keys are CSS class names and map values are CSS properties object.
 */
export const serializeStylesMap = (styles: Record<string, CSSProperties>, pretty?: boolean): string => {
  return Object.keys(styles).reduce((accum, name) => {
    const serializedStyles = serializeStyles(styles[name], pretty);

    return `${accum}${pretty ? DIVIDER : ''}${name}{${serializedStyles}}`;
  }, '');
};

/** Serialize styles object to CSS string. */
export const serializeStyles = (style: CSSProperties, pretty?: boolean): string => {
  return Object.keys(style).reduce((accum: string, key: string) => {
    // transform the key from camelCase to kebab-case
    const cssKey = kebabCase(key);

    // remove ' in value
    const cssValue = (style as any)[key].replace("'", '');

    // build the result
    return `${accum}${cssKey}:${cssValue};${pretty ? DIVIDER : ''}`;
  }, '');
};
