import CodedGradeContainer from '@src/components/codebook/CodedGradeContainer';
import React from 'react';

// -- Component
// ----------
const CodedGradePage = () => {

  return (
    <React.Fragment>
      <CodedGradeContainer />
    </React.Fragment>
  );
};

export default CodedGradePage;
