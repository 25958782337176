import { UploadOutlined } from '@ant-design/icons';

import AppContent from '@src/components/common/container/AppContent';
import FileList from '@src/components/common/file/FileList';
import { IListAdditionalCol } from '@src/components/common/list/ListAdditionalCol';
import ListPagination from '@src/components/common/list/ListPagination';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import FileUpload, { IUploadStatus } from '@src/components/common/upload/FileUpload';
import { UserNameView } from '@src/components/common/view/UserNameView';
import { IFile } from '@src/model/file/File';
import { ICollectionData } from '@src/service/business/common/types';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, Col, Row, Spin } from 'antd';
import moment from 'moment';
import React from 'react';

const timeFormat = AppConfigService.getValue('dateTimeFormat.dateWithTime');

// -- Prop types
// ----------

interface IUserDocumentsListViewOwnProps {
  userFileList: ICollectionData<IFile>;
  onPageChange: (page: number, size?: number) => void;
  onUploadFile?: (data: IFile) => void;
  onRemoveFile?: (data: IFile) => void;
}

type IUserDocumentsListViewProps = IUserDocumentsListViewOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

class UserDocumentsListView extends React.Component<IUserDocumentsListViewProps> {
  render = () => {
    return (
      <AppContent
        title={
          <Row justify="end">
            <Col>
              <FileUpload onUploadFile={this.props.onUploadFile} uploadProps={{ multiple: false }}>
                {(status: IUploadStatus) => (
                  <React.Fragment>
                    {status.isUploading && <Spin />}&nbsp;
                    <Button type="primary">
                      <UploadOutlined />
                      &nbsp;{this.props.translate('COMMON.ACTION_ADD')}
                    </Button>
                  </React.Fragment>
                )}
              </FileUpload>
            </Col>
          </Row>
        }
      >
        {this.props.userFileList && <ListPagination page={this.props.userFileList.page} onChange={this.props.onPageChange} />}
        <FileList fileList={this.props.userFileList ? this.props.userFileList.content : []} fileColWidth={12} renderAdditionalCols={this.additionalCols} showActions={true} onRemoveFile={this.props.onRemoveFile} />
        {this.props.userFileList && <ListPagination page={this.props.userFileList.page} onChange={this.props.onPageChange} />}
      </AppContent>
    );
  };

  private additionalCols = (): IListAdditionalCol[] => {
    return [
      {
        key: 'author',
        responsiveWidth: { lg: 6, md: 7 },
        headerTitle: this.props.translate('USER_VIEW.DOCUMENTS_TABLE_AUTHOR_LABEL'),
        content: (file: IFile) => <UserNameView user={file.creator} fallback={this.props.translate('USER_VIEW.USER_DELETED_LABEL')} />,
      },
      {
        key: 'createdAt',
        responsiveWidth: { lg: 6, md: 5 },
        headerTitle: this.props.translate('USER_VIEW.DOCUMENTS_TABLE_TIME_LABEL'),
        content: (file: IFile) => moment(file.addedDateTime).format(timeFormat),
        className: 'timun-userView__fileListCol',
      },
    ];
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<IUserDocumentsListViewOwnProps>(UserDocumentsListView as any);
