import { ActionsObservable, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap, tap } from 'rxjs/operators';

import { IUserInfo } from '@src/model/user/User';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import StoreService from '@src/service/business/StoreService';
import AuthenticatioManager from '@src/service/util/auth/AuthenticationManager.ts';
import AuthenticationManager from '@src/service/util/auth/AuthenticationManager.ts';
import { LangUtils } from '@src/service/util/LangUtils';
import trackAction from '@src/service/util/observable/operators/trackAction';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';
import { TrackingHelper } from '../../util/tracking/tracking';

export interface ILoginPayload {
  redirectUri?: string;
}
export interface ILogoutPayload {
  redirectUri?: string;
}

// -
// -------------------- Selectors

const getCurrentUser = (store: any): IUserInfo => store.currentUser;

const hasUserToken = (): boolean => !!AuthenticatioManager.getToken();

const isUserLoggedIn = (store: any): boolean => store.currentUser != null;

// -
// -------------------- Actions

const Actions = {
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  VALIDATE_RESET_TOKEN: 'VALIDATE_RESET_TOKEN',
  RESET_PASSWORD: 'RESET_PASSWORD',
  REGISTRATION: 'REGISTRATION',
  VALIDATE_ACTIVATION_TOKEN: 'VALIDATE_ACTIVATION_TOKEN',
  CURRENT_USER_TOKEN_STORE: 'USER_TOKEN',
  CURRENT_USER_FETCH: 'CURRENT_USER_FETCH',
  CURRENT_USER_STORE: 'CURRENT_USER_STORE',
  CURRENT_USER_CLEAR: 'CURRENT_USER_CLEAR',
};

const doLogin = (options?: ILoginPayload): IPayloadAction<ILoginPayload> => {
  return {
    type: Actions.LOGIN,
    payload: options || {},
  };
};

const doLogout = (options?: ILogoutPayload): IPayloadAction<ILogoutPayload> => {
  return {
    type: Actions.LOGOUT,
    payload: options || {},
  };
};

const fetchCurrentUser = (): ILemonAction => {
  return {
    type: Actions.CURRENT_USER_FETCH,
  };
};

const clearCurrentUser = (): ILemonAction => {
  return {
    type: Actions.CURRENT_USER_CLEAR,
  };
};

const storeCurrentUser = (user: IUserInfo): IPayloadAction<IUserInfo> => {
  return {
    type: Actions.CURRENT_USER_STORE,
    payload: user,
  };
};

// -
// -------------------- Side-effects

const doLoginEffect = (action$: Observable<IPayloadAction<ILoginPayload>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.LOGIN;
    }),

    // authenticate user aka. do login
    mergeMap((action) => {
      return AuthenticatioManager.login(action.payload).pipe(trackAction(action));
    }),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'LOGIN.ERROR', 'LOGIN.ERROR.GENERAL_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error(error);

      return o;
    })
  );
};

const doLogoutEffect = (action$: ActionsObservable<IPayloadAction<ILogoutPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.LOGOUT;
    }),

    // persist token
    mergeMap((action) => {
      console.log(`Do logout for current user`);

      return of(AuthenticatioManager.logout(action.payload)).pipe(
        // purge store after logout but BEFORE action thunk confirmation because it will do a redirect which can prevent purge from happening
        tap(() => {
          StoreService.getPersistor().purge();
        }),

        trackAction(action)
      );
    }),

    // no actions after this so this prevents errors in redux-observable epic
    ignoreElements(),

    // tslint:disable-next-line: no-identical-functions
    catchError((error: any, o: Observable<any>) => {
      console.error(error);

      return o;
    })
  );
};

const fetchCurrentUserEffect = (action$: ActionsObservable<ILemonAction>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.CURRENT_USER_FETCH;
    }),

    filter((action) => {
      if (!LangUtils.isStringEmpty(AuthenticationManager.getToken())) {
        return true;
      } else {
        console.log('Cannot fetch current user, auth token is empty.');

        // we must complete action thunk manually since this represents error
        TrackingHelper.error(action, 'Auth token is empty');

        return false;
      }
    }),

    // fetch current user
    mergeMap((action) => {
      console.log(`Fetching current user`);

      return EntityApiServiceRegistry.getService('User')
        .fetchMethod('current')
        .pipe(trackAction(action));
    }),

    // store user
    map((user) => {
      return storeCurrentUser(user);
    }),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching current user', error);

      return o;
    })
  );
};

// -
// -------------------- Reducers

/** Dummy login check. Untila proper user is resolved. */
const currentUser = (state: any = null, action: IPayloadAction<IUserInfo>) => {
  if (action.type === Actions.CURRENT_USER_STORE) {
    return action.payload;
  } else if (action.type === Actions.CURRENT_USER_CLEAR) {
    return null;
  }

  return state;
};

// --
// ----- Export store object

const LoginBusinessStore = {
  actions: {
    doLogin,
    doLogout,
    fetchCurrentUser,
    storeCurrentUser,
    clearCurrentUser,
  },

  selectors: {
    isUserLoggedIn,
    getCurrentUser,
    hasUserToken,
  },

  effects: {
    doLoginEffect,
    doLogoutEffect,
    fetchCurrentUserEffect,
  },

  reducers: {
    currentUser,
  },
};

export default LoginBusinessStore;
