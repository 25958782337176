import React from 'react';

import LemonIcon from '@src/components/common/image/LemonIcon';
import { Tooltip } from 'antd';


// -- Prop types
// ----------
export interface IFormItemLabelIconOwnProps {
  label?: string;
  tooltipDescription?: string;
}
type IFormItemLabelIconProps = IFormItemLabelIconOwnProps;

// -- Component
// ----------

/** Display label with info icon and tooltip description */
const FormItemLabelIcon: React.FC<IFormItemLabelIconProps> = (props: IFormItemLabelIconProps) => {

  return (
    <React.Fragment>
      {props.label} &nbsp;
      <Tooltip title={props.tooltipDescription}>
        < LemonIcon name="infoCircle" />
      </Tooltip>
    </React.Fragment>
  );
};

export default FormItemLabelIcon;
