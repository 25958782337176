import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { Form } from '@ant-design/compatible';

import { Col, Row, Select } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import React from 'react';

// -- Const
// ----------
const FormItem = Form.Item;
const Option = Select.Option;

const sortItems = [
  {
    id: 'addedDateTime,desc',
    translationKey: 'COMMON.ACTION_SORT_NEWEST',
  },
  {
    id: 'addedDateTime,asc',
    translationKey: 'COMMON.ACTION_SORT_OLDEST',
  },
];

// -- Prop types
// ----------
export interface INoteListFilterOwnProps {
  onSortChange: (sort: string) => any;
}

// -- State types
// ----------
type INoteListFilterProps = INoteListFilterOwnProps & IWithLocalizeOwnProps & FormComponentProps;

export interface INoteListFilterState { }

// -- Component
// ----------

/** Component for sorting note list */
class NoteListFilter extends React.Component<INoteListFilterProps, INoteListFilterState> {
  render() {
    return (
      <React.Fragment>
        <Row>
          <Col span={6}>
            <FormItem label={this.props.translate('NOTE_LIST_FILTER.SORT_BY_DATE_ADDED_LABEL')} colon={false}>
              <Select defaultValue={sortItems[0].id} onChange={this.props.onSortChange}>
                {sortItems.map((item) => (
                  <Option key={item.id} value={item.id}>
                    {this.props.translate(item.translationKey)}
                  </Option>
                ))}
              </Select>
            </FormItem>
          </Col>
        </Row >
      </React.Fragment >
    );
  }
}

// -- HOCs and exports
// ----------

export default withLocalize<INoteListFilterOwnProps>(NoteListFilter as any);
