import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ExecutionMethodEnum } from '@src/model/externaleducationinstance/ExternalEducationInstance';
import { Select } from 'antd';

// -- Const
// ----------
const { Option } = Select;

// -- Prop types
// ----------

export interface IExecutionMethodSelectorOwnProps {
  value?: ExecutionMethodEnum;
  onChange?: (value: ExecutionMethodEnum) => void;
  disabled?: boolean;
  allowClear?: boolean;
}
type IExecutionMethodSelectorProps = IExecutionMethodSelectorOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

const ExecutionMethodSelector: React.FC<IExecutionMethodSelectorProps> = (props: IExecutionMethodSelectorProps) => {
  const executionMethodOptions = Object.keys(ExecutionMethodEnum).map((item: any) => (
    <Option value={ExecutionMethodEnum[item as keyof typeof ExecutionMethodEnum]} key={item} >
      {props.translate(`EXECUTION_METHOD.SELECT_OPTION.${item}_LABEL`)}
    </Option>
  ));
  return (
    <Select disabled={props.disabled} allowClear={props.allowClear} onChange={props.onChange} value={props.value}>
      {executionMethodOptions}
    </Select>
  );
};

export default withLocalize<IExecutionMethodSelectorOwnProps>(ExecutionMethodSelector as any);
