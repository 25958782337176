import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';
import AdminNotificationListContainer from "@src/components/notification/list/AdminNotificationListContainer";
import AllowedUserRoles from "@src/components/common/role/AllowedUserRoles";


// -- Prop types
// ----------
export interface IAdminNotificationPagePublicProps { }
interface IAdminNotificationPageStateProps {
  currentUser: IUserInfo;
}
type IAdminNotificationPageProps = IAdminNotificationPagePublicProps & IAdminNotificationPageStateProps & WithRouterProps & IWithRolesOwnProps;

// -- Component
// ----------
const AdminNotificationPage = (props: IAdminNotificationPageProps) => {
  return (
    <React.Fragment>
      <AllowedUserRoles roles={[UserRoleEnum.ORGANIZATION_ADMIN]} fallback="">
        <AdminNotificationListContainer />
      </AllowedUserRoles>
    </React.Fragment>
  );
};

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IAdminNotificationPagePublicProps): IAdminNotificationPageStateProps => ({
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
});

export default withRouter(connect<IAdminNotificationPageStateProps, any, IAdminNotificationPagePublicProps>(mapStateToProps)(withRoles(AdminNotificationPage)));