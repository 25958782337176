import ActivityShortListContainer from '@src/components/activity/ActivityShortListContainer';
import AppContent from '@src/components/common/container/AppContent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ReminderCalendarContainer from '@src/components/reminder/calendar/ReminderCalendarContainer';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import UserListBusinessStore, { IUserListFilter } from '@src/service/business/user/userListBusinessStore';
import { Col, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

// -- Prop types
// ----------

export interface IAdminDashboardContainerOwnProps {
}

export interface IAdminDashboardContainerStateProps {
  userListFilter: IUserListFilter;
  currentUser: IUserInfo;
}

export interface IAdminDashboardContainerDispatchProps {
  clearFilter: () => any;
  storeFilter: (listFilter: IUserListFilter) => any;
}

type IAdminDashboardContainerProps = IAdminDashboardContainerOwnProps & IAdminDashboardContainerStateProps & IAdminDashboardContainerDispatchProps & IWithLocalizeOwnProps;

interface IAdminDashboardContainerState {
}

// -- Component
// ----------

/** Dashboard for Admin */
class AdminDashboardContainer extends React.Component<IAdminDashboardContainerProps, IAdminDashboardContainerState> {
  constructor(props: IAdminDashboardContainerProps) {
    super(props);

    this.state = {};
  }

  componentDidMount = () => {
    this.onFilterChange({ ...this.props.userListFilter, role: UserRoleEnum.TRAINEE });
  };

  componentWillUnmount = () => {
    this.props.clearFilter();
  };

  render = () => {
    return (
      <AppContent>
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <ActivityShortListContainer/>
          </Col>
          <Col xs={24} md={12}>
            <div className="panel">
              <AppContent title={this.props.translate('REMINDER_CALENDAR.TITLE_LABEL')} level={3}>
                <ReminderCalendarContainer currentUser={this.props.currentUser}/>
              </AppContent>
            </div>
          </Col>
        </Row>
      </AppContent>
    );
  };

  onFilterChange = (listFilter: IUserListFilter) => {
    this.setState({ page: 0 });
    this.props.storeFilter(listFilter);
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IAdminDashboardContainerStateProps => ({
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
  userListFilter: UserListBusinessStore.selectors.getUserListFilter(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IAdminDashboardContainerDispatchProps => ({
  clearFilter: () => dispatch(UserListBusinessStore.actions.clearUserListFilter()),
  storeFilter: (listFilter: IUserListFilter) => dispatch(UserListBusinessStore.actions.storeUserListFilter(listFilter)),
});

export default connect<IAdminDashboardContainerStateProps, IAdminDashboardContainerDispatchProps, IAdminDashboardContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(AdminDashboardContainer as any));
