import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import DialogPanel from '@src/components/common/panel/DialogPanel';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/common/EducationApplicationHelperUtils';
import { IFileListsByType } from '@src/components/externalEducationApplication/common/types';
import ExternalEducationApplicationForm from '@src/components/externalEducationApplication/form/ExternalEducationApplicationForm';
import CoordinatorPickerModalContainer from '@src/components/externalEducationApplication/participant/CoordinatorPickerModalContainer';
import ExternalEducationApplicationInfoView from '@src/components/externalEducationApplication/view/ExternalEducationApplicationInfoView';
import ExternalEducationApplicationCoordinatorApprovalView from '@src/components/externalEducationApplication/view/phases/ExternalEducationApplicationCoordinatorApprovalView';
import ExternalEducationApplicationCoordinatorCompletedView from '@src/components/externalEducationApplication/view/phases/ExternalEducationApplicationCoordinatorCompletedView';
import ExternalEducationApplicationCoordinatorDurationView from '@src/components/externalEducationApplication/view/phases/ExternalEducationApplicationCoordinatorDurationView';
import ExternalEducationApplicationTraineeApprovalView from '@src/components/externalEducationApplication/view/phases/ExternalEducationApplicationTraineeApprovalView';
import ExternalEducationApplicationTraineeCompletedView from '@src/components/externalEducationApplication/view/phases/ExternalEducationApplicationTraineeCompletedView';
import ExternalEducationApplicationTraineeDurationView from '@src/components/externalEducationApplication/view/phases/ExternalEducationApplicationTraineeDurationView';
import { IActivityParticipant } from '@src/model/activity/ActivityParticipant';
import { ActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { ITimelineActivity } from '@src/model/activity/TimelineActivity';

import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { IExternalEducationApplicationPaymentInfo } from '@src/model/externalEducationApplication/ExternalEducationApplicationPaymentInfo';
import { IFile } from '@src/model/file/File';

import { IExternalEducationApplicationAgreementCreateData, IExternalEducationApplicationAgreementUpdateData } from '@src/components/externalEducationApplication/form/ExternalEducationApplicationAgreementForm';
import EducationApplicationCoordinatorHelpPanel from '@src/components/externalEducationApplication/view/section/EducationApplicationCoordinatorHelpPanel';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { ICollectionData } from '@src/service/business/common/types';
import { IExternalEducationExpenseCreatePayload } from '@src/service/business/externalEducationExpense/ExternalEducationExpenseBusinessStore';
import { Row, Steps } from 'antd';
import React from 'react';

const Step = Steps.Step;

// -- Prop types
// ----------
interface IExternalEducationApplicationViewOwnProps {
  externalEducationApplication: IExternalEducationApplication;
  applicationFiles: IFileListsByType;
  currentUser: IUserInfo;
  traineeUser?: IUserInfo;
  currentCoordinator?: IActivityParticipant;
  timeline: ICollectionData<ITimelineActivity>;
  termsOfAgreement?: string;
  onUpdateExternalEducationApplication?: (data: IExternalEducationApplication) => void;
  onExternalEducationApplicationAgreementSubmit?: (data: IExternalEducationApplicationAgreementCreateData) => void;
  onExternalEducationApplicationAgreementUpdate?: (data: IExternalEducationApplicationAgreementUpdateData) => void;
  onSubmit?: (data: IExternalEducationApplication) => void;
  onUpdateAndChangePhase?: (newPhase: ActivityPhaseEnum, data: IExternalEducationApplication) => void;
  onChangePhase?: (newPhase: ActivityPhaseEnum, data?: IExternalEducationApplication) => void;
  onCancel?: () => void;
  onFileUpload?: (file: IFile[]) => void;
  onFileRemove?: (file: IFile[]) => void;
  onCoordinatorAdd?: (userId: string) => void;
  onCreateExpense?: (data: IExternalEducationExpenseCreatePayload) => void;
  onChangeCoordinator?: (userId: string) => void;
  onCreateNote?: () => void;
  onUpdatePaymentInfo?: (data: IExternalEducationApplicationPaymentInfo) => void;
  onFinishConsultation?: () => void;
}
type IExternalEducationApplicationViewProps = IExternalEducationApplicationViewOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

interface IExternalEducationApplicationViewState {
  externalEducationApplication?: IExternalEducationApplication;
  currentStep?: number;
}

// -- Component
// ----------

class ExternalEducationApplicationView extends React.Component<IExternalEducationApplicationViewProps, IExternalEducationApplicationViewState> {
  state: IExternalEducationApplicationViewState = {};

  componentDidMount = () => {
    if (this.props.externalEducationApplication) {
      this.handleChangeApplicationData();
    }
  };

  componentDidUpdate = (prevProps: IExternalEducationApplicationViewProps, prevState: IExternalEducationApplicationViewState) => {
    if (this.props.externalEducationApplication !== prevProps.externalEducationApplication) {
      this.handleChangeApplicationData();
    }
  };

  isApplicationInPhases = (phases: ActivityPhaseEnum[]): boolean => {
    return EducationApplicationHelperUtils.isInPhases(phases, this.props.externalEducationApplication.activity);
  };

  handleChangePhase = (phase: ActivityPhaseEnum, saveExternalEducationApplication: boolean = false) => {
    if (saveExternalEducationApplication && this.props.onUpdateAndChangePhase && this.state.externalEducationApplication) {
      this.props.onUpdateAndChangePhase(phase, this.state.externalEducationApplication);
    } else if (this.props.onChangePhase) {
      this.props.onChangePhase(phase);
    }
  };

  handleCoordinatorAdd = () => {
    if (this.props.onCoordinatorAdd) {
      this.props.onCoordinatorAdd(this.props.currentUser.id);
    }
  };

  render() {
    const isCurrentUserTrainee = this.props.isInRoles([UserRoleEnum.TRAINEE]);
    const isCurrentUserCoordinator = this.props.isInRoles([UserRoleEnum.COORDINATOR]);
    const isCurrentUserAdmin = this.props.isInRoles([UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN]);

    return this.state.externalEducationApplication ? (
      <React.Fragment>
        <Row justify="space-between">
          {this.props.currentCoordinator && (isCurrentUserAdmin || isCurrentUserCoordinator) && <HeaderTitle icon={null} title={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.VIEW.EVALUATOR_TITLE', { name: `${this.props.currentCoordinator.firstName} ${this.props.currentCoordinator.lastName}` })} />}
          {isCurrentUserAdmin && this.props.onChangeCoordinator && <CoordinatorPickerModalContainer currentCoordinator={this.props.currentCoordinator} onChangeCoordinator={this.props.onChangeCoordinator} />}
        </Row>

        {/* ---------- Step 1 ---------- */}
        {this.state.currentStep === 0 && (
          <React.Fragment>
            {isCurrentUserCoordinator && this.props.externalEducationApplication.consultation != null && (
              <EducationApplicationCoordinatorHelpPanel externalEducationApplication={this.props.externalEducationApplication} onCoordinatorAdd={this.handleCoordinatorAdd} onFinishConsultation={this.props.onFinishConsultation} />
            )}
            <ExternalEducationApplicationForm
              externalEducationApplication={this.props.externalEducationApplication}
              applicationFiles={this.props.applicationFiles}
              traineeUser={this.props.traineeUser}
              onUpdate={this.props.onUpdateExternalEducationApplication}
              onSubmit={this.props.onSubmit}
              onFileUpload={this.props.onFileUpload}
              onFileRemove={this.props.onFileRemove}
              termsOfAgreement={this.props.termsOfAgreement}
            />
          </React.Fragment>
        )}

        {this.state.currentStep != null && this.state.currentStep !== 0 && (
          <DialogPanel className="timun-externalEducationApplication__stepsPanel">
            <Steps current={this.state.currentStep} responsive={true}>
              <Step title={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.VIEW.STEP_ONE_TITLE')} data-test-id="timun-externalEducationApplication__stepsView_1" />
              <Step title={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.VIEW.STEP_TWO_TITLE')} data-test-id="timun-externalEducationApplication__stepsView_2" />
              <Step title={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.VIEW.STEP_THREE_TITLE')} data-test-id="timun-externalEducationApplication__stepsView_3" />
              <Step title={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.VIEW.STEP_FOUR_TITLE')} data-test-id="timun-externalEducationApplication__stepsView_3" />
            </Steps>

            <br />

            {/* ---------- Step 2 ---------- */}
            {this.state.currentStep === 1 && (
              <React.Fragment>
                {isCurrentUserTrainee && (
                  <React.Fragment>
                    <ExternalEducationApplicationTraineeApprovalView
                      applicationFiles={this.props.applicationFiles}
                      externalEducationApplication={this.state.externalEducationApplication}
                      onUpdate={this.setApplication}
                      onChangePhase={this.handleChangePhase}
                      onFileUpload={this.props.onFileUpload}
                      onFileRemove={this.props.onFileRemove}
                      onUpdatePaymentInfo={this.props.onUpdatePaymentInfo}
                    />
                  </React.Fragment>
                )}

                {isCurrentUserCoordinator && (
                  <React.Fragment>
                    <ExternalEducationApplicationCoordinatorApprovalView
                      applicationFiles={this.props.applicationFiles}
                      externalEducationApplication={this.state.externalEducationApplication}
                      onUpdateExternalEducationApplication={this.setApplication}
                      onExternalEducationApplicationAgreementSubmit={this.props.onExternalEducationApplicationAgreementSubmit}
                      onExternalEducationApplicationAgreementUpdate={this.props.onExternalEducationApplicationAgreementUpdate}
                      onChangePhase={this.handleChangePhase}
                      onCreateExpense={this.props.onCreateExpense}
                      onCoordinatorAdd={this.handleCoordinatorAdd}
                      onFileUpload={this.props.onFileUpload}
                      onFileRemove={this.props.onFileRemove}
                      onUpdatePaymentInfo={this.props.onUpdatePaymentInfo}
                    />
                  </React.Fragment>
                )}
              </React.Fragment>
            )}

            {/* ---------- Step 3 ---------- */}
            {this.state.currentStep === 2 && (
              <React.Fragment>
                {isCurrentUserTrainee && (
                  <React.Fragment>
                    <ExternalEducationApplicationTraineeDurationView
                      applicationFiles={this.props.applicationFiles}
                      externalEducationApplication={this.state.externalEducationApplication}
                      onUpdate={this.setApplication}
                      onChangePhase={this.handleChangePhase}
                      onFileUpload={this.props.onFileUpload}
                      onFileRemove={this.props.onFileRemove}
                    />
                  </React.Fragment>
                )}

                {isCurrentUserCoordinator && (
                  <React.Fragment>
                    <ExternalEducationApplicationCoordinatorDurationView
                      applicationFiles={this.props.applicationFiles}
                      externalEducationApplication={this.state.externalEducationApplication}
                      onUpdate={this.setApplication}
                      onCreateExpense={this.props.onCreateExpense}
                      onChangePhase={this.handleChangePhase}
                      onFileUpload={this.props.onFileUpload}
                      onFileRemove={this.props.onFileRemove}
                    />
                  </React.Fragment>
                )}
              </React.Fragment>
            )}

            {/* ---------- Step 4 ---------- */}
            {this.state.currentStep === 3 && (
              <React.Fragment>
                {isCurrentUserTrainee && (
                  <React.Fragment>
                    <ExternalEducationApplicationTraineeCompletedView
                      applicationFiles={this.props.applicationFiles}
                      externalEducationApplication={this.state.externalEducationApplication}
                      onUpdate={this.setApplication}
                      onChangePhase={this.handleChangePhase}
                      onFileUpload={this.props.onFileUpload}
                      onFileRemove={this.props.onFileRemove}
                    />
                  </React.Fragment>
                )}

                {isCurrentUserCoordinator && (
                  <React.Fragment>
                    <ExternalEducationApplicationCoordinatorCompletedView
                      applicationFiles={this.props.applicationFiles}
                      externalEducationApplication={this.state.externalEducationApplication}
                      onUpdate={this.setApplication}
                      onChangePhase={this.handleChangePhase}
                      onFileUpload={this.props.onFileUpload}
                      onFileRemove={this.props.onFileRemove}
                    />
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </DialogPanel>
        )}

        {/* ---------- Common application data ---------- */}
        {this.state.currentStep != null && this.state.currentStep !== 0 && (
          <ExternalEducationApplicationInfoView externalEducationApplication={this.state.externalEducationApplication} applicationFiles={this.props.applicationFiles} traineeUser={this.props.traineeUser} timeline={this.props.timeline?.content} onCreateNote={this.props.onCreateNote} />
        )}
      </React.Fragment>
    ) : null;
  }

  private setApplication = (externalEducationApplication?: IExternalEducationApplication) => {
    this.setState({ externalEducationApplication });
  };

  private setStep = () => {
    let step: number | undefined = this.state.currentStep;

    if (this.isApplicationInPhases([ActivityPhaseEnum.EXTERNAL_EDUCATION_APPLICATION_CREATION, ActivityPhaseEnum.RETURNED_TO_USER])) {
      step = 0;
    } else if (
      this.isApplicationInPhases([ActivityPhaseEnum.SUBMITTED, ActivityPhaseEnum.DENIED, ActivityPhaseEnum.USER_CANCELLED, ActivityPhaseEnum.AWAITING_APPROVAL, ActivityPhaseEnum.IN_PROCESS, ActivityPhaseEnum.CONTRACT_GENERATION, ActivityPhaseEnum.AWAITING_SIGNED_CONTRACT, ActivityPhaseEnum.AWAITING_PAYMENT])
    ) {
      step = 1;
    } else if (this.isApplicationInPhases([ActivityPhaseEnum.EDUCATION_IN_PROGRESS])) {
      step = 2;
    } else if (this.isApplicationInPhases([ActivityPhaseEnum.CONTRACT_TERMINATED, ActivityPhaseEnum.EDUCATION_COMPLETED])) {
      step = 3;
    }

    this.setState({ currentStep: step });
  };

  private handleChangeApplicationData = () => {
    this.setStep();
    this.setApplication(this.props.externalEducationApplication);
  };
}

// -- HOCs and exports
// ----------

export default withRoles(withLocalize<IExternalEducationApplicationViewOwnProps>(ExternalEducationApplicationView as any));
