import { Form } from '@ant-design/compatible';

import { FormComponentProps } from '@ant-design/compatible/lib/form';
import UserGroupDataPicker from '@src/components/common/datapicker/UserGroupDataPicker';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ICourseListFilter } from '@src/service/business/courses/courseListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, Col, DatePicker, Row } from 'antd';
import moment, { Moment } from 'moment';
import React from 'react';

// --
// ----- Prop types
const FormItem = Form.Item;

const dateFormat = AppConfigService.getValue('dateTimeFormat.dateWithYear');
const apiDateFormat = AppConfigService.getValue('dateTimeFormat.backendDate');

interface ICoursesReportListFilterOwnProps {
  value: ICourseListFilter;
  onChange?: (listFilter: ICourseListFilter) => void;
}

type ICoursesReportListFilterProps = ICoursesReportListFilterOwnProps & IWithLocalizeOwnProps & FormComponentProps;

// --
// ----- Courses report list filter
class CoursesReportListFilter extends React.Component<ICoursesReportListFilterProps> {
  render = () => {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form layout="vertical" onSubmit={this.handleSubmit}>
        <Row gutter={16}>
          <Col xs={24} sm={12} md={6}>
            <FormItem className="" label={this.props.translate('COURSE_LIST_FILTER.TRAINEE_STATS_FROM_LABEL')}>
              {getFieldDecorator('traineeStatsFrom', { initialValue: this.props.value?.traineeStatsFrom != null ? moment(this.props.value?.traineeStatsFrom) : undefined })(
                <DatePicker allowClear={true} disabledDate={this.disabledStartDate} format={dateFormat} className="full-width" data-test-id="timun-courseReportListFilter__traineeStatsFrom"/>,
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <FormItem className="" label={this.props.translate('COURSE_LIST_FILTER.TRAINEE_STATS_TO_LABEL')}>
              {getFieldDecorator('traineeStatsTo', { initialValue: this.props.value?.traineeStatsTo != null ? moment(this.props.value?.traineeStatsTo) : undefined })(
                <DatePicker allowClear={true} disabledDate={this.disabledEndDate} format={dateFormat} className="full-width"
                            data-test-id="timun-courseReportListFilter__traineeStatsTo"
                />,
              )}
            </FormItem>
          </Col>
          <Col xs={24} sm={12} md={6}>
            <FormItem label={this.props.translate('COURSE_LIST_FILTER.USER_GROUPS_LABEL')}>
              {getFieldDecorator('userGroups', { initialValue: this.props.value?.userGroups })(
                <UserGroupDataPicker/>,
              )}
            </FormItem>
          </Col>
        </Row>

        <Row>
          <Col className="timun-wrapper__gutterBox--vertical">
            <Button type="primary" htmlType="submit" data-test-id="timun-courseReportListFilter__submitButton">
              {this.props.translate('COMMON.ACTION_SEARCH')}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  private disabledStartDate = (startValue?: Moment) => {
    const endValue = this.props.form.getFieldValue('traineeStatsTo');
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  private disabledEndDate = (endValue?: Moment) => {
    const startValue = this.props.form.getFieldValue('traineeStatsFrom');
    if (!endValue || !startValue) {
      return false;
    }

    return endValue.valueOf() <= startValue.valueOf();
  };

  private handleSubmit = (e: any) => {
    e.preventDefault();
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        const listFilter: ICourseListFilter = {
          traineeStatsFrom: values.traineeStatsFrom
            ? moment(values.traineeStatsFrom)
              .startOf('day')
              .format(apiDateFormat)
              .toString()
            : undefined,
          traineeStatsTo: values.traineeStatsTo
            ? moment(values.traineeStatsTo)
              .endOf('day')
              .format(apiDateFormat)
              .toString()
            : undefined,
          userGroups: values.userGroups,
        };

        this.props.onChange?.(listFilter);
      }
    });
  };
}

const CoursesReportListFilterWrapped = Form.create()(CoursesReportListFilter);

export default withLocalize<ICoursesReportListFilterOwnProps>(CoursesReportListFilterWrapped as any);
