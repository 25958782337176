import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ExternalEducationApplicationStatisticsView from '@src/components/externalEducationApplication/statistics/ExternalEducationApplicationStatisticsView';
import { IExternalEducationApplicationStatistics } from '@src/model/externalEducationApplication/ExternalEducationApplicationStatistics';
import ExternalEducationApplicationBusinessStore, { IExternalEducationApplicationListFilter } from '@src/service/business/externalEducationApplication/ExternalEducationApplicationBusinessStore';
import { Button } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

// -- Prop types
// ----------
interface IExternalEducationApplicationStatisticsContainerOwnProps { }

interface IExternalEducationApplicationStatisticsContainerStateProps {
  educationApplicationStatistics: IExternalEducationApplicationStatistics;
  educationApplicationListFilter: IExternalEducationApplicationListFilter;
}

interface IExternalEducationApplicationStatisticsContainerDispatchProps {
  fetchEducationApplicationStatistics: (params: IExternalEducationApplicationListFilter) => void;
}

type IExternalEducationApplicationStatisticsContainerProps = IExternalEducationApplicationStatisticsContainerOwnProps & IExternalEducationApplicationStatisticsContainerStateProps & IWithLocalizeOwnProps & IExternalEducationApplicationStatisticsContainerDispatchProps;

interface IExternalEducationApplicationStatisticsContainerState {
  isExternalEducationApplicationStatisticsVisibile: boolean;
}

// -- Component
// ----------
class ExternalEducationApplicationStatisticsContainer extends React.Component<IExternalEducationApplicationStatisticsContainerProps, IExternalEducationApplicationStatisticsContainerState> {
  state: IExternalEducationApplicationStatisticsContainerState = {
    isExternalEducationApplicationStatisticsVisibile: false,
  };

  componentDidMount = () => {
    this.updateStatistics();
  };

  componentDidUpdate = (prevProps: IExternalEducationApplicationStatisticsContainerProps, prevState: IExternalEducationApplicationStatisticsContainerState) => {
    if (prevProps.educationApplicationListFilter !== this.props.educationApplicationListFilter) {
      this.updateStatistics();
    }
  };

  render = () => {
    return (
      <React.Fragment>
        <div className="timun-wrapper__gutterBox--vertical">
          <Button onClick={this.toggleStatistics} data-test-id="timun-externalEducationStatisticsView__toggleStatisticsButton">{this.props.translate('EXTERNAL_EDUCATION_APPLICATION_STATISTICS.VIEW_STATISTICS_BUTTON_LABEL')}</Button>
        </div>

        {this.state.isExternalEducationApplicationStatisticsVisibile && <ExternalEducationApplicationStatisticsView applicationListFilter={this.props.educationApplicationListFilter} educationApplicationStatistics={this.props.educationApplicationStatistics} onCancel={this.toggleStatistics} />}
      </React.Fragment>
    );
  };

  updateStatistics = () => {
    this.props.fetchEducationApplicationStatistics(this.props.educationApplicationListFilter ?? {});
  };

  toggleStatistics = () => {
    this.setState({ isExternalEducationApplicationStatisticsVisibile: !this.state.isExternalEducationApplicationStatisticsVisibile });
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IExternalEducationApplicationStatisticsContainerStateProps => ({
  educationApplicationStatistics: ExternalEducationApplicationBusinessStore.selectors.getExternalEducationApplicationStatistics(state),
  educationApplicationListFilter: ExternalEducationApplicationBusinessStore.selectors.getExternalEducationApplicationListFilter(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IExternalEducationApplicationStatisticsContainerDispatchProps => ({
  fetchEducationApplicationStatistics: (params: IExternalEducationApplicationListFilter) => dispatch(ExternalEducationApplicationBusinessStore.actions.fetchExternalEducationApplicationStatistics(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<IExternalEducationApplicationStatisticsContainerOwnProps>(ExternalEducationApplicationStatisticsContainer as any));
