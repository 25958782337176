import { ILemonApplicationIconSet } from '@src/service/common/icon/LemonApplicationIconSet';
import LemonError from '@src/service/common/LemonError';
import { IconResolver } from '@src/service/util/icon/IconResolver';

// resolver instance
let resolverInstance: any;

/** Creates singleton instance of icon resolver.  */
export class LemonApplicationIconResolver extends IconResolver<ILemonApplicationIconSet> {
  /** Initialize application icon set resolver. */
  static initialize<T>(iconSet: ILemonApplicationIconSet) {
    resolverInstance = new IconResolver<ILemonApplicationIconSet>(iconSet);
  }

  /** Returns application icon set resolver instance. Throws error if resolver has not been initialized. */
  static instance<T>(): IconResolver<ILemonApplicationIconSet> {
    if (resolverInstance == null) {
      throw new LemonError('Lemon application icon resolver not initialized yet.');
    }

    return resolverInstance;
  }
}
