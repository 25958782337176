import { LeftOutlined } from '@ant-design/icons';

import AppContent from '@src/components/common/container/AppContent';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import RouterNavigationPrompt from '@src/components/common/route/prompt/RouterNavigationPrompt';
import { FileUploadHelper } from '@src/components/common/upload/FileUploadHelper';
import UserInterfaceUtils from '@src/components/common/util/UserInterfaceUtils';
import CourseHelperUtils from '@src/components/course/common/CourseHelperUtils';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import RelatedObjects from '@src/components/course/RelatedObjects';
import CourseLectureContentUpdateForm from '@src/components/course/update/CourseLectureContentUpdateForm';
import CourseUpdateForm from '@src/components/course/update/CourseUpdateForm';
import CourseViewLectureContent from '@src/components/course/view/CourseViewLectureContent';
import CourseViewSider from '@src/components/course/view/CourseViewSider';
import TagContainer from '@src/components/tag/TagContainer';
import NoteListViewContainer from '@src/components/user/view/NoteListViewContainer';
import { CommentObjectTypeEnum } from '@src/model/comment/CommentObjectType';
import { CourseStatusEnum, ICourse } from '@src/model/course/Course';
import { ICourseGroup } from '@src/model/course/CourseGroup';
import { ICourseQuiz } from '@src/model/course/CourseQuiz';
import { ILecture, ILectureWithContent } from '@src/model/course/Lecture';
import { ICourseLectureGroup, ICourseLectureListElement } from '@src/model/course/LectureGroup';
import { IFile } from '@src/model/file/File';
import { TagItemTypeEnum } from '@src/model/tag/TagItemType';
import { NoteObjectTypeEnum } from '@src/model/user/Note';
import { UserRoleEnum } from '@src/model/user/UserRole';
import CollectionBusinessStore from '@src/service/business/common/collectionBusinessStore';
import { ICollectionData, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import courseUpdateBusinessStore, { ICourseUpdate, ILectureCreatePayload, ILectureGroupCreatePayload } from '@src/service/business/courses/courseUpdateBusinessStore';
import { ICourseLectureListFilter } from '@src/service/business/courses/courseViewBusinessStore';
import examTemplateBusinessStore, { IExamTemplateCreatePayload } from '@src/service/business/examtemplates/examTemplateBusinessStore';
import { createActionThunk, IActionThunkMap } from '@src/service/util/action/thunk';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { ITrackableEvent } from '@src/service/util/event/trackEvent';
import UrlBuilderFactory from '@src/service/util/UrlBuilderFactory';
import { Layout, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';
import { Dispatch } from 'redux';
import { map, mergeMap } from 'rxjs/operators';

const { Header, Sider, Content } = Layout;

// -- Prop types
// ----------

export interface ICourseUpdateContainerOwnProps {
  courseId?: string;
  lectureId: string;
}

export interface ICourseUpdateContainerStateProps {
  courseGroups: ICourseGroup[];
  course: ICourse;
  courseLectureList: ICourseLectureListElement[];
  lecture: ILectureWithContent;
  lectureFileList: ICollectionData<IFile>;
}

export interface ICourseUpdateContainerDispatchProps {
  fetchCourseContent: (courseId: string) => void;
  clearCourseContentData: () => void;
  updateCourse: (data: ICourseUpdate, callback: IActionThunkMap) => void;
  deleteCourse: (id: string, callback: IActionThunkMap) => void;
  uploadCourseCover: (id: string, data: IFile, thunkMap: IActionThunkMap) => void;

  fetchCourseLectureList: (courseId: string, listFilter: ICourseLectureListFilter) => void;
  clearCourseLectureList: () => void;

  createLectureGroup: (data: ILectureGroupCreatePayload, sourceEvent?: ITrackableEvent<string>) => ITrackableAction;
  updateLectureGroup: (data: ICourseLectureGroup, callback: IActionThunkMap) => void;
  deleteLectureGroup: (id: string, callback: IActionThunkMap) => void;

  fetchLectureContent: (lectureId: string) => ITrackableAction;
  clearCourseLectureContentData: () => void;
  createLecture: (data: ILectureCreatePayload, callback: IActionThunkMap) => void;
  updateLecture: (data: ILecture, callback: IActionThunkMap) => void;
  deleteLecture: (id: string, callback: IActionThunkMap) => void;
  uploadLectureVideo: (id: string, data: IFile) => ITrackableAction;
  removeLectureVideo: (id: string) => ITrackableAction;

  fetchLectureFileList: (lectureId: string) => any;
  clearLectureFileList: () => void;
  addLectureFile: (lectureId: string, data: IFile[]) => ITrackableAction<IFile[]>;
  removeLectureFile: (lectureId: string, data: IFile[], callback: IActionThunkMap) => void;

  createExamTemplate: (data: IExamTemplateCreatePayload) => ITrackableAction;
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
}

type ICourseUpdateContainerProps = ICourseUpdateContainerOwnProps & ICourseUpdateContainerStateProps & ICourseUpdateContainerDispatchProps & IWithLocalizeOwnProps & WithRouterProps & IWithRolesOwnProps;

interface ICourseUpdateContainerState {
  editing: boolean;
  deleteAction: boolean;
}

// -- Component
// ----------

/** container for the display&edit of a course and lectures inside */
class CourseUpdateContainer extends React.Component<ICourseUpdateContainerProps, ICourseUpdateContainerState> {
  state = {
    editing: false,
    deleteAction: false,
  };

  componentDidMount = () => {
    // initial list update
    if (this.props.courseId) {
      this.fetchCourse();
      this.updateCourseLectureList();
      if (CourseHelperUtils.isLecture(this.props.lectureId)) {
        this.updateLectureContent();
        this.fetchLectureFileList();
      }
    } else if (this.props.lectureId) {
      if (CourseHelperUtils.isLecture(this.props.lectureId)) {
        this.fetchLectureCourse();
        this.fetchLectureFileList();
      }
    } else {
      console.warn('Bad route to course/lecture');
    }
  };

  componentDidUpdate = (prevProps: ICourseUpdateContainerProps) => {
    if (this.props.courseId && this.props.courseId !== prevProps.courseId) {
      this.fetchCourse();
      this.updateCourseLectureList();
      this.updateLectureContent();
    }

    if (this.props.lectureId && this.props.lectureId !== prevProps.lectureId) {
      if (CourseHelperUtils.isLecture(this.props.lectureId)) {
        this.updateLectureContent();
        this.fetchLectureFileList();
      }
      if (this.state.editing) {
        this.setEditing(false);
      }
      UserInterfaceUtils.scrollToTop();
    }
  };

  componentWillUnmount = () => {
    this.props.clearCourseContentData();
    this.props.clearCourseLectureContentData();
    this.props.clearCourseLectureList();
    this.props.clearLectureFileList();
  };

  render = () => {
    const canDeleteCourse: boolean = this.props.allowedRoles([UserRoleEnum.SUPERADMIN]); // I have become death, the destroyer of courses.
    const canEditStructure: boolean =
      (this.props.allowedRoles([UserRoleEnum.COURSE_CREATOR]) && CourseHelperUtils.isCourseInStatus(this.props.course, [CourseStatusEnum.IN_CREATION])) ||
      (this.props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]) && CourseHelperUtils.isCourseInStatus(this.props.course, [CourseStatusEnum.IN_CREATION, CourseStatusEnum.CREATION_FINISHED])) ||
      this.props.allowedRoles([UserRoleEnum.SUPERADMIN]);

    const canSeeExamTemplate: boolean = this.props.allowedRoles([UserRoleEnum.COURSE_CREATOR, UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN]);

    const canEditContent: boolean = (this.props.allowedRoles([UserRoleEnum.COURSE_CREATOR]) && CourseHelperUtils.isCourseInStatus(this.props.course, [CourseStatusEnum.IN_CREATION])) || this.props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]);
    return (
      <AppContent>
        <RouterNavigationPrompt when={canEditContent && this.state.editing && !this.state.deleteAction} message={this.props.translate('COMMON.CONFIRMATION_ROUTE_NAVIGATION')}>
          {this.props.course && (
            <Layout>
              <Header className="timun-courseView__sider-courseTitle">
                <HeaderTitle link={this.props.isInRoles([UserRoleEnum.TRAINEE]) ? '/mycourses' : '/courses'} title={this.props.isInRoles([UserRoleEnum.TRAINEE]) ? this.props.translate('APP_NAVIGATION.MY_COURSES') : this.props.translate('COURSE_LIST.VIEW_TITLE')} icon={<LeftOutlined />} />
                <Typography.Title> {this.props.course.title} </Typography.Title>
                <TagContainer entityId={this.props.course.id} entityType={TagItemTypeEnum.COURSE} tags={this.props.course?.tags && this.props.course.tags} onEntityTagUpdate={this.fetchCourse} />
              </Header>
              <Layout>
                <Sider className="timun-courseView__sider" breakpoint="lg" collapsedWidth={0}>
                  {this.props.courseLectureList && (
                    <CourseViewSider
                      lectureList={this.props.courseLectureList}
                      selectedLecture={this.props.lectureId}
                      course={this.props.course}
                      showExamInstance={false}
                      canEdit={this.props.course.status.id === CourseStatusEnum.IN_CREATION}
                      onLectureAdd={this.handleLectureAdd}
                      onLectureGroupAdd={this.handleLectureGroupAdd}
                      canSeeExamTemplate={canSeeExamTemplate}
                      onCreateExamTemplate={this.handleCreateExamTemplate}
                    />
                  )}
                </Sider>
                <Content className="timun-courseView__content">
                  {/* ----- Course about ----- */}
                  {CourseHelperUtils.isAboutCourseContent(this.props.lectureId) &&
                    (this.state.editing ? (
                      <CourseUpdateForm
                        course={this.props.course}
                        courseGroups={this.props.courseGroups}
                        lectureList={this.props.courseLectureList}
                        canEditStructure={canEditStructure}
                        canDeleteCourse={canDeleteCourse}
                        onEditingChange={this.setEditing}
                        onCourseUpdate={this.handleCourseUpdate}
                        onCourseDelete={this.handleCourseDelete}
                        onLectureGroupDelete={this.handleLectureGroupDelete}
                        onLectureGroupAdd={this.handleLectureGroupAdd}
                        onLectureGroupUpdate={this.handleLectureGroupUpdate}
                      />
                    ) : (
                      <React.Fragment>
                        <CourseViewLectureContent
                          title={this.props.course.title}
                          body={this.props.course.content && this.props.course.content.body}
                          canEdit={canEditContent || canEditStructure}
                          coverImageUrl={this.props.course.coverImageUrl}
                          onCoverImageSubmit={this.handleCourseCoverSubmit}
                          onEditingChange={() => this.setEditing(true)}
                        />
                      </React.Fragment>
                    ))}

                  {/* Lecture */}
                  {CourseHelperUtils.isLecture(this.props.lectureId) && this.props.lecture && this.props.lectureFileList && (
                    <React.Fragment>
                      {this.state.editing ? (
                        <CourseLectureContentUpdateForm
                          lecture={this.props.lecture}
                          lectureList={this.props.courseLectureList}
                          course={this.props.course}
                          fileList={this.props.lectureFileList.content}
                          canEditStructure={canEditStructure}
                          onEditingChange={this.setEditing}
                          onLectureUpdate={this.handleLectureUpdate}
                          onLectureDelete={this.handleLectureDelete}
                          onFileAdd={this.handleLectureFileAdd}
                          onFileDelete={this.handleLectureFileDelete}
                          onEditorFileAdd={this.handleLectureEditorFileAdd}
                        />
                      ) : (
                        <React.Fragment>
                          <CourseViewLectureContent
                            title={this.props.lecture.title}
                            body={this.props.lecture.content && this.props.lecture.content.body}
                            coverVideo={this.props.lecture.integrationVideo}
                            coverVideoFileUrl={this.props.lecture.videoUrl}
                            fileList={this.props.lectureFileList.content}
                            canEdit={canEditContent || canEditStructure}
                            onEditingChange={() => this.setEditing(true)}
                            onCoverVideoSubmit={this.handleCoverVideoSubmit}
                            onCoverVideoRemove={this.handleCoverVideoRemove}
                          />
                          <RelatedObjects noteObject={{ objectId: this.props.course.id, objectTypeId: NoteObjectTypeEnum.COURSE }} commentObject={{ objectId: this.props.lectureId, objectTypeId: CommentObjectTypeEnum.LECTURE }} />
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  )}

                  {/* ----- Course notes ----- */}
                  {CourseHelperUtils.isNotesCourseContent(this.props.lectureId) && this.props.courseId && <NoteListViewContainer objectId={this.props.courseId} />}
                </Content>
              </Layout>
            </Layout>
          )}
        </RouterNavigationPrompt>
      </AppContent>
    );
  };

  setEditing = (editing: boolean) => {
    this.setState({
      editing,
    });
  };

  handleCreateExamTemplate = (title: string) => {
    this.props
      .createExamTemplate({ title })
      .track()
      .subscribe(
        // sucess
        (response) => {
          const updatedCourse = { ...this.props.course };
          const quiz = {} as ICourseQuiz;
          quiz.examTemplate = {
            id: response.id,
          };
          updatedCourse.quiz = quiz;
          this.handleCourseUpdate(updatedCourse);
        }
      );
  };

  handleCourseUpdate = (data: ICourseUpdate) => {
    this.props.updateCourse(data, {
      success: () => {
        this.fetchCourse();
        this.updateCourseLectureList();
      },
    });
  };

  handleCourseDelete = (data: ICourse) => {
    this.setState({ deleteAction: true });
    this.props.deleteCourse(data.id, {
      success: () => {
        this.props.router.replace(`/courses`);
      },
    });
  };

  handleCourseCoverSubmit = (data: IFile) => {
    if (this.props.courseId) {
      this.props.uploadCourseCover(this.props.courseId, data, {
        success: () => {
          this.fetchCourse();
        },
      });
    }
  };

  handleLectureAdd = (groupId: string, title: string) => {
    const data: ILectureCreatePayload = {
      lectureGroup: { id: groupId },
      title,
    };

    this.props.createLecture(data, {
      success: (response) => {
        this.updateCourseLectureList();
        this.props.router.replace(`/course/${this.props.courseId}/${response.id}`);
        this.setEditing(true);
      },
    });
  };

  handleLectureUpdate = (data: ILectureWithContent) => {
    this.props.updateLecture(data, {
      success: () => {
        this.updateCourseLectureList();
        this.updateLectureContent();
      },
    });
  };

  handleLectureDelete = (data: ILectureWithContent) => {
    this.setState({ deleteAction: true });
    this.props.deleteLecture(data.id, {
      success: () => {
        this.props.router.replace(`/course/${this.props.courseId}`);
        this.setState({ deleteAction: false });
        this.updateCourseLectureList();
        this.setEditing(false);
        this.props.reportMessage({ message: this.props.translate('COURSE_VIEW.UPDATE.LECTURE_DELETED_MESSAGE', { lecture: data.title }), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.INFO });
      },
    });
  };

  handleLectureGroupAdd = (title: string, event?: ITrackableEvent<string>) => {
    const data = {
      course: this.props.course,
      title,
    };

    if (event) {
      this.props
        .createLectureGroup(data, event)
        .track()
        .subscribe(() => {
          this.updateCourseLectureList();
        });
    } else {
      this.props
        .createLectureGroup(data)
        .track()
        .subscribe(() => this.updateCourseLectureList());
    }
  };

  handleLectureGroupUpdate = (data: ICourseLectureGroup) => {
    this.props.updateLectureGroup(data, {
      success: () => {
        this.updateCourseLectureList();
      },
    });
  };

  handleLectureGroupDelete = (data: ICourseLectureGroup) => {
    this.props.deleteLectureGroup(data.id, {
      success: () => {
        this.updateCourseLectureList();
      },
    });
  };

  handleLectureFileAdd = (data: IFile[]) => {
    this.props
      .addLectureFile(this.props.lectureId, data)
      .track()
      .subscribe(() => {
        this.fetchLectureFileList();
      });
  };
  handleCoverVideoSubmit = (data: IFile) => {
    // this presumes that this is lecture video, in case course should also participate, some if-ology would be required
    this.props
      .uploadLectureVideo(this.props.lectureId, data)
      .track()
      .subscribe(() => {
        this.updateLectureContent();
      });
  };

  handleCoverVideoRemove = () => {
    // this presumes that this is lecture video, in case course should also participate, some if-ology would be required
    this.props
      .removeLectureVideo(this.props.lectureId)
      .track()
      .subscribe(() => {
        this.updateLectureContent();
      });
  };

  handleLectureEditorFileAdd = (fileBlob: any, fileName: string, success: (fileUrl: string) => void, failure: (err: string) => void) => {
    FileUploadHelper
      // -- upload editor file
      // maybe we could've sent entire blob to redux effect and handle this logic there but we want to handle blobs as close to it's origin (form, editor, ...) as we can to avoid sending blobs throughout app and accidentally keeping their references
      .uploadFileBlob(fileBlob, fileName)
      .pipe(
        // -- add uploaded file to lecture
        mergeMap((file) => {
          return this.props
            .addLectureFile(this.props.lectureId, [file])
            .track()
            .pipe(
              // remap back to file to avoid meddling with list
              map((fileList) => file)
            );
        })
      )
      .subscribe(
        (file) => {
          const uploadedFileUrl = UrlBuilderFactory.buildApiFileUrl(file.id);
          console.log(`Uploaded edito file ${uploadedFileUrl}`);
          success(uploadedFileUrl);
        },
        // -- error, notify everyone, abort ship
        (err) => {
          console.error(`Error adding editor file "${fileName}" to lecture ${this.props.lectureId}`, err);
          failure(this.props.translate('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR'));
        }
      );
  };

  handleLectureFileDelete = (data: IFile[]) => {
    this.props.removeLectureFile(this.props.lectureId, data, {
      success: () => {
        this.fetchLectureFileList();
      },
    });
  };

  private fetchCourse = (courseId = this.props.courseId) => {
    if (courseId) {
      this.props.fetchCourseContent(courseId);
    }
  };

  private updateCourseLectureList = (courseId = this.props.courseId) => {
    if (courseId) {
      this.props.fetchCourseLectureList(courseId, {});
    }
  };

  private updateLectureContent = (lectureId = this.props.lectureId) => {
    this.props.fetchLectureContent(lectureId);
  };

  private fetchLectureCourse = (lectureId = this.props.lectureId) => {
    this.props
      .fetchLectureContent(lectureId)
      .track()
      .subscribe((response: ILecture) => {
        this.fetchCourse(response.course.id);
        this.updateCourseLectureList(response.course.id);
      });
  };

  private fetchLectureFileList(lectureId: string = this.props.lectureId) {
    this.props.fetchLectureFileList(lectureId);
  }
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ICourseUpdateContainerOwnProps): ICourseUpdateContainerStateProps => ({
  courseGroups: CollectionBusinessStore.selectors.getCollectionContent(state, 'CourseGroup'),
  course: courseUpdateBusinessStore.selectors.getCourseContent(state),
  courseLectureList: courseUpdateBusinessStore.selectors.getCourseLectureList(state),
  lecture: courseUpdateBusinessStore.selectors.getLectureContent(state),
  lectureFileList: courseUpdateBusinessStore.selectors.getLectureFileList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: Dispatch): ICourseUpdateContainerDispatchProps => ({
  fetchCourseContent: (courseId: string) => dispatch(courseUpdateBusinessStore.actions.fetchCourseContent({ id: courseId })),
  clearCourseContentData: () => dispatch(courseUpdateBusinessStore.actions.clearCourseContentData()),
  updateCourse: (data: ICourseUpdate, thunkMap: IActionThunkMap) => dispatch(createActionThunk(courseUpdateBusinessStore.actions.updateCourse(data), thunkMap)),
  deleteCourse: (id: string, thunkMap: IActionThunkMap) => dispatch(createActionThunk(courseUpdateBusinessStore.actions.deleteCourse({ id }), thunkMap)),
  uploadCourseCover: (id: string, data: IFile, thunkMap: IActionThunkMap) => dispatch(createActionThunk(courseUpdateBusinessStore.actions.uploadCourseCover(id, data), thunkMap)),

  fetchCourseLectureList: (courseId: string, listFilter: ICourseLectureListFilter) => dispatch(courseUpdateBusinessStore.actions.fetchCourseLectureList(courseId, listFilter)),
  clearCourseLectureList: () => dispatch(courseUpdateBusinessStore.actions.clearCourseLectureList()),

  createLectureGroup: (data: ILectureGroupCreatePayload, sourceEvent?: ITrackableEvent<string>) => createTrackableAction(dispatch(courseUpdateBusinessStore.actions.createLectureGroup(data)), sourceEvent),
  updateLectureGroup: (data: ICourseLectureGroup, thunkMap: IActionThunkMap) => dispatch(createActionThunk(courseUpdateBusinessStore.actions.updateLectureGroup(data), thunkMap)),
  deleteLectureGroup: (id: string, thunkMap: IActionThunkMap) => dispatch(createActionThunk(courseUpdateBusinessStore.actions.deleteLectureGroup({ id }), thunkMap)),

  fetchLectureContent: (id: string) => createTrackableAction(dispatch(courseUpdateBusinessStore.actions.fetchLectureContent({ id }))),
  clearCourseLectureContentData: () => dispatch(courseUpdateBusinessStore.actions.clearLectureContentData()),
  createLecture: (data: ILectureCreatePayload, thunkMap: IActionThunkMap) => dispatch(createActionThunk(courseUpdateBusinessStore.actions.createLecture(data), thunkMap)),
  updateLecture: (data: ILecture, thunkMap: IActionThunkMap) => dispatch(createActionThunk(courseUpdateBusinessStore.actions.updateLecture(data), thunkMap)),
  deleteLecture: (id: string, thunkMap: IActionThunkMap) => dispatch(createActionThunk(courseUpdateBusinessStore.actions.deleteLecture({ id }), thunkMap)),
  uploadLectureVideo: (id: string, data: IFile) => dispatch(createTrackableAction(courseUpdateBusinessStore.actions.uploadLectureVideo(id, data))),
  removeLectureVideo: (id: string) => dispatch(createTrackableAction(courseUpdateBusinessStore.actions.removeLectureVideo(id))),

  fetchLectureFileList: (lectureId: string) => dispatch(courseUpdateBusinessStore.actions.fetchLectureFileList(lectureId)),
  clearLectureFileList: () => dispatch(courseUpdateBusinessStore.actions.clearLectureFileList()),
  addLectureFile: (lectureId: string, data: IFile[]) => createTrackableAction(dispatch(courseUpdateBusinessStore.actions.addLectureFile(lectureId, data))),
  removeLectureFile: (lectureId: string, data: IFile[], thunkMap: IActionThunkMap) => dispatch(createActionThunk(courseUpdateBusinessStore.actions.removeLectureFile(lectureId, data), thunkMap)),

  createExamTemplate: (data: IExamTemplateCreatePayload) => createTrackableAction(dispatch(examTemplateBusinessStore.actions.createExamTemplate(data))),
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<ICourseUpdateContainerOwnProps>(withRoles(withRouter(CourseUpdateContainer as any))));
