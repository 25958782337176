import React from 'react';
import { connect } from 'react-redux';

import PrivateCourseListView from '@src/components/course/list/catalog/PrivateCourseListView';
import { ICourse } from '@src/model/course/Course';
import { ICourseGroup } from '@src/model/course/CourseGroup';
import CollectionBusinessStore from '@src/service/business/common/collectionBusinessStore';
import { ICollectionData } from '@src/service/business/common/types';
import CourseListBusinessStore, { ICourseListFilter } from '@src/service/business/courses/courseListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';

const defaultSortValue = ['title,asc'];

// -- Prop types
// ----------

export interface IPrivateCourseListContainerOwnProps {
  predefinedListFilter?: ICourseListFilter;
}
export interface IPrivateCourseListContainerStateProps {
  courseGroups: ICourseGroup[];
  courseList: ICollectionData<ICourse>;
  courseListFilter: ICourseListFilter;
}
export interface IPrivateCourseListContainerDispatchProps {
  fetchCourseList: (params: ICourseListFilter, page: number, size: number, sort: string[]) => any;
  clearCourseList: () => void;
  storeListFilter: (filter: ICourseListFilter) => void;
  clearListFilter: () => void;
}
type IPrivateCourseListContainerProps = IPrivateCourseListContainerOwnProps & IPrivateCourseListContainerStateProps & IPrivateCourseListContainerDispatchProps;

interface IPrivateCourseListContainerState {
  page: number;
  size: number;
  sort: string[];
}

// -- Component
// ----------

/** Describe your component ... */
class PrivateCourseListContainer extends React.Component<IPrivateCourseListContainerProps, IPrivateCourseListContainerState> {
  state: IPrivateCourseListContainerState = {
    page: 0,
    size: AppConfigService.getValue('api.paging.defaultPageSize'),
    sort: defaultSortValue,
  };

  componentDidMount() {
    this.handleFilterChange(this.props.courseListFilter);
  }

  componentDidUpdate(prevProps: IPrivateCourseListContainerProps, prevState: IPrivateCourseListContainerState) {
    if (this.props.predefinedListFilter !== prevProps.predefinedListFilter) {
      this.handleFilterChange(this.props.courseListFilter);
    }

    if (this.props.courseListFilter !== prevProps.courseListFilter || this.state !== prevState) {
      this.updateList();
    }
  }

  componentWillUnmount = () => {
    this.props.clearCourseList();
    this.props.clearListFilter();
  };

  render = () => {
    return (
      <PrivateCourseListView courseList={this.props.courseList} courseGroups={this.props.courseGroups} courseListFilter={this.props.courseListFilter} sortValue={this.state.sort} getPath={this.getPath} onSortChange={this.handleSortChange} onFilterChange={this.handleFilterChange} onPageChange={this.handlePageChange} />
    );
  };

  handleFilterChange = (filter: ICourseListFilter) => {
    this.props.storeListFilter({
      // custom filter
      ...(filter ?? {}),
      // predefined filter
      ...(this.props.predefinedListFilter ?? {}),
    });
    this.setState({ page: 0 });
  };

  handleSortChange = (sort: string[]) => {
    this.setState({ sort, page: 0 });
  };

  handlePageChange = (page: number, pageSize?: number) => {
    this.setState({ page: page - 1, size: pageSize ?? this.state.size });
  };

  getPath = (id: string, lecture?: string) => {
    return `/course/${id}/${lecture}`;
  };

  private updateList() {
    this.props.fetchCourseList(this.props.courseListFilter, this.state.page, this.state.size, this.state.sort);
  }
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IPrivateCourseListContainerOwnProps): IPrivateCourseListContainerStateProps => ({
  courseGroups: CollectionBusinessStore.selectors.getCollectionContent(state, 'CourseGroup'),
  courseList: CourseListBusinessStore.selectors.getCourseList(state),
  courseListFilter: CourseListBusinessStore.selectors.getCourseListFilter(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IPrivateCourseListContainerDispatchProps => ({
  fetchCourseList: (filter: ICourseListFilter, page: number, size: number, sort: string[]) => dispatch(CourseListBusinessStore.actions.fetchCourseList({ filter, page, size, sort })),
  clearCourseList: () => dispatch(CourseListBusinessStore.actions.clearCourseList()),
  storeListFilter: (filter: ICourseListFilter) => dispatch(CourseListBusinessStore.actions.storeCourseListFilter(filter)),
  clearListFilter: () => dispatch(CourseListBusinessStore.actions.clearCourseListFilter()),
});

export default connect<IPrivateCourseListContainerStateProps, IPrivateCourseListContainerDispatchProps, IPrivateCourseListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(PrivateCourseListContainer as any);
