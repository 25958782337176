import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

import { EducationApplicationActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import IIdRef from '@src/model/common/IdRef';
import { ICompany } from '@src/model/company/Company';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { IExternalEducationApplicationStatistics } from '@src/model/externalEducationApplication/ExternalEducationApplicationStatistics';
import { IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { IFile } from '@src/model/file/File';
import { ISkill } from '@src/model/skillgroup/Skill';
import { IUserInfo } from '@src/model/user/User';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, IIdDataPayload, IIdPayload, ILemonAction, IPayloadAction, IStoreListData } from '@src/service/business/common/types';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { transformExternalEducationApplicationListFilter } from '@src/service/business/externalEducationApplication/util';
import { IFileListFilter } from '@src/service/business/files/FileListBusinessStore';
import { RequiredBy } from '@src/service/util/lang/type';
import { actionThunk, startGlobalProgress, stopGlobalProgress, trackAction } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts
export interface IExternalEducationApplicationListFilter {
  participant?: RequiredBy<Partial<IUserInfo>, 'id'>; // ID is the only required value in this obj
  assignee?: RequiredBy<Partial<IUserInfo>, 'id'>;
  evaluator?: RequiredBy<Partial<IUserInfo>, 'id'>;
  excludedEvaluator?: RequiredBy<Partial<IUserInfo>, 'id'>;
  phase?: EducationApplicationActivityPhaseEnum;
  educationProvider?: RequiredBy<Partial<ICompany>, 'id'>;
  skill?: RequiredBy<Partial<ISkill>, 'id'>;
  education?: RequiredBy<Partial<IExternalEducationTemplate>, 'id'>;
  city?: string;
  pendingConsultation?: boolean;
  educationRelated?: boolean;
  employmentStatus?: string;
  jobRelated?: boolean;
  withoutCoordinator?: boolean;
  educationMinDuration?: number;
  educationMaxDuration?: number;
  educationMinExpenses?: number;
  educationMaxExpenses?: number;
  travelMinExpenses?: number;
  travelMaxExpenses?: number;
  insuranceMinExpense?: number;
  insuranceMaxExpense?: number;
  submittedFrom?: string;
  submittedTo?: string;
}

export type IExternalEducationApplicationCreatePayload = Partial<IExternalEducationApplication>;

// List filter ID
const EXTERNAL_EDUCATION_APPLICATION_LIST_FILTER = '@@EXTERNAL_EDUCATION_APPLICATION_LIST_FILTER';

// -
// -------------------- Selectors

/** Returns ExternalEducationApplication from store. */
const getExternalEducationApplication = (store: any): IExternalEducationApplication => store.ExternalEducationApplicationView;

/** Returns ExternalEducationApplication from store. */
const getExternalEducationApplications = (store: any): ICollectionData<IExternalEducationApplication> => store.externalEducationApplications;

/** Returns externalEducationApplications filter. */
const getExternalEducationApplicationListFilter = (store: any): IExternalEducationApplicationListFilter => ListFilterBusinessStore.selectors.getListFilter(store, EXTERNAL_EDUCATION_APPLICATION_LIST_FILTER);

const getExternalEducationApplicationFileList = (store: any, externalEducationApplicationId: string): ICollectionData<IFile> => store.externalEducationApplicationFileList[externalEducationApplicationId];

/** Returns ExternalEducationApplication statistics from store. */
const getExternalEducationApplicationStatistics = (store: any): IExternalEducationApplicationStatistics => store.externalEducationApplicationStatistics;

// -
// -------------------- Actions

const Actions = {
  EXTERNAL_EDUCATION_APPLICATION_LIST_FETCH: 'EXTERNAL_EDUCATION_APPLICATION_LIST_FETCH',
  EXTERNAL_EDUCATION_APPLICATION_LIST_LOAD: 'EXTERNAL_EDUCATION_APPLICATION_LIST_LOAD',
  EXTERNAL_EDUCATION_APPLICATION_LIST_CLEAR: 'EXTERNAL_EDUCATION_APPLICATION_LIST_CLEAR',
  EXTERNAL_EDUCATION_APPLICATION_FETCH: 'EXTERNAL_EDUCATION_APPLICATION_FETCH',
  EXTERNAL_EDUCATION_APPLICATION_LOAD: 'EXTERNAL_EDUCATION_APPLICATION_LOAD',
  EXTERNAL_EDUCATION_APPLICATION_CLEAR: 'EXTERNAL_EDUCATION_APPLICATION_CLEAR',
  EXTERNAL_EDUCATION_APPLICATION_CREATE: 'EXTERNAL_EDUCATION_APPLICATION_CREATE',
  EXTERNAL_EDUCATION_APPLICATION_UPDATE: 'EXTERNAL_EDUCATION_APPLICATION_UPDATE',
  EXTERNAL_EDUCATION_APPLICATION_DELETE: 'EXTERNAL_EDUCATION_APPLICATION_DELETE',
  EXTERNAL_EDUCATION_APPLICATION_CONSULTATION_FINISH: 'EXTERNAL_EDUCATION_APPLICATION_CONSULTATION_FINISH',
  EXTERNAL_EDUCATION_APPLICATION_FILE_LIST_FETCH: 'EXTERNAL_EDUCATION_APPLICATION_FILE_LIST_FETCH',
  EXTERNAL_EDUCATION_APPLICATION_FILE_LIST_LOAD: 'EXTERNAL_EDUCATION_APPLICATION_FILE_LIST_LOAD',
  EXTERNAL_EDUCATION_APPLICATION_FILE_LIST_CLEAR: 'EXTERNAL_EDUCATION_APPLICATION_FILE_LIST_CLEAR',
  EXTERNAL_EDUCATION_APPLICATION_FILE_LIST_ADD_FILES: 'EXTERNAL_EDUCATION_APPLICATION_FILE_LIST_ADD_FILES',
  EXTERNAL_EDUCATION_APPLICATION_FILE_LIST_REMOVE_FILES: 'EXTERNAL_EDUCATION_APPLICATION_FILE_LIST_REMOVE_FILES',
  EXTERNAL_EDUCATION_APPLICATION_FILE_LIST_UPDATE_FILE: 'EXTERNAL_EDUCATION_APPLICATION_FILE_LIST_UPDATE_FILE',
  EXTERNAL_EDUCATION_APPLICATION_STATISTICS_FETCH: 'EXTERNAL_EDUCATION_APPLICATION_STATISTICS_FETCH',
  EXTERNAL_EDUCATION_APPLICATION_STATISTICS_LOAD: 'EXTERNAL_EDUCATION_APPLICATION_STATISTICS_LOAD',
  EXTERNAL_EDUCATION_APPLICATION_STATISTICS_CLEAR: 'EXTERNAL_EDUCATION_APPLICATION_STATISTICS_CLEAR',
};

/** Fetch externalEducationApplication list by filter */
const fetchExternalEducationApplicationList = (params: ICollectionFetchPayload<IExternalEducationApplicationListFilter>): IPayloadAction<ICollectionFetchPayload<IExternalEducationApplicationListFilter>> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_APPLICATION_LIST_FETCH,
    payload: params,
  };
};

/** Load externalEducationApplication list to the store */
const loadExternalEducationApplicationList = (data: ICollectionData<IExternalEducationApplication>): IPayloadAction<ICollectionData<IExternalEducationApplication>> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_APPLICATION_LIST_LOAD,
    payload: data,
  };
};

/** Clear externalEducationApplication list from store. Eg. when leaving view. */
const clearExternalEducationApplicationList = (): ILemonAction => {
  return {
    type: Actions.EXTERNAL_EDUCATION_APPLICATION_LIST_CLEAR,
  };
};

/** store external education application list filter */
const storeExternalEducationApplicationListFilter = (listFilter: IExternalEducationApplicationListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(EXTERNAL_EDUCATION_APPLICATION_LIST_FILTER, listFilter);
};

/** clear external education application list filter */
const clearExternalEducationApplicationListFilter = (): ILemonAction => {
  return ListFilterBusinessStore.actions.clearListFilter(EXTERNAL_EDUCATION_APPLICATION_LIST_FILTER);
};

/** Fetch ExternalEducationApplication by ID. */
const fetchExternalEducationApplication = (params: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_APPLICATION_FETCH,
    payload: params,
  };
};

/** Load ExternalEducationApplication to store. */
const loadExternalEducationApplication = (data: IExternalEducationApplication): IPayloadAction<IExternalEducationApplication> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_APPLICATION_LOAD,
    payload: data,
  };
};

/** Create new ExternalEducationApplication. */
const createExternalEducationApplication = (data: IExternalEducationApplicationCreatePayload): IPayloadAction<IExternalEducationApplicationCreatePayload> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_APPLICATION_CREATE,
    payload: data,
  };
};

/** Delete externalEducationApplication by id */

const deleteExternalEducationApplication = (id: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_APPLICATION_DELETE,
    payload: {
      id,
    },
  };
};

/** Clear externalEducationApplication from store. Eg. when leaving view. */
const clearExternalEducationApplication = (): ILemonAction => {
  return {
    type: Actions.EXTERNAL_EDUCATION_APPLICATION_CLEAR,
  };
};

/** Update externalEducationApplication */

const updateExternalEducationApplication = (externalEducationApplication: IExternalEducationApplication): IPayloadAction<IIdDataPayload<IExternalEducationApplication>> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_APPLICATION_UPDATE,
    payload: {
      id: externalEducationApplication.id,
      data: externalEducationApplication,
    },
  };
};

const fetchExternalEducationApplicationFileList = (externalEducationApplicationId: string, listFilter: IFileListFilter, page: number, size: number, sort: string[]): IPayloadAction<IIdDataPayload<ICollectionFetchPayload<IFileListFilter>>> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_APPLICATION_FILE_LIST_FETCH,
    payload: {
      id: externalEducationApplicationId,
      data: {
        filter: listFilter,
        page,
        size,
        sort,
      },
    },
  };
};

const loadExternalEducationApplicationFileList = (externalEducationApplicationId: string, data: ICollectionData<IFile>): IPayloadAction<IIdDataPayload<ICollectionData<IFile>>> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_APPLICATION_FILE_LIST_LOAD,
    payload: {
      id: externalEducationApplicationId,
      data,
    },
  };
};

const clearExternalEducationApplicationFileList = (externalEducationApplicationId: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_APPLICATION_FILE_LIST_CLEAR,
    payload: externalEducationApplicationId,
  };
};

const addExternalEducationApplicationFile = (externalEducationApplicationId: string, data: IFile[]): IPayloadAction<IIdDataPayload<IFile[]>> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_APPLICATION_FILE_LIST_ADD_FILES,
    payload: {
      id: externalEducationApplicationId,
      data,
    },
  };
};

const removeExternalEducationApplicationFile = (externalEducationApplicationId: string, data: IFile[]): IPayloadAction<IIdDataPayload<IFile[]>> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_APPLICATION_FILE_LIST_REMOVE_FILES,
    payload: {
      id: externalEducationApplicationId,
      data,
    },
  };
};

/** Fetch externalEducationApplication statistics by filter */
const fetchExternalEducationApplicationStatistics = (params: IExternalEducationApplicationListFilter): IPayloadAction<IExternalEducationApplicationListFilter> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_APPLICATION_STATISTICS_FETCH,
    payload: params,
  };
};

/** Load externalEducationApplication statistics to the store */
const loadExternalEducationApplicationStatistics = (data: IExternalEducationApplication): IPayloadAction<IExternalEducationApplication> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_APPLICATION_STATISTICS_LOAD,
    payload: data,
  };
};

/** Clear externalEducationApplication statistics from store. Eg. when leaving view. */
const clearExternalEducationApplicationStatistics = (): ILemonAction => {
  return {
    type: Actions.EXTERNAL_EDUCATION_APPLICATION_STATISTICS_CLEAR,
  };
};

/** Finish consultation by id. */
const finishConsultation = (applicationId: IIdRef<string>): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_APPLICATION_CONSULTATION_FINISH,
    payload: applicationId,
  };
};

// -
// -------------------- Side-effects

const fetchExternalEducationApplicationListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<IExternalEducationApplicationListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_EDUCATION_APPLICATION_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = {
        // first copy entire payload
        ...action.payload,
        // then override filter with transformed value
        filter: action.payload.filter ? transformExternalEducationApplicationListFilter(action.payload.filter) : undefined,
      };

      return EntityApiServiceRegistry.getService('ExternalEducationApplication')
        .fetchEntityList(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadExternalEducationApplicationList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error fetching externalEducationApplication list', error);
      return o;
    })
  );
};

const fetchExternalEducationApplicationEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_EDUCATION_APPLICATION_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('ExternalEducationApplication')
        .fetchEntity(id)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadExternalEducationApplication(data);
    }),

    // reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'EXTERNAL_EDUCATION_APPLICATION_VIEW.ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),
    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching ExternalEducationApplication', error);
      return o;
    })
  );
};

const createExternalEducationApplicationEffect = (action$: Observable<IPayloadAction<IExternalEducationApplicationCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_EDUCATION_APPLICATION_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('ExternalEducationApplication')
        .createEntity(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadExternalEducationApplication(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating ExternalEducationApplication', error);
      return o;
    })
  );
};

const deleteExternalEducationApplicationEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_EDUCATION_APPLICATION_DELETE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const id = action.payload.id;

      return EntityApiServiceRegistry.getService('ExternalEducationApplication')
        .deleteEntity(id)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_DELETE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error deleting externalEducationApplication', error);
      return o;
    })
  );
};

const updateExternalEducationApplicationEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IExternalEducationApplication>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_EDUCATION_APPLICATION_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;
      return EntityApiServiceRegistry.getService('ExternalEducationApplication')
        .updateEntity(id, data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadExternalEducationApplication(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error updating externalEducationApplication', error);
      return o;
    })
  );
};

const fetchExternalEducationApplicationFileListEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ICollectionFetchPayload<IFileListFilter>>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_EDUCATION_APPLICATION_FILE_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('ExternalEducationApplication')
        .fetchSubentityList(id, 'File', data)
        .pipe(
          actionThunk(action),

          map((response) => {
            return { id, response };
          })
        );
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadExternalEducationApplicationFileList(data.id, data.response);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching externalEducationApplication file list', error);
      return o;
    })
  );
};

const addFileToExternalEducationApplicationEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IFile[]>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_EDUCATION_APPLICATION_FILE_LIST_ADD_FILES;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('ExternalEducationApplication')
        .createSubentityList(id, 'File', data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error adding file to externalEducationApplication', error);
      return o;
    })
  );
};

const removeFileFromExternalEducationApplicationEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IFile[]>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_EDUCATION_APPLICATION_FILE_LIST_REMOVE_FILES;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('ExternalEducationApplication')
        .deleteSubentityList(id, 'File', data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error removing file from externalEducationApplication', error);
      return o;
    })
  );
};

const fetchExternalEducationApplicationStatisticsEffect = (action$: Observable<IPayloadAction<IExternalEducationApplicationListFilter>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_EDUCATION_APPLICATION_STATISTICS_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = transformExternalEducationApplicationListFilter(action.payload);
      return EntityApiServiceRegistry.getService('ExternalEducationApplication')
        .fetchMethod('statistics', payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadExternalEducationApplicationStatistics(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching ExternalEducationApplication statistics', error);
      return o;
    })
  );
};

const finishConsultationEffect = (action$: Observable<IPayloadAction<IIdRef<string>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_EDUCATION_APPLICATION_CONSULTATION_FINISH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('ExternalEducationApplication')
        .updateEntityMethod(payload.id, 'endConsultation', {})
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error ending consultation', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers
const externalEducationApplications = (state: ICollectionData<IExternalEducationApplication> | null = null, action: IPayloadAction<ICollectionData<IExternalEducationApplication>>) => {
  if (action.type === Actions.EXTERNAL_EDUCATION_APPLICATION_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.EXTERNAL_EDUCATION_APPLICATION_LIST_CLEAR) {
    return null;
  }

  return state;
};

const ExternalEducationApplicationView = (state: IExternalEducationApplication | null = null, action: IPayloadAction<IExternalEducationApplication>) => {
  if (action.type === Actions.EXTERNAL_EDUCATION_APPLICATION_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.EXTERNAL_EDUCATION_APPLICATION_CLEAR) {
    return null;
  }

  return state;
};

const externalEducationApplicationFileList = (state: IStoreListData<IFile> = {}, action: IPayloadAction<IIdDataPayload<ICollectionData<IFile>>>) => {
  if (action.type === Actions.EXTERNAL_EDUCATION_APPLICATION_FILE_LIST_LOAD) {
    const { id, data } = action.payload;
    return {
      ...state,
      [id]: data,
    };
  } else if (action.type === Actions.EXTERNAL_EDUCATION_APPLICATION_FILE_LIST_CLEAR) {
    const { id } = action.payload;
    const { [id]: removedFiles, ...newState } = state;
    return newState;
  }

  return state;
};

const externalEducationApplicationStatistics = (state: IExternalEducationApplicationStatistics | null = null, action: IPayloadAction<IExternalEducationApplicationStatistics>) => {
  if (action.type === Actions.EXTERNAL_EDUCATION_APPLICATION_STATISTICS_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.EXTERNAL_EDUCATION_APPLICATION_STATISTICS_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

const ExternalEducationApplicationBusinessStore = {
  actions: {
    fetchExternalEducationApplicationList,
    loadExternalEducationApplicationList,
    clearExternalEducationApplicationList,
    storeExternalEducationApplicationListFilter,
    clearExternalEducationApplicationListFilter,
    fetchExternalEducationApplication,
    createExternalEducationApplication,
    updateExternalEducationApplication,
    deleteExternalEducationApplication,
    clearExternalEducationApplication,
    fetchExternalEducationApplicationFileList,
    loadExternalEducationApplicationFileList,
    clearExternalEducationApplicationFileList,
    addExternalEducationApplicationFile,
    removeExternalEducationApplicationFile,
    fetchExternalEducationApplicationStatistics,
    loadExternalEducationApplicationStatistics,
    clearExternalEducationApplicationStatistics,
    finishConsultation,
  },

  selectors: {
    getExternalEducationApplication,
    getExternalEducationApplications,
    getExternalEducationApplicationListFilter,
    getExternalEducationApplicationFileList,
    getExternalEducationApplicationStatistics,
  },

  effects: {
    fetchExternalEducationApplicationListEffect,
    fetchExternalEducationApplicationEffect,
    createExternalEducationApplicationEffect,
    updateExternalEducationApplicationEffect,
    deleteExternalEducationApplicationEffect,
    fetchExternalEducationApplicationFileListEffect,
    addFileToExternalEducationApplicationEffect,
    removeFileFromExternalEducationApplicationEffect,
    fetchExternalEducationApplicationStatisticsEffect,
    finishConsultationEffect,
  },

  reducers: {
    ExternalEducationApplicationView,
    externalEducationApplications,
    externalEducationApplicationFileList,
    externalEducationApplicationStatistics,
  },
};

// --
// export business store

export default ExternalEducationApplicationBusinessStore;
