import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ISkillLevel } from '@src/model/skillgroup/SkillLevelClassification';
import { Form, Input, Modal, Radio } from 'antd';
import React from 'react';

// -- Prop types
// ----------
export interface ISkillLevelCodebookFormOwnProps {
  skillLevel?: ISkillLevel;
  onSubmit?: (skillLevel: ISkillLevel) => void;
  onCancel?: () => void;
}

type ISkillLevelCodebookFormProps = ISkillLevelCodebookFormOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

const SkillLevelCodebookForm: React.FC<ISkillLevelCodebookFormProps> = (props: ISkillLevelCodebookFormProps) => {
  const [form] = Form.useForm<ISkillLevel>();

  const handleFinish = (values: ISkillLevel) => {
    props.onSubmit?.({
      ...props.skillLevel,
      ...values,
    });
  };

  return <Modal
    title={props.translate('COMMON.ACTION_EDIT')}
    visible={true}
    onOk={form.submit}
    onCancel={props.onCancel}
    okText={props.translate('COMMON.ACTION_SAVE')}
    cancelText={props.translate('COMMON.ACTION_CANCEL')}
  >
    <Form<ISkillLevel>
      layout="vertical"
      form={form}
      initialValues={props.skillLevel}
      name="skillLevel"
      onFinish={handleFinish}
    >
      <Form.Item
        label={props.translate('COMMON.LABEL.NAME')}
        name="name"
        rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={props.translate('COMMON.LABEL.STATUS')}
        name={'active'}
        rules={[{ required: true, message: props.translate('COMMON.PICK_REQUIRED_FIELD') }]}>
        <Radio.Group buttonStyle="solid">
          <Radio.Button value={true}>{props.translate('COMMON.LABEL.ACTIVE')} </Radio.Button>
          <Radio.Button value={false}>{props.translate('COMMON.LABEL.INACTIVE')} </Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        noStyle={true}
        hidden={true}
        name={'id'}>
        <Input />
      </Form.Item>
    </Form>
  </Modal>;
};

export default withLocalize<ISkillLevelCodebookFormOwnProps>(SkillLevelCodebookForm as any);
