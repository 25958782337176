import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import EntityApiService from '@src/service/api/registry/entity/EntityApiService';
import IAbstractEntityApi from '@src/service/util/api/IAbstractEntityApi';

export default class LoginApiService extends EntityApiService<any> {
  static METHODS = {
    LOGIN: 'login',
    PASSWORD: 'password',
    REGISTRATION: 'registration',
  };

  static create(entityName: string, api: IAbstractEntityApi<any, any>): LoginApiService {
    return new LoginApiService(entityName, api);
  }

  login(username: string, password: string): Observable<string> {
    const body = {
      username,
      password,
    };

    // TODO: this should call createMethod, but backend API isn't finished so we're targeting static json file which can accept only fetch
    return this.createMethod(LoginApiService.METHODS.LOGIN, body).pipe(
      // map result back to model
      map((payload) => {
        return this.convertEntityToModel(this.entityName, payload.accessToken);
      })
    );
  }

  password(email: string): Observable<string> {
    const body = {
      email,
    };

    // TODO: this should call createMethod, but backend API isn't finished so we're targeting static json file which can accept only fetch
    return this.createMethod(LoginApiService.METHODS.PASSWORD, body).pipe(
      // map result back to model
      map((payload) => {
        return this.convertEntityToModel(this.entityName, payload.accessToken);
      })
    );
  }

  validateResetToken(token: string): Observable<string> {
    const params = {
      token,
    };

    // TODO: this should call createMethod, but backend API isn't finished so we're targeting static json file which can accept only fetch
    return this.fetchMethod(LoginApiService.METHODS.PASSWORD, params).pipe(
      // map result back to model
      map((payload) => {
        return this.convertEntityToModel(this.entityName, payload.accessToken);
      })
    );
  }

  resetPassword(token: string, password: string): Observable<string> {
    const params = {
      token,
      password,
    };

    // TODO: this should call createMethod, but backend API isn't finished so we're targeting static json file which can accept only fetch
    return this.updateMethod(LoginApiService.METHODS.PASSWORD, params).pipe(
      // map result back to model
      map((payload) => {
        return this.convertEntityToModel(this.entityName, payload.accessToken);
      })
    );
  }

  validateActivationToken(token: string): Observable<string> {
    const params = {
      token,
    };

    // TODO: this should call createMethod, but backend API isn't finished so we're targeting static json file which can accept only fetch
    return this.fetchMethod(LoginApiService.METHODS.REGISTRATION, params).pipe(
      // map result back to model
      map((payload) => {
        return this.convertEntityToModel(this.entityName, payload.accessToken);
      })
    );
  }
}
