import React from 'react';

import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { IQuiz } from '@lamarodigital/quizzler-lib-frontend/model/quiz/Quiz';
import { QuizTypeEnum } from '@lamarodigital/quizzler-lib-frontend/model/quiz/QuizType';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import QuizCreatorContainer from '@src/components/exam/quiz/QuizCreatorContainer';
import { Modal } from 'antd';

// -- Prop types
// ----------
export interface IQuizCreatorModalOwnProps {
  quizId?: string;
  onQuizCollectionSubmit?: (quizData: IQuiz) => void;
  onCancel?: (isVisible: boolean) => void;
}

export interface IQuizCreatorModalStateProps { }
export interface IQuizCreatorModalDispatchProps { }

type IQuizCreatorModalProps = IQuizCreatorModalOwnProps & IQuizCreatorModalStateProps & IQuizCreatorModalDispatchProps & IWithLocalizeOwnProps & FormComponentProps;

interface IQuizCreatorModalState { }

/** Quiz creator modal */
class QuizCreatorModal extends React.Component<IQuizCreatorModalProps, IQuizCreatorModalState> {
  render() {
    return (
      <Modal maskClosable={false} title={this.props.translate('QUIZ_CREATOR.MODAL_TITLE')} visible={true} className="lemon-modal__modal--full timun-quiz__modal" onCancel={() => this.props.onCancel && this.props.onCancel(false)} cancelText={this.props.translate('COMMON.ACTION_CLOSE')} okButtonProps={{ style: { display: 'none' } }}>
        <QuizCreatorContainer quizType={QuizTypeEnum.QUIZ} quizId={this.props.quizId} onQuizCollectionSubmit={this.props.onQuizCollectionSubmit} />
      </Modal>
    );
  }
}

export default withLocalize<IQuizCreatorModalOwnProps>(QuizCreatorModal as any);
