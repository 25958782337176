import WorkPositionUpdateForm from '@src/components/administration/workposition/WorkPositionUpdateForm';
import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import { Form, Modal } from 'antd';
import React from 'react';

export interface IWorkPositionUpdateModalOwnProps {
  workPosition?: IWorkPosition;
  onSubmit?: (values: IWorkPosition) => void;
  onCancelEdit?: () => void;
}

export interface IWorkPositionUpdateModalInjectedProps {
}

type IWorkPositionUpdateModalProps = IWorkPositionUpdateModalOwnProps & IWorkPositionUpdateModalInjectedProps & IWithLocalizeOwnProps;

export interface IWorkPositionUpdateModalStateProps {
}

const WorkPositionUpdateModal: React.FC<IWorkPositionUpdateModalProps> = (props: IWorkPositionUpdateModalProps) => {
  const [form] = Form.useForm<IWorkPosition>();

  const onFinish = (values: IWorkPosition) => {
    props.onSubmit?.(values);
  };

  const handleCancel = () => {
    if (form.isFieldsTouched()) {
      confirmationDialog({
        onConfirm: () => props.onCancelEdit?.(),
        title: props.translate('COMMON.CONFIRMATION_CANCEL_ACTION_MESSAGE'),
      });
    } else {
      props.onCancelEdit?.();
    }
  };

  return (
    <Modal
      className="lemon-modal__modal--md"
      title={props.translate('ADMINISTRATION.WORKPOSITION_UPDATE_MODAL_TITLE')}
      visible={true}
      okText={props.translate('COMMON.ACTION_SAVE')}
      onOk={form.submit}
      cancelText={props.translate('COMMON.ACTION_CANCEL')}
      onCancel={handleCancel}
    >
      <WorkPositionUpdateForm
        workPosition={props.workPosition}
        formRef={form}
        onFormFinish={onFinish}
      />
    </Modal>
  );
};

export default withLocalize<IWorkPositionUpdateModalOwnProps>(WorkPositionUpdateModal as any);
