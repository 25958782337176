import React, { createContext, useCallback, useContext, useState } from 'react';

import scriptLoader from '@src/components/common/script/scriptLoader';
import LemonError from '@src/service/common/LemonError';
import { Globals } from '@src/service/util/Globals';

export type IMathLibElement = React.ReactComponentElement<any, any>;

export interface IMathLib {
  renderContent: (elements: IMathLibElement[]) => Promise<any>;
  renderFormula: (formula: string) => Promise<string>;
}

const MathJaxContext = createContext<IMathLib | null>(null);


// -- Prop types
// ----------

export interface IMathProviderOwnProps {
  src?: string;
  options: Record<string, any>;
}

type IMathProviderProps = IMathProviderOwnProps;


// -- Component
// ----------

/**
 * Context provider that prepares math rendering environment eg. loads scripts, configures math lib, ... and provides math object to it's consumers.
 * Math provider is implemented using MathJax and it's mathml2chtml component.
 */
const MathProvider: React.FC<IMathProviderProps> = (props) => {
  const { options } = props;

  const [MathJax, setMathJax] = useState<any>(options);
  const [isMathJaxLoaded, setIsMathJaxLoaded] = useState(false);
  // original config object - MathJax lib expects a global config object which it later "enhances" to full blown MathJax management obj
  Globals.global.MathJax = MathJax;

  /** Async function that requires all math requirements (loading scripts, config, ...) and returns math management object. */
  const requireMathJax = useCallback(() => {
    if (isMathJaxLoaded) {
      return Promise.resolve(MathJax);
    } else {
      if (props.src == null) {
        throw new LemonError('Math script SRC is empty!');
      }

      // TODO: MathJax on startup automatically searches for mathml tags and performs typesetting, this should not be performed automatically but only on demand but could not find the right options to disable it

      const scriptUrls = [props.src];
      return scriptLoader(scriptUrls)
        .then(() => {
          console.log('Script(s) loaded ', scriptUrls);
          const newMathJax = Globals.global.MathJax;
          setMathJax(newMathJax); // put enhanced object back to state
          setIsMathJaxLoaded(true);

          return newMathJax;
        });
    }
  }, [MathJax, isMathJaxLoaded]);

  const MathJaxLibObj: IMathLib = {
    renderContent: (elements) => {
      return requireMathJax()
        .then((MathJaxInst) => {
          if (MathJaxInst == null || MathJaxInst.typesetPromise == null) {
            throw new LemonError('Math management object is empty or missing required method(s).');
          }

          // chain new typeset request to the one possibly already running (see: http://docs.mathjax.org/en/latest/web/typeset.html#typeset-async)
          MathJaxInst.startup.promise = MathJaxInst.startup.promise
            .then(() => {
              return MathJaxInst.typesetPromise(elements);
            });
          // request new typesetting on given elements
          return MathJaxInst.startup.promise;
        })
        .catch((e: any) => {
          // TODO: how to use app's logger instead of console
          console.error('Error typesetting math on elements', elements, e);
        });
    },

    renderFormula: (content) => {
      return requireMathJax()
        .then((MathJaxInst) => Promise.resolve(MathJaxInst.mathml2chtml(content)));
    },
  };

  // ----- render
  return (
    <MathJaxContext.Provider
      value={MathJaxLibObj}
      {...props}
    />
  );
};

export const useMathContext = () => useContext(MathJaxContext);

export default MathProvider;
