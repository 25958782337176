import React from 'react';

import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ExternalEducationInstanceList from '@src/components/externaleducationistance/ExternalEducationInstanceList';
import { IExternalEducationInstance } from '@src/model/externaleducationinstance/ExternalEducationInstance';
import { ICollectionData } from '@src/service/business/common/types';
import { IExternalEducationInstanceListFilter } from '@src/service/business/externaleducations/ExternalEducationInstanceListBusinessStore';
import { Button, Row } from 'antd';

// -- Prop types
// ----------

export interface IExternalEducationInstanceListViewOwnProps {
  externalEducationInstanceList: ICollectionData<IExternalEducationInstance>;
  filter?: IExternalEducationInstanceListFilter;
  canAdd?: boolean;
  onAdd?: () => void;
  canEdit?: boolean;
  onEdit?: (data: IExternalEducationInstance) => void;
  onPageChange?: (page: number, size?: number) => void;
}
type IExternalEducationInstanceListViewProps = IExternalEducationInstanceListViewOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Display external education Instance short info table */
const ExternalEducationInstanceListView: React.FC<IExternalEducationInstanceListViewProps> = (props: IExternalEducationInstanceListViewProps) => {
  return (
    <React.Fragment>
      {props.canAdd &&
        <React.Fragment>
          <Row className="timun-wrapper__gutterBox" justify="end">
            <Button icon={<LemonIcon name="plus" />} type="primary" onClick={props.onAdd}>
              {props.translate('EXTERNAL_EDUCATION_INSTANCE_LIST_VIEW.ADD_EXTERNAL_EDUCATION_INSTANCE_BUTTON_LABEL')}
            </Button>
          </Row>
        </React.Fragment>
      }

      <ExternalEducationInstanceList canEdit={props.canEdit} onEdit={props.onEdit} onPageChange={props.onPageChange} externalEducationInstanceList={props.externalEducationInstanceList} />
    </React.Fragment>
  );
};

export default withLocalize<IExternalEducationInstanceListViewOwnProps>(ExternalEducationInstanceListView as any);
