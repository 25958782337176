declare const require: any;

export const imagePathContext = require.context('@src/asset/img', true);

/**
 * Resolve image path against webpack context so their paths can be injected with generated file hash.
 *
 * Image file names must not start with relative path symbols ('.', '..' or '/') because image (asset) root path is assumed and calculated by app bundler (eg. webpack) during app bundling.
 * They can however containe subfolders of image (asset) root.
 *
 * Examples of file names:
 * * someImage.jsp - OK
 * * subfolder/someImage.png - OK
 * * ../someImage.png - NOK
 * * /some/folder/image.jpg - NOK
 *
 */
const imagePathResolver = (fileName: string): string => {
  return imagePathContext(`./${fileName}`, true);
};

export default imagePathResolver;
