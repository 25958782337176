import { Select } from 'antd';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import React from 'react';
import withLocalize, { IWithLocalizeOwnProps } from '../common/localize/withLocalize';

// -- Const
// ----------

const sortItems = [
  {
    id: 'addedDateTime,asc',
    translationKey: 'COMMON.ACTION_SORT_OLDEST',
  },
  {
    id: 'addedDateTime,desc',
    translationKey: 'COMMON.ACTION_SORT_NEWEST',
  },
  {
    id: 'upVoteCount,desc',
    translationKey: 'COMMENTS_VIEW.ACTION_SORT_POPULAR',
  },
];

const Option = Select.Option;

// -- PropTypes
// ----------

export interface ICommentListSorPickerOwnProps {
  changeSort: (sort: string) => void;
}

type ICommentListFilterProps = ICommentListSorPickerOwnProps & FormComponentProps & IWithLocalizeOwnProps;

// -- Component
// ----------

class CommentListSortPicker extends React.Component<ICommentListFilterProps> {
  render() {
    return (
      <div>
        {this.props.translate('COMMENTS_VIEW.ACTION_SORT_LABEL')}&nbsp;
        <Select defaultValue={sortItems[0].id} onChange={this.props.changeSort}>
          {sortItems.map((item) => (
            <Option key={item.id} value={item.id}>
              {' '}
              {this.props.translate(item.translationKey)}{' '}
            </Option>
          ))}
        </Select>
      </div>
    );
  }
}

// -- HOCs and exports
// ----------

export default withLocalize<ICommentListSorPickerOwnProps>(CommentListSortPicker as any);
