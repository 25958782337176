import { Form } from '@ant-design/compatible';

import { FormComponentProps } from '@ant-design/compatible/es/form';
import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';
import { CrmEditorConfig } from '@src/components/common/editor/CrmEditorConfigs';
import { Editor } from '@src/components/common/editor/Editor';

import FormItemLabelIcon from '@src/components/common/form/FormItemLabelIcon';
import { hasFormErrors } from '@src/components/common/form/validation';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { emailTemplateTypeEnum, EmailTemplateTypeEnum, IEmailTemplate } from '@src/model/emailtemplate/EmailTemplate';
import { IEmailTemplateCreatePayload } from '@src/service/business/emailtemplates/EmailTemplateBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { Input, Modal, Select } from 'antd';
import React, { SyntheticEvent } from 'react';

const FormItem = Form.Item;

// -- Const
// ----------
const MAX_NUMBER_OF_CHARACTER: number = AppConfigService.getValue('components.emailTemplateForm.maxChar');
const TITLE_MAX_LENGTH: number = AppConfigService.getValue('components.common.inputMaxLength');

const emailTemplateTypes = Object.keys(emailTemplateTypeEnum).map((key) => {
  return {
    name: key,
    id: emailTemplateTypeEnum[key],
  };
});

// -- Prop types
// ----------
interface IEmailTemplateFormOwnProps {
  onCancel?: () => void;
  onUpdate?: (emailTemplate: IEmailTemplate) => void;
  onCreate?: (EmailTemplateData: IEmailTemplateCreatePayload) => void;
  title: string;
  emailTemplate?: IEmailTemplate;
}

type IEmailTemplateFormProps = IEmailTemplateFormOwnProps & IWithLocalizeOwnProps & FormComponentProps;

// -- Component
// ----------
class EmailTemplateForm extends React.Component<IEmailTemplateFormProps, any> {
  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        title={this.props.title}
        visible={true}
        onCancel={this.handleCancel}
        onOk={this.handleSubmit}
        okButtonProps={{ disabled: !this.props.form.isFieldsTouched() || this.hasFormErrors() }}
        okText={this.props.translate('COMMON.ACTION_SAVE')}
        cancelText={this.props.translate('COMMON.ACTION_CANCEL')}>
        <Form layout="vertical">
          <FormItem label={this.props.translate('EMAIL_TEMPLATE_FORM.TITLE_LABEL')}>
            {getFieldDecorator('title', {
              initialValue: this.props.emailTemplate?.title,
              rules: [
                { whitespace: true, message: this.props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
                { required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') },
                { max: TITLE_MAX_LENGTH, message: this.props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') },
              ],
            })(<Input />)}
          </FormItem>

          <FormItem label={<FormItemLabelIcon label={this.props.translate('EMAIL_TEMPLATE_FORM.CONTENT_LABEL')}
            tooltipDescription={this.props.translate('COMMON.LABEL.MAX_LENGTH_CHARACTER_INFO_LABEL', { maxCharNum: MAX_NUMBER_OF_CHARACTER })} />}>
            {getFieldDecorator('content', {
              initialValue: this.props.emailTemplate?.content,
              rules: [
                { whitespace: true, message: this.props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
                { required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') },
                { max: MAX_NUMBER_OF_CHARACTER, message: this.props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') },
              ],
            })(<Editor
              init={{
                ...CrmEditorConfig.defaultConfig(),
                height: 300,
                menubar: true,
              }} />)}
          </FormItem>


          <Form.Item label={this.props.translate('EMAIL_TEMPLATE_FORM.TYPE_LABEL')}>
            {getFieldDecorator('type.id', {
              initialValue: this.props.emailTemplate?.type.id ?? EmailTemplateTypeEnum.CODE_GENERATION,
            })(
              <Select>
                {
                  emailTemplateTypes.map((type) => {
                    return <Select.Option key={type.id} value={type.id}>
                      {this.props.translate(`EMAIL_TEMPLATE_FORM.${type.name}_TYPE`)}
                    </Select.Option>;
                  })
                }
              </Select>
            )
            }
          </Form.Item>

          <FormItem className="hidden">
            {getFieldDecorator('id', {
              initialValue: this.props.emailTemplate?.id,
            })(<Input type="hidden" />)}
          </FormItem>
        </Form>
      </Modal>
    );
  }

  handleCancel = () => {
    if (this.props.form.isFieldsTouched()) {
      confirmationDialog({
        onConfirm: () => this.props.onCancel?.(),
        title: this.props.translate('COMMON.CONFIRMATION_CANCEL_ACTION_MESSAGE'),
      });
    } else {
      this.props.onCancel?.();
    }
  };

  handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        const emailTemplateData = { ...values };
        if (this.props.emailTemplate) {
          this.props.onUpdate?.(emailTemplateData);
        } else {
          this.props.onCreate?.(emailTemplateData);
        }
      }
    });
  };

  hasFormErrors = (): boolean => {
    const { form } = this.props;
    const fieldsError: any = form.getFieldsError();
    return hasFormErrors(fieldsError);
  };

}

// -- HOCs and exports
// ----------

const EmailTemplateFormWrapper = Form.create()(EmailTemplateForm);

export default withLocalize<IEmailTemplateFormOwnProps>(EmailTemplateFormWrapper as any);
