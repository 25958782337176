import React from 'react';

import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

export type IInitializerFn = () => Observable<boolean>;

export interface IInitializerRouteGuardPublicProps {
  initializer: IInitializerFn;
}
export interface IInitializerRouteGuardState {
  initialized?: boolean;
}

type IInitializerRouteGuardProps = IInitializerRouteGuardPublicProps;

/**
 * Show child component if and when initializer fn ginishes. Initializer fn is a function that returns Observable to which
 * this guard cmpnt subscribes. After Observable finishes this components shows it's children.
 */
class InitializerRouteGuard extends React.Component<IInitializerRouteGuardProps, IInitializerRouteGuardState> {
  state: IInitializerRouteGuardState = {};

  componentDidMount() {
    this.initialize();
  }

  render() {
    return <React.Fragment>{this.state.initialized && this.props.children}</React.Fragment>;
  }

  private initialize() {
    const initializer = this.getInitializer();
    if (initializer == null) {
      console.warn('Route initializer guard fn is empty. Did you forget to pass initializer fn through props?');
      return;
    }

    initializer()
      .pipe(
        finalize(() => {
          console.log('Initialized completed');
          this.setState({
            initialized: true,
          });
        })
      )
      .subscribe();
  }

  private getInitializer(): IInitializerFn {
    // TODO: find type for route props to avoid any
    return this.props.initializer || (this.props as any).route.props.initializer;
  }
}

export default InitializerRouteGuard;

// ----- withInitializerRouteGuard

/** Higher order component for wrapping existing component with route guard. */
const withInitializerRouteGuard = <P extends object>(Component: React.ComponentType<P>, initializer: IInitializerFn) =>
  // tslint:disable-next-line:max-classes-per-file - don't want to declare this in a new file since it is only a util
  class WrappedComponent extends React.Component<P> {
    render() {
      // TODO: find how to type router Route props
      const wrappedProps = (this.props as any).route.props;
      return (
        <InitializerRouteGuard {...wrappedProps} initializer={initializer}>
          <Component {...this.props} />
        </InitializerRouteGuard>
      );
    }
  };

export { withInitializerRouteGuard };
