import { message } from 'antd';
import React from 'react';

import { IUserFeedbackMessage, UserFeedbackMessageSeverity } from '@src/service/business/common/types';

// --
// ---------- Prop types

export interface IUserMessagesPublicProps {
  messages: IUserFeedbackMessage[];
}
type IUserMessagesProps = IUserMessagesPublicProps;

interface IUserMessagesState {
  mesageResult: (() => void) | null;
}

// --
// ---------- Component

// Ant's message API is not declarative but imperative so this is not visual component but we're using only component's mount lifecyle methods to create/destroy message
class UserMessageItem extends React.PureComponent<{ message: IUserFeedbackMessage }, IUserMessagesState> {
  state: IUserMessagesState = {
    // message result is function returned when creating message and is used to close that same message
    mesageResult: null,
  };

  componentDidMount() {
    // create appropriate message type once, on component mount
    let messageCallback;
    const messageText = <span data-test-id={`lemon-feedbackMessage_${UserFeedbackMessageSeverity[this.props.message.severity]}`}>{this.props.message.message}</span>;
    if (UserFeedbackMessageSeverity[UserFeedbackMessageSeverity.ERROR] === UserFeedbackMessageSeverity[this.props.message.severity]) {
      messageCallback = message.error(messageText, 0);
    } else if (UserFeedbackMessageSeverity[UserFeedbackMessageSeverity.WARNING] === UserFeedbackMessageSeverity[this.props.message.severity]) {
      messageCallback = message.warn(messageText, 0);
    } else if (UserFeedbackMessageSeverity[UserFeedbackMessageSeverity.SUCCESS] === UserFeedbackMessageSeverity[this.props.message.severity]) {
      messageCallback = message.success(messageText, 0);
    } else if (UserFeedbackMessageSeverity[UserFeedbackMessageSeverity.INFO] === UserFeedbackMessageSeverity[this.props.message.severity]) {
      messageCallback = message.info(messageText, 0);
    } else {
      console.warn(`Unknown message severity: ${this.props.message.severity}. Defaulting to INFO.`);
      messageCallback = message.info(messageText, 0);
    }

    this.setState({ mesageResult: messageCallback });
  }

  componentWillUnmount() {
    // close message - simply called callback function
    if (this.state.mesageResult != null) {
      (this.state.mesageResult as any)();
    }
  }

  render() {
    return <React.Fragment />;
  }
}

// tslint:disable-next-line:max-classes-per-file - component UserMessageItem was created purely to use component's lifecycle (see UserMessageItem for more info), so this rule was nagging me
export default class UserMessages extends React.PureComponent<IUserMessagesProps> {
  render() {
    return (
      <React.Fragment>
        {this.props.messages.map((item) => (
          <UserMessageItem key={item.id} message={item} />
        ))}
      </React.Fragment>
    );
  }
}
