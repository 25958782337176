import { IReminder } from '@src/model/reminder/Reminder';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { IIdPayload, IPayloadAction, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import { actionThunk, startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage, reportMessage } from '@src/service/util/observable/operators/userFeedback';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, mergeMap } from 'rxjs/operators';

// -
// -------------------- Actions

const Actions = {
  REMINDER_CREATE: 'REMINDER_CREATE',
  REMINDER_UPDATE: 'REMINDER_UPDATE',
  REMINDER_DELETE: 'REMINDER_DELETE',
};

/** Create new reminder. */
const createReminder = (data: IReminder): IPayloadAction<IReminder> => {
  return {
    type: Actions.REMINDER_CREATE,
    payload: data,
  };
};

/** Update existing reminder */
const updateReminder = (data: IReminder): IPayloadAction<IReminder> => {
  return {
    type: Actions.REMINDER_UPDATE,
    payload: data,
  };
};

/** Delete reminder by id */
const deleteReminder = (data: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.REMINDER_DELETE,
    payload: data,
  };
};

// -
// -------------------- Effects

const createReminderEffect = (action$: Observable<IPayloadAction<IReminder>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.REMINDER_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Reminder')
        .createEntity(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    reportMessage((value) => {
      return { message: LocalizeService.translate('REMINDER.CREATED_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS };
    }),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating reminder', error);
      return o;
    }),
  );
};

const updateReminderEffect = (action$: Observable<IPayloadAction<IReminder>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.REMINDER_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('Reminder')
        .updateEntity(data.id, data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    reportMessage((value) => {
      return { message: LocalizeService.translate('COMMON.EDITED_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS };
    }),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating reminder', error);
      return o;
    }),
  );
};

const deleteReminderEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.REMINDER_DELETE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('Reminder')
        .deleteEntity(data.id)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    reportMessage((value) => {
      return { message: LocalizeService.translate('REMINDER.DELETED_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.INFO };
    }),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_DELETE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error deleting reminder', error);
      return o;
    }),
  );
};

// --
// -------------------- Business Store

const ReminderBusinessStore = {
  actions: {
    createReminder,
    updateReminder,
    deleteReminder,
  },

  selectors: {},

  effects: {
    createReminderEffect,
    updateReminderEffect,
    deleteReminderEffect,
  },

  reducers: {},
};

export default ReminderBusinessStore;
