import React, { ReactNode } from 'react';

import { Form } from '@ant-design/compatible';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ICourseContent } from '@src/model/course/Content';

import { FormComponentProps } from '@ant-design/compatible/lib/form/Form';
import { Tabs } from 'antd';

// -- Consts
// ----------

const TabPane = Tabs.TabPane;

// -- Interfaces
// ----------

export interface ICourseContentPreview {
  title?: string;
  body: string | ReactNode;
}

export type ICourseContentPreviewRenderer = (content: Partial<ICourseContent>) => ICourseContentPreview;
export type ICourseContentEditRenderer = (content: Partial<ICourseContent>, handleBodyChange: (contentBody: string) => void) => ICourseContentPreview;

// -- Prop types
// ----------

export interface ICourseContentControlOwnProps {
  value?: Partial<ICourseContent> | null;
  renderPreview: ICourseContentPreviewRenderer;
  renderEdit: ICourseContentEditRenderer;
  dataTestId?: string;

  onChange?: (content: Partial<ICourseContent>) => void;
}
export interface ICourseContentControlStateProps { }
export interface ICourseContentControlDispatchProps { }

type ICourseContentControlProps = ICourseContentControlOwnProps & ICourseContentControlStateProps & ICourseContentControlDispatchProps & IWithLocalizeOwnProps & FormComponentProps;

interface ICourseContentControlState {
  content: Partial<ICourseContent>;
}

// TODO: prevent rendering preview on each input
// for some reason this textarea triggers "change" event on each enter, instead of on blur, thus rendering preview
// also, preview tab is rerendered even if it's invisible, so, peview is rerendered also on each input

/** Form control for editing course content objects. Takes and returns entire content object. */
class CourseContentControl extends React.Component<ICourseContentControlProps, ICourseContentControlState> {
  static getDerivedStateFromProps(nextProps: ICourseContentControlProps): ICourseContentControlState | null {
    if (nextProps.value) {
      return {
        content: { ...nextProps.value },
      };
    }

    return null;
  }

  state: ICourseContentControlState = {
    content: {},
  };

  handleBodyChange = (e: string) => {
    const newContent = { ...this.state.content, body: e };

    this.setState({ content: newContent });

    this.triggerChange(newContent);
  };

  triggerChange = (changedValue: Partial<ICourseContent>) => {
    if (this.props.onChange) {
      this.props.onChange(changedValue);
    }
  };

  /** Call preivew renderer function */
  renderPreview(): ICourseContentPreview {
    return this.props.renderPreview(this.state.content);
  }

  renderEdit = (): ICourseContentPreview => {
    return this.props.renderEdit(this.state.content, this.handleBodyChange);
  };

  render() {
    const edit = this.renderEdit();
    const preview = this.renderPreview();

    return (
      <Tabs>
        {edit != null && (
          <TabPane tab={<span data-test-id={`${this.props.dataTestId || 'timun-courseUpdate__content'}_edit`}>{this.props.translate('COURSE_VIEW.UPDATE.COURSE_CONTENT_EDIT_LABEL')}</span>} key="1">
            {edit.body}
          </TabPane>
        )}

        {/* ----- render preview ----- */}
        {preview != null && (
          <TabPane tab={<span data-test-id={`${this.props.dataTestId || 'timun-courseUpdate__content'}_preivew`}>{preview.title || this.props.translate('COURSE_VIEW.UPDATE.COURSE_CONTENT_PREVIEW_LABEL')}</span>} key="2">
            {this.state.content.body != null && <div>{preview.body}</div>}
          </TabPane>
        )}
      </Tabs>
    );
  }
}

export default withLocalize<ICourseContentControlOwnProps>(Form.create()(CourseContentControl) as any);
