import { Form } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { validateOib } from '@src/components/common/form/validation';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ICompany } from '@src/model/company/Company';
import { ContactDataTypeEnum } from '@src/model/user/ContactData';
import AppConfigService from '@src/service/common/AppConfigService';
import { Col, Input, Row } from 'antd';
import { debounce } from 'lodash';
import React from 'react';

export interface ICompanyDataFormOwnProps {
  company?: ICompany;
  onChange?: (data: any) => void;
}

type ICompanyDataFormProps = ICompanyDataFormOwnProps & FormComponentProps & IWithLocalizeOwnProps;

/** Company data form that triggers onChange once all required fields are validated */
class CompanyDataForm extends React.Component<ICompanyDataFormProps> {
  componentDidUpdate = (prevProps: ICompanyDataFormProps) => {
    if (JSON.stringify(this.props.company) !== JSON.stringify(prevProps.company)) { // after form change, company is always a new object
      this.props.form.resetFields();
    }
  };

  render = () => {
    const { getFieldDecorator } = this.props.form;

    const formDisabled = this.props.company?.cesCompanyId != null || this.props.company?.id != null;

    return <Form layout="vertical" name="company">
      <Row gutter={12}>
        {/* Company name */}
        <Col xs={24} md={12}>
          <Form.Item label={this.props.translate('COMPANY.FORM.NAME_LABEL')}>
            {
              getFieldDecorator('name', {
                initialValue: this.props.company?.name,
                rules: [
                  { required: true, message: this.props.translate('COMPANY.FORM.NAME_VALIDATION_MESSAGE') },
                ],
              })(
                <Input disabled={formDisabled} data-test-id="timun-companyForm__name"/>,
              )
            }
          </Form.Item>
        </Col>
        {/* Company identification number */}
        <Col xs={24} md={12}>
          <Form.Item label={this.props.translate('COMPANY.FORM.ID_LABEL')}>
            {
              getFieldDecorator('identificationNumber', {
                initialValue: this.props.company?.identificationNumber,
                rules: [
                  { validator: validateOib, required: true, message: this.props.translate('COMPANY.FORM.ID_VALIDATION_MESSAGE') },
                ],
              })(
                <Input disabled={formDisabled} data-test-id="timun-companyForm__identificationNumber"/>,
              )
            }
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={12}>
        <Col xs={24} md={16}>
          {/* Company city location */}
          <Form.Item label={this.props.translate('COMPANY.FORM.CITY_LABEL')}>
            {
              getFieldDecorator('address.city', {
                initialValue: this.props.company?.address.city,
                rules: [
                  { required: true, message: this.props.translate('COMPANY.FORM.CITY_VALIDATION_MESSAGE') },
                ],
              })(<Input disabled={formDisabled} data-test-id="timun-companyForm__city"/>)
            }
          </Form.Item>
        </Col>
        <Col xs={24} md={8}>
          {/* Company zip code */}
          <Form.Item label={this.props.translate('COMPANY.FORM.ZIP_LABEL')}>
            {
              getFieldDecorator('address.zip', {
                initialValue: this.props.company?.address.zip,
                rules: [
                  { required: true, message: this.props.translate('COMPANY.FORM.ZIP_VALIDATION_MESSAGE') },
                ],
              })(<Input disabled={formDisabled} data-test-id="timun-companyForm__zip"/>)
            }
          </Form.Item>
        </Col>
        <Col xs={24} md={24}>
          {/* Company address */}
          <Form.Item label={this.props.translate('COMPANY.FORM.STREET_LABEL')}>
            {
              getFieldDecorator('address.streetWithHomeNumber', {
                initialValue: this.props.company?.address.streetWithHomeNumber,
                rules: [
                  { required: true, message: this.props.translate('COMPANY.FORM.ADDRESS_VALIDATION_MESSAGE') },
                ],
              })(<Input disabled={formDisabled} data-test-id="timun-companyForm__streetWithHomeNumber"/>)
            }
          </Form.Item>
        </Col>
        {/* Company country */}
        {
          getFieldDecorator('address.country', {
            initialValue: this.props.company?.address.country,
          })(<Input type="hidden"/>)
        }
      </Row>

      <Row gutter={12}>
        <Col xs={24} md={12}>
          {/* Company e-mail */}
          <Form.Item label={this.props.translate('COMPANY.FORM.CONTACT_LABEL')}>
            {
              getFieldDecorator('contactData[0].value', {
                initialValue: this.getEmailValue(),
                rules: [
                  { required: true, message: this.props.translate('COMPANY.FORM.CONTACT_VALIDATION_MESSAGE') },
                  { type: 'email', message: this.props.translate('COMPANY.FORM.VALID_EMAIL_MESSAGE') },
                ],
              })(<Input data-test-id="timun-companyForm__contactDataValue"/>)
            }
          </Form.Item>
        </Col>
        {
          getFieldDecorator('contactData[0].type.id', {
            initialValue: ContactDataTypeEnum.EMAIL,
          })(<Input type="hidden"/>)
        }
      </Row>
    </Form>;
  };

  getEmailValue = (): string => {
    if (this.props.company) {
      const email = this.props.company.contactData?.find((contact) => contact.type.id === ContactDataTypeEnum.EMAIL);
      if (email) {
        return email.value;
      }
    }
    return '';
  };
}

const CompanyDataFormWrapper = Form.create<ICompanyDataFormProps>({
  onValuesChange: debounce((props) => {
    if (props.onChange) {
      props.form.validateFields((err: any, values: any) => {
        if (!err) {
          props.onChange(values);
        }
      });
    }
  }, AppConfigService.getValue('components.common.debounceTimeout')),
})(CompanyDataForm);

export default withLocalize<ICompanyDataFormOwnProps>(CompanyDataFormWrapper as any);
