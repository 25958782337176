import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { Button, Col, Row, Tooltip, Typography } from 'antd';
import moment, { Moment } from 'moment';
import React from 'react';

const monthFormat = 'MMMM';
const monthYearFormat = 'MMMM YYYY.';

// -- Prop types
// ----------

export interface ICalendarNavigationOwnProps {
  currentDate: Moment;

  onDateChange?: (date: Moment) => void;
}
type ICalendarNavigationProps = ICalendarNavigationOwnProps & IWithLocalizeOwnProps;

interface ICalendarNavigationState {
  displayDate: string | undefined;
}

// -- Component
// ----------

/** Describe your component */
class CalendarNavigation extends React.Component<ICalendarNavigationProps, ICalendarNavigationState> {
  state: ICalendarNavigationState = {
    displayDate: undefined,
  };

  componentDidMount() {
    this.setState(this.deriveStateFromProps(this.props, null));
  }

  componentDidUpdate(prevProps: ICalendarNavigationProps) {
    this.setState(this.deriveStateFromProps(this.props, prevProps));
  }

  deriveStateFromProps(nextProps: ICalendarNavigationProps, prevProps: ICalendarNavigationProps | null): Pick<ICalendarNavigationState, 'displayDate'> | null {
    if (prevProps == null || nextProps !== prevProps) {
      return {
        displayDate: nextProps.currentDate.format(monthYearFormat),
      };
    }

    return null;
  }

  render = () => {
    return (
      <Row align="middle" justify="space-between" className="timun-reminderCalendar__navigation">
        <Col>
          <Tooltip title={this.getPreviousButtonLabel(this.props.currentDate)}>
            <Button disabled={this.isPreviousMonthDisabled()} className="timun-reminderCalendar__previousMonth" type="link" onClick={() => this.handleDateChangeClick(-1)}>
              <LeftOutlined />
            </Button>
          </Tooltip>
        </Col>
        <Col className="timun-reminderCalendar__currentMonthContainer">
          <Typography.Title className="timun-reminderCalendar__currentMonth" level={4}>{this.state.displayDate}</Typography.Title>
        </Col>
        <Col>
          <Tooltip title={this.getNextButtonLabel(this.props.currentDate)}>
            <Button className="timun-reminderCalendar__nextMonth" type="link" onClick={() => this.handleDateChangeClick(1)}>
              <RightOutlined />
            </Button>
          </Tooltip>
        </Col>
      </Row>
    );
  };

  handleDateChangeClick = (amount: number) => {
    if (this.props.onDateChange) {
      const nextDate = moment(this.props.currentDate).add(amount, 'month');
      this.props.onDateChange(nextDate);
    }
  };

  isPreviousMonthDisabled = () => {
    const currentMonth = moment().startOf('month');
    const previousMonth = moment(this.props.currentDate).add(-1, 'month');

    return previousMonth.isBefore(currentMonth);

  };

  getPreviousButtonLabel = (currentDate: Moment) => {
    const previousMonthName = moment(currentDate).add(-1, 'month').format(monthFormat);
    return this.props.translate('REMINDER_CALENDAR.PREVIOUS_MONTH', { month: previousMonthName });
  };

  getNextButtonLabel = (currentDate: Moment) => {
    const nextMonthName = moment(currentDate).add(1, 'month').format(monthFormat);
    return this.props.translate('REMINDER_CALENDAR.NEXT_MONTH', { month: nextMonthName });
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<ICalendarNavigationOwnProps>(CalendarNavigation as any);
