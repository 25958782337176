// @ts-nocheck

const TIMUN_APP_CONFIG = {
  app: {
    environment: 'dev',

    name: 'Timun',

    defaultLocale: 'hr',
    defaultTimeZone: 'CET',
    defaultStyleTheme: 'default',

    basePath: '',
  },

  // ---------- REST API config
  api: {
    // API base URL
    baseUrl: '',

    // API source url parts
    sourceUrl: '/api',
    publicSourceUrl: '/api/public',

    // default resource URL for fetching files
    fileResourceUrl: '/files',

    // API by default returns limited number of list items (eg 20), so if we want to fetch them all we have to workaround it by setting limit high enough
    collectionDefaultLimit: 1000,

    // default params sent with every request
    defaultParams: {},

    paging: {
      gridRowPageSize: 4,
      smallPageSize: 5,
      midPageSize: 12,
      defaultPageSize: 20,
      maxPageSize: 100,
    },

    sorting: {
      byDate: 'requestedDateTime,desc',
    },

    upload: {
      maxFileSize: 100 * 1024 * 1024, // file size in bytes
      allowedImageTypes: ['image/png', 'image/jpe?g'],
      allowedVideoTypes: ['video/.+', 'application/.+mpegurl'],
    },
  },

  // ---------- quizzler config
  quizzler: {
    questionTypes: {
      surveyQuestionList: ['ESSAY', 'LINEAR_SCALE', 'GRID'],
      examQuestionList: ['YES_NO', 'SINGLE_ANSWER', 'MULTIPLE_ANSWERS', 'WORD_INSERT', 'ESSAY', 'NUMERIC_RANGE', 'PAIR_DRAG', 'ELEMENT_ORDER', 'FILE', 'PICTURE_DRAG'],
    },
  },

  // ---------- tenat config
  tenant: {
    mappings: [
      // anything to 1 value
      { pattern: '.*', value: 'LamDig' },

      // --- mapping examples
      // extract subdomain
      // { pattern: '(.+)\\.example\\.com', value: '$1' }, // aaa.example.com -> aaa
      // extract part of URL and prefix it with static string
      // { pattern: 'www\\.(first)example\\.com', value: '$1-order' }, // www.firstexample.com -> first-order
      // multiple 1-to-1 mappings
      // { pattern: '.*\\.example1\\.com', value: 'example1' }, // www.example1.com -> example1
      // { pattern: 'www\\.example2\\.com', value: 'example2' }, // www.example2.com -> example2
    ],
  },

  // ---------- layout stuff
  layout: {
    table: {
      scrollSize: 768, // size of antd table scroll (see antd docs for details)
    },
  },

  // ----- logging configs
  logging: {
    logLevel: 'debug', // see loglevel LogLevelDesc for details

    redux: {
      enabled: true,

      // See: https://github.com/LogRocket/redux-logger#options
      config: {
        collapsed: true,
      },
    },

    sentry: {
      enabled: false,
      config: {
        dsn: '<DSN>',
      },
    },
  },

  // ---------- Authentication config
  authentication: {
    token: {
      name: 'sid',
      duration: 365 * 24 * 60 * 60 * 1000, // 1 year
    },

    keycloak: {
      init: {
        onLoad: 'check-sso',
        silentCheckSsoRedirectUri: '/static/keycloak/sso.html',
        promiseType: 'native',
      },
      config: {
        realm: 'ld-timun-dev',
        clientId: 'ld-fe-client-localdev',
        // keycloak checks if auth server's URL is them same one that created token for FE and the one which checked token on BE
        // thus, we (FE and BE) have to use the same external address of auth server
        url: 'https://ld-test.int.sedamit.hr/auth/',
      },
    },
  },

  // ---------- Cookie config
  cookies: {
    tenant: {
      name: 'TenantID',
      duration: 365 * 24 * 60 * 60 * 1000, // 1 year
    },
    locale: {
      name: 'locale',
      duration: 30 * 24 * 60 * 60 * 1000, // 1 month
    },
  },

  // ---------- Routing config

  routing: {
    // routes allowed for anonymous users
    publicRoutes: ['/home', '/public'],
    // default route for anonymous users
    publicDefaultRoute: '/public',
    // default route for authenticated users
    // NOTE: when external auth (eg. keycloak) lets user in but user isn't recognized by the app (eg. user is missing from app), this route should be
    // set to something public to avoid endless redirect loop
    authDefaultRoute: '/dashboard',

    // these should be prefixed with "application.staticBaseUrl" value
    loginPage: '/login.html',
    errorPage: '/error.html',
  },

  // ---------- External content
  external: {
    integrationVideo: {
      vimeo: {
        baseUrl: 'https://player.vimeo.com/',
        config: {
          // for available options see:
          // https://github.com/CookPete/react-player#config-prop
          // https://developer.vimeo.com/player/sdk/embed
          vimeo: {
            title: false,
          },
        },
      },
    },
  },

  // TODO: make formats linked to defaultLocale

  // ---------- Date and time formats
  dateTimeFormat: {
    // TODO: unify backend date format, make all with or without timezone
    backendDate: 'YYYY-MM-DDTHH:mm:ss',
    backendDateWithTimezone: 'YYYY-MM-DDTHH:mm:ssZ', // used in UserProfileDetails
    backendQueryParamDateFormat: 'YYYY-MM-DD',
    localeDate: 'L',
    localeTime: 'LT',
    localeDateTime: 'L LT',
    dateWithTime: 'DD.MM.YYYY. HH:mm',
    dateWithYear: 'DD.MM.YYYY.',
    dateWithoutDay: 'MM-YYYY.',
    monthNameWithYear: 'MMM YYYY',
    year: 'YYYY',
    date: 'DD.MM.',
    time: 'HH:mm',
    timeWithSeconds: 'HH:mm:ss',
    dayOfWeekName: 'dddd',
    dateDayOfWeekName: 'DD. dddd',
    dayOfMonthAndMonthName: 'DD. MMMM',
  },

  // ---------- Number formats
  numberFormat: {
    decimalSeparator: ',', // used in antd InputNumber
    currency: '(0,0.00)', // numeral doesn't handle symbols well, so only number format here
    bytes: '0 b',
    percentage: '0%',
    time: '00:00:00',
    exponential: '0e+0',
    roundCurrency: '0.00', // antd charts can accept only number
  },

  // ---------- Currency and their formats
  currencyFormat: {
    currencySymbol: 'kn',
  },

  // ---------- Username, password and email minimum length
  userValidation: {
    minUsernameLength: 5,
    minPasswordLength: 8,
    maxPasswordLength: 32,
    nameLengthMax: 128,
    minEmailLength: 256,
  },

  // ----- Various UI configs for components. Keys should follow component folders.
  components: {
    common: {
      debounceTimeout: 500,
      minSearchStringLength: 3,
      smallCharacterLengthInput: 64,
      mediumCharacterLengthInput: 128,
      defaultCharacterLengthInput: 255,
      bigCharacterLengthInput: 512,
    },

    userForm: {
      minZipValue: 10000,
      maxZipValue: 99999,
    },

    externalEducationApplication: {
      minDescriptionLength: 750,
    },

    userGroups: {
      adminUserGroupRouterProp: 'administration/usergroups',
      userGroupRouterProp: 'usergroups',
    },

    courses: {
      courseAboutRouterProp: 'about',
      courseNotesRouterProp: 'notes',
      courseExamTemplateRouterProp: 'examTemplate',
      courseExamInstanceRouterProp: 'examInstance',
      minOrderingValue: 1,
    },

    termsOfAgreement: {
      maxChar: 10000,
    },

    userNoteForm: {
      maxChar: 255,
    },

    emailTemplateForm: {
      maxChar: 2500,
    },

    repository: {
      debounceTime: 500,
    },

    notification: {
      sort: {
        date: {
          ascend: {
            id: 'metadata_createdAt,asc',
          },
          descend: {
            id: 'metadata_createdAt,desc',
          },
        },
        title: {
          ascend: {
            id: 'metadata_title,asc',
          },
          descend: {
            id: 'metadata_title,desc',
          },
        },
      },
    },
  },

  // ------- TinyMCE configs
  tinyEditor: {
    external_plugins: {
      ldformulaeditor: '/static/tinymce/plugin/ldformulaeditor/plugin.js',
    },
    plugins: ['advlist autolink lists link image charmap print preview anchor searchreplace visualblocks code fullscreen insertdatetime media table paste code help wordcount ldformulaeditor'],
    toolbar: 'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ldformulaeditor_insert | help',
    contextmenu: 'ldlink image imagetools table ldformulaeditor',
    entity_encoding: 'raw', // prevent editor from encoding non-ascii characters
  },

  // ----- iMathEQ config
  imatheq: {
    apiKey: '<API_KEY>',
    lang: 'EN', // make sure imatheq supports configured locale
  },

  // ----- Draw.io config
  drawio: {
    integration: {
      // more info: https://desk.draw.io/support/solutions/articles/16000042546-supported-url-parameters
      drawioUrl: 'https://draw.io', // '/static/drawio/webapp/index.html',
      theme: 'min', // min|atlas|dark (default: Kennedy)
      language: 'en',
      showSpinner: true,
      noSaveBtn: true,
      // libs: '', // enable all libs by default
    },
    editors: {
      default: {
        libs: 'uml;arrows',
      },
    },
  },

  // ----- Math config
  math: {
    src: '/static/mathjax/es5/mml-chtml.js', // scripts are copied to /static using webpack
    options: {},
  },

  // ----- Course management options
  courseManagement: {
    // is manually adding participants to courses enabled (as opposed to adding participants to courses automatically according to their properties)
    manualParticipationEnabled: true,
  },
};

export default TIMUN_APP_CONFIG;
