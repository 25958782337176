import { Form } from '@ant-design/compatible';

import { FormComponentProps } from '@ant-design/compatible/es/form';
import FormItemLabelIcon from '@src/components/common/form/FormItemLabelIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { INotification, NotificationTypeEnum } from '@src/model/notification/Notification';
import { INotificationCreatePayload } from '@src/service/business/notification/notificationViewBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { Input, Modal } from 'antd';
import React, { SyntheticEvent } from 'react';

// -- Const
// ----------
const TextArea = Input.TextArea;
const TEXT_AREA_MAX_LENGTH = AppConfigService.getValue('components.common.bigCharacterLengthInput');
const TITLE_MAX_LENGTH = AppConfigService.getValue('components.common.smallCharacterLengthInput');

// -- Prop types
// ----------
interface INotificationFormOwnProps {
  onCreateSubmit: (data: INotificationCreatePayload) => void;
  onUpdateSubmit: (data: INotification) => void;
  onClose: () => void;
  notification?: INotification;
}

type INotificationFormProps = INotificationFormOwnProps & IWithLocalizeOwnProps & FormComponentProps;

interface INotificationFormState {
  modalVisible: boolean;
}

// -- Component
// ----------

/** Display modal with notification form */
class NotificationForm extends React.Component<INotificationFormProps, INotificationFormState> {
  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal visible={true} title={this.props.translate('NOTIFICATION.NEW_NOTIFICATION')} onCancel={this.handleCancel} onOk={this.handleSubmit}>
        <Form>
          <Form.Item label={this.props.translate('NOTIFICATION_FORM.TITLE_LABEL')}>
            {getFieldDecorator('title', {
              initialValue: this.props.notification && this.props.notification.title,
              rules: [
                { whitespace: true, message: this.props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
                { required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') },
                { max: TITLE_MAX_LENGTH, message: this.props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') },
              ],
            })(<Input placeholder={this.props.translate('NOTIFICATION.TITLE')} />)}
          </Form.Item>
          <Form.Item label={<FormItemLabelIcon label={this.props.translate('NOTIFICATION_FORM.MESSAGE_LABEL')}
            tooltipDescription={this.props.translate('COMMON.LABEL.MAX_LENGTH_CHARACTER_INFO_LABEL', { maxCharNum: TEXT_AREA_MAX_LENGTH })} />}>
            {getFieldDecorator('message', {
              initialValue: this.props.notification && this.props.notification.message,
              rules: [
                { whitespace: true, message: this.props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
                { required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') },
                { max: TEXT_AREA_MAX_LENGTH, message: this.props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') },
              ],
            })(<TextArea placeholder={this.props.translate('NOTIFICATION.TEXT')} />)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }

  handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        const data = {
          ...values,
          type: { id: NotificationTypeEnum.GENERIC },
        };

        if (this.props.notification) {
          if (this.props.onUpdateSubmit) {
            const newNotificationData: INotification = {
              id: this.props.notification.id,
              ...data,
            };

            this.props.onUpdateSubmit(newNotificationData);
          }
        } else {
          if (this.props.onCreateSubmit) {
            this.props.onCreateSubmit(data);
          }
        }
      }
    });
  };

  handleCancel = () => {
    this.props.onClose();
  };
}

// -- HOCs and exports
// ----------

const NotificationFormComponent = Form.create()(NotificationForm as any);

export default withLocalize<INotificationFormOwnProps>(NotificationFormComponent as any);
