import AppConfigService from '@src/service/common/AppConfigService';
import moment from 'moment';
import { MomentInput } from 'moment';

const backendDateFormat = AppConfigService.getValue('dateTimeFormat.backendDate');
const dateWithTime = AppConfigService.getValue('dateTimeFormat.dateWithTime');
const dateWithYear = AppConfigService.getValue('dateTimeFormat.dateWithYear');
const dateWithoutDay = AppConfigService.getValue('dateTimeFormat.dateWithoutDay');

export type IDateFormat = 'dateWithTime' | 'dateWithYear' | 'dateWithoutDay';

export class DateUtils {
  /** Format date to backend format */
  static toBackendDateFormat = (date: MomentInput): string => {
    return moment(date).format(backendDateFormat);
  };

  /** Format BE date to view format */
  static toViewDateFormat = (date: MomentInput, format: IDateFormat): string => {
    const getSelecteFormat = () => {
      if (format === 'dateWithTime') {
        return dateWithTime;
      } else if (format === 'dateWithYear') {
        return dateWithYear;
      } else if (format === 'dateWithoutDay') {
        return dateWithoutDay;
      }
    };
    return moment(date).format(getSelecteFormat());
  };
}
