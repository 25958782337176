import React, { useState } from 'react';

import { FormComponentProps } from '@ant-design/compatible/lib/form';
import EditableWrapper from '@src/components/common/container/EditableWrapper';
import { CrmEditorConfig } from '@src/components/common/editor/CrmEditorConfigs';
import { Editor } from '@src/components/common/editor/Editor';
import FormItemLabelIcon from '@src/components/common/form/FormItemLabelIcon';
import EditorAvatar from '@src/components/common/image/EditorAvatar';
import LemonIcon from '@src/components/common/image/LemonIcon';
import LemonImage from '@src/components/common/image/LemonImage';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import ImageUploadForm from '@src/components/common/upload/ImageUploadForm';
import { IFile } from '@src/model/file/File';
import ITenantOrganizationConfiguration from '@src/model/tenant/TenantOrganizationConfiguration';
import { ITenantOrganizationConfigurationUpdatePayload } from '@src/service/business/tenant/tenantOrganizationConfigurationBussinessService';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, Col, ColProps, Form, Input, Row, Tooltip } from 'antd';

// -- Const
// ----------
const TextArea = Input.TextArea;
const MAX_NUMBER_OF_CHARACTER: number = AppConfigService.getValue('components.termsOfAgreement.maxChar');
const GRID_LAYOUT_LABEL_LAYOUT: ColProps = { xs: 24, sm: 10, md: 8 };
const GRID_LAYOUT_WRAPPER_LAYOUT: ColProps = { xs: 24, sm: 12, md: 12 };
const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};

type Editors = keyof Pick<ITenantOrganizationConfiguration['configuration'], 'logoFileId' | 'backgroundImageFileId'>;

// -- Prop types
// ----------

export interface ITenantOrganizationConfigurationEditOwnProps {
  configuration: ITenantOrganizationConfiguration;

  onCancel?: () => void;
  onSubmit?: (data: ITenantOrganizationConfigurationUpdatePayload) => void;
}
type ITenantOrganizationConfigurationEditProps = ITenantOrganizationConfigurationEditOwnProps & IWithLocalizeOwnProps & FormComponentProps & IWithPathResolverOwnProps;

// -- Component
// ----------

/** Display form for create and update location codebook */
const TenantOrganizationConfigurationEdit: React.FC<ITenantOrganizationConfigurationEditProps> = (props: ITenantOrganizationConfigurationEditProps) => {
  const [form] = Form.useForm<ITenantOrganizationConfiguration>();

  const [openedEditor, setOpenedEditor] = useState<Editors | undefined>();

  /** Set image form value and mark field as touched. */
  const setImageFormValue = (name: Editors, value: string | undefined) => {
    const namePath = ['configuration', name];
    form.setFields([
      { name: namePath, touched: true, value },
    ]);
  };

  /** Returns resolved image file path if there is image value, undefined otherwise. */
  const resolveFormImagePath = (editorName: Editors): string | undefined => {
    const namePath = ['configuration', editorName];
    if (form.getFieldValue(namePath)) {
      return props.resolveApiFilePath(form.getFieldValue(namePath));
    }
    return;
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((value) => {
        const tenantOrganizationData: ITenantOrganizationConfigurationUpdatePayload = {
          ...value,
        };
        props.onSubmit?.(tenantOrganizationData);
      })
      .catch((info) => {
        console.log('Validate Failed:', info);
      });
  };

  const handleCancel = () => {
    props.onCancel?.();
  };

  // --- Logo image handlers

  const handleLogoImageEditClick = () => {
    toggleOpenedEditor('logoFileId', true);
  };

  const handleLogoImageRemoveClick = () => {
    setImageFormValue('logoFileId', undefined);
  };

  const handleLogoImageClose = () => {
    toggleOpenedEditor('logoFileId', false);
  };

  const handleLogoImageSubmit = (file: IFile) => {
    setImageFormValue('logoFileId', file.id);
    toggleOpenedEditor('logoFileId', false);
  };

  const toggleOpenedEditor = (editorName: Editors, opened: boolean) => {
    setOpenedEditor(opened ? editorName : undefined);
  };

  // --- Background image handlers

  const handleBackgroundImageEditClick = () => {
    toggleOpenedEditor('backgroundImageFileId', true);
  };

  const handleBackgroundImageRemoveClick = () => {
    setImageFormValue('backgroundImageFileId', undefined);
  };

  const handleBackgroundImageClose = () => {
    toggleOpenedEditor('backgroundImageFileId', false);
  };

  const handleBackgroundImageSubmit = (file: IFile) => {
    setImageFormValue('backgroundImageFileId', file.id);
    toggleOpenedEditor('backgroundImageFileId', false);
  };

  return (
    <Form<ITenantOrganizationConfiguration>
      {...layout}
      layout="vertical"
      form={form}
      initialValues={props.configuration}
      name="configuration"
      onFinish={handleSubmit}
    >
      {/* --- display name --- */}
      <Form.Item
        name={['configuration', 'displayName']}
        labelCol={GRID_LAYOUT_LABEL_LAYOUT}
        wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT}
        label={props.translate('TENANT_CONFIGURATION_VIEW.DISPLAY_NAME_LABEL')}
        rules={[
          { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
          { required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
        ]}
      >
        <Input />
      </Form.Item>

      {/* --- Footer HTML --- */}
      <Form.Item
        name={['configuration', 'footerHtml']}
        labelCol={GRID_LAYOUT_LABEL_LAYOUT}
        wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT}
        label={props.translate('TENANT_CONFIGURATION_VIEW.FOOTER_LABEL')}
      >
        <Editor
          init={{
            ...CrmEditorConfig.defaultConfig(),
            height: 300,
            menubar: true,
          }} />
      </Form.Item>

      {/* --- JS scripts --- */}
      <Form.Item
        name={['configuration', 'jsScripts']}
        labelCol={GRID_LAYOUT_LABEL_LAYOUT}
        wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT}
        label={
          <React.Fragment>
            {props.translate('TENANT_CONFIGURATION_VIEW.JAVASCRIPT_LABEL')}
            <Tooltip title={props.translate('TENANT_CONFIGURATION_VIEW.JAVASCRIPT_TOOLTIP')}>
              <LemonIcon name="infoCircle" />
            </Tooltip>
          </React.Fragment>
        }>
        <TextArea autoSize={{ minRows: 6, maxRows: 6 }} className="timun-TenantOrganizationConfigurationEdit__jsScript" />
      </Form.Item>

      {/* --- logo image --- */}
      {/* when shouldUpdate prop is used children of Form.Item need to be anonymous fN  */}
      {/* @see https://ant.design/components/form/#shouldUpdate */}
      <Form.Item
        labelCol={GRID_LAYOUT_LABEL_LAYOUT}
        wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT}
        label={props.translate('TENANT_CONFIGURATION_VIEW.LOGO_IMAGE_LABEL')}
        shouldUpdate={true}>
        {() => {
          return (
            <React.Fragment>
              {/* Form.Item with multiple controls. <Form.Item name="field" /> will only bind the control(Input/Select) which is the only children of it. */}
              {/* @see https://ant.design/components/form/#components-form-demo-complex-form-control */}
              <Form.Item
                noStyle={true}
                name={['configuration', 'logoFileId']}
              >
                <Input type="hidden" />
              </Form.Item>

              {/* avatar preview*/}
              <EditableWrapper onEdit={handleLogoImageEditClick} onRemove={handleLogoImageRemoveClick} showRemove={form.getFieldValue(['configuration', 'logoFileId']) != null}>
                <EditorAvatar imagePath={resolveFormImagePath('logoFileId')} size="large" />
              </EditableWrapper>

              {/* image uploader */}
              {openedEditor == 'logoFileId' && <ImageUploadForm defaultImageLink={resolveFormImagePath('logoFileId')} avatar={true} onSubmit={handleLogoImageSubmit} onClose={handleLogoImageClose} />}
            </React.Fragment>
          );
        }}
      </Form.Item>


      {/* --- background image --- */}
      <Form.Item
        labelCol={GRID_LAYOUT_LABEL_LAYOUT}
        wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT}
        label={props.translate('TENANT_CONFIGURATION_VIEW.BACKGROUND_IMAGE_LABEL')}
        shouldUpdate={true}>
        {() => {
          return (
            <React.Fragment>
              <Form.Item noStyle={true} name={['configuration', 'backgroundImageFileId']} >
                <Input type="hidden" />
              </Form.Item>

              {/* avatar preview */}
              <EditableWrapper onEdit={handleBackgroundImageEditClick} onRemove={handleBackgroundImageRemoveClick} showRemove={form.getFieldValue(['configuration', 'backgroundImageFileId']) != null} className="timun-courseView__coverContainer">
                <LemonImage imagePath={resolveFormImagePath('backgroundImageFileId')} fallbackImagePath={props.resolveImagePath('cover-placeholder.png')} className="timun-courseView__coverImage" />
              </EditableWrapper>

              {/* image uploader */}
              {openedEditor == 'backgroundImageFileId' && <ImageUploadForm defaultImageLink={resolveFormImagePath('backgroundImageFileId')} onSubmit={handleBackgroundImageSubmit} onClose={handleBackgroundImageClose} />}
            </React.Fragment>
          );
        }}
      </Form.Item>

      {/* --- terms of agreement --- */}
      <Form.Item
        name={['configuration', 'displayTerms']}
        labelCol={GRID_LAYOUT_LABEL_LAYOUT}
        wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT}
        label={<FormItemLabelIcon label={props.translate('TENANT_CONFIGURATION_VIEW.DISPLAY_TERMS_LABEL')}
          tooltipDescription={props.translate('COMMON.LABEL.MAX_LENGTH_CHARACTER_INFO_LABEL', { maxCharNum: MAX_NUMBER_OF_CHARACTER })} />}
        rules={[
          { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
          { max: MAX_NUMBER_OF_CHARACTER, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') },
        ]}>
        <Editor
          init={{
            ...CrmEditorConfig.defaultConfig(),
            height: 300,
            menubar: true,
          }} />
      </Form.Item>

      {/* --- Configuration id --- */}
      <Form.Item
        noStyle={true}
        hidden={true}
        name={['id']}>
        <Input />
      </Form.Item>

      {/* --- form buttons --- */}
      <Form.Item shouldUpdate={true}>
        {() => <Row justify="space-around">
          <Col className="text-center">
            <Button onClick={handleCancel}>{props.translate('COMMON.ACTION_CANCEL')}</Button>
            &nbsp;
            <Button htmlType="submit" disabled={!form.isFieldsTouched()}>
              {props.translate('COMMON.ACTION_SAVE')}
            </Button>
          </Col>
        </Row>
        }
      </Form.Item>
    </Form >
  );
};

export default withLocalize<ITenantOrganizationConfigurationEditOwnProps>(withPathResolver(TenantOrganizationConfigurationEdit as any));
