import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import {catchError, filter, ignoreElements, map, mergeMap} from 'rxjs/operators';

import {IWebinar} from '@src/model/webinar/Webinar';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import {IIdDataPayload, IIdPayload, ILemonAction, IPayloadAction} from '@src/service/business/common/types';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { actionThunk } from '@src/service/util/observable/operators';
import { startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';
import IIdRef from "@src/model/common/IdRef";
import {IFile} from "@src/model/file/File";

// -
// -------------------- Types&Consts

export interface IWebinarCreatePayload {
  title: string;
  actualStartDateTime?: string | undefined;
  description?: string;
  coverImageUrl?: string;
  guest?: string;
  actualEndDateTime?: string | undefined;
  estimatedEndDateTime?: string | undefined;
  estimatedStartDateTime: string;
  link: string;
  status: IIdRef<string>;
}

export type IWebinarUpdate = ReplaceBy<IWebinar, 'status', IIdRef<string>>;

// -
// -------------------- Selectors

/** Returns webinar from store. */
const getWebinar = (store: any): IWebinar => store.webinarData;

// -
// -------------------- Actions

const Actions = {
  WEBINAR_FETCH: 'WEBINAR_FETCH',
  WEBINAR_LOAD: 'WEBINAR_LOAD',
  WEBINAR_CLEAR: 'WEBINAR_CLEAR',
  WEBINAR_CREATE: 'WEBINAR_CREATE',
  WEBINAR_DELETE: 'WEBINAR_DELETE',
  WEBINAR_UPDATE: 'WEBINAR_UPDATE',
  WEBINAR_COVER_IMAGE_UPLOAD: 'WEBINAR_COVER_IMAGE_UPLOAD'
};

/** Fetch webinar by ID. */
const fetchWebinar = (params: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.WEBINAR_FETCH,
    payload: params,
  };
};

/** Load webinar to store. */
const loadWebinar = (data: IWebinar): IPayloadAction<IWebinar> => {
  return {
    type: Actions.WEBINAR_LOAD,
    payload: data,
  };
};

/** Clear webinar from store. Eg. when leaving view. */
const clearWebinarData = (): ILemonAction => {
  return {
    type: Actions.WEBINAR_CLEAR,
  };
};

/** Create webinar */
const createWebinar = (data: IWebinarCreatePayload): IPayloadAction<IWebinarCreatePayload> => {
  return {
    type: Actions.WEBINAR_CREATE,
    payload: data,
  };
};

/** Delete webinar by ID */
const deleteWebinar = (data: IIdRef<string>): IPayloadAction<IIdRef<string>> => {
  return {
    type: Actions.WEBINAR_DELETE,
    payload: data,
  };
};

/** Update webinar by ID */
const updateWebinar = (data: IWebinarUpdate): IPayloadAction<IWebinarUpdate> => {
  return {
    type: Actions.WEBINAR_UPDATE,
    payload: data,
  };
};

/** Change webinar cover image */
const uploadWebinarCover = (id: string, data: IFile): IPayloadAction<IIdDataPayload<IFile>> => {
  return {
    type: Actions.WEBINAR_COVER_IMAGE_UPLOAD,
    payload: {
      id,
      data,
    },
  };
};

// -
// -------------------- Side-effects

const fetchWebinarEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.WEBINAR_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('Webinar')
        .fetchEntity(id)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadWebinar(data);
    }),

    // reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'COURSE_VIEW.ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),
    // tslint:disable-next-line: no-duplicate-string
    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching webinar', error);
      return o;
    })
  );
};

const createWebinarEffect = (action$: Observable<IPayloadAction<IWebinarCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.WEBINAR_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Webinar')
        .createEntity(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadWebinar(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating webinar', error);
      return o;
    })
  );
};

const deleteWebinarEffect = (action$: Observable<IPayloadAction<IIdRef<string>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.WEBINAR_DELETE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Webinar')
        .deleteEntity(payload.id)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_DELETE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error deleting webinar', error);
      return o;
    })
  );
};

const updateWebinarEffect = (action$: Observable<IPayloadAction<IWebinarUpdate>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.WEBINAR_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Webinar')
        .updateEntity(payload.id, payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error updating webinar', error);
      return o;
    })
  );
};

const uploadWebinarCoverEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IFile>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.WEBINAR_COVER_IMAGE_UPLOAD;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('Webinar')
        .createSubobject(id, 'cover', data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_SEND_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error uploading cover image', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const webinarData = (state: IWebinar | null = null, action: IPayloadAction<IWebinar>) => {
  if (action.type === Actions.WEBINAR_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.WEBINAR_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const webinarViewBusinessStore = {
  actions: {
    fetchWebinar,
    loadWebinar,
    clearWebinarData,
    createWebinar,
    deleteWebinar,
    updateWebinar,
    uploadWebinarCover
  },

  selectors: {
    getWebinar,
  },

  effects: {
    fetchWebinarEffect,
    createWebinarEffect,
    deleteWebinarEffect,
    updateWebinarEffect,
    uploadWebinarCoverEffect
  },

  reducers: {
    webinarData,
  },
};

// --
// export business store
export default webinarViewBusinessStore;
