import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

import { ICompany } from '@src/model/company/Company';
import { IExternalEducationOutcome } from '@src/model/externalEducationTemplate/ExternalEducationOutcome';
import { IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError, createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { actionThunk } from '@src/service/util/observable/operators';
import { startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts

export interface IExternalEducationTemplateCreatePayload {
  duration: number;
  educationOutcomes: IExternalEducationOutcome[];
  educationProvider: Pick<ICompany, 'id'>;
  title: string;
}

// -
// -------------------- Selectors

/** Returns external education template from store. */
const getExternalEducationTemplate = (store: any): IExternalEducationTemplate => store.externalEducationTemplate;

// -
// -------------------- Actions

const Actions = {
  EXTERNAL_EDUCATION_TEMPLATE_FETCH: 'EXTERNAL_EDUCATION_TEMPLATE_FETCH',
  EXTERNAL_EDUCATION_TEMPLATE_LOAD: 'EXTERNAL_EDUCATION_TEMPLATE_LOAD',
  EXTERNAL_EDUCATION_TEMPLATE_CLEAR: 'EXTERNAL_EDUCATION_TEMPLATE_CLEAR',
  EXTERNAL_EDUCATION_TEMPLATE_CREATE: 'EXTERNAL_EDUCATION_TEMPLATE_CREATE',
  EXTERNAL_EDUCATION_TEMPLATE_UPDATE: 'EXTERNAL_EDUCATION_TEMPLATE_UPDATE',
  EXTERNAL_EDUCATION_TEMPLATE_DELETE: 'EXTERNAL_EDUCATION_TEMPLATE_DELETE',
};

/** Fetch external education template by ID. */
const fetchExternalEducationTemplate = (id: string): IPayloadAction<string> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_TEMPLATE_FETCH,
    payload: id,
  };
};

/** Load external education template to store. */
const loadExternalEducationTemplate = (data: IExternalEducationTemplate): IPayloadAction<IExternalEducationTemplate> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_TEMPLATE_LOAD,
    payload: data,
  };
};

/** Clear external education template from store. Eg. when leaving view. */
const clearExternalEducationData = (): ILemonAction => {
  return {
    type: Actions.EXTERNAL_EDUCATION_TEMPLATE_CLEAR,
  };
};

/** Create new external education template. */
const createExternalEducationTemplate = (data: IExternalEducationTemplateCreatePayload): IPayloadAction<IExternalEducationTemplateCreatePayload> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_TEMPLATE_CREATE,
    payload: data,
  };
};

/** Update external education template by id */
const updateExternalEducationTemplate = (data: IExternalEducationTemplate): IPayloadAction<IExternalEducationTemplate> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_TEMPLATE_UPDATE,
    payload: data,
  };
};

/** Delete external education template by id */
const deleteExternalEducationTemplate = (id: string): IPayloadAction<string> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_TEMPLATE_DELETE,
    payload: id,
  };
};

// -
// -------------------- Side-effects

const fetchExternalEducationTemplateEffect = (action$: Observable<IPayloadAction<string>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_EDUCATION_TEMPLATE_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const id = action.payload;

      return EntityApiServiceRegistry.getService('ExternalEducationTemplate')
        .fetchEntity(id)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadExternalEducationTemplate(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching external education template', error);
      return o;
    })
  );
};

const createExternalEducationTemplateEffect = (action$: Observable<IPayloadAction<IExternalEducationTemplateCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_EDUCATION_TEMPLATE_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('ExternalEducationTemplate')
        .createEntity(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'TAG_ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating external education template', error);
      return o;
    })
  );
};

const updateExternalEducationEffect = (action$: Observable<IPayloadAction<IExternalEducationTemplate>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_EDUCATION_TEMPLATE_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('ExternalEducationTemplate')
        .updateEntity(data.id, data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'TAG_ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating external education template', error);
      return o;
    })
  );
};

const deleteExternalEducationTemplateEffect = (action$: Observable<IPayloadAction<string>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_EDUCATION_TEMPLATE_DELETE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const id = action.payload;

      return EntityApiServiceRegistry.getService('ExternalEducationTemplate')
        .deleteEntity(id)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_DELETE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error deleting external education template', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const externalEducationTemplate = (state: IExternalEducationTemplate | null = null, action: IPayloadAction<IExternalEducationTemplate>) => {
  if (action.type === Actions.EXTERNAL_EDUCATION_TEMPLATE_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.EXTERNAL_EDUCATION_TEMPLATE_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

const ExternalEducationTemplateBusinessStore = {
  actions: {
    fetchExternalEducationTemplate,
    clearExternalEducationData,
    createExternalEducationTemplate,
    updateExternalEducationTemplate,
    deleteExternalEducationTemplate,
  },

  selectors: {
    getExternalEducationTemplate,
  },

  effects: {
    fetchExternalEducationTemplateEffect,
    createExternalEducationTemplateEffect,
    updateExternalEducationEffect,
    deleteExternalEducationTemplateEffect,
  },

  reducers: {
    externalEducationTemplate,
  },
};

// --
// export business store
export default ExternalEducationTemplateBusinessStore;
