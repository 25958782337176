import { Form } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';

import FormItemLabelIcon from '@src/components/common/form/FormItemLabelIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IReminder } from '@src/model/reminder/Reminder';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, DatePicker, Input, Modal, TimePicker } from 'antd';
import moment, { Moment } from 'moment';
import React, { SyntheticEvent } from 'react';

// -- Const
// ----------
const dateFormat = AppConfigService.getValue('dateTimeFormat.dateWithYear');
const timeFormat = AppConfigService.getValue('dateTimeFormat.time');
const backendDate = AppConfigService.getValue('dateTimeFormat.backendDate');
const REMINDER_MAX_LENGTH = AppConfigService.getValue('components.common.bigCharacterLengthInput');
const TextArea = Input.TextArea;

// -- Prop types
// ----------
export interface IReminderEditFormOwnProps {
  reminder?: IReminder;

  onCancel: () => void;
  onSubmit: (data: IReminder) => void;
}

type IReminderEditFormProps = IReminderEditFormOwnProps & FormComponentProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Component for creating and editing reminders */
class ReminderEdit extends React.Component<IReminderEditFormProps> {
  render = () => {
    const { getFieldDecorator } = this.props.form;
    return <Modal
      title={this.props.translate('REMINDER_FORM.LABEL')}
      visible={true}
      maskClosable={false}
      onOk={this.handleSubmit}
      onCancel={this.handleCancel}
      footer={<React.Fragment>
        <Button onClick={this.handleCancel}>{this.props.translate('COMMON.ACTION_CANCEL')}</Button>
        <Button key="submit" type="primary" onClick={this.handleSubmit}>{this.props.translate('COMMON.ACTION_SAVE')}</Button>
      </React.Fragment>}
    >
      <Form layout="vertical">
        <Form.Item label={this.props.translate('REMINDER_FORM.DATE')}>
          {getFieldDecorator('startDate', {
            initialValue: this.props.reminder ? moment(this.props.reminder.startDateTime) : moment(),
            rules: [
              { required: true, message: this.props.translate('REMINDER_FORM.DATE_VALIDATION_MESSAGE') },
            ],
          })(<DatePicker format={dateFormat} disabledDate={this.disabledDate} onChange={this.onDateChange} allowClear={false} />)}
        </Form.Item>
        <Form.Item label={this.props.translate('REMINDER_FORM.TIME')}>
          {getFieldDecorator('startTime', {
            initialValue: this.props.reminder ? moment(this.props.reminder.startDateTime) : moment(),
            rules: [
              { required: true, message: this.props.translate('REMINDER_FORM.TIME_VALIDATION_MESSAGE') },
            ],
          })(<TimePicker showNow={false} format={timeFormat} disabledHours={this.disabledHours} disabledMinutes={this.disabledMinutes} hideDisabledOptions={true} allowClear={false} />)}
        </Form.Item>
        <Form.Item label={<FormItemLabelIcon label={this.props.translate('REMINDER_FORM.DESCRIPTION')}
          tooltipDescription={this.props.translate('COMMON.LABEL.MAX_LENGTH_CHARACTER_INFO_LABEL', { maxCharNum: REMINDER_MAX_LENGTH })} />}>
          {getFieldDecorator('description', {
            initialValue: this.props.reminder ? this.props.reminder.description : null,
            rules: [
              { required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') },
              { max: REMINDER_MAX_LENGTH, message: this.props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') },
            ],
          })(<TextArea />)}
        </Form.Item>
      </Form>
    </Modal>;
  };

  handleCancel = () => {
    if (this.props.form.isFieldsTouched()) {
      confirmationDialog({
        onConfirm: this.props.onCancel,
        title: this.props.translate('COMMON.CONFIRMATION_CANCEL_ACTION_MESSAGE'),
      });
    } else {
      this.props.onCancel();
    }
  };

  handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        const data = this.props.reminder ? {
          ...this.props.reminder,
          description: values.description,
          startDateTime: values.startTime.format(backendDate),
        } : {
          description: values.description,
          startDateTime: values.startTime.format(backendDate),
        } as IReminder;

        this.props.onSubmit(data);
      }
    });
  };

  private getMinDateTime = (): Moment => {
    const now = moment();

    return now
      .minute(now.minute())
      .second(0);
  };

  private disabledDate = (current: Moment | undefined): boolean => {
    return current != null ? current < moment().startOf('day') : false;
  };

  private disabledHours = (): number[] => {
    const startTime = this.props.form.getFieldValue('startTime');
    const minDateTime = this.getMinDateTime();
    const disabledHours = [];
    if (startTime.isSame(minDateTime, 'day')) {
      // is Today
      const minHour = minDateTime.hour();
      for (let i = 0; i < minHour; i++) {
        disabledHours.push(i);
      }
    }
    return disabledHours;
  };

  private disabledMinutes = (selectedHour: number): number[] => {
    const startTime = this.props.form.getFieldValue('startTime');
    const minDateTime = this.getMinDateTime();
    const disabledMinutes: number[] = [];
    if (startTime.isSame(minDateTime, 'day')) {
      // isToday
      if (selectedHour === minDateTime.hour()) {
        // is Today's min Hour
        const minMinute = minDateTime.minute();
        for (let i = 0; i < minMinute; i++) {
          disabledMinutes.push(i);
        }
      }
    }
    return disabledMinutes;
  };

  private onDateChange = (newDateTime: Moment | null) => {
    let newValues;
    let newStartTime;

    if (newDateTime) {
      newValues = { year: newDateTime.year(), month: newDateTime.month(), date: newDateTime.date() };
      newStartTime = moment(this.props.form.getFieldValue('startTime')).set(newValues);
    }

    const minDateTime = this.getMinDateTime();
    if (newStartTime && newStartTime.isBefore(minDateTime)) {
      newStartTime = minDateTime;
    }

    this.props.form.setFieldsValue({
      startTime: newStartTime,
    });
  };
}

// -- HOCs and exports
// ----------

const ReminderEditForm = Form.create<IReminderEditFormProps>()(ReminderEdit);

export default withLocalize<IReminderEditFormOwnProps>(ReminderEditForm as any);
