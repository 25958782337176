import DeleteElementButton from '@src/components/common/button/DeleteElementButton';
import AppUserAvatar from '@src/components/common/image/AppUserAvatar';
import LemonIcon from '@src/components/common/image/LemonIcon';
import ListPagination from '@src/components/common/list/ListPagination';
import { IRoleTypes } from '@src/components/common/member/MemberList';
import { UserNameView } from '@src/components/common/view/UserNameView';
import { IMemberUser } from '@src/model/common/MemberUser';
import { IReference } from '@src/model/common/Reference';
import { ICollectionData } from '@src/service/business/common/types';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import { Button, Col, Divider, Empty, Row } from 'antd';
import React from 'react';
import { Link } from 'react-router';

// -- Prop types
// ----------

export interface IMemberCardListOwnProps<T> {
  memberList: ICollectionData<IMemberUser<T>>;
  canEditMember?: (member: IMemberUser<T>) => boolean;
  roleTypes: IRoleTypes[];
  onPageChange?: (page?: number | undefined, size?: number | undefined) => void;
  onUpdateMember?: (user: IMemberUser<T>) => void;
  onDeleteMember?: (user: IMemberUser<T>) => void;

}
type IMemberCardListProps<T> = IMemberCardListOwnProps<T>;

// -- Component
// ----------

class MemberCardList<T extends IReference> extends React.Component<IMemberCardListProps<T>> {
  getRoleLabel = (role: T) => {
    const filtered = this.props.roleTypes.find((type) => type.value === role.id);
    return filtered?.label;
  };

  renderMemberItem = (member: IMemberUser<T>) => {
    return <Col key={member.user.id} lg={6} md={12} xs={24}>
      <div className="panel timun-memberList__cardItem">
        <Link to={`/user/${member.user.id}`}>
          <Col span={24}>
            <AppUserAvatar imagePath={member.user.profileImageUrl} />
          </Col>
        </Link>
        <Col span={24} className="timun-wrapper__gutterBox--vertical">
          <UserNameView user={member.user} fallback={LocalizeService.translate('USER_VIEW.USER_DELETED_LABEL')} link={true} />
        </Col>
        <Col span={24}>
          {LocalizeService.translate('MEMBER_LIST.ROLE_LABEL')}:&nbsp;{this.getRoleLabel(member.role)}
        </Col>
        {this.props.canEditMember?.(member) &&
          <Col span={24}>
            <Divider className="timun-wrapper__gutterBox--vertical" />
            <Row justify="space-around">
              <Col>
                <Button key={member.user.id} title={LocalizeService.translate('COMMON.ACTION_EDIT')} icon={<LemonIcon name="edit" />} onClick={() => this.props.onUpdateMember?.(member)} />
              </Col>
              <Col>
                <DeleteElementButton<IMemberUser<T>> item={member} customNameRender={<UserNameView user={member.user} />} onDelete={this.props.onDeleteMember} />
              </Col>
            </Row>
          </Col>
        }
      </div>
    </Col>;
  };

  render = () => {
    return (
      <React.Fragment>
        <ListPagination page={this.props.memberList.page} onChange={this.props.onPageChange} />

        {this.props.memberList.content.length > 0 ?
          <Row className="timun-memberList">
            {this.props.memberList.content.map((member: IMemberUser<T>) => {
              return this.renderMemberItem(member);
            })}
          </Row>
          :
          <Empty description={LocalizeService.translate('COMMON.MESSAGE_NO_DATA')} />
        }

        <ListPagination page={this.props.memberList.page} onChange={this.props.onPageChange} />
      </React.Fragment>
    );
  };
}

// -- HOCs and exports
// ----------

export default MemberCardList;
