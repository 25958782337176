import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import AppStyleProvider from '@src/components/common/theme/AppStyleProvider';
import ITenantOrganizationConfiguration from '@src/model/tenant/TenantOrganizationConfiguration';
import PublicTenantOrganizationConfigurationBusinessStore from '@src/service/business/tenant/publicTenantOrganizationConfigurationService';
import { LangUtils } from '@src/service/util/LangUtils';
import React, { CSSProperties, PropsWithChildren } from 'react';
import { connect } from 'react-redux';

// -- Prop types
// ----------

export interface ITimunStyleProviderOwnProps {
}

export interface ITimunStyleProviderStateProps {
  tenantConfig: ITenantOrganizationConfiguration;
}

type ITimunStyleProviderProps = PropsWithChildren<ITimunStyleProviderOwnProps> & ITimunStyleProviderStateProps & IWithPathResolverOwnProps;

// -- Component
// ----------

/**
 * Provides app style overrides derived from various runtime sources (eg. tenant configuration).
 *
 * These styles override theme styles so they both must be kept in sync.
 */
const TimunStyleProvider: React.FC<ITimunStyleProviderProps> = (props) => {
  return <AppStyleProvider styles={getStyles(props)}>{props.children}</AppStyleProvider>;
};

function getStyles(props: ITimunStyleProviderProps): Record<string, CSSProperties> {
  const styles: Record<string, CSSProperties> = {};

  // ----- background image
  const backgroundImageFileId = props.tenantConfig?.configuration?.backgroundImageFileId;
  if (!LangUtils.isEmpty(backgroundImageFileId)) {
    styles['.lemon-appLayout__layout'] = { backgroundImage: `url(${props.resolveApiFilePath(backgroundImageFileId)})` };
  }

  return styles;
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ITimunStyleProviderOwnProps): ITimunStyleProviderStateProps => ({
  tenantConfig: PublicTenantOrganizationConfigurationBusinessStore.selectors.getTenantOrganizationConfiguration(state),
});

export default connect<ITimunStyleProviderStateProps, {}, ITimunStyleProviderOwnProps>(mapStateToProps)(withPathResolver(TimunStyleProvider as any));
