import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ILemonApplicationIconSet } from '@src/service/common/icon/LemonApplicationIconSet';
import { Button, Modal, Tooltip } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import React, { SyntheticEvent } from 'react';

// -- Const
// ----------
const confirm = Modal.confirm;

// -- Prop types
// ----------
export interface IDeleteButtonOwnProps {
  itemTitle?: string;
  confirmText?: string;
  buttonText?: string;
  iconOnly?: boolean;
  iconName?: keyof ILemonApplicationIconSet;
  className?: string;
  buttonProps?: Omit<ButtonProps, 'onClick' | 'className'>;

  onConfirm?: () => void;
}

type IDeleteButtonProps = IDeleteButtonOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Component for item deletion with multiple display modes, defaults to "button" mode */
const DeleteButton: React.FC<IDeleteButtonProps> = (props: IDeleteButtonProps) => {
  const renderAsIcon = () => {
    return (
      // tslint:disable-next-line:no-duplicate-string
      <Tooltip overlay={props.translate('COMMON.ACTION_DELETE')}>
        <a onClick={showDeleteConfirm} data-test-id="timun-deleteElementLink">
          <LemonIcon name={props.iconName ?? 'delete'} />
        </a>
      </Tooltip>
    );
  };

  const renderAsButton = () => {
    return (
      <Button danger={true} title={props.translate('COMMON.ACTION_DELETE')} onClick={showDeleteConfirm} icon={<LemonIcon name={props.iconName ?? 'delete'} />} data-test-id="timun-DeleteButton" className={props.className} {...props.buttonProps}>
        {props.buttonText}
      </Button>
    );
  };

  const showDeleteConfirm = (event: SyntheticEvent) => {
    event.stopPropagation();
    const confirmTitle = props.itemTitle ? props.translate('COMMON.CONFIRMATION_ITEM_DELETE_TITLE', { title: props.itemTitle }) : props.translate('COMMON.CONFIRMATION_DELETE_TITLE');
    confirm({
      title: confirmTitle,
      okText: props.translate('COMMON.ACTION_DELETE'),
      content: props.confirmText,
      icon: <LemonIcon name="delete" className="timun-modalDialog__deleteIcon" />,
      okType: 'danger',
      cancelText: props.translate('COMMON.ACTION_CANCEL'),
      maskClosable: false,
      autoFocusButton: 'cancel',
      onOk: () => {
        props.onConfirm?.();
      },
    });
  };

  if (props.iconOnly) {
    return renderAsIcon();
  } else {
    return renderAsButton();
  }
};

export default withLocalize<IDeleteButtonOwnProps>(DeleteButton as any);
