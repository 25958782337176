import { Form } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Button, Checkbox, Col, DatePicker, Input, InputNumber, message, Modal, Radio, Row, Select } from 'antd';
import moment from 'moment-timezone';
import React, { SyntheticEvent } from 'react';
import { Subscription } from 'rxjs';

import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';
import { validateOib } from '@src/components/common/form/validation';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import { IAddress } from '@src/model/user/Address';
import { ContactDataTypeEnum, IContactData } from '@src/model/user/ContactData';
import { GenderEnum, IUserInfo } from '@src/model/user/User';
import { IUserRole, UserRoleEnum } from '@src/model/user/UserRole';
import { IWorkData } from '@src/model/user/WorkData';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import AppConfigService from '@src/service/common/AppConfigService';
import { createEvent, ILemonEvent } from '@src/service/util/event/lemonEvent';
import { createTrackableEvent } from '@src/service/util/event/trackEvent';
import { IUserStatus, UserStatusEnum } from '@src/model/user/UserStatus';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const Option = Select.Option;

const DATE_FORMAT: string = AppConfigService.getValue('dateTimeFormat.dateWithYear');
const ISO_DATE_FORMAT: string = AppConfigService.getValue('dateTimeFormat.backendQueryParamDateFormat');
const MIN_USERNAME_LENGTH: number = AppConfigService.getValue('userValidation.minUsernameLength');
const MIN_PASSWORD_LENGTH: number = AppConfigService.getValue('userValidation.minPasswordLength');

interface IUserFormOwnProps {
  user?: IUserInfo;
  workPositionList: IWorkPosition[];
  allowRoleChange: boolean;
  allowUserStatusChange: boolean;
  onToggleModal: () => void;
  onUserSubmit: (data: ILemonEvent<IUserInfo>) => void;
}

type IUserFormProps = IUserFormOwnProps & FormComponentProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

interface IUserFormState {
  contactData: IContactData[];
  addressRequired: boolean;
  confirmLoading: boolean;
}

class NormalUserForm extends React.Component<IUserFormProps, IUserFormState> {
  state: IUserFormState = {
    contactData: [],
    addressRequired: false,
    confirmLoading: false,
  };

  address?: IAddress = this.props.user?.address;
  workData?: IWorkData = this.props.user?.workData;
  workPosition?: IWorkPosition = this.workData && this.workData.workPosition;
  initialRolesValue: string[] = this.props.user ? this.props.user.roles.map((role: IUserRole) => role.id) : [UserRoleEnum.TRAINEE];

  private submitProgressSubscription: Subscription | null = null;

  componentWillUnmount() {
    if (this.submitProgressSubscription) {
      this.submitProgressSubscription.unsubscribe();
    }
  }

  // tslint:disable-next-line: no-big-function
  render = () => {
    const { getFieldDecorator, getFieldValue } = this.props.form;
    getFieldDecorator('keys', { initialValue: this.mapDataToKeys() });
    const keys = getFieldValue('keys');

    return (
      <React.Fragment>
        <Modal
          className="lemon-modal__modal--md timun-userForm__modal"
          visible={true}
          maskClosable={false}
          confirmLoading={this.state.confirmLoading}
          title={this.props.translate(`USER_FORM.FORM_TITLE_${this.props.user ? 'EDIT' : 'CREATE'}`)}
          okText={this.props.translate(`COMMON.ACTION_SAVE`)}
          cancelText={this.props.translate('COMMON.ACTION_CANCEL')}
          onCancel={this.handleCancel}
          onOk={this.handleSubmit}
        >
          <Form labelCol={{ span: 6 }} wrapperCol={{ span: 18 }} onSubmit={this.handleSubmit}>
            {/* -- User status -- */}
            {this.props.allowUserStatusChange && (
              <FormItem label={this.props.translate('USER_FORM.STATUS_LABEL')}>
                {getFieldDecorator('userStatus', {
                  initialValue: this.props.user?.userStatus.id,
                })(<Select>
                  <Option key={UserStatusEnum.ACTIVE} value={UserStatusEnum.ACTIVE}>
                    {this.props.translate('USER_FORM.ACTIVE_LABEL')}
                  </Option>
                  <Option key={UserStatusEnum.INACTIVE} value={UserStatusEnum.INACTIVE}>
                    {this.props.translate('USER_FORM.INACTIVE_LABEL')}
                  </Option>
                </Select>)}
              </FormItem>
            )}

            {/* -- User role -- */}
            {this.props.allowRoleChange && (
              <FormItem label={this.props.translate('USER_FORM.ROLES_LABEL')}>
                {getFieldDecorator('roles', {
                  rules: [{ required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') }],
                  initialValue: this.initialRolesValue,
                })(
                  <Select mode="multiple" placeholder={this.props.translate('USER_FORM.ROLES_PLACEHOLDER')}>
                    {Object.keys(UserRoleEnum).map((item: any) => (
                      <Option key={UserRoleEnum[item as keyof typeof UserRoleEnum]} value={UserRoleEnum[item as keyof typeof UserRoleEnum]} disabled={item === 'SUPERADMIN'}>
                        {this.props.translate(`USER_FORM.ROLE_${item}_LABEL`)}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
            )}

            {/* -- First name -- */}
            <FormItem label={this.props.translate('USER_FORM.FIRST_NAME_LABEL')}>
              {getFieldDecorator('firstName', {
                // tslint:disable-next-line: no-duplicate-string
                rules: [{ required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') }],
                initialValue: this.props.user?.firstName,
              })(<Input autoFocus={true} placeholder={this.props.translate('USER_FORM.FIRST_NAME_PLACEHOLDER')} />)}
            </FormItem>

            {/* -- Last name -- */}
            <FormItem label={this.props.translate('USER_FORM.LAST_NAME_LABEL')}>
              {getFieldDecorator('lastName', {
                rules: [{ required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') }],
                initialValue: this.props.user?.lastName,
              })(<Input placeholder={this.props.translate('USER_FORM.LAST_NAME_PLACEHOLDER')} />)}
            </FormItem>

            {/* -- Gender -- */}
            <FormItem label={this.props.translate('USER_FORM.GENDER_LABEL')}>
              {getFieldDecorator('gender', {
                initialValue: this.props.user?.gender || GenderEnum.M,
              })(
                <RadioGroup>
                  {Object.keys(GenderEnum).map((item: any) => (
                    <Radio value={GenderEnum[item as keyof typeof GenderEnum]} key={item}>
                      {this.props.translate(`USER_FORM.GENDER_LABEL_${item}`)}
                    </Radio>
                  ))}
                </RadioGroup>
              )}
            </FormItem>

            {/* -- Date of birth -- */}
            <FormItem label={this.props.translate('USER_FORM.DATE_OF_BIRTH_LABEL')}>
              {getFieldDecorator('dateOfBirth', {
                initialValue: this.props.user?.dateOfBirth && moment(this.props.user.dateOfBirth),
              })(<DatePicker placeholder={this.props.translate('USER_FORM.DATE_OF_BIRTH_PLACEHOLDER')} allowClear={false} format={DATE_FORMAT} />)}
            </FormItem>

            {/* -- Identification number -- */}
            <FormItem label={this.props.translate('USER_FORM.IDENTIFICATION_NUMBER_LABEL')}>
              {getFieldDecorator('identificationNumber', {
                rules: [{ validator: validateOib }],
                initialValue: this.props.user?.identificationNumber,
              })(<Input placeholder={this.props.translate('USER_FORM.IDENTIFICATION_NUMBER_PLACEHOLDER')} />)}
            </FormItem>

            {/* -- Adress -- */}
            <FormItem required={this.state.addressRequired}>
              {getFieldDecorator('address.id', {
                initialValue: this.address && this.address.id,
              })(<Input type="hidden" />)}
            </FormItem>

            {/* -- Street and home number -- */}
            <FormItem label={this.props.translate('USER_FORM.ADDRESS_STREET_WITH_NUMBER_LABEL')}>
              {/* tslint:disable-next-line: no-duplicate-string */}
              {getFieldDecorator('address.streetWithHomeNumber', {
                rules: [{ required: this.state.addressRequired, message: ' ' }],
                initialValue: this.address && this.address.streetWithHomeNumber,
              })(<Input onBlur={this.checkAddressRequired} placeholder={this.props.translate('USER_FORM.ADDRESS_STREET_WITH_NUMBER_PLACEHOLDER')} />)}
            </FormItem>

            {/* -- ZIP code -- */}
            <FormItem label={this.props.translate('USER_FORM.ADDRESS_ZIP_LABEL')}>
              {/* tslint:disable-next-line: no-duplicate-string */}
              {getFieldDecorator('address.zip', {
                rules: [{ required: this.state.addressRequired, message: ' ' }],
                initialValue: this.address && this.address.zip,
              })(<InputNumber min={AppConfigService.getValue('components.userForm.minZipValue')} max={AppConfigService.getValue('components.userForm.maxZipValue')} onBlur={this.checkAddressRequired} placeholder={this.props.translate('USER_FORM.ADDRESS_ZIP_PLACEHOLDER')} />)}
            </FormItem>

            {/* -- City -- */}
            <FormItem label={this.props.translate('USER_FORM.ADDRESS_CITY_LABEL')}>
              {/* tslint:disable-next-line: no-duplicate-string */}
              {getFieldDecorator('address.city', {
                rules: [{ required: this.state.addressRequired, message: ' ' }],
                initialValue: this.address && this.address.city,
              })(<Input onBlur={this.checkAddressRequired} placeholder={this.props.translate('USER_FORM.ADDRESS_CITY_PLACEHOLDER')} />)}
            </FormItem>

            {/* -- Country -- */}
            <FormItem label={this.props.translate('USER_FORM.ADDRESS_COUNTRY_LABEL')}>
              {/* tslint:disable-next-line: no-duplicate-string */}
              {getFieldDecorator('address.country', {
                rules: [{ required: this.state.addressRequired, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') }],
                initialValue: this.address && this.address.country,
              })(<Input onBlur={this.checkAddressRequired} placeholder={this.props.translate('USER_FORM.ADDRESS_COUNTRY_PLACEHOLDER')} />)}
            </FormItem>

            {/* -- Contact data -- */}
            <FormItem label={this.props.translate('USER_FORM.CONTACT_DATA_LABEL')} required={true}>
              {keys.map((key: number) => {
                const contactData: IContactData | undefined = this.props.user?.contactData && this.props.user.contactData[key];
                const currentType = (contactData && contactData.type.id) || this.props.form.getFieldValue(`contact[${key}].type`);
                const isEmail = !currentType || currentType === ContactDataTypeEnum.EMAIL;
                return (
                  <Row gutter={4} key={key}>
                    {/*<InputGroup compact={true}>*/}
                    <Col span={0}>
                      <FormItem>
                        {getFieldDecorator(`contact[${key}].id`, {
                          initialValue: contactData && contactData.id,
                        })(<Input type="hidden" />)}
                      </FormItem>
                    </Col>
                    <Col span={6}>
                      <FormItem>
                        {getFieldDecorator(`contact[${key}].type`, {
                          initialValue: (contactData && contactData.type.id) || ContactDataTypeEnum.EMAIL,
                        })(
                          <Select onChange={() => this.onContactDataTypeChange(key)} dropdownMatchSelectWidth={false}>
                            {Object.keys(ContactDataTypeEnum).map((item: any) => (
                              <Option value={ContactDataTypeEnum[item as keyof typeof ContactDataTypeEnum]} key={`${key}-${item}`}>
                                {this.props.translate(`USER_FORM.${item}_LABEL`)}
                              </Option>
                            ))}
                          </Select>
                        )}
                      </FormItem>
                    </Col>
                    <Col span={14}>
                      <FormItem>
                        {getFieldDecorator(`contact[${key}].value`, {
                          rules: [{ required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') }, isEmail ? { type: 'email', message: this.props.translate('USER_FORM.EMAIL_VALIDATION_TEXT') } : {}],
                          initialValue: contactData && contactData.value,
                        })(<Input type={isEmail ? 'email' : 'tel'} placeholder={this.props.translate(`USER_FORM.${isEmail ? 'EMAIL' : 'PHONE'}_PLACEHOLDER`)} />)}
                      </FormItem>
                    </Col>
                    <Col span={4}>
                      {keys.length > 1 && (
                        <FormItem>
                          <LemonIcon name="times" onClick={() => this.removeKey(key)} />
                        </FormItem>
                      )}
                    </Col>
                    {/*</InputGroup>*/}
                  </Row>
                );
              })}
              <FormItem>
                <Row>
                  <Button icon={<LemonIcon name="plus" />} onClick={() => this.addKey()}>
                    {this.props.translate('USER_FORM.ADD_CONTACT_DATA_LABEL')}
                  </Button>
                </Row>
              </FormItem>
            </FormItem>

            {this.props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]) && (
              <React.Fragment>
                {/* -- Workposition -- */}
                <FormItem label={this.props.translate('USER_FORM.WORK_POSITION_NAME_LABEL')}>
                  {getFieldDecorator('workData.workPosition.id', {
                    rules: [{ required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') }],
                    initialValue: this.workPosition && this.workPosition.id,
                  })(
                    <Select placeholder={this.props.translate('USER_FORM.WORK_POSITION_NAME_PLACEHOLDER')} allowClear={true}>
                      {this.props.workPositionList.map((item: IWorkPosition) => (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  )}
                </FormItem>

                {/* -- Workposition start date -- */}
                <FormItem label={this.props.translate('USER_FORM.WORK_POSITION_START_DATE_LABEL')}>
                  {getFieldDecorator('workData.startDate', {
                    rules: [{ required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') }],
                    initialValue: this.workData?.startDate && moment(this.workData.startDate),
                  })(<DatePicker placeholder={this.props.translate('USER_FORM.WORK_POSITION_START_DATE_PLACEHOLDER')} allowClear={true} format={DATE_FORMAT} />)}
                </FormItem>
              </React.Fragment>
            )}

            <FormItem label={this.props.translate('USER_FORM.GDPR_LABEL')}>
              {getFieldDecorator('gdprAccepted', {
                valuePropName: 'checked',
                initialValue: this.props.user?.gdprAccepted,
              })(<Checkbox>{this.props.translate('USER_FORM.GDPR_ACCEPTED')}</Checkbox>)}
            </FormItem>

            {/* -- Username and password (new user) -- */}
            {!this.props.user && (
              <React.Fragment>
                <FormItem label={this.props.translate('USER_FORM.USERNAME_LABEL')}>
                  {getFieldDecorator('username', {
                    rules: [
                      { required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') },
                      { min: MIN_USERNAME_LENGTH, message: this.props.translate('USER_FORM.USERNAME_MINIMUM_LENGTH_VALIDATION_MESSAGE') },
                    ],
                  })(<Input placeholder={this.props.translate('USER_FORM.USERNAME_PLACEHOLDER')} />)}
                </FormItem>
                <FormItem label={this.props.translate('USER_FORM.PASSWORD_LABEL')}>
                  {getFieldDecorator('password', {
                    rules: [{ required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') }, { min: MIN_PASSWORD_LENGTH, message: this.props.translate('USER_FORM.PASSWORD_MINIMUM_LENGTH_VALIDATION_MESSAGE') }, { validator: this.validatePassword }],
                    validateFirst: true,
                  })(<Input type="password" placeholder={this.props.translate('USER_FORM.PASSWORD_PLACEHOLDER')} />)}
                </FormItem>
                <FormItem label={this.props.translate('USER_FORM.CONFIRM_PASSWORD_LABEL')}>
                  {getFieldDecorator('confirmPassword', {
                    rules: [{ required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') }, { min: MIN_PASSWORD_LENGTH, message: this.props.translate('USER_FORM.PASSWORD_MINIMUM_LENGTH_VALIDATION_MESSAGE') }, { validator: this.validateConfirmPassword }],
                    validateFirst: true,
                  })(<Input type="password" placeholder={this.props.translate('USER_FORM.CONFIRM_PASSWORD_PLACEHOLDER')} />)}
                </FormItem>
              </React.Fragment>
            )}
          </Form>
        </Modal>
      </React.Fragment>
    );
  };

  handleCancel = () => {
    if (this.props.form.isFieldsTouched()) {
      confirmationDialog({
        onConfirm: this.props.onToggleModal,
        title: this.props.translate('COMMON.CONFIRMATION_ROUTE_NAVIGATION'),
      });
    } else {
      this.props.onToggleModal();
    }
  };

  /**
   * maps contact data into keys for dynamic form
   * if no contactData found, return one key for mandatory data
   */
  private mapDataToKeys = () => {
    const keys: number[] | undefined =
      this.props.user &&
      this.props.user.contactData &&
      this.props.user.contactData
        // .sort((a: IContactData, b: IContactData) => a.type.id > b.type.id ? 1 : -1)
        .map((contectData: IContactData, index: number) => index);
    return keys && keys.length ? keys : [0];
  };

  private removeKey = (key: number) => {
    const keys = this.props.form.getFieldValue('keys');
    this.props.form.setFieldsValue({ keys: keys.filter((item: number) => item !== key) });
  };

  private addKey = () => {
    const keys: number[] = this.props.form.getFieldValue('keys');
    const maxIndex = Math.max(...keys);
    let nextIndex = isFinite(maxIndex) ? maxIndex + 1 : 0;
    if (this.props.user?.contactData != null && this.props.user.contactData.length > nextIndex) {
      nextIndex = this.props.user.contactData.length;
    }
    keys.push(nextIndex);
    this.props.form.setFieldsValue({ keys });
  };

  private checkAddressRequired = () => {
    this.setState(
      {
        addressRequired: this.props.form.getFieldValue('address.street') || this.props.form.getFieldValue('address.homeNumber') || this.props.form.getFieldValue('address.zip') || this.props.form.getFieldValue('address.city') || this.props.form.getFieldValue('address.country'),
      },
      () => this.props.form.validateFields(['address.street', 'address.homeNumber', 'address.zip', 'address.city', 'address.country'], { force: true })
    );
  };

  private onContactDataTypeChange = (key: number) => {
    this.setState({}, () => this.props.form.validateFields([`contact[${key}].value`], { force: true }));
  };

  private validatePassword = (rule: any, value: any, callback: any) => {
    const confirmPassword = this.props.form.getFieldValue('confirmPassword');
    if (value && confirmPassword) {
      this.props.form.validateFields(['confirmPassword'], { force: true });
      callback();
    } else {
      callback();
    }
  };

  private validateConfirmPassword = (rule: any, value: any, callback: any) => {
    const password = this.props.form.getFieldValue('password');
    if (value && password && value !== password) {
      callback(this.props.translate('USER_FORM.PASSWORDS_DIFFERENT_ERROR'));
    } else {
      callback();
    }
  };

  private handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err: any, values: any) => {
      if (!err) {
        const user: IUserInfo = {
          ...this.props.user,
          firstName: values.firstName,
          lastName: values.lastName,
          gender: values.gender,
          dateOfBirth: values?.dateOfBirth?.format(ISO_DATE_FORMAT),
        } as IUserInfo;

        if (values.userStatus) {
          const userStatusData = {
            id: values.userStatus
          } as IUserStatus;
          user.userStatus = userStatusData;
        }

        if (values.workData) {
          const workData = {
            startDate: values.workData.startDate.format(ISO_DATE_FORMAT),
            workPosition: {
              id: values.workData.workPosition.id,
            },
          } as IWorkData;
          user.workData = workData;
        }

        if (values.address.streetWithHomeNumber) {
          const address = {
            streetWithHomeNumber: values.address.streetWithHomeNumber,
            zip: values.address.zip.toString(),
            city: values.address.city,
            country: values.address.country,
          } as IAddress;
          if (values.address.id) {
            address.id = values.address.id;
          }
          user.address = address;
        } else {
          user.address = undefined;
        }

        if (values.identificationNumber) {
          user.identificationNumber = values.identificationNumber;
        } else {
          user.identificationNumber = undefined;
        }

        user.contactData = [] as IContactData[];
        if (values.contact) {
          values.contact.map((contact: any) => {
            const contactData: IContactData = {
              value: contact.value,
              type: {
                id: contact.type,
              },
            } as IContactData;
            if (contact.id) {
              contactData.id = contact.id;
            }
            user.contactData.push(contactData);
          });
        }

        if (values.roles) {
          user.roles = values.roles.map((item: string) => ({ id: item }));
        }

        this.setState({ confirmLoading: true });

        const submitEvent = createTrackableEvent(createEvent(user));

        this.submitProgressSubscription = submitEvent.track().subscribe(
          () => {
            // success
            message.success(this.props.translate(`GENERAL_MESSAGE.GENERAL_${this.props.user ? 'UPDATE' : 'SEND'}_SUCCESS`));
            this.props.onToggleModal();
          },
          () => {
            // error
            this.setState({ confirmLoading: false });
          }
        );

        this.props.onUserSubmit(submitEvent);
      }
    });
  };
}

const UserForm = Form.create()(NormalUserForm);

export default withRoles(withLocalize<IUserFormOwnProps>(UserForm as any));
