import { SurveyTypeEnum } from '@src/model/survey/SurveyTemplate';
import { EmploymentStatusEnum } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { ICountPerStatus, ISurveyReport, ISurveyReportPerType } from '@src/model/externalEducationApplication/ExternalEducationApplicationStatistics';
import { ActivityPhaseEnum } from '@src/model/activity/ActivityPhase';

/** Static application statistics utility methods. */
export default class ApplicationStatisticsUtils {
  /** Returns sum of external education application count per requested status group */
  static sumEducationApplicationCountPerRequestedApplicationStatusGroup(requestedApplicationStatusGroup: ActivityPhaseEnum[], applicationPerStatus: ICountPerStatus<ActivityPhaseEnum>[]): number {
    const requestedApplications = applicationPerStatus.filter((application: ICountPerStatus<ActivityPhaseEnum>) => requestedApplicationStatusGroup.find((status) => application.type.id === status));
    return requestedApplications.reduce((acumulator: number, requestedApplication: ICountPerStatus<ActivityPhaseEnum>) => {
      return acumulator + requestedApplication.count;
    }, 0);
  }

  /** Returns sum of external education application count per employment status */
  static sumEducationApplicationCountPerRequestedEmplyomentStatusGroup(requestedApplicationStatusGroup: EmploymentStatusEnum[], applicationPerStatus: ICountPerStatus<EmploymentStatusEnum>[]): number {
    const requestedApplications = applicationPerStatus.filter((application: ICountPerStatus<EmploymentStatusEnum>) => requestedApplicationStatusGroup.find((status) => application.type.id === status));
    return requestedApplications.reduce((acumulator: number, requestedApplication: ICountPerStatus<EmploymentStatusEnum>) => {
      return acumulator + requestedApplication.count;
    }, 0);
  }

  /** Returns survey report by requested survey type */
  static findSurveyReportByType(requestedSurveyType: SurveyTypeEnum, surveyReportPerType: ISurveyReportPerType[]): ISurveyReport | undefined {
    return surveyReportPerType.find((survey) => survey.type.id === requestedSurveyType)?.report;
  }

  /** Returns sum of completed survey reports per requested survey types */
  static sumCompletedSurveyReportPerRequestedType(requestedSurveyType: SurveyTypeEnum[], surveyReportPerType: ISurveyReportPerType[]): number {
    const requestedApplications = surveyReportPerType.filter((survey: ISurveyReportPerType) => requestedSurveyType.find((status) => survey.type.id === status));
    return requestedApplications.reduce((acumulator: number, requestedSurveyType: ISurveyReportPerType) => {
      return acumulator + requestedSurveyType.report.numberOfSubmissions;
    }, 0);
  }
}
