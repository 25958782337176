import React from 'react';

import SkillLevelClassificationPicker from '@src/components/common/datapicker/SkillLevelClassificationPicker';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ISkillLevelClassification } from '@src/model/skillgroup/SkillLevelClassification';
import { IUserSkillEvaluation } from '@src/model/user/UserSkillEvaluation';
import { Form, FormInstance, Input } from 'antd';
import { RuleObject } from 'antd/lib/form';
import FormItem from 'antd/lib/form/FormItem';

// -- Prop types
// ----------

export interface IUserSkillEvaluationFormOwnProps {
  userSkillEvaluation?: IUserSkillEvaluation;
  formRef?: FormInstance<IUserSkillEvaluation>;
  onSubmit?: (value: IUserSkillEvaluation) => void;
}
type IUserSkillEvaluationFormProps = IUserSkillEvaluationFormOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const UserSkillEvaluationForm: React.FC<IUserSkillEvaluationFormProps> = (props: IUserSkillEvaluationFormProps) => {
  const [form] = Form.useForm<IUserSkillEvaluation>(props.formRef);

  const skillLevelClassificationPickerValidator = (_: RuleObject, values: ISkillLevelClassification) => {
    if (!values || values.skill == null || values.skillLevel == null) {
      return Promise.reject(new Error(props.translate('COMMON.FILL_REQUIRED_FIELD')));
    }
    return Promise.resolve();
  };

  return (
    <Form<IUserSkillEvaluation>
      name="userSkillEvaluation"
      layout="vertical"
      form={form}
      initialValues={props.userSkillEvaluation}
      onFinish={props.onSubmit}>
      <FormItem
        label={props.translate('USER_SKILL_EVALUATION.FORM.ASSIGN_SKILL_LABEL')}
        name="skillLevelClassification"
        rules={[
          {
            required: true,
            validator: skillLevelClassificationPickerValidator,
          },
        ]}>
        <SkillLevelClassificationPicker />
      </FormItem>

      <FormItem
        noStyle={true}
        hidden={true}
        name={['user', 'id']}>
        <Input />
      </FormItem>

      <Form.Item
        noStyle={true}
        hidden={true}
        name={['id']}>
        <Input />
      </Form.Item>
    </Form >
  );
};

export default withLocalize<IUserSkillEvaluationFormOwnProps>(UserSkillEvaluationForm as any);
