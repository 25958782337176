import React from 'react';
import { connect } from 'react-redux';

import DataPicker, { IDataPickerItem } from '@src/components/common/datapicker/DataPicker';
import { ISkill } from '@src/model/skillgroup/Skill';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import SkillBusinessStore, { ISkillListFilter } from '@src/service/business/skillgroup/SkillBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { LangUtils } from '@src/service/util/LangUtils';

const minSearchLength = AppConfigService.getValue('components.common.minSearchStringLength');

// -- Prop types
// ----------

export interface ISkillDataPickerOwnProps {
  value?: ISkill;
  disabled?: boolean;
  onChange?: (newValue?: ISkill) => void;
}

export interface ISkillDataPickerStateProps {
}

export interface ISkillDataPickerDispatchProps {
  clearSkill: () => void;
  fetchSkillList: (params: ICollectionFetchPayload<ISkillListFilter>) => ITrackableAction;
}
type ISkillDataPickerProps = ISkillDataPickerOwnProps & ISkillDataPickerStateProps & ISkillDataPickerDispatchProps;

interface ISkillDataPickerState {
  skillList?: ISkill[] | null;
}

// -- Component
// ----------

/** Describe your component ... */
class SkillDataPicker extends React.Component<ISkillDataPickerProps, ISkillDataPickerState> {
  state: ISkillDataPickerState = {};

  render = () => {
    const pickerValue = this.props.value && this.mapToItem(this.props.value);
    const pickerItems = this.state.skillList != null ? this.state.skillList.map(this.mapToItem) : [];

    return <DataPicker value={pickerValue} items={pickerItems} disabled={this.props.disabled} onChange={this.handleDataPickerChange} onSearch={this.handleDataPickerSearch} dataTestIdPrefix="timun-skillPicker" />;
  };

  private handleDataPickerChange = (value?: IDataPickerItem) => {
    if (this.props.onChange) {
      this.props.onChange(value?.data);
    }
  };

  private handleDataPickerSearch = (value: string) => {
    if (value.length >= minSearchLength) {
      this.searchList(value);
    } else if (LangUtils.isEmpty(value) || value.length < minSearchLength) {
      this.storeList();
    }
  };

  /** Map entity ref list to data picker item list. */
  private mapToItem(skill: ISkill): IDataPickerItem {
    return {
      value: skill.id,
      name: skill.title,
      data: skill,
    };
  }

  private searchList(search: string) {
    this.fetchList({
      filter: { title: search },
      page: 0,
      size: AppConfigService.getValue('components.dataPicker.defaultPageSize'),
      sort: [],
    });
  }

  private fetchList(params: ICollectionFetchPayload<ISkillListFilter>) {
    this.props.fetchSkillList(params).track().subscribe(this.storeList);
  }

  private storeList = (skillList?: ICollectionData<ISkill>) => {
    this.setState({ skillList: skillList?.content });
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ISkillDataPickerOwnProps): ISkillDataPickerStateProps => ({
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ISkillDataPickerDispatchProps => ({
  clearSkill: () => dispatch(SkillBusinessStore.actions.clearSkill),
  fetchSkillList: (params: ICollectionFetchPayload<ISkillListFilter>) => dispatch(createTrackableAction(SkillBusinessStore.actions.fetchSkillPickerList(params))),
});

export default connect<ISkillDataPickerStateProps, ISkillDataPickerDispatchProps, ISkillDataPickerOwnProps>(mapStateToProps, mapDispatchToProps)(SkillDataPicker as any);
