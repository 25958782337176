import { ILemonAction } from '@src/service/business/common/types';
import { TrackingHelper } from '@src/service/util/tracking/tracking';

// --
// ---------- Constants

// thunk keys are created as symbols to avoid unauthorized access and accidental serialization to JSON
const THUNK_ACTIONS_KEY = Symbol.for('@@THUNK_ACTIONS_KEY');
const THUNK_PROMISE_KEY = Symbol.for('@@THUNK_PROMISE_KEY');

// --
// ---------- Action creators

// ----- Thunk action

// callback function prototypes
export type ISuccessThunk = (data: any) => void;
export type IErrorThunk = (error: any) => void;
export type ICompleteThunk = () => void;

// object containing thunks
export interface IActionThunkMap {
  success?: ISuccessThunk;
  error?: IErrorThunk;
}

// callback action object type
export type IThunkAction<T = any> = ILemonAction & {
  [THUNK_ACTIONS_KEY]: IActionThunkMap;
  [THUNK_PROMISE_KEY]: Promise<T>;
  watch: () => Promise<T>;
};

/**
 * Enhance action object with thunk callbacks.
 */
export const createActionThunk = (action: ILemonAction, callback?: IActionThunkMap): IThunkAction => {
  return _actionEnhancer(action, callback);
};

/** Helper for executing callback defined on action object. */
export const ActionThunkHelper = {
  /** Apply success thunk on action. */
  success: <S>(action: ILemonAction, data: S) => {
    // force any to allow cheking for T_A_KEY on possibly non-thunk action
    if ((action as any)[THUNK_ACTIONS_KEY] != null) {
      const thunkAction = action as IThunkAction;
      const thunkMap = thunkAction[THUNK_ACTIONS_KEY];
      if (thunkMap && thunkMap.success) {
        thunkMap.success.apply(null, [data]);
      }
    }

    // notify trackables
    TrackingHelper.success(action, data);
  },

  /** Apply error thunk on action. */
  error: <S>(action: ILemonAction, error: S) => {
    // force any to allow cheking for T_A_KEY on possibly non-thunk action
    if ((action as any)[THUNK_ACTIONS_KEY] != null) {
      const thunkAction = action as IThunkAction;
      const thunkMap = thunkAction[THUNK_ACTIONS_KEY];
      if (thunkMap && thunkMap.error) {
        thunkMap.error.apply(null, [error]);
      }
    }

    // notify trackables
    TrackingHelper.error(action, error);
  },
};

// ---------- private

function _actionEnhancer(action: ILemonAction, callback?: IActionThunkMap, event?: any /* TODO: set ILemonEvent */): IThunkAction {
  let promiseResolver: ((value?: any /*T | PromiseLike<T>*/) => void) | undefined;
  let promiseRejector: ((reason?: any) => void) | undefined;

  const thunkAction: IThunkAction = Object.assign(
    // target action object
    action,

    // thunk action properties
    {
      // thunk actions
      [THUNK_ACTIONS_KEY]: {
        /** Thunk success callback */
        success: <T>(data: T) => {
          // execute explicit callback
          if (callback && callback.success) {
            callback.success.apply(null, [data]);
          }

          // resolve internal promise
          if (promiseResolver) {
            promiseResolver(data);
          }
        },

        /** Thunk error callback. */
        error: (error: any) => {
          // execute explicit callback
          if (callback && callback.error) {
            callback.error.apply(null, [error]);
          }

          // reject internal promise
          if (promiseRejector) {
            promiseRejector(error);
          }
        },
      },

      /** Thunk action internal promise for implicit listeners. */
      [THUNK_PROMISE_KEY]: (() => {
        return new Promise((resolve, reject) => {
          // function references
          promiseResolver = resolve;
          promiseRejector = reject;
        });
      })(),

      watch: () => {
        return thunkAction[THUNK_PROMISE_KEY];
      },
    }
  );

  return thunkAction;
}
