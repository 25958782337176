import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ExternalContentCard from '@src/components/externalcontent/list/ExternalContentCard';
import { IExternalContent } from '@src/model/externalcontent/ExternalContent';
import { Col, Empty, Row } from 'antd';
import React from 'react';

// -- Prop types
// ----------

interface IExternalContentCardListOwnProps {
  externalContentList: IExternalContent[];
  getPath: (id: string) => string;
}
type IExternalContentCardListProps = IExternalContentCardListOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

class ExternalContentCardList extends React.Component<IExternalContentCardListProps> {
  render = () => {
    return (
      this.props.externalContentList.length ? <Row gutter={16}>
        {this.props.externalContentList.map((externalContent) => {
          return <Col key={externalContent.id} xs={24} sm={12} md={8} lg={6}>
            <ExternalContentCard externalContent={externalContent} getPath={this.props.getPath} />
            <br />
          </Col>;
        })}
      </Row> :
        <Empty description={this.props.translate('EXTERNAL_CONTENT.LIST.EMPTY_MESSAGE')} />
    );
  }
}

// -- HOCs and exports
// ----------

export default withLocalize<IExternalContentCardListOwnProps>(ExternalContentCardList as any);