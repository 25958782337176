import { IRefObject } from '@src/components/reminder/ReminderCreateContainer';
import { IUserDetails } from '@src/model/user/UserDetails';
export interface IReminder {
  id: string;
  description: string;
  startDateTime: string;
  createdBy: IUserDetails | null;
  type: IReminderType;
  object?: IRefObject;
}
export interface IReminderType {
  id: ReminderTypeEnum;
  description: string;
  name: string;
}
export enum ReminderTypeEnum {
  USER_DEFINED = '1',
  WEBINAR = '2',
  CONSULTATIONS = '3',
}
