import LemonImage from '@src/components/common/image/LemonImage';
import { IExternalContent } from '@src/model/externalcontent/ExternalContent';
import { Card, Tooltip } from 'antd';
import React from 'react';
import { Link } from 'react-router';

// -- Prop types
// ----------

interface IExternalContentCardOwnProps {
  externalContent: IExternalContent;
  getPath: (id: string) => string;
}
type IExternalContentCardProps = IExternalContentCardOwnProps;

// -- Component
// ----------

class ExternalContentCard extends React.Component<IExternalContentCardProps> {
  render = () => {
    return <Link to={this.props.getPath(this.props.externalContent.id)}>
      <Card hoverable={true} cover={<LemonImage imagePath={this.props.externalContent.coverImageUrl || 'cover-placeholder.png'} className="timun-externalContentCard__coverImage" />}>
        <Card.Meta
          title={
            <div className="text-center">
              <Tooltip overlay={this.props.externalContent.title}>{this.props.externalContent.title}</Tooltip>
            </div>
          }
        />
      </Card>
    </Link>;
  }
}

// -- HOCs and exports
// ----------

export default ExternalContentCard;