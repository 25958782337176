import { useCallback, useMemo, useState } from 'react';

export interface ISetModalStateFunctions<T> {
  onCloseUpdate: () => void;
  onCloseCreate: () => void;
  onOpenCreate: () => void;
  onOpenUpdate: (entity: T) => void;
}

interface IUseEntityModalsStateState<T> {
  isCreateModalVisible: boolean;
  isUpdateModalVisible: boolean;
  selectedEntity?: T;
}

/* custom hook to handle state for showing create and update modal in list components */
const useEntityModalsState = <T>(initialState?: Partial<IUseEntityModalsStateState<T>>): [IUseEntityModalsStateState<T>, ISetModalStateFunctions<T>] => {

  const [modalsState, setModalsState] = useState<IUseEntityModalsStateState<T>>({
    isCreateModalVisible: false,
    isUpdateModalVisible: false,
    selectedEntity: undefined,
    ...initialState,
  });

  const toggleUpdateModal = useCallback((entity?: T) => {
    setModalsState({ ...modalsState, selectedEntity: entity, isUpdateModalVisible: !!entity });
  }, [modalsState]);

  const toggleCreateModal = useCallback((isVisible: boolean) => {
    setModalsState({ ...modalsState, isCreateModalVisible: isVisible });
  }, [modalsState]);

  const handleCreateModalOpen = useCallback(() => {
    toggleCreateModal(true);
  }, []);

  const handleCreateModalClose = useCallback(() => {
    toggleCreateModal(false);
  }, []);

  const handleUpdateModalClose = useCallback(() => {
    toggleUpdateModal();
  }, []);

  const handleUpdateModalOpen = useCallback((entity: T) => {
    toggleUpdateModal(entity);
  }, []);

  const toggleEntityModals: ISetModalStateFunctions<T> = useMemo(() => ({
    onCloseUpdate: handleUpdateModalClose,
    onCloseCreate: handleCreateModalClose,
    onOpenCreate: handleCreateModalOpen,
    onOpenUpdate: handleUpdateModalOpen,
  }), [handleUpdateModalClose, handleCreateModalClose, handleCreateModalOpen, handleUpdateModalOpen]);

  return [modalsState, toggleEntityModals];
};

export default useEntityModalsState;
