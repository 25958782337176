import { IQuiz } from '@lamarodigital/quizzler-lib-frontend/model/quiz/Quiz';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import SurveyInstanceFeedbackModal from '@src/components/survey/view/SurveyInstanceFeedbackModal';
import { ISurveyInstance } from '@src/model/survey/SurveyInstance';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import SurveyInstanceBusinessStore, { ISurveyInstanceSubmissionListFilter } from '@src/service/business/survey/SurveyInstanceBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import React from 'react';
import { connect } from 'react-redux';

// -- Const
// ----------
const midPageSize = AppConfigService.getValue('api.paging.midPageSize');

// -- Prop types
// ----------
export interface ISurveyInstanceFeedbackContainerOwnProps {
  surveyInstance: ISurveyInstance;
  onModalClose: () => void;
}

export interface ISurveyInstanceFeedbackContainerStateProps {
  surveyInstanceSubmissionList: ICollectionData<IQuiz>;
  surveyInstanceSubmissionListFilter: ISurveyInstanceSubmissionListFilter;
}

export interface ISurveyInstanceFeedbackContainerDispatchProps {
  fetchSurveyInstanceSubmissionList: (id: string, params: ICollectionFetchPayload<ISurveyInstanceSubmissionListFilter>) => ITrackableAction;
  storeFilter: (listFilter: ISurveyInstanceSubmissionListFilter) => void;
  clearFilter: () => void;
}

type ISurveyInstanceFeedbackContainerProps = ISurveyInstanceFeedbackContainerOwnProps
  & ISurveyInstanceFeedbackContainerStateProps & ISurveyInstanceFeedbackContainerDispatchProps & IWithLocalizeOwnProps;

interface ISurveyInstanceFeedbackContainerState {
  sort: string;
  page: number;
  size: number;
}

// -- Component
// ----------

/** Container component for displaying survey instance */
class SurveyInstanceFeedbackContainer extends React.Component<ISurveyInstanceFeedbackContainerProps, ISurveyInstanceFeedbackContainerState> {
  state: ISurveyInstanceFeedbackContainerState = {
    sort: '',
    page: 0,
    size: midPageSize,
  };

  componentDidMount() {
    this.props.storeFilter({ token: this.props.surveyInstance.token });
  }

  componentDidUpdate(prevProps: ISurveyInstanceFeedbackContainerProps, prevState: ISurveyInstanceFeedbackContainerState) {
    if (this.state.page !== prevState.page || this.state.size !== prevState.size || this.state.sort !== prevState.sort || prevProps.surveyInstance !== this.props.surveyInstance || prevProps.surveyInstanceSubmissionListFilter !== this.props.surveyInstanceSubmissionListFilter) {
      this.fetchSurveyInstanceSubmissions();
    }
  }

  render = () => {
    return (
      <React.Fragment>
        {/* title={this.props.translate(`SURVEY_INSTANCE_FEEDBACK.SURVEY_TYPE_TITLE.${this.props.surveyType.name}`)} */}
        {this.props.surveyInstanceSubmissionList && <SurveyInstanceFeedbackModal onCancel={this.props.onModalClose} surveyInstanceSubmissionList={this.props.surveyInstanceSubmissionList.content} showEvaluationFeedback={true} surveyInstance={this.props.surveyInstance} />}
      </React.Fragment>
    );
  }

  fetchSurveyInstanceSubmissions = () => {
    if (this.props.surveyInstance) {
      this.props.fetchSurveyInstanceSubmissionList(this.props.surveyInstance.id, {
        filter: { ...this.props.surveyInstanceSubmissionListFilter },
        page: this.state.page,
        sort: [this.state.sort],
        size: this.state.size,
      });
    }
  }

}
// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ISurveyInstanceFeedbackContainerOwnProps): ISurveyInstanceFeedbackContainerStateProps => ({
  surveyInstanceSubmissionList: SurveyInstanceBusinessStore.selectors.getSurveyInstanceSubmissionList(state),
  surveyInstanceSubmissionListFilter: SurveyInstanceBusinessStore.selectors.getSurveyInstanceSubmissionListFilter(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ISurveyInstanceFeedbackContainerDispatchProps => ({
  fetchSurveyInstanceSubmissionList: (id: string, params: ICollectionFetchPayload<ISurveyInstanceSubmissionListFilter>) => createTrackableAction(dispatch(SurveyInstanceBusinessStore.actions.fetchSurveyInstanceSubmissionList(id, params))),
  storeFilter: (listFilter: ISurveyInstanceSubmissionListFilter) => dispatch(SurveyInstanceBusinessStore.actions.storeSurveyInstanceSubmissionListFilter(listFilter)),
  clearFilter: () => dispatch(SurveyInstanceBusinessStore.actions.clearSurveyInstanceSubmissionListFilter),
});

// -- HOCs and exports
// ----------
export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<ISurveyInstanceFeedbackContainerOwnProps>(SurveyInstanceFeedbackContainer as any));
