import { IReference } from '@src/model/common/Reference';

export interface IUserGroupMemberRole extends IReference<UserGroupMemberRoleEnum> {
}

export enum UserGroupMemberRoleEnum {
  OWNER = '1',
  ADMIN = '2',
  MEMBER = '3',
  PENDING_MEMBER = '4',
}
