import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import NoteForm from '@src/components/user/form/NoteForm';
import { INote, NoteObjectTypeEnum } from '@src/model/user/Note';
import { IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import UserNoteBusinessStore, { INoteCreatePayload } from '@src/service/business/usernotes/userNoteBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { Button } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

// -- Prop types
// ----------
interface INoteCreateContainerOwnProps {
  objectId: string;
  objectTypeId: NoteObjectTypeEnum;
  objectPublic?: boolean;
  onCreateNote?: () => void;
  buttonLabel?: string;
  modalTitle?: string;
  modalPlaceholder?: string;
  modalOkButtonLabel?: string;
}

interface INoteCreateContainerStateProps {
}

interface INoteCreateContainerDispatchProps {
  createUserNote: (data: INoteCreatePayload) => ITrackableAction;
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
}

// -- State types
// ----------
type INoteCreateContainerContentProps = INoteCreateContainerOwnProps & INoteCreateContainerStateProps & INoteCreateContainerDispatchProps & IWithLocalizeOwnProps;

interface INoteCreateContainerState {
  isNoteModalVisible: boolean;
}

// -- Component
// ----------

/** Component for note creation with callback for various objects */
class NoteCreateContainer extends React.Component<INoteCreateContainerContentProps, INoteCreateContainerState> {
  state = {
    isNoteModalVisible: false,
  };

  render() {
    return (
      <React.Fragment>
        <Button block={false} onClick={() => this.toggleNotesModal(true)} data-test-id="timun-notes__openNotesFormButton">{this.props.buttonLabel || this.props.translate('COURSE_VIEW.CREATE_NEW_NOTE')}</Button>
        {this.state.isNoteModalVisible && <NoteForm
          objectPublic={this.props.objectPublic}
          objectId={this.props.objectId}
          objectTypeId={this.props.objectTypeId}
          title={this.props.modalTitle || this.props.translate('COURSE_VIEW.CREATE_NEW_NOTE')}
          placeholder={this.props.modalPlaceholder}
          okButtonLabel={this.props.modalOkButtonLabel}
          onCancel={this.closeModal}
          onSubmit={this.handleCreateUserNote}
        />}
      </React.Fragment>
    );
  }

  closeModal = () => {
    this.toggleNotesModal(false);
  };

  toggleNotesModal = (isVisible: boolean) => {
    this.setState({ isNoteModalVisible: isVisible });
  };

  private handleCreateUserNote = (noteData: INote) => {
    this.props.createUserNote(noteData).track().subscribe(
      // success
      () => {
        this.toggleNotesModal(false);
        this.props.reportMessage({ message: this.props.translate('NOTE_CONTAINER.SUCCESS_CREATE_INFO_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.INFO });
        this.props.onCreateNote && this.props.onCreateNote();
      },
    );
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: INoteCreateContainerOwnProps): INoteCreateContainerStateProps => ({});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: Dispatch): INoteCreateContainerDispatchProps => ({
  createUserNote: (data: INoteCreatePayload) => createTrackableAction(dispatch(UserNoteBusinessStore.actions.createUserNote(data))),
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<INoteCreateContainerOwnProps>(NoteCreateContainer as any));
