import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import RepositoryFileListView from '@src/components/repository/file/RepositoryFileListView';
import { IFile } from '@src/model/file/File';
import { ICollectionData } from '@src/service/business/common/types';
import FileListBusinessStore, { IFileListFilter } from '@src/service/business/files/FileListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import React from 'react';
import { connect } from 'react-redux';

// -- Prop types
// ----------

interface IRepositoryFileListContainerOwnProps {
  selectedFile?: IFile;
  onFileSelect?: (data: IFile) => void;
}

interface IRepositoryFileListContainerStateProps {
  fileList: ICollectionData<IFile>;
  fileListFilter: IFileListFilter;
}

interface IRepositoryFileListContainerDispatchProps {
  fetchRepositoryFileList: (filter: IFileListFilter, page: number, size: number, sort: string[]) => void;
  clearFileList: () => void;
  storeFileListFilter: (filter: IFileListFilter) => void;
  clearFileListFilter: () => void;
}
type IRepositoryFileListContainerProps = IRepositoryFileListContainerOwnProps & IRepositoryFileListContainerStateProps & IRepositoryFileListContainerDispatchProps & IWithLocalizeOwnProps;

interface IRepositoryFileListContainerState {
  page: number;
  size: number;
  sort: string[];
}
// -- Component
// ----------

/** Container for uploading repository files to lectures */
class RepositoryFileListContainer extends React.Component<IRepositoryFileListContainerProps, IRepositoryFileListContainerState> {
  state: IRepositoryFileListContainerState = {
    page: 0,
    size: AppConfigService.getValue('api.paging.midPageSize'),
    sort: [],
  };

  componentDidMount = () => {
    this.updateList();
  };

  componentDidUpdate = (prevProps: IRepositoryFileListContainerProps, prevState: IRepositoryFileListContainerState) => {
    if (this.props.fileListFilter !== prevProps.fileListFilter || prevState.page !== this.state.page) {
      this.updateList();
    }
  };

  componentWillUnmount = () => {
    this.props.clearFileList();
    this.props.clearFileListFilter();
  };

  render = () => {
    return this.props.fileList ?
      <RepositoryFileListView
        fileList={this.props.fileList}
        fileListFilter={this.props.fileListFilter}
        selectedFile={this.props.selectedFile}
        onFileSelect={this.handleFileSelect}
        onPageChange={this.handlePageChange}
        onFilterChange={this.handleFilterChange}
      />
      : null;
  };

  handleFileSelect = (file: IFile) => {
    this.props.onFileSelect?.(file);
  }

  handleFilterChange = (filter: IFileListFilter) => {
    this.props.storeFileListFilter(filter);
    this.setState({ page: 0 });
  };

  handlePageChange = (page: number, pageSize?: number) => {
    this.setState({ page: page - 1, size: pageSize ?? this.state.size });
  };

  private updateList = () => {
    this.props.fetchRepositoryFileList(this.props.fileListFilter, this.state.page, this.state.size, this.state.sort);
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IRepositoryFileListContainerStateProps => ({
  fileList: FileListBusinessStore.selectors.getFileList(state),
  fileListFilter: FileListBusinessStore.selectors.getFileListFilter(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IRepositoryFileListContainerDispatchProps => ({
  fetchRepositoryFileList: (filter: IFileListFilter, page: number, size: number, sort: string[]) => dispatch(FileListBusinessStore.actions.fetchRepositoryFileList(filter, page, size, sort)),
  clearFileList: () => dispatch(FileListBusinessStore.actions.clearFileList()),
  storeFileListFilter: (filter: IFileListFilter) => dispatch(FileListBusinessStore.actions.storeFileListFilter(filter)),
  clearFileListFilter: () => dispatch(FileListBusinessStore.actions.clearFileListFilter()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<IRepositoryFileListContainerOwnProps>(RepositoryFileListContainer as any));
