import { Store } from 'redux';
import { Persistor } from 'redux-persist';

import { ILemonAction } from '@src/service/business/common/types';
import { getPersistor, getStore } from '@src/service/business/rootBusinessStore';
import { ConfigUtils } from '@src/service/util/config/ConfigUtils';

export type IStoreValueChangeCallback<T = any> = (next: T, current: T) => void;
export type IStoreValueComparator<T = any> = (current: T, next: T) => boolean;

/** Class with static methods for controlled access to redux store. */
export default class StoreService {
  /** Dispatch redux store action. */
  static dispatchAction<T extends ILemonAction>(action: T): T {
    return getStore().dispatch<T>(action);
  }

  /** Returns instance of redux action store. */
  static getStore(): Store {
    return getStore();
  }

  /** Get store persistor. */
  static getPersistor(): Persistor {
    return getPersistor();
  }

  /** Return store value on given path. */
  static getValue = <T>(path: string): T => {
    return ConfigUtils.reducePath(StoreService.getStore().getState(), path);
  };

  /** Notify callback function if value store on path changes. */
  static watchValue = (path: string, callback: IStoreValueChangeCallback, compare?: IStoreValueComparator<any>): void => {
    let currentValue = StoreService.getValue(path);

    StoreService.getStore().subscribe(() => {
      const nextValue = StoreService.getValue(path);

      if ((compare && compare(currentValue, nextValue)) || currentValue === nextValue) {
        const oldValue = currentValue;
        currentValue = nextValue;
        callback(currentValue, oldValue);
      }
    });
  };
}
