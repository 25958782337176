import GridItem from '@src/components/common/grid/GridItem';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ViewSectionContentItem from '@src/components/common/section/ViewSectionContentItem';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import ExternalEducationTemplateStatus from '@src/components/externaleducation/ExternalEducationTemplateStatus';
import { IEnrollmentRequirement } from '@src/model/enrollmentrequirement/EnrollmentRequirement';
import { IExternalEducationOutcome } from '@src/model/externalEducationTemplate/ExternalEducationOutcome';
import { ExternalEducationTemplateStatusEnum, IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { ISkill } from '@src/model/skillgroup/Skill';
import { Button, Card, Col, Row, Tag } from 'antd';
import React from 'react';

interface IExternalEducationTemplateDetailsPanelOwnProps {
  isCurrentUserCoordinator?: boolean;
  externalEducationTemplate: IExternalEducationTemplate;
  onVerificationSubmit?: (updatedExternalEducationTemplate: IExternalEducationTemplate) => void;
}
type IExternalEducationTemplateDetailsPanelProps = IExternalEducationTemplateDetailsPanelOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Display external education template info sections. */
const ExternalEducationTemplateDetailsPanel: React.FC<IExternalEducationTemplateDetailsPanelProps> = (props: IExternalEducationTemplateDetailsPanelProps) => {
  const renderOutcomes = (data: IExternalEducationOutcome[]) => {
    return data.map((outcome: IExternalEducationOutcome) => {
      return <Row key={outcome.id}>
        <Col xs={24} md={12}>
          <ViewSectionContentItem icon={<LemonIcon name="outcome" size="xlarge" />} title={outcome.title} description={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_OUTCOME_DESCRIPTION')} />
        </Col>
      </Row>;
    });
  };
  const renderEnrollmentRequirements = (data: IEnrollmentRequirement[]) => {
    return data.map((enrollmentReq) => {
      return <Col xs={24} md={12} key={enrollmentReq.id}>
        <ViewSectionContentItem
          icon={<LemonIcon name="enrollmentRequirement" size="xlarge" />}
          title={enrollmentReq.name}
          description={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.ENROLLMENT_REQUIREMENTS_DESCRIPTION')} />
      </Col>;
    });
  };

  const handleVerificationSubmit = () => {
    const externalEducationTemplateData = { ...props.externalEducationTemplate };
    externalEducationTemplateData.status.id = ExternalEducationTemplateStatusEnum.CREATION_FINISHED;
    return props.onVerificationSubmit?.(externalEducationTemplateData);
  };

  const isVerificationSubmitButtonVisible = (props.externalEducationTemplate.status.id === ExternalEducationTemplateStatusEnum.IN_CREATION) && !props.isCurrentUserCoordinator;

  return (
    <Card className="lemon-card--fullWidthBody">
      <Col className="timun-externalEducationTemplate__contentColumn">
        <Row justify="space-between" className="timun-externalEducationTemplate__row">
          {/* Title */}
          <Row gutter={8}>
            <Col>
              <HeaderTitle title={props.externalEducationTemplate.title} icon={<LemonIcon name="externalEducation" size="large" />} />
            </Col>
            <Col>
              <ExternalEducationTemplateStatus status={props.externalEducationTemplate.status} />
            </Col>
          </Row>
          {isVerificationSubmitButtonVisible && <Col className="timun-externalEducationTemplate__column">
            <Button onClick={handleVerificationSubmit} size="middle" type="primary">{props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.VERIFICATION_BUTTON_LABEL')}</Button>
          </Col>}
        </Row>

        <Row className="timun-externalEducationTemplate__row">
          {/* Skills */}
          <GridItem className="timun-externalEducationTemplate__gridItem" label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.SKILLS_TITLE')}>
            {props.externalEducationTemplate.skills.map((skill: ISkill) => <Tag key={skill.id}> {skill.title} </Tag>)}
          </GridItem>

          {/* Education sector */}
          <GridItem className="timun-externalEducationTemplate__gridItem" label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_SECTOR_TITLE')}>
            <Tag> {props.externalEducationTemplate?.educationSector.name} </Tag>
          </GridItem>
        </Row>


        <Row className="timun-externalEducationTemplate__row">
          {/* Details */}
          <GridItem className="timun-externalEducationTemplate__gridItem" label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.DETAILS_TITLE')}>
            <Row>
              <Col xs={24} md={12}>
                <ViewSectionContentItem
                  icon={<LemonIcon name="clockCircle" size="xlarge" />}
                  title={`${props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_DURATION', { duration: props.externalEducationTemplate.duration })}`}
                  description={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_DURATION_LABEL')} />
                <ViewSectionContentItem
                  icon={<LemonIcon name="moneyBill" size="xlarge" />}
                  title={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.COST_LABEL', { minPrice: props.externalEducationTemplate.minPrice, maxPrice: props.externalEducationTemplate.maxPrice })}
                  description={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.COST_SPAN_DESCRIPTION')} />

              </Col>
              <Col xs={24} md={12}>
                <ViewSectionContentItem icon={<LemonIcon name="certificate" size="xlarge" />} title={props.externalEducationTemplate.contractorQualification} description={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.CONTRACTOR_QUALIFICATION_DESCRIPTION')} />
                <ViewSectionContentItem icon={<LemonIcon name="certificateName" size="xlarge" />} title={props.externalEducationTemplate.certificateName} description={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.CERTIFICATE_NAME_DESCRIPTION')} />
              </Col>
            </Row>
          </GridItem>
        </Row>

        <Row className="timun-externalEducationTemplate__row">
          {/* Description */}
          <GridItem className="timun-externalEducationTemplate__gridItem" label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.DESCRIPTION_TITLE')}>
            {props.externalEducationTemplate.description}
          </GridItem>
        </Row>


        <Row className="timun-externalEducationTemplate__row">
          {/* Enrollment requirements */}
          <GridItem className="timun-externalEducationTemplate__gridItem" label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.ENROLLMENT_REQUIREMENTS_TITLE')}>
            <Row>
              {renderEnrollmentRequirements(props.externalEducationTemplate.enrollmentRequirements)}
            </Row>
          </GridItem>
        </Row>


        <Row className="timun-externalEducationTemplate__row">
          {/* Outcomes */}
          <GridItem className="timun-externalEducationTemplate__gridItem" label={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_OUTCOME_LABEL')}>
            {renderOutcomes(props.externalEducationTemplate.educationOutcomes)}
          </GridItem>
        </Row>
      </Col>
    </Card>
  );
};

export default withLocalize<IExternalEducationTemplateDetailsPanelOwnProps>(ExternalEducationTemplateDetailsPanel as any);
