import React from 'react';

import IfUserRoles from '@src/components/common/role/IfUserRoles';
import PrivateCourseListContainer from '@src/components/course/list/catalog/PrivateCourseListContainer';
import { UserRoleEnum } from '@src/model/user/UserRole';

const CourseListPage = () => (
  <React.Fragment>
    <IfUserRoles roles={[UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN, UserRoleEnum.COURSE_CREATOR, UserRoleEnum.TRAINEE]} fallback="">
      <PrivateCourseListContainer />
    </IfUserRoles>
  </React.Fragment>
);

export default CourseListPage;
