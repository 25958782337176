import DeleteElementButton from '@src/components/common/button/DeleteElementButton';
import LemonIcon from '@src/components/common/image/LemonIcon';
import ListPagination, { ListPaginationTypeEnum } from '@src/components/common/list/ListPagination';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import DateTimeView from '@src/components/common/view/DateTimeView';
import { IReminder, ReminderTypeEnum } from '@src/model/reminder/Reminder';
import { ICollectionData } from '@src/service/business/common/types';
import { Col, Empty, Row, Tooltip, Typography } from 'antd';
import React from 'react';

// -- Prop types
// ----------

export interface IReminderListOwnProps {
  reminderList: ICollectionData<IReminder>;
  onReminderDelete: (reminder: IReminder) => void;
  onReminderUpdate: (reminder: IReminder) => void;
  onLoadMore?: () => void;
}

type IReminderListProps = IReminderListOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Component for rendering list of reminders ... */
class ReminderList extends React.Component<IReminderListProps> {
  renderReminderItem = (reminder: IReminder) => {
    return (
      <Row key={reminder.id} className="timun-reminderCalendar__reminderListItem">
        <Col span={24}>
          <div className="panel">
            <Row justify="space-between">
              <Col>
                <Typography.Title level={4}>{this.getReminderTypeLabel(reminder.type.id)}</Typography.Title>
              </Col>
              {((reminder.type.id === ReminderTypeEnum.USER_DEFINED) || (reminder.type.id === ReminderTypeEnum.WEBINAR)) && (
                <Col>
                  <Tooltip overlay={this.props.translate('COMMON.ACTION_EDIT')}>
                    <a onClick={() => this.props.onReminderUpdate(reminder)}>
                      <LemonIcon name="edit"/>
                    </a>
                  </Tooltip>
                  &nbsp;&nbsp;
                  <DeleteElementButton<IReminder> item={reminder} onDelete={this.props.onReminderDelete} iconOnly={true}/>
                </Col>
              )}
            </Row>
            <Row>
              <Col>
                <Typography.Paragraph>{reminder.description}</Typography.Paragraph>
              </Col>
            </Row>
            <Row>
              <Typography.Text type="secondary">
                <DateTimeView value={reminder.startDateTime} mode="datetime" fallback=""/>
              </Typography.Text>
            </Row>
          </div>
        </Col>
      </Row>
    );
  };

  render = () => {
    return this.props.reminderList.content.length > 0 ? (
      <React.Fragment>
        {this.props.reminderList.content.map((reminder: IReminder) => {
          return this.renderReminderItem(reminder);
        })}
        <ListPagination page={this.props.reminderList.page} paginationType={ListPaginationTypeEnum.LOAD_MORE} onLoadMore={this.props.onLoadMore}/>
      </React.Fragment>
    ) : (
      <Empty description={this.props.translate('REMINDER_CALENDAR.EMPTY_MESSAGE')}/>
    );
  };

  getReminderTypeLabel = (reminderType: string) => {
    if (reminderType === ReminderTypeEnum.WEBINAR) {
      return this.props.translate('REMINDER_TYPE.WEBINAR');
    } else if (reminderType === ReminderTypeEnum.CONSULTATIONS) {
      return this.props.translate('REMINDER_TYPE.CONSULTATIONS');
    } else {
      return this.props.translate('REMINDER_TYPE.USER_DEFINED');
    }
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<IReminderListOwnProps>(ReminderList as any);
