import { Col, Row, Typography } from 'antd';
import React from 'react';

import DatetimeView from '@src/components/common/view/DateTimeView';
import { INotification } from '@src/model/notification/Notification';

interface IDashboardNotificationListItemOwnProps {
  notification: INotification;
}

class DashboardNotificationListItem extends React.Component<IDashboardNotificationListItemOwnProps> {
  render() {
    return (
      <Row className="timun--dashboardView__notificationListItem">
        <Col className="timun-dashboardView__notificationContent" span={24}>
          <div className="panel">
            <Row className="timun-dashboardView__notificationTitle">
              <Typography.Title level={4}>{this.props.notification.title}</Typography.Title>
            </Row>
            <Row className="timun-dashboardView__notificationMessage">
              <Typography.Paragraph ellipsis={{ expandable: true }}>{this.props.notification.message}</Typography.Paragraph>
            </Row>
            <Row>
              <Typography.Text type="secondary">
                <DatetimeView value={this.props.notification.createdAt} fallback="" />
              </Typography.Text>
            </Row>
          </div>
        </Col>
      </Row>
    );
  }
}

export default DashboardNotificationListItem;
