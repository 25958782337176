import React from 'react';
import { IQuiz } from '@lamarodigital/quizzler-lib-frontend/model/quiz/Quiz';
import { QuizTypeEnum } from '@lamarodigital/quizzler-lib-frontend/model/quiz/QuizType';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import QuizCreatorContainer from '@src/components/exam/quiz/QuizCreatorContainer';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import { LeftOutlined } from '@ant-design/icons';
import { ISurveyTemplate } from '@src/model/survey/SurveyTemplate';

// -- Prop types
// ----------
export interface ISurveyTemplateViewOwnProps {
  surveyTemplate?: ISurveyTemplate;
  onSurveyCollectionSubmit: (surveyCollection: IQuiz) => void;
}


type ISurveyTemplateViewProps = ISurveyTemplateViewOwnProps & IWithLocalizeOwnProps;

interface ISurveyTemplateViewState {
}

// -- Component
// ----------

/** Container component for displaying survey template */
class SurveyTemplateView extends React.Component<ISurveyTemplateViewProps, ISurveyTemplateViewState> {
  state: ISurveyTemplateViewState = {
  };

  componentDidMount() {
  }

  componentDidUpdate(prevProps: ISurveyTemplateViewProps, prevState: ISurveyTemplateViewState) {
  }

  render = () => {
    const surveyTemplateListRoute = 'administration/surveytemplates';
    
    return (
      <React.Fragment>
        <HeaderTitle link={surveyTemplateListRoute} icon={<LeftOutlined />} title={this.props.translate(`SURVEY_TEMPLATE.TITLE.${this.props.surveyTemplate?.title}`)} />
        <QuizCreatorContainer quizType={QuizTypeEnum.SURVEY} quizId={this.props.surveyTemplate?.collection?.id} onQuizCollectionSubmit={this.props.onSurveyCollectionSubmit} />
      </React.Fragment>
    );
  }

}

// -- HOCs and exports
// ----------
export default withLocalize<ISurveyTemplateViewOwnProps>(SurveyTemplateView as any);
