import { LeftOutlined } from '@ant-design/icons';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import RouterNavigationPrompt from '@src/components/common/route/prompt/RouterNavigationPrompt';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import ExternalContentDataForm from '@src/components/externalcontent/create/ExternalContentDataForm';
import { IExternalContent } from '@src/model/externalcontent/ExternalContent';
import { IFile } from '@src/model/file/File';
import { IExternalContentCreatePayload } from '@src/service/business/externalcontent/ExternalContentViewBusinessStore';
import { Button, Layout, Row } from 'antd';
import IframeResizer from 'iframe-resizer-react';
import React from 'react';

const { Header, Content } = Layout;

// -- Prop types
// ----------

interface IExternalContentViewOwnProps {
  externalContent?: IExternalContent;
  canEdit?: boolean;
  isEditing?: boolean;
  onUpdate?: (data: IExternalContentCreatePayload) => void;
  onDelete?: () => void;
  onCoverUpload?: (data: IFile) => void;
  setEditing?: (isEditing: boolean) => void;
}

type IExternalContentViewProps = IExternalContentViewOwnProps & IWithLocalizeOwnProps;

interface IExternalContentViewState {
  isDeleteAction: boolean;
}

// -- Component
// ----------

/**
 * Content displays eternal web content in an IFRAME element.
 *
 * Unresizable content is content not under our control thus we cannot setup resizer.
 * We should introduce content types in order to make appropriate assumptions about content sizing.
 */
class ExternalContentView extends React.Component<IExternalContentViewProps, IExternalContentViewState> {
  state: IExternalContentViewState = {
    isDeleteAction: false,
  };

  render = () => {
    const header = this.props.externalContent && (
      <Row justify="space-between">
        <HeaderTitle link={'/externalcontents'} icon={<LeftOutlined/>} title={this.props.externalContent.title}/>
        {this.props.canEdit && !this.props.isEditing && <Button onClick={() => this.props.setEditing && this.props.setEditing(true)}>{this.props.translate('COMMON.ACTION_EDIT')}</Button>}
      </Row>
    );

    return (
      <RouterNavigationPrompt when={this.props.canEdit && this.props.isEditing && !this.state.isDeleteAction} message={this.props.translate('COMMON.CONFIRMATION_ROUTE_NAVIGATION')}>
        <Layout>
          <Header className="timun-externalContentView__header">{header}</Header>
          <Content className="timun-externalContentView__container panel">
            <Layout>
              {this.props.isEditing && this.props.onCoverUpload ? (
                <ExternalContentDataForm externalContent={this.props.externalContent} onCoverSubmit={this.props.onCoverUpload} onSubmit={this.handleSubmit} onDelete={this.handleDelete} onCancel={this.props.setEditing}/>
              ) : (
                this.props.externalContent && (
                  <React.Fragment>
                    {this.props.externalContent.description && <div className="timun-externalContentView__description">{this.props.externalContent.description}</div>}

                    <div className="timun-externalContentView__contentContainer">
                      <IframeResizer className="timun-externalContentView__content timun-externalContentView__content--unresizable" src={this.props.externalContent.url} checkOrigin={false} heightCalculationMethod="lowestElement" onInit={this.handleIframeResizerInit} scrolling="omit"/>
                    </div>
                  </React.Fragment>
                )
              )}
            </Layout>
          </Content>
        </Layout>
      </RouterNavigationPrompt>
    );
  };

  /**
   * We've successfully initialized resizer.
   *
   * If we've initialized resizer adjust some props for resizable content.
   */
  handleIframeResizerInit = (iframe: HTMLIFrameElement) => {
    // remove "unresizable" content styles
    iframe.classList.remove('timun-externalContentView__content--unresizable');

    // scrolling is ommited by default so we'll disable it only for resizable content
    iframe.scrolling = 'no';
  };

  handleDelete = () => {
    this.setState({ isDeleteAction: true });
    this.props.onDelete?.();
  };

  handleSubmit = (data: IExternalContentCreatePayload) => {
    if (this.props.onUpdate) {
      this.props.onUpdate(data);
    }
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<IExternalContentViewOwnProps>(ExternalContentView as any);
