import React from 'react';

import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ExamInstanceHelperUtils from '@src/components/exam/common/ExamInstanceHelperUtils';
import { ActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { ICourseProgress } from '@src/model/course/Course';
import { ICourseQuiz } from '@src/model/course/CourseQuiz';
import { Col, Row, Tooltip } from 'antd';


// -- Prop types
// ----------

export interface ICourseProgressInfoOwnProps {
  progress?: ICourseProgress;
  quiz?: ICourseQuiz;
}
type ICourseProgressInfoProps = ICourseProgressInfoOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Display course deadline date and exam results */
const CourseProgressInfo: React.FC<ICourseProgressInfoProps> = (props: ICourseProgressInfoProps) => {
  return (
    <Row align="bottom" justify="space-between">
      <Col>
        {props.progress?.deadlineDate && <Tooltip title={props.translate('COURSE_PROGRESS_INFO.DEADLINE_DATE_TOOLTIP_LABEL')}>
          <LemonIcon name="deadlineDate" />  {props.progress.deadlineDate}
        </Tooltip>}
      </Col>
      <Col>
        {props.quiz?.examInstance?.activities && ExamInstanceHelperUtils.isExamInstancenInPhase(props.quiz.examInstance, [ActivityPhaseEnum.EVALUATED]) && <Tooltip title={props.translate('COURSE_PROGRESS_INFO.EARNED_POINTS_TOOLTIP_LABEL')}>
          <LemonIcon name="spellCheck" />  {ExamInstanceHelperUtils.getTotalAndEarnedPointsRatio(props.quiz.examInstance)}
        </Tooltip>}
      </Col>
    </Row>
  );
};

export default withLocalize<ICourseProgressInfoOwnProps>(CourseProgressInfo as any);
