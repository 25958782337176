import React from 'react';

import { Upload } from 'antd';
// tslint:disable-next-line:no-submodule-imports
import { UploadProps } from 'antd/lib/upload';

import FileUtils from '@src/components/common/file/FileUtils';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { FileUploadHelper } from '@src/components/common/upload/FileUploadHelper';
import { IFile } from '@src/model/file/File';
import { SuccessApiResponse } from '@src/service/api/model/apiEvent';
import { RcFile, UploadChangeParam, UploadFile, UploadListType } from 'antd/lib/upload/interface';

export type IUploadedFile = RcFile;

// -- Prop types
// ----------

export interface IUploadStatus {
  isUploading: boolean;
}

export interface IFileUploadOwnProps {
  children?: (status: IUploadStatus) => React.ReactNode;
  uploadProps?: UploadProps<IFile>;
  dragger?: boolean;
  onUploadFile?: (file: IFile) => void;

  dataTestIdPrefix?: string;
}
type IFileUploadProps = IFileUploadOwnProps & IWithLocalizeOwnProps;

interface IFileUploadState {
  isUploading: boolean;
}

class FileUpload extends React.Component<IFileUploadProps, IFileUploadState> {
  state: IFileUploadState = {
    isUploading: false,
  };

  handleChange = (info: UploadChangeParam<UploadFile<SuccessApiResponse<IFile>>>) => {
    if (this.props.uploadProps?.onChange) {
      this.props.uploadProps?.onChange(info);
    }
    FileUploadHelper.handleAntdUploadChange<IFile>(info, this.props.onUploadFile);

    if (info.file.status === 'done') {
      // stop spinner
      this.setState({ isUploading: false });
    }
  };

  beforeUpload = (file: RcFile, FileList: RcFile[]) => {
    this.setState({ isUploading: true });

    if (this.props.uploadProps?.beforeUpload) {
      return this.props.uploadProps?.beforeUpload(file, FileList);
    }
    return true;
  };

  getUploadProps = () => {
    if (this.props.uploadProps) {
      const { onChange, beforeUpload, ...uploadProps } = this.props.uploadProps;
      return uploadProps;
    }
    return {};
  };

  render() {
    const UploadType = !this.props.dragger ? Upload : Upload.Dragger;

    const props: UploadProps<IFile> = {
      ...FileUploadHelper.getUploadDefaultProps(),
      iconRender: (file: UploadFile, listType?: UploadListType) => FileUtils.isFilePreviewable(file.type) && <LemonIcon className="timun-fileUpload__icon" onClick={() => this.props.uploadProps?.onPreview?.(file)} name="eye" />,
      showUploadList: false,
      multiple: true,
      onChange: this.handleChange,
      beforeUpload: this.beforeUpload,
      ...this.getUploadProps(),
    };

    return (
      <UploadType {...props} {...this.props.uploadProps} data-test-id={this.props.dataTestIdPrefix ? `${this.props.dataTestIdPrefix}_upload` : undefined}>
        {this.props.children != null ? this.props.children({ isUploading: this.state.isUploading }) : null}
      </UploadType>
    );
  }
}

export default withLocalize<IFileUploadOwnProps>(FileUpload as any);
