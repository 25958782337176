import React from 'react';

import LemonImage from '@src/components/common/image/LemonImage';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';

// -- Prop types
// ----------
export interface ICourseCoverImagePublicProps {
  coverImageUrl?: string;
}
interface ICourseCoverImageStateProps { }
type ICourseCoverImageProps = ICourseCoverImagePublicProps & IWithLocalizeOwnProps & ICourseCoverImageStateProps;

// --
// ----- Component
const CourseCoverImage: React.FC<ICourseCoverImageProps> = (props) => {

  return (
    <LemonImage imagePath={props.coverImageUrl || 'cover-placeholder.png'} className="timun-courseCard__coverImage" />
  );
};

export default withLocalize<ICourseCoverImagePublicProps>(CourseCoverImage as any);
