import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';
import { ISurveyTemplate } from '@src/model/survey/SurveyTemplate';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { ICollectionFetchPayload, ILemonAction, IPayloadAction, ICollectionData } from '@src/service/business/common/types';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { trackAction } from '@src/service/util/observable/operators';
import { startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';

// -
// -------------------- Types&Consts
export interface ISurveyTemplateListFilter {}

// List filter ID
const SURVEY_TEMPLATE_LIST_FILTER = '@@SURVEY_TEMPLATE_LIST_FILTER';

// -
// -------------------- Selectors

/** Returns SurveyTemplate list from store. */
const getSurveyTemplateList = (store: any): ICollectionData<ISurveyTemplate> => store.surveyTemplateList;

/** Returns UserGroupMember list filter. */
const getSurveyTemplateListFilter = (store: any): ISurveyTemplateListFilter => ListFilterBusinessStore.selectors.getListFilter(store, SURVEY_TEMPLATE_LIST_FILTER);

// -
// -------------------- Actions

const Actions = {
  SURVEY_TEMPLATE_LIST_FETCH: 'SURVEY_TEMPLATE_LIST_FETCH',
  SURVEY_TEMPLATE_LIST_LOAD: 'SURVEY_TEMPLATE_LIST_LOAD',
  SURVEY_TEMPLATE_LIST_CLEAR: 'SURVEY_TEMPLATE_LIST_CLEAR',
};

/** Fetch SurveyTemplate list by filter */
const fetchSurveyTemplateList = (params: ICollectionFetchPayload<ISurveyTemplateListFilter>): IPayloadAction<ICollectionFetchPayload<ISurveyTemplateListFilter>> => {
  return {
    type: Actions.SURVEY_TEMPLATE_LIST_FETCH,
    payload: params,
  };
};

/** Load SurveyTemplate list to store. */
const loadSurveyTemplateList = (data: ICollectionData<ISurveyTemplate>): IPayloadAction<ICollectionData<ISurveyTemplate>> => {
  return {
    type: Actions.SURVEY_TEMPLATE_LIST_LOAD,
    payload: data,
  };
};

/** Clear SurveyTemplate list from store. Eg. when leaving view. */
const clearSurveyTemplateList = (): ILemonAction => {
  return {
    type: Actions.SURVEY_TEMPLATE_LIST_CLEAR,
  };
};

/** Store list filter to store. */
const storeSurveyTemplateListFilter = (listFilter: ISurveyTemplateListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(SURVEY_TEMPLATE_LIST_FILTER, listFilter);
};

/** Clear list filter from store. */
const clearSurveyTemplateListFilter = (): ILemonAction => {
  return ListFilterBusinessStore.actions.clearListFilter(SURVEY_TEMPLATE_LIST_FILTER);
};

// -
// -------------------- Side-effects

const fetchSurveyTemplateListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<ISurveyTemplateListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.SURVEY_TEMPLATE_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('SurveyTemplate')
        .fetchEntityList(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadSurveyTemplateList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching SurveyTemplate list', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const surveyTemplateList = (state: ICollectionData<ISurveyTemplate> | null = null, action: IPayloadAction<ICollectionData<ISurveyTemplate>>) => {
  if (action.type === Actions.SURVEY_TEMPLATE_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.SURVEY_TEMPLATE_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const surveyTemplateBusinessStore = {
  actions: {
    fetchSurveyTemplateList,
    loadSurveyTemplateList,
    clearSurveyTemplateList,
    storeSurveyTemplateListFilter,
    clearSurveyTemplateListFilter,
  },

  selectors: {
    getSurveyTemplateList,
    getSurveyTemplateListFilter,
  },

  effects: {
    fetchSurveyTemplateListEffect,
  },

  reducers: {
    surveyTemplateList,
  },
};

// --
// export business store
export default surveyTemplateBusinessStore;
