import FileList from '@src/components/common/file/FileList';
import { IListAdditionalCol } from '@src/components/common/list/ListAdditionalCol';
import ListPagination from '@src/components/common/list/ListPagination';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import FileUpload, { IUploadStatus } from '@src/components/common/upload/FileUpload';
import RepositoryFileListFilter from '@src/components/repository/file/RepositoryFileListFilter';
import { IFile } from '@src/model/file/File';
import { ICollectionData } from '@src/service/business/common/types';
import { IFileListFilter } from '@src/service/business/files/FileListBusinessStore';
import { Button, Row, Spin } from 'antd';
import React from 'react';

// -- Prop types
// ----------

export interface IRepositoryFileViewOwnProps {
  fileList: ICollectionData<IFile>;
  fileListFilter: IFileListFilter;
  renderAdditionalCols?: () => IListAdditionalCol[];
  onPageChange: (page: number, pageSize?: number) => void;
  onFilterChange: (filter: IFileListFilter) => void;
  onUpload: (data: IFile) => void;
  onDelete: (data: IFile) => void;
}

type IRepositoryFileViewProps = IRepositoryFileViewOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

class RepositoryFileView extends React.Component<IRepositoryFileViewProps> {
  render = () => {
    return <React.Fragment>
      <Row justify="space-between">
        <RepositoryFileListFilter fileListFilter={this.props.fileListFilter} onFilterChange={this.props.onFilterChange}/>
        <FileUpload onUploadFile={this.props.onUpload}>
          {(status: IUploadStatus) => (
            <React.Fragment>
              {status.isUploading && <Spin/>}&nbsp;
              <Button type="primary">+ {this.props.translate('REPOSITORY.FILE_ADD_LABEL')}</Button>
            </React.Fragment>
          )}
        </FileUpload>
      </Row>
      {this.props.fileList && <ListPagination page={this.props.fileList.page} onChange={this.props.onPageChange}/>}
      {this.props.fileList && <FileList fileList={this.props.fileList.content} fileColWidth={12} renderAdditionalCols={this.props.renderAdditionalCols} onRemoveFile={this.props.onDelete} showActions={true}/>}
      {this.props.fileList && <ListPagination page={this.props.fileList.page} onChange={this.props.onPageChange}/>}
    </React.Fragment>;
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<IRepositoryFileViewOwnProps>(RepositoryFileView as any);
