import AppContent from '@src/components/common/container/AppContent';
import { IListAdditionalCol } from '@src/components/common/list/ListAdditionalCol';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import UserListContainer from '@src/components/user/list/UserListContainer';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { IUserListFilter } from '@src/service/business/user/userListBusinessStore';
import React from 'react';

// -- Prop types
// ----------

export interface IAdminUsersReportContainerOwnProps {
}

type IAdminUsersReportContainerProps = IAdminUsersReportContainerOwnProps & IWithLocalizeOwnProps;

interface IAdminUsersReportContainerState {
}

class AdminUsersReportContainer extends React.Component<IAdminUsersReportContainerProps, IAdminUsersReportContainerState> {
  predefinedListFilter: Readonly<IUserListFilter> = { role: UserRoleEnum.TRAINEE, withCoursesStats: true };

  render = () => {
    return (
      <AppContent>
        <UserListContainer additionalCols={this.additionalCols} additionalSearchParams={false} withCreateUserButton={false} predefinedListFilter={this.predefinedListFilter}/>
      </AppContent>
    );
  };

  private additionalCols = (): IListAdditionalCol[] => {
    return [
      {
        key: 'workPosition',
        responsiveWidth: { xs: 8, lg: 6 },
        headerTitle: this.props.translate('USER_LIST.WORKPOSITION_LABEL'),
        content: (user: IUserInfo) => (user.workData?.workPosition ? user.workData.workPosition.name : null),
      },
      {
        key: 'coursesStats',
        responsiveWidth: { xs: 8, lg: 6 },
        headerTitle: this.props.translate('USER_LIST.COMPLETED_COURSES_LABEL'),
        content: (user: IUserInfo) => (user.additionalData && user.additionalData.coursesStats ? `${+user.additionalData.coursesStats.completedCount} / ${+user.additionalData.coursesStats.enrolledCount}` : null), // TODO: add course progress status
      },
    ];
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<IAdminUsersReportContainerOwnProps>(AdminUsersReportContainer as any);
