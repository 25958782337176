import GridItem from '@src/components/common/grid/GridItem';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withNumberFormat, { IWithNumberFormatOwnProps } from '@src/components/common/numberformat/withNumberFormat';
import ViewSectionContentItem from '@src/components/common/section/ViewSectionContentItem';
import ViewSectionItem from '@src/components/common/section/ViewSectionItem';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import { ExpeneseTypeEnum, IExternalEducationExpense } from '@src/model/externalEducationExpense/ExternalEducationExpense';
import AppConfigService from '@src/service/common/AppConfigService';
import { Col, Row } from 'antd';
import moment from 'moment';
import React from 'react';

const dateFormat = AppConfigService.getValue('dateTimeFormat.dateWithYear');

interface IEducationApplicationExpensesViewSectionOwnProps {
  expenses: IExternalEducationExpense[];
}

type IEducationApplicationExpensesViewSectionProps = IEducationApplicationExpensesViewSectionOwnProps & IWithLocalizeOwnProps & IWithNumberFormatOwnProps;

interface IEducationApplicationExpensesViewSectionState {
}

class EducationApplicationExpensesViewSection extends React.Component<IEducationApplicationExpensesViewSectionProps, IEducationApplicationExpensesViewSectionState> {
  state: IEducationApplicationExpensesViewSectionState = {
  };

  getExpenseList = (type: ExpeneseTypeEnum): IExternalEducationExpense[] => {
    return this.props.expenses.filter((expense) => expense.expenseType.id === type);
  };

  render = () => {

    return (
      <React.Fragment>
        <ViewSectionItem
          bordered={true}
          header={<HeaderTitle title={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.EXPENSE.PANEL_TITLE')} icon={<LemonIcon name="coins" />} />}
          collapsible={true}
        >
          <Row>
            <Col md={24} lg={12}>
              <GridItem label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EDUCATION_EXPENSE_HEADER_TITLE')}>
                {this.renderexpenses(this.getExpenseList(ExpeneseTypeEnum.EDUCATION_OFFER_PRICE))}
              </GridItem>
            </Col>
            {/* TODO: hide for HZZ demo purpose only */}
            {/* <Col xs={24} md={12} lg={8}>
              <GridItem label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.INSURANCE_EXPENSE_HEADER_TITLE')}>
                {this.renderexpenses(this.getExpenseList(ExpeneseTypeEnum.INSURANCE_EXPENSE))}
              </GridItem>
            </Col> */}
            <Col md={24} lg={12}>
              <GridItem label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.TRAVEL_EXPENSE_HEADER_TITLE')}>
                {this.renderexpenses(this.getExpenseList(ExpeneseTypeEnum.TRAVEL_EXPENSE))}
              </GridItem>
            </Col>
          </Row>
        </ViewSectionItem>
      </React.Fragment>
    );
  };

  renderexpenses = (expenses?: IExternalEducationExpense[]) => {
    return <React.Fragment>
      {expenses?.map((expense) => {
        return <ViewSectionContentItem key={expense.id}
          icon={<LemonIcon name="coins" size="xlarge" />}
          title={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.LIST_VIEW.EXPENSE_VALUE_LABEL', { value: this.props.formatCurrency(expense.value) })}
          description={moment(expense.paymentDate).format(dateFormat)} />;
      })}
    </React.Fragment>;
  };


}

export default withNumberFormat(withLocalize<IEducationApplicationExpensesViewSectionOwnProps>(EducationApplicationExpensesViewSection as any));
