import { Form } from '@ant-design/compatible';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IExternalContentListFilter } from '@src/service/business/externalcontent/ExternalContentListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';

import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Col, Input, Row, Select } from 'antd';
import { debounce } from 'lodash';
import React, { ChangeEvent } from 'react';

const sortItems = [
  {
    id: 'title,asc',
    translationKey: 'COMMON.ACTION_SORT_ASCEND',
  },
  {
    id: 'title,desc',
    translationKey: 'COMMON.ACTION_SORT_DESCEND',
  },
];

// -- Prop types
// ----------

export interface IExternalContentListFilterOwnProps {
  filter: IExternalContentListFilter;
  sortValue: string[];
  onFilterChange: (filter: IExternalContentListFilter) => void;
  onSortChange: (sort: string[]) => void;
}
type IExternalContentListFilterProps = IExternalContentListFilterOwnProps & FormComponentProps & IWithLocalizeOwnProps;

// -- Component
// ----------

class ExternalContentListFilter extends React.Component<IExternalContentListFilterProps> {

  debouncedSearch = debounce((value: string) => {
    const filter = { title: value };
    this.props.onFilterChange(filter);
  }, AppConfigService.getValue(`components.common.debounceTimeout`));
  render = () => {
    const { getFieldDecorator } = this.props.form;
    return <Form>
      <Row gutter={12}>
        <Col xs={24} lg={12}>
          <Form.Item label={this.props.translate('COMMON.ACTION_FILTER')}>
            {
              getFieldDecorator('title', {
                initialValue: this.props.filter?.title || ''
              })
                (<Input allowClear={true} placeholder={this.props.translate('EXTERNAL_CONTENT.LIST.FILTER_PLACEHOLDER')} onChange={this.handleChange} />)
            }
          </Form.Item>
        </Col>
        <Col xs={24} lg={12}>
          <Form.Item label={this.props.translate('COMMON.ACTION_SORT_LABEL')}>
            <Select onChange={this.props.onSortChange} value={this.props.sortValue || sortItems[0].id}>
              {sortItems.map((item) => {
                return <Select.Option key={item.id} value={item.id}>{this.props.translate(item.translationKey)}</Select.Option>;
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </Form>;
  };

  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    this.debouncedSearch(value);
  };
}

const ExternalContentListFilterForm = Form.create<IExternalContentListFilterProps>()(ExternalContentListFilter);

// -- HOCs and exports
// ----------

export default withLocalize<IExternalContentListFilterOwnProps>(ExternalContentListFilterForm as any);
