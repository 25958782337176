import IIdRef from '@src/model/common/IdRef';
import { IIdDataPayload, IIdPayload } from '@src/service/business/common/types';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

import { ISkill } from '@src/model/skillgroup/Skill';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError, createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { startGlobalProgress, stopGlobalProgress, trackAction } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts
export interface ISkillListFilter {
  title?: string;
}

export interface ISkillCreatePayload {
  title: string;
  active: boolean;
  skillGroup: IIdRef<string>;
}

// List filter ID
const SKILL_LIST_FILTER = '@@SKILL_LIST_FILTER';

// -
// -------------------- Selectors

/** Returns skill list from store. */
const getSkillList = (store: any): ICollectionData<ISkill> => store.skillList;

/** Returns skill  from store. */
const getSkill = (store: any): ISkill => store.skill;

/** Returns skill list filter. */
const getSkillListFilter = (store: any): ISkillListFilter => ListFilterBusinessStore.selectors.getListFilter(store, SKILL_LIST_FILTER);

// -
// -------------------- Actions
const Actions = {
  SKILL_FETCH: 'SKILL_FETCH',
  SKILL_LOAD: 'SKILL_LOAD',
  SKILL_CLEAR: 'SKILL_CLEAR',
  SKILL_LIST_FETCH: 'SKILL_LIST_FETCH',
  SKILL_LIST_PICKER_FETCH: 'SKILL_LIST_PICKER_FETCH',
  SKILL_LIST_LOAD: 'SKILL_LIST_LOAD',
  SKILL_LIST_CLEAR: 'SKILL_LIST_CLEAR',
  SKILL_CREATE: 'SKILL_CREATE',
  SKILL_UPDATE: 'SKILL_UPDATE',
};

/** Fetch Skill list by filter */
const fetchSkillList = (params: ICollectionFetchPayload<ISkillListFilter>): IPayloadAction<ICollectionFetchPayload<ISkillListFilter>> => {
  return {
    type: Actions.SKILL_LIST_FETCH,
    payload: params,
  };
};

/** Fetch Skill list picker by filter */
const fetchSkillPickerList = (params: ICollectionFetchPayload<ISkillListFilter>): IPayloadAction<ICollectionFetchPayload<ISkillListFilter>> => {
  return {
    type: Actions.SKILL_LIST_PICKER_FETCH,
    payload: params,
  };
};

/** Load Skill list to the store */
const loadSkillList = (data: ICollectionData<ISkill>): IPayloadAction<ICollectionData<ISkill>> => {
  return {
    type: Actions.SKILL_LIST_LOAD,
    payload: data,
  };
};

/** Clear Skill list from store. Eg. when leaving view. */
const clearSkillList = (): ILemonAction => {
  return {
    type: Actions.SKILL_LIST_CLEAR,
  };
};

/** Fetch Skill by id */
const fetchSkill = (id: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.SKILL_FETCH,
    payload: { id },
  };
};

/** Load Skill  to the store */
const loadSkill = (data: ISkill): IPayloadAction<ISkill> => {
  return {
    type: Actions.SKILL_LOAD,
    payload: data,
  };
};

/** Clear Skill  from store. Eg. when leaving view. */
const clearSkill = (): ILemonAction => {
  return {
    type: Actions.SKILL_CLEAR,
  };
};

/** Create Skill */
const createSkill = (data: ISkillCreatePayload): IPayloadAction<ISkillCreatePayload> => {
  return {
    type: Actions.SKILL_CREATE,
    payload: data,
  };
};

/** Update Skill by id */
const updateSkill = (data: ISkill): IPayloadAction<IIdDataPayload<ISkill>> => {
  return {
    type: Actions.SKILL_UPDATE,
    payload: {
      id: data.id,
      data,
    },
  };
};

const storeSkillListFilter = (listFilter: ISkillListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(SKILL_LIST_FILTER, listFilter);
};

const clearSkillListFilter = (): ILemonAction => {
  return ListFilterBusinessStore.actions.clearListFilter(SKILL_LIST_FILTER);
};

// -
// -------------------- Side-effects
const fetchSkillListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<ISkillListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.SKILL_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Skill')
        .fetchEntityList(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadSkillList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error fetching Skill list', error);
      return o;
    })
  );
};

const fetchSkillPickerListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<ISkillListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.SKILL_LIST_PICKER_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Skill')
        .fetchEntityList(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error fetching Skill list', error);
      return o;
    })
  );
};

const fetchSkillEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.SKILL_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Skill')
        .fetchEntity(payload.id)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadSkill(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error fetching Skill list', error);
      return o;
    })
  );
};

const createSkillEffect = (action$: Observable<IPayloadAction<ISkillCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.SKILL_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Skill')
        .createEntity(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'SKILL_LIST.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error creating Skill', error);
      return o;
    })
  );
};

const updateSkillEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ISkill>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.SKILL_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('Skill')
        .updateEntity(id, data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'SKILL_LIST.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error updating Skill', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers
const skillList = (state: ICollectionData<ISkill> | null = null, action: IPayloadAction<ICollectionData<ISkill>>) => {
  if (action.type === Actions.SKILL_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.SKILL_LIST_CLEAR) {
    return null;
  }

  return state;
};

const skill = (state: ISkill | null = null, action: IPayloadAction<ISkill>) => {
  if (action.type === Actions.SKILL_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.SKILL_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store
const SkillBusinessStore = {
  actions: {
    fetchSkill,
    loadSkill,
    clearSkill,
    fetchSkillList,
    fetchSkillPickerList,
    loadSkillList,
    clearSkillList,
    createSkill,
    updateSkill,
    storeSkillListFilter,
    clearSkillListFilter,
  },

  selectors: {
    getSkill,
    getSkillList,
    getSkillListFilter,
  },

  effects: {
    fetchSkillListEffect,
    fetchSkillPickerListEffect,
    createSkillEffect,
    updateSkillEffect,
    fetchSkillEffect,
  },

  reducers: {
    skillList,
    skill,
  },
};

// --
// export business store
export default SkillBusinessStore;
