import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

import AppContent from '@src/components/common/container/AppContent';
import EmailTemplateListView from '@src/components/emailtemplate/view/EmailTemplateListView';
import { IEmailTemplate } from '@src/model/emailtemplate/EmailTemplate';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import { EmailTemplateBusinessStore, IEmailTemplateListFilter } from '@src/service/business/emailtemplates/EmailTemplateBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

const DEFAULT_SORT_VALUE = 'title,asc';

// -- Prop types
// ----------
export interface IAdminEmailTemplateListContainerOwnProps {

}
export interface IAdminEmailTemplateListContainerStateProps {
  emailTemplateListFilter: IEmailTemplateListFilter;
  emailTemplateList: ICollectionData<IEmailTemplate>;
}
export interface IAdminEmailTemplateListContainerDispatchProps {
  fetchEmailTemplateList: (filter: ICollectionFetchPayload<IEmailTemplateListFilter>) => void;
  deleteEmailTemplate: (emailTemplateId: string) => ITrackableAction;
  storeEmailTemplateListFIlter: (filter: IEmailTemplateListFilter) => void;
  clearEmailTemplateListFilter: () => void;
}
type IAdminEmailTemplateListContainerProps = IAdminEmailTemplateListContainerOwnProps & IAdminEmailTemplateListContainerStateProps & IAdminEmailTemplateListContainerDispatchProps & WithRouterProps;

// --
// ----- State types
interface IAdminEmailTemplateListContainerState {
  page: number;
  size: number;
  sort: string;
}

// -- Component
// ----------
class AdminEmailTemplateListContainer extends React.Component<IAdminEmailTemplateListContainerProps, IAdminEmailTemplateListContainerState> {
  state: IAdminEmailTemplateListContainerState = {
    page: 0,
    size: AppConfigService.getValue('api.paging.defaultPageSize'),
    sort: DEFAULT_SORT_VALUE,
  };

  componentDidMount() {
    this.fetchEmailTemplateList();
  }

  componentDidUpdate = (prevProps: IAdminEmailTemplateListContainerProps, prevState: IAdminEmailTemplateListContainerState) => {
    if (this.state.page !== prevState.page || this.state.size !== prevState.size || this.state.sort !== prevState.sort || this.props.emailTemplateListFilter !== prevProps.emailTemplateListFilter) {
      this.fetchEmailTemplateList();
    }
  };

  componentWillUnmount() {
    this.props.clearEmailTemplateListFilter();
  }

  render() {
    return (
      <AppContent>
        <EmailTemplateListView
          emailTemplateList={this.props.emailTemplateList}
          showControls={true}
          onDelete={this.handleDelete}
          onFilterChange={this.handleFilterChange}
          emailTemplateListViewFilter={this.props.emailTemplateListFilter}
          handlePageChange={this.handlePageChange}
          onChange={this.fetchEmailTemplateList}
        />
      </AppContent>
    );
  }

  handlePageChange = (page: number, pageSize?: number) => {
    this.setState({ page: page - 1, size: pageSize ?? this.state.size });
  };

  handleFilterChange = (filter: IEmailTemplateListFilter) => {
    this.props.storeEmailTemplateListFIlter(filter);
    this.setState({ page: 0 });
  };

  handleDelete = (emailTemplate: IEmailTemplate) => {
    this.props.deleteEmailTemplate(emailTemplate.id).track().subscribe(
      // success
      () => this.fetchEmailTemplateList()
    );
  };

  fetchEmailTemplateList = () => {
    this.props.fetchEmailTemplateList(
      {
        page: this.state.page,
        sort: [this.state.sort],
        size: this.state.size,
        filter: this.props.emailTemplateListFilter,
      }
    );
  };

}
// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IAdminEmailTemplateListContainerOwnProps): IAdminEmailTemplateListContainerStateProps => ({
  emailTemplateList: EmailTemplateBusinessStore.selectors.getEmailTemplateList(state),
  emailTemplateListFilter: EmailTemplateBusinessStore.selectors.getEmailTemplateListFilter(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IAdminEmailTemplateListContainerDispatchProps => ({
  fetchEmailTemplateList: (filter: ICollectionFetchPayload<IEmailTemplateListFilter>) => dispatch(EmailTemplateBusinessStore.actions.fetchEmailTemplateList(filter)),
  deleteEmailTemplate: (emailTemplateId: string) => createTrackableAction(dispatch(EmailTemplateBusinessStore.actions.deleteEmailTemplate(emailTemplateId))),
  storeEmailTemplateListFIlter: (filter: IEmailTemplateListFilter) => dispatch(EmailTemplateBusinessStore.actions.storeEmailTemplateListFilter(filter)),
  clearEmailTemplateListFilter: () => dispatch(EmailTemplateBusinessStore.actions.clearEmailTemplateListFilter()),
});

export default connect<IAdminEmailTemplateListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withRouter(AdminEmailTemplateListContainer as any));
