const PLACEHOLDER_PREFIX = '$';

export interface TenantResolverMapping {
  /** String containing valid regex pattern. */
  pattern: string;
  /**
   * Value string. Can be static or can contain placeholder which are replaced with pattern's regex matching groups.
   * Placeholders are in form: $N (eg. $1, $2, ...)
   *
   */
  value: string;
}

/**
 * Iterate through tenant mappings and return the first maaping that matches location.
 */
export default function resolveTenant(mappings: TenantResolverMapping[], location: string): string | undefined {
  const tenantCode = mappings.reduce((accum: string | undefined, mapping) => {
    // if accum has already been - skip by returning accum value
    if (accum != undefined) {
      return accum;
    }

    const matcher = new RegExp(mapping.pattern);
    let value = mapping.value;

    const matches = location.match(matcher);
    // check if it matches at all
    if (matches != null) {
      // we've matched some groups - replace
      if (matches.length > 1) {
        value = matches.reduce((accum, match, idx) => {
          return replaceMatchPlaceholder(accum, idx, match);
        }, value);
      }

      return value;
    }

    return accum;
  }, undefined);

  return tenantCode;
}

function replaceMatchPlaceholder(template: string, matchOrdNum: number, replacement: string): string {
  return template.replace(`${PLACEHOLDER_PREFIX}${matchOrdNum}`, replacement);
}
