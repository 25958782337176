import DeleteElementButton from '@src/components/common/button/DeleteElementButton';
import FilePreviewModal from '@src/components/common/file/FilePreviewModal';
import FileUtils, { ITimunFile } from '@src/components/common/file/FileUtils';
import LemonIcon from '@src/components/common/image/LemonIcon';
import { IListAdditionalCol } from '@src/components/common/list/ListAdditionalCol';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IFile } from '@src/model/file/File';
import UrlBuilderFactory from '@src/service/util/UrlBuilderFactory';
import { Col, Empty, Row, Tooltip } from 'antd';
import React from 'react';

// -- Prop types
// ----------

interface IFileListOwnProps {
  fileList: IFile[];
  fileColWidth: number;
  showActions?: boolean;
  renderAdditionalCols?: () => IListAdditionalCol[];
  onRemoveFile?: (data: IFile) => void;
}
type IFileListProps = IFileListOwnProps & IWithLocalizeOwnProps;

interface IFileListState {
  fileToDisplay?: ITimunFile;
}

// -- Component
// ----------

class FileList extends React.Component<IFileListProps, IFileListState> {
  state: IFileListState = {
    fileToDisplay: undefined,
  };
  render = () => {
    const additionalCols = this.props.renderAdditionalCols ? this.props.renderAdditionalCols() : [];
    return (
      <React.Fragment>
        <Row className="timun-fileList__header">
          <Col xs={10} span={this.props.fileColWidth}>{this.props.translate('USER_VIEW.DOCUMENTS_TABLE_DOCUMENT_LABEL')}</Col>
          {additionalCols.map((col) => {
            return <Col className={col.className} key={col.key} {...col.responsiveWidth}>{col.headerTitle}</Col>;
          })}
        </Row>
        {/* CONTENT */}
        {this.props.fileList.length ? (
          <div className="timun-fileList__itemContainer">
            {this.props.fileList.map((file) => {
              return (
                <Row key={file.id} className="timun-fileList__item">
                  <Col xs={10} span={this.props.fileColWidth}><LemonIcon name="paperClip"/>&nbsp;{file.name}</Col>
                  {additionalCols.map((col) => {
                    return <Col className={col.className} key={col.key} {...col.responsiveWidth}>
                      {col.content(file)}
                    </Col>;
                  })}
                  {/* ACTIONS */}
                  {this.props.showActions && <Col span={2}>
                    <Row className="text-center" justify="space-around">
                      <Col span={2}>
                        {FileUtils.isFilePreviewable(file.mimeType) && <a onClick={() => this.toggleImagePreviewVisible(file)}>
                          <Tooltip title={this.props.translate('COMMON.ACTION_PREVIEW')}><LemonIcon name="eye" />
                          </Tooltip></a>}
                      </Col>
                      <Col span={2}>
                        <a href={UrlBuilderFactory.buildApiFileUrl(file.id)} target="_self">
                          <Tooltip title={this.props.translate('COMMON.ACTION_DOWNLOAD')}><LemonIcon name="download" /></Tooltip>
                        </a>
                      </Col>
                      <Col span={2}>
                        {this.props.onRemoveFile && <a><Tooltip title={this.props.translate('COMMON.ACTION_DELETE')}>
                          <DeleteElementButton<IFile> item={file} titlePropPath={'name'} iconOnly={true} iconName="times" onDelete={this.props.onRemoveFile} />
                        </Tooltip></a>}
                      </Col>

                    </Row>
                  </Col>}
                </Row>
              );
            })}
          </div>
        ) : <Empty description={this.props.translate('COMMON.MESSAGE_NO_DATA')} />
        }
        {this.state.fileToDisplay && <FilePreviewModal closeModal={this.handleClosePreview} record={this.state.fileToDisplay} />}
      </React.Fragment>
    );
  };

  toggleImagePreviewVisible = (_file: IFile) => {
    this.setState({ fileToDisplay: FileUtils.mapToTimunFile(_file) });
  };

  handleClosePreview = () => {
    this.setState({
      fileToDisplay: undefined,
    });
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<IFileListOwnProps>(FileList as any);
