import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';

import LemonIcon from '@src/components/common/image/LemonIcon';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import { Button, Col, Form, Row, Typography } from 'antd';
import { FormInstance, RuleObject } from 'antd/lib/form';

// -- Const
// ----------

const FormList = Form.List;
const initDefaultValues = (initialCount?: number) => {
  const initialArray = [];
  if (initialCount) {
    for (let index = 0; index < initialCount; index++) {
      initialArray.push(undefined);
    }
  }
  return initialArray;
};

// -- Prop types
// ----------

export interface IFormDataItemListOwnProps {
  name: string; // TODO: should set property to NamePath, currently there is issue with setFieldsValue on mount
  // all action buttons are disabled (move, add, remove)
  disabled?: boolean;
  // inital count of empty fields
  initialCount?: number;
  // min and max number of allowed fileds
  min?: number;
  max?: number;
  formRef: FormInstance<any>;
}

// -- Component
// ----------

/** Form control component for collecting values from multiple fields (dataPicker, selector, input, etc. */
const FormDataItemList: React.FC<IFormDataItemListOwnProps> = (props: PropsWithChildren<IFormDataItemListOwnProps>) => {
  const [disableAdd, setDisableAdd] = useState<boolean>(props.disabled ?? false);
  const isMinConditionValid = (valueLength: number) => props.min && valueLength < props.min;
  const isMaxConditionValid = (valueLength: number) => props.max && valueLength >= props.max;

  useEffect(() => {
    if (props.formRef.getFieldValue(props.name)) {
      return;
    } else {
      props.formRef.setFieldsValue({
        [props.name]: initDefaultValues(props.initialCount),
      });
    }
  }, [props.formRef.getFieldValue(props.name)]);

  /**Do not allow form submit when:
   * form controller is disabled
   * fields length exceed max number of allowed fields
   * fields length missing min number of traget fields length
   * TODO: remove validator out of FC component scope
   * TODO: remove setting state out of validator
   */
  const customValidator = async (rules: RuleObject, values: any, callback?: (error?: string | undefined) => void) => {
    if (props.disabled) {
      setDisableAdd(true);
      return Promise.reject();
    } else if (isMinConditionValid(values.length)) {
      return Promise.reject(new Error(LocalizeService.translate('COMMON.VALIDATION.MIN_FIELDS_REQUIRED', { minFieldNumber: props.min })));
    } else if (isMaxConditionValid(values.length)) {
      setDisableAdd(true);
      return Promise.resolve();
    } else {
      setDisableAdd(false);
      return Promise.resolve();
    }
  };

  /** Move field one place closer to the beginning of array */
  const handleMoveUp = useCallback((move: (from: number, to: number) => void, startPosition: number): void => {
    move(startPosition, startPosition - 1);
  }, []);

  /** Move field one place further from the beginning of array */
  const handleMoveDown = useCallback((move: (from: number, to: number) => void, startPosition: number): void => {
    move(startPosition, startPosition + 1);
  }, []);

  /** Minimum number of field is prohibited from being deleted */
  const disabledButton = useCallback((fieldIndex: number, min?: number): boolean => {
    if (props.disabled) {
      return true;
    } else if (min) {
      return fieldIndex + 1 <= min;
    } else {
      return false;
    }
  }, []);

  return (
    <FormList name={props.name}
      rules={[{ validator: customValidator }]}>
      {(fields, { add, remove, move }, { errors }) => {
        return (
          <React.Fragment>
            {fields.map((field) => {
              return (
                <Row key={field.key} gutter={[4, 8]}>
                  <Col flex="auto" >
                    <Form.Item
                      noStyle={true}
                      name={field.name}
                      fieldKey={field.key}
                      rules={[{ required: true, message: LocalizeService.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
                      {props.children}
                    </Form.Item>
                  </Col>
                  <Col>
                    <Button disabled={props.disabled} icon={<LemonIcon name="up" />} onClick={() => handleMoveUp(move, field.name)} />
                  </Col>
                  <Col>
                    <Button disabled={props.disabled} icon={<LemonIcon name="down" />} onClick={() => handleMoveDown(move, field.name)} />
                  </Col>
                  <Col>
                    <Button danger={true} disabled={disabledButton(field.name, props.min)} onClick={() => remove(field.name)} icon={<LemonIcon name="delete" />} />
                  </Col>
                </Row>
              );
            })}
            <Row gutter={8} align="bottom">
              <Col>
                <Button disabled={disableAdd} onClick={() => add()}> {LocalizeService.translate('COMMON.ACTION_ADD')} </Button>
              </Col>
              <Col flex="auto">
                {isMaxConditionValid(fields.length) && <Typography.Text type="warning"> {LocalizeService.translate('COMMON.VALIDATION.MAX_FIELDS_ADDED')} </Typography.Text>}
              </Col>
            </Row>
            {/* Display error messages */}
            <Form.ErrorList errors={errors} />
          </React.Fragment>
        );
      }}
    </FormList >
  );
};

export default FormDataItemList;
