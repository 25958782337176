import React from 'react';
import { connect } from 'react-redux';

import { IQuizOutcomeStats } from '@lamarodigital/quizzler-lib-frontend/model/quiz/outcome/QuizOutcomeStats';
import { IQuiz } from '@lamarodigital/quizzler-lib-frontend/model/quiz/Quiz';
import withCollectionState, { IWithCollectionStateOwnProps, IWithCollectionStatePublicProps } from '@src/components/common/collectionParams/withCollectionState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ExamInstanceModal from '@src/components/exam/examtemplate/ExamInstanceModal';
import ExamTemplateView from '@src/components/exam/view/ExamTemplateView';
import { IExamInstance } from '@src/model/education/ExamInstance';
import { IExamTemplate } from '@src/model/education/ExamTemplate';
import { IExamTemplateStatistics } from '@src/model/education/ExamTemplateStatistics';
import { ICollectionData, ICollectionFetchPayload, IUserFeedbackMessagePayload } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import examInstanceBusinessStore, { IExamInstanceCreatePayload, IExamInstanceListFilter } from '@src/service/business/examtemplates/examInstanceBusinessStore';
import examTemplateBusinessStore from '@src/service/business/examtemplates/examTemplateBusinessStore';
import QuizCollectionBusinessStore from '@src/service/business/quiz/quizCollectionBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { withRouter, WithRouterProps } from 'react-router';

const VIEW_NAME = '@@EXAM_TEMPLATE_LIST';
const collectionDefaults: IWithCollectionStatePublicProps<IExamInstanceListFilter> = {
  viewName: VIEW_NAME,
  initialValues: {},
};

// -- Prop types
// ----------

export interface IExamTemplateContainerOwnProps {
  examTemplateId: string;
}

export interface IExamTemplateContainerStateProps {
  examTemplate: IExamTemplate;
  examTemplateStatistics: IExamTemplateStatistics;
  examInstanceList: ICollectionData<IExamInstance>;
  quizCollectionOutcomeStats: IQuizOutcomeStats;
}

export interface IExamTemplateContainerDispatchProps {
  fetchExamTemplate: (examTemplateId: string) => ITrackableAction;
  fetchExamTemplateStatistics: (examTemplateId: string) => ITrackableAction;
  createExamInstance: (id: string, data: IExamInstanceCreatePayload) => ITrackableAction;
  fetchExamInstanceList: (payload: ICollectionFetchPayload<IExamInstanceListFilter>) => void;
  updateExamTemplate: (examTemplate: IExamTemplate) => ITrackableAction;
  fetchQuizCollectionOutcomeStats: (quizCollectionId: string) => void;
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
}

type IExamTemplateContainerProps = IExamTemplateContainerOwnProps & IExamTemplateContainerStateProps & IExamTemplateContainerDispatchProps & IWithLocalizeOwnProps & IWithCollectionStateOwnProps<IExamInstanceListFilter> & WithRouterProps;

interface IExamTemplateContainerState {
  isExamInstanceModalVisible: boolean;
}

// -- Component
// ----------

/** Container component for displaying examTemplate instances and creating instances */
class ExamTemplateContainer extends React.Component<IExamTemplateContainerProps, IExamTemplateContainerState> {
  state: IExamTemplateContainerState = {
    isExamInstanceModalVisible: false,
  };

  componentDidMount() {
    this.fetchExamTemplateData(this.props.examTemplateId);

  }

  componentDidUpdate(prevProps: IExamTemplateContainerProps, prevState: IExamTemplateContainerState) {
    if (this.props.examTemplateId !== prevProps.examTemplateId) {
      this.fetchExamTemplateData(this.props.examTemplateId);
    }
    if (this.props.collectionParams !== prevProps.collectionParams) {
      this.updateExamInstanceList();
    }
  }

  render = () => {
    const headerTitleRoute = this.props.location?.state?.examCourseId ? `/course/${this.props.location.state.examCourseId}/about` : '/education/groups';

    return (
      <React.Fragment>
        {/* Display examTemplate and examInstanceList */}
        <ExamTemplateView
          handleQuizCollectionSubmit={this.handleQuizCollectionSubmit}
          handleExamTemplateSubmit={this.handleUpdatedExamTemplateSubmit}
          examTemplate={this.props.examTemplate}
          examTemplateStatistics={this.props.examTemplateStatistics}
          examTemplateInstanceList={this.props.examInstanceList}
          headerTitleRoute={headerTitleRoute}
          onPageChange={this.props.updateCollectionParams.onPageChange}
        />

        {/* Modal for generating exam instances */}
        {this.state.isExamInstanceModalVisible && this.props.quizCollectionOutcomeStats &&
          <ExamInstanceModal
            quizCollectionOutcomeStats={this.props.quizCollectionOutcomeStats}
            onSubmit={() => console.log('TODO: exam instances will be generated automatically')}
            onCancel={this.toggleExamInstanceModal}
            title={this.props.examTemplate?.title} />}
      </React.Fragment>
    );
  };

  toggleExamInstanceModal = (isVisible: boolean) => {
    this.setState({
      isExamInstanceModalVisible: isVisible,
    });
  };

  handleUpdatedExamTemplateSubmit = (examTemplateData: IExamTemplate) => {
    const updateExamTemplate = { ...this.props.examTemplate, ...examTemplateData };
    this.props.updateExamTemplate(updateExamTemplate).track().subscribe(
      () => {
        this.props.fetchExamTemplate(this.props.examTemplateId);
      }
    );
  };

  updateExamInstanceList = () => {
    this.props.onUpdateList(this.props.fetchExamInstanceList, { template: this.props.examTemplateId });
  };

  handleQuizCollectionSubmit = (quizData: IQuiz) => {
    if (this.props.examTemplate) {
      const newExamTemplateCollection: IExamTemplate = { ...this.props.examTemplate };
      newExamTemplateCollection.collection = quizData;
      this.handleUpdatedExamTemplateSubmit(newExamTemplateCollection);
    }
  };

  fetchExamTemplateData = (examTemplateId: string) => {
    this.fetchExamTemplate(examTemplateId);
    this.updateExamInstanceList();
    this.props.fetchExamTemplateStatistics(examTemplateId);
  };

  fetchExamTemplate = (examTemplateId: string) => {
    this.props.fetchExamTemplate(examTemplateId).track().subscribe(
      () => {
        if (this.props.examTemplate?.collection?.id) {
          this.props.fetchQuizCollectionOutcomeStats(this.props.examTemplate.collection.id);
        }
      }
    );
  };

}
// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IExamTemplateContainerOwnProps): IExamTemplateContainerStateProps => ({
  examTemplate: examTemplateBusinessStore.selectors.getExamTemplate(state),
  examTemplateStatistics: examTemplateBusinessStore.selectors.getExamTemplateStatistics(state),
  examInstanceList: examInstanceBusinessStore.selectors.getExamInstanceList(state),
  quizCollectionOutcomeStats: QuizCollectionBusinessStore.selectors.getQuizCollectionOutcomeStats(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IExamTemplateContainerDispatchProps => ({
  fetchExamTemplate: (examTemplateId: string) => createTrackableAction(dispatch(examTemplateBusinessStore.actions.fetchExamTemplate(examTemplateId))),
  updateExamTemplate: (examTemplate: IExamTemplate) => createTrackableAction(dispatch(examTemplateBusinessStore.actions.updateExamTemplate(examTemplate))),

  fetchExamTemplateStatistics: (examTemplateId: string) => createTrackableAction(dispatch(examTemplateBusinessStore.actions.fetchExamTemplateStatistics(examTemplateId))),
  createExamInstance: (id: string, data: IExamInstanceCreatePayload) => createTrackableAction(dispatch(examInstanceBusinessStore.actions.createExamInstance(id, data))),
  fetchExamInstanceList: (payload: ICollectionFetchPayload<IExamInstanceListFilter>) => dispatch(examInstanceBusinessStore.actions.fetchExamInstanceList(payload)),

  fetchQuizCollectionOutcomeStats: (quizCollectionId: string) => dispatch(QuizCollectionBusinessStore.actions.fetchQuizCollectionOutcomeStats(quizCollectionId)),
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
});

// -- HOCs and exports
// ----------
export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withCollectionState(collectionDefaults)(withRouter<IExamTemplateContainerOwnProps>(ExamTemplateContainer as any))));
