import withRoles from '@src/components/common/role/withRoles';
import React from 'react';
import { withRouter } from 'react-router';
import ReadOnlyNotificationListContainer from "@src/components/notification/list/ReadOnlyNotificationListContainer";

// -- Component
// ----------
const AdminNotificationPage = () => {
  return (
    <React.Fragment>
      <ReadOnlyNotificationListContainer />
    </React.Fragment>
  );
};

export default withRouter((withRoles(AdminNotificationPage)));