import AppContent from '@src/components/common/container/AppContent';
import { IListAdditionalCol } from '@src/components/common/list/ListAdditionalCol';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import CourseList from '@src/components/course/list/CourseList';
import CoursesReportListFilter from '@src/components/course/report/CoursesReportListFilter';
import { ICourse } from '@src/model/course/Course';
import { ICourseGroup } from '@src/model/course/CourseGroup';
import CollectionBusinessStore from '@src/service/business/common/collectionBusinessStore';
import { ICollectionData } from '@src/service/business/common/types';
import CourseListBusinessStore, { ICourseListFilter } from '@src/service/business/courses/courseListBusinessStore';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------

export interface ICoursesReportContainerOwnProps {
}

export interface ICoursesReportContainerStateProps {
  courseGroups: ICourseGroup[];
  courseList: ICollectionData<ICourse>;
  courseListFilter: ICourseListFilter;
}

export interface ICoursesReportContainerDispatchProps {
  fetchCourseList: (params: ICourseListFilter) => void;
  storeCourseListFilter: (listFilter: ICourseListFilter) => void;
}

type ICoursesReportContainerProps = ICoursesReportContainerOwnProps & ICoursesReportContainerStateProps & ICoursesReportContainerDispatchProps & IWithLocalizeOwnProps & WithRouterProps;

// -- Component
// ----------

/** Container component for list of all courses. */
class CoursesReportContainer extends React.Component<ICoursesReportContainerProps> {
  componentDidMount() {
    // initial list update
    this.updateList();
  }

  componentDidUpdate(prevProps: ICoursesReportContainerProps) {
    if (this.props !== prevProps && this.props.courseListFilter !== prevProps.courseListFilter) {
      this.updateList();
    }
  }

  render = () => {
    return (
      <AppContent>
        <CoursesReportListFilter value={this.props.courseListFilter} onChange={this.handleListFilterChange}/>
        {/* ----- Course List ----- */}
        <CourseList courseGroups={this.props.courseGroups} courses={this.props.courseList ? this.props.courseList.content : []} courseColWidth={12} renderAdditionalCols={this.additionalCols} getPath={this.getPath}/>
      </AppContent>
    );
  };

  private additionalCols = (): IListAdditionalCol[] => {
    return [
      {
        key: 'toDo',
        responsiveWidth: { lg: 4 },
        headerTitle: this.props.translate('COURSES_REPORT.TODO_LABEL'),
        content: (record: ICourse) => record.additionalData && record.additionalData.traineesStats && record.additionalData.traineesStats.toDoCount,
      },
      {
        key: 'inProgress',
        responsiveWidth: { lg: 4 },
        headerTitle: this.props.translate('COURSES_REPORT.INPROGRESS_LABEL'),
        content: (record: ICourse) => record.additionalData && record.additionalData.traineesStats && record.additionalData.traineesStats.inProgressCount,
      },
      {
        key: 'completedCount',
        responsiveWidth: { lg: 4 },
        headerTitle: this.props.translate('COURSES_REPORT.COMLETED_LABEL'),
        content: (record: ICourse) => record.additionalData && record.additionalData.traineesStats && record.additionalData.traineesStats.completedCount,
      },
    ];
  };

  // private aditionalCols = (course: ICourse): ICourseListAdditionalCol[] => {
  //   const traineesStats: ITraineesStats = (course.additionalData && course.additionalData.traineesStats) || ({} as ITraineesStats);
  //   return [
  //     {
  //       key: `${course.id}-col1`,
  //       colWidth: 4,
  //       header: this.props.translate('COURSES_REPORT.TODO_LABEL'),
  //       content: traineesStats.toDoCount || '-',
  //     },
  //     {
  //       key: `${course.id}-col2`,
  //       colWidth: 4,
  //       header: this.props.translate('COURSES_REPORT.INPROGRESS_LABEL'),
  //       content: traineesStats.inProgressCount || '-',
  //     },
  //     {
  //       key: `${course.id}-col3`,
  //       colWidth: 4,
  //       header: this.props.translate('COURSES_REPORT.COMLETED_LABEL'),
  //       content: traineesStats.completedCount || '-',
  //     },
  //   ];
  // };

  private getPath = (id: string, lecture: string) => {
    return `/reports/courses/${id}`;
  };

  private handleListFilterChange = (value: ICourseListFilter) => {
    this.props.storeCourseListFilter(value);
  };

  private updateList() {
    this.props.fetchCourseList({
      ...this.props.courseListFilter,
      // force trainee stats becuase this view is all about stats
      withTraineesStats: true,
    });
  }
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ICoursesReportContainerOwnProps): ICoursesReportContainerStateProps => ({
  courseGroups: CollectionBusinessStore.selectors.getCollectionContent(state, 'CourseGroup'),
  courseList: CourseListBusinessStore.selectors.getCourseList(state),
  courseListFilter: CourseListBusinessStore.selectors.getCourseListFilter(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ICoursesReportContainerDispatchProps => ({
  fetchCourseList: (filter: ICourseListFilter) => dispatch(CourseListBusinessStore.actions.fetchAdminCourseList(filter)),
  storeCourseListFilter: (filter: ICourseListFilter) => dispatch(CourseListBusinessStore.actions.storeCourseListFilter(filter)),
});

export default connect<ICoursesReportContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(withRouter(CoursesReportContainer as any)));
