import { ICollectionFetchPayload, INamedPayload, IPayloadAction } from '@src/service/business/common/types';

// -
// -------------------- Types

export interface ICollectionParamsPayload<T> extends INamedPayload {
  collectionParams: ICollectionFetchPayload<T>;
}

// -
// -------------------- Selectors

/* get collection params for specific view by it's name */
const getCollectionParams = <P>(store: any, name?: string): ICollectionFetchPayload<P> => name ? store.collectionParams[name] : undefined;

// -
// -------------------- Actions

const Actions = {
  COLLECTION_PARAMS_STORE: 'COLLECTION_PARAMS_STORE',
  COLLECTION_PARAMS_CLEAR: 'COLLECTION_PARAMS_CLEAR',
};

/* store collection params for specific view by it's name */
const storeCollectionParams = <P>(name: string, params: ICollectionFetchPayload<P>): IPayloadAction<ICollectionParamsPayload<P>> => {
  return {
    type: Actions.COLLECTION_PARAMS_STORE,
    payload: {
      name,
      collectionParams: params,
    },
  };
};
/* clear collection params from store for specific view by it's name */
const clearCollectionParams = (name: string): IPayloadAction<INamedPayload> => {
  return {
    type: Actions.COLLECTION_PARAMS_STORE,
    payload: {
      name,
    },
  };
};

// -
// -------------------- Reducers

const collectionParams = <P>(state: { [name: string]: ICollectionFetchPayload<P>; } = {}, action: IPayloadAction<ICollectionParamsPayload<P>>) => {
  // ----- COLLECTION_PARAMS_STORE
  if (action.type === Actions.COLLECTION_PARAMS_STORE) {
    return {
      ...state,
      [action.payload.name]: action.payload.collectionParams,
    };
  }
  // ----- COLLECTION_PARAMS_CLEAR
  else if (action.type === Actions.COLLECTION_PARAMS_CLEAR) {
    // copy all keys except the one that sgould be cleared
    return Object.keys(state).reduce((accum: Record<string, any>, key) => {
      if (key !== action.payload.name) {
        accum[action.payload.name] = state[key];
      }
      return accum;
    }, {});
  }

  return state;
};

// --
// ----- Store object

const CollectionParamsBusinessStore = {
  actions: {
    storeCollectionParams,
    clearCollectionParams,
  },

  selectors: {
    getCollectionParams,
  },

  reducers: {
    collectionParams,
  },
};

// --
// ----- Exports

export default CollectionParamsBusinessStore;
