import AppUserAvatar from '@src/components/common/image/AppUserAvatar';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import UserActivityUiHelper from '@src/components/common/useractivities/UserActivityUiHelper';
import { IUserActivity, UserActivityItemTypeEnum } from '@src/model/activity/UserActivity';
import AppConfigService from '@src/service/common/AppConfigService';
import { Col, Empty, Row, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

const dateFormat = AppConfigService.getValue('dateTimeFormat.dateWithYear');

export interface IUserActivityListOwnProps {
  userActivities: IUserActivity[];
}

type IUserActivityListProps = IUserActivityListOwnProps & IWithLocalizeOwnProps & WithRouterProps;

interface IUserActivityListState {
}

class UserActivityList extends React.Component<IUserActivityListProps, IUserActivityListState> {
  render = () => {
    return this.props.userActivities.length ?
      this.props.userActivities.map((activity) => {
        return <Row className="panel timun-userActivityList__item" key={activity.id} justify="start">
          <Col className="timun-userActivityList__userAvatar"><AppUserAvatar size="small" imagePath={activity.createdBy.profileImageUrl} /></Col>
          <Col offset={1} className="timun-userActivityList__itemContent">
            <Row>{UserActivityUiHelper.getActivityTitle(activity)}&nbsp;
              {moment(activity.createdAt).format(dateFormat)}</Row>
            {activity.type.id === UserActivityItemTypeEnum.LEFT_COMMENT && <Row><Typography.Paragraph ellipsis={{ rows: 3, expandable: true }}>{`"${UserActivityUiHelper.getActivityContent(activity)}"`}</Typography.Paragraph></Row>}
          </Col>
        </Row>;
      })
      : <Empty description={this.props.translate('USER_ACTIVITY.LIST_EMPTY')} />;

  };
}

export default withLocalize(withRouter<IUserActivityListOwnProps>(UserActivityList as any));
