import AppConfigService from '@src/service/common/AppConfigService';
import moment from 'moment';
import React from 'react';

const DATE_FORMAT = AppConfigService.getValue('dateTimeFormat.localeDate');
const TIME_FORMAT = AppConfigService.getValue('dateTimeFormat.localeTime');
const DATETIME_FORMAT = AppConfigService.getValue('dateTimeFormat.localeDateTime');

// -- Types
// ----------
export type DateTimeViewMode = 'date' | 'time' | 'datetime';

// -- Prop types
// ----------
export interface IDatetimeViewOwnProps {
  value: string;
  mode?: DateTimeViewMode;
  fallback: React.ReactNode | string;
  title?: string;
}

// -- Component
// ----------

/**
 * Standard component for displaying localized date/time.
 *
 * Use this if you have no intent to format anything, just display some date/time. Otherwise, use manual formatting.
 */
const DatetimeView: React.FC<IDatetimeViewOwnProps> = (props) => {
  if (props.value != null) {
    return <span title={renderTitle(props)}>{moment(props.value).format(getFormat(props.mode))}</span>;
  } else {
    return <React.Fragment>{props.fallback}</React.Fragment>;
  }
};

function renderTitle(props: IDatetimeViewOwnProps): string {
  if (props.value != null) {
    if (props.title != null) {
      return props.title;
    } else {
      return moment(props.value).format(getFormat('datetime'));
    }
  }
  return '';
}

function getFormat(mode: DateTimeViewMode | undefined): string {
  if (mode === 'date') {
    return DATE_FORMAT;
  } else if (mode === 'time') {
    return TIME_FORMAT;
  } else {
    return DATETIME_FORMAT;
  }
}

export default DatetimeView;