import React from 'react';
import { connect } from 'react-redux';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import SkillModal from '@src/components/skillgroup/modal/SkillModal';
import { ISkill } from '@src/model/skillgroup/Skill';
import SkillBusinessStore from '@src/service/business/skillgroup/SkillBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

// -- Prop types
// ----------

export interface ISkillModalUpdateContainerOwnProps {
  skill: ISkill;
  onUpdateSkill?: () => void;
  onClose?: () => void;
}

export interface ISkillModalUpdateContainerStateProps {
}

export interface ISkillModalUpdateContainerDispatchProps {
  updateSkill: (data: ISkill) => ITrackableAction;
}
type ISkillModalUpdateContainerProps = ISkillModalUpdateContainerOwnProps & ISkillModalUpdateContainerStateProps
  & ISkillModalUpdateContainerDispatchProps & IWithLocalizeOwnProps;

interface ISkillModalUpdateContainerState {
}


// -- Component
// ----------

/** Describe your component ... */
class SkillModalUpdateContainer extends React.Component<ISkillModalUpdateContainerProps, ISkillModalUpdateContainerState> {
  state: ISkillModalUpdateContainerState = {
  };

  render = () => {
    return (
      <SkillModal
        skill={this.props.skill}
        title={this.props.translate('SKILL_MODAL.SKILL_UPDATE_TITLE')}
        onClose={this.props.onClose}
        onUpdateSkill={this.handleUpdateSkill} />
    );
  }

  handleUpdateSkill = (skill: ISkill) => {
    this.props.updateSkill(skill).track().subscribe(
      // success
      () => {
        this.props.onUpdateSkill?.();
      }
    );
  }
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ISkillModalUpdateContainerOwnProps): ISkillModalUpdateContainerStateProps => ({
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ISkillModalUpdateContainerDispatchProps => ({
  updateSkill: (data: ISkill) => createTrackableAction(dispatch(SkillBusinessStore.actions.updateSkill(data))),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<ISkillModalUpdateContainerOwnProps>(SkillModalUpdateContainer as any));
