import React from 'react';

import DeleteButton from '@src/components/common/button/DeleteButton';
import { ILemonApplicationIconSet } from '@src/service/common/icon/LemonApplicationIconSet';
import { ButtonProps } from 'antd/lib/button';
import { get } from 'lodash';

// -- Prop types
// ----------
export interface IDeleteElementButtonOwnProps<T> {
  item?: T;
  titlePropPath?: string;
  customNameRender?: React.ReactNode;
  buttonText?: string;
  confirmText?: string;
  iconName?: keyof ILemonApplicationIconSet;
  iconOnly?: boolean;
  className?: string;
  buttonProps?: Omit<ButtonProps, 'onClick' | 'className'>;

  onDelete?: (item: T) => any;
}

type IDeleteElementButtonProps<T> = IDeleteElementButtonOwnProps<T>;

// -- Component
// ----------

/** Display generic delete element with custom confirmation item title  */
const DeleteElementButton = <T,>(props: IDeleteElementButtonProps<T>) => {

  const getItemTitle = () => {

    if (props.item) {
      return props.customNameRender ?? get(props.item, props.titlePropPath ?? 'title');
    }
    return;
  };

  const handleDelete = () => {
    if (props.item) {
      props.onDelete?.(props.item);
    }
  };

  return (
    <DeleteButton
      onConfirm={() => handleDelete()}
      itemTitle={getItemTitle()}
      confirmText={props.confirmText}
      buttonText={props.buttonText}
      iconName={props.iconName}
      iconOnly={props.iconOnly}
      className={props.className}
      buttonProps={props.buttonProps}
    />
  );
};

// -- HOCs and exports
// ----------

export default DeleteElementButton;
