import useCollectionStore from '@src/components/common/collectionParams/useCollectionStore';
import { ICollectionFetchPayload } from '@src/service/business/common/types';
import AppConfigService from '@src/service/common/AppConfigService';
import { useCallback, useEffect, useMemo, useState } from 'react';

const DEFAULT_PAGE_SIZE: number = AppConfigService.getValue('api.paging.defaultPageSize');

// --
// ----- Prop types

export type ICollectionUpdateFunction<F> = (payload: ICollectionFetchPayload<F>) => void;

export type IUpdateListFunction<F> = (onUpdateList?: ICollectionUpdateFunction<F>, filter?: F, page?: number, size?: number, sort?: string[]) => void;

export interface IUseCollectionStatePublicProps<F> {
  viewName?: string; // set view name used as key in global redux store. If none provided, local store only used
  initialValues?: Partial<ICollectionFetchPayload<F>>;  // set initial values for params if they are not default ones
  updateFn?: ICollectionUpdateFunction<F>;
}

export interface IUpdateCollectionStateFunctions<F> {
  onPageChange: (page?: number, size?: number) => void;
  onSortChange: (sort?: string[]) => void;
  onLoadMore: (loadSize?: number) => void;
  onFilterChange: (filter?: F) => void;
  /* tableChange: (pagination: TablePaginationConfig, filters: Partial<Record<string, ReactText[] | null>>, sorter: SorterResult<any> | Array<SorterResult<any>>) => void; */
  onParamsChange: (params: Partial<ICollectionFetchPayload<F>>) => void;
}

// --
// ----- Component

/** Custom hook for injecting collection params support to components. Also able to autoupdate list on param changes if provided with update function */
/** add dependency list to hook if update should happen on some other variable change also */
const useCollectionState = <F>({ viewName, initialValues, updateFn }: IUseCollectionStatePublicProps<F>, updateDependencies: React.DependencyList = []): [Readonly<ICollectionFetchPayload<F>>, IUpdateCollectionStateFunctions<F>, IUpdateListFunction<F>] => {

  const defaultParams: ICollectionFetchPayload<F> = {
    page: 0,
    size: DEFAULT_PAGE_SIZE,
    sort: [],
    filter: undefined,
    ...initialValues,
  };

  const [collectionState, setCollectionState] = useState<ICollectionFetchPayload<F>>(defaultParams);

  const [collectionStore, setCollectionStore] = useCollectionStore<F>(viewName, defaultParams);

  const collectionParams = useMemo(() => (collectionStore ?? collectionState), [collectionStore, collectionState]);
  const setCollectionParams = useMemo(() => (setCollectionStore ?? setCollectionState), [setCollectionStore, setCollectionState]);

  useEffect(() => {
    if (updateFn != null && collectionParams != null) {
      updateList(updateFn);
    }

  }, [collectionParams, ...updateDependencies]);

  const updateList = useCallback((onUpdate: ICollectionUpdateFunction<F> | undefined = updateFn, filter: F | undefined = collectionParams.filter, page: number | undefined = collectionParams.page, size: number | undefined = collectionParams.size, sort: string[] | undefined = collectionParams.sort) => {
    onUpdate?.({
      filter,
      page,
      size,
      sort,
    });
  }, [updateFn, collectionParams]);

  /** Handle load more feature for ListPagination component */
  const handleLoadMore = useCallback((loadSize?: number) => {
    const addSize = loadSize ?? defaultParams?.size ?? DEFAULT_PAGE_SIZE;
    const newSize = collectionParams.size != null ? collectionParams.size + addSize : addSize;
    setCollectionParams({
      ...collectionParams,
      size: newSize,
    });
  }, [defaultParams, collectionParams, setCollectionParams]);

  /** Handle changes in collection paging. */
  const handlePageChange = useCallback((page?: number, size: number | undefined = collectionParams.size) => {
    const newPage = page != null ? page - 1 : collectionParams.page;
    setCollectionParams({
      ...collectionParams,
      page: newPage,
      size,
    });
  }, [collectionParams, setCollectionParams]);

  /** Handle changes in collection sorting. */
  const handleSortChange = useCallback((sort?: string[]) => {
    setCollectionParams({
      ...collectionParams,
      sort,
      page: 0,
    });
  }, [collectionParams, setCollectionParams]);

  /** Handle changes in collection filter. Sets page to 0 on filter change */
  const handleFilterChange = useCallback((filter?: F) => {
    setCollectionParams({
      ...collectionParams,
      filter,
      page: 0,
    });
  }, [collectionParams, setCollectionParams]);

  /** Handle changes to multiple params */
  const handleUpdateCollectionParams = useCallback((params: Partial<ICollectionFetchPayload<F>>) => {
    const page = params.page != null ? params.page - 1 : collectionParams.page;
    setCollectionParams({
      ...collectionParams,
      ...params,
      page,
    });
  }, [collectionParams, setCollectionParams]);

  /* Map of all update event functions */
  const updateCollectionParams: IUpdateCollectionStateFunctions<F> = useMemo(() => ({
    onPageChange: handlePageChange,
    onSortChange: handleSortChange,
    onLoadMore: handleLoadMore,
    onFilterChange: handleFilterChange,
    /* tableChange: handleTableChange, */
    onParamsChange: handleUpdateCollectionParams,
  }), [handlePageChange, handleSortChange, handleLoadMore, handleFilterChange, /* handleTableChange,*/ handleUpdateCollectionParams]);

  return [collectionParams, updateCollectionParams, updateList];


  /** Method for handling changes specific to collections displayed using ant's Table. */
  /*     handleTableChange = (pagination: TablePaginationConfig, filters: Partial<Record<string, ReactText[] | null>>, sorter: SorterResult<any> | Array<SorterResult<any>>) => {
        if (!LangUtils.isArray(sorter)) {
          if (sorter.order != null && sorter.columnKey != null) {
                    handleSortChange([{ field: sorter.columnKey.toString(), direction: sorter.order }]);
          } else {
                    handleSortChange([]);
          }
        } else if (LangUtils.isArray(sorter)) {
          const sortArray: string[] = [];
          sorter.forEach((sort) => {
            if (sort.columnKey != null && sort.order != null) {
                    sortArray.push({ field: sort.columnKey?.toString(), direction: sort.order });
            }
          });
                  handleSortChange(sortArray);
        }
      }; */

};

// ----- exports
export default useCollectionState;
