import { Form } from '@ant-design/compatible';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import AddElementLink from '@src/components/course/update/AddElementLink';

import { Col, Row, Select } from 'antd';
import React from 'react';

const sortItems = [
  {
    id: 'name,asc',
    translationKey: 'COMMON.ACTION_SORT_ASCEND',
  },
  {
    id: 'name,desc',
    translationKey: 'COMMON.ACTION_SORT_DESCEND',
  },
];

// -- Prop types
// ----------

interface IAdministrationWorkPositionHeaderViewOwnProps {
  onCreateWorkPosition: (data: string) => void;
  changeSort: (sort: string[]) => void;
}

// -- Component
// ----------

class AdministrationWorkPositionHeaderView extends React.Component<IWithLocalizeOwnProps & IAdministrationWorkPositionHeaderViewOwnProps> {
  render() {
    return (
      <Row align="bottom" justify="space-between">
        <Col xs={24} lg={12}>
          <Form layout="inline">
            <Form.Item label={this.props.translate('COMMON.ACTION_SORT_LABEL')}>
              <Select defaultValue={[sortItems[0].id]} onChange={this.props.changeSort}>
                {sortItems.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {this.props.translate(item.translationKey)}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
        </Col>
        <Col xs={24} lg={12}>
          <Row justify="end">
            <AddElementLink title={this.props.translate('ADMINISTRATION.CONTAINER_CREATE_WORKPOSITION')} onSubmit={this.props.onCreateWorkPosition}/>
          </Row>
        </Col>
      </Row>
    );
  }
}

// -- HOCs and exports
// ----------

export default withLocalize<IAdministrationWorkPositionHeaderViewOwnProps>(AdministrationWorkPositionHeaderView as any);
