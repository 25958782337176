import { IAddress } from '@src/model/user/Address';

export interface IActivityParticipant {
  participantId: string;
  userId: string;
  firstName: string;
  lastName: string;
  participantRole: string;
  address?: IAddress;
}

export enum ParticipantRoleEnum {
  EVALUATOR = 'EVALUATOR',
  ASSIGNEE = 'ASSIGNEE',
}
