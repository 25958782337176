import { Menu } from 'antd';
import React, { Key } from 'react';
import { withRouter, WithRouterProps } from 'react-router';

import { DownOutlined, UpOutlined } from '@ant-design/icons';
import LocalePickerContainer from '@src/components/app/locale/LocalePickerContainer';
import AppUserAvatar from '@src/components/common/image/AppUserAvatar';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';

// -- Types&Interfaces
// ----------

enum MenuKeys {
  // Admin,
  MyProfile,
  ChangePassword,
  ChangeLanguage,

  Logout,
  Notifications,
}

// cannot import ClickParam from antd so we recreate it only with things we need
// tslint:disable-next-line: interface-name
interface MenuItemClickEvent {
  key: string;
}

// -- Prop types
// ----------
export interface IUserMenuPublicProps {
  user: IUserInfo;

  onDoLogout?: () => void;
}
type IUserMenuProps = IUserMenuPublicProps & IWithLocalizeOwnProps & WithRouterProps & IWithTenantPropEnabledOwnProps;

interface IUserMenuState {
  isMenuOpened: boolean;
  isAdmin: boolean;
}

// TODO: protection against long user names (some container with overflow and ellipsis)

// --
// ----- Component
class UserMenu extends React.Component<IUserMenuProps, IUserMenuState> {
  state = {
    isMenuOpened: false,
    isAdmin: !!this.props.user.roles.find((item) => item.id === UserRoleEnum.SUPERADMIN),
  };

  render() {
    // TODO: default menu open/close icon appears only in iniline menu - this one is only a quick replacement
    const subMenuTitle = (
      <div className="lemon-userMenu__loggedUserContainer">
        <AppUserAvatar imagePath={this.props.user.profileImageUrl} size="small" />
        {/* &nbsp;&nbsp;
        <span className="lemon-userMenu__loggedUserName">{this.userDisplayName()}</span> */}
        &nbsp;&nbsp;
        {this.state.isMenuOpened ? <UpOutlined className="lemon-userMenu__menuOpener" /> : <DownOutlined className="lemon-userMenu__menuOpener" />}
      </div>
    );

    return (
      <div>
        <Menu mode="horizontal" overflowedIndicator={null} selectable={false} onClick={this.onMenuClick} onOpenChange={this.onMenuOpenChange} data-test-id="lemon-userMenu" className="lemon-appLayout__headerMenu">
          <Menu.SubMenu title={subMenuTitle} className="lemon-appLayout__headerMenu" key="lemon-userMenu">
            <Menu.Item key={'LoggedUser'} disabled={true} className="lemon-userMenu__loggedUserMenuItem">
              <AppUserAvatar imagePath={this.props.user.profileImageUrl} size="small" />
              &nbsp;&nbsp;
              {this.userDisplayName()}
            </Menu.Item>

            <Menu.Item key={MenuKeys.MyProfile} data-test-id="lemon-userMenu__itemMyProfile">
              {this.props.translate('USER_MENU.MY_PROFILE')}
            </Menu.Item>

            <LocalePickerContainer key={MenuKeys.ChangeLanguage} />

            {this.props.isTenantPropEnabled('admin_notifications') && (
              <Menu.Item key={MenuKeys.Notifications} data-test-id="lemon-userMenu__itemLogout">
                {this.props.translate('USER_MENU.NOTIFICATIONS')}
              </Menu.Item>
            )}

            <Menu.Item key={MenuKeys.Logout} data-test-id="lemon-userMenu__itemLogout">
              {this.props.translate('USER_MENU.LOGOUT_LABEL')}
            </Menu.Item>
          </Menu.SubMenu>
        </Menu>
      </div>
    );
  }

  onMenuOpenChange = (openKeys: Key[]) => {
    this.setState({
      isMenuOpened: openKeys.length > 0,
    });
  };

  onMenuClick = (event: MenuItemClickEvent) => {
    const eventKey = Number.parseInt(event.key, undefined);

    // -- Logout
    if (MenuKeys[MenuKeys.Logout] === MenuKeys[eventKey]) {
      this.handleLogout();
    }
    // -- MyProfile
    else if (MenuKeys[MenuKeys.MyProfile] === MenuKeys[eventKey]) {
      this.props.router.push(`/user/${this.props.user.id}`);
    }
    // -- Change Password
    else if (MenuKeys[MenuKeys.ChangePassword] === MenuKeys[eventKey]) {
      this.props.router.push('/changepassword');
    }
    // -- Notifications
    else if (MenuKeys[MenuKeys.Notifications] === MenuKeys[eventKey]) {
      this.props.router.push('/notifications');
    }
    // sanity check
    else {
      console.warn(`Unhandled menu item clicked: ${event.key}`);
    }
  };

  handleLogout = () => {
    if (this.props.onDoLogout != null) {
      this.props.onDoLogout();
    }
  };

  userDisplayName = (): string => {
    return `${this.props.user.firstName} ${this.props.user.lastName}`;
  };
}

export default withLocalize<IUserMenuPublicProps>(withRouter(withTenantPropEnabled(UserMenu) as any));
