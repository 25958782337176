import AppConfigService from '@src/service/common/AppConfigService';
import { CookieManager } from '@src/service/util/CookieManager';
import { DomainUtils } from '@src/service/util/DomainUtils';

let TENANT_CODE: string;

/** Global tenant utils for easier access from other parts of code. */
export default class TenantManager {
  /** Set global tenant code. This method should be the only method to keep global tenant code. */
  static setTenantCode(code: string) {
    TENANT_CODE = code;

    const webappDomainWithoutSubdomains = DomainUtils.extractDomainWithoutSubs();
    CookieManager.setCookie({
      // tslint:disable-next-line: no-duplicate-string
      name: AppConfigService.getValue('cookies.tenant.name'),
      value: code,
      expires: AppConfigService.getValue('Config.cookies.tenant.duration') ? new Date(Date.now() + AppConfigService.getValue('cookies.tenant.duration')) : undefined,
      path: '/',
      domain: webappDomainWithoutSubdomains,
    });
  }

  /** Returns global tenant code. This method should be the only source of truth. */
  static getTenantCode(): string {
    return TENANT_CODE;
  }
}
