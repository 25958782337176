import GridItem from '@src/components/common/grid/GridItem';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import DialogPanel from '@src/components/common/panel/DialogPanel';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/common/EducationApplicationHelperUtils';
import EducationApplicationUserFileListView from '@src/components/externalEducationApplication/common/EducationApplicationUserFileListView';
import { IFileListsByType } from '@src/components/externalEducationApplication/common/types';
import ExternalEducationExpensesForm from '@src/components/externalEducationApplication/form/ExternalEducationExpensesForm';
import NoteCreateContainer from '@src/components/usernote/NoteCreateContainer';
import { ActivityPhaseEnum } from '@src/model/activity/ActivityPhase';

import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { IFile } from '@src/model/file/File';
import { FileTypeEnum } from '@src/model/file/FileSystemElement';
import { NoteObjectTypeEnum } from '@src/model/user/Note';
import { IExternalEducationExpenseCreatePayload } from '@src/service/business/externalEducationExpense/ExternalEducationExpenseBusinessStore';
import { Col, Row, Typography } from 'antd';

import React from 'react';

// -- Prop types
// ----------
interface IExternalEducationApplicationCoordinatorDurationViewOwnProps {
  externalEducationApplication: IExternalEducationApplication;
  applicationFiles: IFileListsByType;
  onUpdate?: (data: IExternalEducationApplication) => void;
  onCreateExpense?: (data: IExternalEducationExpenseCreatePayload) => void;
  // tslint:disable-next-line: bool-param-default
  onChangePhase?: (newPhase: ActivityPhaseEnum, save?: boolean) => void;
  onFileUpload?: (file: IFile[]) => void;
  onFileRemove?: (file: IFile[]) => void;
}

type IExternalEducationApplicationCoordinatorDurationViewProps = IExternalEducationApplicationCoordinatorDurationViewOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

interface IExternalEducationApplicationCoordinatorDurationViewState { }

// -- Component
// ----------

class ExternalEducationApplicationCoordinatorDurationView extends React.Component<IExternalEducationApplicationCoordinatorDurationViewProps, IExternalEducationApplicationCoordinatorDurationViewState> {
  state: IExternalEducationApplicationCoordinatorDurationViewState = {};

  componentDidUpdate = (prevProps: IExternalEducationApplicationCoordinatorDurationViewProps, prevState: IExternalEducationApplicationCoordinatorDurationViewState) => {
    //
  };

  isApplicationInPhases = (phases: ActivityPhaseEnum[]): boolean => {
    return EducationApplicationHelperUtils.isInPhases(phases, this.props.externalEducationApplication.activity);
  };

  handleChangePhase = (phase: ActivityPhaseEnum) => {
    if (this.props.onChangePhase) {
      this.props.onChangePhase(phase);
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.isApplicationInPhases([ActivityPhaseEnum.EDUCATION_IN_PROGRESS]) && (
          <React.Fragment>
            <Row justify="end">
              <Col>
                <NoteCreateContainer
                  objectId={this.props.externalEducationApplication.id}
                  objectTypeId={NoteObjectTypeEnum.EDUCATION_APPLICATION}
                  objectPublic={true}
                  buttonLabel={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CANCEL_APPLICATION_APPROVAL_BUTTON_TEXT')}
                  modalTitle={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CANCEL_APPLICATION_APPROVAL_MODAL_TITLE')}
                  modalOkButtonLabel={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CANCEL_APPLICATION_APPROVAL_BUTTON_TEXT')}
                  modalPlaceholder={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CANCEL_APPLICATION_APPROVAL_PLACEHOLDER')}
                  onCreateNote={() => this.handleChangePhase(ActivityPhaseEnum.DENIED)}
                />
              </Col>
            </Row>

            <Typography.Paragraph> {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.USER_EDUCATION_IN_PROGERSS_LABEL')}  </Typography.Paragraph>

            <GridItem label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.FILES.ATTENDANCE_CERTIFICATE_LABEL')}>
              <EducationApplicationUserFileListView files={this.props.applicationFiles} displayTypes={[FileTypeEnum.ATTENDANCE_CERTIFICATE]} />
            </GridItem>

            <DialogPanel title={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.EXPENSE.PANEL_LABEL')} className="timun-externalEducationApplication__expensesPanel">
              <ExternalEducationExpensesForm externalEducationApplication={this.props.externalEducationApplication} onSubmit={this.props.onCreateExpense} />
            </DialogPanel>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

// -- HOCs and exports
// ----------

export default withRoles(withLocalize<IExternalEducationApplicationCoordinatorDurationViewOwnProps>(ExternalEducationApplicationCoordinatorDurationView as any));
