import AppContent from '@src/components/common/container/AppContent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import RepositoryCoursesContainer from '@src/components/repository/course/RepositoryCoursesContainer';
import RepositoryFileContainer from '@src/components/repository/file/RepositoryFileContainer';
import RepositoryTagFilter from '@src/components/repository/tag/RepositoryTagFilter';
import RepositoryUsersContainer from '@src/components/repository/user/RepositoryUsersContainer';
import RepositoryWebinarsContainer from '@src/components/repository/webinar/RepositoryWebinarsContainer';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import { ITag } from '@src/model/tag/Tag';
import { ICollectionData } from '@src/service/business/common/types';
import TagBusinessStore, { ITagListFilter } from '@src/service/business/tag/TagBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { Col, Row, Tabs } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

const tagPageSize = AppConfigService.getValue('api.paging.maxPageSize');

enum RepositoryContainerTabKeys {
  COURSES = 'courses',
  WEBINARS = 'webinars',
  DOCUMENTS = 'documents',
  USERS = 'users',
}

// -- Prop types
// ----------

export interface IRepositoryContainerOwnProps {
  entity: string;
  entityId?: string;
}

export interface IRepositoryContainerStateProps {
  tagList: ICollectionData<ITag>;
  tagListFilter: ITagListFilter;
}

export interface IRepositoryContainerDispatchProps {
  fetchTagList: (listFilter: ITagListFilter, page: number, size: number, sort: string[]) => void;
  clearTagList: () => void;
}

type IRepositoryContainerProps = IRepositoryContainerOwnProps & IRepositoryContainerStateProps & IRepositoryContainerDispatchProps & IWithLocalizeOwnProps & WithRouterProps & IWithTenantPropEnabledOwnProps;

interface IRepositoryContainerState {
  currentTags: string[];
  page: number;
  size: number;
}

// -- Component
// ----------

/** Repository container component */
class RepositoryContainer extends React.Component<IRepositoryContainerProps, IRepositoryContainerState> {
  state: IRepositoryContainerState = {
    currentTags: [],
    page: 0,
    size: tagPageSize,
  };

  componentDidMount = () => {
    this.updateTagList();
  };

  componentWillUnmount = () => {
    this.props.clearTagList();
  };

  render = () => {
    return (
      <AppContent level={1} title={this.props.translate('REPOSITORY.TITLE')}>
        <Row justify="space-between" gutter={24}>
          <Col className="timun-tagFilter__container" xs={24} lg={6}>
            <RepositoryTagFilter tagList={this.props.tagList} currentTags={this.state.currentTags} onTagSearch={this.handleTagSearchEvent} onTagChange={this.handleTagChange}/>
          </Col>
          <Col xs={24} lg={18}>
            <Tabs activeKey={this.props.entity} onChange={this.handleTabChange} destroyInactiveTabPane={true}>
              <Tabs.TabPane tab={this.props.translate('REPOSITORY.COURSES_LABEL')} key={RepositoryContainerTabKeys.COURSES}>
                <RepositoryCoursesContainer currentTags={this.state.currentTags}/>
              </Tabs.TabPane>
              {/* Tabs can only ignore empty values, conditional components are always rendered, even empty (at least in antd 3.x) */}
              {this.props.isTenantPropEnabled('webinars') && (
                <Tabs.TabPane tab={this.props.translate('REPOSITORY.WEBINARS_LABEL')} key={RepositoryContainerTabKeys.WEBINARS}>
                  <RepositoryWebinarsContainer currentTags={this.state.currentTags}/>
                </Tabs.TabPane>
              )}
              <Tabs.TabPane tab={this.props.translate('REPOSITORY.DOCUMENTS_LABEL')} key={RepositoryContainerTabKeys.DOCUMENTS}>
                <RepositoryFileContainer folderId={this.props.entityId} currentTags={this.state.currentTags}/>
              </Tabs.TabPane>
              <Tabs.TabPane tab={this.props.translate('REPOSITORY.USERS_LABEL')} key={RepositoryContainerTabKeys.USERS}>
                <RepositoryUsersContainer currentTags={this.state.currentTags}/>
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
      </AppContent>
    );
  };

  handleTagChange = (tags: string[]) => {
    this.setState({
      currentTags: tags,
    });
  };

  handleTabChange = (key: string) => {
    this.props.router.push('/repository/' + key);
  };

  handleTagSearchEvent = (filter: ITagListFilter) => {
    this.updateTagList(filter);
  };

  private updateTagList = (filter: ITagListFilter = this.props.tagListFilter, page: number = this.state.page, pageSize: number = this.state.size, sort: string[] = []) => {
    this.props.fetchTagList(filter, page, pageSize, sort);
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IRepositoryContainerStateProps => ({
  tagList: TagBusinessStore.selectors.getTagList(state),
  tagListFilter: TagBusinessStore.selectors.getTagListFilter(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IRepositoryContainerDispatchProps => ({
  fetchTagList: (filter: ITagListFilter, page: number, size: number, sort: string[]) => dispatch(TagBusinessStore.actions.fetchTagList(filter, page, size, sort)),
  clearTagList: () => dispatch(TagBusinessStore.actions.clearTagList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<IRepositoryContainerOwnProps>(withTenantPropEnabled(withRouter(RepositoryContainer) as any)));
