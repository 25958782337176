import { Form } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { SearchOutlined } from '@ant-design/icons';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import { HzzUserRoleEnum, UserRoleEnum } from '@src/model/user/UserRole';
import { IUserListFilter } from '@src/service/business/user/userListBusinessStore';
import { Button, Col, Input, Row, Select } from 'antd';
import React, { SyntheticEvent } from 'react';

// --
// ----- Const

export enum UserListActivityEnum {
  ALL_USERS = 0,
  ACTIVE_ONLY = 1,
}

// --
// ----- Prop types
const FormItem = Form.Item;
const Option = Select.Option;

interface IAdminUserManagementListFilterOwnProps {
  userListFilter: IUserListFilter;
  onFilterSubmit: (listFilter: IUserListFilter) => void;
  showOib?: boolean;
}

type IAdminUserManagementListFilterProps = IAdminUserManagementListFilterOwnProps & IWithLocalizeOwnProps & FormComponentProps & IWithTenantPropEnabledOwnProps;

interface IAdminUserManagementListFilterState {
}

// -- Component
// ----------
class AdminUserManagementListFilter extends React.Component<IAdminUserManagementListFilterProps, IAdminUserManagementListFilterState> {
  state: IAdminUserManagementListFilterState = {};

  render = () => {
    const { getFieldDecorator } = this.props.form;
    const userRoleEnum = this.props.isTenantPropEnabled('courses') ? UserRoleEnum : HzzUserRoleEnum;

    return (
      <Form className="timun-userManagementListFilter__form" layout="vertical">
        <Row gutter={12}>
          <Col xs={24} sm={12} md={7}>
            <FormItem label={this.props.translate('ADMIN_USER_MANAGEMENT_LIST_FILTER.NAME_LABEL')}>{getFieldDecorator('name', {})(<Input prefix={<SearchOutlined/>} placeholder={this.props.translate('COMMON.ACTION_SEARCH')} allowClear={true}/>)}</FormItem>
          </Col>

          {this.props.showOib && <Col xs={24} sm={12} md={7}>
            <FormItem label={this.props.translate('ADMIN_USER_MANAGEMENT_LIST_FILTER.OIB_LABEL')}>
              {getFieldDecorator('identificationNumber')(<Input prefix={<SearchOutlined/>} allowClear={true}/>)}
            </FormItem>
          </Col>}

          <Col xs={24} sm={12} md={7}>
            <FormItem label={this.props.translate('ADMIN_USER_MANAGEMENT_LIST_FILTER.ROLE_LABEL')}>
              {getFieldDecorator(
                'role',
                {},
              )(
                <Select placeholder={this.props.translate('USER_FORM.ROLES_PLACEHOLDER')} allowClear={true}>
                  {Object.keys(userRoleEnum).map((item: any) => (
                    <Option value={userRoleEnum[item as keyof typeof userRoleEnum]} key={userRoleEnum[item as keyof typeof userRoleEnum]}>
                      {this.props.translate(`USER_FORM.ROLE_${item}_LABEL`)}
                    </Option>
                  ))}
                </Select>,
              )}
            </FormItem>
          </Col>

          <Col xs={24} sm={12} md={3}>
            <FormItem label={this.props.translate('USER_FORM.ACTIVITY_PLACEHOLDER')}>
              {getFieldDecorator(
                'activeOnly',
                { initialValue: UserListActivityEnum.ACTIVE_ONLY },
              )(
                <Select>
                  <Select.Option value={UserListActivityEnum.ACTIVE_ONLY}>{this.props.translate('USER_FORM.ACTIVITY_ACTIVE_ONLY')}</Select.Option>
                  <Select.Option value={UserListActivityEnum.ALL_USERS}>{this.props.translate('USER_FORM.ACTIVITY_ALL')}</Select.Option>
                </Select>,
              )}
            </FormItem>
          </Col>

          <Col xs={24} md={8}>
            <Button onClick={this.handleSubmit}> {this.props.translate('COMMON.ACTION_FILTER')} </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        const listFilter: IUserListFilter = {};

        if (values.name) {
          listFilter.name = values.name;
        }
        if (values.identificationNumber) {
          listFilter.identificationNumber = values.identificationNumber;
        }
        if (values.role) {
          listFilter.role = values.role;
        }
        if (values.activeOnly || values.activeOnly === UserListActivityEnum.ALL_USERS) {
          listFilter.activeOnly = !!values.activeOnly;
        }

        if (this.props.onFilterSubmit) {
          this.props.onFilterSubmit(listFilter);
        }
      }
    });
  };
}

const AdminUserManagementListFilterForm = Form.create()(AdminUserManagementListFilter);

export default withLocalize(withTenantPropEnabled<IAdminUserManagementListFilterOwnProps>(AdminUserManagementListFilterForm as any));
