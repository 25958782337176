import React, { PropsWithChildren, CSSProperties } from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { serializeStylesMap } from '@src/components/common/util/styleProperties';

// -- Prop types
// ----------

export interface IAppStyleProviderOwnProps {
  styles: Record<string, CSSProperties>;
}
type IAppStyleProviderProps = PropsWithChildren<IAppStyleProviderOwnProps & IWithLocalizeOwnProps>;

// -- Component
// ----------

/** Component takes CSS properties object, se */
const AppStyleProvider: React.FC<IAppStyleProviderProps> = (props) => {
  return <style type="text/css">{serializeStylesMap(props.styles)}</style>;
};

export default withLocalize<IAppStyleProviderOwnProps>(AppStyleProvider as any);
