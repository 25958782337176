import { LeftOutlined } from '@ant-design/icons';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import CourseDataForm from '@src/components/course/create/CourseDataForm';
import CourseViewSider from '@src/components/course/view/CourseViewSider';

import { ICourseGroup } from '@src/model/course/CourseGroup';

import CollectionBusinessStore from '@src/service/business/common/collectionBusinessStore';
import courseUpdateBusinessStore, { ICourseCreatePayload } from '@src/service/business/courses/courseUpdateBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createActionThunk, IActionThunkMap } from '@src/service/util/action/thunk';
import { Layout, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';
import { Dispatch } from 'redux';

const COURSE_ABOUT_ROUTE = AppConfigService.getValue('components.courses.courseAboutRouterProp');

const { Header, Sider, Content } = Layout;

// -- Prop types
// ----------

export interface ICourseCreateContainerOwnProps {
}

export interface ICourseCreateContainerStateProps {
  courseGroups: ICourseGroup[];
}

export interface ICourseCreateContainerDispatchProps {
  createCourse: (data: ICourseCreatePayload, callback: IActionThunkMap) => void;
}

type ICourseCreateContainerProps = ICourseCreateContainerOwnProps & ICourseCreateContainerStateProps & ICourseCreateContainerDispatchProps & IWithLocalizeOwnProps & WithRouterProps;

interface ICourseCreateContainerState {
}

// -- Component
// ----------

/** container for the create course form */
class CourseCreateContainer extends React.Component<ICourseCreateContainerProps, ICourseCreateContainerState> {
  state = {};

  render = () => {
    return (
      <div>
        <Layout>
          <Header className="timun-courseView__sider-courseTitle">
            <HeaderTitle title={this.props.translate('COURSE_LIST.VIEW_TITLE')} link={'/courses'} icon={<LeftOutlined/>}/>
            <Typography.Title> {this.props.translate('COURSE_VIEW.CREATE.CREATE_COURSE_TITLE')} </Typography.Title>
          </Header>
          <Layout>
            <Sider className="timun-courseView__sider" breakpoint="lg" collapsedWidth={0}>
              <CourseViewSider canSeeExamTemplate={false} lectureList={[]} selectedLecture={COURSE_ABOUT_ROUTE} canEdit={false} showExamInstance={false}/>
            </Sider>
            <Content className="timun-courseView__content">
              <CourseDataForm courseGroups={this.props.courseGroups} onSubmit={this.handleCourseCreate} onCancel={this.handleCancel}/>
            </Content>
          </Layout>
        </Layout>
      </div>
    );
  };

  handleCancel = () => {
    this.props.router.goBack();
  };

  handleCourseCreate = (data: ICourseCreatePayload) => {
    this.props.createCourse(data, {
      success: (response) => {
        this.props.router.replace(`/course/${response.id}`);
      },
    });
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ICourseCreateContainerOwnProps): ICourseCreateContainerStateProps => ({
  courseGroups: CollectionBusinessStore.selectors.getCollectionContent(state, 'CourseGroup'),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: Dispatch): ICourseCreateContainerDispatchProps => ({
  createCourse: (data: ICourseCreatePayload, thunkMap: IActionThunkMap) => dispatch(createActionThunk(courseUpdateBusinessStore.actions.createCourse(data), thunkMap)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<ICourseCreateContainerOwnProps>(withRouter(CourseCreateContainer as any)));
