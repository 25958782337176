import React from 'react';

import ContactDataPropertyInput from '@src/components/codebook/form/common/ContactDataPropertyInput';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import GdprContainer from '@src/components/user/registration/GdprContainer';
import { IUserInfo } from '@src/model/user/User';
import { Checkbox, Col, Form, FormInstance, Row } from 'antd';
import { RuleObject } from 'antd/lib/form';

// -- Prop types
// ----------

export interface IUserRegistrationFormOwnProps {
  value?: IUserInfo;
  onSubmit?: (data: IUserInfo) => void;
  formRef?: FormInstance<IUserInfo>;
  disabled?: boolean;
}
type IUserRegistrationFormProps = IUserRegistrationFormOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Display user registration form */
const UserRegistrationForm: React.FC<IUserRegistrationFormProps> = (props: IUserRegistrationFormProps) => {
  const [form] = Form.useForm<IUserInfo>(props.formRef);

  const handleFinish = (values: IUserInfo) => {
    const userData = { ...props.value, ...values };
    props.onSubmit?.(userData);
  };

  const validateCheckBoxValue = async (rules: RuleObject, values: any, callback?: (error?: string | undefined) => void) => {
    if (!values) {
      return Promise.reject(new Error(props.translate('USER_REGISGTRATION.FORM.ERROR_MESSAGE.GDPR_REQUIRED')));
    }
    return Promise.resolve();
  };

  return (
    <Form<IUserInfo>
      initialValues={props.value}
      layout="vertical"
      form={form}
      name="user"
      onFinish={handleFinish}>

      <Form.Item>
        <Row>
          <Col className="text-center">{props.translate('USER_REGISTRATION.MODAL.WELCOME', { firstName: props.value?.firstName, lastName: props.value?.lastName })}</Col>
        </Row>
      </Form.Item>

      <Form.Item label={props.translate('USER_FORM.CONTACT_DATA_LABEL')} required={true}>
        <ContactDataPropertyInput required={true} formRef={form} />
      </Form.Item>

      <Form.Item label={props.translate('USER_FORM.GDPR_LABEL')}
        name={'gdprAccepted'}
        valuePropName="checked"
        required={true}
        rules={[{ validator: validateCheckBoxValue }]}>
        <Checkbox>{props.translate('USER_FORM.GDPR_ACCEPTED')}</Checkbox>
      </Form.Item>

      <GdprContainer />
    </Form >
  );
};

export default withLocalize<IUserRegistrationFormOwnProps>(UserRegistrationForm as any);
