import DataPicker, { IDataPickerItem } from '@src/components/common/datapicker/DataPicker';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ICompany } from '@src/model/company/Company';
import CompanyBusinessStore from '@src/service/business/company/CompanyBusinessStore';
import CompanyListBusinessStore, { ICompanyListFilter } from '@src/service/business/company/CompanyListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { LangUtils } from '@src/service/util/LangUtils';
import ValidationUtils from '@src/service/util/validation/ValidationUtils';
import { Col, Row } from 'antd';
import { debounce } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

const debounceTimeout = AppConfigService.getValue('components.common.debounceTimeout');
const minSearchLength = AppConfigService.getValue('components.common.minSearchStringLength');

// -- Prop types
// ----------

interface ICompanyDataPickerOwnProps {
  value?: ICompany;
  onChange?: (company: ICompany) => void;
  onClear?: () => void;
}

interface ICompanyDataPickerStateProps {
  company: ICompany;
}

interface ICompanyDataPickerDispatchProps {
  fetchCompanyList: (filter: ICompanyListFilter) => ITrackableAction;
}

type ICompanyDataPickerProps = ICompanyDataPickerOwnProps & ICompanyDataPickerStateProps & IWithLocalizeOwnProps & ICompanyDataPickerDispatchProps;

interface ICompanyDataPickerState {
  companyList: ICompany[];
}

// -- Component
// ----------

class CompanyDataPicker extends React.Component<ICompanyDataPickerProps> {
  state: ICompanyDataPickerState = {
    companyList: [],
  };

  handleNameSearch = debounce((value: string) => {
    const filter: ICompanyListFilter = { name: value };
    if (value.length >= minSearchLength) {
      this.updateList(filter);
    } else if (LangUtils.isEmpty(value) || value.length < minSearchLength) {
      this.storeList();
    }
  }, debounceTimeout);

  handleOibSearch = debounce((value: string) => {
    const filter: ICompanyListFilter = { identificationNumber: value };
    if (ValidationUtils.isOibValid(value)) {
      this.updateList(filter);
    } else {
      this.storeList();
    }
  }, debounceTimeout);

  render = () => {
    const pickerValue = this.props.value && this.mapToItem(this.props.value);
    const pickerItems = this.state.companyList?.map(this.mapToItem) || [];

    return (
      <Row gutter={[12, 12]}>
        <Col xs={24} md={12}>
          {/* Search by name */}
          <DataPicker onClear={this.props.onClear} value={pickerValue} items={pickerItems} disabled={false} onChange={this.handleDataPickerChange} onSearch={this.handleNameSearch}
                      placeholderSuffix={this.props.translate('COMPANY.FORM.SELECTOR_NAME_LABEL')} dataTestIdPrefix="timun-companyPicker"/>
        </Col>
        <Col xs={24} md={12}>
          {/* Search by identificationNumber */}
          <DataPicker onClear={this.props.onClear} value={pickerValue} items={pickerItems} disabled={false} onChange={this.handleDataPickerChange} onSearch={this.handleOibSearch}
                      placeholderSuffix={this.props.translate('COMPANY.FORM.SELECTOR_ID_LABEL')} dataTestIdPrefix="timun-companyPicker"/>
        </Col>
      </Row>
    );
  };

  handleDataPickerChange = (value?: IDataPickerItem) => {
    this.props.onChange?.(value?.data);
    this.storeList(); // clear search results
  };

  private mapToItem = (company: ICompany): IDataPickerItem => {
    return {
      value: company.id || company.cesCompanyId,
      name: company.name,
      data: company,
      description: `${company.identificationNumber ? `${company.identificationNumber}` : ''}`,
    };
  };

  private updateList = (filter: ICompanyListFilter = {}) => {
    this.props
      .fetchCompanyList(filter)
      .track()
      .subscribe((data: ICompany[]) => this.storeList(data));
  };

  private storeList = (companyList?: ICompany[]) => {
    this.setState({ companyList });
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ICompanyDataPickerOwnProps): ICompanyDataPickerStateProps => ({
  company: CompanyBusinessStore.selectors.getCompany(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: Dispatch): ICompanyDataPickerDispatchProps => ({
  fetchCompanyList: (filter: ICompanyListFilter) => createTrackableAction(dispatch(CompanyListBusinessStore.actions.fetchPickerCompanyList(filter))),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<ICompanyDataPickerOwnProps>(CompanyDataPicker as any));
