import GridItem from '@src/components/common/grid/GridItem';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ViewSectionItem from '@src/components/common/section/ViewSectionItem';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { ISkill } from '@src/model/skillgroup/Skill';
import AppConfigService from '@src/service/common/AppConfigService';
import { Tag } from 'antd';
import moment from 'moment';
import React from 'react';

// -- Const
// ----------
const dateFormat = AppConfigService.getValue('dateTimeFormat.dateWithYear');

// -- Prop types
// ----------
export interface IEducationApplicationDetailsViewSectionOwnProps {
  educationApplicationDetails?: IExternalEducationApplication;
  active?: boolean;
}

type IEducationApplicationDetailsViewSectionProps = IEducationApplicationDetailsViewSectionOwnProps & IWithLocalizeOwnProps;

interface IEducationApplicationDetailsViewSectionState {}

// -- Component
// ----------

/** Component wrapped with viewSectionItem displaying application details */
class EducationApplicationDetailsViewSection extends React.Component<IEducationApplicationDetailsViewSectionProps, IEducationApplicationDetailsViewSectionState> {
  state: IEducationApplicationDetailsViewSectionState = {};

  render() {
    return (
      <ViewSectionItem bordered={true} active={this.props.active} header={<HeaderTitle title={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.TITLE')} icon={<LemonIcon name="clipboard" />} />} collapsible={true}>
        <GridItem ariaLabel={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.EDUCATION_START_DATE_TITLE')} label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.EDUCATION_START_DATE_TITLE')}>
          {this.props.educationApplicationDetails?.startDateTime != null ? moment(this.props.educationApplicationDetails?.startDateTime).format(dateFormat) : null}
        </GridItem>

        <GridItem ariaLabel={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.SKILL_ACQUISITION_TITLE')} label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.SKILL_ACQUISITION_TITLE')}>
          {this.props.educationApplicationDetails?.skills?.length ? this.props.educationApplicationDetails?.skills?.filter((skill) => skill != null).map((skill: ISkill) => (skill != null ? this.renderSkillTag(skill) : null)) : null}
        </GridItem>

        <GridItem ariaLabel={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.JOB_RELATED_TITLE')} label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.JOB_RELATED_TITLE')}>
          {this.props.educationApplicationDetails?.jobRelated ? this.props.translate('COMMON.LABEL.YES') : this.props.translate('COMMON.LABEL.NO')}
        </GridItem>

        <GridItem ariaLabel={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.EDUCATION_RELATED_TITLE')} label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.EDUCATION_RELATED_TITLE')}>
          {this.props.educationApplicationDetails?.educationRelated ? this.props.translate('COMMON.LABEL.YES') : this.props.translate('COMMON.LABEL.NO')}
        </GridItem>

        <GridItem ariaLabel={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.CONSULTATION_TITLE')} label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.CONSULTATION_TITLE')}>
          {this.props.educationApplicationDetails?.consultation ? this.props.translate('COMMON.LABEL.YES') : this.props.translate('COMMON.LABEL.NO')}
        </GridItem>

        <GridItem ariaLabel={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.APPLICATION_DESCRIPTION_TITLE')} label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.APPLICATION_DESCRIPTION_TITLE')}>
          {this.props.educationApplicationDetails?.applicationDescription}
        </GridItem>
      </ViewSectionItem>
    );
  }

  renderSkillTag = (skill: ISkill) => {
    return (
      <Tag color="blue" key={skill.id}>
        {skill.title}
      </Tag>
    );
  };
}
// -- HOCs and exports
// ----------

export default withLocalize<IEducationApplicationDetailsViewSectionOwnProps>(EducationApplicationDetailsViewSection as any);
