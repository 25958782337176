import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ProgressBar from '@src/components/common/progressbar/ProgressBar';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import CourseHelperUtils from '@src/components/course/common/CourseHelperUtils';
import { ICourseProgress } from '@src/model/course/Course';
import React from 'react';


// -- Prop types
// ----------

export interface ICourseProgressBarOwnProps {
  progress?: ICourseProgress;
}
type ICourseProgressBarProps = ICourseProgressBarOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

// -- Component
// ----------

/** Component display education information */
class CourseProgressBar extends React.Component<ICourseProgressBarProps> {
  render() {
    const currentLecture = CourseHelperUtils.getCurrentLecture(this.props.progress);

    return (
      <div>
        {currentLecture.title}
        <div>
          <ProgressBar progressProps={{ size: 'small' }} progress={this.props.progress} />
        </div>
      </div>
    );
  }
}

export default withRoles(withLocalize<ICourseProgressBarOwnProps>(CourseProgressBar as any));
