import { Icon as LegacyIcon } from '@ant-design/compatible';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IWebinar } from '@src/model/webinar/Webinar';
import AppConfigService from '@src/service/common/AppConfigService';
import { Tooltip } from 'antd';
import moment from 'moment';
import React, { SyntheticEvent } from 'react';

const DATE_WITH_YEAR: string = AppConfigService.getValue('dateTimeFormat.dateWithYear');

interface IAdditionalCardElementsOwnProps {
  webinar?: IWebinar;
  onSetReminder?: (webinar: IWebinar) => void;
  reminderSet?: boolean;
}

type IAdditionalCardElementsProps = IAdditionalCardElementsOwnProps & IWithLocalizeOwnProps;

class AdditionalCardElements extends React.Component<IAdditionalCardElementsProps, any> {
  render() {
    return (
      <React.Fragment>
        <div className="timun-webinarCardList__reminderDiv text-right" onClick={this.setReminder}>
          <Tooltip title={this.props.translate('REMINDER.SET_REMINDER_TOOLTIP')}>
            <LegacyIcon className="timun-webinarCardList__reminderIcon" type="bell" theme={this.props.reminderSet ? 'filled' : 'outlined'} />
          </Tooltip>
        </div>
        <div className="timun-webinarCardList__date text-right">{moment(this.props.webinar?.estimatedStartDateTime).format(DATE_WITH_YEAR)}</div>
      </React.Fragment>
    );
  }

  setReminder = (e: SyntheticEvent) => {
    e.preventDefault();
    if (this.props.onSetReminder && this.props.webinar) {
      this.props.onSetReminder(this.props.webinar);
    }
  };
}

export default withLocalize<IAdditionalCardElementsOwnProps>(AdditionalCardElements as any);
