import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { Button, Col, Row } from 'antd';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------
export interface IAdminCodebookListViewOwnProps {
}

type IAdminCodebookListViewProps = IAdminCodebookListViewOwnProps & IWithLocalizeOwnProps & WithRouterProps;

interface IAdminCodebookListViewState {
}

enum CodebookEnum {
  GRADE = 'grade',
  EDUCATION_QUALIFICATION = 'educationqualification',
  LOCATION = 'location',
  SKILL_LEVEL = 'skilllevel',
  ENROLLMENT_REQUIREMENT = 'enrollmentrequirement',
  EDUCATION_SECTOR = 'educationsector',
  EDUCATION_CATEGORY = 'educationcategory',
}

// -- Component
// ----------

/** Display list of codebook edit pages */
class AdminCodebookListView extends React.Component<IAdminCodebookListViewProps, IAdminCodebookListViewState> {
  state: IAdminCodebookListViewState = {};

  render = () => {
    return (
      <React.Fragment>
        {Object.keys(CodebookEnum).map((item: string) => {
          return (
            <Row justify="space-between" className="panel" key={CodebookEnum[item as keyof typeof CodebookEnum]}>
              <Col>
                {this.props.translate(`CODEBOOK.TITLE.${item}`)}
              </Col>
              <Col>
                <Button onClick={() => this.setPath(CodebookEnum[item as keyof typeof CodebookEnum])}> {this.props.translate('COMMON.ACTION_EDIT')} </Button>
              </Col>
            </Row>
          );
        })}
      </React.Fragment>
    );
  };

  setPath = (path: string) => {
    return this.props.router.push(`administration/codebooks/${path}`);
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<IAdminCodebookListViewOwnProps>(withRouter(AdminCodebookListView as any));
