import AppContent from '@src/components/common/container/AppContent';
import LemonAvatar from '@src/components/common/image/LemonAvatar';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import ViewSectionContentItem from '@src/components/common/section/ViewSectionContentItem';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import { IEducationProvider } from '@src/model/educationprovider/EducationProvider';
import { ContactDataTypeEnum, IContactData } from '@src/model/user/ContactData';
import { Col, Row } from 'antd';
import React, { useCallback } from 'react';
import { withRouter, WithRouterProps } from 'react-router';

interface IEducationProviderViewSectionOwnProps {
  educationProvider: IEducationProvider;
}
type IEducationProviderViewSectionProps = IEducationProviderViewSectionOwnProps & IWithLocalizeOwnProps & IWithPathResolverOwnProps & WithRouterProps;

// -- Component
// ----------

/** Display education provider info section */
const EducationProviderViewSection: React.FC<IEducationProviderViewSectionProps> = (props: IEducationProviderViewSectionProps) => {
  const educationProviderPath = `organizations/${props.educationProvider.id}`;
  const getContactEmail = useCallback((contactData: IContactData[]) => {
    const email = contactData.find((data) => data.type.id === ContactDataTypeEnum.EMAIL);
    if (email) {
      return email.value;
    } else {
      return props.translate('REQUEST.USER_VIEW_SECTION.EMAIL_MISSING_LABEL');
    }
  }, []);

  return (
    <AppContent title={
      <HeaderTitle link={educationProviderPath} title={props.translate('EDUCATION_PROVIDER.VIEW_SECTION.HEADER_TITLE')} icon={<LemonIcon name="educationProvider" size="large" />} />
    }>
      <Row>
        <Col xs={24} md={12}>
          <ViewSectionContentItem icon={<LemonAvatar size="small" fallbackIconName="educationProvider" imagePath={props.educationProvider.logoUrl} />} title={props.educationProvider.company.name} description={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_PROVIDER_NAME_LABEL')} />
          <ViewSectionContentItem icon={<LemonIcon name="address" size="xlarge" />} title={`${props.educationProvider.company.address.streetWithHomeNumber}, ${props.educationProvider.company.address.city}`} description={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_PROVIDER_ADDRESS_LABEL')} />
        </Col>
        <Col xs={24} md={12}>
          <ViewSectionContentItem icon={<LemonIcon name="star" size="xlarge" />} title={props.educationProvider.company.identificationNumber} description={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_PROVIDER_IDENTIFICATION_NUMBER_LABEL')} />
          <ViewSectionContentItem icon={<LemonIcon name="mail" size="xlarge" />} title={getContactEmail(props.educationProvider.company.contactData)} description={props.translate('EXTERNAL_EDUCATION_TEMPLATE.VIEW_SECTION.EDUCATION_PROVIDER_EMAIL_LABEL')} />
        </Col>
      </Row>
    </AppContent >
  );
};

export default withLocalize<IEducationProviderViewSectionOwnProps>(withPathResolver(withRouter(EducationProviderViewSection as any)));
