import { Card } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

import AppContent from '@src/components/common/container/AppContent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ChangePasswordForm from '@src/components/password/change/ChangePasswordForm';
import { IUserInfo } from '@src/model/user/User';
import { IChangePassword } from '@src/service/business/login/IPassword';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import UserBusinessStore from '@src/service/business/user/UserBusinessStore';
import { createActionThunk } from '@src/service/util/action/thunk';
import { IActionThunkMap } from '@src/service/util/action/thunk';

interface IChangePasswordContainerOwnProps { }
interface IChangePasswordContainerStateProps {
  currentUser: IUserInfo;
}
interface IChangePasswordContainerDispatchProps {
  onChangePassword: (data: IChangePassword, callback: IActionThunkMap) => any;
}
type IChangePasswordContainerProps = IChangePasswordContainerOwnProps & IChangePasswordContainerStateProps & IChangePasswordContainerDispatchProps & IWithLocalizeOwnProps;

interface IChangePasswordContainerState {
  isChangeDone: boolean;
}

class ChangePasswordContainer extends React.Component<IChangePasswordContainerProps, IChangePasswordContainerState> {
  state = {
    isChangeDone: false,
  };

  render = () => {
    return <AppContent title={this.props.translate('CHANGE_PASSWORD.VIEW_TITLE')}>{this.state.isChangeDone ? <Card className="lemon-changePassword__changePasswordInfo indent text-center">{this.props.translate('CHANGE_PASSWORD.CHANGE_DONE')}</Card> : <ChangePasswordForm onSubmit={this.doChangePassword} />}</AppContent>;
  };

  private doChangePassword = (oldPassword: string, newPassword: string) => {
    const data = {
      id: this.props.currentUser.id,
      oldPassword,
      newPassword,
    };

    this.props.onChangePassword(data, {
      success: () => {
        this.setState({ isChangeDone: true });
      },
    });
  };
}

const mapStateToProps = (state: any, ownProps: IChangePasswordContainerOwnProps): IChangePasswordContainerStateProps => ({
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
});

const mapDispatchToProps = (dispatch: any): IChangePasswordContainerDispatchProps => ({
  onChangePassword: (data: IChangePassword, callback: IActionThunkMap) => dispatch(createActionThunk(UserBusinessStore.actions.doChangePassword(data), callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(ChangePasswordContainer as any));
