import AppConfigService from '@src/service/common/AppConfigService';
import { CookieManager } from '@src/service/util/CookieManager';
import { DomainUtils } from '@src/service/util/DomainUtils';

/** Exposes static methods for authentication token management. */
export default class AuthTokenManager {
  /** Save authentication token. */
  static saveToken(token: string) {
    const webappDomainWithoutSubdomains = DomainUtils.extractDomainWithoutSubs();

    // NOTE: auth cookie has to be removed with same params it was added on login
    CookieManager.setCookie({
      // tslint:disable-next-line: no-duplicate-string
      name: AppConfigService.getValue('authentication.token.name'),
      value: token,
      expires: AppConfigService.getValue('Config.authentication.token.duration') ? new Date(Date.now() + AppConfigService.getValue('authentication.token.duration')) : undefined,
      path: '/',
      domain: webappDomainWithoutSubdomains,
    });
  }

  /** Delete authentication token (eg. on logout). */
  static deleteToken() {
    const webappDomainWithoutSubdomains = DomainUtils.extractDomainWithoutSubs();

    CookieManager.deleteCookie({
      name: AppConfigService.getValue('authentication.token.name'),
      path: '/',
      domain: webappDomainWithoutSubdomains,
    });
  }

  /** Returns authentication token. */
  static getToken(): string {
    return CookieManager.getCookie(AppConfigService.getValue('authentication.token.name'));
  }
}
