import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import MemberList from '@src/components/common/member/MemberList';
import { IUserGroupMember } from '@src/model/usergroup/UserGroupMember';
import { IUserGroupMemberRole, UserGroupMemberRoleEnum } from '@src/model/usergroup/UserGroupMemberRole';
import { ICollectionData } from '@src/service/business/common/types';
import React from 'react';

// -- Prop types
// ----------

export interface IUserGroupMemberListOwnProps {
  userGroupMemberList: ICollectionData<IUserGroupMember>;
  onPageChange?: (page?: number | undefined, size?: number | undefined) => void;
}
type IUserGroupMemberListProps = IUserGroupMemberListOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

class UserGroupMemberList extends React.Component<IUserGroupMemberListProps> {

  roleTypes = Object.entries(UserGroupMemberRoleEnum).map(([key, value]) => {
    return { value, label: this.props.translate(`USER_GROUP_MEMBER_LIST.ROLE_TYPE.${key}`) };
  });

  render = () => {
    return (
      <MemberList<IUserGroupMemberRole>
        defaultListType="card"
        memberList={this.props.userGroupMemberList}
        roleTypes={this.roleTypes}
        onPageChange={this.props.onPageChange}
        canAddMembers={false} // TODO: set user group member actions
        canEditMember={() => false} // TODO: set user group member actions
      />
    );
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<IUserGroupMemberListOwnProps>(UserGroupMemberList as any);
