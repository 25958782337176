import EntityApiService from '@src/service/api/registry/entity/EntityApiService';
import RestEntityApi from '@src/service/api/registry/entity/RestEntityApi';
import AuthHttpRestClient from '@src/service/api/rest/AuthHttpRestClient';
import HttpRestClient from '@src/service/api/rest/HttpRestClient';
import ApiServiceRegistry from '@src/service/util/api/ApiServiceRegistry';
import IAbstractEntityApi from '@src/service/util/api/IAbstractEntityApi';
import IApiServiceRegistry from '@src/service/util/api/IApiServiceRegistry';

/** Interface for API service factory functions. */
export type EntityApiServiceFactoryFn = (entityName: string, api: IAbstractEntityApi<any, any>) => EntityApiServiceRegistry;

/**
 * Main class for API services management. Exposes static methods for service registration and resolving API services.
 * This class could be base for creating service context.
 */
export default class EntityApiServiceRegistry {
  /** Get registered service for given name. */
  static getService(name: string): EntityApiService<any> {
    return EntityApiServiceRegistry.registry.getService(name, true);
  }

  /** Register service for public REST API. */
  static registerService(name: string, entityName?: string, factoryFn?: EntityApiServiceFactoryFn): void {
    EntityApiServiceRegistry.__registerService(name, entityName, factoryFn, false);
  }

  /** Register service for authenticated REST API. Includes authentication header with token in each API call. */
  static registerAuthService(name: string, entityName?: string, factoryFn?: EntityApiServiceFactoryFn): void {
    EntityApiServiceRegistry.__registerService(name, entityName, factoryFn, true);
  }

  private static registry: IApiServiceRegistry = new ApiServiceRegistry();

  // ---------- private

  private static __registerService(name: string, entityName: string | undefined | null, factoryFn: EntityApiServiceFactoryFn | undefined | null, authenticate: boolean): void {
    const serviceEntityName = entityName != null ? entityName : name;
    const service = factoryFn != null ? EntityApiServiceRegistry.createServiceWithFactory(serviceEntityName, factoryFn, authenticate) : EntityApiServiceRegistry.createService(serviceEntityName, authenticate);

    console.log(`Register service ${name}, ${serviceEntityName}`);
    EntityApiServiceRegistry.registry.registerService(name, service);
  }

  private static createService(entityName: string, authenticated: boolean) {
    return new EntityApiService(entityName, EntityApiServiceRegistry.createApi(authenticated));
  }

  private static createServiceWithFactory(entityName: string, factoryFn: EntityApiServiceFactoryFn, authenticate: boolean) {
    return factoryFn(entityName, EntityApiServiceRegistry.createApi(authenticate));
  }

  private static createApi(authenticate: boolean): RestEntityApi<string, any> {
    if (authenticate) {
      return new RestEntityApi(new AuthHttpRestClient(), true);
    } else {
      return new RestEntityApi(new HttpRestClient(), false);
    }
  }
}
