import { IQuiz } from '@lamarodigital/quizzler-lib-frontend/model/quiz/Quiz';

export interface ISurveyTemplate {
  collection: IQuiz;
  id: string;
  title: string;
  instructions?: string;
  type: ISurveyType;
}

export interface ISurveyType {
  descritption: string;
  id: SurveyTypeEnum;
  name: string;
}

export enum SurveyTypeEnum {
  USER_EDUCATION_COMPLETED = '1',
  USER_EDUCATION_COMPLETED_CHECK_IN = '2', //user check in after 6 months
  EDUCATION_PROVIDER = '3',
  EMPLOYER = '4', //employer get survey after 6 months
}
