import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import { Tabs } from 'antd';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

// -- Types&Interfaces
// ----------

export enum ReportsNavigationMenuKeys {
  Courses = '/reports/courses',
  Users = '/reports/users',
  Reports = '/reports/reports',
  EducationApplication = '/reports/educationapplication',
  UserActivities = '/reports/useractivities',
}

// -- Prop types
// ----------
export interface IReportsNavigationPublicProps {
}

type IReportsNavigationProps = IReportsNavigationPublicProps & IWithLocalizeOwnProps & WithRouterProps & IWithTenantPropEnabledOwnProps;

interface IReportsNavigationState {
  selectedKey: string;
}

// --
// ----- Component
class ReportsNavigation extends React.Component<IReportsNavigationProps, IReportsNavigationState> {
  state: IReportsNavigationState = {
    selectedKey: this.props.location.pathname,
  };

  componentDidUpdate(prevProp: IReportsNavigationProps) {
    if (this.props.location !== prevProp.location) {
      this.changeSelectedKey();
    }
  }

  render() {
    return (
      <div>
        <Tabs onChange={this.handleChange} activeKey={this.state.selectedKey} data-test-id="timun-reportsNavigation">
          {this.props.isTenantPropEnabled('courses') && <Tabs.TabPane tab={this.props.translate('REPORTS_NAVIGATION.COURSES_MENU_LABEL')} key={ReportsNavigationMenuKeys.Courses} data-test-id="ltimun-reportsNavigation__itemCourses"/>}
          {this.props.isTenantPropEnabled('courses') && <Tabs.TabPane tab={this.props.translate('REPORTS_NAVIGATION.USERS_MENU_LABEL')} key={ReportsNavigationMenuKeys.Users} data-test-id="timun-reportsNavigation__itemUsers"/>}
          <Tabs.TabPane tab={this.props.translate('REPORTS_NAVIGATION.REPORTS_MENU_LABEL')} key={ReportsNavigationMenuKeys.Reports} data-test-id="timun-reportsNavigation__reports"/>
          {this.props.isTenantPropEnabled('externaleducationapplications') && <Tabs.TabPane tab={this.props.translate('REPORTS_NAVIGATION.EDUCATION_APPLICATION_MENU_LABEL')} key={ReportsNavigationMenuKeys.EducationApplication} data-test-id="timun-reportsNavigation__reports"/>}
          {this.props.isTenantPropEnabled('admin_activities') && <Tabs.TabPane tab={this.props.translate('ADMINISTRATION_NAVIGATION.USER_ACTIVITIES_MENU_LABEL')} key={ReportsNavigationMenuKeys.UserActivities}/>}
        </Tabs>
      </div>
    );
  }

  handleChange = (key: string) => {
    this.props.router.push(key);
  };

  changeSelectedKey = () => {
    const selectedKey = this.props.location.pathname;
    if (selectedKey) {
      this.setState({ selectedKey });
    }
  };
}

export default withLocalize<IReportsNavigationPublicProps>(withRouter(withTenantPropEnabled(ReportsNavigation) as any));
