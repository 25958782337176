import { Form } from '@ant-design/compatible';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { EmailTemplateTypeEnum, emailTemplateTypeEnum } from '@src/model/emailtemplate/EmailTemplate';
import { IEmailTemplateListFilter } from '@src/service/business/emailtemplates/EmailTemplateBusinessStore';

import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Col, Row, Select } from 'antd';
import React from 'react';

// -- Const
// ----------
const emailTemplateTypes = Object.keys(emailTemplateTypeEnum).map((key) => {
  return {
    name: key,
    id: emailTemplateTypeEnum[key],
  };
});

const ALL_EMAIL_TEMPLATE_TYPES = '';

// -- Prop types
// ----------
interface IEmailTemplateFilterOwnProps {
  onFilterChange: (filter: IEmailTemplateListFilter) => void;
  filter?: IEmailTemplateListFilter;
}

type IEmailTemplateFilterProps = IEmailTemplateFilterOwnProps & IWithLocalizeOwnProps & FormComponentProps;

// -- Component
// ----------
class EmailTemplateFilter extends React.Component<IEmailTemplateFilterProps> {
  render = () => {
    const { getFieldDecorator } = this.props.form;
    return <Form>
      <Row gutter={12} >
        <Col xs={24} md={12}>
          <Form.Item label={this.props.translate('COMMON.ACTION_FILTER')}>
            {getFieldDecorator('emailTemplateType', {
              initialValue: this.props.filter?.type ?? ALL_EMAIL_TEMPLATE_TYPES,
              trigger: 'onSelect',
            })(
              <Select
                placeholder={this.props.translate('EMAIL_TEMPLATE_FILTER.ALL_TYPES_LABEL')}
                onChange={this.handleChange}
              >
                <Select.Option value={ALL_EMAIL_TEMPLATE_TYPES}>
                  {this.props.translate('EMAIL_TEMPLATE_FILTER.ALL_TYPES_LABEL')}
                </Select.Option>
                {
                  emailTemplateTypes.map((type) => {
                    return <Select.Option key={type.id} value={type.id}>
                      {this.props.translate(`EMAIL_TEMPLATE_FORM.${type.name}_TYPE`)}
                    </Select.Option>;
                  })
                }
              </Select>
            )
            }
          </Form.Item>
        </Col>
      </Row>
    </Form>;
  };

  handleChange = (emailTemplateType: EmailTemplateTypeEnum) => {
    if (this.props.onFilterChange) {
      this.props.onFilterChange({ type: emailTemplateType });
    }
  };

}

const EmailTemplateFilterForm = Form.create()(EmailTemplateFilter);

// -- HOCs and exports
// ----------

export default withLocalize<IEmailTemplateFilterOwnProps>(EmailTemplateFilterForm as any);
