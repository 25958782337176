import { IUserInfo } from '@src/model/user/User';
import { IWorkPosition } from '@src/model/user/WorkPosition';

export function isUserInfo(arg: IWorkPosition | IUserInfo): arg is IUserInfo {
  return 'firstName' in arg;
}

export function isWorkPosition(arg: IWorkPosition | IUserInfo): arg is IWorkPosition {
  return 'skillLevelClassifications' in arg;
}
