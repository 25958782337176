import React from 'react';

import { CrmEditorConfig } from '@src/components/common/editor/CrmEditorConfigs';
import { Editor } from '@src/components/common/editor/Editor';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IExamTemplate } from '@src/model/education/ExamTemplate';

import { Checkbox, Form, InputNumber, Modal } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

// -- Prop types
// ----------
export interface IExamTemplateUpdateModalOwnProps {
  examTemplate: IExamTemplate;
  onSubmit?: (updatedExamTemplate: IExamTemplate) => void;
  onCancel?: () => void;
}

type IExamTemplateUpdateModalProps = IExamTemplateUpdateModalOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Describe your component ... */
const ExamTemplateUpdateModal: React.FC<IExamTemplateUpdateModalProps> = (props: IExamTemplateUpdateModalProps) => {
  const [form] = Form.useForm<IExamTemplate>();

  const maxNumberOfQuestions = props.examTemplate.collection?.questions?.length ?? 0;

  const handleSubmit = (values: IExamTemplate) => {
    props.onSubmit?.(values);
  };

  return (
    <Modal
      maskClosable={false}
      title={props.translate('EXAM_TEMPLATE.MODAL_TITLE', { examTemplateTitle: props.examTemplate.title })}
      visible={true}
      className="lemon-modal__modal--md"
      onOk={form.submit}
      onCancel={props.onCancel}
      okText={props.translate('COMMON.ACTION_SAVE')}
      cancelText={props.translate('COMMON.ACTION_CANCEL')}
    >
      <Form<IExamTemplate>
        form={form}
        name="examTemplate"
        layout="vertical"
        initialValues={props.examTemplate}
        onFinish={handleSubmit}>

        {/* Exam template Instruction text area */}
        <FormItem name="instructions"
          label={props.translate('EXAM_TEMPLATE.MODAL_INSTRUCTION_TITLE')}>

          <Editor
            init={{
              ...CrmEditorConfig.defaultConfig(),
              height: 500,
              menubar: true,
            }} />
        </FormItem>

        {/* Input for number of questions in exam template settings*/}
        <FormItem name={['settings', 'numberOfQuestions']}
          label={props.translate('EXAM_TEMPLATE.MODAL_SETTINGS_NUMBER_OF_QUESTIONS_TITLE')}>
          <InputNumber min={0} max={maxNumberOfQuestions} />
        </FormItem>

        {/* Checkbox for displaying answers in exam template settings */}
        <FormItem name={['settings', 'answersVisible']}
          label={props.translate('EXAM_TEMPLATE.MODAL_SETTINGS_ANSWERS_VISIBLE_TITLE')}
          valuePropName="checked">
          <Checkbox />
        </FormItem>

        {/* Checkbox for setting evaluation phase in exam template settings */}
        <FormItem name={['settings', 'skipEvaluationPhase']}
          label={props.translate('EXAM_TEMPLATE.MODAL_SETTINGS_SKIP_EVALUATION_TITLE')}
          valuePropName="checked">
          <Checkbox />
        </FormItem>

      </Form>
    </Modal >
  );
};

export default withLocalize<IExamTemplateUpdateModalOwnProps>(ExamTemplateUpdateModal as any);
