import AppContent from '@src/components/common/container/AppContent';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import TagContainer from '@src/components/tag/TagContainer';
import WebinarViewWebinarContent from '@src/components/webinar/view/WebinarViewWebinarContent';
import { TagItemTypeEnum } from '@src/model/tag/TagItemType';
import { IUserInfo } from '@src/model/user/User';
import { IWebinar } from '@src/model/webinar/Webinar';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import webinarViewBusinessStore from '@src/service/business/webinars/webinarViewBusinessStore';
import { Layout, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

const { Header, Content } = Layout;

interface IWebinarViewContainerOwnProps {
  webinarId: string;
}

interface IWebinarViewContainerStateProps {
  webinar: IWebinar;
  currentUser: IUserInfo;
}

interface IWebinarViewContainerDispatchProps {
  fetchWebinar: (id: string) => any;
  clearWebinarData: () => void;
}

type IWebinarViewContainerProps = IWebinarViewContainerOwnProps & IWebinarViewContainerStateProps & IWebinarViewContainerDispatchProps & IWithLocalizeOwnProps;

class WebinarViewContainer extends React.Component<IWebinarViewContainerProps, any> {
  componentDidMount() {
    this.fetchWebinar();
  }

  componentDidUpdate(prevProps: IWebinarViewContainerProps) {
    if (this.props !== prevProps && this.props.webinarId !== prevProps.webinarId) {
      this.fetchWebinar();
    }
  }

  componentWillUnmount() {
    this.props.clearWebinarData();
  }

  render() {
    return (
      <AppContent>
        {this.props.webinar && (
          <Layout>
            <Header className="timun-webinarView__webinarTitle">
              <HeaderTitle link={'/webinars'} title={this.props.translate('WEBINAR_LIST.VIEW_TITLE')} icon={<LemonIcon name="left" />} />
              <Typography.Title> {this.props.webinar.title} </Typography.Title>
              <TagContainer entityId={this.props.webinarId} entityType={TagItemTypeEnum.WEBINAR} tags={this.props.webinar?.tags && this.props.webinar.tags} onEntityTagUpdate={this.fetchWebinar} />
            </Header>
            <Layout>
              <Content className="timun-webinarView__content">
                &nbsp;
                <WebinarViewWebinarContent
                  webinar={this.props.webinar}
                  onWebinarRedirect={this.handleWebinarRedirect}
                  currentUser={this.props.currentUser}
                />
              </Content>
            </Layout>
          </Layout>
        )}
      </AppContent>
    );
  }

  handleWebinarRedirect = () => {
    const win = window.open(this.props.webinar.link, '_blank');
    if (win) {
      win.focus();
    }
  };

  private fetchWebinar = (webinarId = this.props.webinarId) => {
    this.props.fetchWebinar(webinarId);
  };
}

const mapStateToProps = (state: any, ownProps: IWebinarViewContainerOwnProps): IWebinarViewContainerStateProps => ({
  webinar: webinarViewBusinessStore.selectors.getWebinar(state),
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
});

const mapDispatchToProps = (dispatch: Dispatch): IWebinarViewContainerDispatchProps => ({
  fetchWebinar: (webinarId: string) => dispatch(webinarViewBusinessStore.actions.fetchWebinar({ id: webinarId })),
  clearWebinarData: () => dispatch(webinarViewBusinessStore.actions.clearWebinarData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<IWebinarViewContainerOwnProps>(WebinarViewContainer as any));
