import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ViewSectionItem from '@src/components/common/section/ViewSectionItem';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import { IColumnChartData, TimunColumnChart } from '@src/components/externalEducationApplication/statistics/charts/TimunColumnChart';
import { IAveragePerExpenseType } from '@src/model/externalEducationApplication/ExternalEducationApplicationStatistics';
import NumberFormatService from '@src/service/util/numberformat/NumberFormatService';
import React from 'react';

interface IEducationApplicationStatisticsExpensesOwnProps {
  averagePerExpenseTypes: IAveragePerExpenseType[];
}

type IEducationApplicationStatisticsExpensesProps = IEducationApplicationStatisticsExpensesOwnProps & IWithLocalizeOwnProps;

const EducationApplicationStatisticsExpenses = (props: IEducationApplicationStatisticsExpensesProps) => {
  const expensesByTypeData: IColumnChartData[] = [];
  props.averagePerExpenseTypes.map((averageExpenseType: IAveragePerExpenseType) => expensesByTypeData.push({
    label: props.translate(`EXTERNAL_EDUCATION_APPLICATION.EXPENSE.${averageExpenseType.type.name}`),
    value: NumberFormatService.roundNumberCurrency(averageExpenseType.average),
  }));
  return (
    <ViewSectionItem
      header={<HeaderTitle title={props.translate('EXTERNAL_EDUCATION_APPLICATION_STATISTICS.STATISTICS_AVERAGE_EXPENSES_TITLE')} icon={<LemonIcon name="coins" size="large" />} />}
      collapsible={false}
      active={true}
      bordered={true}
    >
      <TimunColumnChart data={expensesByTypeData} />
    </ViewSectionItem>
  );
};

export default withLocalize<IEducationApplicationStatisticsExpensesOwnProps>(EducationApplicationStatisticsExpenses as any);
