import React from 'react';

import { ITimunFile } from '@src/components/common/file/FileUtils';
import LemonImage from '@src/components/common/image/LemonImage';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import UrlBuilderFactory from '@src/service/util/UrlBuilderFactory';
import { Modal } from 'antd';
import DocViewer, { PDFRenderer } from 'react-doc-viewer';

// -- Prop types
// ----------

export interface IFilePreviewModalOwnProps {
  record: ITimunFile;
  closeModal: () => void;
}
type IFilePreviewModalProps = IFilePreviewModalOwnProps & IWithLocalizeOwnProps & IWithPathResolverOwnProps;

interface IFilePreviewModalState { }

// -- Component
// ----------

/** Modal for file preview ... */
class FilePreviewModal extends React.Component<IFilePreviewModalProps, IFilePreviewModalState> {
  getItemLink = (record: ITimunFile) => {
    return (
      <a href={this.props.resolveApiFilePath(record.uid)} download={record.name}>
        {record.name}
      </a>
    );
  };

  render = () => {
    const fileUrl = this.props.record.url || UrlBuilderFactory.buildApiFileUrl(this.props.record.uid);
    return (
      <React.Fragment>
        <Modal title={this.getItemLink(this.props.record)} visible={true} mask={true} centered={true} footer={null} onCancel={this.props.closeModal} transitionName="fade" className="timun-courseViewFileList__previewImageModal">
          {this.props.record.type?.startsWith('image/') &&
            <LemonImage className="timun-courseView__uploadedImageContent" imagePath={fileUrl} />
          }
          {this.props.record.type?.startsWith('video/') &&
            <video className="timun-courseView__uploadedVideoContent" controls={true} src={fileUrl} />
          }
          {this.props.record.type?.startsWith('audio/') &&
            <audio controls={true} src={fileUrl} />
          }
          {this.props.record.type?.includes('pdf') &&
            <DocViewer
              className="timun-courseViewFileList__previewPDF"
              documents={[
                {
                  uri: fileUrl,
                  fileType: this.props.record.type,
                },
              ]}
              pluginRenderers={[PDFRenderer]}
              config={{
                header: {
                  disableHeader: true,
                },
              }} />
          }
        </Modal>
      </React.Fragment>
    );
  };
}

export default withLocalize<IFilePreviewModalOwnProps>(withPathResolver(FilePreviewModal as any));
