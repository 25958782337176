import DataPicker, { IDataPickerItem } from '@src/components/common/datapicker/DataPicker';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import CollectionBusinessStore from '@src/service/business/common/collectionBusinessStore';
import { ICollectionData } from '@src/service/business/common/types';
import AppConfigService from '@src/service/common/AppConfigService';
import { LangUtils } from '@src/service/util/LangUtils';
import React from 'react';
import { connect } from 'react-redux';

const minSearchLength = AppConfigService.getValue('components.common.minSearchStringLength');

// -- Prop types
// ----------

export interface IUserDataPickerOwnProps {
  value?: IWorkPosition;
  onChange?: (newValue?: IWorkPosition) => void;
  placeholder?: string;
  disabled?: boolean;
}

export interface IUserDataPickerStateProps {
  workPositions: ICollectionData<IWorkPosition>;
}

export interface IUserDataPickerDispatchProps {
}

type IUserDataPickerProps = IUserDataPickerOwnProps & IUserDataPickerStateProps & IUserDataPickerDispatchProps;

interface IUserDataPickerState {
  workPositions?: IWorkPosition[];
}

// -- Component
// ----------

/** User data picker by or without userRole */
class UserDataPicker extends React.Component<IUserDataPickerProps, IUserDataPickerState> {
  state: IUserDataPickerState = {
    workPositions: this.props.workPositions?.content,
  };

  render = () => {
    const pickerValue = this.props.value && this.mapToItem(this.props.value);
    const pickerItems = this.state.workPositions != null ? this.state.workPositions.map(this.mapToItem) : pickerValue ? [pickerValue] : [];

    return <DataPicker placeholderSuffix={this.props.placeholder} value={pickerValue} items={pickerItems} disabled={this.props.disabled} onChange={this.handleDataPickerChange} onSearch={this.handleDataPickerSearch} dataTestIdPrefix={`timun-workPositionPicker`}/>;
  };

  private handleDataPickerChange = (value?: IDataPickerItem) => {
    if (this.props.onChange) {
      this.props.onChange(value?.data);
    }
  };

  private handleDataPickerSearch = (value: string) => {
    if (value.length >= minSearchLength) {
      this.searchList(value);
    } else if (LangUtils.isEmpty(value) || value.length < minSearchLength) {
      this.storeList(this.props.workPositions?.content);
    }
  };

  /** Map entity ref list to data picker item list. */
  private mapToItem(workPosition: IWorkPosition): IDataPickerItem {
    return {
      value: workPosition.id,
      name: workPosition.name,
      data: workPosition,
    };
  }

  private searchList(search: string) {
    this.storeList(this.props.workPositions?.content.filter((workPos) => workPos.name.toUpperCase().includes(search.toUpperCase())));
  }

  private storeList = (workPositions?: IWorkPosition[]) => {
    this.setState({ workPositions });
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IUserDataPickerOwnProps): IUserDataPickerStateProps => ({
  workPositions: CollectionBusinessStore.selectors.getCollection(state, 'WorkPosition'),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IUserDataPickerDispatchProps => ({});

export default connect<IUserDataPickerStateProps, IUserDataPickerDispatchProps, IUserDataPickerOwnProps>(mapStateToProps, mapDispatchToProps)(UserDataPicker as any);
