import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import { NoteObjectTypeEnum } from '@src/model/user/Note';
import { INoteListFileCreatePayload } from '@src/service/business/usernotes/userNoteBusinessStore';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button } from 'antd';

// -- Prop types
// ----------
export interface INoteListExportButtonOwnProps {
  onExport: (noteListRefObject: INoteListFileCreatePayload) => void;
  noteListFileId?: string;
  noteListId: string;
}

// -- State types
// ----------
type NoteListExportButtonProps = INoteListExportButtonOwnProps & IWithLocalizeOwnProps & IWithPathResolverOwnProps;

export interface INoteListExportButtonState { }

// -- Component
// ----------

/** Component display two state button one for export and one for download */
class NoteListExportButton extends React.Component<NoteListExportButtonProps, INoteListExportButtonState> {
  state: INoteListExportButtonState = {};

  render() {
    return (
      <React.Fragment>
        {/* Display note list export button */}
        {this.props.noteListFileId ?
          <Button
            icon={<LegacyIcon type={'download'} />}
            href={this.props.resolveApiFilePath(this.props.noteListFileId)}>
            {this.props.translate('COMMON.ACTION_DOWNLOAD')}
          </Button>
          :
          <Button
            onClick={this.onExport}>{this.props.translate('NOTE_LIST.EXPORT_BUTTON_LABEL')}
          </Button>
        }
      </React.Fragment>
    );
  }

  onExport = () => {
    const noteListRefObject: INoteListFileCreatePayload = {
      type: { id: NoteObjectTypeEnum.COURSE },
      object: { id: this.props.noteListId },
    };
    this.props.onExport(noteListRefObject);
  }
}
// -- HOCs and exports
// ----------
export default withLocalize<INoteListExportButtonOwnProps>(withPathResolver(NoteListExportButton as any));
