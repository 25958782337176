import React from 'react';

import AppContent from '@src/components/common/container/AppContent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ITimunDataTableCol } from '@src/components/common/table/TimunDataTableCol';
import ExternalEducationTemplateTable from '@src/components/externaleducation/list/ExternalEducationTemplateTable';
import { IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { ICollectionData } from '@src/service/business/common/types';
import { IExternalEducationTemplateListFilter } from '@src/service/business/externaleducations/ExternalEducationTemplateListBusinessStore';
import { Row } from 'antd';


// -- Prop types
// ----------

export interface ICoordinatorExternalEducationTemplateListViewOwnProps {
  externalEducationTemplateList: ICollectionData<IExternalEducationTemplate>;
  onPageChange?: (page: number, pageSize?: number) => void;
  additionalCols?: () => Array<ITimunDataTableCol<IExternalEducationTemplate>>;
  externalEducationTemplateFilter?: IExternalEducationTemplateListFilter;
  showFilter?: boolean;
}
type ICoordinatorExternalEducationTemplateListViewProps = ICoordinatorExternalEducationTemplateListViewOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Display coordinator external education template table  */
const CoordinatorExternalEducationTemplateListView: React.FC<ICoordinatorExternalEducationTemplateListViewProps> = (props: ICoordinatorExternalEducationTemplateListViewProps) => {

  return (
    <AppContent>
      {props.showFilter && <Row>
        {/* TODO: ADD Filter */}
      </Row>}

      <ExternalEducationTemplateTable
        externalEducationTemplateList={props.externalEducationTemplateList}
        onPageChange={props.onPageChange}
      />
    </AppContent>
  );
};

export default withLocalize<ICoordinatorExternalEducationTemplateListViewOwnProps>(CoordinatorExternalEducationTemplateListView as any);
