import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { Button } from 'antd';
import React from 'react';

export interface IEnrollButtonViewOwnProps {
  onEnroll: () => void;
}
type IEnrollButtonViewProps = IEnrollButtonViewOwnProps & IWithLocalizeOwnProps;

class EnrollButtonView extends React.Component<IEnrollButtonViewProps> {
  render = () => {
    return <Button onClick={this.props.onEnroll}>{this.props.translate('COMMON.ACTION_ENROLL')}</Button>
  }
}

export default withLocalize<IEnrollButtonViewOwnProps>(EnrollButtonView as any);