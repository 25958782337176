import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import OrganizationExternalEducationTemplateListContainer from '@src/components/externaleducation/OrganizationExternalEducationTemplateListContainer';
import OrganizationMemberListContainer from '@src/components/organization/members/OrganizationMemberListContainer';
import { IOrganization } from '@src/model/organization/Organization';
import { OrganizationMemberRoleEnum } from '@src/model/organization/OrganizationMemberRole';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { Tabs } from 'antd';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

enum OrganizationViewContainerTabMenuKeys {
  Members = 'members',
  ExternalEducationTemplates = 'externaleducationtemplates',
}

interface IOrganizationViewTabsOwnProps {
  organization: IOrganization;
  openedTab: string;
}

type IOrganizationViewTabsProps = IOrganizationViewTabsOwnProps & IWithLocalizeOwnProps & WithRouterProps & IWithRolesOwnProps;


class OrganizationViewTabs extends React.Component<IOrganizationViewTabsProps> {

  handleTabChange = (key: string) => {
    this.props.router.push(`/organizations/${this.props.organization.id}/${key}`);
  };

  render = () => {
    const showMembersTab = this.props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.COORDINATOR]) ||
      (this.props.organization.currentUserMember?.role.id === OrganizationMemberRoleEnum.OWNER || this.props.organization.currentUserMember?.role.id === OrganizationMemberRoleEnum.ADMIN);
    return (
      <div className="timun-organizationView__tabContainer">
        <Tabs defaultActiveKey={this.props.openedTab} onChange={this.handleTabChange} destroyInactiveTabPane={true}>
          {showMembersTab &&
            <Tabs.TabPane tab={this.props.translate('ORGANIZATION_VIEW.USERS_TAB_LABEL')} key={OrganizationViewContainerTabMenuKeys.Members}>
              <div className="timun-organizationView__tab">
                <OrganizationMemberListContainer organization={this.props.organization} />
              </div>
            </Tabs.TabPane>
          }
          <Tabs.TabPane tab={this.props.translate('ORGANIZATION_VIEW.EXTERNAL_EDUCATION_TEMPLATES_TAB_LABEL')} key={OrganizationViewContainerTabMenuKeys.ExternalEducationTemplates}>
            <div className="timun-organizationView__tab">
              <OrganizationExternalEducationTemplateListContainer educationProviderStatus={this.props.organization.active} educationProviderId={this.props.organization.id} />
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
    );
  };
}

export default withLocalize<IOrganizationViewTabsOwnProps>(withRoles(withRouter(OrganizationViewTabs as any)));
