import { Button, Col, Row } from 'antd';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

import AppContent from '@src/components/common/container/AppContent';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import AllowedUserRoles from '@src/components/common/role/AllowedUserRoles';
import CourseListView from '@src/components/course/list/CourseListView';
import { ICourse } from '@src/model/course/Course';
import { ICourseGroup } from '@src/model/course/CourseGroup';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { ICollectionData } from '@src/service/business/common/types';
import { ICourseListFilter } from '@src/service/business/courses/courseListBusinessStore';

// -- Prop types
// ----------

interface IPrivateCourseListViewOwnProps {
  courseList: ICollectionData<ICourse>;
  courseListFilter: ICourseListFilter;
  courseGroups: ICourseGroup[];
  sortValue: string[];

  getPath: (id: string, lecture?: string) => string;

  onPageChange: (page: number, size?: number) => void;
  onSortChange: (sort: string[]) => void;
  onFilterChange: (data: ICourseListFilter) => void;
}
type IPrivateCourseListViewProps = IPrivateCourseListViewOwnProps & IWithLocalizeOwnProps & WithRouterProps;
// -- Component
// ----------

class PrivateCourseListView extends React.Component<IPrivateCourseListViewProps> {
  render = () => {
    return (
      <AppContent
        title={
          <Row justify="space-between">
            <Col>{this.props.translate('COURSE_LIST.VIEW_TITLE')}</Col>
            {/* ----- Course list actions ----- */}
            <Col>
              <AllowedUserRoles roles={[UserRoleEnum.COURSE_CREATOR]} fallback="">
                <Button type="primary" onClick={this.handleEditCourseGroups} data-test-id="timun-courseListView__editGroupsButton">
                  {this.props.translate('COURSE_LIST.ACTION_GROUPS_EDIT')}
                </Button>
                &nbsp;
                <Button icon={<LemonIcon name="plus" />} type="primary" onClick={this.handleCreateCourseButtonClick} data-test-id="timun-courseListView__createGroupButton">
                  {this.props.translate('COURSE_LIST.ACTION_CREATE')}
                </Button>
              </AllowedUserRoles>
            </Col>
          </Row>
        }
      >
        {/* ----- Course List ----- */}
        <CourseListView
          courseList={this.props.courseList}
          courseListFilter={this.props.courseListFilter}
          courseGroups={this.props.courseGroups}
          sortValue={this.props.sortValue}
          onSortChange={this.props.onSortChange}
          onPageChange={this.props.onPageChange}
          onFilterChange={this.props.onFilterChange}
          getPath={this.props.getPath}
        />
      </AppContent>
    );
  };

  handleCreateCourseButtonClick = () => {
    // redirect to course creation view
    this.props.router.push('/course/create');
  };

  handleEditCourseGroups = () => {
    // redirect to course group list view
    this.props.router.push('course/groups');
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<IPrivateCourseListViewOwnProps>(withRouter(PrivateCourseListView) as any);
