import AppConfigService from '@src/service/common/AppConfigService';
import { Globals } from '@src/service/util/Globals';
import UrlBuilderFactory from './UrlBuilderFactory';

/**
 * Static router - contains static methods for routing to statically defined pages (default, login, error, ...).
 *
 * This routing helper is NOT using React router but browser's location property!
 */
export class StaticPageRoutingHelper {
  /** Used to transfer requested route while temporarily being redirect somewhere else (eg. to login page if authenticated route is requested). */
  static RETURN_ROUTE_URL_PARAMETER = 'rr';

  /** Routes to app's root route. See app config to see configured values. */
  static routeToApp() {
    const appPath: string = UrlBuilderFactory.createApplicationBuilder().build();

    StaticPageRoutingHelper.route(appPath);
  }

  /** Routes to configured public default route. See app config to see configured values. */
  static routeToPublicDefaultPage() {
    const publicPagePath: string = UrlBuilderFactory.buildAppUrl(AppConfigService.getValue('routing.publicDefaultRoute'));

    StaticPageRoutingHelper.route(publicPagePath);
  }

  /** Routes to configured app's default route. See app config to see configured values. */
  static routeToAuthDefaultPage() {
    const defaultPagePath: string = UrlBuilderFactory.createApplicationBuilder()
      .urlPart(AppConfigService.getValue('routing.authDefaultRoute'))
      .build();

    StaticPageRoutingHelper.route(defaultPagePath);
  }

  /** Routes to configured error route. See app config to see configured values. */
  static routeToErrorPage() {
    const errorPagePath: string = UrlBuilderFactory.buildAppUrl(AppConfigService.getValue('routing.errorPage'));
    console.warn('Trying to route to error page which is not implemented yet!');

    StaticPageRoutingHelper.route(errorPagePath);
  }

  /** Route to given URL */
  static route(url: string) {
    if (Globals.window.location.href !== url) {
      // prevent endless loop
      Globals.window.location.href = url;
    }
  }
}
