import React from 'react';
import { connect } from 'react-redux';

import CodebookShortInfoForm from '@src/components/codebook/form/CodebookShortInfoForm';
import CodeBookListView from '@src/components/codebook/view/CodeBookListView';
import withCollectionState, { IWithCollectionStateOwnProps, IWithCollectionStatePublicProps } from '@src/components/common/collectionParams/withCollectionState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IEducationSector } from '@src/model/educationsector/EducationSector';
import { ICollectionData, ICollectionFetchPayload, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import EducationSectorBusinessStore, { IEducationSectorCreatePayload, IEducationSectorListFilter } from '@src/service/business/educationsector/educationSectorBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

// -- Const
// ----------
const DEFAULT_PAGE_SIZE_VALUE = AppConfigService.getValue('api.collectionDefaultLimit');
const VIEW_NAME = '@@EDUCATION_SECTOR_CODEBOOK';
const collectionDefaults: IWithCollectionStatePublicProps<IEducationSectorListFilter> = {
  viewName: VIEW_NAME,
  initialValues: { size: DEFAULT_PAGE_SIZE_VALUE },
};

// -- Prop types
// ----------
export interface IEducationSectorCodebookContainerOwnProps { }
export interface IEducationSectorCodebookContainerStateProps {
  educationSectorList: ICollectionData<IEducationSector>;
}
export interface IEducationSectorCodebookContainerDispatchProps {
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
  fetchEducationSectorList: (params: ICollectionFetchPayload<IEducationSectorListFilter>) => ITrackableAction;
  createEducationSector: (data: IEducationSectorCreatePayload) => ITrackableAction;
  updateEducationSector: (data: IEducationSector) => ITrackableAction;
}
type IEducationSectorCodebookContainerProps = IEducationSectorCodebookContainerOwnProps & IEducationSectorCodebookContainerStateProps & IEducationSectorCodebookContainerDispatchProps & IWithLocalizeOwnProps & IWithCollectionStateOwnProps<IEducationSectorListFilter>;

interface IEducationSectorCodebookContainerState {
  educationSectorModalVisible: boolean;
  educationSectorToEdit?: IEducationSector;
}

// -- Component
// ----------

/** Coded grade container */
class EducationSectorCodebookContainer extends React.Component<IEducationSectorCodebookContainerProps, IEducationSectorCodebookContainerState> {
  state: IEducationSectorCodebookContainerState = {
    educationSectorModalVisible: false,
    educationSectorToEdit: undefined,
  };

  componentDidMount() {
    this.fetchEducationSectorList();
  }

  componentDidUpdate(prevProps: IEducationSectorCodebookContainerProps, prevState: IEducationSectorCodebookContainerState) {
    if (prevProps.collectionParams !== this.props.collectionParams) {
      this.fetchEducationSectorList();
    }
  }

  render = () => {
    return (
      <React.Fragment>
        {this.props.educationSectorList && <CodeBookListView<IEducationSector>
          showBackColumn={true}
          title={this.props.translate('CODEBOOK.TITLE.EDUCATION_SECTOR')}
          descriptionPrefix={'EDUCATION_SECTOR.DESCRIPTION_LABEL'}
          buttonLabel={this.props.translate('EDUCATION_SECTOR.CREATE_BUTTON_LABEL')}
          codebookItemList={this.props.educationSectorList}
          canEdit={true}
          onEditCodebookItemClick={this.handleEducationSectorUpdateModalOpen}
          onCreateCodebookItemClick={this.handleEducationSectorCreateModalOpen}
          onPageChange={this.props.updateCollectionParams.onPageChange}
        />}

        {this.state.educationSectorModalVisible && <CodebookShortInfoForm<IEducationSector, IEducationSectorCreatePayload>
          value={this.state.educationSectorToEdit}
          onCancel={this.handleCodebookModalCancel}
          onUpdate={this.handleEducationSectorUpdateSubmit}
          onCreate={this.handleEducationSectorCreateSubmit} />}
      </React.Fragment>
    );
  };

  toggleCodebookModal = (isVisible: boolean) => {
    this.setState({ educationSectorModalVisible: isVisible });
  };

  handleCodebookModalCancel = () => {
    this.setState({ educationSectorToEdit: undefined });
    this.toggleCodebookModal(false);
  };

  handleEducationSectorCreateModalOpen = () => {
    this.toggleCodebookModal(true);
  };

  handleEducationSectorUpdateModalOpen = (EducationSector: IEducationSector) => {
    this.setState({ educationSectorToEdit: EducationSector });
    this.toggleCodebookModal(true);
  };

  private handleEducationSectorCreateSubmit = (data: IEducationSectorCreatePayload) => {
    this.props.createEducationSector(data).track().subscribe(
      // success
      () => {
        this.props.reportMessage({ message: this.props.translate('EDUCATION_SECTOR.CREATE_INFO_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
        this.fetchEducationSectorList();
        this.handleCodebookModalCancel();
      }
    );
  };

  private handleEducationSectorUpdateSubmit = (data: IEducationSector) => {
    this.props.updateEducationSector(data).track().subscribe(
      // success
      () => {
        this.fetchEducationSectorList();
        this.handleCodebookModalCancel();
      }
    );
  };

  private fetchEducationSectorList = () => {
    this.props.onUpdateList(this.props.fetchEducationSectorList);
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IEducationSectorCodebookContainerOwnProps): IEducationSectorCodebookContainerStateProps => ({
  educationSectorList: EducationSectorBusinessStore.selectors.getEducationSectorList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IEducationSectorCodebookContainerDispatchProps => ({
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
  fetchEducationSectorList: (params: ICollectionFetchPayload<IEducationSectorListFilter>) => dispatch(createTrackableAction(EducationSectorBusinessStore.actions.fetchEducationSectorList(params))),
  createEducationSector: (data: IEducationSectorCreatePayload) => dispatch(createTrackableAction(EducationSectorBusinessStore.actions.createEducationSector(data))),
  updateEducationSector: (data: IEducationSector) => dispatch(createTrackableAction(EducationSectorBusinessStore.actions.updateEducationSector(data))),
});

export default connect<IEducationSectorCodebookContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withCollectionState(collectionDefaults)(withLocalize(EducationSectorCodebookContainer as any)));
