import GridItem from '@src/components/common/grid/GridItem';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/common/EducationApplicationHelperUtils';
import EducationApplicationUserFileListView from '@src/components/externalEducationApplication/common/EducationApplicationUserFileListView';
import ExternalEducationApplicationFileListUpload from '@src/components/externalEducationApplication/common/ExternalEducationApplicationFileListUpload';
import { IFileListsByType } from '@src/components/externalEducationApplication/common/types';
import ExternalEducationPaymentInfoForm from '@src/components/externalEducationApplication/form/ExternalEducationPaymentInfoForm';
import { ActivityPhaseEnum } from '@src/model/activity/ActivityPhase';

import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { EmploymentStatusEnum, IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { ExternalEducationApplicationPaymentInfoTypeEnum, IExternalEducationApplicationPaymentInfo } from '@src/model/externalEducationApplication/ExternalEducationApplicationPaymentInfo';
import { IFile } from '@src/model/file/File';
import { FileTypeEnum } from '@src/model/file/FileSystemElement';
import { UserRoleEnum } from '@src/model/user/UserRole';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, Col, DatePicker, Row } from 'antd';
import { debounce } from 'lodash';
import moment from 'moment';
import React from 'react';

const dateFormat = AppConfigService.getValue('dateTimeFormat.dateWithYear');
const backendDate = AppConfigService.getValue('dateTimeFormat.backendDate');

const LAYOUT_BUTTONS_1 = {
  sm: 24,
  xs: 24,
  md: 12,
};

// -- Prop types
// ----------
interface IExternalEducationApplicationTraineeApprovalViewOwnProps {
  externalEducationApplication: IExternalEducationApplication;
  applicationFiles: IFileListsByType;
  onUpdate?: (data: IExternalEducationApplication) => void;
  // tslint:disable-next-line: bool-param-default
  onChangePhase?: (newPhase: ActivityPhaseEnum, save?: boolean) => void;
  onFileUpload?: (file: IFile[]) => void;
  onFileRemove?: (file: IFile[]) => void;
  onUpdatePaymentInfo?: (data: IExternalEducationApplicationPaymentInfo) => void;
}

type IExternalEducationApplicationTraineeApprovalViewProps = IExternalEducationApplicationTraineeApprovalViewOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps & FormComponentProps;

interface IExternalEducationApplicationTraineeApprovalViewState { }

// -- Component
// ----------

class ExternalEducationApplicationTraineeApprovalView extends React.Component<IExternalEducationApplicationTraineeApprovalViewProps, IExternalEducationApplicationTraineeApprovalViewState> {
  state: IExternalEducationApplicationTraineeApprovalViewState = {};

  componentDidUpdate = (prevProps: IExternalEducationApplicationTraineeApprovalViewProps, prevState: IExternalEducationApplicationTraineeApprovalViewState) => {
    if (this.props.externalEducationApplication.paymentInfos !== prevProps.externalEducationApplication.paymentInfos) {
      this.props.form.resetFields(['startDateTime']);
    }
  };

  isApplicationInPhases = (phases: ActivityPhaseEnum[]): boolean => {
    return EducationApplicationHelperUtils.isInPhases(phases, this.props.externalEducationApplication.activity);
  };

  handleChangePhase = (phase: ActivityPhaseEnum, save: boolean = false) => {
    if (this.props.onChangePhase) {
      this.props.onChangePhase(phase, save);
    }
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    const canSubmitContractGeneration = (this.props.externalEducationApplication?.employmentStatus?.id === EmploymentStatusEnum.EMPLOYED || this.getUserPaymentInfo()) && this.props.externalEducationApplication.startDateTime;

    return (
      <React.Fragment>
        {this.isApplicationInPhases([ActivityPhaseEnum.USER_CANCELLED]) && (
          <Row>
            <Col>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.APPLICATION_CANCELED_TEXT')}</Col>
          </Row>
        )}

        {this.isApplicationInPhases([ActivityPhaseEnum.DENIED]) && (
          <Row>
            <Col>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.APPLICATION_DENIED_TEXT')}</Col>
          </Row>
        )}

        {/* User cancel application button */}
        {this.isApplicationInPhases([ActivityPhaseEnum.SUBMITTED, ActivityPhaseEnum.AWAITING_APPROVAL, ActivityPhaseEnum.IN_PROCESS]) && (
          <React.Fragment>
            <Row>
              <Col>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.SUBMITED_AND_WAITING_COORDINATOR_TEXT')}</Col>
            </Row>
            <Row>
              <Col {...LAYOUT_BUTTONS_1}>
                {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.CANCEL_APPLICATION_LABEL')}
                &nbsp;
                <Button block={false} onClick={() => this.handleChangePhase(ActivityPhaseEnum.USER_CANCELLED)} data-test-id="timun-externalEducationApplicationView__cancelApplicationButton">
                  {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.CANCEL_APPLICATION_BUTTON_TEXT')}
                </Button>
              </Col>
            </Row>
          </React.Fragment>
        )}

        {this.isApplicationInPhases([ActivityPhaseEnum.CONTRACT_GENERATION]) && (
          <React.Fragment>
            <Row>
              <Col span={24}>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.APPROVED_AND_CONTRACT_IN_GENERATION_TEXT')}</Col>
            </Row>

            <br />

            {this.props.externalEducationApplication?.employmentStatus?.id === EmploymentStatusEnum.UNEMPLOYED && (
              <Row>
                <Col span={24}>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.ENTER_PAYMENT_INFO_TEXT')}</Col>
                <Col span={24}>
                  <ExternalEducationPaymentInfoForm userRole={UserRoleEnum.TRAINEE} externalEducationApplication={this.props.externalEducationApplication} paymentInfo={this.getUserPaymentInfo()} paymentInfoType={ExternalEducationApplicationPaymentInfoTypeEnum.USER} onSubmit={this.props.onUpdatePaymentInfo} />
                </Col>
              </Row>
            )}

            <Row>
              <Col span={24}>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.ENTER_START_DATE_TEXT')}</Col>
              <Col span={24}>
                <Form>
                  <GridItem label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.DETAILS.EDUCATION_START_DATE_TITLE')} infoText={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.START_DATE_INFO')} requiredMark={true}>
                    <Form.Item required={true}>
                      {getFieldDecorator('startDateTime', {
                        initialValue: this.props.externalEducationApplication.startDateTime ? moment(this.props.externalEducationApplication.startDateTime) : undefined,
                        rules: [{ required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') }],
                      })(<DatePicker format={dateFormat} data-test-id="timun-externalEducationApplicationView__startDateTimePicker" />)}
                    </Form.Item>
                  </GridItem>
                </Form>
              </Col>
            </Row>

            <Row>
              <Col {...LAYOUT_BUTTONS_1}>
                {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.GENERATE_CONTRACT_TEXT')}
                &nbsp;
                <Button block={true} onClick={() => this.handleChangePhase(ActivityPhaseEnum.AWAITING_SIGNED_CONTRACT, true)} disabled={!canSubmitContractGeneration} data-test-id="timun-externalEducationApplicationView__generateContractButton">
                  {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.GENERATE_CONTRACT_BUTTON_TEXT')}
                </Button>
              </Col>
              <Col {...LAYOUT_BUTTONS_1}>
                {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.CANCEL_APPLICATION_LABEL')}
                &nbsp;
                <Button block={false} onClick={() => this.handleChangePhase(ActivityPhaseEnum.USER_CANCELLED)} data-test-id="timun-externalEducationApplicationView__cancelApplicationButton">
                  {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.CANCEL_APPLICATION_BUTTON_TEXT')}
                </Button>
              </Col>
            </Row>
          </React.Fragment>
        )}

        {this.isApplicationInPhases([ActivityPhaseEnum.AWAITING_SIGNED_CONTRACT]) && (
          <React.Fragment>
            <Row>
              <Col span={24}>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.CONTRACT_GENERATED_TEXT')}</Col>
            </Row>

            <GridItem label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.FILES.FINANCING_AGREEMENT_LABEL')} infoText={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.CONTRACT_GENERATION_INFO')}>
              <EducationApplicationUserFileListView files={this.props.applicationFiles} displayTypes={[FileTypeEnum.FINANCING_AGREEMENT]} />
            </GridItem>
            <GridItem label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.FILES.SIGNED_FINANCING_AGREEMENT_LABEL')} infoText={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.SIGNED_FINANCING_AGREEMENT_INFO')} requiredMark={true}>
              <ExternalEducationApplicationFileListUpload applicationFiles={this.props.applicationFiles} fileType="SIGNED_FINANCING_AGREEMENT" onFileUpload={this.props.onFileUpload} onFileRemove={this.props.onFileRemove} />
            </GridItem>

            <Row>
              <Col {...LAYOUT_BUTTONS_1}>
                {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.SUBMIT_SIGNED_CONTRACT_TEXT')}
                &nbsp;
                <Button type="primary" block={false} onClick={() => this.handleChangePhase(ActivityPhaseEnum.AWAITING_PAYMENT)} disabled={false} data-test-id="timun-externalEducationApplicationView__submitSignedContractButton">
                  {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.SUBMIT_SIGNED_CONTRACT_BUTTON_TEXT')}
                </Button>
              </Col>
              <Col {...LAYOUT_BUTTONS_1}>
                {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.CANCEL_APPLICATION_LABEL')}
                &nbsp;
                <Button block={false} onClick={() => this.handleChangePhase(ActivityPhaseEnum.USER_CANCELLED)} data-test-id="timun-externalEducationApplicationView__cancelApplicationButton">
                  {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.CANCEL_APPLICATION_BUTTON_TEXT')}
                </Button>
              </Col>
            </Row>
          </React.Fragment>
        )}

        {this.isApplicationInPhases([ActivityPhaseEnum.AWAITING_PAYMENT]) && (
          <Row>
            <Col>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TRAINEE.AWAITING_PAYMENT_TEXT')}</Col>
          </Row>
        )}
      </React.Fragment>
    );
  }

  getUserPaymentInfo = () => {
    return this.props.externalEducationApplication.paymentInfos?.find((paymentInfo) => paymentInfo.paymentInfoType.id === ExternalEducationApplicationPaymentInfoTypeEnum.USER);
  };
}

const ExternalEducationApplicationTraineeApprovalFormWrapper = Form.create<IExternalEducationApplicationTraineeApprovalViewProps>({
  onValuesChange: debounce((props) => {
    if (props.onUpdate) {
      props.form.validateFields((err: any, values: any) => {
        if (!err) {
          props.onUpdate({
            ...props.externalEducationApplication,
            startDateTime: values.startDateTime.format(backendDate),
          });
        }
      });
    }
  }, AppConfigService.getValue('components.common.debounceTimeout')),
})(ExternalEducationApplicationTraineeApprovalView);

// -- HOCs and exports
// ----------

export default withRoles(withLocalize<IExternalEducationApplicationTraineeApprovalViewOwnProps>(ExternalEducationApplicationTraineeApprovalFormWrapper as any));
