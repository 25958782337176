import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import DataTable from '@src/components/common/table/DataTable';
import { ITimunDataTableCol } from '@src/components/common/table/TimunDataTableCol';
import ExternalEducationTemplateActivityStatus from '@src/components/externaleducation/ExternalEducationTemplateActivityStatus';
import { ParticipantRoleEnum } from '@src/model/activity/ActivityParticipant';
import { IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { ICollectionData } from '@src/service/business/common/types';
import AppConfigService from '@src/service/common/AppConfigService';
import { ActivityUtils } from '@src/service/util/activity/ActivityUtils';
import { Tag } from 'antd';
import moment from 'moment';
import { Link } from 'react-router';

// -- Const
// ---
const dateFormat = AppConfigService.getValue('dateTimeFormat.dateWithTime');

// -- Prop types
// ----------
export interface IExternalEducationTemplateTableViewOwnProps {
  externalEducationTemplateList: ICollectionData<IExternalEducationTemplate>;
  onPageChange?: (page: number, pageSize?: number) => void;
  additionalCols?: () => Array<ITimunDataTableCol<IExternalEducationTemplate>>;
}
type IExternalEducationTemplateTableViewProps = IExternalEducationTemplateTableViewOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Display external education template data table */
const ExternalEducationTemplateTableView: React.FC<IExternalEducationTemplateTableViewProps> = (props: IExternalEducationTemplateTableViewProps) => {

  const renderColumns = () => {
    const columns: Array<ITimunDataTableCol<IExternalEducationTemplate>> = [
      {
        key: 'educationProviderName',
        contentType: 'string',
        headerTitle: props.translate('EXTERNAL_EDUCATION_TEMPLATE.TABLE.EDUCATION_PROVIDER_HEADER_LABEL'),
        content: (record: IExternalEducationTemplate) => record.educationProvider.company.name,
      },
      {
        key: 'externalEducationTemplateName',
        contentType: 'string',
        headerTitle: props.translate('EXTERNAL_EDUCATION_TEMPLATE.TABLE.EXTERNAL_EDUCATION_TEMPLATE_TITLE_HEADER_LABEL'),
        content: (record: IExternalEducationTemplate) => <Link to={`externaleducationtemplate/${record.id}`}> {record.title} </Link>,
      },
      {
        key: 'externalEducationTemplateActivityStatus',
        contentType: 'string',
        headerTitle: props.translate('EXTERNAL_EDUCATION_TEMPLATE.TABLE.ACTIVITY_STATUS_HEADER_LABEL'),
        content: (record: IExternalEducationTemplate) => record.activity ? <ExternalEducationTemplateActivityStatus activityStatus={record.activity.currentPhase} /> : props.translate('EXTERNAL_EDUCATION_TEMPLATE.TABLE.NOT_SUBMITTED_LABEL'),
      },
      {
        key: 'coodinatorName',
        contentType: 'string',
        headerTitle: props.translate('EXTERNAL_EDUCATION_TEMPLATE.TABLE.COORDINATOR_HEADER_LABEL'),
        content: (record: IExternalEducationTemplate) => ActivityUtils.getParticipantNameByRole(ParticipantRoleEnum.EVALUATOR, record.activity) ?? <Tag color="magenta">{props.translate('EXTERNAL_EDUCATION_TEMPLATE.TABLE.COORDINATOR_NOT_ASSIGNED_LABEL')} </Tag>,
      },
      {
        key: 'submittedAt',
        contentType: 'string',
        headerTitle: props.translate('EXTERNAL_EDUCATION_TEMPLATE.TABLE.SUMBITTED_AT_HEADER_LABEL'),
        content: (record: IExternalEducationTemplate) => record.submittedAt ? moment(record.submittedAt).format(dateFormat) : props.translate('EXTERNAL_EDUCATION_TEMPLATE.TABLE.NOT_SUBMITTED_LABEL')
        ,
      },
    ];
    if (props.additionalCols) {
      columns.push(...props.additionalCols());
    }
    return columns;
  };


  return (
    <React.Fragment>
      <DataTable<IExternalEducationTemplate>
        items={props.externalEducationTemplateList.content}
        paginationData={props.externalEducationTemplateList.page}
        paginationConfig={{ onChange: props.onPageChange }}
        columns={renderColumns()}
        scrollable={true}
        showActionMenu={true}
      />
    </React.Fragment>
  );
};

export default withLocalize<IExternalEducationTemplateTableViewOwnProps>(ExternalEducationTemplateTableView as any);
