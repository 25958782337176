import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { Typography } from 'antd';
import React, { ReactNode } from 'react';

const CSS_CLASS_CONTAINER = 'lemon-dialogPanel__container';
const CSS_CLASS_TITLE = 'lemon-dialogPanel__header';
const CSS_CLASS_BODY = 'lemon-dialogPanel__body';

// -- Prop types
// ----------

export interface IDialogPanelOwnProps {
  title?: string | ReactNode;
  actions?: ReactNode;
  className?: string;
}
type IDialogPanelProps = IDialogPanelOwnProps & IWithLocalizeOwnProps;

interface IDialogPanelState { }

// -- Component
// ----------

/** Describe your component ... */
class DialogPanel extends React.Component<IDialogPanelProps, IDialogPanelState> {
  state: IDialogPanelState = {};

  render = () => {
    return (
      <div className={`${CSS_CLASS_CONTAINER} ${this.props.className}`}>
        {this.props.title && (
          <div className={CSS_CLASS_TITLE}>
            <Typography.Title level={3}>{this.props.title}</Typography.Title>
            {this.props.actions && (
              <div className="lemon-dialogContainerActions">
                {this.props.actions}
              </div>
            )}
          </div>
        )}
        <div className={CSS_CLASS_BODY}>{this.props.children}</div>
      </div>
    );
  };
}

export default withLocalize<IDialogPanelOwnProps>(DialogPanel as any);
