import React from 'react';

import LemonImage from '@src/components/common/image/LemonImage';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import AdditionalCardElements from '@src/components/webinar/common/AdditionalCardElements';
import WebinarHelperUtils from '@src/components/webinar/common/WebinarHelperUtils';
import { IWebinar, WebinarStatusEnum } from '@src/model/webinar/Webinar';

// -- Prop types
// ----------
export interface IWebinarCoverImagePublicProps {
  webinar: IWebinar;
  onSetReminder?: (webinar: IWebinar) => void;
}
interface IWebinarCoverImageStateProps { }
type IWebinarCoverImageProps = IWebinarCoverImagePublicProps & IWithLocalizeOwnProps & IWebinarCoverImageStateProps;

// --
// ----- Component
const WebinarCoverImage: React.FC<IWebinarCoverImageProps> = (props) => {

  const canSetReminder = WebinarHelperUtils.isWebinarInStatus(props.webinar, [WebinarStatusEnum.IN_CREATION, WebinarStatusEnum.CREATION_FINISHED, WebinarStatusEnum.PUBLISHED]);

  return (
    <div className="timun-webinarCard__coverView">
      <LemonImage imagePath={props.webinar.coverImageUrl || 'cover-placeholder.png'} className="timun-webinarCard__coverImage" />
      {canSetReminder && <AdditionalCardElements webinar={props.webinar} onSetReminder={props.onSetReminder} reminderSet={false} />}
    </div>
  );
};

export default withLocalize<IWebinarCoverImagePublicProps>(WebinarCoverImage as any);
