export interface IVoteType {
  id: VoteTypeEnum;
  name?: string;
}

export enum VoteTypeEnum {
  UPVOTE = '1',
  DOWNVOTE = '2',
}

export interface IVoteObject {
  id: string;
  name: string;
}

export interface IVoteObjectType {
  id: VoteObjectTypeEnum;
  name: string;
}

export enum VoteObjectTypeEnum {
  COURSE = '1',
  LECTURE = '2',
  WEBINAR = '3',
}
