import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

import { IQuizOutcomeStats } from '@lamarodigital/quizzler-lib-frontend/model/quiz/outcome/QuizOutcomeStats';
import { IQuiz } from '@lamarodigital/quizzler-lib-frontend/model/quiz/Quiz';
import IIdRef from '@src/model/common/IdRef';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { IIdDataPayload, IIdPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { actionThunk, startGlobalProgress, stopGlobalProgress, trackAction } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts

// -
// -------------------- Selectors

/** Returns quiz collection from store. */
const getQuizCollection = (store: any): IQuiz => store.quizCollection;

/** Returns quiz collection outcome stats from store. */
const getQuizCollectionOutcomeStats = (store: any): IQuizOutcomeStats => store.quizCollectionOutcomeStats;

// -
// -------------------- Actions

const Actions = {
  QUIZ_COLLECTION_FETCH: 'QUIZ_COLLECTION_FETCH',
  QUIZ_COLLECTION_LOAD: 'QUIZ_COLLECTION_LOAD',
  QUIZ_COLLECTION_CREATE: 'QUIZ_COLLECTION_CREATE',
  QUIZ_COLLECTION_UPDATE: 'QUIZ_COLLECTION_UPDATE',
  QUIZ_COLLECTION_CLEAR: 'QUIZ_COLLECTION_CLEAR',
  QUIZ_COLLECTION_OUTCOME_STATS_FETCH: 'QUIZ_COLLECTION_OUTCOME_STATS_FETCH',
  QUIZ_COLLECTION_OUTCOME_STATS_LOAD: 'QUIZ_COLLECTION_OUTCOME_STATS_LOAD',
  QUIZ_COLLECTION_OUTCOME_STATS_CLEAR: 'QUIZ_COLLECTION_OUTCOME_STATS_CLEAR',
};

/** Fetch quizCollection list by filter. Forces default QUIZ_COLLECTION_PAYLOAD_BASE. */
const fetchQuizCollection = (data: IIdRef<string>): IPayloadAction<IIdRef<string>> => {
  return {
    type: Actions.QUIZ_COLLECTION_FETCH,
    payload: data,
  };
};

/** Load quizCollection list to store. */
const loadQuizCollection = (data: IQuiz): IPayloadAction<IQuiz> => {
  return {
    type: Actions.QUIZ_COLLECTION_LOAD,
    payload: data,
  };
};

/** Create new quizCollection */
const createQuizCollection = (data: IQuiz): IPayloadAction<IQuiz> => {
  return {
    type: Actions.QUIZ_COLLECTION_CREATE,
    payload: data,
  };
};

/** Delete quizCollection by ID */
const updateQuizCollection = (data: IQuiz, id: string): IPayloadAction<IIdDataPayload<IQuiz>> => {
  return {
    type: Actions.QUIZ_COLLECTION_UPDATE,
    payload: {
      data,
      id,
    },
  };
};

const clearQuizCollectionData = (): ILemonAction => {
  return {
    type: Actions.QUIZ_COLLECTION_CLEAR,
  };
};

/** Fetch quiz collection outcome stats by ID. */

const fetchQuizCollectionOutcomeStats = (id: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.QUIZ_COLLECTION_OUTCOME_STATS_FETCH,
    payload: {
      id,
    },
  };
};

/** Load quiz collection outcome stats to store. */
const loadQuizCollectionOutcomeStats = (data: IQuizOutcomeStats): IPayloadAction<IQuizOutcomeStats> => {
  return {
    type: Actions.QUIZ_COLLECTION_OUTCOME_STATS_LOAD,
    payload: data,
  };
};

/** Clear quiz collection outcome stats from store. Eg. when leaving view. */
const clearQuizCollectionOutcomeStats = (): ILemonAction => {
  return {
    type: Actions.QUIZ_COLLECTION_OUTCOME_STATS_CLEAR,
  };
};

// -
// -------------------- Side-effects

const fetchQuizCollectionEffect = (action$: Observable<IPayloadAction<IIdRef<string>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.QUIZ_COLLECTION_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Collection')
        .fetchEntity(payload.id)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadQuizCollection(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching quizCollection list', error);
      return o;
    })
  );
};

const createQuizCollectionEffect = (action$: Observable<IPayloadAction<IQuiz>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.QUIZ_COLLECTION_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Collection')
        .createEntity(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadQuizCollection(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating quizCollection', error);
      return o;
    })
  );
};

const updateQuizCollectionEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IQuiz>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.QUIZ_COLLECTION_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;
      return EntityApiServiceRegistry.getService('Collection')
        .updateEntity(payload.id, payload.data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadQuizCollection(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error deleting quizCollection', error);
      return o;
    })
  );
};

const fetchQuizCollectionOutcomeStatsEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.QUIZ_COLLECTION_OUTCOME_STATS_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('Collection')
        .fetchSubobject(id, 'outcomestats')
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadQuizCollectionOutcomeStats(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching quiz collection outcome stats', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const quizCollection = (state: IQuiz | null = null, action: IPayloadAction<IQuiz>) => {
  if (action.type === Actions.QUIZ_COLLECTION_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.QUIZ_COLLECTION_CLEAR) {
    return null;
  }
  return state;
};

const quizCollectionOutcomeStats = (state: IQuizOutcomeStats | null = null, action: IPayloadAction<IQuizOutcomeStats>) => {
  if (action.type === Actions.QUIZ_COLLECTION_OUTCOME_STATS_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.QUIZ_COLLECTION_OUTCOME_STATS_CLEAR) {
    return null;
  }
  return state;
};

// --
// -------------------- Business Store

export const QuizCollectionBusinessStore = {
  actions: {
    fetchQuizCollection,
    loadQuizCollection,
    createQuizCollection,
    updateQuizCollection,
    clearQuizCollectionData,
    fetchQuizCollectionOutcomeStats,
    loadQuizCollectionOutcomeStats,
    clearQuizCollectionOutcomeStats,
  },
  selectors: {
    getQuizCollection,
    getQuizCollectionOutcomeStats,
  },
  effects: {
    fetchQuizCollectionEffect,
    createQuizCollectionEffect,
    updateQuizCollectionEffect,
    fetchQuizCollectionOutcomeStatsEffect,
  },
  reducers: {
    quizCollection,
    quizCollectionOutcomeStats,
  },
};

// --
// export business store
export default QuizCollectionBusinessStore;
