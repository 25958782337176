import DeleteButton from '@src/components/common/button/DeleteButton';
import LemonIcon from '@src/components/common/image/LemonIcon';
import LemonImage from '@src/components/common/image/LemonImage';
import { Button, Card, Tooltip } from 'antd';
import React, { ReactNode, SyntheticEvent } from 'react';
import { withRouter, WithRouterProps } from 'react-router';

// -- Const
// ----------
export enum TimunActionTypeEnum {
  EDIT_CARD = 'editCard',
  EDIT_COVER = 'editCover',
  DELETE_CARD = 'deleteCard',
}

// -- Prop types
// ----------
export interface ITimunCardOwnProps {
  title?: string;
  showButtons?: boolean;
  coverImageUrl?: string;
  path?: string;
  description?: string | ReactNode;
  showProgressBar?: string;
  showStatusBadge?: string;
  isCardInProgress?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
  onUpdateCoverImage?: () => void;
}

type ITimunCardProps = ITimunCardOwnProps & WithRouterProps;

// -- Component
// ----------

/** Component display card information */
const TimunCard: React.FC<ITimunCardProps> = (props) => {
  const onUpdateCoverImage = (event: SyntheticEvent) => {
    event.stopPropagation();
    props.onUpdateCoverImage?.();
  };

  const onEdit = (event: SyntheticEvent) => {
    event.stopPropagation();
    props.onEdit?.();
  };
  return (
    <Card className="timun-timunCard__content" onClick={() => props.router.push(props.path ?? '')} cover={<LemonImage imagePath={props.coverImageUrl || 'cover-placeholder.png'} className="timun-courseCard__coverImage"/>}
          actions={
            props.showButtons ? [
              <Button icon={<LemonIcon name="plus"/>} key={TimunActionTypeEnum.EDIT_COVER} onClick={onUpdateCoverImage}/>,
              <Button icon={<LemonIcon name="edit"/>} key={TimunActionTypeEnum.EDIT_CARD} onClick={onEdit}/>,
              <DeleteButton key={TimunActionTypeEnum.DELETE_CARD} onConfirm={props.onDelete}/>] : []
          }>
      <Card.Meta
        className="text-center"
        title={
          <Tooltip overlay={props.title}>
            {props.title}
          </Tooltip>
        }
        description={props.description}
      />
    </Card>
  );
};

export default withRouter<ITimunCardOwnProps>(TimunCard as any);
