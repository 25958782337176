import { IIdDataPayload, ITitlePayload } from '@src/service/business/common/types';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

import { ILocation } from '@src/model/location/Location';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError, createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { startGlobalProgress, stopGlobalProgress, trackAction } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts
export interface ILocationListFilter {}

export type ILocationCreatePayload = Pick<ILocation, 'type' | 'address' | 'title'>;

// List filter ID
const LOCATION_LIST_FILTER = '@@LOCATION_LIST_FILTER';

// -
// -------------------- Selectors

/** Returns Location list from store. */
const getLocationList = (store: any): ICollectionData<ILocation> => store.locationList;

/** Returns Location list filter. */
const getLocationListFilter = (store: any): ILocationListFilter => ListFilterBusinessStore.selectors.getListFilter(store, LOCATION_LIST_FILTER);

// -
// -------------------- Actions
const Actions = {
  LOCATION_LIST_FETCH: 'LOCATION_LIST_FETCH',
  LOCATION_LIST_LOAD: 'LOCATION_LIST_LOAD',
  LOCATION_LIST_CLEAR: 'LOCATION_LIST_CLEAR',
  LOCATION_CREATE: 'LOCATION_CREATE',
  LOCATION_UPDATE: 'LOCATION_UPDATE',
};

/** Fetch location list by filter */
const fetchLocationList = (params: ICollectionFetchPayload<ILocationListFilter>): IPayloadAction<ICollectionFetchPayload<ILocationListFilter>> => {
  return {
    type: Actions.LOCATION_LIST_FETCH,
    payload: params,
  };
};

/** Load location list to the store */
const loadLocationList = (data: ICollectionData<ILocation>): IPayloadAction<ICollectionData<ILocation>> => {
  return {
    type: Actions.LOCATION_LIST_LOAD,
    payload: data,
  };
};

/** Clear location list from store. Eg. when leaving view. */
const clearLocationList = (): ILemonAction => {
  return {
    type: Actions.LOCATION_LIST_CLEAR,
  };
};

/** Create location */
const createLocation = (data: ILocationCreatePayload): IPayloadAction<ILocationCreatePayload> => {
  return {
    type: Actions.LOCATION_CREATE,
    payload: data,
  };
};

/** Update location by id */
const updateLocation = (data: ILocation): IPayloadAction<IIdDataPayload<ILocation>> => {
  return {
    type: Actions.LOCATION_UPDATE,
    payload: {
      id: data.id,
      data,
    },
  };
};

const storeLocationListFilter = (listFilter: ILocationListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(LOCATION_LIST_FILTER, listFilter);
};

const clearLocationListFilter = (): ILemonAction => {
  return ListFilterBusinessStore.actions.clearListFilter(LOCATION_LIST_FILTER);
};

// -
// -------------------- Side-effects
const fetchLocationListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<ILocationListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.LOCATION_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Location')
        .fetchEntityList(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadLocationList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error fetching location list', error);
      return o;
    })
  );
};

const createLocationEffect = (action$: Observable<IPayloadAction<ITitlePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.LOCATION_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('Location')
        .createEntity(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'SKILL_GROUP_LIST.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error creating location', error);
      return o;
    })
  );
};

const updateLocationEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ILocation>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.LOCATION_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('Location')
        .updateEntity(id, data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'SKILL_GROUP_LIST.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error updating location', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers
const locationList = (state: ICollectionData<ILocation> | null = null, action: IPayloadAction<ICollectionData<ILocation>>) => {
  if (action.type === Actions.LOCATION_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.LOCATION_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store
export const LocationBusinessStore = {
  actions: {
    fetchLocationList,
    loadLocationList,
    clearLocationList,
    createLocation,
    updateLocation,
    storeLocationListFilter,
    clearLocationListFilter,
  },

  selectors: {
    getLocationList,
    getLocationListFilter,
  },

  effects: {
    fetchLocationListEffect,
    createLocationEffect,
    updateLocationEffect,
  },

  reducers: {
    locationList,
  },
};

// --
// export business store
export default LocationBusinessStore;
