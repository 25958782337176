import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

import { ICodedGrade } from '@src/model/education/CodedGrade';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, IIdPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError, createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { trackAction } from '@src/service/util/observable/operators';
import { startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts
export interface ICodedGradeListFilter {
  name?: string;
  active?: boolean;
}

export interface ICodedGradeCreatePayload {
  name: string;
  description: string;
  ordering: number;
}

// List filter ID
const CODED_GRADE_LIST_FILTER = '@@CODED_GRADE_LIST_FILTER';

// -
// -------------------- Selectors

/** Returns CodedGradeList from store. */
const getCodedGradeList = (store: any): ICollectionData<ICodedGrade> => store.codedGradeList;

/** Return CodedGrade from store */
const getCodedGrade = (store: any): ICodedGrade => store.codedGrade;

/** Returns CodedGradeList filter. */
const getCodedGradeListFilter = (store: any): ICodedGradeListFilter => ListFilterBusinessStore.selectors.getListFilter(store, CODED_GRADE_LIST_FILTER);

// -
// -------------------- Actions

const Actions = {
  CODED_GRADE_LIST_FETCH: 'CODED_GRADE_LIST_FETCH',
  CODED_GRADE_FETCH: 'CODED_GRADE_FETCH',
  CODED_GRADE_LIST_LOAD: 'CODED_GRADE_LIST_LOAD',
  CODED_GRADE_LIST_CLEAR: 'CODED_GRADE_LIST_CLEAR',
  CODED_GRADE_CREATE: 'CODED_GRADE_CREATE',
  CODED_GRADE_UPDATE: 'CODED_GRADE_UPDATE',
  CODED_GRADE_LOAD: 'CODED_GRADE_LOAD',
  CODED_GRADE_CLEAR: 'CODED_GRADE_CLEAR',
};

/** Fetch CodedGradeList */
const fetchCodedGradeList = (params: ICollectionFetchPayload<ICodedGradeListFilter>): IPayloadAction<ICollectionFetchPayload<ICodedGradeListFilter>> => {
  return {
    type: Actions.CODED_GRADE_LIST_FETCH,
    payload: params,
  };
};

/** Fetch CodedGrade by ID. */
const fetchCodedGrade = (params: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.CODED_GRADE_FETCH,
    payload: params,
  };
};

/** Load CodedGradeList to store. */
const loadCodedGradeList = (data: ICollectionData<ICodedGrade>): IPayloadAction<ICollectionData<ICodedGrade>> => {
  return {
    type: Actions.CODED_GRADE_LIST_LOAD,
    payload: data,
  };
};

/** Clear CodedGradeList from store. Eg. when leaving view. */
const clearCodedGradeListData = (): ILemonAction => {
  return {
    type: Actions.CODED_GRADE_LIST_CLEAR,
  };
};

/** Load CodedGrade to store */
const loadCodedGrade = (data: ICodedGrade): IPayloadAction<ICodedGrade> => {
  return {
    type: Actions.CODED_GRADE_LOAD,
    payload: data,
  };
};

/** Clear CodedGrade from store. Eg. when leaving view */
const clearCodedGrade = (): ILemonAction => {
  return {
    type: Actions.CODED_GRADE_CLEAR,
  };
};

/** Create new CodedGrade. */
const createCodedGrade = (data: ICodedGradeCreatePayload): IPayloadAction<ICodedGradeCreatePayload> => {
  return {
    type: Actions.CODED_GRADE_CREATE,
    payload: data,
  };
};

/** Update CodedGrade by ID. */
const updateCodedGrade = (data: ICodedGrade): IPayloadAction<ICodedGrade> => {
  return {
    type: Actions.CODED_GRADE_UPDATE,
    payload: data,
  };
};

// -
// -------------------- Side-effects

const fetchCodedGradeListEffect = (action$: Observable<IPayloadAction<ICollectionFetchPayload<ICodedGradeListFilter>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.CODED_GRADE_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('CodedGrade')
        .fetchEntityList(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadCodedGradeList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching coded grade list', error);
      return o;
    })
  );
};

const fetchCodedGradeEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.CODED_GRADE_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('CodedGrade')
        .fetchEntity(id)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadCodedGrade(data);
    }),

    // reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'CODED_GRADE_VIEW.ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),
    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching CodedGrade', error);
      return o;
    })
  );
};

const createCodedGradeEffect = (action$: Observable<IPayloadAction<ICodedGradeCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.CODED_GRADE_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;
      return EntityApiServiceRegistry.getService('CodedGrade')
        .createEntity(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadCodedGradeList(data);
    }),

    ignoreElements(),

    // reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),
    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'CODEBOOK_GRADE.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating CodedGrade', error);
      return o;
    })
  );
};

const updateCodedGradeEffect = (action$: Observable<IPayloadAction<ICodedGrade>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.CODED_GRADE_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('CodedGrade')
        .updateEntity(data.id, data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    map((data) => {
      return loadCodedGrade(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating CodedGrade', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const codedGradeList = (state: ICollectionData<ICodedGrade> | null = null, action: IPayloadAction<ICodedGrade>) => {
  if (action.type === Actions.CODED_GRADE_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.CODED_GRADE_LIST_CLEAR) {
    return null;
  }

  return state;
};

const codedGrade = (state: ICodedGrade | null = null, action: IPayloadAction<ICodedGrade>) => {
  if (action.type === Actions.CODED_GRADE_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.CODED_GRADE_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const codedGradeBusinessStore = {
  actions: {
    fetchCodedGradeList,
    fetchCodedGrade,
    loadCodedGradeList,
    clearCodedGradeListData,
    loadCodedGrade,
    clearCodedGrade,
    createCodedGrade,
    updateCodedGrade,
  },

  selectors: {
    getCodedGradeList,
    getCodedGradeListFilter,
    getCodedGrade,
  },

  effects: {
    fetchCodedGradeListEffect,
    fetchCodedGradeEffect,
    createCodedGradeEffect,
    updateCodedGradeEffect,
  },

  reducers: {
    codedGradeList,
    codedGrade,
  },
};

// --
// export business store
export default codedGradeBusinessStore;
