import { IQuiz } from '@lamarodigital/quizzler-lib-frontend/model/quiz/Quiz';
import { ISurveyInstance } from '@src/model/survey/SurveyInstance';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, IIdDataPayload, IIdPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { trackAction } from '@src/service/util/observable/operators';
import { startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

// -
// -------------------- Types&Consts
export interface ISurveyInstanceSubmissionListFilter {
  token?: string;
}

// List filter ID
const SURVEY_INSTANCE_SUBMISSION_LIST_FILTER = '@@SURVEY_INSTANCE_SUBMISSION_LIST_FILTER';

// -
// -------------------- Selectors

/** Returns SurveyInstace from store. */
const getSurveyInstance = (store: any): ISurveyInstance => store.surveyInstance;

/** Returns SurveyInstaceSubmission list from store. */
const getSurveyInstanceSubmissionList = (store: any): ICollectionData<IQuiz> => store.surveyInstanceSubmissionList;

/** Returns SurveyInstaceSubmission list filter. */
const getSurveyInstanceSubmissionListFilter = (store: any): ISurveyInstanceSubmissionListFilter => ListFilterBusinessStore.selectors.getListFilter(store, SURVEY_INSTANCE_SUBMISSION_LIST_FILTER);

// -
// -------------------- Actions

const Actions = {
  SURVEY_INSTANCE_FETCH: 'SURVEY_INSTANCE_FETCH',
  SURVEY_INSTANCE_LOAD: 'SURVEY_INSTANCE_LOAD',
  SURVEY_INSTANCE_CLEAR: 'SURVEY_INSTANCE_CLEAR',
  SURVEY_INSTANCE_SUBMISSION_LIST_FETCH: 'SURVEY_INSTANCE_SUBMISSION_LIST_FETCH',
  SURVEY_INSTANCE_SUBMISSION_LIST_LOAD: 'SURVEY_INSTANCE_SUBMISSION_LIST_LOAD',
  SURVEY_INSTANCE_SUBMISSION_LIST_CLEAR: 'SURVEY_INSTANCE_SUBMISSION_LIST_CLEAR',
};

/** Fetch SurveyInstance by token. */
const fetchSurveyInstance = (id: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.SURVEY_INSTANCE_FETCH,
    payload: {
      id,
    },
  };
};

/** Load SurveyInstance to store. */
const loadSurveyInstance = (data: ISurveyInstance): IPayloadAction<ISurveyInstance> => {
  return {
    type: Actions.SURVEY_INSTANCE_LOAD,
    payload: data,
  };
};

/** Clear SurveyInstance from store. Eg. when leaving view. */
const clearSurveyInstance = (): ILemonAction => {
  return {
    type: Actions.SURVEY_INSTANCE_CLEAR,
  };
};

/** Fetch SurveyInstanceSubmission list by id and token. */
const fetchSurveyInstanceSubmissionList = (id: string, data: ICollectionFetchPayload<ISurveyInstanceSubmissionListFilter>): IPayloadAction<IIdDataPayload<ICollectionFetchPayload<ISurveyInstanceSubmissionListFilter>>> => {
  return {
    type: Actions.SURVEY_INSTANCE_SUBMISSION_LIST_FETCH,
    payload: {
      id,
      data,
    },
  };
};

/** Load SurveyInstanceSubmission list to store. */
const loadSurveyInstanceSubmissionList = (data: ICollectionData<any>): IPayloadAction<ICollectionData<any>> => {
  return {
    type: Actions.SURVEY_INSTANCE_SUBMISSION_LIST_LOAD,
    payload: data,
  };
};

/** Clear SurveyInstanceSubmission list from store. Eg. when leaving view. */
const clearSurveyInstanceSubmissionList = (): ILemonAction => {
  return {
    type: Actions.SURVEY_INSTANCE_SUBMISSION_LIST_CLEAR,
  };
};

/** Store list filter to store. */
const storeSurveyInstanceSubmissionListFilter = (listFilter: ISurveyInstanceSubmissionListFilter): ILemonAction => {
  return ListFilterBusinessStore.actions.storeListFilter(SURVEY_INSTANCE_SUBMISSION_LIST_FILTER, listFilter);
};

/** Clear list filter from store. */
const clearSurveyInstanceSubmissionListFilter = (): ILemonAction => {
  return ListFilterBusinessStore.actions.clearListFilter(SURVEY_INSTANCE_SUBMISSION_LIST_FILTER);
};

// -
// -------------------- Side-effects

const fetchSurveyInstanceEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.SURVEY_INSTANCE_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('SurveyInstance')
        .fetchMethod(id)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadSurveyInstance(data);
    }),
    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'SURVEY_INSTANCE.ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),
    // reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching SurveyInstance', error);
      return o;
    })
  );
};

const fetchSurveyInstanceSubmissionListEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ICollectionFetchPayload<ISurveyInstanceSubmissionListFilter>>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.SURVEY_INSTANCE_SUBMISSION_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('SurveyInstance')
        .fetchSubentityList(id, 'submission', data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadSurveyInstanceSubmissionList(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'SURVEY_INSTANCE.ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),
    // reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching SurveyInstanceSubmission list', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const surveyInstance = (state: ISurveyInstance | null = null, action: IPayloadAction<ISurveyInstance>) => {
  if (action.type === Actions.SURVEY_INSTANCE_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.SURVEY_INSTANCE_CLEAR) {
    return null;
  }

  return state;
};

const surveyInstanceSubmissionList = (state: ICollectionData<any> | null = null, action: IPayloadAction<ICollectionData<any>>) => {
  if (action.type === Actions.SURVEY_INSTANCE_SUBMISSION_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.SURVEY_INSTANCE_SUBMISSION_LIST_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const surveyInstanceBusinessStore = {
  actions: {
    fetchSurveyInstance,
    loadSurveyInstance,
    clearSurveyInstance,
    fetchSurveyInstanceSubmissionList,
    loadSurveyInstanceSubmissionList,
    clearSurveyInstanceSubmissionList,
    storeSurveyInstanceSubmissionListFilter,
    clearSurveyInstanceSubmissionListFilter,
  },

  selectors: {
    getSurveyInstance,
    getSurveyInstanceSubmissionList,
    getSurveyInstanceSubmissionListFilter,
  },

  effects: {
    fetchSurveyInstanceEffect,
    fetchSurveyInstanceSubmissionListEffect,
  },

  reducers: {
    surveyInstance,
    surveyInstanceSubmissionList,
  },
};

// --
// export business store
export default surveyInstanceBusinessStore;
