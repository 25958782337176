import useCollectionState, { ICollectionUpdateFunction, IUpdateCollectionStateFunctions } from '@src/components/common/collectionParams/useCollectionState';
import { ICollectionFetchPayload } from '@src/service/business/common/types';
import React, { useEffect, useState } from 'react';

// --
// ----- Prop types

export interface IWithCollectionStatePublicProps<F> {
  viewName?: string; // set view name used as key in global redux store. If none provided, local store only used
  initialValues?: Partial<ICollectionFetchPayload<F>>;  // set initial values for params if they are not default ones
}

export interface IWithCollectionStateOwnProps<F> {
  collectionParams: Readonly<ICollectionFetchPayload<F>>;
  onUpdateList: (onUpdateList?: (payload: ICollectionFetchPayload<F>) => void, filter?: F, page?: number, size?: number, sort?: string[]) => void;
  updateCollectionParams: IUpdateCollectionStateFunctions<F>;
  setUpdateFunction: (onUpdateList: ICollectionUpdateFunction<F>) => void;
}

// --
// ----- Component

/** Higher order component for injecting collection params support to components. Also able to autoupdate list on param changes if provided with update function */
const withCollectionState = <F,>({ viewName, initialValues }: IWithCollectionStatePublicProps<F>) => <P,>(Component: React.ComponentType<P>) => ({ ...props }: P) => {

  const [updateFunction, setUpdateFunction] = useState<ICollectionUpdateFunction<F>>();

  const [collectionParams, updateCollectionParams, onUpdateList] = useCollectionState({ viewName, initialValues });

  useEffect(() => {
    if (updateFunction != null && collectionParams != null) {
      onUpdateList(updateFunction);
    }

  }, [collectionParams, updateFunction]);

  const onSetUpdateFunction = (updateFn: ICollectionUpdateFunction<F>) => {
    setUpdateFunction(() => updateFn); // https://medium.com/swlh/how-to-store-a-function-with-the-usestate-hook-in-react-8a88dd4eede1
  };

  const TypedComponent: React.ComponentType<P & IWithCollectionStateOwnProps<F>> = Component as any;
  return (
    <TypedComponent
      {...props}
      collectionParams={collectionParams}
      updateCollectionParams={updateCollectionParams}
      onUpdateList={onUpdateList}
      setUpdateFunction={onSetUpdateFunction}
    />
  );

};

// ----- exports
export default withCollectionState;
