import { Form } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import AppConfigService from '@src/service/common/AppConfigService';
import { Input, Modal } from 'antd';
import React, { SyntheticEvent } from 'react';

const NAME_LENGTH_MAX = AppConfigService.getValue('userValidation.nameLengthMax');

// -- Prop types
// ----------

export interface INameInputFormOwnProps {
  title: string; // sets name of the modal title
  defaultName?: string; // if the component is used for renaming, old name can be passed for initial display
  onSubmit: (name: string) => void;
  onCancel: () => void;
}

type INameInputFormProps = INameInputFormOwnProps & IWithLocalizeOwnProps & FormComponentProps;

interface INameInputFormState {
}

// -- Component
// ----------

/** Component for entering the name of a newly created item */
class NameInputForm extends React.Component<INameInputFormProps, INameInputFormState> {
  handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.form.validateFields((err: any, values: any) => {
      if (!err && values.name !== this.props.defaultName) {
        this.props.onSubmit(values.name);
        this.props.form.resetFields();
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Modal
        maskClosable={false}
        title={this.props.title}
        visible={true}
        className="lemon-modal__modal--sm"
        onOk={this.handleSubmit}
        onCancel={this.handleCancel}
        okText={this.props.translate('COMMON.ACTION_SAVE')}
        cancelText={this.props.translate('COMMON.ACTION_CANCEL')}
        okButtonProps={{ disabled: !this.props.form.isFieldsTouched() || !!this.props.form.getFieldError('name') }}
      >
        <Form layout="vertical" hideRequiredMark={true} onSubmit={this.handleSubmit}>
          <Form.Item label={this.props.translate('COMMON.LABEL.NAME')}>
            {getFieldDecorator('name', {
              rules: [
                { whitespace: true, message: this.props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
                { required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') },
              ],
              initialValue: this.props.defaultName,
            })(<Input maxLength={NAME_LENGTH_MAX} autoFocus={true} data-test-id={`timun-nameInputModal__nameInput`} onPressEnter={this.handleSubmit}/>)}
          </Form.Item>
        </Form>
      </Modal>
    );
  }

  handleCancel = () => {
    if (this.props.form.isFieldsTouched()) {
      confirmationDialog({
        onConfirm: this.props.onCancel,
        title: this.props.translate('COMMON.CONFIRMATION_CANCEL_ACTION_MESSAGE'),
      });
    } else {
      this.props.onCancel();
    }
  };
}

const NameInputModal = Form.create()(NameInputForm);

export default withLocalize<INameInputFormOwnProps>(NameInputModal as any);
