import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Layout, Row, Typography } from 'antd';
import React, { ReactNode } from 'react';

import EditableWrapper from '@src/components/common/container/EditableWrapper';
import UploadedFileList from '@src/components/common/file/UploadedFileList';
import LemonIcon from '@src/components/common/image/LemonIcon';
import LemonImage from '@src/components/common/image/LemonImage';
import LemonVideo from '@src/components/common/image/LemonVideo';
import IntegrationVideoPlayer from '@src/components/common/integrationvideo/IntegrationVideoPlayer';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import MathContent from '@src/components/common/math/MathContent';
import ImageUploadForm from '@src/components/common/upload/ImageUploadForm';
import VideoUploadForm from '@src/components/common/upload/VideoUploadForm';
import IfTenantPropEnabled from '@src/components/tenant/IfTenantPropEnabled';
import { IFile } from '@src/model/file/File';
import { IIntegrationVideo } from '@src/model/integrationvideo/IntegrationVideo';

const { Header, Content } = Layout;

// -- Prop types
// ----------

export interface ICourseViewLectureContentOwnProps {
  title: string;
  body?: string;
  coverVideo?: IIntegrationVideo;
  coverImageUrl?: string;
  coverVideoFileUrl?: string;
  fileList?: IFile[];
  canEdit?: boolean;
  showProgress?: boolean;
  canStart?: boolean;

  renderEnrollButton?: () => ReactNode;
  onEditingChange?: () => void;
  onCoverImageSubmit?: (data: IFile) => void;
  onCoverVideoSubmit?: (data: IFile) => void;
  onCoverVideoRemove?: () => void;
  onCourseProgress?: (results?: any) => void;
  onCourseStart?: () => void;
}

type ICourseViewLectureContentProps = ICourseViewLectureContentOwnProps & IWithLocalizeOwnProps;

interface ICourseViewLectureContentState {
  isEditingCoverImage: boolean;
  isEditingCoverVideo: boolean;
}

// -- Component
// ----------

/** view component to display the content of a lecture ... */
class CourseViewLectureContent extends React.Component<ICourseViewLectureContentProps, ICourseViewLectureContentState> {
  state: ICourseViewLectureContentState = {
    isEditingCoverImage: false,
    isEditingCoverVideo: false,
  };

  render = () => {
    return (
      <React.Fragment>
        {this.state.isEditingCoverImage && this.props.onCoverImageSubmit && <ImageUploadForm defaultImageLink={this.props.coverImageUrl} onSubmit={this.props.onCoverImageSubmit} onClose={this.toggleCoverEdit} />}
        <Layout>
          {/* ----- course cover image (placeholder shown if it doesn't exist and user is course creator) ----- */}
          {(this.props.coverImageUrl ||
            /* if cover image exists on object then it is "null", on eg. lecture it is undefined */
            (this.props.canEdit && this.props.coverImageUrl === null)) && (
            <div className={this.props.canEdit ? 'timun-courseView__coverContainer--editable' : 'timun-courseView__coverContainer'}>
              <LemonImage imagePath={this.props.coverImageUrl || 'cover-placeholder.png'} className="timun-courseView__coverImage" />
              {this.props.canEdit && (
                <div className="timun-courseView__coverImage-editButton">
                  <a onClick={this.toggleCoverEdit} data-test-id="timun-courseView__coverImage-editButton">
                    <EditOutlined />
                  </a>
                </div>
              )}
            </div>
          )}

          {/* ----- external video ID ----- */}
          <IfTenantPropEnabled property="integration_video" fallback="">
            {this.props.coverVideo && (
              // TODO: URL builder for video src
              <React.Fragment>
                {/* <iframe className="timun-courseView__videoContent" src={`${VIDEO_URL}${this.props.coverVideo}`} /-*frameborder="0"*-/ allow="autoplay; fullscreen" /-*allowfullscreen={true}*-/ /> */}
                <IntegrationVideoPlayer videoId={this.props.coverVideo.integrationId} integrationType={this.props.coverVideo.integrationType.id} />
              </React.Fragment>
            )}
          </IfTenantPropEnabled>

          {/* ----- API video URL ----- */}
          <IfTenantPropEnabled not={true} property="integration_video" fallback="">
            {this.props.coverVideoFileUrl && (
              <EditableWrapper showMask={false} position={{ h: 'right', v: 'top' }} disabled={!this.props.canEdit} showRemove={false} className="timun-courseView__coverContainer" onEdit={() => this.openCoverVideoEdit()} onRemove={() => this.props.onCoverVideoRemove?.()}>
                {this.props.coverVideoFileUrl && <LemonVideo src={this.props.coverVideoFileUrl} className="timun-courseView__coverVideo" options={{ showControls: true }} />}
              </EditableWrapper>
            )}
            {this.state.isEditingCoverVideo && <VideoUploadForm onClose={this.closeCoverVideoEdit} onSubmit={(file) => this.props.onCoverVideoSubmit?.(file)} />}
          </IfTenantPropEnabled>

          <Header className="timun-courseView__lecture-title">
            <Row justify="space-between" align="middle">
              <Col>
                <Typography.Title level={1}>{this.props.title}</Typography.Title>
              </Col>

              {/* ----- show enroll button ----- */}
              {this.props.renderEnrollButton && <Col>{this.props.renderEnrollButton()}</Col>}

              {this.props.canEdit && this.props.onEditingChange && (
                <Col>
                  <IfTenantPropEnabled not={true} property="integration_video" fallback="">
                    {/* show video add button only if there is no video */}
                    {this.props.coverVideoFileUrl === null && (
                      <React.Fragment>
                        <Button icon={<LemonIcon name="plus" />} onClick={() => this.openCoverVideoEdit()} data-test-id="timun-courseView__lecture-addVideoButton">
                          {this.props.translate('COMMON.ACTION_ADD_VIDEO')}
                        </Button>
                        &nbsp;
                      </React.Fragment>
                    )}
                  </IfTenantPropEnabled>

                  <Button icon={<LemonIcon name="edit" />} onClick={this.props.onEditingChange} data-test-id="timun-courseView__lecture-editButton">
                    {this.props.translate('COMMON.ACTION_EDIT')}
                  </Button>
                </Col>
              )}
            </Row>
            {/* <Row justify="space-between">
              {this.props.videoId &&
                <Col>
                  <Typography.Text type="secondary">Trajanje: 5 min</Typography.Text> {/** TODO: set duration from video
                </Col>
              }
            </Row> */}
          </Header>

          <Content className="timun-courseView__lecture-content">
            <div>
              {this.props.body && (
                <React.Fragment>
                  <Typography.Paragraph>
                    <MathContent key={this.props.body}>
                      <div dangerouslySetInnerHTML={{ __html: this.props.body }} />
                    </MathContent>
                  </Typography.Paragraph>
                  <br /> <br />
                </React.Fragment>
              )}

              {/**
               * written like this because empty array is falsy, but not false, so empty array will
               * render 0 to the screen
               */}
              {this.props.fileList && this.props.fileList.length > 0 && (
                <React.Fragment>
                  <Typography.Title level={4}>{this.props.translate('COURSE_VIEW.UPDATE.LECTURE_FILES_LABEL')}:</Typography.Title>
                  <UploadedFileList fileList={this.props.fileList} />
                  <br /> <br />
                </React.Fragment>
              )}

              {/** Display start button which opens first lecture */}
              {this.props.canStart && (
                <Row justify="center">
                  <Col>
                    <Button icon={<LemonIcon name="forward" />} onClick={this.props.onCourseStart} data-test-id="timun-courseView__lecture-startButton">
                      {this.props.translate('COURSE_VIEW.VIEW.COURSE_START_BUTTON')}
                    </Button>
                  </Col>
                </Row>
              )}

              {/** Display continue button which unlocks the next lecture */}
              {this.props.showProgress && (
                <Row justify="center">
                  <Col>
                    <Button icon={<LemonIcon name="forward" />} onClick={this.props.onCourseProgress} data-test-id="timun-courseView__lecture-nextButton">
                      {this.props.translate('COMMON.ACTION_CONTINUE')}
                    </Button>
                  </Col>
                </Row>
              )}
            </div>
          </Content>
        </Layout>
      </React.Fragment>
    );
  };

  private toggleCoverEdit = () => {
    this.setState({ isEditingCoverImage: !this.state.isEditingCoverImage });
  };

  private openCoverVideoEdit = () => {
    this.setState({ isEditingCoverVideo: true });
  };

  private closeCoverVideoEdit = () => {
    this.setState({ isEditingCoverVideo: false });
  };
}

export default withLocalize<ICourseViewLectureContentOwnProps>(CourseViewLectureContent as any);
