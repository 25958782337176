import { IExternalContent } from '@src/model/externalcontent/ExternalContent';
import { IFile } from '@src/model/file/File';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { IIdDataPayload, ILemonAction, IPayloadAction, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError, createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import { actionThunk, startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage, reportMessage } from '@src/service/util/observable/operators/userFeedback';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

// -
// -------------------- Types&Consts

export interface IExternalContentCreatePayload {
  title: string;
  url: string;
  description?: string;
  coverImageUrl?: string;
}

// -
// -------------------- Selectors

/** Returns external content from store. */
const getExternalContent = (store: any): IExternalContent => store.externalContentData;

// -
// -------------------- Actions

const Actions = {
  EXTERNAL_CONTENT_FETCH: 'EXTERNAL_CONTENT_FETCH',
  EXTERNAL_CONTENT_LOAD: 'EXTERNAL_CONTENT_LOAD',
  EXTERNAL_CONTENT_CLEAR: 'EXTERNAL_CONTENT_CLEAR',
  EXTERNAL_CONTENT_CREATE: 'EXTERNAL_CONTENT_CREATE',
  EXTERNAL_CONTENT_UPDATE: 'EXTERNAL_CONTENT_UPDATE',
  EXTERNAL_CONTENT_DELETE: 'EXTERNAL_CONTENT_DELETE',
  EXTERNAL_CONTENT_COVER_UPLOAD: 'EXTERNAL_CONTENT_COVER_UPLOAD',
};

/** Fetch external content by ID. */
const fetchExternalContent = (id: string): IPayloadAction<string> => {
  return {
    type: Actions.EXTERNAL_CONTENT_FETCH,
    payload: id,
  };
};

/** Load external content to store. */
const loadExternalContent = (data: IExternalContent): IPayloadAction<IExternalContent> => {
  return {
    type: Actions.EXTERNAL_CONTENT_LOAD,
    payload: data,
  };
};

/** Clear external content from store. Eg. when leaving view. */
const clearExternalContentData = (): ILemonAction => {
  return {
    type: Actions.EXTERNAL_CONTENT_CLEAR,
  };
};

/** Create new external content. */
const createExternalContent = (data: IExternalContentCreatePayload): IPayloadAction<IExternalContentCreatePayload> => {
  return {
    type: Actions.EXTERNAL_CONTENT_CREATE,
    payload: data,
  };
};

/** Update external content by id */
const updateExternalContent = (id: string, data: IExternalContentCreatePayload): IPayloadAction<IIdDataPayload<IExternalContentCreatePayload>> => {
  return {
    type: Actions.EXTERNAL_CONTENT_UPDATE,
    payload: {
      id,
      data,
    },
  };
};

/** Delete external content by id */
const deleteExternalContent = (id: string): IPayloadAction<string> => {
  return {
    type: Actions.EXTERNAL_CONTENT_DELETE,
    payload: id,
  };
};

/** Upload cover for external content */
const uploadExternalContentCover = (id: string, data: IFile): IPayloadAction<IIdDataPayload<IFile>> => {
  return {
    type: Actions.EXTERNAL_CONTENT_COVER_UPLOAD,
    payload: {
      id,
      data,
    },
  };
};

// -
// -------------------- Side-effects

const fetchExternalContentEffect = (action$: Observable<IPayloadAction<string>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_CONTENT_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const id = action.payload;

      return EntityApiServiceRegistry.getService('ExternalContent')
        .fetchEntity(id)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadExternalContent(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching external content', error);
      return o;
    }),
  );
};

const createExternalContentEffect = (action$: Observable<IPayloadAction<IExternalContentCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_CONTENT_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('ExternalContent')
        .createEntity(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'TAG_ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating external content', error);
      return o;
    }),
  );
};

const updateExternalContentEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IExternalContentCreatePayload>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_CONTENT_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('ExternalContent')
        .updateEntity(id, data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'TAG_ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating external content', error);
      return o;
    }),
  );
};

const deleteExternalContentEffect = (action$: Observable<IPayloadAction<string>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_CONTENT_DELETE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const id = action.payload;

      return EntityApiServiceRegistry.getService('ExternalContent')
        .deleteEntity(id)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    reportMessage((value) => ({ message: LocalizeService.translate('EXTERNAL_CONTENT.DELETED_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.INFO })),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_DELETE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error deleting external content', error);
      return o;
    }),
  );
};

const uploadExternalContentCoverEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IFile>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_CONTENT_COVER_UPLOAD;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('ExternalContent')
        .createSubobject(id, 'cover', data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_SEND_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error uploading cover image', error);
      return o;
    }),
  );
};

// -
// -------------------- Reducers

const externalContentData = (state: IExternalContent | null = null, action: IPayloadAction<IExternalContent>) => {
  if (action.type === Actions.EXTERNAL_CONTENT_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.EXTERNAL_CONTENT_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const ExternalContentViewBusinessStore = {
  actions: {
    fetchExternalContent,
    clearExternalContentData,
    createExternalContent,
    updateExternalContent,
    deleteExternalContent,
    uploadExternalContentCover,
  },

  selectors: {
    getExternalContent,
  },

  effects: {
    fetchExternalContentEffect,
    createExternalContentEffect,
    updateExternalContentEffect,
    deleteExternalContentEffect,
    uploadExternalContentCoverEffect,
  },

  reducers: {
    externalContentData,
  },
};

// --
// export business store
export default ExternalContentViewBusinessStore;
