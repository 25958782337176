import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import IIdRef from '@src/model/common/IdRef';
import { ICourse } from '@src/model/course/Course';
import { ICourseGroup } from '@src/model/course/CourseGroup';
import { IUserGroupCourse } from '@src/model/course/UserGroupCourse';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import { IUserGroupCoursePayload } from '@src/service/business/usergroup/UserGroupBusinessStore';
import { Button, Col, Row, Tree } from 'antd';
import React from 'react';

const { TreeNode } = Tree;

const COURSE_GROUP_MISSING_ID = '-1';

const ID_SEPARATOR = '__';

interface ICoursesByCourseGroupMap {
  [groupId: string]: Array<{ course: ICourse, workPositions?: IWorkPosition[]; }>;
}

interface IWorkPositionsByCourseMap {
  [courseId: string]: Array<IIdRef<string>>;
}

interface IUserGroupCourseKeys {
  courseGroupId?: string;
  courseId: string;
  workPositionId: string;
}

// -- Prop types
// ----------

interface ICourseWorkpositionTreeOwnProps {
  courseGroups: ICourseGroup[];
  workPositionList?: IWorkPosition[];
  allCoursesList: ICourse[];
  assignedCourses: IUserGroupCourse[];
  onSubmit: (data: IUserGroupCoursePayload[]) => void;
}

// --
// ----- State types

interface ICourseWorkpositionTreeState {
  coursesMap: ICoursesByCourseGroupMap;
  assignedCourses: string[];
}

// -- Component
// ----------

class CourseWorkpositionTree extends React.Component<IWithLocalizeOwnProps & ICourseWorkpositionTreeOwnProps, ICourseWorkpositionTreeState> {
  state: ICourseWorkpositionTreeState = {
    coursesMap: {},
    assignedCourses: [],
  };

  componentDidMount() {
    this.updateCoursesMap();
    this.checkboxAssignedKeys(this.props.assignedCourses);
  }

  render() {
    return (
      <React.Fragment>
        <Row>
          <Tree checkable={true} selectable={false} defaultExpandAll={true} checkedKeys={this.props.assignedCourses ? this.state.assignedCourses : []} onCheck={(check) => this.onAssignCourses(check as string[])}>
            {Object.keys(this.state.coursesMap)
              .map((courseGroupId: string) => {
                const courseGroup = this.getCourseGroupById(courseGroupId);
                const courses = this.state.coursesMap[courseGroupId];

                if (this.state.coursesMap[courseGroupId].length) {
                  return (
                    <TreeNode title={courseGroup === undefined ? this.props.translate('COURSE_LIST.UNGROUPED_TITLE') : courseGroup.title} key={courseGroup === undefined ? this.props.translate('COURSE_LIST.UNGROUPED_TITLE') : courseGroup.id}>
                      {courses.map((course) => {
                        return <TreeNode
                          title={<div onClick={() => this.props.onSubmit([{ course: { id: course.course.id } }])}>{course.course.title}</div>}
                          key={this.getTreeKey(courseGroup, course.course)}
                        >
                          {this.props.workPositionList?.map((workPosition) => <TreeNode title={workPosition.name} key={this.getTreeKey(courseGroup, course.course, workPosition)} />
                          )}
                        </TreeNode>;
                      })}
                    </TreeNode>
                  );
                } else {
                  return null;
                }
              })
              .filter((el) => el != null)}
          </Tree>
        </Row>
        <Row justify="end">
          <Col>
            <Button key="submit" type="primary" onClick={this.onSubmit}>
              {this.props.translate('COMMON.ACTION_SAVE')}
            </Button></Col>
        </Row>
      </React.Fragment>
    );
  }

  onSubmit = () => {
    const assignedWorkpositionsFromKeys = this.state.assignedCourses
      .map((course) => {
        const { courseId, workPositionId } = this.getKeys(course);
        return { course: { id: courseId }, workPositions: [{ id: workPositionId }] };
      }).filter((course) => course.course.id != null && course.workPositions[0]?.id != null); // we only need items where workpoisition exist, without it key was from course or courseGroup key
    const assignedCoursesMap = this.courseWorkPositionsByCourses(assignedWorkpositionsFromKeys);
    const assignedCourses = Object.keys(assignedCoursesMap).filter((key: string) => key != null).map((key: string) => ({ course: { id: key }, workPositions: assignedCoursesMap[key] }));
    this.props.onSubmit(assignedCourses);
  };

  updateCoursesMap = () => {
    const courseList = this.props.allCoursesList.map((course) => ({ course }));
    this.setState({
      coursesMap: this.coursesByCourseGroup(courseList),
    });
  };

  /** Receives course and its coursegroup and returns the corresponding treenode key of the course */
  getTreeKey = (courseGroup: ICourseGroup | undefined, course: ICourse, workPosition?: IWorkPosition): string => {
    const groupId = courseGroup?.id ?? COURSE_GROUP_MISSING_ID;
    const keys = workPosition ? [groupId, course.id, workPosition.id] : [groupId, course.id];
    return keys.join(ID_SEPARATOR);
  };

  getKeys = (treeKey: string): IUserGroupCourseKeys => {
    const keys = treeKey.split(ID_SEPARATOR);
    return { courseGroupId: keys[0], courseId: keys[1], workPositionId: keys[2] };
  };

  /** Maps courses to their coursegroups in an object with keys that corresponds to coursegroup id */
  coursesByCourseGroup = (courseList: Array<{ course: ICourse; }>): ICoursesByCourseGroupMap => {
    return courseList.reduce((accum, course) => {
      // get course groups
      const courseGroups = this.getCourseGroupsById(course.course.courseGroups);

      let refList: Array<IIdRef<string>>;

      // if course group is missing from list or course's group ID is invalid, assign course to "ungrouped list"
      if (courseGroups.length === 0) {
        refList = [{ id: COURSE_GROUP_MISSING_ID }];
      } else {
        refList = courseGroups.map((courseGroup) => ({ id: courseGroup.id }));
      }
      refList.forEach((ref) => {
        if (accum[ref.id] == null) {
          accum[ref.id] = [course];
        } else {
          accum[ref.id].push(course);
        }
      });
      return accum;
    }, {} as ICoursesByCourseGroupMap);
  };

  /** Maps courses to their coursegroups in an object with keys that corresponds to coursegroup id */
  courseWorkPositionsByCourses = (courseList: IUserGroupCoursePayload[]): IWorkPositionsByCourseMap => {
    return courseList.reduce((accum, item) => {
      if (accum[item.course.id] == null) {
        accum[item.course.id] = [];
      }
      if (item.workPositions) {
        accum[item.course.id].push(...item.workPositions);
      }
      return accum;
    }, {} as IWorkPositionsByCourseMap);
  };

  /** Return list of course groups found in reference list. */
  getCourseGroupsById(refList: Array<IIdRef<string>>): ICourseGroup[] {
    return this.props.courseGroups.filter((courseGroup) => refList.find((ref) => ref.id === courseGroup.id) != null);
  }

  /** Return course group by ID. */
  getCourseGroupById(id: string): ICourseGroup | undefined {
    return this.props.courseGroups.find((courseGroup) => courseGroup.id === id);
  }

  /** Saves courses ids from TreeNode keys without parent Nodes */
  onAssignCourses = (courses: string[]) => {
    this.setState({
      assignedCourses: courses,
    });
  };

  /** Checks boxes of courses that are already assigned to workposition */
  checkboxAssignedKeys = (courseList: IUserGroupCourse[]) => {
    // map courses to coursegroups where they belong
    const checkedCoursesMap = this.coursesByCourseGroup(courseList);
    const checkedKeys: string[] = [];

    // go through checkedCoursesMap and map courses in a single array
    Object.keys(checkedCoursesMap).forEach((courseGroupId) => {
      const courseGroup = this.getCourseGroupById(courseGroupId);
      const courses = checkedCoursesMap[courseGroupId];

      courses.forEach((course) => {
        if (course.workPositions) {
          course.workPositions.forEach((workPosition) => checkedKeys.push(this.getTreeKey(courseGroup, course.course, workPosition)));
        } else {
          checkedKeys.push(this.getTreeKey(courseGroup, course.course));
        }

      });
    });

    this.setState({ assignedCourses: checkedKeys });
  };

}

// -- HOCs and exports
// ----------

export default withLocalize<ICourseWorkpositionTreeOwnProps>(CourseWorkpositionTree as any);
