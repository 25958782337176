import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import useEntityModalsState from '@src/components/common/hook/useEntityModalsState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ExternalEducationTemplateFormModal from '@src/components/externaleducation/form/ExternalEducationTemplateFormModal';
import ExternalEducationTemplateListView from '@src/components/externaleducation/list/ExternalEducationTemplateListView';
import { IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { IUserDetails } from '@src/model/user/UserDetails';
import { ICollectionData, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import { ICollectionFetchPayload } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import ExternalEducationTemplateBusinessStore, { IExternalEducationTemplateCreatePayload } from '@src/service/business/externaleducations/ExternalEducationTemplateBusinessStore';
import ExternalEducationTemplateListBusinessStore, { IExternalEducationTemplateListFilter } from '@src/service/business/externaleducations/ExternalEducationTemplateListBusinessStore';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import UrlBuilderFactory from '@src/service/util/UrlBuilderFactory';

// -- Const
// ----------
const EXTERNAL_EDUCATION_TEMPLATE_ROUTE = 'externaleducationtemplate/';

const VIEW_NAME = '@@ORGANIZATION_EXTERNAL_EDUCATION_TEMPLATE_LIST';

// -- Prop types
// ----------
export interface IOrganizationExternalEducationTemplateListContainerOwnProps {
  educationProviderId: string;
  educationProviderStatus: boolean;
}
export interface IOrganizationExternalEducationTemplateListContainerStateProps {
  currentUser: IUserDetails;
  externalEducationTemplateList: ICollectionData<IExternalEducationTemplate>;
}
export interface IOrganizationExternalEducationTemplateListContainerDispatchProps {
  reportMessage: (data: IUserFeedbackMessagePayload) => void;

  createExternalEducationTemplate: (data: IExternalEducationTemplateCreatePayload) => ITrackableAction;
  updateExternalEducationTemplate: (data: IExternalEducationTemplate) => ITrackableAction;

  fetchList: (params: ICollectionFetchPayload<IExternalEducationTemplateListFilter>) => any;
  clearList: () => void;

  storeFilter: (filter: IExternalEducationTemplateListFilter) => void;
  clearFilter: () => void;
}
type IOrganizationExternalEducationTemplateListContainerProps = IOrganizationExternalEducationTemplateListContainerOwnProps & IOrganizationExternalEducationTemplateListContainerStateProps & IOrganizationExternalEducationTemplateListContainerDispatchProps & IWithLocalizeOwnProps & WithRouterProps;

// -- Component
// ----------
const OrganizationExternalEducationTemplateListContainer = (props: IOrganizationExternalEducationTemplateListContainerProps) => {

  const handleEducationProviderIdUpdate = () => {
    onUpdateList(props.fetchList, { ...collectionParams.filter, educationProvider: props.educationProviderId });
  };

  const [collectionParams, updateCollectionParams, onUpdateList] = useCollectionState<IExternalEducationTemplateListFilter>({
    viewName: VIEW_NAME,
    updateFn: handleEducationProviderIdUpdate,
  });

  const [templateModalsState, toggleTemplateModals] = useEntityModalsState<IExternalEducationTemplate>();

  const handleAdd = () => {
    toggleTemplateModals.onOpenCreate();
  };

  const handleEdit = (selectedExternalEducationTemplate: IExternalEducationTemplate) => {
    toggleTemplateModals.onOpenUpdate(selectedExternalEducationTemplate);
  };

  const handleCreate = (template: IExternalEducationTemplateCreatePayload) => {
    props.createExternalEducationTemplate(template).track().subscribe(
      // success
      (response: IExternalEducationTemplate) => {
        props.reportMessage({ message: props.translate('EXTERNAL_EDUCATION_TEMPLATE.CREATE_INFO_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
        toggleTemplateModals.onCloseCreate();
        onUpdateList();
        props.router.push(UrlBuilderFactory.createApplicationBuilder().urlPart(EXTERNAL_EDUCATION_TEMPLATE_ROUTE + response.id).build());
      }
    );
  };

  const handleUpdate = (template: IExternalEducationTemplate) => {
    props.updateExternalEducationTemplate(template).track().subscribe(
      // success
      () => {
        props.reportMessage({ message: props.translate('EXTERNAL_EDUCATION_TEMPLATE.UPDATE_INFO_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
        toggleTemplateModals.onCloseUpdate();
        onUpdateList();
      }
    );
  };

  return (
    <React.Fragment>
      {props.externalEducationTemplateList &&
        <ExternalEducationTemplateListView
          isEducationProviderActive={props.educationProviderStatus}
          externalEducationTemplateList={props.externalEducationTemplateList}
          externalEducationTemplateListFilter={collectionParams.filter}
          onExternalEducationTemplateEdit={handleEdit}
          onPageChange={updateCollectionParams.onPageChange}
          onSortChange={updateCollectionParams.onSortChange}
          onFilterChange={updateCollectionParams.onFilterChange}
          canAdd={true}
          onAdd={handleAdd}
        />
      }

      {(templateModalsState.isCreateModalVisible || templateModalsState.selectedEntity || templateModalsState.isUpdateModalVisible) && <ExternalEducationTemplateFormModal
        externalEducationTemplate={templateModalsState.selectedEntity}
        educationProviderId={props.educationProviderId}
        onCancel={toggleTemplateModals.onCloseCreate}
        onCreate={handleCreate}
        onUpdate={handleUpdate} />}
    </React.Fragment>
  );

};
// -- HOCs and exports
// ----------
// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IOrganizationExternalEducationTemplateListContainerOwnProps): IOrganizationExternalEducationTemplateListContainerStateProps => ({
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
  externalEducationTemplateList: ExternalEducationTemplateListBusinessStore.selectors.getExternalEducationTemplateList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IOrganizationExternalEducationTemplateListContainerDispatchProps => ({
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),

  fetchList: (params: ICollectionFetchPayload<IExternalEducationTemplateListFilter>) => dispatch(ExternalEducationTemplateListBusinessStore.actions.fetchExternalEducationTemplateList(params)),
  clearList: () => dispatch(ExternalEducationTemplateListBusinessStore.actions.clearExternalEducationTemplateList()),

  storeFilter: (filter: IExternalEducationTemplateListFilter) => dispatch(ExternalEducationTemplateListBusinessStore.actions.storeExternalEducationTemplateListFilter(filter)),
  clearFilter: () => dispatch(ExternalEducationTemplateListBusinessStore.actions.clearExternalEducationTemplateListFilter()),

  createExternalEducationTemplate: (data: IExternalEducationTemplateCreatePayload) => createTrackableAction(dispatch(ExternalEducationTemplateBusinessStore.actions.createExternalEducationTemplate(data))),
  updateExternalEducationTemplate: (data: IExternalEducationTemplate) => createTrackableAction(dispatch(ExternalEducationTemplateBusinessStore.actions.updateExternalEducationTemplate(data))),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<IOrganizationExternalEducationTemplateListContainerOwnProps>(withRouter(OrganizationExternalEducationTemplateListContainer as any)));
