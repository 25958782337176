import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

import { IUserInfo } from '@src/model/user/User';
import { UserTypeEnum } from '@src/model/user/UserType';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';

export interface IUserTypeRouteGuardOwnProps {
  type: UserTypeEnum;
}

export interface IUserTypeRouteGuardStateProps {
  currentUser: IUserInfo;
}
type IUserTypeRouteGuardProps = IUserTypeRouteGuardOwnProps & IUserTypeRouteGuardStateProps & WithRouterProps;

export interface IUserTypeGuardState {
  allowed: boolean;
}

class UserTypeRouteGuard extends React.Component<IUserTypeRouteGuardProps, IUserTypeGuardState> {
  state = {
    allowed: false,
  };

  componentDidMount() {
    this.check();
  }

  render() {
    return <React.Fragment>{this.state.allowed && this.props.children}</React.Fragment>;
  }

  private check() {
    const isAllowed = this.isAllowed();
    // always set state in case this component doesn't get unmounted on redirect
    this.setState({ allowed: isAllowed });

    if (!isAllowed) {
      this.redirectAway();
    }
  }

  private isAllowed(): boolean {
    if (this.props.currentUser == null) {
      console.warn('Cannot check user type if current user is empty. This protection does not work on public pages.');
      return false;
    }

    return this.props.type === this.props.currentUser.userType.id;
  }

  private redirectAway() {
    this.props.router.replace(this.getRedirectRoute());
  }

  private getRedirectRoute(): string {
    // default route for logged users - if user is not logged in, authetication route guard should take care of him
    return AppConfigService.getValue('routing.authDefaultRoute');
  }
}

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IUserTypeRouteGuardStateProps => ({
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
});

// store exported component so we can use it in withXXX HOC
const ExportComponent = connect<IUserTypeRouteGuardStateProps, any, IUserTypeRouteGuardOwnProps>(mapStateToProps)(withRouter(UserTypeRouteGuard));
export default ExportComponent;

// ----- withUserRoleRouteGuard

/** Higher order component for wrapping existing component with route guard. */
const withUserTypeRouteGuard = <P extends object>(Component: React.ComponentType<P>, type: UserTypeEnum) =>
  // tslint:disable-next-line:max-classes-per-file - don't want to declare this in a new file since it is only a util
  class WrappedComponent extends React.Component<P> {
    render() {
      // TODO: find how to type router Route props
      const wrappedProps = (this.props as any).route.props;
      return (
        <ExportComponent {...wrappedProps} type={type}>
          <Component {...this.props} />
        </ExportComponent>
      );
    }
  };

export { withUserTypeRouteGuard };
