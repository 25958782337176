import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import CalendarNavigation from '@src/components/reminder/calendar/CalendarNavigation';
import { IReminder } from '@src/model/reminder/Reminder';
import { Calendar } from 'antd';
import moment, { Moment } from 'moment';
import React from 'react';

// -- Prop types
// ----------

export interface IReminderCalendarOwnProps {
  reminderList: IReminder[];
  selectedDate?: Moment;
  selectedMonth: Moment;
  onCalendarDateChange: (newDate?: Moment) => void;
  onCalendarDateSelect: (newDate?: Moment) => void;
}

type IReminderCalendarProps = IReminderCalendarOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Reminder calendar component ... */
class ReminderCalendar extends React.Component<IReminderCalendarProps> {
  renderDateCell = (date: Moment) => {
    const cellWithReminder = this.props.reminderList && this.props.reminderList.find((item) => {
      return moment(item.startDateTime).isSame(date, 'day');
    });
    const isSelectedDate = date.date() === this.props.selectedDate?.date() && date.month() === this.props.selectedDate?.month();

    let dateContent = <React.Fragment>{date.get('date')}</React.Fragment>;

    if (cellWithReminder) {
      dateContent = <span className="timun-reminderCalendar__hasReminder">{dateContent}</span>;
    }

    return <div className={`timun-reminderCalendar__${isSelectedDate ? 'selectedDate' : 'date'}`}>
      <div className="timun-reminderCalendar__value">{dateContent}</div>
    </div>;
  };

  render = () => {
    return <React.Fragment>
      <CalendarNavigation currentDate={this.props.selectedMonth} onDateChange={this.props.onCalendarDateChange}/>
      <Calendar
        disabledDate={this.disabledDates}
        dateFullCellRender={this.renderDateCell}
        mode={'month'}
        value={this.props.selectedMonth}
        fullscreen={false}
        onChange={this.props.onCalendarDateChange}
        onSelect={this.props.onCalendarDateSelect}
      />
    </React.Fragment>;
  };

  disabledDates = (currentDate: Moment) => {
    const today = moment();

    return currentDate.isBefore(today.startOf('month'));
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<IReminderCalendarOwnProps>(ReminderCalendar as any);

