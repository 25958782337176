import React from 'react';
import { connect } from 'react-redux';

import CodebookShortInfoForm from '@src/components/codebook/form/CodebookShortInfoForm';
import CodeBookListView from '@src/components/codebook/view/CodeBookListView';
import withCollectionState, { IWithCollectionStateOwnProps, IWithCollectionStatePublicProps } from '@src/components/common/collectionParams/withCollectionState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IEducationCategory } from '@src/model/educationcategory/EducationCategory';
import { ICollectionData, ICollectionFetchPayload, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import EducationCategoryBusinessStore, { IEducationCategoryCreatePayload, IEducationCategoryListFilter } from '@src/service/business/educationcategory/educationCategoryBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

// -- Const
// ----------
const DEFAULT_PAGE_SIZE_VALUE = AppConfigService.getValue('api.collectionDefaultLimit');
const VIEW_NAME = '@@EDUCATION_CATEGORY_CODEBOOK';
const collectionDefaults: IWithCollectionStatePublicProps<IEducationCategoryListFilter> = {
  viewName: VIEW_NAME,
  initialValues: { size: DEFAULT_PAGE_SIZE_VALUE },
};

// -- Prop types
// ----------
export interface IEducationCategoryCodebookContainerOwnProps { }
export interface IEducationCategoryCodebookContainerStateProps {
  educationCategoryList: ICollectionData<IEducationCategory>;
}
export interface IEducationCategoryCodebookContainerDispatchProps {
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
  fetchEducationCategoryList: (params: ICollectionFetchPayload<IEducationCategoryListFilter>) => ITrackableAction;
  createEducationCategory: (data: IEducationCategoryCreatePayload) => ITrackableAction;
  updateEducationCategory: (data: IEducationCategory) => ITrackableAction;
}
type IEducationCategoryCodebookContainerProps = IEducationCategoryCodebookContainerOwnProps & IEducationCategoryCodebookContainerStateProps & IEducationCategoryCodebookContainerDispatchProps & IWithLocalizeOwnProps & IWithCollectionStateOwnProps<IEducationCategoryListFilter>;

interface IEducationCategoryCodebookContainerState {
  educationCategoryModalVisible: boolean;
  educationCategoryToEdit?: IEducationCategory;
}

// -- Component
// ----------

/** Coded grade container */
class EducationCategoryCodebookContainer extends React.Component<IEducationCategoryCodebookContainerProps, IEducationCategoryCodebookContainerState> {
  state: IEducationCategoryCodebookContainerState = {
    educationCategoryModalVisible: false,
    educationCategoryToEdit: undefined,
  };

  componentDidMount() {
    this.fetchEducationCategoryList();
  }

  componentDidUpdate(prevProps: IEducationCategoryCodebookContainerProps, prevState: IEducationCategoryCodebookContainerState) {
    if (prevProps.collectionParams !== this.props.collectionParams) {
      this.fetchEducationCategoryList();
    }
  }

  render = () => {
    return (
      <React.Fragment>
        {this.props.educationCategoryList && <CodeBookListView<IEducationCategory>
          title={this.props.translate('CODEBOOK.TITLE.EDUCATION_CATEGORY')}
          descriptionPrefix={'EDUCATION_CATEGORY.DESCRIPTION_LABEL'}
          buttonLabel={this.props.translate('EDUCATION_CATEGORY.CREATE_BUTTON_LABEL')}
          codebookItemList={this.props.educationCategoryList}
          canEdit={true}
          onEditCodebookItemClick={this.handleEducationCategoryUpdateModalOpen}
          showBackColumn={true}
          onCreateCodebookItemClick={this.handleEducationCategoryCreateModalOpen}
          onPageChange={this.props.updateCollectionParams.onPageChange}
        />}

        {this.state.educationCategoryModalVisible && <CodebookShortInfoForm<IEducationCategory, IEducationCategoryCreatePayload>
          value={this.state.educationCategoryToEdit}
          onCancel={this.handleCodebookModalCancel}
          onUpdate={this.handleEducationCategoryUpdateSubmit}
          onCreate={this.handleEducationCategoryCreateSubmit} />}
      </React.Fragment>
    );
  };

  toggleCodebookModal = (isVisible: boolean) => {
    this.setState({ educationCategoryModalVisible: isVisible });
  };

  handleCodebookModalCancel = () => {
    this.setState({ educationCategoryToEdit: undefined });
    this.toggleCodebookModal(false);
  };

  handleEducationCategoryCreateModalOpen = () => {
    this.toggleCodebookModal(true);
  };

  handleEducationCategoryUpdateModalOpen = (EducationCategory: IEducationCategory) => {
    this.setState({ educationCategoryToEdit: EducationCategory });
    this.toggleCodebookModal(true);
  };

  private handleEducationCategoryCreateSubmit = (data: IEducationCategoryCreatePayload) => {
    this.props.createEducationCategory(data).track().subscribe(
      // success
      () => {
        this.props.reportMessage({ message: this.props.translate('EDUCATION_CATEGORY.CREATE_INFO_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
        this.fetchEducationCategoryList();
        this.handleCodebookModalCancel();
      }
    );
  };

  private handleEducationCategoryUpdateSubmit = (data: IEducationCategory) => {
    this.props.updateEducationCategory(data).track().subscribe(
      // success
      () => {
        this.fetchEducationCategoryList();
        this.handleCodebookModalCancel();
      }
    );
  };

  private fetchEducationCategoryList = () => {
    this.props.onUpdateList(this.props.fetchEducationCategoryList);
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IEducationCategoryCodebookContainerOwnProps): IEducationCategoryCodebookContainerStateProps => ({
  educationCategoryList: EducationCategoryBusinessStore.selectors.getEducationCategoryList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IEducationCategoryCodebookContainerDispatchProps => ({
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
  fetchEducationCategoryList: (params: ICollectionFetchPayload<IEducationCategoryListFilter>) => dispatch(createTrackableAction(EducationCategoryBusinessStore.actions.fetchEducationCategoryList(params))),
  createEducationCategory: (data: IEducationCategoryCreatePayload) => dispatch(createTrackableAction(EducationCategoryBusinessStore.actions.createEducationCategory(data))),
  updateEducationCategory: (data: IEducationCategory) => dispatch(createTrackableAction(EducationCategoryBusinessStore.actions.updateEducationCategory(data))),
});

export default connect<IEducationCategoryCodebookContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withCollectionState(collectionDefaults)(withLocalize(EducationCategoryCodebookContainer as any)));
