import { ISkillLevelClassification } from '@src/model/skillgroup/SkillLevelClassification';

export interface ISkillBasedRecommendation {
  id: string;
  compatibility: number;
  title: string;
  subtitle: string;
  skillLevelClassifications: ISkillLevelClassification[];
}

export enum SkillBasedRecommendationType {
  USER = '1',
  WORKPOSITION = '2',
}

export enum SkillBasedRecommendationValueRelationTypeEnum {
  LOWER = '1',
  HIGHER = '2',
  BETWEEN = '3',
}

export const SkillBasedRecommendationValueRelationTypes: ISkillBasedRecommendationValueRelationType = { ...SkillBasedRecommendationValueRelationTypeEnum };

interface ISkillBasedRecommendationValueRelationType {
  // by default TS considers enum key type to be number
  [key: string]: string;
}
