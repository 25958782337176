import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import courseViewBusinessStore, { ICourseLectureListFilter } from '@src/service/business/courses/courseViewBusinessStore';

import AppContent from '@src/components/common/container/AppContent';
import LemonIcon from '@src/components/common/image/LemonIcon';
import ProgressBar from '@src/components/common/progressbar/ProgressBar';
import UserInterfaceUtils from '@src/components/common/util/UserInterfaceUtils';
import CourseHelperUtils from '@src/components/course/common/CourseHelperUtils';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import CourseEnrollButtonContainer from '@src/components/course/CourseEnrollButtonContainer';
import AdditionalCourseListContainer from '@src/components/course/list/additional/AdditionalCourseListContainer';
import RelatedObjects from '@src/components/course/RelatedObjects';
import CourseViewLectureContent from '@src/components/course/view/CourseViewLectureContent';
import CourseViewSider from '@src/components/course/view/CourseViewSider';
import TagContainer from '@src/components/tag/TagContainer';
import NoteListViewContainer from '@src/components/user/view/NoteListViewContainer';
import { CommentObjectTypeEnum } from '@src/model/comment/CommentObjectType';
import IIdRef from '@src/model/common/IdRef';
import { CourseCompletionStatusEnum, ICourse } from '@src/model/course/Course';
import { ILecture, ILectureWithContent } from '@src/model/course/Lecture';
import { ICourseLectureListElement } from '@src/model/course/LectureGroup';
import { IFile } from '@src/model/file/File';
import { TagItemTypeEnum } from '@src/model/tag/TagItemType';
import { NoteObjectTypeEnum } from '@src/model/user/Note';
import { ICollectionData, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { Layout, Typography } from 'antd';
import { withRouter, WithRouterProps } from 'react-router';

const { Header, Sider, Content } = Layout;

const COURSE_ABOUT_ROUTE = AppConfigService.getValue('components.courses.courseAboutRouterProp');
const COURSE_EXAM_INSTANCE_ROUTE = AppConfigService.getValue('components.courses.courseExamInstanceRouterProp');

// -- Prop types
// ----------

export interface ICourseViewContainerOwnProps {
  courseId?: string;
  lectureId: string;
}
export interface ICourseViewContainerStateProps {
  course: ICourse;
  lecture: ILectureWithContent;
  courseLectureList: ICourseLectureListElement[];
  lectureFileList: ICollectionData<IFile>;
}
export interface ICourseViewContainerDispatchProps {
  fetchCourseContent: (courseId: string) => void;
  clearCourseContentData: () => void;
  fetchLectureContent: (lectureId: string) => ITrackableAction;
  clearLectureContentData: () => void;
  fetchCourseLectureList: (courseId: string, listFilter: ICourseLectureListFilter) => void;
  clearCourseLectureList: () => void;
  fetchLectureFileList: (lectureId: string) => any;
  clearLectureFileList: () => void;

  updateCourseProgress: (courseId: string, finishedLecture: IIdRef<string>) => ITrackableAction;
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
}
type ICourseViewContainerProps = ICourseViewContainerOwnProps & ICourseViewContainerStateProps & ICourseViewContainerDispatchProps & IWithLocalizeOwnProps & WithRouterProps;

interface ICourseViewContainerState { }

// -- Component
// ----------

/** container for the display of a course and lectures inside */
class CourseViewContainer extends React.Component<ICourseViewContainerProps, ICourseViewContainerState> {
  state = {};

  componentDidMount = () => {
    // initial list update
    if (this.props.courseId) {
      this.fetchCourse();
      if (CourseHelperUtils.isLecture(this.props.lectureId)) {
        this.updateLectureContent();
        this.fetchLectureFileList();
      }
    } else if (this.props.lectureId) {
      this.fetchLectureCourse();
      if (CourseHelperUtils.isLecture(this.props.lectureId)) {
        this.updateLectureContent();
        this.fetchLectureFileList();
      }
    } else {
      console.warn('Bad route to course/lecture');
    }
  };

  componentDidUpdate = (prevProps: ICourseViewContainerProps) => {
    if (this.props !== prevProps && this.props.courseId !== prevProps.courseId) {
      this.fetchCourse();
    }
    if (this.props !== prevProps && this.props.lectureId !== prevProps.lectureId && CourseHelperUtils.isLecture(this.props.lectureId)) {
      this.updateLectureContent();
      this.fetchLectureFileList();
      UserInterfaceUtils.scrollToTop();
    }
  };

  componentWillUnmount = () => {
    this.props.clearCourseContentData();
    this.props.clearLectureContentData();
    this.props.clearCourseLectureList();
    this.props.clearLectureFileList();
  };

  render = () => {
    const canProgress = this.props.course?.progress?.completedLecturesNumber !== this.props.course?.progress?.lecturesNumber && (this.props.course?.progress?.completionStatus.id === CourseCompletionStatusEnum.IN_PROGRESS || this.props.course?.progress?.completionStatus.id === CourseCompletionStatusEnum.TO_DO);
    const canStart = this.props.course && this.props.course.progress && this.props.course.progress.completionStatus.id === CourseCompletionStatusEnum.TO_DO;

    return (
      <AppContent>
        {this.props.course && (
          <Layout>
            <Header className="timun-courseView__sider-courseTitle">
              <HeaderTitle link={'/dashboard'} title={this.props.translate('APP_NAVIGATION.MY_PROFILE')} icon={<LemonIcon name="left" />} />
              <Typography.Title> {this.props.course.title} </Typography.Title>
              <TagContainer entityId={this.props.course.id} entityType={TagItemTypeEnum.COURSE} tags={this.props.course.tags} onEntityTagUpdate={this.fetchCourse} />
            </Header>
            <Layout>
              <Sider className="timun-courseView__sider" breakpoint="lg" collapsedWidth={0}>
                <CourseViewSider canSeeExamTemplate={false} lectureList={this.props.courseLectureList} selectedLecture={this.props.lectureId} course={this.props.course} showExamInstance={CourseHelperUtils.hasExamInstance(this.props.course.quiz)} />
              </Sider>
              <Content className="timun-courseView__content">
                <React.Fragment>
                  <ProgressBar progressProps={{ className: 'timun-courseView__content-progressBar', showInfo: false }} progress={this.props.course.progress} />
                  {/* About course */}
                  {CourseHelperUtils.isAboutCourseContent(this.props.lectureId) && (
                    <CourseViewLectureContent
                      title={this.props.course.title}
                      body={this.props.course.content && this.props.course.content.body}
                      coverImageUrl={this.props.course.coverImageUrl}
                      renderEnrollButton={() => <CourseEnrollButtonContainer course={this.props.course} onEnroll={this.fetchCourse} />}
                      canStart={canStart}
                      onCourseStart={canStart ? this.handleCourseStart : undefined}
                    />
                  )}

                  {/* Lecture */}
                  {CourseHelperUtils.isLecture(this.props.lectureId) && this.props.lecture && this.props.lectureFileList && (
                    <React.Fragment>
                      <CourseViewLectureContent
                        title={this.props.lecture.title}
                        body={this.props.lecture.content && this.props.lecture.content.body}
                        coverVideo={this.props.lecture.integrationVideo}
                        coverVideoFileUrl={this.props.lecture.videoUrl}
                        fileList={this.props.lectureFileList.content}
                        showProgress={canProgress && this.props.course.progress && this.props.course.progress.currentLecture.id === this.props.lectureId}
                        onCourseProgress={canProgress ? this.handleCourseProgress : undefined}
                      />
                      <RelatedObjects
                        noteObject={{ objectId: this.props.course.id, objectTypeId: NoteObjectTypeEnum.COURSE }}
                        commentObject={{
                          objectId: this.props.lectureId,
                          objectTypeId: CommentObjectTypeEnum.LECTURE,
                        }}
                      />
                    </React.Fragment>
                  )}

                  {/* ----- Course notes ----- */}
                  {CourseHelperUtils.isNotesCourseContent(this.props.lectureId) && this.props.courseId && <NoteListViewContainer objectId={this.props.courseId} />}
                </React.Fragment>
              </Content>
            </Layout>
            <AdditionalCourseListContainer course={this.props.course} getPath={this.getPath} />
          </Layout>
        )}
      </AppContent>
    );
  };

  handleCourseStart = () => {
    if (this.props.course && this.props.course.progress) {
      const firstLecture = this.props.course.progress.currentLecture.id; // by default first lecture is this after enroll
      this.props.router.replace(`/course/${this.props.course.id}/${firstLecture}`);
    }
  };

  handleCourseProgress = (results?: any) => {
    const finishedLecture: IIdRef<string> = { id: this.props.lectureId };
    if (this.props.courseId) {
      this.props.updateCourseProgress(this.props.courseId, finishedLecture).track().subscribe(
        // success
        (data: ICourse) => {
          if (data.progress && data.progress.completionStatus.id === CourseCompletionStatusEnum.IN_PROGRESS) {
            const nextAssignment = data.progress.completedLecturesNumber === data.progress.lecturesNumber ? `${COURSE_EXAM_INSTANCE_ROUTE}/${data.quiz.examInstance.id}` : data.progress.currentLecture.id;
            this.props.router.replace(`/course/${this.props.courseId}/${nextAssignment}`);
          } else {
            // completed course message
            this.props.router.replace(`/course/${this.props.courseId}/${COURSE_ABOUT_ROUTE}`);
            this.props.reportMessage({ message: this.props.translate('COURSE_VIEW.COURSE_COMPLETED_MESSAGE', { courseName: this.props.course.title }), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
          }
        });
    }
  }

  private getPath = (id: string) => {
    return `/course/${id}`;
  };

  private fetchLectureCourse = (lectureId = this.props.lectureId) => {
    this.props
      .fetchLectureContent(lectureId)
      .track()
      .subscribe((response: ILecture) => {
        this.fetchCourse(response.course.id);
      });
  };

  private fetchCourse = (courseId = this.props.courseId) => {
    if (courseId) {
      this.props.fetchCourseContent(courseId);
      this.props.fetchCourseLectureList(courseId, {});
    }
  };

  private updateLectureContent = (lectureId = this.props.lectureId) => {
    this.props.fetchLectureContent(lectureId);
  };

  private fetchLectureFileList(lectureId: string = this.props.lectureId) {
    this.props.fetchLectureFileList(lectureId);
  }
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ICourseViewContainerOwnProps): ICourseViewContainerStateProps => ({
  course: courseViewBusinessStore.selectors.getCourseContent(state),
  lecture: courseViewBusinessStore.selectors.getLectureContent(state),
  courseLectureList: courseViewBusinessStore.selectors.getCourseLectureList(state),
  lectureFileList: courseViewBusinessStore.selectors.getLectureFileList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: Dispatch): ICourseViewContainerDispatchProps => ({
  fetchCourseContent: (courseId: string) => dispatch(courseViewBusinessStore.actions.fetchCourseContent({ id: courseId })),
  clearCourseContentData: () => dispatch(courseViewBusinessStore.actions.clearCourseContentData()),
  fetchLectureContent: (id: string) => createTrackableAction(dispatch(courseViewBusinessStore.actions.fetchLectureContent({ id }))),
  clearLectureContentData: () => dispatch(courseViewBusinessStore.actions.clearLectureContentData()),
  fetchCourseLectureList: (courseId: string, listFilter: ICourseLectureListFilter) => dispatch(courseViewBusinessStore.actions.fetchCourseLectureList(courseId, listFilter)),
  clearCourseLectureList: () => dispatch(courseViewBusinessStore.actions.clearCourseLectureList()),
  fetchLectureFileList: (lectureId: string) => dispatch(courseViewBusinessStore.actions.fetchLectureFileList(lectureId)),
  clearLectureFileList: () => dispatch(courseViewBusinessStore.actions.clearLectureFileList()),

  updateCourseProgress: (courseId: string, finishedLecture: IIdRef<string>) => dispatch(createTrackableAction(courseViewBusinessStore.actions.updateCourseProgress(courseId, finishedLecture))),
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
});

export default withLocalize<ICourseViewContainerOwnProps>(connect(mapStateToProps, mapDispatchToProps)(withRouter(CourseViewContainer as any)));
