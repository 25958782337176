import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import TagView from '@src/components/tag/TagView';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import { ITag } from '@src/model/tag/Tag';
import { ITagItemType } from '@src/model/tag/TagItemType';
import { IUserInfo } from '@src/model/user/User';
import { ICollectionData } from '@src/service/business/common/types';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import TagBusinessStore, { ITagListFilter } from '@src/service/business/tag/TagBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { debounce } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

const tagPageSize = AppConfigService.getValue('api.paging.maxPageSize');
const tagPageNumber = 0; // tags should be searched through filter

// -- Prop types
// ----------

export interface ITagContainerOwnProps {
  entityId: string;
  entityType: string;
  tags?: ITag[];
  onEntityTagUpdate?: () => void;
}

export interface ITagContainerStateProps {
  currentUser: IUserInfo;
  tagList: ICollectionData<ITag>;
  tagListFilter: ITagListFilter;
}

export interface ITagContainerDispatchProps {
  fetchTagList: (listFilter: ITagListFilter, page: number, size: number, sort: string[]) => void;
  clearTagList: () => void;
  createTag: (title: string) => ITrackableAction;
  removeTagFromEntity: (objectId: string, tag: ITag, type: ITagItemType) => ITrackableAction;
  addTagToEntity: (objectId: string, tag: ITag, type: ITagItemType) => ITrackableAction;
}
type ITagContainerProps = ITagContainerOwnProps & ITagContainerStateProps & ITagContainerDispatchProps & IWithTenantPropEnabledOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Component that displays entities tags and handles add/remove tags regarding role permissions */
class TagContainer extends React.Component<ITagContainerProps> {

  componentDidMount = () => {
    this.updateTagList();
  };

  render = () => {
    return <React.Fragment>
      {this.props.isTenantPropEnabled('repository') && <TagView
        tagList={this.props.tagList ? this.props.tagList.content : []}
        userRoles={this.props.currentUser.roles}
        tags={this.props.tags}
        onTagDelete={this.handleDeleteTag}
        onAddTagToEntity={this.handleAddTagToEntity}
        onCreateTag={this.handleCreateTag}
        onTagSearch={this.handleTagSearch}
      />}
    </React.Fragment>;
  };

  // tslint:disable-next-line: member-ordering
  handleTagSearch = debounce((value: string) => {
    const filter = { title: value };
    this.updateTagList(filter);
  }, AppConfigService.getValue(`components.repository.debounceTime`));

  getTagById = (tagId: string): ITag | undefined => {
    return this.props.tagList?.content.find((tag: ITag) => tag.id === tagId);
  };

  handleAddTagToEntity = (tag: ITag) => {
    this.props.addTagToEntity(this.props.entityId, tag, { id: this.props.entityType } as ITagItemType).track().subscribe(
      () => this.props.onEntityTagUpdate && this.props.onEntityTagUpdate()
    );
    this.updateTagList();
  };

  handleCreateTag = (value: string) => {
    this.props.createTag(value).track().subscribe(
      (newTag) => { // create a tag then add it to entity
        this.props.addTagToEntity(this.props.entityId, newTag, { id: this.props.entityType } as ITagItemType).track().subscribe(
          () => this.props.onEntityTagUpdate && this.props.onEntityTagUpdate()
        );
      }
    );
    this.updateTagList();
  };

  handleDeleteTag = (event: React.MouseEvent, tagId: string) => {
    event.preventDefault();
    const tag = this.getTagById(tagId);
    if (tag) {
      confirmationDialog({
        title: this.props.translate('COURSE_VIEW.UPDATE.DELETE_CONFIRM_TITLE'),
        okText: this.props.translate('COMMON.ACTION_DELETE'),
        onConfirm: () => this.props.removeTagFromEntity(this.props.entityId, tag, { id: this.props.entityType } as ITagItemType).track().subscribe(
          () => this.props.onEntityTagUpdate && this.props.onEntityTagUpdate()
        ),
      });
    }
  };

  private updateTagList = (filter: ITagListFilter = this.props.tagListFilter, page: number = tagPageNumber, pageSize: number = tagPageSize, sort: string[] = []) => {
    this.props.fetchTagList(filter, page, pageSize, sort);
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): ITagContainerStateProps => ({
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
  tagList: TagBusinessStore.selectors.getTagList(state),
  tagListFilter: TagBusinessStore.selectors.getTagListFilter(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ITagContainerDispatchProps => ({
  fetchTagList: (filter: ITagListFilter, page: number, size: number, sort: string[]) => dispatch(TagBusinessStore.actions.fetchTagList(filter, page, size, sort)),
  clearTagList: () => dispatch(TagBusinessStore.actions.clearTagList()),
  createTag: (title: string) => createTrackableAction(dispatch(TagBusinessStore.actions.createTag({ title }))),
  removeTagFromEntity: (objectId: string, tag: ITag, type: ITagItemType) => createTrackableAction(dispatch(TagBusinessStore.actions.removeTagFromEntity([objectId], [tag], type))),
  addTagToEntity: (objectId: string, tag: ITag, type: ITagItemType) => createTrackableAction(dispatch(TagBusinessStore.actions.addTagToEntity([objectId], [tag], type))),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<ITagContainerOwnProps>(withTenantPropEnabled(TagContainer) as any));
