import React from 'react';
import { connect } from 'react-redux';

import EducationQualificationForm from '@src/components/codebook/form/EducationQualificationForm';
import CodeBookListView from '@src/components/codebook/view/CodeBookListView';
import withCollectionState, { IWithCollectionStateOwnProps, IWithCollectionStatePublicProps } from '@src/components/common/collectionParams/withCollectionState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IEducationQualification } from '@src/model/educationqualification/EducationQualification';
import { ICollectionData, ICollectionFetchPayload, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import EducationQualificationBusinessStore, { IEducationQualificationCreatePayload, IEducationQualificationListFilter } from '@src/service/business/educationqualification/EducationQualificationBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

const VIEW_NAME = '@@EDUCATION_QUALIFICATION_CODEBOOK_LIST';
const collectionDefaults: IWithCollectionStatePublicProps<IEducationQualificationListFilter> = {
  viewName: VIEW_NAME,
  initialValues: {},
};


// -- Prop types
// ----------
export interface IEducationQualificationCodebookContainerOwnProps {
}

export interface IEducationQualificationCodebookContainerStateProps {
  educationQualificationList: ICollectionData<IEducationQualification>;
}

export interface IEducationQualificationCodebookContainerDispatchProps {
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
  fetchEducationQualificationList: (params: ICollectionFetchPayload<IEducationQualificationListFilter>) => ITrackableAction;
  createEducationQualification: (data: IEducationQualificationCreatePayload) => ITrackableAction;
  updateEducationQualification: (data: IEducationQualification) => ITrackableAction;
  deleteEducationQualification: (id: string) => ITrackableAction;
}

type IEducationQualificationCodebookContainerProps = IEducationQualificationCodebookContainerOwnProps & IEducationQualificationCodebookContainerStateProps
  & IEducationQualificationCodebookContainerDispatchProps & IWithLocalizeOwnProps & IWithCollectionStateOwnProps<IEducationQualificationListFilter>;

interface IEducationQualificationCodebookContainerState {
  isEducationQualificationModalVisible: boolean;
  educationQualificationToEdit?: IEducationQualification;
}


// -- Component
// ----------

/** Education qualification container */
class EducationQualificationCodebookContainer extends React.Component<IEducationQualificationCodebookContainerProps, IEducationQualificationCodebookContainerState> {
  state: IEducationQualificationCodebookContainerState = {
    isEducationQualificationModalVisible: false,
    educationQualificationToEdit: undefined,
  };

  componentDidMount() {
    this.fetchEducationQualificationList();
  }

  componentDidUpdate(prevProps: IEducationQualificationCodebookContainerProps, prevState: IEducationQualificationCodebookContainerState) {
    if (prevProps.collectionParams !== this.props.collectionParams) {
      this.fetchEducationQualificationList();
    }
  }

  render = () => {
    return (
      <React.Fragment>
        {this.props.educationQualificationList && <CodeBookListView<IEducationQualification>
          title={this.props.translate('CODEBOOK.TITLE.EDUCATION_QUALIFICATION')}
          buttonLabel={this.props.translate('EDUCATION_QUALIFICATION.CREATE_EDUCATION_QUALIFICATION_BUTTON_LABEL')}
          codebookItemList={this.props.educationQualificationList}
          canEdit={true}
          onEditCodebookItemClick={this.handleEducationQualificationUpdateModalOpen}
          onCreateCodebookItemClick={this.handleEducationQualificationModalOpen}
          canDelete={true}
          onCodebookItemDelete={this.handleEducationQualificationDeleteSubmit}
          onPageChange={this.props.updateCollectionParams.onPageChange}
        />}

        {this.state.isEducationQualificationModalVisible && <EducationQualificationForm
          educationQualification={this.state.educationQualificationToEdit}
          onCancel={this.handleEducationQualificationModalCancel}
          onEducationQualificationCreateSubmit={this.handleEducationQualificationCreateSubmit}
          onEducationQualificationUpdateSubmit={this.handleEducationQualificationUpdateSubmit} />}
      </React.Fragment>
    );
  };

  toogleEducationQualificationModal = (isVisible: boolean) => {
    this.setState({ isEducationQualificationModalVisible: isVisible });
  };

  handleEducationQualificationModalCancel = () => {
    this.setState({ educationQualificationToEdit: undefined });
    this.toogleEducationQualificationModal(false);
  };

  handleEducationQualificationModalOpen = () => {
    this.toogleEducationQualificationModal(true);
  };

  handleEducationQualificationUpdateModalOpen = (data: IEducationQualification) => {
    this.setState({ educationQualificationToEdit: data });
    this.handleEducationQualificationModalOpen();
  };

  private handleEducationQualificationDeleteSubmit = (data: IEducationQualification) => {
    this.props.deleteEducationQualification(data.id).track().subscribe(
      // success
      () => {
        this.props.reportMessage({ message: this.props.translate('EDUCATION_QUALIFICATION.DELETE_INFO_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
        this.fetchEducationQualificationList();
      }
    );
  };

  private handleEducationQualificationCreateSubmit = (data: IEducationQualificationCreatePayload) => {
    this.props.createEducationQualification(data).track().subscribe(
      // success
      () => {
        this.props.reportMessage({ message: this.props.translate('EDUCATION_QUALIFICATION.CREATE_INFO_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
        this.fetchEducationQualificationList();
        this.handleEducationQualificationModalCancel();
      }
    );
  };

  private handleEducationQualificationUpdateSubmit = (data: IEducationQualification) => {
    this.props.updateEducationQualification(data).track().subscribe(
      // success
      () => {
        this.fetchEducationQualificationList();
        this.handleEducationQualificationModalCancel();
      }
    );
  };

  private fetchEducationQualificationList = (): void => {
    this.props.onUpdateList(this.props.fetchEducationQualificationList);
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IEducationQualificationCodebookContainerOwnProps): IEducationQualificationCodebookContainerStateProps => ({
  educationQualificationList: EducationQualificationBusinessStore.selectors.getEducationQualificationList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IEducationQualificationCodebookContainerDispatchProps => ({
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
  fetchEducationQualificationList: (params: ICollectionFetchPayload<IEducationQualificationListFilter>) => createTrackableAction(dispatch(EducationQualificationBusinessStore.actions.fetchEducationQualificationList(params))),
  createEducationQualification: (data: IEducationQualificationCreatePayload) => createTrackableAction(dispatch(EducationQualificationBusinessStore.actions.createEducationQualification(data))),
  deleteEducationQualification: (id: string) => createTrackableAction(dispatch(EducationQualificationBusinessStore.actions.deleteEducationQualification({ id }))),
  updateEducationQualification: (data: IEducationQualification) => createTrackableAction(dispatch(EducationQualificationBusinessStore.actions.updateEducationQualification(data))),
});

export default connect<IEducationQualificationCodebookContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withCollectionState(collectionDefaults)(withLocalize(EducationQualificationCodebookContainer as any)));
