import LemonImage from '@src/components/common/image/LemonImage';
import { Tooltip } from 'antd';
import React from 'react';

const style = { width: '1px', height: '1px' }; // content in tooltip must have some width to be displayed properly

export interface ILemonInfoTooltipOwnProps {
  message: string;
}

type ILemonInfoTooltipProps = ILemonInfoTooltipOwnProps;

class LemonInfoTooltip extends React.Component<ILemonInfoTooltipProps> {
  render = () => {
    return <Tooltip overlay={this.props.message}>
      <div style={style}><LemonImage fallbackIconName="infoCircle"/></div>
    </Tooltip>;
  };
}

export default LemonInfoTooltip;
