import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import useEntityModalsState from '@src/components/common/hook/useEntityModalsState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import OrganizationForm from '@src/components/organization/edit/OrganizationForm';
import OrganizationListView from '@src/components/organization/list/OrganizationListView';
import { IOrganization } from '@src/model/organization/Organization';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import OrganizationBusinessStore, { IOrganizationCreatePayload } from '@src/service/business/organization/OrganizationBusinessStore';
import OrganizationListBusinessStore, { IOrganizationListFilter } from '@src/service/business/organization/OrganizationListBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

// -- Const
// ----------
const VIEW_NAME = '@@ADMIN_ORGANIZATION_LIST';

// -- Prop types
// ----------
export interface IAdminOrganizationListContainerOwnProps {
}

export interface IAdminOrganizationListContainerStateProps {
  organizationList: ICollectionData<IOrganization>;
}

export interface IAdminOrganizationListContainerDispatchProps {
  fetchOrganizationList: (filter: ICollectionFetchPayload<IOrganizationListFilter>) => ITrackableAction;
  createOrganization: (data: IOrganizationCreatePayload) => ITrackableAction;
  updateOrganization: (organization: IOrganization) => ITrackableAction;
  deleteOrganization: (organization: IOrganization) => ITrackableAction;
}

type IAdminOrganizationListContainerProps = IAdminOrganizationListContainerOwnProps & IAdminOrganizationListContainerStateProps & IAdminOrganizationListContainerDispatchProps & WithRouterProps & IWithLocalizeOwnProps;

// -- Component
// ---------- list of organizations for admin panel with update and create organization
const AdminOrganizationListContainer = (props: IAdminOrganizationListContainerProps) => {

  const [organizationModalsState, toggleOrganizationModals] = useEntityModalsState<IOrganization>();

  const [collectionParams, updateCollectionParams, onUpdateList] = useCollectionState<IOrganizationListFilter>({
    viewName: VIEW_NAME,
    updateFn: props.fetchOrganizationList,
  });

  const handleOrganizationUpdate = (organization: IOrganization) => {
    props.updateOrganization(organization).track().subscribe(
      // success
      () => {
        toggleOrganizationModals.onCloseUpdate();
        onUpdateList();
      }
    );
  };

  const handleCreateOrganization = (organization: IOrganizationCreatePayload) => {
    props.createOrganization(organization).track().subscribe(
      // success
      () => {
        toggleOrganizationModals.onCloseCreate();
        onUpdateList();
      }
    );
  };

  const handleDeleteOrganization = (organization: IOrganization) => {
    props.deleteOrganization(organization).track().subscribe(
      // success
      () => {
        onUpdateList();
      }
    );
  };

  return (
    <React.Fragment>
      {props.organizationList &&
        <OrganizationListView
          organizationList={props.organizationList}
          filter={collectionParams.filter}
          onFilterChange={updateCollectionParams.onFilterChange}
          canAdd={true}
          canDelete={true}
          canEdit={true}
          onOrganizationAdd={toggleOrganizationModals.onOpenCreate}
          onOrganizationEdit={toggleOrganizationModals.onOpenUpdate}
          onOrganizationDelete={handleDeleteOrganization}
          onPageChange={updateCollectionParams.onPageChange}
        />
      }

      {/* Modals */}
      {organizationModalsState.isCreateModalVisible &&
        <OrganizationForm
          title={props.translate('ORGANIZATION_FORM.TITLE_CREATE')}
          onCancel={toggleOrganizationModals.onCloseCreate}
          onSubmit={handleCreateOrganization}
        />
      }
      {organizationModalsState.isUpdateModalVisible && organizationModalsState.selectedEntity &&
        <OrganizationForm
          organization={organizationModalsState.selectedEntity}
          title={props.translate('ORGANIZATION_FORM.TITLE_EDIT')}
          onCancel={toggleOrganizationModals.onCloseCreate}
          onSubmit={handleOrganizationUpdate}
        />
      }
    </React.Fragment>
  );
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IAdminOrganizationListContainerStateProps => ({
  organizationList: OrganizationListBusinessStore.selectors.getOrganizationList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IAdminOrganizationListContainerDispatchProps => ({
  fetchOrganizationList: (params: ICollectionFetchPayload<IOrganizationListFilter>) => createTrackableAction(dispatch(OrganizationListBusinessStore.actions.fetchOrganizationList(params))),
  updateOrganization: (organization: IOrganization) => createTrackableAction(dispatch(OrganizationBusinessStore.actions.updateOrganization(organization))),
  deleteOrganization: (organization: IOrganization) => createTrackableAction(dispatch(OrganizationBusinessStore.actions.deleteOrganization(organization.id))),
  createOrganization: (data: IOrganizationCreatePayload) => createTrackableAction(dispatch(OrganizationBusinessStore.actions.createOrganization(data))),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withRouter<IAdminOrganizationListContainerOwnProps>(AdminOrganizationListContainer as any)));
