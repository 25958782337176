import React, { useEffect } from 'react';

import ActivityHistoryList from '@src/components/activity/list/ActivityHistoryList';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IActivity } from '@src/model/activity/Activity';
import { IActivityHistory } from '@src/model/activity/ActivityHistory';
import activityBusinessStore from '@src/service/business/examtemplates/activityBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { connect } from 'react-redux';

// -- Prop types
// ----------

export interface IActivityHistoryContainerOwnProps {
  activity: IActivity;
}

export interface IActivityHistoryContainerStateProps {
  activityHistory: IActivityHistory[];
}

export interface IActivityHistoryContainerDispatchProps {
  fetchActivityHistoryList: (id: string) => ITrackableAction;
}

type IActivityHistoryContainerProps = IActivityHistoryContainerOwnProps & IActivityHistoryContainerStateProps & IActivityHistoryContainerDispatchProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Display activity history list */
const ActivityHistoryContainer = (props: IActivityHistoryContainerProps) => {

  useEffect(() => {
    fetchExternalEducationActivityHistoryList();
  }, [props.activity.currentPhase.id, props.activity.id]);

  const fetchExternalEducationActivityHistoryList = () => {
    props.fetchActivityHistoryList(props.activity.id);
  };

  return (
    <React.Fragment>
      {props.activityHistory && <ActivityHistoryList activityHistoryList={props.activityHistory} />}
    </React.Fragment>
  );
};

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IActivityHistoryContainerOwnProps): IActivityHistoryContainerStateProps => ({
  activityHistory: activityBusinessStore.selectors.getActivityHistoryList(state, ownProps.activity.id),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IActivityHistoryContainerDispatchProps => ({
  fetchActivityHistoryList: (id: string) => createTrackableAction(dispatch(activityBusinessStore.actions.fetchActivityHistoryList(id))),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<IActivityHistoryContainerOwnProps>(ActivityHistoryContainer as any));
