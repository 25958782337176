import React from 'react';
import { connect } from 'react-redux';

import DataPicker, { IDataPickerItem } from '@src/components/common/datapicker/DataPicker';
import { ISkillLevelClassificationShortInfo } from '@src/model/skillgroup/SkillLevelClassification';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import SkillLevelClassificationListBusinessStore, { ISkillLevelClassificationShortInfoListFilter } from '@src/service/business/skilllevelclassification/SkillLevelClassificationBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

/* min search length
const minSearchLength = AppConfigService.getValue('components.common.minSearchStringLength');
*/

// -- Prop types
// ----------

export interface ISkillLevelDataPickerOwnProps {
  skillId?: string;
  value?: ISkillLevelClassificationShortInfo;
  disabled?: boolean;
  onChange?: (newValue?: ISkillLevelClassificationShortInfo) => void;
}

export interface ISkillLevelDataPickerStateProps {
}

export interface ISkillLevelDataPickerDispatchProps {
  fetchSkillLevelClassificationList: (skillId: string, params: ICollectionFetchPayload<ISkillLevelClassificationShortInfoListFilter>) => ITrackableAction;
  clearSkillLevelClassificationList: (skillId: string) => void;
}
type ISkillLevelDataPickerProps = ISkillLevelDataPickerOwnProps & ISkillLevelDataPickerStateProps & ISkillLevelDataPickerDispatchProps;

interface ISkillLevelDataPickerState {
  skillLevelList?: ISkillLevelClassificationShortInfo[] | null;
}

// -- Component
// ----------

/** Describe your component ... */
class SkillLevelDataPicker extends React.Component<ISkillLevelDataPickerProps, ISkillLevelDataPickerState> {
  state: ISkillLevelDataPickerState = {};

  componentDidMount = () => {
    if (this.props.skillId) {
      this.searchList(this.props.skillId);
    }
  };

  componentDidUpdate = (prevProps: ISkillLevelDataPickerProps, prevState: ISkillLevelDataPickerState) => {
    if (prevProps.skillId !== this.props.skillId) {
      if (this.props.skillId) {
        this.searchList(this.props.skillId);
      } else {
        this.storeList();
      }
    }
  };

  componentWillUnmount() {
    if (this.props.skillId) {
      this.props.clearSkillLevelClassificationList(this.props.skillId);
    }
  }

  render = () => {
    const pickerValue = this.props.value && this.mapToItem(this.props.value);
    const pickerItems = this.state.skillLevelList != null ? this.state.skillLevelList.map(this.mapToItem) : [];

    return <DataPicker value={pickerValue} items={pickerItems} disabled={this.props.disabled} allowClear={false} onChange={this.handleDataPickerChange} /* onSearch={this.handleDataPickerSearch} */ dataTestIdPrefix="timun-skillLevelPicker" />;
  };

  private handleDataPickerChange = (value?: IDataPickerItem<ISkillLevelClassificationShortInfo>) => {
    this.props.onChange?.(value?.data);
  };

  /* private handleDataPickerSearch = (value: string) => {
    if (value.length >= minSearchLength) {
      this.searchList(value);
    } else if (LangUtils.isEmpty(value) || value.length < minSearchLength) {
      this.storeList();
    }
  }; */

  /** Map entity ref list to data picker item list. */
  private mapToItem(skillLevel: ISkillLevelClassificationShortInfo): IDataPickerItem<ISkillLevelClassificationShortInfo> {
    return {
      value: skillLevel.skillLevel?.id,
      name: skillLevel.skillLevel?.name,
      data: skillLevel,
    };
  }

  private searchList(skillId: string, search?: string) {
    this.fetchList(skillId, {
      filter: {/* title: search */ },
      page: 0,
      size: AppConfigService.getValue('components.dataPicker.maxPageSize'),
      sort: [],
    });
  }

  private fetchList(skillId: string, params: ICollectionFetchPayload<ISkillLevelClassificationShortInfoListFilter>) {
    this.props.fetchSkillLevelClassificationList(skillId, params).track().subscribe(this.storeList);
  }

  private storeList = (skillLevelList?: ICollectionData<ISkillLevelClassificationShortInfo>) => {
    this.setState({ skillLevelList: skillLevelList?.content });
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ISkillLevelDataPickerOwnProps): ISkillLevelDataPickerStateProps => ({
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ISkillLevelDataPickerDispatchProps => ({
  fetchSkillLevelClassificationList: (skillId: string, params: ICollectionFetchPayload<ISkillLevelClassificationShortInfoListFilter>) => dispatch(createTrackableAction(SkillLevelClassificationListBusinessStore.actions.fetchSkillLevelClassificationShortInfoList(skillId, params))),
  clearSkillLevelClassificationList: (skillId: string) => dispatch(SkillLevelClassificationListBusinessStore.actions.clearSkillLevelClassificationShortInfoList(skillId)),
});

export default connect<ISkillLevelDataPickerStateProps, ISkillLevelDataPickerDispatchProps, ISkillLevelDataPickerOwnProps>(mapStateToProps, mapDispatchToProps)(SkillLevelDataPicker as any);
