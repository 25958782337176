import FileList from '@src/components/common/file/FileList';
import { IListAdditionalCol } from '@src/components/common/list/ListAdditionalCol';
import ListPagination from '@src/components/common/list/ListPagination';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import FileUpload, { IUploadStatus } from '@src/components/common/upload/FileUpload';
import { IFile } from '@src/model/file/File';
import { IUserGroupObject } from '@src/model/usergroup/UserGroupObject';
import { ICollectionData } from '@src/service/business/common/types';
import { IReferencedObjectListFilter } from '@src/service/business/usergroup/UserGroupReferencedObjectsBusinessStore';
import { Button, Row, Spin } from 'antd';
import React from 'react';

// -- Prop types
// ----------

export interface IUserGroupFileViewOwnProps {
  fileList: ICollectionData<IUserGroupObject<IFile>>;
  fileListFilter: IReferencedObjectListFilter;
  renderAdditionalCols?: () => IListAdditionalCol[];
  onPageChange: (page: number, pageSize?: number) => void;
  onFilterChange?: (filter: IReferencedObjectListFilter) => void;
  onUpload: (data: IFile) => void;
  onRemoveFile: (data: IFile) => void;
}

type IUserGroupFileViewProps = IUserGroupFileViewOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

class UserGroupFileView extends React.Component<IUserGroupFileViewProps> {
  render = () => {
    const fileListContent = this.props.fileList?.content.map((item) => item.object);
    return (
      <React.Fragment>
        <Row justify="end">
          <FileUpload onUploadFile={this.props.onUpload}>
            {(status: IUploadStatus) => (
              <React.Fragment>
                {status.isUploading && <Spin/>}&nbsp;
                <Button>+ {this.props.translate('USER_GROUP_LIST_VIEW.FILE_ADD_LABEL')}</Button>
              </React.Fragment>
            )}
          </FileUpload>
        </Row>
        {this.props.fileList && <ListPagination page={this.props.fileList.page} onChange={this.props.onPageChange}/>}
        {this.props.fileList && <FileList fileList={fileListContent} fileColWidth={22} showActions={true} onRemoveFile={this.props.onRemoveFile}/>}
        {this.props.fileList && <ListPagination page={this.props.fileList.page} onChange={this.props.onPageChange}/>}
      </React.Fragment>
    );
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<IUserGroupFileViewOwnProps>(UserGroupFileView as any);
