import IIdRef from '@src/model/common/IdRef';

export interface INote {
  addedDateTime: string;
  id: string;
  object: IIdRef<string>;
  objectType: INoteObjectType;
  text: string;
  public?: boolean;
}
export enum NoteObjectTypeEnum {
  COURSE = '1',
  EXAM_TEMPLATE = '2',
  WEBINAR = '3',
  EDUCATION_APPLICATION = '4',
  EXTERNAL_EDUCATION_TEMPLATE = '5',
}
export interface INoteObjectType {
  id: NoteObjectTypeEnum;
  name: string;
}
