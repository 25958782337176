import React from 'react';
import { connect } from 'react-redux';

import CodebookShortInfoForm from '@src/components/codebook/form/CodebookShortInfoForm';
import CodeBookListView from '@src/components/codebook/view/CodeBookListView';
import withCollectionState, { IWithCollectionStateOwnProps, IWithCollectionStatePublicProps } from '@src/components/common/collectionParams/withCollectionState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IEnrollmentRequirement } from '@src/model/enrollmentrequirement/EnrollmentRequirement';
import { ICollectionData, ICollectionFetchPayload, IUserFeedbackMessagePayload, UserFeedbackMessageSeverity, UserFeedbackMessageType } from '@src/service/business/common/types';
import UserFeedbackBusinessStore from '@src/service/business/common/userFeedbackBusinessProvider';
import EnrollmentRequirementBusinessStore, { IEnrollmentRequirementCreatePayload, IEnrollmentRequirementListFilter } from '@src/service/business/enrollmentrequirements/enrollmentRequirementBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

// -- Const
// ----------
const DEFAULT_PAGE_SIZE_VALUE = AppConfigService.getValue('api.collectionDefaultLimit');
const VIEW_NAME = '@@ENROLLMENT_REQUIREMENT_CODEBOOK';
const collectionDefaults: IWithCollectionStatePublicProps<IEnrollmentRequirementListFilter> = {
  viewName: VIEW_NAME,
  initialValues: { size: DEFAULT_PAGE_SIZE_VALUE },
};

// -- Prop types
// ----------
export interface IEnrollmentRequirementCodebookContainerOwnProps { }
export interface IEnrollmentRequirementCodebookContainerStateProps {
  enrollmentRequirementList: ICollectionData<IEnrollmentRequirement>;
}
export interface IEnrollmentRequirementCodebookContainerDispatchProps {
  reportMessage: (data: IUserFeedbackMessagePayload) => void;
  fetchEnrollmentRequirementList: (params: ICollectionFetchPayload<IEnrollmentRequirementListFilter>) => ITrackableAction;
  createEnrollmentRequirement: (data: IEnrollmentRequirementCreatePayload) => ITrackableAction;
  updateEnrollmentRequirement: (data: IEnrollmentRequirement) => ITrackableAction;
}
type IEnrollmentRequirementCodebookContainerProps = IEnrollmentRequirementCodebookContainerOwnProps & IEnrollmentRequirementCodebookContainerStateProps & IEnrollmentRequirementCodebookContainerDispatchProps & IWithLocalizeOwnProps & IWithCollectionStateOwnProps<IEnrollmentRequirementListFilter>;

interface IEnrollmentRequirementCodebookContainerState {
  enrollmentRequirementModalVisible: boolean;
  enrollmentRequirementToEdit?: IEnrollmentRequirement;
}

// -- Component
// ----------

/** Coded grade container */
class EnrollmentRequirementCodebookContainer extends React.Component<IEnrollmentRequirementCodebookContainerProps, IEnrollmentRequirementCodebookContainerState> {
  state: IEnrollmentRequirementCodebookContainerState = {
    enrollmentRequirementModalVisible: false,
    enrollmentRequirementToEdit: undefined,
  };

  componentDidMount() {
    this.fetchEnrollmentRequirementList();
  }

  componentDidUpdate(prevProps: IEnrollmentRequirementCodebookContainerProps, prevState: IEnrollmentRequirementCodebookContainerState) {
    if (prevProps.collectionParams !== this.props.collectionParams) {
      this.fetchEnrollmentRequirementList();
    }
  }

  render = () => {
    return (
      <React.Fragment>
        {this.props.enrollmentRequirementList && <CodeBookListView<IEnrollmentRequirement>
          showBackColumn={true}
          title={this.props.translate('CODEBOOK.TITLE.ENROLLMENT_REQUIREMENT')}
          descriptionPrefix={'ENROLLMENT_REQUIREMENT.DESCRIPTION_LABEL'}
          buttonLabel={this.props.translate('ENROLLMENT_REQUIREMENT.CREATE_BUTTON_LABEL')}
          codebookItemList={this.props.enrollmentRequirementList}
          canEdit={true}
          onEditCodebookItemClick={this.handleEnrollmentRequirementUpdateModalOpen}
          onCreateCodebookItemClick={this.handleEnrollmentRequirementCreateModalOpen}
          onPageChange={this.props.updateCollectionParams.onPageChange}
        />}

        {this.state.enrollmentRequirementModalVisible && <CodebookShortInfoForm<IEnrollmentRequirement, IEnrollmentRequirementCreatePayload>
          value={this.state.enrollmentRequirementToEdit}
          onCancel={this.handleCodebookModalCancel}
          onUpdate={this.handleEnrollmentRequirementUpdateSubmit}
          onCreate={this.handleEnrollmentRequirementCreateSubmit} />}
      </React.Fragment>
    );
  };

  toggleCodebookModal = (isVisible: boolean) => {
    this.setState({ enrollmentRequirementModalVisible: isVisible });
  };

  handleCodebookModalCancel = () => {
    this.setState({ enrollmentRequirementToEdit: undefined });
    this.toggleCodebookModal(false);
  };

  handleEnrollmentRequirementCreateModalOpen = () => {
    this.toggleCodebookModal(true);
  };

  handleEnrollmentRequirementUpdateModalOpen = (enrollmentRequirement: IEnrollmentRequirement) => {
    this.setState({ enrollmentRequirementToEdit: enrollmentRequirement });
    this.toggleCodebookModal(true);
  };

  private handleEnrollmentRequirementCreateSubmit = (data: IEnrollmentRequirementCreatePayload) => {
    this.props.createEnrollmentRequirement(data).track().subscribe(
      // success
      () => {
        this.props.reportMessage({ message: this.props.translate('ENROLLMENT_REQUIREMENT.CREATE_INFO_MESSAGE'), type: UserFeedbackMessageType.NOTIFICATION, severity: UserFeedbackMessageSeverity.SUCCESS });
        this.fetchEnrollmentRequirementList();
        this.handleCodebookModalCancel();
      }
    );
  };

  private handleEnrollmentRequirementUpdateSubmit = (data: IEnrollmentRequirement) => {
    this.props.updateEnrollmentRequirement(data).track().subscribe(
      // success
      () => {
        this.fetchEnrollmentRequirementList();
        this.handleCodebookModalCancel();
      }
    );
  };

  private fetchEnrollmentRequirementList = () => {
    this.props.onUpdateList(this.props.fetchEnrollmentRequirementList);
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IEnrollmentRequirementCodebookContainerOwnProps): IEnrollmentRequirementCodebookContainerStateProps => ({
  enrollmentRequirementList: EnrollmentRequirementBusinessStore.selectors.getEnrollmentRequirementList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IEnrollmentRequirementCodebookContainerDispatchProps => ({
  reportMessage: (data: IUserFeedbackMessagePayload) => dispatch(UserFeedbackBusinessStore.actions.reportMessage(data)),
  fetchEnrollmentRequirementList: (params: ICollectionFetchPayload<IEnrollmentRequirementListFilter>) => dispatch(createTrackableAction(EnrollmentRequirementBusinessStore.actions.fetchEnrollmentRequirementList(params))),
  createEnrollmentRequirement: (data: IEnrollmentRequirementCreatePayload) => dispatch(createTrackableAction(EnrollmentRequirementBusinessStore.actions.createEnrollmentRequirement(data))),
  updateEnrollmentRequirement: (data: IEnrollmentRequirement) => dispatch(createTrackableAction(EnrollmentRequirementBusinessStore.actions.updateEnrollmentRequirement(data))),
});

export default connect<IEnrollmentRequirementCodebookContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withCollectionState(collectionDefaults)(withLocalize(EnrollmentRequirementCodebookContainer as any)));
