import IfUserRoles from '@src/components/common/role/IfUserRoles';
import AdminDashboardContainer from '@src/components/dashboard/AdminDashboardContainer';
import CreatorDashboardContainer from '@src/components/dashboard/CreatorDashboardContainer';
import TraineeDashboardContainer from '@src/components/dashboard/TraineeDashboardContainer';
import { UserRoleEnum } from '@src/model/user/UserRole';
import React from 'react';

const DashboardPage = () => (
  <React.Fragment>
    {/* ----- Trainee dashboard ----- */}
    <IfUserRoles roles={[UserRoleEnum.TRAINEE]} fallback="">
      <TraineeDashboardContainer />
    </IfUserRoles>

    {/* ----- Admin dashboard ----- */}
    <IfUserRoles roles={[UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN]} fallback="">
      <AdminDashboardContainer />
    </IfUserRoles>

    {/* ----- Course creator dashboard ----- */}
    <IfUserRoles roles={[UserRoleEnum.COURSE_CREATOR]} fallback="">
      <CreatorDashboardContainer />
    </IfUserRoles>
  </React.Fragment>
);

export default DashboardPage;
