import { Form } from '@ant-design/compatible';

import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { SearchOutlined } from '@ant-design/icons';
import UserGroupDataPicker from '@src/components/common/datapicker/UserGroupDataPicker';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { IUserListFilter } from '@src/service/business/user/userListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, Col, DatePicker, Input, Row, Select } from 'antd';
import moment, { Moment } from 'moment';
import React from 'react';

enum ActiveOnly {
  YES,
  NO,
}

const dateFormat = AppConfigService.getValue('dateTimeFormat.dateWithYear');
const apiDateFormat = AppConfigService.getValue('dateTimeFormat.backendDate');

const LAYOUT_FILTER = {
  xs: 24,
  sm: 12,
  md: 6,
};

// --
// ----- Prop types
const FormItem = Form.Item;
const Option = Select.Option;

interface IUsersViewOwnProps {
  value: IUserListFilter;
  additionalSearchParams: boolean;
  onChange: (listFilter: IUserListFilter) => void;
  showUserGroupParams?: boolean;
}

type IUsersViewProps = IUsersViewOwnProps & IWithLocalizeOwnProps & FormComponentProps;
// --
// ----- State types
interface IUsersViewState {
  isEditing: boolean;
}

// --
// ----- Teomporary component with functionality for creating new user
// TODO: move code from this temporariy UsersViewContainer into new one made for admin users list
class UserListFilter extends React.Component<IUsersViewProps, IUsersViewState> {
  state: IUsersViewState = {
    isEditing: false,
  };

  render = () => {
    const { getFieldDecorator } = this.props.form;
    return (
      <Form layout="vertical" onSubmit={this.handleSubmit}>
        <Row gutter={12}>
          <Col {...LAYOUT_FILTER}>
            <FormItem label={this.props.translate('USER_LIST_FILTER.NAME_LABEL')}>{getFieldDecorator('name', {})(<Input prefix={<SearchOutlined/>} allowClear={true}/>)}</FormItem>
          </Col>

          <Col {...LAYOUT_FILTER}>
            <FormItem label={this.props.translate('USER_LIST_FILTER.ASSIGNED_DATE_FROM_LABEL')}>
              {getFieldDecorator('assignedDateFrom', { initialValue: this.props.value?.assignedDateFrom != null ? moment(this.props.value?.assignedDateFrom) : undefined })(<DatePicker allowClear={true} disabledDate={this.disabledStartDate} format={dateFormat} className="full-width"/>)}
            </FormItem>
          </Col>
          <Col {...LAYOUT_FILTER}>
            <FormItem label={this.props.translate('USER_LIST_FILTER.ASSIGNED_DATE_TO_LABEL')}>
              {getFieldDecorator('assignedDateTo', { initialValue: this.props.value?.assignedDateTo != null ? moment(this.props.value?.assignedDateTo) : undefined })(<DatePicker allowClear={true} disabledDate={this.disabledEndDate} format={dateFormat} className="full-width"/>)}
            </FormItem>
          </Col>

          {this.props.showUserGroupParams && <Col {...LAYOUT_FILTER}>
            <FormItem label={this.props.translate('USER_LIST_FILTER.USER_GROUPS_LABEL')}>
              {getFieldDecorator('userGroups', { initialValue: this.props.value?.userGroups })(
                <UserGroupDataPicker/>,
              )}
            </FormItem>
          </Col>}

          {this.props.additionalSearchParams && (
            <Col {...LAYOUT_FILTER}>
              <FormItem label={this.props.translate('USER_FORM.ROLES_PLACEHOLDER')}>
                {getFieldDecorator('role', { initialValue: this.props.value.role })(
                  <Select mode="multiple" allowClear={true}>
                    {Object.keys(UserRoleEnum).map((item: any) => (
                      <Option value={UserRoleEnum[item as keyof typeof UserRoleEnum]} key={UserRoleEnum[item as keyof typeof UserRoleEnum]}>
                        {this.props.translate(`USER_FORM.ROLE_${item}_LABEL`)}
                      </Option>
                    ))}
                  </Select>,
                )}
              </FormItem>
            </Col>
          )}

          {this.props.additionalSearchParams && (
            <Col {...LAYOUT_FILTER}>
              <FormItem label={this.props.translate('USER_LIST_FILTER.ALL_USERS')}>
                {getFieldDecorator('activeOnly', {
                  initialValue: this.props.value.activeOnly ? ActiveOnly.YES : ActiveOnly.NO,
                })(
                  <Select>
                    <Option value={ActiveOnly.YES}>{this.props.translate('USER_LIST_FILTER.ONLY_ACTIVE_USERS')}</Option>
                    <Option value={ActiveOnly.NO}>{this.props.translate('USER_LIST_FILTER.ALL_USERS')}</Option>
                  </Select>,
                )}
              </FormItem>
            </Col>
          )}
        </Row>

        <Row>
          <Col className="timun-wrapper__gutterBox--vertical">
            <Button type="primary" htmlType="submit">
              {this.props.translate('COMMON.ACTION_SEARCH')}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  private disabledStartDate = (startValue?: Moment) => {
    const endValue = this.props.form.getFieldValue('traineeStatsTo');
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  };

  private disabledEndDate = (endValue?: Moment) => {
    const startValue = this.props.form.getFieldValue('traineeStatsFrom');
    if (!endValue || !startValue) {
      return false;
    }

    return endValue.valueOf() <= startValue.valueOf();
  };

  private handleSubmit = (e: any) => {
    e.preventDefault();
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        const listFilter: IUserListFilter = {
          name: values.name,

          assignedDateFrom: values.assignedDateFrom
            ? moment(values.assignedDateFrom)
              .startOf('day')
              .format(apiDateFormat)
              .toString()
            : undefined,
          assignedDateTo: values.assignedDateTo
            ? moment(values.assignedDateTo)
              .endOf('day')
              .format(apiDateFormat)
              .toString()
            : undefined,

          // additional
          role: values.role,
          activeOnly: values.activeOnly != null ? values.activeOnly === ActiveOnly.YES : undefined,
          userGroups: values.userGroups,
        };

        this.props.onChange?.(listFilter);
      }
    });
  };
}

const UserListFilterForm = Form.create()(UserListFilter);

export default withLocalize<IUsersViewOwnProps>(UserListFilterForm as any);
