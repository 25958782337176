import { LangUtils } from './LangUtils';

const URL_PART_SEPARATOR = '/';
const QUERY_STRING_SEPARATOR = '?';
const QUERY_STRING_PART_SEPARATOR = '&';
const TRAILING_URL_PART_SEPARATOR_PATTERN = new RegExp(`${URL_PART_SEPARATOR}+$`);

// tslint:disable-next-line: interface-name
export interface Parameter {
  name: string;
  value: string;
}

/**
 * Helper class for constructing absolute and relative URLs from one or more parts.
 * This class should be used for creating all URLs and serve as a single point of control for later changes to URL creation.
 */
export default class UrlBuilder {
  /** Create absolute resource URL builder. Resource URLs are all web resources (images, styles, fonts, scripts, ...). */
  static createResource(baseUrl?: string, urlParts?: string[], queryString?: Parameter[] | string): UrlBuilder {
    const builder = new UrlBuilder();

    if (!LangUtils.isEmpty(baseUrl)) {
      builder.baseUrl(baseUrl);
    }

    if (!LangUtils.isEmpty(urlParts)) {
      builder.urlPart(...urlParts);
    }

    if (!LangUtils.isEmpty(queryString)) {
      if (LangUtils.isString(queryString)) {
        builder.queryString(queryString);
      } else {
        builder.queryParameterPairPart(...queryString);
      }
    }

    return builder;
  }

  // tslint:disable-next-line: variable-name
  private _baseUrl: string = '';
  // tslint:disable-next-line: variable-name
  private _urlParts: string[] = [];
  // tslint:disable-next-line: variable-name
  private _queryParameterParts: Parameter[] = [];
  // tslint:disable-next-line: variable-name
  private _queryParameterString!: string;

  baseUrl(value: string): UrlBuilder {
    if (value === null || value === undefined) {
      // tslint:disable-next-line:no-string-throw - TODO: Add UrlBuilder exceptions
      throw 'Empty baseUrl value';
    }

    this._baseUrl = value;

    return this;
  }

  urlPart(...value: string[]): UrlBuilder {
    this._urlParts.push(...value);

    return this;
  }

  queryParameterPart(name: string, value: string): UrlBuilder {
    return this.queryParameterPairPart({ name, value });
  }

  queryParameterPairPart(...value: Parameter[]): UrlBuilder {
    this._queryParameterParts.push(...value);

    return this;
  }

  queryString(value: string): UrlBuilder {
    this._queryParameterString = value;

    return this;
  }

  build(): string {
    let url = this._baseUrl;
    // * url += (url !== '' ? URL_PART_SEPARATOR : '');

    // remove trailing slashes, urlParts will provider one
    url = url.replace(TRAILING_URL_PART_SEPARATOR_PATTERN, '');

    url += this._urlParts.reduce((accum, part) => {
      let urlPart = accum;

      // force separator at the beging of URL only if part does not start with path separatator or .
      if (part.substr(0, URL_PART_SEPARATOR.length) !== URL_PART_SEPARATOR && part.substr(0, 1) !== '.') {
        urlPart += URL_PART_SEPARATOR;
      }

      // remove trailing slashes
      part = part.replace(TRAILING_URL_PART_SEPARATOR_PATTERN, '');

      return urlPart + part;
    }, '');

    // query string has precedance over individual query parts
    if (this._queryParameterString !== undefined) {
      url += QUERY_STRING_SEPARATOR + this._queryParameterString;
    } else if (this._queryParameterParts.length > 0) {
      url +=
        QUERY_STRING_SEPARATOR +
        this._queryParameterParts.reduce((accum, part) => {
          // * return accum + (accum !== '' ? QUERY_STRING_PART_SEPARATOR : '') + part.name + '=' + part.value;
          return `${accum}${accum !== '' ? QUERY_STRING_PART_SEPARATOR : ''}${part.name}=${part.value}`;
        }, '');
    }

    return url;
  }
}
