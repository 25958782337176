import React from 'react';

import ExamTemplateContainer from '@src/components/exam/examtemplate/ExamTemplateContainer';
import { withRouter, WithRouterProps } from 'react-router';

type IExamTemplateViewPageProps = WithRouterProps;

const ExamTemplateViewPage = (props: IExamTemplateViewPageProps) => <ExamTemplateContainer examTemplateId={props.params.examTemplateId} />;

export default withRouter(ExamTemplateViewPage as any);
