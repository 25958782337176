import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IEmailTemplate } from '@src/model/emailtemplate/EmailTemplate';
import EmailTemplateBusinessStore from '@src/service/business/emailtemplates/EmailTemplateBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import EmailTemplateFormWrapper from '@src/components/emailtemplate/form/EmailTemplateForm';

// -- Prop types
// ----------
interface IEmailTemplateUpdateContainerOwnProps {
  emailTemplate?: IEmailTemplate;
  onCancel?: () => void;
  onUpdate?: () => void;
}

interface IEmailTemplateUpdateContainerStateProps {
}

interface IEmailTemplateUpdateContainerDispatchProps {
  updateEmailTemplate: (emailTemplate: IEmailTemplate) => ITrackableAction;
}

// -- State types
// ----------
type IEmailTemplateUpdateContainerContentProps = IEmailTemplateUpdateContainerOwnProps & IEmailTemplateUpdateContainerStateProps & IEmailTemplateUpdateContainerDispatchProps & IWithLocalizeOwnProps;

interface IEmailTemplateUpdateContainerState {
}

// -- Component
// ----------

/** Component for email template update */
class EmailTemplateUpdateContainer extends React.Component<IEmailTemplateUpdateContainerContentProps, IEmailTemplateUpdateContainerState> {
  state = {
  };

  render() {
    return (
      <EmailTemplateFormWrapper emailTemplate={this.props.emailTemplate} title={this.props.translate('EMAIL_TEMPLATE.EDIT_EMAIL_TEMPLATE_TITLE')} onCancel={this.props.onCancel} onUpdate={this.handleEmailTemplateUpdate} />
    );
  }

  private handleEmailTemplateUpdate = (emailTemplate: IEmailTemplate) => {
    this.props.updateEmailTemplate(emailTemplate).track().subscribe(
      // success
      () => {
        this.props.onUpdate && this.props.onUpdate();
      }
    );
  }
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IEmailTemplateUpdateContainerOwnProps): IEmailTemplateUpdateContainerStateProps => ({
});
// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: Dispatch): IEmailTemplateUpdateContainerDispatchProps => ({
  updateEmailTemplate: (emailTemplate: IEmailTemplate) => createTrackableAction(dispatch(EmailTemplateBusinessStore.actions.updateEmailTemplate(emailTemplate))),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<IEmailTemplateUpdateContainerOwnProps>(EmailTemplateUpdateContainer as any));
