import { Badge, Tag } from 'antd';
import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ICourseStatus } from '@src/model/course/Course';
import { IWebinarStatus } from '@src/model/webinar/Webinar';

// -- Prop types
// ----------
export interface IStatusBadgeWrapperPublicProps {
  showBadge?: boolean;
  status: ICourseStatus | IWebinarStatus;
  type: 'COURSE' | 'WEBINAR';
}
interface IStatusBadgeWrapperStateProps { }
type IStatusBadgeWrapperProps = IStatusBadgeWrapperPublicProps & IWithLocalizeOwnProps & IStatusBadgeWrapperStateProps;

// --
// ----- Component
const StatusBadgeWrapper: React.FC<IStatusBadgeWrapperProps> = (props) => {
  if (props.showBadge) {
    return (
      <Badge count={<Tag className="timun-courseCard__badge">{props.translate(props.type + '.STATUS_TITLE.' + props.status.name)}</Tag>}>
        {props.children}
      </Badge>
    );
  } else {
    return (<React.Fragment>{props.children}</React.Fragment>);
  }
};

export default withLocalize<IStatusBadgeWrapperPublicProps>(StatusBadgeWrapper as any);
