import RepositoryTag from '@src/components/repository/tag/RepositoryTag';
import { ITag } from '@src/model/tag/Tag';
import React from 'react';

// -- Prop types
// ----------

export interface IRepositoryTagListOwnProps {
  tagList: ITag[];
  currentTags: string[];
  onTagChange: (tags: string[]) => void;
}

type IRepositoryTagListProps = IRepositoryTagListOwnProps;

// -- Component
// ----------

/** Tag list component */
class RepositoryTagList extends React.Component<IRepositoryTagListProps> {
  render = () => {
    return this.props.tagList.map((tag) => {
      return <RepositoryTag key={tag.id} tag={tag} checked={this.props.currentTags.some((tagId) => tagId === tag.id)} onChecked={(checked) => this.handleChange(tag.id, checked)}/>;
    });
  };

  handleChange = (tagId: string, checked: boolean) => {
    let newTags: string[] = [...this.props.currentTags];

    if (checked) { // add tag id to current tags
      newTags.push(tagId);
    } else { // remove tag id from current tags
      newTags = newTags.filter((tag) => {
        return tag !== tagId;
      });
    }
    this.props.onTagChange(newTags);
  };
}

// -- HOCs and exports
// ----------

export default RepositoryTagList;
