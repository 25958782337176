import { Form } from '@ant-design/compatible';

import { FormComponentProps } from '@ant-design/compatible/lib/form';
import CurrencyInput from '@src/components/common/input/CurrencyInput';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { ExpeneseTypeEnum, IExternalEducationExpense } from '@src/model/externalEducationExpense/ExternalEducationExpense';
import { IExternalEducationExpenseCreatePayload } from '@src/service/business/externalEducationExpense/ExternalEducationExpenseBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, Input } from 'antd';
import moment from 'moment';
import React, { SyntheticEvent } from 'react';

const backendDate = AppConfigService.getValue('dateTimeFormat.backendDate');

export interface IExternalEducationApplicationAgreementCreateData {
  externalEducationApplicationData: IExternalEducationApplication;
  externalEducationApplicationExpenseCreateData: IExternalEducationExpenseCreatePayload;
}

export interface IExternalEducationApplicationAgreementUpdateData {
  externalEducationApplicationData: IExternalEducationApplication;
  externalEducationApplicationExpenseUpdateData: IExternalEducationExpense;
}

interface IExternalEducationApplicationAgreementFormOwnProps {
  externalEducationApplication: IExternalEducationApplication;
  onSubmit?: (data: IExternalEducationApplicationAgreementCreateData) => void;
  onUpdate?: (data: IExternalEducationApplicationAgreementUpdateData) => void;
}

type IExternalEducationApplicationAgreementFormProps = IExternalEducationApplicationAgreementFormOwnProps & IWithLocalizeOwnProps & FormComponentProps;

interface IExternalEducationApplicationAgreementFormState {
}

class ExternalEducationApplicationAgreementForm extends React.Component<IExternalEducationApplicationAgreementFormProps, IExternalEducationApplicationAgreementFormState> {
  state: IExternalEducationApplicationAgreementFormState = {};

  componentDidMount = () => {
    if (this.props.externalEducationApplication) {
      this.props.form.setFieldsValue(this.props.externalEducationApplication);
    }
  };

  componentDidUpdate = (prevProps: IExternalEducationApplicationAgreementFormProps, prevState: IExternalEducationApplicationAgreementFormState) => {
    if (this.props.externalEducationApplication && this.props.externalEducationApplication !== prevProps.externalEducationApplication) {
      this.props.form.setFieldsValue(this.props.externalEducationApplication);
    }
  };

  render = () => {
    const { getFieldDecorator } = this.props.form;
    const educationExpense = this.props.externalEducationApplication.expenses?.find((expense: IExternalEducationExpense) => expense.expenseType.id === ExpeneseTypeEnum.EDUCATION_OFFER_PRICE);

    return <Form layout="vertical" onSubmit={this.handleSubmit}>
      <Form.Item label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.CODE_OFFER_LABEL')} required={true}>
        {getFieldDecorator('offerCode', {
          initialValue: this.props.externalEducationApplication?.offerCode,
          rules: [{ required: true, message: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.VALIDATION.CODE_OFFER_MESSAGE') }],
        })(<Input placeholder={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.CODE_OFFER_PLACEHOLDER')} data-test-id="timun-externalEducationApplicationAgreementForm__offerCode"/>)}
      </Form.Item>

      <Form.Item label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.EDUCATION_EXPENSE_LABEL')} required={true}>
        {getFieldDecorator('educationExpenseValue', {
          initialValue: educationExpense?.value,
          rules: [{ required: true, message: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.VALIDATION.EDUCATION_EXPENSE_MESSAGE') }],
        })(<CurrencyInput placeholder={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.EDUCATION_EXPENSE_PLACEHOLDER')} data-test-id="timun-externalEducationApplicationAgreementForm__educationExpenseValue"/>)}
      </Form.Item>

      <Form.Item label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.DISTRICT_OFFICE_ADDRESS_LABEL')} required={true}>
        {getFieldDecorator('districtOfficeAddress.city', {
          initialValue: this.props.externalEducationApplication?.districtOfficeAddress?.city,
          rules: [{ required: true, message: this.props.translate('EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.VALIDATION.DISTRICT_OFFICE_ADDRESS_MESSAGE') }],
        })(<Input placeholder={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.AGREEMENT_FORM.DISTRICT_OFFICE_ADDRESS_PLACEHOLDER')} data-test-id="timun-externalEducationApplicationAgreementForm__districtOfficeAddressCity"/>)}
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" data-test-id="timun-externalEducationApplicationAgreementForm__submitButton">{this.props.translate('COMMON.ACTION_SAVE')}</Button>
      </Form.Item>
    </Form>;
  };

  handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        const educationExpense = this.props.externalEducationApplication.expenses?.find((expense: IExternalEducationExpense) => expense.expenseType.id === ExpeneseTypeEnum.EDUCATION_OFFER_PRICE);

        const externalEducationApplicationData: IExternalEducationApplication = {
          ...this.props.externalEducationApplication,
          offerCode: values.offerCode,
          districtOfficeAddress: values.districtOfficeAddress,
        };

        const externalEducationApplicationExpenseCreateData: IExternalEducationExpenseCreatePayload = {
          value: values.educationExpenseValue,
          paymentDate: moment().format(backendDate),
          expenseType: { id: ExpeneseTypeEnum.EDUCATION_OFFER_PRICE },
          externalEducationApplication: this.props.externalEducationApplication,
        };

        const externalEducationApplicationExpenseUpdateData = { ...educationExpense, value: values.educationExpenseValue } as IExternalEducationExpense;

        const createData = {
          externalEducationApplicationData,
          externalEducationApplicationExpenseCreateData,
        };

        const updateData = {
          externalEducationApplicationData,
          externalEducationApplicationExpenseUpdateData,
        };

        if (educationExpense?.id) {
          this.props.onUpdate && this.props.onUpdate(updateData);
        } else {
          this.props.onSubmit && this.props.onSubmit(createData);
        }
      }
    });
  };


}

const ExternalEducationApplicationAgreementFormWrapper = Form.create<IExternalEducationApplicationAgreementFormProps>()(ExternalEducationApplicationAgreementForm);

export default withLocalize<IExternalEducationApplicationAgreementFormOwnProps>(ExternalEducationApplicationAgreementFormWrapper as any);
