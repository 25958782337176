import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import AdminUserManagementListFilter from '@src/components/user/filter/AdminUserManagementListFilter';
import UserManagementListView from '@src/components/user/view/UserManagementListView';
import { IUserInfo } from '@src/model/user/User';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import CollectionBusinessStore from '@src/service/business/common/collectionBusinessStore';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import TenantManager from '@src/service/business/tenant/TenantManager';
import UserBusinessStore from '@src/service/business/user/UserBusinessStore';
import UserListBusinessStore, { IUserListFilter } from '@src/service/business/user/userListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { ILemonEvent } from '@src/service/util/event/lemonEvent';
import React from 'react';
import { connect } from 'react-redux';

// TODO: luoyuma specific code to hide oib fields, remove once/if ever handled differently
const tenant = TenantManager.getTenantCode();

// -- Prop types
// ----------
export interface IAdminUserManagementListContainerOwnProps {
}

export interface IAdminUserManagementListContainerStateProps {
  userListFilter: IUserListFilter;
  userList: ICollectionData<IUserInfo>;
  workPositionList: IWorkPosition[];
}

export interface IAdminUserManagementListContainerDispatchProps {
  createUser: (data: IUserInfo, sourceEvent: ILemonEvent<IUserInfo>) => ITrackableAction;
  clearUserList: () => any;
  fetchUserList: (params: ICollectionFetchPayload<IUserListFilter>) => any;
  clearFilter: () => any;
  storeFilter: (listFilter: IUserListFilter) => any;
}

type IAdminUserManagementListContainerProps = IAdminUserManagementListContainerOwnProps & IAdminUserManagementListContainerStateProps & IAdminUserManagementListContainerDispatchProps & IWithLocalizeOwnProps & IWithTenantPropEnabledOwnProps;

interface IAdminUserManagementListContainerState {
  page: number;
  size: number;
  sort: string[];
  isEditing: boolean;
}

// -- Component
// ----------
/** Admin user management list container */
class AdminUserManagementListContainer extends React.Component<IAdminUserManagementListContainerProps, IAdminUserManagementListContainerState> {
  state: IAdminUserManagementListContainerState = {
    page: 0,
    size: AppConfigService.getValue('api.paging.defaultPageSize'),
    sort: [],
    isEditing: false,
  };

  componentDidMount = () => {
    this.updateUserList();
  };

  componentDidUpdate(prevProps: IAdminUserManagementListContainerStateProps, prevState: IAdminUserManagementListContainerState) {
    if (this.props.userListFilter !== prevProps.userListFilter || this.state !== prevState) {
      this.updateUserList();
    }
  }

  componentWillUnmount = () => {
    this.props.clearFilter();
  };

  render = () => {
    const isLuyouma = tenant === 'luyouma' || tenant === 'Luyouma';
    return <React.Fragment>
      {/* Filter */}
      <AdminUserManagementListFilter showOib={!isLuyouma} onFilterSubmit={this.handleFilterSubmit} userListFilter={this.props.userListFilter}/>
      {/* List */}
      {this.props.userList && <UserManagementListView showOib={!isLuyouma} onUserSubmit={this.handleUserSubmit} workPositionList={this.props.workPositionList} onPageChange={this.handlePageChange} userList={this.props.userList}/>}
    </React.Fragment>;
  };

  handlePageChange = (page: number, pageSize?: number) => {
    this.setState({ page: page - 1, size: pageSize ?? this.state.size });
  };

  handleFilterSubmit = (listFilter: IUserListFilter) => {
    this.setState({ page: 0 });
    this.props.storeFilter(listFilter);
  };

  handleUserSubmit = (event: ILemonEvent<IUserInfo>) => {
    this.props
      .createUser(event.data, event)
      .track()
      .subscribe(
        () => {
          // success
          this.updateUserList();
        },
        () => {
          console.error('Error creating new user');
        },
      );
  };

  toggleEdit = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };

  updateUserList = () => {
    this.props.fetchUserList({
      filter: this.props.userListFilter,
      page: this.state.page,
      size: this.state.size,
      sort: this.state.sort,
    });
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IAdminUserManagementListContainerStateProps => ({
  userListFilter: UserListBusinessStore.selectors.getUserListFilter(state),
  userList: UserListBusinessStore.selectors.getUserList(state),
  workPositionList: CollectionBusinessStore.selectors.getCollectionContent(state, 'WorkPosition'),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IAdminUserManagementListContainerDispatchProps => ({
  createUser: (data: IUserInfo, sourceEvent: ILemonEvent<IUserInfo>) => dispatch(createTrackableAction(UserBusinessStore.actions.createUser(data), sourceEvent)),
  clearFilter: () => dispatch(UserListBusinessStore.actions.clearUserListFilter()),
  clearUserList: () => dispatch(UserListBusinessStore.actions.clearUserList()),
  fetchUserList: (params: ICollectionFetchPayload<IUserListFilter>) => dispatch(UserListBusinessStore.actions.fetchUserList({ ...params })),
  storeFilter: (listFilter: IUserListFilter) => dispatch(UserListBusinessStore.actions.storeUserListFilter(listFilter)),
});

export default connect<IAdminUserManagementListContainerStateProps, IAdminUserManagementListContainerDispatchProps, IAdminUserManagementListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize<IAdminUserManagementListContainerOwnProps>(withTenantPropEnabled(AdminUserManagementListContainer) as any));
