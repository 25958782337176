import GridItem from '@src/components/common/grid/GridItem';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import DialogPanel from '@src/components/common/panel/DialogPanel';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import EducationApplicationHelperUtils from '@src/components/externalEducationApplication/common/EducationApplicationHelperUtils';
import EducationApplicationUserFileListView from '@src/components/externalEducationApplication/common/EducationApplicationUserFileListView';
import ExternalEducationApplicationFileListUpload from '@src/components/externalEducationApplication/common/ExternalEducationApplicationFileListUpload';
import { IFileListsByType } from '@src/components/externalEducationApplication/common/types';
import ExternalEducationApplicationAgreementForm, { IExternalEducationApplicationAgreementCreateData, IExternalEducationApplicationAgreementUpdateData } from '@src/components/externalEducationApplication/form/ExternalEducationApplicationAgreementForm';
import ExternalEducationPaymentInfoForm from '@src/components/externalEducationApplication/form/ExternalEducationPaymentInfoForm';
import NoteCreateContainer from '@src/components/usernote/NoteCreateContainer';
import { ActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { ExternalEducationApplicationPaymentInfoTypeEnum, IExternalEducationApplicationPaymentInfo } from '@src/model/externalEducationApplication/ExternalEducationApplicationPaymentInfo';
import { IFile } from '@src/model/file/File';
import { FileTypeEnum } from '@src/model/file/FileSystemElement';
import { NoteObjectTypeEnum } from '@src/model/user/Note';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { IExternalEducationExpenseCreatePayload } from '@src/service/business/externalEducationExpense/ExternalEducationExpenseBusinessStore';
import { Button, Col, Row } from 'antd';
import React from 'react';

const LAYOUT_BUTTONS_1 = {
  sm: 24,
  xs: 24,
  md: 12,
};

const LAYOUT_BUTTONS_3 = {
  sm: 24,
  xs: 24,
  md: 8,
};

// -- Prop types
// ----------
interface IExternalEducationApplicationCoordinatorApprovalViewOwnProps {
  externalEducationApplication: IExternalEducationApplication;
  applicationFiles: IFileListsByType;
  onUpdateExternalEducationApplication?: (data: IExternalEducationApplication) => void;
  onCreateExpense?: (data: IExternalEducationExpenseCreatePayload) => void;
  onExternalEducationApplicationAgreementSubmit?: (data: IExternalEducationApplicationAgreementCreateData) => void;
  onExternalEducationApplicationAgreementUpdate?: (data: IExternalEducationApplicationAgreementUpdateData) => void;
  // tslint:disable-next-line: bool-param-default
  onChangePhase?: (newPhase: ActivityPhaseEnum, save?: boolean) => void;
  onCoordinatorAdd?: () => void;
  onFileUpload?: (file: IFile[]) => void;
  onFileRemove?: (file: IFile[]) => void;
  onUpdatePaymentInfo?: (data: IExternalEducationApplicationPaymentInfo) => void;
}

type IExternalEducationApplicationCoordinatorApprovalViewProps = IExternalEducationApplicationCoordinatorApprovalViewOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

interface IExternalEducationApplicationCoordinatorApprovalViewState {
  showEducationForm: boolean;
  showAgreementForm: boolean;
  showPaymentForm: boolean;
}

// -- Component
// ----------

class ExternalEducationApplicationCoordinatorApprovalView extends React.Component<IExternalEducationApplicationCoordinatorApprovalViewProps, IExternalEducationApplicationCoordinatorApprovalViewState> {
  state: IExternalEducationApplicationCoordinatorApprovalViewState = {
    showEducationForm: false,
    showAgreementForm: false,
    showPaymentForm: false,
  };

  componentDidUpdate = (prevProps: IExternalEducationApplicationCoordinatorApprovalViewProps, prevState: IExternalEducationApplicationCoordinatorApprovalViewState) => {
    //
  };

  isApplicationInPhases = (phases: ActivityPhaseEnum[]): boolean => {
    return EducationApplicationHelperUtils.isInPhases(phases, this.props.externalEducationApplication.activity);
  };

  handleChangePhase = (phase: ActivityPhaseEnum) => {
    if (this.props.onChangePhase) {
      this.props.onChangePhase(phase);
    }
  };

  render() {
    const hasCoordinator = EducationApplicationHelperUtils.hasCoordinator(this.props.externalEducationApplication.activity);
    const canSubmitContractGeneration = this.props.externalEducationApplication.education && this.getOfferPaymentInfo() && this.props.externalEducationApplication.offerCode && this.props.externalEducationApplication.districtOfficeAddress?.city;
    return (
      <React.Fragment>
        {this.isApplicationInPhases([ActivityPhaseEnum.USER_CANCELLED]) && (
          <Row>
            <Col>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.APPLICATION_CANCELED_TEXT')}</Col>
          </Row>
        )}

        {this.isApplicationInPhases([ActivityPhaseEnum.DENIED]) && (
          <Row>
            <Col>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.APPLICATION_DENIED_TEXT')}</Col>
          </Row>
        )}

        {this.isApplicationInPhases([ActivityPhaseEnum.SUBMITTED]) && (
          <React.Fragment>
            <Row>
              <Col>
                {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.USER_SUBMITTED_TEXT')}
                &nbsp;
                {hasCoordinator ? (
                  <Button block={true} onClick={() => this.handleChangePhase(ActivityPhaseEnum.AWAITING_APPROVAL)} data-test-id="timun-externalEducationApplicationView__continueButton">
                    {this.props.translate('COMMON.ACTION_CONTINUE')}
                  </Button>
                ) : (
                  <Button type="primary" block={true} onClick={() => this.props.onCoordinatorAdd && this.props.onCoordinatorAdd()} data-test-id="timun-externalEducationApplicationView__takeRequestButton">
                    {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.TAKE_APPLICATION_BUTTON_TEXT')}
                  </Button>
                )}
              </Col>
            </Row>
          </React.Fragment>
        )}

        {this.isApplicationInPhases([ActivityPhaseEnum.AWAITING_APPROVAL]) && (
          <React.Fragment>
            <Row>
              <Col>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.IN_PROCCESS_TEXT')}</Col>
            </Row>

            <Row gutter={[12, 12]}>
              <Col {...LAYOUT_BUTTONS_3}>
                <Button type="primary" block={true} onClick={() => this.handleChangePhase(ActivityPhaseEnum.IN_PROCESS)} data-test-id="timun-externalEducationApplicationView__approveApplicationButton">
                  {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.APPROVE_APPLICATION_BUTTON_TEXT')}
                </Button>
              </Col>
              <Col {...LAYOUT_BUTTONS_3}>
                <NoteCreateContainer
                  objectId={this.props.externalEducationApplication.id}
                  objectTypeId={NoteObjectTypeEnum.EDUCATION_APPLICATION}
                  objectPublic={true}
                  buttonLabel={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.RETURN_APPLICATION_BUTTON_TEXT')}
                  modalTitle={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.RETURN_APPLICATION_MODAL_TITLE')}
                  modalOkButtonLabel={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.RETURN_APPLICATION_BUTTON_TEXT')}
                  modalPlaceholder={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.RETURN_APPLICATION_PLACEHOLDER')}
                  onCreateNote={() => this.handleChangePhase(ActivityPhaseEnum.RETURNED_TO_USER)}
                />
              </Col>
              <Col {...LAYOUT_BUTTONS_3}>
                <NoteCreateContainer
                  objectId={this.props.externalEducationApplication.id}
                  objectTypeId={NoteObjectTypeEnum.EDUCATION_APPLICATION}
                  objectPublic={true}
                  buttonLabel={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.REJECT_APPLICATION_BUTTON_TEXT')}
                  modalTitle={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.REJECT_APPLICATION_MODAL_TITLE')}
                  modalOkButtonLabel={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.REJECT_APPLICATION_CONFIRM_BUTTON')}
                  modalPlaceholder={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.REJECT_APPLICATION_PLACEHOLDER')}
                  onCreateNote={() => this.handleChangePhase(ActivityPhaseEnum.DENIED)}
                />
              </Col>
            </Row>
          </React.Fragment>
        )}

        {/* common deny request button */}
        {this.isApplicationInPhases([ActivityPhaseEnum.IN_PROCESS, ActivityPhaseEnum.CONTRACT_GENERATION, ActivityPhaseEnum.AWAITING_SIGNED_CONTRACT, ActivityPhaseEnum.AWAITING_PAYMENT]) && (
          <React.Fragment>
            <Row>
              <Col {...LAYOUT_BUTTONS_1}>
                {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.APPLICATION_APPROVED_TEXT')}
                &nbsp;
                <NoteCreateContainer
                  objectId={this.props.externalEducationApplication.id}
                  objectTypeId={NoteObjectTypeEnum.EDUCATION_APPLICATION}
                  objectPublic={true}
                  buttonLabel={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CANCEL_APPLICATION_APPROVAL_BUTTON_TEXT')}
                  modalTitle={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CANCEL_APPLICATION_APPROVAL_MODAL_TITLE')}
                  modalOkButtonLabel={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CANCEL_APPLICATION_APPROVAL_BUTTON_TEXT')}
                  modalPlaceholder={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CANCEL_APPLICATION_APPROVAL_PLACEHOLDER')}
                  onCreateNote={() => this.handleChangePhase(ActivityPhaseEnum.DENIED)}
                />
              </Col>
            </Row>
          </React.Fragment>
        )}

        {this.isApplicationInPhases([ActivityPhaseEnum.IN_PROCESS]) && (
          <React.Fragment>
            <br />
            <Row>
              <Col {...LAYOUT_BUTTONS_1}>
                {canSubmitContractGeneration ? this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.EDUCATION_ENTERED_TEXT') : this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.EDUCATION_NOT_ENTERED_TEXT')}
                &nbsp;
                <Button type="primary" block={false} onClick={this.toggleForms} data-test-id="timun-externalEducationApplicationView__toggleEducationFormButton">
                  {this.props.translate('COMMON.ACTION_EDIT')}
                </Button>
              </Col>
            </Row>

            <br />

            {/* TODO: separate states? */}
            {this.state.showEducationForm && (
              <DialogPanel>
                <ExternalEducationPaymentInfoForm userRole={UserRoleEnum.COORDINATOR} externalEducationApplication={this.props.externalEducationApplication} paymentInfo={this.getOfferPaymentInfo()} paymentInfoType={ExternalEducationApplicationPaymentInfoTypeEnum.OFFER} onSubmit={this.props.onUpdatePaymentInfo} />
              </DialogPanel>
            )}

            <br />

            {/* TODO: separate states? */}
            {this.state.showEducationForm && (
              <DialogPanel title={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.EDUCATION_APPLICATION_AGREEMENT_PANEL_TITLE')}>
                <ExternalEducationApplicationAgreementForm onUpdate={this.handleAgreementUpdate} onSubmit={this.handleAgreementSubmit} externalEducationApplication={this.props.externalEducationApplication} />
              </DialogPanel>
            )}

            {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.CONTRACT_GENERATION_TEXT')}
            <Row>
              <Col {...LAYOUT_BUTTONS_1}>
                <Button type="primary" block={false} disabled={!canSubmitContractGeneration} onClick={() => this.handleChangePhase(ActivityPhaseEnum.CONTRACT_GENERATION)} data-test-id="timun-externalEducationApplicationView__requestSignedContractButton">
                  {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.REQUEST_SIGNED_CONTRACT_BUTTON_TEXT')}
                </Button>
              </Col>
            </Row>
          </React.Fragment>
        )}

        {this.isApplicationInPhases([ActivityPhaseEnum.CONTRACT_GENERATION, ActivityPhaseEnum.AWAITING_SIGNED_CONTRACT]) && (
          <React.Fragment>
            <Row>
              <Col>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.AWAITING_SIGNED_CONTRACT_TEXT')}</Col>
            </Row>
          </React.Fragment>
        )}

        {this.isApplicationInPhases([ActivityPhaseEnum.AWAITING_PAYMENT]) && (
          <React.Fragment>
            <Row>
              <Col span={24}>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.SIGNED_CONTRACT_UPLOADED_TEXT')}</Col>
            </Row>
            <GridItem label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.FILES.SIGNED_FINANCING_AGREEMENT_LABEL')}>
              <EducationApplicationUserFileListView files={this.props.applicationFiles} displayTypes={[FileTypeEnum.SIGNED_FINANCING_AGREEMENT]} />
            </GridItem>

            <GridItem label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.FILES.MUTUALLY_SIGNED_FINANCING_AGREEMENT_LABEL')} requiredMark={true}>
              <ExternalEducationApplicationFileListUpload applicationFiles={this.props.applicationFiles} fileType="MUTUALLY_SIGNED_FINANCING_AGREEMENT" onFileUpload={this.props.onFileUpload} onFileRemove={this.props.onFileRemove} />
            </GridItem>

            <Row>
              <Col span={24}>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.UPLOAD_PROOF_OF_PAYMENT_TEXT')}</Col>
            </Row>

            <GridItem label={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.FILES.PROOF_OF_PAYMENT_LABEL')} infoText={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.TOOLTIP.PROOF_OF_PAYMENT_INFO')} requiredMark={true}>
              <ExternalEducationApplicationFileListUpload applicationFiles={this.props.applicationFiles} fileType="PROOF_OF_PAYMENT" onFileUpload={this.props.onFileUpload} onFileRemove={this.props.onFileRemove} />
            </GridItem>

            <Row>
              <Col span={24}>{this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.ENABLE_EDUCATION_TEXT')}</Col>
            </Row>

            <Row>
              <Col {...LAYOUT_BUTTONS_1}>
                <Button type="primary" block={false} onClick={() => this.handleChangePhase(ActivityPhaseEnum.EDUCATION_IN_PROGRESS)}
                  disabled={!EducationApplicationHelperUtils.hasFilesInAllTypes(this.props.applicationFiles, [FileTypeEnum.MUTUALLY_SIGNED_FINANCING_AGREEMENT, FileTypeEnum.PROOF_OF_PAYMENT])}
                  data-test-id="timun-externalEducationApplicationView__enableEducationButton"
                >
                  {this.props.translate('EXTERNAL_EDUCATION_APPLICATION.COORDINATOR.ENABLE_EDUCATION_BUTTON_TEXT')}
                </Button>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

  handleUpdatePaymentInfo = (data: IExternalEducationApplicationPaymentInfo) => {
    if (this.props.onUpdatePaymentInfo) {
      this.props.onUpdatePaymentInfo(data);
    }
    // this.togglePaymentForm(false);
  };

  getOfferPaymentInfo = () => {
    return this.props.externalEducationApplication.paymentInfos?.find((paymentInfo) => paymentInfo.paymentInfoType.id === ExternalEducationApplicationPaymentInfoTypeEnum.OFFER);
  };

  toggleEducationForm = (isVisible: boolean) => {
    this.setState({
      showEducationForm: isVisible,
    });
  };

  toggleAgreementForm = (isVisible: boolean) => {
    this.setState({
      showAgreementForm: isVisible,
    });
  };

  togglePaymentForm = (isVisible: boolean) => {
    this.setState({
      showPaymentForm: isVisible,
    });
  };

  handleEducationFormClose = () => {
    this.toggleEducationForm(false);
  };

  handleAgreementFormClose = () => {
    this.toggleAgreementForm(false);
  };

  toggleForms = () => {
    this.toggleEducationForm(!this.state.showEducationForm);
  };

  handleAgreementUpdate = (data: IExternalEducationApplicationAgreementUpdateData) => {
    if (this.props.onExternalEducationApplicationAgreementUpdate) {
      this.props.onExternalEducationApplicationAgreementUpdate(data);
    }
    // this.handleAgreementFormClose();
  };

  handleAgreementSubmit = (data: IExternalEducationApplicationAgreementCreateData) => {
    if (this.props.onExternalEducationApplicationAgreementSubmit) {
      this.props.onExternalEducationApplicationAgreementSubmit(data);
    }

    // this.handleAgreementFormClose();
  };
}

// -- HOCs and exports
// ----------

export default withRoles(withLocalize<IExternalEducationApplicationCoordinatorApprovalViewOwnProps>(ExternalEducationApplicationCoordinatorApprovalView as any));
