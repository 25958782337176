import React from 'react';

import { HtmlViewer } from '@src/components/common/htmlviewer/HtmlViewer';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { Button, Modal } from 'antd';

// -- Prop types
// ----------
export interface IGdprModalOwnProps {
  onOk?: () => void;
  onCancel?: () => void;
  terms?: string;
}

type IGdprModalProps = IGdprModalOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Display gdpr terms in modal  */
const GdprModal: React.FC<IGdprModalProps> = (props: IGdprModalProps) => {

  return (
    <Modal
      className="lemon-modal__modal--lg"
      title={props.translate('GDPR_MODAL.TITLE')}
      visible={true}
      maskClosable={false}
      onCancel={props.onCancel}
      footer={[
        <Button key="back" onClick={props.onOk}>
          {props.translate('COMMON.ACTION_OK')}
        </Button>]}
    >
      <HtmlViewer dangerousValue={props.terms} />
    </Modal >
  );
};

export default withLocalize<IGdprModalOwnProps>(GdprModal as any);
