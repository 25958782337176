export interface IIntegrationVideo {
  id: string;
  integrationId: string;
  status: IIntegrationVideoStatus;
  integrationType: IIntegrationVideoType;
  properties: IIntegrationVideoProperties;
}

export interface IIntegrationVideoProperties {
  uploadLink: string;
}

export interface IIntegrationVideoType {
  id: IntegrationVideoTypeEnum;
  name: string;
  description: string;
}

export interface IIntegrationVideoStatus {
  id: IntegrationVideoStatusEnum;
  name: string;
  description: string;
}

export enum IntegrationVideoStatusEnum {
  IN_PREPARATION = 1,
  READY = 2,
}

export enum IntegrationVideoTypeEnum {
  VIMEO = '1',
}
