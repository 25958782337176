import { ActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { EmploymentStatusEnum } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { SurveyTypeEnum } from '@src/model/survey/SurveyTemplate';

export const ExternalApplicationStatisticsCategories = {
  submitted: [ActivityPhaseEnum.SUBMITTED, ActivityPhaseEnum.RETURNED_TO_USER],
  inProcess: [ActivityPhaseEnum.IN_PROCESS, ActivityPhaseEnum.CONTRACT_GENERATION, ActivityPhaseEnum.AWAITING_SIGNED_CONTRACT, ActivityPhaseEnum.AWAITING_PAYMENT],
  inProgress: [ActivityPhaseEnum.EDUCATION_IN_PROGRESS],
  completed: [ActivityPhaseEnum.EDUCATION_COMPLETED],
  employed: [EmploymentStatusEnum.EMPLOYED],
  unemplyoed: [EmploymentStatusEnum.UNEMPLOYED],
};

export const SurveyStatisticsCategories = {
  submitted: [SurveyTypeEnum.EDUCATION_PROVIDER, SurveyTypeEnum.EMPLOYER, SurveyTypeEnum.USER_EDUCATION_COMPLETED, SurveyTypeEnum.USER_EDUCATION_COMPLETED_CHECK_IN],
  educationProvider: [SurveyTypeEnum.EDUCATION_PROVIDER],
  employer: [SurveyTypeEnum.EMPLOYER],
  userCompleted: [SurveyTypeEnum.USER_EDUCATION_COMPLETED],
  userCheckIn: [SurveyTypeEnum.USER_EDUCATION_COMPLETED_CHECK_IN],
};