import EditableWrapper from '@src/components/common/container/EditableWrapper';
import EditorAvatar from '@src/components/common/image/EditorAvatar';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import ImageUploadForm from '@src/components/common/upload/ImageUploadForm';
import { IFile } from '@src/model/file/File';
import React, { useCallback, useEffect, useState } from 'react';

// -- Prop types
// ----------

export interface IFormImageComponentOwnProps {
  value?: IFile;
  onChange?: (data?: IFile) => void;
}

type IFormImageComponentProps = IFormImageComponentOwnProps & IWithPathResolverOwnProps;

// -- Component
// ----------

/** Custom form component that handles api image upload and preview and subsequently inputs the image file object to form */
const ImageFormComponent: React.FC<IFormImageComponentProps> = (props) => {

  const [isEditorOpened, setEditorOpened] = useState<boolean>();
  const [image, setImage] = useState<IFile | undefined>();

  useEffect(() => {
    setImage(props.value);
  }, [props.value]);

  const setImageFile = useCallback((imageFile) => {
    props.onChange?.(imageFile);
  }, [image]);

  const handleLogoImageEditClick = () => {
    toggleOpenedEditor(true);
  };

  const handleLogoImageRemoveClick = () => {
    setImageFile(undefined);
  };

  const toggleOpenedEditor = (opened: boolean) => {
    setEditorOpened(opened);
  };

  const handleLogoImageSubmit = (file: IFile) => {
    setImageFile(file);
    toggleOpenedEditor(false);
  };

  const handleLogoImageClose = () => {
    toggleOpenedEditor(false);
  };

  /** Returns resolved image file path if there is file id value, undefined otherwise. */
  const resolveFormImagePath = (): string | undefined => {
    if (image?.id) {
      return props.resolveApiFilePath(image.id);
    }
    return;
  };

  return <React.Fragment>
    {/* avatar preview*/}
    <EditableWrapper onEdit={handleLogoImageEditClick} onRemove={handleLogoImageRemoveClick} showRemove={!!image}>
      <EditorAvatar imagePath={resolveFormImagePath()} size="large"/>
    </EditableWrapper>

    {/* image uploader */}
    {isEditorOpened && <ImageUploadForm defaultImageLink={resolveFormImagePath()} avatar={true} onSubmit={handleLogoImageSubmit} onClose={handleLogoImageClose}/>}
  </React.Fragment>;
};

export default withPathResolver<IFormImageComponentOwnProps>(ImageFormComponent as any);
