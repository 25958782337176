import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';
import { ICollectionFetchPayload } from '@src/service/business/common/types';

import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { ICollectionData, IIdDataPayload, IIdPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { actionThunk, trackAction } from '@src/service/util/observable/operators';
import { reportCaughtMessage, startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators/userFeedback';

import IIdRef from '@src/model/common/IdRef';
import { IFile } from '@src/model/file/File';
import { IUserInfo } from '@src/model/user/User';
import EntityApiService from '@src/service/api/registry/entity/EntityApiService';
import { createApiResponseUserFeedbackError, createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { IChangePassword } from '@src/service/business/login/IPassword';
import { StateObservable } from 'redux-observable';

// -
// -------------------- Types&Consts

// -
// -------------------- Selectors

const getUser = (store: any): IUserInfo => store.user;
const getUserFileList = (store: any): ICollectionData<IFile> => store.userFileList;

// -
// -------------------- Actions

const Actions = {
  USER_FETCH: 'USER_FETCH',
  USER_CREATE: 'USER_CREATE',
  USER_DELETE: 'USER_DELETE',
  USER_UPDATE: 'USER_UPDATE',
  USER_LOAD: 'USER_LOAD',
  USER_CLEAR: 'USER_CLEAR',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  USER_AVATAR_CREATE: 'USER_AVATAR_CREATE',
  USER_FILES_FETCH: 'USER_FILES_FETCH',
  USER_FILES_LOAD: 'USER_FILES_LOAD',
  USER_FILES_CLEAR: 'USER_FILES_CLEAR',
  USER_ADD_FILES: 'USER_ADD_FILES',
  USER_REMOVE_FILES: 'USER_REMOVE_FILES',
};

const fetchUser = (params: IIdPayload): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.USER_FETCH,
    payload: params,
  };
};

const createUser = (data: IUserInfo): IPayloadAction<IUserInfo> => {
  return {
    type: Actions.USER_CREATE,
    payload: data,
  };
};

const deleteUser = (data: IIdRef<string>): IPayloadAction<IIdRef<string>> => {
  return {
    type: Actions.USER_DELETE,
    payload: data,
  };
};

const updateUser = (data: IUserInfo): IPayloadAction<IUserInfo> => {
  return {
    type: Actions.USER_UPDATE,
    payload: data,
  };
};

const loadUser = (data: IUserInfo): IPayloadAction<IUserInfo> => {
  return {
    type: Actions.USER_LOAD,
    payload: data,
  };
};

const clearUser = (): ILemonAction => {
  return {
    type: Actions.USER_CLEAR,
  };
};

const doChangePassword = (data: IChangePassword): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.CHANGE_PASSWORD,
    payload: data,
  };
};

const createUserAvatar = (id: string, data: IFile): IPayloadAction<IIdDataPayload<IFile>> => {
  return {
    type: Actions.USER_AVATAR_CREATE,
    payload: {
      id,
      data,
    },
  };
};

const fetchUserFileList = (id: string, page: number, size: number, sort: string[]): IPayloadAction<IIdDataPayload<ICollectionFetchPayload<any>>> => {
  return {
    type: Actions.USER_FILES_FETCH,
    payload: {
      id,
      data: {
        page,
        size,
        sort,
        filter: {},
      },
    },
  };
};

const loadUserFileList = (data: ICollectionData<IFile>): IPayloadAction<ICollectionData<IFile>> => {
  return {
    type: Actions.USER_FILES_LOAD,
    payload: data,
  };
};

const clearUserFileList = (): ILemonAction => {
  return {
    type: Actions.USER_FILES_CLEAR,
  };
};

const addUserFiles = (id: string, data: IFile[]): IPayloadAction<IIdDataPayload<IFile[]>> => {
  return {
    type: Actions.USER_ADD_FILES,
    payload: {
      id,
      data,
    },
  };
};

const removeUserFiles = (id: string, data: IFile[]): IPayloadAction<IIdDataPayload<IFile[]>> => {
  return {
    type: Actions.USER_REMOVE_FILES,
    payload: {
      id,
      data,
    },
  };
};

// -
// -------------------- Side-effects

const fetchUserEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('User')
        .fetchEntity(id)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadUser(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching user', error);
      return o;
    })
  );
};

const createUserEffect = (action$: Observable<IPayloadAction<IUserInfo>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return (EntityApiServiceRegistry.getService('User') as EntityApiService<IUserInfo>).createEntity(data).pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('USER_FORM.ERROR_MESSAGE.USER_CREATE_OR_UPDATE')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating user', error);
      return o;
    })
  );
};

const deleteUserEffect = (action$: Observable<IPayloadAction<IIdRef<string>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_DELETE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('User')
        .deleteEntity(data.id)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_DELETE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error deleting user', error);
      return o;
    })
  );
};

const updateUserEffect = (action$: Observable<IPayloadAction<IUserInfo>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return (EntityApiServiceRegistry.getService('User') as EntityApiService<IUserInfo>).updateEntity(data.id, data).pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadUser(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('USER_FORM.ERROR_MESSAGE.USER_CREATE_OR_UPDATE')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating user', error);
      return o;
    })
  );
};

const doChangePasswordEffect = (action$: Observable<IPayloadAction<IChangePassword>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.CHANGE_PASSWORD;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const id = action.payload.id;
      const data = {
        oldPassword: action.payload.oldPassword,
        newPassword: action.payload.newPassword,
      };

      return (EntityApiServiceRegistry.getService('User') as EntityApiService<IUserInfo>).updateSubobject(id, 'password', data).pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    // no actions after this so this prevents errors in redux-observable epic
    ignoreElements(),

    // reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('TUTOR_PROFILE_CALENDAR.ERROR_MESSAGE.TUTOR_CALENDAR_UPDATE')),
    reportCaughtMessage((error: any) =>
      createApiResponseUserFeedbackError(
        error,
        {
          BAD_CREDENTIALS: 'CHANGE_PASSWORD.ERROR_MESSAGE.BAD_CREDENTIALS',
        },
        'CHANGE_PASSWORD.ERROR_MESSAGE.CHANGE_PASSWORD'
      )
    ),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating user', error);

      return o;
    })
  );
};

const createUserAvatarEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IFile>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_AVATAR_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('User')
        .createSubobject(id, 'avatar', data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_SEND_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error uploading avatar', error);
      return o;
    })
  );
};

const fetchUserFileListEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ICollectionFetchPayload<any>>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_FILES_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('User')
        .fetchSubentityList(id, 'file', data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadUserFileList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error fetching user file list', error);
      return o;
    })
  );
};

const addUserFileEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IFile[]>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_ADD_FILES;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('User')
        .createSubentityList(id, 'file', data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error adding file to user', error);
      return o;
    })
  );
};

const removeUserFileEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IFile[]>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.USER_REMOVE_FILES;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('User')
        .deleteSubentityList(id, 'file', data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error removing file from user', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const user = (state: IUserInfo | null = null, action: IPayloadAction<IUserInfo>) => {
  if (action.type === Actions.USER_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.USER_CLEAR) {
    return null;
  }

  return state;
};

const userFileList = (state: IFile | null = null, action: IPayloadAction<ICollectionData<IFile>>) => {
  if (action.type === Actions.USER_FILES_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.USER_FILES_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const UserBusinessStore = {
  actions: {
    fetchUser,
    createUser,
    deleteUser,
    updateUser,
    loadUser,
    clearUser,
    doChangePassword,
    createUserAvatar,
    fetchUserFileList,
    clearUserFileList,
    addUserFiles,
    removeUserFiles,
  },

  selectors: {
    getUser,
    getUserFileList,
  },

  effects: {
    fetchUserEffect,
    createUserEffect,
    deleteUserEffect,
    updateUserEffect,
    doChangePasswordEffect,
    createUserAvatarEffect,
    fetchUserFileListEffect,
    addUserFileEffect,
    removeUserFileEffect,
  },

  reducers: {
    user,
    userFileList,
  },
};

// --
// ----- Exports

export default UserBusinessStore;
