import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

import ITenantConfiguration from '@src/model/tenant/TenantConfiguration';
import ITenantOrganizationConfiguration from '@src/model/tenant/TenantOrganizationConfiguration';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { IPayloadAction } from '@src/service/business/common/types';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { trackAction } from '@src/service/util/observable/operators';
import { startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Selectors

/** Returns tenantConfiguration from store. */
const getTenantOrganizationConfiguration = (store: any): ITenantOrganizationConfiguration => store.tenantOrganizationConfigurationView;

// -
// -------------------- Actions

const Actions = {
  PUBLIC_TENANT_ORGANIZATION_CONFIGURATION_FETCH: 'PUBLIC_TENANT_ORGANIZATION_CONFIGURATION_FETCH',
  PUBLIC_TENANT_ORGANIZATION_CONFIGURATION_LOAD: 'PUBLIC_TENANT_ORGANIZATION_CONFIGURATION_LOAD',
};

/** Fetch tenantOrganizationConfiguration by tenant code. */
const fetchTenantOrganizationConfiguration = (tenantCode: string): IPayloadAction<string> => {
  return {
    type: Actions.PUBLIC_TENANT_ORGANIZATION_CONFIGURATION_FETCH,
    payload: tenantCode,
  };
};

/** Load tenantConfiguration to store. */
const loadTenantOrganizationConfiguration = (data: ITenantConfiguration): IPayloadAction<ITenantConfiguration> => {
  return {
    type: Actions.PUBLIC_TENANT_ORGANIZATION_CONFIGURATION_LOAD,
    payload: data,
  };
};

// -
// -------------------- Side-effects

const fetchTenantOrganizationConfigurationEffect = (action$: Observable<IPayloadAction<string>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.PUBLIC_TENANT_ORGANIZATION_CONFIGURATION_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const tenantCode = action.payload;

      return EntityApiServiceRegistry.getService('PublicOrganizationConfiguration')
        .fetchEntity(tenantCode)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadTenantOrganizationConfiguration(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching tenant organization configuration', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const tenantOrganizationConfigurationView = (state: ITenantOrganizationConfiguration | null = null, action: IPayloadAction<ITenantOrganizationConfiguration>) => {
  if (action.type === Actions.PUBLIC_TENANT_ORGANIZATION_CONFIGURATION_LOAD) {
    return {
      ...action.payload,
    };
  }

  return state;
};

// --
// -------------------- Business Store

export const PublicTenantOrganizationConfigurationBusinessStore = {
  actions: {
    fetchTenantOrganizationConfiguration,
    loadTenantOrganizationConfiguration,
  },

  selectors: {
    getTenantOrganizationConfiguration,
  },

  effects: {
    fetchTenantOrganizationConfigurationEffect,
  },

  reducers: {
    tenantOrganizationConfigurationView,
  },
};

// --
// export business store
export default PublicTenantOrganizationConfigurationBusinessStore;
