import { AxiosRequestConfig } from 'axios';

import HttpRestClient from '@src/service/api/rest/HttpRestClient';
// import AuthenticationManager from '@src/service/util/auth/AuthenticationManager';

/** HTTP REST client which automatically and unconditionally adds authentication header. */
export default class AuthHttpRestClient extends HttpRestClient {
  protected useDefaultOptions(): AxiosRequestConfig {
    const defaultOptions = super.useDefaultOptions();

    return {
      ...(defaultOptions || {}),

      headers: {
        // other headers
        ...(defaultOptions?.headers || {}),

        // NOTE: currently disabled because we're sending token via cookie and then remapping it to header on server
        // auth token
        // Authorization: `Bearer ${AuthenticationManager.getToken()}`,
      },
    };
  }
}
