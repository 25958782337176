import { Observable, Observer } from 'rxjs';

import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import StoreService from '@src/service/business/StoreService';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction } from '@src/service/util/action/trackAction';
import { catchError, tap } from 'rxjs/operators';

export function hasUserToken() {
  return LoginBusinessStore.selectors.hasUserToken();
}
export function isUserLoggedIn() {
  return LoginBusinessStore.selectors.isUserLoggedIn(StoreService.getStore().getState());
}
export function getCurrentUser() {
  return LoginBusinessStore.selectors.getCurrentUser(StoreService.getStore().getState());
}

/** Initialize current user and return observable to allow subscriptions. */
export function initializeCurrentUser(): Observable<boolean> {
  return Observable.create((observer: Observer<boolean>): void => {
    StoreService.dispatchAction(createTrackableAction(LoginBusinessStore.actions.fetchCurrentUser()))
      .track()
      .pipe(
        catchError((err, o) => {
          // wait for logout process to finish before resuming process so we're sure logout has happend before we rethrow error
          return logoutCurrentUser().pipe(
            tap(() => {
              throw err;
            })
          );
        })
      )
      .subscribe(
        (data) => {
          observer.next(true);
          observer.complete();
        },
        (err) => {
          observer.error(err);
          observer.complete();
        }
      );
  });
}

/** Do entire logout procedure - clean up after user and reset app state (auth token, persisted state, redirect to public page, ...) */
export function logoutCurrentUser(): Observable<boolean> {
  return clearCurrentUser();
}

// ---------- private

function clearCurrentUser(): Observable<boolean> {
  console.log(`Routing to public default page - we've cleared current user data, ie. after we did a logout.`);
  return StoreService.dispatchAction(createTrackableAction<boolean>(LoginBusinessStore.actions.doLogout({ redirectUri: AppConfigService.getValue('routing.publicDefaultRoute') }))).track();
}
