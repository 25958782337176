import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ReminderEditForm from '@src/components/reminder/ReminderEditForm';
import { IReminder, IReminderType } from '@src/model/reminder/Reminder';
import { IUserDetails } from '@src/model/user/UserDetails';
import ReminderBusinessStore from '@src/service/business/reminder/ReminderBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { Button } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

// -- Prop types
// ----------

// -- ref interface are needed for creating reminders for webinars and consultations
export interface IRefObject {
  id: string;
  name: string;
}

export interface IReminderCreateContainerOwnProps {
  reminderType: string;
  user: IUserDetails;
  refObject?: IRefObject;
  showCreateButton?: boolean;
  showModal?: boolean;
  onReminderCreate?: () => void;
  onCloseModal?: (isVisible: boolean) => void;
}

export interface IReminderCreateContainerStateProps {

}

export interface IReminderCreateContainerDispatchProps {
  createReminder: (data: IReminder) => ITrackableAction;
}


type IReminderCreateContainerProps = IReminderCreateContainerOwnProps & IReminderCreateContainerDispatchProps & IReminderCreateContainerStateProps & IWithLocalizeOwnProps;

interface IReminderCreateContainerState {
  modalVisible: boolean;
}

// -- Component
// ----------

/** Component for toggling reminder create form ... */
class ReminderCreateContainer extends React.Component<IReminderCreateContainerProps, IReminderCreateContainerState> {
  state: IReminderCreateContainerState = {
    modalVisible: false,
  };

  render = () => {
    return <React.Fragment>
      {this.props.showCreateButton && <Button type="primary" onClick={this.openModal}>{this.props.translate('REMINDER.CREATE_BUTTON_LABEL')}</Button>}
      {(this.state.modalVisible || this.props.showModal) && <ReminderEditForm onCancel={this.closeModal} onSubmit={this.handleCreateReminder}/>}
    </React.Fragment>;
  };

  openModal = () => {
    this.setState({
      modalVisible: true,
    });
  };

  closeModal = () => {
    this.setState({
      modalVisible: false,
    });
    if (this.props.onCloseModal) {
      this.props.onCloseModal(false);
    }
  };

  private handleCreateReminder = (partialData: IReminder) => {
    const data: IReminder = {
      ...partialData,
      object: this.props.refObject,
      createdBy: this.props.user,
      type: {
        id: this.props.reminderType,
      } as IReminderType,
    };
    this.props.createReminder(data).track().subscribe(
      () => {
        if (this.props.onReminderCreate) {
          this.props.onReminderCreate();
        }
        this.closeModal();
      },
    );
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IReminderCreateContainerOwnProps): IReminderCreateContainerStateProps => ({});

// // `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IReminderCreateContainerDispatchProps => ({
  createReminder: (data: IReminder) => createTrackableAction(dispatch(ReminderBusinessStore.actions.createReminder(data))),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(ReminderCreateContainer as any));
