import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import AppConfigService from '@src/service/common/AppConfigService';
import { Form, Input } from 'antd';

// -- Const
// ----------
const TEXT_AREA_MAX_LENGTH = AppConfigService.getValue('components.common.defaultCharacterLengthInput');

// -- Prop types
// ----------

export interface ISkillLevelClassificationInputLevelFieldsOwnProps {
}

type ISkillLevelClassificationInputLevelFieldsProps = ISkillLevelClassificationInputLevelFieldsOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Describe your component ... */
const SkillLevelClassificationInputLevelFields: React.FC<ISkillLevelClassificationInputLevelFieldsProps> = (props: ISkillLevelClassificationInputLevelFieldsProps) => {
  return (
    <Form.List name="skillLevelClassificationList">
      {(fields, { add, remove }) => {
        return (
          <React.Fragment>
            {fields.map((field) => {
              const levelNumber = field.name + 1;
              return (
                <React.Fragment key={field.key}>
                  <Form.Item
                    label={props.translate('SKILL_LEVEL_CLASSIFICATION.FORM.LEVEL_DESCRIPTION_LABEL', { levelNumber })}
                    name={[field.name, 'description']}
                    rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
                    { max: TEXT_AREA_MAX_LENGTH, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') },
                    ]}>
                    <Input.TextArea autoSize={true} maxLength={TEXT_AREA_MAX_LENGTH} placeholder={props.translate('SKILL_LEVEL_CLASSIFICATION.FORM.LEVEL_DESCRIPTION_PLACEHOLDER', { levelNumber })} />
                  </Form.Item>

                  <Form.Item
                    name={[field.name, 'skillLevel', 'id']}
                    noStyle={true}
                    hidden={true}>
                    <Input />
                  </Form.Item>
                </React.Fragment>
              );
            })}
          </React.Fragment>
        );
      }}
    </Form.List >
  );
};

export default withLocalize<ISkillLevelClassificationInputLevelFieldsOwnProps>(SkillLevelClassificationInputLevelFields as any);
