import { LeftOutlined } from '@ant-design/icons';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import ExternalContentDataForm from '@src/components/externalcontent/create/ExternalContentDataForm';
import { IFile } from '@src/model/file/File';
import { IExternalContentCreatePayload } from '@src/service/business/externalcontent/ExternalContentViewBusinessStore';
import { Layout } from 'antd';
import React from 'react';

// -- Prop types
// ----------

const { Header, Content } = Layout;

interface IExternalContentCreateViewOwnProps {
  onCreate: (data: IExternalContentCreatePayload, coverImage?: IFile) => void;
  onCancel: () => void;
}

type IExternalContentCreateViewProps = IExternalContentCreateViewOwnProps & IWithLocalizeOwnProps;

interface IExternalContentCreateViewState {
  isEditingCover: boolean;
  coverImage?: IFile;
}

// -- Component
// ----------

class ExternalContentCreateView extends React.Component<IExternalContentCreateViewProps, IExternalContentCreateViewState> {
  state: IExternalContentCreateViewState = {
    isEditingCover: false,
    coverImage: undefined,
  };

  render() {
    return (
      <Layout>
        <Header>
          <HeaderTitle link={'/externalcontents'} icon={<LeftOutlined />} title={this.props.translate('EXTERNAL_CONTENT.CREATE.TITLE')} />
        </Header>
        <Content className="timun-externalContentView__createContainer">
          <ExternalContentDataForm coverImageId={this.state.coverImage?.id} onCoverSubmit={this.handleCoverPick} onCancel={this.props.onCancel} onSubmit={this.handleSubmit} />
        </Content>
      </Layout>
    );
  }

  handleSubmit = (data: IExternalContentCreatePayload) => {
    this.props.onCreate(data, this.state.coverImage);
  };

  private handleCoverPick = (data: IFile) => {
    if (data) {
      this.setState({
        coverImage: data,
      });
    }
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<IExternalContentCreateViewOwnProps>(ExternalContentCreateView as any);
