import { Form } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { validateIban } from '@src/components/common/form/validation';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';
import { ExternalEducationApplicationPaymentInfoTypeEnum, IExternalEducationApplicationPaymentInfo } from '@src/model/externalEducationApplication/ExternalEducationApplicationPaymentInfo';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { Button, Col, Input, Row } from 'antd';
import React, { SyntheticEvent } from 'react';

interface IExternalEducationPaymentInfoFormOwnProps {
  userRole: UserRoleEnum;
  paymentInfo?: IExternalEducationApplicationPaymentInfo;
  externalEducationApplication: IExternalEducationApplication;
  paymentInfoType: ExternalEducationApplicationPaymentInfoTypeEnum;
  onSubmit?: (data: IExternalEducationApplicationPaymentInfo) => void;
}

type IExternalEducationPaymentInfoFormProps = IExternalEducationPaymentInfoFormOwnProps & IWithLocalizeOwnProps & FormComponentProps;

interface IExternalEducationPaymentInfoFormState {
}

class ExternalEducationPaymentInfoForm extends React.Component<IExternalEducationPaymentInfoFormProps, IExternalEducationPaymentInfoFormState> {
  state: IExternalEducationPaymentInfoFormState = {};

  componentDidUpdate = (prevProps: IExternalEducationPaymentInfoFormProps, prevState: IExternalEducationPaymentInfoFormState) => {
    //
  };

  render = () => {
    const { getFieldDecorator } = this.props.form;

    const bankNameLabel = this.props.userRole === UserRoleEnum.COORDINATOR ? 'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR_PAYMENT_INFO.BANK_NAME_LABEL' : 'EXTERNAL_EDUCATION_APPLICATION.TRAINEE_PAYMENT_INFO.BANK_NAME_LABEL';
    const ibanLabel = this.props.userRole === UserRoleEnum.COORDINATOR ? 'EXTERNAL_EDUCATION_APPLICATION.COORDINATOR_PAYMENT_INFO.IBAN_LABEL' : 'EXTERNAL_EDUCATION_APPLICATION.TRAINEE_PAYMENT_INFO.IBAN_LABEL';

    return (
      <Form layout="vertical" colon={false}>
        <Form.Item className="hidden">
          {getFieldDecorator('id', {
            initialValue: this.props.paymentInfo?.id,
          })(<Input type="hidden"/>)}
        </Form.Item>
        <Form.Item className="hidden">
          {getFieldDecorator('externalEducationApplication.id', {
            initialValue: this.props.externalEducationApplication?.id,
          })(<Input type="hidden"/>)}
        </Form.Item>
        <Form.Item className="hidden">
          {getFieldDecorator('paymentInfoType.id', {
            initialValue: this.props.paymentInfoType,
          })(<Input type="hidden"/>)}
        </Form.Item>

        <Row gutter={[16, 16]} align="bottom">
          <Col xs={24} sm={24} md={12}>
            <Form.Item label={this.props.translate(bankNameLabel)}>
              {getFieldDecorator('bankName', {
                initialValue: this.props.paymentInfo?.bankName,
                rules: [{ required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') }],
              })(<Input data-test-id="timun-externalEducationApplicationPaymentInfoForm__bankName"/>)}
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Form.Item label={this.props.translate(ibanLabel)}>
              {getFieldDecorator('iban', {
                initialValue: this.props.paymentInfo?.iban,
                rules: [{ validator: validateIban }],
              })(<Input data-test-id="timun-externalEducationApplicationPaymentInfoForm__iban"/>)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col>
            <Button type="primary" onClick={this.handleSubmit} disabled={!this.props.form.isFieldsTouched()} data-test-id="timun-externalEducationApplicationPaymentInfoForm__submitButton">
              {this.props.translate('COMMON.ACTION_SAVE')}
            </Button>
          </Col>
        </Row>
      </Form>
    );
  };

  handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();

    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        const data = {
          ...values,
        };

        if (this.props.onSubmit) {
          this.props.onSubmit(data);
        }
      }
    });
  };
}

const ExternalEducationPaymentInfoFormWrapper = Form.create<IExternalEducationPaymentInfoFormProps>()(ExternalEducationPaymentInfoForm);

export default withLocalize<IExternalEducationPaymentInfoFormOwnProps>(ExternalEducationPaymentInfoFormWrapper as any);
