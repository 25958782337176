import React from 'react';

import AppContent from '@src/components/common/container/AppContent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import ExternalEducationTemplateCoordinatorApprovalPanel from '@src/components/externaleducation/ExternalEducationTemplateCoordinatorApprovalPanel';
import ExternalEducationTemplateDetailsPanel from '@src/components/externaleducation/ExternalEducationTemplateDetailsPanel';
import ExternalEducationTemplateTabs from '@src/components/externaleducation/ExternalEducationTemplateTabs';
import { ParticipantRoleEnum } from '@src/model/activity/ActivityParticipant';
import { ExternalEducationTemplateActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { ActivityUtils } from '@src/service/util/activity/ActivityUtils';
import RoleUtils from '@src/service/util/role/RoleUtils';


// -- Prop types
// ----------

export interface IExternalEducationTemplateViewOwnProps {
  currentUser: IUserInfo;
  externalEducationTemplate: IExternalEducationTemplate;
  onVerificationSubmit: (updatedExternalEducationTemplate: IExternalEducationTemplate) => void;
  openedTab: string;
  onActivityPhaseChange?: (phaseId: ExternalEducationTemplateActivityPhaseEnum) => void;
  onCoordinatorAdd?: () => void;
}
type IExternalEducationTemplateViewProps = IExternalEducationTemplateViewOwnProps & IWithRolesOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Display external education template details panel and tabs section */
const ExternalEducationTemplateView: React.FC<IExternalEducationTemplateViewProps> = (props: IExternalEducationTemplateViewProps) => {
  const isCurrentUserCoordinator = RoleUtils.isInRoles([UserRoleEnum.COORDINATOR], props.currentUser.roles);
  const isCurrentUserEvaluator = props.externalEducationTemplate.activity && ActivityUtils.getParticipantByRole(ParticipantRoleEnum.EVALUATOR, props.externalEducationTemplate.activity)?.userId === props.currentUser.id;

  return (
    <AppContent>
      {/* Verification Panel */}
      {props.externalEducationTemplate.activity &&
        isCurrentUserCoordinator &&
        ActivityUtils.isInPhases([ExternalEducationTemplateActivityPhaseEnum.APPROVAL_REQUESTED, ExternalEducationTemplateActivityPhaseEnum.APPROVAL_IN_PROCESS, ExternalEducationTemplateActivityPhaseEnum.CHANGE_SUBMITTED], props.externalEducationTemplate.activity) &&
        <ExternalEducationTemplateCoordinatorApprovalPanel
          isCurrentUserEvalutor={isCurrentUserEvaluator}
          externalEducationTemplate={props.externalEducationTemplate}
          onCoordinatorAdd={props.onCoordinatorAdd}
          onActivityPhaseChange={props.onActivityPhaseChange} />}

      <ExternalEducationTemplateDetailsPanel isCurrentUserCoordinator={isCurrentUserCoordinator} onVerificationSubmit={props.onVerificationSubmit} externalEducationTemplate={props.externalEducationTemplate} />

      <ExternalEducationTemplateTabs openedTab={props.openedTab} externalEducationTemplate={props.externalEducationTemplate} />
    </AppContent>
  );
};

export default withLocalize<IExternalEducationTemplateViewOwnProps>(withRoles(ExternalEducationTemplateView as any));
