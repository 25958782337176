import { PlusOutlined } from '@ant-design/icons';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import NameInputModal from '@src/components/course/update/NameInputModal';
import { Button } from 'antd';
import React from 'react';

// -- Prop types
// ----------

export interface IAddElementLinkOwnProps {
  title: string;
  onSubmit: (name: string) => void;
  className?: string;
}

type IAddElementLinkProps = IAddElementLinkOwnProps & IWithLocalizeOwnProps;

interface IAddElementLinkState {
  isModalVisible: boolean;
}

// -- Component
// ----------

/** Component to display a menu item for creating new course elements, and handle name input */
class AddElementLink extends React.Component<IAddElementLinkProps, IAddElementLinkState> {
  state = {
    isModalVisible: false,
  };

  render() {
    return (
      <React.Fragment>
        <Button className={this.props.className} type="primary" onClick={() => this.toggleModal()} data-test-id="timun-addElementButton">
          <PlusOutlined/> {this.props.title}
        </Button>
        {this.state.isModalVisible && <NameInputModal onSubmit={this.handleNameSubmit} onCancel={this.toggleModal} title={this.props.title}/>}
      </React.Fragment>
    );
  }

  toggleModal = () => {
    this.setState({
      isModalVisible: !this.state.isModalVisible,
    });
  };

  handleNameSubmit = (newName: string) => {
    this.props.onSubmit(newName);
    this.toggleModal();
  };
}

export default withLocalize<IAddElementLinkOwnProps>(AddElementLink as any);
