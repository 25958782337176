import { IListAdditionalCol } from '@src/components/common/list/ListAdditionalCol';
import ListPagination from '@src/components/common/list/ListPagination';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import UserListFilter from '@src/components/user/filter/UserListFilter';
import UserForm from '@src/components/user/form/UserForm';
import UserList from '@src/components/user/list/UserList';
import { IUserInfo } from '@src/model/user/User';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import CollectionBusinessStore from '@src/service/business/common/collectionBusinessStore';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import UserBusinessStore from '@src/service/business/user/UserBusinessStore';
import UserListBusinessStore, { IUserListFilter } from '@src/service/business/user/userListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { ILemonEvent } from '@src/service/util/event/lemonEvent';
import { Col, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';

// --
// ----- Prop types
interface IUserListOwnProps {
  additionalSearchParams: boolean;
  withCreateUserButton: boolean;
  predefinedListFilter?: IUserListFilter;

  additionalCols?: () => IListAdditionalCol[];
}

interface IUserListStateProps {
  userList: ICollectionData<IUserInfo>;
  userListFilter: IUserListFilter;
  workPositionList: IWorkPosition[];
}

interface IUserListDispatchProps {
  clearFilter: () => any;
  clearUserList: () => any;
  fetchUserList: (params: ICollectionFetchPayload<IUserListFilter>) => any;
  storeFilter: (listFilter: IUserListFilter) => any;
  createUser: (data: IUserInfo, sourceEvent: ILemonEvent<IUserInfo>) => ITrackableAction;
}

type IUserListProps = IUserListOwnProps & IUserListStateProps & IUserListDispatchProps & IWithLocalizeOwnProps;

// --
// ----- State types
interface IUserListState {
  page: number;
  size: number;
  sort: string[];
  isEditing: boolean;
}

// --
// ----- Component
class UserListContainer extends React.Component<IUserListProps, IUserListState> {
  state: IUserListState = {
    page: 0,
    size: AppConfigService.getValue('api.paging.defaultPageSize'),
    sort: [],
    isEditing: false,
  };

  componentDidMount() {
    // trigger filter update which will trigger list update
    this.handleFilterChange({});
  }

  componentDidUpdate = (prevProps: IUserListProps, prevState: IUserListState) => {
    if (this.state !== prevState || this.props.userListFilter !== prevProps.userListFilter || this.props.predefinedListFilter !== prevProps.predefinedListFilter) {
      this.updateList();
    }
  };

  componentWillUnmount = () => {
    this.props.clearUserList();
    this.props.clearFilter();
  };

  render = () => {
    return (
      <React.Fragment>
        <Row>
          <Col>
            <UserListFilter showUserGroupParams={true} onChange={this.handleFilterChange} additionalSearchParams={this.props.additionalSearchParams} value={this.props.userListFilter || {}} />
          </Col>
        </Row>

        {/* Paging */}
        {this.props.userList && <ListPagination page={this.props.userList.page} onChange={this.onPageChange} />}

        {/* List */}
        {this.props.userList && <UserList userColWidth={{ xs: 8, lg: 12 }} renderAdditionalCols={this.props.additionalCols} users={this.props.userList.content} />}

        {/* Modal */}
        {this.state.isEditing && <UserForm workPositionList={this.props.workPositionList} allowRoleChange={true} allowUserStatusChange={true} onToggleModal={this.toggleEdit} onUserSubmit={this.handleFormSubmit} />}

        {/* Paging */}
        {this.props.userList && <ListPagination page={this.props.userList.page} onChange={this.onPageChange} />}
      </React.Fragment>
    );
  };

  handleFilterChange = (listFilter: IUserListFilter) => {
    if (this.state.page !== 0) {
      this.setState({ page: 0 });
    }

    // force predefined values upon filter
    const compositeFilter = {
      ...listFilter,
      ...(this.props.predefinedListFilter ?? {}),
    };

    this.props.storeFilter(compositeFilter);
  };

  onSortChange = (sort: string) => {
    this.setState({ page: 0, sort: new Array<string>(sort) });
  };

  onPageChange = (page: number, pageSize?: number) => {
    this.setState({ page: page - 1, size: pageSize ?? this.state.size });
  };

  private handleFormSubmit = (event: ILemonEvent<IUserInfo>) => {
    this.props
      .createUser(event.data, event)
      .track()
      .subscribe(
        () => {
          // success
          this.updateList();
        },
        () => {
          console.error('Error creating new user');
        },
      );
  };

  private toggleEdit = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };

  private updateList() {
    this.props.fetchUserList({
      filter: { ...this.props.userListFilter },
      page: this.state.page,
      size: this.state.size,
      sort: this.state.sort,
    });
  }
}

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any /*, ownProps: ITutorListOwnProps*/): IUserListStateProps => ({
  userList: UserListBusinessStore.selectors.getUserList(state),
  userListFilter: UserListBusinessStore.selectors.getUserListFilter(state),
  workPositionList: CollectionBusinessStore.selectors.getCollectionContent(state, 'WorkPosition'),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IUserListDispatchProps => ({
  clearFilter: () => dispatch(UserListBusinessStore.actions.clearUserListFilter()),
  clearUserList: () => dispatch(UserListBusinessStore.actions.clearUserList()),
  fetchUserList: (params: ICollectionFetchPayload<IUserListFilter>) => dispatch(UserListBusinessStore.actions.fetchUserList({ ...params })),
  storeFilter: (listFilter: IUserListFilter) => dispatch(UserListBusinessStore.actions.storeUserListFilter(listFilter)),
  createUser: (data: IUserInfo, sourceEvent: ILemonEvent<IUserInfo>) => dispatch(createTrackableAction(UserBusinessStore.actions.createUser(data), sourceEvent)),
});

export default connect<IUserListStateProps, IUserListDispatchProps>(mapStateToProps, mapDispatchToProps)(withLocalize<IUserListOwnProps>(UserListContainer as any));
