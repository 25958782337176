import { ConfigProvider } from 'antd';
import moment from 'moment';
import React from 'react';

import UserSettingsBusinessStore, { IUserSettings } from '@src/service/business/usersettings/userSettingsBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { ANTDLOCALES } from '@src/service/locale/antd';
import { MESSAGES } from '@src/service/locale/message';
import { CookieManager } from '@src/service/util/CookieManager';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import { connect } from 'react-redux';

const defaultLocale = AppConfigService.getValue('app.defaultLocale');
const localeCookieName = AppConfigService.getValue('cookies.locale.name');
const localeCookieDuration = new Date(Date.now() + AppConfigService.getValue('cookies.locale.duration'));

const localeCookie = CookieManager.getCookie(localeCookieName);

// -- Prop types
// ----------

export interface ILdLocaleProviderOwnProps { }

export interface ILdLocaleProviderStateProps {
  userSettings: IUserSettings;
}

export interface ILdLocaleProviderDispatchProps {
  //  loadLocale: (locale: string) => void;
}
type ILdLocaleProviderProps = ILdLocaleProviderDispatchProps & ILdLocaleProviderOwnProps & ILdLocaleProviderStateProps;

// -- Component
// ----------

/** Component that handles app locale */
class LdLocaleProvider extends React.Component<ILdLocaleProviderProps> {
  componentDidUpdate = (prevProps: ILdLocaleProviderProps) => {
    if (prevProps.userSettings !== this.props.userSettings) {
      this.setLocalization(this.props.userSettings.locale);
    }
  };

  componentDidMount = () => {
    // init locale
    if (this.props.userSettings) {
      this.setLocalization(this.props.userSettings.locale);
    } else if (localeCookie) {
      this.setLocalization(localeCookie);
    } else {
      this.setLocalization(defaultLocale);
    }
  };

  render = () => {
    return (
      <ConfigProvider key={Math.random()} locale={this.getAntdLocale()}>
        {this.props.children}
      </ConfigProvider>
    );
  };

  getAntdLocale = () => {
    if (this.props.userSettings && ANTDLOCALES[this.props.userSettings.locale]) {
      return ANTDLOCALES[this.props.userSettings.locale];
    } else {
      return ANTDLOCALES[defaultLocale];
    }
  };

  setLocalization = (locale: string) => {
    if (MESSAGES[locale]) {
      if (locale !== localeCookie) {
        CookieManager.setCookie({ name: localeCookieName, value: locale, path: '/', expires: localeCookieDuration });
      }
      LocalizeService.initLocalize(locale, MESSAGES);
      moment.locale(locale);
    } else {
      console.warn('Unsupported locale, loading default ', defaultLocale);
      CookieManager.setCookie({ name: localeCookieName, value: defaultLocale, path: '/', expires: localeCookieDuration });
      LocalizeService.initLocalize(defaultLocale, MESSAGES);
      moment.locale(defaultLocale);
    }
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ILdLocaleProviderOwnProps): ILdLocaleProviderStateProps => ({
  userSettings: UserSettingsBusinessStore.selectors.getUserSettings(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ILdLocaleProviderDispatchProps => ({
  //  loadLocale: (locale: string) => dispatch(UserSettingsBusinessStore.actions.updateUserSettings({ locale })),
});

export default connect<ILdLocaleProviderOwnProps>(mapStateToProps, mapDispatchToProps)(LdLocaleProvider as any);
