import { WrappedFormUtils } from '@ant-design/compatible/lib/form/Form';
import { IWebinar, WebinarStatusEnum } from '@src/model/webinar/Webinar';

export default class WebinarHelperUtils {
  /** Check if webinar is in one of given statuses */
  static isWebinarInStatus = (webinar: IWebinar, requestedStatuses: WebinarStatusEnum[]): boolean => {
    return requestedStatuses.find((status) => webinar && webinar.status.id === status) != null;
  };

  // go through all form fields and get errors, return if there are any
  static hasFormErrors = (form: WrappedFormUtils): boolean => {
    const fieldsError: any = form.getFieldsError();
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  };

  // if user has changed some values and there are no errors, save button can be enabled
  static isReadyToSubmit = (form: WrappedFormUtils): boolean => {
    return form.isFieldsTouched() && !WebinarHelperUtils.hasFormErrors(form);
  };
}
