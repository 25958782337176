import ExternalContentView from '@src/components/externalcontent/view/ExternalContentView';
import { IExternalContent } from '@src/model/externalcontent/ExternalContent';
import { IFile } from '@src/model/file/File';
import ExternalContentViewBusinessStore, { IExternalContentCreatePayload } from '@src/service/business/externalcontent/ExternalContentViewBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';
import { Dispatch } from 'redux';

// -- Prop types
// ----------

interface IExternalContentViewContainerOwnProps {
  externalId: string;
}

interface IExternalContentViewContainerStateProps {
  externalContent: IExternalContent;
}

interface IExternalContentViewContainerDispatchProps {
  fetchExternalContent: (id: string) => void;
  updateExternalContent: (id: string, data: IExternalContentCreatePayload) => ITrackableAction;
  deleteExternalContent: (id: string) => ITrackableAction;
  uploadCover: (id: string, data: IFile) => ITrackableAction;
  clearExternalContent: () => void;
}

type IExternalContentViewContainerProps = IExternalContentViewContainerOwnProps & IExternalContentViewContainerStateProps & IExternalContentViewContainerDispatchProps & WithRouterProps;

interface IExternalContentViewContainerState {
  isEditing: boolean;
}

// -- Component
// ----------

class ExternalContentViewContainer extends React.Component<IExternalContentViewContainerProps, IExternalContentViewContainerState> {
  state: IExternalContentViewContainerState = {
    isEditing: false,
  };

  componentDidMount = () => {
    this.props.fetchExternalContent(this.props.externalId);
  };

  componentWillUnmount = () => {
    this.props.clearExternalContent();
  };

  render = () => {
    return <ExternalContentView
      externalContent={this.props.externalContent}
      canEdit={true}
      isEditing={this.state.isEditing}
      setEditing={this.setEditing}
      onUpdate={this.handleUpdate}
      onDelete={this.handleDelete}
      onCoverUpload={this.handleCoverUpload}
    />;
  };

  handleUpdate = (data: IExternalContentCreatePayload) => {
    this.props.updateExternalContent(this.props.externalId, data).track().subscribe(
      () => {
        this.props.fetchExternalContent(this.props.externalId);
        this.setEditing(false);
      },
    );
  };

  handleDelete = () => {
    this.props.deleteExternalContent(this.props.externalId).track().subscribe(
      () => this.props.router.push('/externalcontents'),
    );
  };

  handleCoverUpload = (data: IFile) => {
    this.props.uploadCover(this.props.externalId, data).track().subscribe(
      () => this.props.fetchExternalContent(this.props.externalId),
    );
  };

  private setEditing = (isEditing: boolean) => {
    this.setState({ isEditing });
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IExternalContentViewContainerOwnProps): IExternalContentViewContainerStateProps => ({
  externalContent: ExternalContentViewBusinessStore.selectors.getExternalContent(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: Dispatch): IExternalContentViewContainerDispatchProps => ({
  fetchExternalContent: (id: string) => dispatch(ExternalContentViewBusinessStore.actions.fetchExternalContent(id)),
  updateExternalContent: (id: string, data: IExternalContentCreatePayload) => createTrackableAction(dispatch(ExternalContentViewBusinessStore.actions.updateExternalContent(id, data))),
  deleteExternalContent: (id: string) => dispatch(createTrackableAction(ExternalContentViewBusinessStore.actions.deleteExternalContent(id))),
  uploadCover: (id: string, data: IFile) => createTrackableAction(dispatch(ExternalContentViewBusinessStore.actions.uploadExternalContentCover(id, data))),
  clearExternalContent: () => dispatch(ExternalContentViewBusinessStore.actions.clearExternalContentData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ExternalContentViewContainer));
