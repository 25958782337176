import { Form } from '@ant-design/compatible';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IUserGroup, UserGroupTypeEnum } from '@src/model/usergroup/UserGroup';
import { UserGroupVisibilityEnum } from '@src/model/usergroup/UserGroupVisibility';
import { IUserGroupCreatePayload } from '@src/service/business/usergroup/UserGroupBusinessStore';

import { FormComponentProps } from '@ant-design/compatible/es/form';
import FormItemLabelIcon from '@src/components/common/form/FormItemLabelIcon';
import { hasFormErrors, validateRequired } from '@src/components/common/form/validation';
import { IReference } from '@src/model/common/Reference';
import AppConfigService from '@src/service/common/AppConfigService';
import { Input, Modal, Radio } from 'antd';
import React, { SyntheticEvent } from 'react';

// -- Const
// ----------
const FormItem = Form.Item;
const TEXT_AREA_MAX_LENGTH = AppConfigService.getValue('components.common.bigCharacterLengthInput');

// -- Prop types
// ----------
interface IUserGroupFormOwnProps {
  onCancel?: () => void;
  onUpdate?: (userGroup: IUserGroup) => void;
  onCreate?: (userGroupData: IUserGroupCreatePayload) => void;
  groupType?: UserGroupTypeEnum;
  parentGroup?: IReference<string>;
  title: string;
  userGroup?: IUserGroup;
}

type IUserGroupFormProps = IUserGroupFormOwnProps & IWithLocalizeOwnProps & FormComponentProps;

// -- Component
// ----------
class UserGroupForm extends React.Component<IUserGroupFormProps, any> {
  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        title={this.props.title}
        visible={true}
        onCancel={this.props.onCancel}
        onOk={this.handleSubmit}
        okButtonProps={{ disabled: !this.props.form.isFieldsTouched() || this.hasFormErrors() }}
        okText={this.props.translate('COMMON.ACTION_SAVE')}
        cancelText={this.props.translate('COMMON.ACTION_CANCEL')}>
        <Form>
          <FormItem label={this.props.translate('USER_GROUP_FORM.NAME_LABEL')}>
            {getFieldDecorator('name', {
              initialValue: this.props.userGroup?.name,
              rules: [
                { whitespace: true, message: this.props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
                { required: true, validator: validateRequired },
              ],
            })(<Input />)
            }
          </FormItem>

          <FormItem label={<FormItemLabelIcon
            label={this.props.translate('USER_GROUP_FORM.DESCRIPTION_LABEL')}
            tooltipDescription={this.props.translate('COMMON.LABEL.MAX_LENGTH_CHARACTER_INFO_LABEL', { maxCharNum: TEXT_AREA_MAX_LENGTH })} />}>
            {getFieldDecorator('description', {
              initialValue: this.props.userGroup?.description,
              rules: [
                { whitespace: true, message: this.props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
                { required: true, validator: validateRequired },
                { max: TEXT_AREA_MAX_LENGTH, message: this.props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') },
              ],
            })(<Input.TextArea rows={4} />)}
          </FormItem>

          {!this.props.userGroup &&
            <Form.Item label={this.props.translate('USER_GROUP_FORM.TYPE_LABEL')}>
              {getFieldDecorator('groupType.id', {
                initialValue: this.props.groupType ?? UserGroupTypeEnum.BASIC,
              })
                (<Radio.Group buttonStyle="solid" disabled={this.props.groupType != null}>
                  <Radio.Button value={UserGroupTypeEnum.BASIC}>{this.props.translate('USER_GROUP_FORM.TYPE_BASIC')}</Radio.Button>
                  <Radio.Button value={UserGroupTypeEnum.ORGANIZATION}>{this.props.translate('USER_GROUP_FORM.TYPE_ORGANIZATION')}</Radio.Button>
                </Radio.Group>)
              }
            </Form.Item>
          }


          {this.props.userGroup && <Form.Item label={this.props.translate('USER_GROUP_FORM.VISIBILITY_LABEL')}>
            {getFieldDecorator('visibilityId', {
              initialValue: this.props.userGroup.visibility?.id,
            })(
              <Radio.Group buttonStyle="solid">
                <Radio.Button value={UserGroupVisibilityEnum.PUBLIC}>{this.props.translate('USER_GROUP_FORM.VISIBILITY_TITLE_PUBLIC')}</Radio.Button>
                <Radio.Button value={UserGroupVisibilityEnum.LIMITED}>{this.props.translate('USER_GROUP_FORM.VISIBILITY_TITLE_LIMITED')}</Radio.Button>
                <Radio.Button value={UserGroupVisibilityEnum.CLOSED}>{this.props.translate('USER_GROUP_FORM.VISIBILITY_TITLE_CLOSED')}</Radio.Button>
              </Radio.Group>
            )}
          </Form.Item>}
        </Form>
      </Modal>
    );
  }

  handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        let userGroupData;
        if (this.props.userGroup) {
          userGroupData = {
            ...this.props.userGroup,
            name: values.name,
            description: values.description,
            visibility: { ...this.props.userGroup.visibility, id: values.visibilityId },
            parent: this.props.parentGroup,
          };
          this.props.onUpdate?.(userGroupData);
        } else {
          userGroupData = {
            name: values.name,
            description: values.description,
            groupType: values.groupType,
            parent: this.props.parentGroup,
          };
          this.props.onCreate?.(userGroupData);
        }
      }
    });
  };

  hasFormErrors = (): boolean => {
    const { form } = this.props;
    const fieldsError: any = form.getFieldsError();
    return hasFormErrors(fieldsError);
  };

}

// -- HOCs and exports
// ----------

const UserGroupFormWrapper = Form.create()(UserGroupForm);

export default withLocalize<IUserGroupFormOwnProps>(UserGroupFormWrapper as any);
