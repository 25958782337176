import React from 'react';

import ActivityHistoryContainer from '@src/components/activity/ActivityHistoryContainer';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import EducationProviderViewSection from '@src/components/externaleducation/EducationProviderViewSection';
import ExternalEducationInstanceListContainer from '@src/components/externaleducationistance/ExternalEducationInstanceListContainer';
import { IExternalEducationTemplate } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import Tabs from 'antd/lib/tabs';
import { withRouter, WithRouterProps } from 'react-router';


// -- Prop types
// ----------

export interface IExternalEducationTemplateTabsOwnProps {
  externalEducationTemplate: IExternalEducationTemplate;
  openedTab: string;
}
type IExternalEducationTemplateTabsProps = IExternalEducationTemplateTabsOwnProps & IWithLocalizeOwnProps & WithRouterProps;

enum ExternalEducationTemplateTabsTabMenuKeys {
  Rating = 'rating',
  ExternalEducationInstances = 'externaleducationinstances',
  EducationProvier = 'educationprovider',
  Activities = 'activites',
}

// -- Component
// ----------

/** Describe your component ... */
const ExternalEducationTemplateTabs: React.FC<IExternalEducationTemplateTabsProps> = (props: IExternalEducationTemplateTabsProps) => {
  const handleTabChange = (key: string) => {
    props.router.push(`/externaleducationtemplate/${props.externalEducationTemplate.id}/${key}`);
  };

  return (
    <div className="timun-externalEducationTemplateView__tabContainer">
      <Tabs defaultActiveKey={props.openedTab} onChange={handleTabChange} destroyInactiveTabPane={true}>
        <Tabs.TabPane tab={props.translate('EXTERNAL_EDUCATION_TEMPLATE.TABS.EDUCATION_INSTANCES_LABEL')} key={ExternalEducationTemplateTabsTabMenuKeys.ExternalEducationInstances}>
          <div className="panel timun-externalEducationTemplateView__tab">
            <ExternalEducationInstanceListContainer externalEducationTemplateStatus={props.externalEducationTemplate.status} externalEducationTemplateId={props.externalEducationTemplate.id} />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={props.translate('EXTERNAL_EDUCATION_TEMPLATE.TABS.EDUCATION_PROVIDER_LABEL')} key={ExternalEducationTemplateTabsTabMenuKeys.EducationProvier}>
          <div className="panel timun-externalEducationTemplateView__tab">
            <EducationProviderViewSection educationProvider={props.externalEducationTemplate.educationProvider} />
          </div>
        </Tabs.TabPane>
        {props.externalEducationTemplate.activity && <Tabs.TabPane tab={props.translate('EXTERNAL_EDUCATION_TEMPLATE.TABS.ACTIVITY_HISTORY_LABEL')} key={ExternalEducationTemplateTabsTabMenuKeys.Activities}>
          <div className="panel timun-externalEducationTemplateView__tab">
            <ActivityHistoryContainer activity={props.externalEducationTemplate.activity} />
          </div>
        </Tabs.TabPane>}
        {/* <Tabs.TabPane tab={props.translate('EXTERNAL_EDUCATION_TEMPLATE.TABS.RATING_LABEL')} key={ExternalEducationTemplateTabsTabMenuKeys.Rating}>
          <div className="panel timun-externalEducationTemplateView__tab">
            TODO: add ExternalEducationTemplateRateSection component
          </div>
        </Tabs.TabPane> */}
      </Tabs>
    </div>
  );
};

export default withLocalize<IExternalEducationTemplateTabsOwnProps>(withRouter(ExternalEducationTemplateTabs as any));
