import React, { useMemo } from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ICodeBookEntry } from '@src/model/common/CodeBookEntry';
import { ExternalEducationTemplateStatusEnum } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { Tag } from 'antd';


// -- Prop types
// ----------

export interface IExternalEducationTemplateStatusOwnProps {
  status?: ICodeBookEntry<ExternalEducationTemplateStatusEnum>;
}
type IExternalEducationTemplateStatusProps = IExternalEducationTemplateStatusOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Display colored status tag */
const ExternalEducationTemplateStatus: React.FC<IExternalEducationTemplateStatusProps> = (props: IExternalEducationTemplateStatusProps) => {
  const renderStatusColor = useMemo(() => {
    let tagColor;
    if (props.status?.id === ExternalEducationTemplateStatusEnum.CREATION_FINISHED) {
      tagColor = 'blue';
    } else if (props.status?.id === ExternalEducationTemplateStatusEnum.IN_CREATION) {
      tagColor = 'teal';
    } else if (props.status?.id === ExternalEducationTemplateStatusEnum.ARCHIVED) {
      tagColor = 'gray';
    } else if (props.status?.id === ExternalEducationTemplateStatusEnum.PUBLISHED) {
      tagColor = 'green';
    }
    return tagColor;
  }, [props.status?.id]);


  return (
    <React.Fragment>
      <Tag color={renderStatusColor}>  {props.translate(`EXTERNAL_EDUCATION_TEMPLATE.STATUS.${props.status?.name}`)}  </Tag>
    </React.Fragment >
  );
};

export default withLocalize<IExternalEducationTemplateStatusOwnProps>(ExternalEducationTemplateStatus as any);
