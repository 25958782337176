import ListPagination from '@src/components/common/list/ListPagination';
import SkillBasedRecommendationList from '@src/components/skillbasedrecommendations/list/SkillBasedRecommendationList';
import SkillBasedRecommendationListFilter from '@src/components/skillbasedrecommendations/list/SkillBasedRecommendationListFilter';
import { IChartDataItem } from '@src/components/user/statistics/TimunRadarChart';
import { ISkillBasedRecommendation } from '@src/model/skillbasedrecommendation/SkillBasedRecommendation';
import { ICollectionData } from '@src/service/business/common/types';
import { ISkillBasedRecommendationsListFilter } from '@src/service/business/skillbasedrecommendations/SkillBasedRecommendationsBusinessStore';
import React from 'react';

export interface ISkillBasedRecommendationListViewOwnProps {
  recommendations: ICollectionData<ISkillBasedRecommendation>;
  filter?: ISkillBasedRecommendationsListFilter;
  onPageChange?: (page: number, pageSize?: number) => void;
  onFilterChange?: (filter: ISkillBasedRecommendationsListFilter) => void;
  selectedEntitySkills?: IChartDataItem[];
}

type ISkillBasedRecommendationListViewProps = ISkillBasedRecommendationListViewOwnProps;

const SkillBasedRecommendationListView: React.FC<ISkillBasedRecommendationListViewProps> = (props) => {
  return <React.Fragment>
    <SkillBasedRecommendationListFilter filter={props.filter} onFilterChange={props.onFilterChange}/>
    {props.recommendations && <React.Fragment>
      &nbsp;
      <ListPagination page={props.recommendations.page} onChange={props.onPageChange}/>
      <SkillBasedRecommendationList recommendations={props.recommendations.content} selectedEntitySkills={props.selectedEntitySkills}/>
      <ListPagination page={props.recommendations.page} onChange={props.onPageChange}/>
    </React.Fragment>}
  </React.Fragment>;
};

export default SkillBasedRecommendationListView;
