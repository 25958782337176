import { IBaseUserActivity } from '@src/model/activity/UserActivity';
import { ICodeBookEntry } from '@src/model/common/CodeBookEntry';

export interface ITimelineActivity {
  content: IBaseUserActivity<null, ICodeBookEntry<TimelineActivityItemTypeEnum>>;
  type: TimelineActivityTypeEnum;
  createdAt: string;
}

export enum TimelineActivityTypeEnum {
  ACTION = 'ACTION',
  NOTE = 'NOTE',
}

export enum TimelineActivityItemTypeEnum {
  EXTERNAL_EDUCATION_APPLICATION_SUBMITTED = '1',
  EXTERNAL_EDUCATION_APPLICATION_APPROVED = '2',
  EXTERNAL_EDUCATION_APPLICATION_DENIED = '3',
  COORDINATOR_ADDED = '4',
  COORDINATOR_REMOVED = '5',
  SIGNED_FINANCING_AGREEMENT_REQUESTED = '6',
  FILE_ATTACHED = '7',
  EDUCATION_COMPLETED = '8',
  SURVEY_SUBMITTED = '9',
}
