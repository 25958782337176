import SkillLevelCodebookForm from '@src/components/codebook/form/SkillLevelCodebookForm';
import SkillLevelCodebookListView from '@src/components/codebook/view/SkillLevelCodebookListView';
import withCollectionState, { IWithCollectionStateOwnProps, IWithCollectionStatePublicProps } from '@src/components/common/collectionParams/withCollectionState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ISkillLevel } from '@src/model/skillgroup/SkillLevelClassification';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import SkillLevelBusinessStore, { ISkillLevelListFilter } from '@src/service/business/skilllevel/SkillLevelBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import React from 'react';
import { connect } from 'react-redux';

// -- Const
// ----------
const DEFAULT_PAGE_SIZE_VALUE = AppConfigService.getValue('api.collectionDefaultLimit');
const VIEW_NAME = '@@SKILL_LEVEL_CODEBOOK_LIST';
const collectionDefaults: IWithCollectionStatePublicProps<ISkillLevelListFilter> = {
  viewName: VIEW_NAME,
  initialValues: { size: DEFAULT_PAGE_SIZE_VALUE },
};

// -- Prop types
// ----------
export interface ISkillLevelCodebookContainerOwnProps {
}

export interface ISkillLevelCodebookContainerStateProps {
  skillLevelList: ICollectionData<ISkillLevel>;
}

export interface ISkillLevelCodebookContainerDispatchProps {
  fetchSkillLevelList: (params: ICollectionFetchPayload<ISkillLevelListFilter>) => ITrackableAction;
  updateSkillLevel: (data: ISkillLevel) => ITrackableAction;
}

type ISkillLevelCodebookContainerProps = ISkillLevelCodebookContainerOwnProps & ISkillLevelCodebookContainerStateProps
  & ISkillLevelCodebookContainerDispatchProps & IWithLocalizeOwnProps & IWithCollectionStateOwnProps<ISkillLevelListFilter>;

interface ISkillLevelCodebookContainerState {
  isSkillLevelModalVisible: boolean;
  skillLevelToEdit?: ISkillLevel;
}


// -- Component
// ----------

/** Skill levels codebook container */
class SkillLevelCodebookContainer extends React.Component<ISkillLevelCodebookContainerProps, ISkillLevelCodebookContainerState> {
  state: ISkillLevelCodebookContainerState = {
    isSkillLevelModalVisible: false,
    skillLevelToEdit: undefined,
  };

  componentDidMount() {
    this.fetchSkillLevelList();
  }

  componentDidUpdate(prevProps: ISkillLevelCodebookContainerProps, prevState: ISkillLevelCodebookContainerState) {
    if (prevProps.collectionParams !== this.props.collectionParams) {
      this.fetchSkillLevelList();
    }
  }

  render = () => {
    return (
      <React.Fragment>
        {this.props.skillLevelList &&
          <SkillLevelCodebookListView
            skillLevelCodebookList={this.props.skillLevelList}
            onEditClick={this.handleSkillLevelUpdateModalOpen}
            onPageChange={this.props.updateCollectionParams.onPageChange}
          />
        }
        {
          this.state.isSkillLevelModalVisible && <SkillLevelCodebookForm
            skillLevel={this.state.skillLevelToEdit}
            onSubmit={this.handleSkillLevelUpdateSubmit}
            onCancel={this.handleSkillLevelModalCancel}
          />
        }
      </React.Fragment>
    );
  };

  toogleSkillLevelModal = (isVisible: boolean) => {
    this.setState({ isSkillLevelModalVisible: isVisible });
  };

  handleSkillLevelModalCancel = () => {
    this.setState({ skillLevelToEdit: undefined });
    this.toogleSkillLevelModal(false);
  };

  handleSkillLevelModalOpen = () => {
    this.toogleSkillLevelModal(true);
  };

  handleSkillLevelUpdateModalOpen = (skillLevelToEdit: ISkillLevel) => {
    this.setState({ skillLevelToEdit });
    this.handleSkillLevelModalOpen();
  };

  private handleSkillLevelUpdateSubmit = (data: ISkillLevel) => {
    this.props.updateSkillLevel(data).track().subscribe(
      // success
      () => {
        this.fetchSkillLevelList();
        this.handleSkillLevelModalCancel();
      }
    );
  };

  private fetchSkillLevelList = (): void => {
    this.props.onUpdateList(this.props.fetchSkillLevelList);
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ISkillLevelCodebookContainerOwnProps): ISkillLevelCodebookContainerStateProps => ({
  skillLevelList: SkillLevelBusinessStore.selectors.getSkillLevelList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ISkillLevelCodebookContainerDispatchProps => ({
  fetchSkillLevelList: (params: ICollectionFetchPayload<ISkillLevelListFilter>) => createTrackableAction(dispatch(SkillLevelBusinessStore.actions.fetchSkillLevelList(params))),
  updateSkillLevel: (data: ISkillLevel) => createTrackableAction(dispatch(SkillLevelBusinessStore.actions.updateSkillLevel(data))),
});

export default connect<ISkillLevelCodebookContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withCollectionState(collectionDefaults)(withLocalize(SkillLevelCodebookContainer as any)));
