import { pipe } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';

import StoreService from '@src/service/business/StoreService';
import PublicTenantConfigurationBusinessStore from '@src/service/business/tenant/publicTenantConfigurationBusinessService';
import PublicTenantOrganizationConfigurationBusinessStore from '@src/service/business/tenant/publicTenantOrganizationConfigurationService';
import TenantManager from '@src/service/business/tenant/TenantManager';
import resolveTenant from '@src/service/business/tenant/tenantResolver';
import AppConfigService from '@src/service/common/AppConfigService';
import LemonError from '@src/service/common/LemonError';
import { createTrackableAction } from '@src/service/util/action/trackAction';
import { DomainUtils } from '@src/service/util/DomainUtils';

/** Resolve tenant code. This *must* be done before all API calls. Even for resolving tenant's config. */
export function initializeTenantCode() {
  return pipe(
    tap(() => {
      const tenantMappings = AppConfigService.getValue('tenant.mappings');
      const currentDomain = DomainUtils.getCurrentDomain();

      const tenantCode = resolveTenant(tenantMappings, currentDomain);
      if (tenantCode == null) {
        throw new LemonError('App tenant is empty');
      } else {
        console.info(`Resolved tenant "${tenantCode}"`);
      }

      TenantManager.setTenantCode(tenantCode);
    })
  );
}

/** Initialize tenant's configuration or fail. We must resolve this otherwise we cannot continue. */
export function initializeTenantConfig() {
  return pipe(
    mergeMap(() => {
      const tenantCode = TenantManager.getTenantCode();
      return StoreService.dispatchAction(createTrackableAction(PublicTenantConfigurationBusinessStore.actions.fetchTenantConfiguration(tenantCode))).track();
    }),
    mergeMap(() => {
      const tenantOrganizationCode = TenantManager.getTenantCode();
      return StoreService.dispatchAction(createTrackableAction(PublicTenantOrganizationConfigurationBusinessStore.actions.fetchTenantOrganizationConfiguration(tenantOrganizationCode))).track();
    })
  );
}
