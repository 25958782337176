import { Modal } from 'antd';
import React from 'react';

interface IEmailTemplateContentViewOwnProps {
  content: string;
  onCancel: () => void;
}

type IEmailTemplateContentViewProps = IEmailTemplateContentViewOwnProps;

class EmailTemplateContentView extends React.Component<IEmailTemplateContentViewProps> {
  render = () => {
    return <Modal className="lemon-modal__modal--md" onCancel={this.props.onCancel} visible={true} footer={null}>
      <div className="timun-emailTemplateList__content" dangerouslySetInnerHTML={{ __html: this.props.content }}/>
    </Modal>;
  };
}

export default EmailTemplateContentView;
