import React from 'react';

import FormItemLabelIcon from '@src/components/common/form/FormItemLabelIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IComment, ICommentCreatePayload } from '@src/model/comment/Comment';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button, Form, Input } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

// -- Const
// ----------
const TEXT_AREA_MAX_LENGTH = AppConfigService.getValue('components.common.bigCharacterLengthInput');

// -- Prop types
// ----------
export interface ICommentFormOwnProps {
  comment?: IComment;
  objectType?: string;
  object?: string;
  onCommentUpdateSubmit?: (comment: IComment) => void;
  onCommentCreateSubmit?: (comment: ICommentCreatePayload) => void;
}
type ICommentFormProps = ICommentFormOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** component for comment form & handling text input and submitting comments */
const CommentForm: React.FC<ICommentFormProps> = (props: ICommentFormProps) => {
  const [form] = Form.useForm<IComment>();

  const isReadyToSubmit = () => {
    const fieldsError: any = form.getFieldsError();
    const hasFormErrors = Object.keys(fieldsError).some((field) => fieldsError[field].errors.length > 0);
    return form.isFieldTouched(['content']) && !hasFormErrors;
  };

  const handleFinish = (value: IComment) => {
    if (props.comment?.id) {
      //  update
      props.onCommentUpdateSubmit?.({ ...props.comment, ...value });
    } else if (props.onCommentCreateSubmit) {
      // create
      props.onCommentCreateSubmit(value);
      form.resetFields();
    }
  };

  return (
    <Form<IComment>
      className="timun-commentView__commentForm"
      layout="vertical"
      form={form}
      initialValues={props.comment}
      name="comment"
      onFinish={handleFinish}>

      <Form.Item
        label={<FormItemLabelIcon
          label={''}
          tooltipDescription={props.translate('COMMON.LABEL.MAX_LENGTH_CHARACTER_INFO_LABEL', { maxCharNum: TEXT_AREA_MAX_LENGTH })} />}
        name={'content'}
        required={false}
        rules={[{ whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
        { required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
        { max: TEXT_AREA_MAX_LENGTH, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') }]}
      >
        <TextArea />
      </Form.Item>

      <Form.Item
        noStyle={true}
        hidden={true}
        name={['object', 'id']}
        initialValue={props.object}
      >
        <Input />
      </Form.Item>

      <Form.Item
        noStyle={true}
        hidden={true}
        initialValue={props.objectType}
        name={['objectType', 'id']}
      >
        <Input />
      </Form.Item>

      <Form.Item shouldUpdate={true}>
        {() => {
          return (
            <Button disabled={!isReadyToSubmit()} htmlType="submit" type="primary">
              {props.translate('COMMON.ACTION_SAVE')}
            </Button>
          );
        }}
      </Form.Item>
    </Form >
  );
};

export default withLocalize<ICommentFormOwnProps>(CommentForm as any);
