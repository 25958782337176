import { ITimelineActivity, TimelineActivityItemTypeEnum, TimelineActivityTypeEnum } from '@src/model/activity/TimelineActivity';
import { ICodeBookEntry } from '@src/model/common/CodeBookEntry';
import { FileTypeEnum } from '@src/model/file/FileSystemElement';
import { INote } from '@src/model/user/Note';
import AppConfigService from '@src/service/common/AppConfigService';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import moment from 'moment';

// Timeline activities content types
export interface ITimelineFileContent {
  fileResource: ITimelineFileResource;
  type: ICodeBookEntry<FileTypeEnum>;
}

export interface ITimelineContentItem {
  id: string;
  name: string;
}

export interface ITimelineFileResource extends ITimelineContentItem { }

export interface ITimelineCoordinatorChangeContent {
  addedParticipant?: ITimelineContentItem;
  removedParticipant?: ITimelineContentItem;
}

const dateFormat = AppConfigService.getValue('dateTimeFormat.dateWithTime');

/** Utility helper for timeline which is composed of very mixed contents */
export class TimelineUIHelper {
  static getTimelineItemTitle = (timelineItem: ITimelineActivity) => {
    const content = timelineItem.content;
    const creator = `${content.createdBy?.firstName} ${content.createdBy?.lastName}`;

    let text = '';

    // ----- Timeline action
    if (TimelineUIHelper.isTimelineItemAction(timelineItem)) {
      // ---- Multiple use cases depending on content type
      if (content.type.id === TimelineActivityItemTypeEnum.FILE_ATTACHED) {
        const fileContent: any = content.content;
        let filename = LocalizeService.translate('EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.UNKNOWN_FILE_TITLE');

        if (TimelineUIHelper.isTimelineItemContentFile(fileContent)) {
          filename = fileContent.fileResource.name;
        }

        text = LocalizeService.translate(`EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.${content.type.name}`, { name: creator, filename });
      } else if (content.type.id === TimelineActivityItemTypeEnum.COORDINATOR_ADDED || content.type.id === TimelineActivityItemTypeEnum.COORDINATOR_REMOVED) {
        const coordinatorChangeContent: any = content.content;
        if (TimelineUIHelper.isTimelineItemContentCoordinatorChange(coordinatorChangeContent)) {
          text = LocalizeService.translate(`EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.${content.type.name}`, { name: coordinatorChangeContent.addedParticipant?.name || coordinatorChangeContent.removedParticipant?.name });
        }
      }
      // ----- everything else
      else {
        text = LocalizeService.translate(`EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.${content.type.name}`, { name: creator });
      }
    }

    // ----- Timeline note
    else if (TimelineUIHelper.isTimelineItemNote(timelineItem)) {
      text = LocalizeService.translate('EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.NOTE.TITLE');
    } else {
      throw new Error(`Cannot resolve name for unknown timeline activity type: ${timelineItem.type}`);
    }

    return text;
  };

  static getTimelineItemDateTime = (timelineItem: ITimelineActivity) => {
    return moment(timelineItem.createdAt).format(dateFormat);
  };

  static getTimelineItemNoteText = (timelineItem: ITimelineActivity) => {
    const content = timelineItem.content;
    if (TimelineUIHelper.isTimelineItemContentNote(content)) {
      return content.text;
    } else {
      return '';
    }
  };

  static isTimelineItemNote = (timelineItem: ITimelineActivity) => {
    return timelineItem.type === TimelineActivityTypeEnum.NOTE;
  };

  static isTimelineItemAction = (timelineItem: ITimelineActivity) => {
    return timelineItem.type === TimelineActivityTypeEnum.ACTION;
  };

  // ----- Various type checks to ensure proper content is shown for timeline activities

  /** Check is timeline content of type INote for timeline type of NOTE */
  static isTimelineItemContentNote = (arg: any): arg is INote => {
    return 'text' in arg;
  };

  static isTimelineItemContentFile = (arg: any): arg is ITimelineFileContent => {
    return 'fileResource' in arg;
  };

  static isTimelineItemContentCoordinatorChange = (arg: any): arg is ITimelineCoordinatorChangeContent => {
    return 'addedParticipant' in arg || 'removedParticipant' in arg;
  };
}
