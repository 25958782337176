import { message } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import CourseListEditForm from '@src/components/course/list/CourseListEditForm';
import { CourseStatusEnum, ICourse } from '@src/model/course/Course';
import { IUserInfo } from '@src/model/user/User';
import { ICollectionData } from '@src/service/business/common/types';
import CourseListBusinessStore, { ICourseListFilter } from '@src/service/business/courses/courseListBusinessStore';
import UsersCoursesUpdateBusinessStore, { IUpdateUsersCoursesPayload } from '@src/service/business/courses/UsersCoursesUpdateBusinessStore';
import { createActionThunk, IActionThunkMap } from '@src/service/util/action/thunk';

// -- Prop types
// ----------

export interface ICourseListEditContainerOwnProps {
  user: IUserInfo;
  userCourseList: ICourse[];
  updateCourseList: () => void;
  onCancel: () => void;
}
export interface ICourseListEditContainerStateProps {
  allCourseList: ICollectionData<ICourse>;
}
export interface ICourseListEditContainerDispatchProps {
  fetchAllCourseList: (filter: ICourseListFilter) => void;
  clearAllCourseList: () => void;
  updateUsersCourses: (data: IUpdateUsersCoursesPayload, callback: IActionThunkMap) => void;
}
type ICourseListEditContainerProps = ICourseListEditContainerOwnProps & ICourseListEditContainerStateProps & ICourseListEditContainerDispatchProps & IWithLocalizeOwnProps & WithRouterProps;

// -- Component
// ----------

/** Container component for list of all courses. */
class CourseListEditContainer extends React.Component<ICourseListEditContainerProps> {
  componentDidMount = () => {
    this.props.fetchAllCourseList({ status: CourseStatusEnum.PUBLISHED });
  };

  componentWillUnmount = () => {
    this.props.clearAllCourseList();
  };

  render = () => {
    return this.props.allCourseList && <CourseListEditForm user={this.props.user} allCourseList={this.props.allCourseList.content} userCourseList={this.props.userCourseList} onCancel={this.props.onCancel} onSubmit={this.handleSubmit} />;
  };

  private handleSubmit = (data: IUpdateUsersCoursesPayload) => {
    this.props.updateUsersCourses(data, {
      success: () => {
        this.props.onCancel();
        this.props.updateCourseList();
        message.success(this.props.translate(`GENERAL_MESSAGE.GENERAL_${this.props.userCourseList && this.props.userCourseList.length ? 'UPDATE' : 'SEND'}_SUCCESS`));
      },
    });
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ICourseListEditContainerOwnProps): ICourseListEditContainerStateProps => ({
  allCourseList: CourseListBusinessStore.selectors.getAllCourseList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ICourseListEditContainerDispatchProps => ({
  fetchAllCourseList: (filter: ICourseListFilter) => dispatch(CourseListBusinessStore.actions.fetchAllCourseList(filter)),
  clearAllCourseList: () => dispatch(CourseListBusinessStore.actions.clearAllCourseList()),
  updateUsersCourses: (data: IUpdateUsersCoursesPayload, thunkMap: IActionThunkMap) => dispatch(createActionThunk(UsersCoursesUpdateBusinessStore.actions.updateUsersCourses(data), thunkMap)),
});

export default connect<ICourseListEditContainerStateProps, ICourseListEditContainerDispatchProps, ICourseListEditContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(withRouter(CourseListEditContainer as any)));
