import OrganizationListContainer from '@src/components/organization/list/OrganizationListContainer';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------
export interface IOrganizationListPagePublicProps {
}

type IOrganizationListPageProps = IOrganizationListPagePublicProps & WithRouterProps;

// -- Component
// ----------
const OrganizationListPage = (props: IOrganizationListPageProps) => {
  return <OrganizationListContainer />;
};

export default withRouter<IOrganizationListPagePublicProps>(OrganizationListPage);
