import { Form } from '@ant-design/compatible';

import { FormComponentProps } from '@ant-design/compatible/lib/form';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { NotificationTypeEnum } from '@src/model/notification/Notification';
import { INotificationListFilter } from '@src/service/business/notification/notificationListBusinessStore';
import { Col, Row, Select } from 'antd';
import React from 'react';

const FormItem = Form.Item;

const { Option } = Select;

interface INotificationListFilterOwnProps {
  listFilter?: INotificationListFilter;
  onChangeFilter: (listFilter: INotificationListFilter) => void;
}

type INotificationListFilterProps = INotificationListFilterOwnProps & IWithLocalizeOwnProps & FormComponentProps;

class NotificationListFilter extends React.Component<INotificationListFilterProps> {
  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Form>
        <Row align="middle">
          {/**
           this is here until we decide to use search for filtering too
           */}
          {/*<Col span={14}>
              <FormItem>
                {getFieldDecorator('searchInput', {})(<Input prefix={<Icon type="search"/>}/>)}
              </FormItem>
            </Col>*/}
          <Col xs={24} md={12} lg={8}>
            <FormItem label={this.props.translate('NOTIFICATION_LIST_FILTER.SORT_BY_TYPE')} colon={false}>
              {getFieldDecorator('type', {
                initialValue: this.props.listFilter && this.props.listFilter.type || '',
              })(
                <Select onSelect={this.handleFilterSubmit}>
                  <Option value={''}>{this.props.translate('NOTIFICATION_LIST_FILTER.SELECTOR_OPTION.ALL_NOTIFICATIONS')}</Option>
                  {Object.keys(NotificationTypeEnum).map((item: any) => (
                    <Option value={NotificationTypeEnum[item as keyof typeof NotificationTypeEnum]} key={NotificationTypeEnum[item as keyof typeof NotificationTypeEnum]}>
                      {this.props.translate(`NOTIFICATION_FORM.${item}_TYPE`)}
                    </Option>
                  ))}
                </Select>,
              )}
            </FormItem>
          </Col>
        </Row>
      </Form>
    );
  }

  handleFilterSubmit = (e: any) => {
    this.props.form.validateFields((err: any, values: any) => {
      this.props.onChangeFilter(values);
    });
  };
}

export default withLocalize<INotificationListFilterOwnProps>(Form.create()(NotificationListFilter) as any);
