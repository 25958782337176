import TimunCard from '@src/components/common/list/TimunCard';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IUserGroup } from '@src/model/usergroup/UserGroup';
import { Col, Empty, Row } from 'antd';
import React, { SyntheticEvent } from 'react';

// -- Prop types
// ----------
export interface IUserGroupListOwnProps {
  userGroups: IUserGroup[];
  showButtons: boolean;
  basePath: string;
  onUserGroupEdit?: (userGroup: IUserGroup) => void;
  onUserGroupCoverEdit?: (userGroup: IUserGroup) => void;
  onUserGroupDelete?: (userGroupId: string) => void;
}

type IUserGroupListProps = IUserGroupListOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------
class UserGroupList extends React.Component<IUserGroupListProps> {

  render() {
    return (
      this.props.userGroups.length ? <Row gutter={[16, 8]}>
          {this.props.userGroups.map((userGroup: IUserGroup) => {
            return (
              <Col key={userGroup.id} xs={24} sm={12} md={8} lg={6}>
                <TimunCard
                  title={userGroup.name}
                  coverImageUrl={userGroup.coverImageUrl}
                  showButtons={this.props.showButtons}
                  path={this.getPath(userGroup.id)}
                  onEdit={() => this.props.onUserGroupEdit && this.props.onUserGroupEdit(userGroup)}
                  onUpdateCoverImage={() => this.props.onUserGroupCoverEdit && this.props.onUserGroupCoverEdit(userGroup)}
                  onDelete={() => this.props.onUserGroupDelete && this.props.onUserGroupDelete(userGroup.id)}
                />
              </Col>
            );
          })}
        </Row> :
        <Empty description={this.props.translate('COMMON.MESSAGE_NO_DATA')}/>
    );
  }

  handleDelete = (e: SyntheticEvent, userGroup: IUserGroup) => {
    e.stopPropagation();

  };

  getPath = (id: string) => {
    return id ? `${this.props.basePath}/${id}` : this.props.basePath;
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<IUserGroupListOwnProps>(UserGroupList as any);
