import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import ReminderCreateContainer from '@src/components/reminder/ReminderCreateContainer';
import WebinarListView from '@src/components/webinar/list/WebinarListView';
import { ReminderTypeEnum } from '@src/model/reminder/Reminder';
import { IUserInfo } from '@src/model/user/User';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { IWebinar } from '@src/model/webinar/Webinar';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import WebinarListBusinessStore, { IWebinarsFilter } from '@src/service/business/webinars/webinarListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

// ----- Constants
const SIZE = AppConfigService.getValue('api.paging.defaultPageSize');
const DEFAULT_SORT_VALUE = 'estimatedStartDateTime,asc';

export interface IWebinarListContainerOwnProps {
  showStatusFilter?: boolean;
}
export interface IWebinarListContainerStateProps {
  currentUser: IUserInfo;
  webinarList: ICollectionData<IWebinar>;
  webinarListFilter: IWebinarsFilter;

}

export interface IWebinarListContainerDispatchProps {
  fetchWebinarList: (params: ICollectionFetchPayload<IWebinarsFilter>) => any;
  clearWebinarList: () => void;
  storeWebinarsFilter: (params: IWebinarsFilter) => any;
}

type IWebinarListContainerProps = IWebinarListContainerOwnProps & IWebinarListContainerStateProps & IWebinarListContainerDispatchProps & IWithLocalizeOwnProps & IWithRolesOwnProps & WithRouterProps;

interface IWebinarListContainerState {
  size: number;
  page: number;
  sort: string;
  selectedWebinar?: IWebinar;
  reminderModalVisible: boolean;
}

class WebinarListContainer extends React.Component<IWebinarListContainerProps, IWebinarListContainerState> {
  state: IWebinarListContainerState = {
    size: SIZE,
    page: 0,
    sort: DEFAULT_SORT_VALUE,
    selectedWebinar: undefined,
    reminderModalVisible: false,
  };

  componentDidMount() {
    this.fetchWebinarList();
  }

  componentDidUpdate(prevProps: IWebinarListContainerProps, prevState: IWebinarListContainerState) {
    if (this.props !== prevProps && this.props.webinarListFilter !== prevProps.webinarListFilter || this.state.sort !== prevState.sort || this.state.size !== prevState.size || this.state.page !== prevState.page) {
      this.fetchWebinarList();
    }

  }

  componentWillUnmount() {
    this.props.clearWebinarList();
  }

  render() {
    const showStatusFilter = this.props.showStatusFilter && this.props.isInRoles([UserRoleEnum.COURSE_CREATOR, UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN]);
    console.log(this.props.showStatusFilter, this.props.isInRoles([UserRoleEnum.COURSE_CREATOR, UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN]), showStatusFilter);
    return (
      <React.Fragment>
        <WebinarListView
          webinarList={this.props.webinarList}
          webinarListFilter={this.props.webinarListFilter}
          showStatusFilter={showStatusFilter}
          sortValue={this.state.sort}
          onCreateWebinar={this.handleCreateWebinarButtonClick}
          onPageChange={this.handlePageChange}
          onSetReminder={this.handleSetReminder}
          onFilterChange={this.props.storeWebinarsFilter}
          onSortChange={this.handleSortChange}
        />

        {/* Create webinar reminder  */}
        {this.state.selectedWebinar &&
          <ReminderCreateContainer
            showModal={this.state.reminderModalVisible}
            onCloseModal={this.handleCloseModal}
            refObject={{ id: this.state.selectedWebinar.id, name: this.state.selectedWebinar.title }}
            user={this.props.currentUser}
            reminderType={ReminderTypeEnum.WEBINAR}
          />
        }
      </React.Fragment>

    );
  }

  handleSortChange = (sort: string) => {
    if (this.state.sort !== sort) {
      this.setState({ page: 0, sort });
    }
  };

  handlePageChange = (page: number, pageSize?: number) => {
    this.setState({ page: page - 1, size: pageSize ?? this.state.size });
  };

  handleCreateWebinarButtonClick = () => {
    this.props.router.push('/webinar/create');
  };

  handleSetReminder = (webinar: IWebinar) => {
    this.setState({ selectedWebinar: webinar, reminderModalVisible: true });
  };

  handleCloseModal = (isVisible: boolean) => {
    this.setState({ selectedWebinar: undefined, reminderModalVisible: isVisible });
  };

  private fetchWebinarList = () => {
    this.props.fetchWebinarList({
      filter: this.props.webinarListFilter,
      page: this.state.page,
      size: this.state.size,
      sort: [this.state.sort],
    });
  };
}

const mapStateToProps = (state: any, ownProps: IWebinarListContainerOwnProps): IWebinarListContainerStateProps => ({
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
  webinarList: WebinarListBusinessStore.selectors.getWebinars(state),
  webinarListFilter: WebinarListBusinessStore.selectors.getWebinarsFilter(state),
});

const mapDispatchToProps = (dispatch: any): IWebinarListContainerDispatchProps => ({
  fetchWebinarList: (params: ICollectionFetchPayload<IWebinarsFilter>) => dispatch(WebinarListBusinessStore.actions.fetchWebinars({ ...params })),
  clearWebinarList: () => dispatch(WebinarListBusinessStore.actions.clearWebinars()),
  storeWebinarsFilter: (params: IWebinarsFilter) => dispatch(WebinarListBusinessStore.actions.storeWebinarsFilter(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<IWebinarListContainerOwnProps>(withRoles(withRouter(WebinarListContainer as any))));
