import { Form } from '@ant-design/compatible';

import { FormComponentProps } from '@ant-design/compatible/lib/form';

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import DeleteElementButton from '@src/components/common/button/DeleteElementButton';
import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';
import { CrmEditorConfig } from '@src/components/common/editor/CrmEditorConfigs';
import { Editor } from '@src/components/common/editor/Editor';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import MathContent from '@src/components/common/math/MathContent';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import CourseHelperUtils from '@src/components/course/common/CourseHelperUtils';
import CourseContentControl from '@src/components/course/CourseContentControl';
import { CourseStatusEnum, CourseVisibilityEnum, ICourse } from '@src/model/course/Course';
import { ICourseGroup } from '@src/model/course/CourseGroup';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { ICourseCreatePayload } from '@src/service/business/courses/courseUpdateBusinessStore';
import { Button, Col, Input, Radio, Row, Select } from 'antd';
import React, { SyntheticEvent } from 'react';

const FormItem = Form.Item;
const Option = Select.Option;

// -- Prop types
// ----------

export interface ICourseDataFormOwnProps {
  course?: ICourse;
  courseGroups: ICourseGroup[];
  canDeleteCourse?: boolean;
  isInCreation?: boolean;
  onSubmit: (data: ICourseCreatePayload) => void;
  onCancel: () => void;
  onDelete?: (data: ICourse) => void;
}

type ICourseDataFormProps = ICourseDataFormOwnProps & IWithLocalizeOwnProps & FormComponentProps & IWithRolesOwnProps;

// -- Component
// ----------

/** Create and update form for course data */
class CourseDataFields extends React.Component<ICourseDataFormProps> {
  render = () => {
    const ButtonControls = (
      <FormItem>
        <Row justify="end" gutter={12}>
          {this.props.onDelete && this.props.canDeleteCourse && (
            <Col>
              <DeleteElementButton<ICourse> item={this.props.course} buttonText={this.props.translate('COMMON.ACTION_DELETE')} onDelete={this.props.onDelete} />
            </Col>
          )}
          <Col>
            <Button onClick={this.handleCancel} icon={<CloseOutlined />} data-test-id="timun-courseView__update-aboutCourse_cancelButton">
              {this.props.translate('COMMON.ACTION_CANCEL')}
            </Button>
          </Col>
          <Col>
            <Button data-test-id="timun-courseView__update-aboutCourse_submitButton" icon={<CheckOutlined />} disabled={!CourseHelperUtils.isReadyToSubmit(this.props.form)} type="primary" htmlType="submit">
              {this.props.translate('COMMON.ACTION_SAVE')}
            </Button>
          </Col>
        </Row>
      </FormItem>
    );

    const { getFieldDecorator } = this.props.form;

    // course which was already published (statuses published and archived) can not be set back to creation
    const wasPublished = this.props.course && CourseHelperUtils.isCourseInStatus(this.props.course, [CourseStatusEnum.PUBLISHED, CourseStatusEnum.ARCHIVED]);

    return (
      <div className="timun-courseView__lecture-content">
        <Form layout="vertical" onSubmit={this.handleSubmit}>
          {ButtonControls}

          {/* ----- course title ----- */}
          <FormItem label={this.props.translate('COURSE_VIEW.UPDATE.COURSE_TITLE_FORM_LABEL')}>
            {getFieldDecorator('courseTitle', {
              initialValue: this.props.course && this.props.course.title,
              rules: [
                { whitespace: true, message: this.props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
                // tslint:disable-next-line: no-duplicate-string
                { required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') },
              ],
            })(<Input data-test-id="timun-courseView__update-aboutCourse_courseTitle" />)}
          </FormItem>

          {/* ----- course groups ----- */}
          <FormItem label={this.props.translate('COURSE_VIEW.UPDATE.COURSE_GROUP_SELECT_FORM_LABEL')}>
            {getFieldDecorator('courseGroups[0].id', {
              // BE supports multiple groups, but limited to 1 because of workplaceCourse modal
              initialValue: this.props.course?.courseGroups?.[0]?.id, // BE supports multiple groups, but limited to 1 because of workplaceCourse modal
              rules: [{ required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') }],
            })(
              <Select placeholder={this.props.translate('COURSE_VIEW.UPDATE.COURSE_GROUP_SELECT_PLACEHOLDER')} showArrow={true} data-test-id="timun-courseDataForm__courseGroupSelect">
                {this.props.courseGroups.map((courseGroup: ICourseGroup) => (
                  <Option key={courseGroup.id} value={courseGroup.id} data-test-id={`timun-courseDataForm__courseGroupSelect_option_${courseGroup.id}`}>
                    {courseGroup.title}
                  </Option>
                ))}
              </Select>
            )}
          </FormItem>

          {/* ----- course status ----- */}
          {this.props.course && (
            <FormItem label={this.props.translate('COURSE_VIEW.UPDATE.COURSE_STATUS_FORM_LABEL')}>
              {getFieldDecorator('courseStatus', {
                initialValue: this.props.course.status.id,
                rules: [{ required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') }],
              })(
                <Radio.Group buttonStyle="solid" data-test-id="timun-courseDataForm__courseStatusRadio">
                  <Radio.Button value={CourseStatusEnum.IN_CREATION} disabled={wasPublished} data-test-id={`timun-courseDataForm__courseStatusRadio_option_${CourseStatusEnum.IN_CREATION}`}>
                    {this.props.translate('COURSE.STATUS_TITLE.IN_CREATION')}
                  </Radio.Button>
                  <Radio.Button value={CourseStatusEnum.CREATION_FINISHED} disabled={wasPublished} data-test-id={`timun-courseDataForm__courseStatusRadio_option_${CourseStatusEnum.CREATION_FINISHED}`}>
                    {this.props.translate('COURSE.STATUS_TITLE.CREATION_FINISHED')}
                  </Radio.Button>

                  {/* course creator doens't have permission to publish course */}
                  {this.props.allowedRoles([UserRoleEnum.ORGANIZATION_ADMIN]) && (
                    <React.Fragment>
                      <Radio.Button value={CourseStatusEnum.PUBLISHED} data-test-id={`timun-courseDataForm__courseStatusRadio_option_${CourseStatusEnum.PUBLISHED}`}>
                        {this.props.translate('COURSE.STATUS_TITLE.PUBLISHED')}
                      </Radio.Button>
                      <Radio.Button value={CourseStatusEnum.ARCHIVED} data-test-id={`timun-courseDataForm__courseStatusRadio_option_${CourseStatusEnum.ARCHIVED}`}>
                        {this.props.translate('COURSE.STATUS_TITLE.ARCHIVED')}
                      </Radio.Button>
                    </React.Fragment>
                  )}
                </Radio.Group>
              )}
            </FormItem>
          )}

          {/* ----- visibility ----- */}
          <FormItem label={this.props.translate('COURSE_VIEW.UPDATE.VISIBILITY_LABEL')}>
            {getFieldDecorator('courseVisibility', {
              initialValue: this.props.course?.visibility.id,
              rules: [{ required: true, message: this.props.translate('COURSE_VIEW.UPDATE.VISIBILITY_REQUIRED_LABEL') }],
            })(
              <Radio.Group buttonStyle="solid" data-test-id="timun-courseDataForm__courseVisibilityRadio">
                <Radio.Button value={CourseVisibilityEnum.PUBLIC} data-test-id={`timun-courseDataForm__courseVisibilityRadio_option_${CourseVisibilityEnum.PUBLIC}`}>
                  {this.props.translate('COURSE_VIEW.UPDATE.VISIBILITY_TITLE_PUBLIC')}
                </Radio.Button>
                <Radio.Button value={CourseVisibilityEnum.PRIVATE} data-test-id={`timun-courseDataForm__courseVisibilityRadio_option_${CourseVisibilityEnum.PRIVATE}`}>
                  {this.props.translate('COURSE_VIEW.UPDATE.VISIBILITY_TITLE_PRIVATE')}
                </Radio.Button>
                <Radio.Button value={CourseVisibilityEnum.LIMITED} data-test-id={`timun-courseDataForm__courseVisibilityRadio_option_${CourseVisibilityEnum.LIMITED}`}>
                  {this.props.translate('COURSE_VIEW.UPDATE.VISIBILITY_TITLE_LIMITED')}
                </Radio.Button>
              </Radio.Group>
            )}
          </FormItem>

          {/* ----- content ----- */}
          <FormItem label={this.props.translate('COURSE_VIEW.UPDATE.COURSE_TEXT_FORM_LABEL')}>
            {getFieldDecorator('content', {
              initialValue: this.props.course && this.props.course.content,
              getValueFromEvent: (e) => {
                return e;
              },
            })(
              <CourseContentControl
                renderEdit={(content, handleEditorChange) => {
                  return {
                    body: (
                      <Editor
                        value={content.body}
                        onChange={(contentBody: string) => {
                          handleEditorChange(contentBody);
                        }}
                        data-test-id="timun-courseView__update-aboutCourse-content"
                        init={{
                          ...CrmEditorConfig.defaultConfig(),
                          height: 500,
                          menubar: true,
                        }}
                      />
                    ),
                  };
                }}
                renderPreview={(content) => {
                  return {
                    body: (
                      <React.Fragment>
                        {content.body && (
                          <MathContent key={content.body}>
                            <div dangerouslySetInnerHTML={{ __html: content.body }} />
                          </MathContent>
                        )}
                      </React.Fragment>
                    ),
                  };
                }}
              />
            )}
          </FormItem>

          {/* ----- quiz ----- */}
          {/* TODO: add InpuntNumber component in Form for numberOfQuestion in course exam */}

          {ButtonControls}
        </Form>
      </div>
    );
  };

  handleCancel = () => {
    if (this.props.form.isFieldsTouched()) {
      confirmationDialog({
        onConfirm: () => {
          this.props.form.resetFields();
          this.props.onCancel();
        },
        title: this.props.translate('COMMON.CONFIRMATION_CANCEL_ACTION_MESSAGE'),
      });
    } else {
      this.props.form.resetFields();
      this.props.onCancel();
    }
  };

  handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    this.props.form.validateFields((err: any, values: any) => {
      console.log(values.courseGroups);
      if (!err) {
        const data = {
          ...this.props.course,
          title: values.courseTitle,
          courseGroups: values.courseGroups,
          content: values.content,
          status: { id: values.courseStatus },
          visibility: { id: values.courseVisibility },
        };
        this.props.onSubmit(data);
      }
    });
  };
}

const CourseDataForm = Form.create()(CourseDataFields);

export default withLocalize<ICourseDataFormOwnProps>(withRoles(CourseDataForm as any));
