import { IQuiz } from '@lamarodigital/quizzler-lib-frontend/model/quiz/Quiz';
import AppContent from '@src/components/common/container/AppContent';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import ExamTemplateHelperUtils from '@src/components/exam/common/ExamTemplateHelperUtils';
import ExamInstanceList from '@src/components/exam/examtemplate/ExamInstanceList';
import ExamTemplateUpdateModal from '@src/components/exam/modal/ExamTemplateUpdateModal';
import QuizCreatorModal from '@src/components/exam/quiz/QuizCreatorModal';
import ExamTemplateStatisticsPanel from '@src/components/exam/view/ExamTemplateStatisticsPanel';
import { ActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IExamInstance } from '@src/model/education/ExamInstance';
import { IExamTemplate } from '@src/model/education/ExamTemplate';
import { IExamTemplateStatistics } from '@src/model/education/ExamTemplateStatistics';
import { ICollectionData } from '@src/service/business/common/types';
import { Button, Col, Row, Tooltip, Typography } from 'antd';
import React from 'react';
import { WithRouterProps } from 'react-router';

// -- Prop types
// ----------

export interface IExamTemplateViewOwnProps {
  examTemplate?: IExamTemplate;
  examTemplateStatistics: IExamTemplateStatistics;
  examTemplateInstanceList: ICollectionData<IExamInstance>;
  headerTitleRoute?: string;
  handleExamTemplateSubmit: (updatedExamTemplate: IExamTemplate) => void;
  handleQuizCollectionSubmit: (quizData: IQuiz) => void;
  onPageChange?: (page: number, pageSize?: number) => void;
}

type IExamTemplateViewProps = IExamTemplateViewOwnProps & IWithLocalizeOwnProps & WithRouterProps;

interface IExamTemplateViewState {
  isInstructionModalVisible: boolean;
  isQuizzlerCreatorVisible: boolean;
}

// -- Component
// ----------

/** Component that display exam template details */
class ExamTemplateView extends React.Component<IExamTemplateViewProps, IExamTemplateViewState> {
  state: IExamTemplateViewState = {
    isInstructionModalVisible: false,
    isQuizzlerCreatorVisible: false,
  };

  render() {
    const isExamTemplateFinished = this.props.examTemplate && ExamTemplateHelperUtils.isExamTemplateInPhase(this.props.examTemplate, [ActivityPhaseEnum.FINISHED]);
    return (
      <AppContent>
        <HeaderTitle title={this.props.translate('EXAM_TEMPLATE.EXAM_TEMPLATE_HEADER_LABEL')} link={this.props.headerTitleRoute} icon={<LemonIcon name="left" />} />
        <Typography.Title> {this.props.examTemplate?.title} </Typography.Title>
        <Row justify="space-between">
          <Col>
            <Tooltip title={isExamTemplateFinished ? this.props.translate('EXAM_TEMPLATE.EXAM_STATUS_FINISHED_INFO_LABEL') : null}>
              <Button disabled={isExamTemplateFinished} onClick={() => this.toggleQuizCreatorModal(true)}>{this.props.translate('EXAM_TEMPLATE.VIEW_QUESTION_LIST')}</Button>
            </Tooltip>
          </Col>
          <Col>
            <Row>
              <Col>
                <Tooltip title={isExamTemplateFinished ? this.props.translate('EXAM_TEMPLATE.EXAM_STATUS_FINISHED_INFO_LABEL') : null}>
                  <Button disabled={isExamTemplateFinished} onClick={() => this.toggleInstructionModal(true)}>{this.props.translate('EXAM_TEMPLATE.EDIT_BUTTON_LABEL')}</Button>
                </Tooltip>
              </Col>
            </Row>
          </Col>
        </Row>
        {
          this.props.examTemplateStatistics &&
          <ExamTemplateStatisticsPanel examTemplateStatistics={this.props.examTemplateStatistics} />
        }

        {this.props.examTemplateInstanceList && <Row>
          <Col span={24}>
            <ExamInstanceList examInstances={this.props.examTemplateInstanceList} />
          </Col>
        </Row>}

        {/* Modal for updating exam template instruction and settings */}
        {this.state.isInstructionModalVisible && this.props.examTemplate && <ExamTemplateUpdateModal examTemplate={this.props.examTemplate} onCancel={this.handleModalCancel} onSubmit={this.handleExamTemplateSubmit} />}
        {/* Modal for quizzler creator*/}
        {this.state.isQuizzlerCreatorVisible && <QuizCreatorModal onCancel={this.toggleQuizCreatorModal} quizId={this.props.examTemplate?.collection?.id} onQuizCollectionSubmit={this.props.handleQuizCollectionSubmit} />}
      </AppContent>
    );
  }


  handleModalCancel = () => {
    this.toggleInstructionModal(false);
  }

  toggleInstructionModal = (isVisible: boolean) => {
    this.setState({ isInstructionModalVisible: isVisible });
  };

  toggleQuizCreatorModal = (isVisible: boolean) => {
    this.setState({ isQuizzlerCreatorVisible: isVisible });
  };

  handleExamTemplateSubmit = (updatedExamTemplate: IExamTemplate) => {
    this.props.handleExamTemplateSubmit(updatedExamTemplate);
    this.handleModalCancel();
  }
}

// -- HOCs and exports
// ----------

export default withLocalize<IExamTemplateViewOwnProps>(ExamTemplateView as any);
