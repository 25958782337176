import * as Sentry from '@sentry/browser';
import { Logger, LogLevelNumbers, MethodFactory } from 'loglevel';

import { getCurrentUser, isUserLoggedIn } from '@src/service/business/login/loginBusinessService';
import AppConfigService from '@src/service/common/AppConfigService';
import { LangUtils } from '@src/service/util/LangUtils';

const SENTRY_CONFIG = AppConfigService.getValue('logging.sentry.config');
const APP_ENVIRONMENT = AppConfigService.getValue('app.environment');

/** Loglevel sentry plugin. */
const methodFactory = (originalFactory: MethodFactory) => (methodName: string, level: LogLevelNumbers, loggerName: string | symbol) => {
  const rawMethod = originalFactory(methodName, level, loggerName);

  return (...args: any[]) => {
    // ----- error log
    if (methodName === 'error') {
      Sentry.withScope((scope) => {
        const eventData = prepareEventData(...args);

        if (eventData.error != null) {
          scope.setExtra('message', eventData.message);
          Sentry.captureException(eventData.error);
        } else {
          Sentry.captureMessage(eventData.message);
        }
      });
    }
    // ----- warning log
    else if (methodName === 'warn') {
      const eventData = prepareEventData(...args);

      // we do not expect error objects here(?!)
      Sentry.captureMessage(eventData.message, Sentry.Severity.Warning);
    }

    // always call raw method to preserve logging function chain
    rawMethod.apply(null, args);
  };
};

/** Search method args, separate error from messages and create event object. */
function prepareEventData(...args: any[]) {
  let error: Error | undefined;
  const messages: string[] = [];

  // search args and extract error from messages
  args.forEach((item) => {
    // find an instance of Error object
    if (item instanceof Error) {
      error = item;
    }
    // everything else is serialized as string message
    else {
      messages.push(LangUtils.serialize(item));
    }
  });

  const message = messages.join('; ');

  return {
    error,
    message,
  };
}

/** Plugin application function. */
export function applyPlugin(rootLogger: Logger) {
  // initialize sentry
  Sentry.init({
    ...SENTRY_CONFIG,
    environment: APP_ENVIRONMENT,
  });

  // configure global scope
  Sentry.configureScope((scope) => {
    if (isUserLoggedIn()) {
      const currentUser = getCurrentUser();
      scope.setUser({
        id: currentUser.id,
        // TODO: user does not have username anymore, should we put anything?
        // username: currentUser.username,
      });
    }
  });

  const originalFactory = rootLogger.methodFactory;
  rootLogger.methodFactory = methodFactory(originalFactory);
}
