import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import SkillForm from '@src/components/skillgroup/form/SkillForm';
import { ISkill } from '@src/model/skillgroup/Skill';
import { ISkillGroup } from '@src/model/skillgroup/SkillGroup';
import { ISkillLevelClassification } from '@src/model/skillgroup/SkillLevelClassification';
import { ICollectionData } from '@src/service/business/common/types';
import { ISkillCreatePayload } from '@src/service/business/skillgroup/SkillBusinessStore';
import { Form, Modal } from 'antd';

// -- Prop types
// ----------
export interface ISkillModalOwnProps {
  onClose?: () => void;
  onUpdateSkill?: (skillData: ISkill) => void;
  onCreateSkill?: (skillData: ISkillCreatePayload) => void;
  title: string;
  skill?: ISkill;
  skillGroup?: ISkillGroup;
  skillLevelClassificationList?: ICollectionData<ISkillLevelClassification>;
}

type ISkillModalProps = ISkillModalOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Display form for create and update skill */
const SkillModal: React.FC<ISkillModalProps> = (props: ISkillModalProps) => {

  const [form] = Form.useForm<ISkill>();

  const handleSubmit = (value: ISkill) => {
    if (props.skill?.id) {
      //  update
      props.onUpdateSkill?.(value);
    } else if (props.onCreateSkill && props.skillGroup) {
      // create
      props.onCreateSkill({ ...value, skillGroup: props.skillGroup });
    }
  };

  return (
    <Modal
      title={props.title}
      visible={true}
      onCancel={props.onClose}
      onOk={form.submit}
      okText={props.translate('COMMON.ACTION_SAVE')}
      cancelText={props.translate('COMMON.ACTION_CANCEL')}>
      <SkillForm
        formRef={form}
        skill={props.skill}
        onSubmit={handleSubmit}
      />
    </Modal >
  );
};

export default withLocalize<ISkillModalOwnProps>(SkillModal as any);
