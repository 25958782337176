import DeleteElementButton from '@src/components/common/button/DeleteElementButton';
import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import { UserNameView } from '@src/components/common/view/UserNameView';
import { IReport, IReportStatusEnum } from '@src/model/report/Report';
import { ICollectionDataCount } from '@src/service/business/common/types';
import AppConfigService from '@src/service/common/AppConfigService';
import { Empty, Row, Spin, Table, Tooltip } from 'antd';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router';

// -- Const
// ----------
const Column = Table.Column;

const dateTimeFormat = AppConfigService.getValue('dateTimeFormat.dateWithTime');
const TABLE_SCROLL_SIZE = AppConfigService.getValue('layout.table.scrollSize');

// -- Prop types
// ----------
export interface IReportListOwnProps {
  reportList: IReport[];
  pagination: ICollectionDataCount;
  onDelete: (data: IReport) => void;
  onReload: () => void;
}

type IReportListProps = IReportListOwnProps & IWithLocalizeOwnProps & IWithPathResolverOwnProps;

// -- Component
// ----------

class ReportList extends React.Component<IReportListProps> {
  render = () => {
    return (
      <Table
        rowKey={(record) => record.id}
        dataSource={this.props.reportList}
        bordered={true}
        locale={{
          emptyText: <Empty description={this.props.translate('REPORTS_LIST.TABLE_EMPTY')}/>,
        }}
        pagination={false}
        scroll={{ x: TABLE_SCROLL_SIZE }}
      >
        <Column key="name" width="40%" title={this.props.translate('REPORTS_LIST.TABLE_FILENAME_LABEL')}
                render={(record: IReport) => (
                  <React.Fragment>
                    {record.status.id === IReportStatusEnum.DONE && record.fileResource.name}
                    {record.status.id === IReportStatusEnum.IN_CREATION && this.props.translate('REPORTS_LIST.TABLE_UNFINISHED_REPORT')}
                    {record.status.id === IReportStatusEnum.FAILED && this.props.translate('REPORTS_LIST.TABLE_FAILED_REPORT')}
                  </React.Fragment>
                )}
        />

        <Column key="type" width="20%" title={this.props.translate('REPORTS_LIST.TABLE_TYPE_LABEL')} align="center" render={(record: IReport) => this.props.translate(`REPORTS_LIST.TYPE_${record.type.name}`)}/>

        <Column key="requestedBy" width="15%" title={this.props.translate('REPORTS_LIST.TABLE_REQUESTED_LABEL')} align="center" render={(record: IReport) => <UserNameView user={record.requestedBy} fallback={this.props.translate('USER_VIEW.USER_DELETED_LABEL')}/>}/>

        <Column key="datetime" width="18%" title={this.props.translate('REPORTS_LIST.TABLE_DATETIME_LABEL')} align="center" render={(record: IReport) => moment(record.requestedDateTime).format(dateTimeFormat)}/>

        < Column
          key="actions"
          width="7%"
          title={
            <a onClick={() => this.props.onReload()}>
              <LemonIcon name="redo"/>
            </a>
          }
          align="center"
          render={(record: IReport) => (
            <Row justify="space-between">

              {record.status.id === IReportStatusEnum.DONE && (
                <Link to={this.props.resolveApiFilePath(record.fileResource.id)} download={true} target="_self">
                  <Tooltip title={this.props.translate('COMMON.ACTION_DOWNLOAD')}><LemonIcon name="download"/></Tooltip>
                </Link>
              )}

              {record.status.id === IReportStatusEnum.IN_CREATION && (
                <Spin indicator={<LemonIcon name="spinner" spin={true}/>}/>
              )}

              {record.status.id === IReportStatusEnum.FAILED && (
                <Spin indicator={<LemonIcon name="times"/>}/>
              )}

              <Link to="">
                <DeleteElementButton<IReport> item={record} titlePropPath={'fileResource.name'} onDelete={this.props.onDelete} iconOnly={true}/>
              </Link>
            </Row>
          )}
        />
      </Table>
    );
  };
}

// -- HOCs and exports
// ----------

export default withPathResolver(withLocalize<IReportListOwnProps>(ReportList as any));
