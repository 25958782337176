import React from 'react';
import withLocalize, {IWithLocalizeOwnProps} from "@src/components/common/localize/withLocalize";
import { Progress } from "antd";
import { ProgressProps } from "antd/lib/progress";
import {CourseCompletionStatusEnum, ICourseProgress} from "@src/model/course/Course";

interface IProgressBarOwnProps {
  progressProps: ProgressProps,
  progress?: ICourseProgress,
};

type IProgressBarProps = IProgressBarOwnProps & IWithLocalizeOwnProps;

class ProgressBar extends React.Component<IProgressBarProps> {

  render() {
    let courseProgressStatus: ProgressProps['status'];
    let courseProgressPercentage;

    let isQuizLectureNext = this.props.progress && this.props.progress.completedLecturesNumber !== 0 && this.props.progress.completedLecturesNumber !== this.props.progress.lecturesNumber;
    let userCompletionStatus = this.props.progress && this.props.progress.completionStatus.id === CourseCompletionStatusEnum.IN_PROGRESS && this.props.progress.completedLecturesNumber === this.props.progress.lecturesNumber;

    if (isQuizLectureNext || userCompletionStatus) {
      courseProgressStatus = 'active';
    }

    if (this.props.progress) {
      courseProgressPercentage = this.props.progress ? Math.round((this.props.progress.completedLecturesNumber / this.props.progress.lecturesNumber) * 100) : undefined;
    }

    return(
      <Progress {...this.props.progressProps}  percent={courseProgressPercentage} status={courseProgressStatus}>
        {this.props.children}
      </Progress>
    );
  }
}

export default withLocalize<IProgressBarOwnProps>(ProgressBar as any);