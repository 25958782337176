import { Observable, Observer } from 'rxjs';

import { IFile } from '@src/model/file/File';
import { ApiResponseEventStatus, SuccessApiResponse } from '@src/service/api/model/apiEvent';
import AuthTokenManager from '@src/service/util/AuthTokenManager';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import UrlBuilderFactory from '@src/service/util/UrlBuilderFactory';
import { message } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { UploadFile, UploadProps } from 'antd/lib/upload/interface';

/** Helper utils for handling file uploads. */
export class FileUploadHelper {

  static getUploadDefaultProps = (): UploadProps => {
    return {
      action: UrlBuilderFactory.createApiFileBuilder().build(),
      headers: {
        // tslint:disable-next-line: object-literal-key-quotes
        authorization: `Bearer ${AuthTokenManager.getToken()}`,
        // remove X-Requested-With header because CORS complains it is not in header
        // but this generates an error: Type 'null' is not assignable to type 'string' because of --strictNullChecks
        // TODO: open issue on https://github.com/ant-design/ant-design/issues
        'X-Requested-With': null as any,
      },
    };
  };
  /** Upload file blob to API files resource. Can be used with drag'n'drop, editors, ... and other components that read entire file into o blob. */
  static uploadFileBlob(fileBlob: any, fileName: string): Observable<IFile> {
    return Observable.create((observer: Observer<IFile>): void => {
      try {
        const formAction = UrlBuilderFactory.createApiFileBuilder().build();

        /** Upload completed handler */
        function uploadCompleted(evt: any) {
          let json;

          if (xhr.status != 200) {
            observer.error('HTTP Error: ' + xhr.status);
            return;
          }
          json = JSON.parse(xhr.responseText);

          if (!json || !json.payload) {
            // } || typeof json.location != 'string') {
            observer.error('Invalid JSON: ' + xhr.responseText);
            return;
          }
          observer.next(json.payload);
        }

        /** Upload failed handler */
        function uploadFailed(evt: any) {
          observer.error('Upload error: ' + evt);
        }

        /** Upload canceled handler */
        function uploadCanceled(evt: any) {
          observer.error('Upload cancelled: ' + evt);
        }

        // NOTE: stupid rx-http-request client does not support multipart form upload so we have to use basic XHR object :facepalm:
        const xhr = new XMLHttpRequest();
        xhr.withCredentials = false;

        // xhr.addEventListener("progress", updateProgress);
        xhr.addEventListener('load', uploadCompleted);
        xhr.addEventListener('error', uploadFailed);
        xhr.addEventListener('abort', uploadCanceled);

        xhr.open('POST', formAction);

        const formData = new FormData();
        formData.append('file', fileBlob, fileName);
        xhr.send(formData);
      } catch (err) {
        observer.error(err);
      }
    });
  }

  static handleAntdUploadChange = <T>(info: UploadChangeParam<UploadFile<SuccessApiResponse<T>>>, onUploadSuccess?: (file: T) => any) => {
    const file = info.file;
    const filename = file.name;
    if (file.status === 'done') {
      // stop spinner
      if (file.response?.status === ApiResponseEventStatus.ERROR) {
        message.error(LocalizeService.translate('FILE_UPLOAD.FILE_UPLOAD_ERROR_MESSAGE', { filename }));
      } else {
        if (onUploadSuccess && file.response) {
          onUploadSuccess(file.response.payload);
        }
        message.success(LocalizeService.translate('FILE_UPLOAD.FILE_ADDED_MESSAGE', { filename }));
      }
    } else if (file.status === 'error') {
      message.error(LocalizeService.translate('FILE_UPLOAD.FILE_UPLOAD_ERROR_MESSAGE', { filename }));
    } else if (file.status === 'removed') {
      // TODO: it gets to status removed when BE refuses upload
      message.warning(LocalizeService.translate('FILE_UPLOAD.FILE_REMOVED_MESSAGE', { filename }));
    }
  };
}
