import React from 'react';
import UserGroupContainer from '@src/components/usergroup/UserGroupContainer';
import { withRouter, WithRouterProps } from 'react-router';

type IUserGroupPageProps = WithRouterProps;

const UserGroupPage = (props: IUserGroupPageProps) => <UserGroupContainer userGroupId={props.params.userGroupId} />;

export default withRouter(UserGroupPage as any);


