import React from 'react';

import HomeView from '@src/components/home/HomeView';

export const HomePage = (props: any) => (
  <div>
    <br />
    <br />
    <h3>Hello! Welcome to Timun!</h3>
    <HomeView label="ASDF" />

    {/* render subroutes */}
    {props.children}
  </div>
);
