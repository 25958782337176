import { Form } from '@ant-design/compatible';

import { FormComponentProps } from '@ant-design/compatible/es/form';
import { hasFormErrors } from '@src/components/common/form/validation';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ISkillGroup } from '@src/model/skillgroup/SkillGroup';
import { ISkillGroupCreatePayload } from '@src/service/business/skillgroup/SkillGroupBusinessStore';
import { Input, Modal, Radio } from 'antd';
import React, { SyntheticEvent } from 'react';

const FormItem = Form.Item;

// -- Prop types
// ----------
interface ISkillGroupFormOwnProps {
  onClose?: () => void;
  onUpdate?: (skillGroupData: ISkillGroup) => void;
  onCreate?: (skillGroupData: ISkillGroupCreatePayload) => void;
  title: string;
  skillGroup?: ISkillGroup;
}

type ISkillGroupFormProps = ISkillGroupFormOwnProps & IWithLocalizeOwnProps & FormComponentProps;

// -- Component
// ----------
class SkillGroupForm extends React.Component<ISkillGroupFormProps, any> {
  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        title={this.props.title}
        visible={true}
        onCancel={this.props.onClose}
        onOk={this.handleSubmit}
        okButtonProps={{ disabled: !this.props.form.isFieldsTouched() || this.hasFormErrors() }}
        okText={this.props.translate('COMMON.ACTION_SAVE')}
        cancelText={this.props.translate('COMMON.ACTION_CANCEL')}>
        <Form>
          <FormItem label={this.props.translate('COMMON.LABEL.NAME')}>
            {getFieldDecorator('title', {
              initialValue: this.props.skillGroup?.title,
              rules: [
                { whitespace: true, message: this.props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
                { required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') },
                { max: 128, message: this.props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') },
              ],
            })(<Input/>)}
          </FormItem>

          <FormItem label={this.props.translate('COMMON.LABEL.STATUS')}>
            {getFieldDecorator('active', {
              initialValue: this.props.skillGroup?.active,
              rules: [
                { required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') },
              ],
            })(
              <Radio.Group buttonStyle="solid">
                <Radio.Button value={true}>{this.props.translate('COMMON.LABEL.ACTIVE')} </Radio.Button>
                <Radio.Button value={false}>{this.props.translate('COMMON.LABEL.INACTIVE')} </Radio.Button>
              </Radio.Group>,
            )}
          </FormItem>

          <FormItem className="hidden">
            {getFieldDecorator('id', {
              initialValue: this.props.skillGroup?.id,
            })(<Input type="hidden"/>)}
          </FormItem>
        </Form>
      </Modal>
    );
  }

  handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        let skillGroupData = { ...values };
        if (this.props.skillGroup) {
          skillGroupData = { ...this.props.skillGroup, ...values };
          this.props.onUpdate?.(skillGroupData);
        } else {
          this.props.onCreate?.(skillGroupData);
        }
      }
    });
  };

  hasFormErrors = (): boolean => {
    const { form } = this.props;
    const fieldsError: any = form.getFieldsError();
    return hasFormErrors(fieldsError);
  };

}

// -- HOCs and exports
// ----------

const SkillGroupModal = Form.create()(SkillGroupForm);

export default withLocalize<ISkillGroupFormOwnProps>(SkillGroupModal as any);
