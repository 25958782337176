import { PlusOutlined } from '@ant-design/icons';
import ListPagination from '@src/components/common/list/ListPagination';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import EmailTemplateFilter from '@src/components/emailtemplate/filter/EmailTemplateFilter';
import EmailTemplateList from '@src/components/emailtemplate/list/EmailTemplateList';
import EmailTemplateCreateContainer from '@src/components/emailtemplate/update/EmailTemplateCreateContainer';
import EmailTemplateUpdateContainer from '@src/components/emailtemplate/update/EmailTemplateUpdateContainer';
import EmailTemplateContentView from '@src/components/emailtemplate/view/EmailTemplateContentView';
import { IEmailTemplate } from '@src/model/emailtemplate/EmailTemplate';
import { ICollectionData } from '@src/service/business/common/types';
import { IEmailTemplateListFilter } from '@src/service/business/emailtemplates/EmailTemplateBusinessStore';
import { Button, Row } from 'antd';
import React from 'react';

// -- Prop types
// ----------
interface IEmailTemplateListViewOwnProps {
  emailTemplateList: ICollectionData<IEmailTemplate>;
  showControls: boolean;
  emailTemplateListViewFilter?: IEmailTemplateListFilter;
  onDelete?: (emailTemplate: IEmailTemplate) => void;
  onFilterChange: (filter: IEmailTemplateListFilter) => void;
  handlePageChange: (page: number, pageSize?: number) => void;
  onChange: () => void;
}

type IEmailTemplateListViewProps = IEmailTemplateListViewOwnProps & IWithLocalizeOwnProps;

// --
// ----- State types
interface IEmailTemplateListViewState {
  isEditModalVisible: boolean;
  isCreateModalVisible: boolean;
  isContentModalVisible: boolean;
  selectedEmailTemplate?: IEmailTemplate;
  content?: string;
}

// -- Component
// ----------
class EmailTemplateListView extends React.Component<IEmailTemplateListViewProps, IEmailTemplateListViewState> {
  state: IEmailTemplateListViewState = {
    isEditModalVisible: false,
    isCreateModalVisible: false,
    isContentModalVisible: true,
    selectedEmailTemplate: undefined,
    content: undefined,
  };

  render() {
    return (
      <React.Fragment>
        {/* Action */}
        <Row justify="end">
          <Button type="primary" icon={<PlusOutlined/>} onClick={this.handleCreate}>{this.props.translate('EMAIL_TEMPLATE.ADD_EMAIL_TEMPLATE_LABEL')}</Button>
        </Row>

        {/* Filter */}
        <EmailTemplateFilter filter={this.props.emailTemplateListViewFilter} onFilterChange={this.props.onFilterChange}/>
        {/* Pagination */}
        {this.props.emailTemplateList && <ListPagination page={this.props.emailTemplateList.page} onChange={this.props.handlePageChange}/>}
        {/* List */}
        <EmailTemplateList emailTemplateList={this.props.emailTemplateList} onDelete={this.props.onDelete} onShowContent={this.handleShowContent} showControls={this.props.showControls} onEdit={this.handleEdit}/>
        {/* Pagination */}
        {this.props.emailTemplateList && <ListPagination page={this.props.emailTemplateList.page} onChange={this.props.handlePageChange}/>}

        {/* Modal for email template */}
        {this.state.isContentModalVisible && this.state.content && <EmailTemplateContentView content={this.state.content} onCancel={this.handleCloseContent}/>}
        {this.state.isCreateModalVisible && <EmailTemplateCreateContainer onCreate={this.handleEmailTemplateCreateSubmit} onCancel={this.handleCreateCancel}/>}
        {this.state.isEditModalVisible && <EmailTemplateUpdateContainer emailTemplate={this.state.selectedEmailTemplate} onCancel={this.handleEditCancel} onUpdate={this.handleEmailTemplateUpdateSubmit}/>}
      </React.Fragment>
    );
  }

  toggleEmailTemplateContentModal = (isVisible: boolean) => {
    this.setState({ isContentModalVisible: isVisible });
  };

  toggleEmailTemplateEditModal = (isVisible: boolean) => {
    this.setState({ isEditModalVisible: isVisible });
  };

  toggleEmailTemplateCreateModal = (isVisible: boolean) => {
    this.setState({ isCreateModalVisible: isVisible });
  };

  handleCloseContent = () => {
    this.toggleEmailTemplateContentModal(false);
    this.setState({
      content: undefined,
    });
  };

  handleShowContent = (content: string) => {
    this.toggleEmailTemplateContentModal(true);
    this.setState({
      content,
    });
  };
  
  handleCreate = () => {
    this.toggleEmailTemplateCreateModal(true);
  };

  handleCreateCancel = () => {
    this.toggleEmailTemplateCreateModal(false);
  };

  handleEmailTemplateCreateSubmit = () => {
    this.props.onChange();
    this.toggleEmailTemplateCreateModal(false);
  };

  handleEdit = (selectedEmailTemplate: IEmailTemplate) => {
    this.setState({ selectedEmailTemplate });
    this.toggleEmailTemplateEditModal(true);
  };

  handleEditCancel = () => {
    this.toggleEmailTemplateEditModal(false);
  };

  handleEmailTemplateUpdateSubmit = () => {
    this.props.onChange();
    this.toggleEmailTemplateEditModal(false);
  };

}

// -- HOCs and exports
// ----------

export default withLocalize<IEmailTemplateListViewOwnProps>(EmailTemplateListView as any);
