import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IAddress } from '@src/model/user/Address';
import { Col, Form, Input, Row } from 'antd';


// -- Prop types
// ----------
type IAddressShortInfo = Array<Pick<IAddress, 'city' | 'streetWithHomeNumber'>>;

export interface IAddressShortInfoFormInputOwnProps {
  value?: IAddressShortInfo;
  onChange?: (value?: IAddressShortInfo) => void;
  required?: boolean;
  disabled?: boolean;
}
type IAddressShortInfoFormInputProps = IAddressShortInfoFormInputOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Display form with city and streetWithHomeNumber input fields */
const AddressShortInfoForm: React.FC<IAddressShortInfoFormInputProps> = (props: IAddressShortInfoFormInputProps) => {
  const handleChange = (changedValues: any, allValues: IAddressShortInfo) => props.onChange?.(allValues);
  return (
    <Form<IAddressShortInfo>
      name="address"
      initialValues={props.value}
      onValuesChange={handleChange}
      component={false}>
      <Row gutter={8}>
        <Col>
          <Form.Item
            name={['city']}
            rules={[{ required: props.required, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
            { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') }]}>
            <Input disabled={props.disabled} placeholder={props.translate('ADDRESS_SHORT_INFO_INPUT.CITY_PLACEHOLDER')} />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            name={['streetWithHomeNumber']}
            rules={[{ required: props.required, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
            { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') }]}>
            <Input disabled={props.disabled} placeholder={props.translate('ADDRESS_SHORT_INFO_INPUT.STREET_WITH_HOME_NUMBER_PLACEHOLDER')} />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default withLocalize<IAddressShortInfoFormInputOwnProps>(AddressShortInfoForm as any);
