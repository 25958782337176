import rootLogger, { Logger, LogLevelDesc, LogLevel } from 'loglevel';


// ----- Types

export interface LemonLogger extends rootLogger.Logger {
}


// ----- Loggers

/** Returns root logger. */
export function getRootLogger(): LemonLogger {
  return rootLogger;
}

/** Create new or return existing named logger */
export function getLogger(name: string): LemonLogger {
  return rootLogger.getLogger(name);
}


// ----- Log level

/** Set default logging level. Lower levels are not logged. */
export function setDefaultLevel(level: LogLevelDesc): void {
  rootLogger.setDefaultLevel(level);
}


/** Get current logging level */
export function getLevel(): LogLevel[keyof LogLevel] {
  return rootLogger.getLevel();
}


// ----- Plugins

/**
 * Add logger plugin.
 * Plugins are applied to logger ie. to logger's default factory method.
 * Multiple logins can be applied as they will create function composition applying each plugin's tranfromation to desired log message.
 */
export function addLoggerPlugin(applyFunction: (logger: Logger) => void) {
  applyFunction(rootLogger);
}
