/**
 * Structure of Lemon's custom events.
 *
 * Wrapping raw event data allows features such as tracking events to (async?) actions and reacting to their state.
 */
export interface ILemonEvent<T> {
  readonly id: string;
  readonly data: Readonly<T>;
}

/** Create custom Lemon event. */
export function createEvent<T>(data: T): ILemonEvent<T> {
  return {
    id: generateEventId(),
    data,
  };
}

// ---------- private

/** Generate unique event ID. */
function generateEventId(): string {
  // tslint:disable-next-line:no-bitwise - we need bitwise op to convert rnd number to int
  const rnd = (Math.random() * 1000) | 0;
  return `EVENT-${Date.now().toString()}-${rnd}`;
}
