import EducationCard from '@src/components/common/card/EducationCard';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import CourseCoverImage from '@src/components/course/common/CourseCoverImage';
import CourseHelperUtils from '@src/components/course/common/CourseHelperUtils';
import CourseProgressView from '@src/components/course/common/CourseProgressView';
import { ICourse } from '@src/model/course/Course';
import { Col, Empty, Row } from 'antd';
import React from 'react';

// -- Prop types
// ----------

export interface ICourseCardListOwnProps {
  courses: ICourse[];
  showProgressView?: boolean;
  showStatusBadge?: boolean;
  getPath: (id: string, lecture?: string) => string;
}

type ICourseCardListProps = ICourseCardListOwnProps & IWithLocalizeOwnProps & IWithRolesOwnProps;

// -- Component
// ----------

/** Show list of courses grouped by course groups. Additional (custom) columns can be added per each course using render function. */
class CourseCardList extends React.Component<ICourseCardListProps> {
  render() {
    return (
      this.props.courses.length ? (
        <Row className="timun-courseCardList__container" gutter={[16, 16]}>
          {this.props.courses.map((course: ICourse) => {
            return (
              <Col key={course.id} xs={24} sm={12} md={8} lg={6}>
                <EducationCard
                  type="COURSE"
                  currentLecture={CourseHelperUtils.getCurrentLecture(course.progress)}
                  showStatusBadge={this.props.showStatusBadge}
                  key={course.id}
                  education={course}
                  getPath={this.props.getPath}
                  cardCover={<CourseCoverImage coverImageUrl={course.coverImageUrl}/>}
                  cardDescription={this.props.showProgressView ? <CourseProgressView course={course}/>
                    : null}
                />
              </Col>
            );
          })}
        </Row>
      ) : (
        <Empty description={this.props.translate('COMMON.MESSAGE_NO_DATA')}/>
      )
    );
  }
}

export default withRoles(withLocalize<ICourseCardListOwnProps>(CourseCardList as any));
