import React from 'react';

export interface IHtmlViewerProps {
  dangerousValue?: string | null;
}

/** Display html tags as TextArea with scrollbar */
export const HtmlViewer = (props: IHtmlViewerProps) => {
  return (
    props.dangerousValue ? <div className="timun-htmlViewer__textArea">
      <div className="timun-htmlViewer__scrollbar" dangerouslySetInnerHTML={{ __html: props.dangerousValue }} />
    </div> : null
  );
};
