import { IPaymentInfo } from '@src/model/common/PaymentInfo';
import { IFile } from '@src/model/file/File';
import { IOrganization } from '@src/model/organization/Organization';
import { IOrganizationMember } from '@src/model/organization/OrganizationMember';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import ListFilterBusinessStore from '@src/service/business/common/listFilterBusinessStore';
import { ICollectionData, ICollectionFetchPayload, IIdDataPayload, IIdPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError, createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { ICompanyCreatePayload } from '@src/service/business/company/CompanyBusinessStore';
import { startGlobalProgress, stopGlobalProgress, trackAction } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

// -
// -------------------- Types&Consts
export interface IOrganizationMemberListFilter {
}

export type IOrganizationMemberCreatePayload = Exclude<IOrganizationMember, 'id'>;

export interface IOrganizationCreatePayload {
  description?: string;
  paymentInfo?: Exclude<IPaymentInfo, 'id'>;
  webUrl?: string;
  active: boolean;
  company: ICompanyCreatePayload;
  logo?: IFile;
}
// List filter ID
const ORGANIZATION_MEMBER_LIST_FILTER = '@@ORGANIZATION_MEMBER_LIST_FILTER';

// -
// -------------------- Selectors

/** Returns Organization from store. */
const getOrganization = (store: any): IOrganization => store.organization;

/** Returns OrganizationMember list from store. */
const getOrganizationMemberList = (store: any): ICollectionData<IOrganizationMember> => store.organizationMemberList;

/** Returns OrganizationMember list filter. */
const getOrganizationMemberListFilter = (store: any): IOrganizationMemberListFilter => ListFilterBusinessStore.selectors.getListFilter(store, ORGANIZATION_MEMBER_LIST_FILTER);


// -
// -------------------- Actions

const Actions = {
  ORGANIZATION_FETCH: 'ORGANIZATION_FETCH',
  ORGANIZATION_LOAD: 'ORGANIZATION_LOAD',
  ORGANIZATION_CLEAR: 'ORGANIZATION_CLEAR',
  ORGANIZATION_CREATE: 'ORGANIZATION_CREATE',
  ORGANIZATION_UPDATE: 'ORGANIZATION_UPDATE',
  ORGANIZATION_DELETE: 'ORGANIZATION_DELETE',
  ORGANIZATION_LEAVE: 'ORGANIZATION_LEAVE',
  ORGANIZATION_JOIN: 'ORGANIZATION_JOIN',
  ORGANIZATION_JOIN_CODE_GENERATE: 'ORGANIZATION_JOIN_CODE_GENERATE',
  ORGANIZATION_MEMBER_LIST_FETCH: 'ORGANIZATION_MEMBER_LIST_FETCH',
  ORGANIZATION_MEMBER_LIST_LOAD: 'ORGANIZATION_MEMBER_LIST_LOAD',
  ORGANIZATION_MEMBER_LIST_CLEAR: 'ORGANIZATION_MEMBER_LIST_CLEAR',
  ORGANIZATION_MEMBERS_ADD: 'ORGANIZATION_MEMBERS_ADD',
  ORGANIZATION_MEMBERS_UPDATE: 'ORGANIZATION_MEMBERS_UPDATE',
  ORGANIZATION_MEMBERS_DELETE: 'ORGANIZATION_MEMBERS_DELETE',
};

/** Fetch Organization by ID. */
const fetchOrganization = (id: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.ORGANIZATION_FETCH,
    payload: {
      id,
    },
  };
};

/** Load Organization to store. */
const loadOrganization = (data: IOrganization): IPayloadAction<IOrganization> => {
  return {
    type: Actions.ORGANIZATION_LOAD,
    payload: data,
  };
};

/** Clear organization profile from store. Eg. when leaving view. */
const clearOrganization = (): ILemonAction => {
  return {
    type: Actions.ORGANIZATION_CLEAR,
  };
};

/** Create Organization */
const createOrganization = (data: IOrganizationCreatePayload): IPayloadAction<IOrganizationCreatePayload> => {
  return {
    type: Actions.ORGANIZATION_CREATE,
    payload: data,
  };
};

/** Update Organization */
const updateOrganization = (data: IOrganization): IPayloadAction<IOrganization> => {
  return {
    type: Actions.ORGANIZATION_UPDATE,
    payload: data,
  };
};

/** Delete Organization */
const deleteOrganization = (id: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.ORGANIZATION_DELETE,
    payload: {
      id,
    },
  };
};

/** Leave Organization */
const leaveOrganization = (id: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.ORGANIZATION_LEAVE,
    payload: {
      id,
    },
  };
};

/** Join Organization */
const joinOrganization = (accessCode: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.ORGANIZATION_JOIN,
    payload: {
      id: accessCode,
    },
  };
};

/** Create Organization joining code. */
const generateOrganizationJoinCode = (id: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.ORGANIZATION_JOIN_CODE_GENERATE,
    payload: {
      id,
    },
  };
};

/** Fetch OrganizationMember list by id and filter */
const fetchOrganizationMemberList = (organizationId: string, params: ICollectionFetchPayload<IOrganizationMemberListFilter>): IPayloadAction<IIdDataPayload<ICollectionFetchPayload<IOrganizationMemberListFilter>>> => {
  return {
    type: Actions.ORGANIZATION_MEMBER_LIST_FETCH,
    payload: {
      id: organizationId,
      data: params,
    },
  };
};

/** Load OrganizationMember list to the store */
const loadOrganizationMemberList = (data: ICollectionData<IOrganizationMember>): IPayloadAction<ICollectionData<IOrganizationMember>> => {
  return {
    type: Actions.ORGANIZATION_MEMBER_LIST_LOAD,
    payload: data,
  };
};

/** Clear OrganizationMember list from store. Eg. when leaving view. */
const clearOrganizationMemberList = (): ILemonAction => {
  return {
    type: Actions.ORGANIZATION_MEMBER_LIST_CLEAR,
  };
};

/** Clear OrganizationMember list filter from store. Eg. when leaving view. */
const clearOrganizationMemberListFilter = (): ILemonAction => {
  return ListFilterBusinessStore.actions.clearListFilter(ORGANIZATION_MEMBER_LIST_FILTER);
};

/** Add organizationMembers to target profile */
const addOrganizationMembers = (profileId: string, data: IOrganizationMemberCreatePayload[]): IPayloadAction<IIdDataPayload<IOrganizationMemberCreatePayload[]>> => {
  return {
    type: Actions.ORGANIZATION_MEMBERS_ADD,
    payload: {
      id: profileId,
      data,
    },
  };
};

/** update organizationMembers in target profile */
const updateOrganizationMembers = (profileId: string, data: IOrganizationMember): IPayloadAction<IIdDataPayload<IOrganizationMember>> => {
  return {
    type: Actions.ORGANIZATION_MEMBERS_UPDATE,
    payload: {
      id: profileId,
      data,
    },
  };
};


/** remove organizationMembers from target profile */
const removeOrganizationMembers = (profileId: string, data: IOrganizationMember[]): IPayloadAction<IIdDataPayload<IOrganizationMember[]>> => {
  return {
    type: Actions.ORGANIZATION_MEMBERS_DELETE,
    payload: {
      id: profileId,
      data,
    },
  };
};

// -
// -------------------- Side-effects

const fetchOrganizationEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.ORGANIZATION_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('OrganizationProfile')
        .fetchEntity(id)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadOrganization(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching Organization', error);
      return o;
    })
  );
};

const createOrganizationEffect = (action$: Observable<IPayloadAction<IOrganizationCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.ORGANIZATION_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('OrganizationProfile')
        .createEntity(payload)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating organization profile', error);
      return o;
    })
  );
};

const updateOrganizationEffect = (action$: Observable<IPayloadAction<IOrganization>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.ORGANIZATION_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('OrganizationProfile')
        .updateEntity(data.id, data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating organization profile', error);
      return o;
    })
  );
};

const deleteOrganizationEffect = (action$: Observable<IPayloadAction<IIdDataPayload<string>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.ORGANIZATION_DELETE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const id = action.payload.id;

      return EntityApiServiceRegistry.getService('OrganizationProfile')
        .deleteEntity(id)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_DELETE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error deleting organization profile', error);
      return o;
    })
  );
};

const leaveOrganizationEffect = (action$: Observable<IPayloadAction<IIdDataPayload<string>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.ORGANIZATION_LEAVE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const id = action.payload.id;

      return EntityApiServiceRegistry.getService('OrganizationProfile')
        .fetchSubobject(id, 'leave')
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_SEND_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error leaving organization profile', error);
      return o;
    })
  );
};

const joinOrganizationEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.ORGANIZATION_JOIN;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const accessCode = action.payload.id;

      return EntityApiServiceRegistry.getService('OrganizationProfile')
        .createMethod('join', { value: accessCode })
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'ORGANIZATION_ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_SEND_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error joining organization profile', error);
      return o;
    })
  );
};

const generateOrganizationJoinCodeEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.ORGANIZATION_JOIN_CODE_GENERATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('OrganizationProfile')
        .createSubmethod(id, 'code')
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_SEND_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error generating joining code', error);
      return o;
    })
  );
};

const fetchOrganizationMemberListEffect = (action$: Observable<IPayloadAction<IIdDataPayload<ICollectionFetchPayload<IOrganizationMemberListFilter>>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.ORGANIZATION_MEMBER_LIST_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('OrganizationProfile')
        .fetchSubentityList(id, 'member', data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadOrganizationMemberList(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error fetching OrganizationMember list', error);
      return o;
    })
  );
};

const addOrganizationMembersEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IOrganizationMemberCreatePayload[]>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.ORGANIZATION_MEMBERS_ADD;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('OrganizationProfile')
        .createSubentityList(id, 'member', data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error adding organizationMembers', error);
      return o;
    })
  );
};

const updateOrganizationMembersEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IOrganizationMember>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.ORGANIZATION_MEMBERS_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('OrganizationProfile')
        .updateSubentityList(id, 'member', data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error updating organizationMembers', error);
      return o;
    })
  );
};

const removeOrganizationMembersEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IOrganizationMember[]>>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.ORGANIZATION_MEMBERS_DELETE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('OrganizationProfile')
        .deleteSubentityList(id, 'member', data)
        .pipe(trackAction(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.log('Error removing organizationMembers', error);
      return o;
    })
  );
};


// -
// -------------------- Reducers

const organization = (state: IOrganization | null = null, action: IPayloadAction<IOrganization>) => {
  if (action.type === Actions.ORGANIZATION_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.ORGANIZATION_CLEAR) {
    return null;
  }

  return state;
};

const organizationMemberList = (state: ICollectionData<IOrganizationMember> | null = null, action: IPayloadAction<ICollectionData<IOrganizationMember>>) => {
  if (action.type === Actions.ORGANIZATION_MEMBER_LIST_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.ORGANIZATION_MEMBER_LIST_CLEAR) {
    return null;
  }

  return state;
};


// --
// -------------------- Business Store

export const OrganizationBusinessStore = {
  actions: {
    fetchOrganization,
    loadOrganization,
    clearOrganization,
    createOrganization,
    updateOrganization,
    deleteOrganization,
    leaveOrganization,
    joinOrganization,
    generateOrganizationJoinCode,
    fetchOrganizationMemberList,
    loadOrganizationMemberList,
    clearOrganizationMemberList,
    clearOrganizationMemberListFilter,
    addOrganizationMembers, updateOrganizationMembers, removeOrganizationMembers,
  },

  selectors: {
    getOrganization,
    getOrganizationMemberList,
    getOrganizationMemberListFilter,
  },

  effects: {
    fetchOrganizationEffect,
    fetchOrganizationMemberListEffect,
    createOrganizationEffect,
    updateOrganizationEffect,
    generateOrganizationJoinCodeEffect,
    deleteOrganizationEffect,
    leaveOrganizationEffect,
    joinOrganizationEffect,
    addOrganizationMembersEffect, updateOrganizationMembersEffect, removeOrganizationMembersEffect,
  },

  reducers: {
    organization,
    organizationMemberList,
  },
};

// --
// export business store

export default OrganizationBusinessStore;
