import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ISkill } from '@src/model/skillgroup/Skill';
import { ISkillGroup } from '@src/model/skillgroup/SkillGroup';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import SkillGroupBusinessStore, { ISkillGroupListFilter } from '@src/service/business/skillgroup/SkillGroupBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { TreeSelect } from 'antd';
import memoizeOne from 'memoize-one';
import { DataNode } from 'rc-tree/lib/interface';
import React from 'react';
import { connect } from 'react-redux';

// -- Const
// ----------
const MAX_PAGE_SIZE = AppConfigService.getValue('api.collectionDefaultLimit');

// -- PropTypes
// ----------

export interface ISkillTreePickerOwnProps {
  value?: ISkill;
  disabled?: boolean;
  onChange?: (newValue?: ISkill) => void;
}

export interface ISkillTreePickerStateProps { }
export interface ISkillTreePickerDispatchProps {
  fetchSkillGroupList: (params: ICollectionFetchPayload<ISkillGroupListFilter>) => ITrackableAction;
}

type ISkillTreePickerProps = ISkillTreePickerOwnProps & ISkillTreePickerStateProps & ISkillTreePickerDispatchProps & IWithLocalizeOwnProps;

interface ISkillTreePickerState {
  skillList?: ISkillGroup[];
}

// -- Component
// ----------
class SkillTreePicker extends React.Component<ISkillTreePickerProps, ISkillTreePickerState> {
  state: ISkillTreePickerState = {};

  /** Map skill list to data picker item list. */
  private mapToItems = memoizeOne((skillGroups?: ISkillGroup[]): DataNode[] => {
    return (
      skillGroups
        ?.filter((skillGroup) => skillGroup.active && skillGroup.skills?.length > 0)
        .map((skillGroup) => {
          return {
            key: `group_${skillGroup.id}`,
            value: `group_${skillGroup.id}`,
            title: skillGroup.title,
            selectable: false,
            isLeaf: false,
            children: this.mapSkillGroupToItems(skillGroup),
            data: skillGroup,
            ['data-test-id']: `timun-skillTreePicker__skillGroup_${skillGroup.id}`,
          };
        }) || []
    );
  });

  /** Map skill list to data picker item list. */
  private mapSkillGroupToItems = memoizeOne((skillGroup: ISkillGroup): DataNode[] => {
    return (
      skillGroup.skills
        ?.filter((skill) => skill.active)
        .map((skill) => {
          return {
            key: skill.id,
            value: skill.id,
            title: skill.title,
            selectable: true,
            isLeaf: true,
            children: undefined,
            data: skill,
            ['data-test-id']: `timun-skillTreePicker__skill_${skill.id}`,
          };
        }) || []
    );
  });

  componentDidMount = () => {
    this.fetchList();
  };

  render = () => {
    const valueParentId: string | undefined = this.props.value?.skillGroup?.id;

    const pickerItems = this.mapToItems(this.state.skillList);

    return <TreeSelect<string> showSearch={true} treeNodeFilterProp="title" defaultValue={this.props.value?.id} treeData={pickerItems} treeDefaultExpandedKeys={valueParentId ? [`group_${valueParentId}`] : undefined} disabled={this.props.disabled} onChange={this.handleDataPickerChange} />;
  };

  handleDataPickerChange = (value: string, labelList: React.ReactNode[], extra: { triggerNode: { props: { data?: ISkill; }; }; }) => {
    if (this.props.onChange && extra.triggerNode.props.data) {
      this.props.onChange(extra.triggerNode.props.data);
    }
  };

  private fetchList() {
    this.props
      .fetchSkillGroupList({
        filter: {},
        page: 0,
        size: MAX_PAGE_SIZE,
        sort: [],
      })
      .track()
      .subscribe(this.storeList);
  }

  private storeList = (skillList?: ICollectionData<ISkillGroup>) => {
    this.setState({ skillList: skillList?.content || [] });
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ISkillTreePickerOwnProps): ISkillTreePickerStateProps => ({});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ISkillTreePickerDispatchProps => ({
  fetchSkillGroupList: (params: ICollectionFetchPayload<ISkillGroupListFilter>) => dispatch(createTrackableAction(SkillGroupBusinessStore.actions.fetchSkillGroupList(params))),
});

export default connect<ISkillTreePickerStateProps, ISkillTreePickerDispatchProps, ISkillTreePickerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(SkillTreePicker as any));
