import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import React from 'react';
import { connect } from 'react-redux';

import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';
import AppContent from '@src/components/common/container/AppContent';
import ExamInstanceView from '@src/components/exam/examInstance/ExamInstanceView';
import { ActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IExamInstance } from '@src/model/education/ExamInstance';
import activityBusinessStore, { IActivityPhaseCreatePayload } from '@src/service/business/examtemplates/activityBusinessStore';
import examInstanceBusinessStore from '@src/service/business/examtemplates/examInstanceBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

// -- Prop types
// ----------

export interface IExamInstanceContainerOwnProps {
  examInstanceId: string;
}
export interface IExamInstanceContainerStateProps {
  examInstance: IExamInstance;
}
export interface IExamInstanceContainerDispatchProps {
  fetchExamInstance: (examInstanceId: string) => ITrackableAction;
  clearExamInstance: () => void;
  submitExamInstance: (examInstance: IExamInstance) => ITrackableAction;
  updateActivityNextPhase: (id: string, data: IActivityPhaseCreatePayload) => ITrackableAction;
}
type IExamInstanceContainerProps = IExamInstanceContainerOwnProps & IExamInstanceContainerStateProps & IExamInstanceContainerDispatchProps & IWithLocalizeOwnProps;

interface IExamInstanceContainerState {
}

// -- Component
// ----------

/** Describe your component ... */
class ExamInstanceContainer extends React.Component<IExamInstanceContainerProps, IExamInstanceContainerState> {
  state: IExamInstanceContainerState = {
  };

  componentDidMount() {
    // mount
    this.fetchExamInstance(this.props.examInstanceId);
  }

  componentDidUpdate(prevProps: IExamInstanceContainerProps, prevState: IExamInstanceContainerState) {
    if (this.props.examInstanceId !== prevProps.examInstanceId) {
      this.fetchExamInstance(this.props.examInstanceId);
    }
  }

  render() {
    return (
      <AppContent>
        {/* --- Display exam instance view --- */}
        {this.props.examInstance && <ExamInstanceView examInstance={this.props.examInstance} onSubmit={this.handleExamInstanceSubmit} onExamStart={this.resolveConfirmationDialog} />}
      </AppContent>
    );
  }

  resolveConfirmationDialog = () => {
    confirmationDialog({
      onConfirm: () => this.handleNextPhase(this.props.examInstance.activities[0].id, ActivityPhaseEnum.ANSWERING_STARTED),
    });
  };

  handleExamInstanceSubmit = (examInstance: IExamInstance) => {
    this.props.submitExamInstance(examInstance).track().subscribe(
      // success
      () => this.fetchExamInstance(this.props.examInstanceId)
    );
  };

  fetchExamInstance = (examInstanceId: string) => {
    this.props.fetchExamInstance(examInstanceId);
  };

  handleNextPhase = (activityId: string, nextPhaseId: string) => {
    this.props.updateActivityNextPhase(activityId, { phaseId: nextPhaseId }).track().subscribe(
      // success
      () => this.fetchExamInstance(this.props.examInstanceId)
    );
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IExamInstanceContainerOwnProps): IExamInstanceContainerStateProps => ({
  examInstance: examInstanceBusinessStore.selectors.getExamInstance(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IExamInstanceContainerDispatchProps => ({
  fetchExamInstance: (examInstanceId: string) => createTrackableAction(dispatch(examInstanceBusinessStore.actions.fetchExamInstance(examInstanceId))),
  clearExamInstance: () => dispatch(examInstanceBusinessStore.actions.clearExamInstance()),
  submitExamInstance: (examInstance: IExamInstance) => createTrackableAction(dispatch(examInstanceBusinessStore.actions.submitExamInstance(examInstance))),
  updateActivityNextPhase: (id: string, data: IActivityPhaseCreatePayload) => createTrackableAction(dispatch(activityBusinessStore.actions.updateActivityPhase(id, data))),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<IExamInstanceContainerOwnProps>(ExamInstanceContainer as any));
