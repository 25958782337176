import React from 'react';
import { connect } from 'react-redux';

import AppContent from '@src/components/common/container/AppContent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import TenantConfigurationEdit from '@src/components/tenant/TenantConfigurationEdit';
import TenantConfigurationView from '@src/components/tenant/TenantConfigurationView';
import ITenantConfiguration from '@src/model/tenant/TenantConfiguration';
import PublicTenantConfigurationBusinessStore from '@src/service/business/tenant/publicTenantConfigurationBusinessService';
import TenantConfigurationBusinessStore, { ITenantConfigurationUpdatePayload } from '@src/service/business/tenant/tenantConfigurationBusinessService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

// -- Prop types
// ----------

export interface ITenantConfigurationContainerOwnProps { }
export interface ITenantConfigurationContainerStateProps {
  tenantConfiguration: ITenantConfiguration;
}
export interface ITenantConfigurationContainerDispatchProps {
  updateTenantConfiguration: (tenantConfiguration: ITenantConfiguration) => ITrackableAction;
}
type ITenantConfigurationContainerProps = ITenantConfigurationContainerOwnProps & ITenantConfigurationContainerStateProps & ITenantConfigurationContainerDispatchProps & IWithLocalizeOwnProps;

interface ITenantConfigurationContainerState {
  editing: boolean;
}

// -- Component
// ----------

/** Describe your component ... */
class TenantConfigurationContainer extends React.Component<ITenantConfigurationContainerProps, ITenantConfigurationContainerState> {
  state: ITenantConfigurationContainerState = {
    editing: false,
  };

  render = () => {
    return (
      <AppContent>
        {this.props.tenantConfiguration && (this.state.editing ? <TenantConfigurationEdit configuration={this.props.tenantConfiguration} onCancel={this.handleCancel} onSubmit={this.handleSubmit} /> : <TenantConfigurationView configuration={this.props.tenantConfiguration} onEdit={this.handleEdit} />)}
      </AppContent>
    );
  };

  handleCancel = () => {
    this.stopEditing();
  };

  handleEdit = () => {
    this.startEditing();
  };

  handleSubmit = (data: ITenantConfigurationUpdatePayload) => {
    this.props
      .updateTenantConfiguration(data)
      .track()
      .subscribe(() => this.stopEditing());
  };

  startEditing = () => {
    this.setState({ editing: true });
  };
  stopEditing = () => {
    this.setState({ editing: false });
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ITenantConfigurationContainerOwnProps): ITenantConfigurationContainerStateProps => ({
  tenantConfiguration: PublicTenantConfigurationBusinessStore.selectors.getTenantConfiguration(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ITenantConfigurationContainerDispatchProps => ({
  updateTenantConfiguration: (tenantConfiguration: ITenantConfiguration) => dispatch(createTrackableAction(TenantConfigurationBusinessStore.actions.updateTenantConfiguration(tenantConfiguration))),
});

export default connect<ITenantConfigurationContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(TenantConfigurationContainer as any));
