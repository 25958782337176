import { LeftOutlined } from '@ant-design/icons';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import RouterNavigationPrompt from '@src/components/common/route/prompt/RouterNavigationPrompt';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import WebinarDataForm from '@src/components/webinar/create/WebinarDataForm';
import { IFile } from '@src/model/file/File';
import webinarViewBusinessStore, { IWebinarCreatePayload } from '@src/service/business/webinars/webinarViewBusinessStore';
import { createActionThunk, IActionThunkMap } from '@src/service/util/action/thunk';
import { Layout } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';
import { Dispatch } from 'redux';

const { Header, Content } = Layout;

interface IWebinarCreateContainerOwnProps { }

interface IWebinarCreateContainerDispatchProps {
  createWebinar: (data: IWebinarCreatePayload, callback: IActionThunkMap) => void;
  uploadWebinarCover: (id: string, data: IFile, callback: IActionThunkMap) => void;
}

type IWebinarCreateContainerProps = IWebinarCreateContainerOwnProps & IWebinarCreateContainerDispatchProps & IWithLocalizeOwnProps & WithRouterProps;

interface IWebinarCreateContainerState {
  isEditingCover: boolean;
  coverImage?: IFile;
}

class WebinarCreateContainer extends React.Component<IWebinarCreateContainerProps, IWebinarCreateContainerState> {
  state: IWebinarCreateContainerState = {
    isEditingCover: false,
    coverImage: undefined,
  };

  render() {
    return (
      <RouterNavigationPrompt when={true} message={this.props.translate('COMMON.CONFIRMATION_ROUTE_NAVIGATION')}>
        <Layout>
          <Header className="timun-webinarView__webinarTitle">
            <div className="flex-between">
              <HeaderTitle title={this.props.translate('WEBINAR_VIEW.CREATE.CREATE_WEBINAR_TITLE')} link={'/webinars'} icon={<LeftOutlined />} />
            </div>
          </Header>
          <Layout>
            &nbsp;
            <Content className="timun-webinarView__content">
              <WebinarDataForm onSubmit={this.handleWebinarCreate} onCancel={this.handleCancel} onCoverSubmit={this.handleCoverPick} coverImageId={this.state.coverImage?.id} />
            </Content>
          </Layout>
        </Layout>
      </RouterNavigationPrompt>
    );
  }

  handleCancel = () => {
    this.props.router.push('/webinars');
  };

  handleWebinarCreate = (data: IWebinarCreatePayload) => {
    this.props.createWebinar(data, {
      success: (response) => {
        if (this.state.coverImage) {
          this.handleWebinarCoverSubmit(response.id);
        } else {
          this.props.router.replace(`/webinar/${response.id}`);
        }
      },
    });
  };

  private handleWebinarCoverSubmit = (webinarId: string) => {
    if (this.state.coverImage) {
      this.props.uploadWebinarCover(webinarId, this.state.coverImage, {
        success: () => {
          this.props.router.replace(`/webinar/${webinarId}`);
        },
      });
    }
  };

  private handleCoverPick = (data: IFile) => {
    if (data) {
      this.setState({
        coverImage: data,
      });
    }
  };
}

// -- HOCs and exports
// ----------

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: Dispatch): IWebinarCreateContainerDispatchProps => ({
  createWebinar: (data: IWebinarCreatePayload, thunkMap: IActionThunkMap) => dispatch(createActionThunk(webinarViewBusinessStore.actions.createWebinar(data), thunkMap)),
  uploadWebinarCover: (id: string, data: IFile, thunkMap: IActionThunkMap) => dispatch(createActionThunk(webinarViewBusinessStore.actions.uploadWebinarCover(id, data), thunkMap)),
});

export default connect(null, mapDispatchToProps)(withLocalize<IWebinarCreateContainerOwnProps>(withRouter(WebinarCreateContainer as any)));
