// constructs theme file name from theme name
const STYLE_THEME_FILENAME_CREATOR = (name: string) => `theme-${name}.less`;
// extract theme name from theme file path
const STYLE_THEME_NAME_MATCHER = (filePath: string) =>
  filePath
    .match(/.+theme-(.+).less$/)
    ?.slice(1, 2)
    .shift();

const styleThemePathContext: __WebpackModuleApi.RequireContext = require.context('@src/asset/style', false, /theme-[^\.]+\.less$/, 'lazy');

/**
 * Resolve app style themes from webpack context.
 *
 * Only theme name should be provided (eg. 'default') which is used to construct file name: `./theme-<NAME>.less`
 * For available themes see folder "@src/asset/style"
 *
 * @param {string} name - theme name
 * @param {string} fallback - fallback theme name if requested theme does not exists (for what ever reason)
 */
function styleThemePathResolver(name: string, fallback: string): Promise<any> {
  let themeFileName = STYLE_THEME_FILENAME_CREATOR(name);
  if (!styleThemeExists(themeFileName)) {
    console.log(`Requested theme "${name}" does not exist. Using fallback theme "${fallback}".`);
    themeFileName = STYLE_THEME_FILENAME_CREATOR(fallback);
  }

  return styleThemePathContext(`./${themeFileName}`);
}

/** Returns list of available theme names. Sheme names are extracted from webpack's context use for packaging available theme files. */
function availableStyleThemes(): string[] {
  return styleThemePathContext
    .keys()
    .map((filePath: string) => {
      return STYLE_THEME_NAME_MATCHER(filePath);
    })
    .filter((item): item is string => item != null);
}

/** Check if theme file exists. */
function styleThemeExists(name: string): boolean {
  return styleThemePathContext.keys().find((filePath: string) => filePath.endsWith(name)) != null;
}

export default styleThemePathResolver;
export { availableStyleThemes };
