import { Observable, Observer } from 'rxjs';

import { ILemonAction } from '@src/service/business/common/types';
import { ActionThunkHelper } from '@src/service/util/action/thunk';

/** Thunk action RxJS operator which takes care of simple success/error handling. */
export default (action: ILemonAction) => {
  // create and return pure operator function
  return <T>(source: Observable<T>): Observable<T> => {
    return Observable.create((subscriber: Observer<T>) => {
      return source.subscribe(
        (data: T) => {
          try {
            subscriber.next(data);
            ActionThunkHelper.success(action, data);
          } catch (err) {
            subscriber.error(err);
            ActionThunkHelper.error(action, err);
          }
        },
        (err) => {
          subscriber.error(err);
          ActionThunkHelper.error(action, err);
        },
        () => {
          subscriber.complete();
        }
      );
    });
  };
};
