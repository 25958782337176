import React from 'react';

import FormItemLabelIcon from '@src/components/common/form/FormItemLabelIcon';
import IIdRef from '@src/model/common/IdRef';
import AppConfigService from '@src/service/common/AppConfigService';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import { Form, Input, Modal, Radio } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

// -- Const
// ----------
const TEXT_AREA_MAX_LENGTH = AppConfigService.getValue('components.common.bigCharacterLengthInput');
const INPUT_MAX_LENGTH = AppConfigService.getValue('components.common.mediumCharacterLengthInput');

// -- Prop types
// ----------
export interface ICodebookShortInfoFormOwnProps<T, P> {
  value?: T;
  onCreate?: (data: P) => void;
  onUpdate?: (data: T) => void;
  onCancel?: () => void;
}

type ICodebookShortInfoFormProps<T, P> = ICodebookShortInfoFormOwnProps<T, P>;

// -- Component
// ----------

/** Display modal with shortInfo form for create and update shortInfo objects (name,description) */
const CodebookShortInfoForm = <T extends IIdRef<string>, P>(props: ICodebookShortInfoFormProps<T, P>) => {
  const isUpdateType = (arg: any): arg is T => {
    return arg.id !== undefined;
  };
  const isCreateType = (arg: any): arg is P => {
    return arg !== undefined;
  };

  const [form] = Form.useForm<T>();
  const handleSubmit = (value: unknown) => {
    if (isUpdateType(value)) {
      //  update
      props.onUpdate?.(value);
    } else if (isCreateType(value)) {
      // create
      props.onCreate?.(value);
    }
  };

  return (
    <Modal
      visible={true}
      onCancel={props.onCancel}
      onOk={form.submit}
      okText={LocalizeService.translate('COMMON.ACTION_SAVE')}
      cancelText={LocalizeService.translate('COMMON.ACTION_CANCEL')}>
      <Form<T>
        layout="vertical"
        form={form}
        initialValues={props.value}
        onFinish={handleSubmit}>
        <Form.Item
          label={<FormItemLabelIcon label={LocalizeService.translate('COMMON.LABEL.NAME')}
            tooltipDescription={LocalizeService.translate('COMMON.LABEL.MAX_LENGTH_CHARACTER_INFO_LABEL', { maxCharNum: INPUT_MAX_LENGTH })} />}
          name={'name'}
          rules={[{ required: true, message: LocalizeService.translate('COMMON.FILL_REQUIRED_FIELD') },
          { whitespace: true, message: LocalizeService.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
          { max: INPUT_MAX_LENGTH, message: LocalizeService.translate('COMMON.LABEL.MAX_LENGTH_REACHED') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={<FormItemLabelIcon label={LocalizeService.translate('EDUCATION_QUALIFICATION_FORM.DESCRIPTION_LABEL')}
            tooltipDescription={LocalizeService.translate('COMMON.LABEL.MAX_LENGTH_CHARACTER_INFO_LABEL', { maxCharNum: TEXT_AREA_MAX_LENGTH })} />}
          name={'description'}
          rules={[{ whitespace: true, message: LocalizeService.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
          { max: TEXT_AREA_MAX_LENGTH, message: LocalizeService.translate('COMMON.LABEL.MAX_LENGTH_REACHED') }]}
        >
          <TextArea />
        </Form.Item>

        <Form.Item
          label={LocalizeService.translate('COMMON.LABEL.STATUS')}
          name={'active'}
          rules={[{ required: true, message: LocalizeService.translate('COMMON.PICK_REQUIRED_FIELD') }]}>
          <Radio.Group buttonStyle="solid">
            <Radio.Button value={true}>{LocalizeService.translate('COMMON.LABEL.ACTIVE')} </Radio.Button>
            <Radio.Button value={false}>{LocalizeService.translate('COMMON.LABEL.INACTIVE')} </Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          noStyle={true}
          hidden={true}
          name={'id'}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal >
  );
};

export default CodebookShortInfoForm;
