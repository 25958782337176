import { Button, Col, Input, Row } from 'antd';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Subscription } from 'rxjs';

import IntegrationVideoPlayer from '@src/components/common/integrationvideo/IntegrationVideoPlayer';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IIntegrationVideo, IntegrationVideoTypeEnum } from '@src/model/integrationvideo/IntegrationVideo';
import IntegrationVideoBusinessStore from '@src/service/business/integrationvideo/IntegrationVideoBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

// -- Prop types
// ----------

export interface IIntegrationVideoImportOwnProps {
  value?: IIntegrationVideo;
  disabled?: boolean;

  onChange?: (value?: IIntegrationVideo) => void;
}
export interface IIntegrationVideoImportStateProps { }
export interface IIntegrationVideoImportDispatchProps {
  createVideo: (integrationId: string, integrationType: IntegrationVideoTypeEnum) => ITrackableAction<IIntegrationVideo>;
}
type IIntegrationVideoImportProps = IIntegrationVideoImportOwnProps & IIntegrationVideoImportStateProps & IIntegrationVideoImportDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Component for importing integration video from video ID. This can be used for already uploaded files. */
const IntegrationVideoImport: React.FC<IIntegrationVideoImportProps> = (props) => {
  const [videoId, setVideoId] = useState<string | undefined>();
  const [createVideoActionSubscription, setCreateVideoActionSubscription] = useState<Subscription | undefined>();

  // -------------------- start callbacks ----- //

  // ---------- component lifecycle

  // component unmount
  useEffect(() => {
    return () => {
      // clear subscription on unmount
      if (createVideoActionSubscription != null) {
        createVideoActionSubscription.unsubscribe();
        setCreateVideoActionSubscription(undefined);
      }
    };
  }, []);

  // --------- event handlers

  const handleVideoIdChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const newVideoId = value != null && value.trim().length > 0 ? value.trim() : undefined;

    setVideoId(newVideoId);
  };

  const handleVideoImportClick = () => {
    if (videoId != null) {
      const subscription = props
        .createVideo(videoId, IntegrationVideoTypeEnum.VIMEO)
        .track()
        .subscribe(
          (integrationVideo: IIntegrationVideo) => props.onChange?.(integrationVideo),
          (err) => {
            // TODO: show err here or in BS, maybe BS is enough
          },
          () => {
            setCreateVideoActionSubscription(undefined);
          }
        );
      setCreateVideoActionSubscription(subscription);
    }
  };

  // -------------------- end callbacks ----- //

  const videoPreview = props.value != null ? <IntegrationVideoPlayer videoId={props.value.integrationId} integrationType={props.value.integrationType.id} /> : null;

  return (
    <React.Fragment>
      <Row gutter={12}>
        <Col flex={10}>
          <Input defaultValue={props.value?.integrationId} disabled={props.disabled} onChange={handleVideoIdChange} />
        </Col>
        <Col>
          <Button type="primary" disabled={props.disabled ?? videoId == null} onClick={handleVideoImportClick}>
            {props.translate('INTEGRATION_VIDEO.IMPORT.IMPORT_BUTTON')}
          </Button>
        </Col>
      </Row>
      <br />
      <Row>
        <Col span="24">{videoPreview}</Col>
      </Row>
    </React.Fragment>
  );
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IIntegrationVideoImportOwnProps): IIntegrationVideoImportStateProps => ({});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IIntegrationVideoImportDispatchProps => ({
  createVideo: (integrationId: string, integrationType: IntegrationVideoTypeEnum) => dispatch(createTrackableAction(IntegrationVideoBusinessStore.actions.createVideo(integrationId, integrationType))),
});

export default connect<IIntegrationVideoImportStateProps, IIntegrationVideoImportDispatchProps, IIntegrationVideoImportOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(IntegrationVideoImport as any));
