import ListPagination from '@src/components/common/list/ListPagination';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import RepositoryFileList from '@src/components/repository/file/RepositoryFileList';
import RepositoryFileListFilter from '@src/components/repository/file/RepositoryFileListFilter';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import { IFile } from '@src/model/file/File';
import { ICollectionData } from '@src/service/business/common/types';
import { IFileListFilter } from '@src/service/business/files/FileListBusinessStore';
import React from 'react';

// -- Prop types
// ----------

interface IRepositoryFileListViewOwnProps {
  fileList: ICollectionData<IFile>;
  fileListFilter: IFileListFilter;
  selectedFile?: IFile;

  onFileSelect?: (data: IFile) => void;
  onPageChange?: (page: number, pageSize?: number) => void;
  onFilterChange?: (filter: IFileListFilter) => void;
}
type IRepositoryFileListViewProps = IRepositoryFileListViewOwnProps & IWithLocalizeOwnProps & IWithTenantPropEnabledOwnProps;

interface IRepositoryFileListViewState {
}

// -- Component
// ----------

class RepositoryFileListView extends React.Component<IRepositoryFileListViewProps, IRepositoryFileListViewState> {
  state: IRepositoryFileListViewState = {
  };

  render = () => {
    return (
      <React.Fragment>
        <RepositoryFileListFilter fileListFilter={this.props.fileListFilter} onFilterChange={this.props.onFilterChange} />
        <RepositoryFileList fileList={this.props.fileList.content} uploadMode={true} onChange={this.props.onFileSelect} selectedFile={this.props.selectedFile} />
        <div className="timun-repository__pagination">
          <ListPagination page={this.props.fileList.page} onChange={this.props.onPageChange} />
        </div>
      </React.Fragment>
    );
  };

}

// -- HOCs and exports
// ----------

export default withLocalize<IRepositoryFileListViewOwnProps>(withTenantPropEnabled(RepositoryFileListView as any));
