import CommentContainer from '@src/components/comment/CommentContainer';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import NoteCreateContainer from '@src/components/usernote/NoteCreateContainer';
import { CommentObjectTypeEnum } from '@src/model/comment/CommentObjectType';
import { NoteObjectTypeEnum } from '@src/model/user/Note';
import { Tabs } from 'antd';
import React from 'react';

export enum CourseContentNavigationMenuKeys {
  Comments = '1',
  Notes = '2',
}

interface IRelatedObject<T> {
  objectId: string;
  objectTypeId: T;
}

const { TabPane } = Tabs;

interface IRelatedObjectsOwnProps {
  commentObject: IRelatedObject<CommentObjectTypeEnum>;
  noteObject: IRelatedObject<NoteObjectTypeEnum>;
}

interface IRelatedObjectsState {
  selectedKey: string;
}

type IRelatedObjectsProps = IRelatedObjectsOwnProps & IWithLocalizeOwnProps;

class RelatedObjects extends React.Component<IRelatedObjectsProps, IRelatedObjectsState> {
  state = {
    selectedKey: CourseContentNavigationMenuKeys.Comments,
  };

  render() {
    return (
      <Tabs tabBarGutter={12} activeKey={this.state.selectedKey} onChange={this.onMenuClick}>
        <TabPane tab={this.props.translate('COURSE_VIEW.COMMENTS')} key={CourseContentNavigationMenuKeys.Comments} data-test-id="timun-relatedObjects__tab_comments">
          <div className="timun-relatedObjects__tabPane">
            <CommentContainer objectId={this.props.commentObject.objectId} objectTypeId={this.props.commentObject.objectTypeId}/>
          </div>
        </TabPane>
        <TabPane tab={this.props.translate('COURSE_VIEW.NOTES')} key={CourseContentNavigationMenuKeys.Notes} data-test-id="timun-relatedObjects__tab_notes">
          <div className="timun-relatedObjects__tabPane">
            {/* Component for creating note */}
            <NoteCreateContainer objectId={this.props.noteObject.objectId} objectTypeId={this.props.noteObject.objectTypeId}/>
          </div>
        </TabPane>
      </Tabs>
    );
  }

  onMenuClick = (actionKey: string) => {
    this.setState({
      selectedKey: actionKey,
    });
  };

}

export default withLocalize<IRelatedObjectsOwnProps>(RelatedObjects as any);
