import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, ignoreElements, map, mergeMap } from 'rxjs/operators';

import { IExternalEducationExpense } from '@src/model/externalEducationExpense/ExternalEducationExpense';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { IIdDataPayload, ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError, createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { actionThunk } from '@src/service/util/observable/operators';
import { startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';
import IIdRef from '@src/model/common/IdRef';

// -
// -------------------- Types&Consts

export interface IExternalEducationExpenseCreatePayload {
  expenseType: IIdRef<string>;
  externalEducationApplication: IIdRef<string>;
  paymentDate: string;
  value: number;
}

// -
// -------------------- Selectors

/** Returns external education from store. */
const getExternalEducationExpense = (store: any): IExternalEducationExpense => store.externalEducationExpenseData;

// -
// -------------------- Actions

const Actions = {
  EXTERNAL_EDUCATION_EXPENSE_FETCH: 'EXTERNAL_EDUCATION_EXPENSE_FETCH',
  EXTERNAL_EDUCATION_EXPENSE_LOAD: 'EXTERNAL_EDUCATION_EXPENSE_LOAD',
  EXTERNAL_EDUCATION_EXPENSE_CLEAR: 'EXTERNAL_EDUCATION_EXPENSE_CLEAR',
  EXTERNAL_EDUCATION_EXPENSE_CREATE: 'EXTERNAL_EDUCATION_EXPENSE_CREATE',
  EXTERNAL_EDUCATION_EXPENSE_UPDATE: 'EXTERNAL_EDUCATION_EXPENSE_UPDATE',
  EXTERNAL_EDUCATION_EXPENSE_DELETE: 'EXTERNAL_EDUCATION_EXPENSE_DELETE',
};

/** Fetch external education by ID. */
const fetchExternalEducationExpense = (id: string): IPayloadAction<string> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_EXPENSE_FETCH,
    payload: id,
  };
};

/** Load external education to store. */
const loadExternalEducationExpense = (data: IExternalEducationExpense): IPayloadAction<IExternalEducationExpense> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_EXPENSE_LOAD,
    payload: data,
  };
};

/** Clear external education from store. Eg. when leaving view. */
const clearExternalEducationExpenseData = (): ILemonAction => {
  return {
    type: Actions.EXTERNAL_EDUCATION_EXPENSE_CLEAR,
  };
};

/** Create new external education. */
const createExternalEducationExpense = (data: IExternalEducationExpenseCreatePayload): IPayloadAction<IExternalEducationExpenseCreatePayload> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_EXPENSE_CREATE,
    payload: data,
  };
};

/** Update external education by id */
const updateExternalEducationExpense = (id: string, data: IExternalEducationExpenseCreatePayload): IPayloadAction<IIdDataPayload<IExternalEducationExpenseCreatePayload>> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_EXPENSE_UPDATE,
    payload: {
      id,
      data,
    },
  };
};

/** Delete external education by id */
const deleteExternalEducationExpense = (id: string): IPayloadAction<string> => {
  return {
    type: Actions.EXTERNAL_EDUCATION_EXPENSE_DELETE,
    payload: id,
  };
};

// -
// -------------------- Side-effects

const fetchExternalEducationExpenseEffect = (action$: Observable<IPayloadAction<string>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_EDUCATION_EXPENSE_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const id = action.payload;

      return EntityApiServiceRegistry.getService('ExternalEducationExpense')
        .fetchEntity(id)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadExternalEducationExpense(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching external education', error);
      return o;
    })
  );
};

const createExternalEducationExpenseEffect = (action$: Observable<IPayloadAction<IExternalEducationExpenseCreatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_EDUCATION_EXPENSE_CREATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const payload = action.payload;

      return EntityApiServiceRegistry.getService('ExternalEducationExpense')
        .createEntity(payload)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'TAG_ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error creating external education', error);
      return o;
    })
  );
};

const updateExternalEducationExpenseEffect = (action$: Observable<IPayloadAction<IIdDataPayload<IExternalEducationExpenseCreatePayload>>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_EDUCATION_EXPENSE_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id, data } = action.payload;

      return EntityApiServiceRegistry.getService('ExternalEducationExpense')
        .updateEntity(id, data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'TAG_ERROR_MESSAGE', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating external education', error);
      return o;
    })
  );
};

const deleteExternalEducationExpenseEffect = (action$: Observable<IPayloadAction<string>>, state$: Observable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.EXTERNAL_EDUCATION_EXPENSE_DELETE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const id = action.payload;

      return EntityApiServiceRegistry.getService('ExternalEducationExpense')
        .deleteEntity(id)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    ignoreElements(),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_DELETE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error deleting external education', error);
      return o;
    })
  );
};


// -
// -------------------- Reducers

const externalEducationExpenseData = (state: IExternalEducationExpense | null = null, action: IPayloadAction<IExternalEducationExpense>) => {
  if (action.type === Actions.EXTERNAL_EDUCATION_EXPENSE_LOAD) {
    return {
      ...action.payload,
    };
  } else if (action.type === Actions.EXTERNAL_EDUCATION_EXPENSE_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

const ExternalEducationExpenseBusinessStore = {
  actions: {
    fetchExternalEducationExpense,
    clearExternalEducationExpenseData,
    createExternalEducationExpense,
    updateExternalEducationExpense,
    deleteExternalEducationExpense,
  },

  selectors: {
    getExternalEducationExpense,
  },

  effects: {
    fetchExternalEducationExpenseEffect,
    createExternalEducationExpenseEffect,
    updateExternalEducationExpenseEffect,
    deleteExternalEducationExpenseEffect,
  },

  reducers: {
    externalEducationExpenseData,
  },
};

// --
// export business store
export default ExternalEducationExpenseBusinessStore;
