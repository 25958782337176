import DataPicker, { IDataPickerItem } from '@src/components/common/datapicker/DataPicker';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IExternalEducationOutcome } from '@src/model/externalEducationTemplate/ExternalEducationOutcome';
import { ICollectionData } from '@src/service/business/common/types';
import ExternalEducationOutcomeListBusinessStore, { IExternalEducationOutcomeCreatePayload, IExternalEducationOutcomeListFilter } from '@src/service/business/externaleducations/outcomes/ExternalEducationOutcomeListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';
import { LangUtils } from '@src/service/util/LangUtils';
import { debounce } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';

const debounceTimeout = AppConfigService.getValue('components.common.debounceTimeout');
const minSearchLength = AppConfigService.getValue('components.common.minSearchStringLength');

// -- Prop types
// ----------

interface IExternalEducationOutcomePickerContainerOwnProps {
  value?: IExternalEducationOutcome;
  onChange?: (data?: IExternalEducationOutcome) => void;
  disabled?: boolean;
}

interface IExternalEducationOutcomePickerContainerStateProps {
}

interface IExternalEducationOutcomePickerContainerDispatchProps {
  fetchEducationOutcomes: (filter: any) => ITrackableAction;
  createEducationOutcome: (outcome: IExternalEducationOutcomeCreatePayload) => ITrackableAction;
}
type IExternalEducationOutcomePickerContainerProps = IExternalEducationOutcomePickerContainerOwnProps & IExternalEducationOutcomePickerContainerStateProps & IWithLocalizeOwnProps & IExternalEducationOutcomePickerContainerDispatchProps;

interface IExternalEducationOutcomePickerContainerState {
  educationOutcomes?: IExternalEducationOutcome[];
}

// -- Component
// ----------

class EducationOutcomePickerContainer extends React.Component<IExternalEducationOutcomePickerContainerProps, IExternalEducationOutcomePickerContainerState> {
  state: IExternalEducationOutcomePickerContainerState = {
    educationOutcomes: [],
  };

  handleSearch = debounce((value: string) => {
    const filter: IExternalEducationOutcomeListFilter = { title: value };
    if (value.length >= minSearchLength) {
      this.updateList(filter);
    } else if (LangUtils.isEmpty(value) || value.length < minSearchLength) {
      this.storeList();
    }
  }, debounceTimeout);

  render = () => {
    const pickerItems = this.state.educationOutcomes?.map(this.mapToItem) || [];
    const pickerValue = this.props.value && this.mapToItem(this.props.value);

    return (
      <DataPicker
        value={pickerValue}
        items={pickerItems}
        disabled={this.props.disabled}
        onChange={this.handleDataPickerChange}
        showCreate={true}
        onCreate={this.handleCreateOutcome}
        onSearch={this.handleSearch}
        placeholderSuffix={this.props.translate('EDUCATION_OUTCOME.PICKER.OUTCOME_PLACEHOLDER')}
        emptyMessage={this.props.translate('EDUCATION_OUTCOME.PICKER.OUTCOME_EMPTY_MESSAGE')}
        dataTestIdPrefix="timun-educationOutcomePicker"
      />
    );
  };

  handleCreateOutcome = (value: string) => {
    const newOutcome = { title: value };
    this.props.createEducationOutcome(newOutcome).track().subscribe(
      (data) => {
        if (this.props.onChange) {
          this.props.onChange(data);
        }
      }
    );
  };

  handleDataPickerChange = (value?: IDataPickerItem) => {
    if (this.props.onChange) {
      this.props.onChange(value?.data);
    }
  };

  private mapToItem = (outcome: IExternalEducationOutcome): IDataPickerItem => {
    return {
      value: outcome.id,
      name: outcome.title,
      data: outcome,
    };
  };

  private updateList = (filter: IExternalEducationOutcomeListFilter = {}) => {
    this.props.fetchEducationOutcomes(filter).track().subscribe(
      (data: ICollectionData<IExternalEducationOutcome>) => this.storeList(data.content)
    );
  };

  private storeList = (educationOutcomes?: IExternalEducationOutcome[]) => {
    this.setState({ educationOutcomes });
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IExternalEducationOutcomePickerContainerOwnProps): IExternalEducationOutcomePickerContainerStateProps => ({
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: Dispatch): IExternalEducationOutcomePickerContainerDispatchProps => ({
  fetchEducationOutcomes: (filter: IExternalEducationOutcomeListFilter) => createTrackableAction(dispatch(ExternalEducationOutcomeListBusinessStore.actions.fetchExternalEducationOutcomePickerList(filter))),
  createEducationOutcome: (outcome: IExternalEducationOutcomeCreatePayload) => createTrackableAction(dispatch(ExternalEducationOutcomeListBusinessStore.actions.createExternalEducationOutcome(outcome))),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<IExternalEducationOutcomePickerContainerOwnProps>(EducationOutcomePickerContainer as any));
