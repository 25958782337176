import { Form } from '@ant-design/compatible';

import { FormComponentProps } from '@ant-design/compatible/es/form';
import { confirmationDialog } from '@src/components/common/confirmation/ConfirmationDialog';
import { CrmEditorConfig } from '@src/components/common/editor/CrmEditorConfigs';
import { Editor } from '@src/components/common/editor/Editor';
import FormItemLabelIcon from '@src/components/common/form/FormItemLabelIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { INote, NoteObjectTypeEnum } from '@src/model/user/Note';
import AppConfigService from '@src/service/common/AppConfigService';
import { Input, Modal } from 'antd';
import React, { SyntheticEvent } from 'react';

const FormItem = Form.Item;

const MAX_NUMBER_OF_CHARACTER: number = AppConfigService.getValue('components.userNoteForm.maxChar');

interface INoteFormOwnProps {
  onCancel?: () => void;
  onSubmit?: (noteData: INote) => void;
  title: string;
  placeholder?: string;
  okButtonLabel?: string;
  note?: INote;
  objectId?: string;
  objectTypeId?: NoteObjectTypeEnum;
  objectPublic?: boolean;
}

type INoteFormProps = INoteFormOwnProps & IWithLocalizeOwnProps & FormComponentProps;

class NormalNoteForm extends React.Component<INoteFormProps, any> {
  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        className='lemon-modal__modal--md'
        title={this.props.title}
        visible={true}
        onCancel={this.handleCancel}
        onOk={this.handleSubmit}
        okButtonProps={{ disabled: !this.props.form.isFieldsTouched() || !!this.props.form.getFieldError('noteText') }}
        okText={this.props.okButtonLabel || this.props.translate('COMMON.ACTION_SAVE')}
        cancelText={this.props.translate('COMMON.ACTION_CANCEL')}>
        <Form>
          {this.props.placeholder && <span>{this.props.placeholder}</span>}
          <FormItem label={<FormItemLabelIcon label={''}
            tooltipDescription={this.props.translate('COMMON.LABEL.MAX_LENGTH_CHARACTER_INFO_LABEL', { maxCharNum: MAX_NUMBER_OF_CHARACTER })} />}>
            {getFieldDecorator('noteText', {
              initialValue: this.props.note ? this.props.note.text : '',
              rules: [
                { whitespace: true, message: this.props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
                // tslint:disable-next-line:no-duplicate-string
                { required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') },
                { max: MAX_NUMBER_OF_CHARACTER, message: this.props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') },
              ],
            })(<Editor
              data-test-id="timun-notesForm__noteText"

              init={{
                ...CrmEditorConfig.defaultConfig(),
                height: 300,
                menubar: true,
              }} />)}
          </FormItem>
          {/* Enable two hidden input field when creating new note */}
          {this.props.objectId && this.props.objectTypeId && <React.Fragment>
            <FormItem className="hidden">
              {getFieldDecorator('objectId', {
                initialValue: this.props.objectId,
                rules: [
                  { required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') },
                ],
              })(<Input type="hidden" />)}
            </FormItem>
            <FormItem className="hidden">
              {getFieldDecorator('objectTypeId', {
                initialValue: this.props.objectTypeId,
                rules: [
                  { required: true, message: this.props.translate('COMMON.FILL_REQUIRED_FIELD') },
                ],
              })(<Input type="hidden" />)}
            </FormItem>
          </React.Fragment>}
        </Form>
      </Modal>
    );
  }

  handleCancel = () => {
    if (this.props.onCancel) {
      if (this.props.form.isFieldsTouched()) {
        confirmationDialog({
          onConfirm: this.props.onCancel,
          title: this.props.translate('COMMON.CONFIRMATION_ROUTE_NAVIGATION'),
        });
      } else {
        this.props.onCancel();
      }
    }
  };

  handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        const noteData = this.props.note ? {
          ...this.props.note,
          text: values.noteText,
        } : {
          text: values.noteText,
          object: { id: values.objectId },
          objectType: { id: values.objectTypeId },
          public: this.props.objectPublic || false,
        } as INote;
        this.props.onSubmit?.(noteData);
      }
    });
  };
}

const NoteForm = Form.create()(NormalNoteForm);

export default withLocalize<INoteFormOwnProps>(NoteForm as any);
