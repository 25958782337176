import { PaperClipOutlined, PlusOutlined } from '@ant-design/icons';

import AppUserAvatar from '@src/components/common/image/AppUserAvatar';
import LemonImage from '@src/components/common/image/LemonImage';
import LemonInfoTooltip from '@src/components/common/info/LemonInfoTooltip';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import FileUpload, { IUploadedFile, IUploadStatus } from '@src/components/common/upload/FileUpload';
import { IFile } from '@src/model/file/File';
import AppConfigService from '@src/service/common/AppConfigService';
import NumberFormatService from '@src/service/util/numberformat/NumberFormatService';
import { message, Modal, Row, Spin } from 'antd';
import React from 'react';

const ALLOWED_FILE_TYPES: string[] = AppConfigService.getValue('api.upload.allowedImageTypes');
const ALLOWED_MAX_SIZE: number = AppConfigService.getValue('api.upload.maxFileSize');

// -- Prop types
// ----------

export interface IImageUploadFormOwnProps {
  defaultImageLink?: string;
  avatar?: boolean;

  onSubmit: (file: IFile) => void;
  onClose: () => void;
}

type IImageUploadFormProps = IImageUploadFormOwnProps & IWithLocalizeOwnProps & IWithPathResolverOwnProps;

interface IImageUploadFormState {
  newFile?: IFile;
}

// -- Component
// ----------

/** Component with a modal form for uploading new images and changing existing with preview */
class ImageUploadForm extends React.Component<IImageUploadFormProps, IImageUploadFormState> {
  state: IImageUploadFormState = {
    newFile: undefined,
  };

  checkFile = (file: IUploadedFile) => {
    // check file type
    const isValidType = ALLOWED_FILE_TYPES.some((pattern) => file.type.match(new RegExp(pattern)));
    if (!isValidType) {
      message.error(this.props.translate('FILE_UPLOAD.IMAGE.ERROR_FILE_TYPE'));
    }

    // check file size
    const isValidSize = file.size <= ALLOWED_MAX_SIZE;
    if (!isValidSize) {
      const maxFileSizeInMbs = NumberFormatService.formatBytes(ALLOWED_MAX_SIZE);
      message.error(this.props.translate('FILE_UPLOAD.ERROR_FILE_SIZE', { maxFileSize: maxFileSizeInMbs }));
    }

    return isValidType && isValidSize;
  };

  handleUploadFile = (data: IFile) => {
    this.setState({
      newFile: data,
    });
  };

  handleSubmit = () => {
    if (this.state.newFile != null) {
      this.props.onSubmit(this.state.newFile);
    }

    this.handleClose();
  };

  handleClose = () => {
    // remove newFile when modal is closing, so it doen't remeber uploaded image upon next modal open
    this.setState({
      newFile: undefined,
    });

    this.props.onClose();
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          maskClosable={false}
          title={<Row>{this.props.translate('FILE_UPLOAD.MODAL_TITLE')}&nbsp;<LemonInfoTooltip message={this.props.translate('FILE_UPLOAD.TOOLTIP_MESSAGE')}/></Row>}
          className="timun-fileUploader__dialog"
          visible={true}
          onOk={this.handleSubmit}
          onCancel={this.handleClose}
          okText={this.props.translate('COMMON.ACTION_SAVE')}
          cancelText={this.props.translate('COMMON.ACTION_CANCEL')}
          okButtonProps={{ disabled: !this.state.newFile }}
        >
          <div className="timun-fileUploader__previewPlaceholder">
            <FileUpload onUploadFile={this.handleUploadFile} dragger={true} uploadProps={{ multiple: false, beforeUpload: this.checkFile }}>
              {(status: IUploadStatus) => (
                <React.Fragment>
                  {status.isUploading && <Spin/>}&nbsp;
                  {this.props.avatar ? (
                    <AppUserAvatar className="lemon-image__avatar" imagePath={this.state.newFile ? this.props.resolveApiFilePath(this.state.newFile.id) : this.props.defaultImageLink}/>
                  ) : this.props.defaultImageLink || this.state.newFile ? (
                    <LemonImage className="timun-fileUploader__preview" imagePath={this.state.newFile ? this.props.resolveApiFilePath(this.state.newFile.id) : this.props.defaultImageLink}/>
                  ) : (
                    <PlusOutlined/>
                  )}
                </React.Fragment>
              )}
            </FileUpload>
          </div>

          <div className="timun-fileUploader__uploadLinkContainer">
            <FileUpload onUploadFile={this.handleUploadFile} uploadProps={{ multiple: false, beforeUpload: this.checkFile }}>
              {(status: IUploadStatus) => (
                <React.Fragment>
                  {status.isUploading && <Spin/>}&nbsp;
                  <a>
                    <PaperClipOutlined/>
                    {this.props.translate('FILE_UPLOAD.MODAL_UPLOAD_BUTTON')}
                  </a>
                </React.Fragment>
              )}
            </FileUpload>
          </div>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withLocalize<IImageUploadFormOwnProps>(withPathResolver(ImageUploadForm) as any);

