import React, { useCallback, useState } from 'react';

import { CrmEditorConfig } from '@src/components/common/editor/CrmEditorConfigs';
import { Editor } from '@src/components/common/editor/Editor';
import FormDataItemList from '@src/components/common/form/FormDataItemList';
import FormItemLabelIcon from '@src/components/common/form/FormItemLabelIcon';
import CurrencyInput from '@src/components/common/input/CurrencyInput';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import AddressShortInfoForm from '@src/components/externaleducationistance/form/AddressShortInfoForm';
import ExecutionMethodSelector from '@src/components/externaleducationistance/form/ExecutionMethodSelector';
import { ExecutionMethodEnum, IExternalEducationInstance } from '@src/model/externaleducationinstance/ExternalEducationInstance';
import AppConfigService from '@src/service/common/AppConfigService';
import { DateUtils } from '@src/service/util/date/DateUtils';
import { DatePicker, Form, FormInstance, Input } from 'antd';
import { Moment } from 'moment';
import moment from 'moment';

// -- Const
// ----------
const TEXT_AREA_MAX_LENGTH = AppConfigService.getValue('components.common.bigCharacterLengthInput');
const dateFormat = AppConfigService.getValue('dateTimeFormat.dateWithYear');
/** disabled all dates including "today" */
const disabledDate = (current: Moment): boolean => {
  return current != null ? current < moment().startOf('day').add(1, 'day') : false;
};

// -- Prop types
// ----------

export interface IExternalEducationInstanceFormOwnProps {
  value?: IExternalEducationInstance;
  onSubmit?: (data: IExternalEducationInstance) => void;
  formRef?: FormInstance<IExternalEducationInstance>;
  disabled?: boolean;
}
type IExternalEducationInstanceFormProps = IExternalEducationInstanceFormOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Display external education template form */
const ExternalEducationInstanceForm: React.FC<IExternalEducationInstanceFormProps> = (props: IExternalEducationInstanceFormProps) => {
  const [form] = Form.useForm<IExternalEducationInstance>(props.formRef);
  const initialValue = { ...props.value, startDateTime: moment(props.value?.startDateTime) };
  const [educationOnSite, setEducationOnSite] = useState<boolean>(false);

  const onExecutionMethodChange = useCallback((value: ExecutionMethodEnum) => {
    if (value === ExecutionMethodEnum.ONLINE) {
      setEducationOnSite(false);
    } else { setEducationOnSite(true); }
  }, []);

  const handleFinish = (values: IExternalEducationInstance) => {
    const data = { ...values, startDateTime: DateUtils.toBackendDateFormat(values.startDateTime) };
    props.onSubmit?.(data);
  };

  return (
    <Form<IExternalEducationInstance>
      initialValues={initialValue}
      layout="vertical"
      form={form}
      name="externalEducationTemplate"
      onFinish={handleFinish}>

      <Form.Item noStyle={true} hidden={true} name={'status'}>
        <Input />
      </Form.Item>

      <Form.Item noStyle={true} hidden={true} name={'externalEducationTemplate'}>
        <Input />
      </Form.Item>

      <Form.Item noStyle={true} hidden={true} name={['id']}>
        <Input />
      </Form.Item>

      <Form.Item label={props.translate('EXTERNAL_EDUCATION_INSTANCE.FORM.START_DATE_TIME_LABEL')}
        name="startDateTime"
        rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]} >
        <DatePicker allowClear={true} disabledDate={disabledDate} format={dateFormat} />
      </Form.Item>

      <Form.Item label={props.translate('EXTERNAL_EDUCATION_INSTANCE.FORM.EXECUTION_METHOD_LABEL')}
        name={['executionMethod', 'id']}
        rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]} >
        <ExecutionMethodSelector allowClear={true} onChange={onExecutionMethodChange} />
      </Form.Item>

      <Form.Item label={props.translate('EXTERNAL_EDUCATION_INSTANCE.FORM.ADDRESS_LABEL')} required={educationOnSite}>
        <FormDataItemList name={'addresses'} formRef={form} initialCount={1} min={educationOnSite ? 1 : 0}>
          <AddressShortInfoForm required={educationOnSite} />
        </FormDataItemList>
      </Form.Item>

      <Form.Item label={props.translate('EXTERNAL_EDUCATION_INSTANCE.FORM.PRICE_LABEL')}
        name="price"
        rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}>
        <CurrencyInput disabled={props.disabled} placeholder={props.translate('COMMON.LABEL.MAXIMUM')} />
      </Form.Item>

      <Form.Item label={props.translate('EXTERNAL_EDUCATION_INSTANCE.FORM.CONTRACTORS_LABEL')}
        name="contractors"
        rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
        { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
        { max: TEXT_AREA_MAX_LENGTH, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') }]}>
        <Input placeholder={props.translate('EXTERNAL_EDUCATION_INSTANCE.FORM.CONTRACTORS_PLACEHOLDER')} />
      </Form.Item>

      <Form.Item
        label={<FormItemLabelIcon label={props.translate('EXTERNAL_EDUCATION_INSTANCE.FORM.DESCRIPTION_LABEL')}
          tooltipDescription={props.translate('COMMON.LABEL.MAX_LENGTH_CHARACTER_INFO_LABEL', { maxCharNum: TEXT_AREA_MAX_LENGTH })} />}
        name="description"
        rules={[{ whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
        { max: TEXT_AREA_MAX_LENGTH, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') }]}>
        <Editor
          init={{
            ...CrmEditorConfig.defaultConfig(),
            height: 300,
            menubar: true,
          }} />
      </Form.Item>
    </Form >
  );
};

export default withLocalize<IExternalEducationInstanceFormOwnProps>(ExternalEducationInstanceForm as any);
