import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ViewSectionItem from '@src/components/common/section/ViewSectionItem';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import NoteCreateContainer from '@src/components/usernote/NoteCreateContainer';
import { ITimelineActivity } from '@src/model/activity/TimelineActivity';
import { NoteObjectTypeEnum } from '@src/model/user/Note';
import { TimelineUIHelper } from '@src/service/util/timeline/TimelineUiHelper';
import { Typography } from 'antd';
import React from 'react';

interface IExternalEducationApplicationActivitiesViewSectionOwnProps {
  id: string;
  timeline: ITimelineActivity[];
  canCreateNotes: boolean;
  onCreateNote?: () => void;
}
type IExternalEducationApplicationActivitiesViewSectionProps = IExternalEducationApplicationActivitiesViewSectionOwnProps & IWithLocalizeOwnProps;

class ExternalEducationApplicationActivitiesViewSection extends React.Component<IExternalEducationApplicationActivitiesViewSectionProps> {
  render = () => {
    return <ViewSectionItem
      header={<HeaderTitle title={this.props.translate('EXTERNAL_EDUCATION_APPLICATION.ACTIVITY.TITLE')} icon={<LemonIcon name="activities" size="large" />} />}
      collapsible={true}
      bordered={true}
    >
      {this.props.timeline && this.props.timeline.map((item: ITimelineActivity) => {
        return this.renderTimelineItem(item);
      })}
      {this.props.canCreateNotes && <NoteCreateContainer onCreateNote={this.props.onCreateNote} objectId={this.props.id} objectTypeId={NoteObjectTypeEnum.EDUCATION_APPLICATION} />}
    </ViewSectionItem>;
  };

  renderTimelineItem = (item: ITimelineActivity) => {
    return <div key={Math.random()} className="timun-educationApplicationTimeline__card">
      <Typography.Title level={4}>{TimelineUIHelper.getTimelineItemTitle(item)}</Typography.Title>
      {TimelineUIHelper.isTimelineItemNote(item) && <div dangerouslySetInnerHTML={{ __html: TimelineUIHelper.getTimelineItemNoteText(item) }} />}
      <span>{TimelineUIHelper.getTimelineItemDateTime(item)}</span>
    </div>;
  };
}

export default withLocalize<IExternalEducationApplicationActivitiesViewSectionOwnProps>(ExternalEducationApplicationActivitiesViewSection as any);
