import { ILemonEvent } from '@src/service/util/event/lemonEvent';
import { createTrackableObject, ITrackableObject } from '@src/service/util/tracking/tracking';

// callback event object type
export type ITrackableEvent<T> = ILemonEvent<T> & ITrackableObject<T>;

/** Enhance event object with event tracker. */
export function createTrackableEvent<T, P = ILemonEvent<any>>(event: ILemonEvent<T>, trackingParent?: P): ITrackableEvent<T> {
  return createTrackableObject<ILemonEvent<T>>(event, trackingParent);
}
