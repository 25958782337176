import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------

export interface IRouterNavigationPromptOwnProps {
  when?: boolean;
  message: string;
}
type IRouterNavigationPromptProps = IRouterNavigationPromptOwnProps & WithRouterProps;

// -- Component
// ----------

/** Prompt user before leaving route */
class RouterNavigationPrompt extends React.Component<IRouterNavigationPromptProps> {
  componentDidMount = () => {
    const currentRoute = this.props.routes[this.props.routes.length - 1];
    this.props.router.setRouteLeaveHook(currentRoute, () => {
      if (this.props.when) {
        return this.props.message;
      } else {
        return true
      }
    });
  }

  render = () => {
    return <React.Fragment>
      {this.props.children}
    </React.Fragment>
  }
}

// -- HOCs and exports
// ----------

export default withRouter(RouterNavigationPrompt);