import React from 'react';

import FormItemLabelIcon from '@src/components/common/form/FormItemLabelIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IEducationQualification } from '@src/model/educationqualification/EducationQualification';
import { IEducationQualificationCreatePayload } from '@src/service/business/educationqualification/EducationQualificationBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { Form, Input, Modal } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

// -- Const
// ----------
const TEXT_AREA_MAX_LENGTH = AppConfigService.getValue('components.common.bigCharacterLengthInput');
const INPUT_MAX_LENGTH = AppConfigService.getValue('components.common.mediumCharacterLengthInput');

// -- Prop types
// ----------
export interface IEducationQualificationFormOwnProps {
  educationQualification?: IEducationQualification;
  onEducationQualificationCreateSubmit?: (educationQualification: IEducationQualificationCreatePayload) => void;
  onEducationQualificationUpdateSubmit?: (educationQualification: IEducationQualification) => void;
  onCancel?: () => void;
}

type IEducationQualificationFormProps = IEducationQualificationFormOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Display form for create and update od education qualification */
const EducationQualificationForm: React.FC<IEducationQualificationFormProps> = (props: IEducationQualificationFormProps) => {
  const [form] = Form.useForm<IEducationQualification>();

  const handleFinish = (value: IEducationQualification) => {
    if (props.educationQualification?.id) {
      //  update
      props.onEducationQualificationUpdateSubmit?.(value);
    } else if (props.onEducationQualificationCreateSubmit) {
      // create
      props.onEducationQualificationCreateSubmit(value);
    }
  };

  return (
    <Modal
      title={props.translate('EDUCATION_QUALIFICATION_FORM.TITLE')}
      visible={true}
      onCancel={props.onCancel}
      onOk={form.submit}
      okText={props.translate('COMMON.ACTION_SAVE')}
      cancelText={props.translate('COMMON.ACTION_CANCEL')}>
      <Form<IEducationQualification>
        layout="vertical"
        form={form}
        initialValues={props.educationQualification}
        name="educationQualification"
        onFinish={handleFinish}>
        <Form.Item
          label={<FormItemLabelIcon label={props.translate('EDUCATION_QUALIFICATION_FORM.NAME_LABEL')}
            tooltipDescription={props.translate('COMMON.LABEL.MAX_LENGTH_CHARACTER_INFO_LABEL', { maxCharNum: INPUT_MAX_LENGTH })} />}
          name={'name'}
          rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
          { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
          { max: INPUT_MAX_LENGTH, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={<FormItemLabelIcon label={props.translate('EDUCATION_QUALIFICATION_FORM.DESCRIPTION_LABEL')}
            tooltipDescription={props.translate('COMMON.LABEL.MAX_LENGTH_CHARACTER_INFO_LABEL', { maxCharNum: TEXT_AREA_MAX_LENGTH })} />}
          name={'description'}
          rules={[{ whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
          { max: TEXT_AREA_MAX_LENGTH, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') }]}
        >
          <TextArea />
        </Form.Item>

        <Form.Item
          noStyle={true}
          hidden={true}
          name={'id'}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal >
  );
};

export default withLocalize<IEducationQualificationFormOwnProps>(EducationQualificationForm as any);
