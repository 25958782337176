export enum CommentObjectTypeEnum {
  COURSE = '1',
  LECTURE = '2',
  WEBINAR = '3',
}

export interface ICommentObjectType {
  id: CommentObjectTypeEnum;
  name: string;
}
