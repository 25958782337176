import { Collapse, Tooltip } from 'antd';
import React, { ReactNode } from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { classNames } from '@src/components/common/util/classNames';

const VIEW_SECTION_ITEM_CSS_BASE = 'timun-viewSectionItem__container';

// -- Prop types
// ----------

export interface IViewSectionItemOwnProps {
  collapsible?: boolean;
  active?: boolean;
  onChange?: (isActive: boolean) => void;
  icon?: (isActive: boolean) => ReactNode;
  header: ReactNode;
  actions?: ReactNode;
  bordered?: boolean;
  titleTooltip?: string;
  hideArrow?: boolean;
  footer?: string | ReactNode;
  className?: string;
}
type IViewSectionItemProps = IViewSectionItemOwnProps & IWithLocalizeOwnProps;

interface IViewSectionItemState {
  isActive: boolean;
}

const { Panel } = Collapse;

// -- Component
// ----------

class ViewSectionItem extends React.Component<IViewSectionItemProps, IViewSectionItemState> {
  state: IViewSectionItemState = {
    isActive: this.props.active ?? false,
  };

  renderActions = () => {
    if (this.props.actions) {
      return this.props.actions;
    }
    return;
  };

  renderHeader = () => {
    const header = <div className="timun-viewSectionItem__header">{this.props.header}</div>;
    if (this.props.titleTooltip) {
      return <Tooltip title={this.props.titleTooltip}>{header}</Tooltip>;
    }
    return header;
  };

  handleOnChange = (isActive: boolean) => {
    if (this.props.collapsible === undefined || this.props.collapsible === true) {
      this.setState({ isActive });
      if (this.props.onChange) {
        this.props.onChange(isActive);
      }
    }
  };

  render = () => {
    return (
      <React.Fragment>
        <Collapse className={this.getClassName()} activeKey={this.state.isActive === true ? '1' : ''} onChange={() => this.handleOnChange(!this.state.isActive)} bordered={this.props.bordered ?? false}>
          <Panel showArrow={(!this.props.hideArrow && this.props.collapsible) ?? false} extra={this.renderActions()} header={this.renderHeader()} key="1">
            <div className="timun-viewSectionItem__content">
              {this.props.children}
              {this.props.footer && (
                <React.Fragment>
                  <hr />
                  {this.props.footer}
                </React.Fragment>
              )}
            </div>
          </Panel>
        </Collapse>
      </React.Fragment>
    );
  };

  getClassName() {
    return classNames({
      [VIEW_SECTION_ITEM_CSS_BASE]: true,
      [this.props.className || '']: true,
    });
  }
}

export default withLocalize<IViewSectionItemOwnProps>(ViewSectionItem as any);
