import IIdRef from '@src/model/common/IdRef';
import { ICourseContent } from '@src/model/course/Content';
import { ILecture } from '@src/model/course/Lecture';
import { ITag } from '@src/model/tag/Tag';
import { IUserDetails } from '@src/model/user/UserDetails';
import { ICourseQuiz } from './CourseQuiz';

export interface ICourse {
  id: string;
  title: string;
  courseGroups: Array<IIdRef<string>>;
  content: ICourseContent;
  coverImageUrl: string;
  status: ICourseStatus;
  createdBy: IUserDetails | null;
  createdAt: string;
  quiz: ICourseQuiz;
  progress?: ICourseProgress;
  additionalData: IAdditionalData;
  tags?: ITag[];
  visibility: ICourseVisibility;
}

export interface ICourseVisibility {
  id: CourseVisibilityEnum;
  name: string;
  description: string;
}

export enum CourseVisibilityEnum {
  PUBLIC = '1',
  LIMITED = '2',
  PRIVATE = '3',
}

export interface ICourseStatus {
  id: CourseStatusEnum;
  description: string;
  name: string;
}

export enum CourseStatusEnum {
  IN_CREATION = '1',
  CREATION_FINISHED = '2',
  PUBLISHED = '3',
  ARCHIVED = '4',
}

export interface ICourseProgress {
  completionStatus: ICourseCompletionStatus;
  currentLecture: ILecture;
  completedLecturesNumber: number;
  lecturesNumber: number;
  assignedDateTime: string;
  finishDateTime: string;
  endDate: string;
  deadlineDate: string;
}

export interface ICourseCompletionStatus {
  id: CourseCompletionStatusEnum;
  name: string;
  description: string;
}

export enum CourseCompletionStatusEnum {
  TO_DO = '1',
  IN_PROGRESS = '2',
  COMPLETED = '3',
  PUBLISHED = '2',
}

export interface IAdditionalData {
  traineesStats?: ITraineesStats;
}

export interface ITraineesStats {
  toDoCount: number;
  inProgressCount: number;
  completedCount: number;
}
