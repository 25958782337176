import React from 'react';
import { connect } from 'react-redux';

import CoursesTreeModal from '@src/components/administration/common/CoursesTreeModal';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import IIdRef from '@src/model/common/IdRef';
import { CourseStatusEnum, ICourse } from '@src/model/course/Course';
import { ICourseGroup } from '@src/model/course/CourseGroup';
import CollectionBusinessStore from '@src/service/business/common/collectionBusinessStore';
import { ICollectionData } from '@src/service/business/common/types';
import courseListBusinessStore from '@src/service/business/courses/courseListBusinessStore';

// -- Prop types
// ----------

export interface ICoursesTreeModalContainerOwnProps {
  modalTitle: string;
  isPicker: boolean;
  assignedCourses?: ICourse[];
  onSubmit: (data: Array<IIdRef<string>>) => void;
  onCloseModal: () => void;
}

export interface ICoursesTreeModalContainerDispatchProps {
  fetchCourseList: () => void;
}

export interface ICoursesTreeModalContainerStateProps {
  courseList: ICollectionData<ICourse>;
  courseGroups: ICourseGroup[];
}
type ICoursesTreeModalContainerProps = ICoursesTreeModalContainerOwnProps & ICoursesTreeModalContainerDispatchProps & ICoursesTreeModalContainerStateProps & IWithLocalizeOwnProps;

// -- State types
// ----------

// -- Component
// ----------

/** Component for selecting courses, can also be used as a single course picker. OnSubmit returns an array of IIdRefs */
class CoursesTreeModalContainer extends React.Component<ICoursesTreeModalContainerProps> {
  componentDidMount = () => {
    this.props.fetchCourseList();
  };

  render = () => {
    return (
      <React.Fragment>
        {this.props.courseList && (
          <CoursesTreeModal
            isPicker={this.props.isPicker}
            courseGroups={this.props.courseGroups}
            allCoursesList={this.props.courseList ? this.props.courseList.content : []}
            assignedCourses={this.props.assignedCourses ? this.props.assignedCourses : []}
            modalTitle={this.props.modalTitle}
            onCloseModal={this.props.onCloseModal}
            onSubmit={this.handleSubmit}
          />
        )}
      </React.Fragment>
    );
  };

  handleSubmit = (data: string[]) => {
    this.props.onSubmit(
      data.map((item) => {
        return { id: item };
      })
    );
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ICoursesTreeModalContainerOwnProps): ICoursesTreeModalContainerStateProps => ({
  courseList: courseListBusinessStore.selectors.getCourseList(state),
  courseGroups: CollectionBusinessStore.selectors.getCollectionContent(state, 'CourseGroup'),
});

const mapDispatchToProps = (dispatch: any): ICoursesTreeModalContainerDispatchProps => ({
  fetchCourseList: () => dispatch(courseListBusinessStore.actions.fetchAdminCourseList({ status: CourseStatusEnum.PUBLISHED })),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<ICoursesTreeModalContainerOwnProps>(CoursesTreeModalContainer as any));
