import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

import { IUserInfo } from '@src/model/user/User';
import { UserStatusEnum } from '@src/model/user/UserStatus';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';

export interface IUserRegistrationRouteGuardPublicProps {}
export interface IUserRegistrationRouteGuardStateProps {
  isUserLoggedIn: boolean;
  currentUser: IUserInfo;
}
export interface IUserRegistrationRouteGuardDispatchProps {}
type IUserRegistrationRouteGuardProps = IUserRegistrationRouteGuardPublicProps & IUserRegistrationRouteGuardStateProps & IUserRegistrationRouteGuardDispatchProps & WithRouterProps;

interface IUserRegistrationRouteGuardState {
  registrationFinished: boolean;
}

const USER_REGISTRATION_PATH = '/user/registration';

/**
 * Guard checks if user is logged in and if registration is required. If registration is required it redirects to registration page, otherwise it simply renders it's children.
 */
class UserRegistrationRouteGuard extends React.Component<IUserRegistrationRouteGuardProps, IUserRegistrationRouteGuardState> {
  state: IUserRegistrationRouteGuardState = {
    registrationFinished: false,
  };

  componentDidMount() {
    this.check();
  }

  componentDidUpdate(previousProps: IUserRegistrationRouteGuardProps) {
    if (this.props !== previousProps) {
      this.check();
    }
  }

  render() {
    return <React.Fragment>{this.state.registrationFinished && this.props.children}</React.Fragment>;
  }

  /** Do neccessary checks. */
  private check() {
    const requiresRegistration = this.requiresRegistration();

    if (requiresRegistration && this.props.location.pathname !== USER_REGISTRATION_PATH) {
      this.setState({ registrationFinished: false });

      console.log('User not registered. Redirecting to registration page ...');
      this.props.router.push(USER_REGISTRATION_PATH);
    } else {
      this.setState({ registrationFinished: true });
    }
  }

  /** Check if user requires registration. If user is not logged in (eg. public pages) then registratoin is not required (no one to register) */
  private requiresRegistration(): boolean {
    // TODO: remove temporary condition for requiring user registration
    return this.props.isUserLoggedIn && this.props.currentUser?.userStatus?.id === UserStatusEnum.PARTIAL;
  }
}

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IUserRegistrationRouteGuardStateProps => ({
  isUserLoggedIn: LoginBusinessStore.selectors.isUserLoggedIn(state),
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IUserRegistrationRouteGuardDispatchProps => ({});

// store exported component so we can use it in withXXX HOC
const UserRegistrationRouteGuardWrapper = connect<IUserRegistrationRouteGuardStateProps, any, IUserRegistrationRouteGuardPublicProps>(mapStateToProps, mapDispatchToProps)(withRouter(UserRegistrationRouteGuard));
export default UserRegistrationRouteGuardWrapper;
