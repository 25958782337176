import React from 'react';
import { Link, withRouter, WithRouterProps } from 'react-router';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { Menu } from 'antd';

export enum MenuKeys {
  Login = '/login',
}

// -- Prop types
// ----------
export interface ILoginMenuPublicProps { }
type ILoginMenuProps = ILoginMenuPublicProps & IWithLocalizeOwnProps & WithRouterProps;

// --
// ----- Component
const LoginMenu: React.FC<ILoginMenuProps> = (props) => {
  return (
    <React.Fragment>
      <Menu mode="horizontal" selectedKeys={getSelectedItems(props)} className="lemon-appLayout__headerMenu">
        <Menu.Item key={MenuKeys.Login} data-test-id="lemon-loginMenu__loginMenu">
          <Link data-test-id="lemon-loginMenu__loginLink" to="/login">
            {props.translate('LOGIN_MENU.LOGIN_LABEL')}
          </Link>
        </Menu.Item>
      </Menu>
    </React.Fragment>
  );
};

const getSelectedItems = (props: ILoginMenuProps): string[] => {
  return Object.values(MenuKeys)
    .filter((key) => props.location.pathname.startsWith(key))
    .slice(0, 1);
};

export default withLocalize<ILoginMenuPublicProps>(withRouter(LoginMenu as any));
