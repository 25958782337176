import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

import DeleteButton from '@src/components/common/button/DeleteButton';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { classNames } from '@src/components/common/util/classNames';

type VerticalPosition = 'top' | 'middle' | 'bottom';
type HorizontalPosition = 'left' | 'center' | 'right';
interface IPosition {
  h?: HorizontalPosition;
  v?: VerticalPosition;
}

// -- Prop types
// ----------

// -- Public component props
export interface IEditableWrapperOwnProps {
  /** Show remove button. */
  showRemove?: boolean;
  /** Show overlay mask. */
  showMask?: boolean;
  /** Where to position buttons. Vertically: top, bottom, middle. Horizontally: left, right, center. */
  position?: IPosition;
  /** Should buttons be rendered. Default is true. */
  disabled?: boolean;

  className?: string;

  onEdit?: () => void;
  onRemove?: () => void;
}
// -- Complete component props
type IEditableWrapperProps = React.PropsWithChildren<IEditableWrapperOwnProps & IWithLocalizeOwnProps>;

// -- Component
// ----------

/** Wrapper around element that on hover displays edit/remove buttons over wrapped element. Can be used to add editing butotns to eg. images, videos, ... */
const EditableWrapper: React.FC<IEditableWrapperProps> = (props) => {
  return (
    <div className={`timun-editableWrapper__container ${props.className ?? ''}`}>
      {props.children}

      {(props.showMask ?? true) && <div className="timun-editableWrapper__overlay" />}

      {!(props.disabled ?? false) && (
        <div className={getButtonsCssClass(props)}>
          <Button icon={<EditOutlined />} shape="circle" size="large" className="timun-editableWrapper__editButton" title={props.translate('COMMON.ACTION_EDIT')} onClick={() => props.onEdit?.()} data-test-id="timun-editableWrapper__editButton" />

          {(props.showRemove ?? true) && (
            <React.Fragment>
              &nbsp;&nbsp;
              <DeleteButton buttonProps={{ shape: 'circle', size: 'large' }} className="timun-editableWrapper__cancelButton" onConfirm={() => props.onRemove?.()} />
            </React.Fragment>
          )}
        </div>
      )}
    </div>
  );
};

const getVerticalPosition = (props: IEditableWrapperProps): VerticalPosition => {
  return props.position?.v ?? 'middle';
};

const getHorizontalPosition = (props: IEditableWrapperProps): HorizontalPosition => {
  return props.position?.h ?? 'center';
};

const getButtonsCssClass = (props: IEditableWrapperProps): string => {
  return classNames({
    'timun-editableWrapper__buttonContainer': true,
    [`timun-editableWrapper__buttonContainer--${getHorizontalPosition(props)}`]: true,
    [`timun-editableWrapper__buttonContainer--${getVerticalPosition(props)}`]: true,
  });
};

export default withLocalize<IEditableWrapperOwnProps>(EditableWrapper as any);
