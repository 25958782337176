import { IReminderType } from './../reminder/Reminder';
import { ICommentObjectType } from '@src/model/comment/CommentObjectType';
import { IUserDetails } from '@src/model/user/UserDetails';
import { IVoteObject, IVoteObjectType, IVoteType } from '@src/model/comment/VoteObjectType';

/** Base user activity interface. Use `type` property to distinguish them apart and cast appropriate `content`. */
export interface IBaseUserActivity<C extends IUserActivityContent, T> {
  id: string;
  createdAt: string;
  createdBy: IUserDetails;
  receiver?: IUserDetails;
  type: T;
  content: C;
}

export type IUserActivity = ILeftCommentUserActivity | ICompletedCourseUserActivity;

/** Union type for user activity content. */
export type IUserActivityContent = ILeftCommentUserActivityContent | ICompletedCourseUserActivityContent | ICreatedReminderUserActivityContent | IVoteUserActivityContent | null;

// ----- LeftComment activity
export interface ILeftCommentUserActivity extends IBaseUserActivity<ILeftCommentUserActivityContent, IUserActivityItemType> {}

export interface ILeftCommentUserActivityContent {
  commentContent: string;
  objectType: ICommentObjectType;
  object: ILeftCommentContentObject;
}

// ----- CompletedCourse activity
export interface ICompletedCourseUserActivity extends IBaseUserActivity<ICompletedCourseUserActivityContent, IUserActivityItemType> {}

export interface ICompletedCourseUserActivityContent {
  courseTitle?: string;
  courseId?: string;
}

// ----- CreatedReminder activity
export interface ICreatedReminderUserActivity extends IBaseUserActivity<ICreatedReminderUserActivityContent, IUserActivityItemType> {}

export interface ICreatedReminderUserActivityContent {
  reminderType: IReminderType;
}

// ----- Vote activity
export interface IVoteUserActivity extends IBaseUserActivity<IVoteUserActivityContent, IUserActivityItemType> {}

export interface IVoteUserActivityContent {
  voteType?: IVoteType;
  objectType: IVoteObjectType;
  object: IVoteObject;
}

// ----- User activity types

export enum UserActivityItemTypeEnum {
  LEFT_COMMENT = '1',
  COMPLETED_COURSE = '2',
  CREATED_REMINDER = '3',
  ADDED_VOTE = '4',
  CHANGED_VOTE = '5',
  REMOVED_VOTE = '6',
}

export interface IUserActivityItemType {
  id: UserActivityItemTypeEnum;
  name: string;
  description: string;
}

export const userActivityTypesEnum: IActivityTypesType = { ...UserActivityItemTypeEnum };

interface IActivityTypesType {
  // by default TS considers enum key type to be number
  [key: string]: string;
}

interface ILeftCommentContentObject {
  id: string;
  name: string;
}
