import React, { SyntheticEvent } from 'react';

import { Form } from '@ant-design/compatible';
import { CrmEditorConfig } from '@src/components/common/editor/CrmEditorConfigs';
import { Editor } from '@src/components/common/editor/Editor';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';

import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { ISurveyTemplate } from '@src/model/survey/SurveyTemplate';
import { Modal, Typography } from 'antd';

// -- Consts
// ----------
const FormItem = Form.Item;

// -- Prop types
// ----------
export interface ISurveyTemplateInstructionFormOwnProps {
  surveyTemplate: ISurveyTemplate;
  onSubmit: (surveyTemplate: ISurveyTemplate) => void;
  onCancel: () => void;
}

export interface ISurveyTemplateInstructionFormStateProps { }
export interface ISurveyTemplateInstructionFormDispatchProps { }

type ISurveyTemplateInstructionFormProps = ISurveyTemplateInstructionFormOwnProps & ISurveyTemplateInstructionFormStateProps & ISurveyTemplateInstructionFormDispatchProps & IWithLocalizeOwnProps & FormComponentProps;

interface ISurveyTemplateInstructionFormState { }

/** Form control for survey template instruction input */
class SurveyTemplateInstructionForm extends React.Component<ISurveyTemplateInstructionFormProps, ISurveyTemplateInstructionFormState> {

  render() {
    const { getFieldDecorator } = this.props.form;
    const surveyTemplateInstruction = this.props.surveyTemplate.instructions;

    return (
      <Modal
        maskClosable={false}
        title={this.props.translate('COMMON.LABEL.EDIT')}
        visible={true}
        className="lemon-modal__modal--md"
        onOk={this.handleSubmit}
        onCancel={this.props.onCancel}
        okText={this.props.translate('COMMON.ACTION_SAVE')}
        cancelText={this.props.translate('COMMON.ACTION_CANCEL')}
      >
        <Form>
          {/* Instruction text area */}
          <FormItem>
            <Typography.Title level={4}>{this.props.translate('EXAM_TEMPLATE.MODAL_INSTRUCTION_TITLE')}</Typography.Title>

            {getFieldDecorator('surveyTemplateInstruction', {
              initialValue: surveyTemplateInstruction,
            })(
              <Editor
                init={{
                  ...CrmEditorConfig.defaultConfig(),
                  height: 500,
                  menubar: true,
                }}
              />
            )}
          </FormItem>
        </Form>
      </Modal>
    );
  }

  handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        const surveyTemplateData: ISurveyTemplate = {
          ...this.props.surveyTemplate,
          instructions: values.surveyTemplateInstruction,
        };
        this.props.onSubmit(surveyTemplateData);
      }
    });
  };
}

const SurveyTemplateInstructionModal = Form.create()(SurveyTemplateInstructionForm);

export default withLocalize<ISurveyTemplateInstructionFormOwnProps>(SurveyTemplateInstructionModal as any);
