import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import withTenantPropEnabled, { IWithTenantPropEnabledOwnProps } from '@src/components/tenant/withTenantPropEnabled';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { Tabs } from 'antd';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

// -- Types&Interfaces
// ----------

export enum AdministrationNavigationMenuKeys {
  Users = '/administration/users',
  Workposition = '/administration/workposition',
  ExamResults = '/administration/grades',
  Notifications = '/administration/notifications',
  Comments = '/administration/comments',
  Tenant = '/administration/tenant',
  TenantOrganization = '/administration/tenantorganization',
  EmailTemplates = '/administration/emailtemplates',
  UserGroups = '/administration/usergroups',
  SkillGroups = '/administration/skillgroups',
  SurveyTemplates = '/administration/surveytemplates',
  Codebooks = '/administration/codebooks',
  Organizations = '/administration/organizations',
  Recommendations = '/administration/recommendations',
}

// -- Prop types
// ----------
export interface IAdministrationNavigationPublicProps {
}

type IAdministrationNavigationProps = IAdministrationNavigationPublicProps & IWithLocalizeOwnProps & WithRouterProps & IWithRolesOwnProps & IWithTenantPropEnabledOwnProps;

interface IAdministrationNavigationState {
  selectedKey: string;
}

// --
// ----- Component
class AdministrationNavigation extends React.Component<IAdministrationNavigationProps, IAdministrationNavigationState> {
  state: IAdministrationNavigationState = {
    selectedKey: this.props.location.pathname,
  };

  componentDidUpdate(prevProp: IAdministrationNavigationProps) {
    if (this.props.location !== prevProp.location) {
      this.changeSelectedKey();
    }
  }

  render() {
    return (
      <div>
        <Tabs onChange={this.handleChange} activeKey={this.state.selectedKey}>
          {/* <Tabs.TabPane tab={<Link to={AdministrationNavigationMenuKeys.Users}>{this.props.translate('ADMINISTRATION_NAVIGATION.USERS_MENU_LABEL')}</Link>} key={AdministrationNavigationMenuKeys.Users} /> */}
          <Tabs.TabPane tab={this.props.translate('ADMINISTRATION_NAVIGATION.USERS_MANAGEMENT_MENU_LABEL')} key={AdministrationNavigationMenuKeys.Users}/>
          {this.props.isTenantPropEnabled('admin_workpositions') && <Tabs.TabPane tab={this.props.translate('ADMINISTRATION_NAVIGATION.WORKPOSITION_MENU_LABEL')} key={AdministrationNavigationMenuKeys.Workposition}/>}
          {this.props.isTenantPropEnabled('admin_comments') && <Tabs.TabPane tab={this.props.translate('ADMINISTRATION_NAVIGATION.APPROVAL_COMMENT_MENU_LABEL')} key={AdministrationNavigationMenuKeys.Comments}/>}
          {/* NOTE: disabled until tenant config is implemented */}
          {/* <Tabs.TabPane tab={<Link to={AdministrationNavigationMenuKeys.ExamResults}>{this.props.translate('ADMINISTRATION_NAVIGATION.RESULTS_MENU_LABEL')}</Link>} key={AdministrationNavigationMenuKeys.ExamResults} /> */}
          {this.props.isTenantPropEnabled('admin_notifications') && <Tabs.TabPane tab={this.props.translate('ADMINISTRATION_NAVIGATION.NOTIFICATIONS_MENU_LABEL')} key={AdministrationNavigationMenuKeys.Notifications}/>}
          {this.props.isTenantPropEnabled('admin_emails') && <Tabs.TabPane tab={this.props.translate('ADMINISTRATION_NAVIGATION.EMAIL_TEMPLATES_MENU_LABEL')} key={AdministrationNavigationMenuKeys.EmailTemplates}/>}
          {this.props.isTenantPropEnabled('admin_skills') && <Tabs.TabPane tab={this.props.translate('ADMINISTRATION_NAVIGATION.SKILL_GROUPS_MENU_LABEL')} key={AdministrationNavigationMenuKeys.SkillGroups}/>}
          {this.props.isTenantPropEnabled('usergroups') && <Tabs.TabPane tab={this.props.translate('ADMINISTRATION_NAVIGATION.USER_GROUPS_MENU_LABEL')} key={AdministrationNavigationMenuKeys.UserGroups}/>}
          <Tabs.TabPane tab={this.props.translate('ADMINISTRATION_NAVIGATION.ORGANIZATIONS_MENU_LABEL')} key={AdministrationNavigationMenuKeys.Organizations}/>
          {this.props.isTenantPropEnabled('admin_surveys') && this.props.allowedRoles([UserRoleEnum.SUPERADMIN]) && <Tabs.TabPane key={AdministrationNavigationMenuKeys.SurveyTemplates} tab={this.props.translate('ADMINISTRATION_NAVIGATION.SURVEY_TEMPLATE_MENU_LABEL')}/>}
          {this.props.isTenantPropEnabled('admin_codebooks') && this.props.allowedRoles([UserRoleEnum.SUPERADMIN]) && <Tabs.TabPane key={AdministrationNavigationMenuKeys.Codebooks} tab={this.props.translate('ADMINISTRATION_NAVIGATION.CODEBOOKS_MENU_LABEL')}/>}
          {this.props.allowedRoles([UserRoleEnum.SUPERADMIN]) && <Tabs.TabPane key={AdministrationNavigationMenuKeys.Tenant} tab={this.props.translate('ADMINISTRATION_NAVIGATION.TENANT_MENU_LABEL')}/>}
          {this.props.allowedRoles([UserRoleEnum.SUPERADMIN, UserRoleEnum.ORGANIZATION_ADMIN]) && <Tabs.TabPane key={AdministrationNavigationMenuKeys.TenantOrganization} tab={this.props.translate('ADMINISTRATION_NAVIGATION.TENANT_ORGANIZATION_MENU_LABEL')}/>}
          {this.props.isTenantPropEnabled('admin_skills') && <Tabs.TabPane key={AdministrationNavigationMenuKeys.Recommendations} tab={this.props.translate('ADMINISTRATION_NAVIGATION.SKILLS_GAP_MENU_LABEL')}/>}
        </Tabs>
      </div>
    );
  }

  handleChange = (key: string) => {
    this.props.router.push(key);
  };

  changeSelectedKey = () => {
    const selectedKey = this.props.location.pathname;
    if (selectedKey) {
      this.setState({ selectedKey });
    }
  };
}

export default withLocalize<IAdministrationNavigationPublicProps>(withRoles(withTenantPropEnabled(withRouter(AdministrationNavigation as any))));
