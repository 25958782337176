import { Form } from '@ant-design/compatible';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';

import { FormComponentProps } from '@ant-design/compatible/es/form';
import { Input, Modal } from 'antd';
import React, { SyntheticEvent } from 'react';

const FormItem = Form.Item;

// -- Prop types
// ----------
interface IUserGroupAccessFormOwnProps {
  onCancel?: () => void;
  onSubmit?: (accessCode: string) => void;
  title: string;
}

type IUserGroupAccessFormProps = IUserGroupAccessFormOwnProps & IWithLocalizeOwnProps & FormComponentProps;

// -- Component
// ----------
class UserGroupAccessForm extends React.Component<IUserGroupAccessFormProps, any> {
  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <Modal
        title={this.props.title}
        visible={true}
        onCancel={this.props.onCancel}
        onOk={this.handleSubmit}
        okButtonProps={{ disabled: !this.props.form.isFieldsTouched() }}
        okText={this.props.translate('COMMON.ACTION_SAVE')}
        cancelText={this.props.translate('COMMON.ACTION_CANCEL')}>
        <Form>
          <FormItem label={this.props.translate('ACCESS_CODE_FORM.ACCESS_CODE_LABEL')}>
            {getFieldDecorator('accessCode', {
            })(<Input />)}
          </FormItem>
        </Form>
      </Modal>
    );
  }

  handleSubmit = (event: SyntheticEvent) => {
    event.preventDefault();
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        this.props.onSubmit?.(values.accessCode);
      }
    });
  };

}

// -- HOCs and exports
// ----------

const UserGroupAccessFormWrapper = Form.create()(UserGroupAccessForm);

export default withLocalize<IUserGroupAccessFormOwnProps>(UserGroupAccessFormWrapper as any);
