import { ConfigManagerValueAlreadyExistsException } from '@src/service/util/config/ConfigManagerValueAlreadyExistsException';
import { ConfigManagerValueNotFoundException } from '@src/service/util/config/ConfigManagerValueNotFoundException';
import { ConfigUtils } from '@src/service/util/config/ConfigUtils';
import { ConfigValueContainer } from '@src/service/util/config/ConfigValueContainer';

/**
 * Config manager provides easy way to extract values at arbitrary path in config object.
 */
export default class ConfigManager<T> {
  // tslint:disable-next-line: variable-name
  constructor(private _name: string, private _config: ConfigValueContainer<T>) {}

  name() {
    return `Config manager [${this._name}]`;
  }

  toString() {
    return this.name();
  }

  /** Return value found under selector path. Path is a string of property names concatenated with ".", eg. "prop1.prop2.prop3". */
  getValue(selector: string, required: boolean = false): any {
    const entry = this.reduceStateValue(selector);

    if (entry === undefined && required) {
      throw new ConfigManagerValueNotFoundException(selector, this.name());
    }

    return entry;
  }

  /**
   * Set value on object under selector path. Path is a string of property names concatenated with ".", eg. "prop1.prop2.prop3".
   * Last property name is used as a target property for given value.
   */
  setValue(selector: string, name: string, value: any, overwrite: boolean = true): void {
    const part = selector === '' ? this._config.value() : this.getValue(selector, true);

    if (part[name] !== undefined && !overwrite) {
      // is warning enough?
      throw new ConfigManagerValueAlreadyExistsException(selector, this.name());
    }

    part[name] = value;
  }

  private reduceStateValue(selector: string) {
    return ConfigUtils.reducePath(this._config.value(), selector);
  }
}
