import { useCallback, useState } from 'react';

/** Callback ref hook. As opposed to useRef hook, this one executes not only on mount/unmount but also when ref changes value. */
export default function useCallbackRef<T>(): [T | null, (node: T) => void] {
  const [ref, setRef] = useState<T | null>(null);
  const refCallback = useCallback((node: T) => {
    if (node !== null) {
      setRef(node);
    }
  }, []);
  return [ref, refCallback];
}
