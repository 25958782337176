import memoizeOne from 'memoize-one';
import React from 'react';
import { connect } from 'react-redux';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import PrivateCourseListContainer from '@src/components/course/list/catalog/PrivateCourseListContainer';
import { IUserInfo } from '@src/model/user/User';
import { ICourseListFilter } from '@src/service/business/courses/courseListBusinessStore';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';

// -- Prop types
// ----------

export interface ITraineeCourseListContainerOwnProps { }
export interface ITraineeCourseListContainerStateProps {
  currentUser: IUserInfo;
}
export interface ITraineeCourseListContainerDispatchProps { }
type ITraineeCourseListContainerProps = ITraineeCourseListContainerOwnProps & ITraineeCourseListContainerStateProps & ITraineeCourseListContainerDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Container component for list of all courses. */
class TraineeCourseListContainer extends React.Component<ITraineeCourseListContainerProps> {
  private createPredefinedListFilter = memoizeOne(
    (creatorUser: IUserInfo): ICourseListFilter => {
      return { assignee: creatorUser.id };
    }
  );

  render = () => {
    const predefinedListFilter = this.createPredefinedListFilter(this.props.currentUser);
    return <PrivateCourseListContainer predefinedListFilter={predefinedListFilter} />;
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): ITraineeCourseListContainerStateProps => ({
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): ITraineeCourseListContainerDispatchProps => ({});

export default connect<ITraineeCourseListContainerStateProps, ITraineeCourseListContainerDispatchProps, ITraineeCourseListContainerOwnProps>(mapStateToProps, mapDispatchToProps)(withLocalize(TraineeCourseListContainer as any));
