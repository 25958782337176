export interface ITagItemType {
  id: TagItemTypeEnum;
  name: string;
}

export enum TagItemTypeEnum {
  COURSE = '1',
  WEBINAR = '2',
  USER = '3',
  EXAM_TEMPLATE = '4',
  EXAM_INSTANCE = '5',
  FILE = '6',
  FOLDER = '7',
}
