import { Form } from '@ant-design/compatible';
import { FormComponentProps } from '@ant-design/compatible/lib/form';
import { Button, Row, Select, Switch } from 'antd';
import Col, { ColProps } from 'antd/lib/col';
import React from 'react';

import { hasFormErrors, validateRequired } from '@src/components/common/form/validation';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import withPathResolver, { IWithPathResolverOwnProps } from '@src/components/common/path/withPathResolver';
import { availableStyleThemes } from '@src/components/common/theme/styleThemePathResolver';
import ITenantConfiguration from '@src/model/tenant/TenantConfiguration';
import { ITenantConfigurationUpdatePayload } from '@src/service/business/tenant/tenantConfigurationBusinessService';
import AppConfigService from '@src/service/common/AppConfigService';

// -- Const
// ----------
const GRID_LAYOUT_LABEL_LAYOUT: ColProps = { xs: 24, sm: 10, md: 8 };
const GRID_LAYOUT_WRAPPER_LAYOUT: ColProps = { xs: 24, sm: 12, md: 12 };
const Option = Select.Option;
const surveyQuestionList: string[] = AppConfigService.getValue('quizzler.questionTypes.surveyQuestionList');
const examQuestionList: string[] = AppConfigService.getValue('quizzler.questionTypes.examQuestionList');

// -- Prop types
// ----------

export interface ITenantConfigurationEditOwnProps {
  configuration: ITenantConfiguration;

  onCancel?: () => void;
  onSubmit?: (data: ITenantConfigurationUpdatePayload) => void;
}
type ITenantConfigurationEditProps = ITenantConfigurationEditOwnProps & IWithLocalizeOwnProps & FormComponentProps & IWithPathResolverOwnProps;

interface ITenantConfigurationEditState {
}

// -- Component
// ----------

/** Edit tenant configuration items. */
class TenantConfigurationEdit extends React.Component<ITenantConfigurationEditProps, ITenantConfigurationEditState> {
  state: ITenantConfigurationEditState = {};

  render = () => {
    const form = this.props.form;
    const { getFieldDecorator } = form;
    const themeList = availableStyleThemes().sort();

    return (
      <Form onSubmit={this.handleSubmit}>
        {/* --- styleTheme --- */}
        <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={this.props.translate('TENANT_CONFIGURATION_VIEW.STYLE_THEME_LABEL')}>
          {getFieldDecorator('styleTheme', {
            initialValue: this.props.configuration.configuration?.styleTheme,
            rules: [{ required: true, validator: validateRequired }],
          })(
            <Select>
              {themeList.map((themeName) => (
                <Select.Option key={themeName} value={themeName}>
                  {themeName}
                </Select.Option>
              ))}
            </Select>
          )}
        </Form.Item>

        {/* --- enable dashboard --- */}
        <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={this.props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_DASHBOARD_LABEL')}>
          {getFieldDecorator('enableDashboard', {
            valuePropName: 'checked',
            initialValue: this.props.configuration.configuration.enableDashboard,
          })(<Switch />)}
        </Form.Item>

        {/* --- enable multi locales --- */}
        <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={this.props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_LOCALES_LABEL')}>
          {getFieldDecorator('enableLanguages', {
            valuePropName: 'checked',
            initialValue: this.props.configuration.configuration.enableLanguages,
          })(<Switch />)}
        </Form.Item>

        {/* --- enable courses --- */}
        <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={this.props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_COURSES_LABEL')}>
          {getFieldDecorator('enableCourses', {
            valuePropName: 'checked',
            initialValue: this.props.configuration.configuration.enableCourses,
          })(<Switch />)}
        </Form.Item>

        {/* --- enable webinars --- */}
        <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={this.props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_WEBINARS_LABEL')}>
          {getFieldDecorator('enableWebinars', {
            valuePropName: 'checked',
            initialValue: this.props.configuration.configuration.enableWebinars,
          })(<Switch />)}
        </Form.Item>

        {/* --- enable external contents --- */}
        <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={this.props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_EXTERNAL_CONTENTS_LABEL')}>
          {getFieldDecorator('enableExternalContents', {
            valuePropName: 'checked',
            initialValue: this.props.configuration.configuration.enableExternalContents,
          })(<Switch />)}
        </Form.Item>

        {/* --- enable repository --- */}
        <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={this.props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_REPOSITORY_LABEL')}>
          {getFieldDecorator('enableRepository', {
            valuePropName: 'checked',
            initialValue: this.props.configuration.configuration.enableRepository,
          })(<Switch />)}
        </Form.Item>

        {/* --- enable user groups --- */}
        <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={this.props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_USER_GROUPS_LABEL')}>
          {getFieldDecorator('enableUserGroups', {
            valuePropName: 'checked',
            initialValue: this.props.configuration.configuration.enableUserGroups,
          })(<Switch />)}
        </Form.Item>

        {/* --- enable organization user groups --- */}
        <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={this.props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_USER_GROUP_COURSES_LABEL')}>
          {getFieldDecorator('enableUserGroupCourses', {
            valuePropName: 'checked',
            initialValue: this.props.configuration.configuration.enableUserGroupCourses,
          })(<Switch />)}
        </Form.Item>


        {/* --- enable external education applications --- */}
        <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={this.props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_EXTERNAL_EDUCATION_APPLICATIONS_LABEL')}>
          {getFieldDecorator('enableExternalEducationApplications', {
            valuePropName: 'checked',
            initialValue: this.props.configuration.configuration.enableExternalEducationApplications,
          })(<Switch />)}
        </Form.Item>

        {/* --- enable external educations --- */}
        <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={this.props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_EXTERNAL_EDUCATIONS_LABEL')}>
          {getFieldDecorator('enableExternalEducations', {
            valuePropName: 'checked',
            initialValue: this.props.configuration.configuration.enableExternalEducations,
          })(<Switch />)}
        </Form.Item>

        {/* --- Video integration --- */}
        <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={this.props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_INTEGRATION_VIDEO')}>
          {getFieldDecorator('enableIntegrationVideo', {
            valuePropName: 'checked',
            initialValue: this.props.configuration.configuration.enableIntegrationVideo,
          })(<Switch />)}
        </Form.Item>

        {/* --- Administration links --- */}
        {/* --- Workpositions tab --- */}
        <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={this.props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_WORKPOSITIONS')}>
          {getFieldDecorator('enableWorkpositions', {
            valuePropName: 'checked',
            initialValue: this.props.configuration.configuration.enableWorkpositions,
          })(<Switch />)}
        </Form.Item>

        {/* --- Activities tab --- */}
        <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={this.props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_ACTIVITIES')}>
          {getFieldDecorator('enableActivities', {
            valuePropName: 'checked',
            initialValue: this.props.configuration.configuration.enableActivities,
          })(<Switch />)}
        </Form.Item>

        {/* --- Notifications tab --- */}
        <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={this.props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_NOTIFICATIONS')}>
          {getFieldDecorator('enableNotifications', {
            valuePropName: 'checked',
            initialValue: this.props.configuration.configuration.enableNotifications,
          })(<Switch />)}
        </Form.Item>

        {/* --- Email templates tab --- */}
        <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={this.props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_EMAILS')}>
          {getFieldDecorator('enableEmails', {
            valuePropName: 'checked',
            initialValue: this.props.configuration.configuration.enableEmails,
          })(<Switch />)}
        </Form.Item>

        {/* --- Skills tab --- */}
        <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={this.props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_SKILLS')}>
          {getFieldDecorator('enableSkills', {
            valuePropName: 'checked',
            initialValue: this.props.configuration.configuration.enableSkills,
          })(<Switch />)}
        </Form.Item>

        {/* --- Skill levels tab --- */}
        <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={this.props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_SKILL_LEVELS')}>
          {getFieldDecorator('enableSkillLevelClassification', {
            valuePropName: 'checked',
            initialValue: this.props.configuration.configuration.enableSkillLevelClassification,
          })(<Switch />)}
        </Form.Item>

        {/* --- Surveys tab --- */}
        <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={this.props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_SURVEYS')}>
          {getFieldDecorator('enableSurveys', {
            valuePropName: 'checked',
            initialValue: this.props.configuration.configuration.enableSurveys,
          })(<Switch />)}
        </Form.Item>

        {/* --- Comments tab --- */}
        <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={this.props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_COMMENTS')}>
          {getFieldDecorator('enableComments', {
            valuePropName: 'checked',
            initialValue: this.props.configuration.configuration.enableComments,
          })(<Switch />)}
        </Form.Item>

        {/* --- Codebooks tab --- */}
        <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={this.props.translate('TENANT_CONFIGURATION_VIEW.ENABLE_ADMIN_CODEBOOKS')}>
          {getFieldDecorator('enableCodebooks', {
            valuePropName: 'checked',
            initialValue: this.props.configuration.configuration.enableCodebooks,
          })(<Switch />)}
        </Form.Item>

        {/* --- Exam question list --- */}
        <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={this.props.translate('TENANT_CONFIGURATION_VIEW.EXAM_QUESTION_LIST')}>
          {getFieldDecorator('examQuestionList', {
            initialValue: this.props.configuration.configuration.examQuestionList,
          })(<Select mode="multiple" allowClear={true} showArrow={true}>
            {examQuestionList.map((questionType) => <Option key={questionType} value={questionType}>  {this.props.translate(`QUESTION_TYPE.${questionType}`)} </Option>)}
          </Select>)}
        </Form.Item>

        {/* --- Suvey question list --- */}
        <Form.Item labelCol={GRID_LAYOUT_LABEL_LAYOUT} wrapperCol={GRID_LAYOUT_WRAPPER_LAYOUT} label={this.props.translate('TENANT_CONFIGURATION_VIEW.SURVEY_QUESTION_LIST')}>
          {getFieldDecorator('surveyQuestionList', {
            initialValue: this.props.configuration.configuration.surveyQuestionList,
          })(<Select mode="multiple" allowClear={true} showArrow={true}>
            {surveyQuestionList.map((questionType) => <Option key={questionType} value={questionType}>   {this.props.translate(`QUESTION_TYPE.${questionType}`)} </Option>)}
          </Select>)}
        </Form.Item>

        {/* --- form buttons --- */}
        <Form.Item>
          <Row justify="space-around">
            <Col className="text-center">
              <Button onClick={this.handleCancel}>{this.props.translate('COMMON.ACTION_CANCEL')}</Button>
              &nbsp;
              <Button htmlType="submit" type="primary" disabled={this.hasFormErrors() || !form.isFieldsTouched()}>
                {this.props.translate('COMMON.ACTION_SAVE')}
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    );
  };

  handleSubmit: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err: any, values: any) => {
      if (!err) {
        const tenantConfiguration: ITenantConfigurationUpdatePayload = {
          id: this.props.configuration.id,
          configuration: {
            styleTheme: values.styleTheme,
            enableLanguages: values.enableLanguages,
            enableCourses: values.enableCourses,
            enableDashboard: values.enableDashboard,
            enableWebinars: values.enableWebinars,
            enableExternalContents: values.enableExternalContents,
            enableRepository: values.enableRepository,
            enableUserGroups: values.enableUserGroups,
            enableUserGroupCourses: values.enableUserGroupCourses,
            enableExternalEducationApplications: values.enableExternalEducationApplications,
            enableExternalEducations: values.enableExternalEducations,
            enableIntegrationVideo: values.enableIntegrationVideo,
            // administration links
            enableActivities: values.enableActivities,
            enableSurveys: values.enableSurveys,
            enableComments: values.enableComments,
            enableEmails: values.enableEmails,
            enableNotifications: values.enableNotifications,
            enableSkills: values.enableSkills,
            enableSkillLevelClassification: values.enableSkillLevelClassification,
            enableWorkpositions: values.enableWorkpositions,
            enableCodebooks: values.enableCodebooks,
            examQuestionList: values.examQuestionList,
            surveyQuestionList: values.surveyQuestionList,
          },
        };

        this.props.onSubmit?.(tenantConfiguration);
      }
    });
  };

  hasFormErrors = (): boolean => {
    const { form } = this.props;
    const fieldsError: any = form.getFieldsError();
    return hasFormErrors(fieldsError);
  };

  handleCancel = () => {
    this.props.onCancel?.();
  };
}

const TenantConfigurationEditForm = Form.create<ITenantConfigurationEditProps>()(TenantConfigurationEdit as any);

export default withLocalize<ITenantConfigurationEditOwnProps>(withPathResolver(TenantConfigurationEditForm as any));
