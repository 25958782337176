
import ExternalEducationTemplateContainer from '@src/components/externaleducation/ExternalEducationTemplateContainer';
import React from 'react';
import { withRouter, WithRouterProps } from 'react-router';

// -- Prop types
// ----------
export interface IExternalEducationViewPagePublicProps { }
type IExternalEducationViewPageProps = IExternalEducationViewPagePublicProps & WithRouterProps;

// -- Component
// ----------
const ExternalEducationTemplateViewPage = (props: IExternalEducationViewPageProps) => {
  return <ExternalEducationTemplateContainer externalEducationTemplateId={props.params.externalEducationTemplateId} openedTab={props.params.openedTab} />;
};

export default withRouter<IExternalEducationViewPagePublicProps>(ExternalEducationTemplateViewPage);
