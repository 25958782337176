import { IUserDetails } from "../user/UserDetails";

export interface IEmailTemplate {
  id: string;
  title: string;
  content: string;
  type: IEmailTemplateType;
  createdBy: IUserDetails;
  createdAt: string;
}

export interface IEmailTemplateType {
    id: EmailTemplateTypeEnum;
    name: string;
    description: string;
}

export enum EmailTemplateTypeEnum {
    CODE_GENERATION = '1',
    INACTIVE_USER ='2',
}

export const emailTemplateTypeEnum: IEmailTemplateTypesType = { ...EmailTemplateTypeEnum };

interface IEmailTemplateTypesType {
  // by default TS considers enum key type to be number
  [key: string]: string;
}