import UserDataPicker from '@src/components/common/datapicker/UserDataPicker';
import { IRoleTypes } from '@src/components/common/member/MemberList';
import { IMemberUser } from '@src/model/common/MemberUser';
import { IReference } from '@src/model/common/Reference';
import LocalizeService from '@src/service/util/localize/LocalizeService';
import { Form, FormInstance, Input, Modal, Select } from 'antd';
import React from 'react';

// -- Prop types
// ----------

export interface IMemberFormModalOwnProps<T> {
  title: string;
  member?: IMemberUser<T>;
  roleTypes: IRoleTypes[];
  onSubmit?: (values: IMemberUser<T>) => void;
  onCancel: () => void;
}

type IMemberFormModalProps<T> = IMemberFormModalOwnProps<T>;

// -- Component
// ----------

class MemberFormModal<T extends IReference> extends React.Component<IMemberFormModalProps<T>> {
  formRef = React.createRef<FormInstance<IMemberUser<T>>>();

  onFinish = (values: IMemberUser<T>) => {
    this.props.onSubmit?.(values);
  };

  onFinishFailed = (errorInfo: any) => {
    console.log(errorInfo);
  };

  render = () => {
    return <Modal
      className="lemon-modal__modal--md"
      visible={true}
      title={this.props.title}
      okText={LocalizeService.translate(`COMMON.ACTION_SAVE`)}
      cancelText={LocalizeService.translate('COMMON.ACTION_CANCEL')}
      onCancel={this.props.onCancel}
      onOk={() => this.formRef.current?.submit()}
    >
      <Form
        name="memberForm"
        ref={this.formRef}
        onFinish={this.onFinish}
        onFinishFailed={this.onFinishFailed}
        initialValues={this.props.member}
        layout="vertical"
      >
        <Form.Item
          noStyle={true}
          hidden={true}
          name={['id']}
          fieldKey={['id']}
        >
          <Input type="hidden" />
        </Form.Item>

        <Form.Item
          label={LocalizeService.translate(`MEMBER_LIST.USER_LABEL`)}
          name={['user']}
          fieldKey={['user']}
          rules={[{ required: true }]}
        >
          <UserDataPicker disabled={!!this.props.member} placeholder={LocalizeService.translate(`MEMBER_LIST.USER_PICKER_PLACEHOLDER_LABEL`)} />
        </Form.Item>

        <Form.Item
          label={LocalizeService.translate(`MEMBER_LIST.ROLE_LABEL`)}
          name={['role', 'id']}
          fieldKey={['role', 'id']}
          rules={[{ required: true }]}
        >
          <Select data-test-id="timun-memberModal__roleSelect" placeholder={LocalizeService.translate(`MEMBER_LIST.ROLE_PICKER_PLACEHOLDER_LABEL`)}>
            {this.props.roleTypes.map((role) => (
              <Select.Option key={role.value} value={role.value} title={role.label} data-test-id={`timun-memberModal__roleSelect_${role.value}`}>
                {role.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

      </Form>
    </Modal>;
  };
}

export default MemberFormModal;
