import { Dropdown, Menu } from 'antd';

import React from 'react';


// -- Prop types
// ----------

export interface IActionMenuItem<T> {
  title: string;
  icon?: React.ReactNode;
  onClick: (record: T) => void;
  disabled?: (record: T) => boolean;
  hideItem?: (record: T) => boolean;
}

export interface IActionMenuPublicProps<T> {
  id: string;
  actions: Array<IActionMenuItem<T>>;
  record: T;
}

type IActionMenuProps<T> = React.PropsWithChildren<IActionMenuPublicProps<T>>;

// -- Component
// ----------

const ActionMenu = <T,>(props: IActionMenuProps<T>) => {

  const dropdownActionMenu = (
    <Menu>
      {props.actions.map((menuItem) =>
        !menuItem.hideItem?.(props.record) && <Menu.Item disabled={menuItem.disabled?.(props.record) ?? false} onClick={() => menuItem.onClick(props.record)} key={`${props.id}_${menuItem.title}`}>
          {menuItem.icon &&
            <React.Fragment>
              {menuItem.icon}&nbsp;
            </React.Fragment>
          }
          {menuItem.title}
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <Dropdown
      overlay={dropdownActionMenu}
    >
      {props.children}
    </Dropdown>
  );
};

// -- HOCs and exports
// ----------

export default ActionMenu;
