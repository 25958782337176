import React from 'react';

export interface IOrganizationTreeProps {
  children?: React.ReactNode;
}

/**
 * The root of the hierarchy tree
 */
const OrganizationTree: React.FC<IOrganizationTreeProps> = (props: IOrganizationTreeProps) => {
  return (
    <ul className="timun-orgTree__tree">
      {props.children}
    </ul>
  );
};

export default OrganizationTree;
