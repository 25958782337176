import { useCallbackRef } from '@src/components/common/hooks';
import { IMathLibElement } from '@src/components/common/math/MathProvider';
import { useMath } from '@src/components/common/math/useMath';
import React, { useEffect } from 'react';

// -- Prop types
// ----------

export interface IMathContentOwnProps {
  children: React.ComponentElement<any, any>;
}

type IMathContentProps = IMathContentOwnProps;

// -- Component
// ----------

/**
 * Renders math in it's child content. Rendering is executed on component's mount. If content changes, new math elements will not be rendered until this
 * component is remounted. This can be done using component's "key" attribute.
 */
const MathContent: React.FC<IMathContentProps> = (props) => {
  const [renderMathContent] = useMath();
  const [containerElRef, setContainerElRef] = useCallbackRef<IMathLibElement | null>();

  useEffect(() => {
    if (containerElRef != null) {
      renderMathContent([containerElRef]);
    }
  }, [containerElRef]);

  const child = React.Children.only(props.children);

  // clone element in order to inject "ref"
  return React.cloneElement(child, {
    ref: (node) => {
      setContainerElRef(node);

      // Call the original ref, if any
      const { ref } = child;
      if (typeof ref === 'function') {
        ref(node);
      }
    },
  });
};

export default MathContent;
