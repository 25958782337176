import { Pie } from '@ant-design/charts';
import { PieConfig } from '@ant-design/charts/es/pie';
import React from 'react';

// -- Props
// ----------
export interface IPieData {
  label: string;
  value: number;
}

export interface ITimunPieChartOwnProps {
  data: IPieData[];
  percentage?: boolean;
}

type ITimunPieChartProps = ITimunPieChartOwnProps;

/** Timun pie chart component */
export const TimunPieChart = (props: ITimunPieChartProps) => {

  const config: PieConfig = {
    autoFit: true,
    appendPadding: 10,
    data: props.data,
    angleField: 'value',
    colorField: 'label',
    radius: 0.8,
    legend: {
      itemName: {
        style: {
          fontSize: 14,
          fontWeight: 'bold',
        },
      },
    },
    label: {
      type: 'outer',
      content: `{name} - {value}${props.percentage ? ' %' : ''}`,
      style: {
        fontWeight: 'bold',
        fontSize: 14,
      },
    },
    state: {
      active: {
        style: {
          lineWidth: 0,
          fillOpacity: 0.85,
        },
      },
    },
  };

  return <Pie {...config} />;
};
