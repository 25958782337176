import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import ExamInstanceHelperUtils from '@src/components/exam/common/ExamInstanceHelperUtils';
import ExamInstanceForm from '@src/components/exam/examInstance/ExamInstanceForm';
import { ActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IExamInstance } from '@src/model/education/ExamInstance';
import { Button, Divider, Layout, Typography } from 'antd';
import React from 'react';

// -- Const
// ----------
const { Header, Content } = Layout;

// -- Prop types
// ----------

export interface IExamInstanceViewOwnProps {
  examInstance: IExamInstance;
  onSubmit: (examInstance: IExamInstance) => void;
  onExamStart: () => void;
  courseId?: string;
}

export interface IExamInstanceViewStateProps {
}

export interface IExamInstanceViewDispatchProps {
}

type IExamInstanceViewProps = IExamInstanceViewOwnProps & IExamInstanceViewStateProps & IExamInstanceViewDispatchProps & IWithLocalizeOwnProps;

interface IExamInstanceViewState {
}

// -- Component
// ----------

/** Describe your component ... */
class ExamInstanceView extends React.Component<IExamInstanceViewProps, IExamInstanceViewState> {
  state: IExamInstanceViewState = {};

  render() {
    return (
      <React.Fragment>
        {/* --- Display confirmation dialog button  --- */}
        {ExamInstanceHelperUtils.isExamInstancenInPhase(this.props.examInstance, [ActivityPhaseEnum.READY]) &&
        <Layout className="panel">
          <Header>
            <HeaderTitle link={this.getLink()} title={this.props.examInstance?.title} icon={<LemonIcon name="left"/>}/>

            {/*<Typography.Title> {this.props.examInstance?.title} </Typography.Title>*/}
          </Header>
          <Divider/>
          <Layout>
            <Content>
              <Button onClick={this.props.onExamStart}> {this.props.translate('EXAM_INSTANCE.CONFIRM_DIALOG_MESSAGE')} </Button>
            </Content>
          </Layout>
        </Layout>}

        {/* --- Display evaluation message  --- */}
        {ExamInstanceHelperUtils.isExamInstancenInPhase(this.props.examInstance, [ActivityPhaseEnum.ANSWERS_SUBMITTED, ActivityPhaseEnum.IN_EVALUATION]) &&
        <Layout className="panel">
          <Header>
            <HeaderTitle link={this.getLink()} title={this.props.examInstance?.title} icon={<LemonIcon name="left"/>}/>
          </Header>
          <Divider/>
          <Layout>
            <Content className="timun-examInstanceView__examContentTitle">
              <Typography.Title> {this.props.translate('EXAM_INSTANCE.EVALUATION_MESSAGE_INFO')} </Typography.Title>
            </Content>
          </Layout>
        </Layout>
        }

        {/* --- Display exam instance  --- */}
        {ExamInstanceHelperUtils.isExamInstancenInPhase(this.props.examInstance, [ActivityPhaseEnum.ANSWERING_STARTED]) &&
        <ExamInstanceForm
          showEvaluationFeedback={false}
          showQuestionIntroActionButtons={true}
          examInstance={this.props.examInstance}
          onSubmit={this.props.onSubmit}
          getLink={this.getLink}
        />}

        {/* --- Display exam instance feedback  --- */}
        {ExamInstanceHelperUtils.isExamInstancenInPhase(this.props.examInstance, [ActivityPhaseEnum.EVALUATED]) &&
        <ExamInstanceForm
          showQuestionIntroActionButtons={false}
          showEvaluationFeedback={true}
          examInstance={this.props.examInstance}
          getLink={this.getLink}
        />
        }
      </React.Fragment>
    );
  }

  getLink = () => {
    return this.props.courseId ? `/course/${this.props.courseId}` : `/examTemplate/${this.props.examInstance.examTemplate.id}`;
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<IExamInstanceViewOwnProps>(ExamInstanceView as any);
