import { ICode } from '@src/model/common/Code';

export interface IUserRole extends ICode<UserRoleEnum> {}

export enum HzzUserRoleEnum {
  SUPERADMIN = '1',
  ORGANIZATION_ADMIN = '2',
  TRAINEE = '4',
  COORDINATOR = '6',
}

export enum TimunUserRoleEnum {
  SUPERADMIN = '1',
  ORGANIZATION_ADMIN = '2',
  COURSE_CREATOR = '3',
  TRAINEE = '4',
  ORGANIZER = '5',
  ORGANIZATION_MEMBER = '7',
}

export const UserRoleEnum = { ...TimunUserRoleEnum, ...HzzUserRoleEnum };

export type UserRoleEnum = TimunUserRoleEnum | HzzUserRoleEnum;
