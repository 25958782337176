import React from 'react';

import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import DataTable from '@src/components/common/table/DataTable';
import { ITimunDataTableCol } from '@src/components/common/table/TimunDataTableCol';
import { IExternalEducationInstance } from '@src/model/externaleducationinstance/ExternalEducationInstance';
import { ICollectionData } from '@src/service/business/common/types';
import AppConfigService from '@src/service/common/AppConfigService';
import { DateUtils } from '@src/service/util/date/DateUtils';
import NumberFormatService from '@src/service/util/numberformat/NumberFormatService';
import { Button } from 'antd';

// -- Const
// ----------
const CURRENCY_SYMBOL = AppConfigService.getValue('currencyFormat.currencySymbol');

// -- Prop types
// ----------

export interface IExternalEducationInstanceListOwnProps {
  externalEducationInstanceList: ICollectionData<IExternalEducationInstance>;
  additionalCols?: () => Array<ITimunDataTableCol<IExternalEducationInstance>>;
  canEdit?: boolean;
  onEdit?: (data: IExternalEducationInstance) => void;
  onPageChange?: (page: number, pageSize?: number) => void;
}
type IExternalEducationInstanceListProps = IExternalEducationInstanceListOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** External education instance table */
const ExternalEducationInstanceList: React.FC<IExternalEducationInstanceListProps> = (props: IExternalEducationInstanceListProps) => {
  const renderColumns = () => {
    const columns: Array<ITimunDataTableCol<IExternalEducationInstance>> = [
      {
        key: 'startDateTime',
        contentType: 'string',
        headerTitle: props.translate('EXTERNAL_EDUCATION_INSTANCE_LIST.START_DATE_TIME_LABEL'),
        content: (record: IExternalEducationInstance) => DateUtils.toViewDateFormat(record.startDateTime, 'dateWithYear'),
      },
      {
        key: 'address',
        contentType: 'string',
        headerTitle: props.translate('EXTERNAL_EDUCATION_INSTANCE_LIST.ADDRESS_LABEL'),
        content: (record: IExternalEducationInstance) => record.addresses.map((adress) => <span key={adress.id}> {adress.city} </span>),
      },
      {
        key: 'executionMethod',
        contentType: 'string',
        headerTitle: props.translate('EXTERNAL_EDUCATION_INSTANCE_LIST.EXECUTION_METHOD_LABEL'),
        content: (record: IExternalEducationInstance) => props.translate(`EXECUTION_METHOD.SELECT_OPTION.${record.executionMethod.name}_LABEL`),
      },
      {
        key: 'price',
        contentType: 'string',
        headerTitle: props.translate('EXTERNAL_EDUCATION_INSTANCE_LIST.PRICE_LABEL'),
        content: (record: IExternalEducationInstance) => NumberFormatService.formatCurrency(record.price, CURRENCY_SYMBOL),
      },
      {
        key: 'contractors',
        contentType: 'string',
        headerTitle: props.translate('EXTERNAL_EDUCATION_INSTANCE_LIST.CONTRACTORS_LABEL'),
        content: (record: IExternalEducationInstance) => record.contractors,
      },
      {
        key: 'action',
        contentType: 'string',
        headerTitle: props.translate('EXAM_INSTANCE.LIST.ACTION_LABEL'),
        content: (record: IExternalEducationInstance) => props.canEdit && <Button onClick={() => props.onEdit?.(record)} icon={<LemonIcon name="edit" />} />,
      },
    ];
    if (props.additionalCols) {
      columns.push(...props.additionalCols());
    }
    return columns;
  };

  return (
    <DataTable<IExternalEducationInstance>
      items={props.externalEducationInstanceList.content}
      paginationData={props.externalEducationInstanceList.page}
      paginationConfig={{ onChange: props.onPageChange }}
      columns={renderColumns()}
      scrollable={true}
      expandable={{
        rowExpandable: (record) => record.description != null,
        expandedRowRender: (record) => <div dangerouslySetInnerHTML={{ __html: record.description }} />,
      }}
    />
  );
};

export default withLocalize<IExternalEducationInstanceListOwnProps>(ExternalEducationInstanceList as any);
