import React from 'react';

import LemonAvatar, { ILemonAvatarOwnProps } from '@src/components/common/image/LemonAvatar';

// -- Prop types
// ----------

// -- Public component props
export interface IEditorAvatarOwnProps extends ILemonAvatarOwnProps {}
// -- Complete component props
type IEditorAvatarProps = IEditorAvatarOwnProps;

// -- Component
// ----------

/** Specialized version of LemonAvatar component used for editing forms. Defaults invalid or empty image to icon indicating image upload. */
const EditorAvatar: React.FC<IEditorAvatarProps> = (props) => {
  return <LemonAvatar className={props.className} imagePath={props.imagePath} fallbackIconName="picture" size={props.size} />;
};

export default EditorAvatar;
