import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ISkill } from '@src/model/skillgroup/Skill';
import { Form, FormInstance, Input, Radio } from 'antd';

// -- Prop types
// ----------

export interface ISkillFormOwnProps {
  formRef?: FormInstance<ISkill>;
  skill?: ISkill;
  onSubmit?: (value: ISkill) => void;
}
type ISkillFormProps = ISkillFormOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Describe your component ... */
const SkillForm: React.FC<ISkillFormProps> = (props: ISkillFormProps) => {
  const [form] = Form.useForm<ISkill>(props.formRef);

  return (
    <Form<ISkill>
      form={form}
      layout="vertical"
      initialValues={props.skill}
      name="skill"
      onFinish={props.onSubmit}>

      <Form.Item
        label={props.translate('COMMON.LABEL.NAME')}
        name={'title'}
        rules={[
          { required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
          { max: 128, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') },
        ]}>
        <Input />
      </Form.Item>

      <Form.Item
        label={props.translate('COMMON.LABEL.STATUS')}
        name={'active'}
        rules={[{ required: true, message: props.translate('COMMON.PICK_REQUIRED_FIELD') }]}>
        <Radio.Group buttonStyle="solid">
          <Radio.Button value={true}>{props.translate('COMMON.LABEL.ACTIVE')} </Radio.Button>
          <Radio.Button value={false}>{props.translate('COMMON.LABEL.INACTIVE')} </Radio.Button>
        </Radio.Group>
      </Form.Item>

      <Form.Item
        noStyle={true}
        hidden={true}
        name={'id'}>
        <Input />
      </Form.Item>

      <Form.Item
        noStyle={true}
        hidden={true}
        name={['skillGroup', 'id']}>
        <Input />
      </Form.Item>
    </Form>
  );
};

export default withLocalize<ISkillFormOwnProps>(SkillForm as any);
