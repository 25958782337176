import { Layout } from 'antd';
import React from 'react';

import LemonErrorBoundary from '@src/components/common/error/LemonErrorBoundary';

export interface ISubviewLayoutPublicProps {
  header: string | React.ReactNode;
}

type ISubviewLayoutProps = ISubviewLayoutPublicProps;

/** Layout with header and content. Intended for subviews that need dedicated header (submenu, navigatio, wizard steps, ...). */
const SubviewLayout: React.SFC<ISubviewLayoutProps> = (props) => {
  return (
    <Layout className="lemon-subviewLayout__layout">
      <Layout.Header className="lemon-subviewLayout__header">{props.header}</Layout.Header>

      <Layout.Content className="lemon-subviewLayout__content">
        <LemonErrorBoundary showDefaultError={true}>{props.children}</LemonErrorBoundary>
      </Layout.Content>
    </Layout>
  );
};

export default SubviewLayout;
