import React from 'react';

import FormItemLabelIcon from '@src/components/common/form/FormItemLabelIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { ICodedGrade } from '@src/model/education/CodedGrade';
import { ICodedGradeCreatePayload } from '@src/service/business/codedgrade/codedGradeBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import { Form, Input, Modal, Radio } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

// -- Const
// ----------
const TEXT_AREA_MAX_LENGTH = AppConfigService.getValue('components.common.bigCharacterLengthInput');
const INPUT_MAX_LENGTH = AppConfigService.getValue('components.common.mediumCharacterLengthInput');

// -- Prop types
// ----------
export interface IGradeFormOwnProps {
  codedGrade?: ICodedGrade;
  onCodedGradeCreateSubmit?: (codedGrade: ICodedGradeCreatePayload) => void;
  onCodedGradeUpdateSubmit?: (codedGrade: ICodedGrade) => void;
  onCancel?: () => void;
}

type IGradeFormProps = IGradeFormOwnProps & IWithLocalizeOwnProps;


// -- Component
// ----------

/** Display form for create and update coded grades */
const CodedGradeForm: React.FC<IGradeFormProps> = (props: IGradeFormProps) => {
  const [form] = Form.useForm<ICodedGrade>();

  const handleFinish = (value: ICodedGrade) => {
    if (props.codedGrade?.id) {
      //  update
      props.onCodedGradeUpdateSubmit?.(value);
    } else if (props.onCodedGradeCreateSubmit) {
      // create
      props.onCodedGradeCreateSubmit(value);
    }
  };

  return (
    <Modal
      title={props.translate('CODEBOOK_GRADE_FORM.TITLE')}
      visible={true}
      onCancel={props.onCancel}
      onOk={form.submit}
      okText={props.translate('COMMON.ACTION_SAVE')}
      cancelText={props.translate('COMMON.ACTION_CANCEL')}>
      <Form<ICodedGrade>
        layout="vertical"
        form={form}
        initialValues={props.codedGrade}
        name="codedGrade"
        onFinish={handleFinish}>
        <Form.Item
          label={<FormItemLabelIcon
            label={props.translate('CODEBOOK_GRADE_FORM.NAME_LABEL')}
            tooltipDescription={props.translate('COMMON.LABEL.MAX_LENGTH_CHARACTER_INFO_LABEL', { maxCharNum: INPUT_MAX_LENGTH })} />}
          name={'name'}
          rules={[
            { whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
            { required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') },
            { max: INPUT_MAX_LENGTH, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') }]} >
          <Input />
        </Form.Item>

        <Form.Item
          label={<FormItemLabelIcon
            label={props.translate('CODEBOOK_GRADE_FORM.DESCRIPTION_LABEL')}
            tooltipDescription={props.translate('COMMON.LABEL.MAX_LENGTH_CHARACTER_INFO_LABEL', { maxCharNum: TEXT_AREA_MAX_LENGTH })} />}
          name={'description'}
          rules={[{ whitespace: true, message: props.translate('COMMON.ENTERED_VALUE_NOT_VALID') },
          { max: TEXT_AREA_MAX_LENGTH, message: props.translate('COMMON.LABEL.MAX_LENGTH_REACHED') }]} >
          <TextArea />
        </Form.Item>

        <Form.Item
          label={props.translate('CODEBOOK_GRADE_FORM_FORM.ORDERING_LABEL')}
          name={'ordering'}
          rules={[{ required: true, message: props.translate('COMMON.FILL_REQUIRED_FIELD') }]}  >
          <Input type="number" />
        </Form.Item>

        <Form.Item
          label={props.translate('COMMON.LABEL.STATUS')}
          name={'active'}
          rules={[{ required: true, message: props.translate('COMMON.PICK_REQUIRED_FIELD') }]}>
          <Radio.Group buttonStyle="solid">
            <Radio.Button value={true}>{props.translate('COMMON.LABEL.ACTIVE')} </Radio.Button>
            <Radio.Button value={false}>{props.translate('COMMON.LABEL.INACTIVE')} </Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          noStyle={true}
          hidden={true}
          name={'id'} >
          <Input />
        </Form.Item>
      </Form>
    </Modal >
  );
};

export default withLocalize<IGradeFormOwnProps>(CodedGradeForm as any);
