import FileUtils from '@src/components/common/file/FileUtils';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IFile } from '@src/model/file/File';
import { FileOutlined } from '@ant-design/icons';
import { Col, Empty, Row } from 'antd';
import React from 'react';

// -- Prop types
// ----------

export interface IRepositoryFileListOwnProps {
  fileList: IFile[];
  selectedFile?: IFile;
  uploadMode?: boolean;
  onChange?: (data: IFile) => void;
}
type IRepositoryFileListProps = IRepositoryFileListOwnProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Repository file list component with upload mode for lectures. If in upload mode, pass onChange handler */
class RepositoryFileList extends React.Component<IRepositoryFileListProps> {
  renderItem = (item: IFile) => {
    if (FileUtils.isFile(item)) {
      return (
        <Col key={item.id} lg={6} md={12} xs={24}>
          <a onClick={() => this.handleFileClick(item)}>
            <div className={this.props.selectedFile?.id === item.id ? "panel timun-repository__item--selected" : "panel timun-repository__item"}>
              <Col>
                <FileOutlined className="timun-repository__itemIcon" />
              </Col>
              <Col>
                {item.name}
              </Col>
            </div>
          </a>
        </Col>
      );
    } else {
      console.warn('Unhandled file type ' + item);
      return null;
    }
  };

  render = () => {
    return this.props.fileList.length > 0 ?
      <Row className="timun-repository__itemList">
        {this.props.fileList.map((file) => {
          return this.renderItem(file);
        })}
      </Row>
      :
      <Empty description={this.props.translate('REPOSITORY.DOCUMENTS_EMPTY_LABEL')} />;
  };

  handleFileClick = (file: IFile) => {
    if (this.props.uploadMode) {
      if (this.props.onChange) {
        this.props.onChange(file);
      }
    } else { // trigger download
      window.open(FileUtils.getFileLink(file));
    }

  };
}

// -- HOCs and exports
// ----------

export default withLocalize<IRepositoryFileListOwnProps>(RepositoryFileList as any);