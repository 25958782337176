import { LeftOutlined } from '@ant-design/icons';
import AppContent from '@src/components/common/container/AppContent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import HeaderTitle from '@src/components/course/common/HeaderTitle';
import TagContainer from '@src/components/tag/TagContainer';
import WebinarDataForm from '@src/components/webinar/create/WebinarDataForm';
import WebinarViewWebinarContent from '@src/components/webinar/view/WebinarViewWebinarContent';
import { IFile } from '@src/model/file/File';
import { TagItemTypeEnum } from '@src/model/tag/TagItemType';
import { IUserInfo } from '@src/model/user/User';
import { IWebinar } from '@src/model/webinar/Webinar';
import LoginBusinessStore from '@src/service/business/login/loginBusinessStore';
import webinarViewBusinessStore, {
  IWebinarCreatePayload,
  IWebinarUpdate
} from '@src/service/business/webinars/webinarViewBusinessStore';
import { createActionThunk, IActionThunkMap } from '@src/service/util/action/thunk';
import { Button, Layout } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';
import { Dispatch } from 'redux';

const { Header, Content } = Layout;

interface IWebinarUpdateContainerOwnProps {
  webinarId: string;
}

interface IWebinarUpdateContainerStateProps {
  webinar: IWebinar;
  currentUser: IUserInfo;
}

interface IWebinarUpdateContainerDispatchProps {
  fetchWebinar: (id: string) => any;
  updateWebinar: (data: IWebinarUpdate, callback: IActionThunkMap) => any;
  deleteWebinar: (id: string, callback: IActionThunkMap) => any;
  createWebinar: (data: IWebinarCreatePayload, thunkMap: IActionThunkMap) => void;
  uploadWebinarCover: (id: string, data: IFile, thunkMap: IActionThunkMap) => void;
  clearWebinarData: () => void;
}

type IWebinarUpdateContainerProps = IWebinarUpdateContainerOwnProps & IWebinarUpdateContainerStateProps & IWebinarUpdateContainerDispatchProps & IWebinarUpdateContainerState & IWithLocalizeOwnProps & WithRouterProps;

interface IWebinarUpdateContainerState {
  editing: boolean;
  isEditingCover: boolean;
}

class WebinarUpdateContainer extends React.Component<IWebinarUpdateContainerProps, IWebinarUpdateContainerState> {
  state = {
    editing: false,
    isEditingCover: false,
  };

  componentDidMount() {
    this.fetchWebinar();
  }

  componentDidUpdate = (prevProps: IWebinarUpdateContainerProps) => {
    if (this.props !== prevProps && this.props.webinarId !== prevProps.webinarId) {
      this.fetchWebinar();
    }
  };

  componentWillUnmount() {
    this.props.clearWebinarData();
  }

  render() {
    return (
      <AppContent>
        {this.props.webinar && (
          <Layout>
            <Header className="timun-webinarView__webinarTitle">
              <div className="flex-between">
                <HeaderTitle link={'/webinars'} title={this.props.webinar && this.props.webinar.title} icon={<LeftOutlined />} />
                {!this.state.editing && <Button onClick={() => this.setEditing(!this.state.editing)}>{this.props.translate('WEBINAR_LIST_VIEW.EDIT_WEBINAR')}</Button>}
              </div>
              <TagContainer entityId={this.props.webinarId} entityType={TagItemTypeEnum.WEBINAR} tags={this.props.webinar?.tags && this.props.webinar.tags} onEntityTagUpdate={this.fetchWebinar} />
            </Header>
            <Layout>
              <Content className="timun-webinarView__content">
                &nbsp;
                {this.state.editing ?
                  (<WebinarDataForm onSubmit={this.handleWebinarUpdate} onCancel={this.setEditing} onDelete={this.handleWebinarDelete} webinar={this.props.webinar} onCoverSubmit={this.handleWebinarCoverSubmit} />) :
                  (<WebinarViewWebinarContent
                    webinar={this.props.webinar}
                    onWebinarRedirect={this.handleWebinarRedirect}
                    currentUser={this.props.currentUser}
                  />)}
              </Content>
            </Layout>
          </Layout>
        )}
      </AppContent>
    );
  }

  setEditing = (editing: boolean) => {
    this.setState({
      editing,
    });
  };

  handleWebinarRedirect = (link: string) => {
    const win = window.open(link, '_blank');
    if (win) {
      win.focus();
    }
  };

  private handleWebinarCoverSubmit = (data: IFile) => {
    this.props.uploadWebinarCover(this.props.webinarId, data, {
      success: () => {
        this.fetchWebinar();
      },
    });
  };

  private handleWebinarUpdate = (data: IWebinarCreatePayload) => {
    const webinarData: IWebinarUpdate = {
      id: this.props.webinar.id,
      ...data,
    };

    this.props.updateWebinar(webinarData, {
      success: () => {
        this.props.fetchWebinar(this.props.webinar.id);
        this.setEditing(false);
      },
    });
  };

  private handleWebinarDelete = () => {
    this.props.deleteWebinar(this.props.webinar.id, {
      success: () => {
        this.props.router.push('/webinars');
      },
    });
  };

  private fetchWebinar = (webinarId = this.props.webinarId) => {
    this.props.fetchWebinar(webinarId);
  };
}

const mapStateToProps = (state: any, ownProps: IWebinarUpdateContainerOwnProps): IWebinarUpdateContainerStateProps => ({
  webinar: webinarViewBusinessStore.selectors.getWebinar(state),
  currentUser: LoginBusinessStore.selectors.getCurrentUser(state),
});

const mapDispatchToProps = (dispatch: Dispatch): IWebinarUpdateContainerDispatchProps => ({
  fetchWebinar: (webinarId: string) => dispatch(webinarViewBusinessStore.actions.fetchWebinar({ id: webinarId })),
  updateWebinar: (data: IWebinarUpdate, thunkMap: IActionThunkMap) => dispatch(createActionThunk(webinarViewBusinessStore.actions.updateWebinar(data), thunkMap)),
  deleteWebinar: (webinarId: string, thunkMap: IActionThunkMap) => dispatch(createActionThunk(webinarViewBusinessStore.actions.deleteWebinar({ id: webinarId }), thunkMap)),
  createWebinar: (data: IWebinarCreatePayload, thunkMap: IActionThunkMap) => dispatch(createActionThunk(webinarViewBusinessStore.actions.createWebinar(data), thunkMap)),
  uploadWebinarCover: (id: string, data: IFile, thunkMap: IActionThunkMap) => dispatch(createActionThunk(webinarViewBusinessStore.actions.uploadWebinarCover(id, data), thunkMap)),
  clearWebinarData: () => dispatch(webinarViewBusinessStore.actions.clearWebinarData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<IWebinarUpdateContainerOwnProps>(withRouter(WebinarUpdateContainer as any)));
