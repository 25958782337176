import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import AppConfigService from '@src/service/common/AppConfigService';
import { Col, Row, Select } from 'antd';
import { debounce } from 'lodash';
import { LabelInValueType, RawValueType } from 'rc-select/lib/Select';
import React from 'react';

const CREATE_OPTION_KEY = 'create';

/** Data picker item data structure. */
export interface IDataPickerItem<T = any> {
  name: string;
  value: string | number;
  description?: string;
  icon?: string;
  /** Item data can be used to store additional object related to selected value which can only be string. */
  data?: T;
}

// -- Prop types
// ----------

export interface IDataPickerOwnProps {
  items?: IDataPickerItem[];
  value?: IDataPickerItem;
  disabled?: boolean;
  placeholderSuffix?: string;
  emptyMessage?: string;
  allowClear?: boolean;
  onClear?: () => void;

  showCreate?: boolean;
  onCreate?: (value: string) => void; // for creating simpler objects with one string

  onChange?: (newValue?: IDataPickerItem) => void;
  onSearch?: (value: string) => void;

  dataTestIdPrefix?: string;
}

type IDataPickerProps = IDataPickerOwnProps & IWithLocalizeOwnProps;

interface IDataPickerState {
  inputValue: string;
}

// -- Component
// ----------

/** Describe your component ... */
class DataPicker extends React.Component<IDataPickerProps, IDataPickerState> {
  state: IDataPickerState = {
    inputValue: '',
  };

  handleSearch = debounce((value: string) => {
    this.setState({ inputValue: value });
    if (this.props.onSearch) {
      this.props.onSearch(value);
    }
  }, AppConfigService.getValue(`components.common.debounceTimeout`));

  handleChange = (value: string) => {
    const selectedItem = this.props.items?.find((item: IDataPickerItem) => {
      return item.value === value;
    });

    if (this.props.onChange) {
      if (selectedItem) {
        this.props.onChange(selectedItem);
      } else {
        this.props.onChange();
      }
    }
  };

  handleCreate = () => {
    if (this.props.onCreate) {
      this.props.onCreate(this.state.inputValue);
      this.setState({ inputValue: '' }); // reset input value
    }
  };

  render = () => {
    const { Option } = Select;

    return (
      <Select
        showSearch={true}
        onChange={this.handleChange}
        value={this.props.value?.name}
        onSearch={this.handleSearch}
        className="timun-dataPicker__select"
        optionLabelProp="label"
        allowClear={this.props.allowClear ?? true}
        defaultActiveFirstOption={true}
        notFoundContent={null}
        filterOption={false}
        disabled={this.props.disabled}
        onSelect={this.handleSelect}
        onClear={this.props.onClear}
        data-test-id={this.props.dataTestIdPrefix ? `${this.props.dataTestIdPrefix}_select` : undefined}
        placeholder={
          <React.Fragment>
            {this.props.placeholderSuffix || this.props.translate('COMMON.ACTION_SEARCH')}
          </React.Fragment>
        }
      >
        {this.props.items &&
          this.props.items.map((item: IDataPickerItem) => (
            <Option key={item.value} value={item.value} title={item.name} data-test-id={this.props.dataTestIdPrefix ? `${this.props.dataTestIdPrefix}_value_${item.value}` : undefined}>
              <React.Fragment>
                {item.name}
                {item.description && <span>&nbsp;-&nbsp;{item.description}</span>}
              </React.Fragment>
            </Option>
          ))}

        {/* no data option */}
        {this.props.items?.length === 0 && (
          <Option key="empty" value="" disabled={true}>
            <Row>
              <Col className="text-center">{this.props.emptyMessage || this.props.translate('COMMON.MESSAGE_NO_DATA')}</Col>
            </Row>
          </Option>
        )}

        {/* create new */}
        {this.props.showCreate && this.state.inputValue && <Option key={CREATE_OPTION_KEY} value={CREATE_OPTION_KEY}>
          {this.props.translate('COMMON.ACTION_CREATE_NEW', { input: this.state.inputValue })}
        </Option>}
      </Select>
    );
  };

  handleSelect = (value: RawValueType | LabelInValueType) => {
    if (this.props.onCreate && value === CREATE_OPTION_KEY) {
      this.handleCreate();
    }
  };
}

export default withLocalize<IDataPickerOwnProps>(DataPicker as any);
