import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

import ITenantOrganizationConfiguration from '@src/model/tenant/TenantOrganizationConfiguration';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { IPayloadAction } from '@src/service/business/common/types';
import { createApiResponseUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import PublicTenantOrganizationConfigurationBusinessStore from '@src/service/business/tenant/publicTenantOrganizationConfigurationService';
import { actionThunk } from '@src/service/util/observable/operators';
import { startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts

export type ITenantOrganizationConfigurationUpdatePayload = Pick<ITenantOrganizationConfiguration, 'id' | 'configuration'>;

// -
// -------------------- Actions

const Actions = {
  TENANT_ORGANIZATION_CONFIGURATION_UPDATE: 'TENANT_ORGANIZATION_CONFIGURATION_UPDATE',
};

/** Update tenantOrganizationConfiguration by ID. */
const updateTenantOrganizationConfiguration = (data: ITenantOrganizationConfigurationUpdatePayload): IPayloadAction<ITenantOrganizationConfigurationUpdatePayload> => {
  return {
    type: Actions.TENANT_ORGANIZATION_CONFIGURATION_UPDATE,
    payload: data,
  };
};

// -
// -------------------- Side-effects

const updateTenantOrganizationConfigurationEffect = (action$: Observable<IPayloadAction<ITenantOrganizationConfigurationUpdatePayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.TENANT_ORGANIZATION_CONFIGURATION_UPDATE;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const data = action.payload;

      return EntityApiServiceRegistry.getService('OrganizationConfiguration')
        .updateMethod('current', data)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return PublicTenantOrganizationConfigurationBusinessStore.actions.loadTenantOrganizationConfiguration(data);
    }),

    reportCaughtMessage((error: any) => createApiResponseUserFeedbackError(error, 'TENANT_CONFIGURATION_CREATE.ERROR', 'GENERAL_MESSAGE.GENERAL_UPDATE_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error updating tenantOrganizationConfiguration', error);
      return o;
    })
  );
};

// --
// -------------------- Business Store

export const TenantOrganizationConfigurationBusinessStore = {
  actions: {
    updateTenantOrganizationConfiguration,
  },

  effects: {
    updateTenantOrganizationConfigurationEffect,
  },
};

// --
// export business store
export default TenantOrganizationConfigurationBusinessStore;
