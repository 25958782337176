import { IIdPayload } from '@src/service/business/common/types';
import { StateObservable } from 'redux-observable';
import { Observable } from 'rxjs';
import { catchError, filter, map, mergeMap } from 'rxjs/operators';

import { ICompany } from '@src/model/company/Company';
import { IAddress } from '@src/model/user/Address';
import { IContactData } from '@src/model/user/ContactData';
import EntityApiServiceRegistry from '@src/service/api/registry/entity/EntityApiServiceRegistry';
import { ILemonAction, IPayloadAction } from '@src/service/business/common/types';
import { createStaticMessageUserFeedbackError } from '@src/service/business/common/userFeedbackUtils';
import { actionThunk } from '@src/service/util/observable/operators';
import { startGlobalProgress, stopGlobalProgress } from '@src/service/util/observable/operators';
import { reportCaughtMessage } from '@src/service/util/observable/operators/userFeedback';

// -
// -------------------- Types&Consts
export interface ICompanyCreatePayload {
  name: string;
  identificationNumber: string;
  contactData: Array<Exclude<IContactData, 'id'>>;
  address: Exclude<IAddress, 'id'>;
}

// -
// -------------------- Selectors
const getCompany = (store: any): ICompany => store.company;

// -
// -------------------- Actions
const Actions = {
  COMPANY_FETCH: 'COMPANY_FETCH',
  COMPANY_LOAD: 'COMPANY_LOAD',
  COMPANY_CLEAR: 'COMPANY_CLEAR',
};

const fetchCompany = (id: string): IPayloadAction<IIdPayload> => {
  return {
    type: Actions.COMPANY_FETCH,
    payload: { id },
  };
};

const loadCompany = (data: ICompany): IPayloadAction<ICompany> => {
  return {
    type: Actions.COMPANY_LOAD,
    payload: data,
  };
};

const clearCompany = (): ILemonAction => {
  return {
    type: Actions.COMPANY_CLEAR,
  };
};

// -
// -------------------- Side-effects

const fetchCompanyEffect = (action$: Observable<IPayloadAction<IIdPayload>>, state$: StateObservable<any>) => {
  return action$.pipe(
    filter((action) => {
      return action.type === Actions.COMPANY_FETCH;
    }),

    startGlobalProgress(),

    mergeMap((action) => {
      const { id } = action.payload;

      return EntityApiServiceRegistry.getService('Company')
        .fetchEntity(id)
        .pipe(actionThunk(action));
    }),

    stopGlobalProgress(),

    map((data) => {
      return loadCompany(data);
    }),

    reportCaughtMessage((error: any) => createStaticMessageUserFeedbackError('GENERAL_MESSAGE.GENERAL_FETCH_ERROR')),

    catchError((error: any, o: Observable<any>) => {
      console.error('Error fetching company', error);
      return o;
    })
  );
};

// -
// -------------------- Reducers

const company = (state: ICompany | null = null, action: IPayloadAction<ICompany>) => {
  if (action.type === Actions.COMPANY_LOAD) {
    return action.payload;
  } else if (action.type === Actions.COMPANY_CLEAR) {
    return null;
  }

  return state;
};

// --
// -------------------- Business Store

export const CompanyBusinessStore = {
  actions: {
    fetchCompany,
    clearCompany,
  },

  selectors: {
    getCompany,
  },

  effects: {
    fetchCompanyEffect,
  },

  reducers: {
    company,
  },
};

// --
// export business store
export default CompanyBusinessStore;
