import { DownOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { classNames } from '@src/components/common/util/classNames';
import { LABELS, MESSAGES } from '@src/service/locale/message';
// tslint:disable-next-line:no-submodule-imports
import { MenuInfo } from 'rc-menu/lib/interface';

// -- Prop types
// ----------
export interface ILocalePickerOwnProps {
  onLocaleChange: (menuIfo: MenuInfo) => void;
  locale: string;
  standalone?: boolean;
}

export interface ILocalePickerStateProps { }

export interface ILocalePickerDispatchProps { }
type ILocalePickerProps = ILocalePickerOwnProps & ILocalePickerStateProps & ILocalePickerDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Menu component for picking app locale */
const LocalePicker: React.FC<ILocalePickerProps> = (props) => {
  return (
    <Menu mode={props.standalone ? 'horizontal' : 'vertical'} overflowedIndicator={null} onClick={props.onLocaleChange} defaultSelectedKeys={[props.locale]} className={classNames({ 'lemon-appLayout__headerMenu': props.standalone ?? false })}>
      <Menu.SubMenu
        key="lemon-localeMenu"
        title={
          <React.Fragment>
            {props.translate('LOGIN_MENU.LANGUAGE_LABEL')}
            &nbsp;
            {props.standalone && <DownOutlined />}
          </React.Fragment>
        }
        className={classNames({ 'lemon-appLayout__headerMenu': props.standalone ?? false })}
      >
        {Object.keys(MESSAGES).map((locale) => {
          return <Menu.Item key={locale}>{LABELS[locale]}</Menu.Item>;
        })}
      </Menu.SubMenu>
    </Menu>
  );
};

// -- HOCs and exports
// ----------

export default withLocalize<ILocalePickerOwnProps>(LocalePicker as any);
