import React from 'react';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import DataTable from '@src/components/common/table/DataTable';
import { ITimunDataTableCol } from '@src/components/common/table/TimunDataTableCol';
import ExamInstanceHelperUtils from '@src/components/exam/common/ExamInstanceHelperUtils';
import ExamInstanceStatus from '@src/components/exam/common/ExamInstanceStatus';
import { ParticipantRoleEnum } from '@src/model/activity/ActivityParticipant';
import { ActivityPhaseEnum } from '@src/model/activity/ActivityPhase';
import { IExamInstance } from '@src/model/education/ExamInstance';
import { ICollectionData } from '@src/service/business/common/types';
import AppConfigService from '@src/service/common/AppConfigService';
import { Button } from 'antd';
import moment from 'moment';
import { withRouter, WithRouterProps } from 'react-router';

// -- Const
// ----------
const timeFormat = AppConfigService.getValue('dateTimeFormat.timeWithSeconds');

// -- Prop types
// ----------

export interface IExamInstanceListOwnProps {
  examInstances: ICollectionData<IExamInstance>;
  scrollable?: boolean;
  additionalCols?: () => Array<ITimunDataTableCol<IExamInstance>>;
  onPageChange?: (page: number, pageSize?: number) => void;
}

type IExamInstanceListProps = IExamInstanceListOwnProps & IWithLocalizeOwnProps & WithRouterProps;

interface IExamTemplateDataFormState { }

// -- Component
// ----------

/** Component that display table with students and asigneed exams */
class ExamInstanceList extends React.Component<IExamInstanceListProps, IExamTemplateDataFormState> {
  state: IExamTemplateDataFormState = {
  };

  renderColumns = () => {
    const columns: Array<ITimunDataTableCol<IExamInstance>> = [
      {
        key: 'traineeName',
        contentType: 'string',
        headerTitle: this.props.translate('EXAM_INSTANCE.LIST.TRAINEE_LABEL'),
        content: (record: IExamInstance) => ExamInstanceHelperUtils.getActivityParticipantNameByRole(ParticipantRoleEnum.ASSIGNEE, record.activities[0]),
      },
      {
        key: 'examTitle',
        contentType: 'string',
        headerTitle: this.props.translate('EXAM_INSTANCE.LIST.ASSIGNED_EXAM_LABEL'),
        content: (record: IExamInstance) => record.title,
      },
      {
        key: 'currentPhase',
        contentType: 'string',
        headerTitle: this.props.translate('EXAM_INSTANCE.LIST.STATUS_LABEL'),
        content: (record: IExamInstance) => <ExamInstanceStatus activity={record.activities[0]} />,
      },
      {
        key: 'earnedPoints',
        contentType: 'string',
        headerTitle: this.props.translate('EXAM_INSTANCE.LIST.EARNED_POINTS_LABEL'),
        content: (record: IExamInstance) => `${record.additionalData.totalEarnedPoints}/${record.additionalData.totalPoints}`,
      },
      {
        key: 'timeOfCompletion',
        contentType: 'string',
        headerTitle: this.props.translate('EXAM_INSTANCE.LIST.TIME_LABEL'),
        content: (record: IExamInstance) => moment().startOf('day').milliseconds(record.additionalData.completionTimeInMs).format(timeFormat), // can't find a normal way to display time format from miliseconds
      },
      {
        key: 'action',
        contentType: 'string',
        headerTitle: this.props.translate('EXAM_INSTANCE.LIST.ACTION_LABEL'),
        content: (record: IExamInstance) => ExamInstanceHelperUtils.isExamInstancenInPhase(record, [ActivityPhaseEnum.EVALUATED]) && <Button disabled={!ExamInstanceHelperUtils.isExamInstancenInPhase(record, [ActivityPhaseEnum.EVALUATED])} onClick={() => this.props.router.push(`examInstance/${record.id}`)} > {this.props.translate('EXAM_INSTANCE.LIST_ITEM.BUTTON.VIEW_EVALUATION_LABEL')} </Button >,
      },
    ];
    if (this.props.additionalCols) {
      columns.push(...this.props.additionalCols());
    }
    return columns;
  };

  render() {
    return (
      <React.Fragment>
        <DataTable<IExamInstance>
          items={this.props.examInstances.content}
          paginationData={this.props.examInstances.page}
          columns={this.renderColumns()}
          scrollable={true}
          paginationConfig={{ onChange: this.props.onPageChange }}
          showActionMenu={true}
        />
      </React.Fragment>
    );
  }
}

// -- HOCs and exports
// ----------

export default withRouter<IExamInstanceListOwnProps>(withLocalize(ExamInstanceList as any));
