import AppContent from '@src/components/common/container/AppContent';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import AllowedUserRoles from '@src/components/common/role/AllowedUserRoles';
import withRoles, { IWithRolesOwnProps } from '@src/components/common/role/withRoles';
import WebinarListContainer from '@src/components/webinar/list/WebinarListContainer';
import { UserRoleEnum } from '@src/model/user/UserRole';
import { WebinarStatusEnum } from '@src/model/webinar/Webinar';
import WebinarListBusinessStore, { IWebinarsFilter } from '@src/service/business/webinars/webinarListBusinessStore';
import { Button, Row, Tabs } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

// ----- Constants

const TabPane = Tabs.TabPane;

export enum WebinarListNavigationMenuKeys {
  Anounced = '1',
  Recorded = '2',
}

export interface IWebinarListPageContainerOwnProps {
}

export interface IWebinarListPageContainerStateProps {
  webinarListFilter: IWebinarsFilter;
}

export interface IWebinarListPageContainerDispatchProps {
  storeWebinarsFilter: (params: IWebinarsFilter) => any;
}

type IWebinarListPageContainerProps = IWebinarListPageContainerOwnProps & IWebinarListPageContainerStateProps & IWebinarListPageContainerDispatchProps & IWithLocalizeOwnProps & IWithRolesOwnProps & WithRouterProps;

interface IWebinarListPageContainerState {
  selectedKey: string;
}

class WebinarListPageContainer extends React.Component<IWebinarListPageContainerProps, IWebinarListPageContainerState> {
  state: IWebinarListPageContainerState = {
    selectedKey: WebinarListNavigationMenuKeys.Anounced,
  };

  componentDidMount() {
    this.setWebinarListFilterByTab(this.state.selectedKey);
  }

  componentDidUpdate(prevProps: IWebinarListPageContainerProps, prevState: IWebinarListPageContainerState) {
    //
  }

  componentWillUnmount() {
    //
  }

  render() {
    const anouncedTabLabel = this.props.isInRoles([UserRoleEnum.COURSE_CREATOR, UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN]) ?
      'WEBINAR_LIST_VIEW.ALL_WEBINARS' : 'WEBINAR_LIST_VIEW.TABS.ANOUNCED';
    return (
      <AppContent level={1} title={
        <Row justify="space-between">
          {this.props.translate('WEBINAR_LIST.VIEW_TITLE')}
          <AllowedUserRoles roles={[UserRoleEnum.COURSE_CREATOR, UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN]} fallback="">
            <Button type="primary" className="text-right" onClick={this.handleCreateWebinarButtonClick}>
              {this.props.translate('WEBINAR_LIST_VIEW.CREATE_WEBINAR')}
            </Button>
          </AllowedUserRoles>
        </Row>
      }>
        <Tabs activeKey={this.state.selectedKey} onChange={this.onMenuClick}>
          <TabPane tab={this.props.translate(anouncedTabLabel)} key={WebinarListNavigationMenuKeys.Anounced}>
            <WebinarListContainer showStatusFilter={true} />
          </TabPane>
          <TabPane tab={this.props.translate('WEBINAR_LIST_VIEW.TABS.FINISHED')} key={WebinarListNavigationMenuKeys.Recorded} >
            <WebinarListContainer showStatusFilter={false} />
          </TabPane>
        </Tabs>

      </AppContent >
    );
  }

  setWebinarListFilterByTab = (tabKey: string) => {
    let statuses: string[] = [];
    if (tabKey === WebinarListNavigationMenuKeys.Anounced) {
      statuses = this.props.isInRoles([UserRoleEnum.COURSE_CREATOR, UserRoleEnum.ORGANIZATION_ADMIN, UserRoleEnum.SUPERADMIN]) ?
        [WebinarStatusEnum.IN_CREATION, WebinarStatusEnum.CREATION_FINISHED, WebinarStatusEnum.PUBLISHED] : [WebinarStatusEnum.PUBLISHED];
    } else if (tabKey === WebinarListNavigationMenuKeys.Recorded) {
      statuses = [WebinarStatusEnum.FINISHED];
    }

    this.props.storeWebinarsFilter({ ...this.props.webinarListFilter, statuses });
  };

  handleCreateWebinarButtonClick = () => {
    this.props.router.push('/webinar/create');
  };

  onMenuClick = (tabKey: string) => {
    this.setWebinarListFilterByTab(tabKey);
    this.setState({
      selectedKey: tabKey,
    });
  };

}

const mapStateToProps = (state: any, ownProps: IWebinarListPageContainerOwnProps): IWebinarListPageContainerStateProps => ({
  webinarListFilter: WebinarListBusinessStore.selectors.getWebinarsFilter(state),
});

const mapDispatchToProps = (dispatch: any): IWebinarListPageContainerDispatchProps => ({
  storeWebinarsFilter: (params: IWebinarsFilter) => dispatch(WebinarListBusinessStore.actions.storeWebinarsFilter(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<IWebinarListPageContainerOwnProps>(withRoles(withRouter(WebinarListPageContainer as any))));
