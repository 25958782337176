import LemonIcon from '@src/components/common/image/LemonIcon';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import UserForm from '@src/components/user/form/UserForm';
import UserManagementList from '@src/components/user/list/UserManagementList';
import { IUserInfo } from '@src/model/user/User';
import { IWorkPosition } from '@src/model/user/WorkPosition';
import { ICollectionData } from '@src/service/business/common/types';
import { ILemonEvent } from '@src/service/util/event/lemonEvent';
import { Button, Col, Row } from 'antd';
import React from 'react';

// -- Prop types
// ----------
export interface IUserManagementListViewOwnProps {
  userList: ICollectionData<IUserInfo>;
  workPositionList: IWorkPosition[];
  onPageChange: (page: number, pageSize?: number | undefined) => void;
  onUserSubmit: (data: ILemonEvent<IUserInfo>) => void;
  showOib?: boolean;
}

export interface IUserManagementListViewStateProps {
}

export interface IUserManagementListViewDispatchProps {
}

type IUserManagementListViewProps = IUserManagementListViewOwnProps & IUserManagementListViewStateProps & IUserManagementListViewDispatchProps & IWithLocalizeOwnProps;

interface IUserManagementListViewState {
  isEditing: boolean;
}

// -- Component
// ----------
/** Admin user management list view */
class UserManagementListView extends React.Component<IUserManagementListViewProps, IUserManagementListViewState> {
  state: IUserManagementListViewState = {
    isEditing: false,
  };

  render = () => {
    return <React.Fragment>
      {/* Action */}
      <Row justify="end">
        <Col>
          <Button type="primary" icon={<LemonIcon name="plus" />} onClick={this.toggleEdit}>  {this.props.translate('USER_VIEW_CONTAINER.CREATE_USER')} </Button>
        </Col>
      </Row>
      <br />
      {/* List */}
      {this.props.userList && <UserManagementList showOib={this.props.showOib} onPageChange={this.props.onPageChange} userList={this.props.userList} />}
      {/* Modal */}
      {this.state.isEditing && <UserForm workPositionList={this.props.workPositionList} allowUserStatusChange={true} allowRoleChange={true} onToggleModal={this.toggleEdit} onUserSubmit={this.props.onUserSubmit} />}
    </React.Fragment>;
  };

  toggleEdit = () => {
    this.setState({ isEditing: !this.state.isEditing });
  };
}

// -- HOCs and exports
// ----------

export default withLocalize<IUserManagementListViewOwnProps>(UserManagementListView as any);
