import AppConfigService from '@src/service/common/AppConfigService';
import { Globals } from '@src/service/util/Globals';
import imagePathResolver from '@src/service/util/imagePathResolver';
import UrlBuilder from '@src/service/util/UrlBuilder';

/**
 * Factory class with convenience methods for creating resource and api URL builders. Each methods prepopulates
 * URL builder with appropriate config values (eg. base URL).
 *
 * See {@see UrlBuilder} for more info.
 */
export default class UrlBuilderFactory {
  /** Create full application URL builder prefixed with <proto>://<domain>:<port> */
  static createFullApplicationBuilder(): UrlBuilder {
    return new UrlBuilder().baseUrl(Globals.window.location.origin).urlPart(AppConfigService.getValue('app.basePath'));
  }

  /** Create application base URL builder. */
  static createApplicationBuilder(): UrlBuilder {
    return new UrlBuilder().baseUrl(AppConfigService.getValue('app.basePath'));
  }

  /**
   * Create URL builder for paths resolved by webpack context.
   *
   * This differs from application builder by missing app's base URL in order to avoid
   * concatenating both "app.baseUrl" and webpack's "output.publicPath" which should be the same.
   */
  static createPathResolverBuilder(): UrlBuilder {
    return new UrlBuilder();
  }

  /**
   * Create API base URL builder. This method expects full API path prefixed with source URL and appends API base URL.
   * Usually, this is used to reference full API paths stored in DB.
   *
   * If you're building resource API url from scratch (eg. with entity name and ID) you should probably use {@see UrlBuilderFactory.createApiResourceBuilder}.
   */
  static createApiBuilder(): UrlBuilder {
    // NOTE: this builder currently does not do anything but is here to centralize handling of this type of URLs in case later something needs to be done with them.
    return new UrlBuilder();
  }

  /** Create API base URL builder. Use this method if your building API URL from scratch, eg. with entity name and ID only. */
  static createApiResourceBuilder(): UrlBuilder {
    return new UrlBuilder().urlPart(AppConfigService.getValue('api.sourceUrl'));
  }

  /** Create API file URL builder. */
  static createApiFileBuilder(): UrlBuilder {
    return UrlBuilderFactory.createApiResourceBuilder().urlPart(AppConfigService.getValue('api.fileResourceUrl'));
  }

  /** Create application 'asset' base URL builder. */
  static createAssetBuilder(): UrlBuilder {
    return UrlBuilderFactory.createApplicationBuilder().urlPart('asset');
  }

  /** Build URL from given app path. */
  static buildAppUrl(resourcePath: string): string {
    return UrlBuilderFactory.createApplicationBuilder()
      .urlPart(resourcePath)
      .build();
  }

  /** Build URL from given image path. */
  static buildImageUrl(imageName: string): string {
    return UrlBuilderFactory.createPathResolverBuilder()
      .urlPart(imagePathResolver(imageName))
      .build();
  }

  /**
   * Build URL from given API path. This method expects full API path. See {@see UrlBuilderFactory.createApiBuilder} fr more details.
   *
   * If you're building resource API url from scratch (eg. with entity name and ID) you should probably use {@see UrlBuilderFactory.buildApiResourceUrl}.
   */
  static buildApiUrl(resourcePath: string): string {
    return UrlBuilderFactory.createApiBuilder()
      .urlPart(resourcePath)
      .build();
  }

  /** Build URL from given API resurce path. */
  static buildApiResourceUrl(resourcePath: string): string {
    return UrlBuilderFactory.createApiResourceBuilder()
      .urlPart(resourcePath)
      .build();
  }

  /** Build file URL from given API file object ID. */
  static buildApiFileUrl(fileId: string): string {
    return UrlBuilderFactory.createApiFileBuilder()
      .urlPart(fileId)
      .build();
  }
}
