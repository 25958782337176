import UserActivityListFilter from '@src/components/activity/list/UserActivityListFilter';
import UserActivityTableList from '@src/components/activity/list/UserActivityTableList';
import AppContent from '@src/components/common/container/AppContent';

import ListPagination from '@src/components/common/list/ListPagination';

import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import { IUserActivity, userActivityTypesEnum } from '@src/model/activity/UserActivity';
import { IUserInfo } from '@src/model/user/User';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import userActivityListBusinessStore, { IUserActivityListFilter } from '@src/service/business/user/userActivityListBusinessStore';
import UserListBusinessStore, { IUserListFilter } from '@src/service/business/user/userListBusinessStore';
import AppConfigService from '@src/service/common/AppConfigService';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter, WithRouterProps } from 'react-router';

const userActivityTypes = Object.keys(userActivityTypesEnum).map((key: string) => {
  return {
    name: key,
    id: userActivityTypesEnum[key],
  };
});

// -- Prop types
// ----------
interface IUserActivityListContainerOwnProps {
}

// combine props base with already provided WithRouterProps
interface IUserActivityListContainerStateProps {
  userActivityList: ICollectionData<IUserActivity>;
  userActivityListFilter: IUserActivityListFilter;
  userList: ICollectionData<IUserInfo>;
}

interface IUserActivityListContainerDispatchProps {
  fetchList: (listFilter: IUserActivityListFilter, page: number, size: number, sort: string[]) => any;
  clearList: () => void;
  storeListFilter: (listFilter: IUserActivityListFilter) => any;
  clearListFilter: () => any;

  fetchUserList: (params: ICollectionFetchPayload<IUserListFilter>) => any;
  clearUserList: () => void;
}

type IUserActivityListContainerProps = IUserActivityListContainerOwnProps & IUserActivityListContainerStateProps & IUserActivityListContainerDispatchProps & WithRouterProps & IWithLocalizeOwnProps;

interface IUserActivityListContainerState {
  page: number;
  size: number;
  sort: string[];
}

// -- Component
// ----------
class UserActivityListContainer extends React.Component<IUserActivityListContainerProps, IUserActivityListContainerState> {
  state: IUserActivityListContainerState = {
    page: 0,
    size: AppConfigService.getValue('api.paging.defaultPageSize'),
    sort: [],
  };

  componentDidMount = () => {
    this.props.clearUserList();
    this.updateUserActivityList();
  };

  componentDidUpdate(prevProps: IUserActivityListContainerProps, prevState: IUserActivityListContainerState) {
    if (prevProps.userActivityListFilter !== this.props.userActivityListFilter || this.state !== prevState) {
      this.updateUserActivityList();
    }
  }

  componentWillUnmount = () => {
    // clear current data from store
    this.props.clearList();
    this.props.clearListFilter();
    this.props.clearUserList();
  };

  render = () => {
    return (
      <AppContent level={2} title={<UserActivityListFilter filter={this.props.userActivityListFilter ? this.props.userActivityListFilter : {}} activityTypesList={userActivityTypes} onChange={this.handleFilterChange} userList={this.props.userList ? this.props.userList.content : []} updateUserList={this.updateUserList}/>
      }>
        {this.props.userActivityList && <ListPagination page={this.props.userActivityList.page} onChange={this.handlePageChange}/>}

        {this.props.userActivityList && <UserActivityTableList userActivities={this.props.userActivityList}/>}

        {this.props.userActivityList && <ListPagination page={this.props.userActivityList.page} onChange={this.handlePageChange}/>}
      </AppContent>
    );
  };

  // --------- private

  handlePageChange = (page: number, pageSize?: number) => {
    this.setState({ page: page - 1, size: pageSize ?? this.state.size });
  };

  handleFilterChange = (listFilter: IUserActivityListFilter) => {
    this.props.storeListFilter(listFilter);
    this.setState({ page: 0 });
  };

  private updateUserList = (filter: IUserListFilter, page: number = 0, size: number = AppConfigService.getValue('paging.smallPageSize'), sort: string[] = []) => {
    this.props.fetchUserList({
      filter,
      page,
      size,
      sort,
    });
  };

  private updateUserActivityList = () => {
    this.props.fetchList(this.props.userActivityListFilter, this.state.page, this.state.size, this.state.sort);
  };
}

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any): IUserActivityListContainerStateProps => ({
  userActivityList: userActivityListBusinessStore.selectors.getList(state),
  userActivityListFilter: userActivityListBusinessStore.selectors.getListFilter(state),
  userList: UserListBusinessStore.selectors.getUserList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IUserActivityListContainerDispatchProps => ({
  fetchList: (listFilter: IUserActivityListFilter, page: number, size: number, sort: string[]) => dispatch(userActivityListBusinessStore.actions.fetchList(listFilter, page, size, sort)),
  clearList: () => dispatch(userActivityListBusinessStore.actions.clearList()),
  storeListFilter: (listFilter: IUserActivityListFilter) => dispatch(userActivityListBusinessStore.actions.storeListFilter(listFilter)),
  clearListFilter: () => dispatch(userActivityListBusinessStore.actions.clearListFilter()),

  fetchUserList: (params: ICollectionFetchPayload<IUserListFilter>) => dispatch(UserListBusinessStore.actions.fetchUserList(params)),
  clearUserList: () => dispatch(UserListBusinessStore.actions.clearUserList()),
});

// TODO: improve component public props API when using third party HOCs
// type assertion to 'any' to because 'withRouter' doesn't do type reduction so injected props types are appearing in public props API
export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withRouter(UserActivityListContainer)) as any);
