import React, { useCallback } from 'react';
import { connect } from 'react-redux';

import useCollectionState from '@src/components/common/collectionParams/useCollectionState';
import useEntityModalsState from '@src/components/common/hook/useEntityModalsState';
import withLocalize, { IWithLocalizeOwnProps } from '@src/components/common/localize/withLocalize';
import ExternalEducationInstanceListView from '@src/components/externaleducationistance/ExternalEducationInstanceListView';
import ExternalEducationInstanceModalForm from '@src/components/externaleducationistance/form/ExternalEducationInstanceModalForm';
import { ICodeBookEntry } from '@src/model/common/CodeBookEntry';
import { IExternalEducationInstance } from '@src/model/externaleducationinstance/ExternalEducationInstance';
import { ExternalEducationTemplateStatusEnum } from '@src/model/externalEducationTemplate/ExternalEducationTemplate';
import { ICollectionData, ICollectionFetchPayload } from '@src/service/business/common/types';
import ExternalEducationInstanceBusinessStore, { IExternalEducationInstanceCreatePayload } from '@src/service/business/externaleducations/ExternalEducationInstanceBusinessStore';
import ExternalEducationInstanceListBusinessStore, { IExternalEducationInstanceListFilter } from '@src/service/business/externaleducations/ExternalEducationInstanceListBusinessStore';
import { createTrackableAction, ITrackableAction } from '@src/service/util/action/trackAction';

// -- Const
// ----------
const VIEW_NAME = '@@ExternalEducationInstanceListContainer';

// -- Prop types
// ----------

export interface IExternalEducationInstanceListContainerOwnProps {
  externalEducationTemplateId: string;
  externalEducationTemplateStatus: ICodeBookEntry<ExternalEducationTemplateStatusEnum>;
}
export interface IExternalEducationInstanceListContainerStateProps {
  externalEducationInstanceList: ICollectionData<IExternalEducationInstance>;
}
export interface IExternalEducationInstanceListContainerDispatchProps {
  fetchExternalEducationInstanceList: (params: ICollectionFetchPayload<IExternalEducationInstanceListFilter>) => ITrackableAction;

  createExternalEducationInstance: (data: IExternalEducationInstanceCreatePayload) => ITrackableAction;
  updateExternalEducationInstance: (data: IExternalEducationInstance) => ITrackableAction;
}
type IExternalEducationInstanceListContainerProps = IExternalEducationInstanceListContainerOwnProps & IExternalEducationInstanceListContainerStateProps
  & IExternalEducationInstanceListContainerDispatchProps & IWithLocalizeOwnProps;

// -- Component
// ----------

/** Display external education instance list with modal */
const ExternalEducationInstanceListContainer = (props: IExternalEducationInstanceListContainerProps) => {
  const handleFilterUpdate = () => onUpdateList(props.fetchExternalEducationInstanceList, { ...collectionParams.filter, externalEducationTemplate: props.externalEducationTemplateId });

  const [collectionParams, updateCollectionParams, onUpdateList] = useCollectionState<IExternalEducationInstanceListFilter>({
    viewName: VIEW_NAME,
    updateFn: handleFilterUpdate,
  }, [props.externalEducationTemplateId]);

  const [externalEducationInstanceModalsState, toggleExternalEducationInstanceModals] = useEntityModalsState<IExternalEducationInstance>();

  const handleCreate = useCallback((externalEducationInstanceData: IExternalEducationInstanceCreatePayload) => {
    props.createExternalEducationInstance(externalEducationInstanceData).track().subscribe(
      // success
      () => {
        onUpdateList();
        toggleExternalEducationInstanceModals.onCloseCreate();
      }
    );
  }, [props.createExternalEducationInstance, toggleExternalEducationInstanceModals, onUpdateList]);

  const handleUpdate = useCallback((externalEducationInstanceData: IExternalEducationInstance) => {
    props.updateExternalEducationInstance(externalEducationInstanceData).track().subscribe(
      // success
      () => {
        onUpdateList();
        toggleExternalEducationInstanceModals.onCloseUpdate();
      }
    );
  }, [props.createExternalEducationInstance, toggleExternalEducationInstanceModals, onUpdateList]);

  const isExternalEducationTemplateNotArchived = props.externalEducationTemplateStatus.id !== ExternalEducationTemplateStatusEnum.ARCHIVED;

  return (
    <React.Fragment>
      {/* List */}
      {props.externalEducationInstanceList && <ExternalEducationInstanceListView
        externalEducationInstanceList={props.externalEducationInstanceList}
        filter={collectionParams.filter}
        onPageChange={updateCollectionParams.onPageChange}
        canAdd={isExternalEducationTemplateNotArchived}
        onAdd={toggleExternalEducationInstanceModals.onOpenCreate}
        canEdit={isExternalEducationTemplateNotArchived}
        onEdit={toggleExternalEducationInstanceModals.onOpenUpdate}
      />}

      {/* Modal */}
      {(externalEducationInstanceModalsState.isCreateModalVisible || externalEducationInstanceModalsState.isUpdateModalVisible) && <ExternalEducationInstanceModalForm
        externalEducationInstance={externalEducationInstanceModalsState.selectedEntity}
        externalEducationTemplateId={props.externalEducationTemplateId}
        onCreate={handleCreate}
        onUpdate={handleUpdate}
        onCancel={toggleExternalEducationInstanceModals.onCloseCreate} />}
    </React.Fragment>
  );

};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: IExternalEducationInstanceListContainerOwnProps): IExternalEducationInstanceListContainerStateProps => ({
  externalEducationInstanceList: ExternalEducationInstanceListBusinessStore.selectors.getExternalEducationInstanceList(state),
});

// `dispatch` parameter needs a type annotation to type-check the correct shape of an action object when using dispatch function
const mapDispatchToProps = (dispatch: any): IExternalEducationInstanceListContainerDispatchProps => ({
  fetchExternalEducationInstanceList: (params: ICollectionFetchPayload<IExternalEducationInstanceListFilter>) => createTrackableAction(dispatch(ExternalEducationInstanceListBusinessStore.actions.fetchExternalEducationInstanceList(params))),

  createExternalEducationInstance: (data: IExternalEducationInstanceCreatePayload) => createTrackableAction(dispatch(ExternalEducationInstanceBusinessStore.actions.createExternalEducationInstance(data))),
  updateExternalEducationInstance: (data: IExternalEducationInstance) => createTrackableAction(dispatch(ExternalEducationInstanceBusinessStore.actions.updateExternalEducationInstance(data))),
});


export default connect(mapStateToProps, mapDispatchToProps)(withLocalize<IExternalEducationInstanceListContainerOwnProps>(ExternalEducationInstanceListContainer as any));
