import React from "react";
import DashboardNotificationListItem from "@src/components/notification/view/DashboardNotificationListItem";
import {INotification} from "@src/model/notification/Notification";

interface IDashboardNotificationListOwnProps {
  notifications: INotification[];
}

class DashboardNotificationList extends React.Component<IDashboardNotificationListOwnProps, any>{
  render() {
    return (
      <React.Fragment>
        {this.props.notifications.map((notification: INotification) => <DashboardNotificationListItem key={notification.id} notification={notification} />)}
      </React.Fragment>
    );
  }
}

export default DashboardNotificationList;