import React, { PropsWithChildren } from 'react';
import { connect } from 'react-redux';

import PublicTenantConfigurationBusinessStore from '@src/service/business/tenant/publicTenantConfigurationBusinessService';
import ITenantConfiguration from '@src/model/tenant/TenantConfiguration';
import AppConfigService from '@src/service/common/AppConfigService';
import AppStyleThemeProvider from '@src/components/common/theme/AppStyleThemeProvider';

const DEFAULT_STYLE_THEME = AppConfigService.getValue('app.defaultStyleTheme');

// -- Prop types
// ----------

export interface ITimunStyleThemeProviderOwnProps {}
export interface ITimunStyleThemeProviderStateProps {
  tenantConfig: ITenantConfiguration;
}
type ITimunStyleThemeProviderProps = PropsWithChildren<ITimunStyleThemeProviderOwnProps & ITimunStyleThemeProviderStateProps>;

// -- Component
// ----------

/** Loads app style theme according to theme name read from tenant configuration. Fallback theme is read from app config. */
const TimunStyleThemeProvider: React.FC<ITimunStyleThemeProviderProps> = (props) => {
  return (
    <AppStyleThemeProvider name={props.tenantConfig?.configuration?.styleTheme ?? DEFAULT_STYLE_THEME} fallback={DEFAULT_STYLE_THEME}>
      {props.children}
    </AppStyleThemeProvider>
  );
};

// -- HOCs and exports
// ----------

// `state` parameter needs a type annotation to type-check the correct shape of a state object but also it'll be used by "type inference" to infer the type of returned props
const mapStateToProps = (state: any, ownProps: ITimunStyleThemeProviderOwnProps): ITimunStyleThemeProviderStateProps => ({
  tenantConfig: PublicTenantConfigurationBusinessStore.selectors.getTenantConfiguration(state),
});

export default connect<ITimunStyleThemeProviderOwnProps>(mapStateToProps)(TimunStyleThemeProvider as any);
