import { ICodeBookEntry } from '@src/model/common/CodeBookEntry';
import { IPaymentInfo } from '@src/model/common/PaymentInfo';
import { IExternalEducationApplication } from '@src/model/externalEducationApplication/ExternalEducationApplication';

export interface IExternalEducationApplicationPaymentInfo extends IPaymentInfo {
  externalEducationApplication?: IExternalEducationApplication;
  paymentInfoType: ICodeBookEntry<ExternalEducationApplicationPaymentInfoTypeEnum>;
}

export enum ExternalEducationApplicationPaymentInfoTypeEnum {
  OFFER = '1',
  USER = '2',
}
